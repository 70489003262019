import { styled } from "@mui/material";
import {
  createGridAreaLettersRange,
  createSingleColumnGridArea,
} from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledEditingForm = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-areas:
    "a a a b b b"
    "c c d d e e";

  ${createGridAreaLettersRange(5)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-columns: ${createSingleColumnGridArea(5)};
  }
`;
