import * as yup from "yup";

export interface WorkloadDTO {
  id: number;
  name: string;
  version: string;
  deactivatedDate: Date | null;
}

export const schemaWorkloadDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  version: yup.string().defined(),
  deactivatedDate: yup.date().nullable().default(null),
});
