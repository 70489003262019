import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FeeYearMonthDTO } from "../../../../../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthDTO";
import {
  deleteFeeYearMonth,
  getFeeYearMonthyId,
} from "../../../../../../../services/feeYearMonth/feeYarMonth.services";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<FeeYearMonthDTO, FeeYearMonthDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: feeYearMonth }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{
              name: `${feeYearMonth?.fee.description} - ${feeYearMonth?.fee.costCenter.code} - ${feeYearMonth?.yearMonth.toPrettyString()}`,
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: feeYearMonth,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const feeYearMonthData = await getFeeYearMonthyId(feeYearMonth.id);

          if (!feeYearMonthData)
            return setModalError(
              i18next.t("fee.errors.data.general.feeYearMonthDoesNotExist")
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(feeYearMonthData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "fee.errors.data.fetch.failedToFetchFeeYearMonth",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: feeYearMonth,
      }) => {
        if (!feeYearMonth)
          throw new Error("feeYearMonth data cannot be null on confirm.");

        try {
          await deleteFeeYearMonth(feeYearMonth.id, feeYearMonth.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!feeYearMonth)
            return notifyError(
              i18next.t("fee.errors.data.delete.failedToDelete")
            );

          notifyError(
            <Trans
              i18nKey="fee.errors.data.delete.failedToDelete"
              values={{
                name: `${feeYearMonth?.fee.description} - ${feeYearMonth?.fee.costCenter.code} - ${feeYearMonth?.yearMonth.toPrettyString()}`,
              }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
