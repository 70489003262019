import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  ExpenseType,
  schemaExpenseType,
} from "../../enums/expenses/ExpenseType";

export interface ExpenseAmountMonthDTO {
  yearMonth: YearMonth;
  amount: number;
  expenseType: ExpenseType;
}

export const schemaExpenseAmountMonthDTO = yup.object({
  yearMonth: schemaYearMonth.defined(),
  amount: yup.number().defined(),
  expenseType: schemaExpenseType.defined(),
});
