import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ClipboardIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/ClipboardIconButton";
import { CenteredContent } from "../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { ProjectBaselineDTO } from "../../../../../../../../shared/specific/DTOs/projectBaseline/ProjectBaselineDTO";
import { ClipboardButtonContainer, JsonDisplayContainer } from "./index.styles";

interface OwnProps {
  projectBaseline: ProjectBaselineDTO;
}

export const ModalContent = ({ projectBaseline }: OwnProps) => {
  const { t } = useTranslation();

  const stringifiedJson = useMemo(() => {
    if (!projectBaseline.projectJson) return null;

    try {
      const parsedJson = JSON.parse(projectBaseline.projectJson);
      return JSON.stringify(parsedJson, null, 2);
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [projectBaseline.projectJson]);

  const jsonDisplay = useMemo(() => {
    if (!stringifiedJson) return null;

    return (
      <JsonDisplayContainer>
        <ClipboardButtonContainer>
          <ClipboardIconButton size="small" text={stringifiedJson} />
        </ClipboardButtonContainer>
        <SyntaxHighlighter language="json" style={coy}>
          {stringifiedJson}
        </SyntaxHighlighter>
      </JsonDisplayContainer>
    );
  }, [stringifiedJson]);

  if (stringifiedJson === null)
    return (
      <CenteredContent>
        {t(
          "projectBaselines.errors.data.fields.projectJson.noJsonAvailableForBaselineVersion"
        )}
      </CenteredContent>
    );
  if (stringifiedJson === false)
    return (
      <CenteredContent>
        {t("general.errors.data.fields.jsons.failedToParseJson")}
      </CenteredContent>
    );

  return jsonDisplay;
};
