import * as yup from "yup";
import {
  schemaTimesheetMonthDayTotalDTO,
  TimesheetMonthDayTotalDTO,
} from "./TimesheetMonthDayTotalDTO";

export interface TimesheetMonthDayAllEntriesDTO {
  monthDays: TimesheetMonthDayTotalDTO[];
  workloadMinutes: number;
}

export const schemaTimesheetMonthDayAllEntriesDTO = yup.object({
  monthDays: yup.array(schemaTimesheetMonthDayTotalDTO).defined(),
  workloadMinutes: yup.number().defined(),
});
