import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import {
  getContractRoleById,
  updateContractRole,
} from "../../../../../../services/contractRole/ContractRole.service";
import {
  formatFromTranslatableFieldValues,
  formatToTranslatableFieldValues,
} from "../../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { ContractRoleDTO } from "../../../../../../shared/specific/DTOs/ContractRole/ContractRoleDTO";
import { ContractRoleUpdateDTO } from "../../../../../../shared/specific/DTOs/ContractRole/ContractRoleUpdateDTO";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ContractRoleDataForm } from "../components/contractRoleDataForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { ContractRoleDataFormValues } from "../shared/types/form.types";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EditingButtonContainer: (props: ContractRoleDTO) => JSX.Element | null;
  editingModal: JSX.Element | null;
};

export const useEditingForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      ContractRoleDataFormValues,
      ContractRoleDTO,
      ContractRoleDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: contractRole }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: contractRole?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: contractRoleRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const contractRoleDTO = await getContractRoleById(
              contractRoleRow.id
            );
            if (!contractRoleDTO)
              return setModalError(
                i18next.t(
                  "contractRole.errors.data.general.contractRoleDoesNotExist"
                )
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;
            setInternalFormData(contractRoleDTO);
            setFormValues({
              deactivatedDate: contractRoleDTO.deactivatedDate,
              nameTranslations: formatToTranslatableFieldValues({
                source: contractRoleDTO.contractRoleTranslations,
                convertItem: ({ idLanguage, name }) => ({
                  language: idLanguage,
                  value: name ?? "",
                }),
              }),
              effectiveRole: contractRoleDTO.effectiveRole,
              trustedRole: contractRoleDTO.trustedRole,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "contractRole.errors.data.fetch.failedToFetchCostCenter",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <ContractRoleDataForm {...rest} mode="editing" />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: contractRole,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!contractRole)
            throw new Error("ContractRole cannot be null on submission.");

          try {
            await updateContractRole(
              convertTo<ContractRoleUpdateDTO>({
                effectiveRole: formValues.effectiveRole,
                trustedRole: formValues.trustedRole,
                id: contractRole.id,
                version: contractRole.version,
                deactivatedDate: formValues.deactivatedDate,
                contractRoleTranslations: formatFromTranslatableFieldValues({
                  source: formValues.nameTranslations,
                  convertItem: ({ language, value }) => ({
                    idLanguage: language,
                    name: value,
                  }),
                }),
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (contractRole?.name)
              return notifyError(
                <Trans
                  i18nKey="contractRole.errors.data.edit.failedToEditName"
                  values={{ name: contractRole?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("contractRole.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData: null as ContractRoleDTO | null,
      },
    });

  return { EditingButtonContainer, editingModal };
};
