import { css, styled } from "@mui/material";
import { HTMLProps } from "react";
import { StretchMode } from "../index.types";

export interface StyledCenteredContentContainer
  extends HTMLProps<HTMLDivElement> {
  stretchMode?: StretchMode;
}

const PureDiv = ({ stretchMode, ...rest }: StyledCenteredContentContainer) => (
  <div {...rest} />
);

export const CenteredContentContainer = styled(PureDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;

  ${({ stretchMode }) => {
    switch (stretchMode) {
      case "none":
        return null;
      case "horizontal":
        return css`
          width: 100%;
        `;
      case "vertical":
        return css`
          height: 100%;
        `;
      case "both":
      default:
        return css`
          width: 100%;
          height: 100%;
        `;
    }
  }}
`;
