import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxLengthFields } from "../shared/constants/form.constants";
import { FormValues } from "../shared/types/data/form/values.types";
import {
  StyledDelayJustificationForm,
  StyledInfoMessage,
} from "./index.styles";

export const DelayJustificationForm = ({
  formikProps: { isSubmitting },
}: FormContentProps<FormValues>) => {
  const { t } = useTranslation();

  return (
    <StyledDelayJustificationForm>
      <StyledInfoMessage>
        {t("projects.modal.data.delayJustification.info")}
      </StyledInfoMessage>
      <TextFieldFormik
        name="delayJustification"
        label={t("general.keywords.fields.delayJustification")}
        multiline
        rows={3}
        required
        inputProps={{ maxLength: maxLengthFields.delayJustification }}
        disabled={isSubmitting}
      />
    </StyledDelayJustificationForm>
  );
};
