import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a a b b b"
    "c c d d e e"
    "f f f f f f"
    "g g g g g g"
    "h h h h h h";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(8)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;
