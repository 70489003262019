import { ReactNode, useMemo } from "react";
import { PaletteColorVariant } from "../../../../../../../types/externalLibraries/mui.types";
import { ExtendedCircularProgress } from "../../../../../../components/ui/ExtendedCircularProgress";
import {
  InternalLoadingPosition,
  ModalTitleComponent,
} from "../shared/types/props.types";

interface OwnProps<InternalFormData> {
  isLoadingModal: boolean;
  isModalDisabled: boolean;
  letModalTitleDealWithLoading: boolean;
  letModalTitleDealWithError: boolean;
  internalLoadingPosition: InternalLoadingPosition;
  modalColorVariant: PaletteColorVariant;
  modalError: ReactNode;
  modalTitle?: ReactNode;
  ModalTitle?: ModalTitleComponent<InternalFormData>;
  ModalTitleMemo?: ModalTitleComponent<InternalFormData>;
  internalFormData: InternalFormData | null;
}

export const useFinalModalTitle = <InternalFormData,>({
  isLoadingModal,
  isModalDisabled,
  letModalTitleDealWithLoading,
  letModalTitleDealWithError,
  internalLoadingPosition,
  modalColorVariant,
  modalError,
  modalTitle,
  ModalTitle,
  ModalTitleMemo,
  internalFormData,
}: OwnProps<InternalFormData>) => {
  const finalModalTitle = useMemo(() => {
    if (isLoadingModal && !letModalTitleDealWithLoading) {
      if (internalLoadingPosition === "content") return "";

      return (
        <ExtendedCircularProgress
          size={24}
          color={modalColorVariant}
          colorSubVariant="contrastText"
        />
      );
    }

    if (modalError && !letModalTitleDealWithError) return "";

    if (modalTitle) return modalTitle;

    if (ModalTitle)
      return (
        <ModalTitle
          internalFormData={internalFormData}
          isLoadingModal={isLoadingModal}
          isModalDisabled={isModalDisabled}
        />
      );

    if (ModalTitleMemo)
      return (
        <ModalTitleMemo
          internalFormData={internalFormData}
          isLoadingModal={isLoadingModal}
          isModalDisabled={isModalDisabled}
        />
      );

    return null;
  }, [
    modalTitle,
    ModalTitle,
    internalFormData,
    isLoadingModal,
    isModalDisabled,
    modalError,
    internalLoadingPosition,
    letModalTitleDealWithLoading,
    letModalTitleDealWithError,
    modalColorVariant,
  ]);

  return finalModalTitle;
};
