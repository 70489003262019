import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMainContainer = styled(Paper)`
  gap: ${({ theme }) => theme.spacing(0.5)};
  padding-top: ${({ theme }) => theme.spacing(0.5)};
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  flex-direction: column;
`;

export const StyledTablesSection = styled("div")`
  max-height: calc(100vh - 198px);
  width: 100%;
  overflow: auto;
`;

export const StyledTable2 = styled("div")`
  width: max-content;
  min-width: 100%;
`;

export const StyledTableHeader = styled("div")`
  width: max-content;
  min-width: 100%;
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: white;
`;

export const StyledFooterContent2 = styled("div")`
  display: flex;
  padding-right: ${({ theme }) => theme.spacing(0.5)};
  justify-content: flex-end;
  width: 100%;
`;

export const StyledCircularProgress = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const StyledButtonArea = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
