import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";

import {
  schemaCustomerBalanceDTO,
  CustomerBalanceDTO,
} from "./CustomerBalanceDTO";

export type PaginatedCustomerBalanceDTO =
  PaginationResponse<CustomerBalanceDTO>;

export const schemaPaginatedCustomerBalanceDTO =
  getSchemaPaginationResponse<CustomerBalanceDTO>(
    schemaCustomerBalanceDTO as yup.Schema<CustomerBalanceDTO>
  );
