/* eslint-disable react/no-this-in-sfc */
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FilterValues } from "./types/general.types";
import { getNowBrazilianDate } from "../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { CommonFilterForm } from "./FormComponent";

interface OwnProps {
  setAllocationMapFilter: (
    value: React.SetStateAction<FilterValues | null | undefined>
  ) => void;
  filterIsOpen: boolean;
  setFilterIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FormFilter = ({
  setAllocationMapFilter,
  filterIsOpen,
  setFilterIsOpen,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const formikRef = useRef<FormikProps<FilterValues>>(null);

  useEffect(() => {
    formikRef.current?.resetForm();
  }, [i18n.language]);

  const { initialValues, validationSchema } = useMemo(() => {
    const startDate = getNowBrazilianDate();
    const endDate = getNowBrazilianDate();
    endDate.setMonth(endDate.getMonth() + 2);

    const initialValues: FilterValues = {
      startDate,
      endDate,
      division: null,
      segment: null,
      collaborator: null,
      onlyAllocatedCollaborators: [],
      contractRoles: [],
      discipline: [],
      manager: null,
      coordinator: null,
      project: null,
    };

    const validationSchema = yup.object({
      startDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      endDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup.object().nullable(),
      segment: yup.object().nullable(),
      collaborator: yup.object().nullable(),
      manager: yup.object().nullable(),
      coordinator: yup.object().nullable(),
      project: yup.object().nullable(),
      onlyAllocatedCollaborators: yup.array(yup.object()),
      contractRoles: yup.array(yup.object()),
      discipline: yup.array(yup.object()),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [t]);

  const formikOnSubmit = (
    values: FilterValues,
    { setSubmitting }: FormikHelpers<FilterValues>
  ) => {
    setAllocationMapFilter(values);
  };

  const formikOnReset = (
    values: FilterValues,
    { setFieldValue }: FormikHelpers<FilterValues>
  ) => {
    setFieldValue("collaborator", null);
    setFieldValue("dividion", null);
    setAllocationMapFilter(values);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      onReset={formikOnReset}
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <CommonFilterForm
          formikProps={formikProps}
          filterIsOpen={filterIsOpen}
        />
      )}
    </Formik>
  );
};
