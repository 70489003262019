import { css } from "@emotion/react";
import { styled } from "@mui/material";

interface OwnProps {
  alignment?: "left" | "right" | "center";
  width?: number;
}

export const StyledActionButtonsContainer = styled("div")<OwnProps>`
  text-align: ${({ alignment = "left" }) => alignment};
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
      max-width: ${width}px;
      overflow: hidden;
    `};
`;
