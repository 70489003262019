import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { AutocompleteOption } from "../../../../../shared/common/types/data/form/autocomplete.types";
import { ReportTypesAutocomplete } from "./form/general/autocomplete/ReportTypesAutocomplete";
import { ReportForm } from "./form/general/reportForms";
import { StyledViewContent } from "./index.styles";
import { ReportOption } from "./shared/types/enums/general.enum";

export const Reports = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState<AutocompleteOption<ReportOption> | null>(
    null
  );

  useEffect(() => {
    setPageTitle(t("reports.pages.main.title"));
  }, [t]);

  return (
    <StyledViewContent>
      <ReportTypesAutocomplete
        autocompleteProps={{
          value,
          setValue,
        }}
      />
      {value && <ReportForm id={value.id} />}
    </StyledViewContent>
  );
};
