import { styled } from "@mui/material";

export const StyledViewContent = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > * {
    width: 45%;
  }
`;
