import { ReactNode, useMemo } from "react";
import { getProjectSummary } from "../../../../../../../../../../../services/projects/projects.service";
import { useLoadLatest } from "../../../../../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { SummaryContextValues } from "./index.types";
import { SummaryContext } from "./SummaryContext";
import { getFrameSummary } from "../../../../../../../../../../../services/frames/frames.service";

interface OwnProps {
  children: ReactNode;
  id: number;
  isFrame: boolean;
}

export const SummaryContextProvider = ({ children, id, isFrame }: OwnProps) => {
  const {
    value: summaryData,
    hasError: summaryHasError,
    errorStatusCode: summaryErrorStatusCode,
    isLoading: isLoadingSummary,
    translationError: summaryTranslationError,
  } = useLoadLatest({
    initialValue: null,
    getValue: () => (isFrame ? getFrameSummary(id) : getProjectSummary(id)),
    onError: ({ setValue }) => setValue(null),
  });

  const value = useMemo((): SummaryContextValues => {
    return {
      summaryData,
      summaryHasError,
      summaryErrorStatusCode,
      isLoadingSummary,
      summaryTranslationError,
      isFrame,
    };
  }, [
    summaryData,
    summaryHasError,
    summaryErrorStatusCode,
    isLoadingSummary,
    summaryTranslationError,
    isFrame,
  ]);

  return (
    <SummaryContext.Provider value={value}>{children}</SummaryContext.Provider>
  );
};
