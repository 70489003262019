import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useRef } from "react";
import { TextField, Typography } from "@mui/material";
import { ExpensesOfTypeDTO } from "../../../../../../../../../../shared/specific/DTOs/expenses/ExpensesOfTypeDTO";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { FormMode } from "../shared/types/data/form/general.types";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useTableData } from "./hooks/useTableData";
import { expenseTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/expenses/expenseType.maps";
import { StyledForm, StyledMessage, StyledTotal } from "./index.styles";
import { useCreationForm } from "./hooks/useCreationForm";
import { Protected } from "../../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { formatCurrency } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ExpenseModifyFormValues } from "../shared/types/data/form/values.types";
import { ExpenseType } from "../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";

type OwnProps = Omit<
  FormContentProps<ExpenseModifyFormValues>,
  "internalFormData"
> & {
  expensesRow: ExpensesOfTypeDTO;
  yearMonth: YearMonth | null;
  mode: FormMode;
  costCenterPep: CostCenterPepOnlyDTO;
  reloadMainTablePage: () => void;
};

export const ViewExpensesModal = ({
  formikProps: { values },
  expensesRow,
  yearMonth,
  mode,
  costCenterPep,
  reloadMainTablePage,
}: OwnProps) => {
  const isReadOnly = mode === "viewing";

  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { CreationButton, creationModal } = useCreationForm({
    reloadTablePage,
    reloadMainTablePage,
    expenseType: expensesRow.expenseType,
  });
  const {
    headCells,
    onTablePageChange,
    editingModal,
    deletionModal,
    expensesSum,
  } = useTableData({
    expenseType: expensesRow.expenseType,
    costCenterPep,
    yearMonth,
    isReadOnly,
    reloadTablePage,
    reloadMainTablePage,
  });

  const immutableWorkForceMessage = useMemo(() => {
    if (expensesRow.expenseType !== ExpenseType.Workforce) return null;

    return (
      <StyledMessage>
        {t("expenses.modal.data.modifySingle.workForceInfo")}
      </StyledMessage>
    );
  }, [t, expensesRow.expenseType]);

  return (
    <StyledForm>
      <TextField
        label={t("costCenterPeps.keywords.general.pep")}
        value={costCenterPep.costCenter.code ?? "-"}
        InputProps={{ readOnly: true }}
        required
      />
      <TextField
        label={t("general.keywords.fields.type")}
        value={
          t(expenseTypeToTranslationCodeMap[expensesRow.expenseType]) ?? "-"
        }
        InputProps={{ readOnly: true }}
        required
      />
      <TextField
        label={t("brlParity.keywords.fields.yearMonth")}
        value={yearMonth?.toString() ?? "-"}
        InputProps={{ readOnly: true }}
        required
      />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: !isReadOnly && (
            <Protected
              restrictions={{
                type: PermissionType.Expenses,
                level: PermissionLevel.Create,
              }}
            >
              <CreationButton
                expenseType={expensesRow.expenseType}
                costCenterPep={costCenterPep}
                yearMonth={yearMonth}
              />
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onTablePageChange,
          },
        }}
      />
      <StyledTotal>
        <Typography variant="h6" component="div">
          {t("general.keywords.fields.total")}
        </Typography>
        <Typography variant="h6" component="div" color="#707070">
          {formatCurrency({
            value: expensesSum,
            currencySymbol: costCenterPep.currencySymbol,
          })}
        </Typography>
      </StyledTotal>
      {immutableWorkForceMessage}
      {editingModal}
      {creationModal}
      {deletionModal}
    </StyledForm>
  );
};
