import { useMemo } from "react";
import { PermissionType } from "../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { CollaboratorAllocationsWithinContext } from "./CollaboratorAllocationsWithinContext";
import { CollaboratorAllocationProvider } from "./shared/context";
import { CollaboratorAllocationsSuperUserContext } from "./shared/context/CollaboratorAllocationsSuperUserContext";

export const CollaboratorAllocationsPage = () => {
  const {
    superUserButton: canEditAllCollaboratorAllocationsButton,
    inSuperUserMode: canEditAllCollaboratorAllocations,
  } = useSuperUserButton({
    restrictions: [
      PermissionType.CanEditAllCorporateAllocations,
      PermissionType.CanEditAllAllocations,
    ],
    tooltipNotInSuperUserModeOff:
      "corporateAllocations.actions.authorization.editAllocationsWithoutRestrictions",
    tooltipInSuperUserMode:
      "corporateAllocations.actions.authorization.stopEditAllocationsWithoutRestrictions",
  });

  const collaboratorAllocationsSuperUserValues = useMemo(() => {
    return {
      canEditAllCollaboratorAllocationsButton,
      canEditAllCollaboratorAllocations,
    };
  }, [
    canEditAllCollaboratorAllocationsButton,
    canEditAllCollaboratorAllocations,
  ]);

  return (
    <CollaboratorAllocationsSuperUserContext.Provider
      value={collaboratorAllocationsSuperUserValues}
    >
      <CollaboratorAllocationProvider>
        <CollaboratorAllocationsWithinContext />
      </CollaboratorAllocationProvider>
    </CollaboratorAllocationsSuperUserContext.Provider>
  );
};
