import { styled } from "@mui/material";

export const StyledButtonContainer = styled("div")`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

export const StyledForm = styled("div")`
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 500px;
`;
