import { css } from "@emotion/css";
import {
  Drawer,
  List,
  Tooltip,
  TooltipProps,
  styled,
  useTheme,
} from "@mui/material";
import { constants } from "./constants";

export interface SidebarProps {
  open?: boolean;
}

export const SidebarIconCss = ({ open }: SidebarProps) => {
  const theme = useTheme();

  return css`
    display: flex;
    min-width: 0;
    width: ${constants.iconWidth}px;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.primary.contrastText};

    margin-right: ${open ? "12px" : "0px"};
    transition: margin-right ${constants.transitionTime}s;
  `;
};

export const SidebarContainer = styled(Drawer)<SidebarProps>`
  .MuiDrawer-paper {
    position: relative;
    width: ${({ open }) =>
      open ? constants.drawerWidthOpened : constants.drawerWidthClosed}px;
    transition: width ${constants.transitionTime}s;
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
    border-right: none;
  }

  .MuiList-root {
    padding: 0;
  }

  .MuiListItemIcon-root,
  .MuiListItemAvatar-root {
    ${SidebarIconCss}
  }

  .MuiListItemText-root,
  .MuiListItemIcon-root,
  .MuiListItemAvatar-root {
    height: ${constants.buttonHeight}px;
  }

  .MuiListItemText-root {
    display: flex;
    align-items: center;
    min-width: ${constants.textWidth}px;

    opacity: ${({ open }) => (open ? 1 : 0)};
    transition: opacity ${constants.transitionTime}s;

    margin: 0;

    .MuiTypography-root {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: ${constants.buttonHeight}px;
      font-weight: 500;
    }
  }

  .MuiListItemButton-root {
    overflow-x: hidden;
  }

  .MuiListItemButton-root,
  .MuiListItem-root {
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiDrawer-paper {
      position: fixed;
      width: ${({ open }) => (open ? constants.drawerWidthOpened : 0)}px;
    }
  }
`;

export const SidebarHeader = styled("div")<SidebarProps>`
  .MuiListItemButton-root {
    justify-content: center;
    margin-right: 0;
    height: 55px;
    overflow: hidden;
  }

  .MuiListItemIcon-root {
    margin-right: 0;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .MuiListItemIcon-root {
      opacity: ${({ open }) => (open ? 1 : 0)};
      transition: opacity ${constants.transitionTime}s;
    }
  }
`;

export const SidebarButtons = styled(List)<SidebarProps>`
  flex: 1;

  a {
    text-decoration: none;
    color: inherit;
  }

  .MuiListItemButton-root {
    justify-content: initial;
  }
`;

export const SidebarFooter = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const RiseVersion = styled("div")<{ ismenuopen: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: ${({ ismenuopen }) => (ismenuopen === "true" ? "1rem" : "0.8rem")};
  transition: all ease 0.25s;
  height: max-content;
  font-weight: bold;
`;

export const MenuTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} placement="right" classes={{ popper: className }} />
))``;
