import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormButtonProps } from "../../../shared/types/props.types";
import { StyledButton } from "./index.styles";

interface OwnProps extends FormButtonProps {
  buttonText: string;
}

export const UploadTextButton = ({ onClick, buttonText }: OwnProps) => (
  <StyledButton onClick={onClick} color="primary" variant="contained">
    <CloudUploadIcon />
    {buttonText}
  </StyledButton>
);
