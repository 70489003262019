import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { createCostCenter } from "../../../../../../../services/costCenter/costCenter.service";
import { CostCenterCreateDTO } from "../../../../../../../shared/specific/DTOs/costCenter/CostCenterCreateDTO";
import { CreationForm } from "./CreationForm";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useFormikConfig } from "./shared/hooks/data/validation/useFormikConfig";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import { formatFromTranslatableFieldValues } from "../../../../../../../shared/common/helpers/data/form/languages/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("costCenters.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: CreationForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!formValues.division || !formValues.subsidiary)
            throw new Error("Required values are missing.");

          try {
            await createCostCenter(
              convertTo<CostCenterCreateDTO>({
                code: formValues.code,
                idDivision: formValues.division.id,
                idSubsidiary: formValues.subsidiary.id,
                costCenterTranslations: formatFromTranslatableFieldValues({
                  source: formValues.nameTranslations,
                  convertItem: ({ language, value }) => ({
                    idLanguage: language,
                    name: value,
                  }),
                }),
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "costCenters.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
