import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  ContractType,
  schemaContractType,
} from "../../enums/projects/ContractType.enum";
import {
  ProjectStatus,
  schemaProjectStatus,
} from "../../enums/projects/ProjectStatus.enum";

export interface ProjectOnlyDTO {
  id: number;
  idCustomer: number;
  idUserManager: number;
  idUserCoordinator: number | null;
  idDivision: number;
  idSubsidiary: number;
  idProductService: number;
  idFrame: number | null;
  code: string;
  name: string;
  description: string;
  contractType: ContractType;
  daysForPayment: number;
  billingAmount: number;
  status: ProjectStatus;
  scheduledStartDate: DateOnly | null;
  scheduledEndDate: DateOnly | null;
  mv: number | null;
  isValid: boolean;
  validatedAt: Date | null;
  validatedBy: string | null;
  createdBy: string;
  insertedDate: Date;
  updatedDate: Date;
  version: string;
  dealApprovedCredit: boolean;
  isInvestmentProject: boolean;
  clientAccount: string | null;
}

export const schemaProjectOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  idCustomer: yup.number().integer().defined(),
  idUserManager: yup.number().integer().defined(),
  idUserCoordinator: yup.number().integer().nullable().defined(),
  idDivision: yup.number().integer().defined(),
  idSubsidiary: yup.number().integer().defined(),
  idProductService: yup.number().integer().defined(),
  idFrame: yup.number().integer().nullable().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  description: yup.string().defined(),
  contractType: schemaContractType.defined(),
  daysForPayment: yup.number().integer().defined(),
  billingAmount: yup.number().defined(),
  status: schemaProjectStatus.defined(),
  scheduledStartDate: schemaDateOnly.nullable().defined(),
  scheduledEndDate: schemaDateOnly.nullable().defined(),
  mv: yup.number().nullable().defined(),
  isValid: yup.boolean().defined(),
  validatedAt: yup.date().nullable().defined(),
  validatedBy: yup.string().nullable().defined(),
  createdBy: yup.string().defined(),
  insertedDate: yup.date().defined(),
  updatedDate: yup.date().defined(),
  version: yup.string().defined(),
  dealApprovedCredit: yup.boolean().defined(),
  isInvestmentProject: yup.boolean().defined(),
  clientAccount: yup.string().nullable().defined(),
});
