import { Environment } from "./index.interface";

export const enviroment: Environment = {
  apiURL: process.env.REACT_APP_API_URI ?? "",
  user: {
    auth: {
      microsoft: {
        authority: process.env.REACT_APP_AUTHORITY ?? "",
        clientId: process.env.REACT_APP_CLIENT_ID ?? "",
        redirectUri: process.env.REACT_APP_REDIRECT_URI ?? "",
        graphEndpoint: process.env.REACT_APP_MICROSOFT_GRAPH ?? "",
        apiScope: process.env.REACT_APP_API_SCOPE ?? "",
      },
    },
  },
};
