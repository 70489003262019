import { createContext } from "react";

export interface CollaboratorAllocationsSuperUserContextValues {
  canEditAllCollaboratorAllocations: boolean;
  canEditAllCollaboratorAllocationsButton: JSX.Element | null;
}

export const CollaboratorAllocationsSuperUserContext =
  createContext<CollaboratorAllocationsSuperUserContextValues>({
    canEditAllCollaboratorAllocations: false,
    canEditAllCollaboratorAllocationsButton: null,
  });
