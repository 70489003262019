import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";

interface OwnProps {
  rows: ExternalEnhancedRow[];
}

export const CertificationsViewTable = ({ rows }: OwnProps) => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("skillCertification.keywords.general.certification"),
      t("skillCertification.keywords.general.provider"),
      t("collaboratorProfile.keywords.fields.dateOfAcquisition"),
      t("collaboratorProfile.keywords.fields.expirationDate"),
      {
        value: "",
        canSort: false,
        width: 150,
      },
    ];

    return headCells;
  }, [t]);

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        hasDynamicTableHeight: true,
        stickyHeader: true,
        maxTableHeight: 350,
      }}
      rows={rows}
      orderDefaults={{
        defaultOrderBy: "none",
      }}
    />
  );
};
