import * as yup from "yup";

export interface DivisionDTO {
  id: number;
  name: string;
  prefix: string | null;
  uniqueCode: string;
}

export const schemaDivisionDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  prefix: yup.string().nullable().defined(),
  uniqueCode: yup.string().defined(),
});
