import * as yup from "yup";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  AllocationYearMonthDTO,
  schemaAllocationYearMonthDTO,
} from "./AllocationYearMonthDTO";

export interface AllocationsOfUserDTO {
  allocationGroup: number;
  idUserCollaborator: number | null;
  name: string | null;
  currentCollaboratorJobRoleName: string | null;
  allocationType: EmployeeAllocationType;
  idCostCenterPep: number;
  idJobRole: number | null;
  jobRoleName: string | null;
  totalAllocationCost: number;
  resignationDate: Date | null;
  allocationYearMonths: AllocationYearMonthDTO[];
}

export const schemaAllocationsOfUserDTO = yup.object({
  allocationGroup: yup.number().integer().defined(),
  idUserCollaborator: yup.number().integer().nullable().defined(),
  name: yup.string().nullable().defined(),
  currentCollaboratorJobRoleName: yup.string().defined().nullable(),
  allocationType: schemaEmployeeAllocationType.defined(),
  idCostCenterPep: yup.number().integer().defined(),
  idJobRole: yup.number().integer().nullable().defined(),
  jobRoleName: yup.string().defined().nullable(),
  totalAllocationCost: yup.number().defined(),
  resignationDate: yup.date().nullable().defined(),
  allocationYearMonths: yup.array(schemaAllocationYearMonthDTO).defined(),
});
