import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  schemaSkillCertificationDTO,
  SkillCertificationDTO,
} from "./SkillCertificationDTO";

export type PaginatedSkillCertificationDTO =
  PaginationResponse<SkillCertificationDTO>;

export const schemaPaginatedSkillCertificationDTO =
  getSchemaPaginationResponse<SkillCertificationDTO>(
    schemaSkillCertificationDTO as yup.Schema<SkillCertificationDTO>
  );
