import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { AllocationYearMonthCell } from "../../shared/components/AllocationYearMonthCell";
import { CollaboratorAllocationContext } from "../../shared/context";
import {
  AllocationYearMonthsFormValues,
  FormValuesCollaboratorAllocationsFilter,
  FormValues,
} from "../../shared/types/form.types";
import { AllocationYearMonthTotalCell } from "./AllocationYearMonthTotalCell";
import { EmployeeAllocationType } from "../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CollaboratorAllocationsSuperUserContext } from "../../shared/context/CollaboratorAllocationsSuperUserContext";

interface OwProps {
  reloadTablePage: () => void;
  allocationCollaboratorFilter?: FormValuesCollaboratorAllocationsFilter | null;
  formikValues: FormValues;
  setAllocationYearMonths: (
    value: AllocationYearMonthsFormValues[],
    shouldValidate?: boolean | undefined
  ) => void;
}

export const useTableData = ({
  reloadTablePage,
  formikValues,
  allocationCollaboratorFilter,
  setAllocationYearMonths,
}: OwProps) => {
  const { t, i18n } = useTranslation();

  const allocationContext = useContext(CollaboratorAllocationContext);

  const { canEditAllCollaboratorAllocations } = useContext(
    CollaboratorAllocationsSuperUserContext
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("usersCollaborators.keywords.general.collaborator"),
      {
        value: `${t("projects.keywords.fields.project")}/${t(
          "allocations.keywords.employeeAllocationType.allocation"
        )}`,
      },
      t("costCenters.keywords.general.costCenter"),
      t("divisions.keywords.general.un"),
      t("projects.keywords.fields.manager"),
      {
        value: t("general.keywords.fields.total"),
        width: 120,
        justifyContent: "center",
      },
      {
        value: t("collaboratorAllocations.keywords.fields.week1"),
        width: 120,
        justifyContent: "center",
      },
      {
        value: t("collaboratorAllocations.keywords.fields.week2"),
        width: 120,
        justifyContent: "center",
      },
      {
        value: t("collaboratorAllocations.keywords.fields.week3"),
        width: 120,
        justifyContent: "center",
      },
      {
        value: t("collaboratorAllocations.keywords.fields.week4"),
        width: 120,
        justifyContent: "center",
      },
      {
        value: t("collaboratorAllocations.keywords.fields.week5"),
        width: 120,
        justifyContent: "center",
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(
      async ({ setInfo }) => {
        try {
          if (
            !allocationCollaboratorFilter?.userCollaborator ||
            !allocationCollaboratorFilter?.yearMonth
          ) {
            setInfo("allocationsMap.info.data.general.fillTheFilter");
          }

          if (
            allocationCollaboratorFilter?.userCollaborator &&
            allocationCollaboratorFilter?.yearMonth &&
            allocationContext.allocations.length === 0
          ) {
            setInfo("general.info.data.general.noResultsFoundSearch");
          }

          const data = allocationContext.allocations.map((x) => ({
            yearMonth: x.yearMonth.toDate(),
            week1Percentage: formatNumber(x.week1Percentage, {
              fractionDigits: 2,
            }),
            week2Percentage: formatNumber(x.week2Percentage, {
              fractionDigits: 2,
            }),
            week3Percentage: formatNumber(x.week3Percentage, {
              fractionDigits: 2,
            }),
            week4Percentage: formatNumber(x.week4Percentage, {
              fractionDigits: 2,
            }),
            week5Percentage: x.week5Percentage
              ? formatNumber(x.week5Percentage, { fractionDigits: 2 })
              : "0",
          }));

          setAllocationYearMonths(data);

          const isDisabledAllocationCell = (
            type: EmployeeAllocationType,
            canEdit: boolean
          ) => {
            if (canEditAllCollaboratorAllocations) return false;

            if (!canEdit) {
              return true;
            }

            switch (type) {
              case EmployeeAllocationType.Leave:
                return !allocationContext.CanAccessLeaveInCorporateAllocation;
              case EmployeeAllocationType.Training:
                return !allocationContext.CanAccessTrainingInCorporateAllocation;
              case EmployeeAllocationType.Unallocated:
                return !allocationContext.CanAccessDeallocationInCorporateAllocation;
              case EmployeeAllocationType.SupplementaryAllocation:
                return true;
              default:
                return false;
            }
          };

          const rows: ExternalEnhancedRow[] = allocationContext.allocations.map(
            (allocation, index): ExternalEnhancedRow => {
              const disabled = isDisabledAllocationCell(
                allocation.allocationType,
                allocation.canEdit
              );

              return {
                id: allocation?.id || index,
                cells: [
                  allocation?.userCollaborator?.name || "-",
                  allocation.costCenterPep
                    ? allocation.costCenterPep.project.name
                    : t(
                        employeeAllocationTypeToTranslationCodeMap[
                          allocation.allocationType
                        ]
                      ),
                  allocation.costCenterPep
                    ? allocation.costCenterPep.costCenter.code
                    : allocation.costCenter?.code || "-",
                  allocation.costCenterPep
                    ? allocation.costCenterPep.project.division.name
                    : allocation.costCenter?.division.name || "-",
                  allocation.costCenterPep?.project.userManager.name || "-",
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthTotalCell
                          yearMonth={allocation.yearMonth}
                          index={index}
                        />
                      );
                    },
                  },
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthCell
                          lineNumber={index}
                          weekNumber={1}
                          yearMonth={allocation.yearMonth}
                          disabled={disabled}
                        />
                      );
                    },
                  },
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthCell
                          lineNumber={index}
                          weekNumber={2}
                          yearMonth={allocation.yearMonth}
                          disabled={disabled}
                        />
                      );
                    },
                  },
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthCell
                          lineNumber={index}
                          weekNumber={3}
                          yearMonth={allocation.yearMonth}
                          disabled={disabled}
                        />
                      );
                    },
                  },
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthCell
                          lineNumber={index}
                          weekNumber={4}
                          yearMonth={allocation.yearMonth}
                          disabled={disabled}
                        />
                      );
                    },
                  },
                  {
                    CellRenderer: () => {
                      return (
                        <AllocationYearMonthCell
                          lineNumber={index}
                          weekNumber={5}
                          yearMonth={allocation.yearMonth}
                          disabled={disabled}
                        />
                      );
                    },
                  },
                ],
              };
            }
          );

          return rows;
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [
        t,
        i18n,
        allocationContext.allocations.length,
        canEditAllCollaboratorAllocations,
        allocationCollaboratorFilter,
      ]
    );

  return {
    headCells,
    onPageChange,
  };
};
