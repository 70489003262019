import { useContext } from "react";
import { DeleteIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { InvoicesSuperUserContext } from "../../../shared/react/contexts/InvoicesSuperUserContext";

interface OwnProps {
  invoice: InvoiceDTO;
  DeleteButtonContainer: (props: InvoiceDTO) => JSX.Element | null;
}

export const DeleteInvoiceTableButton = ({
  invoice,
  DeleteButtonContainer,
}: OwnProps) => {
  const { canEditAllInvoices } = useContext(InvoicesSuperUserContext);

  if (!invoice.issueDate) return <DeleteButtonContainer {...invoice} />;
  if (!canEditAllInvoices) return <DeleteIconButton disabled />;
  return <DeleteButtonContainer {...invoice} />;
};
