import { styled } from "@mui/material/styles";

interface StyledFixedColumnProps {
  isread: string;
}

export const StyledFixedColumn = styled("div")<StyledFixedColumnProps>`
  width: 100%;
  height: 60px;
  background-color: ${({ isread, theme }) =>
    isread === "true" ? "#f1f1f1" : ""};
`;
