import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useContext, useMemo, useRef } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import i18next from "i18next";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { FormValuesUserGroup } from "../../../shared/types/form.types";
import {
  StyledAddCollaboratorField,
  StyledUserGroupModal,
} from "./index.styles";
import { AccessGroupsDTO } from "../../../../../../../../shared/specific/DTOs/accessGroups/AccessGroupsDTO";
import { UserGroupTable } from "./UserGroupTable";
import { addUserToGroup } from "../../../../../../../../services/userGroup/userGroup.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { UserGroupContext } from "../context";
import { AddIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { LoadingBackdrop } from "../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface OwnProps {
  accessGroup?: AccessGroupsDTO;
}

export const CommonUserGroupForm = ({ accessGroup }: OwnProps) => {
  const { t } = useTranslation();

  const { isLoading, setIsLoading } = useContext(UserGroupContext);

  const formikRef = useRef<FormikProps<FormValuesUserGroup>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesUserGroup = {
      collaboratorToAdd: null,
    };

    const validationSchema = yup.object({
      collaboratorToAdd: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesUserGroup,
    formikHelpers: FormikHelpers<FormValuesUserGroup>
  ) => {
    try {
      await addUserToGroup({
        idAccessGroup: accessGroup?.id ?? 0,
        idUserCollaborator: values.collaboratorToAdd?.id ?? 0,
      });

      setIsLoading(true);

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "accessGroups.errors.data.edit.failedToEdit",
      });
    } finally {
      formikHelpers.setFieldValue("collaboratorToAdd", null);
      setIsLoading(false);
    }
  };

  return (
    <StyledUserGroupModal>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, values }) => (
          <StyledAddCollaboratorField>
            <UsersSearchAutocompleteFormik
              name="collaboratorToAdd"
              textfieldProps={{
                label: t("accessGroups.keywords.fields.includeCollaborator"),
                required: true,
              }}
              filtersMemo={{ isActive: true, isCollaborator: true }}
            />
            <AddIconButton
              onClick={submitForm}
              disabled={!values.collaboratorToAdd?.id || isLoading}
            />
            <LoadingBackdrop open={isSubmitting} />
          </StyledAddCollaboratorField>
        )}
      </Formik>
      <UserGroupTable accessGroupId={accessGroup?.id ?? 0} />
    </StyledUserGroupModal>
  );
};
