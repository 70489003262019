import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../types/form.types";
import { getNowBrazilianDate } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      certification: null,
      dateOfAcquisition: null,
      expirationDate: null,
      certificationFile: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      certification: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      dateOfAcquisition: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t(
            "collaboratorProfile.keywords.warnings.greaterThanCurrentDate"
          ),
          test(dateOfAcquisition) {
            if (!dateOfAcquisition) return true;
            if (dateOfAcquisition > getNowBrazilianDate()) return false;
            return true;
          },
        })
        .required(t("general.errors.data.fields.general.required")),
      expirationDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate")),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
