import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCreationEditingFormikConfig } from "../shared/hooks/validation/useCreationEditingFormikConfig";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { BudgetRoundFormValues } from "../shared/types/BudgetRoundFormValues";
import { BudgetRoundDTO } from "../../../../../shared/specific/DTOs/BudgetRound/BudgetRoundDTO";
import {
  getBudgetRoundId,
  updateBudgetRound,
} from "../../../../../services/budgetRound/BudgetRound.service";
import { BudgetRoundCommomForm } from "../shared/components/BudgetRoundCommomForm/index";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useCreationEditingFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<BudgetRoundFormValues, BudgetRoundDTO, BudgetRoundDTO>(
      {
        modal: {
          keepModalMounted: 1000,
          ModalTitleMemo: ({ internalFormData: budgetRound }) => (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: budgetRound?.name ?? "",
              }}
              components={{ italic: <em /> }}
            />
          ),
        },
        button: {
          FormButtonMemo: EditFormButton,
          onClickContentButtonComponentMemo: async ({
            contentButtonContentProps: budgetRound,
            setFormValues,
            setInternalFormData,
            setIsLoadingModal,
            setModalError,
            getOpenCloseModalCount,
            checkInCurrentModalCount,
          }) => {
            setIsLoadingModal(true);
            const startingOpenCloseModalCount = getOpenCloseModalCount();
            try {
              const roundData = await getBudgetRoundId(budgetRound.id);

              if (!roundData)
                return setModalError(
                  i18next.t(
                    "dashboards.errors.data.general.dashboardDoesNotExist"
                  )
                );

              if (!checkInCurrentModalCount(startingOpenCloseModalCount))
                return;

              setInternalFormData(roundData);
              setFormValues({
                name: roundData.name,
                beginDate: roundData.beginDate,
                endDate: roundData.endDate,
              });
            } catch (error) {
              if (!checkInCurrentModalCount(startingOpenCloseModalCount))
                return;

              const errorMessage = getTextIf4xxApiErrorDTO({
                error,
                defaultMessage:
                  "budget.errors.data.fetch.failedToFetchBudgetRound",
              });

              setModalError(errorMessage);
            } finally {
              setIsLoadingModal(false);
            }
          },
        },
        form: {
          formikConfig,
          FormContentMemo: BudgetRoundCommomForm,
          FormActionsMemo: ({
            submitFormValues,
            closeModal,
            isSubmitting,
            isLoadingModal,
            modalError,
          }) => {
            const { t } = useTranslation();

            return (
              <>
                <Button onClick={closeModal} disabled={isSubmitting}>
                  {t("general.actions.general.cancel")}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                  disabled={isLoadingModal || !!modalError}
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              </>
            );
          },
          onSubmit: async ({
            internalFormData: round,
            formValues,
            formikHelpers: { setSubmitting },
            closeModal,
            setFormValues,
          }) => {
            try {
              await updateBudgetRound({
                id: round?.id || 0,
                name: formValues.name,
                beginDate: formValues.beginDate ?? new Date(),
                endDate: formValues.endDate ?? new Date(),
                version: round?.version || undefined,
              });

              notifySuccess(
                i18next.t(
                  "general.success.data.general.operationExecutedSuccessfully"
                )
              );
              reloadTablePage();
              closeModal();
            } catch (error) {
              const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
              if (errorApiErrorDTO instanceof TranslatedError)
                return notifyError(
                  i18next.t(
                    errorApiErrorDTO.message,
                    errorApiErrorDTO.interpolation
                  )
                );
              if (errorApiErrorDTO instanceof Error)
                return notifyError(errorApiErrorDTO.message);

              notifyError(t("budget.errors.data.edit.failedToEditBudgetRound"));
            } finally {
              setSubmitting(false);
            }
          },
        },
      }
    );

  return { EditingButtonContainer, editingModal };
};
