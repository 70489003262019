import { ReactNode } from "react";
import {
  PaletteColorSubVariant,
  PaletteColorVariant,
} from "../../../../types/externalLibraries/mui.types";
import { CenteredContent } from "../CenteredContent";
import { StretchMode } from "../CenteredContent/CenteredContent";
import { ExtendedCircularProgress } from "../ExtendedCircularProgress";

export interface CenteredLoadingProps {
  loadingMessage?: string;
  size?: number;
  children?: ReactNode;
  className?: string;
  color?: PaletteColorVariant;
  colorSubVariant?: PaletteColorSubVariant;
  stretchMode?: StretchMode;
}

export const CenteredLoading = ({
  loadingMessage,
  children,
  size = 40,
  className,
  color,
  colorSubVariant,
  stretchMode,
}: CenteredLoadingProps) => {
  return (
    <CenteredContent className={className} stretchMode={stretchMode}>
      <ExtendedCircularProgress
        size={size}
        color={color}
        colorSubVariant={colorSubVariant}
      />
      {children || loadingMessage || null}
    </CenteredContent>
  );
};
