import { InputAdornment, TextField, Typography } from "@mui/material";
import { isValid } from "date-fns";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generalFieldsConstants } from "../../../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { DatePickerFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";
import {
  fieldsConstants,
  maxLengthFields,
} from "../../../../constants/data/form/fields.constants";
import { FormMode, FormValues } from "../../../../types/data/form/values.types";
import { StyledForm } from "./index.styles";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { AdditiveDTO } from "../../../../../../../../../../../../shared/specific/DTOs/additive/AdditiveDTO";

interface OwnProps
  extends Omit<FormContentProps<FormValues, AdditiveDTO>, "internalFormData"> {
  mode: FormMode;
  createdBy?: string;
}

export const CommonAdditivesForm = ({
  formikProps: { values, isSubmitting },
  mode,
  createdBy,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const isReadOnlyMode = mode === "viewing";
  const isDeadlineAdditive = mode === "deadlineAdditive";

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {project?.subsidiary.currencySymbol ?? ""}
      </InputAdornment>
    );
  }, [project?.subsidiary.currencySymbol]);

  const isAntecipationJustificationRequired = useMemo(() => {
    if (
      !values.scheduledStartDate ||
      !isValid(values.scheduledStartDate) ||
      values.scheduledStartDate.getFullYear() < 1900 ||
      !project.scheduledStartDate
    )
      return false;

    return values.scheduledStartDate < project.scheduledStartDate.toDate();
  }, [values.scheduledStartDate, project.scheduledStartDate]);

  return (
    <StyledForm>
      <DatePickerFormik
        name="scheduledStartDate"
        datePickerProps={{
          label: t("additives.keywords.fields.scheduledStart"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.scheduledEndDate ?? undefined,
          disabled: isSubmitting,
          readOnly: isReadOnlyMode,
        }}
        textFieldProps={{
          required: true,
          InputProps: {
            readOnly: isReadOnlyMode,
          },
        }}
      />
      <DatePickerFormik
        name="scheduledEndDate"
        datePickerProps={{
          label: t("additives.keywords.fields.scheduledEnd"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          minDate: values.scheduledStartDate ?? undefined,
          disabled: isSubmitting,
          readOnly: isReadOnlyMode,
        }}
        textFieldProps={{
          required: true,
          InputProps: {
            readOnly: isReadOnlyMode,
          },
        }}
      />
      <LocalizedNumberTextFieldFormik
        name="mv"
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("additives.keywords.fields.commercialMv"),
          InputProps: {
            endAdornment: "%",
            readOnly: isReadOnlyMode,
          },
          required: true,
        }}
        disabled={isSubmitting || isDeadlineAdditive}
        max={fieldsConstants.maxMv}
        min={fieldsConstants.minMv}
      />
      <LocalizedNumberTextFieldFormik
        name="amount"
        min={-generalFieldsConstants.maxAmountValue}
        max={generalFieldsConstants.maxAmountValue}
        padFractionalZeros
        overwrite="shift"
        textFieldProps={{
          label: t("general.keywords.fields.value"),
          required: true,
          disabled: isSubmitting || isDeadlineAdditive,
          InputProps: {
            startAdornment: currencyStartAdornment,
            readOnly: isReadOnlyMode,
          },
        }}
      />
      {isReadOnlyMode || isDeadlineAdditive ? (
        <TextField
          label={t("projects.keywords.fields.approvedCredit")}
          value={values.approvedCredit?.label ?? ""}
          aria-readonly
          disabled={isDeadlineAdditive}
        />
      ) : (
        <YesNoAutocompleteFormik
          name="approvedCredit"
          textfieldProps={{
            label: t("projects.keywords.fields.approvedCredit"),
            InputProps: {
              readOnly: isReadOnlyMode,
            },
            required: true,
            disabled: isSubmitting || isReadOnlyMode,
          }}
        />
      )}
      <TextFieldFormik
        name="justification"
        label={t("general.keywords.fields.justification")}
        multiline
        rows={3}
        required
        inputProps={{
          maxLength: maxLengthFields.justification,
        }}
        InputProps={{
          readOnly: isReadOnlyMode,
        }}
        disabled={isSubmitting}
      />
      {(isAntecipationJustificationRequired ||
        isReadOnlyMode ||
        !isDeadlineAdditive) && (
        <TextFieldFormik
          name="anticipationJustification"
          label={t("additives.keywords.fields.anticipationJustification")}
          multiline
          rows={3}
          required
          inputProps={{
            maxLength: maxLengthFields.anticipationJustification,
          }}
          InputProps={{
            readOnly: isReadOnlyMode,
          }}
          disabled={isSubmitting}
        />
      )}
      {createdBy && (
        <Typography variant="h6" color="red">
          <Trans
            i18nKey="additives.keywords.fields.includedBy"
            values={{ name: createdBy ?? "-" }}
            components={{ italic: <em /> }}
          />
        </Typography>
      )}
    </StyledForm>
  );
};
