import { Edit as EditIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../types/core/typescript.types";
import { SimpleIconButton, SimpleIconButtonProps } from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const EditIconButton = ({
  tooltipText = "general.actions.general.edit",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={EditIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
