import * as yup from "yup";

import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { StyledButtonContainer } from "./index.styles";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { OnClickContentButtonComponentFunction } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import {
  AllocationMapEditingFormValues,
  FormMode,
  LabelType,
} from "../../shared/types/form.types";
import { CommonFormEditingCell } from "./CommonFormEditingCell";
import { LabelTypeTranslationCode } from "../../shared/maps/LabelTypeTranslationCode";
import { UpdateAllocationMapDTO } from "../../../../../../../shared/specific/DTOs/allocationMap/UpdateAllocationMapDTO";
import { updateAllocationMap } from "../../../../../../../services/allocationMap/allocationMap.service";
import { AllocationMapContext } from "../shared/context";

interface OwnProps {
  reloadTablePage: () => void;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  name: string;
  formMode: FormMode;
  labelType?: LabelType;
  allocationMapObservation?: string;
  vacationForcast?: string;
  customerOfficeAllocation?: boolean;
  idUserCollaborator: number;
}

interface InternalFormData {
  idUserCollaborator: number;
  name: string;
  formMode: FormMode;
}

export const useEditingCellForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { data, setData } = useContext(AllocationMapContext);

  const formikConfig = useMemo(() => {
    const initialValues: AllocationMapEditingFormValues = {
      customerOfficeAllocation: null,
      labelType: {
        id: 6,
        label: "",
      },
      allocationMapObservation: "",
      vacationForcast: "",
    };

    const validationSchema = yup.object({
      customerOfficeAllocation: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      labelType: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      allocationMapObservation: yup.string(),
      vacationForcast: yup.string(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      AllocationMapEditingFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: { name, formMode, idUserCollaborator },
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
    }) => {
      const vacationForcastContext = data.find(
        (e) => e.idUserCollaborator === idUserCollaborator
      );

      setFormValues({
        labelType: {
          id: vacationForcastContext?.labelType ?? LabelType.None, // TODO: verificar
          label: vacationForcastContext?.labelType
            ? t(LabelTypeTranslationCode[vacationForcastContext?.labelType])
            : "",
        },
        customerOfficeAllocation: {
          id: vacationForcastContext?.customerOfficeAllocation ?? false,
          label: vacationForcastContext?.customerOfficeAllocation
            ? t("general.keywords.general.yes")
            : t("general.keywords.general.no"),
        },
        allocationMapObservation:
          vacationForcastContext?.allocationMapObservation ?? "",
        vacationForcast: vacationForcastContext?.vacationForecast ?? "",
      });

      setInternalFormData({
        name,
        formMode,
        idUserCollaborator,
      });

      setIsLoadingModal(false);
    },
    [t, data]
  );

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton({
      modal: {
        ModalTitleMemo: ({ internalFormData }) => {
          const modalTitle = {
            vacationForcast: t(
              "allocationsMap.keywords.general.vocationForecast"
            ),
            allocationMapObservation: t(
              "allocationsMap.keywords.general.observation"
            ),
            customerOfficeAllocation: t(
              "allocationsMap.keywords.general.allocatedToTheClient"
            ),
            labelType: t("allocationsMap.keywords.general.legend"),
          };

          return (
            <span>
              {internalFormData?.formMode
                ? modalTitle[internalFormData.formMode]
                : ""}
            </span>
          );
        },
        keepModalMounted: 1000,
        modalMode: "dialog",
      },
      button: {
        onClickContentButtonComponent,
        createModalButtonContainerMemo: ({
          onClickButton,
          onClickContentButtonComponent,
        }) => {
          return (props) => {
            const { children } = props;
            const onClick = () => {
              onClickButton();
              onClickContentButtonComponent({ props });
            };

            return (
              <StyledButtonContainer onClick={onClick}>
                {children}
              </StyledButtonContainer>
            );
          };
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) =>
          internalFormData && (
            <CommonFormEditingCell {...rest} mode={internalFormData.formMode} />
          ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!internalFormData)
            throw new Error("Missing internal form data on submit.");

          try {
            const allocationMapUpdated: UpdateAllocationMapDTO = {
              allocationMapLabelType: formValues.labelType.id,
              customerOfficeAllocation:
                formValues.customerOfficeAllocation?.id ?? false,
              allocationMapObservation:
                formValues.allocationMapObservation || null,
              vacationForecast: formValues.vacationForcast || null,
              idUserCollaborator: internalFormData.idUserCollaborator,
            };

            const dataUpdated = await updateAllocationMap(allocationMapUpdated);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );

            const newData = data.map((allocationMap) => {
              if (
                allocationMap.idUserCollaborator ===
                internalFormData.idUserCollaborator
              ) {
                return {
                  ...allocationMap,
                  allocationMapObservation:
                    formValues.allocationMapObservation || null,
                  vacationForecast: formValues.vacationForcast || null,
                  customerOfficeAllocation:
                    formValues.customerOfficeAllocation?.id ?? false,
                  labelType: dataUpdated.labelType,
                  labelColorType: dataUpdated.labelColorType,
                };
              }
              return allocationMap;
            });

            setData(newData);
            setSubmitting(false);
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "allocations.errors.data.update.failedToUpdateAllocationsGroup",
            });
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
