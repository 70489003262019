import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  ProjectDropdownDTO,
  schemaProjectDropdownDTO,
} from "./ProjectDropdownDTO";

export type PaginatedProjectDropdownDTO =
  PaginationResponse<ProjectDropdownDTO>;

export const schemaPaginatedProjectDropdownDTO =
  getSchemaPaginationResponse<ProjectDropdownDTO>(
    schemaProjectDropdownDTO as yup.Schema<ProjectDropdownDTO>
  );
