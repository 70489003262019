import { Avatar, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";
import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { LoggedLayoutContext } from "../../shared/LoggedLayoutContext";
import { AvatarListItem, ListAreaSkeleton } from "./index.styles";
import { MenuTooltip } from "../index.styles";
import { formatNameWithOnlyOneFamilyName } from "../../../../../shared/common/helpers/user.helpers";

export const MobileListItemButton = () => {
  const { isMenuOpen } = useContext(LoggedLayoutContext);
  const userData = useSelector((state: RootState) => state.user.userData);

  const userFirstLastName = useMemo(() => {
    return formatNameWithOnlyOneFamilyName(userData?.userFullName ?? "");
  }, [userData?.userFullName]);

  if (!userData)
    return (
      <ListAreaSkeleton open={isMenuOpen}>
        <div>
          <Skeleton className="scheleton-icon" variant="circular" />
        </div>
        <Skeleton className="scheleton-text" variant="text" width={120} />
      </ListAreaSkeleton>
    );

  return (
    <MenuTooltip title={isMenuOpen ? "" : userFirstLastName}>
      <AvatarListItem>
        <ListItemAvatar>
          <Avatar src={userData.avatarUrl} />
        </ListItemAvatar>
        <ListItemText>{userFirstLastName}</ListItemText>
      </AvatarListItem>
    </MenuTooltip>
  );
};
