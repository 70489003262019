import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TurnOffDataFormValues } from "../../../../../types/data/form/values.types";

export const useTurnOffFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: TurnOffDataFormValues = useMemo(() => {
    return {
      resignationDate: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      resignationDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return {
      initialValues,
      validationSchema,
    };
  }, [initialValues, validationSchema]);
};
