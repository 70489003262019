import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CarouselContextProvider } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext/CarouselContextProvider";
import { CenteredContent } from "../../../../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useProjectContext } from "../../../../../../shared/react/contexts/ProjectContext";
import { CalcStartCaroussel } from "../../../../../../shared/react/utils/CalcStartCaroussel";

interface OwnProps {
  children: ReactNode;
}

export const AllocationsCarouselContextProvider = ({ children }: OwnProps) => {
  const { t } = useTranslation();

  const { project, isLoadingProject, projectHasError, projectErrorStatusCode } =
    useProjectContext();

  const earliestYearMonth = useMemo(() => {
    if (!project?.scheduledStartDate) return null;
    return YearMonth.createFromDate(project.scheduledStartDate.toDate());
  }, [project?.scheduledStartDate]);

  const latestYearMonth = useMemo(() => {
    if (!project?.finalScheduledEndDate) return null;
    return YearMonth.createFromDate(project.finalScheduledEndDate.toDate());
  }, [project?.finalScheduledEndDate]);

  const positionStart = useMemo(() => {
    if (earliestYearMonth && latestYearMonth) {
      const position = CalcStartCaroussel(earliestYearMonth, latestYearMonth);
      return position;
    }
  }, [earliestYearMonth, latestYearMonth]);

  if (isLoadingProject) return <CenteredLoading />;
  if (projectHasError)
    return (
      <CenteredContent>
        {projectErrorStatusCode === 403
          ? t("general.errors.authorization.unauthorizedAccessContent")
          : t("allocations.errors.data.fetch.failedLoadProjectAllocationsInfo")}
      </CenteredContent>
    );

  return (
    <CarouselContextProvider
      initialEarliestYearMonth={earliestYearMonth}
      initialLatestYearMonth={latestYearMonth}
      firstYearMonth={positionStart}
    >
      {children}
    </CarouselContextProvider>
  );
};
