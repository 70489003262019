import * as yup from "yup";

export interface InvoiceSummaryDTO {
  totalInvoiceAmount: number;
  totalDeprovisionAmount: number;
  projectBudget: number;
  numberInvoices: number;
  projectIncome: number;
  totalPercentage: number;
  totalInProgressOfCancellation: number;
}

export const schemaInvoiceSummaryDTO = yup.object({
  totalInvoiceAmount: yup.number().defined(),
  totalDeprovisionAmount: yup.number().defined(),
  projectBudget: yup.number().defined(),
  numberInvoices: yup.number().integer().defined(),
  projectIncome: yup.number().defined(),
  totalPercentage: yup.number().defined(),
  totalInProgressOfCancellation: yup.number().defined(),
});
