export enum ReportOption {
  PerformedTimesheet,
  GlobalAllocation,
  ProjectTimesheet,
  RevenueAndBilling,
  EapReport,
  ProjectsOverview,
  DatailedProjectsOverview,
  StatusOfProjects,
  CreationProjectsAdditives,
  ProjectMVs,
  WeeklyAllocation,
  ProvisionReport,
  BaseCollaborators,
  PepElement,
  BudgetOverview,
  PlannedAndPerformedByPepReport,
  BillingVision,
  CostMdo,
  Zah,
  TurnoverDays,
  MvWithFinancialCost,
}
