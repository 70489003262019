import * as yup from "yup";
import {
  FeeYearMonthDTO,
  schemaFeeYearMonthDTO,
} from "../feeYearMonth/FeeYearMonthDTO";
import {
  CalculatedFeeYearMonthDTO,
  schemaCalculatedFeeYearMonthDTO,
} from "./CalculatedFeeYarMonthDTO";

export interface CalculatedFeeDTO {
  fees: FeeYearMonthDTO[];
  calculatedFees: CalculatedFeeYearMonthDTO[];
}

export const schemaCalculatedFeeDTO = yup.object({
  fees: yup.array(schemaFeeYearMonthDTO).defined(),
  calculatedFees: yup.array(schemaCalculatedFeeYearMonthDTO).defined(),
});
