import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DivisionsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { FilterValues } from "../shared/types/general.types";
import { StyledForm } from "./index.styles";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { SkillsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skills/SkillsAutocompleteFormik";
import { PipedriveServiceLinesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/PipedriveServiceLinesAutocompleteFormik";
import { KeywordsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/KeywordsAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (ownProps: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TextFieldFormik name="code" label={t("general.keywords.fields.code")} />
      <TextFieldFormik
        name="customerName"
        label={t("customers.keywords.fields.customerName")}
      />
      <DivisionsAutocompleteFormik name="division" />
      <UsersSearchAutocompleteFormik
        name="IdsUsersManagers"
        textfieldProps={{
          label: t("projects.keywords.fields.manager"),
        }}
        autocompleteProps={{
          multiple: true,
        }}
      />
      <SkillsAutocompleteFormik
        name="skills"
        textfieldProps={{
          label: t("projects.keywords.fields.technologiesUsed"),
        }}
        autocompleteProps={{
          multiple: true,
        }}
      />
      <PipedriveServiceLinesAutocompleteFormik
        name="pipedriveServiceLine"
        textfieldProps={{
          label: t("projects.keywords.fields.portfolio"),
        }}
        autocompleteProps={{
          multiple: true,
        }}
      />
      <KeywordsAutocompleteFormik
        name="keywords"
        textfieldProps={{
          label: t("projects.keywords.fields.keywords"),
        }}
        autocompleteProps={{
          multiple: true,
        }}
      />
    </StyledForm>
  );
};
