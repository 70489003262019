import { InputAdornment } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpenseType } from "../../../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { FormValues } from "../types/form.types";
import { DescriptionAutoCompleteFormik } from "./components/DescriptionAutoCompleteFormik";
import { StyledForm } from "./index.styles";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  costCenterPep: CostCenterPepOnlyDTO;
  expenseType: ExpenseType;
};

export const CommonExpenseForm = ({
  formikProps: { isSubmitting },
  costCenterPep,
  expenseType,
}: OwnProps) => {
  const { t } = useTranslation();

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {costCenterPep.currencySymbol}
      </InputAdornment>
    );
  }, [costCenterPep.currencySymbol]);

  return (
    <StyledForm>
      <LocalizedNumberTextFieldFormik
        name="amount"
        scale={2}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("general.keywords.fields.value"),
          disabled: isSubmitting,
          required: true,
          InputProps: {
            startAdornment: currencyStartAdornment,
          },
        }}
      />
      {![ExpenseType.Workforce, ExpenseType.Additional].includes(
        expenseType
      ) ? (
        <TextFieldFormik
          name="description"
          label={t("general.keywords.fields.description")}
          multiline
          rows={1}
          required
          disabled={isSubmitting}
          inputProps={{
            maxLength: 200,
          }}
        />
      ) : (
        <DescriptionAutoCompleteFormik
          name="additionalDescription"
          textfieldProps={{ required: true, disabled: isSubmitting }}
        />
      )}

      <TextFieldFormik
        name="observation"
        label={t("allocationsMap.keywords.general.observation")}
        multiline
        rows={1}
        required
        disabled={isSubmitting}
        inputProps={{
          maxLength: 200,
        }}
      />
    </StyledForm>
  );
};
