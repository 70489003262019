import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface InvoiceCompetenceDTO {
  id: number;
  yearMonth: YearMonth;
}

export const schemaInvoiceCompetenceDTO = yup.object({
  id: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined(),
});
