export enum IntervalType {
  Day,
  Week,
  Month,
}

export enum ActivityAmountOption {
  FixedByActivity,
  MaxBasedProjectSize,
}
