import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import {
  getCorporateSegmentById,
  updateCorporateSegment,
} from "../../../../../services/corporateSegments/corporateSegments.service";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { CorporateSegmentsDTO } from "../../../../../shared/specific/DTOs/corporateSegment/CorporateSegmentsDTO";
import { CorporateSegmentsUpdateDTO } from "../../../../../shared/specific/DTOs/corporateSegment/CorporateSegmentsUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { CommonSegmentCorporateForm } from "../shared/react/components/CommonDisciplineForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      FormValues,
      CorporateSegmentsDTO,
      CorporateSegmentsDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: corporateSegment }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: corporateSegment?.name ?? "-",
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: corporateSegment,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const corporateSegmentData = await getCorporateSegmentById(
              corporateSegment.id
            );

            if (!corporateSegmentData)
              return setModalError(
                t(
                  "corporateSegment.errors.data.general.corporateSegmentDoesNotExist"
                )
              );

            setInternalFormData(corporateSegmentData);
            setFormValues({
              costCenter: corporateSegmentData.costCenter,
              allocationType: {
                id: corporateSegmentData.allocationType,
                label: t(
                  employeeAllocationTypeToTranslationCodeMap[
                    corporateSegmentData.allocationType
                  ]
                ),
              },
              description: corporateSegmentData.name,
              deactivatedDate: corporateSegmentData.deactivatedDate,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "corporateSegment.errors.data.fetch.failedToFetchcorporateSegment",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonSegmentCorporateForm {...rest} mode="editing" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedCorporateSegment: CorporateSegmentsUpdateDTO = {
              id: internalFormData?.id ?? 0,
              name: formValues.description,
              costCenterId: formValues.costCenter?.id ?? undefined,
              allocationType: formValues.allocationType.id,
              deactivatedDate: formValues.deactivatedDate,
              version: internalFormData?.version ?? "",
            };

            await updateCorporateSegment(updatedCorporateSegment);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "corporateSegment.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
