import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AllocationEditingFormValues } from "../../../../types/data/form/values.types";
import { YearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { checkDoesWeekExist } from "../../../../../../../../../../../../shared/common/helpers/data/dates/checkers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): AllocationEditingFormValues => {
    return {
      yearMonth: YearMonth.createFromNow(),
      week1Percentage: "",
      week2Percentage: "",
      week3Percentage: "",
      week4Percentage: "",
      week5Percentage: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      week1Percentage: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      week2Percentage: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      week3Percentage: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      week4Percentage: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      week5Percentage: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("yearMonth", (yearMonth, schema) => {
          const yearMonthValue = yearMonth[0] as unknown as YearMonth;
          if (!checkDoesWeekExist(5, yearMonthValue)) return schema;
          return schema.required(
            t("general.errors.data.fields.general.required")
          );
        }),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
