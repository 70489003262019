import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { SkillCategoryDTO } from "../../../../../../../shared/specific/DTOs/skillCategory/SkillCategoryDTO";
import { SkillCategoryDataFormValues } from "../shared/types/form.types";
import {
  getSkillCategoryById,
  updateSkillCategory,
} from "../../../../../../../services/skillCategory/skillCategory.service";
import { SkillCategoryDataForm } from "../components/SkillCategoryDataForm";
import { SkillCategoryUpdateDTO } from "../../../../../../../shared/specific/DTOs/skillCategory/SkillCategoryUpdateDTO";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EditingButtonContainer: (props: SkillCategoryDTO) => JSX.Element | null;
  editingModal: JSX.Element | null;
};

export const useEditingForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();

  const { initialValues, validationSchema } =
    useCreationEditingDefaultFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      SkillCategoryDataFormValues,
      SkillCategoryDTO,
      SkillCategoryDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: skillCategory }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: skillCategory?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: skillCategoryRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const skillCategoryDTO = await getSkillCategoryById(
              skillCategoryRow.id
            );
            if (!skillCategoryDTO)
              return setModalError(
                i18next.t(
                  "skillCategory.errors.data.general.skillCategoryDoesNotExist"
                )
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(skillCategoryDTO);
            setFormValues({
              namePt:
                skillCategoryDTO.skillCategoryTranslation.find(
                  (x) => x.idLanguage === Language.ptBR
                )?.name ?? "",
              nameEn:
                skillCategoryDTO.skillCategoryTranslation.find(
                  (x) => x.idLanguage === Language.enUS
                )?.name ?? "",
              discipline: skillCategoryDTO.discipline,
              deactivatedDate: skillCategoryDTO.deactivatedDate,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "skillCategory.errors.data.fetch.failedToFetchSkillCategory",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig: {
          initialValues,
          validationSchema,
        },
        FormContentMemo: (props) => (
          <SkillCategoryDataForm {...props} mode="editing" />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: skillCategory,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!skillCategory)
            throw new Error("Skill Category cannot be null on submission.");

          if (!formValues.namePt)
            throw new Error("Required values are missing.");

          try {
            await updateSkillCategory(
              convertTo<SkillCategoryUpdateDTO>({
                id: skillCategory.id,
                name: formValues.namePt,
                idDiscipline: formValues.discipline?.id ?? 0,
                deactivatedDate: formValues.deactivatedDate,
                version: skillCategory.version,
                skillCategoryTranslation: [
                  {
                    idLanguage: Language.ptBR,
                    name: formValues.namePt,
                  },
                  {
                    idLanguage: Language.enUS,
                    name: formValues.nameEn,
                  },
                ],
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (skillCategory?.name)
              return notifyError(
                <Trans
                  i18nKey="skillCategory.errors.data.edit.failedToEditName"
                  values={{ name: skillCategory?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("skillCategory.errors.data.edit.failedToEditName"));
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData: null as SkillCategoryDTO | null,
      },
    });

  return { EditingButtonContainer, editingModal };
};
