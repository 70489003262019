import * as yup from "yup";
import { riseApi } from "./connection";
import {
  DashboardGroupDTO,
  schemaDashboardGroupDTO,
} from "../../../shared/specific/DTOs/dashboardGroups/DashboardGroupDTO";
import { DashboardGroupCreateDTO } from "../../../shared/specific/DTOs/dashboardGroups/DashboardGroupCreateDTO";
import { DashboardGroupUpdateDTO } from "../../../shared/specific/DTOs/dashboardGroups/DashboardGroupUpdateDTO";

export const getDashBoardGroup = async (): Promise<DashboardGroupDTO[]> => {
  const { data: dashboardGroups } = await riseApi.get("/DashboardGroups");

  return yup
    .array(schemaDashboardGroupDTO)
    .defined()
    .validateSync(dashboardGroups);
};

export const getDashboardGroupById = async (
  id: number
): Promise<DashboardGroupDTO | null> => {
  const { data: dashboardGroup } = await riseApi.get(`/DashboardGroups/${id}`);

  return schemaDashboardGroupDTO.nullable().validateSync(dashboardGroup);
};

export const createDashboardGroup = async (
  dashboardGroup: DashboardGroupCreateDTO
) => {
  await riseApi.post("/DashboardGroups", dashboardGroup);
};

export const updateDashboardGroup = async (
  dashboardGroup: DashboardGroupUpdateDTO
) => {
  await riseApi.put("/DashboardGroups", dashboardGroup);
};

export const deleteDashboardGroup = async (id: number, version: string) => {
  await riseApi.delete(`/DashboardGroups/${id}/${version}`);
};
