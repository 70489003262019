import { UserPermissionDTO } from "../../../../DTOs/user/UserPermissionDTO";
import { PermissionType } from "../../../../enums/users/permissions/PermissionType.enum";
import { PermissionData } from "../../../../types/user/permissions.types";

export type RestrictionsOperand =
  | PermissionType
  | PermissionData
  | AbstractPermissionsOperator;

export abstract class AbstractPermissionsOperator {
  public abstract checkPermissions({
    userPermissions,
  }: {
    userPermissions: UserPermissionDTO[];
  }): boolean;

  public static checkPermission({
    permission,
    isAdmin,
    userPermissions,
  }: {
    permission: RestrictionsOperand;
    isAdmin: boolean;
    userPermissions: UserPermissionDTO[];
  }) {
    if (isAdmin) return true;

    if (permission instanceof AbstractPermissionsOperator) {
      return permission.checkPermissions({ userPermissions });
    }

    const isPermissionData =
      AbstractPermissionsOperator.checkIsPermissionData(permission);

    const permissionData = isPermissionData ? permission : { type: permission };

    return AbstractPermissionsOperator.checkPermissionEqualsUserPermission({
      permission: permissionData,
      userPermissions,
    });
  }

  public static checkPermissionEqualsUserPermission({
    userPermissions,
    permission,
  }: {
    userPermissions: UserPermissionDTO[];
    permission: PermissionData;
  }) {
    return userPermissions.some(
      (userPermission) =>
        permission.type === userPermission.type &&
        (permission.level ?? null) === userPermission.level
    );
  }

  protected static checkIsPermissionData(
    value: PermissionType | PermissionData
  ): value is PermissionData {
    return value.hasOwnProperty("type");
  }
}
