import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getDropdownCorporateSegments } from "../../../../../../../../../services/corporateSegments/corporateSegments.service";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CorporateSegmentsDTO } from "../../../../../../../DTOs/corporateSegment/CorporateSegmentsDTO";
import { CorporateSegmentsFilterDTO } from "../../../../../../../DTOs/corporateSegment/filters/CorporateSegmentsFilterDTO";
import { usePermissionChecker } from "../../../../../../hooks/data/user/permissions/usePermissionChecker";
import { PermissionType } from "../../../../../../../enums/users/permissions/PermissionType.enum";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    CorporateSegmentsDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: Omit<CorporateSegmentsFilterDTO, "name">;
}

export const CorporateSegmentsAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const { hasPermission: canAccessCalamityCorporateSegment } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessCalamityCorporateSegment,
      },
    });

  const { hasPermission: canAccessHECompensationCorporateSegment } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessHECompensationCorporateSegment,
      },
    });

  const { hasPermission: canAccessInefficiencyCorporateSegment } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessInefficiencyCorporateSegment,
      },
    });

  const getData = useCallback(async () => {
    const corporateSegments = await getDropdownCorporateSegments({
      filters: {
        onlyActivated: true,
        ...filters,
      },
      pagination: {
        pageSize: 100,
      },
    });

    let data = corporateSegments.tableData;

    if (!canAccessCalamityCorporateSegment) {
      const corporateSegmentCalamityName = "Situação especial (Calamidade)";
      data = data.filter((x) => x.name !== corporateSegmentCalamityName);
    }

    if (!canAccessHECompensationCorporateSegment) {
      const corporateSegmentHECompensationName = "Compensação HE - Projetos";
      data = data.filter((x) => x.name !== corporateSegmentHECompensationName);
    }

    if (!canAccessInefficiencyCorporateSegment) {
      const corporateSegmentInefficiencyName = "Ineficiência";
      data = data.filter((x) => x.name !== corporateSegmentInefficiencyName);
    }

    return data;
  }, [filters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "corporateSegment.errors.data.fetch.failedToFetchcorporateSegments"
      )}
      fetchOptions={getData}
      textfieldProps={{
        label: t("corporateSegment.keywords.general.corporateSegments"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
