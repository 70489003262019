import * as yup from "yup";

export interface ActivitySummaryDTO {
  totalActivityAmount: number;
  totalDeprovisionAmount: number;
  projectAmount: number;
  projectAmountWithAdditives: number;
  numberActivities: number;
  totalProjectIncome: number;
}

export const schemaActivitySummaryDTO = yup.object({
  totalActivityAmount: yup.number().defined(),
  totalDeprovisionAmount: yup.number().defined(),
  projectAmount: yup.number().defined(),
  projectAmountWithAdditives: yup.number().defined(),
  numberActivities: yup.number().integer().defined(),
  totalProjectIncome: yup.number().defined(),
});
