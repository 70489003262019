import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { ActivityCreateManyDTO } from "../../../../../../../../../../../../shared/specific/DTOs/activity/ActivityCreateManyDTO";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";
import { StyledTotal } from "./index.styles";

interface OwnProps extends Omit<FormContentProps, "internalModalData"> {
  activityCreateManyDTO: ActivityCreateManyDTO;
  totalActivitiesAmount: number;
}

export const ViewCreateManyForm = ({
  activityCreateManyDTO,
  totalActivitiesAmount,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const { costCenterPep } = useProjectContext();

  const headCells = useMemo((): ExternalEnhancedHeadCell[] => {
    return [
      t("activities.keywords.fields.activityDescription"),
      t("activities.keywords.fields.scheduledAt"),
      t("general.keywords.fields.value"),
    ];
  }, [t]);

  const rows = useMemo(() => {
    const rows = activityCreateManyDTO.activities.map(
      (x): ExternalEnhancedRow => [
        x.description,
        {
          value: x.scheduledDate.toDate(),
          displayValue: x.scheduledDate
            .toDate()
            .toLocaleDateString(i18n.language),
        },
        {
          value: x.amount,
          displayValue: formatCurrency({
            value: x.amount,
            currencySymbol: costCenterPep.currencySymbol,
          }),
        },
      ]
    );

    rows.push([
      {},
      {
        CellRenderer: () => (
          <StyledTotal>{t("general.keywords.fields.total")}</StyledTotal>
        ),
      },
      {
        displayValue: formatCurrency({
          value: totalActivitiesAmount,
          currencySymbol: costCenterPep.currencySymbol,
        }),
      },
    ]);

    return rows;
  }, [activityCreateManyDTO, i18n.language, totalActivitiesAmount]);

  return (
    <EnhancedTable
      headCells={headCells}
      rows={rows}
      uiSettings={{
        stickyHeader: true,
      }}
      orderDefaults={{
        defaultOrderBy: "none",
      }}
    />
  );
};
