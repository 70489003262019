import { YearMonth } from "../../../../common/classes/data/date/YearMonth";
import { ApiErrorDTO } from "../../../DTOs/errors/ApiErrorDTO";
import {
  Allocations,
  CodeErrorEnums,
  CorporateAllocations,
  ProjectSummary,
  Activities,
} from "../../../enums/errors/apiErrors.enums";
import { TranslationObject } from "../../../types/translations/general.types";
import {
  sharedActivitiesCannotDeleteWhenHasAllocationInThisPeriod,
  sharedCollaboratorCannotAllocateAfterResignation,
  sharedCollaboratorHistoryNoHistoryForPeriod,
  sharedMustHaveSomeActivityInTheSamePeriodOfAllocation,
  sharedPercentageWouldBecomeGreaterThanMonthlyLimit,
} from "./shared.helpers";

export const apiErrorCodeToTranslationHandler: {
  readonly [key in CodeErrorEnums]?: (
    ApiError: ApiErrorDTO
  ) => TranslationObject | null;
} = {
  [Allocations.AllocationsCannotEditWhenHasNotActivities]:
    sharedMustHaveSomeActivityInTheSamePeriodOfAllocation,
  [Allocations.CollaboratorHistoryNoHistoryForPeriod]:
    sharedCollaboratorHistoryNoHistoryForPeriod,
  [Allocations.CollaboratorCannotAllocateAfterResignation]: (apiError) =>
    sharedCollaboratorCannotAllocateAfterResignation(
      apiError,
      "allocations.errors.data.fields.yearMonth.cannotAfterColaboratorResignation"
    ),
  [Allocations.PercentageWouldBecomeGreaterThanMonthlyLimit]:
    sharedPercentageWouldBecomeGreaterThanMonthlyLimit,
  [ProjectSummary.BrlParityDoesNotExist]: (apiError) => {
    if (
      typeof apiError.details.from !== "string" ||
      typeof apiError.details.to !== "string" ||
      typeof apiError.details.yearMonth !== "string"
    )
      return { code: "planningSummary.errors.data.general.parityDoesNotExist" };

    const { from, to } = apiError.details;
    const yearMonth = YearMonth.createFromString(apiError.details.yearMonth);

    return {
      code: "planningSummary.errors.data.general.parityDoesNotExistMonthFromTo",
      details: { from, to, yearMonth: yearMonth.toPrettyString() },
    };
  },
  [CorporateAllocations.CollaboratorEntryDoesNotExistForYearMonth]: (
    apiError
  ) => {
    if (typeof apiError.details.yearMonth !== "string") return null;
    const yearMonth = YearMonth.createFromString(apiError.details.yearMonth);

    const collaboratorName = apiError.details.userCollaboratorName || "?";

    return {
      code: "corporateAllocations.errors.data.fields.collaboratorHistoryEntry.doesNotExistForYearMonth",
      details: { yearMonth: yearMonth.toPrettyString(), collaboratorName },
    };
  },
  [CorporateAllocations.CollaboratorCannotAllocateAfterResignation]: (
    apiError
  ) =>
    sharedCollaboratorCannotAllocateAfterResignation(
      apiError,
      "corporateAllocations.errors.data.fields.yearMonth.cannotAfterColaboratorResignation"
    ),
  [CorporateAllocations.CollaboratorHistoryNoHistoryForPeriod]:
    sharedCollaboratorHistoryNoHistoryForPeriod,
  [CorporateAllocations.PercentageWouldBecomeGreaterThanMonthlyLimit]:
    sharedPercentageWouldBecomeGreaterThanMonthlyLimit,
  [Activities.ActivitiesCannotDeleteWhenHasAllocationInThisPeriod]:
    sharedActivitiesCannotDeleteWhenHasAllocationInThisPeriod,
};
