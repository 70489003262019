import { InputAdornment, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { MaskedTextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { TextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { ContractTypeAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ContractTypeAutocompleteFormik";
import {
  StyledControllership,
  StyledFormSection,
  StyledFormSectionsContainer,
  StyledProjectDatesBaseline,
  StyledProjectInfo,
} from "./index.styles";
import { CommonProjectFormValues } from "../../shared/types/forms/general.types";
import {
  planningFieldsConstants,
  projectsMaxLengthFields,
} from "../../../../../../constants/data/form/constraints.constants";

import { ProjectStatus } from "../../../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { CostCenterPepsSection } from "./CostCenterPepsSection";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { generalFieldsConstants } from "../../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { formatNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { RootState } from "../../../../../../../../../../../store";
import { CustomersSearchAutocompleteDealsFormik } from "./CustomersSearcAutocompleteDealsFormik/CustomersSearchAutocompleteFormik";
import { DivisionsAutocompleteDealsFormik } from "./DivisionSearcAutocompleteDealsFormik/DivisionSearcAutocompleteDealsFormik";
import { ProductServicesAutocompleteDealsFormik } from "./ProductServicesAutocompleteDealsFormik/ProductServicesAutocompleteDealsFormik";
import { UserManagerSearchAutocompleteDealsFormik } from "./UserManagerSearcAutocompleteDealsFormik/UserManagerSearchAutocompleteFormik";
import { SegmentsAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentsAutocompleteFormik";
import { TypeOfEvidenceAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/TypeOfEvidenceAutocompleteFormik";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";
import { PermissionType } from "../../../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { usePermissionChecker } from "../../../../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { FramesSearchAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/frames/FramesSearchAutocompleteFormik";
import { FormContext } from "../../shared/contexts/FormContext";

interface OwnProps {
  values: CommonProjectFormValues;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const InternalFormContent = ({ values, setFieldValue }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const { mode, projectData } = useContext(FormContext);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [initialValues, setInitialValues] =
    useState<CommonProjectFormValues | null>(null);

  const userData = useSelector((state: RootState) => state.user.userData);
  const admCanEdit = userData?.isAdmin;

  const { hasPermission: hasPermissionCanEditTypeOfEvidences } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanEditOngoingProjectTypeOfEvidence,
      },
    });

  const { hasPermission: hasPermissionCanEditApprovedCredit } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanEditApprovedCredit,
      },
    });

  const disabledApprovedCredit = useMemo(() => {
    if (mode !== "editing" || !projectData) return false;

    const isProjectAfterCreatingStage = [
      ProjectStatus.Ongoing,
      ProjectStatus.Planning,
    ].includes(projectData.status);

    return (
      isProjectAfterCreatingStage ||
      (!hasPermissionCanEditApprovedCredit && !admCanEdit)
    );
  }, [projectData, mode]);

  const disabledIsInvestmentPorject = useMemo(() => {
    if (mode !== "editing" || !projectData) return false;

    const isCreateStage = [ProjectStatus.Created].includes(projectData.status);

    return !isCreateStage;
  }, [projectData, mode]);

  const canEditTypeOfEvidence =
    projectData?.status === ProjectStatus.Ongoing &&
    hasPermissionCanEditTypeOfEvidences;

  const billingAmountStartAdornment = useMemo(() => {
    if (!values.subsidiary) return null;

    return (
      <InputAdornment position="start">
        {values.subsidiary.currencySymbol}
      </InputAdornment>
    );
  }, [values.subsidiary?.currencySymbol]);

  const disabledOnEditing = mode === "editing";
  const disabledOnDealCreation = mode === "dealCreation" || disabledOnEditing;
  const disabledCustumer = useMemo(() => {
    if (disabledOnEditing && projectData === null) return true;

    return (
      disabledOnEditing &&
      projectData?.status !== ProjectStatus.Created &&
      !admCanEdit
    );
  }, [projectData, mode]);

  const disabledEditingAfterPlanning = useMemo(() => {
    if (mode !== "editing" || !projectData) return false;

    const isProjectPastPlanningStage = ![
      ProjectStatus.Created,
      ProjectStatus.Planning,
    ].includes(projectData.status);

    return isProjectPastPlanningStage;
  }, [projectData, mode]);

  const isProjectFinalized =
    projectData === null
      ? false
      : projectData.status === ProjectStatus.Finalized;

  const generalReadOnly = mode === "viewing";

  const isSubsidiaryDisabled = useMemo(() => {
    if (generalReadOnly) return true;
    if (!projectData?.status) return false;
    return projectData.status !== ProjectStatus.Created;
  }, [projectData?.status, generalReadOnly]);

  useEffect(() => {
    if (values) setInitialValues(values);
  }, [values.billingAmount]);
  useEffect(() => {
    const changed =
      JSON.stringify(Object.values(values)) !==
      JSON.stringify(Object.values(initialValues || []));
    setHasChanges(changed);
  }, [values]);

  useEffect(() => {
    if (
      values.segment &&
      values.division &&
      values.segment.division.id !== values.division?.id
    )
      setFieldValue("segment", null);
  }, [values.division]);

  useEffect(() => {
    if (values.segment && !values.division)
      setFieldValue("division", values.segment.division);
  }, [values.segment]);

  return (
    <StyledFormSectionsContainer>
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("projects.keywords.fields.projectInformation")}
        </Typography>
        <StyledProjectInfo>
          <TextFieldFormik
            name="name"
            label={t("general.keywords.fields.name")}
            required
            inputProps={{
              maxLength: projectsMaxLengthFields.name,
            }}
            InputProps={{ readOnly: generalReadOnly }}
            disabled={disabledEditingAfterPlanning && !admCanEdit}
          />
          <MaskedTextFieldFormik
            name="code"
            mask={/^[-—0-9a-zA-ZÀ-ÖØ-öø-ÿ_]+$/}
            textFieldProps={{
              label: t("general.keywords.fields.code"),
              required: true,
              inputProps: {
                maxLength: projectsMaxLengthFields.code,
              },
              InputProps: {
                readOnly: generalReadOnly,
              },
              disabled: disabledEditingAfterPlanning && !admCanEdit,
            }}
          />
          {generalReadOnly ? (
            <TextField
              label={t("projects.keywords.fields.manager")}
              value={values.userManager?.name ?? "-"}
              InputProps={{ readOnly: true }}
              required
            />
          ) : (
            <UserManagerSearchAutocompleteDealsFormik
              name="userManager"
              textfieldProps={{
                label: t("projects.keywords.fields.manager"),
                required: true,
              }}
              filtersMemo={{ isActive: true }}
              userManagerFromDeal={values.userManagerNameFromDeal}
              UserCollaboratorDTO={values.userManager}
            />
          )}
          {generalReadOnly ? (
            <TextField
              label={t("projects.keywords.fields.coordinator")}
              value={values.userCoordinator?.name ?? "-"}
              InputProps={{ readOnly: true }}
            />
          ) : (
            <UsersSearchAutocompleteFormik
              name="userCoordinator"
              textfieldProps={{
                label: t("projects.keywords.fields.coordinator"),
              }}
              filtersMemo={{ isActive: true }}
            />
          )}
          {generalReadOnly ? (
            <TextField
              label={t("divisions.keywords.general.un")}
              value={values.division?.name ?? "-"}
              InputProps={{ readOnly: true }}
              disabled={disabledOnEditing}
              required
            />
          ) : (
            <DivisionsAutocompleteDealsFormik
              name="division"
              textfieldProps={{
                required: true,
                label: t("divisions.keywords.general.businessUnit"),
              }}
              divisionDTO={values.division}
              divisionFromDeal={values.divisionFromDeal}
            />
          )}
          {generalReadOnly ? (
            <TextField
              label={t("projects.keywords.fields.segment")}
              value={values.segment?.segmentName ?? "-"}
              InputProps={{ readOnly: true }}
              disabled={disabledOnEditing}
            />
          ) : (
            <SegmentsAutocompleteFormik
              name="segment"
              filters={{
                idDivision: values.division?.id ?? undefined,
                divisionHasPrefix: true,
              }}
              textfieldProps={{
                label: t("projects.keywords.fields.segment"),
              }}
            />
          )}
          {generalReadOnly ? (
            <TextField
              label={t("productServices.keywords.general.productService")}
              value={values.productService?.name ?? "-"}
              InputProps={{ readOnly: true }}
              required
            />
          ) : (
            <ProductServicesAutocompleteDealsFormik
              name="productService"
              textfieldProps={{ required: true }}
              productServiceDTO={values.productService}
              productServiceFromDeal={values.productServiceFromDeal}
            />
          )}
          {generalReadOnly || disabledCustumer ? (
            <TextField
              label={t("customers.keywords.general.customer")}
              value={
                values.customer?.registrationCode
                  ? `${values.customer?.tradingName} - ${values.customer?.registrationCode}`
                  : (values.customer?.tradingName ?? "-")
              }
              InputProps={{ readOnly: true }}
              disabled={disabledCustumer}
              required
            />
          ) : (
            <CustomersSearchAutocompleteDealsFormik
              name="customer"
              textfieldProps={{ required: true, disabled: disabledCustumer }}
              customerFromDeal={values.customerNameFromDeal}
              customerDTO={values.customer}
            />
          )}
          <TextFieldFormik
            name="clientAccount"
            label={t("general.keywords.fields.clientAccount")}
            InputProps={{
              readOnly: generalReadOnly,
              disabled: disabledOnDealCreation,
            }}
            inputProps={{
              maxLength: projectsMaxLengthFields.clientAccount,
            }}
            value={values.clientAccount ?? null}
          />
          <TextFieldFormik
            name="description"
            label={t("general.keywords.fields.description")}
            multiline
            rows={3}
            required
            inputProps={{ maxLength: projectsMaxLengthFields.description }}
            InputProps={{ readOnly: generalReadOnly }}
          />
        </StyledProjectInfo>
        <StyledProjectDatesBaseline>
          {mode !== "creation" && (
            <>
              <TextField
                label={t("projects.keywords.fields.baselineStart")}
                value={
                  projectData?.scheduledStartDate
                    ?.toDate()
                    .toLocaleDateString(i18n.language) ?? "-"
                }
                InputProps={{ readOnly: true }}
                disabled={mode === "editing"}
              />
              <TextField
                label={t("projects.keywords.fields.baselineEnd")}
                value={
                  projectData?.scheduledEndDate
                    ?.toDate()
                    .toLocaleDateString(i18n.language) ?? "-"
                }
                InputProps={{ readOnly: true }}
                disabled={mode === "editing"}
              />
              {mode === "editing" ? (
                <>
                  <LocalizedNumberTextFieldFormik
                    name="mv"
                    min={0}
                    max={planningFieldsConstants.maxMvValue}
                    overwrite="shift"
                    padFractionalZeros
                    textFieldProps={{
                      label: t("projects.keywords.fields.commercialMv"),
                      required: true,
                      InputProps: {
                        endAdornment: "%",
                      },
                    }}
                  />
                  <TypeOfEvidenceAutocompleteFormik
                    name="typeOfEvidence"
                    textfieldProps={{
                      label: t(
                        "projects.keywords.fields.revenueTypeOfEvidence"
                      ),
                      required: true,
                    }}
                    autocompleteProps={{
                      disableClearable: true,
                      disabled:
                        disabledEditingAfterPlanning && !canEditTypeOfEvidence,
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label={t("projects.keywords.fields.commercialMv")}
                    value={
                      projectData?.mv
                        ? formatNumber(projectData.mv, {
                            fractionDigits: 2,
                            suffix: "%",
                          })
                        : "-"
                    }
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label={t("projects.keywords.fields.revenueTypeOfEvidence")}
                    value={
                      projectData?.typeOfEvidence
                        ? typeOfEvidenceToTranslationCodeMap[
                            projectData.typeOfEvidence
                          ]
                        : "-"
                    }
                    InputProps={{ readOnly: true }}
                  />
                </>
              )}
            </>
          )}
        </StyledProjectDatesBaseline>
      </StyledFormSection>
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("projects.keywords.fields.controllership")}
        </Typography>
        <StyledControllership>
          {isSubsidiaryDisabled ? (
            <TextField
              label={t("subsidiaries.keywords.general.billingCountry")}
              value={values.subsidiary?.name ?? "-"}
              InputProps={{ readOnly: true }}
              disabled={disabledOnEditing}
              required
            />
          ) : (
            <SubsidiariesAutocompleteFormik
              name="subsidiary"
              textfieldProps={{
                required: true,
                label: t("subsidiaries.keywords.general.billingCountry"),
              }}
            />
          )}
          <ContractTypeAutocompleteFormik
            name="contractType"
            textfieldProps={{
              required: true,
              disabled: isProjectFinalized,
              InputProps: { readOnly: generalReadOnly },
            }}
            autocompleteProps={{
              disabled: isProjectFinalized,
              readOnly: generalReadOnly,
              popupIcon:
                isProjectFinalized || generalReadOnly ? null : undefined,
            }}
          />
          {generalReadOnly ? (
            <TextField
              label={t("frames.keywords.general.frame")}
              value={values.frame?.name ?? "-"}
              InputProps={{ readOnly: true }}
            />
          ) : (
            <FramesSearchAutocompleteFormik name="frame" />
          )}
          <LocalizedNumberTextFieldFormik
            name="daysForPayment"
            min={0}
            max={generalFieldsConstants.maxDaysValue}
            scale={0}
            textFieldProps={{
              label: t("general.keywords.fields.daysForPayment"),
              required: true,
              InputProps: { readOnly: generalReadOnly },
            }}
          />
          <LocalizedNumberTextFieldFormik
            name="billingAmount"
            min={0}
            max={generalFieldsConstants.maxAmountValue}
            overwrite="shift"
            padFractionalZeros
            textFieldProps={{
              label: t("general.keywords.fields.billingAmount"),
              required: true,
              InputProps: {
                startAdornment: billingAmountStartAdornment,
                readOnly: generalReadOnly,
              },
              disabled: disabledEditingAfterPlanning && !admCanEdit,
            }}
          />
          {generalReadOnly || disabledApprovedCredit ? (
            <TextField
              label={t("projects.keywords.fields.approvedCredit")}
              value={
                values.approvedCredit?.id
                  ? t("general.keywords.general.yes")
                  : t("general.keywords.general.no")
              }
              InputProps={{ readOnly: true }}
              disabled={disabledApprovedCredit}
            />
          ) : (
            <YesNoAutocompleteFormik
              name="approvedCredit"
              textfieldProps={{
                label: t("projects.keywords.fields.approvedCredit"),
                required: true,
                disabled: disabledApprovedCredit,
              }}
            />
          )}
          {generalReadOnly || disabledIsInvestmentPorject ? (
            <TextField
              label={t("projects.keywords.fields.investmentProject")}
              value={
                values.isInvestmentProject?.id
                  ? t("general.keywords.general.yes")
                  : t("general.keywords.general.no")
              }
              InputProps={{ readOnly: true }}
              disabled={disabledIsInvestmentPorject}
            />
          ) : (
            <YesNoAutocompleteFormik
              name="isInvestmentProject"
              textfieldProps={{
                label: t("projects.keywords.fields.investmentProject"),
                required: true,
                disabled: disabledIsInvestmentPorject,
              }}
            />
          )}
        </StyledControllership>
      </StyledFormSection>
      {mode !== "creation" && projectData && (
        <CostCenterPepsSection hasChanges={hasChanges} />
      )}
    </StyledFormSectionsContainer>
  );
};
