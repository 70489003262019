/* eslint-disable react/no-this-in-sfc */
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { FormValuesGlobalAllocation } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { generateGlobalAllocationReport } from "../../../../../../../../../services/reports/reports.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";

export const GlobalAllocation = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesGlobalAllocation>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesGlobalAllocation = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      userCollaborators: [],
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      division: yup
        .object()
        .nullable()
        .test({
          name: "teste",
          message: t("reports.errors.validations.periodMustBeLonger"),
          test(value) {
            if (value) return true;

            const endYearMonthDate = new Date(this.parent.endYearMonth);
            const startYearMonthDate = new Date(this.parent.startYearMonth);
            const timeDiff = Math.abs(
              startYearMonthDate.getTime() - endYearMonthDate.getTime()
            );
            const diffMonths = Math.ceil(timeDiff / (1000 * 3600 * 24) / 30);
            return !(diffMonths > 6);
          },
        }),
      userCollaborators: yup.array(yup.object()).defined(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesGlobalAllocation,
    { setSubmitting }: FormikHelpers<FormValuesGlobalAllocation>
  ) => {
    if (!values.startYearMonth || !values.endYearMonth)
      throw new Error("All required fields must be filled.");

    try {
      await generateGlobalAllocationReport({
        startYearMonth: YearMonth.createFromDate(values.startYearMonth),
        endYearMonth: YearMonth.createFromDate(values.endYearMonth),
        divisionName: values?.division?.name ?? undefined,
        idDivision:
          values?.division && typeof values?.division.id === "number"
            ? values.division.id
            : undefined,
        idsUserCollaborators:
          values.userCollaborators.length > 0
            ? values.userCollaborators.map((x) => x.id)
            : undefined,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="startYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.startPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <YearMonthDatePickerFormik
              name="endYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.endPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <DivisionsAutocompleteFormik name="division" />
            <UsersSearchAutocompleteFormik
              name="userCollaborators"
              autocompleteProps={{
                multiple: true,
              }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
          <LoadingBackdrop open={isSubmitting} />
        </StyledViewContent>
      )}
    </Formik>
  );
};
