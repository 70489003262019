export enum SubsidiaryToCountry {
  RDXBR = "RDX.BR",
  RDXUS = "RDX.US",
}

export const subsidiaryToCountryToTranslationCode = (
  subsidiary: string
): string => {
  switch (subsidiary) {
    case SubsidiaryToCountry.RDXBR:
      return "BR";
    case SubsidiaryToCountry.RDXUS:
      return "US";
    default:
      return "-";
  }
};
