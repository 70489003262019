import * as yup from "yup";

export interface FeeYearMonthValueDTO {
  id: number;
  idFeeYearMonth: number;
  value: number;
}

export const schemaFeeYearMonthValueDTO = yup.object({
  id: yup.number().integer().defined(),
  idFeeYearMonth: yup.number().integer().defined(),
  value: yup.number().defined(),
});
