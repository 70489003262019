import { Chip, Paper, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "a b c"
    "d d d";
  ${createGridAreaLettersRange(4)}
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledDropzone = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 450px;
  height: 150px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: #cccccc;
  }

  svg {
    font-size: 50px;
  }
`;

export const StyledSelectedFile = styled("div")`
  border: solid 2px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.palette.primary.light};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledGrayBox = styled(Chip)`
  padding: 0;
  margin: 5px;
  height: 35px;
  span {
    font-size: 15px;
  }
  width: fit-content;
`;

export const StyledLabelChip = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};

  div {
    display: flex;
    width: max-content;
  }

  svg {
    cursor: pointer;
  }
`;
