import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { getDivisions } from "../../../../../../../../../services/division/division.service";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { DivisionDTO } from "../../../../../../../DTOs/division/DivisionDTO";
import { DivisionFiltersDTO } from "../../../../../../../DTOs/division/filters/DivisionFiltersDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    DivisionDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: DivisionFiltersDTO;
  filtersMemo?: DivisionFiltersDTO;
  includeAllDivisionsOption?: boolean;
  blockedDivision?: string[];
}

export const DivisionsAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
  includeAllDivisionsOption = false,
  blockedDivision,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const internalGetDivisions = useCallback(async () => {
    const data = await getDivisions({
      filters: finalFilters,
      includeAllDivisionsOption,
    });

    if (blockedDivision)
      return data.filter((x) => !blockedDivision.includes(x.name));

    return data;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "divisions.errors.data.fetch.failedToFetchDivisions"
      )}
      fetchOptions={internalGetDivisions}
      textfieldProps={{
        label: t("divisions.keywords.general.un"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (division) => division.name ?? String(division.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
