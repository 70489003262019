import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FormValues } from "../../../types/form.types";
import { parseLocaleNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      amount: "",
      costCenter: null,
      customerTrigram: "",
      yearMonth: null,
      type: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      customerTrigram: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      costCenter: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      yearMonth: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.general.date"))
        .required(t("general.errors.data.fields.general.required")),
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      type: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
