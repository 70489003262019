import { SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SelectField } from "../../../../../../../../../shared/common/react/components/form/general/select/SelectField";
import { DEFAULT_CAROUSEL_SIZE } from "../../shared/constants/ui/table/carousel.constants";
import { StyledFooter } from "./index.styles";

interface OwnProps {
  setCarouselSize: (size: number) => void;
  carouselSize: number;
}

export const TableFooter = ({ setCarouselSize, carouselSize }: OwnProps) => {
  const { t } = useTranslation();

  const onChange = (event: SelectChangeEvent<unknown>) => {
    setCarouselSize(
      typeof event.target.value === "number"
        ? event.target.value
        : DEFAULT_CAROUSEL_SIZE
    );
  };

  return (
    <StyledFooter>
      <span>{t("allocations.keywords.table.visibleColumns")}</span>
      <SelectField
        formControlProps={{ size: "small" }}
        menuItems={[{ value: 4 }, { value: 6 }, { value: 8 }, { value: 10 }]}
        selectProps={{ value: carouselSize, onChange, variant: "outlined" }}
      />
    </StyledFooter>
  );
};
