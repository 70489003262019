import { styled } from "@mui/material";
import { Form } from "formik";
import {
  createGridAreaLettersRange,
  createSingleColumnGridArea,
} from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-areas:
    "a b c"
    "d d d";

  ${createGridAreaLettersRange(4)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
    grid-template-areas: ${createSingleColumnGridArea(4)};
  }
`;
