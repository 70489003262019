import { Trans, useTranslation } from "react-i18next";
import { DropzoneState, useDropzone } from "react-dropzone";
import { Link } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useMemo } from "react";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { YearMonthDatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { LoadLedgerAccountFormValues } from "../../types/form.values";
import {
  StyledDropzone,
  StyledGrayBox,
  StyledLabelChip,
} from "../../../../../../shared/react/components/ CollaboratorProfile/components/CertificationsArea/components/CreationEditingForm/index.styles";
import { DownloadIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  ErrorsListContainer,
  SuccessContainer,
} from "../../../../UploadPerformedExpense/UploadPerformedExpensePage/useModalLoading/index.styles";
import { LedgerAccountUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/ledgerAccount/LedgerAccountResponseDTO";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { StyledForm } from "../../../index.style";

type OwnProps = FormContentProps<LoadLedgerAccountFormValues> & {
  internalFormData: LedgerAccountUploadResponseDTO | null;
};

export const LoadForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
  internalFormData,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFieldValue("file", acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneState =
    useDropzone({
      onDrop,
      accept: {
        "application/vnd.ms-excel": [".csv", ".xlsx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".csv",
          ".xlsx",
        ],
      },
    });

  const requestReturnInfo = useMemo(() => {
    const yearMonthString = values.yearMonth
      ? YearMonth.createFromDate(values.yearMonth).toString()
      : "";

    if (
      internalFormData?.listError.length === 0 &&
      internalFormData?.totalSuccess > 0
    )
      return (
        <SuccessContainer>
          <Trans
            i18nKey="ledgerAccount.keywords.successMessage"
            values={{
              filename: values.file?.name ?? "",
              yearMonth: yearMonthString,
            }}
            components={{ italic: <em /> }}
          />
        </SuccessContainer>
      );

    return (
      <ErrorsListContainer>
        <div>
          {internalFormData?.totalSuccess &&
          internalFormData?.totalSuccess > 0 ? (
            <Trans
              i18nKey="ledgerAccount.errors.data.general.someLinesError"
              values={{
                filename: values.file?.name ?? "",
                yearMonth: yearMonthString,
              }}
              components={{ italic: <em /> }}
            />
          ) : (
            <div>
              {t("ledgerAccount.errors.data.general.noRegisterInserted")}
            </div>
          )}
        </div>
        <div>
          {internalFormData?.listError.map((error, index) => (
            <div key={index}>
              <strong>
                {error.costCenter !== "" ? `${error.costCenter} |` : ""}
                {error.costCenterPep !== "" ? `${error.costCenterPep} |` : ""}
                {error.amount !== "" ? `${error.amount}: ` : ""}
              </strong>
              {t(error.errorMenssage)}
            </div>
          ))}
        </div>
      </ErrorsListContainer>
    );
  }, [internalFormData, i18n.language]);

  return !internalFormData ? (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="yearMonth"
        datePickerProps={{
          label: t("jobRates.keywords.general.startYearMonth"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
        }}
      />
      {((!isDragActive && !values.file) || isDragActive) && (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && !values.file && (
            <>
              <p>
                {t(
                  "ledgerAccount.actions.data.dropzone.dragAndDropOrClickToUpload"
                )}
              </p>
              <CloudUploadIcon fontSize="large" color="primary" />
            </>
          )}
          {isDragActive && (
            <p>{t("collaboratorProfile.actions.data.dropzone.liveFile")}</p>
          )}
        </StyledDropzone>
      )}

      {values.file && !isDragActive && (
        <StyledGrayBox
          size="medium"
          label={
            <StyledLabelChip>
              {`${values.file.name}`}
              <div>
                <Link href={URL.createObjectURL(values.file)} download>
                  <DownloadIconButton disabled={isSubmitting} color="primary" />
                </Link>
                <DeleteIconButton
                  disabled={isSubmitting}
                  onClick={() => setFieldValue("file", null)}
                />
              </div>
            </StyledLabelChip>
          }
        />
      )}
    </StyledForm>
  ) : (
    requestReturnInfo
  );
};
