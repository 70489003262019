import { createContext } from "react";

export interface ExpensesSuperUserContextValues {
  canEditAllExpenses: boolean;
  canEditAllExpensesButton: JSX.Element | null;
}

export const ExpensesSuperUserContext =
  createContext<ExpensesSuperUserContextValues>({
    canEditAllExpenses: false,
    canEditAllExpensesButton: null,
  });
