import { createContext } from "react";

export interface CorporateAllocationsSuperUserContextValues {
  canEditAllCorporateAllocations: boolean;
  canEditAllCorporateAllocationsButton: JSX.Element | null;
}

export const CorporateAllocationsSuperUserContext =
  createContext<CorporateAllocationsSuperUserContextValues>({
    canEditAllCorporateAllocations: false,
    canEditAllCorporateAllocationsButton: null,
  });
