import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { FormikProps } from "formik";
import {
  StyledFormSection,
  StyledInputSelectCompetence,
  StyledLabelChip,
  StyledTextField,
  StyledGrayBox,
  StyledEmailList,
} from "../index.styles";
import {
  CommonInvoiceRequestFormValues,
  FormMode,
} from "../../../../../types/data/form/values.types";
import { InvoiceDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { maxLengthFields } from "../../../../../constants/data/form/fields.constants";

interface OwnProps {
  formikProps: FormikProps<CommonInvoiceRequestFormValues>;
  invoice?: InvoiceDTO;
  formMode: FormMode;
}

export const FinancialResponsibleComponent = ({
  formikProps,
  invoice,
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  const { isSubmitting, values, setFieldValue } = formikProps;

  const generalReadOnly = formMode === "viewing";

  return (
    <StyledFormSection>
      <Typography variant="h6" component="div">
        {t("invoice.keywords.fields.financialResponsibleEmail")}
      </Typography>
      <StyledInputSelectCompetence>
        {!generalReadOnly && (
          <>
            <StyledTextField
              name="invoiceFinancialResponsibleSelected"
              label={t("invoice.keywords.fields.financialResponsibleEmail")}
              inputProps={{ maxLength: maxLengthFields.invoiceNote }}
              disabled={isSubmitting}
              required
            />
            <Button
              disabled={!values.invoiceFinancialResponsibleSelected}
              onClick={() => {
                const alreadyExist = values.invoiceFinancialResponsible.some(
                  (x) =>
                    x.trim() ===
                    values.invoiceFinancialResponsibleSelected.trim()
                );
                if (
                  values.invoiceFinancialResponsibleSelected &&
                  !alreadyExist
                ) {
                  setFieldValue("invoiceFinancialResponsible", [
                    ...values.invoiceFinancialResponsible,
                    values.invoiceFinancialResponsibleSelected,
                  ]);
                }
                setFieldValue("invoiceFinancialResponsibleSelected", "");
              }}
              variant="contained"
            >
              <AddIcon />
            </Button>
          </>
        )}
        <StyledEmailList>
          {values.invoiceFinancialResponsible.map((x, i) => {
            return (
              <StyledGrayBox
                key={i}
                size="medium"
                label={
                  <StyledLabelChip>
                    {x}
                    {!generalReadOnly && (
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          const newArray =
                            values.invoiceFinancialResponsible.filter(
                              (financialResponsible) =>
                                financialResponsible !== x
                            );

                          setFieldValue(
                            "invoiceFinancialResponsible",
                            newArray
                          );
                        }}
                      />
                    )}
                  </StyledLabelChip>
                }
              />
            );
          })}
        </StyledEmailList>
      </StyledInputSelectCompetence>
    </StyledFormSection>
  );
};
