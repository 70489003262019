import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  CorporateAllocationsOfUserDTO,
  schemaCorporateAllocationsOfUserDTO,
} from "./CorporateAllocationsOfUserDTO";

export type PaginatedCorporateAllocationsOfUserDTO =
  PaginationResponse<CorporateAllocationsOfUserDTO>;

export const schemaPaginatedCorporateAllocationsOfUserDTO =
  getSchemaPaginationResponse<CorporateAllocationsOfUserDTO>(
    schemaCorporateAllocationsOfUserDTO as yup.Schema<CorporateAllocationsOfUserDTO>
  );
