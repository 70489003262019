import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "./UserCollaboratorDTO";

export type PaginatedUserDTO = PaginationResponse<UserCollaboratorDTO>;

export const schemaPaginatedUserDTO =
  getSchemaPaginationResponse<UserCollaboratorDTO>(
    schemaUserCollaboratorDTO as yup.Schema<UserCollaboratorDTO>
  );
