import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getDashBoardGroup } from "../../../../../../../../../services/dashboardGroups/dashboardGroups.service";
import {
  AsyncAutocomplete,
  AsyncAutocompleteProps,
} from "../../../../../../../../common/react/components/form/general/autocomplete/general/AsyncAutocomplete";
import { DashboardGroupDTO } from "../../../../../../../DTOs/dashboardGroups/DashboardGroupDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncAutocompleteProps<
    DashboardGroupDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
}

export const DashboardGroupsAutocomplete = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const internalGetDashboardGroups = useCallback(async () => {
    return getDashBoardGroup();
  }, [t, i18n]);

  return (
    <AsyncAutocomplete
      customErrorMessage={t(
        "dashboardsView.errors.data.fetch.failedToFetchDashboardGroups"
      )}
      fetchOptions={internalGetDashboardGroups}
      textfieldProps={{
        ...textfieldProps,
        label: t("dashboardsView.keywords.general.dashboardGroups"),
      }}
      autocompleteProps={{
        ...autocompleteProps,
        getOptionLabelMemo: (dashboardGroup) => {
          const translatedName = dashboardGroup.dashboardGroupTranslations.find(
            (x) => x.idLanguage === i18n.language
          )?.name;

          if (!translatedName || translatedName === "")
            return dashboardGroup.name;
          return translatedName;
        },
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
