import { createContext } from "react";
import { ActivityDTO } from "../../../../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";

export interface ActivitiesSuperUserContextValues {
  canEditAllActivities: boolean;
  canEditAllActivitiesButton: JSX.Element | null;
  selectedActivities: ActivityDTO[];
  setSelectedActivities: React.Dispatch<React.SetStateAction<ActivityDTO[]>>;
}

export const ActivitiesSuperUserContext =
  createContext<ActivitiesSuperUserContextValues>({
    canEditAllActivities: false,
    canEditAllActivitiesButton: null,
    selectedActivities: [],
    setSelectedActivities: () => {},
  });
