import { useTranslation } from "react-i18next";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../shared/react/styles/ui/layouts/general.styles";
import { FormattedCurrency } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { StyledAmount } from "../../../../../../../../shared/common/react/components/data/styles/numbers/StyledAmount.styles";
import { HeaderLoading } from "../../../../../../../../shared/common/react/components/ui/HeaderLoading";

export const AdditivesSummary = () => {
  const { t } = useTranslation();
  const { project, isLoadingProject } = useProjectContext();

  return (
    <StyledSubHeaderFixed>
      {isLoadingProject && <HeaderLoading />}
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.projectBudget")}</div>
        <div>
          <FormattedCurrency
            value={project.billingAmountWithAdditives}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("additives.keywords.fields.additivesTotal")}</div>
        <div>
          <StyledAmount value={project.additivesAmount}>
            <FormattedCurrency
              value={project.additivesAmount}
              currencySymbol={project.subsidiary.currencySymbol}
            />
          </StyledAmount>
        </div>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};
