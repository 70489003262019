export enum Months {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

export const monthsTranslationCodeMap: {
  readonly [key in Months]: string;
} = {
  [Months.January]: "general.actions.months.january",
  [Months.February]: "general.actions.months.february",
  [Months.March]: "general.actions.months.march",
  [Months.April]: "general.actions.months.april",
  [Months.May]: "general.actions.months.may",
  [Months.June]: "general.actions.months.june",
  [Months.July]: "general.actions.months.july",
  [Months.August]: "general.actions.months.august",
  [Months.September]: "general.actions.months.september",
  [Months.October]: "general.actions.months.october",
  [Months.November]: "general.actions.months.november",
  [Months.December]: "general.actions.months.december",
};
