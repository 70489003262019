import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { createContractRole } from "../../../../../../services/contractRole/ContractRole.service";
import { formatFromTranslatableFieldValues } from "../../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { ContractRoleCreateDTO } from "../../../../../../shared/specific/DTOs/ContractRole/ContractRoleCreateDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ContractRoleDataForm } from "../components/contractRoleDataForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("contractRole.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <ContractRoleDataForm {...rest} mode="creation" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            await createContractRole(
              convertTo<ContractRoleCreateDTO>({
                contractRoleTranslations: formatFromTranslatableFieldValues({
                  source: formValues.nameTranslations,
                  convertItem: ({ language, value }) => ({
                    idLanguage: language,
                    name: value,
                  }),
                }),
                effectiveRole: formValues.effectiveRole,
                trustedRole: formValues.trustedRole,
              })
            );
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "contractRole.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
