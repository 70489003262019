import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { TextField } from "@mui/material";
import { ClosingActivityFormValues } from "../../../shared/types/data/form/values.types";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { StyledForm } from "./index.styles";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { ActivitiesSuperUserContext } from "../../../shared/react/contexts/ActivitiesSuperUserContext";
import { ProjectDTO } from "../../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ActivityDTO } from "../../../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";

interface OwnProps
  extends Omit<
    FormContentProps<ClosingActivityFormValues>,
    "internalFormData"
  > {
  project: ProjectDTO | null;
  activity: ActivityDTO | null;
}

export const ClosingActivityForm = ({
  formikProps: { isSubmitting },
  project,
  activity,
}: OwnProps) => {
  const { t } = useTranslation();
  const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);

  return (
    <StyledForm>
      <TextField
        label={t("general.keywords.fields.description")}
        value={activity?.description ?? "-"}
        disabled
      />
      {canEditAllActivities && (
        <DatePickerFormik
          name="completedDate"
          datePickerProps={{
            label: t("activities.keywords.fields.closedAt"),
            disabled: isSubmitting,
            minDate: project?.scheduledStartDate?.toDate(),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
    </StyledForm>
  );
};
