import { Trans, useTranslation } from "react-i18next";
import { DropzoneState, useDropzone } from "react-dropzone";
import { useMemo } from "react";
import { Link } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CustomerBalancesUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/customerBalance/CustomerBalancesUploadResponseDTO";
import { LoadCustomerBalancesFormValues } from "../../shared/react/shared/types/form.types";
import {
  ErrorsListContainer,
  SuccessContainer,
} from "../../../../UploadPerformedExpense/UploadPerformedExpensePage/useModalLoading/index.styles";
import {
  StyledDropzone,
  StyledGrayBox,
  StyledLabelChip,
} from "../../../../../../shared/react/components/ CollaboratorProfile/components/CertificationsArea/components/CreationEditingForm/index.styles";
import { DownloadIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { StyledForm } from "./index.style";

type OwnProps = FormContentProps<LoadCustomerBalancesFormValues> & {
  internalFormData: CustomerBalancesUploadResponseDTO | null;
};

export const LoadForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
  internalFormData,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFieldValue("file", acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneState =
    useDropzone({
      onDrop,
      accept: {
        "application/vnd.ms-excel": [".csv", ".xlsx"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".csv",
          ".xlsx",
        ],
      },
    });

  const requesReturnInfo = useMemo(() => {
    if (!internalFormData) return null;

    if (
      internalFormData?.listError.length === 0 &&
      internalFormData?.totalSuccess > 0
    )
      return (
        <SuccessContainer>
          <Trans
            i18nKey="customerBalance.keywords.successMessage"
            values={{
              filename: values.file?.name ?? "",
            }}
            components={{ italic: <em /> }}
          />
        </SuccessContainer>
      );
    return (
      <>
        <SuccessContainer>
          <p>
            {t("customerBalance.modal.data.upload.totalSuccess").replace(
              "{0}",
              internalFormData?.totalSuccess?.toString() ?? ""
            )}
          </p>
        </SuccessContainer>
        <ErrorsListContainer>
          <div>
            {internalFormData?.totalSuccess &&
            internalFormData?.totalSuccess > 0 ? (
              <div>
                {t("customerBalance.errors.data.general.someLinesError")}
              </div>
            ) : (
              <div>
                {t("customerBalance.errors.data.general.noRegisterInserted")}
              </div>
            )}
          </div>
          <div>
            {internalFormData?.listError.map((error, index) => (
              <div key={index}>
                <strong>
                  {error.referenceDate !== ""
                    ? `${error.referenceDate} | `
                    : ""}
                  {error.customerTrigram !== ""
                    ? `${error.customerTrigram} | `
                    : ""}
                  {error.costCenter !== "" ? `${error.costCenter} | ` : ""}
                  {error.type !== "" ? `${error.type} | ` : ""}
                </strong>
                {t(error.errorMenssage)}
              </div>
            ))}
          </div>
        </ErrorsListContainer>
      </>
    );
  }, [internalFormData, i18n.language]);

  return !internalFormData ? (
    <StyledForm>
      {((!isDragActive && !values.file) || isDragActive) && (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && !values.file && (
            <>
              <p>
                {t(
                  "customerBalance.actions.data.dropzone.dragAndDropOrClickToUpload"
                )}
              </p>
              <CloudUploadIcon fontSize="large" color="primary" />
            </>
          )}
          {isDragActive && (
            <p>{t("collaboratorProfile.actions.data.dropzone.liveFile")}</p>
          )}
        </StyledDropzone>
      )}

      {values.file && !isDragActive && (
        <StyledGrayBox
          size="medium"
          label={
            <StyledLabelChip>
              {`${values.file.name}`}
              <div>
                <Link href={URL.createObjectURL(values.file)} download>
                  <DownloadIconButton disabled={isSubmitting} color="primary" />
                </Link>
                <DeleteIconButton
                  disabled={isSubmitting}
                  onClick={() => setFieldValue("file", null)}
                />
              </div>
            </StyledLabelChip>
          }
        />
      )}
    </StyledForm>
  ) : (
    requesReturnInfo
  );
};
