import { Constants } from "./index.interface";

const transitionTime = 0.6;
const drawerWidthClosed = 60;
const drawerWidthOpened = 240;
const iconWidth = drawerWidthClosed;
const textWidth = drawerWidthOpened - iconWidth;
const buttonHeight = 56;

export const constants: Constants = {
  transitionTime,
  drawerWidthClosed,
  drawerWidthOpened,
  iconWidth,
  textWidth,
  buttonHeight,
};
