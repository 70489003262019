import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../shared/types/form.types";
import { StyledCostCenterCreationForm } from "./index.styles";
import { CostCenterDTO } from "../../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { SharedFormComponents } from "../../../shared/react/components/content/SharedFormComponents";

type OwnProps = FormContentProps<FormValues, CostCenterDTO>;

export const CreationForm = ({
  formikProps: { isSubmitting },
  internalFormData: costCenterDTO,
}: OwnProps) => {
  return (
    <StyledCostCenterCreationForm>
      <SharedFormComponents
        isSubmitting={isSubmitting}
        isLinkedToProject={costCenterDTO?.isLinkedToProject}
      />
    </StyledCostCenterCreationForm>
  );
};
