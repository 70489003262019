import { styled } from "@mui/material";

export const JsonDisplayContainer = styled("div")`
  position: relative;
`;

export const ClipboardButtonContainer = styled("div")`
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
`;
