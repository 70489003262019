import * as yup from "yup";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "../user/UserCollaboratorDTO";

export interface LastInvoiceDto {
  invoiceNote?: string | null;
  launchInstructions: string | null;
  serviceDescription: string | null;
  invoiceFinancialResponsibles: string[];
  invoiceCopyToUsers: UserCollaboratorDTO[];
}

export const schemaLastInvoiceDto = yup.object({
  invoiceNote: yup.string().nullable().defined(),
  launchInstructions: yup.string().nullable().defined(),
  serviceDescription: yup.string().nullable().defined(),
  invoiceFinancialResponsibles: yup.array(yup.string().defined()).defined(),
  invoiceCopyToUsers: yup.array(schemaUserCollaboratorDTO.defined()).defined(),
});
