import { Tooltip } from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { getCostCenterPepsOnly } from "../../../../../../../../../services/costCenterPeps/costCenterPeps.service";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import {
  EnhancedTableRef,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { useEffectAfterRenders } from "../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { ProjectDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  project: ProjectDTO;
}

export const CostCenterPepsTable = ({ project }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("subsidiaries.keywords.general.subsidiary"),
      t("costCenters.keywords.general.costCenter"),
      t("costCenterPeps.keywords.fields.pepCode"),
      t("general.keywords.fields.currency"),
      t("general.keywords.fields.tax"),
      t("general.keywords.fields.exemption"),
      t("costCenterPeps.keywords.fields.isExempted"),
      t("cfops.keywords.general.cfop"),
    ];

    return headCells;
  }, [t]);

  const onCostCenterPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      if (!project) {
        throw new Error("projectData shouldn't be null at this stage.");
      }

      try {
        const costCenterPeps = await getCostCenterPepsOnly({
          filters: {
            idProject: project.id,
          },
        });

        const rows = costCenterPeps.map(
          (costCenterPep): ExternalEnhancedRow => ({
            id: costCenterPep.id,
            cells: [
              costCenterPep.subsidiary.name,
              {
                // needs value for sorting
                value: costCenterPep.costCenter.code,
                CellRenderer: () => (
                  <Tooltip
                    title={costCenterPep.costCenter.name}
                    placement="top"
                  >
                    <div>{costCenterPep.costCenter.code}</div>
                  </Tooltip>
                ),
              },
              costCenterPep.pepCode,
              costCenterPep.currency,
              costCenterPep.tax
                ? `${costCenterPep.tax.toLocaleString(i18n.language)}%`
                : "-",
              costCenterPep.taxWithExemption
                ? `${costCenterPep.taxWithExemption.toLocaleString(
                    i18n.language
                  )}%`
                : "-",
              costCenterPep.isExempted
                ? t("general.keywords.general.yes")
                : t("general.keywords.general.no"),
              costCenterPep.cfop
                ? `${costCenterPep.cfop.code} - ${costCenterPep.cfop.name}`
                : "-",
            ],
          })
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [project?.id]);

  useEffectAfterRenders({
    effect: () => {
      enhancedTableRef.current?.reloadPage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        stickyHeader: true,
      }}
      paginationSettings={{
        externalPagination: {
          loadSinglePageContent: onCostCenterPageChange,
        },
      }}
    />
  );
};
