import { Tab } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabInfo } from "./index.types";
import { TabCode } from "../../shared/types/TabCode.enums";

export const useTabs = () => {
  const { t } = useTranslation();

  const tabs = useMemo(() => {
    const tabsInfo: (TabInfo | null)[] = [
      {
        label: t("projects.keywords.general.projects"),
        value: TabCode.Projects,
      },
      {
        label: t("planningSummary.keywords.general.summary"),
        value: TabCode.Summary,
      },
    ];

    const tabsInfoNotNull = tabsInfo.filter((x) => !!x) as TabInfo[];

    return tabsInfoNotNull.map(({ label, value }) => (
      <Tab label={label} value={value} key={value} />
    ));
  }, [t]);

  return tabs;
};
