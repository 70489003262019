import {
  ElementRef,
  ReactElement,
  ReactNode,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { PaletteColorVariant } from "../../../../../../types/externalLibraries/mui.types";
import {
  SimpleModal,
  SimpleModalFooterActionsDirection,
  SimpleModalMode,
} from "../../../../../components/modal/general/SimpleModal";
import { CenteredContent } from "../../../../../components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../components/ui/CenteredLoading";
import { ExtendedCircularProgress } from "../../../../../components/ui/ExtendedCircularProgress";
import {
  FooterActionsProps,
  ModalContentProps,
  ModalTitleProps,
} from "../shared/types/props.types";
import { ModalContentMessageContainer } from "./index.styles";

export interface FormModalRef {
  closeModal: () => void;
  openModal: () => void;
}

interface OwnProps<InternalModalData> {
  modalTitle?: ReactNode;
  ModalTitle?: (
    values: ModalTitleProps<InternalModalData>
  ) => ReactElement | null;
  ModalTitleMemo?: (
    values: ModalTitleProps<InternalModalData>
  ) => ReactElement | null;
  modalContent?: ReactNode;
  modalContentMemo?: ReactNode;
  ModalContent?: (
    props: ModalContentProps<InternalModalData>
  ) => ReactElement | null;
  ModalContentMemo?: (
    props: ModalContentProps<InternalModalData>
  ) => ReactElement | null;
  modalActions?: ReactElement;
  ModalActions?: (
    props: FooterActionsProps<InternalModalData>
  ) => ReactElement | null;
  ModalActionsMemo?: (
    props: FooterActionsProps<InternalModalData>
  ) => ReactElement | null;
  innerRef?: Ref<FormModalRef>;
  keepModalMounted?: boolean | number;
  onCloseModal?: () => void;
  onOpenModal?: () => void;
  modalColorVariant: PaletteColorVariant;
  mode?: SimpleModalMode;
  internalModalData: InternalModalData | null;
  isLoadingModal: boolean;
  modalError: ReactNode;
  internalLoadingPosition: "title" | "content";
  letModalTitleDealWithLoading: boolean;
  letModalContentDealWithLoading: boolean;
  letModalContentDealWithError: boolean;
  letModalTitleDealWithError: boolean;
  suppressInternalCloseModal: boolean;
  suppressCloseBackdropClick: boolean;
  modalActionsDirection: SimpleModalFooterActionsDirection;
}

export const FormModal = <InternalModalData,>({
  modalTitle,
  ModalTitle,
  ModalTitleMemo,
  modalContent,
  modalContentMemo,
  ModalContent,
  ModalContentMemo,
  modalActions,
  ModalActions,
  ModalActionsMemo,
  innerRef,
  keepModalMounted,
  onCloseModal,
  onOpenModal,
  modalColorVariant,
  mode,
  internalModalData,
  isLoadingModal,
  modalError,
  internalLoadingPosition,
  letModalTitleDealWithLoading,
  letModalContentDealWithLoading,
  letModalContentDealWithError,
  letModalTitleDealWithError,
  suppressInternalCloseModal,
  suppressCloseBackdropClick,
  modalActionsDirection,
}: OwnProps<InternalModalData>) => {
  const modalRef = useRef<ElementRef<typeof SimpleModal>>(null);

  const closeModal = () => {
    modalRef.current?.closeModal();
  };

  const openModal = () => {
    modalRef.current?.openModal();
  };

  useImperativeHandle(innerRef, () => ({
    closeModal,
    openModal,
  }));

  const simpleModalActions = useMemo(() => {
    if (modalActions) {
      return modalActions;
    }

    if (ModalActions) {
      return (
        <ModalActions
          closeModal={closeModal}
          internalModalData={internalModalData}
          isLoadingModal={isLoadingModal}
          modalError={modalError}
        />
      );
    }

    if (ModalActionsMemo) {
      return (
        <ModalActionsMemo
          closeModal={closeModal}
          internalModalData={internalModalData}
          isLoadingModal={isLoadingModal}
          modalError={modalError}
        />
      );
    }

    return null;
  }, [
    modalActions,
    ModalActions,
    internalModalData,
    isLoadingModal,
    modalError,
  ]);

  const finalModalTitle = useMemo(() => {
    if (isLoadingModal && !letModalTitleDealWithLoading) {
      if (internalLoadingPosition === "content") return "";

      return (
        <ExtendedCircularProgress
          size={24}
          color={modalColorVariant}
          colorSubVariant="contrastText"
        />
      );
    }

    if (modalError && !letModalTitleDealWithError) return "";

    if (modalTitle) return modalTitle;

    if (ModalTitle)
      return (
        <ModalTitle
          internalModalData={internalModalData}
          isLoadingModal={isLoadingModal}
          modalError={modalError}
        />
      );

    if (ModalTitleMemo)
      return (
        <ModalTitleMemo
          internalModalData={internalModalData}
          isLoadingModal={isLoadingModal}
          modalError={modalError}
        />
      );

    return null;
  }, [
    modalTitle,
    ModalTitle,
    internalModalData,
    isLoadingModal,
    modalError,
    internalLoadingPosition,
    letModalTitleDealWithLoading,
    letModalTitleDealWithError,
    isLoadingModal,
    modalError,
    modalColorVariant,
  ]);

  const finalModalContent = useMemo(() => {
    if (isLoadingModal && !letModalContentDealWithLoading) {
      if (internalLoadingPosition === "title") return null;

      return (
        <ModalContentMessageContainer>
          <CenteredLoading color={modalColorVariant} />
        </ModalContentMessageContainer>
      );
    }

    if (modalError && !letModalContentDealWithError) {
      return (
        <ModalContentMessageContainer>
          <CenteredContent>{modalError}</CenteredContent>
        </ModalContentMessageContainer>
      );
    }

    if (modalContent) {
      return modalContent;
    }

    if (modalContentMemo) {
      return modalContentMemo;
    }

    if (ModalContent) {
      return (
        <ModalContent
          closeModal={closeModal}
          internalModalData={internalModalData}
        />
      );
    }

    if (ModalContentMemo) {
      return (
        <ModalContentMemo
          closeModal={closeModal}
          internalModalData={internalModalData}
        />
      );
    }

    return null;
  }, [
    modalContent,
    ModalContent,
    internalModalData,
    isLoadingModal,
    modalError,
    internalLoadingPosition,
    letModalContentDealWithLoading,
    letModalContentDealWithError,
    modalColorVariant,
  ]);

  return (
    <SimpleModal
      ref={modalRef}
      title={finalModalTitle}
      keepMounted={keepModalMounted}
      onClose={onCloseModal}
      onOpen={onOpenModal}
      footerActions={simpleModalActions}
      colorVariant={modalColorVariant}
      mode={mode}
      suppressInternalCloseModal={suppressInternalCloseModal}
      suppressCloseBackdropClick={suppressCloseBackdropClick}
      footerActionsDirection={modalActionsDirection}
    >
      {finalModalContent}
    </SimpleModal>
  );
};
