import { BudgetIntercompanyUploadDTO } from "../../../shared/specific/DTOs/BudgetIntercompany/BudgetIntercompanyUploadDTO";
import { riseApi } from "./connection";

export const uploadIntercompany = async (
  files: File,
  IdDivision: string,
  IdSubsidiary: string,
  idSegmentGroup: string | null
): Promise<BudgetIntercompanyUploadDTO | null> => {
  const form = new FormData();
  form.append(`file`, files);
  form.append(`IdSubsidiary`, IdSubsidiary);
  form.append(`IdDivision`, IdDivision);
  if (idSegmentGroup) form.append(`idSegmentGroup`, idSegmentGroup);

  const { data: errorsString } = await riseApi.post(
    "/BudgetIntercompany/budgetIntercompanyFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return errorsString;
};
