import { UserSkillLevelEnum } from "../../enums/userSkill/UserSkillLevelEnum";

export const userSkillTranslationCodeMap: {
  readonly [key in UserSkillLevelEnum]: string;
} = {
  [UserSkillLevelEnum.Basic]: "collaboratorProfile.keywords.fields.basic",
  [UserSkillLevelEnum.Intermediate]:
    "collaboratorProfile.keywords.fields.intermediate",
  [UserSkillLevelEnum.Advanced]: "collaboratorProfile.keywords.fields.advanced",
  [UserSkillLevelEnum.Expert]: "Expert",
};
