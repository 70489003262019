import { Box, TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { getCustomers } from "../../../../../../../../../services/customers/customers.service";
import { CustomerDTO } from "../../../../../../../DTOs/customers/CustomerDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    CustomerDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  cusomerCode: string;
}

export const CustomerByCodeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  cusomerCode,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const getCostCentersFinal = useCallback(async () => {
    const paginatedCustomers = await getCustomers({
      filters: { code: cusomerCode },
      pagination: { pageSize: 50 },
    });

    return paginatedCustomers.tableData;
  }, [cusomerCode]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenters.errors.data.fetch.failedToFetchCostCenters"
      )}
      fetchOptionsMemo={getCostCentersFinal}
      textfieldProps={{
        label: t("costCenters.keywords.general.costCenter"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${x.tradingName} - ${x.registrationCode && x.registrationCode}`,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.tradingName}
            {option.registrationCode && `- ${option.registrationCode}`}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
