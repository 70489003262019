import { TableContainer } from "@mui/material";
import { memo, useContext, useMemo } from "react";
import { StyledEnhancedTableContainer } from "./styles/StyledEnhancedTableContainer.styles";
import { EnhancedTableHead } from "../EnhancedTableHead";
import { EnhancedTableBody } from "../EnhancedTableBody";
import { EnhancedTableToolbar } from "../EnhancedTableToolbar";
import { TableContext } from "../shared/contexts/TableContext";
import { EnhancedTablePagination } from "../EnhancedTablePagination";
import { classnames } from "../shared/constants/classnames";
import { EnhancedTableMessage } from "../EnhancedTableMessage";
import { EnhancedTableBelowToolbar } from "../EnhancedTableBelowToolbar";
import { EnhancedTableFooter } from "../EnhancedTableFooter";
import { StyledTable } from "./styles/StyledTable.styles";

export const EnhancedTableWithinContext = memo(() => {
  const {
    ui: {
      hasDynamicTableHeight,
      minTableWidth,
      stickyHeader,
      maxTableHeight,
      minTableHeight,
    },
    state: { error, info, loading },
    pagination: { page } = { page: null },
    rows,
  } = useContext(TableContext);

  const tableProper = useMemo(() => {
    if (loading !== false || error !== false || info !== false) return null;
    if (!rows || rows?.length === 0) return null;

    return (
      <TableContainer>
        <StyledTable stickyHeader={stickyHeader}>
          <EnhancedTableHead />
          <EnhancedTableBody />
        </StyledTable>
      </TableContainer>
    );
  }, [error, loading, rows?.length, page, stickyHeader]);

  const enhancedTableContent = useMemo(() => {
    return (
      <StyledEnhancedTableContainer
        className={classnames.root}
        hasDynamicTableHeight={hasDynamicTableHeight}
        minTableWidth={minTableWidth}
        maxTableHeight={maxTableHeight}
        minTableHeight={minTableHeight}
      >
        <EnhancedTableToolbar />
        <EnhancedTableBelowToolbar />
        {tableProper}
        <EnhancedTableMessage />
        <EnhancedTablePagination />
        <EnhancedTableFooter />
      </StyledEnhancedTableContainer>
    );
  }, [tableProper, hasDynamicTableHeight, minTableWidth]);

  return enhancedTableContent;
});
