import { styled } from "@mui/material";

export const StyledTableHeadButtons = styled("div")`
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const SingleLineItem = styled("div")`
  white-space: nowrap;
`;
