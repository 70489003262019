import { css, styled } from "@mui/material";

interface OwnProps {
  isWarning?: boolean;
  children?: React.ReactNode;
}

const PureDiv = ({ isWarning, children, ...rest }: OwnProps) => (
  <div {...rest}>{children}</div>
);

export const StyledReadOnlyCarouselCell = styled(PureDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSize}px;

  & > * {
    white-space: nowrap;
  }

  & > :last-child {
    color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ isWarning, theme }) =>
    isWarning &&
    css`
      border: solid ${theme.palette.error.main} 1px;
    `}
`;
