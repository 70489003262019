import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { FrameOnlyDTO } from "../../../../../../../DTOs/frame/FrameOnlyDTO";
import { getFramesOnly } from "../../../../../../../../../services/frames/frames.service";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    FrameOnlyDTO,
    DisableClearable
  >["autocompleteProps"];
}

export const FramesSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<DisableClearable>) => {
  const { t } = useTranslation();

  const getFramesFinal = useCallback(async (name: string) => {
    const paginatedFrames = await getFramesOnly({
      filters: { name },
      pagination: { pageSize: 10 },
    });
    return paginatedFrames.tableData;
  }, []);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("frames.errors.data.fetch.failedToFetchFrames")}
      fetchOptionsMemo={getFramesFinal}
      textfieldProps={{
        label: t("frames.keywords.general.frame"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
