import * as yup from "yup";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  SegmentTranslationDTO,
  schemaSegmentTranslationDTO,
} from "./translation/SegmentTranslationDTO";

export interface SegmentDTO {
  id: number;
  segmentName: string;
  division: DivisionDTO;
  segmentTranslation: SegmentTranslationDTO[];
}

export const schemaSegmentDTO = yup.object({
  id: yup.number().integer().defined(),
  segmentName: yup.string().defined(),
  division: schemaDivisionDTO.defined(),
  segmentTranslation: yup.array(schemaSegmentTranslationDTO).defined(),
});
