import * as yup from "yup";

export interface UserCollaboratorOnlyDTO {
  id: number;
  idWorkload: number | null;
  idDiscipline: number | null;
  login: string | null;
  name: string | null;
  email: string | null;
  state: string | null;
  country: string | null;
  isAdmin: boolean;
  isCollaborator: boolean;
  isActive: boolean;
  admissionDate: Date | null;
  resignationDate: Date | null;
  dateOfBirth: Date | null;
  version: string;
}

export const schemaUserCollaboratorOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  idWorkload: yup.number().integer().nullable().defined(),
  idDiscipline: yup.number().integer().nullable().defined(),
  login: yup.string().nullable().defined(),
  name: yup.string().nullable().defined(),
  email: yup.string().nullable().defined(),
  state: yup.string().nullable().defined(),
  country: yup.string().nullable().defined(),
  isAdmin: yup.boolean().defined(),
  isActive: yup.boolean().defined(),
  isCollaborator: yup.boolean().defined(),
  admissionDate: yup.date().nullable().defined(),
  resignationDate: yup.date().nullable().defined(),
  dateOfBirth: yup.date().nullable().defined(),
  version: yup.string().defined(),
});
