import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
  CenteredHeaderTitle,
  CustomStyledInfoItems,
  StyledCenteredContent,
  StyledRightSideHeader,
} from "./styles/general.styles";
import { contractTypeToTranslationCodeMap } from "../../../../../../../shared/specific/maps/projects/contractType.maps";
import { useViewingPepsModal } from "./hooks/useViewingPepsModal";
import { usePlanningEditingForm } from "./hooks/usePlanningEditingForm";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import {
  StyledInfoContainer,
  StyledInfoItem,
} from "../../shared/react/styles/ui/layouts/general.styles";
import { FormattedCurrency } from "../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { HeaderLoading } from "../../../../../../../shared/common/react/components/ui/HeaderLoading";
import { ScheduledEnd } from "./styles/ScheduledEnd.styles";

export const PlanningHeader = () => {
  const { t, i18n } = useTranslation();

  const {
    project,
    isLoadingProject,
    projectHasError,
    costCenterPep,
    accessMode,
  } = useProjectContext();

  const { viewingPepsButton, viewingPepsModal } = useViewingPepsModal();
  const { planningEditingButton, planningEditingModal } =
    usePlanningEditingForm();

  const canEditPlanning =
    accessMode === "writing" &&
    [ProjectStatus.Created, ProjectStatus.Planning].includes(project.status);

  // inside useMemo without t() and i18n so it's not translated
  // before project updates
  const planningHeaderLeftSide = useMemo(() => {
    const finalScheduledEndAfterBaselineEnd =
      !!project.scheduledEndDateWithAdditives &&
      !!project.finalScheduledEndDate &&
      project.finalScheduledEndDate.greaterThan(
        project.scheduledEndDateWithAdditives
      );

    return (
      <CustomStyledInfoItems>
        <StyledInfoItem>
          <div>{t("projects.keywords.fields.baselineStart")}</div>
          <div>
            {project.scheduledStartDate
              ?.toDate()
              .toLocaleDateString(i18n.language) ?? "-"}
          </div>
        </StyledInfoItem>
        <StyledInfoItem>
          <div>{t("projects.keywords.fields.baselineEnd")}</div>
          <div>
            {project.scheduledEndDateWithAdditives
              ?.toDate()
              .toLocaleDateString(i18n.language) ?? "-"}
          </div>
        </StyledInfoItem>
        <StyledInfoItem>
          <div>{t("projects.keywords.fields.scheduledEnd")}</div>
          <ScheduledEnd afterBaselineEnd={finalScheduledEndAfterBaselineEnd}>
            {project.finalScheduledEndDate
              ?.toDate()
              .toLocaleDateString(i18n.language) ?? "-"}
          </ScheduledEnd>
        </StyledInfoItem>
        <StyledInfoItem>
          <div>{t("general.keywords.fields.billingAmount")}</div>
          <div>
            <FormattedCurrency
              value={project.billingAmountWithAdditives}
              currencySymbol={project.subsidiary.currencySymbol}
            />
          </div>
        </StyledInfoItem>
        <StyledInfoItem>
          <CenteredHeaderTitle>
            {t("projects.keywords.general.pepSubsidiary", {
              name: project.subsidiary.name,
            })}
            {viewingPepsButton}
          </CenteredHeaderTitle>
          <div>{costCenterPep.pepCode}</div>
        </StyledInfoItem>
        <StyledInfoItem>
          <div>{t("divisions.keywords.general.un")}</div>
          <div>{project.division.name}</div>
        </StyledInfoItem>
        <StyledInfoItem>
          <div>{t("projects.keywords.fields.contractType")}</div>
          <div>{t(contractTypeToTranslationCodeMap[project.contractType])}</div>
        </StyledInfoItem>
      </CustomStyledInfoItems>
    );
  }, [project, costCenterPep, canEditPlanning]);

  if (projectHasError)
    return (
      <StyledCenteredContent stretchMode="horizontal">
        {t("projects.errors.data.fetch.failedToFetchProject")}
      </StyledCenteredContent>
    );

  return (
    <>
      <StyledInfoContainer>
        {isLoadingProject && <HeaderLoading />}
        {planningHeaderLeftSide}
        <StyledRightSideHeader>
          {canEditPlanning && <div>{planningEditingButton}</div>}
        </StyledRightSideHeader>
      </StyledInfoContainer>
      {viewingPepsModal}
      {canEditPlanning && planningEditingModal}
    </>
  );
};
