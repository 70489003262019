import { css } from "@emotion/react";
import { Dialog, DialogProps, styled } from "@mui/material";
import { PaletteColorVariant } from "../../../../../types/externalLibraries/mui.types";
import { SimpleModalMode } from "./index.types";

interface StyledDialogProps extends DialogProps {
  colorVariant?: PaletteColorVariant;
  mode?: SimpleModalMode;
}

const PureStyledDialog = ({
  colorVariant,
  mode,
  ...rest
}: StyledDialogProps) => <Dialog {...rest} />;

export const StyledDialog = styled(PureStyledDialog)`
  .MuiDialog-paper {
    width: 100%;
    max-width: 1000px;
    min-height: 100px;
    max-height: calc(100% - 16px);

    ${({ mode }) =>
      mode === "dialog" &&
      css`
        width: auto;
        min-height: 100px;
        min-width: 400px;
        max-width: 600px;
      `}

    overflow: hidden;

    background: linear-gradient(
      to bottom,
      ${({ theme, colorVariant = "primary" }) =>
          theme.palette[colorVariant].main}
        0 20px,
      ${({ theme }) => theme.palette.background.default} 0
    );

    .MuiDialogTitle-root {
      display: flex;
      align-items: center;
      background-color: ${({ theme, colorVariant = "primary" }) =>
        theme.palette[colorVariant].main};
      padding: ${({ theme }) => `${theme.spacing(1.2)} ${theme.spacing(3)}`};

      & > :first-child {
        flex: 1;
        color: ${({ theme }) => theme.palette.primary.contrastText};
      }

      & > :nth-child(2) {
        &.MuiIconButton-root {
          color: ${({ theme }) => theme.palette.primary.contrastText};
        }
      }
    }

    .MuiDialogContent-root {
      padding-top: ${({ theme }) => theme.spacing(2)};
      padding-bottom: ${({ theme }) => theme.spacing(1)};
    }
  }
`;

export const StyledVerticalDialogActions = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: 8px;
`;
