import { Button, ButtonProps, styled } from "@mui/material";

export const LanguageButtonContainer = styled((props: ButtonProps) => (
  <Button {...props} color="inherit" />
))`
  padding-left: 10px;
  padding-right: 10px;
  line-height: 0;

  .MuiButton-startIcon {
    height: 18px;
    width: 27px;
  }
`;
