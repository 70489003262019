import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { UploadsAreaFilterValues } from "./shared/types/form.types";
import { UploadFilterForm } from "./shared/components/UploadFilterForm";
import { BudgetContext } from "../../context/budgetContextProvider";

interface OwnProps {
  currentDolarTax: number | null;
  reloadTablePage: () => void;
}

export const useUploadsArea = ({
  currentDolarTax,
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();
  const { budget } = useContext(BudgetContext);

  const formikRef = useRef<FormikProps<UploadsAreaFilterValues>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: UploadsAreaFilterValues = {
      dolarTax: "",
      segmentGroup: null,
      subsidiary: null,
      un: null,
    };

    const validationSchema = yup.object({
      dolarTax: yup.number().nullable(),
      segmentGroup: yup.object().nullable(),
      subsidiary: yup.object().nullable(),
      un: yup.object().nullable(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [t]);

  const FormComponent = useMemo(() => {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={() => undefined}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <UploadFilterForm
            formikContenProps={formikProps}
            reloadTablePage={reloadTablePage}
          />
        )}
      </Formik>
    );
  }, [budget]);

  return {
    FormComponent,
  };
};
