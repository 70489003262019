import { riseApi } from "./connection";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { CustomerBalanceFilterDTO } from "../../../shared/specific/DTOs/customerBalance/CustomerBalanceFilterDTO";
import {
  PaginatedCustomerBalanceDTO,
  schemaPaginatedCustomerBalanceDTO,
} from "../../../shared/specific/DTOs/customerBalance/PaginatedCustomerBalanceDTO";
import {
  CustomerBalanceDTO,
  schemaCustomerBalanceDTO,
} from "../../../shared/specific/DTOs/customerBalance/CustomerBalanceDTO";
import { CustomerBalanceCreateDTO } from "../../../shared/specific/DTOs/customerBalance/CustomerBalanceCreateDTO";
import { CustomerBalanceUpdateDTO } from "../../../shared/specific/DTOs/customerBalance/CustomerBalanceUpdateDTO";
import { CustomerBalancesUploadResponseDTO } from "../../../shared/specific/DTOs/customerBalance/CustomerBalancesUploadResponseDTO";

export const getCustomerBalance = async ({
  filters,
  pagination,
}: {
  filters?: CustomerBalanceFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedCustomerBalanceDTO> => {
  const { data: customerBalances } = await riseApi.get("CustomerBalance", {
    params: {
      ...pagination,
      ...filters,
    },
  });

  return schemaPaginatedCustomerBalanceDTO.validateSync(customerBalances);
};

export const getCustomerBalanceById = async (
  id: number
): Promise<CustomerBalanceDTO | null> => {
  const { data: customerBalance } = await riseApi.get(`/CustomerBalance/${id}`);
  return schemaCustomerBalanceDTO.nullable().validateSync(customerBalance);
};

export const createCustomerBalance = async (
  customerBalance: CustomerBalanceCreateDTO
) => {
  await riseApi.post("CustomerBalance", customerBalance);
};

export const updateCustomerBalance = async (
  CustomerBalance: CustomerBalanceUpdateDTO
) => {
  await riseApi.put("CustomerBalance", CustomerBalance);
};

export const deleteCustomerBalance = async (id: number, version: string) => {
  await riseApi.delete(`CustomerBalance/${id}/${version}`);
};

export const uploadCustomerBalances = async (
  file: File
): Promise<CustomerBalancesUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: response } = await riseApi.post(
    "CustomerBalance/UploadCustomerBalance",
    form,
    {
      headers: {
        "Content-Type": "multpart/form-data",
      },
    }
  );
  return response;
};
