import { BudgetPageWithinContext } from "./budgetPaeWithinContext";
import { BudgetContextProvider } from "./context/budgetContextProvider";

export const BudgetPage = () => {
  return (
    <BudgetContextProvider>
      <BudgetPageWithinContext />
    </BudgetContextProvider>
  );
};
