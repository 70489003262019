import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "a b c d";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(4)}

  max-width: 1500px;
  padding: ${({ theme }) => theme.spacing(1.5)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d";
  }
`;

export const ButtonArea = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
