import { DateOnly } from "../../../../../../common/classes/data/date/DateOnly";

export const formatFilenameReports = (filename: string, extension = "") => {
  const formattedFilename = filename.replaceAll(" ", "");

  const date = DateOnly.createFromNow();

  let finalName = `${formattedFilename}_${date.day}_${date.month + 1}_${
    date.year
  }`;

  if (extension) {
    finalName += `.${extension}`;
  }
  return finalName;
};
