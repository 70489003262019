import { useContext, useMemo } from "react";
import { YearMonth } from "../../../../../../classes/data/date/YearMonth";
import { CarouselContext } from "../CarouselContext";

interface OwnProps<T> {
  items: T[];
  columnIndex: number;
  CarouselComponent?: (props: CarouselComponentProps<T>) => JSX.Element | null;
  CarouselComponentMemo?: (
    props: CarouselComponentProps<T>
  ) => JSX.Element | null;
  findCurrentItem?: (items: T[], yearMonth: YearMonth) => T | null;
  checkIsCurrentItem?: (item: T, yearMonth: YearMonth) => boolean;
}

export interface CarouselComponentProps<T> {
  yearMonth: YearMonth | null;
  item: T | null;
  items: T[];
}

export const CarouselCell = <T,>({
  items,
  columnIndex,
  CarouselComponent,
  CarouselComponentMemo,
  findCurrentItem,
  checkIsCurrentItem,
}: OwnProps<T>) => {
  const { calculateYearMonth } = useContext(CarouselContext);

  const yearMonth = useMemo(() => {
    return calculateYearMonth({ columnIndex });
  }, [columnIndex, calculateYearMonth]);

  const currentItem = useMemo(() => {
    if (!yearMonth) return null;

    if (findCurrentItem) {
      return findCurrentItem(items, yearMonth);
    }

    if (checkIsCurrentItem) {
      const currentItem = items.find((item) =>
        checkIsCurrentItem(item, yearMonth)
      );
      return currentItem ?? null;
    }

    return null;
  }, [items, yearMonth]);

  const FinalCarouselComponent = useMemo(() => {
    return CarouselComponent ?? CarouselComponentMemo;
  }, [CarouselComponent]);

  if (!FinalCarouselComponent) return null;

  return (
    <FinalCarouselComponent
      item={currentItem}
      items={items}
      yearMonth={yearMonth}
    />
  );
};
