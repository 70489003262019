import { CustomDatePicker, CustomDatePickerProps } from "./CustomDatePicker";

export type YearMonthDatePickerProps<TDate> = CustomDatePickerProps<TDate>;

export const YearMonthDatePicker = <TDate,>({
  datePickerProps,
  ...rest
}: YearMonthDatePickerProps<TDate>) => {
  return (
    <CustomDatePicker
      datePickerProps={{
        format: "MM/yyyy",
        ...datePickerProps,
        views: ["year", "month"],
      }}
      {...rest}
    />
  );
};
