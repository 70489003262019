import {
  EnhancedHeadCellValue,
  EnhancedRowCellValue,
  ExternalEnhancedRowCells,
  ExternalEnhancedRowData,
} from "./types/data.types";

export const checkIsHeadCellValue = (
  value: unknown
): value is EnhancedHeadCellValue => {
  return typeof value === "number" || typeof value === "string";
};

export const checkIsRowCellValue = (
  value: unknown
): value is EnhancedRowCellValue => {
  return typeof value === "number" || typeof value === "string";
};

export const checkIsRowCells = (
  value: ExternalEnhancedRowCells | ExternalEnhancedRowData
): value is ExternalEnhancedRowCells => {
  return !value.hasOwnProperty("id");
};
