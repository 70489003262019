import * as yup from "yup";
import {
  FeeYearMonthValueDTO,
  schemaFeeYearMonthValueDTO,
} from "./FeeYearMonthValueDTO";

export interface CalculatedFeeYearMonthDTO {
  costCenterCode: string;
  realAllocation: number;
  totalAllocation: number;
  feeYearMonthValues: FeeYearMonthValueDTO[];
}

export const schemaCalculatedFeeYearMonthDTO = yup.object({
  costCenterCode: yup.string().defined(),
  realAllocation: yup.number().defined(),
  totalAllocation: yup.number().defined(),
  feeYearMonthValues: yup.array(schemaFeeYearMonthValueDTO).defined(),
});
