import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { FormValuesProjectTimesheet } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { CustomersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomersSearchAutocompleteFormik";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ProjectTimesheetReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/ProjectTimesheetReportFilterDTO";
import { generateProjectTimesheetReport } from "../../../../../../../../../services/reports/reports.service";

export const ProjectTimesheet = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesProjectTimesheet>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesProjectTimesheet = {
      period: null,
      division: null,
      collaborator: [],
      customer: null,
      project: null,
    };

    const validationSchema = yup.object({
      period: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(),
      division: yup.object().nullable(),
      customer: yup.object().nullable(),
      project: yup.object().nullable(),
      collaborator: yup.array().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesProjectTimesheet,
    formikHelpers: FormikHelpers<FormValuesProjectTimesheet>
  ) => {
    const formattedFilters: ProjectTimesheetReportFilterDTO = {
      CustomerId: values.customer?.id ?? null,
      IdProject: values.project?.id ?? null,
      Period: values?.period
        ? YearMonth.createFromDate(values.period)
        : YearMonth.createFromDate(new Date()),
      IdDivision: values.division?.id ?? null,
      DivisionName: values.division?.name ?? null,
      usersCollaboratorsIds: values.collaborator.map((x) => x.id),
    };
    await generateProjectTimesheetReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <CustomersSearchAutocompleteFormik
              name="customer"
              textfieldProps={{
                label: t("customers.keywords.general.customer"),
              }}
            />
            <ProjectSearchAutocompleteFormik
              name="project"
              autocompleteProps={{
                renderOption: (props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                ),
              }}
              textfieldProps={{
                label: t("projects.keywords.fields.project"),
              }}
            />
            <YearMonthDatePickerFormik
              name="period"
              datePickerProps={{
                label: `${t("reports.pages.form.period")} *`,
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
            />
            <DivisionsAutocompleteFormik name="division" />
            <UsersSearchAutocompleteFormik
              name="collaborator"
              autocompleteProps={{
                multiple: true,
              }}
              textfieldProps={{
                label: t("general.keywords.fields.colaborator"),
              }}
              filtersMemo={{ isActive: true }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
