import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  HighlightOff as HighlightOffIcon,
} from "@mui/icons-material";
import { ValidationItem } from "../../../../../types/data/validation/general.types";
import {
  StyledInvalidItem,
  StyledItems,
  StyledValidItem,
} from "./index.styles";

interface OwnProps {
  validationItems: ValidationItem[];
}

export const ValidationItems = ({ validationItems }: OwnProps) => {
  return (
    <StyledItems>
      {validationItems.map(({ name, isValid }, index) =>
        isValid ? (
          <StyledValidItem key={index}>
            <CheckCircleOutlineIcon />
            <span>{name}</span>
          </StyledValidItem>
        ) : (
          <StyledInvalidItem key={index}>
            <HighlightOffIcon />
            <span>{name}</span>
          </StyledInvalidItem>
        )
      )}
    </StyledItems>
  );
};
