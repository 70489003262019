import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import { useTranslation } from "react-i18next";
import { StyledTextButton } from "./index.styles";

interface OwnProps {
  onClick: () => void;
  isSubmitting: boolean;
  isDisabled?: boolean;
}

export const GenerateReportButton = ({
  onClick,
  isSubmitting,
  isDisabled,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledTextButton
      loading={isSubmitting}
      disabled={isDisabled}
      onClick={onClick}
      variant="contained"
    >
      {t("reports.pages.form.generateReport")}
      <BrowserUpdatedIcon fontSize="small" />
    </StyledTextButton>
  );
};
