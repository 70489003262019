import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";

export interface TimesheetMonthDayTotalDTO {
  entryDate: DateOnly;
  minutes: number;
}

export const schemaTimesheetMonthDayTotalDTO = yup.object({
  entryDate: schemaDateOnly.defined(),
  minutes: yup.number().integer().defined(),
});
