import { TextField } from "@mui/material";
import { useMemo } from "react";
import { parseLocaleNumber } from "../../../../../../../common/helpers/data/numbers/localization/parsers.helpers";
import { formatNumber } from "../../../../../../../common/helpers/data/numbers/localization/formatters.helpers";
import { YearMonth } from "../../../../../../../common/classes/data/date/YearMonth";
import { GetWorkingDaysYearMonth } from "../../../../../../../common/helpers/data/dates/general.helpers";

export interface SharedAllocationYearMonthTotalCellProps {
  weeks: null | {
    week1Percentage: string;
    week2Percentage: string;
    week3Percentage: string;
    week4Percentage: string;
    week5Percentage: string;
  };
  label?: string;
  readOnly?: boolean;
  yearMonth: YearMonth;
}

export const SharedAllocationYearMonthTotalCell = ({
  weeks,
  label,
  readOnly,
  yearMonth,
}: SharedAllocationYearMonthTotalCellProps) => {
  const percentageAverage = useMemo(() => {
    if (!weeks) return "-";

    const weeksPercentage = [
      weeks.week1Percentage,
      weeks.week2Percentage,
      weeks.week3Percentage,
      weeks.week4Percentage,
      weeks.week5Percentage,
    ];

    const nonEmptyWeekFields = weeksPercentage.filter((x) => x);
    if (nonEmptyWeekFields.length === 0) return "-";

    const proporcionWeeksMonth = GetWorkingDaysYearMonth(yearMonth);

    const totalDaysWeek1 = proporcionWeeksMonth.weeks[1].totalDays ?? 0;
    const totalDaysWeek2 = proporcionWeeksMonth.weeks[2].totalDays ?? 0;
    const totalDaysWeek3 = proporcionWeeksMonth.weeks[3].totalDays ?? 0;
    const totalDaysWeek4 = proporcionWeeksMonth.weeks[4].totalDays ?? 0;
    const totalDaysWeek5 =
      proporcionWeeksMonth.weeks[5] === undefined
        ? 0
        : proporcionWeeksMonth.weeks[5].totalDays;

    let totalPorcentageWeek1 = 0;
    let totalPorcentageWeek2 = 0;
    let totalPorcentageWeek3 = 0;
    let totalPorcentageWeek4 = 0;
    let totalPorcentageWeek5 = 0;

    if (weeks.week1Percentage !== "")
      totalPorcentageWeek1 =
        (parseLocaleNumber(weeks.week1Percentage) * totalDaysWeek1) / 100;

    if (weeks.week2Percentage !== "")
      totalPorcentageWeek2 =
        (parseLocaleNumber(weeks.week2Percentage) * totalDaysWeek2) / 100;

    if (weeks.week3Percentage !== "")
      totalPorcentageWeek3 =
        (parseLocaleNumber(weeks.week3Percentage) * totalDaysWeek3) / 100;

    if (weeks.week4Percentage !== "")
      totalPorcentageWeek4 =
        (parseLocaleNumber(weeks.week4Percentage) * totalDaysWeek4) / 100;

    if (weeks.week5Percentage !== "")
      totalPorcentageWeek5 =
        (parseLocaleNumber(weeks.week5Percentage) * totalDaysWeek5) / 100;

    const percentageAverage =
      ((totalPorcentageWeek1 +
        totalPorcentageWeek2 +
        totalPorcentageWeek3 +
        totalPorcentageWeek4 +
        totalPorcentageWeek5) /
        proporcionWeeksMonth.workingDays) *
      100;

    return formatNumber(percentageAverage, { fractionDigits: 2 });
  }, [
    weeks?.week1Percentage,
    weeks?.week2Percentage,
    weeks?.week3Percentage,
    weeks?.week4Percentage,
    weeks?.week5Percentage,
  ]);

  return (
    <TextField
      value={!readOnly ? percentageAverage : `${percentageAverage}%`}
      label={label}
      InputProps={{
        endAdornment: !readOnly ? "%" : undefined,
        readOnly,
      }}
      disabled={!readOnly}
    />
  );
};
