import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { StyledSubHeaderFixed } from "../../../shared/react/styles/ui/layouts/general.styles";
import { StyledSummaryTab } from "../../SummaryTab/SummaryTabWithinContext/index.styles";
import { useAllocationsSummary } from "../hooks/useAllocationsSummary";
import { useCreationForm } from "../hooks/useCreationForm";
import { useTableData } from "../hooks/useTableData";
import { DEFAULT_CAROUSEL_SIZE } from "../shared/constants/ui/table/carousel.constants";
import { AllocationsCarouselContext } from "../shared/react/contexts/AllocationsCarouselContext";
import { AllocationsSuperUserContext } from "../shared/react/contexts/AllocationsSuperUserContext";
import { StyledTableHeader } from "./index.styles";
import { TableFooter } from "./TableFooter";

export const AllocationsTabWithinContext = () => {
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const [carouselSize, setCarouselSize] = useState(DEFAULT_CAROUSEL_SIZE);

  const { startCarousel } = useContext(AllocationsCarouselContext);
  const { costCenterPep: initialCostCenterPep, accessMode } =
    useProjectContext();
  const { canEditAllAllocationsButton } = useContext(
    AllocationsSuperUserContext
  );

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  const { costCenterPep, allocationsSummaryComponent } = useAllocationsSummary({
    initialCostCenterPep,
  });

  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
    costCenterPep,
  });

  const {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    assigningAllocationUserModal,
  } = useTableData({
    costCenterPep,
    reloadAllocationsSummary: () => null,
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [costCenterPep],
    rendersBeforeEffect: 2, // autocomplete updated initialCostCenterPep
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [carouselSize],
    rendersBeforeEffect: 1,
  });

  useEffect(() => {
    startCarousel({ carouselSize });
  }, [carouselSize]);

  const tableFooter = useMemo(() => {
    return (
      <TableFooter
        carouselSize={carouselSize}
        setCarouselSize={setCarouselSize}
      />
    );
  }, [carouselSize, setCarouselSize]);

  const rightActionButtons = useMemo(() => {
    if (accessMode === "reading") return undefined;
    return (
      <StyledTableHeader>
        {canEditAllAllocationsButton}
        {tableFooter}
      </StyledTableHeader>
    );
  }, [canEditAllAllocationsButton, accessMode]);

  const leftActionButtons = useMemo(() => {
    if (accessMode === "reading") return undefined;
    return (
      <Protected
        restrictions={{
          type: PermissionType.Allocation,
          level: PermissionLevel.Create,
        }}
      >
        {creationButton}
      </Protected>
    );
  }, [canEditAllAllocationsButton, accessMode]);

  return (
    <StyledSummaryTab>
      <StyledSubHeaderFixed>{allocationsSummaryComponent}</StyledSubHeaderFixed>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtons,
          rightActionButtons,
        }}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 290px)`,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
      />
      {creationModal}
      {deletionModal}
      {editingModal}
      {assigningAllocationUserModal}
    </StyledSummaryTab>
  );
};
