import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDisciplines } from "../../../../../../../services/disciplines/disciplines.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { DisciplineFiltersDTO } from "../../../../../../../shared/specific/DTOs/discipline/DisciplineFiltersDTO";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";

interface OwProps {
  filters?: DisciplineFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage, filters }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("disciplines.keywords.fields.horizontal"),
      t("general.keywords.fields.name"),
      t("contractRole.keywords.fields.deactivatedDate"),
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onDisciplinesPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["name", "deactivatedDate"];

        try {
          const {
            tableData: disciplineData,
            page,
            totalCount,
          } = await getDisciplines({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = disciplineData.map(
            (discipline): ExternalEnhancedRow => ({
              id: discipline.id,
              cells: [
                {
                  value: discipline.horizontal?.name
                    ? (discipline.horizontal?.horizontalTranslation.find(
                        (y) => y.idLanguage === i18n.language
                      )?.name ?? discipline.horizontal?.name)
                    : "-",
                },
                discipline.name,
                {
                  value: discipline.deactivatedDate,
                  displayValue:
                    discipline.deactivatedDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "",
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.Discipline,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...discipline} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Discipline,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...discipline} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );
          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onDisciplinesPageChange,
    editingModal,
    deletionModal,
  };
};
