import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../types/form.types";
import { checkIsValidType } from "../../../../../../../../shared/common/helpers/data/validation/schema.helpers";
import {
  Country,
  schemaCountry,
} from "../../../../../../../../shared/common/enums/locale/Country.enum";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { StyledViewContent } from "./index.styles";
import { maxLengthFields } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/constants/form.constants";
import { StatesAutocompleteFormik } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/react/components/form/formik/input/autocomplete/StatesAutocompleteFormik";
import { CountriesAutocompleteFormik } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/react/components/form/formik/input/autocomplete/CountriesAutocompleteFormik";

export const CreationEditingForm = ({
  formikProps: { values, setFieldValue, isSubmitting },
}: FormContentProps<FormValues>) => {
  const { t } = useTranslation();

  const statesField = useMemo(() => {
    const countryCode = values.country?.code;

    if (!checkIsValidType<Country>(schemaCountry.defined(), countryCode))
      return (
        <TextFieldFormik
          name="stateTextField"
          label={t("general.keywords.fields.state")}
          inputProps={{ maxLength: maxLengthFields.state }}
        />
      );

    return (
      <StatesAutocompleteFormik
        name="stateAutocomplete"
        country={countryCode}
        textfieldProps={{ required: true }}
        autocompleteProps={{ disableClearable: true, disabled: isSubmitting }}
      />
    );
  }, [values.country?.code, isSubmitting]);

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      setFieldValue("state", null);
    },
    deps: [values.country?.code],
  });

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      setFieldValue("registrationCode", "");
    },
    deps: [values.country?.code === "BR"],
  });

  return (
    <StyledViewContent>
      <CountriesAutocompleteFormik
        name="country"
        textfieldProps={{ required: true }}
        autocompleteProps={{
          disableClearable: true,
          disabled: isSubmitting,
        }}
      />
      {statesField}
    </StyledViewContent>
  );
};
