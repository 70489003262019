import { Trans, useTranslation } from "react-i18next";
import { useMemo, useRef } from "react";
import { Button, CircularProgress } from "@mui/material";
import { useModalButton } from "../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import {
  StyledModalContainer,
  StyledWarningMessage,
  SuccessContainer,
  ErrorsListContainer,
} from "./index.styles";

interface OwnProps {
  isSubmitting: boolean;
  filename: string | null;
  yearMonthSubmitted: string | null;
  hasSubmitted: boolean;
  errorsList: string[];
}

export const useModalLoading = ({
  isSubmitting,
  filename,
  yearMonthSubmitted,
  hasSubmitted,
  errorsList,
}: OwnProps) => {
  const { t } = useTranslation();

  const requestReturnInfo = useMemo(() => {
    if (!hasSubmitted) return null;

    if (errorsList.length === 0)
      return (
        <SuccessContainer>
          <Trans
            i18nKey="performedFinancialCost.keywords.successMessage"
            values={{ filename, yearMonth: yearMonthSubmitted }}
            components={{ italic: <em /> }}
          />
        </SuccessContainer>
      );

    return (
      <ErrorsListContainer>
        <div>
          <Trans
            i18nKey="performedFinancialCost.errors.data.general.someLinesError"
            values={{ filename, yearMonth: yearMonthSubmitted }}
            components={{ italic: <em /> }}
          />
        </div>
        <div>
          {errorsList.map((error, index) => (
            <div key={index}>{t(error)}</div>
          ))}
        </div>
      </ErrorsListContainer>
    );
  }, [hasSubmitted, errorsList, t, filename, yearMonthSubmitted]);

  const uploadButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    ModalButtonContainer: LoadingButtonContainer,
    contentModal: LoadingModal,
  } = useModalButton({
    modal: {
      suppressInternalCloseModal: isSubmitting,
      suppressCloseBackdropClick: isSubmitting,
      letModalContentDealWithLoading: true,
      keepModalMounted: 1000,
      modalTitle: (
        <Trans
          i18nKey="performedFinancialCost.keywords.processingFileName"
          values={{ filename: filename ?? "" }}
          components={{ italic: <em /> }}
        />
      ),
      modalContent: isSubmitting ? (
        <StyledModalContainer>
          <StyledWarningMessage>
            {t("performedFinancialCost.keywords.warning")}
          </StyledWarningMessage>
          <CircularProgress color="primary" size={70} />;
        </StyledModalContainer>
      ) : (
        requestReturnInfo
      ),
      ModalActions: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal} disabled={isSubmitting}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return (
          <button
            type="button"
            onClick={onClick}
            hidden
            ref={uploadButtonRef}
          />
        );
      },
    },
    general: {
      initialInternalModalData: null as unknown,
    },
  });

  return { LoadingButtonContainer, LoadingModal, uploadButtonRef };
};
