import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import { InvoicesStatusEnum } from "../../enums/invoices/InvoicesStatusEnum";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../costCenterPeps/CostCenterPepOnlyDTO";
import { CfopDTO, schemaCfopDTO } from "../cfop/CfopDTO";

export interface InvoiceOnlyDTO {
  id: number;
  milestone: string;
  billingAmount: number;
  status: InvoicesStatusEnum;
  costCenterPep: CostCenterPepOnlyDTO;
  cfop: CfopDTO | null;
  coodinatorName: string | null;
  managerName: string | null;
  plannedBillingDate: DateOnly;
}

export const schemaInvoiceOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  milestone: yup.string().defined(),
  billingAmount: yup.number().defined(),
  plannedBillingDate: schemaDateOnly.defined(),
  costCenterPep: schemaCostCenterPepOnlyDTO.defined(),
  cfop: schemaCfopDTO.nullable().defined(),
  coodinatorName: yup.string().nullable().defined(),
  managerName: yup.string().nullable().defined(),
  status: yup.number().integer().defined(),
});
