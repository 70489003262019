import { cnpj } from "cpf-cnpj-validator";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../../../../types/form.types";
import { CountryDTO } from "../../../../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { maxLengthFields } from "../../../../constants/form.constants";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      code: "",
      tradingName: "",
      corporateName: "",
      email: "",
      phoneNumber: "",
      daysForPayment: "",
      zipCode: "",
      country: null,
      stateAutocomplete: null,
      stateTextField: "",
      city: "",
      district: "",
      streetName: "",
      complement: "",
      registrationCode: "",
      municipalCode: "",
      stateCode: "",
      isPublicAgency: false,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      code: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.code)
        .min(
          maxLengthFields.code,
          t("customers.errors.data.fields.code.mustHaveNumberOfLetters", {
            numberOfLetters: maxLengthFields.code,
          })
        ),
      tradingName: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.tradingName),
      corporateName: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.corporateName),
      email: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .email(t("general.errors.data.fields.email.invalid"))
        .max(maxLengthFields.email),
      phoneNumber: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.phoneNumber),
      daysForPayment: yup
        .number()
        .integer()
        .min(0)
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      zipCode: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.zipCode)
        .when("country", {
          is: (country: CountryDTO | null) => country?.code === "BR",
          then: (schema) =>
            schema.test({
              name: "CEP tester",
              message: t("general.errors.data.fields.cep.invalid"),
              test: (value) => /^\d{5}-\d{3}$/.test(value ?? ""),
            }),
        }),
      country: yup
        .object({
          code: yup.string().max(maxLengthFields.country),
        })
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      stateAutocomplete: yup
        .object({
          abbreviation: yup.string().max(maxLengthFields.state),
        })
        .nullable()
        .when("country", (country, schema) => {
          const countryValue = country[0] as unknown as CountryDTO | null;
          if (!countryValue) return schema;
          if (!["BR", "US"].includes(countryValue.code)) return schema;

          return schema.required(
            t("general.errors.data.fields.general.required")
          );
        }),
      stateTextField: yup.string().max(maxLengthFields.state),
      city: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.city),
      district: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.district),
      streetName: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.streetName),
      complement: yup.string().max(maxLengthFields.complement),
      registrationCode: yup
        .string()
        .required(t("general.errors.data.fields.general.required"))
        .max(maxLengthFields.registrationCode)
        .test({
          name: "CNPJ tester",
          message: t("general.errors.data.fields.cnpj.invalid"),
          test(value) {
            const countryCode = this.parent.country?.code as string;
            if (!value || countryCode !== "BR") return true;
            return cnpj.isValid(value);
          },
        }),
      municipalCode: yup.string().max(maxLengthFields.municipalCode),
      stateCode: yup.string().max(maxLengthFields.stateCode),
      isPublicAgency: yup.boolean().required(),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
