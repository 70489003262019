import LanguageIcon from "@mui/icons-material/Language";
import { Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SimpleIconButton } from "../../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { FormContext } from "../../../shared/contexts/FormContext";
import { CostCenterPepsTable } from "../../../../CostCenterPepsTable";
import { usePepLeavingConfirmationModal } from "../hooks/usePepLeavingConfirmationModal";
import { SectionTitleWithButtons, StyledFormSection } from "../index.styles";

interface OwnProps {
  hasChanges: boolean;
}

export const CostCenterPepsSection = ({ hasChanges }: OwnProps) => {
  const { t } = useTranslation();
  const { mode, projectData } = useContext(FormContext);

  const { pepLeavingConfirmationButton, pepLeavingConfirmationModal } =
    usePepLeavingConfirmationModal();

  const finalPepLeavingConfirmationButton = useMemo(() => {
    if (!projectData) return null;

    switch (mode) {
      case "editing":
        return hasChanges ? (
          pepLeavingConfirmationButton
        ) : (
          <Link to={`/projects/${projectData.id}/peps`}>
            <SimpleIconButton
              Icon={LanguageIcon}
              color="primary"
              tooltipText={t("costCenterPeps.actions.general.managePeps")}
            />
          </Link>
        );
      case "viewing":
        return (
          <Link to={`/projects/${projectData.id}/peps`}>
            <SimpleIconButton
              Icon={LanguageIcon}
              color="primary"
              tooltipText={t("costCenterPeps.actions.general.managePeps")}
            />
          </Link>
        );
      case "creation":
      default:
        return null;
    }
  }, [mode, pepLeavingConfirmationButton, hasChanges]);

  if (mode === "creation" || !projectData) return null;

  return (
    <StyledFormSection>
      <SectionTitleWithButtons>
        <Typography variant="h6" component="div">
          {t("costCenterPeps.keywords.general.costCenterPeps")}
        </Typography>
        {finalPepLeavingConfirmationButton}
        {pepLeavingConfirmationModal}
      </SectionTitleWithButtons>
      <CostCenterPepsTable project={projectData} />
    </StyledFormSection>
  );
};
