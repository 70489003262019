import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CommonAdditivesFormValues } from "../../../types/values.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): CommonAdditivesFormValues => {
    return {
      scheduledStartDate: null,
      scheduledEndDate: null,
      amount: "",
      anticipationJustification: "",
      justification: "",
      mv: "",
      approvedCredit: null,
      project: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      scheduledStartDate: yup
        .date()
        .nullable()
        .when("scheduledEndDate", (scheduledEndDate, schema) => {
          if (!scheduledEndDate[0]) return schema;
          return schema.max(
            scheduledEndDate[0],
            t(
              "allocations.errors.data.fields.periodStart.cannotBeAfterPeriodEnd"
            )
          );
        })
        .required(t("general.errors.data.fields.general.required")),
      scheduledEndDate: yup
        .date()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      anticipationJustification: yup.string().test({
        name: "teste",
        message: t("general.errors.data.fields.general.required"),
        test(anticipationJustification) {
          if (
            !this.parent.scheduledStartDate ||
            !this.parent.project?.scheduledStartDate
          )
            return true;
          if (
            new Date(this.parent?.scheduledStartDate as unknown as Date) >=
            this.parent.project?.scheduledStartDate.toDate()
          )
            return true;

          return !!anticipationJustification;
        },
      }),
      justification: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      mv: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .positive(t("general.errors.data.fields.numbers.mustBePositive"))
        .required(t("general.errors.data.fields.general.required")),
      approvedCredit: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      project: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
