import { SvgIconComponent } from "@mui/icons-material";
import { Badge, IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import { MouseEventHandler } from "react";
import { ColorVariant } from "../../../../../../types/externalLibraries/mui.types";
import { BadgeProps } from "./index.types";

export interface SimpleIconButtonProps {
  Icon: SvgIconComponent;
  tooltipText?: string;
  onClick?: MouseEventHandler;
  size?: "small" | "medium" | "large";
  color?: ColorVariant;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  badgeProps?: BadgeProps;
  disabled?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
}

export const SimpleIconButton = ({
  Icon,
  tooltipText = "",
  onClick = () => null,
  size = "medium",
  placement = "top",
  color,
  badgeProps,
  disabled,
  className,
  sx,
}: SimpleIconButtonProps) => {
  return (
    <Tooltip placement={placement} title={disabled ? "" : tooltipText}>
      <span>
        <IconButton
          size={size}
          color={color}
          onClick={onClick}
          disabled={disabled}
          className={className}
          sx={sx}
        >
          <Badge {...badgeProps}>
            <Icon fontSize={size} />
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
};
