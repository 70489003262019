import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { BudgetRoundFormValues } from "../../types/BudgetRoundFormValues";
import { StyledFormContainer } from "./index.styles";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";

type OwnProps = FormContentProps<BudgetRoundFormValues>;

export const BudgetRoundCommomForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledFormContainer>
      <DatePickerFormik
        name="beginDate"
        datePickerProps={{
          label: t("budget.keywords.fields.roundStart"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <DatePickerFormik
        name="endDate"
        datePickerProps={{
          label: t("budget.keywords.fields.roundEnd"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <TextFieldFormik
        name="name"
        label={t("budget.keywords.fields.round")}
        required
        disabled={isSubmitting}
      />
    </StyledFormContainer>
  );
};
