import { Checkbox } from "@mui/material";
import { FieldArrayRenderProps } from "formik";
import { PermissionLevel } from "../../../../../../../../enums/users/permissions/PermissionLevel.enum";
import { PermissionsCreateDelete } from "../../shared/types/form.types";
import { AccessManagementListPermissions } from "../../shared/types/general.types";
import { PermissionsTypeFormatted } from "../../shared/types/permissionTypeFormatted.types";

interface OwnProps {
  listPermissions: AccessManagementListPermissions[] | null;
  permissionTypeData: PermissionsTypeFormatted;
  array: FieldArrayRenderProps;
  permissionLevel: number | null;
}

export const CheckboxPermission = ({
  listPermissions: oldPermissions,
  permissionTypeData,
  array,
  permissionLevel,
}: OwnProps) => {
  const arrayHelpers = array.form.values.permissions;

  const indexOnTheNewList = arrayHelpers.findIndex(
    (data: PermissionsCreateDelete) =>
      permissionLevel === data.permissionLevel &&
      permissionTypeData.id === data.permissionType
  );

  const isOnTheNewPermissions = indexOnTheNewList > -1;

  const isOnTheOldPermissions = oldPermissions?.find(
    (data: AccessManagementListPermissions) =>
      permissionLevel === data.permissionLevel &&
      permissionTypeData.id === data.permissionType
  );

  const doesGroupHavePermission =
    !!isOnTheOldPermissions !== isOnTheNewPermissions;

  const updatePermission = () => {
    if (isOnTheNewPermissions) {
      return array.remove(indexOnTheNewList);
    }
    array.push({
      permissionType: permissionTypeData.id,
      permissionLevel,
      method: isOnTheOldPermissions ? "delete" : "insert",
      id: isOnTheOldPermissions?.id ?? null,
    });

    const alreadyInArrayHelpers = arrayHelpers.some(
      (permission: PermissionsCreateDelete) =>
        permission.permissionLevel === PermissionLevel.Read &&
        permission.permissionType === permissionTypeData.id &&
        permission.method === "insert"
    );

    const alreadyInOldPermissions = oldPermissions?.some(
      (permission) =>
        permission.permissionLevel === PermissionLevel.Read &&
        permission.permissionType === permissionTypeData.id
    );

    if (
      !isOnTheOldPermissions &&
      permissionLevel !== 2 &&
      !alreadyInArrayHelpers &&
      !alreadyInOldPermissions
    ) {
      array.push({
        permissionType: permissionTypeData.id,
        permissionLevel: PermissionLevel.Read,
        method: "insert",
        id: null,
      });
    }
  };

  return (
    <Checkbox checked={doesGroupHavePermission} onClick={updatePermission} />
  );
};
