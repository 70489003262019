import { Button } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { ModalFilters } from "../../shared/types/data/filters.types";
import { FilterForm } from "./FilterForm";
import { FilterValues } from "./shared/types/general.types";

export const useFilterForm = () => {
  const { t, i18n } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [i18n.language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      name: "",
      customer: null,
      division: null,
    };

    const validationSchema = yup.object({
      name: yup.string(),
      customer: yup.object({ id: yup.string() }).nullable(),
      division: yup.object({ id: yup.number().integer() }).nullable(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filtersModal,
  } = useFormikModalButton({
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<ModalFilters>({
          name: formValues.name || undefined,
          idCustomer: formValues.customer?.id ?? undefined,
          idDivision: formValues.division?.id ?? undefined,
        }),
      FormContentMemo: FilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filtersModal };
};
