import { Language } from "../../../../enums/locale/Language.enum";
import { TranslatableFieldValues } from "../../../../types/data/form/translation.types";

export const createTranslatableFieldValues = (
  value = ""
): TranslatableFieldValues => {
  return Object.fromEntries(
    Object.values(Language).map((x) => [x, value])
  ) as TranslatableFieldValues;
};
