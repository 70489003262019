import { AutocompleteProps, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDeprovisionTypes } from "../../../../../../../../../services/deprovisionTypes/deprovisionTypes.service";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import { AsyncAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { DeprovisionTypeDTO } from "../../../../../../../DTOs/deprovisionType/DeprovisionTypeDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteProps<DeprovisionTypeDTO, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  >;
}

const cachedDeprovisionTypes = new CachedTranslatedData({
  callback: () => getDeprovisionTypes(),
  defaultCacheDuration: 600000, // 10 minutes
});

export const ActivitiesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("general.errors.data.fetch.failedToFetchOptions")}
      fetchOptionsMemo={cachedDeprovisionTypes.run}
      textfieldProps={{
        label: t("activities.keywords.fields.deprovisionType"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
