import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import {
  getProjectById,
  getProjectOnlyById,
  updateProjectPlanning,
} from "../../../../../../../../services/projects/projects.service";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { EditIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ProjectOnlyDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import { ProjectPlanningUpdateDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectPlanningUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonPlanningForm } from "../../../../shared/react/components/content/CommonPlanningForm";
import { usePlanningFormikConfig } from "../../../../shared/react/hooks/data/form/planning/usePlanningFormikConfig";
import { CommonPlanningFormValues } from "../../../../shared/types/data/form/planning.types";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { TypeOfEvidenceEnum } from "../../../../../../../../shared/specific/enums/projects/TypeOfEvidence.enum";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";
import { ContractType } from "../../../../../../../../shared/specific/enums/projects/ContractType.enum";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

export const usePlanningEditingForm = () => {
  const { t } = useTranslation();

  const { initialValues, validationSchema } = usePlanningFormikConfig();
  const {
    project: { id: idProject },
    reloadProject,
  } = useProjectContext();

  const {
    contentButton: planningEditingButton,
    contentModal: planningEditingModal,
  } = useFormikModalButton<CommonPlanningFormValues, ProjectOnlyDTO>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("projects.modal.data.updatePlanning.title"),
      modalMode: "dialog",
    },
    button: {
      FormButtonMemo: ({ onClick }) => (
        <EditIconButton
          onClick={onClick}
          tooltipText="projects.actions.general.updatePlanning"
        />
      ),
      onClickContentButtonMemo: async ({
        setFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const project = await getProjectOnlyById(idProject);
          const projectData = await getProjectById(idProject);
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!project)
            return setModalError(
              i18next.t("projects.errors.data.general.projectDoesNotExist")
            );

          const getTypeOfEvidence = () => {
            switch (project?.contractType) {
              case ContractType.FixedAllocation:
                return TypeOfEvidenceEnum.Eap;
              case ContractType.TimeAndMaterial:
                return TypeOfEvidenceEnum.Timesheet;
              case ContractType.ClientAllocation:
                return TypeOfEvidenceEnum.Bm;
              default:
                return TypeOfEvidenceEnum.Timesheet;
            }
          };

          setInternalFormData(project);
          setFormValues({
            scheduledStartDate: project.scheduledStartDate?.toDate() ?? null,
            scheduledEndDate: project.scheduledEndDate?.toDate() ?? null,
            mv: project.mv
              ? formatNumber(project.mv, { fractionDigits: 2 })
              : "",
            userCoordinator: projectData?.userCoordinator ?? null,
            userManager: projectData?.userManager ?? null,
            typeOfEvidence: projectData?.typeOfEvidence
              ? {
                  id: projectData.typeOfEvidence,
                  label:
                    typeOfEvidenceToTranslationCodeMap[
                      projectData.typeOfEvidence
                    ],
                }
              : {
                  id: getTypeOfEvidence(),
                  label:
                    typeOfEvidenceToTranslationCodeMap[getTypeOfEvidence()],
                },
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "projects.errors.data.fetch.failedToFetchProject",
          });

          setModalError(errorMessage);
        }

        setIsLoadingModal(false);
      },
    },
    form: {
      formikConfig: {
        initialValues,
        validationSchema,
      },
      FormContent: CommonPlanningForm,
      FormActionsMemo: ({
        submitFormValues,
        closeModal,
        isSubmitting,
        isLoadingModal,
        modalError,
      }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
              disabled={isLoadingModal || !!modalError}
            >
              {t("general.actions.general.saveChanges")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData: projectData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!projectData)
          throw new Error("'projectData' cannot be null on submission.");

        if (!formValues.scheduledStartDate || !formValues.scheduledEndDate)
          throw new Error("Dates cannot be null on submission.");

        try {
          await updateProjectPlanning(
            convertTo<ProjectPlanningUpdateDTO>({
              id: projectData.id,
              scheduledStartDate: DateOnly.createFromDate(
                formValues.scheduledStartDate
              ),
              scheduledEndDate: DateOnly.createFromDate(
                formValues.scheduledEndDate
              ),
              mv: parseLocaleNumber(formValues.mv),
              idUserCoordinator: formValues.userCoordinator?.id ?? 0,
              idUserManager: formValues.userManager?.id ?? 0,
              typeOfEvidence: formValues.typeOfEvidence.id,
              version: projectData.version,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          closeModal();
          reloadProject();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "projects.errors.data.startPlanning.failedToStartPlanning",
          });
          setSubmitting(false);
        }
      },
    },
  });

  return {
    planningEditingButton,
    planningEditingModal,
  };
};
