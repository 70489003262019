import { UserLanguageLevelEnum } from "../../enums/userLanguage/UserLanguageLevelEnum";

export const userLanguageTranslationCodeMap: {
  readonly [key in UserLanguageLevelEnum]: string;
} = {
  [UserLanguageLevelEnum.Basic]: "collaboratorProfile.keywords.fields.basic",
  [UserLanguageLevelEnum.Intermediate]:
    "collaboratorProfile.keywords.fields.intermediate",
  [UserLanguageLevelEnum.Advanced]:
    "collaboratorProfile.keywords.fields.advanced",
  [UserLanguageLevelEnum.WithoutKnowledge]:
    "collaboratorProfile.keywords.fields.withoutKnowledge",
  [UserLanguageLevelEnum.Native]: "collaboratorProfile.keywords.fields.native",
};
