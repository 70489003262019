import { RestrictionsOperand } from "../../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../../classes/user/permissions/permissionsOperators/RootPermissionsOperator";
import { UserPermissionDTO } from "../../../../DTOs/user/UserPermissionDTO";

export const checkPermissions = ({
  restrictions,
  userPermissions,
  isAdmin,
}: {
  restrictions: RestrictionsOperand;
  userPermissions?: UserPermissionDTO[];
  isAdmin?: boolean;
}) => {
  return new RootPermissionsOperator(restrictions).checkPermissions({
    userPermissions: userPermissions ?? [],
    isAdmin: isAdmin ?? false,
  });
};
