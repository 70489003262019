import { IconButton, styled } from "@mui/material";

export const StyledButtonContainer = styled("div")`
  height: 65px;
  width: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 2rem;
`;
