import * as yup from "yup";

export interface DeprovisionTypeDTO {
  id: number;
  name: string;
  version: string;
}

export const schemaDeprovisionTypeDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  version: yup.string().defined(),
});
