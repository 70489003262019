import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { deleteCorporateAllocations } from "../../../../../../../services/corporateAllocations/corporateAllocations.service";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { CorporateAllocationsOfUserDTO } from "../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationsOfUserDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ModalContent } from "./ModalContent";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<CorporateAllocationsOfUserDTO>({
    modal: {
      modalColorVariant: "error",
      modalTitle: t("corporateAllocations.modal.data.delete.title"),
      internalLoadingPosition: "title",
      ModalContentMemo: ({ internalModalData: allocation }) => (
        <ModalContent allocation={allocation} />
      ),
    },
    button: {
      ModalButton: DeleteIconButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: externalAllocation,
        setInternalModalData,
      }) => {
        setInternalModalData(externalAllocation);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: allocation }) => {
        if (!allocation)
          throw new Error("'allocation' cannot be null on delete.");

        try {
          await deleteCorporateAllocations(allocation.allocationGroup);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "corporateAllocations.errors.data.delete.failedToDeleteAllocation",
          });
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
