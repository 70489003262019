import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FormValues } from "../../../types/form.types";
import { parseLocaleNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      yearMonth: null,
      plannedTax: "",
      performedTax: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.general.date"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      plannedTax: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      performedTax: yup.number().transform((value, originalValue, context) => {
        if (context.isType(originalValue)) return originalValue;
        return parseLocaleNumber(originalValue);
      }),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
