import * as yup from "yup";
import {
  DisciplineDTO,
  schemaDisciplineDTO,
} from "../discipline/DisciplineDTO";
import { schemaWorkloadDTO, WorkloadDTO } from "../workload/WorkloadDTO";

import {
  schemaArrayUserPermissionDTO,
  UserPermissionDTO,
} from "./UserPermissionDTO";

export interface UserCollaboratorDTO {
  id: number;
  idWorkload: number | null;
  workload: WorkloadDTO | null;
  idDiscipline: number | null;
  discipline: DisciplineDTO | null;
  login: string | null;
  state: string | null;
  country: string | null;
  name: string | null;
  email: string | null;
  isAdmin: boolean;
  isCollaborator: boolean;
  isActive: boolean;
  admissionDate: Date | null;
  resignationDate: Date | null;
  dateOfBirth: Date | null;
  permissions: UserPermissionDTO[];
  version: string;
}

export const schemaUserCollaboratorDTO = yup.object({
  id: yup.number().integer().defined(),
  idWorkload: yup.number().integer().nullable().defined(),
  workload: schemaWorkloadDTO.nullable().defined(),
  idDiscipline: yup.number().integer().nullable().defined(),
  discipline: schemaDisciplineDTO.nullable().defined(),
  login: yup.string().nullable().defined(),
  name: yup.string().nullable().defined(),
  email: yup.string().nullable().defined(),
  state: yup.string().nullable().defined(),
  country: yup.string().nullable().defined(),
  isAdmin: yup.boolean().defined(),
  isActive: yup.boolean().defined(),
  isCollaborator: yup.boolean().defined(),
  admissionDate: yup.date().nullable().defined(),
  resignationDate: yup.date().nullable().defined(),
  dateOfBirth: yup.date().nullable().defined(),
  permissions: schemaArrayUserPermissionDTO.defined(),
  version: yup.string().defined(),
});
