import { useTranslation } from "react-i18next";
import lodash from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { addMonths } from "date-fns";
import { Checkbox } from "@mui/material";
import { StyledForm } from "./index.styles";
import { AllocationCreationFormValues } from "../shared/types/data/form/values.types";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { JobRolesSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/jobRoles/JobRoleSearchAutocompleteFormik";
import { AllocationYearMonthsTable } from "./AllocationYearMonthsTable";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { useMinPeriodStart } from "../shared/hooks/form/fields/useMinPeriodStart";
import { UsersCurrentJobRoleSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersCurrentJobRoleSearchAutocompleteFormik";
import { AllocationsCarouselContext } from "../../../shared/react/contexts/AllocationsCarouselContext";
import { maxAllocationsPeriodMonths } from "../shared/constants/data/form.constants";
import { ProjectAllocationTypeAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/project/ProjectAllocationTypeAutocompleteFormik";

interface OwnProps
  extends Omit<
    FormContentProps<AllocationCreationFormValues>,
    "internalFormData"
  > {}

export const AllocationCreationForm = ({
  formikProps: { values, isSubmitting, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();
  const { earliestYearMonth, latestYearMonth } = useContext(
    AllocationsCarouselContext
  );

  const [onlyResignatedLessThanOneMonth, setOnlyResignatedLessThanOneMonth] =
    useState(false);

  const { minPeriodStartDate } = useMinPeriodStart();

  const finalMinPeriodStartDate = useMemo(() => {
    if (!values.periodEnd) return minPeriodStartDate;

    const minStartForPeriodMonths = addMonths(
      values.periodEnd,
      -maxAllocationsPeriodMonths
    );
    return lodash.max([minPeriodStartDate, minStartForPeriodMonths]);
  }, [minPeriodStartDate, values.periodEnd]);

  const maxPeriodStart = useMemo(() => {
    return values.periodEnd ?? latestYearMonth?.toDateEndOfMonth() ?? null;
  }, [values.periodEnd, latestYearMonth]);

  const minPeriodEnd = useMemo(() => {
    return values.periodStart ?? earliestYearMonth?.toDate() ?? null;
  }, [values.periodStart, latestYearMonth]);

  const maxPeriodEnd = useMemo(() => {
    if (!values.periodStart) return null;
    return addMonths(values.periodStart, maxAllocationsPeriodMonths);
  }, [values.periodStart, latestYearMonth]);

  useEffect(() => {
    if (!values.userCurrentJobRole) {
      setFieldValue("jobRole", null);
    } else {
      setFieldValue(
        "jobRole",
        values.userCurrentJobRole.currentJobRole ?? null
      );
    }
  }, [values.userCurrentJobRole]);

  const UsersCurrentJobRole = useMemo(() => {
    return (
      <UsersCurrentJobRoleSearchAutocompleteFormik
        name="userCurrentJobRole"
        textfieldProps={{
          label: t("general.keywords.fields.colaborator"),
        }}
        autocompleteProps={{
          disabled:
            isSubmitting || (!!values.jobRole && !values.userCurrentJobRole),
        }}
        filters={{
          isActive: !onlyResignatedLessThanOneMonth,
          onlyResignatedLessThanOneMonth,
          isCollaborator: true,
        }}
      />
    );
  }, [onlyResignatedLessThanOneMonth, values]);

  return (
    <StyledForm>
      {UsersCurrentJobRole}
      <div>
        <Checkbox
          checked={onlyResignatedLessThanOneMonth ?? false}
          onChange={() =>
            setOnlyResignatedLessThanOneMonth(!onlyResignatedLessThanOneMonth)
          }
        />
        <span>
          {t("allocations.actions.data.onlyResignatedLessThanOneMonth")}
        </span>
      </div>

      <JobRolesSearchAutocompleteFormik
        name="jobRole"
        textfieldProps={{
          label: t("jobRoles.keywords.general.jobRole"),
        }}
        autocompleteProps={{
          disabled: isSubmitting || !!values.userCurrentJobRole,
        }}
        filtersMemo={{ onlyActivated: true }}
        shouldReplaceValueOnMount
      />
      <ProjectAllocationTypeAutocompleteFormik
        name="allocationType"
        autocompleteProps={{
          disabled: isSubmitting,
          disableClearable: true,
        }}
        textfieldProps={{
          required: true,
        }}
      />
      <YearMonthDatePickerFormik
        name="periodStart"
        datePickerProps={{
          label: t("allocations.keywords.fields.periodStart"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          minDate: finalMinPeriodStartDate,
          maxDate: maxPeriodStart,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <YearMonthDatePickerFormik
        name="periodEnd"
        datePickerProps={{
          label: t("allocations.keywords.fields.periodEnd"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          disabled: isSubmitting,
          minDate: minPeriodEnd,
          maxDate: maxPeriodEnd,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <AllocationYearMonthsTable />
    </StyledForm>
  );
};
