import { styled } from "@mui/material";

export const StyledFooter = styled("div")`
  display: flex;
  justify-content: right;
  align-items: center;

  .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
  }
`;
