import { TableHead, TableRow } from "@mui/material";
import { useContext, useMemo } from "react";
import { TableContext } from "../shared/contexts/TableContext";
import { EnhancedHeadCell } from "./EnhancedHeadCell";

export const EnhancedTableHead = () => {
  const { headCells, headerSettings } = useContext(TableContext);

  const isToHiddenHeader = headerSettings?.hiddenHeader;

  const enhancedTableHead = useMemo(() => {
    return (
      <TableHead
        style={{
          display: isToHiddenHeader ? "none" : "table-header-group",
        }}
      >
        <TableRow>
          {headCells.map((headCell, index) => (
            <EnhancedHeadCell
              key={index}
              headCell={headCell}
              position={index}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  }, [headCells]);

  return enhancedTableHead;
};
