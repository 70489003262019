import { Box, TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SkillFilterDTO } from "../../../../../../../DTOs/skill/filters/SkillFilterDTO";
import { SkillDTO } from "../../../../../../../DTOs/skill/SkillDTO";
import { getSkillsDropdown } from "../../../../../../../../../services/skill/skill.service";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<SkillFilterDTO, "name">;
  filtersMemo?: Omit<SkillFilterDTO, "name">;
  shouldReplaceValueOnMount?: boolean;
  onlyActivated?: boolean;
}

const cachedSkills = new CachedTranslatedData({
  callback: getSkillsDropdown,
  defaultCacheDuration: 600000, // 1 hour
});

export const SkillsMultipleAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  shouldReplaceValueOnMount = true,
  onlyActivated = true,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getSkills = useCallback(async () => {
    let skills = await cachedSkills.run();

    if (finalFilters?.idHorizontal) {
      skills = skills.filter(
        (x) =>
          x.skillCategory.discipline?.horizontal?.id ===
          finalFilters?.idHorizontal
      );
    }

    if (finalFilters?.idDiscipline) {
      skills = skills.filter(
        (x) => x.skillCategory.discipline?.id === finalFilters?.idDiscipline
      );
    }

    if (finalFilters?.idSkillCategory) {
      skills = skills.filter(
        (x) => x.skillCategory.id === finalFilters?.idSkillCategory
      );
    }

    if (onlyActivated) {
      skills = skills.filter((x) => !x.skillCategory.deactivatedDate);
    }

    return skills;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "collaboratorProfile.errors.data.fetch.failedToFetchSkills"
      )}
      fetchOptions={getSkills}
      textfieldProps={{
        label: t("collaboratorProfile.keywords.fields.skills"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${
            x.skillTranslation.find((y) => y.idLanguage === i18n.language)
              ?.name ?? x.name
          } ${
            x.deactivatedDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {`${option.name} ${
                  finalFilters?.idSkillCategory
                    ? ""
                    : `- ${option.skillCategory.name}`
                } ${
                  finalFilters?.idDiscipline
                    ? ""
                    : `- ${option.skillCategory.discipline.name}`
                }`}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
