import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { getCostCentersByAllocationType } from "../../../../../../../../services/corporateSegments/corporateSegments.service";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import { AutocompleteOption } from "../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { TimesheetType } from "../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { checkIsValidHourString } from "../../../shared/helpers/data/calendar/validators.helpers";
import { FormValues } from "../shared/types/data/form/values.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): FormValues => {
    return {
      yearMonth: YearMonth.createFromNow(),
      timesheetType: null,
      allocationType: null,
      project: null,
      costCenter: null,
      corporateSegment: null,
      days: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      yearMonth: schemaYearMonth.defined(),
      timesheetType: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      allocationType: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      project: yup
        .object()
        .nullable()
        .when("timesheetType", {
          is: (timesheetType: AutocompleteOption<TimesheetType> | null) =>
            timesheetType?.id === TimesheetType.Project,
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        }),
      costCenter: yup
        .object()
        .nullable()
        .when(["timesheetType", "allocationType"], {
          is: (
            timesheetType: AutocompleteOption<TimesheetType> | null,
            allocationType: AutocompleteOption<EmployeeAllocationType> | null
          ) =>
            timesheetType?.id === TimesheetType.CostCenter &&
            (allocationType?.id === EmployeeAllocationType.Allocation ||
              allocationType?.id === EmployeeAllocationType.Support),
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        }),
      corporateSegment: yup
        .object()
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          async test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (
              this.parent.allocationType.id === EmployeeAllocationType.Support
            ) {
              const costCentersAllowed = await getCostCentersByAllocationType(
                EmployeeAllocationType.Support
              );

              return !(
                costCentersAllowed
                  .map((x) => x.id)
                  .includes(this.parent.costCenter.id) && !value
              );
            }

            return !(
              [
                EmployeeAllocationType.Unallocated,
                EmployeeAllocationType.Allowance,
                EmployeeAllocationType.Training,
              ].includes(this.parent.allocationType.id) && !value
            );
          },
        }),
      days: yup.array(
        yup.object({
          day: yup.number().integer().defined(),
          time: yup
            .string()
            .required(t("general.errors.data.fields.general.required"))
            .test(
              "is-valid-hour",
              t("general.errors.data.fields.dates.invalidTime"),
              (value) => {
                if (!value) return false;
                return checkIsValidHourString(value);
              }
            ),
        })
      ),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
