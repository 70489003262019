import { Trans, useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { GroupsIconButton } from "../../shared/react/components/simpleIconButtons/GroupsIconButtons";
import { CommonUserGroupForm } from "./CommonUserGroupForm";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { AccessGroupsDTO } from "../../../../../../../shared/specific/DTOs/accessGroups/AccessGroupsDTO";
import { UserGroupContextProvider } from "./context";

export const useUserGroup = () => {
  const {
    ModalButtonContainer: UserGroupButtonContainer,
    contentModal: userGroupModal,
  } = useModalButton({
    modal: {
      letModalContentDealWithLoading: true,
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData }) => {
        return (
          <Trans
            i18nKey="accessGroups.modal.data.userGroup.title"
            values={{
              name: internalModalData?.accessGroup?.name ?? "-",
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) => {
        return (
          <UserGroupContextProvider>
            <CommonUserGroupForm accessGroup={internalModalData?.accessGroup} />
          </UserGroupContextProvider>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return <GroupsIconButton onClick={onClick} />;
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: { accessGroup },
        setInternalModalData,
      }) => {
        setInternalModalData({
          accessGroup,
        });
      },
    },
    general: {
      initialInternalModalData: null as unknown as {
        accessGroup: AccessGroupsDTO;
      },
    },
  });

  return {
    UserGroupButtonContainer,
    userGroupModal,
  };
};
