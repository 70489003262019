import { ProjectPlanningFinishingValidationDTO } from "../../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningFinishingValidationDTO";
import { ValidationItems } from "../../../shared/react/components/data/validation/ValidationItems";
import { usePlanningFinishingValidationData } from "../../../shared/react/hooks/data/validation/usePlanningFinishingValidationData";

interface OwnProps {
  projectFinishingValidation: ProjectPlanningFinishingValidationDTO;
}

export const PlanningFinishingForm = ({
  projectFinishingValidation,
}: OwnProps) => {
  const validationItems = usePlanningFinishingValidationData({
    projectFinishingValidation,
  });

  return <ValidationItems validationItems={validationItems} />;
};
