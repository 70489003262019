import { createContext } from "react";
import { CarouselContextValues } from "./index.types";

export const CarouselContext = createContext<CarouselContextValues>({
  earliestYearMonth: null,
  latestYearMonth: null,
  monthsOffsetFirstYearMonth: null,
  totalYearMonths: 0,
  carouselRealSize: 0,
  getCarouselRealSize: () => 0,
  startCarousel: () => null,
  addMonthsOffsetFirstYearMonth: () => null,
  calculateYearMonth: () => null,
  checkCanMoveLeft: () => false,
  checkCanMoveRight: () => false,
  setEarliestYearMonth: () => null,
  setLatestYearMonth: () => null,
});
