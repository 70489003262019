import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { getCustomerById } from "../../../../../../../services/customers/customers.service";
import { CountryDTO } from "../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { CustomerDTO } from "../../../../../../../shared/specific/DTOs/customers/CustomerDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { getStateFormValues } from "../../shared/helpers/data/fetchers.helpers";
import { cachedCountries } from "../../shared/objects/data/cache/cachedFunctions.objects";
import { CustomerView } from "./CustomerView";

export const useViewingForm = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton<
    {
      customer: CustomerDTO;
      country: CountryDTO | null;
      state: string;
    },
    CustomerDTO
  >({
    button: {
      ModalButtonMemo: ViewModalButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: customerWithRegistration,
        getOpenCloseModalCount,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const [customer, countries] = await Promise.all([
            getCustomerById(customerWithRegistration.id),
            cachedCountries.run(),
          ]);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!customer)
            return setModalError(
              i18next.t("customers.errors.data.general.customerDoesNotExist")
            );

          const country =
            countries.find((x) => x.code === customer.country) ?? null;

          const { stateAutocomplete, stateTextField } =
            await getStateFormValues({
              country,
              customerState: customer.state,
            });

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData({
            customer,
            country,
            state: stateAutocomplete?.name ?? stateTextField,
          });
        } catch (error) {
          const errorApiErrorDTO = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "customers.errors.data.fetch.failedToFetchCustomer",
          });

          setModalError(errorApiErrorDTO);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData }) => {
        if (!internalModalData?.customer) return null;

        const { customer } = internalModalData;

        return (
          <Trans
            i18nKey="general.modal.data.view.detailsOfName"
            values={{ name: customer.tradingName }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) => {
        return (
          <CustomerView
            customer={internalModalData?.customer ?? undefined}
            country={internalModalData?.country ?? undefined}
            state={internalModalData?.state ?? undefined}
          />
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
  });

  return { ViewingButtonContainer, viewingModal };
};
