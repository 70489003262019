import * as yup from "yup";
import {
  ExpenseType,
  schemaExpenseType,
} from "../../enums/expenses/ExpenseType";

export interface CostDistributionPercentageDTO {
  id: number;
  percentage: number;
  originCost: string;
  expenseType: ExpenseType;
  version?: string | null;
}

export const schemaCostDistributionPercentageDTO = yup.object({
  id: yup.number().integer().defined(),
  percentage: yup.number().defined(),
  originCost: yup.string().defined(),
  expenseType: schemaExpenseType.defined(),
  version: yup.string().nullable(),
});
