import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { StyledToolbar } from "./styles/StyledToolbar.styles";
import { StyledActionButtonsContainer } from "./styles/StyledActionButtonsContainer.styles";
import { TableContext } from "../shared/contexts/TableContext";
import { StyledTitleContainer } from "./styles/StyledTitleContainer.styles";
import { StyledTitleString } from "./styles/StyledTitleString.styles";

export const EnhancedTableToolbar = () => {
  const { toolbar } = useContext(TableContext);

  const [titleAlignment, setTitleAlign] = useState<"left" | "right" | "center">(
    "left"
  );
  const [finalActionsWidth, setFinalActionsWidth] = useState<
    number | undefined
  >(undefined);

  const leftActionButtonsRef = useRef<HTMLHeadingElement>(null);
  const rightActionButtonsRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (leftActionButtonsRef.current === null) return;

    if (rightActionButtonsRef.current === null) {
      setTitleAlign("right");
      return;
    }

    setTitleAlign("center");
    const finalActionsWidth = Math.max(
      leftActionButtonsRef.current.offsetWidth ?? 0,
      rightActionButtonsRef.current.offsetWidth ?? 0
    );

    setFinalActionsWidth(finalActionsWidth);
  }, []);

  const leftActionButtonsContainer = useMemo(() => {
    if (!toolbar?.leftActionButtons) return null;

    return (
      <StyledActionButtonsContainer
        ref={leftActionButtonsRef}
        width={finalActionsWidth}
      >
        {toolbar.leftActionButtons}
      </StyledActionButtonsContainer>
    );
  }, [toolbar?.leftActionButtons, finalActionsWidth]);

  const rightActionButtonsContainer = useMemo(() => {
    if (!toolbar?.rightActionButtons) return null;

    return (
      <StyledActionButtonsContainer
        ref={rightActionButtonsRef}
        alignment="right"
        width={finalActionsWidth}
      >
        {toolbar.rightActionButtons}
      </StyledActionButtonsContainer>
    );
  }, [toolbar?.rightActionButtons, finalActionsWidth]);

  const toolbarTitle = useMemo(() => {
    if (typeof toolbar?.title === "string")
      return (
        <StyledTitleString titleAlignment={titleAlignment}>
          {toolbar.title}
        </StyledTitleString>
      );

    return toolbar?.title;
  }, [toolbar?.title, titleAlignment]);

  const enhancedTableToolbar = useMemo(() => {
    if (!toolbar) return null;
    if (Object.values(toolbar).every((x) => !x)) return null;

    return (
      <StyledToolbar>
        {leftActionButtonsContainer}
        <StyledTitleContainer>{toolbarTitle}</StyledTitleContainer>
        {rightActionButtonsContainer}
      </StyledToolbar>
    );
  }, [leftActionButtonsContainer, toolbarTitle, rightActionButtonsContainer]);

  return enhancedTableToolbar;
};
