import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckboxFormik } from "../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DivisionsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const CostCentersFilterForm = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TextFieldFormik name="code" label={t("general.keywords.fields.code")} />
      <TextFieldFormik name="name" label={t("general.keywords.fields.name")} />
      <DivisionsAutocompleteFormik name="division" />
      <div>
        <FormControlLabel
          label={t("general.keywords.fields.activeOnly")}
          control={<CheckboxFormik name="activeOnly" />}
        />
      </div>
    </StyledForm>
  );
};
