import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatCurrency } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpenseOfTypeDTO } from "../../../../../../../../../../shared/specific/DTOs/expenses/ExpenseOfTypeDTO";
import { ExpensesOfTypeDTO } from "../../../../../../../../../../shared/specific/DTOs/expenses/ExpensesOfTypeDTO";
import { UseEditingCellFormContentButtonContentProps } from "../../useEditingCellForm";
import {
  StyledButtonBase,
  StyledCarouselCell,
  StyledCenteredInfo,
  StyledReadOnlyCarouselCell,
} from "./index.styles";

interface OwnProps {
  expensesRow: ExpensesOfTypeDTO;
  expenseCell: ExpenseOfTypeDTO | null;
  yearMonth: YearMonth | null;
  EditingButtonContainer?: (
    props: UseEditingCellFormContentButtonContentProps
  ) => JSX.Element | null;
  costCenterPep: CostCenterPepOnlyDTO;
}

export const CarouselContent = ({
  expensesRow,
  expenseCell,
  yearMonth,
  EditingButtonContainer,
  costCenterPep,
}: OwnProps) => {
  const content = (
    <StyledCenteredInfo>
      {formatCurrency({
        value: expenseCell?.amount ?? 0,
        currencySymbol: costCenterPep.currencySymbol,
      })}
    </StyledCenteredInfo>
  );

  if (!yearMonth) return null;

  if (!EditingButtonContainer)
    return <StyledReadOnlyCarouselCell>{content}</StyledReadOnlyCarouselCell>;

  return (
    <EditingButtonContainer
      expenseCell={expenseCell}
      expensesRow={expensesRow}
      yearMonth={yearMonth}
      costCenterPep={costCenterPep}
    >
      <StyledButtonBase>
        <StyledCarouselCell>{content}</StyledCarouselCell>
      </StyledButtonBase>
    </EditingButtonContainer>
  );
};
