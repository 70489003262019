import { TimesheetType } from "../../enums/timesheets/TimesheetType.enum";

export const timesheetTypeToAbbreviationCodeMap = {
  [TimesheetType.Project]: "timesheets.keywords.timesheetType.project",
  [TimesheetType.CostCenter]: "timesheets.keywords.timesheetType.costCenter",
  [TimesheetType.Management]: "timesheets.keywords.timesheetType.management",
  [TimesheetType.Corporate]: "timesheets.keywords.timesheetType.corporate",
};

export const timesheetTypeToTranslationCodeMap = {
  [TimesheetType.Project]: "projects.keywords.general.project",
  [TimesheetType.CostCenter]: "costCenters.keywords.general.costCenter",
  [TimesheetType.Management]: "timesheets.keywords.timesheetType.management",
  [TimesheetType.Corporate]: "timesheets.keywords.timesheetType.corporate",
};
