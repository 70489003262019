import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  SegmentGroupDTO,
  schemaSegmentGroupDTO,
} from "../Segment/SegmentGroupDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import { CustomerBalanceTypeEnum } from "../../enums/customerBalance/customerBalance.enum";

export interface CustomerBalanceDTO {
  id: number;
  customerTrigram: string;
  costCenter: CostCenterDTO;
  segmentGroup: SegmentGroupDTO;
  yearMonth: YearMonth;
  amount: number;
  type: CustomerBalanceTypeEnum;
  version: string;
}

export const schemaCustomerBalanceDTO = yup.object({
  id: yup.number().integer().defined(),
  customerTrigram: yup.string().defined(),
  costCenter: schemaCostCenterDTO.defined(),
  segmentGroup: schemaSegmentGroupDTO.defined(),
  yearMonth: schemaYearMonth.defined(),
  amount: yup.number().defined(),
  type: yup.number().integer().defined(),
  version: yup.string().defined(),
});
