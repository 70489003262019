import { Close as CloseIcon } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ModalProps,
} from "@mui/material";
import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { PaletteColorVariant } from "../../../../../types/externalLibraries/mui.types";
import { StyledDialog, StyledVerticalDialogActions } from "./index.styles";
import {
  SimpleModalMode,
  SimpleModalFooterActionsDirection,
} from "./index.types";

export type { SimpleModalMode, SimpleModalFooterActionsDirection };

export type SimpleModalChildren = ReactNode | [ReactNode, ReactNode];

export interface SimpleModalProps {
  title?: string | ReactNode;
  defaultIsOpen?: boolean;
  children?: ReactNode;
  footerActions?: ReactNode;
  className?: string;
  keepMounted?: boolean | number;
  onOpen?: () => void;
  onClose?: () => void;
  colorVariant?: PaletteColorVariant;
  mode?: SimpleModalMode;
  suppressInternalCloseModal?: boolean;
  suppressCloseBackdropClick?: boolean;
  footerActionsDirection?: SimpleModalFooterActionsDirection;
}

export interface SimpleModalRef {
  closeModal: () => void;
  openModal: () => void;
}

const SimpleModalWithinForwardRef = (
  {
    className,
    title,
    children,
    footerActions,
    onOpen,
    onClose,
    keepMounted: externalKeepMounted,
    defaultIsOpen = false,
    colorVariant = "primary",
    mode = "modal",
    suppressInternalCloseModal = false,
    suppressCloseBackdropClick = false,
    footerActionsDirection = "horizontal",
  }: SimpleModalProps,
  ref: ForwardedRef<SimpleModalRef>
) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const [keepMounted, setKeepMounted] = useState<boolean>(false);

  useEffect(() => {
    if (externalKeepMounted === undefined) return;

    if (typeof externalKeepMounted === "boolean")
      return setKeepMounted(externalKeepMounted);

    const timeoutId = setTimeout(
      () => setKeepMounted(true),
      externalKeepMounted
    );
    return () => clearTimeout(timeoutId);
  }, [externalKeepMounted]);

  const closeModal = useCallback(() => {
    onClose?.();
    setIsOpen(false);
  }, [onClose]);

  const simpleModalCloseModal = useCallback(() => {
    if (suppressInternalCloseModal) return;

    closeModal();
  }, [closeModal, suppressInternalCloseModal]);

  const muiDialogOnClose: ModalProps["onClose"] = (event, reason) => {
    if (reason === "backdropClick" && suppressCloseBackdropClick) return;

    simpleModalCloseModal();
  };

  const openModal = useCallback(() => {
    onOpen?.();
    setIsOpen(true);
  }, [onOpen]);

  useImperativeHandle(ref, () => ({
    closeModal,
    openModal,
  }));

  const dialogTitle = useMemo(() => {
    return (
      <DialogTitle>
        <span>{title}</span>
        <IconButton onClick={simpleModalCloseModal}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    );
  }, [title, simpleModalCloseModal]);

  const simpleModalActionsComponent = useMemo(() => {
    if (!footerActions) return null;

    return footerActionsDirection === "vertical" ? (
      <StyledVerticalDialogActions>{footerActions}</StyledVerticalDialogActions>
    ) : (
      <DialogActions>{footerActions}</DialogActions>
    );
  }, [footerActions, footerActionsDirection]);

  return (
    <StyledDialog
      className={className}
      open={isOpen}
      onClose={muiDialogOnClose}
      keepMounted={keepMounted}
      colorVariant={colorVariant}
      mode={mode}
    >
      {dialogTitle}
      <DialogContent>{children}</DialogContent>
      {simpleModalActionsComponent}
    </StyledDialog>
  );
};

export const SimpleModal = forwardRef(SimpleModalWithinForwardRef);
