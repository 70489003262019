import { styled } from "@mui/material/styles";

interface StyledColorProps {
  isbg: string;
}

export const StyledCellTable = styled("div")<StyledColorProps>`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px;
  background-color: ${({ isbg }) => (isbg === "true" ? "#eeeeee" : "")};
`;
