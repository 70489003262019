import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { UserCollaboratorDTO } from "../../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { WorkloadsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/WorkloadsAutocompleteFormik";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";
import { UserEffectiveCollaboratorDataFormValues } from "../../../../types/data/form/values/values.types";
import { StyledUserCollaboratorCreationForm } from "./index.style";
import { getNowBrazilianDate } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

type OwnProps = FormContentProps<
  UserEffectiveCollaboratorDataFormValues,
  { userCollaborator: UserCollaboratorDTO | null; contractRole?: string }
>;

export const UserEffectiveCollaboratorDataForm = ({
  formikProps: { isSubmitting, setFieldValue, values },
  internalFormData,
}: OwnProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    const login = values.email?.includes("@")
      ? values.email?.split("@")[0]
      : "";
    setFieldValue("login", login);
  }, [values.email]);

  return (
    <StyledUserCollaboratorCreationForm>
      <TextFieldFormik
        name="name"
        label={t("general.keywords.fields.name")}
        inputProps={{ maxLength: maxLengthFields.name }}
        required
        disabled={isSubmitting}
      />

      <TextFieldFormik
        name="login"
        label={t("usersCollaborators.keywords.fields.login")}
        inputProps={{ maxLength: maxLengthFields.login }}
        required={
          !internalFormData?.userCollaborator ||
          internalFormData?.userCollaborator?.isActive
        }
        disabled
      />

      <TextFieldFormik
        name="email"
        label={t("general.keywords.fields.email")}
        inputProps={{ maxLength: maxLengthFields.email }}
        required
        disabled={isSubmitting}
      />

      <WorkloadsAutocompleteFormik
        name="workload"
        textfieldProps={{ required: true }}
        autocompleteProps={{
          disabled: isSubmitting,
        }}
      />

      <YesNoAutocompleteFormik
        name="isCollaborator"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
        textfieldProps={{
          label: t("usersCollaborators.keywords.fields.isCollaborator"),
          required: true,
          disabled: isSubmitting,
        }}
      />

      <DatePickerFormik
        name="dateOfBirth"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.dateOfBirth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          maxDate: getNowBrazilianDate(),
        }}
      />

      <DatePickerFormik
        name="resignationDate"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.resignationDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
        textFieldProps={{ required: true, disabled: isSubmitting }}
      />

      <DatePickerFormik
        name="admissionDate"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.admissionDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
        textFieldProps={{ required: true, disabled: isSubmitting }}
      />
    </StyledUserCollaboratorCreationForm>
  );
};
