import { Formik, FormikHelpers, FormikProps } from "formik";
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  CommonAdditivesFormProps,
  CommonAdditivesFormRef,
  CommonAdditivesFormValues,
} from "../../types/values.types";
import { useFormikConfig } from "../../hooks/data/validation/useFormikConfig";
import { InternalFormikForm } from "./InternalFormikForm";
import { PipedriveDealDTO } from "../../../../../../../shared/specific/DTOs/pipedrive/PipedriveDealDTO";
import {
  FormContextProvider,
  FormContextProviderRef,
} from "../../contexts/FormContext/FormContextProvider";

const CommonAdditiveFormForwardRef = (
  { onSubmit = () => undefined, mode }: CommonAdditivesFormProps,
  ref: ForwardedRef<CommonAdditivesFormRef>
) => {
  const { initialValues, validationSchema } = useFormikConfig();

  const [dealData, setDealData] = useState<PipedriveDealDTO | null>(null);

  const contextRef = useRef<FormContextProviderRef>(null);
  const formikRef = useRef<FormikProps<CommonAdditivesFormValues>>(null);

  useImperativeHandle(ref, () => ({
    setFormValues: (values) => formikRef.current?.setValues(values),
    isSubmitting: formikRef.current?.isSubmitting ?? false,
    setLoading: (loading) => contextRef.current?.setLoading(loading),
    setError: (error) => contextRef.current?.setError(error),
    setDealData: (dealData) => contextRef.current?.setDealData(dealData),
  }));

  const formikOnSubmit = (
    values: CommonAdditivesFormValues,
    formikHelpers: FormikHelpers<CommonAdditivesFormValues>
  ) => {
    onSubmit(values, formikHelpers, {
      dealData,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <FormContextProvider
            ref={contextRef}
            mode={mode}
            formikProps={formikProps}
            dealData={dealData}
            setDealData={setDealData}
          >
            <InternalFormikForm {...formikProps} />
          </FormContextProvider>
        );
      }}
    </Formik>
  );
};

export const CommonAdditiveForm = forwardRef<
  CommonAdditivesFormRef,
  CommonAdditivesFormProps
>(CommonAdditiveFormForwardRef);
