import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback } from "react";
import { useActivityFormikConfig } from "../shared/react/hooks/data/validation/useActivityFormikConfig";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import {
  getActivityById,
  updateActivity,
} from "../../../../../../../../services/activities/activities.service";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CommonActivityForm } from "../shared/react/components/content/CommonActivityForm";
import { CommonActivityFormValues } from "../shared/types/data/form/values.types";
import { ProjectDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ActivityUpdateDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityUpdateDTO";
import { ActivityDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { EditIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { checkIsDateAfterProject } from "../../../../shared/helpers/DTOs/project/general.helpers";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

interface InternalFormValues {
  project: ProjectDTO | null;
  activity: ActivityDTO | null;
}

export const useEditingForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useActivityFormikConfig();

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      CommonActivityFormValues,
      InternalFormValues,
      ActivityDTO
    >
  >(
    async ({
      contentButtonContentProps: externalActivity,
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    }) => {
      setIsLoadingModal(true);
      const startingOpenCloseModalCount = getOpenCloseModalCount();

      try {
        const activity = await getActivityById(externalActivity.id);
        if (!activity)
          return setModalError(
            i18next.t("activities.errors.data.general.activityDoesNotExist")
          );

        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        setInternalFormData({ project, activity });
        setFormValues({
          description: activity.description,
          scheduledDate: activity.scheduledDate.toDate(),
          amount: formatNumber(activity.amount, { fractionDigits: 2 }),
        });
      } catch (error) {
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage: "activities.errors.data.fetch.failedToFetchActivity",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [project]
  );

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      CommonActivityFormValues,
      InternalFormValues,
      ActivityDTO
    >({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("activities.modal.data.edit.title"),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <EditIconButton
              onClick={onClick}
              tooltipText={t("activities.actions.general.editActivity")}
            />
          );
        },
        onClickContentButtonComponent,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonActivityForm
            project={internalFormData?.project ?? null}
            {...rest}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!internalFormData?.activity)
            throw new Error("'activity' cannot be null on submit.");

          try {
            await updateActivity(
              convertTo<ActivityUpdateDTO>({
                id: internalFormData.activity.id,
                description: formValues.description,
                scheduledDate: DateOnly.createFromDate(
                  formValues.scheduledDate ?? new Date()
                ),
                amount: parseLocaleNumber(formValues.amount),
                version: internalFormData.activity.version,
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            reloadActivitySummary();
            closeModal();

            if (
              checkIsDateAfterProject(
                project.finalScheduledEndDate,
                formValues.scheduledDate
              ) ||
              project.isValid
            )
              reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "activities.errors.data.update.failedToUpdateActivity",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
