import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { schemaSkillCategoryDTO, SkillCategoryDTO } from "./SkillCategoryDTO";

export type PaginatedSkillCategoryDTO = PaginationResponse<SkillCategoryDTO>;

export const schemaPaginatedSkillCategoryDTO =
  getSchemaPaginationResponse<SkillCategoryDTO>(
    schemaSkillCategoryDTO as yup.Schema<SkillCategoryDTO>
  );
