import { createContext } from "react";
import { ExpensesSummaryContextValues } from "./index.types";

export const ExpensesSummaryContext =
  createContext<ExpensesSummaryContextValues>({
    summaryData: null,
    isLoadingSummary: false,
    hasErrorSummary: false,
    setSummaryData: () => null,
    setIsLoadingSummary: () => null,
    setHasErrorSummary: () => null,
  });
