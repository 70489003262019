import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { StyledSummaryTab } from "../SummaryTab/SummaryTabWithinContext/index.styles";
import { AdditivesSummary } from "./AdditivesSummary";
import { useTableData } from "./hooks/useTableData";
import { useCreationDeadlineAdditiveForm } from "./hooks/useCreationDeadlineAdditiveForm";

export const AdditivesTab = () => {
  const { i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const { project, accessMode } = useProjectContext();

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  const {
    headCells,
    onCostCenterPageChange,
    editingModal,
    viewingModal,
    deletionModal,
    editingEditingDeadlineAdditiveButtonModal,
  } = useTableData({
    reloadTablePage,
  });

  const { CreationDeadlineAdditiveButton, creationDeadlineAdditiveModal } =
    useCreationDeadlineAdditiveForm({
      reloadTablePage,
    });

  useEffectAfterRenders({
    effect: () => {
      enhancedTableRef.current?.reloadPage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  const leftActionButtons = useMemo(() => {
    return (
      accessMode !== "reading" && (
        <Protected
          restrictions={{
            type: PermissionType.Additive,
            level: PermissionLevel.Create,
          }}
        >
          <CreationDeadlineAdditiveButton />
        </Protected>
      )
    );
  }, [project.status, accessMode]);

  return (
    <StyledSummaryTab>
      <AdditivesSummary />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtons,
        }}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 290px)`,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: onCostCenterPageChange,
          },
        }}
        orderDefaults={{
          defaultOrderBy: 1,
          defaultOrder: "asc",
        }}
      />
      {creationDeadlineAdditiveModal}
      {editingEditingDeadlineAdditiveButtonModal}
      {editingModal}
      {viewingModal}
      {deletionModal}
    </StyledSummaryTab>
  );
};
