import { styled } from "@mui/material";

export const ValidationStatusContainer = styled("div")`
  position: relative;
`;

export const StyledPlanningPageFooter = styled("div")`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(2)};

  & > :first-child {
    display: flex;
    align-items: center;
    padding-left: ${({ theme }) => theme.spacing(1)};
  }

  & > :last-child {
    display: flex;
    gap: ${({ theme }) => theme.spacing(1)};
  }

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;

    & > :first-child {
      justify-content: end;
    }

    & > :last-child {
      justify-content: end;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    & > :first-child {
      justify-content: center;
    }

    & > :last-child {
      flex-direction: column;
    }
  }
`;
