import * as yup from "yup";
import {
  schemaTimesheetMonthDTO,
  TimesheetMonthDTO,
} from "./TimesheetMonthDTO";

export interface TimesheetsUserMonthDTO {
  timesheetMonths: TimesheetMonthDTO[];
  minutesSum: number;
}

export const schemaTimesheetsUserMonthDTO = yup.object({
  timesheetMonths: yup.array(schemaTimesheetMonthDTO).defined(),
  minutesSum: yup.number().integer().defined(),
});
