import {
  useFormattedNumber,
  UseFormattedNumberProps as FormattedNumberProps,
} from "../../../../hooks/data/formatters/numbers/useFormattedNumber";

export type { FormattedNumberProps };

export const FormattedNumber = (props: FormattedNumberProps) => {
  const result = useFormattedNumber(props);
  return <>{result}</>;
};
