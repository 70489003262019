import { styled } from "@mui/material";
import { CenteredLoading, CenteredLoadingProps } from "../../CenteredLoading";

const CustomComponent = (props: CenteredLoadingProps) => (
  <CenteredLoading size={32} stretchMode="horizontal" {...props} />
);

export const StyledHeaderLoading = styled(CustomComponent)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};
  background-color: #fff6;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  z-index: 1;
  overflow: hidden;
`;
