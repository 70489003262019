import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledViewContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledLocationDataContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "a b c"
    "d e f"
    "g g g";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(7)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g g";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g";
  }
`;

export const StyledGeneralDataContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
    "a a a a a a b b b b b b"
    "c c c d d d e e e f f f";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(6)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a a"
      "b b"
      "c d"
      "e f";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;

export const StyledRegistrationDataContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
  }
`;
