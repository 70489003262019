import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useWorksheetHelp } from "./useWorksheetHelp";
import { uploadPaidOutInvoices } from "../../../../../services/invoices/invoices.service";
import { InvoiceUploadResponseDTO } from "../../../../../shared/specific/DTOs/invoice/InvoiceUploadResponseDTO";
import { useUploadForm } from "./useUploadForm";
import { StyledTextButton } from "./index.style";

export const UploadPaidOutInvoicePage = () => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);
  const loadPaidOutInvoices = () => fileUploadInputRef.current?.click();
  const [filename, setFilename] = useState<string | null>(null);
  const [responseUpload, setResponseUpload] =
    useState<InvoiceUploadResponseDTO | null>(null);

  const { UploadButton, uploadModal, uploadButtonRef } = useUploadForm({
    isSubmitting,
    filename,
    hasSubmitted,
    responseUpload,
  });

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();

  useEffect(() => {
    setPageTitle(t("uploadPaidOutInvoices.pages.main.card"));
  }, [t]);

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;
    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      const result = await uploadPaidOutInvoices(file);
      setResponseUpload(result);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h3>{t("uploadPaidOutInvoices.keywords.info")}</h3>
      <input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
        hidden
        ref={fileUploadInputRef}
        onChange={onPickFile}
      />
      <UploadButton />
      <StyledTextButton onClick={loadPaidOutInvoices}>
        {t("uploadPaidOutInvoices.keywords.uploadButton")}
      </StyledTextButton>
      <UseWorksheetHelpModalButtonContainer />
      {useWorksheetHelpModal}
      {uploadModal}
    </>
  );
};
