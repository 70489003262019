import * as yup from "yup";
import {
  CfopDTO,
  schemaCfopDTO,
} from "../../../shared/specific/DTOs/cfop/CfopDTO";
import { riseApi } from "./connection";

export const getCfops = async (): Promise<CfopDTO[]> => {
  const { data: cfops } = await riseApi.get("Cfops");

  return yup.array(schemaCfopDTO).defined().validateSync(cfops);
};
