import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { DependencyList, Ref, useImperativeHandle } from "react";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "./AutocompleteFormik";
import { useAsyncAutocompleteConfig } from "../../../../../hooks/form/settings/autocomplete/useAsyncAutocompleteConfig";

export interface AsyncFastAutocompleteFormikRef {
  rerunFetch: () => void;
}

export type AsyncFastAutocompleteFormikProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  name: string;
  customErrorMessage?: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnOptionsChange?: boolean;
  shouldReplaceValueOnMount?: boolean;
  innerRef?: Ref<AsyncFastAutocompleteFormikRef>;
  rerunOnDeps?: DependencyList;
} & (
  | { fetchOptions?: undefined; fetchOptionsMemo: () => T[] | Promise<T[]> }
  | { fetchOptions: () => T[] | Promise<T[]>; fetchOptionsMemo?: undefined }
);

export const AsyncAutocompleteFormik = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = boolean | undefined,
>({
  name,
  fetchOptions,
  fetchOptionsMemo,
  customErrorMessage,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange,
  shouldReplaceValueOnMount,
  innerRef,
  rerunOnDeps = [],
}: AsyncFastAutocompleteFormikProps<
  T,
  Multiple,
  DisableClearable,
  FreeSolo
>) => {
  const [{ value }] = useField(name);

  const {
    InputLabelProps,
    freeSolo,
    isLoading,
    label,
    options,
    readOnly,
    rerunFetch,
    textFieldColor,
    textFieldFocused,
  } = useAsyncAutocompleteConfig({
    color: textfieldProps?.color,
    focused: textfieldProps?.focused,
    fetchOptions,
    fetchOptionsMemo,
    value,
    customErrorMessage,
    freeSolo: autocompleteProps?.freeSolo,
    InputLabelProps: textfieldProps?.InputLabelProps,
    label: textfieldProps?.label,
    readOnly: autocompleteProps?.readOnly,
    rerunOnDeps,
  });

  useImperativeHandle(innerRef, () => ({
    rerunFetch,
  }));

  return (
    <AutocompleteFormik
      name={name}
      textfieldProps={{
        ...textfieldProps,
        label,
        InputLabelProps,
        color: textFieldColor,
        focused: textFieldFocused,
      }}
      autocompleteProps={{
        options,
        loading: isLoading,
        ...autocompleteProps,
        readOnly,
        freeSolo,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
