import { LabelColorType } from "../types/form.types";

export const LabelColorTypeColorCode: {
  [key in LabelColorType]: string;
} = {
  [LabelColorType.Allocated]: "#47ff47", // green
  [LabelColorType.Attention]: "#fc32db", // pink
  [LabelColorType.Doubt]: "#fafa2a", // yellow
  [LabelColorType.Resignation]: "#a134ff", // purple
  [LabelColorType.Unallocated]: "#23e6f7", // blue
  [LabelColorType.Vacation]: "#ff4749", // red
};
