import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormHeader = styled("div")`
  text-align: center;
  margin-bottom: 20px;
`;

export const StyledForm = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c c"
    "d d"
    "e e"
    "f f";

  ${createGridAreaLettersRange(6)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas: "a" "b" "c";
  }
`;
