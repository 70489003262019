import { Button } from "@mui/material";
import { useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { getJobRoleById } from "../../../../../services/jobRole/jobRole.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { CenteredContent } from "../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { FooterStyles } from "./index.styles";

export const JobRatesPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const [searchParams] = useSearchParams();

  const idJobRole = useMemo(() => {
    const initialIdJobRole = searchParams.get("initialIdJobRole") ?? "";
    return /^\d+$/.test(initialIdJobRole) ? parseInt(initialIdJobRole) : null;
  }, []);

  const hiddenButton = useMemo(() => {
    return idJobRole === null;
  }, []);

  useEffect(() => {
    setPageTitle(t("jobRates.pages.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const {
    value: jobRole,
    isLoading: isLoadingJobRole,
    hasError: jobRoleHasError,
  } = useLoadLatest({
    initialValue: null,
    dependencies: [],
    getValue: () => {
      if (idJobRole === null) return null;
      return getJobRoleById(idJobRole);
    },
    onError: ({ setValue }) => {
      setValue(null);
    },
  });

  const { filterButton, filterModal, filters } = useFilterForm({ jobRole });
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
    jobRole,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, filters],
    rendersBeforeEffect: 1,
  });

  const {
    headCells,
    deleteDialog,
    editingModal,
    viewingModal,
    onJobRatePageChange,
  } = useTableData({
    filters,
    reloadTablePage,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  if (isLoadingJobRole) return <CenteredLoading />;
  if (jobRoleHasError)
    return (
      <CenteredContent>
        {t("jobRoles.errors.data.fetch.failedToFetchJobRoles")}
      </CenteredContent>
    );

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.JobRate,
                level: PermissionLevel.Create,
              }}
            >
              {creationButton}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
          defaultOrder: "desc",
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onJobRatePageChange,
          },
        }}
        footerSettings={{
          ContentMemo: () => {
            const { t } = useTranslation();

            const previousPage = useMemo(() => {
              return "/job-roles";
            }, []);

            return (
              <FooterStyles>
                <Button
                  style={{ display: hiddenButton ? "none" : undefined }}
                  component={Link}
                  to={previousPage}
                >
                  {t("general.actions.general.return")}
                </Button>
              </FooterStyles>
            );
          },
        }}
      />
      {deleteDialog}
      {creationModal}
      {editingModal}
      {viewingModal}
    </>
  );
};
