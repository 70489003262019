import * as yup from "yup";
import {
  JobRoleTranslationDTO,
  schemaJobRoleTranslationDTO,
} from "./translation/JobRoleTranslationDTO";

export interface JobRoleDTO {
  id: number;
  name: string;
  deactivatedDate: Date | null;
  version: string;
  jobRoleTranslations: JobRoleTranslationDTO[];
}

export const schemaJobRoleDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  deactivatedDate: yup.date().nullable().default(null),
  version: yup.string().defined(),
  jobRoleTranslations: yup.array(schemaJobRoleTranslationDTO).defined(),
});
