import { UploadProjectionAdjustmentResponseDto } from "../../../shared/specific/DTOs/ProjectionAdjustment/UploadProjectionAdjustmentResponseDto";
import { riseApi } from "./connection";
import { ProjectionFiltersDTO } from "../../../shared/specific/DTOs/projection/filters/ProjectionFitlersDTO";
import {
  ProjectionAdjustmentDTO,
  schemaProjectionAdjustmentDTO,
} from "../../../shared/specific/DTOs/ProjectionAdjustment/ProjectionAdjustmentDTO";
import { ProjectionAdjustmentUpdateDTO } from "../../../shared/specific/DTOs/ProjectionAdjustment/ProjectionAdjustmentUpdateDTO";

export const getProjectionAdjustmentData = async ({
  filters,
}: {
  filters: ProjectionFiltersDTO;
}): Promise<ProjectionAdjustmentDTO | null> => {
  const { data: projectionAdjustmentData } = await riseApi.get(
    "ProjectionAdjustment",
    {
      params: {
        ...filters,
      },
    }
  );

  return schemaProjectionAdjustmentDTO.validateSync(projectionAdjustmentData);
};

export const uploadProjectionAdjustment = async (
  files: File
): Promise<UploadProjectionAdjustmentResponseDto | null> => {
  const form = new FormData();
  form.append(`file`, files);

  const { data: errorsString } = await riseApi.post(
    "/ProjectionAdjustment/projectionAdjustmentFile",
    form,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );

  return errorsString;
};

export const updateProjectionAdjustments = async (
  projectionUpdateDTO: ProjectionAdjustmentUpdateDTO
) => {
  await riseApi.put("ProjectionAdjustment", projectionUpdateDTO);
};
