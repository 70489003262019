import * as yup from "yup";

export enum TabCode {
  Activities = "activities",
  Invoice = "invoice",
  Revenue = "revenue",
  Allocations = "allocations",
  Expenses = "expenses",
  Summary = "summary",
  Additives = "additives",
}

export const schemaTabCode = yup
  .mixed<TabCode>()
  .oneOf(Object.values(TabCode) as TabCode[]);
