import * as yup from "yup";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  UserResumeDTO,
  schemaUserResumeDTO,
} from "../userResume/UserResumeDTO";
import { UserSkillDTO, schemaUserSkillDTO } from "../userSkill/UserSkillDTO";
import {
  UserLanguageDTO,
  schemaUserLanguageDTO,
} from "../userLanguage/UserLanguageDTO";
import {
  UserCertificationDTO,
  schemaUserCertificationDTO,
} from "../userCertification/UserCertificationDTO";

export interface UserCollaboratorProfileDTO {
  id: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  userCertifications: UserCertificationDTO[];
  userResume: UserResumeDTO[];
  userSkills: UserSkillDTO[];
  userLanguages: UserLanguageDTO[];
}

export const schemaUserCollaboratorProfileDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  userCertifications: yup.array(schemaUserCertificationDTO).defined(),
  userResume: yup.array(schemaUserResumeDTO).defined(),
  userSkills: yup.array(schemaUserSkillDTO).defined(),
  userLanguages: yup.array(schemaUserLanguageDTO).defined(),
});
