import { AutocompleteOption } from "../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";

export enum TypeOfActivities {
  Activities = 0,
  Desprovisions = 1,
  ActivitiesAndDesprovisions = 2,
}

export interface FilterValues {
  scheduledDateEarliest: Date | null;
  scheduledDateLatest: Date | null;
  typeOfActivity: AutocompleteOption<TypeOfActivities> | null;
}
