import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { getSummaryFee } from "../../../../../../../services/calculatedFee/calculatedFee.service";
import { CalculatedFeeFiltersDTO } from "../../../../../../../shared/specific/DTOs/calculatedFee/CalculateddFeeFiltersDTO";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledCellHeader } from "../shared/styles/index.styles";

interface OwProps {
  filters: CalculatedFeeFiltersDTO | null;
}

export const useTableData = ({ filters }: OwProps) => {
  const { t, i18n } = useTranslation();
  const baseHeadCells: ExternalEnhancedHeadCell[] = [
    t("costCenters.keywords.general.costCenter"),
    t("allocations.keywords.employeeAllocationType.allocation"),
    t("fee.keywords.fields.finalAllocation"),
  ];
  const [headCells, setHeadCells] =
    useState<ExternalEnhancedHeadCell[]>(baseHeadCells);

  const [sumList, setSumList] = useState<number[]>([]);
  const [currencySymbol, setCurrencySymbol] = useState<string>("");

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(
      async ({ setInfo }) => {
        try {
          if (!filters) {
            setInfo(t("allocationsMap.info.data.general.fillTheFilter"));
            setSumList([]);
            return null;
          }
          const calculatedFees = await getSummaryFee(filters);

          if (calculatedFees.fees.length > 0) {
            setCurrencySymbol(
              calculatedFees.fees[0]?.fee.subsidiary.currencySymbol
            );
          }

          const feeList: ExternalEnhancedHeadCell[] = calculatedFees.fees.map(
            (x) => ({
              value: `Fee ${x.fee.description}`,
              HeadRenderer: () => (
                <StyledCellHeader>
                  <span>{`Fee ${x.fee.description}`}</span>
                  <span>{x.fee.costCenter.code}</span>
                </StyledCellHeader>
              ),
              width: 200,
            })
          );

          const header = [...baseHeadCells, ...feeList];
          setHeadCells(header);

          const rows: ExternalEnhancedRow[] = calculatedFees.calculatedFees.map(
            (cfee): ExternalEnhancedRow => {
              return {
                id: cfee.costCenterCode,
                cells: [
                  cfee.costCenterCode,
                  formatNumber(cfee.realAllocation, { fractionDigits: 2 }),
                  formatNumber(cfee.totalAllocation, { fractionDigits: 2 }),
                  ...calculatedFees.fees.map((fee) =>
                    formatNumber(
                      cfee.feeYearMonthValues.find(
                        (x) => x.idFeeYearMonth === fee.id
                      )?.value ?? 0,
                      {
                        fractionDigits: 2,
                        prefix: fee.fee.subsidiary.currencySymbol,
                      }
                    )
                  ),
                ],
              };
            }
          );

          setSumList(
            calculatedFees.fees.map((fee) =>
              calculatedFees.calculatedFees.reduce(
                (acc, cfee) =>
                  acc +
                  (cfee.feeYearMonthValues.find(
                    (x) => x.idFeeYearMonth === fee.id
                  )?.value ?? 0),
                0
              )
            )
          );

          return rows;
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error({ error });

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [t, i18n, filters]
    );

  return {
    headCells,
    onPageChange,
    sumList,
    currencySymbol,
  };
};
