import { useCallback, useContext, useMemo, useRef } from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedTableExternalFilterRows,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { InvoiceDTO } from "../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { StyledSummaryTab } from "../SummaryTab/SummaryTabWithinContext/index.styles";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { InvoicesSummary, InvoicesSummaryRef } from "./InvoicesSummary";
import { InvoicesSuperUserContext } from "./shared/react/contexts/InvoicesSuperUserContext";

export const InvoicesTabWithinContext = () => {
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const invoicesSummaryRef = useRef<InvoicesSummaryRef>(null);

  const { accessMode } = useProjectContext();
  const { canEditAllInvoicesButton, canEditAllInvoices } = useContext(
    InvoicesSuperUserContext
  );

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  const reloadInvoiceSummary = useCallback(() => {
    invoicesSummaryRef.current?.reloadInvoicesSummary();
  }, []);

  const { filterButton, filterModal, filters } = useFilterForm();
  const {
    headCells,
    singlePageLoader,
    deletionModal,
    viewingObservationsModal,
    editingModal,
    requestModal,
    viewingInvoiceRequestModal,
    editingInvoiceRequestModal,
    reopenRequestModal,
    cancellationInvoiceRequestModal,
    editingIssuedDateModal,
  } = useTableData({
    reloadTablePage,
    reloadInvoiceSummary,
    filters,
  });

  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
    reloadInvoiceSummary,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {canEditAllInvoicesButton}
        {filterButton}
      </>
    );
  }, [filterButton, canEditAllInvoicesButton]);

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters, canEditAllInvoices],
    rendersBeforeEffect: 1,
  });

  const filterRows = useCallback<
    ExternalEnhancedTableExternalFilterRows<InvoiceDTO>
  >(
    ({ rowInfo }) => {
      if (!rowInfo) return false;

      if (
        filters.earliestPlannedBillingDate &&
        rowInfo.plannedBillingDate.toDate() < filters.earliestPlannedBillingDate
      )
        return false;
      if (
        filters.latestPlannedBillingDate &&
        rowInfo.plannedBillingDate.toDate() > filters.latestPlannedBillingDate
      )
        return false;

      if (
        filters.earliestIssueDate &&
        (!rowInfo.issueDate ||
          rowInfo.issueDate.toDate() < filters.earliestIssueDate)
      )
        return false;
      if (
        filters.latestIssueDate &&
        (!rowInfo.issueDate ||
          rowInfo.issueDate.toDate() > filters.latestIssueDate)
      )
        return false;

      return true;
    },
    [filters]
  );

  return (
    <StyledSummaryTab>
      <InvoicesSummary ref={invoicesSummaryRef} />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: accessMode === "writing" && (
            <Protected
              restrictions={{
                type: PermissionType.Invoice,
                level: PermissionLevel.Create,
              }}
            >
              {creationButton}
            </Protected>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 290px)`,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        orderDefaults={{
          defaultOrderBy: 3,
        }}
        filterSettings={{
          filterRows: filterRows as ExternalEnhancedTableExternalFilterRows,
        }}
      />
      {filterModal}
      {deletionModal}
      {creationModal}
      {viewingObservationsModal}
      {editingModal}
      {requestModal}
      {viewingInvoiceRequestModal}
      {editingInvoiceRequestModal}
      {reopenRequestModal}
      {cancellationInvoiceRequestModal}
      {editingIssuedDateModal}
    </StyledSummaryTab>
  );
};
