import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useTableData } from "./hooks/useTableData";
import { LoadingBackdrop } from "../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

export const MonthlyAllocationClosingPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    setPageTitle(t("monthlyAllocationClosing.general.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { headCells, onPageChange, deletionModal, isSubmitting } = useTableData(
    {
      reloadTablePage,
    }
  );

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
        orderDefaults={{
          defaultOrderBy: 0,
          defaultOrder: "desc",
        }}
      />
      <LoadingBackdrop open={isSubmitting} />
      {deletionModal}
    </>
  );
};
