import * as yup from "yup";

import {
  BasePaginationResponse,
  getSchemaBasePaginationResponse,
} from "./BaseFilterResponseDTO";

export type PaginationResponse<T> = BasePaginationResponse<T[]>;

export const getSchemaPaginationResponse = <T>(
  rowDataSchema: yup.Schema<T>
) => {
  return getSchemaBasePaginationResponse(
    yup.array(rowDataSchema.defined()).defined()
  );
};
