import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";

export const checkIsDateAfterProject = (
  finalScheduledEndDate?: DateOnly | null,
  date?: Date | DateOnly | YearMonth | null
) => {
  if (!finalScheduledEndDate || !date) return false;

  if (date instanceof YearMonth) {
    const finalScheduledYearMonth = YearMonth.createFromDate(
      finalScheduledEndDate.toDate()
    );

    return date.greaterThan(finalScheduledYearMonth);
  }

  const finalDate =
    date instanceof DateOnly ? date : DateOnly.createFromDate(date);

  return finalDate.greaterThan(finalScheduledEndDate);
};

export const checkIsDateSameProject = (
  finalScheduledEndDate?: DateOnly | null,
  date?: Date | DateOnly | YearMonth | null
) => {
  if (!finalScheduledEndDate || !date) return false;

  if (date instanceof YearMonth) {
    const finalScheduledYearMonth = YearMonth.createFromDate(
      finalScheduledEndDate.toDate()
    );

    return date.equals(finalScheduledYearMonth);
  }

  const finalDate =
    date instanceof DateOnly ? date : DateOnly.createFromDate(date);

  return finalDate.equals(finalScheduledEndDate);
};
