import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import {
  ReopenInvoiceRequest,
  getInvoiceById,
} from "../../../../../../../../../services/invoices/invoices.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { ReopenInvoiceRequestDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateBeforeReleasedDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useReopenInvoiceRequest = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: ReopenRequestButtonContainer,
    contentModal: reopenRequestModal,
  } = useActionButtonDialog<InvoiceDTO, InvoiceDTO>({
    modal: {
      modalColorVariant: "warning",
      ModalTitleMemo: ({ internalModalData: invoice }) => {
        return (
          <Trans
            i18nKey="invoice.actions.data.revertIssuing"
            values={{ name: invoice?.milestone ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <SimpleIconButton
          Icon={AttachMoneyIcon}
          color="warning"
          tooltipText={t("invoice.actions.data.revertIssuing")}
          onClick={openModal}
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoice,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const invoiceData = await getInvoiceById(invoice.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!invoiceData)
            return setModalError(
              i18next.t("invoice.errors.data.general.invoiceDoesNotExist")
            );

          setInternalModalData(invoiceData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoice",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("invoice.actions.data.revertIssuing"),
      onConfirmMemo: async ({ closeModal, internalModalData: invoiceData }) => {
        if (!invoiceData)
          throw new Error("Frame data cannot be null on confirm.");

        try {
          const invoiceToUpdate: ReopenInvoiceRequestDTO = {
            id: invoiceData.id,
            idInvoiceGroup: invoiceData.idInvoiceGroup,
            status: InvoicesStatusEnum.NotReleased,
            version: invoiceData.version,
          };

          await ReopenInvoiceRequest(invoiceToUpdate);

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!invoiceData?.milestone)
            return notifyError(
              i18next.t("frames.errors.data.delete.failedToDeleteFrame")
            );

          notifyError(
            <Trans
              i18nKey="frames.errors.data.delete.failedToDeleteFrame"
              values={{ name: invoiceData.milestone }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { ReopenRequestButtonContainer, reopenRequestModal };
};
