import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../../types/data/form/values.types";
import { parseLocaleNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";

export const useFormikConfig = () => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const initialValues = useMemo((): FormValues => {
    return {
      scheduledStartDate: null,
      scheduledEndDate: null,
      amount: "",
      anticipationJustification: "",
      justification: "",
      mv: "",
      approvedCredit: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      scheduledStartDate: yup
        .date()
        .nullable()
        .when("scheduledEndDate", (scheduledEndDate, schema) => {
          if (!scheduledEndDate[0]) return schema;
          return schema.max(
            scheduledEndDate[0],
            t(
              "allocations.errors.data.fields.periodStart.cannotBeAfterPeriodEnd"
            )
          );
        })
        .required(t("general.errors.data.fields.general.required")),
      scheduledEndDate: yup
        .date()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      anticipationJustification: yup
        .string()
        .when("scheduledStartDate", (scheduledStartDate, schema) => {
          if (!scheduledStartDate[0] || !project.scheduledStartDate)
            return schema;
          if (
            new Date(scheduledStartDate[0] as unknown as Date) >=
            project.scheduledStartDate.toDate()
          )
            return schema;
          return schema.required(
            t("general.errors.data.fields.general.required")
          );
        }),
      justification: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      mv: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .positive(t("general.errors.data.fields.numbers.mustBePositive"))
        .required(t("general.errors.data.fields.general.required")),
      approvedCredit: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
