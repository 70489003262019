import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { UserCollaboratorDTO } from "../../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { DisciplinesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DisciplinesAutocompleteFormik";
import { WorkloadsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/WorkloadsAutocompleteFormik";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";
import { UserCollaboratorDataFormValues } from "../../../../types/data/form/values/values.types";
import {
  StyledInnactiveMessage,
  StyledUserCollaboratorCreationForm,
} from "./index.style";
import { getNowBrazilianDate } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

type OwnProps = FormContentProps<
  UserCollaboratorDataFormValues,
  { userCollaborator: UserCollaboratorDTO | null; contractRole?: string }
>;

export const UserCollaboratorDataForm = ({
  formikProps: { isSubmitting, setFieldValue, values },
  internalFormData,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const login = values.email?.includes("@")
      ? values.email?.split("@")[0]
      : "";
    setFieldValue("login", login);
  }, [values.email]);

  const userInnactiveMessage = useMemo(() => {
    if (
      !internalFormData?.userCollaborator ||
      internalFormData?.userCollaborator.isActive
    )
      return null;

    if (!internalFormData?.userCollaborator.resignationDate)
      return t(
        "usersCollaborators.modal.data.edit.collaboratorHasBeenTerminated"
      );

    return t(
      "usersCollaborators.modal.data.edit.collaboratorHasBeenTerminatedAtDate",
      {
        date: internalFormData?.userCollaborator.resignationDate.toLocaleDateString(
          i18n.language
        ),
      }
    );
  }, [
    t,
    i18n.language,
    internalFormData?.userCollaborator?.isActive,
    internalFormData?.userCollaborator?.resignationDate,
  ]);

  return (
    <StyledUserCollaboratorCreationForm>
      <TextFieldFormik
        name="name"
        label={t("general.keywords.fields.name")}
        inputProps={{ maxLength: maxLengthFields.name }}
        required
        disabled={isSubmitting}
      />

      <TextFieldFormik
        name="login"
        label={t("usersCollaborators.keywords.fields.login")}
        inputProps={{ maxLength: maxLengthFields.login }}
        required={
          !internalFormData?.userCollaborator ||
          internalFormData?.userCollaborator?.isActive
        }
        disabled
      />

      <TextFieldFormik
        name="email"
        label={t("general.keywords.fields.email")}
        inputProps={{ maxLength: maxLengthFields.email }}
        required
        disabled={isSubmitting}
      />

      <WorkloadsAutocompleteFormik
        name="workload"
        textfieldProps={{ required: true }}
        autocompleteProps={{
          disabled: isSubmitting,
        }}
      />

      <DisciplinesAutocompleteFormik
        name="discipline"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
      />

      <YesNoAutocompleteFormik
        name="isCollaborator"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
        textfieldProps={{
          label: t("usersCollaborators.keywords.fields.isCollaborator"),
          required: true,
          disabled: isSubmitting,
        }}
      />

      <DatePickerFormik
        name="admissionDate"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.admissionDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
      />

      <DatePickerFormik
        name="dateOfBirth"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.dateOfBirth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          maxDate: getNowBrazilianDate(),
        }}
      />

      {internalFormData?.contractRole && (
        <TextField
          label={t("Cargo Atual")}
          value={internalFormData?.contractRole || "-"}
          InputProps={{ readOnly: true }}
          disabled
        />
      )}

      {userInnactiveMessage && (
        <StyledInnactiveMessage>{userInnactiveMessage}</StyledInnactiveMessage>
      )}
    </StyledUserCollaboratorCreationForm>
  );
};
