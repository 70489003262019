import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { YearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { LocalizedNumberTextField } from "../../../../../../../../../../../../shared/common/react/components/form/general/textField/LocalizedNumberTextField";
import { StyledAllocationBatchFiller } from "./index.styles";
import { CustomAutocomplete } from "../../../../../../../../../../../../shared/common/react/components/form/general/autocomplete/general/CustomAutocomplete";
import { AutocompleteOption } from "../../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { AllocationCreationYearMonthsFormValues } from "../../../shared/types/data/form/values.types";
import { checkDoesWeekExist } from "../../../../../../../../../../../../shared/common/helpers/data/dates/checkers.helpers";

interface OwnProps {
  periodYearMonths: YearMonth[] | null;
}

export const AllocationBatchFiller = ({ periodYearMonths }: OwnProps) => {
  const { t } = useTranslation();
  const { setFieldValue, isSubmitting } = useFormikContext();
  const [{ value: allocationYearMonths }] = useField<
    AllocationCreationYearMonthsFormValues[]
  >("allocationYearMonths");

  const [percentage, setPercentage] = useState("");
  const [option, setOption] = useState<
    AutocompleteOption<string> | YearMonth | null
  >(null);

  const options = useMemo(() => {
    if (!periodYearMonths) return [];
    return [
      {
        id: "all",
        label: t("allocations.modal.data.create.allAllocationsText"),
      },
      ...periodYearMonths,
    ];
  }, [periodYearMonths, t]);

  const onSubmit = () => {
    if (!percentage || !option) return;

    const updateAllocationColumn = (
      allocationColumn: AllocationCreationYearMonthsFormValues
    ): AllocationCreationYearMonthsFormValues => {
      return {
        ...allocationColumn,
        week1Percentage: percentage,
        week2Percentage: percentage,
        week3Percentage: percentage,
        week4Percentage: percentage,
        week5Percentage: checkDoesWeekExist(5, allocationColumn.yearMonth)
          ? percentage
          : "",
      };
    };

    if (!(option instanceof YearMonth))
      return setFieldValue(
        "allocationYearMonths",
        allocationYearMonths.map((x) => updateAllocationColumn(x))
      );

    const existingColumnIndex = allocationYearMonths.findIndex((x) =>
      x.yearMonth.equals(option)
    );
    const existingColumn = allocationYearMonths[existingColumnIndex];
    if (!existingColumn) return;

    const updatedColumn = updateAllocationColumn(existingColumn);
    setFieldValue(
      `allocationYearMonths[${existingColumnIndex}]`,
      updatedColumn
    );
  };

  return (
    <StyledAllocationBatchFiller>
      <Trans
        i18nKey="allocations.modal.data.create.setMonthsBatch"
        components={{
          percentageField: (
            <LocalizedNumberTextField
              min={0}
              max={100}
              scale={2}
              textFieldProps={{
                InputProps: { endAdornment: "%" },
                value: percentage,
                setValue: (value) => setPercentage(value),
              }}
              disabled={isSubmitting}
            />
          ),
          autocompleteField: (
            <CustomAutocomplete
              autocompleteProps={{
                value: option,
                setValue: (value) => setOption(value),
                options,
                getOptionLabelMemo: (option) =>
                  option instanceof YearMonth
                    ? option.toPrettyString()
                    : option.label,
                isOptionEqualToValueMemo: (option, value) => {
                  if (option instanceof YearMonth && value instanceof YearMonth)
                    return option.equals(value);

                  if (
                    !(option instanceof YearMonth) &&
                    !(value instanceof YearMonth)
                  )
                    return option.id === value.id;

                  return false;
                },
                disabled: isSubmitting,
              }}
            />
          ),
        }}
      />
      <Button color="primary" onClick={onSubmit} disabled={isSubmitting}>
        {t("general.actions.general.set")}
      </Button>
    </StyledAllocationBatchFiller>
  );
};
