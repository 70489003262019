import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  schemaTimesheetMonthDayDTO,
  TimesheetMonthDayDTO,
} from "./TimesheetMonthDayDTO";
import {
  schemaTimesheetMonthDayTotalDTO,
  TimesheetMonthDayTotalDTO,
} from "./TimesheetMonthDayTotalDTO";

export interface TimesheetMonthWithOthersDTO {
  yearMonth: YearMonth;
  days: TimesheetMonthDayDTO[];
  otherProjectOrCostCenterDays: TimesheetMonthDayTotalDTO[];
}

export const schemaTimesheetMonthWithOthersDTO = yup.object({
  yearMonth: schemaYearMonth.defined(),
  days: yup.array(schemaTimesheetMonthDayDTO.defined()).defined(),
  otherProjectOrCostCenterDays: yup
    .array(schemaTimesheetMonthDayTotalDTO.defined())
    .defined(),
});
