import * as yup from "yup";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useMemo } from "react";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { BudgetDTO } from "../../../../../../../shared/specific/DTOs/Budget/BudgetDTO";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getBudgetById,
  updateBudget,
} from "../../../../../../../services/budget/budget.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { generalFieldsConstants } from "../../../../../../../shared/common/constants/data/form/fields.constants";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

interface FormValues {
  dolarValue: string;
}

export const useEditingDolarTaxForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: FormValues = {
      dolarValue: "",
    };

    const validationSchema = yup.object({
      dolarValue: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, []);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, BudgetDTO, BudgetDTO>({
      modal: {
        modalMode: "dialog",
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: budget }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{
              name: "",
            }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: budget,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const roundData = await getBudgetById(budget.id);

            if (!roundData)
              return setModalError(
                i18next.t("budget.errors.data.general.budgetDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(roundData);
            setFormValues({
              dolarValue: formatNumber(budget?.dolarTax, {
                fractionDigits: 4,
              }),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "budget.errors.data.fetch.failedToFetchBudget",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: () => (
          <LocalizedNumberTextFieldFormik
            name="dolarValue"
            scale={4}
            min={0}
            max={generalFieldsConstants.maxAmountValue}
            overwrite="shift"
            padFractionalZeros
            textFieldProps={{
              label: t("budget.keywords.fields.dolarTax"),
              InputProps: {
                startAdornment: "$",
              },
              fullWidth: true,
            }}
          />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: budget,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            await updateBudget({
              id: budget?.id ?? 0,
              dolarTax: parseLocaleNumber(formValues.dolarValue),
              version: budget?.version ?? "",
              year: budget?.year ?? 0,
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            notifyError(t("budget.errors.data.edit.failedToEditBudget"));
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
