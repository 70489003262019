import { useFormikContext } from "formik";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { SharedAllocationYearMonthCell } from "../../../../../../../shared/specific/react/components/form/formik/textField/fields/SharedAllocationYearMonthCell";

interface OwnProps {
  percentage?: number;
  disabled?: boolean;
  lineNumber: number;
  weekNumber: number;
  yearMonth: YearMonth;
}

export const AllocationYearMonthCell = ({
  percentage,
  disabled,
  lineNumber,
  weekNumber,
  yearMonth,
}: OwnProps) => {
  const { isSubmitting } = useFormikContext();

  return (
    <SharedAllocationYearMonthCell
      name={`allocationYearMonths[${lineNumber}].week${weekNumber}Percentage`}
      weekNumber={weekNumber}
      yearMonth={yearMonth}
      disabled={isSubmitting || disabled}
    />
  );
};
