import { styled } from "@mui/material";

export const StyledInfoContainer = styled("div")`
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: ${({ theme }) => theme.zIndex.mobileStepper + 1};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  flex-shrink: 0;
  height: 45px;
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.divider};
`;

export const StyledInfoItems = styled("div")`
  position: relative;
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  overflow: auto;
  max-width: 100%;
  flex-shrink: 0;
`;

export const StyledSubHeaderFixed = styled("div")`
  position: sticky;
  top: 43px;
  padding: 6px 16px 6px 16px;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: ${({ theme }) => theme.zIndex.mobileStepper + 1};
  display: flex;
  gap: ${({ theme }) => theme.spacing(3)};
  overflow: auto;
  max-width: 100%;
  flex-shrink: 0;
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.divider};
`;

export const StyledInfoItem = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  white-space: nowrap;

  line-height: 1.3;

  & > :first-child {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
  }
`;
