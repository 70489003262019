/* eslint-disable react/no-this-in-sfc */
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { FormValuesWeeklyAllocation } from "./types/form.types";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { generateWeeklyAllocationReport } from "../../../../../../../../../services/reports/reports.service";
import {
  StyledFormContainer,
  StyledViewContent,
  WarningText,
} from "./index.styles";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { CheckboxFormik } from "../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

export const WeeklyAllocation = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesWeeklyAllocation>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesWeeklyAllocation = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      userCollaborators: [],
      managerCollaborator: null,
      onlyAllocated: true,
      onlyActive: false,
    };
    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup.object().nullable(),
      userCollaborators: yup.array(yup.object()).nullable(),
      managerCollaborator: yup.object().nullable(),
      onlyAllocated: yup.boolean().required(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesWeeklyAllocation,
    { setSubmitting }: FormikHelpers<FormValuesWeeklyAllocation>
  ) => {
    if (!values.startYearMonth || !values.endYearMonth)
      throw new Error("All required fields must be filled.");

    try {
      const monthsApart =
        (values.endYearMonth.getFullYear() -
          values.startYearMonth.getFullYear()) *
          12 -
        values.startYearMonth.getMonth() +
        values.endYearMonth.getMonth();
      if (monthsApart > 1) {
        notifyIf4xxApiErrorDTO({
          error: "",
          defaultMessage:
            "allocationsMap.errors.data.general.outOfBoundsPeriod",
        });
      } else {
        await generateWeeklyAllocationReport({
          startYearMonth: YearMonth.createFromDate(values.startYearMonth),
          endYearMonth: YearMonth.createFromDate(values.endYearMonth),
          divisionName:
            values?.onlyAllocated === true && values?.division
              ? values?.division?.name
              : undefined,
          idDivision:
            values?.onlyAllocated === true &&
            values?.division &&
            typeof values?.division.id === "number"
              ? values.division.id
              : undefined,
          idsUserCollaborators: values.userCollaborators?.map((x) => x.id),
          idManagerCollaborator:
            values?.onlyAllocated === true
              ? values.managerCollaborator?.id
              : undefined,
          OnlyAllocatedCollaborators: values?.onlyAllocated,
        });
      }
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values, setValues }) => {
        return (
          <StyledViewContent>
            <StyledFormContainer>
              <YearMonthDatePickerFormik
                name="startYearMonth"
                datePickerProps={{
                  label: t("reports.pages.form.startPeriod"),
                  slotProps: {
                    popper: {
                      placement: "right",
                    },
                  },
                }}
                textFieldProps={{
                  required: true,
                }}
              />
              <YearMonthDatePickerFormik
                name="endYearMonth"
                datePickerProps={{
                  label: t("reports.pages.form.endPeriod"),
                  slotProps: {
                    popper: {
                      placement: "right",
                    },
                  },
                }}
                textFieldProps={{
                  required: true,
                }}
              />
              <DivisionsAutocompleteFormik
                name="division"
                autocompleteProps={{
                  disabled: !values.onlyAllocated,
                  value: !values.onlyAllocated ? null : values.division,
                }}
              />
              <UsersSearchAutocompleteFormik
                name="userCollaborators"
                filters={{
                  isActive: true,
                }}
                autocompleteProps={{
                  multiple: true,
                }}
              />
              <UsersSearchAutocompleteFormik
                name="managerCollaborator"
                textfieldProps={{
                  label: t("projects.keywords.fields.manager"),
                }}
                autocompleteProps={{
                  multiple: false,
                  disabled: !values.onlyAllocated,
                  value: !values.onlyAllocated
                    ? null
                    : values.managerCollaborator,
                }}
                filters={{
                  onlyManager: true,
                }}
              />
            </StyledFormContainer>
            <FormControlLabel
              label={t("reports.keywords.fields.onlyAllocated")}
              control={<CheckboxFormik name="onlyAllocated" />}
            />
            {!values.onlyAllocated && (
              <WarningText variant="subtitle1">
                {t("allocationsMap.keywords.form.warning")}
              </WarningText>
            )}
            <GenerateReportButton
              onClick={submitForm}
              isSubmitting={isSubmitting}
            />
            <LoadingBackdrop open={isSubmitting} />
          </StyledViewContent>
        );
      }}
    </Formik>
  );
};
