import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Location } from "react-router-dom";

import { applicationState, PageTitleSettings } from "./index.interface";

const initialState: applicationState = {
  pageTitle: {
    title: "",
    Component: null,
  },
  pagesHistory: [],
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setPageTitle: (state, action: PayloadAction<PageTitleSettings>) => {
      state.pageTitle = action.payload;
    },
    pushToPagesHistory: (state, action: PayloadAction<Location>) => {
      state.pagesHistory.push(action.payload);
    },
  },
});

export const { setPageTitle, pushToPagesHistory } = applicationSlice.actions;

export const applicationReducer = applicationSlice.reducer;
