import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const contextChallenge = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const contextChallengeLableCpt: CptProps = {
    text: "Context/Challenge",
    options: {
      align: pres.AlignH.left,
      y: "13%",
      x: "50%",
      w: "45%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const contextChallengeNameCpt: CptProps = {
    text: projectData.projectChallenges ?? "-",
    options: {
      align: pres.AlignH.left,
      y: "14%",
      x: "50%",
      w: "45%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: getFontSizeByString(projectData.projectChallenges ?? "-"),
    },
  };

  slide.addText(
    contextChallengeLableCpt.text,
    contextChallengeLableCpt.options
  );
  slide.addText(contextChallengeNameCpt.text, contextChallengeNameCpt.options);
};
