import * as yup from "yup";
import { ProjectBaselineType } from "../../enums/projectBaseline/ProjectBaselineType.enum";

export interface ProjectBaselineOnlyDTO {
  id: number;
  type: ProjectBaselineType | null;
  version: number;
  validatedBy: string;
}

export const schemaProjectBaselineOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  type: yup.number().integer().nullable().defined(),
  version: yup.number().integer().defined(),
  validatedBy: yup.string().defined(),
});
