import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { FilterValues } from "../../ProjectionMainPage/shared/types/filter.types";
import { ProjectionFilterContext } from "./ProjectionFilterContext";
import { ProjectionFilterValues } from "./ProjectionFilterValues";

export const ProjectionFilterProvider = () => {
  const [filter, setFilter] = useState<FilterValues>({
    subsidiary: null,
    division: null,
    clientAccount: "",
    customerTrigram: "",
    segmentGroup: null,
    year: null,
  });

  const projectionFilterContextValues: ProjectionFilterValues = useMemo(
    () => ({
      filterValues: filter,
      setFilterValues: setFilter,
    }),
    [filter]
  );

  return (
    <ProjectionFilterContext.Provider value={projectionFilterContextValues}>
      <Outlet />
    </ProjectionFilterContext.Provider>
  );
};
