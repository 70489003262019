import {
  ChevronLeft as ChevronLeftIcon,
  Folder as FolderIcon,
  Home as HomeIcon,
  Menu as MenuIcon,
  Groups as GroupsIcon,
} from "@mui/icons-material";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useWindowSize } from "../../../../shared/common/react/hooks/ui/useWindowSize";
import { LoggedLayoutContext } from "../shared/LoggedLayoutContext";
import {
  MenuTooltip,
  RiseVersion,
  SidebarButtons,
  SidebarContainer,
  SidebarFooter,
  SidebarHeader,
} from "./index.styles";
import { ListItemTranslationButton } from "./ListItemTranslationButton";
import { MobileListItemButton } from "./ListItemUserAreaButton";
import { RootState } from "../../../../store";
import { controllershipReadRestrictions } from "../../../shared/constants/routing/restrictions/controllershipRestrictions.constants";
import { managementReadRestrictions } from "../../../shared/constants/routing/restrictions/managementRestrictions.contants";
import { performanceReadRestrictions } from "../../../shared/constants/routing/restrictions/performanceRestrictions.constants";
import { MenuItem } from "./index.types";
import { checkPermissions } from "../../../../shared/specific/helpers/data/user/permissions/validators.helpers";
import { dashboardsViewReadRestrictions } from "../../../shared/constants/routing/restrictions/dashboardsViewRestrictions.constants";
import { peopleAndManagementReadRestrictions } from "../../../shared/constants/routing/restrictions/peopleAndManagementRestrictions.constants";
import { getMainEnviromentName } from "../../../../shared/specific/helpers/data/utils/enviroment/getEnviromentName";

export const Sidebar = () => {
  const { t } = useTranslation();
  const { isBreakpointSm, isBreakpointMd } = useWindowSize();
  const userData = useSelector((state: RootState) => state.user.userData);
  const { isMenuOpen, toggleIsMenuOpen } = useContext(LoggedLayoutContext);

  const menuItems = useMemo((): MenuItem[] => {
    return [
      {
        icon: <HomeIcon />,
        name: t("home.pages.main.title"),
        link: "/",
      },
      {
        icon: <FolderIcon />,
        name: t("management.pages.main.title"),
        link: "/management",
        restrictions: managementReadRestrictions,
      },
      {
        icon: <LocalPoliceIcon />,
        name: t("controllership.pages.main.title"),
        link: "/controllership",
        restrictions: controllershipReadRestrictions,
      },
      {
        icon: <TrendingUpIcon />,
        name: t("performance.pages.main.title"),
        link: "/performance",
        restrictions: performanceReadRestrictions,
      },
      {
        icon: <GroupsIcon />,
        name: t("peopleAndManagement.pages.main.title"),
        link: "/people-and-management",
        restrictions: peopleAndManagementReadRestrictions,
      },
      {
        icon: <AssessmentIcon />,
        name: t("reports.pages.main.title"),
        link: "/reports",
      },
      {
        icon: <DashboardIcon />,
        name: t("dashboardsView.pages.main.title"),
        link: "/dashboards-view",
        restrictions: dashboardsViewReadRestrictions,
      },
    ];
  }, [t]);

  const AddAdminPages = useCallback(() => {
    if (!userData?.isAdmin || menuItems.some((item) => item.link === "/admin"))
      return;

    menuItems.push({
      icon: <AdminPanelSettingsIcon />,
      name: t("adminPage.pages.main.title"),
      link: "/admin",
    });
  }, [menuItems, isMenuOpen, userData?.permissions, userData?.isAdmin]);

  const menuItemsComponents = useMemo(() => {
    AddAdminPages();
    return menuItems
      .filter((item) => {
        if (!item.restrictions) return true;
        if (!userData) return false;

        return checkPermissions({
          restrictions: item.restrictions,
          userPermissions: userData.permissions,
          isAdmin: userData.isAdmin,
        });
      })
      .map((item) => {
        const isExternalUrl = item.link.includes("https");

        return isExternalUrl ? (
          <MenuTooltip key={item.link} title={isMenuOpen ? "" : item.name}>
            <a href={item.link} target="_blank" rel="noreferrer">
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </a>
          </MenuTooltip>
        ) : (
          <MenuTooltip key={item.link} title={isMenuOpen ? "" : item.name}>
            <ListItemButton component={Link} to={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </MenuTooltip>
        );
      });
  }, [menuItems, isMenuOpen, userData?.permissions, userData?.isAdmin]);

  const mobileFooter = useMemo(() => {
    if (!isBreakpointMd) return null;

    return (
      <SidebarFooter>
        <ListItemTranslationButton />
        <MobileListItemButton />
      </SidebarFooter>
    );
  }, [isBreakpointMd]);

  const menuIcon = useMemo(() => {
    if (isBreakpointSm) {
      return <ChevronLeftIcon />;
    }

    return isMenuOpen ? <ChevronLeftIcon /> : <MenuIcon />;
  }, [isBreakpointSm, isMenuOpen]);

  return (
    <SidebarContainer variant="permanent" open={isMenuOpen}>
      <SidebarHeader open={isMenuOpen}>
        <ListItemButton onClick={toggleIsMenuOpen}>
          <ListItemIcon>{menuIcon}</ListItemIcon>
        </ListItemButton>
      </SidebarHeader>
      <SidebarButtons open={isMenuOpen}>{menuItemsComponents}</SidebarButtons>
      {mobileFooter}
      <RiseVersion ismenuopen={isMenuOpen ? isMenuOpen.toString() : "false"}>
        <div>{getMainEnviromentName()}</div>
        <div>{window.localStorage.getItem("RiseCurrentVersion")}</div>
      </RiseVersion>
    </SidebarContainer>
  );
};
