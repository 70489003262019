import { styled } from "@mui/material";

export const StyledForm = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledPercentageMessage = styled("div")`
  text-align: center;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;
