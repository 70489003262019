import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { ProjectsFiltersDTO } from "../../../../../../../DTOs/project/filters/ProjectsFiltersDTO";
import { getDropdownProjects } from "../../../../../../../../../services/projects/projects.service";
import { ProjectDropdownDTO } from "../../../../../../../DTOs/project/ProjectDropdownDTO";

export interface ProjectSearchAutocompleteFormikWithoutPermissionTypeProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    ProjectDropdownDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<ProjectsFiltersDTO, "name">;
  filtersMemo?: Omit<ProjectsFiltersDTO, "name">;
}

export const ProjectSearchAutocompleteFormikWithoutPermissionType = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: ProjectSearchAutocompleteFormikWithoutPermissionTypeProps<DisableClearable>) => {
  const { t } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getProjectsOnlyFinal = useCallback(
    async (name: string) => {
      const paginatedProjects = await getDropdownProjects({
        filters: { name, ...finalFilters },
        pagination: { pageSize: 10 },
      });

      return paginatedProjects.tableData;
    },
    [finalFilters]
  );

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("projects.errors.data.fetch.failedToFetchProjects")}
      fetchOptions={getProjectsOnlyFinal}
      textfieldProps={{
        label: t("projects.keywords.general.project"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
