import { ButtonBase, styled } from "@mui/material";

export const Container = styled("div")`
  height: 35px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 1rem;
`;
