import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormikProps,
  AutocompleteFormik,
} from "../../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { DescriptionTypes } from "../../types/form.types";
import { DescriptionTranslationCode } from "../../utils/DescriptionTranslationCode";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<DescriptionTypes>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const DescriptionAutoCompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        id: DescriptionTypes.NigthAdditional,
        label: t(DescriptionTranslationCode[DescriptionTypes.NigthAdditional]),
      },
      {
        id: DescriptionTypes.AccumulatedFifithPercent,
        label: t(
          DescriptionTranslationCode[DescriptionTypes.AccumulatedFifithPercent]
        ),
      },
      {
        id: DescriptionTypes.PurchaseDayOffBoarding,
        label: t(
          DescriptionTranslationCode[DescriptionTypes.PurchaseDayOffBoarding]
        ),
      },
      {
        id: DescriptionTypes.DobraPlatNavDoc,
        label: t(DescriptionTranslationCode[DescriptionTypes.DobraPlatNavDoc]),
      },
      {
        id: DescriptionTypes.Boarding,
        label: t(DescriptionTranslationCode[DescriptionTypes.Boarding]),
      },
      {
        id: DescriptionTypes.RemuneratedDayOff,
        label: t(
          DescriptionTranslationCode[DescriptionTypes.RemuneratedDayOff]
        ),
      },
      {
        id: DescriptionTypes.HoursFifithPercent,
        label: t(
          DescriptionTranslationCode[DescriptionTypes.HoursFifithPercent]
        ),
      },
      {
        id: DescriptionTypes.HoursHundredPercent,
        label: t(
          DescriptionTranslationCode[DescriptionTypes.HoursHundredPercent]
        ),
      },
      {
        id: DescriptionTypes.Dangerousness,
        label: t(DescriptionTranslationCode[DescriptionTypes.Dangerousness]),
      },
      {
        id: DescriptionTypes.OnNotice,
        label: t(DescriptionTranslationCode[DescriptionTypes.OnNotice]),
      },
    ];
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.description"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
