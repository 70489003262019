import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledUserHistoryFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c d"
    "e f"
    "g h";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(7)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g";
  }
`;
