import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enUS, ptBR } from "date-fns/locale";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../../shared/common/enums/locale/Language.enum";

interface OwnProps {
  children: ReactNode;
}

export const CustomLocalizationProvider = ({ children }: OwnProps) => {
  const { i18n } = useTranslation();

  const adapterLocale = useMemo(() => {
    const locales = {
      [Language.ptBR]: ptBR,
      [Language.enUS]: enUS,
    };

    return locales[i18n.language as Language] ?? locales[Language.ptBR];
  }, [i18n.language]);

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={adapterLocale}
    >
      {children}
    </LocalizationProvider>
  );
};
