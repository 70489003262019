import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

export const StyledTextButton = styled(LoadingButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  max-width: 200px;
  text-transform: none;
`;
