import * as yup from "yup";
import { InvoiceFilesTypeEnum } from "../../enums/invoiceFiles/InvoiceFilesTypeEnum";

export interface InvoiceFilesDTO {
  id: number;
  idInvoice: number;
  invoiceFilesType: InvoiceFilesTypeEnum;
  idProject: number;
  filePath: string;
  responsibleLogin: string;
}

export const schemaInvoiceFilesDTO = yup.object({
  id: yup.number().integer().defined(),
  idInvoice: yup.number().integer().defined(),
  invoiceFilesType: yup.number().integer().defined(),
  idProject: yup.number().integer().defined(),
  filePath: yup.string().defined(),
  responsibleLogin: yup.string().defined(),
});
