import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import { CommonAdditivesFormValues } from "../../types/values.types";
import { FormContext } from "../../contexts/FormContext";
import { CenteredLoading } from "../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { CenteredContent } from "../../../../../../../shared/common/react/components/ui/CenteredContent";
import { InternalFormContent } from "./InternalFormContent";
import { LoadingBackdrop } from "../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { InternalFormFooter } from "./InternalFormFooter";
import { StyledInternalFormikForm } from "./index.styles";

export const InternalFormikForm = (
  formikProps: FormikProps<CommonAdditivesFormValues>
) => {
  const { t } = useTranslation();
  const { loading, error } = useContext(FormContext);

  const { values, submitForm, isSubmitting } = formikProps;

  const pageContent = useMemo(() => {
    if (loading) return <CenteredLoading />;
    if (error)
      return (
        <CenteredContent>
          {typeof error === "string" ? t(error) : error}
        </CenteredContent>
      );

    return <InternalFormContent {...formikProps} />;
  }, [t, loading, error, values]);

  return (
    <>
      <StyledInternalFormikForm>
        {pageContent}
        <InternalFormFooter
          isSubmitting={isSubmitting}
          submitForm={submitForm}
        />
      </StyledInternalFormikForm>
      <LoadingBackdrop open={isSubmitting} />
    </>
  );
};
