import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { ProjectDTO, schemaProjectDTO } from "./ProjectDTO";

export type PaginatedProjectDTO = PaginationResponse<ProjectDTO>;

export const schemaPaginatedProjectDTO =
  getSchemaPaginationResponse<ProjectDTO>(
    schemaProjectDTO as yup.Schema<ProjectDTO>
  );
