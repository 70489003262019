import i18next from "i18next";
import { parseHourStringToMinutes } from "../../../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { TimesheetMonthDayTotalDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDayTotalDTO";
import { DaysTimesheetValues } from "../../../types/data/form/values.types";

export const validateDaysTimesheetValuesExceeded = (
  entries: DaysTimesheetValues[],
  daysOtherProjects: TimesheetMonthDayTotalDTO[] | null,
  setFieldError: (field: string, message: string | undefined) => void
) => {
  if (!daysOtherProjects) return false;

  let hasFoundErrors = false;
  entries.forEach((entry, i) => {
    const findEntryOtherProjects = daysOtherProjects.find(
      (x) => x.entryDate.toDate().getDate() === entry.day
    );
    if (!findEntryOtherProjects) return;

    const entryMinutes = parseHourStringToMinutes(entry.time);
    if (entryMinutes + findEntryOtherProjects.minutes <= 24 * 60) return;

    hasFoundErrors = true;
    setFieldError(
      `days[${i}].time`,
      i18next.t(
        "timesheets.errors.data.fields.minutes.totalHoursWouldGreater24Hours"
      )
    );
  });

  return hasFoundErrors;
};
