import { PermissionType } from "../../../../../../../../../../../enums/users/permissions/PermissionType.enum";

export const permissionTypeToTranslationCodeMap: {
  readonly [key in PermissionType]: string;
} = {
  [PermissionType.CostCenter]: "costCenters.keywords.general.costCenters",
  [PermissionType.Customer]: "customers.keywords.general.customers",
  [PermissionType.Project]: "projects.keywords.general.projects",
  [PermissionType.JobRole]: "jobRoles.keywords.general.jobRoles",
  [PermissionType.CostCenterPeps]:
    "permissionTypes.keywords.fields.costCenterPeps",
  [PermissionType.JobRate]: "permissionTypes.keywords.fields.jobRates",
  [PermissionType.ContractRole]: "contractRole.keywords.general.contractRoles",
  [PermissionType.Allocation]: "permissionTypes.keywords.fields.allocation",
  [PermissionType.UserCollaborator]:
    "usersCollaborators.keywords.general.collaborators",
  [PermissionType.Activity]: "permissionTypes.keywords.fields.activity",
  [PermissionType.Invoice]: "permissionTypes.keywords.fields.invoice",
  [PermissionType.Additive]: "permissionTypes.keywords.fields.additive",
  [PermissionType.BrlParity]: "brlParity.keywords.general.exchangeRates",
  [PermissionType.Expenses]: "permissionTypes.keywords.fields.expenses",
  [PermissionType.Frame]: "frames.keywords.general.frames",
  [PermissionType.ProjectBaselines]:
    "permissionTypes.keywords.fields.projectBaselines",
  [PermissionType.CorporateAllocations]:
    "permissionTypes.keywords.fields.corporateAllocations",
  [PermissionType.CollaboratorHistory]:
    "permissionTypes.keywords.fields.collaboratorHistory",
  [PermissionType.AuthorizationControl]:
    "accessGroups.keywords.general.accessGroups",
  [PermissionType.DashBoards]: "dashboardsView.pages.main.title",
  [PermissionType.AllocationsMap]: "allocationsMap.pages.main.title",
  [PermissionType.Workload]: "workloads.keywords.general.workload",
  [PermissionType.Discipline]:
    "skillsandCertifications.keywords.permission.disicpline",
  [PermissionType.CorporateSegment]:
    "corporateSegment.keywords.general.corporateSegments",
  [PermissionType.CollaboratorAllocation]:
    "collaboratorAllocations.keywords.general.collaboratorAllocations",
  [PermissionType.InvoiceModule]: "invoice.keywords.general.invoice",
  [PermissionType.ResultsProjection]: "projection.pages.main.title",
  [PermissionType.ProjectRegister]: "pipedrive.keywords.general.pojectRegister",
  [PermissionType.SkillCategory]:
    "skillsandCertifications.keywords.permission.categories",
  [PermissionType.Budget]: "budget.pages.main.title",
  [PermissionType.Skill]: "skillsandCertifications.keywords.permission.skills",
  [PermissionType.SkillCertification]:
    "skillsandCertifications.keywords.permission.certifications",
  [PermissionType.CollaboratorManagement]:
    "collaboratorManagement.general.main.title",
  [PermissionType.MonthlyAllocationClosing]:
    "monthlyAllocationClosing.general.main.title",
  [PermissionType.CostDistribution]: "costDistribution.general.main.title",
  [PermissionType.CustomerBalance]:
    "customerBalance.pages.main.loadPerssionType",
  [PermissionType.Wacc]: "wacc.pages.main.loadPerssionType",
  [PermissionType.AdditiveRegister]:
    "pipedrive.keywords.general.additiveRegister",
  [PermissionType.FeeTypes]: "permissionTypes.keywords.fields.feeTypes",
  [PermissionType.FeePeriod]: "permissionTypes.keywords.fields.feePeriod",
  [PermissionType.FeeSummary]: "permissionTypes.keywords.fields.feeSummary",

  [PermissionType.CanEditPepAfterPlanning]:
    "permissionTypes.keywords.fields.canEditPepAfterPlanning",
  [PermissionType.CanEditAllActivities]:
    "permissionTypes.keywords.fields.canEditAllActivities",
  [PermissionType.CanEditAllInvoices]:
    "permissionTypes.keywords.fields.canEditAllInvoices",
  [PermissionType.CanEditAllAllocations]:
    "permissionTypes.keywords.fields.canEditAllAllocations",
  [PermissionType.CanEditAllExpenses]:
    "permissionTypes.keywords.fields.canEditAllExpenses",
  [PermissionType.CanReopenProject]:
    "permissionTypes.keywords.fields.canReopenProject",
  [PermissionType.CanEditAllCorporateAllocations]:
    "permissionTypes.keywords.fields.canEditAllCorporateAllocations",
  [PermissionType.CanEditPastTimesheet]:
    "permissionTypes.keywords.fields.canEditPastTimesheet",
  [PermissionType.CanUploadExpenses]:
    "permissionTypes.keywords.fields.canUploadExpenses",
  [PermissionType.CanUploadCorporateAllocations]:
    "permissionTypes.keywords.fields.canUploadCorporateAllocations",
  [PermissionType.CanUploadFinancialCost]:
    "permissionTypes.keywords.fields.canUploadFinancialCost",
  [PermissionType.CanUploadProjectAllocations]:
    "permissionTypes.keywords.fields.canUploadProjectAllocations",
  [PermissionType.CanAccessDeallocationInCorporateAllocation]:
    "permissionTypes.keywords.fields.canAccessDeallocationInCorporateAllocation",
  [PermissionType.CanUploadPaidOutInvoices]:
    "permissionTypes.keywords.fields.canUploadPaidOutInvoices",
  [PermissionType.CanEditPastProjectionResult]:
    "permissionTypes.keywords.fields.canEditPastProjectionResult",
  [PermissionType.CanEditOngoingProjectTypeOfEvidence]:
    "permissionTypes.keywords.fields.canEditOngoingProjectTypeOfEvidence",
  [PermissionType.CanUploadHandlingLoad]:
    "permissionTypes.keywords.fields.canUploadHandlingLoad",
  [PermissionType.CanEditApprovedCredit]:
    "permissionTypes.keywords.fields.canEditApprovedCredit",
  [PermissionType.CanEditCollaboratorAndTrackWithoutRestriction]:
    "permissionTypes.keywords.fields.canEditCollaboratorAndTrackWithoutRestriction",
  [PermissionType.CanAccessLeaveInCorporateAllocation]:
    "permissionTypes.keywords.fields.canAccessLeaveInCorporateAllocation",
  [PermissionType.CanAccessTrainingInCorporateAllocation]:
    "permissionTypes.keywords.fields.canAccessTrainingInCorporateAllocation",
  [PermissionType.CanUploadLedgerAccount]: "ledgerAccount.pages.main.title",
  [PermissionType.CanUploadCustomerBalance]:
    "customerBalance.pages.main.loadPerssionType",
  [PermissionType.CanAccessCalamityCorporateSegment]:
    "permissionTypes.keywords.fields.canAccessCalamityCorporateSegment",
  [PermissionType.CanAccessHECompensationCorporateSegment]:
    "permissionTypes.keywords.fields.canAccessHECompensationCorporateSegment",
  [PermissionType.CanAccessInefficiencyCorporateSegment]:
    "permissionTypes.keywords.fields.canAccessInefficiencyCorporateSegment",

  [PermissionType.CanSubmitPerformedTimesheetReport]:
    "permissionTypes.keywords.fields.performedTimesheet",
  [PermissionType.CanSubmitGlobalAllocationReport]:
    "permissionTypes.keywords.fields.globalAllocation",
  [PermissionType.CanSubmitProjectTimesheetReport]:
    "permissionTypes.keywords.fields.projectTimesheet",
  [PermissionType.CanSubmitRevenueAndBillingReport]:
    "permissionTypes.keywords.fields.revenueAndBilling",
  [PermissionType.CanSubmitEAPReport]:
    "permissionTypes.keywords.fields.eapReport",
  [PermissionType.CanSubmitProjectOverviewReport]:
    "permissionTypes.keywords.fields.projectsOverview",
  [PermissionType.CanSubmitProjectDetailedViewReport]:
    "permissionTypes.keywords.fields.datailedProjectsOverview",
  [PermissionType.CanSubmitProjectStatusReport]:
    "permissionTypes.keywords.fields.statusOfProjects",
  [PermissionType.CanSubmitProjectsAndAdditivesCreationReport]:
    "permissionTypes.keywords.fields.creationProjectsAdditives",
  [PermissionType.CanSubmitProjectMVsReport]:
    "permissionTypes.keywords.fields.projectMVs",
  [PermissionType.CanSubmitWeeklyAllocationReport]:
    "permissionTypes.keywords.fields.weeklyAllocation",
  [PermissionType.CanSubmitProvisionReport]:
    "permissionTypes.keywords.fields.provisionReport",
  [PermissionType.CanSubmitBaseCollaboratorReport]:
    "permissionTypes.keywords.fields.baseCollaboratorReport",
  [PermissionType.CanSubmitPepElementReport]:
    "permissionTypes.keywords.fields.pepElementReport",
  [PermissionType.CanSubmitDreQueue]:
    "permissionTypes.keywords.fields.dreQueue",
  [PermissionType.CanSubmitBudgetOverviewReport]:
    "permissionTypes.keywords.fields.budgetOverview",
  [PermissionType.CanSubmitPlannedAndPerformedByPepReport]:
    "permissionTypes.keywords.fields.plannedAndPerformedByPepReport",
  [PermissionType.CanSubmitProjectionQueue]:
    "permissionTypes.keywords.fields.projectionQueue",
  [PermissionType.CanSubmitBillingVisionReport]:
    "permissionTypes.keywords.fields.billingVisionReport",
  [PermissionType.CanSubmitCostMdoReport]:
    "permissionTypes.keywords.fields.workforceCost",
  [PermissionType.CanSubmitZahReport]: "permissionTypes.keywords.fields.zah",
  [PermissionType.CanSubmitTurnoverDaysReport]:
    "permissionTypes.keywords.fields.turnoverDays",
  [PermissionType.CanSubmitMvWithFinancialCostReport]:
    "permissionTypes.keywords.fields.mvWithFinancialCost",
};
