import {
  addMonths,
  addYears,
  differenceInCalendarMonths,
  differenceInMonths,
  endOfMonth,
  isValid,
  parse,
} from "date-fns";
import * as yup from "yup";
import { ValueError } from "../../../errors/general/ValueError";

export class YearMonth {
  public readonly month: number;
  public readonly year: number;

  constructor(month: number, year: number) {
    this.month = month;
    this.year = year;
  }

  public static createFromNow = () => {
    return YearMonth.createFromDate(new Date());
  };

  public static createFromDate = (date: Date) => {
    const dateToCreate = new Date(date);
    return new YearMonth(dateToCreate.getMonth(), dateToCreate.getFullYear());
  };

  public static createFromString = (date: string) => {
    const parsedDate = parse(date, "yyyy-MM", new Date());

    if (!isValid(parsedDate)) throw new ValueError("Invalid date format.");

    return YearMonth.createFromDate(parsedDate);
  };

  public getTimestamp = () => {
    return this.toDate().getTime();
  };

  public addMonths = (months: number) => {
    return YearMonth.createFromDate(addMonths(this.toDate(), months));
  };

  public addYears = (years: number) => {
    return YearMonth.createFromDate(addYears(this.toDate(), years));
  };

  public toDate = (day = 1) => {
    return new Date(this.year, this.month, day);
  };

  public toDateEndOfMonth = () => {
    return endOfMonth(this.toDate());
  };

  public toJSON = () => {
    return this.toString();
  };

  public toString = () => {
    const month = String(this.month + 1).padStart(2, "0");
    const year = String(this.year).padStart(4, "0");

    return `${year}-${month}`;
  };

  public toPrettyString = () => {
    const month = String(this.month + 1).padStart(2, "0");
    const year = String(this.year).padStart(4, "0");

    return `${month}/${year}`;
  };

  public equals = (other: YearMonth) => {
    return this.year === other.year && this.month === other.month;
  };

  public greaterThan = (other: YearMonth) => {
    return (
      this.year > other.year ||
      (this.year === other.year && this.month > other.month)
    );
  };

  public lessThan = (other: YearMonth) => {
    return (
      this.year < other.year ||
      (this.year === other.year && this.month < other.month)
    );
  };

  public greaterThanOrEqual = (other: YearMonth) => {
    return this.equals(other) || this.greaterThan(other);
  };

  public lessThanOrEqual = (other: YearMonth) => {
    return this.equals(other) || this.lessThan(other);
  };

  public differenceMonths = (other: YearMonth): number => {
    return differenceInMonths(this.toDate(), other.toDate());
  };

  public differenceCalendarMonths = (other: YearMonth): number => {
    return differenceInCalendarMonths(this.toDate(), other.toDate());
  };

  public static min = (...yearMonths: (YearMonth | null | undefined)[]) => {
    const yearMonthsNotNull = yearMonths.filter((x) => x) as YearMonth[];

    if (yearMonthsNotNull.length === 0) return null;

    let minYearMonth = yearMonthsNotNull[0];
    for (const yearMonth of yearMonthsNotNull.slice(1)) {
      if (yearMonth.lessThan(minYearMonth)) {
        minYearMonth = yearMonth;
      }
    }

    return minYearMonth;
  };

  public static max = (...yearMonths: (YearMonth | null | undefined)[]) => {
    const yearMonthsNotNull = yearMonths.filter((x) => x) as YearMonth[];

    if (yearMonthsNotNull.length === 0) return null;

    let maxYearMonth = yearMonthsNotNull[0];
    for (const yearMonth of yearMonthsNotNull.slice(1)) {
      if (yearMonth.greaterThan(maxYearMonth)) {
        maxYearMonth = yearMonth;
      }
    }

    return maxYearMonth;
  };

  public static range = (
    startYearMonth: YearMonth,
    endYearMonth: YearMonth
  ): YearMonth[] => {
    let current = startYearMonth;
    const range: YearMonth[] = [];

    while (current.lessThanOrEqual(endYearMonth)) {
      range.push(new YearMonth(current.month, current.year));
      current = current.addMonths(1);
    }

    return range;
  };
}

export const schemaYearMonth = yup
  .object({
    month: yup.number(),
    year: yup.number(),
    toString: yup.object(),
  })
  .transform((value, originalValue) => {
    if (originalValue === null) return null;
    if (originalValue instanceof Date)
      return YearMonth.createFromDate(originalValue);
    if (originalValue instanceof YearMonth) return originalValue;

    if (typeof originalValue !== "string")
      throw new yup.ValidationError("yearMonth.invalid", originalValue);

    try {
      return YearMonth.createFromString(originalValue);
    } catch (error) {
      console.error(error);
      throw new yup.ValidationError("yearMonth.invalid", originalValue);
    }
  }) as unknown as yup.ObjectSchema<YearMonth>;
