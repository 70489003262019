import { Groups as GroupsIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  SimpleIconButton,
  SimpleIconButtonProps,
} from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { SomePartial } from "../../../../../../../../../shared/common/types/core/typescript.types";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const GroupsIconButton = ({
  tooltipText = "accessGroups.actions.general.manageUserAccessGroup",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={GroupsIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
