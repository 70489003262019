import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getProjectOnlyById } from "../../../../../services/projects/projects.service";
import { CenteredContent } from "../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { ProjectBaselinesWithProject } from "./ProjectBaselinesWithProject";

type OwnParams = {
  idProject: string;
};

export const ProjectBaselines = () => {
  const { t } = useTranslation();

  const { idProject: idProjectString = "" } = useParams<OwnParams>();

  const idProject = useMemo(() => {
    return /^\d+$/.test(idProjectString) ? parseInt(idProjectString) : null;
  }, [idProjectString]);

  const {
    value: project,
    hasError: hasErrorProject,
    isLoading: isLoadingProject,
  } = useLoadLatest({
    initialValue: null,
    getValue: async () => {
      if (idProject === null) return null;
      return getProjectOnlyById(idProject);
    },
    onError: ({ setValue }) => setValue(null),
  });

  if (idProject === null)
    return (
      <CenteredContent>
        {t("general.errors.data.fields.id.invalid")}
      </CenteredContent>
    );

  if (hasErrorProject)
    return (
      <CenteredContent>
        {t("projects.errors.data.fetch.failedToFetchProject")}
      </CenteredContent>
    );
  if (isLoadingProject) return <CenteredLoading />;
  if (!project)
    return (
      <CenteredContent>
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );

  return <ProjectBaselinesWithProject project={project} />;
};
