import { styled } from "@mui/material";

export const StyledMainContainerApplyLastInformation = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSubContainer = styled("div")`
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  padding: 1rem;
`;

export const StyledTextContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  p {
    font-weight: 400;
    font-size: 1.5rem;
  }
`;

export const StyledQuestionContainer = styled("div")`
  min-width: ${({ theme }) => theme.spacing(29.4)};
`;

export const StyledButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 10px;
`;
