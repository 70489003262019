import { PlayCircleFilled as PlayCircleFilledIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { reopenActivity } from "../../../../../../../../../services/activities/activities.service";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { ActivityDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { StyledContent } from "./index.styles";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

export const useReopenActivityForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const {
    ModalButtonContainer: ReopeningButtonContainer,
    contentModal: reopeningModal,
  } = useActionButtonDialog<ActivityDTO>({
    modal: {
      modalTitle: t("activities.modal.data.reopen.title"),
      internalLoadingPosition: "title",
      ModalContentMemo: ({ internalModalData: activity }) => {
        const { t } = useTranslation();

        return (
          <StyledContent>
            <TextField
              label={t("general.keywords.fields.description")}
              value={activity?.description ?? "-"}
              disabled
            />
          </StyledContent>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <SimpleIconButton
          Icon={PlayCircleFilledIcon}
          color="primary"
          tooltipText={t("activities.actions.general.reopenActivity")}
          onClick={openModal}
        />
      ),
      onClickContentButtonComponentMemo: ({
        contentButtonContentProps: externalActivity,
        setInternalModalData,
      }) => {
        setInternalModalData(externalActivity);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("activities.actions.general.reopenActivity"),
      onConfirmMemo: async ({ closeModal, internalModalData: activity }) => {
        if (!activity) throw new Error("Activity cannot be null on submit.");

        try {
          await reopenActivity({
            idProject: project.id,
            id: activity.id,
            version: activity.version,
          });
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          reloadActivitySummary();
          closeModal();

          if (project.isValid) reloadProject();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            i18next.t("activities.errors.data.reopen.failedToReopenActivity")
          );
        }
      },
    },
  });

  return { ReopeningButtonContainer, reopeningModal };
};
