import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { SkillDTO } from "../../../../../../../shared/specific/DTOs/skill/SkillDTO";
import {
  deleteSkill,
  getSkillById,
} from "../../../../../../../services/skill/skill.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: skill }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{ name: skill?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData: null as SkillDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: skillData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const skillDTO = await getSkillById(skillData.id);
          if (!skillDTO)
            return setModalError(
              i18next.t("skill.errors.data.general.skillDoesNotExist")
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(skillDTO);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "skill.errors.data.fetch.failedToFetchSkill",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: skill }) => {
        if (!skill) throw new Error("Skill  cannot be null.");

        try {
          await deleteSkill(skill.id, skill.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!skill?.name)
            return notifyError(
              i18next.t("skill.errors.data.delete.failedToDelete")
            );

          notifyError(
            <Trans
              i18nKey="skill.errors.data.delete.failedToDelete"
              values={{ name: skill.name }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
