import { styled } from "@mui/material";

export const StyledItems = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const StyledItem = styled("div")`
  display: flex;
  align-items: center;
  line-height: 1;
  gap: ${({ theme }) => theme.spacing(0.5)};

  & > :nth-child(2) {
    font-weight: 500;
    margin-top: 0.2rem;
  }
`;

export const StyledValidItem = styled(StyledItem)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const StyledInvalidItem = styled(StyledItem)`
  color: ${({ theme }) => theme.palette.error.main};
`;
