import { TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { TimesheetType } from "../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { ProjectSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormikWithoutPermissionType";
import {
  FormValues,
  InternalFormData,
} from "../shared/types/data/form/values.types";
import {
  TimesheetTypeAutocompleteFormik,
  TimesheetTypeAutocompleteFormikRef,
} from "../../../shared/react/components/form/formik/autocomplete/TimesheetTypeAutocompleteFormik";
import { StyledForm } from "./index.styles";
import { TimesheetCalendarForm } from "../../../shared/react/components/content/TimesheetCalendarForm";
import { LoadingBackdrop } from "../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { useLoadLatest } from "../../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { getTimesheetMonthSingleEntry } from "../../../../../../../../services/timesheet/timesheets.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { DaysTimesheetValues } from "../../../shared/types/data/form/values.types";
import { formatMinutesToHourMinute } from "../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { useFormContentContext } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { generateDaysForMonth } from "../../../shared/helpers/data/calendar/generators.helpers";
import { CorporateSegmentsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/CorporateSegmentsAutocompleteFormik";
import { CostCentersSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormikWithoutPermissionType";
import { ProjectStatus } from "../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { TimesheetManagementAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/timesheet/TimesheetManagementAllocationTypeAutocompleteFormik";
import { TimesheetCostCenterAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/timesheet/TimesheetCostCenterAllocationTypeAutocompleteFormik";
import { ProjectAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/project/ProjectAllocationTypeAutocompleteFormik";
import { TimesheetCorporateAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/timesheet/TimesheetCorporateAllocationTypeAutocompleteFormik";

export const CreationForm = () => {
  const { t } = useTranslation();
  const { setInternalFormData, internalFormData } = useFormContentContext<
    FormValues,
    InternalFormData
  >();
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<FormValues>();

  const timesheetTypeAutocompleteRef =
    useRef<TimesheetTypeAutocompleteFormikRef>(null);

  const isTimesheetTypeDisabled =
    isSubmitting ||
    (values.allocationType?.id !== EmployeeAllocationType.Allocation &&
      values.allocationType?.id !== EmployeeAllocationType.Support);

  const projectCostCenterDropdown = useMemo(() => {
    if (
      values.allocationType &&
      values.allocationType?.id !== EmployeeAllocationType.Allocation &&
      values.allocationType?.id !== EmployeeAllocationType.Support &&
      values.allocationType?.id !== EmployeeAllocationType.MonthlyOnboarding &&
      values.allocationType?.id !==
        EmployeeAllocationType.PerformanceEvaluation &&
      !values.corporateSegment &&
      values.allocationType?.id !== EmployeeAllocationType.CorporateMeeting
    )
      return (
        <TextField
          label={t("timesheets.keywords.fields.costCenterForYearMonth", {
            yearMonth: values.yearMonth.toPrettyString(),
          })}
          disabled
        />
      );

    if (
      values.allocationType &&
      values.allocationType?.id !== EmployeeAllocationType.Allocation &&
      values.allocationType?.id !== EmployeeAllocationType.Support &&
      values.corporateSegment
    )
      return (
        <TextField
          label={t(
            "timesheets.info.data.calendar.costCenterForSegmentSelected"
          )}
          disabled
        />
      );

    switch (values.timesheetType?.id) {
      case TimesheetType.Project:
        return (
          <ProjectSearchAutocompleteFormikWithoutPermissionType
            name="project"
            autocompleteProps={{
              disabled: isSubmitting,
            }}
            filters={{
              statusIncludeOnly: [
                ProjectStatus.Created,
                ProjectStatus.Planning,
                ProjectStatus.Ongoing,
                ProjectStatus.Canceled,
              ],
            }}
          />
        );
      case TimesheetType.CostCenter:
        return (
          <CostCentersSearchAutocompleteFormikWithoutPermissionType
            name="costCenter"
            autocompleteProps={{
              disabled: isTimesheetTypeDisabled,
            }}
            filters={{
              isBlockAllocationCostCenters:
                values.allocationType?.id === EmployeeAllocationType.Allocation,
            }}
          />
        );
      case TimesheetType.Management:
        break;
      case TimesheetType.Corporate:
        break;
      default:
        return (
          <TextField
            label={t("timesheets.keywords.fields.projectCostCenter")}
            disabled
          />
        );
    }
  }, [
    t,
    values.timesheetType?.id,
    values.allocationType?.id,
    values.corporateSegment,
    values.yearMonth,
    isSubmitting,
    isTimesheetTypeDisabled,
  ]);

  const costCenterFilter = useMemo(() => {
    if (
      values.allocationType?.id === EmployeeAllocationType.Support &&
      values.costCenter
    ) {
      return values.costCenter.id;
    }
    return undefined;
  }, [values.allocationType, values.costCenter]);

  const yearMonthField = useMemo(() => {
    return (
      <TextField
        value={values.yearMonth.toPrettyString()}
        label={t("general.keywords.dateTime.general.month")}
        disabled
      />
    );
  }, [t, values.yearMonth]);

  const AllocationTypeDrop = useMemo(() => {
    if (values.timesheetType?.id === TimesheetType.Project) {
      return (
        <ProjectAllocationTypeAutocompleteFormik
          name="allocationType"
          autocompleteProps={{
            disabled: true,
          }}
          textfieldProps={{
            label: t("general.keywords.fields.nature"),
          }}
        />
      );
    }
    if (values.timesheetType?.id === TimesheetType.Management) {
      return (
        <TimesheetManagementAllocationTypeAutocompleteFormik
          name="allocationType"
          autocompleteProps={{
            disabled: isSubmitting || !values.timesheetType,
          }}
          textfieldProps={{
            label: t("general.keywords.fields.nature"),
          }}
        />
      );
    }
    if (values.timesheetType?.id === TimesheetType.CostCenter) {
      return (
        <TimesheetCostCenterAllocationTypeAutocompleteFormik
          name="allocationType"
          autocompleteProps={{
            disabled: isSubmitting || !values.timesheetType,
          }}
          textfieldProps={{
            label: t("general.keywords.fields.nature"),
          }}
        />
      );
    }
    if (values.timesheetType?.id === TimesheetType.Corporate) {
      return (
        <TimesheetCorporateAllocationTypeAutocompleteFormik
          name="allocationType"
          autocompleteProps={{
            disabled: isSubmitting || !values.timesheetType,
          }}
          textfieldProps={{
            label: t("general.keywords.fields.nature"),
          }}
        />
      );
    }
  }, [values.timesheetType?.id, isSubmitting]);

  const { value: timesheetMonth, isLoading: isLoadingTimesheetMonth } =
    useLoadLatest({
      getValue: async () => {
        if (!values.allocationType) return null;

        if (
          !values.costCenter &&
          !values.project &&
          (values.allocationType.id === EmployeeAllocationType.Allocation ||
            values.allocationType.id === EmployeeAllocationType.Support)
        )
          return null;

        return getTimesheetMonthSingleEntry({
          yearMonth: values.yearMonth,
          allocationType: values.allocationType.id,
          idCostCenter: values.costCenter?.id,
          idProject: values.project?.id,
          idCorporateSegment: values.corporateSegment?.id,
        });
      },
      initialValue: null,
      onSuccess: ({ value }) => {
        setInternalFormData((x) => {
          if (!x) return x;
          return {
            ...x,
            timesheetMonthWithOthers: value,
          };
        });
      },
      onError: ({ setValue, error }) => {
        setValue(null);
        notifyIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "timesheets.errors.data.fetch.failedToFetchTimesheetData",
        });
      },
      shouldLoadOnMount: false,
      dependencies: [
        values.costCenter,
        values.project,
        values.yearMonth,
        values.allocationType,
        values.corporateSegment,
      ],
    });

  useEffectAfterRenders({
    effect: () => {
      switch (values.timesheetType?.id) {
        case TimesheetType.Project:
          setFieldValue("allocationType", {
            id: EmployeeAllocationType.Allocation,
            label: t(
              employeeAllocationTypeToTranslationCodeMap[
                EmployeeAllocationType.Allocation
              ]
            ),
          });
          setFieldValue("costCenter", null);
          break;
        case TimesheetType.CostCenter:
          setFieldValue("allocationType", null);
          setFieldValue("project", null);
          break;
        case TimesheetType.Management:
          setFieldValue("allocationType", {
            id: EmployeeAllocationType.PerformanceEvaluation,
            label: t(
              employeeAllocationTypeToTranslationCodeMap[
                EmployeeAllocationType.PerformanceEvaluation
              ]
            ),
          });
          setFieldValue("costCenter", null);
          break;
        case TimesheetType.Corporate:
          setFieldValue("allocationType", {
            id: EmployeeAllocationType.CorporateMeeting,
            label: t(
              employeeAllocationTypeToTranslationCodeMap[
                EmployeeAllocationType.CorporateMeeting
              ]
            ),
          });
          setFieldValue("costCenter", null);
          break;
      }
    },
    deps: [values.timesheetType?.id],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("corporateSegment", null);
      setFieldValue("costCenter", null);
    },
    deps: [values.allocationType],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      if (!internalFormData) return;

      setInternalFormData({
        ...internalFormData,
        isCalendarVisible: Boolean(
          values.timesheetType && (values.costCenter || values.project)
        ),
      });
    },
    deps: [values.timesheetType, values.costCenter, values.project],
    rendersBeforeEffect: 1,
  });

  useEffect(() => {
    if (!timesheetMonth) return;

    const daysEntries = generateDaysForMonth(values.yearMonth);

    const daysEntriesWithTimesheetData = daysEntries.map(
      (emptyTimesheetDay): DaysTimesheetValues => {
        const newTimesheetDay = timesheetMonth.days.find(
          (timesheetDay) => timesheetDay.entryDate.day === emptyTimesheetDay.day
        );

        if (!newTimesheetDay) return emptyTimesheetDay;

        return {
          id: newTimesheetDay.id,
          version: newTimesheetDay.version,
          day: newTimesheetDay.entryDate.day,
          time: formatMinutesToHourMinute(newTimesheetDay.minutes),
        };
      }
    );

    for (const dayEntry of daysEntriesWithTimesheetData) {
      setFieldValue(
        `days[${dayEntry.day - 1}]`,
        convertTo<DaysTimesheetValues>({
          id: dayEntry.id,
          version: dayEntry.version,
          day: dayEntry.day,
          time: dayEntry.time,
        })
      );
    }
  }, [timesheetMonth]);

  return (
    <>
      <StyledForm
        isProject={
          values.timesheetType?.id !== TimesheetType.Management &&
          values.timesheetType?.id !== TimesheetType.Corporate
        }
      >
        <TimesheetTypeAutocompleteFormik
          name="timesheetType"
          autocompleteProps={{
            disableClearable: true,
          }}
          innerRef={timesheetTypeAutocompleteRef}
        />
        {AllocationTypeDrop}
        {yearMonthField}
        {projectCostCenterDropdown}
        {values.timesheetType?.id !== TimesheetType.Management &&
          values.timesheetType?.id !== TimesheetType.Corporate && (
            <CorporateSegmentsAutocompleteFormik
              name="corporateSegment"
              autocompleteProps={{
                disabled:
                  isSubmitting ||
                  !values.allocationType ||
                  values.allocationType.id ===
                    EmployeeAllocationType.Allocation ||
                  values.allocationType.id ===
                    EmployeeAllocationType.Vacation ||
                  values.allocationType.id === EmployeeAllocationType.Leave ||
                  (values.allocationType.id ===
                    EmployeeAllocationType.Support &&
                    !values.costCenter),
              }}
              filters={{
                allocationType: values.allocationType?.id,
                subsidiaryId: internalFormData?.idSubsidiaryLoggedUser,
                costCenterId: costCenterFilter,
                showingOnTimesheet: true,
              }}
            />
          )}

        {timesheetMonth && (
          <TimesheetCalendarForm name="days" timesheetMonth={timesheetMonth} />
        )}
      </StyledForm>
      <LoadingBackdrop open={isLoadingTimesheetMonth} />
    </>
  );
};
