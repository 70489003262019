import { css, styled } from "@mui/material";
import { HTMLProps } from "react";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  isCreation: boolean;
}

const PureDiv = ({ isCreation, ...rest }: OwnProps) => <div {...rest} />;

export const StyledViewContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
`;

export const StyledFormContainer = styled(PureDiv)`
  display: grid;
  ${({ isCreation }) =>
    isCreation
      ? css`
          grid-template-columns: repeat(6, 1fr);
          grid-template-areas:
            "a a a b b b"
            "c c d d e e";
        `
      : css`
          grid-template-columns: repeat(6, 1fr);
          grid-template-areas:
            "a a a b b b"
            "c c c d d d";
        `}
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(5)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d";
  }
`;
