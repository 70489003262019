import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DescriptionTypes, FormValues } from "../shared/types/form.types";
import { ExpenseType } from "../../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { AutocompleteOption } from "../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";

interface OwnProps {
  expenseType: ExpenseType;
}

export const useFormikConfig = ({ expenseType }: OwnProps) => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      amount: "",
      description: "",
      additionalDescription: { id: 1, label: "" },
      observation: "",
    };
  }, [t]);

  const validationSchema = useMemo(() => {
    return yup.object({
      amount: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      description: yup
        .string()
        .test({
          name: "descriptionTest",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (
              [ExpenseType.Workforce, ExpenseType.Additional].includes(
                expenseType
              )
            ) {
              return true;
            }
            return !!value;
          },
        })
        .nullable(),
      additionalDescription: yup
        .object()
        .test({
          name: "additionalDescriptionTest",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            const additionalDescription =
              value as AutocompleteOption<DescriptionTypes>;
            if (
              [ExpenseType.Workforce, ExpenseType.Additional].includes(
                expenseType
              )
            ) {
              return !!additionalDescription?.id;
            }
            return true;
          },
        })
        .nullable(),
      observation: yup.string().nullable(),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
