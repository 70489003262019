import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a b b c c"
    "d d d d d d"
    "e e e f f f"
    "g g g h h h"
    "i i i i i i"
    "j j j k k k";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(11)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "i"
      "j"
      "k";
  }
`;
