import * as yup from "yup";

export enum UserLanguageLevelEnum {
  WithoutKnowledge = 1,
  Basic = 2,
  Intermediate = 3,
  Advanced = 4,
  Native = 6,
}

export const schemaUserLanguageLevelEnum = yup
  .mixed<UserLanguageLevelEnum>()
  .oneOf(Object.values(UserLanguageLevelEnum) as UserLanguageLevelEnum[]);
