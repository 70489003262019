import { styled } from "@mui/material";
import { HTMLProps } from "react";
import { PaletteColorVariant } from "../../../../../../../../shared/common/types/externalLibraries/mui.types";

export interface StyledPlanningStatusProps extends HTMLProps<HTMLDivElement> {
  color: PaletteColorVariant;
}

const PureDiv = ({ color, ...rest }: StyledPlanningStatusProps) => (
  <div {...rest} />
);

export const StyledPlanningStatus = styled(PureDiv)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme, color }) => theme.palette[color].main};
  font-weight: 500;

  & > :nth-child(2) {
    margin-top: 0.2rem;
  }
`;
