import { FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { MaskedTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxLengthFields } from "../../../../constants/form.constants";
import {
  StyledGeneralDataContainer,
  StyledLocationDataContainer,
  StyledRegistrationDataContainer,
  StyledSection,
  StyledViewContent,
} from "../../../../styles/ui.styles";
import { CountriesAutocompleteFormik } from "../../form/formik/input/autocomplete/CountriesAutocompleteFormik";
import { StatesAutocompleteFormik } from "../../form/formik/input/autocomplete/StatesAutocompleteFormik";
import { FormValues } from "../../../../types/form.types";
import { checkIsValidType } from "../../../../../../../../../../shared/common/helpers/data/validation/schema.helpers";
import {
  Country,
  schemaCountry,
} from "../../../../../../../../../../shared/common/enums/locale/Country.enum";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { MaskedCodeFormik } from "../../form/formik/input/textField/MaskedCodeFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { generalFieldsConstants } from "../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { CheckboxFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

export const CreationEditingForm = ({
  formikProps: { values, setFieldValue, isSubmitting },
}: FormContentProps<FormValues>) => {
  const { t } = useTranslation();

  const statesField = useMemo(() => {
    const countryCode = values.country?.code;

    if (!checkIsValidType<Country>(schemaCountry.defined(), countryCode))
      return (
        <TextFieldFormik
          name="stateTextField"
          label={t("general.keywords.fields.state")}
          inputProps={{ maxLength: maxLengthFields.state }}
        />
      );

    return (
      <StatesAutocompleteFormik
        name="stateAutocomplete"
        country={countryCode}
        textfieldProps={{ required: true }}
        autocompleteProps={{ disableClearable: true, disabled: isSubmitting }}
      />
    );
  }, [values.country?.code, isSubmitting]);

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      setFieldValue("state", null);
    },
    deps: [values.country?.code],
  });

  return (
    <StyledViewContent>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.location")}
        </Typography>
        <StyledLocationDataContainer>
          <CountriesAutocompleteFormik
            name="country"
            textfieldProps={{ required: true }}
            autocompleteProps={{
              disableClearable: true,
              disabled: isSubmitting,
            }}
          />
          {values.country?.code === "BR" ? (
            <MaskedTextFieldFormik
              name="zipCode"
              mask="00000-000"
              textFieldProps={{
                label: t("general.keywords.fields.zipCodeBrazil"),
                disabled: isSubmitting,
                required: true,
              }}
            />
          ) : (
            <TextFieldFormik
              name="zipCode"
              label={t("general.keywords.fields.zipCodeInternational")}
              inputProps={{ maxLength: maxLengthFields.zipCode }}
              disabled={isSubmitting}
              required
            />
          )}
          {statesField}
          <TextFieldFormik
            name="city"
            label={t("general.keywords.fields.city")}
            inputProps={{ maxLength: maxLengthFields.city }}
            required
            disabled={isSubmitting}
          />
          <TextFieldFormik
            name="district"
            label={t("general.keywords.fields.district")}
            inputProps={{ maxLength: maxLengthFields.district }}
            required
            disabled={isSubmitting}
          />
          <TextFieldFormik
            name="streetName"
            label={t("general.keywords.fields.streetName")}
            inputProps={{ maxLength: maxLengthFields.streetName }}
            required
            disabled={isSubmitting}
          />
          <TextFieldFormik
            name="complement"
            label={t("general.keywords.fields.complement")}
            inputProps={{ maxLength: maxLengthFields.complement }}
            disabled={isSubmitting}
          />
        </StyledLocationDataContainer>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.generalCustomerData")}
        </Typography>
        <StyledGeneralDataContainer>
          <TextFieldFormik
            name="tradingName"
            label={t("customers.keywords.fields.tradingName")}
            inputProps={{ maxLength: maxLengthFields.tradingName }}
            disabled={isSubmitting}
            required
          />
          <TextFieldFormik
            name="corporateName"
            label={t("customers.keywords.fields.corporateName")}
            inputProps={{ maxLength: maxLengthFields.corporateName }}
            disabled={isSubmitting}
            required
          />
          <TextFieldFormik
            name="email"
            label={t("general.keywords.fields.email")}
            inputProps={{ maxLength: maxLengthFields.email }}
            required
            disabled={isSubmitting}
          />
          <MaskedTextFieldFormik
            name="phoneNumber"
            mask={
              values.phoneNumber.length >= 15
                ? "(00) 00000-0000"
                : "(00) 0000-00000"
            }
            textFieldProps={{
              label: t("general.keywords.fields.phoneNumber"),
              inputProps: { maxLength: maxLengthFields.phoneNumber },
              required: true,
              disabled: isSubmitting,
            }}
          />
          <MaskedCodeFormik name="code" required disabled={isSubmitting} />
          <LocalizedNumberTextFieldFormik
            name="daysForPayment"
            scale={0}
            min={0}
            max={generalFieldsConstants.maxDaysValue}
            textFieldProps={{
              label: t("general.keywords.fields.daysForPayment"),
              required: true,
              disabled: isSubmitting,
            }}
          />
        </StyledGeneralDataContainer>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.registrationData")}
        </Typography>
        <StyledRegistrationDataContainer>
          {values.country?.code === "BR" ? (
            <MaskedTextFieldFormik
              name="registrationCode"
              mask="00.000.000/0000-00"
              textFieldProps={{
                label: t("customers.keywords.fields.registrationCodeBrazil"),
                disabled: isSubmitting,
                required: true,
              }}
            />
          ) : (
            <TextFieldFormik
              name="registrationCode"
              label={t(
                "customers.keywords.fields.registrationCodeInternational"
              )}
              disabled={isSubmitting}
              required
            />
          )}
          <TextFieldFormik
            name="municipalCode"
            label={t("customers.keywords.fields.municipalCode")}
            disabled={isSubmitting}
            inputProps={{
              maxLength: maxLengthFields.municipalCode,
            }}
          />
          <TextFieldFormik
            name="stateCode"
            label={t("customers.keywords.fields.stateCode")}
            disabled={isSubmitting}
            inputProps={{
              maxLength: maxLengthFields.stateCode,
            }}
          />
          <FormControlLabel
            label={t("customers.keywords.fields.publicAgency")}
            control={<CheckboxFormik name="isPublicAgency" />}
          />
        </StyledRegistrationDataContainer>
      </StyledSection>
    </StyledViewContent>
  );
};
