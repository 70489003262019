import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { formatMinutesToHourMinute } from "../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { StyledFooter } from "./index.styles";

interface OwnProps {
  totalMinutes: number | null;
}

export const TableFooter = ({ totalMinutes }: OwnProps) => {
  const { t } = useTranslation();

  const formattedTotalMinutes = useMemo(() => {
    if (totalMinutes === null) return null;
    return formatMinutesToHourMinute(totalMinutes);
  }, [totalMinutes]);

  if (formattedTotalMinutes === null) return null;

  return (
    <StyledFooter>
      <span>{t("timesheets.keywords.fields.totalHoursMonth")}</span>
      <span>{formattedTotalMinutes}</span>
    </StyledFooter>
  );
};
