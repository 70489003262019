import { getLoggedUser } from "../../../../../../../../../services/user/user.service";
import { UserCollaboratorOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorOnlyDTO";

interface OwnProps {
  setUserLogged: (
    value: React.SetStateAction<UserCollaboratorOnlyDTO | null>
  ) => void;
}

export async function fillLoggedUser({ setUserLogged }: OwnProps) {
  const loggedUser = await getLoggedUser();
  if (loggedUser) {
    const formattedResponsible: UserCollaboratorOnlyDTO = {
      id: loggedUser.id,
      admissionDate: loggedUser.admissionDate,
      email: loggedUser.email,
      idDiscipline: loggedUser.discipline?.id || null,
      idWorkload: loggedUser.idWorkload,
      isActive: loggedUser.isActive,
      isAdmin: loggedUser.isAdmin,
      isCollaborator: loggedUser.isCollaborator,
      login: loggedUser.login,
      name: loggedUser.name,
      country: loggedUser.country,
      state: loggedUser.state,
      resignationDate: loggedUser.resignationDate,
      dateOfBirth: loggedUser.dateOfBirth,
      version: loggedUser.version,
    };
    setUserLogged(formattedResponsible);
  }
}
