import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { CostCenterByAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCenterByAllocationTypeAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { CorporateSegmentsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/CorporateSegmentsAutocompleteFormik";
import { checkIsDisabledCostCenter } from "../shared/helpers/data/form/fields/checkers.helpers";
import { FormValues } from "../shared/types/data/form/values.types";
import { AllocationYearMonthsTable } from "./AllocationYearMonthsTable";
import { StyledForm } from "./index.styles";
import { ValidatedUsersSearchAutocompleteFormik } from "./ValidatedUsersSearchAutocompleteFormik";
import { CorporateAllocationTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/corporateAllocation/CorporateAllocationTypeAutocompleteFormik";

interface OwnProps
  extends Omit<FormContentProps<FormValues>, "internalFormData"> {}

export const FormContent = ({
  formikProps: { isSubmitting, values, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  const maxPeriodStart = useMemo(() => {
    return values.periodEnd ?? null;
  }, [values.periodEnd]);

  const minPeriodEnd = useMemo(() => {
    return values.periodStart ?? null;
  }, [values.periodStart]);

  const isDisabledCostCenter = useMemo(() => {
    return (
      isSubmitting ||
      checkIsDisabledCostCenter(values.allocationType?.id ?? null)
    );
  }, [values.allocationType?.id, isSubmitting]);

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("costCenter", null);
      setFieldValue("project", null);
    },
    deps: [values.allocationType],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("project", null);
      setFieldValue("corporateSegment", null);
    },
    deps: [values.costCenter],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("userCollaborators", []);
    },
    deps: [values.corporateSegment],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="periodStart"
        datePickerProps={{
          label: t("allocations.keywords.fields.periodStart"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          maxDate: maxPeriodStart,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <YearMonthDatePickerFormik
        name="periodEnd"
        datePickerProps={{
          label: t("allocations.keywords.fields.periodEnd"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          disabled: isSubmitting,
          minDate: minPeriodEnd,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <CorporateAllocationTypeAutocompleteFormik
        name="allocationType"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
      />
      {!values.allocationType ||
      [
        EmployeeAllocationType.Allocation,
        EmployeeAllocationType.Support,
      ].includes(values.allocationType.id) ? (
        <CostCentersSearchAutocompleteFormik
          name="costCenter"
          autocompleteProps={{
            disabled: isDisabledCostCenter,
          }}
          textfieldProps={{ required: true }}
          filters={{
            isBlockAllocationCostCenters:
              values.allocationType?.id === EmployeeAllocationType.Allocation,
            IsBlockCorporateAllocationCostCenters: true,
          }}
        />
      ) : (
        <CostCenterByAllocationTypeAutocompleteFormik
          name="costCenter"
          autocompleteProps={{
            disabled: isDisabledCostCenter,
          }}
          textfieldProps={{ required: true }}
          allocationType={values.allocationType.id}
        />
      )}
      {(values.costCenter?.id ||
        values.allocationType?.id === EmployeeAllocationType.Training) && (
        <CorporateSegmentsAutocompleteFormik
          name="corporateSegment"
          autocompleteProps={{
            disabled:
              isSubmitting ||
              (!values.costCenter &&
                values.allocationType?.id !== EmployeeAllocationType.Training),
          }}
          filters={{
            costCenterId: values.costCenter?.id || undefined,
            allocationType: values.allocationType
              ? values.allocationType.id
              : undefined,
            IsBlockCorporateAllocationCorporateSegments: true,
          }}
        />
      )}
      <ValidatedUsersSearchAutocompleteFormik
        isDisabledCostCenter={isDisabledCostCenter}
      />
      <AllocationYearMonthsTable />
    </StyledForm>
  );
};
