import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getPipedriveDealById } from "../../../../../services/projects/projects.service";
import {
  CommonProjectFormRef,
  CommonProjectForm,
} from "../../Projects/shared/react/components/content/CommonProjectForm";
import { contractTypeToTranslationCodeMap } from "../../../../../shared/specific/maps/projects/contractType.maps";
import { formatNumber } from "../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

type OwnParams = {
  id: string;
};

export const ProjectsViewingByDealPage = () => {
  const { t, i18n } = useTranslation();
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const pipedriveDeal = await getPipedriveDealById(id);
        if (!pipedriveDeal) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }

        formRef.current?.setFormValues({
          name: pipedriveDeal.idProject,
          code: "",
          userManager: pipedriveDeal.userManager,
          userCoordinator: null,
          division: pipedriveDeal.division,
          productService: pipedriveDeal.productService,
          customer: pipedriveDeal.customer,
          description: "",
          subsidiary: pipedriveDeal.subsidiary,
          contractType: pipedriveDeal.contractType
            ? {
                id: pipedriveDeal.contractType,
                label: t(
                  contractTypeToTranslationCodeMap[pipedriveDeal.contractType]
                ),
              }
            : null,
          typeOfEvidence: null,
          frame: null,
          daysForPayment: String(pipedriveDeal.daysForPayment),
          billingAmount: pipedriveDeal.billingAmount
            ? formatNumber(pipedriveDeal.billingAmount, { fractionDigits: 2 })
            : "",
          mv: pipedriveDeal.mv
            ? formatNumber(pipedriveDeal.mv, { fractionDigits: 2 })
            : "",
          customerNameFromDeal: pipedriveDeal.customerName,
          userManagerNameFromDeal: pipedriveDeal.userManagerName,
          productServiceFromDeal: pipedriveDeal.productServiceName,
          divisionFromDeal: pipedriveDeal.unDescription,
          segment: pipedriveDeal.segment,
          approvedCredit: {
            id: pipedriveDeal.approvedCredit ?? false,
            label: pipedriveDeal.approvedCredit
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
          clientAccount: pipedriveDeal.customerName ?? "-",
          isInvestmentProject: {
            id: false,
            label: t("general.keywords.general.no"),
          },
        });
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, [i18n.language]);

  return <CommonProjectForm mode="viewing" ref={formRef} />;
};
