import {
  EnhancedOrderDefaultSettings,
  EnhancedTablePaginationSettings,
  EnhancedTableUiSettings,
} from "../types/settings.types";

interface Defaults {
  ui: Pick<
    EnhancedTableUiSettings,
    | "mincellheight"
    | "minheadcellheight"
    | "minTableWidth"
    | "hasDynamicTableHeight"
    | "stickyHeader"
  >;
  pagination: Pick<
    EnhancedTablePaginationSettings,
    "defaultRowsPerPage" | "rowsPerPageOptions" | "usesPagination"
  > & {
    externalPagination: {
      shouldUpdateTableWhenCallbackChanges: boolean;
    };
  };
  order: Pick<EnhancedOrderDefaultSettings, "defaultOrder" | "defaultOrderBy">;
}

export const defaults: Defaults = {
  ui: {
    mincellheight: 40,
    minheadcellheight: 40,
    minTableWidth: 500,
    hasDynamicTableHeight: false,
    stickyHeader: false,
  },
  pagination: {
    defaultRowsPerPage: 10,
    rowsPerPageOptions: [5, 10, 25, 50],
    usesPagination: false,
    externalPagination: {
      shouldUpdateTableWhenCallbackChanges: false,
    },
  },
  order: {
    defaultOrder: "asc",
    defaultOrderBy: 0,
  },
};
