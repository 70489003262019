import { Button } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getPageFromCurrent } from "../../../../../services/applicationState/history.service";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { getDivisionById } from "../../../../../services/division/division.service";
import { getProjectById } from "../../../../../services/projects/projects.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { CenteredContent } from "../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../shared/common/react/components/ui/CenteredLoading";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { DivisionDTO } from "../../../../../shared/specific/DTOs/division/DivisionDTO";
import { ProjectDTO } from "../../../../../shared/specific/DTOs/project/ProjectDTO";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useCreationForm } from "./hooks/useCreationForm";
import { useTableData } from "./hooks/useTableData";
import {
  FooterStyles,
  StyledCostCenterPepsPageContainer,
} from "./index.styles";
import { ProjectInfo } from "./ProjectInfo";
import { ProjectStatus } from "../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { store } from "../../../../../store";

type OwnParams = {
  idProject: string;
};

export const CostCenterPepsPage = () => {
  const { t, i18n } = useTranslation();
  const { idProject: idProjectString = "" } = useParams<OwnParams>();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [project, setProject] = useState<ProjectDTO | null>(null);
  const [division, setDivision] = useState<DivisionDTO | null>(null);

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const idProject = useMemo(() => {
    return /^\d+$/.test(idProjectString) ? parseInt(idProjectString) : null;
  }, [idProjectString]);

  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);

  const checkCanEditPep = () => {
    if (!project) return false;

    return [ProjectStatus.Created, ProjectStatus.Planning].includes(
      project.status
    );
  };

  const reloadTable = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  useEffect(() => {
    setPageTitle(t("costCenterPeps.pages.main.title"));
  }, [t]);

  useEffect(() => {
    const runAsync = async () => {
      try {
        if (idProject === null) return;

        const project = await getProjectById(idProject);
        setProject(project);
        setDivision(project?.division ?? null);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    runAsync();
  }, []);

  useEffectAfterRenders({
    effect: () => {
      const runAsync = async () => {
        if (!project?.division) return;

        setLoading(true);
        try {
          const division = await getDivisionById(project.division.id);
          setDivision(division);
        } catch (error) {
          console.error(error);
          setDivision(null);
        } finally {
          setLoading(false);
        }
      };

      runAsync();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      enhancedTableRef.current?.reloadPage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  const { creationButton, creationModal } = useCreationForm({
    reloadTable,
    idProject: idProject ?? 0,
  });

  const {
    headCells,
    onCostCenterPageChange,
    editingModal,
    viewingModal,
    deletionModal,
  } = useTableData({
    reloadTable,
    project,
  });

  if (idProject === null)
    return (
      <CenteredContent>
        {t("general.errors.data.fields.id.invalid")}
      </CenteredContent>
    );

  if (loading) return <CenteredLoading />;
  if (project === null)
    return (
      <CenteredContent>
        {t("projects.errors.data.fetch.failedToFetchProject")}
      </CenteredContent>
    );
  if (division === null)
    return (
      <CenteredContent>
        {t("divisions.errors.data.fetch.failedToFetchDivision")}
      </CenteredContent>
    );

  return (
    <StyledCostCenterPepsPageContainer>
      <ProjectInfo project={project} division={division} />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: checkCanEditPep() ? (
            <Protected
              restrictions={{
                type: PermissionType.CostCenterPeps,
                level: PermissionLevel.Create,
              }}
            >
              {creationButton}
            </Protected>
          ) : null,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: onCostCenterPageChange,
          },
        }}
        footerSettings={{
          ContentMemo: () => {
            const { t } = useTranslation();

            const previousPage = useMemo(() => {
              const location = getPageFromCurrent(1);
              const { pagesHistory } = store.getState().application;
              const sourcePage = pagesHistory.at(-2);

              const returnTo =
                sourcePage?.pathname.includes(
                  "registration-of-projects-and-additives"
                ) || sourcePage?.pathname.includes("projects/create")
                  ? "?return-to=/registration-of-projects-and-additives"
                  : "";

              if (pageReturnTo) return pageReturnTo + returnTo;

              if (!location) return "/projects";
              return location.pathname + location.search + returnTo;
            }, []);

            return (
              <FooterStyles>
                <Button component={Link} to={previousPage}>
                  {previousPage
                    ? t("general.actions.general.return")
                    : t("costCenterPeps.actions.navigation.viewProjects")}
                </Button>
              </FooterStyles>
            );
          },
        }}
        messagesSettings={{
          noRowsMessage: t(
            "costCenterPeps.info.data.create.clickButtonRegisterPeps"
          ),
        }}
      />
      {creationModal}
      {editingModal}
      {viewingModal}
      {deletionModal}
    </StyledCostCenterPepsPageContainer>
  );
};
