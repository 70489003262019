import { Paper, styled } from "@mui/material";

export const StyledFormContainer = styled(Paper)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  flex-direction: column;
`;

export const StyledDolarField = styled("div")`
  display: flex;
  max-width: 400px;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledUploadsButtonsAndHelper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 350px;

  button {
    flex-grow: 1;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

export const StyledUploadsButtonsContainer = styled("div")`
  display: flex;
  width: 350px;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledFilterFields = styled("div")`
  display: grid;
  width: max-content;
  gap: ${({ theme }) => theme.spacing(1)};

  & > div {
    width: 350px;
  }

  grid-template-areas: "a b c";
`;
