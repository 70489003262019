import * as yup from "yup";

export enum PermissionType {
  CostCenter = 1,
  Customer = 2,
  Project = 3,
  JobRole = 4,
  CostCenterPeps = 5,
  JobRate = 6,
  ContractRole = 7,
  Allocation = 8,
  UserCollaborator = 9,
  Activity = 10,
  Invoice = 11,
  Additive = 12,
  BrlParity = 13,
  Expenses = 14,
  Frame = 15,
  ProjectBaselines = 16,
  AuthorizationControl = 17,
  CorporateAllocations = 18,
  CollaboratorHistory = 19,
  DashBoards = 21,
  AllocationsMap = 22,
  Discipline = 23,
  Workload = 24,
  CorporateSegment = 25,
  CollaboratorAllocation = 26,
  InvoiceModule = 27,
  ResultsProjection = 28,
  ProjectRegister = 29,
  SkillCategory = 30,
  Budget = 31,
  Skill = 32,
  SkillCertification = 33,
  CollaboratorManagement = 34,
  MonthlyAllocationClosing = 35,
  CostDistribution = 36,
  AdditiveRegister = 38,
  CustomerBalance = 37,
  Wacc = 39,
  FeeTypes = 40,
  FeePeriod = 41,
  FeeSummary = 42,

  CanEditPepAfterPlanning = 1001,
  CanEditAllActivities = 1002,
  CanEditAllInvoices = 1003,
  CanEditAllAllocations = 1004,
  CanEditAllExpenses = 1005,
  CanReopenProject = 1006,
  CanEditAllCorporateAllocations = 1007,
  CanEditPastTimesheet = 1008,
  CanUploadExpenses = 1009,
  CanUploadCorporateAllocations = 1010,
  CanUploadProjectAllocations = 1011,
  CanUploadFinancialCost = 1012,
  CanAccessDeallocationInCorporateAllocation = 1013,
  CanUploadPaidOutInvoices = 1014,
  CanEditPastProjectionResult = 1015,
  CanSubmitDreQueue = 1016,
  CanEditOngoingProjectTypeOfEvidence = 1017,
  CanEditApprovedCredit = 1018,
  CanUploadHandlingLoad = 1019,
  CanEditCollaboratorAndTrackWithoutRestriction = 1020,
  CanSubmitProjectionQueue = 1021,
  CanAccessLeaveInCorporateAllocation = 1022,
  CanAccessTrainingInCorporateAllocation = 1024,
  CanUploadLedgerAccount = 1025,
  CanUploadCustomerBalance = 1026,
  CanAccessCalamityCorporateSegment = 1027,
  CanAccessHECompensationCorporateSegment = 1028,
  CanAccessInefficiencyCorporateSegment = 1029,

  CanSubmitPerformedTimesheetReport = 2001,
  CanSubmitGlobalAllocationReport = 2002,
  CanSubmitProjectTimesheetReport = 2003,
  CanSubmitRevenueAndBillingReport = 2004,
  CanSubmitEAPReport = 2005,
  CanSubmitProjectOverviewReport = 2006,
  CanSubmitProjectDetailedViewReport = 2007,
  CanSubmitProjectStatusReport = 2008,
  CanSubmitProjectsAndAdditivesCreationReport = 2009,
  CanSubmitProjectMVsReport = 2010,
  CanSubmitWeeklyAllocationReport = 2011,
  CanSubmitProvisionReport = 2012,
  CanSubmitBaseCollaboratorReport = 2013,
  CanSubmitPepElementReport = 2014,
  CanSubmitBudgetOverviewReport = 2015,
  CanSubmitPlannedAndPerformedByPepReport = 2016,
  CanSubmitBillingVisionReport = 2017,
  CanSubmitCostMdoReport = 2018,
  CanSubmitZahReport = 2019,
  CanSubmitTurnoverDaysReport = 2020,
  CanSubmitMvWithFinancialCostReport = 2021,
}

export const schemaPermissionType = yup
  .mixed<PermissionType>()
  .oneOf(Object.values(PermissionType) as PermissionType[]);
