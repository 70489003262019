import { getDefaultLanguage } from "../../repositories/APIs/riseAPI/languages.repository";
import {
  Language,
  schemaLanguage,
} from "../../shared/common/enums/locale/Language.enum";
import { store } from "../../store";
import { setDefaultLanguage } from "../../store/features/languageSlice";

export const setStoredDefaultLanguage = async () => {
  const languageDTO = await getDefaultLanguage();
  if (!languageDTO) throw new Error("No default language set.");

  const language: Language = schemaLanguage
    .defined()
    .validateSync(languageDTO.id);

  store.dispatch(setDefaultLanguage(language));
};
