import { t } from "i18next";
import * as yup from "yup";
import {
  DivisionDTO,
  schemaDivisionDTO,
} from "../../../shared/specific/DTOs/division/DivisionDTO";
import { DivisionFiltersDTO } from "../../../shared/specific/DTOs/division/filters/DivisionFiltersDTO";
import { riseApi } from "./connection";

export const getDivisions = async ({
  filters,
  includeAllDivisionsOption,
}: {
  includeAllDivisionsOption?: boolean;
  filters?: DivisionFiltersDTO;
} = {}): Promise<DivisionDTO[]> => {
  const { data: divisions } = await riseApi.get("Divisions", {
    params: filters,
  });

  const divisionsList = yup
    .array(schemaDivisionDTO)
    .defined()
    .validateSync(divisions);

  if (includeAllDivisionsOption) {
    divisionsList.unshift({
      id: 0,
      name: t("general.keywords.general.all2"),
      prefix: null,
      uniqueCode: t("general.keywords.general.all2"),
    });
  }

  return divisionsList;
};

export const getDivisionById = async (
  id: number
): Promise<DivisionDTO | null> => {
  const { data: division } = await riseApi.get(`Divisions/${id}`);

  return schemaDivisionDTO.nullable().validateSync(division);
};
