import * as yup from "yup";

export interface CustomerDTO {
  id: number;
  code: string;
  tradingName: string;
  corporateName: string;
  email: string;
  phoneNumber: string;
  daysForPayment: number;
  active: boolean;
  zipCode: string;
  country: string;
  state: string | null;
  city: string;
  district: string;
  streetName: string;
  complement: string | null;
  registrationCode: string | null;
  municipalCode: string | null;
  stateCode: string | null;
  isPublicAgency: boolean;
  version: string;
}

export const schemaCustomerDTO = yup.object({
  id: yup.number().integer().defined(),
  code: yup.string().defined(),
  tradingName: yup.string().defined(),
  corporateName: yup.string().defined(),
  email: yup.string().defined(),
  phoneNumber: yup.string().defined(),
  daysForPayment: yup.number().integer().min(0).defined(),
  active: yup.boolean().defined(),
  zipCode: yup.string().defined(),
  country: yup.string().defined(),
  state: yup.string().nullable().defined(),
  city: yup.string().defined(),
  district: yup.string().defined(),
  streetName: yup.string().defined(),
  complement: yup.string().nullable().defined(),
  registrationCode: yup.string().nullable().defined(),
  municipalCode: yup.string().nullable().defined(),
  stateCode: yup.string().nullable().defined(),
  isPublicAgency: yup.boolean().defined(),
  version: yup.string().defined(),
});
