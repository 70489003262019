import { styled } from "@mui/material/styles";

export const StyledFixedColumn = styled("div")`
  width: 250px;
  height: 50px;
  background-color: rgba(224, 224, 224, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: ${({ theme }) => theme.spacing(1)};
`;
