import * as yup from "yup";

import {
  CostCenterTranslationDTO,
  schemaCostCenterTranslationDTO,
} from "./translation/CostCenterTranslationDTO";

export interface CostCenterOnlyDTO {
  id: number;
  idDivision: number | null;
  idSubsidiary: number | null;
  code: string;
  name: string;
  deactivatedDate: Date | null;
  version: string;
  costCenterTranslations: CostCenterTranslationDTO[];
}

export const schemaCostCenterOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  idDivision: yup.number().integer().nullable().defined(),
  idSubsidiary: yup.number().integer().nullable().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  deactivatedDate: yup.date().nullable().defined(),
  version: yup.string().defined(),
  costCenterTranslations: yup.array(schemaCostCenterTranslationDTO).defined(),
});
