import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { getTextIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { UserCollaboratorProfileComponent } from "../../../../shared/react/components/ CollaboratorProfile";
import { sleep } from "../../../../../shared/common/helpers/general.helpers";
import { ProfileFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/ProfileFormButton";

export interface InternalFormDataContentCollaboratorProps {
  jobRoleName: string;
  yearMoth?: YearMonth;
  historyId: number;
}

export const useCollaboratorProfileViewForm = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        return (
          <Trans
            i18nKey="collaboratorManagement.modal.data.collaboratorProfile.title"
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) =>
        internalModalData?.idUserCollaborator ? (
          <UserCollaboratorProfileComponent
            idUserCollaborator={internalModalData?.idUserCollaborator}
            mode="viewing"
          />
        ) : (
          <div> </div>
        ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => (
        <ProfileFormButton
          onClick={onClick}
          buttonTooltip="collaboratorManagement.modal.data.collaboratorProfile.title"
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: data,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          await sleep(500);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData({
            idUserCollaborator: data.idUserCollaborator,
          });
        } catch (error) {
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    general: {
      initialInternalModalData: null as {
        idUserCollaborator: number;
      } | null,
    },
  });

  return {
    ViewingButtonContainer,
    viewingModal,
  };
};
