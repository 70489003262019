import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createTranslatableFieldValues } from "../../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { ReadOnlyTextFieldWithTranslationsModal } from "../../../../../../../../shared/common/react/components/form/general/translatable/ReadOnlyTextFieldWithTranslationsModal";
import { TranslatableFieldValues } from "../../../../../../../../shared/common/types/data/form/translation.types";
import { CostCenterDTO } from "../../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { StyledCostCenterContent } from "./index.styles";

interface OwnProps {
  costCenterDTO?: CostCenterDTO;
}

export const CostCenterContent = ({ costCenterDTO }: OwnProps) => {
  const { t } = useTranslation();

  const translatableNames: TranslatableFieldValues = useMemo(() => {
    const translatableNames = createTranslatableFieldValues("-");

    if (!costCenterDTO?.costCenterTranslations) return translatableNames;

    const costCenterTranslationsArray = Object.values(
      costCenterDTO.costCenterTranslations
    );

    for (const costCenterTranslation of costCenterTranslationsArray) {
      const { idLanguage, name } = costCenterTranslation;
      translatableNames[idLanguage] = name ?? "-";
    }

    return translatableNames;
  }, [costCenterDTO?.costCenterTranslations]);

  if (!costCenterDTO) return null;

  return (
    <StyledCostCenterContent>
      <TextField
        label={t("general.keywords.fields.code")}
        value={costCenterDTO.code}
        InputProps={{ readOnly: true }}
      />
      <ReadOnlyTextFieldWithTranslationsModal
        label={t("general.keywords.fields.name")}
        translatableFieldValues={translatableNames}
      />
      <TextField
        label={t("divisions.keywords.general.un")}
        value={costCenterDTO.division?.name ?? ""}
        InputProps={{ readOnly: true }}
      />
      <TextField
        label={t("subsidiaries.keywords.general.subsidiary")}
        value={costCenterDTO.subsidiary?.name ?? ""}
        InputProps={{ readOnly: true }}
      />
    </StyledCostCenterContent>
  );
};
