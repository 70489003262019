import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const InvoicesFilterForm = ({
  formikProps: { values, isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <DatePickerFormik
        name="earliestPlannedBillingDate"
        datePickerProps={{
          label: t("invoice.keywords.fields.earliestPlannedDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.latestPlannedBillingDate ?? undefined,
          disabled: isSubmitting,
        }}
      />
      <DatePickerFormik
        name="latestPlannedBillingDate"
        datePickerProps={{
          label: t("invoice.keywords.fields.latestPlannedDate"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          minDate: values.earliestPlannedBillingDate ?? undefined,
          disabled: isSubmitting,
        }}
      />
      <DatePickerFormik
        name="earliestIssueDate"
        datePickerProps={{
          label: t("invoice.keywords.fields.earliestBillingDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.latestIssueDate ?? undefined,
          disabled: isSubmitting,
        }}
      />
      <DatePickerFormik
        name="latestIssueDate"
        datePickerProps={{
          label: t("invoice.keywords.fields.latestBillingDate"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          minDate: values.earliestIssueDate ?? undefined,
          disabled: isSubmitting,
        }}
      />
    </StyledForm>
  );
};
