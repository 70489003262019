import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../../common/classes/data/date/YearMonth";
import {
  AmountMonthDTO,
  schemaAmountMonthDTO,
} from "../../general/balance/AmountMonthDTO";

export interface ProjectSummaryMonthlyAmountsDTO {
  idProject: number;
  grossRevenues: AmountMonthDTO[];
  performedGrossRevenues: AmountMonthDTO[];
  liquidRevenues: AmountMonthDTO[];
  performedLiquidRevenues: AmountMonthDTO[];
  performedFinancialCosts: AmountMonthDTO[];
  plannedFinancialCosts: AmountMonthDTO[];
  accumulatedLiquidRevenue: AmountMonthDTO[];
  accumulatedPerformedLiquidRevenue: AmountMonthDTO[];
  plannedMv: AmountMonthDTO[];
  performedMv: AmountMonthDTO[];
  performedFinancialCostMv: AmountMonthDTO[];
  plannedFinancialCostMv: AmountMonthDTO[];
  accumulatedPlannedMv: AmountMonthDTO[];
  accumulatedPerformedMv: AmountMonthDTO[];
  expenses: AmountMonthDTO[];
  performedExpenses: AmountMonthDTO[];
  accumulatedExpenses: AmountMonthDTO[];
  accumulatedPerformedExpenses: AmountMonthDTO[];
  periodStart: YearMonth | null;
  periodEnd: YearMonth | null;
}

export const schemaProjectSummaryMonthlyAmountsDTO = yup.object({
  idProject: yup.number().integer().defined(),
  grossRevenues: yup.array(schemaAmountMonthDTO).defined(),
  performedGrossRevenues: yup.array(schemaAmountMonthDTO).defined(),
  liquidRevenues: yup.array(schemaAmountMonthDTO).defined(),
  performedLiquidRevenues: yup.array(schemaAmountMonthDTO).defined(),
  performedFinancialCosts: yup.array(schemaAmountMonthDTO).defined(),
  plannedFinancialCosts: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedLiquidRevenue: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedPerformedLiquidRevenue: yup.array(schemaAmountMonthDTO).defined(),
  plannedMv: yup.array(schemaAmountMonthDTO).defined(),
  performedMv: yup.array(schemaAmountMonthDTO).defined(),
  performedFinancialCostMv: yup.array(schemaAmountMonthDTO).defined(),
  plannedFinancialCostMv: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedPlannedMv: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedPerformedMv: yup.array(schemaAmountMonthDTO).defined(),
  expenses: yup.array(schemaAmountMonthDTO).defined(),
  performedExpenses: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedExpenses: yup.array(schemaAmountMonthDTO).defined(),
  accumulatedPerformedExpenses: yup.array(schemaAmountMonthDTO).defined(),
  periodStart: schemaYearMonth.nullable().defined(),
  periodEnd: schemaYearMonth.nullable().defined(),
});
