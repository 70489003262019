import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useEditingForm } from "./useEditingForm";
import { useDeletionForm } from "./useDeletionForm";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { getBudgetByYear } from "../../../../../services/budget/budget.service";
import { BudgetContext } from "../context/budgetContextProvider";

interface OwProps {
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage }: OwProps) => {
  const { t, i18n } = useTranslation();
  const { setBudget } = useContext(BudgetContext);
  const [dolarTax, setDolarTax] = useState<number | null>(null);

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("budget.keywords.fields.round"),
      t("budget.keywords.fields.roundStart"),
      t("budget.keywords.fields.roundEnd"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const currentDate = new Date();
        const budgetRounds = await getBudgetByYear(
          currentDate.getFullYear() + 1
        );

        const rounds = budgetRounds?.budgetRound || [];

        setDolarTax(budgetRounds?.dolarTax || null);
        setBudget(budgetRounds);

        const rows: ExternalEnhancedRow[] = rounds.map(
          (round): ExternalEnhancedRow => {
            return {
              id: round.id,
              cells: [
                round.name,
                {
                  displayValue: round.beginDate.toLocaleDateString(),
                  value: round.beginDate.getTime(),
                },
                {
                  displayValue: round.endDate.toLocaleDateString(),
                  value: round.endDate.getTime(),
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.Budget,
                          level: PermissionLevel.Create,
                        }}
                      >
                        <EditingButtonContainer {...round} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Budget,
                          level: PermissionLevel.Create,
                        }}
                      >
                        <DeleteButtonContainer {...round} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            };
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, i18n]);

  return {
    headCells,
    onPageChange,
    editingModal,
    deletionModal,
    dolarTax,
  };
};
