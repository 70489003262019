import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback, useContext } from "react";
import { AllocationsOfUserDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { AllocationEditingFormValues } from "./shared/types/data/form/values.types";
import {
  getAllocationSingle,
  modifySingleAllocation,
} from "../../../../../../../../../services/allocations/allocations.service";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { parseLocaleNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikConfig } from "./shared/hooks/data/form/validation/useFormikConfig";
import { StyledButtonContainer } from "./index.styles";
import { FormMode } from "./shared/types/data/form/general.types";
import { AllocationsSuperUserContext } from "../../shared/react/contexts/AllocationsSuperUserContext";
import { AllocationSingleForm } from "./AllocationSingleForm";
import { UserCurrentJobRoleDTO } from "../../../../../../../../../shared/specific/DTOs/user/UserCurrentJobRoleDTO";
import { AllocationModifySingleDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationModifySingleDTO";
import { AllocationYearMonthDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationYearMonthDTO";
import { AllocationDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationDTO";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { usePermissionChecker } from "../../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { checkIsDateSameProject } from "../../../../../shared/helpers/DTOs/project/general.helpers";
import { call } from "../../../../../../../../../shared/common/helpers/general.helpers";
import { EmployeeAllocationType } from "../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { JobRoleDTO } from "../../../../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { useMonthlyAllocationClosing } from "../../../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";

interface OwnProps {
  reloadTablePage: () => void;
  reloadAllocationsSummary: () => void;
}

interface InternalFormData {
  name: string;
  allocationGroup: number;
  userCurrentJobRole: UserCurrentJobRoleDTO | null;
  jobRole: JobRoleDTO | null;
  idCostCenterPep: number | null;
  yearMonth: YearMonth;
  mode: FormMode;
  allocationType: EmployeeAllocationType;
  allocationYearMonth: AllocationYearMonthDTO | null;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  allocationPerUserRow: AllocationsOfUserDTO;
  yearMonth: YearMonth;
}

export const useEditingCellForm = ({
  reloadTablePage,
  reloadAllocationsSummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { hasPermission: canEditAllocations } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Allocation,
      level: PermissionLevel.Update,
    },
  });

  const formikConfig = useFormikConfig();
  const { project, reloadProject } = useProjectContext();
  const { canEditAllAllocations } = useContext(AllocationsSuperUserContext);

  const { checkIfMonthlyAllocationIsClosed } = useMonthlyAllocationClosing();

  const getWeekPercentage = (weekPercentageString?: number | null) => {
    if (typeof weekPercentageString !== "number") return "";
    return formatNumber(weekPercentageString, { fractionDigits: 2 });
  };

  const getFormMode = useCallback(
    (allocation: AllocationDTO | null, yearMonth: YearMonth): FormMode => {
      if (!canEditAllocations) return "viewing";
      if (!canEditAllAllocations && checkIfMonthlyAllocationIsClosed(yearMonth))
        return "viewing";

      if (!allocation) return "creation";
      return "editing";
    },
    [
      canEditAllAllocations,
      canEditAllocations,
      checkIfMonthlyAllocationIsClosed,
    ]
  );

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      AllocationEditingFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: { allocationPerUserRow, yearMonth },
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    }) => {
      setIsLoadingModal(true);
      setInternalFormData(null);
      const startingOpenCloseModalCount = getOpenCloseModalCount();

      try {
        const allocation = await getAllocationSingle({
          allocationGroup: allocationPerUserRow.allocationGroup,
          yearMonth,
        });
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        setFormValues({
          yearMonth,
          week1Percentage: getWeekPercentage(
            allocation?.allocationYearMonth?.week1Percentage
          ),
          week2Percentage: getWeekPercentage(
            allocation?.allocationYearMonth?.week2Percentage
          ),
          week3Percentage: getWeekPercentage(
            allocation?.allocationYearMonth?.week3Percentage
          ),
          week4Percentage: getWeekPercentage(
            allocation?.allocationYearMonth?.week4Percentage
          ),
          week5Percentage: getWeekPercentage(
            allocation?.allocationYearMonth?.week5Percentage
          ),
        });

        setInternalFormData({
          name: allocationPerUserRow.name ?? "",
          allocationGroup: allocationPerUserRow.allocationGroup,
          userCurrentJobRole: allocation?.userCurrentJobRole ?? null,
          jobRole: allocation?.jobRole ?? null,
          idCostCenterPep: allocationPerUserRow.idCostCenterPep,
          yearMonth,
          allocationYearMonth: allocation?.allocationYearMonth ?? null,
          allocationType: allocationPerUserRow.allocationType,
          mode: getFormMode(allocation, yearMonth),
        });
      } catch (error) {
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        console.error(error);

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "allocations.errors.data.fetch.failedToFetchAllocation",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [getFormMode]
  );

  const checkIfNeedsProjectReload = (
    existingAllocation: AllocationYearMonthDTO | null,
    allocationModifySingleDTO: AllocationModifySingleDTO,
    yearMonth: YearMonth
  ) => {
    if (project.isValid) return true;

    const doesAddOrRemoveYearMonth = call(() => {
      if (!existingAllocation) return true;

      const weeks = [
        allocationModifySingleDTO.week1Percentage,
        allocationModifySingleDTO.week2Percentage,
        allocationModifySingleDTO.week3Percentage,
        allocationModifySingleDTO.week4Percentage,
        allocationModifySingleDTO.week5Percentage,
      ];

      return weeks.every((x) => !x);
    });

    if (!doesAddOrRemoveYearMonth) return false;
    return checkIsDateSameProject(project.finalScheduledEndDate, yearMonth);
  };

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      AllocationEditingFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData }) => {
          switch (internalFormData?.mode) {
            case "editing":
              return <>{t("allocations.modal.data.modifySingle.titleEdit")}</>;
            case "creation":
              return (
                <>{t("allocations.modal.data.modifySingle.titleCreate")}</>
              );
            case "viewing":
              return <>{t("allocations.modal.data.modifySingle.titleView")}</>;
            default:
              return null;
          }
        },
        modalMode: "dialog",
      },
      button: {
        onClickContentButtonComponent,
        createModalButtonContainerMemo: ({
          onClickButton,
          onClickContentButtonComponent,
        }) => {
          return (props) => {
            const { children } = props;
            const onClick = () => {
              onClickButton();
              onClickContentButtonComponent({ props });
            };

            return (
              <StyledButtonContainer onClick={onClick}>
                {children}
              </StyledButtonContainer>
            );
          };
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) =>
          internalFormData && (
            <AllocationSingleForm
              {...rest}
              name={internalFormData.name ?? null}
              userCurrentJobRole={internalFormData.userCurrentJobRole ?? null}
              jobRole={internalFormData.jobRole ?? null}
              mode={internalFormData?.mode}
              yearMonth={internalFormData.yearMonth}
              allocationType={internalFormData.allocationType}
            />
          ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          internalFormData,
        }) => {
          const { t } = useTranslation();

          const canShowSaveButton = ["editing", "creation"].includes(
            internalFormData?.mode ?? ""
          );

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {internalFormData?.mode !== "viewing"
                  ? t("general.actions.general.cancel")
                  : t("general.actions.general.close")}
              </Button>
              {canShowSaveButton && (
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              )}
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (
            !internalFormData?.idCostCenterPep ||
            !internalFormData?.yearMonth
          )
            throw new Error("Missing internal form data on submit.");

          try {
            const allocationModifySingleDTO: AllocationModifySingleDTO = {
              allocationGroup: internalFormData.allocationGroup,
              yearMonth: internalFormData.yearMonth,
              week1Percentage: parseLocaleNumber(formValues.week1Percentage),
              week2Percentage: parseLocaleNumber(formValues.week2Percentage),
              week3Percentage: parseLocaleNumber(formValues.week3Percentage),
              week4Percentage: parseLocaleNumber(formValues.week4Percentage),
              week5Percentage: formValues.week5Percentage
                ? parseLocaleNumber(formValues.week5Percentage)
                : undefined,
              version: internalFormData.allocationYearMonth?.version,
            };

            await modifySingleAllocation(allocationModifySingleDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            reloadAllocationsSummary();
            closeModal();

            if (
              checkIfNeedsProjectReload(
                internalFormData.allocationYearMonth,
                allocationModifySingleDTO,
                internalFormData.yearMonth
              )
            )
              reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "allocations.errors.data.update.failedToUpdateAllocationsGroup",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
