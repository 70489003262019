import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../types/core/typescript.types";
import { SimpleIconButton, SimpleIconButtonProps } from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const DownloadIconButton = ({
  tooltipText = "general.actions.general.download",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={FileDownloadIcon}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
