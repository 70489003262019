import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { JobRolesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/jobRoles/JobRoleSearchAutocompleteFormik";
import { FilterValues } from "../../../shared/types/filter.types";
import { StyledForm } from "./index.style";

type OwnProps = FormContentProps<FilterValues>;

export const JobRatesFilterForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <JobRolesSearchAutocompleteFormik
        name="jobRole"
        autocompleteProps={{ disabled: isSubmitting }}
        shouldReplaceValueOnMount
        onlyActivated={false}
      />
      <SubsidiariesAutocompleteFormik
        name="subsidiary"
        autocompleteProps={{ disabled: isSubmitting }}
      />
      <YearMonthDatePickerFormik
        name="startDate"
        datePickerProps={{
          label: t("general.keywords.fields.startDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
      />
      <YearMonthDatePickerFormik
        name="endDate"
        datePickerProps={{
          label: t("general.keywords.fields.endDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
      />
    </StyledForm>
  );
};
