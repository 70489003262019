import { RestrictionsOperand } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { OrPermissions } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/OrPermissions";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";

export const costCenterReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CostCenter,
  level: PermissionLevel.Read,
};

export const customerReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Customer,
  level: PermissionLevel.Read,
};

export const uploadInvoicesReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadPaidOutInvoices,
};

export const invoicesReadRestrictions: RestrictionsOperand = {
  type: PermissionType.InvoiceModule,
  level: PermissionLevel.Read,
};

export const customerBalanceReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CustomerBalance,
  level: PermissionLevel.Read,
};

export const waccReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Wacc,
  level: PermissionLevel.Read,
};

export const feeReadRestrictions: RestrictionsOperand = {
  type: PermissionType.FeeTypes,
  level: PermissionLevel.Read,
};

export const controllershipReadRestrictions = new OrPermissions(
  costCenterReadRestrictions,
  customerReadRestrictions,
  uploadInvoicesReadRestrictions,
  invoicesReadRestrictions,
  customerBalanceReadRestrictions,
  waccReadRestrictions,
  feeReadRestrictions
);
