import { useMemo } from "react";
import { useTypedSelector } from "../../../../../../../store";
import { RestrictionsOperand } from "../../../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { checkPermissions } from "../../../../../helpers/data/user/permissions/validators.helpers";

interface OwnProps {
  restrictions: RestrictionsOperand;
}

export const usePermissionChecker = ({ restrictions }: OwnProps) => {
  const userData = useTypedSelector((state) => state.user.userData);

  const hasPermission = useMemo(() => {
    return checkPermissions({
      restrictions,
      userPermissions: userData?.permissions,
      isAdmin: userData?.isAdmin,
    });
  }, [userData?.permissions, userData?.isAdmin]);

  return { hasPermission };
};
