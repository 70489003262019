import { useTranslation } from "react-i18next";
import { LabelColorTypeColorCode } from "../../../maps/LabelColorTypeColorCode";
import { LabelColorTypeTranslationCode } from "../../../maps/LabelColorTypeTranslationCode";
import {
  StylesContainer,
  StylesDivColor,
  StylesLegend,
  StylesList,
} from "./index.styles";

export const Legend = () => {
  const { t } = useTranslation();

  return (
    <StylesContainer>
      <StylesLegend>
        <StylesList>
          <span>{`${t("allocationsMap.keywords.general.legend")}:`}</span>
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[0])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[0]} />
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[1])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[1]} />
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[2])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[2]} />
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[3])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[3]} />
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[4])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[4]} />
        </StylesList>
        <StylesList>
          <span>{t(LabelColorTypeTranslationCode[5])}</span>
          <StylesDivColor color={LabelColorTypeColorCode[5]} />
        </StylesList>
      </StylesLegend>
    </StylesContainer>
  );
};
