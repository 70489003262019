import { useCallback, useContext } from "react";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ProjectionsSuperUserContext } from "../../../../context/projectionsSuperUserContext";
import { ProjectionFormValues } from "../../../../types/form.types";
import { PasteIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/PasteIconButton";

interface OwnProps {
  formikProps: FormikProps<ProjectionFormValues>;
}

export const useCopyPasteCellInformation = ({
  formikProps: { setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();
  const { canEditAllProjections } = useContext(ProjectionsSuperUserContext);

  const onClickPast = useCallback(
    async (i: number) => {
      const str = await navigator.clipboard.readText();
      const fstr = str.trim().split("\n");
      const data = fstr.map((line) => {
        const strNumberList = line.split("\t").map((strNumber) => {
          const n = parseLocaleNumber(strNumber);
          const formatted = formatNumber(n, {
            fractionDigits: 2,
          });
          return formatted;
        });
        return strNumberList;
      });
      const firstLine = data[0];

      function getMonthNameByIndex(index: number) {
        const date = new Date();
        date.setMonth(index);

        const monthName = date.toLocaleString("en-US", {
          month: "long",
        });
        return monthName.toLowerCase();
      }

      const yearMonthNow = YearMonth.createFromNow();
      const monthNow = yearMonthNow.month;
      firstLine.forEach((value, index) => {
        const month = getMonthNameByIndex(
          canEditAllProjections ? index : monthNow + index
        );
        const monthValue = value;
        setFieldValue(`adjustmentLines[${i}].${month}.value`, monthValue);
      });
    },
    [canEditAllProjections]
  );

  const PastIconButton = useCallback(
    (i: number) => {
      return (
        <PasteIconButton
          color="primary"
          onClick={() => onClickPast(i)}
          tooltipText={t("general.actions.general.pasteValuesFromCells")}
        />
      );
    },
    [canEditAllProjections]
  );

  return {
    PastIconButton,
  };
};
