import { styled, TableRow } from "@mui/material";

interface OwnProps {
  emptyRows: number;
  fixedcellheight: number;
}

export const StyledFillerTableRow = styled(TableRow)<OwnProps>`
  height: ${({ emptyRows, fixedcellheight }) => fixedcellheight * emptyRows}px;

  .MuiTableCell-root {
    border-bottom-width: 0;
  }
`;
