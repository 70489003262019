import { css, styled } from "@mui/material";
import { HTMLProps } from "react";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  disabled?: boolean;
}

const PureDiv = ({ disabled, ...rest }: OwnProps) => <div {...rest} />;

export const StyledEndAdornment = styled(PureDiv)`
  ${({ theme, disabled }) =>
    disabled &&
    css`
      display: flex;
      .MuiAutocomplete-endAdornment {
        position: static;
      }
    `}
`;
