import * as yup from "yup";
import {
  AdjustmentLineDTO,
  schemaAdjustmentLineDTO,
} from "./AdjustmentLineDTO";
import { DreValuesDTO, schemaDreValuesDTO } from "./DreValuesDTO";
import {
  SubsidiaryDTO,
  schemaSubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  SegmentGroupDTO,
  schemaSegmentGroupDTO,
} from "../Segment/SegmentGroupDTO";
import {
  ProjectionAdjustmentMonthlyCommentDto,
  schemaProjectionAdjustmentMonthlyCommentDto,
} from "../ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentDto";

export interface ProjectionAdjustmentDTO {
  riseDreTax: DreValuesDTO[];
  riseDreRevenue: DreValuesDTO[];
  riseDreExpense: DreValuesDTO[];
  pipedriveDreTax: DreValuesDTO[];
  pipedriveDreRevenue: DreValuesDTO[];
  pipedriveDreExpense: DreValuesDTO[];
  monthlyComments: ProjectionAdjustmentMonthlyCommentDto[];
  grossRevenue: AdjustmentLineDTO[];
  tax: AdjustmentLineDTO[];
  salesCost: AdjustmentLineDTO[];
  dol: AdjustmentLineDTO[];
  sgAndA: AdjustmentLineDTO[];
  pdd: AdjustmentLineDTO[];
  admCorp: AdjustmentLineDTO[];
  netFinancialExpenses: AdjustmentLineDTO[];
  irCsll: AdjustmentLineDTO[];
  participationProgram: AdjustmentLineDTO[];
  subsidiary: SubsidiaryDTO;
  division: DivisionDTO;
  segmentGroup: SegmentGroupDTO;
}

export const schemaProjectionAdjustmentDTO = yup.object({
  riseDreTax: yup.array(schemaDreValuesDTO).defined(),
  riseDreRevenue: yup.array(schemaDreValuesDTO).defined(),
  riseDreExpense: yup.array(schemaDreValuesDTO).defined(),
  pipedriveDreTax: yup.array(schemaDreValuesDTO).defined(),
  pipedriveDreRevenue: yup.array(schemaDreValuesDTO).defined(),
  pipedriveDreExpense: yup.array(schemaDreValuesDTO).defined(),
  monthlyComments: yup
    .array(schemaProjectionAdjustmentMonthlyCommentDto)
    .defined(),
  grossRevenue: yup.array(schemaAdjustmentLineDTO).defined(),
  tax: yup.array(schemaAdjustmentLineDTO).defined(),
  salesCost: yup.array(schemaAdjustmentLineDTO).defined(),
  dol: yup.array(schemaAdjustmentLineDTO).defined(),
  sgAndA: yup.array(schemaAdjustmentLineDTO).defined(),
  pdd: yup.array(schemaAdjustmentLineDTO).defined(),
  admCorp: yup.array(schemaAdjustmentLineDTO).defined(),
  netFinancialExpenses: yup.array(schemaAdjustmentLineDTO).defined(),
  irCsll: yup.array(schemaAdjustmentLineDTO).defined(),
  participationProgram: yup.array(schemaAdjustmentLineDTO).defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  division: schemaDivisionDTO.defined(),
  segmentGroup: schemaSegmentGroupDTO.defined(),
});
