import { Button } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { schemaUserCollaboratorDTO } from "../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { ModalFilters } from "../../shared/types/data/filters.types";
import { FilterForm } from "./FilterForm";
import { FilterValues } from "./shared/types/general.types";
import { schemaSkillDTO } from "../../../../../../../shared/specific/DTOs/skill/SkillDTO";
import { schemaPipedriveServiceLineDTO } from "../../../../../../../shared/specific/DTOs/pipedrive/PipedriveServiceLineDTO";
import { schemaKeywordDTO } from "../../../../../../../shared/specific/DTOs/Keyword/KeywordDTO";

export const useFilterForm = () => {
  const { t, i18n } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [i18n.language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      code: "",
      customerName: "",
      division: null,
      IdsUsersManagers: [],
      skills: [],
      pipedriveServiceLine: [],
      keywords: [],
    };

    const validationSchema = yup.object({
      code: yup.string(),
      customerName: yup.string(),
      division: yup.object({ id: yup.number().integer() }).nullable(),
      IdsUsersManagers: yup.array(schemaUserCollaboratorDTO),
      skills: yup.array(schemaSkillDTO),
      pipedriveServiceLine: yup.array(schemaPipedriveServiceLineDTO),
      keywords: yup.array(schemaKeywordDTO),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filtersModal,
  } = useFormikModalButton({
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        if (
          formValues.IdsUsersManagers.length > 0 ||
          formValues.keywords.length > 0 ||
          formValues.pipedriveServiceLine.length > 0 ||
          formValues.skills.length > 0 ||
          formValues.code ||
          formValues.customerName ||
          formValues.division
        )
          return true;
        return false;
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<ModalFilters>({
          code: formValues.code || undefined,
          customerName: formValues.customerName || undefined,
          idDivision: formValues.division?.id ?? undefined,
          IdsUsersManagers: formValues.IdsUsersManagers.map((x) => x.id),
          IdsProjectPipedriveServiceLines: formValues.pipedriveServiceLine.map(
            (x) => x.id
          ),
          IdsKeyWord: formValues.keywords.map((x) => x.id),
          IdsSkills: formValues.skills.map((x) => x.id),
        }),
      FormContentMemo: FilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filtersModal };
};
