import { styled } from "@mui/material";

interface StyledCenteredInfoProps {
  isPast?: boolean;
  isTotal?: boolean;
}

export const StyledCenteredInfo = styled("div")<StyledCenteredInfoProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 39px;
  white-space: nowrap;
  background-color: ${({ isPast }) => (isPast ? "#e6e6e6" : "white")};
  font-weight: ${({ isTotal }) => (isTotal ? 800 : 0)};
`;

export const StyledRowHeader = styled("div")`
  width: 100%;
  text-align: right;
  font-weight: 500;
`;
