import i18next from "i18next";
import { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { deleteUserFromGroup } from "../../../../../../../../../services/userGroup/userGroup.service";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { UserGroupContext } from "../../context";

export const useDeletionForm = () => {
  const { t } = useTranslation();
  const { setIsLoading } = useContext(UserGroupContext);

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<
    { collaboratorGroupId: number; collaboratorName: string },
    { collaboratorGroupId: number; collaboratorName: string }
  >({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: formData }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{ name: formData?.collaboratorName ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: { collaboratorGroupId, collaboratorName },
        setInternalModalData,
        setIsLoadingModal,
      }) => {
        setIsLoadingModal(true);
        setInternalModalData({ collaboratorGroupId, collaboratorName });
        setIsLoadingModal(false);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: formData }) => {
        if (!formData?.collaboratorGroupId)
          throw new Error(
            "CollaboratorGroupId data cannot be null on confirm."
          );

        try {
          setIsLoading(true);

          await deleteUserFromGroup(formData.collaboratorGroupId);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="accessGroups.errors.data.delete.failedToDeleteUser"
              values={{ name: formData?.collaboratorName ?? "-" }}
              components={{ italic: <em /> }}
            />
          );
        } finally {
          setIsLoading(false);
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
