import { ProjectionFormValues } from "../../types/form.types";
import { getAdjustmentSumByMonth, getDreSumByMonth } from "./auxFunctions";
import { expenseGroupTotalValuesFunctions } from "./expenseGroupTotalValuesFunctions";

function getTotalTaxAmount(formValues: ProjectionFormValues) {
  const totalGrossTaxPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const riseMonthTax = getDreSumByMonth(m, formValues.riseDreTax);
    const pipedriveMonthTax = getDreSumByMonth(m, formValues.pipedriveDreTax);
    const adjustmentMonthTax = formValues.adjustmentLines
      ? getAdjustmentSumByMonth(m, formValues.adjustmentLines)
      : 0;
    totalGrossTaxPerMonth.push(
      riseMonthTax + pipedriveMonthTax + adjustmentMonthTax
    );
  });

  return totalGrossTaxPerMonth;
}

function getTotalNetRevenue(formValues: ProjectionFormValues) {
  const totalNetRevenuePerMonth: number[] = [];
  const totalGrossTaxPerMonth: number[] = getTotalTaxAmount(formValues);
  const totalGrossRevenuePerMonth: number[] =
    expenseGroupTotalValuesFunctions.getTotalGrossRevenue(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalNetRevenuePerMonth.push(
      totalGrossRevenuePerMonth[m] + totalGrossTaxPerMonth[m]
    );
  });

  return totalNetRevenuePerMonth;
}

function getSalesMarginAmount(formValues: ProjectionFormValues) {
  const totalSalesMarginAmountPerMonth: number[] = [];
  const totalNetRevenuePerMonth: number[] = getTotalNetRevenue(formValues);
  const totalSalesCostPerMonth: number[] =
    expenseGroupTotalValuesFunctions.getTotalSalesCost(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalSalesMarginAmountPerMonth.push(
      totalNetRevenuePerMonth[m] + totalSalesCostPerMonth[m]
    );
  });

  return totalSalesMarginAmountPerMonth;
}

function getSalesMarginPercentage(formValues: ProjectionFormValues) {
  const totalSalesMarginPercentagePerMonth: number[] = [];
  const totalSalesMarginAmountPerMonth = getSalesMarginAmount(formValues);
  const totalNetRevenuePerMonth = getTotalNetRevenue(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalSalesMarginPercentagePerMonth.push(
      totalNetRevenuePerMonth[m] !== 0
        ? (totalSalesMarginAmountPerMonth[m] / totalNetRevenuePerMonth[m]) * 100
        : 0
    );
  });

  return totalSalesMarginPercentagePerMonth;
}

function getTotalOperationMarginAmount(formValues: ProjectionFormValues) {
  const totalOperationMarginAmountPerMonth: number[] = [];
  const totalSalesMarginAmountPerMonth = getSalesMarginAmount(formValues);
  const totalDolPerMonth =
    expenseGroupTotalValuesFunctions.getTotalDolAmount(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalOperationMarginAmountPerMonth.push(
      totalSalesMarginAmountPerMonth[m] + totalDolPerMonth[m]
    );
  });

  return totalOperationMarginAmountPerMonth;
}

function getTotalOperationMarginPercentage(formValues: ProjectionFormValues) {
  const totalOperationMarginPercentagePerMonth: number[] = [];
  const totalTotalOperationMarginAmount =
    getTotalOperationMarginAmount(formValues);
  const totalNetRevenuePerMonth = getTotalNetRevenue(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalOperationMarginPercentagePerMonth.push(
      totalNetRevenuePerMonth[m] !== 0
        ? (totalTotalOperationMarginAmount[m] / totalNetRevenuePerMonth[m]) *
            100
        : 0
    );
  });

  return totalOperationMarginPercentagePerMonth;
}

function getTotalPadAmount(formValues: ProjectionFormValues) {
  const totalPadAmountPerMonth: number[] = [];
  const totalSgAndAmountPerMonth =
    expenseGroupTotalValuesFunctions.getTotalSgAndAAmount(formValues);
  const totalPddPerMonth =
    expenseGroupTotalValuesFunctions.getTotalPddAmount(formValues);
  const totalOperationalMarginAmountPerMonth =
    getTotalOperationMarginAmount(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalPadAmountPerMonth.push(
      totalSgAndAmountPerMonth[m] +
        totalPddPerMonth[m] +
        totalOperationalMarginAmountPerMonth[m]
    );
  });

  return totalPadAmountPerMonth;
}

export const taxPageFunctions = {
  getTotalTaxAmount,
  getTotalNetRevenue,
  getSalesMarginAmount,
  getSalesMarginPercentage,
  getTotalOperationMarginAmount,
  getTotalPadAmount,
  getTotalOperationMarginPercentage,
};
