import { InputAdornment } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generalFieldsConstants } from "../../../../../../../shared/common/constants/data/form/fields.constants";
import { YearMonthDatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { SubsidiaryWithBusinessRule } from "../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { JobRolesSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/jobRoles/JobRoleSearchAutocompleteFormik";
import { FormMode, JobRateDataFormValues } from "../../shared/types/form.types";
import { StyledJobRateCreationForm } from "./index.style";

type OwnProps = FormContentProps<JobRateDataFormValues> & {
  mode: FormMode;
};

export const JobRateDataForm = ({
  formikProps: { isSubmitting, values },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  const billingAmountStartAdornment = useMemo(() => {
    if (!values.subsidiary) return null;

    return (
      <InputAdornment position="start">
        {values.subsidiary?.currencySymbol}
      </InputAdornment>
    );
  }, [values.subsidiary?.currencySymbol]);

  const canBeTaxed = useMemo(() => {
    return values.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR;
  }, [values.subsidiary?.name]);

  return (
    <StyledJobRateCreationForm>
      <JobRolesSearchAutocompleteFormik
        name="jobRole"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting || mode === "editing" }}
        shouldReplaceValueOnMount
        onlyActivated={false}
      />

      <SubsidiariesAutocompleteFormik
        name="subsidiary"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting }}
      />

      <YearMonthDatePickerFormik
        name="startYearMonth"
        datePickerProps={{
          label: t("jobRates.keywords.general.startYearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
      />

      <LocalizedNumberTextFieldFormik
        name="monthlyRate"
        min={0}
        max={generalFieldsConstants.maxAmountValue}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("jobRates.keywords.general.monthlyRate"),
          required: true,
          InputProps: {
            startAdornment: billingAmountStartAdornment,
            readOnly: false,
          },
          disabled: isSubmitting,
        }}
      />

      {canBeTaxed && (
        <LocalizedNumberTextFieldFormik
          name="monthlyTax"
          min={0}
          max={generalFieldsConstants.maxAmountValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("jobRates.keywords.general.monthlyTax"),
            required: true,
            InputProps: {
              startAdornment: billingAmountStartAdornment,
              readOnly: false,
            },
            disabled: isSubmitting,
          }}
        />
      )}
    </StyledJobRateCreationForm>
  );
};
