import { css, styled } from "@mui/material";

interface OwnProps {
  value?: number;
}

export const StyledAmount = styled("div")<OwnProps>`
  ${({ value, theme }) =>
    value !== undefined &&
    value < 0 &&
    css`
      color: ${theme.palette.error.main};
      font-weight: 500;
    `}
`;
