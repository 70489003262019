import { TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SkillCategoryDTO } from "../../../../../../../DTOs/skillCategory/SkillCategoryDTO";
import { SkillCategoryFilterDTO } from "../../../../../../../DTOs/skillCategory/filters/SkillCategoryFilterDTO";
import { getSkillCategorysDropdown } from "../../../../../../../../../services/skillCategory/skillCategory.service";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillCategoryDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<SkillCategoryFilterDTO, "name">;
  filtersMemo?: Omit<SkillCategoryFilterDTO, "name">;
  shouldReplaceValueOnMount?: boolean;
  onlyActivated?: boolean;
}

const cachedSkillCategories = new CachedTranslatedData({
  callback: getSkillCategorysDropdown,
  defaultCacheDuration: 600000, // 1 hour
});

export const SkillCategoriesAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  shouldReplaceValueOnMount = true,
  onlyActivated = true,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getSkillCategoryFinal = useCallback(async () => {
    let skillCategories = await cachedSkillCategories.run();

    if (finalFilters?.idDiscipline) {
      skillCategories = skillCategories.filter(
        (x) => x.discipline.id === finalFilters?.idDiscipline
      );
    }

    if (onlyActivated) {
      skillCategories = skillCategories.filter((x) => !x.deactivatedDate);
    }

    return skillCategories;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "skillCategory.errors.data.fetch.failedToFetchSkillCategory"
      )}
      fetchOptions={getSkillCategoryFinal}
      textfieldProps={{
        label: t("skillCategory.keywords.general.category"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabel: (x) =>
          `${
            x.skillCategoryTranslation.find(
              (y) => y.idLanguage === i18n.language
            )?.name ?? x.name
          } ${finalFilters?.idDiscipline ? "" : `- ${x.discipline.name}`} ${
            x.deactivatedDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
