import { css, styled } from "@mui/material";
import { HTMLProps } from "react";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  afterBaselineEnd: boolean;
}

const PureDiv = ({ afterBaselineEnd, ...rest }: OwnProps) => <div {...rest} />;

export const ScheduledEnd = styled(PureDiv)`
  ${({ theme, afterBaselineEnd }) =>
    afterBaselineEnd &&
    css`
      font-weight: 500;
      color: ${theme.palette.error.main};
    `}
`;
