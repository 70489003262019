import { useMemo } from "react";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { AllocationsTabWithinContext } from "./AllocationsTabWithinContext";
import { AllocationsCarouselContextProvider } from "./shared/react/contexts/AllocationsCarouselContext/AllocationsCarouselContextProvider";
import { AllocationsSummaryContextProvider } from "./shared/react/contexts/AllocationsSummaryContext";
import { AllocationsSuperUserContext } from "./shared/react/contexts/AllocationsSuperUserContext";

export const AllocationsTab = () => {
  const {
    superUserButton: canEditAllAllocationsButton,
    inSuperUserMode: canEditAllAllocations,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllAllocations,
    tooltipNotInSuperUserModeOff:
      "allocations.actions.authorization.editAllocationsWithoutRestrictions",
    tooltipInSuperUserMode:
      "allocations.actions.authorization.stopEditAllocationsWithoutRestrictions",
  });

  const allocationsSuperUserProvider = useMemo(() => {
    return {
      canEditAllAllocations,
      canEditAllAllocationsButton,
    };
  }, [canEditAllAllocationsButton, canEditAllAllocations]);

  return (
    <AllocationsSuperUserContext.Provider value={allocationsSuperUserProvider}>
      <AllocationsCarouselContextProvider>
        <AllocationsSummaryContextProvider>
          <AllocationsTabWithinContext />
        </AllocationsSummaryContextProvider>
      </AllocationsCarouselContextProvider>
    </AllocationsSuperUserContext.Provider>
  );
};
