import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")<{ isProject: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: ${({ isProject }) =>
    isProject
      ? `"a b"
         "c d"
         "e e"
         "f f"`
      : `"a b"
         "c c"
         "d d"`};
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ isProject }) => createGridAreaLettersRange(isProject ? 6 : 4)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;
