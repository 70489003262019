import * as yup from "yup";

export interface CfopDTO {
  id: number;
  code: string;
  name: string;
  insertedDate: Date;
  updatedDate: Date;
  version: string;
}

export const schemaCfopDTO = yup.object({
  id: yup.number().integer().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  insertedDate: yup.date().defined(),
  updatedDate: yup.date().defined(),
  version: yup.string().defined(),
});
