import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertTo } from "../../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { CommonProjectFormValues } from "../shared/types/forms/general.types";
import { schemaContractTypeOption } from "../../../../../types/data/form/fields/autocomplete/options/ContractTypeOption.types";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { projectsMaxLengthFields } from "../../../../../constants/data/form/constraints.constants";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return convertTo<CommonProjectFormValues>({
      customer: null,
      userManager: null,
      userCoordinator: null,
      division: null,
      subsidiary: null,
      productService: null,
      frame: null,
      code: "",
      name: "",
      description: "",
      contractType: null,
      typeOfEvidence: null,
      daysForPayment: "",
      billingAmount: "",
      mv: "",
      customerNameFromDeal: "",
      userManagerNameFromDeal: "",
      productServiceFromDeal: "",
      divisionFromDeal: "",
      segment: null,
      approvedCredit: null,
      clientAccount: null,
      isInvestmentProject: null,
    });
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      customer: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      userManager: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      userCoordinator: yup.object().nullable(),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      productService: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      frame: yup.object().nullable(),
      code: yup
        .string()
        .max(
          projectsMaxLengthFields.code,
          t(
            "general.errors.data.fields.general.mustNotMoreCharactersThanNumber",
            {
              number: projectsMaxLengthFields.code,
            }
          )
        )
        .required(t("general.errors.data.fields.general.required")),
      name: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      description: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      contractType: schemaContractTypeOption
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      typeOfEvidence: yup.object().nullable(),
      daysForPayment: yup
        .number()
        .integer()
        .min(0)
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      billingAmount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      mv: yup
        .number()
        .nullable()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .positive(t("general.errors.data.fields.numbers.mustBePositive")),
      segment: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      approvedCredit: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      isInvestmentProject: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
