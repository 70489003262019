import { styled } from "@mui/material";

export const StyledTableHeadButtons = styled("div")`
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledDeprovisionValue = styled("div")`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.75)};

  & > :first-child {
    display: inline-flex;
    align-items: center;
  }
`;
