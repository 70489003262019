import { useContext } from "react";
import { ProjectContext } from "./ProjectContext";

export const useProjectContext = () => {
  const projectContext = useContext(ProjectContext);
  if (!projectContext)
    throw new Error("ProjectContext cannot be null at this point.");

  return projectContext;
};
