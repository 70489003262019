import * as yup from "yup";
import {
  SubsidiaryDTO,
  schemaSubsidiaryDTO,
} from "../../subsidiary/SubsidiaryDTO";

export interface ProjectSummaryHeaderDTO {
  idProject: number;
  tax: number | null;
  grossRevenue: number;
  liquidRevenue: number;
  expensesTotal: number;
  daysForPayment: number | null;
  globalMv: number | null;
  commercialMv: number | null;
  atCompletionMv: number | null;
  atCompletionMvWithFinancialCost: number | null;
  subsidiary: SubsidiaryDTO;
}

export const schemaProjectSummaryHeaderDTO = yup.object({
  idProject: yup.number().integer().defined(),
  tax: yup.number().nullable().defined(),
  grossRevenue: yup.number().defined(),
  liquidRevenue: yup.number().defined(),
  expensesTotal: yup.number().defined(),
  daysForPayment: yup.number().integer().nullable().defined(),
  globalMv: yup.number().nullable().defined(),
  commercialMv: yup.number().nullable().defined(),
  atCompletionMv: yup.number().nullable().defined(),
  atCompletionMvWithFinancialCost: yup.number().nullable().defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
});
