import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCostCenters } from "../../../../../../services/costCenter/costCenter.service";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { CostCenterFiltersDTO } from "../../../../../../shared/specific/DTOs/costCenter/filters/CostCenterFiltersDTO";
import { useViewingForm } from "./useViewingForm";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";

interface OwnProps {
  filters?: CostCenterFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const { ViewingButtonContainer, viewingModal } = useViewingForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.code"),
      t("general.keywords.fields.name"),
      t("divisions.keywords.general.un"),
      t("subsidiaries.keywords.general.subsidiary"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onCostCenterPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["code", "name", "division.name", "subsidiary.name"];

        try {
          const {
            tableData: costCentersData,
            page,
            totalCount,
          } = await getCostCenters({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = costCentersData.map(
            (costCenterData): ExternalEnhancedRow => ({
              id: costCenterData.id,
              cells: [
                costCenterData.code,
                costCenterData.costCenterTranslations.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? costCenterData.name,
                costCenterData.division?.name ?? "",
                costCenterData.subsidiary?.name ?? "",
                {
                  CellRenderer: () => (
                    <>
                      <ViewingButtonContainer {...costCenterData} />
                      <Protected
                        restrictions={{
                          type: PermissionType.CostCenter,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...costCenterData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.CostCenter,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...costCenterData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onCostCenterPageChange,
    deleteDialog: deletionModal,
    editingModal,
    viewingModal,
  };
};
