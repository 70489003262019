import * as yup from "yup";

export interface BudgetRoundDTO {
  id: number;
  name: string;
  beginDate: Date;
  endDate: Date;
  version: string;
}

export const schemaBudgetRoundDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  beginDate: yup.date().defined(),
  endDate: yup.date().defined(),
  version: yup.string().defined(),
});
