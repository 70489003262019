import { ForwardedRef, forwardRef } from "react";
import { CollapseTableProps } from "./shared/types/data.types";
import { CollapseTableRef } from "./shared/types/propsRef.types";
import { CollapseTableProvider } from "./shared/context/CollapseTableContext/CollapseTableProvider";
import { CollapseTableBody } from "./CollapseTableBody";

export const CollapseTableForwardRef = (
  {
    header,
    singlePageLoader,
    fixFirstColumn,
    fixLastColumn,
  }: CollapseTableProps,
  ref: ForwardedRef<CollapseTableRef>
) => {
  return (
    <CollapseTableProvider
      ref={ref}
      headCells={header}
      singlePageLoader={singlePageLoader}
      fixFirstColumn={fixFirstColumn}
      fixLastColumn={fixLastColumn}
    >
      <CollapseTableBody />
    </CollapseTableProvider>
  );
};

export const CollapseTable = forwardRef<CollapseTableRef, CollapseTableProps>(
  CollapseTableForwardRef
);
