import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { InvoiceDatesDataFormValues } from "../../../types/form/invoiceDatesDataFormValues";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { StyledInvoiceDatesForm } from "./index.styles";

type OwnProps = FormContentProps<InvoiceDatesDataFormValues>;

export const DatesDataForm = ({
  formikProps: { isSubmitting, values },
}: OwnProps) => {
  const { t } = useTranslation();
  const [showIssueDate, setShowIssueDate] = useState(false);
  const [showPaymentDate, setShowPaymentDate] = useState(false);
  const [showPlannedCancellationDate, setShowPlannedCancellationDate] =
    useState(false);
  const [showCancellationDate, setShowCancellationDate] = useState(false);
  const [showPddDate, setShowPddDate] = useState(false);
  const [showLossDate, setShowLossDate] = useState(false);

  useEffect(() => {
    if (values.issueDate) {
      setShowIssueDate(true);
    }
    if (values.paymentDate) {
      setShowPaymentDate(true);
    }
    if (values.plannedCancellationDate) {
      setShowPlannedCancellationDate(true);
    }
    if (values.cancellationDate) {
      setShowCancellationDate(true);
    }
    if (values.pddDate) {
      setShowPddDate(true);
    }
    if (values.lossDate) {
      setShowLossDate(true);
    }
  }, []);

  return (
    <StyledInvoiceDatesForm>
      {showIssueDate && (
        <DatePickerFormik
          name="issueDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.issueDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {showPaymentDate && (
        <DatePickerFormik
          name="paymentDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.paymentDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {showPlannedCancellationDate && (
        <DatePickerFormik
          name="plannedCancellationDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.provisionCancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {showCancellationDate && (
        <DatePickerFormik
          name="cancellationDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.cancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {showPddDate && (
        <DatePickerFormik
          name="pddDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.pddDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
      {showLossDate && (
        <DatePickerFormik
          name="lossDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.lossDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
      )}
    </StyledInvoiceDatesForm>
  );
};
