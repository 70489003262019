import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { AdjustmentLineDTO } from "../../../../../../../../shared/specific/DTOs/ProjectionAdjustment/AdjustmentLineDTO";
import { DreValues, ProjectionAdjustment } from "../../types/form.types";

export function getDreSumByMonth(month: number, dreValues: DreValues[]) {
  const valuesToSum: number[] = [];
  dreValues.forEach((dreValue) => {
    const valueToAdd =
      dreValue.yearMonthValues.find((x) => x.yearMonth.month === month)
        ?.value ?? 0;
    valuesToSum.push(valueToAdd);
  });

  return valuesToSum.reduce((a, b) => a + b, 0);
}

export function getAdjustmentInitialValuesSumByMonth(
  month: number,
  adjustments: AdjustmentLineDTO[]
) {
  const valuesToSum: number[] = [];
  adjustments.forEach((adjustment) => {
    const valueToAdd =
      adjustment.yearMonthValues.find((x) => x.yearMonth.month === month)
        ?.value ?? 0;
    valuesToSum.push(valueToAdd);
  });

  return valuesToSum.reduce((a, b) => a + b, 0);
}

export function getAdjustmentSumByMonth(
  month: number,
  adjustmentLines: ProjectionAdjustment[]
) {
  const valuesToSum: number[] = [];
  adjustmentLines.forEach((adjustmentLine) => {
    switch (month) {
      case 0:
        valuesToSum.push(
          parseLocaleNumber(
            adjustmentLine.january.value === ""
              ? "0"
              : adjustmentLine.january.value
          )
        );
        break;
      case 1:
        valuesToSum.push(
          parseLocaleNumber(
            adjustmentLine.february.value === ""
              ? "0"
              : adjustmentLine.february.value
          )
        );
        break;
      case 2:
        valuesToSum.push(
          parseLocaleNumber(
            adjustmentLine.march.value === "" ? "0" : adjustmentLine.march.value
          )
        );
        break;
      case 3:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.april.value));
        break;
      case 4:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.may.value));
        break;
      case 5:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.june.value));
        break;
      case 6:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.july.value));
        break;
      case 7:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.august.value));
        break;
      case 8:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.september.value));
        break;
      case 9:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.october.value));
        break;
      case 10:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.november.value));
        break;
      case 11:
        valuesToSum.push(parseLocaleNumber(adjustmentLine.december.value));
        break;
      default:
        break;
    }
  });

  return valuesToSum.reduce((a, b) => a + b, 0);
}
