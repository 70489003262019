import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { Typography } from "@mui/material";
import { useCallback } from "react";
import { FullPageCenteredContent } from "../../shared/common/react/components/ui/FullPageCenteredContent";
import { FullPageLoading } from "../../shared/common/react/components/ui/FullPageLoading";
import { ChildrenOnlyProps } from "../../shared/common/types/core/react.types";
import { config, loginRequest } from "./config";

export const authenticatorInstance = new PublicClientApplication(config);

export const CustomAuthentication = ({ children }: ChildrenOnlyProps) => {
  return (
    <MsalProvider instance={authenticatorInstance}>{children}</MsalProvider>
  );
};

export const AutomaticAuthenticator = ({ children }: ChildrenOnlyProps) => {
  const LoadingError = useCallback(() => {
    return (
      <FullPageCenteredContent>
        <Typography paragraph>Erro de autenticação.</Typography>
        <Typography paragraph>Authentication error.</Typography>
      </FullPageCenteredContent>
    );
  }, []);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={FullPageLoading}
      errorComponent={LoadingError}
    >
      {children}
    </MsalAuthenticationTemplate>
  );
};
