import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import { PipedriveServiceLineDTO } from "../../../../../../../DTOs/pipedrive/PipedriveServiceLineDTO";
import { getPipedriveServiceLinesDropdown } from "../../../../../../../../../services/projects/projects.service";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    PipedriveServiceLineDTO,
    DisableClearable
  >["autocompleteProps"];
  shouldReplaceValueOnMount?: boolean;
  onlyActivated?: boolean;
}

const cachedPipedriveServiceLines = new CachedTranslatedData({
  callback: getPipedriveServiceLinesDropdown,
  defaultCacheDuration: 600000, // 1 hour
});

export const PipedriveServiceLinesAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount = true,
  onlyActivated = true,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "projects.errors.data.fetch.failedToFetchPortfolio"
      )}
      fetchOptions={cachedPipedriveServiceLines.run}
      textfieldProps={{
        label: t("projects.keywords.fields.portfolio"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
