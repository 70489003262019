import { Tooltip } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LabelColorTypeColorCode } from "../../../shared/maps/LabelColorTypeColorCode";
import { LabelColorTypeTranslationCode } from "../../../shared/maps/LabelColorTypeTranslationCode";
import {
  FormMode,
  LabelColorType,
  LabelType,
} from "../../../shared/types/form.types";
import { AllocationMapContext } from "../../shared/context";
import { UseEditingCellFormContentButtonContentProps } from "../../useEditingCellForm";
import {
  Container,
  StyledButtonBase,
  StyledCarouselCell,
  StyleDobleCell,
} from "./index.styles";

interface OwnProps {
  customerOfficeAllocation?: boolean;
  vacationForcast?: string;
  allocationMapObservation?: string;
  labelColorType?: LabelColorType;
  labelType?: LabelType;
  collaboratorName?: string;
  projectOrCostCenterName?: string;
  idUserCollaborator: number;
  formMode: FormMode;
  EditingButtonContainer: (
    props: UseEditingCellFormContentButtonContentProps
  ) => JSX.Element | null;
}

export const CellContent = ({
  EditingButtonContainer,
  customerOfficeAllocation,
  vacationForcast,
  allocationMapObservation,
  labelColorType,
  formMode,
  labelType,
  collaboratorName,
  projectOrCostCenterName,
  idUserCollaborator,
}: OwnProps) => {
  const { t } = useTranslation();

  const { data } = useContext(AllocationMapContext);

  const labelTypeValue = useMemo(() => {
    const allocationMapContext = data.find(
      (e) => e.idUserCollaborator === idUserCollaborator
    );

    const labelTypeValue = allocationMapContext?.labelType || labelType;

    if (labelTypeValue !== 6) {
      const labelTypeContext = data.find(
        (e) => e.idUserCollaborator === idUserCollaborator
      )?.labelColorType;

      const content = labelTypeContext || labelColorType;

      return t(LabelColorTypeTranslationCode[content ?? 0]);
    }
    return t("allocationsMap.keywords.form.none");
  }, [data, labelColorType]);

  const customerOfficeAllocationValue = useMemo(() => {
    const customerOfficeAllocationContext = data.find(
      (e) => e.idUserCollaborator === idUserCollaborator
    )?.customerOfficeAllocation;

    const content =
      data.length > 0
        ? customerOfficeAllocationContext
        : customerOfficeAllocation;

    return content
      ? t("general.keywords.general.yes")
      : t("general.keywords.general.no");
  }, [data]);

  const allocationMapObservationValue = useMemo(() => {
    const allocationMapObservationContext = data.find(
      (e) => e.idUserCollaborator === idUserCollaborator
    )?.allocationMapObservation;

    const content =
      data.length > 0
        ? allocationMapObservationContext
        : allocationMapObservation;

    const text = content ? content.split(" ") : "";

    if (text.length > 4) {
      return {
        cellText: `${text[0]} ${text[1]} ${text[2]} ${text[3]}...`,
        tooltip: content,
      };
    }

    return {
      cellText: content,
      tooltip: content,
    };
  }, [data]);

  const vacationForcastValue = useMemo(() => {
    const vacationForcastContext = data.find(
      (e) => e.idUserCollaborator === idUserCollaborator
    )?.vacationForecast;

    const content = data.length > 0 ? vacationForcastContext : vacationForcast;

    const text = content ? content.split(" ") : "";

    if (text.length > 4) {
      return {
        cellText: `${text[0]} ${text[1]} ${text[2]} ${text[3]}...`,
        tooltip: content,
      };
    }
    return {
      cellText: content,
      tooltip: content,
    };
  }, [data]);

  const fields = useMemo(
    () => ({
      customerOfficeAllocation: customerOfficeAllocationValue,
      vacationForcast: vacationForcastValue.cellText ?? "-",
      allocationMapObservation: allocationMapObservationValue.cellText ?? "-",
      labelType: labelTypeValue,
      collaboratorName,
    }),
    [t, labelColorType, data]
  );

  const tooltipFields = useMemo(
    () => ({
      customerOfficeAllocation: "",
      vacationForcast: vacationForcastValue.tooltip,
      allocationMapObservation: allocationMapObservationValue.tooltip,
      labelType: "",
      collaboratorName: "",
    }),
    [t, data]
  );

  const backgroundColor = useMemo(() => {
    const allocationMapContext = data.find(
      (e) => e.idUserCollaborator === idUserCollaborator
    );

    const content = allocationMapContext?.labelColorType || labelColorType;
    if (
      (content || content === 0) &&
      formMode !== "allocationMapObservation" &&
      formMode !== "vacationForcast" &&
      formMode !== "customerOfficeAllocation" &&
      allocationMapContext?.labelType !== 6
    )
      return LabelColorTypeColorCode[content];
    return undefined;
  }, [labelColorType, data]);

  return (
    <Container labelColorType={backgroundColor}>
      {collaboratorName ? (
        <>
          <EditingButtonContainer
            name="teste"
            formMode={formMode}
            labelType={labelType}
            customerOfficeAllocation={customerOfficeAllocation}
            vacationForcast={vacationForcast}
            allocationMapObservation={allocationMapObservation}
            idUserCollaborator={idUserCollaborator}
          >
            <Tooltip title={tooltipFields[formMode] ?? ""} placement="top">
              <StyledButtonBase>
                <StyledCarouselCell
                  labelColorType={backgroundColor}
                  formMode={formMode}
                  isCollaboratorCell={
                    !!(collaboratorName && collaboratorName?.length > 0)
                  }
                >
                  <div>
                    <p>{collaboratorName}</p>
                  </div>
                </StyledCarouselCell>
              </StyledButtonBase>
            </Tooltip>
          </EditingButtonContainer>
          <StyleDobleCell>
            <p>{projectOrCostCenterName}</p>
          </StyleDobleCell>
        </>
      ) : (
        <EditingButtonContainer
          name="teste"
          formMode={formMode}
          labelType={labelType}
          customerOfficeAllocation={customerOfficeAllocation}
          vacationForcast={vacationForcast}
          allocationMapObservation={allocationMapObservation}
          idUserCollaborator={idUserCollaborator}
        >
          <Tooltip title={tooltipFields[formMode] ?? ""} placement="top">
            <StyledButtonBase>
              <StyledCarouselCell
                labelColorType={backgroundColor}
                formMode={formMode}
                isCollaboratorCell={
                  !!(collaboratorName && collaboratorName?.length > 0)
                }
              >
                <div>
                  <p>{fields[formMode]}</p>
                </div>
              </StyledCarouselCell>
            </StyledButtonBase>
          </Tooltip>
        </EditingButtonContainer>
      )}
    </Container>
  );
};
