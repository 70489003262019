import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { FormValuesPerformedTimesheet } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { PerformedTimesheetReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/PerformedTimesheetReportFilterDTO";
import { generatePerformedTimesheetReport } from "../../../../../../../../../services/reports/reports.service";

export const PerformedTimesheet = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesPerformedTimesheet>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesPerformedTimesheet = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      collaborator: [],
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(),
      division: yup
        .object()
        .typeError(t("general.errors.data.fields.general.required"))
        .nullable(),
      collaboratorToAdd: yup.array().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesPerformedTimesheet,
    formikHelpers: FormikHelpers<FormValuesPerformedTimesheet>
  ) => {
    const formattedFilters: PerformedTimesheetReportFilterDTO = {
      usersCollaboratorsIds: values.collaborator.map((x) => x.id),
      IdDivision: values.division?.id,
      DivisionName: values.division?.name ?? undefined,
      StartDate: values?.startYearMonth
        ? YearMonth.createFromDate(values.startYearMonth)
        : undefined,
      EndDate: values?.endYearMonth
        ? YearMonth.createFromDate(values.endYearMonth)
        : undefined,
    };

    await generatePerformedTimesheetReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="startYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.startPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <YearMonthDatePickerFormik
              name="endYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.endPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <DivisionsAutocompleteFormik name="division" />
            <UsersSearchAutocompleteFormik
              name="collaborator"
              autocompleteProps={{
                multiple: true,
              }}
              textfieldProps={{
                label: t("general.keywords.fields.colaborator"),
              }}
              filtersMemo={{ isActive: true }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
