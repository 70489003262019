import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FillPageCollapseTable,
  HeaderComponentProps,
} from "../../../../../../../../../shared/common/react/components/table/CollapseTable/shared/types/data.types";
import { FixedColumnMainRow } from "./shared/components/FixedColumnMainRow";
import { FixedColumnExtensibleRow } from "./shared/components/FixedColumnExtensibleRow";
import { FixedLastColumnMainRow } from "./shared/components/FixedLastColumnMainRow";
import { ValueCell } from "./shared/components/ValueCell";
import { ProjectionFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/projection/filters/ProjectionFitlersDTO";
import { getProjections } from "../../../../../../../../../services/projection/projection.service";
import { YearMonthValueDTO } from "../../../../../../../../../shared/specific/DTOs/projection/auxTypes/YearMonthValueDTO";
import { YearMonthValueTypeDTO } from "../../../../../../../../../shared/specific/DTOs/projection/auxTypes/YearMonthValueType";
import { TypeTranslationDTO } from "../../../../../../../../../shared/specific/DTOs/projection/auxTypes/TypeTranslationDTO";
import { Language } from "../../../../../../../../../shared/common/enums/locale/Language.enum";
import { ExpenseGroup } from "../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { EmptyCell } from "./shared/components/EmptyCell";

interface OwProps {
  reloadTablePage: () => void;
  filters: ProjectionFiltersDTO;
  currency: string;
}

export const useTableData = ({
  reloadTablePage,
  filters,
  currency,
}: OwProps) => {
  const { t, i18n } = useTranslation();

  const rangeYearMonth = YearMonth.range(
    new YearMonth(0, filters.year || 2024),
    new YearMonth(11, filters.year || 2024)
  );

  const headCells = useMemo(() => {
    const headCells: HeaderComponentProps[] = [
      {
        header: "",
      },
      {
        header: <CarouselLeftButton />,
      },
    ];

    for (let i = 0; i < 5; i++) {
      headCells.push({
        header: <CarouselHead columnIndex={i} />,
      });
    }

    headCells.push({
      header: <CarouselRightButton />,
    });

    headCells.push({
      header: "Total",
    });

    return headCells;
  }, [t, rangeYearMonth]);

  const canSubmit = useCallback(() => {
    if (filters.idSubsidiary === 0 || filters.year === 0) return false;

    return true;
  }, [filters]);

  const GenerateMainInformation = (
    type: string,
    total: number,
    yearMonthValues: YearMonthValueDTO[],
    isRead?: boolean,
    idExpenseGroup?: number,
    isPercentage?: boolean
  ): React.ReactNode[] => {
    const valueCells = [
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 0)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 1)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 2)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 3)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 4)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 5)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 6)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 7)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 8)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 9)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 10)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
      <ValueCell
        currency={currency}
        info={yearMonthValues.find((x) => x.yearMonth.month === 11)?.value ?? 0}
        isRead={isRead}
        isPercentage={isPercentage}
      />,
    ];

    const mainInformation: React.ReactNode[] = [
      <FixedColumnMainRow
        info={type}
        isRead={isRead}
        idExpenseGroup={idExpenseGroup}
      />,
    ];

    mainInformation.push(<EmptyCell isRead={isRead} />);

    for (let i = 0; i < 5; i++) {
      mainInformation.push(
        <CarouselCell
          items={rangeYearMonth}
          columnIndex={i}
          checkIsCurrentItem={(item, yearMonth) => item.equals(yearMonth)}
          CarouselComponent={({ item, yearMonth }) =>
            valueCells[yearMonth?.month ?? 12]
          }
        />
      );
    }

    mainInformation.push(<EmptyCell isRead={isRead} />);

    mainInformation.push(
      <FixedLastColumnMainRow
        currency={!isPercentage ? currency : ""}
        isPercentage={!!isPercentage}
        info={total}
        isRead={isRead}
      />
    );

    return mainInformation;
  };

  const translateType = useCallback(
    (type: TypeTranslationDTO) => {
      if (i18n.language === Language.enUS)
        return type?.enName || type?.ptName || "-";
      if (i18n.language === Language.ptBR)
        return type?.ptName || type?.enName || "-";
      return "-";
    },
    [i18n]
  );

  const GenerateExtensibleInformation = (
    extensibleInformation: YearMonthValueTypeDTO[]
  ): Array<{
    info: React.ReactNode[];
  }> => {
    return extensibleInformation.map((x) => {
      const valueCells = [
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 0)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 1)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 2)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 3)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 4)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 5)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 6)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 7)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 8)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 9)?.value ?? 0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 10)?.value ??
            0
          }
        />,
        <ValueCell
          currency={currency}
          info={
            x.yearmonthValues.find((yv) => yv.yearMonth.month === 11)?.value ??
            0
          }
        />,
      ];

      const info: React.ReactNode[] = [
        <FixedColumnExtensibleRow info={translateType(x.type)} />,
      ];

      info.push(<EmptyCell />);

      for (let i = 0; i < 5; i++) {
        info.push(
          <CarouselCell
            items={rangeYearMonth}
            columnIndex={i}
            checkIsCurrentItem={(item, yearMonth) => item.equals(yearMonth)}
            CarouselComponent={({ item, yearMonth }) =>
              valueCells[yearMonth?.month ?? 12]
            }
          />
        );
      }

      info.push(<EmptyCell />);

      info.push(
        <div
          style={{
            fontWeight: "bold",
          }}
        >
          <ValueCell currency={currency} info={x.total} />
        </div>
      );

      return {
        info,
      };
    });
  };

  const onPageChange: FillPageCollapseTable = useCallback(
    async ({ setInfo }) => {
      if (!canSubmit()) {
        setInfo(t("allocationsMap.info.data.general.fillTheFilter"));
        return null;
      }

      const projections = await getProjections({ filters });

      if (!projections) {
        setInfo(t("general.info.data.general.noResultsFoundSearch"));
        return null;
      }

      return [
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.grossRevenue"),
            projections.grossRevenue.totalTotalMonth,
            projections.grossRevenue.totalMonthValues,
            false,
            ExpenseGroup.GrossRevenue
          ),
          extensibleInformation: projections.grossRevenue?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.grossRevenue.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.tax"),
            projections.tax.totalTotalMonth,
            projections.tax.totalMonthValues,
            false,
            ExpenseGroup.Tax
          ),
          extensibleInformation: projections.tax?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.tax.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.netRevenue"),
            projections.netRevenue.totalTotalMonth,
            projections.netRevenue.totalMonthValues,
            true
          ),
          extensibleInformation: projections.netRevenue?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.netRevenue.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.salesCost"),
            projections.salesCost.totalTotalMonth,
            projections.salesCost.totalMonthValues,
            false,
            ExpenseGroup.SalesCost
          ),
          extensibleInformation: projections.salesCost?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.salesCost.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.salesMarginAmount"),
            projections.salesMarginAmount.totalTotalMonth,
            projections.salesMarginAmount.totalMonthValues,
            true
          ),
          extensibleInformation: projections.salesMarginAmount
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.salesMarginAmount.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.salesMarginPercentage"),
            projections.salesMarginPercentage.totalTotalMonth,
            projections.salesMarginPercentage.totalMonthValues,
            true,
            undefined,
            true
          ),
          extensibleInformation: projections.salesMarginPercentage
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.salesMarginPercentage.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.dol"),
            projections.dol.totalTotalMonth,
            projections.dol.totalMonthValues,
            false,
            ExpenseGroup.Dol
          ),
          extensibleInformation: projections.dol?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.dol.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.operationalMarginAmount"),
            projections.operatingMarginAmount.totalTotalMonth,
            projections.operatingMarginAmount.totalMonthValues,
            true
          ),
          extensibleInformation: projections.operatingMarginAmount
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.operatingMarginAmount.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.operationalMarginPercentage"),
            projections.operatingMarginPercentage.totalTotalMonth,
            projections.operatingMarginPercentage.totalMonthValues,
            true,
            undefined,
            true
          ),
          extensibleInformation: projections.operatingMarginPercentage
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.operatingMarginPercentage.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.sgAndA"),
            projections.sgAndA.totalTotalMonth,
            projections.sgAndA.totalMonthValues,
            false,
            ExpenseGroup.Sgea
          ),
          extensibleInformation: projections.sgAndA?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.sgAndA.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.pdd"),
            projections.pdd.totalTotalMonth,
            projections.pdd.totalMonthValues,
            false,
            ExpenseGroup.Pdd
          ),
          extensibleInformation: projections.pdd?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.pdd.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.padAmount"),
            projections.padAmount.totalTotalMonth,
            projections.padAmount.totalMonthValues,
            true
          ),
          extensibleInformation: projections.padAmount?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.padAmount.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.padPercentage"),
            projections.padPercentage.totalTotalMonth,
            projections.padPercentage.totalMonthValues,
            true,
            undefined,
            true
          ),
          extensibleInformation: projections.padPercentage
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.padPercentage.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.admCorp"),
            projections.admCorp.totalTotalMonth,
            projections.admCorp.totalMonthValues,
            false,
            ExpenseGroup.AdmCorp
          ),
          extensibleInformation: projections.admCorp?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.admCorp.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.operationalResult"),
            projections.operationalResult.totalTotalMonth,
            projections.operationalResult.totalMonthValues,
            true
          ),
          extensibleInformation: projections.operationalResult
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.operationalResult.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.netFinancialExpenses"),
            projections.netFinancialExpenses.totalTotalMonth,
            projections.netFinancialExpenses.totalMonthValues,
            false,
            ExpenseGroup.NetFinancialExpenses
          ),
          extensibleInformation: projections.netFinancialExpenses
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.netFinancialExpenses.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.profitOrLossBeforeIrCsll"),
            projections.profitOrLossBeforeIrCsll.totalTotalMonth,
            projections.profitOrLossBeforeIrCsll.totalMonthValues,
            true
          ),
          extensibleInformation: projections.profitOrLossBeforeIrCsll
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.profitOrLossBeforeIrCsll.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.irCsll"),
            projections.irCsll.totalTotalMonth,
            projections.irCsll.totalMonthValues,
            false,
            ExpenseGroup.IrCsll
          ),
          extensibleInformation: projections.irCsll?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.irCsll.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.profitOrLossAfterIrCsll"),
            projections.profitOrLossAfterIrCsll.totalTotalMonth,
            projections.profitOrLossAfterIrCsll.totalMonthValues,
            true
          ),
          extensibleInformation: projections.profitOrLossAfterIrCsll
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.profitOrLossAfterIrCsll.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.participationProgram"),
            projections.participationProgram.totalTotalMonth,
            projections.participationProgram.totalMonthValues,
            false,
            ExpenseGroup.ParticipationProgram
          ),
          extensibleInformation: projections.participationProgram
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.participationProgram.extensibleInformation
              )
            : null,
        },
        {
          mainInformation: GenerateMainInformation(
            t("projection.keywords.fields.profitOrLossFromPeriod"),
            projections.profitOrLossFromPeriod.totalTotalMonth,
            projections.profitOrLossFromPeriod.totalMonthValues,
            true
          ),
          extensibleInformation: projections.profitOrLossFromPeriod
            ?.extensibleInformation
            ? GenerateExtensibleInformation(
                projections.profitOrLossFromPeriod.extensibleInformation
              )
            : null,
        },
      ];
    },
    [t, i18n, filters]
  );

  return {
    headCells,
    onPageChange,
  };
};
