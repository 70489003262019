import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generalFieldsConstants } from "../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { getNowBrazilianDate } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";

import { CurrencySubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/CurrencySubsidiariesAutocompleteFormik";
import { FormMode, FormValues } from "../../../types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  mode: FormMode;
};

export const CommonBrlParityForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  const dateAfter = useMemo(() => {
    const date = getNowBrazilianDate();
    date.setFullYear(date.getFullYear() + 5);
    return date;
  }, [t]);

  const dateBefore = useMemo(() => {
    const date = getNowBrazilianDate();
    date.setFullYear(date.getFullYear() - 5);
    return date;
  }, [t]);

  const isCreation = mode === "creation";

  return (
    <StyledViewContent>
      <StyledFormContainer isCreation={isCreation}>
        {isCreation && (
          <YearMonthDatePickerFormik
            name="yearMonth"
            datePickerProps={{
              label: t("brlParity.keywords.fields.yearMonth"),
              slotProps: {
                popper: {
                  placement: "right",
                },
              },
              minDate: dateBefore,
              maxDate: dateAfter,
            }}
          />
        )}
        <CurrencySubsidiariesAutocompleteFormik
          name="currency"
          textfieldProps={{ required: false }}
          autocompleteProps={{ disabled: !isCreation }}
        />
        <LocalizedNumberTextFieldFormik
          name="plannedValue"
          scale={4}
          min={0}
          max={generalFieldsConstants.maxAmountValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("brlParity.keywords.fields.plannedQuote"),
            required: true,
            disabled: isSubmitting,
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="performedValue"
          scale={4}
          min={0}
          max={generalFieldsConstants.maxAmountValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("brlParity.keywords.fields.performedQuote"),
            disabled: isSubmitting,
            required: false,
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="lastBusinessDayValue"
          scale={4}
          min={0}
          max={generalFieldsConstants.maxAmountValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("brlParity.keywords.fields.lastBusinessDayQuote"),
            disabled: isSubmitting,
            required: false,
          }}
        />
      </StyledFormContainer>
    </StyledViewContent>
  );
};
