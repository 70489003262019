import { CostCenterOnlyDTO } from "../../../../../../../../../../../../shared/specific/DTOs/costCenter/CostCenterOnlyDTO";
import { EmployeeAllocationType } from "../../../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

export const checkIsDisabledCostCenter = (
  allocationType: EmployeeAllocationType | null
) => {
  const allocationTypesForDisablingCostCenter = [
    EmployeeAllocationType.Vacation,
    EmployeeAllocationType.Leave,
    EmployeeAllocationType.Allowance,
    EmployeeAllocationType.Training,
  ];

  if (!allocationType && allocationType !== 0) return true;
  return allocationTypesForDisablingCostCenter.includes(allocationType);
};

export const checkIsDisabledProject = (
  allocationType: EmployeeAllocationType | null,
  costCenter: CostCenterOnlyDTO | null
) => {
  const allocationTypesForRequiringProject = [
    EmployeeAllocationType.Support,
    EmployeeAllocationType.Training,
  ];

  if (!costCenter || !allocationType) return true;
  return !allocationTypesForRequiringProject.includes(allocationType);
};
