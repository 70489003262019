import * as yup from "yup";
import {
  ProjectStatus,
  schemaProjectStatus,
} from "../../../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";

export interface ProjectStatusOption {
  id: ProjectStatus;
  label: string;
}

export const schemaProjectStatusOption = yup.object({
  id: schemaProjectStatus.defined(),
  label: yup.string().defined(),
});
