import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  CostCenterPepsFormValues,
  FormMode,
} from "../../../../types/data/form.types";
import { StyledFormContainer } from "./index.styles";
import { MaskedPepCode } from "../../form/formik/input/textField/MaskedPepCode";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { maxLengthFields } from "../../../../constants/maxLengthFields";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { ProjectDTO } from "../../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectStatus } from "../../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { RootState } from "../../../../../../../../../../store";
import { PermissionType } from "../../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { checkPermissions } from "../../../../../../../../../../shared/specific/helpers/data/user/permissions/validators.helpers";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { CostCentersSearchAutocompleteFormikProps } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { generalFieldsConstants } from "../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { AutocompleteInfoBubble } from "../../../../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";
import { CfopsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/CfopsAutocompleteFormik";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { SubsidiaryWithBusinessRule } from "../../../../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { CostCentersDealSearchAutocompleteFormik } from "../../../../../../shared/react/components/content/CommonProjectForm/InternalFormikForm/InternalFormContent/CostCenterSearcAutocompleteDealsFormik/CostCenterSearchAutocompleteFormik";
import { CofinsAutocompleteFormik } from "../CofinsAutocompleteFormik";
import { IssAutocompleteFormik } from "../IssAutocompleteFormik";
import { PisAutocompleteFormik } from "../PisAutocompleteFormik";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

type OwnProps = Omit<
  FormContentProps<CostCenterPepsFormValues>,
  "internalFormData"
> & {
  projectData?: ProjectDTO;
  mode: FormMode;
};

export const CommonCostCenterPepForm = ({
  formikProps: { values, setFieldValue, isSubmitting },
  projectData,
  mode,
}: OwnProps) => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  const isToDisable = useCallback(
    (field: string) => {
      if (mode !== "editing" || !projectData) return false;

      const statusProject = projectData?.status;

      const canUserEditAfterPlanning = checkPermissions({
        restrictions: PermissionType.CanEditPepAfterPlanning,
        userPermissions: userData?.permissions,
        isAdmin: userData?.isAdmin,
      });

      if (
        [ProjectStatus.Created, ProjectStatus.Planning].includes(statusProject)
      ) {
        return {
          subsidiary: true,
          costCenter: false,
          pepCode: false,
          pepDescription: false,
          tax: false,
          isExempted: false,
          cfop: false,
        }[field];
      }

      if (
        [ProjectStatus.Ongoing].includes(statusProject) &&
        canUserEditAfterPlanning
      ) {
        return {
          subsidiary: true,
          costCenter: false,
          pepCode: false,
          pepDescription: false,
          tax: false,
          isExempted: false,
          cfop: false,
        }[field];
      }

      if ([ProjectStatus.Ongoing].includes(statusProject)) {
        return {
          subsidiary: true,
          costCenter: true,
          pepCode: true,
          pepDescription: false,
          tax: true,
          isExempted: true,
          cfop: true,
        }[field];
      }

      return true;
    },
    [projectData, values, mode]
  );

  const costCenterFilters: CostCentersSearchAutocompleteFormikProps["filters"] =
    useMemo(() => {
      return {
        idSubsidiary: values.subsidiary?.id,
        idDivision: projectData?.division.id,
        isDeactivated: false,
      };
    }, [values.subsidiary?.id, projectData?.division.id]);

  const pepCodePrefix = useMemo(() => {
    if (!values.subsidiary?.prefix || !values.costCenter?.division.prefix)
      return null;

    return `${values.costCenter.division.prefix}${values.subsidiary.prefix}`;
  }, [values.subsidiary?.prefix, values.costCenter?.division.prefix]);

  const generalReadOnly = mode === "viewing";

  useEffectAfterRenders({
    effect: () => {
      if (isToDisable("subsidiary")) return;

      setFieldValue("costCenter", null);
      setFieldValue("isExempted", null);
      setFieldValue("pis", "");
      setFieldValue("cofins", "");
      setFieldValue("icms", "");
      setFieldValue("iss", "");
      setFieldValue("tax", "");
      setFieldValue("pepCode", "");
    },
    deps: [values.subsidiary?.id],
    rendersBeforeEffect: 1,
  });

  const totalTaxRdxBr: string = useMemo(() => {
    if (
      values.pis?.label !== "" ||
      values.cofins?.label !== "" ||
      values.icms !== "" ||
      values.iss?.label !== ""
    ) {
      let totalTaxes = 0;
      if (values.pis?.label && values.pis?.label !== "")
        totalTaxes += parseLocaleNumber(values.pis.label);
      if (values.cofins?.label && values.cofins?.label !== "")
        totalTaxes += parseLocaleNumber(values.cofins.label);
      if (values.icms !== "") totalTaxes += parseLocaleNumber(values.icms);
      if (values.iss?.label && values.iss?.label !== "")
        totalTaxes += parseLocaleNumber(values.iss.label);
      return formatNumber(totalTaxes, { fractionDigits: 2 });
    }
    return formatNumber(parseLocaleNumber(values.tax), { fractionDigits: 2 });
  }, [values.pis, values.cofins, values.icms, values.iss]);

  return (
    <StyledFormContainer>
      {generalReadOnly ? (
        <TextField
          label={t("subsidiaries.keywords.general.subsidiary")}
          value={values.subsidiary?.name ?? "-"}
          InputProps={{ readOnly: true }}
        />
      ) : (
        <SubsidiariesAutocompleteFormik
          name="subsidiary"
          autocompleteProps={{
            disabled: isToDisable("subsidiary") || isSubmitting,
          }}
          textfieldProps={{
            required: true,
            disabled: isToDisable("subsidiary") || isSubmitting,
          }}
        />
      )}
      {generalReadOnly ? (
        <TextField
          label={t("costCenters.keywords.general.costCenter")}
          value={values.costCenter?.code ?? "-"}
          InputProps={{
            readOnly: true,
            endAdornment: values.costCenter?.name ? (
              <Tooltip title={values.costCenter?.name ?? "-"} placement="top">
                <AutocompleteInfoBubble>
                  {values.costCenter?.name}
                </AutocompleteInfoBubble>
              </Tooltip>
            ) : (
              ""
            ),
          }}
        />
      ) : (
        <CostCentersDealSearchAutocompleteFormik
          name="costCenter"
          autocompleteProps={{
            disabled:
              isToDisable("costCenter") || isSubmitting || !values.subsidiary,
          }}
          textfieldProps={{ required: true }}
          filters={costCenterFilters}
          costCenterDTO={values.costCenter}
          costCenterFromDeal={values.costCenterFromDeal}
        />
      )}
      <MaskedPepCode
        name="pepCode"
        prefix={pepCodePrefix ?? undefined}
        disabled={isToDisable("pepCode") || isSubmitting || !pepCodePrefix}
        readOnly={generalReadOnly}
        required={!generalReadOnly}
      />
      <TextFieldFormik
        name="pepDescription"
        label={t("costCenterPeps.keywords.fields.pepDescription")}
        multiline
        rows={3}
        required={!generalReadOnly}
        inputProps={{
          maxLength: maxLengthFields.pepDescription,
          readOnly: generalReadOnly,
        }}
        disabled={isToDisable("pepDescription") || isSubmitting}
      />
      {values.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR && (
        <>
          {generalReadOnly ? (
            <>
              <TextField
                name="pis"
                label="PIS"
                value={values.pis?.label}
                InputProps={{ readOnly: true, endAdornment: "%" }}
              />
              <TextField
                name="cofins"
                label="COFINS"
                value={values.cofins?.label}
                InputProps={{ readOnly: true, endAdornment: "%" }}
              />
              <LocalizedNumberTextFieldFormik
                name="icms"
                overwrite="shift"
                padFractionalZeros
                max={generalFieldsConstants.maxNumberValue}
                textFieldProps={{
                  label: "ICMS",
                  InputProps: {
                    endAdornment: "%",
                    readOnly: generalReadOnly,
                  },
                }}
                disabled={isToDisable("tax") || isSubmitting}
              />
              <TextField
                name="iss"
                label="ISS"
                value={values.iss?.label}
                InputProps={{ readOnly: true, endAdornment: "%" }}
              />
            </>
          ) : (
            <>
              <PisAutocompleteFormik
                name="pis"
                textfieldProps={{
                  label: "PIS",
                  InputProps: {
                    endAdornment: "%",
                    readOnly: generalReadOnly,
                  },
                  disabled: isToDisable("tax") || isSubmitting,
                }}
                shouldReplaceValueOnMount={mode === "editing"}
              />
              <CofinsAutocompleteFormik
                name="cofins"
                textfieldProps={{
                  label: "COFINS",
                  InputProps: {
                    endAdornment: "%",
                    readOnly: generalReadOnly,
                  },
                  disabled: isToDisable("tax") || isSubmitting,
                }}
                shouldReplaceValueOnMount={mode === "editing"}
              />
              <LocalizedNumberTextFieldFormik
                name="icms"
                overwrite="shift"
                padFractionalZeros
                max={generalFieldsConstants.maxNumberValue}
                textFieldProps={{
                  label: "ICMS",
                  InputProps: {
                    endAdornment: "%",
                    readOnly: generalReadOnly,
                  },
                }}
                disabled={isToDisable("tax") || isSubmitting}
              />
              <IssAutocompleteFormik
                name="iss"
                textfieldProps={{
                  label: "ISS",
                  InputProps: {
                    endAdornment: "%",
                    readOnly: generalReadOnly,
                  },
                  disabled: isToDisable("tax") || isSubmitting,
                }}
                shouldReplaceValueOnMount={mode === "editing"}
              />
            </>
          )}
          <TextField
            name="tax"
            label={t("general.keywords.fields.tax")}
            value={totalTaxRdxBr}
            InputProps={{ readOnly: true, endAdornment: "%" }}
          />
          {generalReadOnly ? (
            <TextField
              label={t("costCenterPeps.keywords.fields.isExempted")}
              value={values.isExempted?.label ?? "-"}
              InputProps={{ readOnly: true }}
            />
          ) : (
            <YesNoAutocompleteFormik
              name="isExempted"
              autocompleteProps={{
                disabled: isToDisable("isExempted") || isSubmitting,
              }}
              textfieldProps={{
                label: t("costCenterPeps.keywords.fields.isExempted"),
                required: true,
                disabled: isToDisable("isExempted") || isSubmitting,
              }}
              shouldReplaceValueOnMount={mode === "editing"}
            />
          )}
          {generalReadOnly ? (
            <TextField
              label={t("cfops.keywords.general.cfop")}
              value={values.cfop?.name ?? "-"}
              InputProps={{ readOnly: true }}
            />
          ) : (
            <CfopsAutocompleteFormik
              name="cfop"
              textfieldProps={{
                label: t("cfops.keywords.general.cfop"),
                required:
                  values.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR,
              }}
              autocompleteProps={{
                disabled: isToDisable("cfop") || isSubmitting,
              }}
            />
          )}
        </>
      )}
      {values.subsidiary?.name === "RDX.US" && (
        <LocalizedNumberTextFieldFormik
          name="tax"
          overwrite="shift"
          padFractionalZeros
          max={generalFieldsConstants.maxNumberValue}
          textFieldProps={{
            label: t("general.keywords.fields.tax"),
            InputProps: {
              endAdornment: "%",
              readOnly: generalReadOnly,
            },
          }}
          disabled={isToDisable("tax") || isSubmitting}
        />
      )}
    </StyledFormContainer>
  );
};
