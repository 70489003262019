import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import { InputAdornment } from "@mui/material";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { StyledForm } from "./index.styles";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { DatePickerFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { ProjectDTO } from "../../../../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ActivitiesAutocompleteFormik } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/activities/ActivitiesAutocompleteFormik";
import { ActivitiesSuperUserContext } from "../../../contexts/ActivitiesSuperUserContext";
import { CommonDeprovisionFormValues } from "../../../../types/data/form/values.types";
import { useMinScheduledDate } from "../../../hooks/data/fields/useMinScheduledDate";
import { generalFieldsConstants } from "../../../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";

interface OwnProps
  extends Omit<
    FormContentProps<CommonDeprovisionFormValues>,
    "internalFormData"
  > {
  project: ProjectDTO | null;
  mode: "creating" | "editing";
  maxScheduledDate: Date | null;
}

export const CommonDeprovisionForm = ({
  formikProps: { isSubmitting },
  project,
  mode,
  maxScheduledDate,
}: OwnProps) => {
  const { t } = useTranslation();
  const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {project?.subsidiary.currencySymbol ?? ""}
      </InputAdornment>
    );
  }, [project?.subsidiary.currencySymbol]);

  const { minScheduledDate } = useMinScheduledDate();

  const isScheduledDateDisabled = useMemo(() => {
    return mode === "creating" || isSubmitting || !canEditAllActivities;
  }, [mode, isSubmitting, canEditAllActivities]);

  return (
    <StyledForm>
      <TextFieldFormik
        name="description"
        label={t("general.keywords.fields.description")}
        inputProps={{ maxLength: maxLengthFields.description }}
        required
        disabled={isSubmitting}
      />
      <LocalizedNumberTextFieldFormik
        name="amount"
        min={0}
        max={generalFieldsConstants.maxAmountValue}
        padFractionalZeros
        textFieldProps={{
          label: t("general.keywords.fields.value"),
          required: true,
          disabled: isSubmitting,
          InputProps: {
            startAdornment: currencyStartAdornment,
          },
        }}
      />
      <DatePickerFormik
        name="scheduledDate"
        datePickerProps={{
          label: t("activities.keywords.fields.scheduledAt"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isScheduledDateDisabled,
          minDate: minScheduledDate,
          maxDate: maxScheduledDate,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <ActivitiesAutocompleteFormik
        name="deprovisionType"
        autocompleteProps={{ disabled: isSubmitting }}
        textfieldProps={{
          required: true,
          disabled: isSubmitting,
        }}
      />
      <TextFieldFormik
        name="deprovisionJustification"
        label={t("activities.keywords.fields.deprovisionJustification")}
        multiline
        rows={3}
        inputProps={{ maxLength: maxLengthFields.deprovisionJustification }}
        disabled={isSubmitting}
        required
      />
    </StyledForm>
  );
};
