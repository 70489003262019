import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { getFees } from "../../../../../../../services/fee/fee.service";

interface OwProps {
  reloadTablePage: () => void;
  onlyActive: boolean;
}

export const useTableData = ({ reloadTablePage, onlyActive }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("Fee"),
      t("costCenters.keywords.general.costCenter"),
      t("fee.keywords.fields.accountingAccountCredit"),
      t("fee.keywords.fields.accountingAccountDebit"),
      t("costDistribution.keywords.fields.subsidiary"),
      {
        value: t("jobRates.keywords.general.deactivated"),
        canSort: false,
      },
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onFeePageChange: ExternalEnhancedTableExternalPageChanger = useCallback(
    async ({ newPage, order, orderBy, rowsPerPage }) => {
      const headKeys = [
        "description",
        "costCenter.code",
        "accountingAccountCredit",
        "accountingAccountDebit",
        "subsidiary.name",
        "deactivatedDate",
      ];

      try {
        const {
          tableData: feeData,
          page,
          totalCount,
        } = await getFees({
          filters: {
            onlyActive,
          },
          pagination: {
            pageSize: rowsPerPage,
            page: newPage + 1,
            columnOrderByDir: orderBy !== null ? order : undefined,
            columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
          },
        });

        const rows = feeData.map(
          (fee): ExternalEnhancedRow => ({
            id: fee.id,
            cells: [
              fee.description,
              fee.costCenter.code,
              fee.accountingAccountCredit,
              fee.accountingAccountDebit,
              fee.subsidiary.name,
              fee.deactivatedDate
                ? t("general.keywords.general.yes")
                : t("general.keywords.general.no"),
              {
                CellRenderer: () => (
                  <>
                    <Protected
                      restrictions={{
                        type: PermissionType.FeeTypes,
                        level: PermissionLevel.Update,
                      }}
                    >
                      <EditingButtonContainer {...fee} />
                    </Protected>
                    <Protected
                      restrictions={{
                        type: PermissionType.FeeTypes,
                        level: PermissionLevel.Delete,
                      }}
                    >
                      <DeleteButtonContainer {...fee} />
                    </Protected>
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ],
          })
        );
        return {
          rows,
          page: page - 1,
          totalItems: totalCount,
        };
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    },
    [i18n, onlyActive]
  );

  return {
    headCells,
    onFeePageChange,
    editingModal,
    deletionModal,
  };
};
