import { css } from "@emotion/react";
import { Box, styled } from "@mui/material";
import { EnhancedCellDataPadding } from "../../../../shared/types/data.types";

interface OwnProps {
  mincellheight: number;
  fixedcellheight?: number;
  paddingmode?: EnhancedCellDataPadding;
}

export const StyledTableCellContainer = styled(Box)<OwnProps>`
  display: flex;
  align-items: center;
  min-height: ${({ mincellheight }) => mincellheight - 1}px;
  border-bottom-width: 1px;
  word-break: break-word;
  overflow: hidden;
  height: 100%;

  ${({ fixedcellheight }) =>
    fixedcellheight &&
    css`
      height: ${fixedcellheight}px;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  ${({ theme, paddingmode }) => {
    switch (paddingmode) {
      case "vertical":
        return css`
          padding: ${theme.spacing(1)} 0;
        `;
      case "horizontal":
        return css`
          padding: 0 ${theme.spacing(2)};
        `;
      case "none":
        return null;
      case "both":
      default:
        return css`
          padding: ${theme.spacing(1)} ${theme.spacing(2)};
        `;
    }
  }}
`;
