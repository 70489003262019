import { AutocompleteOption } from "../../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";

export interface FormValues {
  amount: string;
  description: string;
  additionalDescription: AutocompleteOption<DescriptionTypes>;
  observation: string | null;
}

export enum DescriptionTypes {
  NigthAdditional = 1,
  AccumulatedFifithPercent = 2,
  PurchaseDayOffBoarding = 3,
  DobraPlatNavDoc = 4,
  Boarding = 5,
  RemuneratedDayOff = 6,
  HoursFifithPercent = 7,
  HoursHundredPercent = 8,
  Dangerousness = 9,
  OnNotice = 10,
}
