import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { ContractRoleDropdownDTO } from "../../../../../../../DTOs/ContractRole/ContractRoleDropdownDTO";
import { cachedContractRolesDropdown } from "../../../../../../../objects/data/cache/contractRoles/cachedFunctions.objects";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    ContractRoleDropdownDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnMount?: boolean;
}

export const ContractRolesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "contractRole.errors.data.fetch.failedToFetchContractRoles"
      )}
      fetchOptionsMemo={cachedContractRolesDropdown.run}
      textfieldProps={{
        label: t("contractRole.keywords.general.contractRole"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          x.contractRoleTranslations.find((y) => y.idLanguage === i18n.language)
            ?.name ?? x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
