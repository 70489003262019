import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useFormikContext } from "formik";
import {
  StyledForm,
  StyledFormInfo,
  StyledPercentageMessage,
} from "./index.styles";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AllocationEditingFormValues } from "../../../../../Projects/ProjectPlanningPage/tabs/AllocationsTab/hooks/useEditingCellForm/shared/types/data/form/values.types";
import { CorporateAllocationDTO } from "../../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationDTO";
import { FormMode } from "../shared/types/data/form/general.types";
import { SharedAllocationYearMonthCell } from "../../../../../../../../shared/specific/react/components/form/formik/textField/fields/SharedAllocationYearMonthCell";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import { getRange } from "../../../../../../../../shared/common/helpers/data/arrays/generators.helpers";
import { SharedAllocationYearMonthTotalCell } from "../../../../../../../../shared/specific/react/components/form/readOnly/textField/fields/SharedAllocationYearMonthTotalCell";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";

interface OwnProps
  extends Omit<
    FormContentProps<AllocationEditingFormValues>,
    "internalFormData"
  > {
  corporateAllocation: CorporateAllocationDTO;
  yearMonth: YearMonth;
  mode: FormMode;
}

export const AllocationSingleForm = ({
  formikProps: { values },
  corporateAllocation,
  yearMonth,
  mode,
}: OwnProps) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();

  const readOnlyMode = mode === "viewing";

  const percentageWeeks = useMemo(() => {
    if (!readOnlyMode)
      return getRange(1, 6).map((i) => (
        <SharedAllocationYearMonthCell
          key={i}
          name={`week${i}Percentage`}
          weekNumber={i}
          yearMonth={yearMonth}
          label={`${t("general.keywords.fields.week")} ${i}`}
          disabled={isSubmitting}
        />
      ));

    const weeks = [
      values.week1Percentage,
      values.week2Percentage,
      values.week3Percentage,
      values.week4Percentage,
      values.week5Percentage,
    ];

    return weeks.map((week, i) => (
      <TextField
        key={i}
        value={week ? `${week}%` : "-"}
        label={`${t("general.keywords.fields.week")} ${i + 1}`}
        InputProps={{ readOnly: true }}
      />
    ));
  }, [
    isSubmitting,
    readOnlyMode,
    yearMonth,
    values.week1Percentage,
    values.week2Percentage,
    values.week3Percentage,
    values.week4Percentage,
    values.week5Percentage,
  ]);

  return (
    <StyledForm>
      <StyledFormInfo>
        <TextField
          label={t("general.keywords.fields.colaborator")}
          value={
            corporateAllocation.userCurrentJobRole?.userColaborator.name || "-"
          }
          disabled
        />
        <TextField
          label={t("jobRoles.keywords.general.jobRole")}
          value={
            corporateAllocation.userCurrentJobRole?.currentJobRole?.name ||
            corporateAllocation.jobRole?.name ||
            "-"
          }
          disabled
        />
        <TextField
          label={t("general.keywords.fields.type")}
          value={t(
            employeeAllocationTypeToTranslationCodeMap[
              corporateAllocation.allocationType
            ]
          )}
          disabled
        />
        <TextField
          label={t("costCenters.keywords.general.costCenter")}
          value={corporateAllocation.costCenter?.name || "-"}
          disabled
        />
        {corporateAllocation.corporateSegment && (
          <TextField
            label={t("corporateSegment.keywords.general.corporateSegments")}
            value={corporateAllocation.corporateSegment.name || "-"}
            disabled
          />
        )}
      </StyledFormInfo>
      <StyledPercentageMessage>
        {t("allocations.modal.data.modifySingle.percentageAtYearMonth", {
          yearMonth: yearMonth.toPrettyString(),
        })}
      </StyledPercentageMessage>
      {percentageWeeks}
      <SharedAllocationYearMonthTotalCell
        weeks={{
          week1Percentage: values.week1Percentage,
          week2Percentage: values.week2Percentage,
          week3Percentage: values.week3Percentage,
          week4Percentage: values.week4Percentage,
          week5Percentage: values.week5Percentage,
        }}
        label={t("general.keywords.fields.total")}
        readOnly={readOnlyMode}
        yearMonth={yearMonth}
      />
    </StyledForm>
  );
};
