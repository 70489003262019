import * as yup from "yup";
import {
  Language,
  schemaLanguage,
} from "../../../../common/enums/locale/Language.enum";

export interface CostCenterTranslationDTO {
  id: number;
  idLanguage: Language;
  isDefaultLanguage: boolean;
  name: string | null;
}

export const schemaCostCenterTranslationDTO = yup.object({
  id: yup.number().integer().defined(),
  idLanguage: schemaLanguage.defined(),
  isDefaultLanguage: yup.bool().defined(),
  name: yup.string().nullable().defined(),
});
