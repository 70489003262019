import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FormValuesCreationProjectsAdditives } from "./types/form.types";
import { StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { generateProjectAndAdditiveCreationReport } from "../../../../../../../../../services/reports/reports.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { getNowBrazilianDateOnly } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";

export const CreationProjectsAdditives = () => {
  const { t } = useTranslation();

  const formikRef =
    useRef<FormikProps<FormValuesCreationProjectsAdditives>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesCreationProjectsAdditives = {
      startYearMonth: getNowBrazilianDateOnly().toDate(),
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    formValues: FormValuesCreationProjectsAdditives,
    formikHelpers: FormikHelpers<FormValuesCreationProjectsAdditives>
  ) => {
    try {
      await generateProjectAndAdditiveCreationReport({
        startDate: YearMonth.createFromDate(formValues.startYearMonth),
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <YearMonthDatePickerFormik
            name="startYearMonth"
            datePickerProps={{
              label: t("brlParity.keywords.fields.yearMonth"),
            }}
          />
          <GenerateReportButton
            isDisabled={!values.startYearMonth}
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
