import { ReactNode, useCallback, useMemo } from "react";
import {
  GetOpenCloseModalCountFunction,
  CheckInCurrentModalCountFunction,
  OnClickContentButtonComponentFunction,
} from "../shared/types/functions.types";
import { CreateModalButtonContainer as UseCreateModalButtonContainer } from "../../../../../modal/modalButtons/useModalButton";
import { CreateModalButtonContainer } from "../shared/types/props.types";
import { useUpdatedRef } from "../../../../../../hooks/enhancedReactHooks/useUpdatedRef";

interface OwnProps<FormValues, InternalFormData, ContentButtonContentProps> {
  onClickContentButtonComponent?: OnClickContentButtonComponentFunction<
    FormValues,
    InternalFormData,
    ContentButtonContentProps
  >;
  onClickContentButtonComponentMemo?: OnClickContentButtonComponentFunction<
    FormValues,
    InternalFormData,
    ContentButtonContentProps
  >;
  setInternalExternalFormValues: (formValues: FormValues) => void;
  setInternalFormData: (internalFormData: InternalFormData | null) => void;
  getOpenCloseModalCount: GetOpenCloseModalCountFunction;
  checkInCurrentModalCount: CheckInCurrentModalCountFunction;
  setIsLoadingModal: (isLoadingModal: boolean) => void;
  setIsModalDisabled: (isModalDisabled: boolean) => void;
  setModalError: (modalError: ReactNode) => void;
  createModalButtonContainer?: CreateModalButtonContainer<ContentButtonContentProps>;
  createModalButtonContainerMemo?: CreateModalButtonContainer<ContentButtonContentProps>;
}

export const useCreateModalButtonContainer = <
  FormValues,
  InternalFormData,
  ContentButtonContentProps,
>({
  checkInCurrentModalCount,
  onClickContentButtonComponent,
  onClickContentButtonComponentMemo,
  setInternalExternalFormValues,
  setInternalFormData,
  getOpenCloseModalCount,
  setIsLoadingModal,
  setIsModalDisabled,
  setModalError,
  createModalButtonContainer,
  createModalButtonContainerMemo,
}: OwnProps<FormValues, InternalFormData, ContentButtonContentProps>) => {
  const finalOnClickContentButtonComponent = useMemo(() => {
    return onClickContentButtonComponent ?? onClickContentButtonComponentMemo;
  }, [onClickContentButtonComponent]);

  const finalOnClickContentButtonComponentRef = useUpdatedRef(
    finalOnClickContentButtonComponent
  );

  const finalCreateModalButtonContainer = useCallback<
    UseCreateModalButtonContainer<ContentButtonContentProps>
  >(
    ({ ButtonContainerDiv, button, onClickButton }) => {
      const onClickContentButtonComponent = ({
        props,
      }: {
        props: ContentButtonContentProps;
      }) => {
        finalOnClickContentButtonComponentRef.current?.({
          contentButtonContentProps: props,
          setFormValues: setInternalExternalFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setIsModalDisabled,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        });
      };

      if (createModalButtonContainer)
        return createModalButtonContainer({
          ButtonContainerDiv,
          button,
          onClickButton,
          onClickContentButtonComponent,
        });

      if (createModalButtonContainerMemo)
        return createModalButtonContainerMemo({
          ButtonContainerDiv,
          button,
          onClickButton,
          onClickContentButtonComponent,
        });

      return (props: ContentButtonContentProps) => {
        const onClick = () => onClickContentButtonComponent({ props });

        return (
          <ButtonContainerDiv onClick={onClick}>{button}</ButtonContainerDiv>
        );
      };
    },
    [createModalButtonContainer]
  );

  return finalCreateModalButtonContainer;
};
