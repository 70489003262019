import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface ExpenseOfTypeDTO {
  yearMonth: YearMonth;
  amount: number;
  version: string;
}

export const schemaExpenseOfTypeDTO = yup.object({
  yearMonth: schemaYearMonth.defined(),
  amount: yup.number().defined(),
  version: yup.string().defined(),
});
