import { enviroment } from "../../../../../../core/enviroment";

export function getMainEnviromentName() {
  const redirectUrl = enviroment.apiURL;

  if (redirectUrl.includes("dev")) {
    return "Dev";
  }

  if (redirectUrl.includes("lab")) {
    return "Lab";
  }

  if (redirectUrl.includes("homolog")) {
    return "Homolog";
  }

  if (redirectUrl.includes("localhost")) {
    return "Local";
  }

  return "";
}
