import * as yup from "yup";

import {
  PermissionType,
  schemaPermissionType,
} from "../../enums/users/permissions/PermissionType.enum";
import {
  PermissionLevel,
  schemaPermissionLevelNullable,
} from "../../enums/users/permissions/PermissionLevel.enum";

export interface UserPermissionDTO {
  type: PermissionType;
  level: PermissionLevel | null;
  typeName: string;
  levelName: string | null;
}

export const schemaUserPermissionDTO = yup.object({
  type: schemaPermissionType.defined(),
  level: schemaPermissionLevelNullable.defined(),
  typeName: yup.string().defined(),
  levelName: yup.string().nullable().defined(),
});

export const schemaArrayUserPermissionDTO = yup
  .array(schemaUserPermissionDTO)
  .transform((permissionsArray) => {
    if (!Array.isArray(permissionsArray)) return permissionsArray;
    return permissionsArray.filter(
      (x) => !x?.type || PermissionType.hasOwnProperty(x.type)
    );
  });
