import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { FormFilters } from "../../InvoicesMainPage/shared/types/form.types";
import { InvoiceFilterValues } from "./InvoiceFilterValues";
import { InvoicesFilterContext } from "./InvoicesFilterContext";

export const InvoicesFilterProvider = () => {
  const [filter, setFilter] = useState<FormFilters>({
    subsidiary: null,
    projectCode: "",
    status: [],
    responsible: null,
    limmitDate: null,
    division: null,
    requester: null,
    invoiceNumber: "",
  });

  const invoicesFilterContextValues: InvoiceFilterValues = useMemo(
    () => ({
      filterValues: filter,
      setFilterValues: setFilter,
    }),
    [filter]
  );

  return (
    <InvoicesFilterContext.Provider value={invoicesFilterContextValues}>
      <Outlet />
    </InvoicesFilterContext.Provider>
  );
};
