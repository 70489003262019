import * as yup from "yup";
import { InvoicesStatusEnum } from "../../enums/invoices/InvoicesStatusEnum";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";

export interface InvoiceNegativeDTO {
  id: number;
  milestone: string;
  billingAmount: number;
  issueDate: DateOnly | null;
  status: InvoicesStatusEnum;
  version: string;
}

export const schemaInvoiceNegativeDTO = yup.object({
  id: yup.number().integer().defined(),
  issueDate: schemaDateOnly.nullable().defined(),
  milestone: yup.string().defined(),
  billingAmount: yup.number().defined(),
  status: yup.number().integer().defined(),
  version: yup.string().defined(),
});
