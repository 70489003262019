import * as yup from "yup";

export interface InvoiceFinancialResponsibleDTO {
  id: number;
  email: string;
}

export const schemaInvoiceFinancialResponsibleDTO = yup.object({
  id: yup.number().integer().defined(),
  email: yup.string().defined(),
});
