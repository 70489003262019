import { createContext } from "react";

export interface ProjectionsSuperUserContextValues {
  canEditAllProjections: boolean;
  canEditAllProjectionsButton: JSX.Element | null;
}

export const ProjectionsSuperUserContext =
  createContext<ProjectionsSuperUserContextValues>({
    canEditAllProjections: false,
    canEditAllProjectionsButton: null,
  });
