import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalUserDTO } from "../../../shared/specific/DTOs/user/LocalUserDTO";
import { UserState } from "./index.types";

const initialState: UserState = {
  userData: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<LocalUserDTO>) => {
      state.userData = action.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;

export const userReducer = userSlice.reducer;
