import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TurnOffDataFormValues } from "../shared/types/data/form/values.types";
import { StyledTurnOffForm } from "./index.style";

type OwnProps = FormContentProps<TurnOffDataFormValues>;

export const TurnOffDataForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledTurnOffForm>
      <DatePickerFormik
        name="resignationDate"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.resignationDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
        textFieldProps={{
          required: true,
        }}
      />
    </StyledTurnOffForm>
  );
};
