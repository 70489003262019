import { useTranslation } from "react-i18next";
import { DropzoneState, useDropzone } from "react-dropzone";
import { Link } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../../types/form.types";
import { StyledDropzone, StyledGrayBox, StyledLabelChip } from "./index.styles";
import { DownloadIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";

type OwnProps = FormContentProps<FormValues>;

export const CreationEditingForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFieldValue("resumeFile", acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneState =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "application/msword": [".doc", ".docx"],
      },
    });

  return (
    <>
      {((!isDragActive && !values.resumeFile) || isDragActive) && (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && !values.resumeFile && (
            <>
              <p>
                {t("collaboratorProfile.actions.data.dropzone.resumeMessage")}
              </p>
              <CloudUploadIcon fontSize="large" color="primary" />
            </>
          )}
          {isDragActive && (
            <p>{t("collaboratorProfile.actions.data.dropzone.liveFile")}</p>
          )}
        </StyledDropzone>
      )}

      {values.resumeFile && !isDragActive && (
        <StyledGrayBox
          size="medium"
          label={
            <StyledLabelChip>
              {`${values.resumeFile.name}`}
              <div>
                <Link href={URL.createObjectURL(values.resumeFile)} download>
                  <DownloadIconButton disabled={isSubmitting} color="primary" />
                </Link>
                <DeleteIconButton
                  disabled={isSubmitting}
                  onClick={() => setFieldValue("resumeFile", null)}
                />
              </div>
            </StyledLabelChip>
          }
        />
      )}
    </>
  );
};
