import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { CenteredContent } from "../../../../../shared/common/react/components/ui/CenteredContent";

export const Error404 = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle("");
  }, []);

  return (
    <CenteredContent>
      {t("general.errors.navigation.pageNotFound")}
    </CenteredContent>
  );
};
