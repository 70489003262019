import { Typography } from "@mui/material";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MainRouter } from "../../../Router";
import { setStoredDefaultLanguage } from "../../../services/languages/languages.service";
import { setGlobalUserData } from "../../../services/user/user.service";
import { FullPageCenteredContent } from "../../../shared/common/react/components/ui/FullPageCenteredContent";
import { FullPageLoading } from "../../../shared/common/react/components/ui/FullPageLoading";
import { LoggedUserNotRegisteredError } from "../../../shared/specific/errors/user/LoggedUserNotRegisteredError";
import { RootState } from "../../../store";
import { authenticatorInstance } from "../../microsoftAuthenticator";

const MINIMUM_PLEASANT_PAGE_LOADING_TIME_MS = 800;

export const AuthenticatedApp = () => {
  const { i18n } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);
  const [pageError, setPageError] = useState<ReactNode | null>(null);

  const [hasMinimumLoadingTimePassed, setHasMinimumLoadingTimePassed] =
    useState(false);

  useEffect(() => {
    if (i18n.resolvedLanguage) {
      setHasMinimumLoadingTimePassed(true);
      return;
    }

    setTimeout(
      () => setHasMinimumLoadingTimePassed(true),
      MINIMUM_PLEASANT_PAGE_LOADING_TIME_MS
    );
  }, []);

  useEffect(() => {
    const setGlobalUserDataAsyncEffect = async () => {
      try {
        await setGlobalUserData();
      } catch (error) {
        console.error(error);
        if (pageError) return;

        if (error instanceof LoggedUserNotRegisteredError) {
          setPageError(
            <>
              <Typography paragraph>
                Este usuário não está registrado no RISE...
              </Typography>
              <Typography paragraph>
                This user is not registered on RISE...
              </Typography>
            </>
          );
          return;
        }

        if (error instanceof Error) {
          console.error(error.message);
        }

        setPageError(
          <>
            <Typography paragraph>
              Falha ao se conectar no RISE. Verifique se sua conexão com a VPN
              está ativa!
            </Typography>
            <Typography paragraph>
              Failed to connect to RISE. Check your VPN connection is active!
            </Typography>
          </>
        );
      }
    };

    setGlobalUserDataAsyncEffect();
  }, [authenticatorInstance.getAllAccounts().length]);

  useEffect(() => {
    const setGlobalDefaultLanguageAsyncEffect = async () => {
      try {
        await setStoredDefaultLanguage();
      } catch (error) {
        console.error(error);
        if (pageError) return;

        setPageError(
          <>
            <Typography paragraph>
              Falha ao se conectar no RISE. Verifique se sua conexão com a VPN
              está ativa!
            </Typography>
            <Typography paragraph>
              Failed to connect to RISE. Check your VPN connection is active!
            </Typography>
          </>
        );
      }
    };

    setGlobalDefaultLanguageAsyncEffect();
  }, [authenticatorInstance.getAllAccounts().length]);

  const isLoading = useMemo(() => {
    return !hasMinimumLoadingTimePassed || !i18n.resolvedLanguage || !userData;
  }, [hasMinimumLoadingTimePassed, i18n.resolvedLanguage, userData]);

  if (pageError) {
    return <FullPageCenteredContent>{pageError}</FullPageCenteredContent>;
  }

  if (isLoading) {
    return <FullPageLoading />;
  }

  return <MainRouter />;
};
