import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getJobRoles } from "../../../../../../services/jobRole/jobRole.service";
import { AllocationCostIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AllocationCostIconButton";
import { CustomLink } from "../../../../../../shared/common/react/components/navigation/CustomLink";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/data.types";
import { ExternalEnhancedTableExternalPageChanger } from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { JobRoleFilterDTO } from "../../../../../../shared/specific/DTOs/jobRole/filters/JobRoleFilterDTO";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useViewingForm } from "./useViewingForm";

interface OwnProps {
  filters?: JobRoleFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const { ViewingButtonContainer, viewingModal } = useViewingForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      t("jobRoles.keywords.general.deactivatedDate"),
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onJobRolePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["name", "deactivatedDate"];

        try {
          const {
            tableData: jobRolesData,
            page,
            totalCount,
          } = await getJobRoles({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = jobRolesData.map(
            (jobRoleData): ExternalEnhancedRow => ({
              id: jobRoleData.id,
              cells: [
                jobRoleData.jobRoleTranslations.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? jobRoleData.name,
                {
                  value: jobRoleData.deactivatedDate,
                  displayValue:
                    jobRoleData.deactivatedDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "",
                },
                {
                  CellRenderer: () => (
                    <>
                      <ViewingButtonContainer {...jobRoleData} />
                      <Protected
                        restrictions={{
                          type: PermissionType.JobRole,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...jobRoleData} />
                      </Protected>
                      <CustomLink
                        to={`/job-rates?initialIdJobRole=${jobRoleData.id}`}
                      >
                        <AllocationCostIconButton />
                      </CustomLink>
                      <Protected
                        restrictions={{
                          type: PermissionType.JobRole,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...jobRoleData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onJobRolePageChange,
    deleteDialog: deletionModal,
    editingModal,
    viewingModal,
  };
};
