import * as yup from "yup";
import { riseApi } from "./connection";
import {
  BudgetRoundDTO,
  schemaBudgetRoundDTO,
} from "../../../shared/specific/DTOs/BudgetRound/BudgetRoundDTO";
import { BudgetRoundCreateDTO } from "../../../shared/specific/DTOs/BudgetRound/BudgetRoundCreateDTO";
import { BudgetRoundUpdateDTO } from "../../../shared/specific/DTOs/BudgetRound/BudgetRoundUpdateDTO";

export const getBudgetRounds = async (): Promise<Array<BudgetRoundDTO>> => {
  const { data: BudgetRounds } = await riseApi.get("BudgetRound");
  return yup.array(schemaBudgetRoundDTO).defined().validateSync(BudgetRounds);
};

export const getBudgetRoundId = async (
  id: number
): Promise<BudgetRoundDTO | null> => {
  const { data: bedgetRound } = await riseApi.get(`/BudgetRound/${id}`);

  return schemaBudgetRoundDTO.nullable().validateSync(bedgetRound);
};

export const createBudgetRound = async (budgetRound: BudgetRoundCreateDTO) => {
  await riseApi.post("BudgetRound", budgetRound);
};

export const updateBudgetRound = async (budgetRound: BudgetRoundUpdateDTO) => {
  await riseApi.put("BudgetRound", budgetRound);
};

export const deleteBudgetRound = async (id: number, version: string) => {
  await riseApi.delete(`BudgetRound/${id}/${version}`);
};
