import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { ReactNode } from "react";
import { AllocationsOfUserDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { FormValues } from "./shared/types/data/form/values.types";
import { assignUserToAllocationGroup } from "../../../../../../../../../services/allocations/allocations.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useFormikConfig } from "./shared/hooks/data/form/validation/useFormikConfig";
import { StyledButtonContainer } from "./index.styles";
import { AllocationSingleForm } from "./AllocationsAssignToUserForm";
import { AllocationGroupAssignToUserDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationGroupAssignToUserDTO";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";

interface OwnProps {
  reloadTablePage: () => void;
  reloadAllocationsSummary: () => void;
}

interface InternalFormData {
  allocationPerUserRow: AllocationsOfUserDTO;
}

export interface UseAssignAllocationGroupToUserFormProps {
  children: ReactNode;
  allocationPerUserRow: AllocationsOfUserDTO;
}

export const useAssignAllocationGroupToUserForm = ({
  reloadTablePage,
  reloadAllocationsSummary,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const formikConfig = useFormikConfig();

  const {
    ContentButton: AssigningAllocationUserButton,
    contentModal: assigningAllocationUserModal,
  } = useFormikModalButton<
    FormValues,
    InternalFormData,
    UseAssignAllocationGroupToUserFormProps
  >({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("allocations.modal.data.updatedToUserAllocation.title"),
      modalMode: "dialog",
    },
    button: {
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: { allocationPerUserRow },
        setInternalFormData,
      }) => {
        setInternalFormData({
          allocationPerUserRow,
        });
      },
      createModalButtonContainerMemo: ({
        onClickButton,
        onClickContentButtonComponent,
      }) => {
        return (props) => {
          const { children } = props;
          const onClick = () => {
            onClickButton();
            onClickContentButtonComponent({ props });
          };

          return (
            <StyledButtonContainer onClick={onClick}>
              {children}
            </StyledButtonContainer>
          );
        };
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) =>
        internalFormData && (
          <AllocationSingleForm
            {...rest}
            jobRole={{
              id: internalFormData.allocationPerUserRow?.idJobRole ?? null,
              name:
                internalFormData.allocationPerUserRow
                  .currentCollaboratorJobRoleName ||
                internalFormData.allocationPerUserRow?.jobRoleName,
            }}
          />
        ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!internalFormData)
          throw new Error("Missing internal form data on submit.");

        try {
          await assignUserToAllocationGroup(
            convertTo<AllocationGroupAssignToUserDTO>({
              allocationGroup:
                internalFormData.allocationPerUserRow.allocationGroup,
              idUserCollaborator:
                formValues.userCurrentJobRole?.userColaborator.id ?? 0,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          reloadAllocationsSummary();
          closeModal();

          if (project.isValid) reloadProject();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "allocations.errors.data.updatedToUserAllocation.failedToAssignAllocationGroupToColaborator",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return {
    AssigningAllocationUserButton,
    assigningAllocationUserModal,
  };
};
