import * as yup from "yup";

import { AccessGroupsFiltesDTO } from "../../../Router/pages/authenticated/AccessGroups/AccessGroupsMainPage/shared/types/data/data.filters";
import { AccessGroupCreateDeletePermissionDTO } from "../../../shared/specific/DTOs/accessGroups/AccessGroupCreateDeletePermissionDTO";
import { AccessGroupCreateDTO } from "../../../shared/specific/DTOs/accessGroups/AccessGroupCreateDTO";
import {
  AccessGroupListPermissionsDTO,
  schemaAccessGroupListPermissionsDTO,
} from "../../../shared/specific/DTOs/accessGroups/AccessGroupListPermissionsDTO";
import {
  AccessGroupsDTO,
  schemaAccessGroupsDTO,
} from "../../../shared/specific/DTOs/accessGroups/AccessGroupsDTO";
import { AccessGroupUpdateDTO } from "../../../shared/specific/DTOs/accessGroups/AccessGroupUpdateDTO";
import {
  PaginatedAccessGroupsDTO,
  schemaPaginatedAccessGroupsDTO,
} from "../../../shared/specific/DTOs/accessGroups/PaginatedAccessGroupsDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { PermissionType } from "../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { riseApi } from "./connection";

export const getAccessGroups = async ({
  filters,
  pagination,
}: {
  filters?: AccessGroupsFiltesDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedAccessGroupsDTO> => {
  const { data: accessGroups } = await riseApi.get("AccessGroup", {
    params: {
      ...pagination,
      ...filters,
    },
  });

  return schemaPaginatedAccessGroupsDTO.validateSync(accessGroups);
};

export const getAccessGroupByIdUserCollaborator = async (
  id: number
): Promise<PaginatedAccessGroupsDTO> => {
  const { data: accessGroups } = await riseApi.get(`/AccessGroup`, {
    params: {
      idUserCollaborator: id,
    },
  });

  return schemaPaginatedAccessGroupsDTO.validateSync(accessGroups);
};

export const getAccessGroupById = async (
  id: number
): Promise<AccessGroupsDTO | null> => {
  const { data: accessGroup } = await riseApi.get(`/AccessGroup/${id}`);

  return schemaAccessGroupsDTO.nullable().validateSync(accessGroup);
};

export const createAccessGroup = async (accessGroup: AccessGroupCreateDTO) => {
  await riseApi.post("/AccessGroup", accessGroup);
};

export const updateAccessGroup = async (accessGroup: AccessGroupUpdateDTO) => {
  await riseApi.put("/AccessGroup", accessGroup);
};

export const deleteAccessGroup = async (id: number) => {
  await riseApi.delete(`AccessGroup/${id}`);
};

export const getListPermissionsByIdAccessGroup = async (
  id: number
): Promise<AccessGroupListPermissionsDTO[]> => {
  const { data: accessGroupListPermission } = await riseApi.get(
    `/AccessGroup/ListPermissionsBy/${id}`
  );

  return yup
    .array(schemaAccessGroupListPermissionsDTO)
    .transform((permissionsArray) => {
      if (!Array.isArray(permissionsArray)) return permissionsArray;
      return permissionsArray.filter(
        (x) =>
          !x?.permissionType || PermissionType.hasOwnProperty(x.permissionType)
      );
    })
    .defined()
    .validateSync(accessGroupListPermission);
};

export const createDeleteAccessGroupPermissions = async (
  permissionsList: AccessGroupCreateDeletePermissionDTO
) => {
  await riseApi.post("/AccessGroup/CreateDeletePermissions", permissionsList);
};
