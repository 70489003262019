import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../types/data/form.types";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      subsidiary: null,
      division: null,
      customer: null,
      name: "",
      startDate: null,
      endDate: null,
      amount: "",
      accountManager: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      customer: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      name: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      startDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      endDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      amount: yup
        .number()
        .typeError(t("frames.errors.data.general.amountInvalid"))
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      accountManager: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
