import { styled } from "@mui/material";

export const DayNumber = styled("div")`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const TimeOtherProjects = styled("div")`
  font-size: 0.92rem;
  color: ${({ theme }) => theme.palette.grey[600]};
`;
