import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import {
  ProjectionAdjustment,
  ProjectionFormValues,
} from "../../../types/form.types";
import { useTableData } from "./hooks/useTableData";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { AddIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ProjectionFilterContext } from "../../../../../shared/context/ProjectionFilterContext";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { ProjectionAdjustmentContext } from "../../../context/projectionAdjustmentContexProvider";
import { useDreTableData } from "./hooks/useDreTableData";
import {
  StyledButtonArea,
  StyledCircularProgress,
  StyledFooterContent2,
  StyledMainContainer,
  StyledTable2,
  StyledTableHeader,
  StyledTablesSection,
} from "./index.styles";
import { useSumArea } from "./hooks/useSumArea";
import { PerfectlyCenteredText } from "../../../../../../UploadPaidOutInvoice/UploadPaidOutInvoicePage/useUploadForm/components";
import { CarouselContext } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";
import { useMonthlyCommentTable } from "./hooks/useMonthlyCommentTable";
import { ProjectionsSuperUserContext } from "../../../context/projectionsSuperUserContext";
import { useHeadCells } from "./hooks/useHeadCells";
import { useProjectionSearchParams } from "../../hooks/useProjectionSearchParams";

interface OwnProps {
  formikProps: FormikProps<ProjectionFormValues>;
}

export const ProjectionFormWIthInContext = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();
  const { filterValues } = useContext(ProjectionFilterContext);
  const { loading } = useContext(ProjectionAdjustmentContext);
  const carouselContext = useContext(CarouselContext);
  const { canEditAllProjections, canEditAllProjectionsButton } = useContext(
    ProjectionsSuperUserContext
  );

  const { year: yearFitler } = filterValues;
  const {
    clientAccount,
    customerTrigram,
    year: yearParams,
  } = useProjectionSearchParams();

  function generateId() {
    return Math.random().toString(30).substr(2, 12).toString();
  }

  const rightActionButtons = useMemo(() => {
    return canEditAllProjectionsButton;
  }, [canEditAllProjectionsButton]);

  const { values, submitForm, setFieldValue } = formikProps;

  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [loadingSumTable, setLoadingSumTable] = useState(false);
  const [shouldUpdateTable, setShouldUpdateTable] = useState(false);

  useEffect(() => {
    carouselContext.startCarousel({ carouselSize: 3 });
  }, []);

  useEffect(() => {
    setLoadingSumTable(true);
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = setTimeout(() => {
      setShouldUpdateTable((prev) => !prev);
    }, 3000);

    setTimer(newTimer);

    return () => clearTimeout(newTimer);
  }, [values.adjustmentLines]);

  const { headCells } = useHeadCells();

  const { rows: rowsSumArea } = useSumArea({
    formikProps,
  });

  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const enhancedTableFocusRef = useRef<HTMLInputElement | null>(null);
  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    reloadTablePage();
  }, [
    canEditAllProjections,
    values.adjustmentLines?.length,
    values.adjustmentLines?.filter((x) => x.comment !== "").length,
  ]);

  const setIsLoading = useCallback(
    (isLoading: boolean) => enhancedTableRef.current?.setLoading(isLoading),
    []
  );

  const { onPageChange, editingAdjustmentCommentModal } = useTableData({
    formikProps,
    setIsLoading,
    reloadTablePage,
  });

  const { expenseRows, revenueRows, taxRows } = useDreTableData({
    formikProps,
  });

  const { editingModal, firstRow } = useMonthlyCommentTable();

  useEffect(() => {
    setIsLoading(true);

    if (values.adjustmentLines) {
      setIsLoading(false);
    }
  }, [values.adjustmentLines?.length]);

  const yearMonths = useMemo(() => {
    const yearNumber = yearFitler?.getFullYear() ?? yearParams ?? 0;
    return {
      january: new YearMonth(0, yearNumber).toDate(),
      february: new YearMonth(1, yearNumber).toDate(),
      march: new YearMonth(2, yearNumber).toDate(),
      april: new YearMonth(3, yearNumber).toDate(),
      may: new YearMonth(4, yearNumber).toDate(),
      june: new YearMonth(5, yearNumber).toDate(),
      july: new YearMonth(6, yearNumber).toDate(),
      august: new YearMonth(7, yearNumber).toDate(),
      september: new YearMonth(8, yearNumber).toDate(),
      october: new YearMonth(9, yearNumber).toDate(),
      november: new YearMonth(10, yearNumber).toDate(),
      december: new YearMonth(11, yearNumber).toDate(),
    };
  }, [yearFitler]);

  const AddButton = (
    <AddIconButton
      onClick={async () => {
        enhancedTableFocusRef.current?.focus();
        const value = formatNumber(0, { fractionDigits: 2 });
        const newAdjustmentLine: ProjectionAdjustment = {
          id: generateId(),
          accountCode: "",
          clientAccount,
          costCenter: null,
          expenseType: null,
          customerTrigram,
          comment: "",
          january: {
            id: null,
            value,
            yearMonth: yearMonths.january,
          },
          february: {
            id: null,
            value,
            yearMonth: yearMonths.february,
          },
          march: {
            id: null,
            value,
            yearMonth: yearMonths.march,
          },
          april: {
            id: null,
            value,
            yearMonth: yearMonths.april,
          },
          may: {
            id: null,
            value,
            yearMonth: yearMonths.may,
          },
          june: {
            id: null,
            value,
            yearMonth: yearMonths.june,
          },
          july: {
            id: null,
            value,
            yearMonth: yearMonths.july,
          },
          august: {
            id: null,
            value,
            yearMonth: yearMonths.august,
          },
          september: {
            id: null,
            value,
            yearMonth: yearMonths.september,
          },
          october: {
            id: null,
            value,
            yearMonth: yearMonths.october,
          },
          november: {
            id: null,
            value,
            yearMonth: yearMonths.november,
          },
          december: {
            id: null,
            value,
            yearMonth: yearMonths.december,
          },
          transferenceCountry: false,
        };
        if (values.adjustmentLines) {
          setFieldValue("adjustmentLines", [
            newAdjustmentLine,
            ...values.adjustmentLines,
          ]);
        } else {
          setFieldValue("adjustmentLines", [newAdjustmentLine]);
        }
      }}
    />
  );

  const MonthlyCommentTable = useMemo(
    () => (
      <EnhancedTable
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: false,
        }}
        rows={[firstRow]}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
    ),
    [firstRow, t]
  );

  const PhotoTable = useMemo(
    () => (
      <EnhancedTable
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: false,
        }}
        rows={[...revenueRows, ...taxRows, ...expenseRows]}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
        headerSettings={{
          hiddenHeader: true,
        }}
      />
    ),
    [expenseRows.length, revenueRows.length, taxRows.length, firstRow, t]
  );

  const SumAreaTable = useMemo(() => {
    setLoadingSumTable(false);
    return (
      <EnhancedTable
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: false,
        }}
        rows={rowsSumArea}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
        headerSettings={{
          hiddenHeader: true,
        }}
      />
    );
  }, [shouldUpdateTable]);

  if (loading) {
    return (
      <PerfectlyCenteredText>
        <CircularProgress />
      </PerfectlyCenteredText>
    );
  }

  return (
    <StyledMainContainer>
      <StyledButtonArea>
        {AddButton}
        {rightActionButtons}
      </StyledButtonArea>
      <StyledTablesSection>
        <StyledTableHeader>{MonthlyCommentTable}</StyledTableHeader>
        {[...revenueRows, ...taxRows, ...expenseRows].length > 0 && (
          <StyledTable2>{PhotoTable}</StyledTable2>
        )}
        <StyledTable2>
          <div ref={enhancedTableFocusRef} tabIndex={-1} />
          <EnhancedTable
            ref={enhancedTableRef}
            headCells={headCells}
            uiSettings={{
              hasDynamicTableHeight: false,
            }}
            paginationSettings={{
              usesPagination: false,
              externalPagination: {
                loadSinglePageContent: onPageChange,
              },
            }}
            orderDefaults={{
              defaultOrderBy: "none",
            }}
            headerSettings={{
              hiddenHeader: true,
            }}
          />
        </StyledTable2>
        <StyledTable2 style={{ position: "relative" }}>
          {SumAreaTable}
          {loadingSumTable && (
            <StyledCircularProgress>
              <CircularProgress />
            </StyledCircularProgress>
          )}
        </StyledTable2>
      </StyledTablesSection>
      <StyledFooterContent2>
        <Button component={Link} to="/projection">
          {t("general.actions.general.return")}
        </Button>
        <Button onClick={submitForm} variant="contained">
          {t("general.actions.general.save")}
        </Button>
      </StyledFooterContent2>
      {editingModal}
      {editingAdjustmentCommentModal}
    </StyledMainContainer>
  );
};
