import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import {
  PageCardButton,
  PageCardButtonsList,
} from "../../../../shared/common/react/components/navigation/PageCardButton";
import { RootState } from "../../../../store";
import { StyledWelcome } from "./index.styles";
import { PermissionType } from "../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { DRECardComponent } from "../DreQueues";
import { usePermissionChecker } from "../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { DreProjectionCardComponent } from "../DreProjectionCardComponent";

export const Home = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  const { hasPermission: canSubmitDreQueue } = usePermissionChecker({
    restrictions: {
      type: PermissionType.CanSubmitDreQueue,
    },
  });

  const { hasPermission: canSubmitProjectionQueue } = usePermissionChecker({
    restrictions: {
      type: PermissionType.CanSubmitProjectionQueue,
    },
  });

  useEffect(() => {
    setPageTitle(t("home.pages.main.title"));
  }, [t]);

  const welcomeMessage = useMemo(() => {
    const firstName = userData?.userFullName?.trim().split(" ")[0] || null;
    if (!firstName) return t("home.pages.main.welcome");

    return t("home.pages.main.welcomeFirstName", { firstName });
  }, [t, userData?.userFullName]);

  return (
    <>
      <StyledWelcome>{welcomeMessage}</StyledWelcome>
      <PageCardButtonsList>
        <PageCardButton
          title="timesheets.keywords.general.timesheets"
          Icon={CalendarMonthIcon}
          link="/timesheet"
        />
        <PageCardButton
          title="collaboratorProfile.pages.main.title"
          Icon={AccountCircleIcon}
          link="/user-collaborator-profile"
        />
        {canSubmitDreQueue ? <DRECardComponent /> : ""}
        {canSubmitProjectionQueue ? <DreProjectionCardComponent /> : ""}
      </PageCardButtonsList>
    </>
  );
};
