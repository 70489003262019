import { useTranslation } from "react-i18next";
import DownloadIcon from "@mui/icons-material/Download";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useTableData } from "./hooks/useTableData";
import { useFilterForm } from "./hooks/useFilterForm";
import { useEffectAfterRenders } from "../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import {
  StyledButton,
  StyledFooter,
  StyledFormContainer,
  StyledTableContainer,
} from "./shared/styles/index.styles";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { GenerateCalculatedFeeReport } from "../../../../../../services/calculatedFee/calculatedFee.service";
import { LoadingBackdrop } from "../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

export const SummaryTab = () => {
  const { i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const [isLoading, setIsLoading] = useState(false);

  const { formComponent, filters } = useFilterForm();

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, filters],
    rendersBeforeEffect: 0,
  });

  const { headCells, onPageChange, sumList, currencySymbol } = useTableData({
    filters,
  });

  const footer = useMemo(() => {
    return sumList.length > 0 ? (
      <StyledFooter hasScroll={!filters?.idCostCenter}>
        {sumList.map((x) => (
          <span>
            {formatNumber(x, { fractionDigits: 2, prefix: currencySymbol })}
          </span>
        ))}
      </StyledFooter>
    ) : null;
  }, [i18n.language, sumList, filters?.idCostCenter, currencySymbol]);

  const generateCalculatedFeeReport = async () => {
    try {
      setIsLoading(true);
      if (!filters) throw new Error("filters is null");
      if (!filters.idSubsidiary) throw new Error("idSubsidiary is null");
      if (!filters.yearMonth) throw new Error("yearMonth is null");
      await GenerateCalculatedFeeReport(filters);
    } catch (error) {
      console.error({ error });

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledTableContainer>
      <LoadingBackdrop open={isLoading} />
      <StyledFormContainer>
        {formComponent}
        <StyledButton
          disabled={!filters || !filters.yearMonth}
          onClick={generateCalculatedFeeReport}
          startIcon={<DownloadIcon fontSize="small" />}
          variant="contained"
        >
          Fees
        </StyledButton>
      </StyledFormContainer>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 192px)`,
          minMessageHeight: window.innerHeight - 225,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
        footerSettings={{
          content: footer,
        }}
      />
    </StyledTableContainer>
  );
};
