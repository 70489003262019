import { useContext } from "react";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { AllocationsOfUserDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { AllocationsSuperUserContext } from "../../shared/react/contexts/AllocationsSuperUserContext";
import { useMonthlyAllocationClosing } from "../../../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";

interface OwnProps {
  allocationsOfUser: AllocationsOfUserDTO;
  DeleteButtonContainer: (props: AllocationsOfUserDTO) => JSX.Element | null;
}

export const DeleteButtonSuperUserContainer = ({
  allocationsOfUser,
  DeleteButtonContainer,
}: OwnProps) => {
  const { canEditAllAllocations } = useContext(AllocationsSuperUserContext);

  const { checkIfMonthlyAllocationIsClosed } = useMonthlyAllocationClosing();

  if (canEditAllAllocations)
    return <DeleteButtonContainer {...allocationsOfUser} />;

  const thereAreAllocationsBeforeCurrentMonth =
    allocationsOfUser.allocationYearMonths.some((x) =>
      checkIfMonthlyAllocationIsClosed(x.yearMonth)
    );

  if (thereAreAllocationsBeforeCurrentMonth)
    return <DeleteIconButton disabled />;

  return <DeleteButtonContainer {...allocationsOfUser} />;
};
