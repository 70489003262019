import * as yup from "yup";

export enum TabCode {
  Disciplines = "disciplines",
  Categories = "categories",
  Skills = "skills",
  Certifications = "certifications",
}

export const schemaTabCode = yup
  .mixed<TabCode>()
  .oneOf(Object.values(TabCode) as TabCode[]);
