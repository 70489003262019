import { css, styled, TableCell, TableCellProps } from "@mui/material";

interface OwnProps extends TableCellProps {
  headColumn?: boolean;
  stickyHeader?: boolean;
}

const PureInternal = ({ headColumn, stickyHeader, ...rest }: OwnProps) => (
  <TableCell {...rest} />
);

export const StyledTableCell = styled(PureInternal)`
  padding: 0;
  height: 100%;

  ${({ headColumn, stickyHeader, theme }) =>
    headColumn &&
    stickyHeader &&
    css`
      position: sticky;
      left: 0;
      z-index: 3;
      background-color: ${theme.palette.background.default};
      border-right: solid ${theme.palette.divider} 4px;
    `}
`;
