import * as yup from "yup";
import { FeeCreateDTO } from "../../../shared/specific/DTOs/fee/FeeCreateDTO";
import { FeeDTO, schemaFeeDTO } from "../../../shared/specific/DTOs/fee/FeeDTO";
import { FeeUpdateDTO } from "../../../shared/specific/DTOs/fee/FeeUpdateDTO";
import {
  PaginatedFeeDTO,
  schemaPaginatedFeeDTO,
} from "../../../shared/specific/DTOs/fee/PaginatedFeeDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";
import { FeeFilterDTO } from "../../../shared/specific/DTOs/fee/FeeFilterDTO";

export const getFees = async ({
  pagination,
  filters,
}: {
  pagination?: PaginationDTO;
  filters: FeeFilterDTO;
}): Promise<PaginatedFeeDTO> => {
  const { data: fees } = await riseApi.get("Fee", {
    params: { ...pagination, ...filters },
  });

  return schemaPaginatedFeeDTO.validateSync(fees);
};

export const getFeesDropdown = async (): Promise<FeeDTO[]> => {
  const { data: fees } = await riseApi.get("Fee/Dropdown");
  return yup.array(schemaFeeDTO).defined().validateSync(fees);
};

export const getFeeyId = async (id: number): Promise<FeeDTO | null> => {
  const { data: fee } = await riseApi.get(`/Fee/${id}`);
  return schemaFeeDTO.nullable().validateSync(fee);
};

export const createFee = async (fee: FeeCreateDTO) => {
  await riseApi.post("Fee", fee);
};

export const updateFee = async (fee: FeeUpdateDTO) => {
  await riseApi.put("Fee", fee);
};

export const deleteFee = async (id: number, version: string) => {
  await riseApi.delete(`Fee/${id}/${version}`);
};
