import {
  Country,
  schemaCountry,
} from "../../../../../../../../shared/common/enums/locale/Country.enum";
import { checkIsValidType } from "../../../../../../../../shared/common/helpers/data/validation/schema.helpers";
import { cachedStates } from "../../objects/data/cache/cachedFunctions.objects";
import { GetStateFormValues } from "../../types/helpers/data/fetchers.types";

export const getStateFormValues: GetStateFormValues = async ({
  country,
  customerState,
}) => {
  if (
    !country ||
    !customerState ||
    !checkIsValidType<Country>(schemaCountry, country.code)
  )
    return {
      stateAutocomplete: null,
      stateTextField: customerState ?? "",
    };

  const states = await cachedStates.run({ value: country.code });
  const stateAutocomplete =
    states.find((x) => x.abbreviation === customerState) ?? null;

  return {
    stateAutocomplete,
    stateTextField: "",
  };
};
