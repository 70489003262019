import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const buSegment = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const buLableCpt: CptProps = {
    text: "BU/Segment",
    options: {
      align: pres.AlignH.left,
      y: "13%",
      x: "1%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const name = `${projectData.un.name} / ${projectData.segment}`;

  const buNameCpt: CptProps = {
    text: name,
    options: {
      align: pres.AlignH.left,
      y: "15.5%",
      x: "1%",
      w: "34%",
      color: "363636",
      fontSize: getFontSizeByString(name),
    },
  };

  slide.addText(buLableCpt.text, buLableCpt.options);
  slide.addText(buNameCpt.text, buNameCpt.options);
};
