import * as yup from "yup";
import { UserAccessCreateDeletePermissionDTO } from "../../../shared/specific/DTOs/userAccessPermission/UserAccessCreateDeletePermissionDTO";

import {
  schemaUserAccessPermissionDTO,
  UserAccessPermissionDTO,
} from "../../../shared/specific/DTOs/userAccessPermission/UserAccessPermissionDTO";
import { PermissionType } from "../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { riseApi } from "./connection";

export const getListPermissionsByIdUser = async (
  id: number
): Promise<UserAccessPermissionDTO[]> => {
  const { data: userListPermission } = await riseApi.get(
    `/UserPermission/ListPermissionsBy/${id}`
  );

  return yup
    .array(schemaUserAccessPermissionDTO)
    .transform((permissionsArray) => {
      if (!Array.isArray(permissionsArray)) return permissionsArray;
      return permissionsArray.filter(
        (x) =>
          !x?.permissionType || PermissionType.hasOwnProperty(x.permissionType)
      );
    })
    .defined()
    .validateSync(userListPermission);
};

export const createDeleteUserAccessPermission = async (
  permissionsList: UserAccessCreateDeletePermissionDTO
) => {
  await riseApi.post(
    "/UserPermission/CreateDeletePermissions",
    permissionsList
  );
};
