import * as yup from "yup";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateAllocationYearMonthDTO,
  schemaCorporateAllocationYearMonthDTO,
} from "./CorporateAllocationYearMonthDTO";

export interface CorporateAllocationsOfUserDTO {
  allocationGroup: number;
  allocationType: EmployeeAllocationType;
  corporateSegment: string | null;
  idCollaborator: number;
  collaboratorName: string;
  idCostCenter: number | null;
  costCenterCode: string | null;
  allocationYearMonths: CorporateAllocationYearMonthDTO[];
}

export const schemaCorporateAllocationsOfUserDTO = yup.object({
  allocationGroup: yup.number().integer().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  corporateSegment: yup.string().nullable().defined(),
  idCollaborator: yup.number().integer().defined(),
  collaboratorName: yup.string().defined(),
  idCostCenter: yup.number().integer().nullable().defined(),
  costCenterCode: yup.string().nullable().defined(),
  allocationYearMonths: yup
    .array(schemaCorporateAllocationYearMonthDTO)
    .defined(),
});
