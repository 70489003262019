import * as yup from "yup";

export interface AccessGroupsDTO {
  id: number;
  name: string;
}

export const schemaAccessGroupsDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
});
