import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { CostCenterDTO, schemaCostCenterDTO } from "./CostCenterDTO";

export type PaginatedCostCenterDTO = PaginationResponse<CostCenterDTO>;

export const schemaPaginatedCostCenterDTO =
  getSchemaPaginationResponse<CostCenterDTO>(schemaCostCenterDTO);
