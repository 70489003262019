import { ReactNode, useMemo, useState } from "react";
import { UserGroupContextValues } from "./index.types";
import { UserGroupContext } from "./UserGroupContext";

interface OwnProps {
  children: ReactNode;
}

export const UserGroupContextProvider = ({ children }: OwnProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const userGroupContext: UserGroupContextValues = useMemo(
    () => ({
      isLoading,
      setIsLoading,
    }),
    [isLoading]
  );

  return (
    <UserGroupContext.Provider value={userGroupContext}>
      {children}
    </UserGroupContext.Provider>
  );
};
