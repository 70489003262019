import { JobRateListByFiltersDTO } from "../../../shared/specific/DTOs/jobRate/filters/JobRateListByFiltersDTO";
import { JobRateCreateDTO } from "../../../shared/specific/DTOs/jobRate/JobRateCreateDTO";
import {
  JobRateDTO,
  schemaJobRateDTO,
} from "../../../shared/specific/DTOs/jobRate/JobRateDTO";
import { JobRateUpdateDTO } from "../../../shared/specific/DTOs/jobRate/JobRateUpdateDTO";
import {
  PaginatedJobRateDTO,
  schemaPaginatedJobRateDTO,
} from "../../../shared/specific/DTOs/jobRate/PaginatedJobRateDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getJobRates = async ({
  filters,
  pagination,
}: {
  filters?: JobRateListByFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedJobRateDTO> => {
  const { data: jobRates } = await riseApi.get("/JobRate", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedJobRateDTO.validateSync(jobRates);
};

export const getJobRateById = async (
  id: number
): Promise<JobRateDTO | null> => {
  const { data: jobRate } = await riseApi.get(`JobRate/${id}`);
  return schemaJobRateDTO.nullable().validateSync(jobRate);
};

export const deleteJobRate = async (id: number, version: string) => {
  await riseApi.delete(`JobRate/${id}/${version}`);
};

export const createJobRate = async (jobRate: JobRateCreateDTO) => {
  await riseApi.post("JobRate", jobRate);
};

export const updateJobRate = async (jobRate: JobRateUpdateDTO) => {
  await riseApi.put("JobRate", jobRate);
};
