import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createBrlParity } from "../../../../../../services/brlParity/BrlParity.service";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { BrlParityCreateDTO } from "../../../../../../shared/specific/DTOs/brlParity/BrlParityCreateDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonBrlParityForm } from "../shared/react/components/CommonBrlParityForm";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <>{t("brlParity.modal.data.create.title")}</>;
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonBrlParityForm {...rest} mode="creation" />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const createdBrlParity: BrlParityCreateDTO = {
            yearMonth: YearMonth.createFromDate(
              formValues.yearMonth ?? new Date()
            ),
            currency: formValues.currency ?? "",
            plannedValue:
              parseLocaleNumber(formValues.plannedValue) ?? undefined,
            performedValue: formValues?.performedValue
              ? parseLocaleNumber(formValues.performedValue)
              : undefined,
            lastBusinessDayValue: formValues?.lastBusinessDayValue
              ? parseLocaleNumber(formValues.lastBusinessDayValue)
              : undefined,
          };

          await createBrlParity(createdBrlParity);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "brlParity.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
