import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      { label: string },
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const IssAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        label: formatNumber(0, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(2, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(2.5, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(2.9, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(3, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(3.5, { fractionDigits: 2 }),
      },
      {
        label: formatNumber(5, { fractionDigits: 2 }),
      },
    ];
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) =>
          option.label === value.label,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={textfieldProps}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
