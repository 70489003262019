import { FormControlLabel } from "@mui/material";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { ContractRolesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/contractRoles/ContractRolesAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { BaseCollaboratorReportScopeTypeFormik } from "../BaseCollaboratorReportScopeType/BaseCollaboratorReportScopeTypeAutocompleteFormik";
import { StyledFormContainer, StyledViewContent } from "../index.styles";
import { CheckboxFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { GenerateReportButton } from "../../shared/components/GenerateReportButton";
import { LoadingBackdrop } from "../../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { FormValuesBaseCollaborators } from "../types/form.types";
import { BaseCollaboratorReportScopeTypeEnum } from "../../../../../../../../../../shared/specific/enums/BaseCollaboratorReportScopeType/ReportScopeType.enum";

type OwnProps = FormikProps<FormValuesBaseCollaborators>;
export const BaseCollaboratorForm = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue,
}: OwnProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (
      BaseCollaboratorReportScopeTypeEnum.AllCollaborators ===
        values.reportScopeType.id &&
      values.startYearMonth
    )
      setFieldValue("endYearMonth", values.startYearMonth);
  }, [values.reportScopeType, values.startYearMonth]);
  return (
    <StyledViewContent>
      <StyledFormContainer>
        <BaseCollaboratorReportScopeTypeFormik
          name="reportScopeType"
          autocompleteProps={{
            disableClearable: true,
          }}
        />
        <UsersSearchAutocompleteFormik
          name="userCollaborators"
          autocompleteProps={{
            multiple: true,
          }}
          textfieldProps={{
            label: `${t("usersCollaborators.keywords.general.collaborators")}`,
          }}
          filtersMemo={{ isActive: true }}
        />
        <ContractRolesAutocompleteFormik name="contractRole" />
        <CostCentersSearchAutocompleteFormik name="costCenter" />
        <ProjectSearchAutocompleteFormik name="project" />
        <UsersSearchAutocompleteFormik
          name="userManager"
          textfieldProps={{
            label: `${t("projects.keywords.fields.manager")}`,
          }}
          autocompleteProps={{ multiple: false, value: values.userManager }}
          filtersMemo={{ isActive: true }}
        />
        <DivisionsAutocompleteFormik name="division" />
        <YearMonthDatePickerFormik
          name="startYearMonth"
          datePickerProps={{
            label:
              values.reportScopeType.id ===
              BaseCollaboratorReportScopeTypeEnum.AllAllocations
                ? t("reports.pages.form.startPeriod")
                : t("reports.pages.form.period"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
          }}
          textFieldProps={{
            required: true,
          }}
        />
        {values.reportScopeType &&
          values.reportScopeType.id ===
            BaseCollaboratorReportScopeTypeEnum.AllAllocations && (
            <YearMonthDatePickerFormik
              name="endYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.endPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
          )}
        <FormControlLabel
          label={t("general.keywords.fields.activeCollaboratorsOnly")}
          control={<CheckboxFormik name="onlyActiveUsers" />}
        />
      </StyledFormContainer>
      <GenerateReportButton onClick={submitForm} isSubmitting={isSubmitting} />
      <LoadingBackdrop open={isSubmitting} />
    </StyledViewContent>
  );
};
