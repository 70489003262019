import { useFormikContext } from "formik";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { notifyError } from "../../../../../../../../../../services/applicationState/toast.service";
import { getCollaboratorHistory } from "../../../../../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { getCorporateAllocationsPerUser } from "../../../../../../../../../../services/corporateAllocations/corporateAllocations.service";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { call } from "../../../../../../../../../../shared/common/helpers/general.helpers";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { EmployeeAllocationType } from "../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormValues } from "../../../shared/types/data/form/values.types";

export const useUserCollaboratorValidator = () => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [isValidating, setIsValidating] = useState(false);

  const existingIdsRef = useRef<number[]>([]);

  const handleGetNewUser = () => {
    if (values.userCollaborators.length === existingIdsRef.current.length)
      return null;

    if (values.userCollaborators.length < existingIdsRef.current.length) {
      existingIdsRef.current = existingIdsRef.current.filter((id) =>
        values.userCollaborators.some((user) => user.id === id)
      );
      return null;
    }

    const user = values.userCollaborators.find(
      (user) => !existingIdsRef.current.includes(user.id)
    );

    if (user !== undefined) {
      existingIdsRef.current.push(user.id);
    }

    return user ?? null;
  };

  useEffectAfterRenders({
    effect: () => {
      call(async () => {
        if (
          !values.allocationType?.label ||
          !values?.periodStart ||
          !values?.periodEnd ||
          values.userCollaborators.length === 0
        ) {
          existingIdsRef.current = [];
          return;
        }

        const newUser = handleGetNewUser();
        if (!newUser) return;

        setIsValidating(true);
        try {
          values.userCollaborators.forEach(async (collaborator) => {
            if (
              values.allocationType?.id &&
              [
                EmployeeAllocationType.Vacation,
                EmployeeAllocationType.Leave,
                EmployeeAllocationType.Allowance,
                EmployeeAllocationType.Training,
              ].includes(values.allocationType?.id)
            )
              return;

            const collaboratorHistory = await getCollaboratorHistory(
              collaborator.id
            );

            if (collaboratorHistory.length === 0) return;

            const lastHistory = collaboratorHistory.sort((a, b) => {
              if (a.startYearMonth && b.startYearMonth) {
                return a.startYearMonth > b.startYearMonth ? 1 : -1;
              }
              return 1;
            })[collaboratorHistory.length - 1];

            if (
              lastHistory.costCenter.subsidiary.id !==
              values.costCenter?.subsidiary.id
            ) {
              setFieldValue(
                "userCollaborators",
                values.userCollaborators.filter((x) => x.id !== newUser.id)
              );
              return notifyError(
                t(
                  "corporateAllocations.errors.data.create.collaboratorDiferentSubsidiary",
                  { collaboratorName: newUser.name }
                )
              );
            }
          });

          const existingAllocations = await getCorporateAllocationsPerUser({
            filters: {
              idCostCenter: values.costCenter?.id,
              idCorporateSegment: values.corporateSegment?.id,
              idsUserCollaborators: [newUser.id],
              allocationType: values.allocationType.id,
              earliestYearMonth: YearMonth.createFromDate(values.periodStart),
              latestYearMonth: YearMonth.createFromDate(values.periodEnd),
            },
          });

          if (existingAllocations.length === 0) return;

          setFieldValue(
            "userCollaborators",
            values.userCollaborators.filter((x) => x.id !== newUser.id)
          );
          notifyError(
            t(
              "corporateAllocations.errors.data.create.collaboratorAlreadyHasAllocationsPeriod",
              { collaboratorName: newUser.name }
            )
          );
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: t(
              "corporateAllocations.errors.data.create.failedToValidateCollaborator"
            ),
          });
          setFieldValue(
            "userCollaborators",
            values.userCollaborators.filter((x) => x.id !== newUser.id)
          );
        } finally {
          setIsValidating(false);
        }
      });
    },
    deps: [values.userCollaborators],
    rendersBeforeEffect: 1,
  });

  return { isValidating };
};
