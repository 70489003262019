import { Box, TextFieldProps } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDisciplinesDropdown } from "../../../../../../../../../services/disciplines/disciplines.service";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { DisciplineDropdownDTO } from "../../../../../../../DTOs/discipline/DisciplineDropdownDTO";
import { DisciplineFiltersDTO } from "../../../../../../../DTOs/discipline/DisciplineFiltersDTO";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

export interface DisciplinesSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    DisciplineDropdownDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: DisciplineFiltersDTO;
  filtersMemo?: DisciplineFiltersDTO;
}

export const DisciplinesMultipleAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: DisciplinesSearchAutocompleteFormikProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getDisciplinesFinal = useCallback(async () => {
    const paginatedSkillCategory = await getDisciplinesDropdown({
      filters: { name: "", ...finalFilters },
    });
    return paginatedSkillCategory;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "disciplines.errors.data.fetch.failedToFetchDisciplines"
      )}
      fetchOptions={getDisciplinesFinal}
      textfieldProps={{
        label: t("disciplines.keywords.general.discipline"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.name}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
