import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { FormValuesProjectMVs } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { generateProjectMVsReport } from "../../../../../../../../../services/reports/reports.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { CheckboxFormik } from "../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";

export const ProjectMVs = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesProjectMVs>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesProjectMVs = {
      division: null,
      project: null,
      usersManagers: [],
      customerCodes: [],
      onlyOngoingProjects: true,
      costCenter: null,
    };

    const validationSchema = yup.object({
      division: yup
        .object()
        .nullable()
        .test({
          name: "At least one required check.",
          message: t(
            "reports.errors.validations.atLeastOneAsteriskMustBeProvided"
          ),
          test(value) {
            /* eslint-disable react/no-this-in-sfc */
            return (
              !!value ||
              !!this.parent.project ||
              this.parent.usersManagers?.length > 0 ||
              this.parent.customerCodes?.length > 0 ||
              !!this.parent.costCenter
            );
            /* eslint-enable react/no-this-in-sfc */
          },
        }),
      project: yup.object().nullable(),
      onlyOngoingProjects: yup.boolean().required(),
      usersManagers: yup.array(yup.object()),
      customerCodes: yup.array(yup.string().defined()),
      costCenter: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesProjectMVs,
    { setSubmitting }: FormikHelpers<FormValuesProjectMVs>
  ) => {
    try {
      await generateProjectMVsReport({
        idDivision: values?.division ? values.division.id : undefined,
        idProject: values?.project ? values.project.id : undefined,
        onlyOngoingProjects: values.onlyOngoingProjects,
        idsUsersManagers: values.usersManagers.map((x) => x.id),
        customerCodes: values.customerCodes,
        idCostCenter: values?.costCenter ? values.costCenter.id : undefined,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <DivisionsAutocompleteFormik
              name="division"
              textfieldProps={{
                label: `${t("divisions.keywords.general.un")} (*)`,
              }}
            />
            <ProjectSearchAutocompleteFormik
              name="project"
              textfieldProps={{
                label: `${t("projects.keywords.general.project")} (*)`,
              }}
            />
            <UsersSearchAutocompleteFormik
              name="usersManagers"
              textfieldProps={{
                label: `${t("projects.keywords.fields.manager")} (*)`,
              }}
              autocompleteProps={{ multiple: true }}
            />
            <CustomerCodesSearchAutocompleteFormik
              name="customerCodes"
              textfieldProps={{
                label: `${t(
                  "customers.keywords.fields.projectPrefixCustomerGroup"
                )} (*)`,
              }}
              autocompleteProps={{ multiple: true }}
            />
            <CostCentersSearchAutocompleteFormik
              name="costCenter"
              textfieldProps={{
                label: `${t("costCenters.keywords.general.costCenter")} (*)`,
              }}
            />
            <FormControlLabel
              label={t("reports.keywords.fields.onlyOngoingProjects")}
              control={<CheckboxFormik name="onlyOngoingProjects" />}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
          <LoadingBackdrop open={isSubmitting} />
        </StyledViewContent>
      )}
    </Formik>
  );
};
