import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { parseLocaleNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { AddIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { useFormikConfig } from "./shared/hooks/form/validation/useFormikConfig";
import { AllocationCreationFormValues } from "./shared/types/data/form/values.types";
import { UserAllocationsModifyDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/UserAllocationsModifyDTO";
import { AllocationYearMonthCreateDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationYearMonthCreateDTO";
import {
  createJobRoleAllocations,
  modifyUserAllocations,
} from "../../../../../../../../../services/allocations/allocations.service";
import { AllocationCreationForm } from "./AllocationCreationForm";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { JobRoleAllocationsCreateDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/JobRoleAllocationsCreateDTO";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { checkIsDateAfterProject } from "../../../../../shared/helpers/DTOs/project/general.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  costCenterPep: CostCenterPepOnlyDTO | null;
}

export const useCreationForm = ({
  reloadTablePage,
  costCenterPep,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<AllocationCreationFormValues, CostCenterPepOnlyDTO>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("allocations.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <AddIconButton
              onClick={onClick}
              tooltipText={t("general.actions.general.add")}
            />
          );
        },
        onClickContentButton: ({ setInternalFormData }) =>
          setInternalFormData(costCenterPep),
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <AllocationCreationForm {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: costCenterPep,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            if (formValues.userCurrentJobRole?.userColaborator.id)
              await modifyUserAllocations(
                convertTo<UserAllocationsModifyDTO>({
                  allocationType: formValues.allocationType.id,
                  idCostCenterPep: costCenterPep?.id ?? 0,
                  idUserCollaborator:
                    formValues.userCurrentJobRole.userColaborator.id,
                  allocationYearMonths: formValues.allocationYearMonths.map(
                    (x): AllocationYearMonthCreateDTO => ({
                      yearMonth: x.yearMonth,
                      week1Percentage: parseLocaleNumber(x.week1Percentage),
                      week2Percentage: parseLocaleNumber(x.week2Percentage),
                      week3Percentage: parseLocaleNumber(x.week3Percentage),
                      week4Percentage: parseLocaleNumber(x.week4Percentage),
                      week5Percentage: x.week5Percentage
                        ? parseLocaleNumber(x.week5Percentage)
                        : undefined,
                    })
                  ),
                })
              );
            else
              await createJobRoleAllocations(
                convertTo<JobRoleAllocationsCreateDTO>({
                  allocationType: formValues.allocationType.id,
                  idCostCenterPep: costCenterPep?.id ?? 0,
                  idJobRole: formValues.jobRole?.id ?? 0,
                  allocationYearMonths: formValues.allocationYearMonths.map(
                    (x): AllocationYearMonthCreateDTO => ({
                      yearMonth: x.yearMonth,
                      week1Percentage: parseLocaleNumber(x.week1Percentage),
                      week2Percentage: parseLocaleNumber(x.week2Percentage),
                      week3Percentage: parseLocaleNumber(x.week3Percentage),
                      week4Percentage: parseLocaleNumber(x.week4Percentage),
                      week5Percentage: x.week5Percentage
                        ? parseLocaleNumber(x.week5Percentage)
                        : undefined,
                    })
                  ),
                })
              );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();

            if (
              checkIsDateAfterProject(
                project.finalScheduledEndDate,
                formValues.periodEnd
              ) ||
              project.isValid
            )
              reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "activities.errors.data.create.failedToCreateActivity",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
