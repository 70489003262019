import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  getExpense,
  modifyExpense,
} from "../../../../../../../../../../../services/expenses/expenses.service";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { Language } from "../../../../../../../../../../../shared/common/enums/locale/Language.enum";
import { parseLocaleNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { ExpenseDTO } from "../../../../../../../../../../../shared/specific/DTOs/expenses/ExpenseDTO";
import { ExpenseModifyDTO } from "../../../../../../../../../../../shared/specific/DTOs/expenses/ExpenseModifyDTO";
import { ExpenseType } from "../../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonExpenseForm } from "../shared/react/CommonExpenseForm";
import { DescriptionTypes, FormValues } from "../shared/types/form.types";
import { DescriptionTranslationCode } from "../shared/utils/DescriptionTranslationCode";
import { useFormikConfig } from "./useFormikConfig";
import { formatNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  reloadMainTablePage: () => void;
  expenseType: ExpenseType;
}

export const useEditingForm = ({
  reloadTablePage,
  reloadMainTablePage,
  expenseType,
}: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig({ expenseType });

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, ExpenseDTO, ExpenseDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: expense }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: expense?.description ?? "-",
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: expense,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const expenseData = await getExpense(expense.id);

            if (!expenseData)
              return setModalError(
                t("expenses.errors.data.general.expensesSummaryDoesNotExist")
              );

            setInternalFormData(expenseData);
            if (expenseData.expenseType === ExpenseType.Additional) {
              const getDescription = (description: string | null) => {
                if (!description) {
                  return {
                    id: 0,
                    label: "",
                  };
                }
                const descriptionTypeList = Object.values(
                  DescriptionTypes
                ) as Array<DescriptionTypes>;
                const descriptionFound = descriptionTypeList.find(
                  (idDescription) => {
                    const ptBR = t(DescriptionTranslationCode[idDescription], {
                      lng: Language.ptBR,
                    });
                    const enUS = t(DescriptionTranslationCode[idDescription], {
                      lng: Language.enUS,
                    });

                    return [ptBR, enUS].includes(description);
                  }
                );

                if (descriptionFound) {
                  return {
                    id: descriptionFound,
                    label: t(DescriptionTranslationCode[descriptionFound]),
                  };
                }
                return {
                  id: 0,
                  label: "",
                };
              };
              setFormValues({
                amount: expenseData.amount
                  ? formatNumber(expenseData.amount, { fractionDigits: 2 })
                  : "",
                additionalDescription: getDescription(expenseData.description),
                description: "",
                observation: expenseData.observation || "",
              });
            } else {
              setFormValues({
                amount: expenseData.amount
                  ? formatNumber(expenseData.amount, { fractionDigits: 2 })
                  : "",
                additionalDescription: {
                  id: 0 as DescriptionTypes,
                  label: "",
                },
                description: expenseData.description || "",
                observation: expenseData.observation || "",
              });
            }
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "expenses.errors.data.fetch.failedToFetchExpense",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => {
          if (internalFormData) {
            return (
              <CommonExpenseForm
                {...rest}
                costCenterPep={internalFormData?.costCenterPep}
                expenseType={internalFormData?.expenseType}
              />
            );
          }

          return <div> </div>;
        },
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedExpense: ExpenseModifyDTO = {
              id: internalFormData?.id || 0,
              amount: parseLocaleNumber(formValues.amount) || 0,
              description:
                internalFormData?.expenseType === ExpenseType.Additional
                  ? formValues.additionalDescription.label
                  : formValues.description,
              observation: formValues?.observation || undefined,
              expenseType: internalFormData?.expenseType || 0,
              idCostCenterPep: internalFormData?.costCenterPep.id || 0,
              yearMonth:
                internalFormData?.yearMonth || YearMonth.createFromNow(),
              version: internalFormData?.version || "",
            };

            await modifyExpense(updatedExpense);
            reloadTablePage();
            reloadMainTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "expenses.errors.data.modifySingle.failedToModifyExpens",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
