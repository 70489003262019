import { Box, TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { getInvoicesDropdown } from "../../../../../../../../../../services/invoices/invoices.service";
import { InvoiceOnlyDTO } from "../../../../../../../../DTOs/invoice/InvoiceOnlyDTO";
import { AutocompleteInfoBubble } from "../../../../../general/accessories/AutocompleteInfoBubble";
import { InvoiceBackendFiltersDTO } from "../../../../../../../../DTOs/invoice/filters/InvoiceBackendFiltersDTO";
import {
  MilestoneInternalComponent,
  MilestoneInternalComponentHeader,
  MilestoneLine,
  StyledContainer,
  StyledFindProject,
} from "./styles";
import { formatCurrency } from "../../../../../../../../../common/helpers/data/numbers/localization/formatters.helpers";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../../../maps/invoices/invoicesStatusTypeToTranslationCode";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  isDisabled: boolean;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    InvoiceOnlyDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  filters?: InvoiceBackendFiltersDTO;
}

export const InvoicesByCustomerCodeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  isDisabled,
  textfieldProps,
  autocompleteProps,
  filters,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const getInvoicesFinal = useCallback(async () => {
    const paginatedCustomers = await getInvoicesDropdown({
      filters,
    });

    return paginatedCustomers;
  }, [filters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("invoice.errors.data.fetch.failedToFetchInvoice")}
      fetchOptionsMemo={getInvoicesFinal}
      textfieldProps={{
        label: t("invoice.keywords.fields.invoicesMilestones"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.costCenterPep.project.name,
        renderTags: (value, getTagProps) => {
          return (
            <StyledContainer>
              <MilestoneInternalComponentHeader>
                <span>{t("invoice.keywords.fields.milestone")}</span>
                <span>{t("projects.keywords.general.project")}</span>
                <span>{t("invoice.keywords.fields.plannedDate")}</span>
                <span>{t("general.keywords.fields.value")}</span>
                <span>{t("general.keywords.fields.status")}</span>
              </MilestoneInternalComponentHeader>
              {value.map((x, i) => {
                const date = x.plannedBillingDate
                  ? x.plannedBillingDate
                      ?.toDate()
                      .toLocaleDateString(i18n.language)
                  : "-";
                return (
                  <MilestoneLine
                    {...getTagProps({ index: i })}
                    style={{ width: "100%" }}
                    key={i}
                    label={
                      <MilestoneInternalComponent>
                        <span>{x.milestone}</span>
                        <span>{x.costCenterPep.project.name}</span>
                        <span>{date}</span>
                        <span>
                          {formatCurrency({
                            currencySymbol: x.costCenterPep.currencySymbol,
                            value: x.billingAmount,
                          })}
                        </span>
                        <span>
                          {t(invoicesStatusTypeToTranslationCode[x.status])}
                        </span>
                      </MilestoneInternalComponent>
                    }
                  />
                );
              })}
              <MilestoneInternalComponentHeader>
                <span>
                  Total:{" "}
                  {formatCurrency({
                    currencySymbol:
                      value.length > 0
                        ? value[0].costCenterPep.currencySymbol
                        : "-",
                    value: value
                      .map((x) => x.billingAmount)
                      .reduce((a, b) => a + b, 0),
                  })}
                </span>
              </MilestoneInternalComponentHeader>
              {!isDisabled && (
                <StyledFindProject>
                  {t("invoice.keywords.fields.serchByProjectName")}
                </StyledFindProject>
              )}
            </StyledContainer>
          );
        },
        renderOption: (props, option) => {
          const date = option.plannedBillingDate
            ? option.plannedBillingDate
                ?.toDate()
                .toLocaleDateString(i18n.language)
            : "-";
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <span>{option.costCenterPep.project.name}</span>
              <span>{option.milestone}</span>
              <AutocompleteInfoBubble>{`${t(
                "invoice.keywords.fields.plannedDate"
              )}: ${date}`}</AutocompleteInfoBubble>
            </Box>
          );
        },
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language, filters]}
    />
  );
};
