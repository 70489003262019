import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import {
  createProject,
  getPipedriveDealById,
} from "../../../../../services/projects/projects.service";
import { parseLocaleNumber } from "../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { convertTo } from "../../../../../shared/common/helpers/types/converters.helpers";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  CommonProjectFormRef,
  OnFormSubmitType,
  CommonProjectForm,
} from "../../Projects/shared/react/components/content/CommonProjectForm";
import { contractTypeToTranslationCodeMap } from "../../../../../shared/specific/maps/projects/contractType.maps";
import { ProjectCreateDTO } from "../../../../../shared/specific/DTOs/project/ProjectCreateDTO";
import { formatNumber } from "../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

type OwnParams = {
  id: string;
};

export const ProjectsCreationByDealPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const pipedriveDeal = await getPipedriveDealById(id);
        if (!pipedriveDeal) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }

        formRef.current?.setFormValues({
          name: pipedriveDeal.idProject,
          code: pipedriveDeal.idProject.substring(0, 18),
          userManager: pipedriveDeal.userManager,
          userCoordinator: null,
          division: pipedriveDeal.division,
          productService: pipedriveDeal.productService,
          customer: pipedriveDeal.customer,
          description: "",
          subsidiary: pipedriveDeal.subsidiary,
          contractType:
            pipedriveDeal.contractType || pipedriveDeal.contractType === 0
              ? {
                  id: pipedriveDeal.contractType,
                  label: t(
                    contractTypeToTranslationCodeMap[pipedriveDeal.contractType]
                  ),
                }
              : null,
          typeOfEvidence: null,
          frame: null,
          daysForPayment: String(pipedriveDeal.daysForPayment),
          billingAmount: pipedriveDeal.billingAmount
            ? formatNumber(pipedriveDeal.billingAmount, { fractionDigits: 2 })
            : "",
          mv: pipedriveDeal.mv
            ? formatNumber(pipedriveDeal.mv, { fractionDigits: 2 })
            : "",
          customerNameFromDeal: pipedriveDeal.customerName,
          userManagerNameFromDeal: pipedriveDeal.userManagerName,
          productServiceFromDeal: pipedriveDeal.productServiceName,
          divisionFromDeal: pipedriveDeal.unDescription,
          segment: pipedriveDeal.segment,
          approvedCredit: {
            id: pipedriveDeal.approvedCredit ?? false,
            label: pipedriveDeal.approvedCredit
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
          clientAccount: pipedriveDeal.customerName ?? "-",
          isInvestmentProject: {
            id: false,
            label: t("general.keywords.general.no"),
          },
        });
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, []);

  const onSubmit: OnFormSubmitType = async (
    values,
    { setSubmitting },
    { redirectionLinkOnSuccess }
  ) => {
    if (!values.contractType)
      throw new Error("'contractType' cannot be null on submit.");

    try {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }
      const pipedriveDeal = await getPipedriveDealById(id);
      if (!pipedriveDeal) {
        return formRef.current?.setError(
          "projects.errors.data.general.projectDoesNotExist"
        );
      }
      const projectOnly = await createProject(
        convertTo<ProjectCreateDTO>({
          idCustomer: values.customer?.id ?? 0,
          idUserManager: values.userManager?.id ?? 0,
          idUserCoordinator: values.userCoordinator?.id,
          idSubsidiary: values.subsidiary?.id ?? 0,
          idDivision: values.division?.id ?? 0,
          idProductService: values.productService?.id ?? 0,
          idFrame: values.frame?.id,
          code: values.code,
          name: values.name,
          description: values.description,
          contractType: values.contractType.id,
          daysForPayment: parseLocaleNumber(values.daysForPayment),
          billingAmount: parseLocaleNumber(values.billingAmount),
          IdPipedriveDeal: pipedriveDeal.id,
          idSegment: values.segment?.id ?? 0,
          approvedCredit: values.approvedCredit?.id ?? false,
          clientAccount: values.clientAccount ?? "-",
          isInvestmentProject: values.isInvestmentProject?.id ?? false,
        })
      );

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
      const valueReturnTo = encodeURIComponent(
        `/projects/${projectOnly.id}/edit`
      );
      navigate(`/projects/${projectOnly.id}/peps?return-to=${valueReturnTo}`);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projects.errors.data.update.failedToUpdateProject",
      });
      setSubmitting(false);
    }
  };

  return (
    <CommonProjectForm mode="dealCreation" onSubmit={onSubmit} ref={formRef} />
  );
};
