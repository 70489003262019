import * as yup from "yup";
import {
  SubsidiaryDTO,
  schemaSubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";

export interface FramePepDTO {
  id: number;
  name: string;
  pepCodeProjects: PepCodeProjectDTO[];
  total: number;
  subsidiary: SubsidiaryDTO;
}

export interface PepCodeProjectDTO {
  pepCode: string;
  costCenterCode: string;
  projects: PepCodeProjectInformationDTO[];
  total: number;
}

export interface PepCodeProjectInformationDTO {
  id: number;
  name: string;
  scheduledStartDate: Date | null;
  scheduledEndDate: Date | null;
  billingAmount: number;
  totalAdditivesAmount: number;
  total: number;
}

export const schemaPepCodeProjectInformationDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  scheduledStartDate: yup.date().nullable().defined(),
  scheduledEndDate: yup.date().nullable().defined(),
  billingAmount: yup.number().defined(),
  totalAdditivesAmount: yup.number().defined(),
  total: yup.number().defined(),
});

export const schemaPepCodeProjectDTO = yup.object({
  pepCode: yup.string().defined(),
  costCenterCode: yup.string().defined(),
  projects: yup.array(schemaPepCodeProjectInformationDTO.defined()).defined(),
  total: yup.number().defined(),
});

export const schemaFramePepDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  pepCodeProjects: yup.array(schemaPepCodeProjectDTO.defined()).defined(),
  total: yup.number().defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
});
