import * as yup from "yup";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  UseFormikModalButtonRef,
  useFormikModalButton,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { CollaboratorManagementFilterDTO } from "../../../../../../shared/specific/DTOs/collaboratorManagement/CollaboratorManagementFilterDTO";
import { FilterForm } from "./FilterForm";
import { FilterValues } from "./shared/types/data/form/values.types";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      horizontal: [],
      discipline: [],
      skillCategory: [],
      skill: [],
      certification: [],
      country: [],
      skillLevel: [],
      languageLevel: [],
      languageSkill: [],
      stateAutocomplete: null,
      stateTextField: "",
      isCertificationProgram: null,
      contractRole: [],
      division: null,
    };

    const validationSchema = yup.object({
      horizontal: yup.array(),
      discipline: yup.array(),
      skillCategory: yup.array(),
      skill: yup.array(),
      certification: yup.array(),
      country: yup.array(),
      skillLevel: yup.array(),
      languageLevel: yup.array(),
      languageSkill: yup.array(),
      stateTextField: yup.string(),
      isCertificationProgram: yup.object().nullable(),
      contractRole: yup.array(),
      division: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) => {
        let state = "";

        const isBrazil =
          formValues.country.find((x) => x.code === "BR") &&
          formValues.country.length === 1;

        if (formValues.stateTextField === "") state = formValues.stateTextField;

        if (formValues.stateAutocomplete && isBrazil) {
          state = formValues.stateAutocomplete.abbreviation;
        }
        const skills = formValues.skill.map((x) => x.id);
        const languages = formValues.languageSkill.map((x) => x.id);
        const skillsLevel = formValues.skillLevel.map((x) => x.id);
        const languageLevel = formValues.languageLevel.map((x) => x.id);
        const formattedFilter: CollaboratorManagementFilterDTO = {
          countrys: formValues.country.map((x) => x.code),
          idsDiscipline: formValues.discipline.map((x) => x.id),
          idsCertification: formValues.certification.map((x) => x.id),
          idsHorizontal: formValues.horizontal.map((x) => x.id),
          idsSkill: [...skills],
          idsLanguageSkill: [...languages],
          idsSkillCategory: formValues.skillCategory.map((x) => x.id),
          idsSkillLevel: [...skillsLevel],
          idsLanguageLevel: [...languageLevel],
          isCertificationProgram: formValues.isCertificationProgram?.id,
          state: state === "" ? undefined : state,
          idsContractRole: formValues.contractRole.map((x) => x.id),
          idDivision: formValues.division?.id ?? undefined,
        };
        return formattedFilter;
      },
      FormContentMemo: FilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
