import { useContext, useEffect, useState } from "react";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { AllocationsSummaryContent } from "./components/AllocationsSummaryContent";
import { ProjectContext } from "../../../../shared/react/contexts/ProjectContext";

interface OwnProps {
  initialCostCenterPep: CostCenterPepOnlyDTO;
}

export const useAllocationsSummary = ({ initialCostCenterPep }: OwnProps) => {
  const projectContext = useContext(ProjectContext);

  const [costCenterPep, setCostCenterPep] =
    useState<CostCenterPepOnlyDTO>(initialCostCenterPep);

  useEffect(() => {
    if (projectContext?.setCurrentCostCenterPep) {
      projectContext.setCurrentCostCenterPep(costCenterPep);
    }
  }, [costCenterPep]);
  return {
    allocationsSummaryComponent: (
      <AllocationsSummaryContent
        costCenterPep={costCenterPep}
        setCostCenterPep={setCostCenterPep}
      />
    ),
    costCenterPep,
  };
};
