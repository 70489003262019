import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { createTranslatableFieldValues } from "../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { makeTranslatableFieldValuesFormSchema } from "../../../../../../../shared/common/helpers/data/form/languages/schema.helpers";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";
import { DashboardFormValues } from "../../types/DashboardFormValues";
import { schemaDashboardGroupDTO } from "../../../../../../../shared/specific/DTOs/dashboardGroups/DashboardGroupDTO";

export const useCreationEditingFormikConfig = () => {
  const { t } = useTranslation();
  const defaultLanguage = useSelector(
    (state: RootState) => state.language.defaultLanguage
  );

  const initialValues: DashboardFormValues = useMemo(() => {
    return {
      nameTranslations: createTranslatableFieldValues(),
      dashboardGroup: null,
      url: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      nameTranslations: makeTranslatableFieldValuesFormSchema({
        defaultLanguage: defaultLanguage ?? Language.enUS,
        defaultLanguageSchema: yup
          .string()
          .required(t("general.errors.data.fields.general.required")),
        secondaryLanguagesSchema: yup.string(),
      }).required(),
      dashboardGroup: schemaDashboardGroupDTO
        .typeError(t("general.errors.data.fields.general.required"))
        .defined(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
      url: yup
        .string()
        .defined(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
