import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikConfig } from "./useFormikConfig";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../types/form.types";
import { EditFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../components/CreationEditingForm";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { UserLanguageDTO } from "../../../../../../../../shared/specific/DTOs/userLanguage/UserLanguageDTO";
import { UpdateUserLanguage } from "../../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import { UserLanguageUpdateDTO } from "../../../../../../../../shared/specific/DTOs/userLanguage/userLanguageUpdateDTO";
import { UserLanguageLevelEnum } from "../../../../../../../../shared/specific/enums/userLanguage/UserLanguageLevelEnum";
import { userLanguageTranslationCodeMap } from "../../../../../../../../shared/specific/maps/userLanguage/userLanguageTranslationCodeMap";
import { sleep } from "../../../../../../../../shared/common/helpers/general.helpers";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { getLanguageSkills } from "../../../../../../../../services/skill/skill.service";

export const useEditingForm = () => {
  const { t } = useTranslation();
  const { loadPage } = useContext(ProfileContext);

  const sharedformikConfig = useFormikConfig();

  const formikConfig: EntryFormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: sharedformikConfig.initialValues,
      validationSchema: sharedformikConfig.validationSchema,
    };
  }, [t, sharedformikConfig]);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, UserLanguageDTO[], UserLanguageDTO[]>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: "" }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: userLanguages,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          await sleep(500);
          const languageSkills = await getLanguageSkills();
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;
          setInternalFormData(Object.values(userLanguages));

          setFormValues({
            languages: languageSkills.map((languageSkill) => {
              const collaboratorSkill = Object.values(userLanguages).find(
                (x) => x.skill.id === languageSkill.id
              );

              return {
                language: languageSkill,
                level: collaboratorSkill
                  ? {
                      id: collaboratorSkill.level,
                      label: t(
                        userLanguageTranslationCodeMap[collaboratorSkill.level]
                      ),
                    }
                  : null,
              };
            }),
            languageDropdown: Object.values(userLanguages).map((x) => x.skill),
          });
          setIsLoadingModal(false);
        },
      },
      form: {
        formikConfig,
        FormContentMemo: CreationEditingForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues: { ...formValues },
          formikHelpers: { setSubmitting },
          internalFormData,
          closeModal,
        }) => {
          try {
            const listLangiagesUpdated: UserLanguageUpdateDTO[] =
              formValues.languages
                .filter((x) => x.level)
                .map((language) => ({
                  idSkill: language.language.id,
                  level: language.level?.id as UserLanguageLevelEnum,
                }));
            await UpdateUserLanguage(listLangiagesUpdated);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "usersCollaborators.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
            await loadPage();
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
