import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormattedCurrency } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { CarouselCell } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CarouselLeftButton } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { RevenueInvoicesDTO } from "../../../../../../../../shared/specific/DTOs/revenueInvoice/RevenueInvoicesDTO";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { StyledContainer } from "../../general.styles";
import { RevenueInvoicesCarouselContext } from "../shared/react/contexts/RevenueInvoicesCarouselContext";
import {
  StyledCenteredInfo,
  StyledFootnote,
  StyledRowHeader,
} from "./index.styles";

interface OwnProps {
  revenueInvoices: RevenueInvoicesDTO;
}

const CAROUSEL_SIZE = 8;

export const RevenueInvoicesTabWithinContext = ({
  revenueInvoices,
}: OwnProps) => {
  const { t } = useTranslation();

  const { costCenterPep } = useProjectContext();
  const { startCarousel, carouselRealSize, totalYearMonths } = useContext(
    RevenueInvoicesCarouselContext
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        headColumn: true,
        canSort: false,
      },
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }

    for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
      headCells.push({ value: "", canSort: false });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      align: "right",
      canSort: false,
    });

    return headCells;
  }, [t, carouselRealSize]);

  const rowsHeaders = useMemo(() => {
    return [
      {
        name: "revenueInvoices.keywords.fields.revenue",
        items: revenueInvoices.revenues,
        total: revenueInvoices.revenuesTotal,
      },
      {
        name: "invoice.keywords.general.invoice",
        items: revenueInvoices.invoices,
        total: revenueInvoices.invoicesTotal,
      },
      {
        name: "revenueInvoices.keywords.fields.provision",
        items: revenueInvoices.provisions,
        total: revenueInvoices.provisionsCurrent,
      },
    ];
  }, [revenueInvoices]);

  const rows = useMemo(() => {
    if (!rowsHeaders) return null;
    if (!totalYearMonths) return [];

    startCarousel({ carouselSize: CAROUSEL_SIZE });

    return rowsHeaders.map(({ name, items, total }, index) => {
      const row: ExternalEnhancedRow = {
        id: index,
        cells: [
          {
            value: t(name),
            CellRenderer: () => (
              <StyledRowHeader>
                <div>{t(name)}</div>
                <FormattedCurrency
                  value={total}
                  currencySymbol={costCenterPep.currencySymbol}
                />
              </StyledRowHeader>
            ),
          },
        ],
      };

      row.cells.push({});

      for (let i = 0; i < carouselRealSize; i++) {
        row.cells.push({
          paddingmode: "none",
          CellRenderer: () => (
            <CarouselCell
              items={items}
              columnIndex={i}
              checkIsCurrentItem={(item, yearMonth) =>
                item.yearMonth.equals(yearMonth)
              }
              CarouselComponentMemo={({ item, items, yearMonth }) => {
                if (index === 2 && !item && yearMonth) {
                  const before = items.filter((x) => x.yearMonth < yearMonth);
                  const lastValue = before[before.length - 1]?.amount;
                  return (
                    <StyledCenteredInfo>
                      <FormattedCurrency
                        value={lastValue ?? 0}
                        currencySymbol={costCenterPep.currencySymbol}
                      />
                    </StyledCenteredInfo>
                  );
                }
                return (
                  <StyledCenteredInfo>
                    <FormattedCurrency
                      value={item?.amount ?? 0}
                      currencySymbol={costCenterPep.currencySymbol}
                    />
                  </StyledCenteredInfo>
                );
              }}
            />
          ),
        });
      }

      for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
        row.cells.push({});
      }

      row.cells.push({});

      return row;
    });
  }, [
    t,
    rowsHeaders,
    carouselRealSize,
    totalYearMonths,
    costCenterPep.currencySymbol,
  ]);

  if (!rows) return null;

  return (
    <StyledContainer>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        uiSettings={{
          stickyHeader: true,
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
      <StyledFootnote>
        {t("revenueInvoices.info.data.general.footnoteShowedByDates")}
      </StyledFootnote>
    </StyledContainer>
  );
};
