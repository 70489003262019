import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledGridContainer = styled("div")``;

export const StyledTotalContainer = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 15px;
  position: sticky;
  bottom: 0;
  background-color: #ebebeb;
  border-top: solid 2px #cfcfcf;
`;
