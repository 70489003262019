import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CostCenterPepDTO } from "../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepDTO";
import { TaxUpdateDTO } from "../../../../../../../../shared/specific/DTOs/Tax/TaxUpdateDTO";
import { SubsidiaryWithBusinessRule } from "../../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { CostCenterPepsFormValues } from "../../../shared/types/data/form.types";

export function HandleTaxes(
  formValues: CostCenterPepsFormValues,
  costCenterPep: CostCenterPepDTO
): TaxUpdateDTO[] {
  if (!formValues?.subsidiary) return [];
  switch (formValues.subsidiary.name) {
    case SubsidiaryWithBusinessRule.RDXBR:
      return [
        {
          id: costCenterPep.taxes.find((x) => x.name === "PIS")?.id,
          name: "PIS",
          value: formValues.pis?.label
            ? parseLocaleNumber(formValues.pis.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          version: costCenterPep.taxes.find((x) => x.name === "PIS")?.version,
        },
        {
          id: costCenterPep.taxes.find((x) => x.name === "COFINS")?.id,
          name: "COFINS",
          value: formValues.cofins?.label
            ? parseLocaleNumber(formValues.cofins.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          version: costCenterPep.taxes.find((x) => x.name === "COFINS")
            ?.version,
        },
        {
          id: costCenterPep.taxes.find((x) => x.name === "ICMS")?.id,
          name: "ICMS",
          value: parseLocaleNumber(formValues.icms),
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          version: costCenterPep.taxes.find((x) => x.name === "ICMS")?.version,
        },
        {
          id: costCenterPep.taxes.find((x) => x.name === "ISS")?.id,
          name: "ISS",
          value: formValues.iss?.label
            ? parseLocaleNumber(formValues.iss.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          version: costCenterPep.taxes.find((x) => x.name === "ISS")?.version,
        },
      ];
    case "RDX.US":
      return [
        {
          id: 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          name: "Tax",
          value: parseLocaleNumber(formValues.tax),
        },
      ];
    default:
      return [];
  }
}
