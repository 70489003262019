import { useContext } from "react";
import { useMinDateOrProjectStart } from "../../../../../../../shared/react/hooks/data/fields/useMinDateOrProjectStart";
import { ActivitiesSuperUserContext } from "../../../contexts/ActivitiesSuperUserContext";

export const useMinScheduledDate = () => {
  const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);

  const {
    minDate: minScheduledDate,
    minDateOnly: minScheduledDateOnly,
    minDateErrorMessage: minScheduledDateErrorMessage,
  } = useMinDateOrProjectStart({
    canEditBeforeStartMonth: canEditAllActivities,
    cannotBeforeProjectStartErrorMessage:
      "activities.errors.data.fields.scheduledDate.cannotBeEarlierProject",
    cannotBeforeCurrentMonthErrorMessage:
      "activities.errors.data.fields.scheduledDate.cannotBeEarlierCurrentMonth",
  });

  return {
    minScheduledDate,
    minScheduledDateOnly,
    minScheduledDateErrorMessage,
  };
};
