import { ReactNode, useMemo, useState } from "react";
import { AllocationsSummaryContext } from "./AllocationsSummaryContext";
import {
  AllocationsSummaryContextValues,
  AllocationsSummaryData,
} from "./index.types";

interface OwnProps {
  children: ReactNode;
}

export const AllocationsSummaryContextProvider = ({ children }: OwnProps) => {
  const [summaryData, setSummaryData] = useState<AllocationsSummaryData | null>(
    null
  );
  const [isLoadingSummary, setIsLoadingSummary] = useState(true);
  const [hasErrorSummary, setHasErrorSummary] = useState(true);

  const contextValue = useMemo((): AllocationsSummaryContextValues => {
    return {
      summaryData,
      isLoadingSummary,
      hasErrorSummary,
      setSummaryData,
      setIsLoadingSummary,
      setHasErrorSummary,
    };
  }, [summaryData, isLoadingSummary, hasErrorSummary]);

  return (
    <AllocationsSummaryContext.Provider value={contextValue}>
      {children}
    </AllocationsSummaryContext.Provider>
  );
};
