import { Box, TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SkillCategoryDTO } from "../../../../../../../DTOs/skillCategory/SkillCategoryDTO";
import { SkillCategoryFilterDTO } from "../../../../../../../DTOs/skillCategory/filters/SkillCategoryFilterDTO";
import { getSkillCategorysDropdown } from "../../../../../../../../../services/skillCategory/skillCategory.service";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillCategoryDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<SkillCategoryFilterDTO, "name">;
  filtersMemo?: Omit<SkillCategoryFilterDTO, "name">;
  shouldReplaceValueOnMount?: boolean;
  onlyActivated?: boolean;
}

export const SkillCategoriesMultipleAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  shouldReplaceValueOnMount = true,
  onlyActivated = true,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getSkillCategoryFinal = useCallback(async () => {
    let paginatedSkillCategory = await getSkillCategorysDropdown();

    if (onlyActivated) {
      paginatedSkillCategory = paginatedSkillCategory.filter(
        (x) => !x.deactivatedDate
      );
    }

    return paginatedSkillCategory;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "skillCategory.errors.data.fetch.failedToFetchSkillCategory"
      )}
      fetchOptions={getSkillCategoryFinal}
      textfieldProps={{
        label: t("skillCategory.keywords.general.category"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${
            x.skillCategoryTranslation.find(
              (y) => y.idLanguage === i18n.language
            )?.name ?? x.name
          } - ${x.discipline.name} ${
            x.deactivatedDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {`${
                  option.skillCategoryTranslation.find(
                    (y) => y.idLanguage === i18n.language
                  )?.name ?? option.name
                } - ${option.discipline.name} ${
                  option.deactivatedDate
                    ? `(${t(
                        "jobRates.keywords.general.deactivated"
                      ).toUpperCase()})`
                    : ""
                }`}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
