import { useTranslation } from "react-i18next";
import { ProjectionAdjustmentMonthlyCommentFormValues } from "../../types/ProjectionAdjustmentMonthlyCommentFormValues";
import { TextFieldFormik } from "../../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { InternalFormDataProps } from "../../../../../../../types/form.types";

type OwnProps = FormContentProps<
  ProjectionAdjustmentMonthlyCommentFormValues,
  InternalFormDataProps | null
>;

export const MonthlyCommentCommomForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <TextFieldFormik
      name="comment"
      label={t("projection.keywords.fields.comment")}
      required
      disabled={isSubmitting}
      fullWidth
      rows={4}
      multiline
    />
  );
};
