import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useFormikModalButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DelayJustificationForm } from "./DelayJustificationForm";
import { FormValues } from "./shared/types/data/form/values.types";
import { useProjectContext } from "../../../../../../shared/react/contexts/ProjectContext";
import { savePlanning } from "../../../../../../../../../../../services/projects/projects.service";
import { notifySuccess } from "../../../../../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ProjectOnlyDTO } from "../../../../../../../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";

interface OwnProps {
  closeOuterModal: () => void;
}

export const useDelayJustificationForm = ({ closeOuterModal }: OwnProps) => {
  const { t } = useTranslation();
  const { reloadProject } = useProjectContext();

  const formikConfig = useMemo(() => {
    const initialValues: FormValues = {
      delayJustification: "",
    };

    const validationSchema = yup.object({
      delayJustification: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentModal: delayJustificationModal,
    openModal: openDelayJustificationModal,
  } = useFormikModalButton<FormValues, ProjectOnlyDTO>({
    modal: {
      modalTitle: t("projects.modal.data.delayJustification.title"),
      keepModalMounted: 1000,
      modalMode: "dialog",
    },
    form: {
      formikConfig,
      FormContent: DelayJustificationForm,
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData: updatedProject,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!updatedProject)
          throw new Error("'updatedProject' cannot be null on submit.");

        try {
          await savePlanning({
            idProject: updatedProject.id,
            delayJustification: formValues.delayJustification,
            version: updatedProject.version,
          });

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          closeModal();
          closeOuterModal();
          reloadProject({ shouldResetValue: true });
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "projects.errors.data.savePlanning.failedToSavePlanning",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { delayJustificationModal, openDelayJustificationModal };
};
