import { Link } from "react-router-dom";
import { useContext } from "react";
import { StyledFixedColumn } from "./index.styles";
import { EditIconButton } from "../../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { ProjectionFilterContext } from "../../../../../../../../shared/context/ProjectionFilterContext";

interface OwnProps {
  info: string;
  isRead?: boolean;
  idExpenseGroup?: number;
}

export const FixedColumnMainRow = ({
  info,
  isRead,
  idExpenseGroup,
}: OwnProps) => {
  const { filterValues } = useContext(ProjectionFilterContext);
  return (
    <StyledFixedColumn isread={isRead ? "true" : "false"}>
      <span>{info}</span>
      {!isRead &&
        idExpenseGroup &&
        filterValues.division &&
        filterValues.segmentGroup && (
          <Link
            to={`adjustments?idExpenseGroup=${idExpenseGroup}&idSubsidiary=${filterValues.subsidiary?.id}&idDivision=${filterValues.division?.id}&idSegmentGroup=${filterValues.segmentGroup?.id}&year=${filterValues.year?.getFullYear()}${filterValues.customerTrigram !== "" ? `&customerTrigram=${filterValues.customerTrigram}` : ""}${filterValues.clientAccount !== "" ? `&clientAccount=${filterValues.clientAccount}` : ""}`}
          >
            <EditIconButton color="primary" size="small" />
          </Link>
        )}
    </StyledFixedColumn>
  );
};
