import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledFormInfo = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c d";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(4)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d";
  }
`;

export const StyledPercentageMessage = styled("div")`
  text-align: center;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;
