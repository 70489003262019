import * as yup from "yup";
import {
  ContractRoleTranslationDTO,
  schemaContractRoleTranslationDTO,
} from "./translation/ContractRoleTranslationDTO";

export interface ContractRoleDropdownDTO {
  id: number;
  name: string;
  contractRoleTranslations: ContractRoleTranslationDTO[];
}

export const schemaContractRoleDropdownDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  contractRoleTranslations: yup
    .array(schemaContractRoleTranslationDTO)
    .defined(),
});
