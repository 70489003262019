import * as yup from "yup";
import {
  TypeTranslationDTO,
  schemaTypeTranslationDTO,
} from "./TypeTranslationDTO";
import {
  YearMonthValueDTO,
  schemaYearMonthValueDTO,
} from "./YearMonthValueDTO";

export interface YearMonthValueTypeDTO {
  type: TypeTranslationDTO;
  total: number;
  yearmonthValues: YearMonthValueDTO[];
}

export const schemaYearMonthValueTypeDTO = yup.object({
  type: schemaTypeTranslationDTO.defined(),
  total: yup.number().defined(),
  yearmonthValues: yup.array(schemaYearMonthValueDTO).defined(),
});
