import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { convertTo } from "../../../../../shared/common/helpers/types/converters.helpers";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { FrameFilterDTO } from "../../../../../shared/specific/DTOs/frame/filters/FramesFiltersDTO";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";

export const FramesMainPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("frames.keywords.general.frames"));
  }, [t]);

  const { creationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const { filterButton, filters: modalFilters, filtersModal } = useFilterForm();

  const filterObject =
    belowToolbarFilters.name === ""
      ? modalFilters
      : {
          ...modalFilters,
          ...belowToolbarFilters,
        };

  const filters = useMemo(() => {
    return convertTo<FrameFilterDTO>(filterObject);
  }, [belowToolbarFilters, modalFilters]);

  const { headCells, onTablePageChange, editingModal, deletionModal } =
    useTableData({
      filters,
      reloadTablePage,
    });

  const rightActionButtons = useMemo(() => {
    return filterButton;
  }, [filterButton]);

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters, i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.Frame,
                level: PermissionLevel.Create,
              }}
            >
              {creationButtonContainer}
            </Protected>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 10,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {filtersModal}
      {editingModal}
      {creationModal}
      {deletionModal}
    </>
  );
};
