import React from "react";
import { StyledCellColumn } from "./index.styles";

interface OwnProps {
  info: React.ReactNode;
  isBold?: boolean;
}

export const Cell = ({ info, isBold }: OwnProps) => {
  return (
    <StyledCellColumn
      style={{
        fontWeight: isBold ? "bold" : "normal",
      }}
    >
      {info}
    </StyledCellColumn>
  );
};
