import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { InputAdornment } from "@mui/material";
import { CommonActivityFormValues } from "../../../../types/data/form/values.types";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { StyledForm } from "./index.styles";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { DatePickerFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { ProjectDTO } from "../../../../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { useMinScheduledDate } from "../../../hooks/data/fields/useMinScheduledDate";
import { generalFieldsConstants } from "../../../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";

interface OwnProps
  extends Omit<FormContentProps<CommonActivityFormValues>, "internalFormData"> {
  project: ProjectDTO | null;
}

export const CommonActivityForm = ({
  formikProps: { isSubmitting },
  project,
}: OwnProps) => {
  const { t } = useTranslation();

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {project?.subsidiary.currencySymbol ?? ""}
      </InputAdornment>
    );
  }, [project?.subsidiary.currencySymbol]);

  const { minScheduledDate } = useMinScheduledDate();

  return (
    <StyledForm>
      <TextFieldFormik
        name="description"
        label={t("general.keywords.fields.description")}
        inputProps={{ maxLength: maxLengthFields.description }}
        required
        disabled={isSubmitting}
      />
      <LocalizedNumberTextFieldFormik
        name="amount"
        min={0}
        max={generalFieldsConstants.maxAmountValue}
        padFractionalZeros
        textFieldProps={{
          label: t("general.keywords.fields.value"),
          required: true,
          disabled: isSubmitting,
          InputProps: {
            startAdornment: currencyStartAdornment,
          },
        }}
      />
      <DatePickerFormik
        name="scheduledDate"
        datePickerProps={{
          label: t("activities.keywords.fields.scheduledAt"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          minDate: minScheduledDate,
        }}
        textFieldProps={{
          required: true,
        }}
      />
    </StyledForm>
  );
};
