import * as yup from "yup";

export enum BaseCollaboratorReportScopeTypeEnum {
  AllCollaborators = 1,
  AllAllocations = 0,
}

export const schemaContractType = yup
  .mixed<BaseCollaboratorReportScopeTypeEnum>()
  .oneOf(
    Object.values(
      BaseCollaboratorReportScopeTypeEnum
    ) as BaseCollaboratorReportScopeTypeEnum[]
  );
