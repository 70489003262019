import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";

export const billingCountry = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const billingCountryLableCpt: CptProps = {
    text: "Billing Country",
    options: {
      align: pres.AlignH.left,
      y: "13%",
      x: "38%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const billingCountryNameCpt: CptProps = {
    text: projectData.country,
    options: {
      align: pres.AlignH.left,
      y: "15.5%",
      x: "38%",
      w: "10%",
      color: "363636",
      fontSize: 10,
    },
  };

  slide.addText(billingCountryLableCpt.text, billingCountryLableCpt.options);
  slide.addText(billingCountryNameCpt.text, billingCountryNameCpt.options);
};
