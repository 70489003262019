import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { IntervalType } from "../../../../../../enums/data/form/general.enums";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<IntervalType>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const IntervalTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo((): AutocompleteOption<IntervalType>[] => {
    return [
      {
        id: IntervalType.Day,
        label: t("general.keywords.dateTime.general.day"),
      },
      {
        id: IntervalType.Week,
        label: t("general.keywords.dateTime.general.week"),
      },
      {
        id: IntervalType.Month,
        label: t("general.keywords.dateTime.general.month"),
      },
    ];
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={textfieldProps}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
