import * as yup from "yup";

import { riseApi } from "./connection";
import {
  ExpenseTypeDTO,
  schemaExpenseTypeDTO,
} from "../../../shared/specific/DTOs/ExpenseType/ExpenseTypeDTO";
import { ExpenseTypeFilterDTO } from "../../../shared/specific/DTOs/ExpenseType/filters/ExpenseTypeFilterDTO";

export const getExpenseTypes = async ({
  filters,
}: {
  filters?: ExpenseTypeFilterDTO;
}): Promise<ExpenseTypeDTO[]> => {
  const { data: expenseTypes } = await riseApi.get("/ExpenseType", {
    params: filters,
  });

  return yup.array(schemaExpenseTypeDTO).defined().validateSync(expenseTypes);
};
