import * as yup from "yup";
import { CostCenterPepCreateDTO } from "../../../shared/specific/DTOs/costCenterPeps/CostCenterPepCreateDTO";
import {
  CostCenterPepDTO,
  schemaCostCenterPepDTO,
} from "../../../shared/specific/DTOs/costCenterPeps/CostCenterPepDTO";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CostCenterPepUpdateDTO } from "../../../shared/specific/DTOs/costCenterPeps/CostCenterPepUpdateDTO";
import { CostCenterPepListFiltersDTO } from "../../../shared/specific/DTOs/costCenterPeps/filters/CostCenterPepListFiltersDTO";
import { riseApi } from "./connection";
import { CostCenterPepDropdownFilter } from "../../../shared/specific/DTOs/costCenterPeps/filters/CostCenterPepDropdownFilter";

export const getCostCenterPeps = async ({
  filters,
}: {
  filters: CostCenterPepListFiltersDTO;
}): Promise<CostCenterPepDTO[]> => {
  const { data: costCenterPeps } = await riseApi.get("CostCenterPeps", {
    params: filters,
  });

  return yup
    .array(schemaCostCenterPepDTO)
    .defined()
    .validateSync(costCenterPeps);
};

export const getCostCenterPepCodes = async (): Promise<string[]> => {
  const { data: costCenterPeps } = await riseApi.get("CostCenterPeps/PepCodes");

  return yup
    .array(yup.string().defined())
    .defined()
    .validateSync(costCenterPeps);
};

export const getCostCenterPepsOnly = async ({
  filters,
}: {
  filters: CostCenterPepListFiltersDTO;
}): Promise<CostCenterPepOnlyDTO[]> => {
  const { data: costCenterPeps } = await riseApi.get("CostCenterPeps/Only", {
    params: filters,
  });

  return yup
    .array(schemaCostCenterPepOnlyDTO)
    .defined()
    .validateSync(costCenterPeps);
};

export const getMainCostCenterPepsOnlyDropdown = async ({
  filters,
}: {
  filters?: CostCenterPepDropdownFilter;
}): Promise<CostCenterPepOnlyDTO[]> => {
  const idsProjects = filters?.idsProjects ?? [];
  const idsProjectsQueryString = `?${idsProjects
    .map((n, index) => `idsProjects[${index}]=${n}`)
    .join("&")}`;
  if (filters?.idsProjects) {
    filters.idsProjects = [];
  }
  const { data: costCenterPeps } = await riseApi.get(
    `CostCenterPeps/OnlyMainPepsDropdown${idsProjectsQueryString}`,
    {
      params: filters,
    }
  );

  return yup
    .array(schemaCostCenterPepOnlyDTO)
    .defined()
    .validateSync(costCenterPeps);
};

export const getCostCenterPepById = async (
  id: number
): Promise<CostCenterPepDTO | null> => {
  const { data: costCenterPep } = await riseApi.get(`CostCenterPeps/${id}`);

  return schemaCostCenterPepDTO.nullable().validateSync(costCenterPep);
};

export const getCostCenterPepOnlyById = async (
  id: number
): Promise<CostCenterPepOnlyDTO | null> => {
  const { data: costCenterPep } = await riseApi.get(
    `CostCenterPeps/Only/${id}`
  );

  return schemaCostCenterPepOnlyDTO.nullable().validateSync(costCenterPep);
};

export const getMainProjectPepOnly = async (
  idProject: number
): Promise<CostCenterPepOnlyDTO | null> => {
  const { data: costCenterPep } = await riseApi.get(
    `CostCenterPeps/Only/MainProjectPep/${idProject}`
  );

  return schemaCostCenterPepOnlyDTO.nullable().validateSync(costCenterPep);
};

export const createCostCenterPep = async (
  costCenterPepCreationDTO: CostCenterPepCreateDTO
) => {
  await riseApi.post("CostCenterPeps", costCenterPepCreationDTO);
};

export const updateCostCenterPep = async (
  costCenterPepUpdateDTO: CostCenterPepUpdateDTO
) => {
  await riseApi.put("CostCenterPeps", costCenterPepUpdateDTO);
};

export const deleteCostCenterPep = async (id: number, version: string) => {
  await riseApi.delete(`CostCenterPeps/${id}/${version}`);
};
