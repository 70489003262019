import { ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { RestrictionsOperand } from "../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../classes/user/permissions/permissionsOperators/RootPermissionsOperator";

interface OwnProps {
  children: ReactNode;
  restrictions?: RestrictionsOperand;
  listRestrictions?: RestrictionsOperand[];
}

export const Protected = ({
  children,
  restrictions,
  listRestrictions,
}: OwnProps) => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const canAccessChildren = useMemo(() => {
    if (!userData) return false;

    if (listRestrictions && listRestrictions.length > 0) {
      const listPermission: boolean[] = [];
      listRestrictions.forEach((x) => {
        const permissionsOperator = new RootPermissionsOperator(x);

        listPermission.push(
          permissionsOperator.checkPermissions({
            userPermissions: userData.permissions,
            isAdmin: userData.isAdmin,
          })
        );
      });

      return listPermission.some((x) => x);
    }

    if (restrictions) {
      const permissionsOperator = new RootPermissionsOperator(restrictions);

      return permissionsOperator.checkPermissions({
        userPermissions: userData.permissions,
        isAdmin: userData.isAdmin,
      });
    }

    return false;
  }, [userData?.permissions, userData?.isAdmin]);

  if (!canAccessChildren) return null;

  return <>{children}</>;
};
