import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues, LanguageObjectValue } from "../types/form.types";
import { StyledAreaListLanguages, StyledAreaTextFields } from "./index.styles";
import { StyledTextArea } from "../index.styles";
import { UserLanguageLevelAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/userLanguage/UserLanguageLevelAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";

export const CreationEditingForm = ({
  formikProps: { values, setFieldValue },
}: FormContentProps<FormValues>) => {
  const { t, i18n } = useTranslation();

  useEffectAfterRenders({
    effect: () => {
      const mapperSkills: LanguageObjectValue[] = values.languageDropdown.map(
        (language) => ({
          language,
          level:
            values.languages.find(
              (listLanguage) => listLanguage.language.id === language.id
            )?.level || null,
        })
      );
      setFieldValue("languages", mapperSkills);
    },
    deps: [values?.languageDropdown],
    rendersBeforeEffect: 1,
  });

  const LanguagesComponentList = useMemo(() => {
    return values.languages.map((languageItem, i) => {
      return (
        <StyledAreaTextFields key={languageItem.language.id}>
          <StyledTextArea
            label={t("collaboratorProfile.keywords.fields.language")}
            value={
              languageItem.language.skillTranslation.find(
                (y) => y.idLanguage === i18n.language
              )?.name ?? languageItem.language?.name
            }
            InputProps={{
              readOnly: true,
            }}
          />
          <UserLanguageLevelAutocompleteFormik name={`languages[${i}].level`} />
        </StyledAreaTextFields>
      );
    });
  }, [values.languages]);

  return (
    <div>
      <StyledAreaListLanguages>
        {values.languages.length > 0 && LanguagesComponentList}
      </StyledAreaListLanguages>
    </div>
  );
};
