import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { useMemo } from "react";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode, FormValues } from "../../../types/form.types";
import { StyledFormContainer } from "./indes.styles";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FeesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/fee/FeesAutocompleteFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { generalFieldsConstants } from "../../../../../../../CustomerBalance/CustomerBalancePage/shared/constants/fields.constants";
import { CheckboxFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  mode: FormMode;
};

export const CommonFeeYearMonthForm = ({
  formikProps: { isSubmitting, values },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  const currencySymbol = useMemo(
    () => values.fee?.subsidiary?.currencySymbol,
    [values.fee?.subsidiary?.currencySymbol]
  );

  return (
    <StyledFormContainer>
      {mode !== "editing" && (
        <>
          <YearMonthDatePickerFormik
            name="yearMonth"
            datePickerProps={{
              label: t("brlParity.keywords.fields.yearMonth"),
              slotProps: {
                popper: {
                  placement: "right",
                },
              },
            }}
            textFieldProps={{
              required: true,
            }}
          />
          <FeesAutocompleteFormik
            name="fee"
            textfieldProps={{ required: true }}
            autocompleteProps={{ disabled: isSubmitting }}
          />
        </>
      )}
      <LocalizedNumberTextFieldFormik
        name="value"
        scale={2}
        max={generalFieldsConstants.maxAmountValue}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("general.keywords.fields.value"),
          required: true,
          disabled: isSubmitting,
          InputProps: {
            startAdornment: currencySymbol,
          },
        }}
      />
      <FormControlLabel
        label={t(
          "fee.keywords.fields.useFinalAllocationWithSuplementarAllocations"
        )}
        control={<CheckboxFormik name="useFullAllocation" />}
      />
    </StyledFormContainer>
  );
};
