import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useActionButtonDialog } from "../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { PipedriveDealDTO } from "../../../../../shared/specific/DTOs/pipedrive/PipedriveDealDTO";
import { DeleteIconButton } from "../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { deactivateDealOnRise } from "../../../../../services/projects/projects.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeactivationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deactivationModal,
  } = useActionButtonDialog<PipedriveDealDTO, PipedriveDealDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: pipeDriveDeal }) => {
        return (
          <Trans
            i18nKey="pipedrive.actions.general.deactivateDeal"
            values={{ name: pipeDriveDeal?.idProject ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton
          tooltipText={t(
            "pipedrive.actions.general.deactivationDateRiseRegistrationProcess"
          )}
          onClick={openModal}
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: pipeDriveDeal,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(pipeDriveDeal);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "pipedrive.errors.data.deactivate.failedToDeactivateDeal",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.deactivate"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: pipedriveDeal,
      }) => {
        if (!pipedriveDeal)
          throw new Error("deal data cannot be null on confirm.");

        try {
          await deactivateDealOnRise(pipedriveDeal.id);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="pipedrive.errors.data.deactivate.failedToDeactivateDeal"
              values={{ name: pipedriveDeal?.idProject }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deactivationModal };
};
