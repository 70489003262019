import { CSSObject, TableCell, styled } from "@mui/material";

interface StyledFirsColumnProps {
  isfixfirst: string;
  isfixlast: string;
}

export const StyledCenteredLoading = styled("div")`
  height: 85%;
  flex-grow: 1;
`;

export const StyledButtonColumn = styled(TableCell)<StyledFirsColumnProps>`
  ${(props) =>
    props.isfixfirst === "true" &&
    ({
      position: "sticky",
      left: 0,
    } as CSSObject)};
  background-color: white;
`;

export const StyledFirsColumn = styled(TableCell)<StyledFirsColumnProps>`
  ${(props) =>
    props.isfixfirst === "true" &&
    ({
      position: "sticky",
      left: 65.96,
    } as CSSObject)};

  ${(props) =>
    props.isfixlast === "true" &&
    ({
      position: "sticky",
      right: 0,
    } as CSSObject)};
  background-color: white;
  width: max-content;
`;

export const StyledInformation = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85%;
`;
