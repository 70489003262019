import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import { PageCardButtonsList } from "../../../../shared/common/react/components/navigation/PageCardButton";
import { RootState } from "../../../../store";
import { SyncPipedriveDataCardComponent } from "../SyncPipedriveData";

export const AdminPage = () => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  useEffect(() => {
    setPageTitle(t("adminPage.pages.main.title"));
  }, [t]);

  return userData?.isAdmin ? (
    <PageCardButtonsList>
      <SyncPipedriveDataCardComponent />
    </PageCardButtonsList>
  ) : (
    <div>{t("general.errors.authorization.unauthorizedAccessPage")}</div>
  );
};
