import { ReactNode, useEffect, useMemo, useState } from "react";
import { getCollaboratorAllocations } from "../../../../../../services/collaboratorAllocations/collaboratorAllocation.service";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import { CollaboratorAllocationDTO } from "../../../../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormValuesCollaboratorAllocationsFilter } from "../types/form.types";
import { CollaboratorAllocationContext } from "./collaboratorAllocationContext";
import { CollaboratorAllocationContextValues } from "./index.types";
import { usePermissionChecker } from "../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";

interface OwnProps {
  children: ReactNode;
}

export const CollaboratorAllocationProvider = ({ children }: OwnProps) => {
  const [allocations, setAllocations] = useState<CollaboratorAllocationDTO[]>(
    []
  );

  const { hasPermission: CanAccessLeaveInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessLeaveInCorporateAllocation,
      },
    });

  const { hasPermission: CanAccessTrainingInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessTrainingInCorporateAllocation,
      },
    });

  const { hasPermission: CanAccessDeallocationInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessDeallocationInCorporateAllocation,
      },
    });

  const [filter, setFilter] =
    useState<FormValuesCollaboratorAllocationsFilter | null>(null);

  const [search, setSearch] = useState<boolean>(false);

  useEffect(() => {
    async function getAllocationData() {
      if (filter?.yearMonth && filter.userCollaborator?.id) {
        try {
          setSearch(true);
          const allocations = await getCollaboratorAllocations({
            idUserCollaborator: filter.userCollaborator.id,
            yearMonth: YearMonth.createFromDate(filter.yearMonth),
          });

          setAllocations(allocations);
          setSearch(false);
        } catch (error) {
          console.error(error);
          setAllocations([]);
          setSearch(false);
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "collaboratorAllocations.errors.data.fetch.failedToFetch",
          });
        }
      } else {
        setAllocations([]);
      }
    }

    getAllocationData();
  }, [filter]);

  const allocationMapContext: CollaboratorAllocationContextValues = useMemo(
    () => ({
      allocations,
      setAllocations,
      filter,
      setFilter,
      search,
      setSearch,
      CanAccessLeaveInCorporateAllocation,
      CanAccessTrainingInCorporateAllocation,
      CanAccessDeallocationInCorporateAllocation,
    }),
    [
      allocations,
      search,
      CanAccessLeaveInCorporateAllocation,
      CanAccessTrainingInCorporateAllocation,
      CanAccessDeallocationInCorporateAllocation,
    ]
  );

  return (
    <CollaboratorAllocationContext.Provider value={allocationMapContext}>
      {children}
    </CollaboratorAllocationContext.Provider>
  );
};
