import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import { JobRoleDTO, schemaJobRoleDTO } from "../jobRole/JobRoleDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";

export interface JobRateDTO {
  id: number;
  jobRole: JobRoleDTO;
  startYearMonth: YearMonth;
  subsidiary: SubsidiaryDTO;
  monthlyRate: number;
  monthlyTax?: number;
  version: string;
}

export const schemaJobRateDTO = yup.object({
  id: yup.number().integer().defined(),
  jobRole: schemaJobRoleDTO.defined(),
  startYearMonth: schemaYearMonth.defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  monthlyRate: yup.number().defined(),
  monthlyTax: yup.number(),
  version: yup.string().defined(),
});
