import { useMemo } from "react";
import { YearMonth } from "../../../../../../../common/classes/data/date/YearMonth";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { checkDoesWeekExist } from "../../../../../../../common/helpers/data/dates/checkers.helpers";

interface OwnProps {
  name: string;
  label?: string;
  weekNumber: number;
  yearMonth: YearMonth;
  disabled?: boolean;
}

export const SharedAllocationYearMonthCell = ({
  name,
  label,
  weekNumber,
  yearMonth,
  disabled,
}: OwnProps) => {
  const weekExists = useMemo(() => {
    return checkDoesWeekExist(weekNumber, yearMonth);
  }, [weekNumber, yearMonth]);

  return (
    <LocalizedNumberTextFieldFormik
      name={name}
      min={0}
      max={100}
      textFieldProps={{
        label,
        required: true,
        disabled: disabled || !weekExists,
        InputProps: {
          endAdornment: "%",
        },
      }}
    />
  );
};
