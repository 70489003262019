import * as yup from "yup";
import {
  DashboardGroupDTO,
  schemaDashboardGroupDTO,
} from "../dashboardGroups/DashboardGroupDTO";
import {
  DashboardTranslationDTO,
  schemaDashboardTranslationDTO,
} from "./translate/DashboardTranslationDTO";

export interface DashboardDTO {
  version: string;
  id: number;
  name: string;
  url: string;
  dashBoardGroup: DashboardGroupDTO;
  dashboardTranslations: DashboardTranslationDTO[];
}

export const schemaDashboardDTO = yup.object({
  version: yup.string().defined(),
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  url: yup.string().defined(),
  dashBoardGroup: schemaDashboardGroupDTO.defined(),
  dashboardTranslations: yup.array(schemaDashboardTranslationDTO).defined(),
});
