import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledViewContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-areas:
    "a b"
    "c c"
    "d e"
    "f f";

  ${createGridAreaLettersRange(5)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas: "a" "b" "c" "d" "e" "f";
  }
`;
