import { Save as SaveIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import {
  finishPlanning,
  validatePlanningFinishing,
} from "../../../../../../../../../services/projects/projects.service";
import {
  OnConfirm,
  useActionButtonDialog,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { ProjectPlanningFinishingValidationDTO } from "../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningFinishingValidationDTO";
import { ProjectPlanningSavingValidationDTO } from "../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningSavingValidationDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { PlanningFinishingForm } from "./PlanningFinishingForm";

export const usePlanningFinishing = () => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const onConfirm = useCallback<
    OnConfirm<ProjectPlanningFinishingValidationDTO>
  >(
    async ({ closeModal, internalModalData: planningValidation }) => {
      if (!planningValidation)
        throw new Error("'planningValidation' cannot be null.");

      try {
        await finishPlanning({
          idProject: project.id,
          version: project.version,
        });
        notifySuccess(
          i18next.t(
            "general.success.data.general.operationExecutedSuccessfully"
          )
        );
        reloadProject({ shouldResetValue: true });
        closeModal();
      } catch (error) {
        notifyIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "projects.errors.data.finishProject.failedToFinishPlanning",
        });
      }
    },
    [project.id, project.version]
  );

  const {
    contentButton: planningFinishButton,
    contentModal: planningFinishModal,
  } = useActionButtonDialog<ProjectPlanningFinishingValidationDTO>({
    button: {
      ModalButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <Button
            variant="contained"
            color="success"
            startIcon={<SaveIcon />}
            onClick={onClick}
          >
            {t("projects.actions.general.finishProject")}
          </Button>
        );
      },
      onClickContentButtonMemo: async ({
        getOpenCloseModalCount,
        checkInCurrentModalCount,
        setIsLoadingModal,
        setInternalModalData,
        setModalError,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const planningValidation = await validatePlanningFinishing(
            project.id
          );
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!planningValidation)
            return setModalError(
              i18next.t("projects.errors.data.general.projectDoesNotExist")
            );

          setInternalModalData(planningValidation);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "projects.errors.data.fetch.failedToFetchValidationData",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("projects.modal.data.finishProject.title"),
      mode: "dialog",
      ModalContentMemo: ({ internalModalData: projectValidation }) =>
        projectValidation && (
          <PlanningFinishingForm
            projectFinishingValidation={projectValidation}
          />
        ),
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.save"),
      checkConfirmDisabledMemo: ({
        hasInternalModalError,
        isLoadingModal,
        internalModalData: validationData,
      }) => {
        if (hasInternalModalError || isLoadingModal) return true;
        if (!validationData) return false;

        const savingValidationData: ProjectPlanningSavingValidationDTO[keyof ProjectPlanningSavingValidationDTO][] =
          Object.values(validationData.projectPlanningSavingValidation);

        if (savingValidationData.every((x) => !!x)) return false;

        const finishingValidationData: ProjectPlanningFinishingValidationDTO[keyof ProjectPlanningFinishingValidationDTO][] =
          Object.values(validationData).filter((x) => typeof x === "boolean");

        return finishingValidationData.some((x) => !x);
      },
      onConfirm,
    },
  });

  return {
    planningFinishButton,
    planningFinishModal,
  };
};
