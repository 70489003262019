import * as yup from "yup";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import { SkillDTO, schemaSkillDTO } from "../skill/SkillDTO";
import {
  UserLanguageLevelEnum,
  schemaUserLanguageLevelEnum,
} from "../../enums/userLanguage/UserLanguageLevelEnum";

export interface UserLanguageDTO {
  id: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  skill: SkillDTO;
  level: UserLanguageLevelEnum;
  version: string;
}

export const schemaUserLanguageDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  skill: schemaSkillDTO.defined(),
  level: schemaUserLanguageLevelEnum.defined(),
  version: yup.string().defined(),
});
