import * as yup from "yup";
import {
  ProductServiceDTO,
  schemaProductServiceDTO,
} from "../../../shared/specific/DTOs/productService/ProductServiceDTO";
import { riseApi } from "./connection";

export const getProductServices = async (): Promise<ProductServiceDTO[]> => {
  const { data: productServices } = await riseApi.get("ProductServices");

  return yup
    .array(schemaProductServiceDTO)
    .defined()
    .validateSync(productServices);
};
