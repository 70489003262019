import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { InvoiceGroupDTO, schemaInvoiceGroupDTO } from "./InvoiceGroupDTO";

export type PaginatedInvoiceDTO = PaginationResponse<InvoiceGroupDTO>;

export const schemaPaginatedInvoiceDTO =
  getSchemaPaginationResponse<InvoiceGroupDTO>(
    schemaInvoiceGroupDTO as yup.Schema<InvoiceGroupDTO>
  );
