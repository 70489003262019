import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { AddIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import {
  EnhancedTableRef,
  EnhancedTable,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { setPageTitle } from "../../../../../../../services/applicationState/pageData.service";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useTableData } from "./hooks/useTableData";

export const ProjectTab = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("pipedrive.pages.main.title"));
  }, [t]);

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const {
    headCells,
    onTablePageChange,
    deactivationModal,
    changeDealTypeModal,
  } = useTableData({
    reloadTablePage,
    filters: {
      idProject: belowToolbarFilters.name,
      isAdditive: false,
    },
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, belowToolbarFilters],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.ProjectRegister,
                level: PermissionLevel.Create,
              }}
            >
              <Link to="/projects/create">
                <AddIconButton />
              </Link>
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 10,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {deactivationModal}
      {changeDealTypeModal}
    </>
  );
};
