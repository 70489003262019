import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "./context/ProfileContextProvider";
import { CenteredLoading } from "../../../../../shared/common/react/components/ui/CenteredLoading";
import { MyLocalityArea } from "./components/MyLocalityArea";
import { LanguageArea } from "./components/LanguageArea";
import { StyledAreasContainer } from "./shared/index.styles";
import { SkillsArea } from "./components/SkillsArea";
import { CertificationsArea } from "./components/CertificationsArea";
import { ResumeArea } from "./components/ResumeArea";

export const UserProfileWithInContextPage = () => {
  const { t } = useTranslation();
  const { userCollaboratorProfile, loading, error } =
    useContext(ProfileContext);

  if (loading) {
    return <CenteredLoading />;
  }

  if (error) {
    return <div>{t(error)}</div>;
  }

  if (!userCollaboratorProfile) return <div>Something happened. :(</div>;

  return (
    <StyledAreasContainer>
      <MyLocalityArea />
      <LanguageArea />
      <SkillsArea />
      <CertificationsArea />
      <ResumeArea />
    </StyledAreasContainer>
  );
};
