import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";
import { schemaUserCollaboratorDTO } from "../../../../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { schemaYearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CommonInvoiceRequestFormValues } from "../../../../types/data/form/values.types";
import { getNowBrazilianDate } from "../../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { InvoiceTypeEnum } from "../../../../../../../../../../../../shared/specific/enums/invoices/InvoiceTypeEnum";
import { InvoiceTypeTranslationCodeMap } from "../../../mappers/InvoiceTypeTranslationCodeMap";

export const useInvoiceRequestFormikConfig = () => {
  const { t } = useTranslation();

  const { project } = useProjectContext();

  const initialValues: CommonInvoiceRequestFormValues = useMemo(() => {
    return {
      invoices: [],
      customer: null,
      costCenterPep: null,
      invoiceType: {
        id: InvoiceTypeEnum.InvoiceNote,
        label: t(InvoiceTypeTranslationCodeMap[InvoiceTypeEnum.InvoiceNote]),
      },
      invoiceCompetence: [],
      selectedCompetence: null,
      limitDate: null,
      invoiceFinancialResponsible: [],
      invoiceFinancialResponsibleSelected: "",
      serviceDescription: "",
      launchInstructions: "",
      invoiceNote: "",
      invoiceCopyToUser: [],
      invoiceFilesToDelete: [],
      invoiceFilesToUpload: [],
      invoiceFilesList: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      invoices: yup.array().test({
        name: "teste",
        message: t("general.errors.data.fields.general.required"),
        test(value) {
          if (value && value.length < 1) return false;
          return true;
        },
      }),
      customer: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      costCenterPep: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      invoiceType: yup
        .object()
        .nullable()
        .test({
          name: "test",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!value) return false;
            return true;
          },
        }),
      invoiceCompetence: yup.array(schemaYearMonth),
      limitDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            const minDate = getNowBrazilianDate();
            minDate.setDate(1);
            minDate.setHours(0, 0, 0, 0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .required(t("general.errors.data.fields.general.required")),
      invoiceFinancialResponsible: yup.array(yup.string()),
      serviceDescription: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      launchInstructions: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      invoiceNote: yup.string().nullable(),
      invoiceCopyToUser: yup
        .array(schemaUserCollaboratorDTO)
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (value && value.length < 1) return false;
            return true;
          },
        }),
      selectedCompetence: yup
        .date()
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test() {
            if (this.parent.invoiceCompetence.length === 0) return false;
            return true;
          },
        }),
      invoiceFinancialResponsibleSelected: yup
        .string()
        .nullable()
        .when("invoiceFinancialResponsible", {
          is: (invoiceFinancialResponsible: string[]) => {
            const results = invoiceFinancialResponsible.map((email) => {
              const emailRegex = /^([^@]*@){1}[^@]*$/;
              return emailRegex.test(email);
            });

            return results.some((x) => !x);
          },
          then: (schema) =>
            schema.required(t("general.errors.data.fields.email.invalid")),
        })
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test() {
            return !(this.parent.invoiceFinancialResponsible.length === 0);
          },
        }),
    });
  }, [t, project.scheduledStartDate]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
