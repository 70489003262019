import { formatCurrency } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { StyledCenteredInfo, StyledReadOnlyCarouselCell } from "./index.styles";

interface OwnProps {
  costCenterPep: CostCenterPepOnlyDTO;
  amount?: number;
}

export const CarouselTotaMonthsContent = ({
  costCenterPep,
  amount,
}: OwnProps) => {
  return (
    <StyledReadOnlyCarouselCell>
      <StyledCenteredInfo>
        {formatCurrency({
          value: amount ?? 0,
          currencySymbol: costCenterPep.currencySymbol,
        })}
      </StyledCenteredInfo>
    </StyledReadOnlyCarouselCell>
  );
};
