/* eslint-disable react/no-this-in-sfc */
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesPepElement } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { generatePepElementReport } from "../../../../../../../../../services/reports/reports.service";
import { schemaProjectStatusOption } from "../../../../../../Projects/shared/types/data/form/fields/autocomplete/options/StatusOption.types";
import { FramesSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/frames/FramesSearchAutocompleteFormik";
import { ProjectStatusMultipleAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/shared/ProjectStatusMultipleAutocompleteFormik";

export const PepElement = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesPepElement>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesPepElement = {
      status: [],
      frame: null,
    };

    const validationSchema = yup.object({
      status: yup.array().of(schemaProjectStatusOption).nullable(),
      frame: yup.object().defined().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesPepElement,
    { setSubmitting }: FormikHelpers<FormValuesPepElement>
  ) => {
    try {
      await generatePepElementReport({
        Status: values.status?.map((status) => status.id) ?? [],
        Frame: values.frame?.id ?? null,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <FramesSearchAutocompleteFormik name="frame" />
            <ProjectStatusMultipleAutocompleteFormik
              name="status"
              autocompleteProps={{
                multiple: true,
              }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
          <LoadingBackdrop open={isSubmitting} />
        </StyledViewContent>
      )}
    </Formik>
  );
};
