import * as yup from "yup";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { CreateSkillDTO } from "../../../shared/specific/DTOs/skill/CreateSkillDTO";
import { SkillUpdateDTO } from "../../../shared/specific/DTOs/skill/SkillUpdateDTO";
import {
  PaginatedSkillDTO,
  schemaPaginatedSkillDTO,
} from "../../../shared/specific/DTOs/skill/PaginatedSkillDTO";
import {
  SkillDTO,
  schemaSkillDTO,
} from "../../../shared/specific/DTOs/skill/SkillDTO";
import { SkillFilterDTO } from "../../../shared/specific/DTOs/skill/filters/SkillFilterDTO";
import { riseApi } from "./connection";

export const getSkills = async ({
  filters,
  pagination,
}: {
  filters?: SkillFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedSkillDTO> => {
  const { data: skills } = await riseApi.get("/Skill", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedSkillDTO.validateSync(skills);
};

export const getLanguageSkills = async (): Promise<SkillDTO[]> => {
  const { data: languages } = await riseApi.get("/Skill/Languages");

  return yup.array(schemaSkillDTO).defined().validateSync(languages);
};

export const getSkillsDropdown = async (): Promise<SkillDTO[]> => {
  const { data: skills } = await riseApi.get("/Skill/SkillsDropdown");

  return yup.array(schemaSkillDTO).defined().validateSync(skills);
};

export const getSkillById = async (id: number): Promise<SkillDTO | null> => {
  const { data: skill } = await riseApi.get(`Skill/${id}`);

  return schemaSkillDTO.nullable().validateSync(skill);
};

export const createSkill = async (skill: CreateSkillDTO) => {
  await riseApi.post("Skill", skill);
};

export const deleteSkill = async (id: number, version: string) => {
  await riseApi.delete(`Skill/${id}/${version}`);
};

export const updateSkill = async (skill: SkillUpdateDTO) => {
  await riseApi.put("Skill", skill);
};
