import { useMemo } from "react";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { UseEditingCellFormContentButtonContentProps } from "../../useEditingCellForm";
import { StyledButtonBase, StyledCarouselCell } from "./index.styles";

interface OwnProps {
  EditingButtonContainer: (
    props: UseEditingCellFormContentButtonContentProps
  ) => JSX.Element | null;
  percentage?: number;
  allocationGroup: number;
  yearMonth: YearMonth | null;
}

export const CarouselContent = ({
  EditingButtonContainer,
  percentage,
  allocationGroup,
  yearMonth,
}: OwnProps) => {
  const formattedPercentage = useMemo(() => {
    if (!yearMonth) return null;

    return formatNumber(percentage ?? 0, {
      fractionDigits: 2,
      suffix: "%",
    });
  }, [percentage]);

  if (!yearMonth) return null;

  return (
    <EditingButtonContainer
      allocationGroup={allocationGroup}
      yearMonth={yearMonth}
    >
      <StyledButtonBase>
        <StyledCarouselCell>
          <div>{formattedPercentage}</div>
        </StyledCarouselCell>
      </StyledButtonBase>
    </EditingButtonContainer>
  );
};
