import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material";
import { TextField, Tooltip } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { Week } from "../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { formatMinutesToHourMinute } from "../../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { TimesheetMonthDayAllEntriesDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDayAllEntriesDTO";
import { DayNumber } from "./styles/general.styles";
import { StyledCalendarCell } from "./styles/StyledCalendarCell.styles";

interface OwnProps {
  day: number;
  yearMonth: YearMonth;
  timesheetsMonth: TimesheetMonthDayAllEntriesDTO;
}

export const CalendarCell = ({ day, yearMonth, timesheetsMonth }: OwnProps) => {
  const { t } = useTranslation();

  const matchingTimesheetEntry = useMemo(() => {
    return timesheetsMonth.monthDays.find(
      (x) => x.entryDate.toDate().getDate() === day
    );
  }, []);

  const formattedHours = useMemo(() => {
    if (!matchingTimesheetEntry) return "00:00";
    return formatMinutesToHourMinute(matchingTimesheetEntry.minutes);
  }, [timesheetsMonth, day, matchingTimesheetEntry]);

  const isWeekend = useMemo(() => {
    const weekDay = yearMonth.toDate(day).getDay();
    return weekDay === Week.Saturday || weekDay === Week.Sunday;
  }, [day, yearMonth]);

  const dayIcon = useMemo(() => {
    const dayEntryMinutes = matchingTimesheetEntry?.minutes ?? 0;

    if (isWeekend) return <div />;

    if (dayEntryMinutes > timesheetsMonth.workloadMinutes)
      return (
        <Tooltip
          title={t(
            "timesheets.info.data.calendar.hoursGreaterThanWorkloadOfHours",
            {
              hours: formatMinutesToHourMinute(timesheetsMonth.workloadMinutes),
            }
          )}
          placement="top"
        >
          <ArrowDropUpIcon color="error" />
        </Tooltip>
      );

    if (dayEntryMinutes < timesheetsMonth.workloadMinutes)
      return (
        <Tooltip
          title={t(
            "timesheets.info.data.calendar.hoursLessThanWorkloadOfHours",
            {
              hours: formatMinutesToHourMinute(timesheetsMonth.workloadMinutes),
            }
          )}
          placement="top"
        >
          <ArrowDropDownIcon color="error" />
        </Tooltip>
      );

    return <div />;
  }, [
    matchingTimesheetEntry?.minutes,
    timesheetsMonth.workloadMinutes,
    isWeekend,
  ]);

  return (
    <StyledCalendarCell isWeekend={isWeekend}>
      <DayNumber>{day}</DayNumber>
      {dayIcon}
      <TextField value={formattedHours} InputProps={{ readOnly: true }} />
    </StyledCalendarCell>
  );
};
