import { styled } from "@mui/material";

export const StyledBelowToolbarFilters = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > :last-child {
    max-width: 120px;
  }
`;
