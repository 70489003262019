import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { getMainCostCenterPepsOnlyDropdown } from "../../../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { AsyncAutocompleteProps } from "../../../../../../../../common/react/components/form/general/autocomplete/general/AsyncAutocomplete";
import { AsyncAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CostCenterPepOnlyDTO } from "../../../../../../../DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CostCenterPepDropdownFilter } from "../../../../../../../DTOs/costCenterPeps/filters/CostCenterPepDropdownFilter";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncAutocompleteProps<
    CostCenterPepOnlyDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  filters?: CostCenterPepDropdownFilter;
  filtersMemo: CostCenterPepDropdownFilter;
}

const cachedPepCodes = new CachedTranslatedData({
  callback: () => getMainCostCenterPepsOnlyDropdown({}),
  defaultCacheDuration: 600000, // 1 hour
});

export const CostCenterPepsOnlyAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filtersMemo]);

  const internalGetCostCenterPeps = useCallback(async () => {
    if (!finalFilters.idsProjects || finalFilters.idsProjects.length === 0)
      throw new Error("'idProject' must be provided.");

    const options = await cachedPepCodes.run();

    return options.filter((opt) =>
      finalFilters.idsProjects?.includes(opt.project.id)
    );
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPeps"
      )}
      fetchOptions={internalGetCostCenterPeps}
      textfieldProps={{
        label: t("costCenterPeps.keywords.general.costCenterPeps"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (costCenterPep) => costCenterPep.pepCode,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
