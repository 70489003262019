import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  FillPageCollapseTable,
  HeaderComponentProps,
  RowComponentProps,
} from "../../../../../../../../shared/common/react/components/table/CollapseTable/shared/types/data.types";
import { FramePepDTO } from "../../../../../../../../shared/specific/DTOs/frame/FramePepDTO";
import { Cell } from "../components/Cell";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CustomLink } from "../../../../../../../../shared/common/react/components/navigation/CustomLink";
import { ViewIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";

interface OwProps {
  framePepData: FramePepDTO | null;
}

export const useTableData = ({ framePepData }: OwProps) => {
  const { t, i18n } = useTranslation();

  const headCells = useMemo(() => {
    const headCells: HeaderComponentProps[] = [
      {
        header: "",
      },
      {
        header: "",
      },
      {
        header: "",
      },
      {
        header: "",
      },
      {
        header: "",
      },
      {
        header: "",
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: FillPageCollapseTable = useCallback(async () => {
    if (!framePepData) return [];

    const header = [
      <Cell info={t("general.keywords.fields.name")} isBold />,
      <Cell info={t("additives.keywords.fields.scheduledStart")} isBold />,
      <Cell info={t("additives.keywords.fields.scheduledEnd")} isBold />,
      <Cell info={t("projects.keywords.fields.projectValue")} isBold />,
      <Cell info={t("projects.keywords.fields.additiveValue")} isBold />,
      <Cell info={t("general.keywords.fields.total")} isBold />,
      <Cell info="" isBold />,
    ];

    const data: RowComponentProps[] = framePepData.pepCodeProjects.map(
      (pep) => ({
        mainInformation: [
          <Cell info={`${pep.pepCode} - ${pep.costCenterCode}`} isBold />,
          <Cell info="" />,
          <Cell info="" />,
          <Cell info="" />,
          <Cell info="" />,
          <Cell
            info={formatNumber(pep.total, {
              fractionDigits: 2,
              prefix: framePepData.subsidiary.currencySymbol,
            })}
            isBold
          />,
          <Cell info="" isBold />,
        ],
        extensibleInformation: [
          {
            info: header,
          },
          ...pep.projects.map((project) => ({
            info: [
              <Cell info={project.name} />,
              <Cell
                info={project.scheduledStartDate?.toLocaleDateString() ?? "-"}
              />,
              <Cell
                info={project.scheduledEndDate?.toLocaleDateString() ?? "-"}
              />,
              <Cell
                info={formatNumber(project.billingAmount, {
                  fractionDigits: 2,
                  prefix: framePepData.subsidiary.currencySymbol,
                })}
              />,
              <Cell
                info={formatNumber(project.totalAdditivesAmount, {
                  fractionDigits: 2,
                  prefix: framePepData.subsidiary.currencySymbol,
                })}
              />,
              <Cell
                info={formatNumber(project.total, {
                  fractionDigits: 2,
                  prefix: framePepData.subsidiary.currencySymbol,
                })}
              />,
              <Cell
                info={
                  <CustomLink to={`/projects?name=${project.name}`}>
                    <ViewIconButton />
                  </CustomLink>
                }
                isBold
              />,
            ],
          })),
        ],
      })
    );
    return data;
  }, [t, i18n, framePepData]);

  return {
    headCells,
    onPageChange,
  };
};
