import { useTranslation } from "react-i18next";
import { EditingIssuedDateFormValues } from "../shared/form.types";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { StyledForm } from "../index.styles";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { InvoicesStatusEnum } from "../../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";

interface OwnProps
  extends Omit<
    FormContentProps<EditingIssuedDateFormValues>,
    "internalFormData"
  > {
  mode?: string;
  internalFormData: InvoiceDTO;
}

export const CommonFormEditingCell = ({
  formikProps: { values, isSubmitting, setFieldValue },
  mode,
  internalFormData,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <DatePickerFormik
        name="dueDate"
        datePickerProps={{
          disabled: isSubmitting,
          label: t("invoice.keywords.fields.dueDate"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
        }}
      />
      <DatePickerFormik
        name="issuedDate"
        datePickerProps={{
          disabled: isSubmitting,
          label: t("invoice.keywords.fields.emissionDate"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
        }}
      />
      {(internalFormData.status === InvoicesStatusEnum.PaidOut ||
        internalFormData.paymentDate) && (
        <DatePickerFormik
          name="paymentDate"
          datePickerProps={{
            disabled: isSubmitting,
            label: t("invoice.keywords.fields.paymentDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
          }}
        />
      )}
      {(internalFormData.status ===
        InvoicesStatusEnum.ProvisionForCancellation ||
        internalFormData.negativeInvoicesCancellationReference.length > 1) && (
        <DatePickerFormik
          name="provisionCancellationDate"
          datePickerProps={{
            disabled: isSubmitting,
            label: t("invoice.keywords.fields.provisionCancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
          }}
        />
      )}
      {internalFormData.status === InvoicesStatusEnum.Canceled && (
        <DatePickerFormik
          name="totalCancellationDate"
          datePickerProps={{
            disabled: isSubmitting,
            label: t("invoice.keywords.fields.cancellationDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
          }}
        />
      )}
    </StyledForm>
  );
};
