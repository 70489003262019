import { TextField } from "@mui/material";
import { FocusEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface BelowToolbarFilters {
  name?: string;
}

const TIME_BEFORE_UPDATING_FILTERS = 500;

export const useBelowToolbarFilters = () => {
  const { t } = useTranslation();

  const [belowToolbarFilters, setBelowToolbarFilters] =
    useState<BelowToolbarFilters>({
      name: undefined,
    });

  const filtersUpdateTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);
  }, []);

  const belowToolbarFiltersComponent = useMemo(() => {
    const onChange = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);

      filtersUpdateTimeoutRef.current = setTimeout(
        () =>
          setBelowToolbarFilters((belowToolbarFilters) => ({
            ...belowToolbarFilters,
            name: event.target.value,
          })),
        TIME_BEFORE_UPDATING_FILTERS
      ) as unknown as number;
    };

    return (
      <TextField
        label={t("accessGroups.keywords.fields.searchCollaborator")}
        fullWidth
        onChange={onChange}
        autoFocus
      />
    );
  }, [t, belowToolbarFilters]);

  return { belowToolbarFilters, belowToolbarFiltersComponent };
};
