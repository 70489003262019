import { BrowserRouter } from "react-router-dom";
import { MainRoutes } from "./MainRoutes";

export const MainRouter = () => {
  return (
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
  );
};
