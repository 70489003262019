import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCustomerBalanceForm } from "../../shared/react/CommonCustomerBalanceForm";
import { CustomerBalanceCreateDTO } from "../../../../../../../shared/specific/DTOs/customerBalance/CustomerBalanceCreateDTO";
import { createCustomerBalance } from "../../../../../../../services/customerBalance/customerBalance.service";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikConfig } from "../../shared/react/shared/hooks/data/validation/useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("customerBalance.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ ...rest }) => (
          <CommonCustomerBalanceForm formMode="create" {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (
            !formValues.amount ||
            !formValues.costCenter ||
            !formValues.yearMonth ||
            !formValues.type?.id
          )
            throw new Error("Required values are missing.");

          try {
            const created: CustomerBalanceCreateDTO = {
              amount: parseLocaleNumber(formValues.amount),
              idCostCenter: formValues.costCenter.id,
              yearMonth: YearMonth.createFromDate(formValues.yearMonth),
              customerTrigram: formValues.customerTrigram,
              type: formValues.type?.id,
            };

            await createCustomerBalance(created);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "customerBalance.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
