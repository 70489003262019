import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/general.types";
import { MaskedCodeFormik } from "../../../shared/react/components/form/formik/input/textField/MaskedCodeFormik";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (ownProps: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <MaskedCodeFormik name="code" />
      <TextFieldFormik
        name="corporateName"
        label={t("customers.keywords.fields.corporateName")}
      />
      <TextFieldFormik
        name="tradingName"
        label={t("customers.keywords.fields.tradingName")}
      />
      <TextFieldFormik
        name="registrationCode"
        label={t("customers.keywords.fields.registrationCodeBoth")}
      />
      <TextFieldFormik
        name="municipalCode"
        label={t("customers.keywords.fields.municipalCode")}
      />
      <TextFieldFormik
        name="stateCode"
        label={t("customers.keywords.fields.stateCode")}
      />
    </StyledForm>
  );
};
