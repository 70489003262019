import { RestrictionsOperand } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { OrPermissions } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/OrPermissions";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";

export const allocationsMapReadRestrictions: RestrictionsOperand = {
  type: PermissionType.AllocationsMap,
  level: PermissionLevel.Read,
};

export const collaboratorReadRestrictions: RestrictionsOperand = {
  type: PermissionType.UserCollaborator,
  level: PermissionLevel.Read,
};

export const contractRoleReadRestrictions: RestrictionsOperand = {
  type: PermissionType.ContractRole,
  level: PermissionLevel.Read,
};

export const disciplinesReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Discipline,
  level: PermissionLevel.Read,
};

export const skillCategoriesReadRestrictions: RestrictionsOperand = {
  type: PermissionType.SkillCategory,
  level: PermissionLevel.Read,
};

export const corporateSegmentReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CorporateSegment,
  level: PermissionLevel.Read,
};

export const workloadsCreateRestrictions: RestrictionsOperand = {
  type: PermissionType.Workload,
  level: PermissionLevel.Read,
};

export const peopleAndManagementReadRestrictions = new OrPermissions(
  contractRoleReadRestrictions,
  collaboratorReadRestrictions,
  allocationsMapReadRestrictions,
  workloadsCreateRestrictions,
  disciplinesReadRestrictions
);
