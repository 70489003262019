import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CustomersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomersSearchAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { FilterValues } from "../shared/types/general.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (ownProps: OwnProps) => {
  return (
    <StyledForm>
      <CustomersSearchAutocompleteFormik name="customer" />
      <DivisionsAutocompleteFormik name="division" />
    </StyledForm>
  );
};
