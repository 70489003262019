import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FormFilters } from "../../shared/types/form.types";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { InvoicesStatusAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/invoices/InvoicesStatusAutocompleteFormik";
import {
  StyledButtons,
  StyledForm,
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "./index.styles";
import { AutocompleteInfoBubble } from "../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";
import { InvoicesStatusEnum } from "../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { DivisionsAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { InvoicesFilterContext } from "../../../shared/context/InvoicesFilterContext";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../../shared/specific/maps/invoices/invoicesStatusTypeToTranslationCode";

export const useFilterForm = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormFilters>>(null);
  const { filterValues } = useContext(InvoicesFilterContext);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormFilters = {
      subsidiary: filterValues.subsidiary,
      projectCode: filterValues.projectCode,
      status:
        filterValues.status.length > 0
          ? filterValues.status
          : [
              {
                id: InvoicesStatusEnum.Released,
                label: t(
                  invoicesStatusTypeToTranslationCode[
                    InvoicesStatusEnum.Released
                  ]
                ),
              },
            ],
      responsible: filterValues.responsible,
      requester: filterValues.requester,
      limmitDate: filterValues.limmitDate,
      division: filterValues.division,
      invoiceNumber: filterValues.invoiceNumber,
    };

    const validationSchema = yup.object({
      subsidiary: yup.object().nullable(),
      division: yup.object().nullable(),
      projectCode: yup.string().nullable(),
      status: yup.array(yup.object()),
      responsible: yup.object().nullable(),
      requester: yup.object().nullable(),
      invoiceNumber: yup.string().nullable(),
      limmitDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const [invoicesFilter, setInvoicesFilter] =
    useState<FormFilters>(initialValues);

  const formikOnSubmit = async (values: FormFilters) =>
    setInvoicesFilter(values);

  const FormComponent = useMemo(() => {
    return (
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => (
          <StyledForm>
            <DivisionsAutocompleteFormik
              name="division"
              filtersMemo={{
                hasPrefix: true,
              }}
            />
            <SubsidiariesAutocompleteFormik
              name="subsidiary"
              autocompleteProps={{ disabled: isSubmitting }}
            />
            <TextFieldFormik
              name="projectCode"
              label={t("general.keywords.fields.code")}
            />
            <InvoicesStatusAutocompleteFormik
              name="status"
              blockedStatus={[
                InvoicesStatusEnum.NotReleased,
                InvoicesStatusEnum.AmountCanceled,
              ]}
              autocompleteProps={{
                disableCloseOnSelect: true,
                multiple: true,
                renderTags: (value) => {
                  return (
                    <StyledName>
                      <StyledIcon
                        title={
                          <div>
                            {value.map((x, i) => (
                              <div key={i}>{x.label}</div>
                            ))}
                          </div>
                        }
                        arrow
                      >
                        <AutocompleteInfoBubble>
                          {value.length > 0 ? `+ ${value.length}` : ""}
                        </AutocompleteInfoBubble>
                      </StyledIcon>
                    </StyledName>
                  );
                },
                renderOption: (props, option) => {
                  return (
                    <Box
                      component="li"
                      {...props}
                      key={option.id}
                      style={{
                        borderBottom: "solid #c0c0c0 1px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "5px",
                      }}
                    >
                      <StyledMultipleSelectionContainer>
                        {props["aria-selected"] ? (
                          <CheckBoxIcon color="primary" fontSize="small" />
                        ) : (
                          <CheckBoxOutlineBlankIcon
                            color="primary"
                            fontSize="small"
                          />
                        )}

                        {option.label}
                      </StyledMultipleSelectionContainer>
                    </Box>
                  );
                },
              }}
            />
            <UsersSearchAutocompleteFormik
              name="responsible"
              textfieldProps={{
                label: t("invoice.keywords.fields.responsible"),
              }}
              filtersMemo={{ isActive: true }}
            />
            <UsersSearchAutocompleteFormik
              name="requester"
              textfieldProps={{
                label: t("invoice.keywords.fields.requester"),
              }}
              filtersMemo={{ isActive: true, isCollaborator: true }}
            />
            <TextFieldFormik
              name="invoiceNumber"
              label={t("invoice.keywords.fields.invoiceNumber")}
            />
            <DatePickerFormik
              name="limmitDate"
              datePickerProps={{
                label: t("invoice.keywords.fields.latestDeadline"),
                slotProps: {
                  popper: {
                    placement: "left",
                  },
                },
              }}
            />
            <StyledButtons>
              <Button
                onClick={submitForm}
                variant="contained"
                disabled={
                  isSubmitting || Object.values(values).every((x) => !x)
                }
              >
                {t("general.actions.general.search")}
              </Button>
              <Button
                onClick={() => {
                  setFieldValue("subsidiary", null);
                  setFieldValue("projectCode", "");
                  setFieldValue("status", null);
                  setFieldValue("responsible", null);
                  setFieldValue("limmitDate", null);
                  setFieldValue("division", null);
                  setFieldValue("requester", null);
                  setFieldValue("invoiceNumber", null);
                  return setInvoicesFilter({
                    subsidiary: null,
                    projectCode: "",
                    status: [],
                    responsible: null,
                    limmitDate: null,
                    division: null,
                    requester: null,
                    invoiceNumber: null,
                  });
                }}
              >
                {t("general.actions.general.clear")}
              </Button>
            </StyledButtons>
          </StyledForm>
        )}
      </Formik>
    );
  }, [t, invoicesFilter]);

  return { FormComponent, invoicesFilter };
};
