import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useTableData } from "./hooks/useTableData";
import { Legend } from "./shared/react/components/Legend";
import { FilterValues } from "./hooks/FormFilter/types/general.types";
import { FormFilter } from "./hooks/FormFilter";
import { AllocationMapFilterDTO } from "../../../../../shared/specific/DTOs/allocationMap/filters/AllocationMapFilterDTO";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { SimpleIconButton } from "../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";

export const AllocationMapWithinContext = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const [allocationMapFilter, setAllocationMapFilter] =
    useState<FilterValues | null>();

  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(true);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("allocationsMap.pages.main.title"));
  }, [t]);

  const filterObject = useMemo(() => {
    const filter: AllocationMapFilterDTO = {
      onlyAllocatedCollaborators:
        allocationMapFilter?.onlyAllocatedCollaborators &&
        allocationMapFilter?.onlyAllocatedCollaborators.length > 0
          ? allocationMapFilter?.onlyAllocatedCollaborators.map((x) => x.id)
          : [],
      idDivision: allocationMapFilter?.division
        ? allocationMapFilter?.division.id
        : undefined,
      idSegment: allocationMapFilter?.segment
        ? allocationMapFilter?.segment.id
        : undefined,
      idUserCollaborator: allocationMapFilter?.collaborator
        ? allocationMapFilter?.collaborator.id
        : undefined,
      endDate: allocationMapFilter?.endDate
        ? YearMonth.createFromDate(allocationMapFilter?.endDate)
        : undefined,
      startDate: allocationMapFilter?.startDate
        ? YearMonth.createFromDate(allocationMapFilter?.startDate)
        : undefined,
      idsContractRoles:
        allocationMapFilter?.contractRoles &&
        allocationMapFilter?.contractRoles.length > 0
          ? allocationMapFilter?.contractRoles.map((x) => x.id)
          : undefined,
      idsDiscipline:
        allocationMapFilter?.discipline &&
        allocationMapFilter?.discipline.length > 0
          ? allocationMapFilter?.discipline.map((x) => x.id)
          : undefined,
      idManager: allocationMapFilter?.manager
        ? allocationMapFilter?.manager.id
        : undefined,
      idCoordinator: allocationMapFilter?.coordinator
        ? allocationMapFilter?.coordinator.id
        : undefined,
      idProject: allocationMapFilter?.project
        ? allocationMapFilter?.project.id
        : undefined,
    };

    return filter;
  }, [allocationMapFilter]);

  const { headCells, onTablePageChange, editingModal } = useTableData({
    reloadTablePage,
    modalFilters: filterObject,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filterObject, allocationMapFilter, i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <FormFilter
        setAllocationMapFilter={setAllocationMapFilter}
        filterIsOpen={filterIsOpen}
        setFilterIsOpen={setFilterIsOpen}
      />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons: (
            <SimpleIconButton
              Icon={FilterListIcon}
              color={filterIsOpen ? "info" : "inherit"}
              tooltipText={
                filterIsOpen
                  ? t("general.actions.general.closeFilter")
                  : t("general.actions.general.openFilter")
              }
              onClick={() => setFilterIsOpen(!filterIsOpen)}
            />
          ),
        }}
        footerSettings={{
          content: <Legend />,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 50,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
      />
      {editingModal}
    </>
  );
};
