import { TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import { getCustomersNames } from "../../../../../../../../../services/pipedriveDeal/pipedriveDeal.service";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    string,
    DisableClearable
  >["autocompleteProps"];
  shouldReplaceValueOnMount?: boolean;
}

const cachedClientAccounts = new CachedTranslatedData({
  callback: getCustomersNames,
  defaultCacheDuration: 600000, // 10 minutes
});

export const CustomersNamesAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount = true,
}: OwnProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const getCustomersNames = useCallback(async () => {
    const skills = await cachedClientAccounts.run();

    return skills;
  }, []);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "projection.errors.data.fetch.failedToLoadClientAccount"
      )}
      fetchOptions={getCustomersNames}
      textfieldProps={{
        label: t("general.keywords.fields.clientAccount"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x,
        isOptionEqualToValueMemo: (option, value) => option === value,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
      rerunOnDeps={[i18n.language]}
    />
  );
};
