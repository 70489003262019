import { UserPermissionDTO } from "../../../../DTOs/user/UserPermissionDTO";
import {
  AbstractPermissionsOperator,
  RestrictionsOperand,
} from "./AbstractPermissionsOperator";

export class OrPermissions extends AbstractPermissionsOperator {
  private permissions: RestrictionsOperand[];

  constructor(...permissions: RestrictionsOperand[]) {
    super();
    this.permissions = permissions;
  }

  public checkPermissions({
    userPermissions,
    isAdmin,
  }: {
    userPermissions: UserPermissionDTO[];
    isAdmin: boolean;
  }) {
    return this.permissions.some((permission) =>
      AbstractPermissionsOperator.checkPermission({
        isAdmin,
        permission,
        userPermissions,
      })
    );
  }
}
