import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { DisciplineDTO, schemaDisciplineDTO } from "./DisciplineDTO";

export type PaginatedDisciplineDTO = PaginationResponse<DisciplineDTO>;

export const schemaPaginatedDisciplineDTO =
  getSchemaPaginationResponse<DisciplineDTO>(
    schemaDisciplineDTO as yup.Schema<DisciplineDTO>
  );
