import { ReactNode } from "react";
import { toast, ToastOptions } from "react-toastify";

const DEFAULT_WARNING = 3000;

const toastConfig: ToastOptions = {
  position: "top-right",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const notifySuccess = (
  message: ReactNode,
  timeout = DEFAULT_WARNING
) => {
  toast.success(message, {
    ...toastConfig,
    autoClose: timeout,
  });
};

export const notifyError = (message: ReactNode, timeout = DEFAULT_WARNING) => {
  toast.error(message, {
    ...toastConfig,
    autoClose: timeout,
  });
};

export const notifyWarning = (
  message: ReactNode,
  timeout = DEFAULT_WARNING
) => {
  toast.warn(message, {
    ...toastConfig,
    autoClose: timeout,
  });
};
