import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { WorkloadsDataForm } from "../shared/components/WorkloadsDataForm";
import { WorkloadCreateDTO } from "../../../../../shared/specific/DTOs/workload/WorkloadCreateDTO";
import { createWorkload } from "../../../../../services/workloads/workloads.service";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export function useCreationForm({ reloadTablePage }: OwnProps) {
  const { t } = useTranslation();
  const { initialValues, validationSchema } =
    useCreationEditingDefaultFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("workloads.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig: {
          initialValues,
          validationSchema,
        },
        FormContentMemo: ({ ...rest }) => (
          <WorkloadsDataForm mode="creation" {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const createdWorkload: WorkloadCreateDTO = {
              name: formValues.name,
            };

            await createWorkload(createdWorkload);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "workloads.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
}
