import {
  useFormattedCurrency,
  UseFormattedCurrencyProps as FormattedCurrencyProps,
} from "../../../../hooks/data/formatters/numbers/useFormattedCurrency";

export type { FormattedCurrencyProps };

export const FormattedCurrency = (props: FormattedCurrencyProps) => {
  const result = useFormattedCurrency(props);
  return <>{result}</>;
};
