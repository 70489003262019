import * as yup from "yup";

import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import {
  CostCenterTranslationDTO,
  schemaCostCenterTranslationDTO,
} from "./translation/CostCenterTranslationDTO";

export interface CostCenterDTO {
  id: number;
  code: string;
  name: string;
  deactivatedDate: Date | null;
  division: DivisionDTO;
  subsidiary: SubsidiaryDTO;
  version: string;
  costCenterTranslations: CostCenterTranslationDTO[];
  isLinkedToProject: boolean;
}

export const schemaCostCenterDTO = yup.object({
  id: yup.number().integer().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  deactivatedDate: yup.date().nullable().defined(),
  division: schemaDivisionDTO.defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  version: yup.string().defined(),
  costCenterTranslations: yup.array(schemaCostCenterTranslationDTO).defined(),
  isLinkedToProject: yup.bool().defined(),
});
