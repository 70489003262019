import { styled } from "@mui/material";

export const StyledWarning = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: ${({ theme }) => theme.palette.error.main};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const StyledFormContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;
