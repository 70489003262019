import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModalButton } from "../../../../common/react/hooksWithComponents/modal/modalButtons/useModalButton";

export const useGetLastVersion = () => {
  const { t } = useTranslation();
  const { contentModal: useGetLastVersionModal, openModal } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("general.errors.data.general.versionError"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        return (
          <div>
            <h4>{t("general.errors.data.general.needGetLastVersion")}</h4>
          </div>
        );
      },
      ModalActionsMemo: () => {
        const { t } = useTranslation();

        return (
          <Button
            variant="contained"
            onClick={() => {
              const lastVersion =
                window.localStorage.getItem("RiseLastVersion");
              window.localStorage.setItem(
                "RiseCurrentVersion",
                lastVersion || ""
              );
              return window.location.reload();
            }}
          >
            {t("general.actions.general.update")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: () => {
        return <Button />;
      },
    },
  });
  return {
    useGetLastVersionModal,
    openModal,
  };
};
