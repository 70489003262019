interface Interpolation {
  [key: string]: unknown;
}

type Components = { [key: string]: JSX.Element };

export class TranslatedError extends Error {
  public readonly interpolation?: Interpolation;
  public readonly components?: { [key: string]: JSX.Element };

  constructor(
    message: string,
    interpolation?: Interpolation,
    components?: Components,
    options?: ErrorOptions
  ) {
    super(message, options);
    this.interpolation = interpolation;
    this.components = components;
  }
}
