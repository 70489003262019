import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ActivityErrorDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityErrorDTO";

interface OwnProps {
  errorMessages: ActivityErrorDTO[];
  modalTitle: string;
  reloadTablePage: () => void;
}

export const useActivityManyError = ({
  reloadTablePage,
  errorMessages,
  modalTitle,
}: OwnProps) => {
  const { contentModal, openModal } = useModalButton({
    modal: {
      modalColorVariant: "error",
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      ModalTitle: () => {
        return (
          <Trans
            i18nKey="activities.modal.data.errors.title"
            values={{ name: modalTitle }}
          />
        );
      },
      ModalContent: () => {
        const { t } = useTranslation();
        return (
          <div>
            {errorMessages.map((x, index) => (
              <li key={index}>
                {t("activities.keywords.fields.activitiesDescription")}:{" "}
                {x.description} {t("general.keywords.fields.value")}: {x.amount}{" "}
                {t("activities.modal.data.errors.errorMessage")}:{" "}
                {t(x.errorMessage)}
              </li>
            ))}
          </div>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button
            variant="text"
            color="error"
            onClick={() => {
              reloadTablePage();
              closeModal();
            }}
          >
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: () => {
        return <Button />;
      },
    },
  });
  return {
    contentModal,
    openModal,
  };
};
