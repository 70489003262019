import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getAccessGroups } from "../../../../../../services/accessGroups/accessGroups.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { BelowToolbarFilters } from "../shared/types/data/data.filters";
import { useAccessGroupManagementModal } from "./useAccessGroupsManagementModal";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useUserGroup } from "./useUserGroup";

interface OwnProps {
  filters: BelowToolbarFilters;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { GroupAccessContentButton, ManageGroupAccessModal } =
    useAccessGroupManagementModal({ reloadTablePage });
  const { UserGroupButtonContainer, userGroupModal } = useUserGroup();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      {
        value: "",
        width: 192,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        const headKeys = ["name"];

        try {
          const {
            tableData: accessGroupsData,
            page,
            totalCount,
          } = await getAccessGroups({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[0] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = accessGroupsData.map(
            (accessGroupsData): ExternalEnhancedRow => ({
              id: accessGroupsData.id,
              cells: [
                accessGroupsData.name,
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.AuthorizationControl,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer
                          accessGroup={accessGroupsData}
                        />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.AuthorizationControl,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <GroupAccessContentButton {...accessGroupsData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.AuthorizationControl,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <UserGroupButtonContainer
                          accessGroup={accessGroupsData}
                        />
                      </Protected>

                      <Protected
                        restrictions={{
                          type: PermissionType.AuthorizationControl,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...accessGroupsData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );
  return {
    headCells,
    onTablePageChange,
    editingModal,
    deletionModal,
    ManageGroupAccessModal,
    userGroupModal,
  };
};
