import { Outlet } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { Sidebar } from "./Sidebar";
import { MainLayoutContainer } from "./index.styles";
import { TopBar } from "./TopBar";
import { LoggedLayoutContext } from "./shared/LoggedLayoutContext";
import { LoggedLayoutValues } from "./shared/LoggedLayoutContext/index.interface";
import {
  getStoredSidebarState,
  setStoredSidebarState,
} from "../../../services/applicationState/sidebar.service";
import { useWindowSize } from "../../../shared/common/react/hooks/ui/useWindowSize";

export const LoggedLayout = () => {
  const { isBreakpointSm } = useWindowSize();
  const [isMenuOpen, setIsMenuOpen] = useState(() => getStoredSidebarState());

  useEffect(() => {
    if (!isBreakpointSm) return;

    setStoredSidebarState(false);
    setIsMenuOpen(false);
  }, []);

  const defaultContextValues = useMemo(() => {
    return {
      isMenuOpen,
      toggleIsMenuOpen: () => {
        setStoredSidebarState(!isMenuOpen);
        setIsMenuOpen(!isMenuOpen);
      },
    } as LoggedLayoutValues;
  }, [isMenuOpen]);

  return (
    <LoggedLayoutContext.Provider value={defaultContextValues}>
      <MainLayoutContainer>
        <Sidebar />
        <TopBar />
        <div>
          <Outlet />
        </div>
      </MainLayoutContainer>
    </LoggedLayoutContext.Provider>
  );
};
