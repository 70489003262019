import { styled } from "@mui/material/styles";

interface StyledFixedColumnProps {
  isread: string;
}

export const StyledFixedColumn = styled("div")<StyledFixedColumnProps>`
  width: 100%;
  height: 60px;
  border-left: solid 3px rgba(224, 224, 224, 1);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ isread, theme }) =>
    isread === "true" ? "#f1f1f1" : "white"};
`;
