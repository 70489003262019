import { useContext, useMemo } from "react";
import { TableContext } from "../shared/contexts/TableContext";
import { StyledEnhancedTableBelowToolbar } from "./index.styles";

export const EnhancedTableBelowToolbar = () => {
  const { belowToolbar } = useContext(TableContext);

  const belowToolbarContent = useMemo(() => {
    if (!belowToolbar?.content) return null;

    return (
      <StyledEnhancedTableBelowToolbar>
        {belowToolbar.content}
      </StyledEnhancedTableBelowToolbar>
    );
  }, [belowToolbar?.content]);

  return belowToolbarContent;
};
