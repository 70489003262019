import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { deleteAdditive } from "../../../../../../../../../services/additives/additives.service";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { AdditiveDTO } from "../../../../../../../../../shared/specific/DTOs/additive/AdditiveDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { FormContent } from "./FormContent/index.tsx";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const { reloadProject } = useProjectContext();

  const { ModalButtonContainer: DeleteButton, contentModal: deletionModal } =
    useActionButtonDialog<AdditiveDTO, AdditiveDTO>({
      modal: {
        modalColorVariant: "error",
        modalTitle: t("additives.modal.data.delete.title"),
        ModalContent: FormContent,
        internalLoadingPosition: "title",
      },
      button: {
        ModalButtonMemo: ({ onClick: openModal }) => (
          <DeleteIconButton onClick={openModal} />
        ),
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: additive,
          setInternalModalData,
        }) => {
          setInternalModalData(additive);
        },
      },
      defaultDialogActionsProps: {
        confirmButtonLabel: t("general.actions.general.delete"),
        onConfirmMemo: async ({ closeModal, internalModalData: additive }) => {
          if (!additive) throw new Error("Additive cannot be null.");

          try {
            await deleteAdditive(additive.id, additive.version);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );

            closeModal();
            reloadProject();
            reloadTablePage();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "additives.errors.data.delete.failedToDeleteAdditive",
            });
          }
        },
      },
    });

  return { DeleteButton, deletionModal };
};
