import i18next from "i18next";
import { Language } from "../../../../enums/locale/Language.enum";
import { getDecimalSeparator, getThousandSeparator } from "./general.helpers";

export const parseLocaleNumber = (
  stringNumber: string,
  locale: Language | "current" = "current"
) => {
  if (locale === "current") {
    locale = i18next.language as Language;
  }
  const thousandSeparator = getThousandSeparator(locale);
  const decimalSeparator = getDecimalSeparator(locale);

  return parseFloat(
    stringNumber
      .replaceAll(thousandSeparator, "")
      .replaceAll(decimalSeparator, ".")
  );
};
