import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback } from "react";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import {
  getActivityById,
  updateActivityDeprovision,
} from "../../../../../../../../services/activities/activities.service";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CommonDeprovisionFormValues } from "../shared/types/data/form/values.types";
import { ProjectDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ActivityDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { EditIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { CommonDeprovisionForm } from "../shared/react/components/content/CommonDeprovisionForm";
import { useDeprovisionFormikConfig } from "../shared/react/hooks/data/validation/useDeprovisionFormikConfig";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { ActivityDeprovisionUpdateDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDeprovisionUpdateDTO";
import { getNowBrazilianDateOnly } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { checkIsDateAfterProject } from "../../../../shared/helpers/DTOs/project/general.helpers";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

interface InternalFormValues {
  project: ProjectDTO | null;
  activity: ActivityDTO | null;
  maxScheduledDate: Date | null;
}

export const useDeprovisionEditingForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useDeprovisionFormikConfig();

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      CommonDeprovisionFormValues,
      InternalFormValues,
      ActivityDTO
    >
  >(
    async ({
      contentButtonContentProps: externalActivity,
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    }) => {
      setIsLoadingModal(true);
      const startingOpenCloseModalCount = getOpenCloseModalCount();

      try {
        const activity = await getActivityById(externalActivity.id);
        if (!activity)
          return setModalError(
            i18next.t("activities.errors.data.general.activityDoesNotExist")
          );

        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        setInternalFormData({
          project,
          activity,
          maxScheduledDate: getNowBrazilianDateOnly().toDate(),
        });
        setFormValues({
          deprovisionType: activity.deprovisionType,
          description: activity.description,
          scheduledDate: activity.scheduledDate.toDate(),
          amount: formatNumber(-1 * activity.amount, { fractionDigits: 2 }),
          deprovisionJustification: activity.deprovisionJustification ?? "",
        });
      } catch (error) {
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage: "activities.errors.data.fetch.failedToFetchActivity",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [project]
  );

  const {
    ContentButton: DeprovisionEditingButtonContainer,
    contentModal: deprovisionEditingModal,
  } = useFormikModalButton<
    CommonDeprovisionFormValues,
    InternalFormValues,
    ActivityDTO
  >({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("activities.modal.data.editDeprovision.title"),
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <EditIconButton
            onClick={onClick}
            tooltipText={t("activities.actions.general.editDeprovision")}
          />
        );
      },
      onClickContentButtonComponent,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonDeprovisionForm
          project={internalFormData?.project ?? null}
          {...rest}
          mode="editing"
          maxScheduledDate={internalFormData?.maxScheduledDate ?? null}
        />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!internalFormData?.activity)
          throw new Error("'activity' cannot be null on submit.");

        try {
          await updateActivityDeprovision(
            convertTo<ActivityDeprovisionUpdateDTO>({
              id: internalFormData.activity.id,
              description: formValues.description,
              scheduledDate: DateOnly.createFromDate(
                formValues.scheduledDate ?? new Date()
              ),
              amount: -1 * parseLocaleNumber(formValues.amount),
              deprovisionJustification: formValues.deprovisionJustification,
              version: internalFormData.activity.version,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          reloadActivitySummary();
          closeModal();

          if (
            checkIsDateAfterProject(
              project.finalScheduledEndDate,
              formValues.scheduledDate
            ) ||
            project.isValid
          )
            reloadProject();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "activities.errors.data.update.failedToUpdateDeprovision",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { DeprovisionEditingButtonContainer, deprovisionEditingModal };
};
