import { styled } from "@mui/material";

export const StyledAllocationBatchFiller = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing(1)};

  .MuiTextField-root {
    width: 160px;
  }
`;
