import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { deleteAllocations } from "../../../../../../../../../services/allocations/allocations.service";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { AllocationsOfUserDTO } from "../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { DeletionForm } from "./DeletionForm";

interface OwnProps {
  reloadTablePage: () => void;
  reloadAllocationsSummary: () => void;
}

export const useDeletionForm = ({
  reloadTablePage,
  reloadAllocationsSummary,
}: OwnProps) => {
  const { t } = useTranslation();
  const { reloadProject } = useProjectContext();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<AllocationsOfUserDTO>({
    modal: {
      modalColorVariant: "error",
      modalTitle: t("allocations.modal.data.delete.title"),
      internalLoadingPosition: "title",
      ModalContentMemo: ({ internalModalData: allocationRow }) =>
        allocationRow && <DeletionForm allocationRow={allocationRow} />,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: externalAllocation,
        setInternalModalData,
      }) => {
        setInternalModalData(externalAllocation);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: allocation }) => {
        if (!allocation)
          throw new Error("Allocation cannot be null on delete.");

        try {
          await deleteAllocations(allocation.allocationGroup);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadAllocationsSummary();
          reloadTablePage();
          closeModal();

          reloadProject();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            i18next.t("allocations.errors.data.delete.failedToDeleteAllocation")
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
