import { store } from "../../store";
import { setPageTitle as setPageTitleAction } from "../../store/features/applicationSlice";
import { PageTitleSettings } from "../../store/features/applicationSlice/index.interface";

const applicationName = "RISE";

export const setPageTitle = (pageTitleSettings: PageTitleSettings | string) => {
  if (typeof pageTitleSettings === "string")
    pageTitleSettings = {
      title: pageTitleSettings,
      Component: null,
    };

  const { title } = pageTitleSettings;
  document.title = title ? `${title} - ${applicationName}` : applicationName;

  store.dispatch(setPageTitleAction(pageTitleSettings));
};

export const resetPageTitle = () => {
  setPageTitle("");
};
