export const getFontSizeByString = (string: string): number => {
  const { length } = string;
  if (length > 550) {
    return 7.5;
  }
  if (length > 410) {
    return 8;
  }
  if (length > 350) {
    return 9;
  }
  return 10;
};
