import { styled, TableRow } from "@mui/material";

export const NegativeTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.palette.error.light};

  &.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.palette.error.light}dd;
  }

  .MuiTableCell-root {
    color: ${({ theme }) => theme.palette.error.contrastText};
  }

  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.error.contrastText};
  }
`;
