import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExpenseModifyFormValues } from "../../../../types/data/form/values.types";
import { parseLocaleNumber } from "../../../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): ExpenseModifyFormValues => {
    return {
      amount: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
