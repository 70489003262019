import { Button } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { getUserCollaboratorById } from "../../../../../services/user/user.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useTypedSelector } from "../../../../../store";
import { FooterStyles } from "../../Projects/CostCenterPepsPage/index.styles";
import { useCreationForm } from "./hooks/useCreationForm";
import { useTableData } from "./hooks/useTableData";
import { CollaboratorTrackSuperUserContext } from "./shared/react/contexts";

type OwnParams = {
  collaboratorId: string;
};

export const CollaboratorTrackPageWithinContext = () => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  const { collaboratorId = "" } = useParams<OwnParams>();

  const [collaboratorName, setCollaboratorName] = useState<string>("");

  const {
    canEditCollaboratorTrackWithoutRestrictionsButton,
    canEditCollaboratorTrackWithoutRestrictions,
  } = useContext(CollaboratorTrackSuperUserContext);

  useEffect(() => {
    handleCollaboratorName();
    return () => {
      setCollaboratorName("");
    };
  }, []);

  const handleCollaboratorName = () => setCollaboratorName("");

  const id = useMemo(() => {
    return /^\d+$/.test(collaboratorId) ? parseInt(collaboratorId) : null;
  }, []);

  useEffect(() => {
    const fetchCollaborator = async () => {
      if (!id) return;

      try {
        const collaborator = await getUserCollaboratorById(id);
        setCollaboratorName(collaborator?.name ?? "");
        setPageTitle({
          title: collaborator?.name ?? "",
          Component: () => (
            <Trans
              i18nKey="collaboratorTrack.pages.main.title"
              values={{ name: collaborator?.name ?? "" }}
              components={{ italic: <em /> }}
            />
          ),
        });
      } catch (error) {
        notifyIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
        });
      }
    };

    fetchCollaborator();
  }, [t]);

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffectAfterRenders({
    effect: () => {
      enhancedTableRef.current?.reloadPage();
    },
    deps: [i18n.language, canEditCollaboratorTrackWithoutRestrictions],
    rendersBeforeEffect: 1,
  });

  const { CreationButton, creationModal } = useCreationForm({
    reloadTablePage,
    collaboratorId: id ?? 0,
    isSuperUserContext: canEditCollaboratorTrackWithoutRestrictions,
  });

  const rightActionButtons = useMemo(() => {
    return <>{canEditCollaboratorTrackWithoutRestrictionsButton}</>;
  }, [canEditCollaboratorTrackWithoutRestrictionsButton]);

  const {
    headCells,
    onCollaboratorHistoryPageChange,
    deletionModal,
    editingModal,
    viewingModal,
  } = useTableData({
    reloadTablePage,
    collaboratorId: id ?? 0,
    collaboratorName,
    isSuperUserContext: canEditCollaboratorTrackWithoutRestrictions,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.CollaboratorHistory,
                level: PermissionLevel.Create,
              }}
            >
              <CreationButton />
            </Protected>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        orderDefaults={{
          defaultOrder: "desc",
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: onCollaboratorHistoryPageChange,
          },
        }}
        footerSettings={{
          Content: () => {
            const { t } = useTranslation();
            const history = useTypedSelector(
              (state) => state.application.pagesHistory
            );

            const previousPage = useMemo(() => {
              if (pageReturnTo) return pageReturnTo;

              const location = history.length > 0 ? history.at(-2) : null;
              if (!location) return "/collaborators";

              const searchName = `?name=${collaboratorName
                .toLowerCase()
                .replaceAll(" ", "+")}`;

              return location.pathname + searchName;
            }, [history, collaboratorName]);

            return (
              <FooterStyles>
                <Button component={Link} to={previousPage}>
                  {t("general.actions.general.return")}
                </Button>
              </FooterStyles>
            );
          },
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
      {viewingModal}
    </>
  );
};
