import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import yaml from "js-yaml";
import {
  setStoredLanguage,
  getStoredLanguage,
} from "../../services/applicationState/language.service";
import { Language } from "../../shared/common/enums/locale/Language.enum";

const storedLanguage = getStoredLanguage();

const isPortuguese = /^pt/.test(storedLanguage ?? navigator.language);

const currentLanguage = isPortuguese ? Language.ptBR : Language.enUS;
setStoredLanguage(currentLanguage);

export const i18n = i18next
  .use(initReactI18next)
  .use(Backend)
  .init({
    fallbackLng: currentLanguage,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.yml",
      parse: (data: string) => yaml.load(data) as { [key: string]: unknown },
    },
    react: {
      useSuspense: false,
    },
  });
