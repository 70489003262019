import { useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ExternalEnhancedRow } from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEditingMonthlyComment } from "../useEditingMonthlyComment";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ProjectionAdjustmentContext } from "../../../../../context/projectionAdjustmentContexProvider";
import { CarouselCell } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { ExpenseGroup } from "../../../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";

export const useMonthlyCommentTable = () => {
  const { editingModal, EditingButtonContainer } = useEditingMonthlyComment();

  const { projectionAdjustmentInitialValues } = useContext(
    ProjectionAdjustmentContext
  );

  const [searchParams] = useSearchParams();
  const idExpenseGroup = searchParams.get("idExpenseGroup");
  const idSubsidiary = searchParams.get("idSubsidiary");
  const idDivision = searchParams.get("idDivision");
  const idSegmentGroup = searchParams.get("idSegmentGroup");
  const year = searchParams.get("year");

  const idSubsidiaryValidated = useMemo(() => {
    return /^\d+$/.test(idSubsidiary ?? "")
      ? parseInt(idSubsidiary ?? "")
      : null;
  }, [idSubsidiary]);

  const idDivisionValidated = useMemo(() => {
    return /^\d+$/.test(idDivision ?? "") ? parseInt(idDivision ?? "") : null;
  }, [idDivision]);

  const idSegmentGroupValidated = useMemo(() => {
    return /^\d+$/.test(idSegmentGroup ?? "")
      ? parseInt(idSegmentGroup ?? "")
      : null;
  }, [idSegmentGroup]);

  const idExpenseGroupValidated = useMemo(() => {
    return /^\d+$/.test(idExpenseGroup ?? "")
      ? parseInt(idExpenseGroup ?? "")
      : null;
  }, [idExpenseGroup]);

  const isToShowCustomerTrigramAndClientAccount = useMemo(() => {
    return ![
      ExpenseGroup.AdmCorp,
      ExpenseGroup.Pdd,
      ExpenseGroup.IrCsll,
      ExpenseGroup.NetFinancialExpenses,
      ExpenseGroup.ParticipationProgram,
    ].includes(idExpenseGroupValidated as ExpenseGroup);
  }, [idExpenseGroupValidated]);

  const yearValidated = useMemo(() => {
    return /^\d+$/.test(year ?? "") ? parseInt(year ?? "") : null;
  }, [year]);

  const rangeYearMonth = YearMonth.range(
    new YearMonth(0, yearValidated ?? 0),
    new YearMonth(11, yearValidated ?? 0)
  );

  const firstRow: ExternalEnhancedRow = useMemo(() => {
    const row: ExternalEnhancedRow = {
      id: 1,
      cells: ["", "", "", "", "", "", "", ""],
    };

    if (!isToShowCustomerTrigramAndClientAccount) {
      row.cells.splice(3, 2);
    }

    for (let i = 0; i < 3; i++) {
      row.cells.push({
        paddingmode: "none",
        justifyContent: "center",
        CellRenderer: () => {
          return (
            <CarouselCell
              items={rangeYearMonth}
              columnIndex={i}
              checkIsCurrentItem={(item, yearMonth) => item.equals(yearMonth)}
              CarouselComponentMemo={({ item, yearMonth }) => (
                <EditingButtonContainer
                  comment={
                    projectionAdjustmentInitialValues?.monthlyComments.find(
                      (x) => x.yearMonth.month === yearMonth?.month
                    ) || null
                  }
                  loadFormData={{
                    idDivision: idDivisionValidated ?? 0,
                    idsegmentGroup: idSegmentGroupValidated ?? 0,
                    idSubsidiary: idSubsidiaryValidated ?? 0,
                    yearMonth:
                      yearMonth ?? new YearMonth(i, yearValidated ?? 0),
                  }}
                />
              )}
            />
          );
        },
      });
    }

    row.cells.push({});

    row.cells.push("");

    return row;
  }, [
    idDivisionValidated,
    idSegmentGroupValidated,
    idSubsidiaryValidated,
    yearValidated,
    projectionAdjustmentInitialValues,
  ]);

  return {
    firstRow,
    editingModal,
  };
};
