import { useMemo } from "react";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { ProjectionAdjustmentPageWithInContext } from "./ProjectionAdjustmentPageWithInContext";
import { ProjectionAdjustmentContexProvider } from "./shared/context/projectionAdjustmentContexProvider";
import { ProjectionsSuperUserContext } from "./shared/context/projectionsSuperUserContext";

export const ProjectionAdjustmentPage = () => {
  const {
    superUserButton: canEditAllProjectionsButton,
    inSuperUserMode: canEditAllProjections,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditPastProjectionResult,
    tooltipNotInSuperUserModeOff:
      "corporateAllocations.actions.authorization.editAllocationsWithoutRestrictions",
    tooltipInSuperUserMode:
      "corporateAllocations.actions.authorization.stopEditAllocationsWithoutRestrictions",
  });

  const projectionsSuperUserValues = useMemo(() => {
    return {
      canEditAllProjectionsButton,
      canEditAllProjections,
    };
  }, [canEditAllProjectionsButton, canEditAllProjections]);

  return (
    <ProjectionAdjustmentContexProvider>
      <ProjectionsSuperUserContext.Provider value={projectionsSuperUserValues}>
        <ProjectionAdjustmentPageWithInContext />
      </ProjectionsSuperUserContext.Provider>
    </ProjectionAdjustmentContexProvider>
  );
};
