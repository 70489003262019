import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { DeleteIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { CostCenterPepOnlyDTO } from "../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import {
  deleteCostCenterPep,
  getCostCenterPepOnlyById,
} from "../../../../../../services/costCenterPeps/costCenterPeps.service";

interface OwnProps {
  reloadTable: () => void;
}

export const useDeletionForm = ({ reloadTable }: OwnProps) => {
  const { t } = useTranslation();

  const { ModalButtonContainer: DeleteButton, contentModal: deletionModal } =
    useActionButtonDialog<CostCenterPepOnlyDTO, CostCenterPepOnlyDTO>({
      modal: {
        modalColorVariant: "error",
        ModalTitleMemo: ({ internalModalData: costCenterPep }) => {
          return (
            <Trans
              i18nKey="general.modal.data.delete.deleteName"
              values={{ name: costCenterPep?.pepCode ?? "-" }}
              components={{ italic: <em /> }}
            />
          );
        },
        internalLoadingPosition: "title",
      },
      button: {
        ModalButtonMemo: ({ onClick: openModal }) => (
          <DeleteIconButton onClick={openModal} />
        ),
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: tableCostCenterPepOnly,
          setInternalModalData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const costCenterPepOnly = await getCostCenterPepOnlyById(
              tableCostCenterPepOnly.id
            );

            if (!costCenterPepOnly)
              return setModalError(
                i18next.t("costCenterPeps.errors.data.general.pepDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalModalData(costCenterPepOnly);
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPep",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      defaultDialogActionsProps: {
        confirmButtonLabel: t("general.actions.general.delete"),
        onConfirmMemo: async ({
          closeModal,
          internalModalData: costCenter,
        }) => {
          if (!costCenter)
            throw new Error("Cost center pep cannot be null on confirm.");

          try {
            await deleteCostCenterPep(costCenter.id, costCenter.version);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTable();
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (!costCenter?.pepCode)
              return notifyError(
                i18next.t("costCenters.errors.data.delete.failedToDelete")
              );

            notifyError(
              <Trans
                i18nKey="costCenters.errors.data.delete.failedToDeleteName"
                values={{ name: costCenter.pepCode }}
                components={{ italic: <em /> }}
              />
            );
          }
        },
      },
    });

  return { DeleteButton, deletionModal };
};
