import { styled, TextField } from "@mui/material";

export const StyledTotals = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  padding: 8px 16px;
`;

export const StyledTextField = styled(TextField)`
  width: 88px;
`;

export const StyledFooterContent = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 32px;
  padding: 8px 16px;
`;
