import * as yup from "yup";
import { SkillDTO, schemaSkillDTO } from "../skill/SkillDTO";
import { KeywordDTO, schemaKeywordDTO } from "../Keyword/KeywordDTO";
import {
  PipedriveServiceLineDTO,
  schemaPipedriveServiceLineDTO,
} from "../pipedrive/PipedriveServiceLineDTO";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  ProductServiceDTO,
  schemaProductServiceDTO,
} from "../productService/ProductServiceDTO";
import { CustomerDTO, schemaCustomerDTO } from "../customers/CustomerDTO";

export interface ProjectAdditionalInformationDTO {
  id: number;
  un: DivisionDTO;
  code: string;
  name: string;
  customer: CustomerDTO;
  startBaselineYear: string | null;
  country: string;
  projectManager: string;
  segment: string | null;
  productService: ProductServiceDTO | null;
  isConfidential: boolean;
  customerContact: string | null;
  solutionAdoptedExpectedResults: string | null;
  projectChallenges: string | null;
  projectResults: string | null;
  projectSkill: SkillDTO[];
  projectKeyword: KeywordDTO[];
  projectPipedriveServiceLines: PipedriveServiceLineDTO[];
  version: string;
}

export const schemaProjectAdditionalInformationDTO = yup.object({
  id: yup.number().integer().defined(),
  code: yup.string().defined(),
  name: yup.string().defined(),
  customer: schemaCustomerDTO.defined(),
  startBaselineYear: yup.string().nullable().defined(),
  country: yup.string().defined(),
  projectManager: yup.string().defined(),
  un: schemaDivisionDTO.defined(),
  isConfidential: yup.boolean().defined(),
  segment: yup.string().nullable().defined(),
  productService: schemaProductServiceDTO.nullable().defined(),
  customerContact: yup.string().nullable().defined(),
  solutionAdoptedExpectedResults: yup.string().nullable().defined(),
  projectChallenges: yup.string().nullable().defined(),
  projectResults: yup.string().nullable().defined(),
  projectSkill: yup.array(schemaSkillDTO).defined(),
  projectKeyword: yup.array(schemaKeywordDTO).defined(),
  projectPipedriveServiceLines: yup
    .array(schemaPipedriveServiceLineDTO)
    .defined(),
  version: yup.string().defined(),
});
