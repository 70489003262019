import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { getUserGroup } from "../../../../../../../../../services/userGroup/userGroup.service";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { useDeletionForm } from "./useDeletionForm";

interface OwnProps {
  accessGroupId: number;
  filters: {
    name?: string;
  };
}

export const useTableData = ({ accessGroupId, filters }: OwnProps) => {
  const { t } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.colaborator"),
      {
        value: "",
        canSort: false,
        width: 40,
      },
    ];

    return headCells;
  }, [t]);

  const onCollaboratorsPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      if (!accessGroupId)
        throw new Error(
          "'accessGroupId' cannot be null when this function is called."
        );

      try {
        const userGroupData = await getUserGroup(accessGroupId);

        const filteredData = filters?.name
          ? userGroupData?.filter((collaborator) => {
              const filterWord = filters?.name || "";

              return collaborator.name
                .toLowerCase()
                .includes(filterWord.toLowerCase());
            })
          : userGroupData;

        const rows: ExternalEnhancedRow[] = filteredData?.map(
          (collaboratorGroup) => ({
            id: collaboratorGroup.idUserCollaborator,
            cells: [
              collaboratorGroup.name,
              {
                CellRenderer: () => (
                  <DeleteButtonContainer
                    collaboratorGroupId={collaboratorGroup.id}
                    collaboratorName={collaboratorGroup.name}
                  />
                ),
                paddingmode: "vertical",
              },
            ],
          })
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, filters]);

  return { onCollaboratorsPageChange, headCells, deletionModal };
};
