import { AutocompleteProps, TextFieldProps } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AsyncAutocompleteFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { getStates } from "../../../../../../../../../../../../services/publicData/states.service";
import { StateDTO } from "../../../../../../../../../../../../shared/common/DTOs/publicData/states/StateDTO";
import { Country } from "../../../../../../../../../../../../shared/common/enums/locale/Country.enum";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  country: Country;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteProps<StateDTO, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  >;
}

export const StatesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  country,
  textfieldProps,
  autocompleteProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const fetchStates = useCallback(() => {
    return getStates(country);
  }, [country]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("states.errors.data.fetch.failedToFetch")}
      fetchOptions={fetchStates}
      textfieldProps={{
        label: t("states.keywords.general.state"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (option) => option.name,
        isOptionEqualToValueMemo: (option, value) =>
          option.abbreviation === value.abbreviation,
        ...autocompleteProps,
      }}
    />
  );
};
