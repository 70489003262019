import { FileDownload as FileDownloadIcon } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { generateBrlParitySpreadsheet } from "../../../../../services/brlParity/BrlParity.service";
import { ContextMenu } from "../../../../../shared/common/react/components/menu/ContextMenu";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { useCreationForm } from "./hooks/useCreationForm";

export const BrlParitysPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    setPageTitle(t("brlParity.pages.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { filterButton, filterModal, filters } = useFilterForm();
  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, filters],
    rendersBeforeEffect: 1,
  });

  const { headCells, editingModal, deletionModal, onBrlParityPageChange } =
    useTableData({
      filters,
      reloadTablePage,
    });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
        <ContextMenu
          menuOptions={[
            {
              Icon: FileDownloadIcon,
              content: t("brlParity.actions.data.general.downloadBrlParity"),
              onClick: () => generateBrlParitySpreadsheet({ filters }),
            },
          ]}
        />
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.BrlParity,
                level: PermissionLevel.Create,
              }}
            >
              {CreationButtonContainer}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        orderDefaults={{
          defaultOrderBy: 0,
          defaultOrder: "desc",
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onBrlParityPageChange,
          },
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
    </>
  );
};
