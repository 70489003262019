import saveAs from "file-saver";
import i18next from "i18next";
import { formatFilename } from "../../../shared/common/helpers/data/general/formatters/fileFormatters.helpers";
import { BrlParityCreateDTO } from "../../../shared/specific/DTOs/brlParity/BrlParityCreateDTO";
import {
  BrlParityDTO,
  schemaBrlParityDTO,
} from "../../../shared/specific/DTOs/brlParity/BrlParityDTO";
import { BrlParityUpdateDTO } from "../../../shared/specific/DTOs/brlParity/BrlParityUpdateDTO";
import { BrlParityFiltersDTO } from "../../../shared/specific/DTOs/brlParity/filters/BrlParityFiltersDTO";
import {
  PaginatedBrlParityDTO,
  schemaPaginatedBrlParityDTO,
} from "../../../shared/specific/DTOs/brlParity/PaginatedBrlParityDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getBrlParitys = async ({
  filters,
  pagination,
}: {
  filters?: BrlParityFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedBrlParityDTO> => {
  const { data: PaginatedBrlParitys } = await riseApi.get("BrlParity", {
    params: { ...filters, ...pagination },
  });
  return schemaPaginatedBrlParityDTO.validateSync(PaginatedBrlParitys);
};

export const generateBrlParitySpreadsheet = async ({
  filters,
  filename,
}: {
  filters?: BrlParityFiltersDTO;
  filename?: string;
}) => {
  const { data: spreadsheetBlob } = await riseApi.get("BrlParity/Spreadsheet", {
    params: filters,
    responseType: "blob",
  });

  const finalFilename =
    filename ??
    formatFilename(i18next.t("brlParity.keywords.general.brlParities"), {
      extension: "xlsx",
    });

  saveAs(
    new Blob([spreadsheetBlob], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const getBrlParityById = async (
  BrlParityId: number
): Promise<BrlParityDTO | null> => {
  const { data: brlParity } = await riseApi.get(`/BrlParity/${BrlParityId}`);

  return schemaBrlParityDTO.nullable().validateSync(brlParity);
};

export const createBrlParity = async (brlParity: BrlParityCreateDTO) => {
  await riseApi.post("BrlParity", brlParity);
};

export const updateBrlParity = async (brlParity: BrlParityUpdateDTO) => {
  await riseApi.put("BrlParity", brlParity);
};

export const deleteBrlParity = async (id: number, version: string) => {
  await riseApi.delete(`BrlParity/${id}/${version}`);
};
