import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../../common/classes/data/date/YearMonth";

export interface AmountMonthDTO {
  yearMonth: YearMonth;
  amount: number;
}

export const schemaAmountMonthDTO = yup.object({
  yearMonth: schemaYearMonth.defined(),
  amount: yup.number().defined(),
});
