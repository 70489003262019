import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectPlanningSavingValidationDTO } from "../../../../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningSavingValidationDTO";
import { ValidationItem } from "../../../../types/data/validation/general.types";

interface OwnProps {
  projectValidation: ProjectPlanningSavingValidationDTO;
}

export const usePlanningSavingValidationData = ({
  projectValidation,
}: OwnProps) => {
  const { t } = useTranslation();

  return useMemo((): ValidationItem[] => {
    return [
      {
        name: t(
          "projects.keywords.projectValidation.wasProjectScheduledStartDateProvided"
        ),
        isValid: projectValidation.wasProjectScheduledStartDateProvided,
      },
      {
        name: t("projects.keywords.projectValidation.wasProjectMvProvided"),
        isValid: projectValidation.wasProjectMvProvided,
      },
      {
        name: t(
          "projects.keywords.projectValidation.revenueEqualsProjectAmount"
        ),
        isValid: projectValidation.revenueEqualsProjectAmount,
      },
      {
        name: t(
          "projects.keywords.projectValidation.activitiesLessThanProjectBudget"
        ),
        isValid: projectValidation.activitiesLessThanProjectBudget,
      },
      {
        name: t(
          "projects.keywords.projectValidation.allActivitiesValidAmounts"
        ),
        isValid: projectValidation.allActivitiesValidAmounts,
      },
      {
        name: t(
          "projects.keywords.projectValidation.invoicesEqualProjectBudget"
        ),
        isValid: projectValidation.invoicesEqualProjectBudget,
      },
      {
        name: t(
          "projects.keywords.projectValidation.invoicesCorrectPercentage"
        ),
        isValid: projectValidation.invoicesCorrectPercentage,
      },
      {
        name: t("projects.keywords.projectValidation.atLeastOneAllocation"),
        isValid: projectValidation.atLeastOneAllocation,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noAllocationsCollaboratorsAfterResignationDate"
        ),
        isValid:
          projectValidation.noAllocationsCollaboratorsAfterResignationDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noActivitiesBeforeProjectScheduledStartDate"
        ),
        isValid: projectValidation.noActivitiesBeforeProjectScheduledStartDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noInvoicesBeforeProjectScheduledStartDate"
        ),
        isValid: projectValidation.noInvoicesBeforeProjectScheduledStartDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noAllocationsBeforeProjectScheduledStartDate"
        ),
        isValid: projectValidation.noAllocationsBeforeProjectScheduledStartDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noExpensesBeforeProjectScheduledStartDate"
        ),
        isValid: projectValidation.noExpensesBeforeProjectScheduledStartDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noPerformedExpensesBeforeProjectScheduledStartDate"
        ),
        isValid:
          projectValidation.noPerformedExpensesBeforeProjectScheduledStartDate,
      },
      {
        name: t(
          "projects.keywords.projectValidation.allAllocationsValidComparedToCollaboratorTracks"
        ),
        isValid:
          projectValidation.allAllocationsValidComparedToCollaboratorTracks,
      },
      {
        name: t(
          "projects.keywords.projectValidation.noAllocationsWithZeroCost"
        ),
        isValid: projectValidation.noAllocationsWithZeroCost,
      },
    ];
  }, [t, projectValidation]);
};
