import { styled } from "@mui/material";

export const StyledJobRoleContent = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(1.5)}`};

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;
