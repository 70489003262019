import { TextField } from "@mui/material";
import { FocusEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const TIME_BEFORE_UPDATING_FILTERS = 500;

export const useUserCollaboratorNameFilter = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialUserCollaboratorName = useMemo(
    () => searchParams.get("userCollaboratorName") ?? "",
    []
  );

  const [userCollaboratorName, setUserCollaboratorName] = useState<string>(
    initialUserCollaboratorName
  );

  const filtersUpdateTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);
  }, []);

  useEffect(() => {
    if (userCollaboratorName) {
      searchParams.set("userCollaboratorName", userCollaboratorName ?? "");
    } else {
      searchParams.delete("userCollaboratorName");
    }

    setSearchParams(searchParams, { replace: true });
  }, [userCollaboratorName]);

  const userCollaboratorNameComponent = useMemo(() => {
    const onChange = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);

      filtersUpdateTimeoutRef.current = setTimeout(
        () => setUserCollaboratorName(event.target.value),
        TIME_BEFORE_UPDATING_FILTERS
      ) as unknown as number;
    };

    return (
      <TextField
        label={t("general.keywords.fields.colaborator")}
        fullWidth
        onChange={onChange}
        autoFocus
        defaultValue={initialUserCollaboratorName}
      />
    );
  }, [t, userCollaboratorName]);

  return {
    userCollaboratorName,
    userCollaboratorNameComponent,
  };
};
