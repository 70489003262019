import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";

import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";

export interface MonthlyAllocationClosingDTO {
  id: number;
  yearMonth: YearMonth;
  validatedDate: Date;
  userCollaboratorValidator: UserCollaboratorOnlyDTO | null;
  version: string;
  workforceCostDate: Date | null;
}

export const schemaMonthlyAllocationClosingDTO = yup.object({
  id: yup.number().defined(),
  yearMonth: schemaYearMonth.defined(),
  validatedDate: yup.date().defined(),
  userCollaboratorValidator: schemaUserCollaboratorOnlyDTO.nullable().defined(),
  version: yup.string().defined(),
  workforceCostDate: yup.date().nullable().defined(),
});
