import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createDashboardGroup } from "../../../../../services/dashboardGroups/dashboardGroups.service";
import { formatFromTranslatableFieldValues } from "../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { DashboardGroupCreateDTO } from "../../../../../shared/specific/DTOs/dashboardGroups/DashboardGroupCreateDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { DashboardGroupsCommomForm } from "../shared/components/DashboardGroupsCommomForm";
import { useCreationEditingFormikConfig } from "../shared/hooks/validation/useCreationEditingFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useCreationEditingFormikConfig();

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <div>{t("dashboardGroups.modal.data.create.title")}</div>;
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => <DashboardGroupsCommomForm {...rest} />,
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const dashboardGroupToCreate: DashboardGroupCreateDTO = {
            dashboardGroupTranslations: formatFromTranslatableFieldValues({
              source: formValues.nameTranslations,
              convertItem: ({ language, value }) => ({
                idLanguage: language,
                name: value,
              }),
            }),
          };

          await createDashboardGroup(dashboardGroupToCreate);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "dashboardGroups.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
