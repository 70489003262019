import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../corporateSegment/CorporateSegmentsDTO";
import {
  CostCenterOnlyDTO,
  schemaCostCenterOnlyDTO,
} from "../costCenter/CostCenterOnlyDTO";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../project/ProjectOnlyDTO";
import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  schemaTimesheetMonthDayDTO,
  TimesheetMonthDayDTO,
} from "./TimesheetMonthDayDTO";

export interface TimesheetMonthDTO {
  userCollaborator: UserCollaboratorOnlyDTO;
  project: ProjectOnlyDTO | null;
  costCenter: CostCenterOnlyDTO | null;
  corporateSegment: CorporateSegmentsDTO | null;
  allocationType: EmployeeAllocationType;
  minutesSum: number;
  yearMonth: YearMonth;
  days: TimesheetMonthDayDTO[];
}

export const schemaTimesheetMonthDTO = yup.object({
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  project: schemaProjectOnlyDTO.nullable().defined(),
  costCenter: schemaCostCenterOnlyDTO.nullable().defined(),
  corporateSegment: schemaCorporateSegmentsDTO.nullable().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  minutesSum: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined(),
  days: yup.array(schemaTimesheetMonthDayDTO).defined(),
});
