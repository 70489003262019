import * as yup from "yup";

export enum TabCode {
  ProjectInformation = "projectInformation",
  AdditionalInformation = "additionalInformation",
}

export const schemaTabCode = yup
  .mixed<TabCode>()
  .oneOf(Object.values(TabCode) as TabCode[]);
