import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxLengthFields } from "../../constants/form.constants";
import { FormMode, FormValues } from "../../types/form.types";
import { StyledCreationForm } from "./index.styles";

type OwnProps = FormContentProps<FormValues> & { mode: FormMode };

export const WorkloadsDataForm = ({
  formikProps: { isSubmitting },
  mode,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const datePattern = i18n.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy";

  return mode === "editing" ? (
    <StyledCreationForm>
      <TextFieldFormik
        name="name"
        label={t("workloads.keywords.general.workload")}
        inputProps={{
          maxLength: maxLengthFields.name,
        }}
        required
        disabled={isSubmitting}
      />

      <DatePickerFormik
        name="deactivatedDate"
        datePickerProps={{
          views: ["year", "month", "day"],
          label: t("jobRoles.keywords.general.deactivatedDate"),
          format: datePattern,
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: new Date(),
          disabled: isSubmitting,
        }}
      />
    </StyledCreationForm>
  ) : (
    <TextFieldFormik
      name="name"
      label={t("workloads.keywords.general.workload")}
      inputProps={{
        maxLength: maxLengthFields.name,
      }}
      required
      disabled={isSubmitting}
      fullWidth
    />
  );
};
