import { styled } from "@mui/material";

export const StyledModalContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledWarningMessage = styled("span")`
  color: #d32f2f;
  font-size: larger;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const SuccessContainer = styled("div")`
  margin: 16px 0 16px 0;
  font-size: 18px;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.success.main};
`;

export const ErrorsListContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > :first-child {
    font-size: 18px;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.error.main};
  }

  & > :last-child {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    & > * {
      gap: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;
