import { ProfileContextProvider } from "./context/ProfileContextProvider";
import { Mode } from "./shared/types/index.types";
import { UserProfileWithInContextPage } from "./userProfileWithInContext";

interface OwnProps {
  idUserCollaborator?: number;
  mode: Mode;
}

export const UserCollaboratorProfileComponent = ({
  idUserCollaborator,
  mode,
}: OwnProps) => {
  return (
    <ProfileContextProvider idUserCollaborator={idUserCollaborator} mode={mode}>
      <UserProfileWithInContextPage />
    </ProfileContextProvider>
  );
};
