import classNames from "classnames";
import { ReactNode } from "react";
import { classnames as componentClassnames } from "../shared/classnames";
import { StretchMode } from "./index.types";
import { CenteredContentContainer } from "./styles/CenteredContentContainer.styles";

interface OwnProps {
  children: ReactNode;
  className?: string;
  stretchMode?: StretchMode;
}

export type { StretchMode };

export const CenteredContent = ({
  children,
  className,
  stretchMode,
}: OwnProps) => {
  return (
    <CenteredContentContainer
      className={classNames(componentClassnames.root, className)}
      stretchMode={stretchMode}
    >
      {children}
    </CenteredContentContainer>
  );
};
