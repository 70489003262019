import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { DashboardDTO } from "../../../../../shared/specific/DTOs/dashboards/DashboardDTO";
import { DashboardGroupDTO } from "../../../../../shared/specific/DTOs/dashboardGroups/DashboardGroupDTO";
import { DashboardAutocomplete } from "./form/general/autocomplete/DashboardAutocomplete";
import { StyledDashboard, StyledFormContainer } from "./index.styles";
import { DashboardGroupsAutocomplete } from "../../../../../shared/specific/react/components/form/general/autocomplete/fields/dashboardGroups/DashboardGroupsAutocomplete";

export const DashboardsView = () => {
  const { t } = useTranslation();

  const [dashboardGroup, setDashboardGroup] =
    useState<DashboardGroupDTO | null>(null);

  const [dashboard, setDashboard] = useState<DashboardDTO | null>(null);

  useEffect(() => {
    setPageTitle(t("dashboardsView.pages.main.title"));
  }, [t]);

  useEffect(() => {
    setDashboard(null);
  }, [dashboardGroup]);

  return (
    <>
      <StyledFormContainer>
        <DashboardGroupsAutocomplete
          autocompleteProps={{
            value: dashboardGroup ?? null,
            setValue: setDashboardGroup,
          }}
        />
        <DashboardAutocomplete
          idDashBoardGroup={dashboardGroup?.id}
          autocompleteProps={{
            value: dashboard ?? null,
            setValue: setDashboard,
            disabled: !dashboardGroup?.id,
          }}
        />
      </StyledFormContainer>
      {dashboard && <StyledDashboard src={dashboard.url} />}
    </>
  );
};
