import { styled } from "@mui/material/styles";

export const StyledTitleContainer = styled("div")`
  display: flex;
  flex-direction: column;

  span:nth-child(2) {
    font-size: 1rem;
  }
`;
