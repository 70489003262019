import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { CommonDisciplineForm } from "../shared/react/components/CommonDisciplineForm";
import { useFormikConfig } from "./useFormikConfig";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { DisciplineCreateDTO } from "../../../../../../../shared/specific/DTOs/discipline/DisciplineCreateDTO";
import { createDiscipline } from "../../../../../../../services/disciplines/disciplines.service";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <>{t("disciplines.modal.data.create.title")}</>;
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonDisciplineForm {...rest} mode="creation" />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const createdDiscipline: DisciplineCreateDTO = {
            name: formValues.name,
            idHorizontal: formValues.horizontal?.id ?? 0,
          };

          await createDiscipline(createdDiscipline);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "disciplines.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
