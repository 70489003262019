export const maxLengthFields = {
  code: 3,
  name: 200,
  tradingName: 200,
  corporateName: 200,
  email: 60,
  phoneNumber: 60,
  zipCode: 30,
  country: 2,
  state: 50,
  city: 60,
  district: 60,
  streetName: 60,
  complement: 100,
  registrationCode: 60,
  municipalCode: 60,
  stateCode: 60,
};
