import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { FormContext } from "../../shared/contexts/FormContext";
import { CenteredLoading } from "../../../../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../../../../../ProjectPlanningPage/shared/react/styles/ui/layouts/general.styles";
import { HeaderLoading } from "../../../../../../../../../../../shared/common/react/components/ui/HeaderLoading";

interface OwnProps {}

export const ProjectSummary = (props: OwnProps) => {
  const { t } = useTranslation();
  const { loading, projectData } = useContext(FormContext);

  if ((!projectData && loading) || (!projectData && loading))
    return <CenteredLoading stretchMode="horizontal" />;

  return (
    <StyledSubHeaderFixed>
      {loading && <HeaderLoading />}
      <StyledInfoItem>
        <div>{t("general.keywords.fields.name")}</div>
        <div>{projectData?.name ?? "-"}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("general.keywords.fields.code")}</div>
        <div>{projectData?.code ?? "-"}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("divisions.keywords.general.un")}</div>
        <div>{projectData?.un.name ?? "-"}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.segment")}</div>
        <div>{projectData?.segment ?? "-"}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("productServices.keywords.general.productService")}</div>
        <div>{projectData?.productService?.name ?? "-"}</div>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};
