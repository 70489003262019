import axios from "axios";
import i18next from "i18next";
import { enviroment } from "../../../core/enviroment";
import { getApiAuthorizationData } from "../microsoftAuthentication/authentication.repository";

export const riseApi = axios.create({
  baseURL: enviroment.apiURL,
});

riseApi.interceptors.request.use(async (requestConfig) => {
  const { accessToken } = await getApiAuthorizationData();

  requestConfig.headers = requestConfig.headers || {};

  requestConfig.headers.Authorization = `Bearer ${accessToken}`;
  requestConfig.headers["Accept-Language"] = i18next.language;

  return requestConfig;
});

riseApi.interceptors.response.use((responseConfig) => {
  window.localStorage.setItem(
    "RiseLastVersion",
    responseConfig.headers["rise-version"] || ""
  );

  if (responseConfig.data === "") responseConfig.data = null;

  return responseConfig;
});
