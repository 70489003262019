import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { StyledModalHelperInfos, StyledErrorOutlineIcon } from "../index.style";

export const useWorksheetHelp = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: UseWorksheetHelpModalButtonContainer,
    contentModal: useWorksheetHelpModal,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("performedFinancialCost.modal.data.title"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        const rules = t("performedFinancialCost.modal.data.information");
        const listRules = rules as unknown as string[];
        return (
          <StyledModalHelperInfos>
            <ul>
              {listRules.map((rule, index) => (
                <li key={index}>{rule}</li>
              ))}
            </ul>
            <p>
              {t("performedFinancialCost.modal.data.belowExampleRandomProject")}
            </p>
            <img src="/media/images/pages/performedFinancialCost/performed-financial-cost-example.png" />
          </StyledModalHelperInfos>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return <StyledErrorOutlineIcon onClick={onClick} />;
      },
    },
  });
  return { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal };
};
