import * as yup from "yup";
import {
  SegmentGroupTranslationDTO,
  schemaSegmentGroupTranslationDTO,
} from "./translation/SegmentGroupTranslationDTO";

export interface SegmentGroupDTO {
  id: number;
  description: string;
  segmentGroupTranslations: SegmentGroupTranslationDTO[];
}

export const schemaSegmentGroupDTO = yup.object({
  id: yup.number().integer().defined(),
  description: yup.string().defined(),
  segmentGroupTranslations: yup
    .array(schemaSegmentGroupTranslationDTO)
    .defined(),
});
