import * as yup from "yup";

export enum InvoicesStatusEnum {
  NotReleased = 8,
  Released = 1,
  Returned = 3,
  Analysis = 2,
  Issued = 5,
  PaidOut = 7,
  CancellationAnalysis = 9,
  ProvisionForCancellation = 6,
  Canceled = 4,
  AmountCanceled = 10,
  Pdd = 11,
  Loss = 12,
}

export const schemaInvoicesStatusEnum = yup
  .mixed<InvoicesStatusEnum>()
  .oneOf(Object.values(InvoicesStatusEnum) as InvoicesStatusEnum[]);
