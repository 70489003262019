import { useState } from "react";
import { EnhancedRowData, Order } from "../../../../types/data.types";
import { EnhancedTablePaginationSettings } from "../../../../types/settings.types";
import { usePaginationData } from "./usePaginationData";
import { usePaginationHandler } from "./usePaginationHandler";

interface OwnProps {
  paginationSettings: EnhancedTablePaginationSettings;
  order: Order;
  orderBy: number | null;
  externalRows: EnhancedRowData[] | null;
  setLoading: (loading: string | boolean) => void;
  setError: (error: string | boolean) => void;
  setInfo: (info: string | boolean) => void;
}

export const usePaginationContext = ({
  paginationSettings,
  order,
  orderBy,
  externalRows,
  setLoading,
  setError,
  setInfo,
}: OwnProps) => {
  const [rows, setRows] = useState<EnhancedRowData[] | null>(null);
  const [page, setPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    paginationSettings.defaultRowsPerPage
  );

  const changePage = setPage;

  const { reloadPage } = usePaginationHandler({
    order,
    orderBy,
    page,
    rowsPerPage,
    externalRows,
    setError,
    setLoading,
    setInfo,
    setPage,
    setRows,
    setTotalItems,
    usesPagination: paginationSettings.usesPagination,
    externalPagination: paginationSettings.externalPagination,
  });

  const { pagination } = usePaginationData({
    changePage,
    page,
    paginationSettings,
    reloadPage,
    rowsPerPage,
    setRowsPerPage,
    totalItems,
  });

  return { pagination, rows, reloadPage, changePage };
};
