import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { generatePlannedAndPerformedByPepReport } from "../../../../../../../../../services/reports/reports.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormComponent } from "./FormComponent";
import { FormValuesPlannedAndPerformedByPepReport } from "./types/data/form.types";

export const PlannedAndPerformedByPepReport = () => {
  const { t } = useTranslation();

  const formikRef =
    useRef<FormikProps<FormValuesPlannedAndPerformedByPepReport>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesPlannedAndPerformedByPepReport = {
      division: null,
      endYear: null,
      startYear: null,
      subsidiary: null,
      pepCodes: [],
    };

    const validationSchema = yup.object({
      startYear: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.dates.invalidDate")),
      endYear: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.dates.invalidDate")),
      subsidiary: yup.object().nullable(),
      subsidiaryPep: yup.object().nullable(),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesPlannedAndPerformedByPepReport,
    { setSubmitting }: FormikHelpers<FormValuesPlannedAndPerformedByPepReport>
  ) => {
    if (!values.endYear || !values.startYear || !values.division)
      throw new Error("All required fields must be filled.");

    try {
      await generatePlannedAndPerformedByPepReport({
        startYear: values.startYear.getFullYear(),
        endYear: values.endYear.getFullYear(),
        IdDivision: values.division.id,
        Subsidiary: values.subsidiary?.name || undefined,
        PepCodes: values.pepCodes.length > 0 ? values.pepCodes : undefined,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => <FormComponent formikContenProps={formikProps} />}
    </Formik>
  );
};
