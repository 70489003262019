import { useTranslation } from "react-i18next";
import { US as USIcon, BR as BRIcon } from "country-flag-icons/react/1x1";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useContext } from "react";
import { ListItemTranslationButtonContainer } from "./index.styles";
import { changePersistLanguage } from "../../../../../services/applicationState/language.service";
import { MenuTooltip } from "../index.styles";
import { LoggedLayoutContext } from "../../shared/LoggedLayoutContext";
import { Language } from "../../../../../shared/common/enums/locale/Language.enum";

export const ListItemTranslationButton = () => {
  const { i18n } = useTranslation();
  const { isMenuOpen } = useContext(LoggedLayoutContext);

  const countryFlag = i18n.language === Language.ptBR ? <BRIcon /> : <USIcon />;

  const countryName = i18n.language === Language.ptBR ? "Português" : "English";

  const toggleLanguage = () => {
    const newLanguage =
      i18n.language === Language.ptBR ? Language.enUS : Language.ptBR;
    changePersistLanguage(newLanguage);
  };

  return (
    <MenuTooltip title={isMenuOpen ? "" : countryName}>
      <ListItemTranslationButtonContainer onClick={toggleLanguage}>
        <ListItemIcon>{countryFlag}</ListItemIcon>
        <ListItemText>{countryName}</ListItemText>
      </ListItemTranslationButtonContainer>
    </MenuTooltip>
  );
};
