import * as yup from "yup";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../project/ProjectOnlyDTO";

export interface ProjectBaselineDTO {
  id: number;
  version: number;
  projectJson: string | null;
  validatedBy: string;
  project: ProjectOnlyDTO;
}

export const schemaProjectBaselineDTO = yup.object({
  id: yup.number().integer().defined(),
  version: yup.number().integer().defined(),
  projectJson: yup.string().nullable().defined(),
  validatedBy: yup.string().defined(),
  project: schemaProjectOnlyDTO.defined(),
});
