import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledContent } from "./index.styles";
import { InvoicesStatusEnum } from "../../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../../../../../shared/specific/maps/invoices/invoicesStatusTypeToTranslationCode";

interface OwnProps {
  milestone: string;
  invoiceNumber?: string;
  invoiceNote?: string;
  delayJustification?: string;
  status?: InvoicesStatusEnum;
}

export const InvoiceObservationsContent = ({
  milestone,
  invoiceNumber,
  invoiceNote,
  delayJustification,
  status,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      <TextField
        label={t("general.keywords.fields.status")}
        InputProps={{ readOnly: true }}
        value={status ? t(invoicesStatusTypeToTranslationCode[status]) : "-"}
      />
      <TextField
        label={t("invoice.keywords.fields.invoiceMilestone")}
        value={milestone ?? "-"}
        InputProps={{ readOnly: true }}
      />
      <TextField
        label={t("invoice.keywords.fields.invoiceNumber")}
        value={invoiceNumber || "-"}
        InputProps={{ readOnly: true }}
      />
      <TextField
        label={t("invoice.keywords.fields.invoiceNote")}
        value={invoiceNote || "-"}
        InputProps={{ readOnly: true }}
      />
      <TextField
        label={t("invoice.keywords.fields.delayJustification")}
        value={delayJustification || "-"}
        InputProps={{ readOnly: true }}
        multiline
        rows={3}
      />
    </StyledContent>
  );
};
