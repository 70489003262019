import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FormValuesTurnoverDays } from "./Types/form.types";
import { TurnoverDaysReportFilterDto } from "../../../../../../../../../shared/specific/DTOs/reports/TurnoverDaysReportFilterDto";
import { generateTurnoverDaysReport } from "../../../../../../../../../services/reports/reports.service";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const TurnoverDays = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesTurnoverDays>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesTurnoverDays = {
      yearMonth: null,
      subsidiary: null,
      division: null,
      customerTrigram: "",
    };

    const validationSchema = yup.object({
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      idSubsidiary: yup.number().nullable(),
      idDivision: yup.number().nullable(),
      customerTrigram: yup.string(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (values: FormValuesTurnoverDays) => {
    const formattedFilters: TurnoverDaysReportFilterDto = {
      yearMonth: values?.yearMonth
        ? YearMonth.createFromDate(values.yearMonth)
        : undefined,
      idSubsidiary: values?.subsidiary?.id ?? undefined,
      idDivision: values?.division?.id ?? undefined,
      customerTrigram: values?.customerTrigram ?? undefined,
    };
    try {
      await generateTurnoverDaysReport(formattedFilters);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage:
          "reports.errors.generation.provisionReport.projectsLimitToNotHaveTimeout",
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="yearMonth"
              datePickerProps={{
                label: t("reports.pages.form.period"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <SubsidiariesAutocompleteFormik name="subsidiary" />
            <CustomerCodesSearchAutocompleteFormik
              name="customerTrigram"
              textfieldProps={{
                label: `${t("costDistribution.keywords.fields.customerTrigram")}`,
              }}
            />
            <DivisionsAutocompleteFormik name="division" />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
