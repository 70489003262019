import { LedgerAccountUploadResponseDTO } from "../../../shared/specific/DTOs/ledgerAccount/LedgerAccountResponseDTO";
import { riseApi } from "./connection";

export const uploadLedgerAccount = async (
  file: File,
  yearMonth: string
): Promise<LedgerAccountUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);
  form.append("yearMonth", yearMonth);

  const { data: response } = await riseApi.post(
    "LedgerAccount/UploadLedgerAccount",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response;
};
