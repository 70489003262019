import * as yup from "yup";
import { riseApi } from "./connection";
import { CollaboratorAllocationFilterDTO } from "../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationFilterDTO";
import {
  CollaboratorAllocationDTO,
  schemaCollaboratorAllocationDTO,
} from "../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationDTO";
import { CollaboratorAllocationModifyDTO } from "../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationModifyDTO";

export const getCollaboratorAllocations = async (
  filters: CollaboratorAllocationFilterDTO
): Promise<CollaboratorAllocationDTO[]> => {
  const { data: collaboratorAllocations } = await riseApi.get(
    "CollaboratorAllocations",
    {
      params: { ...filters },
    }
  );

  return yup
    .array(schemaCollaboratorAllocationDTO)
    .defined()
    .validateSync(collaboratorAllocations);
};

export const ModifyCollaboratorAllocation = async (
  allocation: CollaboratorAllocationModifyDTO
) => {
  await riseApi.put("CollaboratorAllocations", allocation);
};
