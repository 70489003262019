import { useTranslation } from "react-i18next";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getNowBrazilianDate } from "../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormikWithTranslationsModal } from "../../../../../../../shared/common/react/components/form/formik/translatable/TextFieldFormikWithTranslationsModal";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  ContractRoleDataFormValues,
  FormMode,
} from "../../shared/types/form.types";
import { StyledContractRoleCreationForm } from "./index.style";
import { CheckboxFormik } from "../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

type OwnProps = Omit<
  FormContentProps<ContractRoleDataFormValues>,
  "internalFormData"
> & {
  mode: FormMode;
};

export const ContractRoleDataForm = ({
  formikProps: { isSubmitting },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();
  return mode === "editing" ? (
    <StyledContractRoleCreationForm>
      <TextFieldFormikWithTranslationsModal
        name="nameTranslations"
        label={t("general.keywords.fields.name")}
        disabled={isSubmitting}
        inputProps={{ maxLength: 200 }}
        defaultLanguageTextFieldProps={{ required: true }}
      />
      <DatePickerFormik
        name="deactivatedDate"
        datePickerProps={{
          label: t("contractRole.keywords.fields.deactivatedDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: getNowBrazilianDate(),
          disabled: isSubmitting,
        }}
      />
      <FormControlLabel
        label={t("contractRole.keywords.fields.effectiveRole")}
        control={<CheckboxFormik name="effectiveRole" />}
      />
      <FormControlLabel
        label={t("contractRole.keywords.fields.trustedRole")}
        control={<CheckboxFormik name="trustedRole" />}
      />
    </StyledContractRoleCreationForm>
  ) : (
    <>
      <TextFieldFormikWithTranslationsModal
        name="nameTranslations"
        label={t("general.keywords.fields.name")}
        disabled={isSubmitting}
        inputProps={{ maxLength: 200 }}
        defaultLanguageTextFieldProps={{ required: true }}
        fullWidth
      />
      <FormControlLabel
        label={t("contractRole.keywords.fields.effectiveRole")}
        control={<CheckboxFormik name="effectiveRole" />}
      />
      <FormControlLabel
        label={t("contractRole.keywords.fields.trustedRole")}
        control={<CheckboxFormik name="trustedRole" />}
      />
    </>
  );
};
