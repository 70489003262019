import * as yup from "yup";

import {
  AllocationCollaboratorDTO,
  schemaAllocationCollaboratorDTO,
} from "../../../shared/specific/DTOs/allocationMap/AllocationCollaboratorDTO";
import {
  AllocationMapUpdatedDTO,
  schemaAllocationMapUpdatedDTO,
} from "../../../shared/specific/DTOs/allocationMap/AllocationMapUpdatedDTO";
import { AllocationMapFilterDTO } from "../../../shared/specific/DTOs/allocationMap/filters/AllocationMapFilterDTO";
import { UpdateAllocationMapDTO } from "../../../shared/specific/DTOs/allocationMap/UpdateAllocationMapDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getAllocationMap = async ({
  filters,
  pagination,
}: {
  filters?: AllocationMapFilterDTO;
  pagination?: PaginationDTO;
}): Promise<AllocationCollaboratorDTO[]> => {
  const { data: allocationMaps } = await riseApi.post(
    `AllocationsMaps`,
    filters
  );

  return yup
    .array(schemaAllocationCollaboratorDTO)
    .defined()
    .validateSync(allocationMaps);
};

export const updateAllocationMap = async (
  allocationMap: UpdateAllocationMapDTO
): Promise<AllocationMapUpdatedDTO> => {
  const { data: allocationMapUpdated } = await riseApi.put(
    "AllocationsMaps",
    allocationMap
  );

  return schemaAllocationMapUpdatedDTO.validateSync(allocationMapUpdated);
};
