import { useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../shared/common/react/components/table/EnhancedTable";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import { useEffectAfterRenders } from "../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";

export const CollaboratorManagementPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  useEffect(() => {
    setPageTitle(t("collaboratorManagement.general.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const { filterButton, filterModal, filters } = useFilterForm();

  const filterObject =
    belowToolbarFilters.name === ""
      ? filters
      : {
          ...filters,
          ...belowToolbarFilters,
        };

  const formattedFilters = useMemo(() => {
    return filterObject;
  }, [belowToolbarFilters, filters]);

  const {
    viewingCurrentAllocationsModal,
    CollaboratorProfileViewingModal,
    headCells,
    onUserCollaboratorPageChange,
  } = useTableData({
    filters: formattedFilters,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, formattedFilters],
    rendersBeforeEffect: 1,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onUserCollaboratorPageChange,
          },
          defaultRowsPerPage: 10,
        }}
        orderDefaults={{
          defaultOrder: "asc",
          defaultOrderBy: 0,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {viewingCurrentAllocationsModal}
      {CollaboratorProfileViewingModal}
    </>
  );
};
