import { AmountMonthDTO } from "../../../../../../../../../shared/specific/DTOs/general/balance/AmountMonthDTO";
import { ProjectSummaryExpenseAmounts } from "../../../../../../../../../shared/specific/DTOs/project/summary/ProjectSummaryExpenseAmountsDTO";
import { ExpenseType } from "../../../../../../../../../shared/specific/enums/expenses/ExpenseType";

export const getExpenseAmountsDictionary = (
  expenseAmounts: ProjectSummaryExpenseAmounts[]
) => {
  const expenseAmountsDicionary: { [key in ExpenseType]?: AmountMonthDTO[] } =
    {};

  for (const { expenseType, expenses } of expenseAmounts)
    expenseAmountsDicionary[expenseType] = expenses.map(
      (x): AmountMonthDTO => ({
        amount: x.amount,
        yearMonth: x.yearMonth,
      })
    );

  return expenseAmountsDicionary;
};
