import * as yup from "yup";

export enum Week {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export const schemaWeek = yup
  .mixed<Week>()
  .oneOf(Object.values(Week) as Week[]);
