export enum ExpenseGroup {
  AdmCorp = 1,
  Dol = 3,
  Sgea = 4,
  GrossRevenue = 5,
  Tax = 6,
  SalesCost = 2,
  Pdd = 7,
  NetFinancialExpenses = 8,
  IrCsll = 9,
  ParticipationProgram = 10,
}
