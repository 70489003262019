import { styled } from "@mui/material/styles";

interface StyledFixedColumnProps {
  isread: string;
}

export const StyledFixedColumn = styled("div")<StyledFixedColumnProps>`
  height: 60px;
  border-right: solid 3px rgba(224, 224, 224, 1);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: ${({ theme }) => theme.spacing(0.5)};
  height: 60px;
  gap: 0;
`;
