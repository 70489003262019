import * as yup from "yup";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../costCenterPeps/CostCenterPepOnlyDTO";
import { JobRoleDTO, schemaJobRoleDTO } from "../jobRole/JobRoleDTO";
import {
  schemaUserCurrentJobRoleDTO,
  UserCurrentJobRoleDTO,
} from "../user/UserCurrentJobRoleDTO";
import {
  AllocationYearMonthDTO,
  schemaAllocationYearMonthDTO,
} from "./AllocationYearMonthDTO";

export interface AllocationDTO {
  id: number;
  userCurrentJobRole: UserCurrentJobRoleDTO | null;
  allocationType: EmployeeAllocationType;
  costCenterPep: CostCenterPepOnlyDTO;
  jobRole: JobRoleDTO | null;
  allocationGroup: number;
  allocationYearMonth: AllocationYearMonthDTO | null;
}

export const schemaAllocationDTO = yup.object({
  id: yup.number().integer().defined(),
  userCurrentJobRole: schemaUserCurrentJobRoleDTO.nullable().defined(),
  jobRole: schemaJobRoleDTO.nullable().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  costCenterPep: schemaCostCenterPepOnlyDTO.defined(),
  allocationGroup: yup.number().integer().defined(),
  allocationYearMonth: schemaAllocationYearMonthDTO.nullable().defined(),
});
