import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormattedCurrency } from "../../../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { ExtendedCircularProgress } from "../../../../../../../../../../../shared/common/react/components/ui/ExtendedCircularProgress";
import { HeaderLoading } from "../../../../../../../../../../../shared/common/react/components/ui/HeaderLoading";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CostCenterPepListFiltersDTO } from "../../../../../../../../../../../shared/specific/DTOs/costCenterPeps/filters/CostCenterPepListFiltersDTO";
import { CostCenterPepsAutocomplete } from "../../../../../../../../../../../shared/specific/react/components/form/general/autocomplete/fields/costCenterPeps/CostCenterPepsAutocomplete";
import { useProjectContext } from "../../../../../../shared/react/contexts/ProjectContext";
import { StyledInfoItem } from "../../../../../../shared/react/styles/ui/layouts/general.styles";
import { ExpensesSummaryContext } from "../../../../shared/react/contexts/ExpensesSummaryContext";
import {
  CustomStyledInfoItems,
  RelativeContainer,
  StyledFieldContainer,
} from "./index.styles";

interface OwnProps {
  costCenterPep: CostCenterPepOnlyDTO;
  setCostCenterPep: (values: CostCenterPepOnlyDTO) => void;
}

export const ExpensesSummaryContent = ({
  costCenterPep,
  setCostCenterPep,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();
  const { summaryData, isLoadingSummary, hasErrorSummary } = useContext(
    ExpensesSummaryContext
  );

  const costCenterPepsFilters: CostCenterPepListFiltersDTO = useMemo(() => {
    return { idProject: project.id };
  }, [project.id]);

  const summaryArea = useMemo(() => {
    if (hasErrorSummary)
      return t("general.errors.data.fetch.failedLoadingSummary");

    if (!summaryData) {
      if (isLoadingSummary) return <ExtendedCircularProgress size={24} />;
      return t("general.errors.data.general.noSummaryAvailable");
    }

    return (
      <RelativeContainer>
        {isLoadingSummary && <HeaderLoading />}
        <StyledInfoItem>
          <div>{t("expenses.keywords.fields.totalExpensesAmount")}</div>
          <div>
            <FormattedCurrency
              value={summaryData.totalExpensesAmount}
              currencySymbol={costCenterPep.subsidiary.currencySymbol}
            />
          </div>
        </StyledInfoItem>
      </RelativeContainer>
    );
  }, [t, summaryData, isLoadingSummary, hasErrorSummary]);

  return (
    <CustomStyledInfoItems>
      <StyledFieldContainer>
        <CostCenterPepsAutocomplete
          autocompleteProps={{
            value: costCenterPep ?? undefined,
            setValue: setCostCenterPep,
            disableClearable: true,
          }}
          filters={costCenterPepsFilters}
        />
      </StyledFieldContainer>
      {summaryArea}
    </CustomStyledInfoItems>
  );
};
