import { CustomerBalanceTypeEnum } from "../../enums/customerBalance/customerBalance.enum";

export const customerBalanceTypeToTranslationCode: {
  [key in CustomerBalanceTypeEnum]: string;
} = {
  [CustomerBalanceTypeEnum.UnidentifiedDeposit]:
    "customerBalance.keywords.fields.unidentifiedDeposit",
  [CustomerBalanceTypeEnum.ContractualRetention]:
    "customerBalance.keywords.fields.contractualRetention",
  [CustomerBalanceTypeEnum.Tax]: "customerBalance.keywords.fields.tax",
};
