import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Paid as PaidIcon,
  GppGood as GppGoodIcon,
  CreditCard as CreditCardIcon,
  AssignmentInd as AssignmentIndIcon,
} from "@mui/icons-material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AddCardIcon from "@mui/icons-material/AddCard";
import SegmentIcon from "@mui/icons-material/Segment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  PageCardButton,
  PageCardButtonsList,
} from "../../../../../shared/common/react/components/navigation/PageCardButton";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import {
  accessGroupsReadRestrictions,
  brlParityReadRestrictions,
  corporateSegmentsCreateRestrictions,
  dashboardsCreateRestrictions,
  jobRateReadRestrictions,
  jobRoleReadRestrictions,
  monthlyAllocationClosingReadRestrictions,
  uploadExpensesRestrictions,
  uploadLedgerAccountRestrictions,
  uploadPerformedFinancialCostRestrictions,
} from "../../../../shared/constants/routing/restrictions/performanceRestrictions.constants";

export const Performance = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("performance.pages.main.title"));
  }, [t]);

  return (
    <PageCardButtonsList>
      <Protected restrictions={jobRateReadRestrictions}>
        <PageCardButton
          title="jobRates.keywords.general.jobRates"
          Icon={PaidIcon}
          link="/job-rates"
        />
      </Protected>
      <Protected restrictions={brlParityReadRestrictions}>
        <PageCardButton
          title="brlParity.keywords.general.exchangeRates"
          Icon={QueryStatsIcon}
          link="/brl-parity"
        />
      </Protected>
      <Protected restrictions={accessGroupsReadRestrictions}>
        <PageCardButton
          title="accessGroups.keywords.general.accessGroups"
          Icon={GppGoodIcon}
          link="/access-groups"
        />
      </Protected>
      <Protected restrictions={jobRoleReadRestrictions}>
        <PageCardButton
          title="jobRoles.keywords.general.jobRoles"
          Icon={AssignmentIndIcon}
          link="/job-roles"
        />
      </Protected>
      <Protected restrictions={uploadExpensesRestrictions}>
        <PageCardButton
          title="performedExpense.pages.main.title"
          Icon={CreditCardIcon}
          link="/upload-performed-expense"
        />
      </Protected>
      <Protected restrictions={uploadPerformedFinancialCostRestrictions}>
        <PageCardButton
          title="performedFinancialCost.pages.main.title"
          Icon={AddCardIcon}
          link="/upload-performed-financial-cost"
        />
      </Protected>
      <Protected restrictions={dashboardsCreateRestrictions}>
        <PageCardButton
          title="dashboardGroups.pages.main.title"
          Icon={DashboardCustomizeIcon}
          link="/dashboard-groups"
        />
      </Protected>
      <Protected restrictions={dashboardsCreateRestrictions}>
        <PageCardButton
          title="dashboards.pages.main.title"
          Icon={DashboardIcon}
          link="/dashboards"
        />
      </Protected>
      <Protected restrictions={corporateSegmentsCreateRestrictions}>
        <PageCardButton
          title="corporateSegment.pages.main.title"
          Icon={SegmentIcon}
          link="/corporate-segment"
        />
      </Protected>
      <Protected restrictions={monthlyAllocationClosingReadRestrictions}>
        <PageCardButton
          title="monthlyAllocationClosing.general.main.title"
          Icon={EventRepeatIcon}
          link="/monthly-allocation-closing"
        />
      </Protected>
      <Protected restrictions={uploadLedgerAccountRestrictions}>
        <PageCardButton
          title="ledgerAccount.pages.main.title"
          Icon={AccountBalanceWalletIcon}
          link="/ledger-account"
        />
      </Protected>
    </PageCardButtonsList>
  );
};
