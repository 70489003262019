import {
  Activities,
  Additives,
  Allocations,
  BrlParity,
  CodeErrorEnums,
  ColaboratorHistory,
  ContractRole,
  CorporateAllocations,
  CostCenterPeps,
  CostCenters,
  Customers,
  Expenses,
  Frames,
  General,
  Invoice,
  JobRate,
  ProjectBaselines,
  ProjectPlanningValidation,
  Projects,
  ProjectSummary,
  RevenueInvoices,
  Subsidiaries,
  Translations,
  UserCollaborator,
  AccessGroups,
  UserGroup,
  UserPermission,
  Timesheet,
  AllocationMap,
  CollaboratorHistory,
  Dashboards,
  Report,
  Projection,
  Budget,
  CostDistribution,
  CustomerBalance,
  Wacc,
  FeeYearMonth,
} from "../../enums/errors/apiErrors.enums";

export const apiErrorCodeToTranslationCode: {
  readonly [key in CodeErrorEnums]?: string;
} = {
  [General.Forbidden]: "general.errors.authorization.unauthorizedAccessContent",
  [CostCenters.CodeAlreadyExists]:
    "general.errors.data.fields.code.alreadyExists",
  [CostCenters.DivisionDoesNotExist]:
    "divisions.errors.data.general.divisionDoesNotExist",
  [CostCenters.SubsidiaryDoesNotExist]:
    "subsidiaries.errors.data.general.subsidiaryDoesNotExist",
  [CostCenters.CannotUpdateValuesWhenLinkedToProject]:
    "costCenters.errors.data.edit.cannotUpdateValuesWhenLinkedToProject",
  [CostCenters.HasAssociatedTimesheets]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedTimesheets",
  [CostCenters.HasAssociatedAllocation]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedAllocations",
  [CostCenters.HasAssociatedCollaboratorHistory]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedCollaboratorHistory",
  [CostCenters.HasAssociatedCorporateSegment]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedCorporateSegment",
  [CostCenters.HasAssociatedCostCenterPep]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedCostCenterPep",
  [CostCenters.HasAssociatedFrame]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedFrames",
  [CostCenters.HasAssociatedProjectionAdjustment]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedProjectionAdjustments",
  [CostCenters.HasAssociatedCustomerBalance]:
    "costCenters.errors.data.delete.hasOneOrMoreAssociatedCustomerBalance",
  [CostCenters.DoesNotExist]:
    "costCenters.errors.data.general.costCenterDoesNotExist",
  [CostCenters.CodeInvalid]: "general.errors.data.fields.code.invalid",
  [Customers.CodeAlreadyExists]:
    "customers.errors.data.fields.code.alreadyExists",
  [Customers.TradingNameAlreadyExists]:
    "customers.errors.data.fields.tradingName.alreadyExists",
  [Customers.CorporateNameAlreadyExists]:
    "customers.errors.data.fields.corporateName.alreadyExists",
  [Customers.EmailAlreadyExists]:
    "customers.errors.data.fields.email.alreadyExists",
  [Customers.StateCodeAlreadyExists]:
    "customers.errors.data.fields.stateCode.alreadyExists",
  [Customers.RegistrationCodeAlreadyExists]:
    "customers.errors.data.fields.registrationCode.alreadyExists",
  [Customers.MunicipalCodeAlreadyExists]:
    "customers.errors.data.fields.municipalCode.alreadyExists",
  [Customers.CustomersDoesNotExist]:
    "customers.errors.data.general.customerDoesNotExist",
  [Customers.HasLinkedProjectsFrames]:
    "customers.errors.data.delete.customerLinkedOneMoreProjects",
  [Customers.CodeInvalid]: "general.errors.data.fields.code.invalid",
  [General.NotEmpty]: "general.errors.data.fields.general.notEmpty",
  [General.MustBeEmpty]: "general.errors.data.fields.general.mustBeEmpty",
  [General.DoesNotExist]: "general.errors.data.fields.general.doesNotExist",
  [General.Required]: "general.errors.data.fields.general.required",
  [General.ExactLength]: "general.errors.data.fields.general.exactLength",
  [General.MustBePositive]:
    "general.errors.data.fields.general.numbers.mustBePositive",
  [General.MinimumLength]: "general.errors.data.fields.general.minimumLength",
  [General.MaximumLength]: "general.errors.data.fields.general.maximumLength",
  [General.GreaterThanOrEqualTo]:
    "general.errors.data.fields.general.greaterThanOrEqualTo",
  [General.LessThanOrEqualTo]:
    "general.errors.data.fields.general.lessThanOrEqualTo",
  [General.InvalidEmail]: "general.errors.data.fields.email.invalid",
  [General.InvalidPhoneNumber]:
    "general.errors.data.fields.phoneNumber.invalid",
  [General.InvalidCep]: "general.errors.data.fields.cep.invalid",
  [General.InvalidCnpj]: "general.errors.data.fields.cnpj.invalid",
  [General.ArrayCannotBeEmpty]:
    "general.errors.data.fields.general.arrayCannotBeEmpty",
  [General.UpdateConcurrencyError]:
    "general.errors.data.general.concurrencyError",
  [General.Validation]: "general.errors.data.general.validation",
  [General.IdsToUpdateDoNotExist]:
    "general.errors.data.general.someItemsToBeUpdateDoNotExist",
  [General.LanguagesDoNotExist]:
    "general.errors.data.general.languagesDoNotExist",
  [General.ValueToDeleteDoesNotExist]:
    "general.errors.data.delete.valueToDeleteDoesNotExist",
  [General.ValueToUpdateDoesNotExist]:
    "general.errors.data.delete.valueToupdateDoesNotExist",
  [General.InvalidDate]: "general.errors.data.fields.dates.invalidDate",
  [General.DateOlderAcceptableLimit]:
    "general.errors.data.fields.dateOlderAcceptableLimit",
  [General.MutuallyExclusiveFields]:
    "general.errors.data.fields.general.writingMutuallyExclusiveFields",
  [General.InvalidContentType]:
    "general.errors.data.fields.general.invalidContentType",
  [JobRate.JobRateAlreadyExists]: "jobRates.errors.data.general.alreadyExists",
  [JobRate.JobRoleDependencyExists]:
    "jobRates.errors.data.dependency.jobRoleDependency",
  [ColaboratorHistory.JobRoleDependencyExists]:
    "general.errors.data.general.dependencyError",
  [ColaboratorHistory.CostCenterDependencyExists]:
    "costCenters.errors.data.delete.cannotDeleteWhenCollaboratorTrackAssociated",
  [ColaboratorHistory.CannotHaveTwoEntriesSameYearMonth]:
    "collaboratorTrack.errors.data.write.cannotHaveTwoEntriesSameYearMonth",
  [ColaboratorHistory.UserCollaboratorDoesNotExist]:
    "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist",
  [ColaboratorHistory.StartYearMonthCannotBeforeAdmissionDate]:
    "collaboratorTrack.errors.data.fields.startYearMonth.cannotBeforeAdmissionDate",
  [ColaboratorHistory.CollaboratorHistoryStartYearMonthCannotBeforeCurrentDate]:
    "collaboratorTrack.errors.data.fields.startYearMonth.cannotBeforeCurrentDate",
  [Translations.DefaultTranslationCodeMissing]:
    "general.errors.data.fields.general.defaultTranslationCodeMissing",
  [Projects.DoesNotExist]: "projects.errors.data.general.projectDoesNotExist",
  [Projects.CustomerDoesNotExistOrDependencyExists]:
    "customers.errors.data.general.customerDoesNotExistOrDependencyExists",
  [Projects.DivisionDoesNotExist]:
    "divisions.errors.data.general.divisionDoesNotExist",
  [Projects.CustomerDoesNotExist]:
    "customers.errors.data.general.customerDoesNotExist",
  [Projects.CustomerCannotUpdateCurrentStage]:
    "customers.errors.data.general.cannotUpdateCurrentStage",
  [Projects.DivisionMustHavePrefix]:
    "projects.errors.data.fields.division.mustHavePrefix",
  [Projects.SubsidiaryDoesNotExist]:
    "subsidiaries.errors.data.general.subsidiaryDoesNotExist",
  [Projects.UserCoordinatorDoesNotExist]:
    "projects.errors.data.fields.coordinator.doesNotExist",
  [Projects.UserManagerDoesNotExist]:
    "projects.errors.data.fields.manager.doesNotExist",
  [Projects.CfopDoesNotExist]: "cfops.errors.data.general.cfopDoesNotExist",
  [Projects.ProductServiceDoesNotExist]:
    "productServices.errors.data.fields.productServiceDoesNotExist",
  [Projects.CodeAlreadyExists]: "general.errors.data.fields.code.alreadyExists",
  [Projects.NameAlreadyExists]: "general.errors.data.fields.name.alreadyExists",
  [Projects.FrameDoesNotExistOrDependencyExists]:
    "frames.errors.data.general.frameDoesNotExistOrDependencyExists",
  [Projects.ProjectCodeInvalid]: "general.errors.data.fields.code.invalid",
  [Projects.FrameCustomerCodesDoNotMatch]:
    "projects.errors.data.fields.frame.customerCodesDoNotMatch",
  [Projects.FrameDivisionsDoNotMatch]:
    "projects.errors.data.fields.frame.divisionsDoNotMatch",
  [Projects.FrameCurrenciesDoNotMatch]:
    "projects.errors.data.fields.frame.currenciesDoNotMatch",
  [Projects.ExceedsFrameAmount]:
    "projects.errors.data.fields.billingAmount.exceedsFrameAmount",
  [Projects.FrameDoesNotMatchCostCenterPeps]:
    "projects.errors.data.fields.frame.doesNotMatchCostCenterPeps",
  [Projects.ScheduledStartDateAfterScheduledEndDate]:
    "projects.errors.data.fields.frame.doesNotMatchCostCenterPeps",
  [Projects.DivisionCanOnlyUpdateOnCreated]:
    "projects.errors.data.fields.division.divisionCanOnlyUpdateOnCreated",
  [Projects.PlanningMustHavePepWithSameSubsidiary]:
    "projects.errors.data.writePlanning.noPepsWithSameSubsidiary",
  [Projects.CannotUpdatePlanningAfterPlanningStage]:
    "projects.errors.data.general.cannotUpdatePlanningAfterPlanningStage",
  [Projects.CannotUpdateFinalizedCanceledProjects]:
    "projects.errors.data.update.finalizedCanceledProjectCannotBeUpdated",
  [Projects.CannotUpdateBlockedFieldsAfterPlanning]:
    "projects.errors.data.update.cannotUpdateBlockedFieldsAfterPlanning",
  [Projects.AmountCategoryNoMatches]:
    "projects.errors.data.general.noProjectCategoryMatches",
  [Projects.FrameMustHaveSameSubsidiary]:
    "projects.errors.data.fields.frame.mustHaveSameSubsidiary",
  [Projects.FrameMustHaveSameSubsidiaryAsFrameCostCenter]:
    "projects.errors.data.fields.frame.mustHaveSameSubsidiaryAsFrameCostCenter",
  [Projects.ManagerDoesNotExist]:
    "projects.errors.data.fields.manager.doesNotExist",
  [Projects.ManagerIsDeactivated]:
    "projects.errors.data.fields.manager.isDeactivated",
  [Projects.CoordinatorDoesNotExist]:
    "projects.errors.data.fields.coordinator.doesNotExist",
  [Projects.CoordinatorIsDeactivated]:
    "projects.errors.data.fields.coordinator.isDeactivated",
  [Projects.ScheduledStartDateThereAreDatesBefore]:
    "projects.errors.data.fields.baselineStart.thereAreDatesBeforeNewBaselineStart",
  [Projects.CannotReopenCurrentStatus]:
    "projects.errors.data.reopen.cannotReopenUnlessFinished",
  [Projects.SubsidiaryCannotUpdateCurrentStage]:
    "projects.errors.data.fields.subsidiary.cannotUpdateBillingCountryCurrentStage",
  [Frames.CostCenterDoesNotExist]:
    "costCenters.errors.data.general.costCenterDoesNotExist",
  [Frames.CustomerDoesNotExist]:
    "customers.errors.data.general.customerDoesNotExist",
  [Frames.DivisionDoesNotExist]:
    "divisions.errors.data.general.divisionDoesNotExist",
  [Frames.InvalidPepCode]: "frames.errors.data.general.invalidPepCode",
  [Frames.DivisionDoesNotMatch]:
    "frames.errors.data.general.divisionDoesNotMatch",
  [Frames.SubsidiaryDoesNotMatch]:
    "frames.errors.data.general.subsidiaryDoesNotMatch",
  [Frames.InvalidSubsidiaryPrefixLength]:
    "frames.errors.data.general.subsidiaryPrefixLength",
  [Frames.InvalidDivisionPrefixLength]:
    "frames.errors.data.general.divisionPrefixLength",
  [Frames.DeactivatedCostCenters]:
    "frames.errors.data.general.deactivatedCostCenters",
  [Frames.AmountInvalid]: "frames.errors.data.general.amountInvalid",
  [Frames.StartDateAfterEndDate]:
    "frames.errors.data.general.startDateAfterEndDate",
  [Frames.FrameDoesNotExist]: "frames.errors.data.general.frameDoesNotExist",
  [Frames.CannotUpdateValuesWhenLinkedToProject]:
    "frames.errors.data.general.cannotUpdateValuesWhenLinkedToProject",
  [Frames.FrameAlreadyExists]: "frames.errors.data.general.frameAlreadyExists",
  [Subsidiaries.SubsidiaryDoesNotExist]:
    "subsidiaries.errors.data.general.subsidiaryDoesNotExist",
  [CostCenterPeps.DoesNotExist]:
    "costCenterPeps.errors.data.general.pepDoesNotExist",
  [CostCenterPeps.SubsidiaryDoesNotExist]:
    "subsidiaries.errors.data.general.subsidiaryDoesNotExist",
  [CostCenterPeps.CostCenterDoesNotExist]:
    "costCenters.errors.data.general.costCenterDoesNotExist",
  [CostCenterPeps.ProjectDoesNotExist]:
    "costCenterPeps.errors.data.fields.pepCode.doesNotExist",
  [CostCenterPeps.PepCodeInvalid]:
    "costCenterPeps.errors.data.fields.pepCode.invalid",
  [CostCenterPeps.CostCenterDivisionDifferentFromProject]:
    "costCenterPeps.errors.data.fields.costCenter.divisionDifferentFromProject",
  [CostCenterPeps.CostCenterSubsidiaryDoesNotMatch]:
    "costCenterPeps.errors.data.fields.costCenter.subsidiaryDoesNotMatch",
  [CostCenterPeps.CostCenterDeactivated]:
    "costCenterPeps.errors.data.fields.costCenter.deactivated",
  [CostCenterPeps.DivisionInvalidPrefixLength]:
    "costCenterPeps.errors.data.fields.division.invalidPrefixLength",
  [CostCenterPeps.SubsidiaryInvalidPrefixLength]:
    "costCenterPeps.errors.data.fields.subsidiary.invalidPrefixLength",
  [CostCenterPeps.CannotTaxWithoutBrazilianSubsidiary]:
    "costCenterPeps.errors.data.general.cannotTaxWithoutBrazilianSubsidiary",
  [CostCenterPeps.CannotUpdateTheseFieldsAfterCreated]:
    "costCenterPeps.errors.data.update.triedUpdatingFieldsThatCannotAfterCreated",
  [CostCenterPeps.CannotUpdateTheseFieldsAfterPlanning]:
    "costCenterPeps.errors.data.update.triedUpdatingFieldsThatCannotAfterPlanning",
  [CostCenterPeps.CannotUpdateMainPepSubsidiaryAfterCreated]:
    "costCenterPeps.errors.data.update.cannotUpdateMainPepSubsidiaryAfterCreated",
  [CostCenterPeps.ProjectSubsidiaryComboAlreadyExists]:
    "costCenterPeps.errors.data.fields.general.projectSubsidiaryComboAlreadyExists",
  [CostCenterPeps.PepCodeDoesNotMatchFrame]:
    "costCenterPeps.errors.data.fields.pepCode.doesNotMatchFrame",
  [CostCenterPeps.CostCenterDoesNotMatchFrame]:
    "costCenterPeps.errors.data.fields.costCenter.doesNotMatchFrame",
  [CostCenterPeps.SubsidiaryDoesNotMatchFrame]:
    "costCenterPeps.errors.data.fields.subsidiary.doesNotMatchFrame",
  [CostCenterPeps.CannotDeleteMainProjectPepAfterPlanning]:
    "costCenterPeps.errors.data.delete.cannotDeleteMainProjectPepAfterPlanning",
  [CostCenterPeps.CannotDeleteWhenDependedUpon]:
    "costCenterPeps.errors.data.delete.pepCannotBeDeletedWhenOtherEntriesDepend",
  [CostCenterPeps.CfopDoesNotExist]:
    "costCenterPeps.errors.data.create.cfopNotFound",
  [CostCenterPeps.CostCenterPepBrCfopCanNotBeNull]:
    "costCenterPeps.errors.data.create.cfopCanNotBeNull",
  [Activities.ActivitiesCompletedDateMustBeLessOrEqualBaseLineEndDate]:
    "activities.errors.data.write.completedDateMustBeLessOrEqualBaseLineEndDate",
  [Activities.CannotCreateWhenNoMainProjectPep]:
    "activities.errors.data.create.cannotCreateWhenNoMainProjectPep",
  [Activities.CostCenterPepDoesNotExist]:
    "costCenterPeps.errors.data.general.pepDoesNotExist",
  [Activities.DeprovisionTypeDoesNotExist]:
    "activities.errors.data.create.cannotCreateWhenNoMainProjectPep",
  [Activities.ScheduledDateMustBeGreaterOrEqualThanMonth]:
    "activities.errors.data.fields.scheduledDate.mustBeGreaterOrEqualThanMonth",
  [Activities.CannotDeleteWhenClosed]:
    "activities.errors.data.delete.cannotDeleteWhenClosed",
  [Activities.ScheduledDateMustBeGreaterOrEqualThanProjectStartDate]:
    "activities.errors.data.fields.scheduledDate.mustBeGreaterOrEqualThanProjectStartDate",
  [Activities.ProjectMissingScheduledStartDate]:
    "activities.errors.data.write.projectMissingScheduledStartDate",
  [Activities.AlreadyClosed]: "activities.errors.data.close.alreadyClosed",
  [Activities.AlreadyOpen]: "activities.errors.data.reopen.alreadyOpen",
  [Activities.CannotReopenBeforeCurrentMonth]:
    "activities.errors.data.reopen.cannotReopenBeforeCurrentMonth",
  [Activities.CannotWriteCurrentStage]:
    "activities.errors.data.write.cannotWriteCurrentStage",
  [Activities.CompletedDateMustBeGreaterOrEqualThanProjectStartDate]:
    "activities.errors.data.fields.completedDate.mustBeGreaterOrEqualThanProjectStartDate",
  [Activities.CompletedDateNotAllowed]:
    "activities.errors.data.fields.completedDate.notAllowed",
  [Activities.ActivityDoesNotExist]:
    "activities.errors.data.general.activityDoesNotExist",
  [Activities.AmountMakesBalanceNegativeAtLeastOneYearMonth]:
    "activities.errors.data.write.amountMakesBalanceNegativeAtLeastOneYearMonth",
  [Activities.CannotUpdateClosedActivity]:
    "activities.errors.data.write.cannotUpdateClosedActivity",
  [Activities.CannotReopenDeprovision]:
    "activities.errors.data.reopen.cannotReopenDeprovision",
  [Activities.ScheduledDateDeprovisionCannotUpdate]:
    "activities.errors.data.fields.scheduledDate.deprovisionCannotUpdate",
  [Activities.ScheduledDateDeprovisionMustBeLessOrEqualThanToday]:
    "activities.errors.data.fields.scheduledDate.deprovisionMustBeLessOrEqualThanToday",
  [Activities.AmountGreaterThanProjectLimit]:
    "activities.errors.data.fields.amount.greaterThanProjectLimit",
  [Activities.AmountCategoryInvalidLimit]:
    "activities.errors.data.write.projectCategoryInvalidLimit",
  [Activities.ProjectDoesNotExist]:
    "projects.errors.data.general.projectDoesNotExist",
  [Activities.AmountExceedsProjectBudget]:
    "activities.errors.data.fields.amount.revenueAmountWouldBeGreaterThanBudget",
  [Invoice.InvoiceCannotWriteCurrentStage]:
    "invoice.errors.data.write.cannotWriteCurrentStage",
  [Invoice.CostCenterPepDoesNotExist]:
    "costCenterPeps.errors.data.general.pepDoesNotExist",
  [Invoice.CannotCreateWhenNoMainProjectPep]:
    "invoice.errors.data.write.cannotWriteWhenNoMainPep",
  [Invoice.InvoicesCannotHaveNegativeAmount]:
    "invoice.errors.data.write.cannnotHaveNegativeAmount",
  [Invoice.PlannedBillingDateCannotBeforeCurrentMonth]:
    "invoice.errors.data.fields.plannedBillingDate.cannotBeforeCurrentMonth",
  [Invoice.PlannedBillingDateCannotBeforeProjectStart]:
    "invoice.errors.data.fields.plannedBillingDate.cannotBeforeProjectStart",
  [Invoice.InvoicesPlannedBillingDateAfterProjectEndDelayJustificationRequired]:
    "invoice.errors.data.fields.plannedBillingDate.afterProjectEndDelayJustificationRequired",
  [Invoice.IssueDateCannotBeBeforeCurrentMonth]:
    "invoice.errors.data.issueInvoice.cannotBeBeforeCurrentMonth",
  [Invoice.IssueDateAfterProjectEndDelayJustificationRequired]:
    "invoice.errors.data.fields.issueDate.afterProjectEndDelayJustificationRequired",
  [Invoice.CannotWriteBeforePlanning]:
    "invoice.errors.data.write.cannotWriteBeforePlanning",
  [Invoice.CannotDeleteIssuedInvoice]:
    "invoice.errors.data.delete.cannotDeleteIssuedInvoice",
  [Invoice.InvoicesUpdateStatusReleased]:
    "invoice.errors.data.update.invoicesUpdateStatusReleased",
  [Invoice.LimitDateRequestDateAreRequired]:
    "invoice.errors.data.update.limitDateRequestDateAreRequired",
  [Invoice.InvoicesUpdateStatusReturned]:
    "invoice.errors.data.update.invoicesUpdateStatusReturned",
  [Invoice.InvoiceNoteIsRequired]:
    "invoice.errors.data.update.invoiceNoteIsRequired",
  [Invoice.InvoicesUpdateStatusAnalysis]:
    "invoice.errors.data.update.invoicesUpdateStatusAnalysis",
  [Invoice.InvoicesUpdateStatusIssued]:
    "invoice.errors.data.update.invoicesUpdateStatusIssued",
  [Invoice.InvoicesUpdateToIssuedMissingFields]:
    "invoice.errors.data.update.invoicesUpdateToIssuedMissingFields",
  [Invoice.InvoicesUpdateToPaidOutMissingDueDate]:
    "invoice.errors.data.update.invoicesUpdateToPaidOutMissingDueDate",
  [Invoice.InvoicesUpdateStatusPaidOut]:
    "invoice.errors.data.update.invoicesUpdateStatusPaidOut",
  [Invoice.InvoicesUpdateStatusCancellation]:
    "invoice.errors.data.update.invoicesUpdateStatusCancellation",
  [Invoice.InvoicesUpdateStatusProvisionCancellation]:
    "invoice.errors.data.update.invoicesUpdateStatusProvisionCancellation",
  [Invoice.InvoicesUpdateToCancellationMissingFields]:
    "invoice.errors.data.update.invoicesUpdateToCancellationMissingFields",
  [Invoice.InvoicesUpdateToProvisionCancellationMissingFields]:
    "invoice.errors.data.update.invoicesUpdateToProvisionCancellationMissingFields",
  [Invoice.InvoicesPaymentDateShouldBeAfterIssuedDate]:
    "invoice.errors.data.update.invoicesPaymentDateShouldBeAfterIssuedDate",
  [Invoice.InvoicesDueDateShouldBeAfterIssuedDate]:
    "invoice.errors.data.update.invoicesDueDateShouldBeAfterIssuedDate",
  [Invoice.InvoicesStatusUpdateToNotReleased]:
    "invoice.errors.data.update.invoicesStatusUpdateToNotReleased",
  [Invoice.InvoicesUpdateStatusCancellationAnalysis]:
    "invoice.errors.data.update.invoicesUpdateStatusCancellationAnalysis",
  [Invoice.LiquidValueMustBeLessThanBillingAmount]:
    "invoice.errors.data.update.liquidValueMustBeLessThanBillingAmount",
  [Invoice.InvoiceBillingMustBeMoreThanZero]:
    "invoice.errors.data.write.mustBeMoreThanZero",
  [Invoice.InvoicesUpdateStatusLoss]:
    "invoice.errors.data.update.invoicesUpdateStatusLoss",
  [Invoice.InvoicesUpdateStatusPdd]:
    "invoice.errors.data.update.invoicesUpdateStatusPdd",
  [Invoice.InvoicesLossDateShouldBeAfterCurrentDate]:
    "invoice.errors.data.update.invoicesLossDateShouldBeAfterCurrentDate",
  [Invoice.InvoicesPddDateShouldBeBeforeCurrentDate]:
    "invoice.errors.data.update.invoicesPddDateShouldBeBeforeCurrentDate",
  [ContractRole.ContrctRoleDependencyExists]:
    "general.errors.data.general.dependencyError",
  [AllocationMap.OutOfBoundsPeriod]:
    "allocationsMap.errors.data.general.outOfBoundsPeriod",
  [Allocations.AllocationsYearMonthOneOrMoreOutAfterScheduledEndDate]:
    "allocations.errors.data.write.allocationsYearMonthOneOrMoreOutAfterScheduledEndDate",
  [Allocations.CostCenterDoesNotExist]:
    "costCenters.errors.data.general.costCenterDoesNotExist",
  [Allocations.CostCenterPepDoesNotExist]:
    "costCentersPep.errors.data.general.pepDoesNotExist",
  [Allocations.JobRoleDoesNotExist]:
    "jobRoles.errors.data.general.jobRoleDoesNotExist",
  [Allocations.WritingMutuallyExclusiveFields]:
    "general.errors.data.fields.general.writingMutuallyExclusiveFields",
  [Allocations.PeriodRangeNotDefined]:
    "allocations.errors.data.general.periodRangeNotDefined",
  [Allocations.YearMonthOneOrMoreOutOfBoundsPeriod]:
    "allocations.errors.data.write.oneOrMoreOutOfBoundsPeriod",
  [Allocations.CannotWriteCurrentStage]:
    "allocations.errors.data.write.cannotWriteCurrentStage",
  [Allocations.AllocationAlreadyExists]:
    "allocations.errors.data.general.allocationAlreadyExists",
  [Allocations.UserColaboratorDoesNotExist]:
    "allocations.errors.data.fields.userColaborator.userColaboratorDoesNotExist",
  [Allocations.YearMonthCannotBeforeCurrentMonth]:
    "allocations.errors.data.fields.yearMonth.cannotBeforeCurrentMonth",
  [Allocations.PepDoesNotExist]:
    "costCenterPeps.errors.data.general.pepDoesNotExist",
  [Allocations.NoJobRolesToUpdateToUser]:
    "allocations.errors.data.update.noJobRolesToUpdateToUser",
  [Allocations.CannotReplaceExistingUserAllocation]:
    "allocations.errors.data.update.cannotReplaceExistingUserAllocation",
  [Allocations.UserColaboratorDoesNotMatchJobRole]:
    "allocations.errors.data.fields.userColaborator.doesNotMatchJobRole",
  [Allocations.PercentageCannotBeZeroOnCreate]:
    "allocations.errors.data.fields.percentage.cannotBeZeroOnCreate",
  [Allocations.AllocationGroupDoesNotExist]:
    "allocations.errors.data.update.allocationRowDoesNotExist",
  [Allocations.NotJobRoleAllocation]:
    "allocations.errors.data.updatedToUserAllocation.notJobRoleAllocation",
  [Allocations.OfficeAndPepSubsidiariesDoNotMatchForPeriod]:
    "allocations.errors.data.write.officeAndPepSubsidiariesDoNotMatch",
  [Allocations.CollaboratorHistoryNoHistoryFound]:
    "allocations.errors.data.fields.userColaborator.colaboratorNeverHadJobRole",
  [Allocations.IdUserCollaboratorJobRolesForPeriodDoNotMatch]:
    "allocations.errors.data.updatedToUserAllocation.jobRolesForPeriodDoNotMatch",
  [Allocations.YearMonthOutOfBoundsPeriod]:
    "allocations.errors.data.write.outOfBoundsPeriod",
  [Allocations.MonthlyAllocationIsClosing]:
    "uploadCorporateAllocation.modal.data.errorMensagens.monthlyAllocationClosed",
  [Allocations.AvoidCreateAllocationWithTwoOrMoreHistory]:
    "uploadCorporateAllocation.modal.data.errorMensagens.avoidCreateAllocationWithTwoOrMoreHistory",
  [Additives.CannotSubmitWithAdditiveWithoutApprovedCredit]:
    "additives.errors.data.general.cannotSubmitWithAdditiveWithoutApprovedCredit",
  [Additives.CannotSubmitCurrentState]:
    "additives.errors.data.write.cannotSubmitCurrentState",
  [Additives.ProjectDoesNotExist]:
    "projects.errors.data.general.projectDoesNotExist",
  [Additives.ProjectPlanningHasNotStarted]:
    "additives.errors.data.write.cannotSubmitBeforePlanning",
  [Additives.AmountMakesProjectAmountNegative]:
    "additives.errors.data.fields.amount.makesProjectBudgetNegative",
  [Additives.AmountLessThanActivitiesAmount]:
    "additives.errors.data.fields.amount.lessActivitiesAmount",
  [Additives.AnticipationJustificationRequired]:
    "additives.errors.data.fields.anticipationJustification.required",
  [Additives.AdditivesMvProjectRequired]:
    "deadlineAdditives.modal.error.MvRequired",
  [Additives.AdditivesScheduledStartDateCannotBeLessThanProjectStartDate]:
    "deadlineAdditives.modal.error.scheduledStartDateLessThanProjectDate",
  [Additives.AdditivesUserCollaboratorNotFound]:
    "deadlineAdditives.modal.error.userCollaboratorNotFound",
  [Expenses.CostCenterPepDoesNotExist]:
    "costCenterPeps.errors.data.general.pepDoesNotExist",
  [Expenses.AmountCannotBeZeroOnCreate]:
    "expenses.errors.data.fields.amount.cannotBeZeroOnCreate",
  [Expenses.CannotWriteCurrentStage]:
    "expenses.errors.data.write.cannotWriteCurrentStage",
  [Expenses.YearMonthOutOfBoundsPeriod]:
    "expenses.errors.data.fields.yearMonth.oneOrMoreOutOfBoundsPeriod",
  [Expenses.ExpenseTypeCannotModifyExpense]:
    "expenses.errors.data.fields.expenseType.cannotModifyExpenseOfProvidedType",
  [Expenses.YearMonthCannotBeforeCurrentMonth]:
    "expenses.errors.data.fields.yearMonth.cannotBeforeCurrentMonth",
  [Expenses.PerformedExpensesUploadLedgerAccountThereIsNoPerformedExpense]:
    "expenses.errors.data.general.performedExpensesUploadLedgerAccountThereIsNoPerformedExpense",
  [BrlParity.BrlParityAlreadyExists]:
    "brlParity.errors.data.general.brlParityAlreadyExist",
  [ProjectSummary.MainPepDoesNotExist]:
    "planningSummary.errors.data.general.projectDoesNotHaveMainPep",
  [ProjectPlanningValidation.CannotWriteCurrentStage]:
    "projects.errors.data.savePlanning.cannotWriteCurrentStage",
  [ProjectPlanningValidation.InvalidProject]:
    "projects.keywords.projectValidation.finalScheduledEndBeforeNow",
  [ProjectPlanningValidation.DelayJustificationRequired]:
    "projects.errors.data.fields.delayJustification.required",
  [ProjectPlanningValidation.ProjectDoesNotExist]:
    "projects.errors.data.general.projectDoesNotExist",
  [ProjectPlanningValidation.MainPepDoesNotExist]:
    "planningSummary.errors.data.general.projectDoesNotHaveMainPep",
  [ProjectPlanningValidation.AmountCategoryConfigurationDoesNotExist]:
    "projects.errors.data.general.noProjectCategoryMatches",
  [ProjectPlanningValidation.CannotFinishUnsavedBaseline]:
    "projects.errors.data.finishPlanning.cannotFinishUnsavedBaseline",
  [ProjectPlanningValidation.PlanningAlreadySaved]:
    "projects.errors.data.savePlanning.planningAlreadySavedAndValid",
  [ProjectBaselines.ProjectDoesNotExist]:
    "projects.errors.data.general.projectDoesNotExist",
  [UserCollaborator.DependencyExists]:
    "general.errors.data.general.dependencyError",
  [UserCollaborator.LoginAlreadyExists]:
    "usersCollaborators.errors.data.fields.login.alreadyExists",
  [UserCollaborator.DateMustBeGreaterThanCurrentOne]:
    "usersCollaborators.errors.data.edit.dateMustBeGreaterThanCurrentOne",
  [UserCollaborator.RegistryNumberAlreadyExist]:
    "usersCollaborators.errors.data.edit.registryNumberAlreadyExist",
  [UserCollaborator.LoginCannotUpdateWhenUserInnactive]:
    "usersCollaborators.errors.data.fields.login.cannotUpdateWhenUserInnactive",
  [UserCollaborator.workloadDependencyExists]:
    "usersCollaborators.errors.data.fields.workload.dependencyExists",
  [UserCollaborator.DisciplineDependencyExists]:
    "disciplines.errors.data.delete.cannotDeleteAssociatedCollaborator",
  [RevenueInvoices.MainPepDoesNotExist]:
    "projects.errors.data.general.projectDoesNotHaveMainPep",
  [CorporateAllocations.NoPermissionToAccessCalamityCorporateSegment]:
    "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToAccessCalamityCorporateSegment",
  [CorporateAllocations.NoPermissionToAccessHECompensationCorporateSegment]:
    "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToAccessHECompensationCorporateSegment",
  [CorporateAllocations.CannotReplaceExistingAllocations]:
    "corporateAllocations.errors.data.write.cannotReplaceExistingAllocations",
  [CorporateAllocations.AllocationTypeCannotBeTypeAllocation]:
    "corporateAllocations.errors.data.fields.allocationType.cannotBeTypeAllocation",
  [CorporateAllocations.YearMonthCannotBeforeCurrentMonth]:
    "corporateAllocations.errors.data.fields.yearMonth.cannotBeforeCurrentMonth",
  [CorporateAllocations.CostCenterDoesNotExist]:
    "costCenters.errors.data.general.costCenterDoesNotExist",
  [CorporateAllocations.CollaboratorHistoryNoHistoryFound]:
    "allocations.errors.data.fields.userColaborator.colaboratorNeverHadJobRole",
  [CorporateAllocations.AllocationGroupIsNotCorporateAllocation]:
    "corporateAllocations.errors.data.fields.allocationGroup.doesNotBelongToCorporateAllocation",
  [CorporateAllocations.NoPermissionToUseThatAllocation]:
    "uploadProjectAllocation.modal.data.errorMensagens.noPermissionToUseThatAllocation",
  [AccessGroups.accessGroupDependencyExists]:
    "accessGroups.errors.data.delete.accessGroupDependencyExists",
  [AccessGroups.accessGroupAlreadyExists]:
    "accessGroups.errors.data.create.accessGroupAlreadyExists",
  [UserGroup.userCollaboratorDependencyExists]:
    "accessGroups.errors.data.delete.accessGroupDependencyExists",
  [UserGroup.userGroupDependencyExists]:
    "accessGroups.errors.data.delete.accessGroupDependencyExists",
  [UserGroup.userAlreadyIncludeInAccessGroup]:
    "accessGroups.errors.data.create.userAlreadyIncludeInAccessGroup",
  [UserPermission.userCollaboratorDependencyExists]:
    "users.errors.data.delete.collaboratorDependencyExists",
  [UserPermission.userCollaboratorPermissionAlreadyExists]:
    "users.errors.data.create.userPermissionAlreadyExists",
  [Timesheet.UserCollaboratorDoesNotExist]:
    "general.errors.data.fields.userCollaborator.doesNotExist",
  [Timesheet.CostCenterDoesNotExist]:
    "general.errors.data.fields.costCenter.doesNotExist",
  [Timesheet.ProjectDoesNotExist]:
    "general.errors.data.fields.project.doesNotExist",
  [Timesheet.CreateMutuallyExclusiveFields]:
    "general.errors.data.fields.general.writingMutuallyExclusiveFields",
  [Timesheet.CreateAlreadyExistsForMonth]:
    "timesheets.errors.data.create.timesheetAlreadyExistsForMonth",
  [Timesheet.DeleteNoItemsFound]:
    "timesheets.errors.data.delete.noEntriesFoundToDelete",
  [Timesheet.MinutesMakesTotalForDayGreaterThanDay]:
    "timesheets.errors.data.fields.minutes.wouldBecomeGreaterThan24Hours",
  [Timesheet.CostCenterNoCollaboratorEntryToGetCostCenter]:
    "timesheets.errors.data.fields.collaboratorHistory.noCollaboratorEntryToGetCostCenterFrom",
  [CollaboratorHistory.RegistryNumberCannotBeSameAsAnyIntern]:
    "collaboratorTrack.errors.data.fields.registryNumber.cannotBeSameAsAnyIntern",
  [CollaboratorHistory.CollaboratorIsAnIntern]:
    "uploadCollaboratorHistory.modal.data.errorMensagens.effectiveRoleIntern",
  [Dashboards.DashboardDependencyExists]:
    "dashboards.errors.data.fields.dashboardDependencyExists",
  [Report.ProjectsLimitToNotHaveTimeout]:
    "reports.errors.generation.provisionReport.projectsLimitToNotHaveTimeout",
  [Projection.ProjectionYearMonthAlreadyExists]:
    "projection.errors.data.create.projectionYearMonthAlreadyExists",
  [CorporateAllocations.UserCollaboratorDiferentSubsidiary]:
    "uploadCorporateAllocation.modal.data.errorMensagens.collaboratorDiferentSubsidiary",
  [CorporateAllocations.costCenterForAllowance]:
    "uploadCorporateAllocation.modal.data.errorMensagens.costCenterForAllowance",
  [Budget.BudgetDoesNotExist]: "budget.errors.data.general.budgetDoesNotExist",
  [Budget.BudgetRoundBeginDateAndEndDateMustBeInTheSameYear]:
    "budget.errors.data.general.budgetRoundDoesNotExist",
  [Budget.BudgetRoundBeginDateMustBeLessThanEndDate]:
    "budget.errors.data.general.beginDateMustBeLessThanEndDate",
  [Budget.BudgetRoundDoesNotExist]:
    "budget.errors.data.general.budgetRoundBeginDateAndEndDateMustBeInTheSameYear",
  [Budget.TheRangeRoundMustNotOverlapAnother]:
    "budget.errors.data.general.theRangeRoundMustNotOverlapAnother",
  [CostDistribution.CostDistributionAlreadyExists]:
    "costDistribution.errors.data.general.costDistributionAlreadyExists",
  [CustomerBalance.CustomerBalanceAlreadyExists]:
    "customerBalance.errors.data.general.customerBalanceAlreadyExists",
  [CustomerBalance.AmountMustBeGreaterThanZero]:
    "customerBalance.errors.data.general.amountMustBeGreaterThanZero",
  [CustomerBalance.CostCenterParameterNotFound]:
    "customerBalance.errors.data.general.costCenterParameterNotFound",
  [CustomerBalance.SegmentGroupNotRelatedToCostCenter]:
    "customerBalance.errors.data.general.segmentGroupNotRelatedToCostCenter",
  [CustomerBalance.CustomerBalanceCannotBeDeleted]:
    "customerBalance.errors.data.general.customerBalanceCannotBeDeleted",
  [CustomerBalance.CustomerBalanceNotFound]:
    "customerBalance.errors.data.general.customerBalanceNotFound",
  [Wacc.WaccNotFound]: "wacc.errors.data.general.waccNotFound",
  [Wacc.WaccAlreadyExists]: "wacc.errors.data.general.waccAlreadyExists",
  [FeeYearMonth.FeeYearMonthAlreadyExists]:
    "fee.errors.data.general.feeYearMonthAlreadyExists",
};
