import * as yup from "yup";

export interface StateDTO {
  name: string;
  abbreviation: string;
}

export const schemaStateDTO = yup.object({
  name: yup.string().defined(),
  abbreviation: yup.string().defined(),
});
