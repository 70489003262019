import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import {
  CostCenterPepDTO,
  schemaCostCenterPepDTO,
} from "../costCenterPeps/CostCenterPepDTO";
import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";

export interface UserAllocationByHistoryDTO {
  id?: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  costCenterPep: CostCenterPepDTO | null;
  costCenter: CostCenterDTO | null;
  yearMonth: YearMonth;
  allocationType: EmployeeAllocationType;
  percentage: number;
}

export const schemaUserAllocationByHistoryDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  costCenterPep: schemaCostCenterPepDTO.nullable().defined(),
  costCenter: schemaCostCenterDTO.nullable().defined(),
  yearMonth: schemaYearMonth.defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  percentage: yup.number().defined(),
});
