import {
  Autocomplete,
  AutocompleteFreeSoloValueMapping,
  AutocompleteProps,
  AutocompleteValue,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { Ref, useEffect, useImperativeHandle, useMemo } from "react";
import { useEffectAfterRenders } from "../../../../../../hooks/enhancedReactHooks/useEffectAfterRenders";
import { StyledEndAdornment } from "./styles/StyledEndAdornment.styles";

export interface CustomAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  textfieldProps?: TextFieldProps;
  autocompleteProps: Omit<
    AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput"
  > & {
    optionsMemo?: ReadonlyArray<T>;
    getOptionLabelMemo?: (option: T) => string;
    isOptionEqualToValueMemo?: (option: T, value: T) => boolean;
    setValue?: (
      value: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>
    ) => void;
  };
  shouldReplaceValueOnOptionsChange?: boolean;
  shouldReplaceValueOnMount?: boolean;
  innerRef?: Ref<CustomAutocompleteRef<T>>;
}

export interface CustomAutocompleteRef<T> {
  setExistingOptionFromValue: () => void;
  getOptions: () => readonly T[];
}

export const CustomAutocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textfieldProps: {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...textfieldProps
  } = {},
  autocompleteProps: {
    options,
    optionsMemo,
    getOptionLabel,
    getOptionLabelMemo,
    isOptionEqualToValue,
    isOptionEqualToValueMemo,
    setValue,
    ...autocompleteProps
  },
  shouldReplaceValueOnOptionsChange = false,
  shouldReplaceValueOnMount = false,
  innerRef,
}: CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const finalOptions = useMemo(() => {
    return options ?? optionsMemo ?? [];
  }, [options]);

  const finalGetOptionLabel = useMemo(() => {
    return getOptionLabel ?? getOptionLabelMemo;
  }, [getOptionLabel]);

  const finalIsOptionEqualToValue = useMemo(() => {
    return isOptionEqualToValue ?? isOptionEqualToValueMemo;
  }, [isOptionEqualToValue]);

  const setExistingOptionFromValue = () => {
    const { value, multiple } = autocompleteProps;

    if (!value || multiple || !setValue) return;

    const matchingOption = finalOptions.find((x) => {
      if (!x) return false;
      if (!finalIsOptionEqualToValue) return x === value;

      return finalIsOptionEqualToValue(x, value as T);
    });

    if (matchingOption)
      setValue(
        matchingOption as AutocompleteValue<
          T,
          Multiple,
          DisableClearable,
          FreeSolo
        >
      );
  };

  useEffect(() => {
    if (!shouldReplaceValueOnMount) return;

    setExistingOptionFromValue();
  }, []);

  useEffectAfterRenders({
    effect: () => {
      if (!shouldReplaceValueOnOptionsChange) return;

      setExistingOptionFromValue();
    },
    deps: [finalOptions],
    rendersBeforeEffect: 1,
  });

  useImperativeHandle(innerRef, () => ({
    setExistingOptionFromValue,
    getOptions: () => options,
  }));

  return useMemo(() => {
    return (
      <Autocomplete
        {...autocompleteProps}
        options={finalOptions}
        getOptionLabel={
          finalGetOptionLabel as (
            option: T | AutocompleteFreeSoloValueMapping<FreeSolo>
          ) => string
        }
        isOptionEqualToValue={finalIsOptionEqualToValue}
        onChange={(_, value) => setValue?.(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, ...inputProps }}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              startAdornment: !InputProps?.startAdornment ? (
                params.InputProps.startAdornment
              ) : (
                <StyledEndAdornment disabled={params.disabled}>
                  {InputProps.startAdornment} {params.InputProps.startAdornment}
                </StyledEndAdornment>
              ),
              endAdornment: !InputProps?.endAdornment ? (
                params.InputProps.endAdornment
              ) : (
                <StyledEndAdornment disabled={params.disabled}>
                  {InputProps.endAdornment} {params.InputProps.endAdornment}
                </StyledEndAdornment>
              ),
            }}
            InputLabelProps={{ ...InputLabelProps }}
            {...textfieldProps}
          />
        )}
      />
    );
  }, [
    ...Object.values(autocompleteProps),
    ...Object.values(textfieldProps),
    ...Object.values(inputProps),
    ...Object.values(InputProps),
    ...Object.values(InputLabelProps),
    finalOptions,
    finalGetOptionLabel,
    finalIsOptionEqualToValue,
  ]);
};
