import * as yup from "yup";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { CreateSkillCategoryDTO } from "../../../shared/specific/DTOs/skillCategory/CreateSkillCategoryDTO";
import { SkillCategoryUpdateDTO } from "../../../shared/specific/DTOs/skillCategory/SkillCategoryUpdateDTO";
import {
  PaginatedSkillCategoryDTO,
  schemaPaginatedSkillCategoryDTO,
} from "../../../shared/specific/DTOs/skillCategory/PaginatedSkillCategoryDTO";
import {
  SkillCategoryDTO,
  schemaSkillCategoryDTO,
} from "../../../shared/specific/DTOs/skillCategory/SkillCategoryDTO";
import { SkillCategoryFilterDTO } from "../../../shared/specific/DTOs/skillCategory/filters/SkillCategoryFilterDTO";
import { riseApi } from "./connection";

export const getSkillCategorys = async ({
  filters,
  pagination,
}: {
  filters?: SkillCategoryFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedSkillCategoryDTO> => {
  const { data: skillCategorys } = await riseApi.get("/SkillCategory", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedSkillCategoryDTO.validateSync(skillCategorys);
};

export const getSkillCategorysDropdown = async (): Promise<
  SkillCategoryDTO[]
> => {
  const { data: skillCategorys } = await riseApi.get("/SkillCategory/Dropdown");

  return yup
    .array(schemaSkillCategoryDTO)
    .defined()
    .validateSync(skillCategorys);
};

export const getSkillCategoryById = async (
  id: number
): Promise<SkillCategoryDTO | null> => {
  const { data: skillCategory } = await riseApi.get(`SkillCategory/${id}`);

  return schemaSkillCategoryDTO.nullable().validateSync(skillCategory);
};

export const createSkillCategory = async (
  skillCategory: CreateSkillCategoryDTO
) => {
  await riseApi.post("SkillCategory", skillCategory);
};

export const deleteSkillCategory = async (id: number, version: string) => {
  await riseApi.delete(`SkillCategory/${id}/${version}`);
};

export const updateSkillCategory = async (
  skillCategory: SkillCategoryUpdateDTO
) => {
  await riseApi.put("SkillCategory", skillCategory);
};
