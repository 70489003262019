import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { MouseEventHandler, useMemo } from "react";
import { MenuOptionData } from "./shared/types/general.option";

interface OwnProps extends MenuOptionData {
  closeMenu: () => void;
}

export const MenuOption = ({ content, Icon, onClick, closeMenu }: OwnProps) => {
  const icon = useMemo(() => {
    if (!Icon) return null;

    return (
      <ListItemIcon>
        <Icon fontSize="medium" />
      </ListItemIcon>
    );
  }, [Icon]);

  const contentElement = useMemo(() => {
    if (typeof content === "string" || typeof content === "number")
      return <Typography>{content}</Typography>;

    return content;
  }, [content]);

  const finalOnClick: MouseEventHandler = (event) => {
    closeMenu();
    onClick?.(event);
  };

  return (
    <MenuItem onClick={finalOnClick}>
      {icon}
      {contentElement}
    </MenuItem>
  );
};
