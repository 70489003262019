import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { CommonProjectAdditionalInformationFormValues } from "../../shared/types/forms/general.types";
import { FormContext } from "../../shared/contexts/FormContext";
import {
  StyledFormSectionsContainer,
  StyledProjectInfo,
  StyledTitle,
} from "./index.styles";
import { TextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { PipedriveServiceLinesMultipleAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/PipedriveServiceLinesMultipleAutocompleteFormik";
import { KeywordsAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/KeywordsAutocompleteFormik";
import { SkillsAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skills/SkillsAutocompleteFormik";
import { ProjectSummary } from "./ProjectSummary";
import { CheckboxFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { PipedriveServiceLinesAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/PipedriveServiceLinesAutocompleteFormik";

interface OwnProps {
  values: CommonProjectAdditionalInformationFormValues;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const InternalFormContent = ({ values, setFieldValue }: OwnProps) => {
  const { t } = useTranslation();
  const { mode, projectData } = useContext(FormContext);

  const readOnly = mode === "viewing";

  return (
    <>
      <ProjectSummary />
      <StyledFormSectionsContainer>
        <StyledTitle>
          <Typography variant="h6">
            {t("projects.keywords.fields.additionalInformations")}
          </Typography>
          <FormControlLabel
            label={t("projects.keywords.fields.confidential")}
            control={
              readOnly ? (
                <Checkbox checked={projectData?.isConfidential} />
              ) : (
                <CheckboxFormik name="isConfidential" />
              )
            }
          />
        </StyledTitle>
        <StyledProjectInfo>
          <TextFieldFormik
            name="customerContact"
            label={t("projects.keywords.fields.customerContact")}
            inputProps={{
              maxLength: 200,
            }}
            InputProps={{
              readOnly,
              style: {
                height: "100%",
              },
              onKeyPress: (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              },
            }}
            style={{
              height: "100%",
            }}
            multiline={readOnly}
          />
          {readOnly ? (
            <PipedriveServiceLinesAutocompleteFormik
              name="projectPipedriveServiceLines"
              autocompleteProps={{
                multiple: true,
                readOnly,
              }}
            />
          ) : (
            <PipedriveServiceLinesMultipleAutocompleteFormik
              name="projectPipedriveServiceLines"
              autocompleteProps={{
                multiple: true,
                disableCloseOnSelect: true,
                readOnly,
              }}
            />
          )}
          <KeywordsAutocompleteFormik
            name="projectKeyword"
            autocompleteProps={{
              multiple: true,
              disableCloseOnSelect: true,
              readOnly,
            }}
          />
          <SkillsAutocompleteFormik
            name="projectSkill"
            autocompleteProps={{
              multiple: true,
              disableCloseOnSelect: true,
              readOnly,
            }}
            textfieldProps={{
              label: t("projects.keywords.fields.technologiesUsed"),
            }}
          />
          <TextFieldFormik
            name="solutionAdoptedExpectedResults"
            label={t("projects.keywords.fields.solutionAdoptedExpectedResults")}
            rows={10}
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            InputProps={{
              readOnly,
              onKeyPress: (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              },
            }}
          />
          <TextFieldFormik
            name="projectChallenges"
            label={t("projects.keywords.fields.projectChallenges")}
            rows={10}
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            InputProps={{
              readOnly,
              onKeyPress: (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              },
            }}
          />
          <TextFieldFormik
            name="projectResults"
            label={t("projects.keywords.fields.projectResults")}
            rows={6}
            multiline
            inputProps={{
              maxLength: 1000,
            }}
            InputProps={{
              readOnly,
              onKeyPress: (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              },
            }}
          />
        </StyledProjectInfo>
      </StyledFormSectionsContainer>
    </>
  );
};
