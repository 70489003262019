import { YearMonth } from "../../../classes/data/date/YearMonth";
import { GetWorkingDaysYearMonth } from "./general.helpers";

export const checkDoesWeekExist = (
  weekNumber: number,
  yearMonth: YearMonth
) => {
  const proporcionWeeksMonth = GetWorkingDaysYearMonth(yearMonth);

  return proporcionWeeksMonth.weeks[weekNumber] !== undefined;
};
