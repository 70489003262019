import * as yup from "yup";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";

export interface FeeDTO {
  id: number;
  description: string;
  costCenter: CostCenterDTO;
  subsidiary: SubsidiaryDTO;
  accountingAccountCredit: string;
  accountingAccountDebit: string;
  deactivatedDate: Date | null;
  version: string;
}

export const schemaFeeDTO = yup.object({
  id: yup.number().integer().defined(),
  description: yup.string().defined(),
  costCenter: schemaCostCenterDTO.defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  accountingAccountCredit: yup.string().defined(),
  accountingAccountDebit: yup.string().defined(),
  deactivatedDate: yup.date().nullable().defined(),
  version: yup.string().defined(),
});
