import { Button, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../store";
import { Language } from "../../../../../../enums/locale/Language.enum";
import { TranslatableFieldValues } from "../../../../../../types/data/form/translation.types";
import {
  useModalButton,
  ButtonProps,
} from "../../../../../hooksWithComponents/modal/modalButtons/useModalButton";
import { TranslationsIconButton } from "../../../../general/buttons/simpleIconButtons/TranslationsIconButton";
import { TranslationsModalContent } from "./index.styles";

interface OwnProps {
  label: string;
  translatableFieldValues: TranslatableFieldValues;
  checkIsValueNotEmpty?: (value: string) => boolean;
}

export const ReadOnlyTextFieldWithTranslationsModal = ({
  label,
  translatableFieldValues,
  checkIsValueNotEmpty,
}: OwnProps) => {
  const defaultLanguage = useSelector(
    (state: RootState) => state.language.defaultLanguage
  );

  const areAllNonDefaultFieldsEmpty = useMemo(() => {
    return Object.entries(translatableFieldValues)
      .filter(([key]) => key !== defaultLanguage)
      .every(([, value]) => {
        if (checkIsValueNotEmpty) return !checkIsValueNotEmpty(value);
        return !value || value === "-";
      });
  }, [translatableFieldValues]);

  const ModalButton = useCallback(
    ({ onClick: openModal }: ButtonProps) => (
      <TranslationsIconButton
        onClick={openModal}
        size="small"
        badgeProps={
          areAllNonDefaultFieldsEmpty
            ? undefined
            : { variant: "dot", color: "primary" }
        }
      />
    ),
    [areAllNonDefaultFieldsEmpty]
  );

  const { contentButton, contentModal } = useModalButton({
    button: {
      ModalButton,
    },
    modal: {
      ModalTitleMemo: () => (
        <Trans
          i18nKey="general.keywords.languages.translationsOfName"
          values={{ name: label }}
          components={{ italic: <em /> }}
        />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
      mode: "dialog",
      modalContentMemo: (
        <TranslationsModalContent>
          {Object.values(Language)
            .filter((x) => x !== defaultLanguage)
            .map((language) => (
              <TextField
                key={language}
                label={`${label} (${language})`}
                value={translatableFieldValues[language]}
                InputProps={{ readOnly: true }}
              />
            ))}
        </TranslationsModalContent>
      ),
    },
  });

  if (!defaultLanguage) return null;

  return (
    <>
      <TextField
        label={`${label} (${defaultLanguage})`}
        value={translatableFieldValues[defaultLanguage]}
        InputProps={{ endAdornment: contentButton, readOnly: true }}
      />
      {contentModal}
    </>
  );
};
