import { useCallback, useContext, useMemo, useRef } from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { StyledSubHeaderFixed } from "../../shared/react/styles/ui/layouts/general.styles";
import { StyledSummaryTab } from "../SummaryTab/SummaryTabWithinContext/index.styles";
import { useExpensesSummary } from "./hooks/useExpensesSummary";
import { useTableData } from "./hooks/useTableData";
import { ExpensesSuperUserContext } from "./shared/react/contexts/ExpensesSuperUserContext";
import { ExpensesCarouselContext } from "./shared/react/contexts/ExpensesCarouselContext";

export const ExpensesTabWithinContext = () => {
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const { costCenterPep: initialCostCenterPep } = useProjectContext();
  const { canEditAllExpensesButton } = useContext(ExpensesSuperUserContext);
  const { getCarouselRealSize } = useContext(ExpensesCarouselContext);

  const carouselRealSize = getCarouselRealSize();

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  const { costCenterPep, expensesSummaryComponent } = useExpensesSummary({
    initialCostCenterPep,
  });

  const { headCells, singlePageLoader, editingModal } = useTableData({
    costCenterPep,
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [costCenterPep],
    rendersBeforeEffect: 2, // autocomplete updated initialCostCenterPep
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [carouselRealSize],
    rendersBeforeEffect: 1,
  });

  const rightActionButtons = useMemo(() => {
    return canEditAllExpensesButton;
  }, [canEditAllExpensesButton]);

  return (
    <StyledSummaryTab>
      <StyledSubHeaderFixed>{expensesSummaryComponent}</StyledSubHeaderFixed>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
        }}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: `calc(100vh - 290px)`,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
      {editingModal}
    </StyledSummaryTab>
  );
};
