import * as yup from "yup";

export interface ProjectPlanningSavingValidationDTO {
  wasProjectScheduledStartDateProvided: boolean;
  wasProjectMvProvided: boolean;
  revenueEqualsProjectAmount: boolean;
  activitiesLessThanProjectBudget: boolean;
  allActivitiesValidAmounts: boolean;
  invoicesEqualProjectBudget: boolean;
  invoicesCorrectPercentage: boolean;
  atLeastOneAllocation: boolean;
  noAllocationsCollaboratorsAfterResignationDate: boolean;
  noActivitiesBeforeProjectScheduledStartDate: boolean;
  noInvoicesBeforeProjectScheduledStartDate: boolean;
  noAllocationsBeforeProjectScheduledStartDate: boolean;
  noExpensesBeforeProjectScheduledStartDate: boolean;
  noPerformedExpensesBeforeProjectScheduledStartDate: boolean;
  noAllocationsWithZeroCost: boolean;
  allAllocationsValidComparedToCollaboratorTracks: boolean;
}

export const schemaProjectPlanningSavingValidationDTO = yup.object({
  wasProjectScheduledStartDateProvided: yup.boolean().defined(),
  wasProjectMvProvided: yup.boolean().defined(),
  revenueEqualsProjectAmount: yup.boolean().defined(),
  activitiesLessThanProjectBudget: yup.boolean().defined(),
  allActivitiesValidAmounts: yup.boolean().defined(),
  invoicesEqualProjectBudget: yup.boolean().defined(),
  invoicesCorrectPercentage: yup.boolean().defined(),
  atLeastOneAllocation: yup.boolean().defined(),
  noAllocationsCollaboratorsAfterResignationDate: yup.boolean().defined(),
  noActivitiesBeforeProjectScheduledStartDate: yup.boolean().defined(),
  noInvoicesBeforeProjectScheduledStartDate: yup.boolean().defined(),
  noAllocationsBeforeProjectScheduledStartDate: yup.boolean().defined(),
  noExpensesBeforeProjectScheduledStartDate: yup.boolean().defined(),
  noPerformedExpensesBeforeProjectScheduledStartDate: yup.boolean().defined(),
  noAllocationsWithZeroCost: yup.boolean().defined(),
  allAllocationsValidComparedToCollaboratorTracks: yup.boolean().defined(),
});
