import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/data/form/values.types";
import { StyledForm } from "./index.style";
import { HorizontalMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/horizontal/HorizontalMultipleAutocompleteFormik";
import { DisciplinesMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DisciplinesMultipleAutocompleteFormik";
import { SkillCategoriesMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skillCategory/SkillCategoriesMultipleAutocompleteFormik";
import { SkillsMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skills/SkillsMultipleAutocompleteFormik";
import { LanguageSkillMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skills/LanguageSkillMultipleAutocompleteFormik";
import { SkillCertificationsMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skillCertification/SkillCertificationsMultipleAutocompleteFormik";
import { CountriesMultipleAutocompleteFormik } from "../../../../Customers/CustomersPage/shared/react/components/form/formik/input/autocomplete/CountriesMultipleAutocompleteFormik";
import { UserSkillLevelMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/userSkill/UserSkillLevelMultipleAutocompleteFormik";
import { UserLanguageLevelMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/userLanguage/UserLanguageLevelMultipleAutocompleteFormik";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { YesNoAutocompleteFormik } from "../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { StatesAutocompleteFormik } from "../../../../Customers/CustomersPage/shared/react/components/form/formik/input/autocomplete/StatesAutocompleteFormik";
import { Country } from "../../../../../../../shared/common/enums/locale/Country.enum";
import { maxLengthFields } from "../../../../Customers/CustomersPage/shared/constants/form.constants";
import { ContractRoleMultipleAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/contractRoles/ContractRoleMultipleAutocomplete";
import { DivisionsAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (props: OwnProps) => {
  const {
    formikProps: { values, setFieldValue },
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (values.skill.length === 0) setFieldValue("skillLevel", []);
  }, [values.skill.length]);

  useEffect(() => {
    if (values.languageSkill.length === 0) setFieldValue("languageLevel", []);
  }, [values.languageSkill.length]);

  const statesField = useMemo(() => {
    const isBrazil =
      values.country.find((x) => x.code === "BR") &&
      values.country.length === 1;

    if (isBrazil) {
      return (
        <StatesAutocompleteFormik
          name="stateAutocomplete"
          country={"BR" as Country}
          textfieldProps={{ required: true }}
          autocompleteProps={{ disableClearable: true }}
        />
      );
    }

    return (
      <TextFieldFormik
        name="stateTextField"
        label={t("general.keywords.fields.state")}
        inputProps={{ maxLength: maxLengthFields.state }}
      />
    );
  }, [values.country]);

  return (
    <StyledForm>
      <HorizontalMultipleAutocompleteFormik
        name="horizontal"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <DivisionsAutocompleteFormik name="division" />
      <DisciplinesMultipleAutocompleteFormik
        name="discipline"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <SkillCategoriesMultipleAutocompleteFormik
        name="skillCategory"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <SkillsMultipleAutocompleteFormik
        name="skill"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <ContractRoleMultipleAutocompleteFormik
        name="contractRole"
        autocompleteProps={{
          multiple: true,
        }}
        textfieldProps={{
          label: t("collaboratorManagement.keywords.fields.contractRoles"),
        }}
      />
      <UserSkillLevelMultipleAutocompleteFormik
        name="skillLevel"
        autocompleteProps={{
          multiple: true,
          disabled: values.skill.length === 0,
        }}
        textfieldProps={{
          label: t("collaboratorManagement.keywords.fields.expertise"),
        }}
      />
      <div />
      <SkillCertificationsMultipleAutocompleteFormik
        name="certification"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <YesNoAutocompleteFormik
        name="isCertificationProgram"
        textfieldProps={{
          label: t("skillCertification.keywords.general.certificationProgram"),
        }}
      />
      <CountriesMultipleAutocompleteFormik
        name="country"
        autocompleteProps={{
          multiple: true,
        }}
      />
      {statesField}
      <LanguageSkillMultipleAutocompleteFormik
        name="languageSkill"
        autocompleteProps={{
          multiple: true,
        }}
      />
      <UserLanguageLevelMultipleAutocompleteFormik
        name="languageLevel"
        autocompleteProps={{
          multiple: true,
          disabled: values.languageSkill.length === 0,
        }}
        textfieldProps={{
          label: t("collaboratorManagement.keywords.fields.proficiency"),
        }}
      />
    </StyledForm>
  );
};
