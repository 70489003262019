import { changeLanguage } from "i18next";
import * as storedLanguage from "../../repositories/localStorage/storedLanguage.repository";
import { Language } from "../../shared/common/enums/locale/Language.enum";

export const changePersistLanguage = (language: Language) => {
  changeLanguage(language);
  storedLanguage.setStoredLanguage(language);
};

export const { getStoredLanguage, removeStoredLanguage, setStoredLanguage } =
  storedLanguage;
