import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormikWithTranslationsModal } from "../../../../../../../shared/common/react/components/form/formik/translatable/TextFieldFormikWithTranslationsModal";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxLengthFields } from "../../shared/constants/form.constants";
import { JobRoleDataFormValues } from "../../shared/types/form.types";
import { StyledJobRoleCreationForm } from "./index.styles";

type OwnProps = FormContentProps<JobRoleDataFormValues>;

export const JobRoleDataForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const datePattern = i18n.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy";

  return (
    <StyledJobRoleCreationForm>
      <TextFieldFormikWithTranslationsModal
        name="nameTranslations"
        label={t("general.keywords.fields.name")}
        disabled={isSubmitting}
        inputProps={{ maxLength: maxLengthFields.name }}
        defaultLanguageTextFieldProps={{ required: true }}
      />

      <DatePickerFormik
        name="deactivatedDate"
        datePickerProps={{
          views: ["year", "month", "day"],
          label: t("jobRoles.keywords.general.deactivatedDate"),
          format: datePattern,
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: new Date(),
          disabled: isSubmitting,
        }}
      />
    </StyledJobRoleCreationForm>
  );
};
