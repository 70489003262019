import { styled, Tooltip } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-areas:
    "a a b b c c d d"
    "e e f f g h h i";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(12)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "i";
  }
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledButtons = styled("div")`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledMultipleSelectionContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledName = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledIcon = styled(Tooltip)`
  cursor: pointer;
`;
