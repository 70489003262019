import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { ExternalEnhancedRow } from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { ProjectionFormValues } from "../../../../../types/form.types";
import { ExpenseGroup } from "../../../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";
import { useEditingMonthlyComment } from "../useEditingMonthlyComment";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CarouselCell } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { formatNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledPhotoCellContainer } from "./index.styles";

interface OwnProps {
  formikProps: FormikProps<ProjectionFormValues>;
}

export const useDreTableData = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();

  const { editingModal } = useEditingMonthlyComment();

  const [searchParams] = useSearchParams();
  const idExpenseGroup = searchParams.get("idExpenseGroup");
  const idSubsidiary = searchParams.get("idSubsidiary");
  const year = searchParams.get("year");

  const idSubsidiaryValidated = useMemo(() => {
    return /^\d+$/.test(idSubsidiary ?? "")
      ? parseInt(idSubsidiary ?? "")
      : null;
  }, [idSubsidiary]);

  const currency = idSubsidiaryValidated === 1 ? "R$" : "US$";

  const yearValidated = useMemo(() => {
    return /^\d+$/.test(year ?? "") ? parseInt(year ?? "") : null;
  }, [year]);

  const idExpenseGroupValidated = useMemo(() => {
    return /^\d+$/.test(idExpenseGroup ?? "")
      ? parseInt(idExpenseGroup ?? "")
      : null;
  }, [idExpenseGroup]);

  const isToShowCustomerTrigramAndClientAccount = useMemo(() => {
    return ![
      ExpenseGroup.AdmCorp,
      ExpenseGroup.Pdd,
      ExpenseGroup.IrCsll,
      ExpenseGroup.NetFinancialExpenses,
      ExpenseGroup.ParticipationProgram,
    ].includes(idExpenseGroupValidated as ExpenseGroup);
  }, [idExpenseGroupValidated]);

  const { values } = formikProps;

  const rangeYearMonth = YearMonth.range(
    new YearMonth(0, yearValidated ?? 0),
    new YearMonth(11, yearValidated ?? 0)
  );

  const revenueRows: ExternalEnhancedRow[] = useMemo(() => {
    if (!values) return [];

    if (idExpenseGroupValidated === ExpenseGroup.GrossRevenue) {
      const riseRevenue = values.riseDreRevenue.map(
        (riseDre, i): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.risePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      const pipedriveRevenue = values.pipedriveDreRevenue.map(
        (riseDre): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.pipedrivePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      return [...riseRevenue, ...pipedriveRevenue];
    }

    return [];
  }, [[values.pipedriveDreRevenue, values.riseDreRevenue]]);

  const taxRows: ExternalEnhancedRow[] = useMemo(() => {
    if (!values) return [];

    if (idExpenseGroupValidated === ExpenseGroup.Tax) {
      const riseTax = values.riseDreTax.map(
        (riseDre, i): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.risePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      const pipedriveTax = values.pipedriveDreTax.map(
        (riseDre): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.pipedrivePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      return [...riseTax, ...pipedriveTax];
    }

    return [];
  }, [values.pipedriveDreTax, values.riseDreTax]);

  const expenseRows: ExternalEnhancedRow[] = useMemo(() => {
    if (!values) return [];

    if (idExpenseGroupValidated === ExpenseGroup.SalesCost) {
      const riseExpense = values.riseDreExpense.map(
        (riseDre, i): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.risePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      const pipedriveExpense = values.pipedriveDreExpense.map(
        (riseDre): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: riseDre.id,
            cells: [
              {
                value: t("projection.keywords.fields.pipedrivePhotography"),
                paddingmode: "both",
              },
              riseDre.costCenter?.code ?? riseDre?.costCenterCode ?? "-",
              "",
              "",
              "",
              "",
              "",
              "",
            ],
          };

          if (!isToShowCustomerTrigramAndClientAccount) {
            row.cells.splice(3, 2);
          }

          for (let i = 0; i < 3; i++) {
            row.cells.push({
              paddingmode: "none",
              CellRenderer: () => {
                return (
                  <CarouselCell
                    items={rangeYearMonth}
                    columnIndex={i}
                    checkIsCurrentItem={(item, yearMonth) =>
                      item.equals(yearMonth)
                    }
                    CarouselComponentMemo={({ item, yearMonth }) => (
                      <StyledPhotoCellContainer>
                        <TextField
                          value={formatNumber(
                            riseDre.yearMonthValues.find(
                              (x) => x.yearMonth.month === yearMonth?.month
                            )?.value ?? 0,
                            { fractionDigits: 2 }
                          )}
                          label={currency}
                          disabled
                        />
                      </StyledPhotoCellContainer>
                    )}
                  />
                );
              },
            });
          }

          row.cells.push({});

          row.cells.push("");

          return row;
        }
      );

      return [...riseExpense, ...pipedriveExpense];
    }

    return [];
  }, [values.pipedriveDreExpense, values.riseDreExpense]);

  return {
    revenueRows,
    taxRows,
    expenseRows,
    editingModal,
  };
};
