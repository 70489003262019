import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { getFramesOnly } from "../../../../../../services/frames/frames.service";
import { formatCurrency } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { ModalFilters } from "../shared/types/data/filters.types";
import { ViewIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { CustomLink } from "../../../../../../shared/common/react/components/navigation/CustomLink";
import { FrameOnlyDTO } from "../../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";

interface OwnProps {
  filters: ModalFilters;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const generateViewFrameLink = (frameData: FrameOnlyDTO) => {
    const valueReturnTo = `${
      location.pathname
    }?name=${encodeURIComponent(frameData.name)}`;

    const actualSearchParams = new URLSearchParams();
    actualSearchParams.set("return-to", valueReturnTo);

    return `/frames/${frameData.id}?${actualSearchParams.toString()}`;
  };

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      t("divisions.keywords.general.un"),
      t("general.keywords.fields.clientName"),
      t("general.keywords.fields.value"),
      t("general.keywords.fields.startDate"),
      t("general.keywords.fields.endDate"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        if (!filters.name && !filters.idCustomer && !filters.idDivision) {
          setInfo(t("projects.info.data.general.typeSomethingName"));
          return null;
        }

        const headKeys = [
          "name",
          "division.name",
          "customer.tradingName",
          "amount",
          "startDate",
          "endDate",
        ];

        try {
          const {
            tableData: framesData,
            page,
            totalCount,
          } = await getFramesOnly({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = framesData.map(
            (frameData): ExternalEnhancedRow => ({
              id: frameData.id,
              cells: [
                frameData.name,
                frameData.division.name,
                frameData.customer.tradingName,
                {
                  value: frameData.amount,
                  displayValue: formatCurrency({
                    currencySymbol: frameData.currencySymbol,
                    value: frameData.amount,
                  }),
                },
                {
                  value: frameData.startDate.toString(),
                  displayValue: frameData.startDate
                    ?.toDate()
                    .toLocaleDateString(i18n.language),
                },
                {
                  value: frameData.endDate.toString(),
                  displayValue: frameData.endDate
                    ?.toDate()
                    .toLocaleDateString(i18n.language),
                },
                {
                  CellRenderer: () => (
                    <>
                      <CustomLink to={generateViewFrameLink(frameData)}>
                        <ViewIconButton />
                      </CustomLink>
                      <Protected
                        restrictions={{
                          type: PermissionType.Frame,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer frameData={frameData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Frame,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...frameData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );

  return {
    headCells,
    onTablePageChange,
    editingModal,
    deletionModal,
  };
};
