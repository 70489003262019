import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckboxFormik } from "../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const JobRolesFilterForm = ({ formikProps: { values } }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TextFieldFormik
        name="name"
        label={t("general.keywords.fields.name")}
        fullWidth
      />

      <FormControlLabel
        label={t("general.keywords.fields.onlyActivated")}
        control={<CheckboxFormik name="onlyActivated" />}
      />
    </StyledForm>
  );
};
