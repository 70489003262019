import { useEffect } from "react";
import { Trans } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { EnhancedTable } from "../../../../../shared/common/react/components/table/EnhancedTable";
import { ProjectOnlyDTO } from "../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import { useTableData } from "./hooks/useTableData";

interface OwnParams {
  project: ProjectOnlyDTO;
}

export const ProjectBaselinesWithProject = ({ project }: OwnParams) => {
  useEffect(() => {
    if (!project?.name) return setPageTitle("");

    setPageTitle({
      title: project.name,
      Component: () => (
        <Trans
          i18nKey="projectBaselines.pages.main.title"
          values={{
            projectName: project.name,
          }}
          components={{ italic: <em /> }}
        />
      ),
    });
  }, [project?.name]);

  const { headCells, singlePageLoader, viewingModal } = useTableData({
    idProject: project.id,
  });

  return (
    <>
      <EnhancedTable
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        orderDefaults={{
          defaultOrder: "desc",
        }}
      />
      {viewingModal}
    </>
  );
};
