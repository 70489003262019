import * as yup from "yup";
import {
  PermissionLevel,
  schemaPermissionLevelNullable,
} from "../../enums/users/permissions/PermissionLevel.enum";
import {
  PermissionType,
  schemaPermissionType,
} from "../../enums/users/permissions/PermissionType.enum";

export interface UserAccessPermissionDTO {
  id: number;
  permissionType: PermissionType;
  permissionLevel: PermissionLevel | null;
}

export const schemaUserAccessPermissionDTO = yup.object({
  id: yup.number().integer().defined(),
  permissionType: schemaPermissionType.defined(),
  permissionLevel: schemaPermissionLevelNullable.defined(),
});
