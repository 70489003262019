import { useContext, useState } from "react";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TableRow } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { RowComponentProps } from "../shared/types/data.types";
import { CollapseTableContext } from "../shared/context/CollapseTableContext";
import { StyledButtonColumn, StyledFirsColumn } from "./index.styles";

export const Row = ({
  mainInformation,
  extensibleInformation,
}: RowComponentProps) => {
  const [open, setOpen] = useState(false);

  const { fixFirstColumn, fixLastColumn } = useContext(CollapseTableContext);

  return (
    <>
      <TableRow>
        <StyledButtonColumn
          isfixfirst={fixFirstColumn ? "true" : "false"}
          isfixlast="false"
        >
          {extensibleInformation ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              style={{
                paddingBlock: 0,
                margin: 0,
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            ""
          )}
        </StyledButtonColumn>
        {mainInformation.map((info, i) => {
          return (
            <StyledFirsColumn
              align="left"
              key={i}
              isfixfirst={i === 0 && fixFirstColumn ? "true" : "false"}
              isfixlast={
                mainInformation.length - 1 === i && fixLastColumn
                  ? "true"
                  : "false"
              }
            >
              {info}
            </StyledFirsColumn>
          );
        })}
      </TableRow>
      {extensibleInformation instanceof Array
        ? extensibleInformation.map((ei, i) => (
            <TableRow
              key={i}
              style={{
                display: open ? "table-row" : "none",
              }}
            >
              <StyledButtonColumn
                style={{
                  paddingBottom: 0,
                  paddingTop: 0,
                }}
                align="left"
                isfixfirst={fixFirstColumn ? "true" : "false"}
                isfixlast="false"
                isextensible="true"
              >
                <Collapse in={open} timeout="auto" unmountOnExit />
              </StyledButtonColumn>
              {ei.info.map((info, i) => {
                return (
                  <StyledFirsColumn
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                    align="left"
                    key={i}
                    isfixfirst={i === 0 && fixFirstColumn ? "true" : "false"}
                    isfixlast={
                      ei.info.length - 1 === i && fixLastColumn
                        ? "true"
                        : "false"
                    }
                    isextensible="true"
                  >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                      <div>{info}</div>
                    </Collapse>
                  </StyledFirsColumn>
                );
              })}
            </TableRow>
          ))
        : extensibleInformation && extensibleInformation}
    </>
  );
};
