import { useTranslation } from "react-i18next";
import i18next, { t } from "i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { EditFormButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { useSuperUserEditingDatesFormikConfig } from "../../../react/hooks/data/validation/useSuperUserEditingDatesFormikConfig";
import { InvoiceDatesDataFormValues } from "../../../types/form/invoiceDatesDataFormValues";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { updateInvoiceDates } from "../../../../../../../../../services/invoices/invoices.service";
import { InvoiceDatesDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDatesDTO";
import { DatesDataForm } from "../DatesDataForm";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { DateOnly } from "../../../../../../../../../shared/common/classes/data/date/DateOnly";

interface OwnProps {
  reloadPage: () => void;
  invoiceData: InvoiceDTO;
}

type OwnReturn = {
  SuperUserEditingDatesButtonContainer: (
    props: InvoiceDTO
  ) => JSX.Element | null;
  superUserEditingDatesModal: JSX.Element | null;
};

export const useSuperUserEditingDates = ({
  reloadPage,
  invoiceData,
}: OwnProps): OwnReturn => {
  const { initialValues, validationSchema } =
    useSuperUserEditingDatesFormikConfig({ invoiceData });

  const {
    ContentButton: SuperUserEditingDatesButtonContainer,
    contentModal: superUserEditingDatesModal,
  } = useFormikModalButton<InvoiceDatesDataFormValues, InvoiceDTO, InvoiceDTO>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("invoice.modal.data.edit.editEventDates"),
    },
    button: {
      FormButtonMemo: EditFormButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoiceData,
        setFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalFormData(invoiceData);
          setFormValues({
            issueDate: invoiceData.issueDate?.toDate() ?? null,
            cancellationDate: invoiceData.cancellationDate?.toDate() ?? null,
            lossDate: invoiceData.lossDate?.toDate() ?? null,
            paymentDate: invoiceData.paymentDate?.toDate() ?? null,
            pddDate: invoiceData.pddDate?.toDate() ?? null,
            plannedCancellationDate:
              invoiceData.plannedCancellationDate?.toDate() ?? null,
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoice",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig: {
        initialValues,
        validationSchema,
      },
      FormContentMemo: (props) => <DatesDataForm {...props} />,
      FormActionsMemo: ({
        submitFormValues,
        closeModal,
        isSubmitting,
        isLoadingModal,
        modalError,
      }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
              disabled={isLoadingModal || !!modalError}
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData: invoiceData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
        setFormValues,
      }) => {
        try {
          await updateInvoiceDates(
            convertTo<InvoiceDatesDTO>({
              id: invoiceData!.id,
              idInvoiceGroup: invoiceData!.idInvoiceGroup,
              issueDate: formValues.issueDate
                ? DateOnly.createFromDate(formValues.issueDate)
                : null,
              paymentDate: formValues.paymentDate
                ? DateOnly.createFromDate(formValues.paymentDate)
                : null,
              plannedCancellationDate: formValues.plannedCancellationDate
                ? DateOnly.createFromDate(formValues.plannedCancellationDate)
                : null,
              cancellationDate: formValues.cancellationDate
                ? DateOnly.createFromDate(formValues.cancellationDate)
                : null,
              pddDate: formValues.pddDate
                ? DateOnly.createFromDate(formValues.pddDate)
                : null,
              lossDate: formValues.lossDate
                ? DateOnly.createFromDate(formValues.lossDate)
                : null,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadPage();
          setFormValues(formValues);
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(t("invoice.errors.data.update.failedToUpdateInvoice"));
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { SuperUserEditingDatesButtonContainer, superUserEditingDatesModal };
};
