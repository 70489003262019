import * as yup from "yup";
import {
  YearMonthValueDTO,
  schemaYearMonthValueDTO,
} from "./YearMonthValueDTO";
import {
  YearMonthValueTypeDTO,
  schemaYearMonthValueTypeDTO,
} from "./YearMonthValueType";

export interface CalculatedDataDTO {
  totalMonthValues: YearMonthValueDTO[];
  extensibleInformation: YearMonthValueTypeDTO[] | null;
  totalTotalMonth: number;
}

export const schemaCalculatedDataDTO = yup.object({
  totalMonthValues: yup.array(schemaYearMonthValueDTO).defined(),
  extensibleInformation: yup
    .array(schemaYearMonthValueTypeDTO)
    .defined()
    .nullable(),
  totalTotalMonth: yup.number().defined(),
});
