import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { InvoiceDatesDataFormValues } from "../../../../types/form/invoiceDatesDataFormValues";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";

interface OwnProps {
  invoiceData: InvoiceDTO;
}

export const useSuperUserEditingDatesFormikConfig = ({
  invoiceData,
}: OwnProps) => {
  const { t } = useTranslation();

  const initialValues: InvoiceDatesDataFormValues = useMemo(() => {
    return {
      issueDate: null,
      paymentDate: null,
      plannedCancellationDate: null,
      cancellationDate: null,
      pddDate: null,
      lossDate: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      issueDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.issueDate) {
              return true;
            }
            return !!value;
          },
        }),
      paymentDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.paymentDate) {
              return true;
            }
            return !!value;
          },
        }),
      plannedCancellationDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.plannedCancellationDate) {
              return true;
            }
            return !!value;
          },
        }),
      cancellationDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.cancellationDate) {
              return true;
            }
            return !!value;
          },
        }),
      pddDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.pddDate) {
              return true;
            }
            return !!value;
          },
        }),
      lossDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            if (!invoiceData.lossDate) {
              return true;
            }
            return !!value;
          },
        }),
    });
  }, [t, invoiceData]);

  return { initialValues, validationSchema };
};
