import { getAccount } from "../../repositories/APIs/microsoftAuthentication/authentication.repository";
import { getProfilePictureBlob } from "../../repositories/APIs/microsoftAuthentication/profile.repository";
import { store } from "../../store";
import { setUserData } from "../../store/features/userSlice";

import {
  getUsers,
  getLoggedUser,
  getPaginatedUsers,
  getPaginatedUsersCurrentJobRole,
  getUserCurrentJobRole,
  getAllWithRecentlyHistory,
  createUserCollaborator,
  getUserCollaboratorById,
  deleteUserCollaborator,
  getByIdWithRecentlyHistory,
  updateUserCollaborator,
  turnOffUserCollaborator,
  turnOnUserCollaborator,
  uploadCollaborators,
  updateUserCollaboratorLocality,
  hireUserCollaborator,
} from "../../repositories/APIs/riseAPI/user.repository";
import { LoggedUserNotRegisteredError } from "../../shared/specific/errors/user/LoggedUserNotRegisteredError";

export {
  getUsers,
  getLoggedUser,
  getPaginatedUsers,
  getPaginatedUsersCurrentJobRole,
  getUserCurrentJobRole,
  getAllWithRecentlyHistory,
  createUserCollaborator,
  getUserCollaboratorById,
  deleteUserCollaborator,
  getByIdWithRecentlyHistory,
  updateUserCollaborator,
  turnOffUserCollaborator,
  turnOnUserCollaborator,
  uploadCollaborators,
  updateUserCollaboratorLocality,
  hireUserCollaborator,
};

export const setGlobalUserData = async () => {
  const account = getAccount();

  const [profile, loggedUser] = await Promise.all([
    getProfilePictureBlob(),
    getLoggedUser(),
  ]);

  if (!loggedUser) throw new LoggedUserNotRegisteredError();

  store.dispatch(
    setUserData({
      userFullName: account?.name ?? "",
      username: account?.username ?? "",
      avatarUrl: profile ?? "",
      isAdmin: loggedUser.isAdmin,
      permissions: loggedUser.permissions,
    })
  );
};
