import * as yup from "yup";

export interface CountryDTO {
  name: string;
  code: string;
}

export const schemaCountryDTO = yup.object({
  name: yup.string().defined(),
  code: yup.string().defined(),
});
