import i18next from "i18next";
import saveAs from "file-saver";
import { riseApi } from "./connection";
import {
  BudgetDTO,
  schemaBudgetDTO,
} from "../../../shared/specific/DTOs/Budget/BudgetDTO";
import { BudgetUpdateDTO } from "../../../shared/specific/DTOs/Budget/BudgetUpdateDTO";
import { BudgetFilterDTO } from "../../../shared/specific/DTOs/Budget/BudgetFilterDTO";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { formatFilenameReports } from "../../../shared/specific/helpers/DTOs/projects/reports/formatters/fileFormatters.helpers";
import { BudgetSpreadsheetUploadDTO } from "../../../shared/specific/DTOs/Budget/BudgetSpreadsheetUploadDTO";

export const getBudgetByYear = async (
  year: number
): Promise<BudgetDTO | null> => {
  const { data: Budget } = await riseApi.get(`Budget/ByYear/${year}`);
  return schemaBudgetDTO.nullable().validateSync(Budget);
};

export const getBudgetById = async (id: number): Promise<BudgetDTO | null> => {
  const { data: bedget } = await riseApi.get(`/Budget/${id}`);

  return schemaBudgetDTO.nullable().validateSync(bedget);
};

export const updateBudget = async (budget: BudgetUpdateDTO) => {
  await riseApi.put("Budget", budget);
};

export const downloadBudgetSpreadsheet = async (filters: BudgetFilterDTO) => {
  const response = await riseApi.post(
    `/Budget/DownloadBudgetSpreadsheet`,
    filters,
    { responseType: "blob" }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("downloadBudgetSpreadsheet.keywords.downloadFileName"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const uploadBudgetSpreadsheet = async (
  files: File,
  IdDivision: string,
  idSegmentGroup: string | null,
  IdSubsidiary: string
): Promise<BudgetSpreadsheetUploadDTO | null> => {
  const form = new FormData();
  form.append(`file`, files);
  form.append(`IdSubsidiary`, IdSubsidiary);
  form.append(`IdDivision`, IdDivision);
  if (idSegmentGroup) form.append(`idSegmentGroup`, idSegmentGroup);

  const { data: errorsString } = await riseApi.post(
    "/Budget/uploadBudgetSpreadsheetFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return errorsString;
};
