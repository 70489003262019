import { useTranslation } from "react-i18next";
import { FilterValues } from "../../../shared/types/form.types";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { StyledForm } from "./index.styles";
import { CurrencySubsidiariesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/CurrencySubsidiariesAutocompleteFormik";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { getNowBrazilianDate } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";

type OwnProps = FormContentProps<FilterValues>;

export const BrlParityFilterForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="startYearMonth"
        datePickerProps={{
          label: t("brlParity.keywords.fields.yearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: getNowBrazilianDate(),
        }}
      />
      <YearMonthDatePickerFormik
        name="endYearMonth"
        datePickerProps={{
          label: t("brlParity.keywords.fields.yearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: getNowBrazilianDate(),
        }}
      />
      <CurrencySubsidiariesAutocompleteFormik
        name="currency"
        textfieldProps={{ required: false }}
        autocompleteProps={{ disabled: isSubmitting }}
      />
    </StyledForm>
  );
};
