import { Language } from "../../../../../../enums/locale/Language.enum";
import {
  getDecimalSeparator,
  getThousandSeparator,
} from "../../../../../../helpers/data/numbers/localization/general.helpers";
import { AutomaticSeparators } from "./index.types";

export const getSeparators = (locale: Language): AutomaticSeparators => {
  return {
    thousandsSeparator: getThousandSeparator(locale),
    radix: getDecimalSeparator(locale),
  };
};
