import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const useProjectionSearchParams = () => {
  const [searchParams] = useSearchParams();

  const idExpenseGroup = searchParams.get("idExpenseGroup");
  const idSubsidiary = searchParams.get("idSubsidiary");
  const idDivision = searchParams.get("idDivision");
  const idSegmentGroup = searchParams.get("idSegmentGroup");
  const year = searchParams.get("year");
  const customerTrigram = searchParams.get("customerTrigram");
  const clientAccount = searchParams.get("clientAccount");

  const idExpenseGroupValidated = useMemo(() => {
    return /^\d+$/.test(idExpenseGroup ?? "")
      ? parseInt(idExpenseGroup ?? "")
      : null;
  }, [idExpenseGroup]);

  const idSubsidiaryValidated = useMemo(() => {
    return /^\d+$/.test(idSubsidiary ?? "")
      ? parseInt(idSubsidiary ?? "")
      : null;
  }, [idSubsidiary]);

  const idDivisionValidated = useMemo(() => {
    return /^\d+$/.test(idDivision ?? "") ? parseInt(idDivision ?? "") : null;
  }, [idDivision]);

  const idSegmentGroupValidated = useMemo(() => {
    return /^\d+$/.test(idSegmentGroup ?? "")
      ? parseInt(idSegmentGroup ?? "")
      : null;
  }, [idSegmentGroup]);

  const validatedCustomerTrigram = useMemo(() => {
    if (customerTrigram === "" || !customerTrigram) return "";
    return decodeURIComponent(customerTrigram ?? "");
  }, [customerTrigram]);

  const validatedClientAccount = useMemo(() => {
    if (clientAccount === "" || !clientAccount) return "";
    return decodeURIComponent(clientAccount ?? "");
  }, [clientAccount]);

  const yearValidated = useMemo(() => {
    return /^\d+$/.test(year ?? "") ? parseInt(year ?? "") : null;
  }, [year]);

  return {
    idExpenseGroup: idExpenseGroupValidated,
    idSubsidiary: idSubsidiaryValidated,
    idDivision: idDivisionValidated,
    idSegmentGroup: idSegmentGroupValidated,
    year: yearValidated,
    customerTrigram: validatedCustomerTrigram,
    clientAccount: validatedClientAccount,
  };
};
