import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { getByIdWithRecentlyHistory } from "../../../../../../../services/user/user.service";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { UserCollaboratorRecentlyHistoryDTO } from "../../../../../../../shared/specific/DTOs/user/UserCollaboratorRecentlyHistoryDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { UserCollaboratorContent } from "./UserCollaboratorContent";

export const useViewingForm = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData: userCollaborator }) => {
        if (!userCollaborator) return null;

        return (
          <Trans
            i18nKey="general.modal.data.view.detailsOfName"
            values={{ name: userCollaborator.name }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData: userCollaborator }) => (
        <UserCollaboratorContent
          userCollaboratorDTO={userCollaborator ?? undefined}
        />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ViewModalButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: userCollaboratorData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const userDTO = await getByIdWithRecentlyHistory(
            userCollaboratorData.id
          );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!userDTO)
            return setModalError(
              i18next.t(
                "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist"
              )
            );

          setInternalModalData(userDTO);
        } catch (error) {
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    general: {
      initialInternalModalData:
        null as UserCollaboratorRecentlyHistoryDTO | null,
    },
  });

  return {
    ViewingButtonContainer,
    viewingModal,
  };
};
