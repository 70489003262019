import {
  CancelRounded as CancelRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { getPageFromCurrent } from "../../../../../../../services/applicationState/history.service";
import { HeaderLoading } from "../../../../../../../shared/common/react/components/ui/HeaderLoading";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { usePlanningFinishing } from "./hooks/usePlanningFinishing";
import { usePlanningSaving } from "./hooks/usePlanningSaving";
import {
  StyledPlanningPageFooter,
  ValidationStatusContainer,
} from "./styles/general.styles";
import { StyledPlanningStatus } from "./styles/StyledPlanningStatus.styles";

export const PlanningFooter = () => {
  const { t, i18n } = useTranslation();

  const { project, accessMode, isLoadingProject } = useProjectContext();
  const { planningSaveButton, planningSaveModal, delayJustificationModal } =
    usePlanningSaving();
  const { planningFinishButton, planningFinishModal } = usePlanningFinishing();

  const validationStatus = useMemo(() => {
    if (project.status !== ProjectStatus.Ongoing) return null;

    if (!project.isValid)
      return (
        <StyledPlanningStatus color="error">
          <CancelRoundedIcon />
          <span>
            {t("projects.info.data.planningGeneral.documentPendingValidation")}
          </span>
        </StyledPlanningStatus>
      );

    if (!project.validatedAt)
      return (
        <StyledPlanningStatus color="success">
          <CheckCircleRoundedIcon />
          <span>
            {t("projects.info.data.planningGeneral.validatedAtUnknownDate")}
          </span>
        </StyledPlanningStatus>
      );

    return (
      <StyledPlanningStatus color="success">
        <CheckCircleRoundedIcon />
        <span>
          {t("projects.info.data.planningGeneral.validatedAtDate", {
            date: project.validatedAt.toLocaleString(i18n.language),
          })}
        </span>
      </StyledPlanningStatus>
    );
  }, [t, project.isValid, project.validatedAt]);

  const planningActionButton = useMemo(() => {
    if (accessMode === "reading") return null;

    if (project.status !== ProjectStatus.Ongoing || !project.isValid)
      return (
        <Protected
          restrictions={{
            type: PermissionType.Project,
            level: PermissionLevel.Update,
          }}
        >
          {planningSaveButton}
        </Protected>
      );

    return (
      <Protected
        restrictions={{
          type: PermissionType.Project,
          level: PermissionLevel.Update,
        }}
      >
        {planningFinishButton}
      </Protected>
    );
  }, [
    accessMode,
    planningSaveButton,
    planningFinishButton,
    project.isValid,
    project.projectInvalidatedToGenerateBaseline,
  ]);

  const [searchParams] = useSearchParams();
  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);

  const previousPage = useMemo(() => {
    if (pageReturnTo) return pageReturnTo;

    const location = getPageFromCurrent(1);
    if (!location) return "/projects";
    return location.pathname + location.search;
  }, []);

  return (
    <StyledPlanningPageFooter>
      <ValidationStatusContainer>
        {!validationStatus ? null : (
          <>
            {isLoadingProject && <HeaderLoading />}
            {accessMode === "writing" && validationStatus}
          </>
        )}
      </ValidationStatusContainer>
      <div>
        <Button component={Link} to={previousPage}>
          {previousPage
            ? t("general.actions.general.return")
            : t("costCenterPeps.actions.navigation.viewProjects")}
        </Button>
        {planningActionButton}
      </div>
      {planningSaveModal}
      {planningFinishModal}
      {delayJustificationModal}
    </StyledPlanningPageFooter>
  );
};
