import * as yup from "yup";
import {
  ExpensesOfTypeDTO,
  schemaExpensesOfTypeDTO,
} from "./ExpensesOfTypeDTO";

export interface ExpensesGeneralDTO {
  totalExpensesAmount: number;
  expensesOfTypes: ExpensesOfTypeDTO[];
}

export const schemaExpensesGeneralDTO = yup.object({
  totalExpensesAmount: yup.number().defined(),
  expensesOfTypes: yup.array(schemaExpensesOfTypeDTO).defined(),
});
