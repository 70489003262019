import { useField } from "formik";
import { ClipboardEvent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Language } from "../../../../../../enums/locale/Language.enum";
import { parseLocaleNumber } from "../../../../../../helpers/data/numbers/localization/parsers.helpers";
import { useEffectAfterRenders } from "../../../../../hooks/enhancedReactHooks/useEffectAfterRenders";
import { MaskedTextField } from "../../../general/textField/MaskedTextField";
import { getSeparators } from "./index.helpers";
import { LocalizedNumberTextFieldProps } from "./index.types";

export type { LocalizedNumberTextFieldProps };

export const LocalizedNumberTextFieldFormik = ({
  name,
  textFieldProps: {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...restTextFieldProps
  } = {},
  ...rest
}: LocalizedNumberTextFieldProps) => {
  const { i18n } = useTranslation();
  const [{ value }, { error }, { setValue }] = useField<string>(name);
  const [separators, setSeparators] = useState(() =>
    getSeparators(i18n.language as Language)
  );

  const previousLanguageRef = useRef(i18n.language);

  const finalInputLabelProps = useMemo(() => {
    return {
      shrink: !!value || undefined,
      ...InputLabelProps,
    };
  }, [value, ...Object.values(InputLabelProps)]);

  useEffectAfterRenders({
    effect: () => {
      const previousLanguage = previousLanguageRef.current as Language;
      previousLanguageRef.current = i18n.language;

      if (!value) return;

      const valueNumber = parseLocaleNumber(value, previousLanguage);
      const formattedValue = Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: rest.padFractionalZeros
          ? (rest.scale ?? 2)
          : undefined,
      }).format(valueNumber);

      setValue(formattedValue);
      setSeparators(getSeparators(i18n.language as Language));
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    const valueNumber = parseLocaleNumber(text);

    const formattedValue = Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: rest.padFractionalZeros
        ? (rest.scale ?? 2)
        : undefined,
    }).format(valueNumber);

    setValue(formattedValue);
    setSeparators(getSeparators(i18n.language as Language));
  };

  return useMemo(() => {
    return (
      <MaskedTextField
        name={name}
        mask={Number}
        value={value}
        textFieldProps={{
          error: !!error,
          helperText: error,
          inputProps,
          InputProps,
          InputLabelProps: finalInputLabelProps,
          ...restTextFieldProps,
          onPaste: handlePaste,
        }}
        onAccept={(value: string) => setValue(value)}
        {...rest}
        {...separators}
      />
    );
  }, [
    value,
    name,
    finalInputLabelProps,
    ...Object.values(inputProps),
    ...Object.values(InputProps),
    ...Object.values(restTextFieldProps),
    ...Object.values(rest),
    error,
  ]);
};
