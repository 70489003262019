import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";

type OwnProps = FormContentProps<FilterValues>;

export const WaccFilterForm = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="startYearMonth"
        datePickerProps={{
          label: t("wacc.keywords.fields.startYearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
      />
      <YearMonthDatePickerFormik
        name="endYearMonth"
        datePickerProps={{
          label: t("wacc.keywords.fields.endYearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
      />
    </StyledForm>
  );
};
