import axios from "axios";
import { enviroment } from "../../../core/enviroment";
import { getGraphAuthorizationData } from "./authentication.repository";

export const graphApi = axios.create({
  baseURL: enviroment.user.auth.microsoft.graphEndpoint,
});

graphApi.interceptors.request.use(async (requestConfig) => {
  const { accessToken } = await getGraphAuthorizationData();

  requestConfig.headers = requestConfig.headers || {};
  requestConfig.headers.Authorization = `Bearer ${accessToken}`;

  return requestConfig;
});
