import * as yup from "yup";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AllocationCreationFormValues } from "../../../types/data/form/values.types";
import { TestContextComplete } from "../../../../../../../../../../../../../shared/common/types/externalLibraries/yup.types";
import { YearMonth } from "../../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { checkDoesWeekExist } from "../../../../../../../../../../../../../shared/common/helpers/data/dates/checkers.helpers";
import { useMinPeriodStart } from "../fields/useMinPeriodStart";
import { AllocationsCarouselContext } from "../../../../../../shared/react/contexts/AllocationsCarouselContext";
import { EmployeeAllocationType } from "../../../../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

export const useFormikConfig = () => {
  const { t } = useTranslation();
  const { earliestYearMonth } = useContext(AllocationsCarouselContext);

  const initialValues = useMemo((): AllocationCreationFormValues => {
    return {
      userCurrentJobRole: null,
      jobRole: null,
      allocationYearMonths: [],
      periodStart: null,
      periodEnd: null,
      allocationType: {
        id: EmployeeAllocationType.Allocation,
        label: "",
      },
    };
  }, []);

  const { minPeriodStartDate, minPeriodStartErrorMessage } =
    useMinPeriodStart();

  const validationSchema = useMemo(() => {
    return yup.object({
      allocationType: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
      userCurrentJobRole: yup
        .object()
        .nullable()
        .test({
          name: "At least one required check.",
          message: t(
            "allocations.errors.data.fields.userColaborator.mustSubmitEitherUserColaboratorOrJobRole"
          ),
          test(value) {
            return !!value || !!this.parent.jobRole;
          },
        }),
      jobRole: yup.object().nullable(),
      periodStart: yup
        .date()
        .nullable()
        .withMutation((schema) =>
          !minPeriodStartDate
            ? schema
            : schema.min(minPeriodStartDate, t(minPeriodStartErrorMessage))
        )
        .when("periodEnd", (periodEnd, schema) => {
          if (!periodEnd) return schema;
          return schema.max(
            periodEnd[0],
            t(
              "allocations.errors.data.fields.periodStart.cannotBeAfterPeriodEnd"
            )
          );
        })
        .required(t("general.errors.data.fields.general.required")),
      periodEnd: yup
        .date()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      allocationYearMonths: yup.array(
        yup.object({
          yearMonth: yup
            .date()
            .nullable()
            .test(
              "allocation yearMonth tester",
              t(
                "allocations.errors.data.fields.yearMonth.cannotBeOlderThanProjectBaselineStart"
              ),
              function test(allocationDate) {
                const periodStart = (this as TestContextComplete).from[1].value
                  .periodStart as Date | null;
                const periodEnd = (this as TestContextComplete).from[1].value
                  .periodEnd as Date | null;

                if (!allocationDate) return true;
                const allocationDateYearMonth =
                  YearMonth.createFromDate(allocationDate);

                if (periodStart) {
                  const periodStartYearMonth =
                    YearMonth.createFromDate(periodStart);
                  if (periodStartYearMonth.greaterThan(allocationDateYearMonth))
                    return false;
                }

                if (periodEnd) {
                  const periodEndYearMonth =
                    YearMonth.createFromDate(periodEnd);
                  if (periodEndYearMonth.lessThan(allocationDateYearMonth))
                    return false;
                }

                return true;
              }
            )
            .required(t("general.errors.data.fields.general.required")),
          week1Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week2Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week3Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week4Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week5Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .when("yearMonth", (yearMonth, schema) => {
              const doesWeekExist = checkDoesWeekExist(
                5,
                YearMonth.createFromDate(
                  new Date(yearMonth[0] as unknown as Date)
                )
              );
              if (!doesWeekExist) return schema;
              return schema.required(
                t("general.errors.data.fields.general.required")
              );
            }),
        })
      ),
    });
  }, [t, earliestYearMonth, minPeriodStartDate, minPeriodStartErrorMessage]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
