import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Dispatch, SetStateAction, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CollaboratorAllocationContext } from "../../shared/context";
import { CollaboratorAllocationDTO } from "../../../../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationDTO";
import { CreationProjectAllocationForm } from "./CreationProjectAllocationForm";
import { EmployeeAllocationType } from "../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import { useFormikConfig } from "./useFormikConfig";
import {
  CreationFormValue,
  FormValuesCollaboratorAllocationsFilter,
} from "../../shared/types/form.types";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();

  const { filter, allocations, setAllocations } = useContext(
    CollaboratorAllocationContext
  );

  const {
    ContentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton<
    CreationFormValue,
    {
      filter: FormValuesCollaboratorAllocationsFilter | null;
      allocations: CollaboratorAllocationDTO[];
      setAllocations: Dispatch<SetStateAction<CollaboratorAllocationDTO[]>>;
    }
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <>{t("collaboratorAllocations.modal.data.create.title")}</>;
      },
    },
    button: {
      FormButton: AddFormButton,
      onClickContentButton: ({ setInternalFormData }) => {
        setInternalFormData({
          filter,
          allocations,
          setAllocations,
        });
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => (
        <CreationProjectAllocationForm {...rest} />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          if (
            internalFormData?.filter?.userCollaborator &&
            internalFormData?.filter?.yearMonth
          ) {
            const existingSameAllocation = internalFormData.allocations.filter(
              (x) => x.costCenterPep?.id === formValues.costCenterPep?.id
            );

            if (existingSameAllocation.length > 0) {
              throw new Error(
                "The collaborator already has an active 'allocation' for this project."
              );
            }

            const yearMonth = YearMonth.createFromDate(
              internalFormData.filter.yearMonth
            );

            const createdProjectAllocation: CollaboratorAllocationDTO = {
              userCollaborator: internalFormData.filter.userCollaborator,
              costCenterPep: formValues.costCenterPep,
              costCenter: null,
              corporateSegment: null,
              allocationGroup: 0,
              allocationType: EmployeeAllocationType.Allocation,
              yearMonth,
              percentage: 0,
              week1Percentage: 0,
              week2Percentage: 0,
              week3Percentage: 0,
              week4Percentage: 0,
              week5Percentage: 0,
              canEdit: true,
            };

            internalFormData.setAllocations([
              ...allocations,
              createdProjectAllocation,
            ]);
          }

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "collaboratorAllocations.errors.data.create.allocationAlreadyExists",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
