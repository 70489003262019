export const headCellsSize = {
  origin: 100,
  costCenter: 205,
  accountCode: 170,
  customerTrigram: 190,
  clientAccount: 200,
  category: 195,
  values: 140,
  lastColumn: 100,
  transferenceCountry: 60,
};
