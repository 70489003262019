import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      resumeFile: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object();
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
