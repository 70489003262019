import { css, styled } from "@mui/material";
import { ReactElement } from "react";

interface OwnProps {
  labelColorType?: string;
  isCollaboratorCell?: boolean;
  children?: ReactElement;
}

const PureDiv = ({
  labelColorType,
  isCollaboratorCell,
  children,
  ...rest
}: OwnProps) => <div {...rest}>{children}</div>;

export const Container = styled(PureDiv)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isCollaboratorCell }) =>
    isCollaboratorCell ? "flex-start" : "center"};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  color: black;
  letter-spacing: 1px;
  ${({ labelColorType }) =>
    labelColorType &&
    css`
      color: #000;
      /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000; */
      background-color: ${labelColorType};
    `}
`;

export const StyleDobleHeader = styled(PureDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  & > p {
    width: 50%;
  }
`;
