import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import {
  getUserCollaboratorById,
  hireUserCollaborator,
} from "../../../../../../services/user/user.service";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import {
  FormButtonProps,
  useFormikModalButton,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { UserCollaboratorDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { UserEffectiveCollaboratorDataForm } from "../shared/react/components/content/UserEffectiveCollaboratorDataForm";
import { useEffectiveFormikConfig } from "../shared/react/hooks/data/validation/useEffectiveFormikConfig";
import { UserEffectiveCollaboratorDataFormValues } from "../shared/types/data/form/values/values.types";
import { UseCollaboratorEffectiveButton } from "../shared/react/components/general/buttons/UseCollaboratorEffectiveButton";
import { UserCollaboratorEffectiveDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorEffectiveDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EffectiveButtonContainer: (props: {
    userCollaborator: UserCollaboratorDTO;
    contractRole?: string;
  }) => JSX.Element | null;
  effectiveModal: JSX.Element | null;
};

export const useEffectiveForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formikConfig = useEffectiveFormikConfig();

  const {
    ContentButton: EffectiveButtonContainer,
    contentModal: effectiveModal,
  } = useFormikModalButton<
    UserEffectiveCollaboratorDataFormValues,
    { userCollaborator: UserCollaboratorDTO; contractRole?: string },
    { userCollaborator: UserCollaboratorDTO; contractRole?: string }
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalFormData }) => {
        return (
          <Trans
            i18nKey="usersCollaborators.modal.data.effective.titleEffectiveName"
            values={{
              name: internalFormData?.userCollaborator?.name,
            }}
            components={{ italic: <em /> }}
          />
        );
      },
    },
    button: {
      FormButtonMemo: ({ onClick }: FormButtonProps) => (
        <UseCollaboratorEffectiveButton onClick={onClick} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: {
          userCollaborator: userCollaboratorRow,
          contractRole,
        },
        setFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const userCollaboratorDTO = await getUserCollaboratorById(
            userCollaboratorRow.id
          );
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!userCollaboratorDTO)
            return setModalError(
              i18next.t(
                "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist"
              )
            );

          setInternalFormData({
            userCollaborator: userCollaboratorRow,
            contractRole,
          });

          setFormValues({
            isActive: userCollaboratorDTO.isActive,

            admissionDate: null,
            email: userCollaboratorDTO.email,
            login: userCollaboratorDTO.email?.split("@")[0] ?? "",
            name: userCollaboratorDTO.name ?? "",
            isCollaborator: {
              id: userCollaboratorDTO.isCollaborator,
              label: userCollaboratorDTO.isCollaborator
                ? t("general.keywords.general.yes")
                : t("general.keywords.general.no"),
            },
            workload: null,
            dateOfBirth: userCollaboratorDTO.dateOfBirth,
            resignationDate: null,
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
          });

          setModalError(errorMessage);
        } finally {
          if (checkInCurrentModalCount(startingOpenCloseModalCount))
            setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: (props) => (
        <UserEffectiveCollaboratorDataForm {...props} />
      ),
      FormActionsMemo: ({
        submitFormValues,
        closeModal,
        isSubmitting,
        isLoadingModal,
        modalError,
      }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
              disabled={isLoadingModal || !!modalError}
            >
              {t("usersCollaborators.modal.data.effective.button")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
        setFormValues,
      }) => {
        if (!internalFormData?.userCollaborator)
          throw new Error("Collaborator cannot be null on submission.");

        try {
          const createdCollaborator = await hireUserCollaborator(
            convertTo<UserCollaboratorEffectiveDTO>({
              id: internalFormData?.userCollaborator.id,
              idWorkload: formValues.workload?.id || undefined,
              login: formValues.login || undefined,
              email: formValues.email || undefined,
              name: formValues.name || undefined,
              isCollaborator: formValues.isCollaborator?.id ?? true,
              admissionDate: formValues.admissionDate || undefined,
              resignationDate: formValues.resignationDate || undefined,
              dateOfBirth: formValues.dateOfBirth || new Date(),
              version: internalFormData?.userCollaborator.version,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          navigate(`/collaborators/${createdCollaborator.id}/track`);
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (internalFormData?.userCollaborator.name)
            return notifyError(
              <Trans
                i18nKey="usersCollaborators.errors.data.edit.failedToEdit"
                values={{
                  name: internalFormData?.userCollaborator.name ?? "",
                }}
                components={{ italic: <em /> }}
              />
            );

          notifyError(t("usersCollaborators.errors.data.edit.failedToEdit"));
        } finally {
          setSubmitting(false);
        }
      },
    },
    general: {
      initialInternalFormData: null as {
        userCollaborator: UserCollaboratorDTO;
        contractRole?: string;
      } | null,
    },
  });

  return { EffectiveButtonContainer, effectiveModal };
};
