import { RestrictionsOperand } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { OrPermissions } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/OrPermissions";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";

export const jobRoleReadRestrictions: RestrictionsOperand = {
  type: PermissionType.JobRole,
  level: PermissionLevel.Read,
};

export const jobRateReadRestrictions: RestrictionsOperand = {
  type: PermissionType.JobRate,
  level: PermissionLevel.Read,
};

export const brlParityReadRestrictions: RestrictionsOperand = {
  type: PermissionType.BrlParity,
  level: PermissionLevel.Read,
};

export const dashboardsCreateRestrictions: RestrictionsOperand = {
  type: PermissionType.DashBoards,
  level: PermissionLevel.Read,
};

export const monthlyAllocationClosingReadRestrictions: RestrictionsOperand = {
  type: PermissionType.MonthlyAllocationClosing,
  level: PermissionLevel.Read,
};

export const corporateSegmentsCreateRestrictions: RestrictionsOperand = {
  type: PermissionType.CorporateSegment,
  level: PermissionLevel.Read,
};

export const accessGroupsReadRestrictions: RestrictionsOperand = {
  type: PermissionType.AuthorizationControl,
  level: PermissionLevel.Read,
};

export const uploadExpensesRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadExpenses,
};

export const uploadCorporateAllocationsRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadCorporateAllocations,
};
export const uploadPerformedFinancialCostRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadFinancialCost,
};

export const uploadProjectAllocationsRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadProjectAllocations,
};

export const uploadLedgerAccountRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadLedgerAccount,
};

export const uploadCustomerBalanceRestrictions: RestrictionsOperand = {
  type: PermissionType.CanUploadCustomerBalance,
};

export const performanceReadRestrictions = new OrPermissions(
  jobRoleReadRestrictions,
  jobRateReadRestrictions,
  brlParityReadRestrictions,
  accessGroupsReadRestrictions,
  dashboardsCreateRestrictions,
  uploadExpensesRestrictions,
  corporateSegmentsCreateRestrictions,
  uploadCorporateAllocationsRestrictions,
  uploadProjectAllocationsRestrictions,
  uploadPerformedFinancialCostRestrictions,
  monthlyAllocationClosingReadRestrictions,
  uploadLedgerAccountRestrictions,
  uploadCustomerBalanceRestrictions
);
