import { useMemo } from "react";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { InvoicesTabWithinContext } from "./InvoicesTabWithinContext";
import { InvoicesSuperUserContext } from "./shared/react/contexts/InvoicesSuperUserContext";

export const InvoicesTab = () => {
  const {
    superUserButton: canEditAllInvoicesButton,
    inSuperUserMode: canEditAllInvoices,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllInvoices,
    tooltipNotInSuperUserModeOff:
      "invoice.actions.authorization.editInvoicesWithoutRestrictions",
    tooltipInSuperUserMode:
      "invoice.actions.authorization.stopEditInvoicesWithoutRestrictions",
  });

  const invoicesSuperUserProvider = useMemo(() => {
    return {
      canEditAllInvoices,
      canEditAllInvoicesButton,
    };
  }, [canEditAllInvoicesButton, canEditAllInvoices]);

  return (
    <InvoicesSuperUserContext.Provider value={invoicesSuperUserProvider}>
      <InvoicesTabWithinContext />
    </InvoicesSuperUserContext.Provider>
  );
};
