import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserCollaboratorDataFormValues } from "../../../../types/data/form/values/values.types";
import { getNowBrazilianDate } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: UserCollaboratorDataFormValues = useMemo(() => {
    return {
      discipline: null,
      workload: null,
      isCollaborator: {
        id: true,
        label: t("general.keywords.general.yes"),
      },
      login: "",
      name: "",
      admissionDate: null,
      dateOfBirth: null,
      email: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      discipline: yup.object().nullable(),
      workload: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      isCollaborator: yup
        .object({
          id: yup.boolean(),
        })
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      login: yup
        .string()
        .nullable()
        .when("isActive", {
          is: (isActive?: boolean) => isActive !== false,
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        }),
      name: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      admissionDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      dateOfBirth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .test({
          name: "teste",
          message: t(
            "usersCollaborators.errors.data.fields.dateOfBirth.greaterThanCurrentDate"
          ),
          test(dateOfBirth) {
            if (!dateOfBirth) return true;
            if (dateOfBirth > getNowBrazilianDate()) return false;
            return true;
          },
        })
        .required(t("general.errors.data.fields.general.required")),
      email: yup
        .string()
        .email(t("general.errors.data.fields.email.invalid"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return {
      initialValues,
      validationSchema,
    };
  }, [initialValues, validationSchema]);
};
