import * as yup from "yup";
import {
  BudgetRoundDTO,
  schemaBudgetRoundDTO,
} from "../BudgetRound/BudgetRoundDTO";

export interface BudgetDTO {
  id: number;
  year: number;
  dolarTax: number;
  budgetRound: BudgetRoundDTO[];
  version: string;
}

export const schemaBudgetDTO = yup.object({
  id: yup.number().integer().defined(),
  year: yup.number().integer().defined(),
  dolarTax: yup.number().defined(),
  budgetRound: yup.array(schemaBudgetRoundDTO).defined(),
  version: yup.string().defined(),
});
