import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useField } from "formik";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CfopDTO } from "../../../../../../../DTOs/cfop/CfopDTO";
import { getCfops } from "../../../../../../../../../services/projects/cfops.service";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    CfopDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
}

const cachedCfops = new CachedTranslatedData({
  callback: getCfops,
  defaultCacheDuration: 600000, // 10 minutes
});

export const CfopsAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const [{ value: cfop }] = useField<CfopDTO | null>(name);
  const { t, i18n } = useTranslation();

  const startAdornment = useMemo(() => {
    if (!cfop?.name) return undefined;
    return (
      <Tooltip title={cfop.code} placement="top">
        <AutocompleteInfoBubble>{cfop.code}</AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [cfop]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("cfops.errors.data.fetch.failedToFetchCfops")}
      fetchOptionsMemo={cachedCfops.run}
      textfieldProps={{
        label: t("cfops.keywords.general.cfop"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.code} - {option.name}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
