import * as yup from "yup";

export interface SkillCertificationDTO {
  id: number;
  name: string;
  provider: string;
  certificationProgram: boolean;
  version: string;
}

export const schemaSkillCertificationDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  provider: yup.string().defined(),
  certificationProgram: yup.boolean().defined(),
  version: yup.string().defined(),
});
