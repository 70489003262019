import { styled } from "@mui/material";

export const TwoSidedModalActions = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > * {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)};
  }
`;
