import { LoadingButton } from "@mui/lab";
import * as yup from "yup";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { LoadLedgerAccountFormValues } from "../../types/form.values";
import { LoadForm } from "./LoadForm";
import { uploadLedgerAccount } from "../../../../../../../services/ledgerAccount/ledgerAccount.service";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { LedgerAccountUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/ledgerAccount/LedgerAccountResponseDTO";

export const useLoadLedgerAccount = () => {
  const { t } = useTranslation();
  const formikConfig = useMemo(() => {
    const initialValues: LoadLedgerAccountFormValues = {
      yearMonth: null,
      file: null,
    };

    const validationSchema = yup.object({
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    ContentButton: LoadLedgerAccount,
    contentModal: uploadLedgerAccountModal,
  } = useFormikModalButton<
    LoadLedgerAccountFormValues,
    LedgerAccountUploadResponseDTO
  >({
    modal: {
      modalMode: "modal",
      keepModalMounted: 1000,
      ModalTitleMemo: () => <>{t("ledgerAccount.modal.load.title")}</>,
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={onClick} variant="contained">
            {t("ledgerAccount.modal.load.title")}
          </Button>
        );
      },
      onClickContentButton: ({ setInternalFormData }) =>
        setInternalFormData(null),
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <LoadForm {...rest} internalFormData={internalFormData} />
      ),
      FormActions: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button
              onClick={closeModal}
              disabled={isSubmitting}
              variant="contained"
            >
              {t("general.actions.general.close")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        setInternalFormData,
      }) => {
        try {
          if (!formValues.file) {
            notifyIf4xxApiErrorDTO({
              error: "ledgerAccount.errors.data.general.mustAttachFile",
              defaultMessage:
                "ledgerAccount.errors.data.general.mustAttachFile",
            });
            setSubmitting(false);
            return;
          }

          const yearMonthString = formValues.yearMonth
            ? YearMonth.createFromDate(formValues.yearMonth).toString()
            : "";

          const res = await uploadLedgerAccount(
            formValues.file,
            yearMonthString
          );
          setInternalFormData(res);
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "ledgerAccount.errors.data.failedToloadLedgerAccount",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { LoadLedgerAccount, uploadLedgerAccountModal };
};
