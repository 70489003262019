import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface AdjustmentYearMonthValueDTO {
  id: number;
  yearMonth: YearMonth;
  value: number;
}

export const schemaAdjustmentYearMonthValueDTO = yup.object({
  id: yup.number().defined(),
  yearMonth: schemaYearMonth.defined(),
  value: yup.number().defined(),
});
