export const projectsMaxLengthFields = {
  code: 18,
  name: 200,
  description: 1000,
  clientAccount: 100,
};

export const planningFieldsConstants = {
  maxMvValue: 100,
};
