import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CostCenterPepsFormValues } from "../../types/data/form.types";
import { SubsidiaryDTO } from "../../../../../../../../shared/specific/DTOs/subsidiary/SubsidiaryDTO";
import { SubsidiaryWithBusinessRule } from "../../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: CostCenterPepsFormValues = useMemo(() => {
    return {
      costCenter: null,
      subsidiary: null,
      pepCode: "",
      pepDescription: "",
      isExempted: null,
      pis: null,
      cofins: null,
      icms: "",
      iss: null,
      tax: "",
      cfop: null,
      costCenterFromDeal: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      costCenter: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      pepCode: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      pepDescription: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      pis: yup
        .object()
        .test({
          message: t("general.errors.data.fields.general.required"),
          test(pis) {
            const pisValue = pis as { label: string } | null;
            if (
              this.parent.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR
            )
              return !(!pisValue?.label || pisValue?.label === "");
            return true;
          },
        })
        .nullable(),
      cofins: yup
        .object()
        .test({
          message: t("general.errors.data.fields.general.required"),
          test(cofins) {
            const cofinsValue = cofins as { label: string } | null;
            if (
              this.parent.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR
            )
              return !(!cofinsValue?.label || cofinsValue?.label === "");
            return true;
          },
        })
        .nullable(),
      icms: yup
        .number()
        .when("subsidiary", {
          is: (subsidiary: SubsidiaryDTO | null) =>
            subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR,
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        })
        .nullable()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        }),
      iss: yup
        .object()
        .test({
          message: t("general.errors.data.fields.general.required"),
          test(iss) {
            const issValue = iss as { label: string } | null;
            if (
              this.parent.subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR
            )
              return !(!issValue?.label || issValue?.label === "");
            return true;
          },
        })
        .nullable(),
      tax: yup
        .number()
        .when("subsidiary", {
          is: (subsidiary: SubsidiaryDTO | null) =>
            subsidiary?.name === "RDX.US",
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        })
        .nullable()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        }),
      isExempted: yup
        .object({
          id: yup.boolean(),
        })
        .when("subsidiary", {
          is: (subsidiary: SubsidiaryDTO | null) =>
            subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR,
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        })
        .nullable(),
      cfop: yup
        .object()
        .nullable()
        .when("subsidiary", {
          is: (subsidiary: SubsidiaryDTO | null) =>
            subsidiary?.name === SubsidiaryWithBusinessRule.RDXBR,
          then: (schema) =>
            schema.required(t("general.errors.data.fields.general.required")),
        }),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
