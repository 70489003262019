import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues, SkillObjectValue } from "../types/form.types";
import { StyledAreaListLanguages, StyledViewContent } from "./index.styles";
import { StyledLine } from "../../../shared/index.styles";
import { DeleteIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { HorizontalAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/horizontal/HorizontalAutocompleteFormik";
import { DisciplinesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DisciplinesAutocompleteFormik";
import { SkillCategoriesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skillCategory/SkillCategoriesAutocompleteFormik";
import { ExternalEnhancedRow } from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { SkillsViewTable } from "./SkillsViewTable";
import { UserSkillLevelAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/userSkill/UserSkillLevelAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { SkillsMultipleAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skills/SkillsMultipleAutocompleteFormik";

export const CreationEditingForm = ({
  formikProps: { values, setFieldValue, isSubmitting },
}: FormContentProps<FormValues>) => {
  const { t, i18n } = useTranslation();

  useEffectAfterRenders({
    effect: () => {
      const mapperSkills: SkillObjectValue[] = values.skillsDropdown.map(
        (skill) => ({
          skill,
          level:
            values.skillsTable.find(
              (tableSkill) => tableSkill.skill.id === skill.id
            )?.level || null,
        })
      );
      setFieldValue("skillsTable", mapperSkills);
    },
    deps: [values?.skillsDropdown],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("skillCategory", null);
    },
    deps: [values?.discipline],
    rendersBeforeEffect: 1,
  });

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    if (values?.skillsDropdown) {
      const rows: ExternalEnhancedRow[] = values.skillsDropdown.map(
        (skillDropdown, index) => {
          return {
            id: index,
            cells: [
              {
                value: skillDropdown.skillCategory.discipline.horizontal
                  ?.horizontalTranslation
                  ? (skillDropdown.skillCategory.discipline.horizontal?.horizontalTranslation.find(
                      (y) => y.idLanguage === i18n.language
                    )?.name ??
                    skillDropdown.skillCategory.discipline.horizontal?.name)
                  : "-",
              },
              {
                value: skillDropdown.skillCategory.discipline.name ?? "-",
              },
              {
                value: skillDropdown.skillCategory?.skillCategoryTranslation
                  ? (skillDropdown.skillCategory.skillCategoryTranslation.find(
                      (y) => y.idLanguage === i18n.language
                    )?.name ?? skillDropdown.skillCategory?.name)
                  : "-",
              },
              {
                value: skillDropdown
                  ? (skillDropdown.skillTranslation.find(
                      (y) => y.idLanguage === i18n.language
                    )?.name ?? skillDropdown?.name)
                  : "-",
              },
              {
                CellRenderer: () => (
                  <UserSkillLevelAutocompleteFormik
                    name={`skillsTable[${index}].level`}
                    autocompleteProps={{
                      fullWidth: true,
                    }}
                  />
                ),
              },
              {
                CellRenderer: () => (
                  <DeleteIconButton
                    onClick={() => {
                      setFieldValue(
                        "skillsDropdown",
                        values.skillsDropdown.filter(
                          (sd) => sd.id !== skillDropdown.id
                        )
                      );
                    }}
                  />
                ),
              },
            ],
          };
        }
      );

      rows.reverse();

      return rows;
    }

    return [];
  }, [values?.skillsDropdown.length, values.skillsTable.length, i18n.language]);

  return (
    <div>
      <StyledViewContent>
        <HorizontalAutocompleteFormik
          name="horizontal"
          autocompleteProps={{ disabled: isSubmitting }}
          blockGeneral={false}
        />
        <DisciplinesAutocompleteFormik
          name="discipline"
          autocompleteProps={{
            disabled: isSubmitting,
          }}
          filters={{
            idHorizontal: values.horizontal?.id ?? undefined,
          }}
        />
        <SkillCategoriesAutocompleteFormik
          name="skillCategory"
          filters={{
            onlyActivated: true,
            idDiscipline: values.discipline?.id,
          }}
          autocompleteProps={{
            disabled: isSubmitting,
          }}
        />
        <SkillsMultipleAutocompleteFormik
          name="skillsDropdown"
          filters={{
            onlyActivated: true,
            idDiscipline: values.discipline?.id,
            idHorizontal: values.horizontal?.id,
            idSkillCategory: values.skillCategory?.id,
          }}
          autocompleteProps={{
            disabled: isSubmitting,
            multiple: true,
            disableCloseOnSelect: true,
          }}
        />
      </StyledViewContent>
      <StyledLine />
      <StyledAreaListLanguages>
        {values.skillsDropdown.length > 0 ? (
          <SkillsViewTable rows={rows} />
        ) : (
          <Typography>
            {t("collaboratorProfile.keywords.warnings.dontHaveSkills")}
          </Typography>
        )}
      </StyledAreaListLanguages>
    </div>
  );
};
