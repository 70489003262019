import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { FormattedCurrency } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { FormattedNumber } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedNumber";
import {
  useViewingCommercialMvModal,
  useViewingMvAtCompletionModal,
  useViewingMvFinancialCostModal,
  useViewingPlannedMvModal,
} from "../../../layout/PlanningHeader/hooks/useViewingMvModal";
import { CenteredHeaderTitle } from "../../../layout/PlanningHeader/styles/general.styles";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../shared/react/styles/ui/layouts/general.styles";
import { SummaryContext } from "../shared/react/contexts/SummaryContext";

export const SummaryHeader = () => {
  const { t } = useTranslation();
  const { summaryData, isFrame } = useContext(SummaryContext);

  const { viewingCommercialMvButton, viewingCommercialMvModal } =
    useViewingCommercialMvModal();
  const { viewingPlannedMvButton, viewingPlannedMvModal } =
    useViewingPlannedMvModal();
  const { viewingMvAtCompletionButton, viewingMvAtCompletionModal } =
    useViewingMvAtCompletionModal();

  const {
    viewingMvFinancialCostAtCompletionButton,
    viewingMvFinancialCostAtCompletionModal,
  } = useViewingMvFinancialCostModal();

  if (!summaryData) return null;

  return (
    <StyledSubHeaderFixed>
      <StyledInfoItem>
        <div>{t("planningSummary.keywords.fields.grossRevenue")}</div>
        <div>
          <FormattedCurrency
            value={summaryData.header.grossRevenue}
            currencySymbol={summaryData?.header.subsidiary.currencySymbol ?? ""}
          />
        </div>
      </StyledInfoItem>
      {!isFrame && (
        <>
          <StyledInfoItem>
            <div>{t("general.keywords.fields.tax")}</div>
            <div>
              <FormattedNumber
                value={summaryData.header.tax}
                options={{ suffix: "%", fractionDigits: 2 }}
              />
            </div>
          </StyledInfoItem>
          <StyledInfoItem>
            <div>{t("general.keywords.fields.daysForPayment")}</div>
            <div>
              <FormattedNumber value={summaryData.header.daysForPayment} />
            </div>
          </StyledInfoItem>
        </>
      )}
      <StyledInfoItem>
        <CenteredHeaderTitle>
          {t("planningSummary.keywords.fields.commercialMv.name")}
          {viewingCommercialMvButton}
          {viewingCommercialMvModal}
        </CenteredHeaderTitle>
        <div>
          <FormattedNumber
            value={summaryData.header.commercialMv}
            defaultValue={t("general.keywords.abbreviations.notAvaliable")}
            options={{ suffix: "%", fractionDigits: 2 }}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <CenteredHeaderTitle>
          {t("planningSummary.keywords.fields.plannedMv.name")}
          {viewingPlannedMvButton}
          {viewingPlannedMvModal}
        </CenteredHeaderTitle>
        <div>
          <FormattedNumber
            value={summaryData.header.globalMv}
            defaultValue={t("general.keywords.abbreviations.notAvaliable")}
            options={{ suffix: "%", fractionDigits: 2 }}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <CenteredHeaderTitle>
          {t("planningSummary.keywords.fields.mvAtCompletion.name")}
          {viewingMvAtCompletionButton}
          {viewingMvAtCompletionModal}
        </CenteredHeaderTitle>
        <div>
          <FormattedNumber
            value={summaryData.header.atCompletionMv}
            defaultValue={t("general.keywords.abbreviations.notAvaliable")}
            options={{ suffix: "%", fractionDigits: 2 }}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <CenteredHeaderTitle>
          {t(
            "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.name"
          )}
          {viewingMvFinancialCostAtCompletionButton}
          {viewingMvFinancialCostAtCompletionModal}
        </CenteredHeaderTitle>
        <div>
          <FormattedNumber
            value={summaryData.header.atCompletionMvWithFinancialCost}
            defaultValue={t("general.keywords.abbreviations.notAvaliable")}
            options={{ suffix: "%", fractionDigits: 2 }}
          />
        </div>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};
