import { ReactNode, useMemo } from "react";
import { TimesheetCalendarContextValues } from "./index.types";
import { TimesheetCalendarContext } from "./TimesheetCalendarContext";

interface OwnProps extends TimesheetCalendarContextValues {
  children: ReactNode;
}

export const TimesheetCalendarContextProvider = ({
  name,
  timesheetMonth,
  isReadOnly,
  children,
}: OwnProps) => {
  const contextValues = useMemo(() => {
    return { name, timesheetMonth, isReadOnly };
  }, [name, timesheetMonth, isReadOnly]);

  return (
    <TimesheetCalendarContext.Provider value={contextValues}>
      {children}
    </TimesheetCalendarContext.Provider>
  );
};
