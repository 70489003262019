import { createContext } from "react";
import { SummaryContextValues } from "./index.types";

const defaultValues: SummaryContextValues = {
  summaryData: null,
  summaryErrorStatusCode: null,
  isLoadingSummary: false,
  summaryHasError: false,
  summaryTranslationError: null,
  isFrame: false,
};

export const SummaryContext =
  createContext<SummaryContextValues>(defaultValues);
