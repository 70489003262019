import * as yup from "yup";
import { YearMonth } from "../../../shared/common/classes/data/date/YearMonth";
import { CollaboratorHistoryCreateDTO } from "../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryCreateDTO";
import {
  CollaboratorHistoryDTO,
  schemaCollaboratorHistoryDTO,
} from "../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryDTO";
import { CollaboratorHistoryUpdateDTO } from "../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryUpdateDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { riseApi } from "./connection";
import {
  UserAllocationByHistoryDTO,
  schemaUserAllocationByHistoryDTO,
} from "../../../shared/specific/DTOs/collaboratorHistory/UserAllocationByHistoryDTO";
import { CollaboratorHistoryUploadResponseDTO } from "../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryUploadResponseDTO";

export const getCollaboratorHistory = async (
  id: number
): Promise<CollaboratorHistoryDTO[]> => {
  const { data: collaboratorHistorys } = await riseApi.get(
    `/CollaboratorHistory/ListByUserId/${id}`
  );

  return yup
    .array(schemaCollaboratorHistoryDTO)
    .defined()
    .validateSync(collaboratorHistorys);
};

export const createCollaboratorHistory = async (
  history: CollaboratorHistoryCreateDTO
) => {
  await riseApi.post("/CollaboratorHistory", history);
};

export const getCollaboratorHistoryById = async (
  id: number
): Promise<CollaboratorHistoryDTO | null> => {
  const { data: collaboratorHistory } = await riseApi.get(
    `/CollaboratorHistory/${id}`
  );

  return schemaCollaboratorHistoryDTO.validateSync(collaboratorHistory);
};

export const getUserAllocationByHistory = async (
  historyId: number
): Promise<UserAllocationByHistoryDTO[]> => {
  const { data: allocations } = await riseApi.get(
    `/CollaboratorHistory/AllocationsByHistory/${historyId}`
  );

  return yup
    .array(schemaUserAllocationByHistoryDTO)
    .defined()
    .validateSync(allocations);
};

export const getCostCenterLoggedUser = async (
  yearMonth: YearMonth
): Promise<CostCenterDTO | null> => {
  const { data: costCenter } = await riseApi.get(
    `/CollaboratorHistory/CostCenterLoggedUser/${yearMonth}`
  );

  return schemaCostCenterDTO.validateSync(costCenter);
};

export const deleteCollaboratorHistory = async (
  id: number,
  version: string
) => {
  await riseApi.delete(`CollaboratorHistory/${id}/${version}`);
};

export const updateCollaboratorHistory = async (
  history: CollaboratorHistoryUpdateDTO
) => {
  await riseApi.put("/CollaboratorHistory", history);
};

export const uploadCollaboratorsHistory = async (
  file: File
): Promise<CollaboratorHistoryUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: collaboratorUploadResponse } = await riseApi.post(
    "CollaboratorHistory/UploadCollaboratorHistory",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return collaboratorUploadResponse;
};
