import * as yup from "yup";
import {
  LabelColorType,
  LabelType,
} from "../../../../Router/pages/authenticated/AllocationsMap/AllocationsMapMainPage/shared/types/form.types";
import {
  AllocationWeekDTO,
  schemaAllocationWeekDTO,
} from "./AllocationWeekDTO";

export interface AllocationCollaboratorDTO {
  idUserCollaborator: number;
  divisionName: string | null;
  segmentName: string | null;
  name: string;
  registryNumber: string | null;
  customerName: string | null;
  projectOrCostCenterName: string | null;
  jobRoleName: string;
  contractRoleName: string | null;
  disciplineName: string | null;
  projectManagerName: string | null;
  projectCoordinatorName: string | null;
  customerOfficeAllocation: boolean | null;
  vacationForecast: string | null;
  allocationMapObservation: string | null;
  labelColorType: LabelColorType;
  labelType: LabelType;
  allocationWeeks: AllocationWeekDTO[];
}

export const schemaAllocationCollaboratorDTO = yup.object({
  idUserCollaborator: yup.number().integer().defined(),
  divisionName: yup.string().nullable().defined(),
  segmentName: yup.string().defined().nullable(),
  name: yup.string().defined(),
  registryNumber: yup.string().nullable().defined(),
  customerName: yup.string().nullable().defined(),
  projectOrCostCenterName: yup.string().nullable().defined(),
  jobRoleName: yup.string().defined(),
  contractRoleName: yup.string().nullable().defined(),
  disciplineName: yup.string().nullable().defined(),
  projectManagerName: yup.string().nullable().defined(),
  projectCoordinatorName: yup.string().nullable().defined(),
  customerOfficeAllocation: yup.bool().nullable().defined(),
  vacationForecast: yup.string().nullable().defined(),
  allocationMapObservation: yup.string().defined().nullable(),
  labelColorType: yup.number().integer().defined(),
  labelType: yup.number().integer().defined(),
  allocationWeeks: yup.array(schemaAllocationWeekDTO).defined(),
});
