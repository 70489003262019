import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../shared/react/components/content/CreationEditingForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CostDistributionCreateDTO } from "../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionCreateDTO";
import { createCostDistribution } from "../../../../../../services/costDistribution/costDistribution.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          const { t } = useTranslation();

          return (
            <div>
              {t("costDistribution.keywords.fields.createCostDistribution")}
            </div>
          );
        },
      },
      button: {
        FormButton: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: (formikProps) => (
          <CreationEditingForm formikProps={formikProps} formMode="create" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const costsUsToSave: CostDistributionCreateDTO[] = formValues.costUs
              .filter((x) => parseLocaleNumber(x.percentage) > 0)
              .map((x) => ({
                expenseType: x.expenseType,
                percentage: parseLocaleNumber(x.percentage),
                customerTrigram:
                  formValues.customerTrigram != null
                    ? formValues.customerTrigram
                    : null,
                idSegmentGroup: formValues.segmentGroup?.id ?? 0,
                idSubsidiary: formValues.subsidiary?.id ?? 0,
                originCost: "RDX.US",
              }));

            const costsBrToSave: CostDistributionCreateDTO[] = formValues.costBr
              .filter((x) => parseLocaleNumber(x.percentage) > 0)
              .map((x) => ({
                expenseType: x.expenseType,
                percentage: parseLocaleNumber(x.percentage),
                customerTrigram:
                  formValues.customerTrigram != null
                    ? formValues.customerTrigram
                    : null,
                idSegmentGroup: formValues.segmentGroup?.id ?? 0,
                idSubsidiary: formValues.subsidiary?.id ?? 0,
                originCost: "RDX.BR",
              }));

            await createCostDistribution([...costsUsToSave, ...costsBrToSave]);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            console.error(error);

            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "costCenters.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
