import { Box, TextFieldProps } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { getLanguageSkills } from "../../../../../../../../../services/skill/skill.service";
import { SkillDTO } from "../../../../../../../DTOs/skill/SkillDTO";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
}

const cachedOffices = new CachedTranslatedData({
  callback: getLanguageSkills,
  defaultCacheDuration: 600000, // 10 minutes
});

export const LanguageSkillMultipleAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "collaboratorProfile.errors.data.fetch.failedToFetchLanguageSkills"
      )}
      fetchOptionsMemo={cachedOffices.run}
      textfieldProps={{
        label: t("collaboratorProfile.keywords.fields.languages"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.name),
        isOptionEqualToValueMemo: (option, value) => option.name === value.name,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.name}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
