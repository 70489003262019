import * as yup from "yup";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { UsersCollaboratorsFiltersDTO } from "../../../shared/specific/DTOs/user/filters/UsersCollaboratorsFiltersDTO";
import {
  PaginatedUserDTO,
  schemaPaginatedUserDTO,
} from "../../../shared/specific/DTOs/user/PaginatedUserDTO";
import {
  PaginatedUserCurrentJobRoleDTO,
  schemaPaginatedUserCurrentJobRoleDTO,
} from "../../../shared/specific/DTOs/user/PaginatedUserCurrentJobRoleDTO";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { riseApi } from "./connection";
import { UsersCurrentJobRoleFilterDTO } from "../../../shared/specific/DTOs/user/filters/UsersCurrentJobRoleFilterDTO";
import { UsersCollaboratorFilterDTO } from "../../../shared/specific/DTOs/user/filters/UsersCollaboratorFilterDTO";
import {
  UserCurrentJobRoleDTO,
  schemaUserCurrentJobRoleDTO,
} from "../../../shared/specific/DTOs/user/UserCurrentJobRoleDTO";
import { PaginatedUserCollaboratorRecentlyHistoryDTO } from "../../../shared/specific/DTOs/user/PaginatedUserCollaboratorRecentlyHistoryDTO";
import { UserCollaboratorCreateDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorCreateDTO";
import { UserCollaboratorRecentlyHistoryDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorRecentlyHistoryDTO";
import { UserCollaboratorUpdateDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorUpdateDTO";
import { UserCollaboratorTurnOffDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorTurnOffDTO";
import { UserCollaboratorTurnOnDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorTurnOnDTO";
import { CollaboratorUploadResponseDTO } from "../../../shared/specific/DTOs/user/CollaboratorUploadResponseDTO";
import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../../../shared/specific/DTOs/user/UserCollaboratorOnlyDTO";
import { UserCollaboratorLocalityUpdateDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorLocalityUpdateDTO";
import { UserCollaboratorEffectiveDTO } from "../../../shared/specific/DTOs/user/UserCollaboratorEffectiveDTO";

export const getUsers = async ({
  filters,
}: {
  filters?: UsersCollaboratorsFiltersDTO;
} = {}): Promise<UserCollaboratorDTO[]> => {
  const { data: users } = await riseApi.get("users", { params: filters });

  return yup.array(schemaUserCollaboratorDTO).defined().validateSync(users);
};

export const getPaginatedUsers = async ({
  filters,
  pagination,
}: {
  filters?: UsersCollaboratorsFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedUserDTO> => {
  const { data: users } = await riseApi.get("users/paginated", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedUserDTO.validateSync(users);
};

export const getAllWithRecentlyHistory = async ({
  filters,
  pagination,
}: {
  filters?: UsersCollaboratorsFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedUserCollaboratorRecentlyHistoryDTO> => {
  const { data: users } = await riseApi.get("users/GetAllWithRecentlyHistory", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedUserDTO.validateSync(users);
};

export const getPaginatedUsersCurrentJobRole = async ({
  filters,
  pagination,
}: {
  filters?: UsersCurrentJobRoleFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedUserCurrentJobRoleDTO> => {
  const { data: users } = await riseApi.get(
    "users/userCurrentJobRole/paginated",
    {
      params: { ...filters, ...pagination },
    }
  );

  return schemaPaginatedUserCurrentJobRoleDTO.validateSync(users);
};

export const getUserCurrentJobRole = async ({
  id,
  filters,
}: {
  id?: number;
  filters?: UsersCollaboratorFilterDTO;
} = {}): Promise<UserCurrentJobRoleDTO | null> => {
  const { data: userColaborator } = await riseApi.get(
    `users/userCurrentJobRole/${id}`,
    {
      params: filters,
    }
  );

  return schemaUserCurrentJobRoleDTO.nullable().validateSync(userColaborator);
};

export const getLoggedUser = async (): Promise<UserCollaboratorDTO | null> => {
  const { data: loggedUser } = await riseApi.get("users/loggedUser");

  return schemaUserCollaboratorDTO.nullable().validateSync(loggedUser);
};

export const createUserCollaborator = async (
  userCollaborator: UserCollaboratorCreateDTO
): Promise<UserCollaboratorOnlyDTO> => {
  const { data: createdCollaborator } = await riseApi.post(
    "/users/",
    userCollaborator
  );

  return schemaUserCollaboratorOnlyDTO.validateSync(createdCollaborator);
};

export const getUserCollaboratorById = async (
  id: number
): Promise<UserCollaboratorDTO | null> => {
  const { data: userCollaborator } = await riseApi.get(`users/${id}`);

  return schemaUserCollaboratorDTO.nullable().validateSync(userCollaborator);
};

export const getByIdWithRecentlyHistory = async (
  id: number
): Promise<UserCollaboratorRecentlyHistoryDTO | null> => {
  const { data: userCollaborator } = await riseApi.get(
    `users/GetByIdWithRecentlyHistory/${id}`
  );

  return userCollaborator;
};

export const deleteUserCollaborator = async (id: number, version: string) => {
  await riseApi.delete(`users/${id}/${version}`);
};

export const updateUserCollaborator = async (
  userCollaborator: UserCollaboratorUpdateDTO
) => {
  await riseApi.put("users", userCollaborator);
};

export const updateUserCollaboratorLocality = async (
  dto: UserCollaboratorLocalityUpdateDTO
) => {
  await riseApi.put("users/Locality", dto);
};

export const turnOffUserCollaborator = async (
  dto: UserCollaboratorTurnOffDTO
) => {
  await riseApi.put("users/turnoff", dto);
};

export const turnOnUserCollaborator = async (
  dto: UserCollaboratorTurnOnDTO
) => {
  await riseApi.put("users/turnon", dto);
};

export const uploadCollaborators = async (
  file: File
): Promise<CollaboratorUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: collaboratorUploadResponse } = await riseApi.post(
    "users/userCollaboratorFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return collaboratorUploadResponse;
};

export const hireUserCollaborator = async (
  userCollaboratorEffective: UserCollaboratorEffectiveDTO
): Promise<UserCollaboratorOnlyDTO> => {
  const { data: createdCollaborator } = await riseApi.post(
    "users/hireIntern",
    userCollaboratorEffective
  );
  return schemaUserCollaboratorOnlyDTO.validateSync(createdCollaborator);
};
