import * as yup from "yup";

export enum Language {
  ptBR = "pt-BR",
  enUS = "en-US",
}

export const schemaLanguage = yup
  .mixed<Language>()
  .oneOf(Object.values(Language) as Language[]);
