import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  ExpenseType,
  schemaExpenseType,
} from "../../enums/expenses/ExpenseType";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../costCenterPeps/CostCenterPepOnlyDTO";

export interface ExpenseDTO {
  id: number;
  costCenterPep: CostCenterPepOnlyDTO;
  yearMonth: YearMonth;
  amount: number;
  expenseType: ExpenseType;
  description: string | null;
  observation: string | null;
  version: string;
}

export const schemaExpenseDTO = yup.object({
  id: yup.number().integer().defined(),
  costCenterPep: schemaCostCenterPepOnlyDTO.defined(),
  yearMonth: schemaYearMonth.defined(),
  amount: yup.number().defined(),
  expenseType: schemaExpenseType.defined(),
  description: yup.string().nullable().defined(),
  observation: yup.string().nullable().defined(),
  version: yup.string().defined(),
});
