import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../costCenterPeps/CostCenterPepOnlyDTO";
import {
  DeprovisionTypeDTO,
  schemaDeprovisionTypeDTO,
} from "../deprovisionType/DeprovisionTypeDTO";

export interface ActivityDTO {
  id: number;
  description: string;
  baselineDate: DateOnly | null;
  scheduledDate: DateOnly;
  completedDate: DateOnly | null;
  amount: number;
  deprovisionJustification: string | null;
  costCenterPep: CostCenterPepOnlyDTO;
  deprovisionType: DeprovisionTypeDTO | null;
  version: string;
}

export const schemaActivityDTO = yup.object({
  id: yup.number().integer().defined(),
  description: yup.string().defined(),
  baselineDate: schemaDateOnly.nullable().defined(),
  scheduledDate: schemaDateOnly.defined() as yup.Schema<DateOnly>,
  completedDate: schemaDateOnly
    .nullable()
    .defined() as yup.Schema<DateOnly | null>,
  amount: yup.number().defined(),
  deprovisionJustification: yup.string().nullable().defined(),
  costCenterPep: schemaCostCenterPepOnlyDTO.defined(),
  deprovisionType: schemaDeprovisionTypeDTO.nullable().defined(),
  version: yup.string().defined(),
});
