import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getExpenseByProperties } from "../../../../../../../../../../../services/expenses/expenses.service";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatCurrency } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { DeleteIconButton } from "../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { EditIconButton } from "../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpenseType } from "../../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { PermissionLevel } from "../../../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";

interface OwnProps {
  expenseType: ExpenseType;
  costCenterPep: CostCenterPepOnlyDTO;
  yearMonth: YearMonth | null;
  isReadOnly: boolean;
  reloadTablePage: () => void;
  reloadMainTablePage: () => void;
}

export const useTableData = ({
  expenseType,
  costCenterPep,
  yearMonth,
  isReadOnly,
  reloadTablePage,
  reloadMainTablePage,
}: OwnProps) => {
  const { t } = useTranslation();

  const [expensesSum, setExpensesSum] = useState<number>(0);

  const { DeletionButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
    reloadMainTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
    reloadMainTablePage,
    expenseType,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.value"),
      t("general.keywords.fields.description"),
      t("allocationsMap.keywords.general.observation"),
      {
        value: "",
        width: 100,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(
      async ({ setInfo }) => {
        try {
          const getData = async () => {
            if (expenseType === ExpenseType.Workforce) {
              const workForce = yearMonth
                ? await getExpenseByProperties({
                    expenseType,
                    idCostCenterPep: costCenterPep.id,
                    yearMonth,
                  })
                : [];

              const additional = yearMonth
                ? await getExpenseByProperties({
                    expenseType: ExpenseType.Additional,
                    idCostCenterPep: costCenterPep.id,
                    yearMonth,
                  })
                : [];

              return [...workForce, ...additional];
            }
            const expenses = yearMonth
              ? await getExpenseByProperties({
                  expenseType,
                  idCostCenterPep: costCenterPep.id,
                  yearMonth,
                })
              : [];

            return expenses;
          };

          const expenses = await getData();

          if (expenses.length === 0) {
            if (!isReadOnly) {
              setInfo("expenses.keywords.general.info.clickToAdd");
            } else {
              setInfo("expenses.keywords.general.info.withoutExpense");
            }
          }

          setExpensesSum(
            expenses.map((x) => x.amount).reduce((a, b) => a + b, 0)
          );

          const rows: ExternalEnhancedRow[] = expenses.map(
            (expenseData): ExternalEnhancedRow => ({
              id: expenseData.id,
              cells: [
                {
                  value: expenseData.amount,
                  displayValue: formatCurrency({
                    currencySymbol: costCenterPep.currencySymbol,
                    value: expenseData.amount,
                  }),
                },
                expenseData.description || "-",
                expenseData.observation || "-",
                {
                  CellRenderer: () =>
                    isReadOnly ||
                    expenseData.expenseType === ExpenseType.Workforce ? (
                      <>
                        <EditIconButton disabled />
                        <DeleteIconButton disabled />
                      </>
                    ) : (
                      <>
                        <Protected
                          restrictions={{
                            type: PermissionType.Expenses,
                            level: PermissionLevel.Update,
                          }}
                        >
                          <EditingButtonContainer {...expenseData} />
                        </Protected>
                        <Protected
                          restrictions={{
                            type: PermissionType.Expenses,
                            level: PermissionLevel.Delete,
                          }}
                        >
                          <DeletionButtonContainer {...expenseData} />
                        </Protected>
                      </>
                    ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return rows;
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [expenseType, costCenterPep, yearMonth, isReadOnly]
    );

  return {
    headCells,
    onTablePageChange,
    editingModal,
    deletionModal,
    expensesSum,
  };
};
