import { useMemo } from "react";
import { Checkbox, CheckboxProps } from "@mui/material";
import { useField } from "formik";

export type CheckboxFormikProps = CheckboxProps & {
  name: string;
  inputPropsMemo?: CheckboxProps["inputProps"];
};

export const CheckboxFormik = ({
  name,
  inputProps,
  inputPropsMemo,
  ...rest
}: CheckboxFormikProps) => {
  const [{ onChange, value, ...field }, , { setValue }] = useField(name);
  const inputPropsFinal = useMemo(() => {
    return inputProps ?? inputPropsMemo ?? {};
  }, [inputProps]);

  return useMemo(() => {
    return (
      <Checkbox
        inputProps={inputPropsFinal}
        checked={value ?? false}
        onChange={(event, checked) => setValue(checked)}
        {...field}
        {...rest}
      />
    );
  }, [
    ...Object.values(field),
    ...Object.values(inputPropsFinal),
    ...Object.values(rest),
    value,
  ]);
};
