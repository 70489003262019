import { styled } from "@mui/material";

export const StyledProjectInfo = styled("div")`
  padding-bottom: 16px;

  & > :first-child {
    ${({ theme }) => theme.typography.h5}
  }
`;

export const StyledProjectButtons = styled("span")`
  padding-left: ${({ theme }) => theme.spacing(1)};
`;
