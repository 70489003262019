import { TextField, TextFieldProps } from "@mui/material";
import { ComponentProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IMaskMixin } from "react-imask";
import { Language } from "../../../../../enums/locale/Language.enum";
import {
  getDecimalSeparator,
  getThousandSeparator,
} from "../../../../../helpers/data/numbers/localization/general.helpers";

type MaskProps = ComponentProps<typeof InternalMaskedTextField>;

export type MaskedTextFieldProps = MaskProps & {
  textFieldProps?: TextFieldProps;
  automaticLocaleSeparators?: Language | "current";
};

const InternalMaskedTextField = IMaskMixin(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ textFieldProps, ...props }: any) => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <TextField {...(textFieldProps as any)} {...(props as any)} />
    );
  }
);

export const MaskedTextField = ({
  automaticLocaleSeparators,
  ...rest
}: MaskedTextFieldProps) => {
  const { i18n } = useTranslation();

  const localeSeparators = useMemo(() => {
    if (!automaticLocaleSeparators) return undefined;

    const locale =
      automaticLocaleSeparators === "current"
        ? (i18n.language as Language)
        : automaticLocaleSeparators;

    return {
      thousandsSeparator: getThousandSeparator(locale),
      radix: getDecimalSeparator(locale),
    };
  }, [i18n.language, automaticLocaleSeparators]);

  return <InternalMaskedTextField {...localeSeparators} {...rest} />;
};
