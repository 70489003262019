import { useMemo, useState } from "react";
import {
  FooterActionsProps,
  useModalButton,
  UseModalButtonProps,
} from "../../modalButtons/useModalButton";
import { InternalModalActions } from "./InternalModalActions";
import { DefaultDialogActionsProps } from "./shared/types/props.types";

export type {
  ButtonProps,
  FooterActionsProps,
  FormContentProps,
  UseModalButtonRef as UseActionButtonDialogRef,
} from "../../modalButtons/useModalButton";

export type { OnConfirm } from "./shared/types/props.types";

export interface UseActionButtonDialogProps<
  InternalModalData,
  ContentButtonContentProps,
> extends UseModalButtonProps<InternalModalData, ContentButtonContentProps> {
  /**
   * Props related to the default action buttons.
   */
  defaultDialogActionsProps?: DefaultDialogActionsProps<InternalModalData>;
}

export const useActionButtonDialog = <
  InternalModalData,
  ContentButtonContentProps = InternalModalData,
>({
  defaultDialogActionsProps: {
    onConfirm,
    onConfirmMemo,
    cancelButtonLabel,
    confirmButtonLabel,
    checkConfirmDisabled,
    checkConfirmDisabledMemo,
  } = {},
  ...rest
}: UseActionButtonDialogProps<
  InternalModalData,
  ContentButtonContentProps
>) => {
  const {
    modal: { modalColorVariant, modalActions, ModalActions, ModalActionsMemo },
  } = rest;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const FinalModalActions = useMemo(() => {
    if (modalActions) return undefined;

    if (ModalActionsMemo) return undefined;

    if (ModalActions) return ModalActions;

    return ({
      closeModal,
      internalModalData,
      isLoadingModal,
      modalError,
    }: FooterActionsProps<InternalModalData>) => (
      <InternalModalActions
        closeModal={closeModal}
        internalModalData={internalModalData}
        isLoadingModal={isLoadingModal}
        modalError={modalError}
        modalColorVariant={modalColorVariant}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        defaultDialogActionsProps={{
          cancelButtonLabel,
          confirmButtonLabel,
          onConfirm,
          onConfirmMemo,
          checkConfirmDisabled,
          checkConfirmDisabledMemo,
        }}
      />
    );
  }, [
    ModalActions,
    modalColorVariant,
    cancelButtonLabel,
    confirmButtonLabel,
    onConfirm,
    checkConfirmDisabled,
    isSubmitting,
  ]);

  const { contentButton, contentModal, ModalButtonContainer, openModal } =
    useModalButton<InternalModalData, ContentButtonContentProps>({
      ...rest,
      modal: {
        mode: "dialog",
        ...rest.modal,
        ModalActions: FinalModalActions,
        suppressInternalCloseModal: isSubmitting,
      },
    });

  return { contentButton, contentModal, ModalButtonContainer, openModal };
};
