import * as yup from "yup";

export interface ApiErrorDTO {
  id: string;
  status: number;
  title: string;
  message: string;
  errorCode: string | null;
  details: { [key: string]: unknown };
}

export const schemaApiErrorDTO = yup.object({
  id: yup.string().defined(),
  status: yup.number().integer().defined(),
  title: yup.string().defined(),
  message: yup.string().defined(),
  errorCode: yup.string().nullable().defined(),
  details: yup.object(),
});
