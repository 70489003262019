import { AutocompleteProps, Box, TextFieldProps } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikRef,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CountryDTO } from "../../../../../../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { cachedCountries } from "../../../../../../objects/data/cache/cachedFunctions.objects";
import {
  StyledIcon,
  StyledName,
  StyledMultipleSelectionContainer,
} from "../../../../../../../../../../../../shared/specific/react/components/form/shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../../../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteProps<CountryDTO, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  >;
}

export const CountriesMultipleAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const asyncAutocompleteRef = useRef<AsyncFastAutocompleteFormikRef | null>(
    null
  );

  useEffect(() => {
    asyncAutocompleteRef.current?.rerunFetch();
  }, [i18n.language]);

  return (
    <AsyncAutocompleteFormik
      innerRef={asyncAutocompleteRef}
      name={name}
      customErrorMessage={t("countries.errors.data.fetch.failedToFetch")}
      fetchOptionsMemo={cachedCountries.run}
      textfieldProps={{
        label: t("countries.keywords.general.country"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (option) => option.name,
        isOptionEqualToValueMemo: (option, value) => option.code === value.code,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.code}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.name}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
    />
  );
};
