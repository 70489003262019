import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ProjectionAdjustmentMonthlyCommentFormValues } from "../types/ProjectionAdjustmentMonthlyCommentFormValues";

export const useCreationEditingFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: ProjectionAdjustmentMonthlyCommentFormValues =
    useMemo(() => {
      return {
        comment: "",
      };
    }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      comment: yup.string().nullable().max(1000),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
