import { ReactNode, useContext, useMemo } from "react";
import {
  EnhancedRowCellData,
  EnhancedRowData,
} from "../../../shared/types/data.types";
import { StyledTableCellContainer } from "./styles/StyledTableCellContainer.styles";
import { TableContext } from "../../../shared/contexts/TableContext";
import { classnames } from "../../../shared/constants/classnames";
import { StyledTableCell } from "./styles/StyledTableCell.styles";

interface OwnProps {
  cell: EnhancedRowCellData;
  position: number;
  row: EnhancedRowData;
}

export const EnhancedTableCell = ({ cell, position, row }: OwnProps) => {
  const {
    ui: { fixedcellheight, mincellheight, stickyHeader },
    headCells,
  } = useContext(TableContext);

  const headCell = headCells[position];

  const cellValue: ReactNode = useMemo(() => {
    if (cell.CellRenderer)
      return (
        <cell.CellRenderer cellData={cell} position={position} rowData={row} />
      );

    if (cell.displayValue) return <>{cell.displayValue}</>;

    if (headCell.CellRenderer)
      return (
        <headCell.CellRenderer
          cellData={cell}
          position={position}
          rowData={row}
        />
      );

    return <>{cell.value}</>;
  }, [cell, headCell.CellRenderer, row]);

  const enhancedTableCell = useMemo(() => {
    const width =
      typeof headCell.width === "string" ? undefined : headCell.width;

    return (
      <StyledTableCell
        key={position}
        align={cell.align}
        width={headCell.width}
        headColumn={headCell.headColumn}
        stickyHeader={stickyHeader}
      >
        <StyledTableCellContainer
          width={width}
          className={classnames.cellContent}
          mincellheight={mincellheight}
          fixedcellheight={fixedcellheight}
          paddingmode={cell.paddingmode}
          justifyContent={cell.justifyContent}
        >
          {cellValue}
        </StyledTableCellContainer>
      </StyledTableCell>
    );
  }, [
    cellValue,
    position,
    cell.align,
    headCell.width,
    mincellheight,
    fixedcellheight,
    headCell.headColumn,
    stickyHeader,
  ]);

  return enhancedTableCell;
};
