import { useMemo } from "react";
import { getNowBrazilianDateOnly } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { useProjectContext } from "../../../contexts/ProjectContext";
import { DateOnly } from "../../../../../../../../../../shared/common/classes/data/date/DateOnly";

interface OwnProps {
  cannotBeforeCurrentMonthErrorMessage: string;
  cannotBeforeProjectStartErrorMessage: string;
  canEditBeforeStartMonth: boolean;
}

export const useMinDateOrProjectStart = ({
  cannotBeforeProjectStartErrorMessage,
  cannotBeforeCurrentMonthErrorMessage,
  canEditBeforeStartMonth,
}: OwnProps) => {
  const { project } = useProjectContext();

  const minDateOnly = useMemo(() => {
    if (canEditBeforeStartMonth) return project.scheduledStartDate;

    const startOfCurrentMonth = getNowBrazilianDateOnly().toStartOfMonth();

    if (
      !project.scheduledStartDate ||
      project.scheduledStartDate.toDate() < startOfCurrentMonth.toDate()
    ) {
      const canEditOneDayAfter =
        project.subsidiary.name === "RDX.US" &&
        getNowBrazilianDateOnly().day < 2;

      if (!canEditOneDayAfter) {
        return startOfCurrentMonth;
      }

      const startOfCurrentMonthInDate = startOfCurrentMonth.toDate();
      startOfCurrentMonthInDate.setMonth(
        startOfCurrentMonthInDate.getMonth() - 1
      );

      return DateOnly.createFromDate(startOfCurrentMonthInDate);
    }

    return project.scheduledStartDate;
  }, [project.scheduledStartDate, canEditBeforeStartMonth]);

  const minDate = useMemo(() => {
    return minDateOnly?.toDate();
  }, [minDateOnly]);

  const minDateErrorMessage = useMemo(() => {
    if (!minDateOnly) return "";

    if (
      project.scheduledStartDate &&
      minDateOnly.equals(project.scheduledStartDate)
    )
      return cannotBeforeProjectStartErrorMessage;

    return cannotBeforeCurrentMonthErrorMessage;
  }, [minDateOnly]);

  return {
    minDate,
    minDateOnly,
    minDateErrorMessage,
  };
};
