import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonActivityFormValues } from "../../../../types/data/form/values.types";
import { parseLocaleNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useMinScheduledDate } from "../fields/useMinScheduledDate";

export const useActivityFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: CommonActivityFormValues = useMemo(() => {
    return {
      amount: "",
      description: "",
      scheduledDate: null,
    };
  }, []);

  const { minScheduledDate, minScheduledDateErrorMessage } =
    useMinScheduledDate();

  const validationSchema = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      description: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      scheduledDate: yup
        .date()
        .withMutation((schema) =>
          !minScheduledDate
            ? schema
            : schema.min(minScheduledDate, t(minScheduledDateErrorMessage))
        )
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t, minScheduledDate, minScheduledDateErrorMessage]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
