import { Button, Chip, Paper, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";

export const StyledMainContainer = styled("div")`
  overflow: auto;
`;

export const StyledFormContainer = styled("div")`
  display: grid;
  height: 68vh;
  overflow-y: auto;
  grid-template-columns: repeat(4, 1fr);
  padding-top: ${({ theme }) => theme.spacing(1.5)};
  padding-right: ${({ theme }) => theme.spacing(1.5)};
  grid-template-areas:
    "a a a a"
    "b c d e"
    "f f f f"
    "g g g g"
    "h h h h"
    "i i i i"
    "j j j j"
    "k k k k"
    "l l l l";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(13)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c c"
      "d d"
      "e e"
      "f f"
      "g g"
      "h h"
      "j j"
      "k k";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "j"
      "k";
  }
  position: relative;
`;

export const StyledFormSection = styled(Paper)`
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledInputSelectCompetence = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  button {
    height: 37px;
  }
`;

export const StyledGrayBox = styled(Chip)`
  padding: 0;
  margin: 5px;
  height: 35px;
  span {
    font-size: 15px;
  }
`;

export const StyledLabelChip = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};

  div {
    display: flex;
    width: max-content;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledTextField = styled(TextFieldFormik)`
  min-width: ${({ theme }) => theme.spacing(29.4)};
`;

export const StyledInformations = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const StyledListFiles = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    gap: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const UploadButtom = styled(Button)`
  max-width: ${({ theme }) => theme.spacing(35)};
`;

export const StyledButtons = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(1.5)};
  position: sticky;
  bottom: 0;
  z-index: 10;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledCloseButton = styled("div")`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const StyledEmailList = styled("div")`
  display: block;
  width: 100%;
  justify-content: space-between;
`;
