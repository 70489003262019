import { TableSortLabel } from "@mui/material";
import { useContext, useMemo } from "react";
import { classnames } from "../../shared/constants/classnames";
import { TableContext } from "../../shared/contexts/TableContext";
import { EnhancedHeadCellData } from "../../shared/types/data.types";
import { StyledTableCellHeadContainer } from "./styles/StyledTableCellHeadContainer.styles";
import { StyledTableHeadCell } from "./styles/StyledTableHeadCell.styles";

interface OwnProps {
  headCell: EnhancedHeadCellData;
  position: number;
}

export const EnhancedHeadCell = ({ headCell, position }: OwnProps) => {
  const {
    ui: { minheadcellheight, stickyHeader },
    sorting: { order, orderBy, onRequestSort },
  } = useContext(TableContext);

  const cellValue = useMemo(() => {
    const createSortHandler = () => {
      onRequestSort(position);
    };

    const cellDisplayValue =
      headCell.HeadRenderer?.({ position }) ?? headCell.value;

    if (!headCell.canSort) return cellDisplayValue;

    return (
      <TableSortLabel
        active={orderBy === position}
        direction={orderBy === position ? order : "asc"}
        onClick={createSortHandler}
      >
        {cellDisplayValue}
      </TableSortLabel>
    );
  }, [
    headCell.value,
    headCell.canSort,
    headCell.HeadRenderer,
    orderBy,
    order,
    position,
    onRequestSort,
  ]);

  const enhancedHeadCell = useMemo(() => {
    const width =
      typeof headCell.width === "string" ? undefined : headCell.width;

    return (
      <StyledTableHeadCell
        className={classnames.cellContent}
        align={headCell.align}
        sortDirection={orderBy === position ? order : false}
        width={headCell.width}
        minWidth={headCell.minWidth}
        headColumn={headCell.headColumn}
        stickyHeader={stickyHeader}
      >
        <StyledTableCellHeadContainer
          width={width}
          paddingmode={headCell.paddingmode}
          align={headCell.align}
          minheadcellheight={minheadcellheight}
          justifyContent={headCell.justifyContent}
        >
          {cellValue}
        </StyledTableCellHeadContainer>
      </StyledTableHeadCell>
    );
  }, [
    cellValue,
    orderBy,
    order,
    headCell.align,
    headCell.width,
    headCell.minWidth,
    headCell.headColumn,
    stickyHeader,
    position,
  ]);

  return enhancedHeadCell;
};
