import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonDeprovisionFormValues } from "../../../../types/data/form/values.types";
import { parseLocaleNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useMinScheduledDate } from "../fields/useMinScheduledDate";
import { getNowBrazilianDateOnly } from "../../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

export const useDeprovisionFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: CommonDeprovisionFormValues = useMemo(() => {
    return {
      amount: "",
      deprovisionType: null,
      description: "",
      scheduledDate: null,
      deprovisionJustification: "",
    };
  }, []);

  const { minScheduledDate, minScheduledDateErrorMessage } =
    useMinScheduledDate();

  const validationSchema = useMemo(() => {
    return yup.object({
      amount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      deprovisionType: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      description: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      scheduledDate: yup
        .date()
        .withMutation((schema) =>
          !minScheduledDate
            ? schema
            : schema.min(minScheduledDate, t(minScheduledDateErrorMessage))
        )
        .max(
          getNowBrazilianDateOnly().toDateEndOfDay(),
          t("general.errors.data.fields.dates.dateLaterAcceptableLimit")
        )
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      deprovisionJustification: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t, minScheduledDate]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
