import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useField } from "formik";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { CustomerDTO } from "../../../../../../../DTOs/customers/CustomerDTO";
import { getCustomers } from "../../../../../../../../../services/customers/customers.service";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";
import { CustomerListByFiltersDTO } from "../../../../../../../DTOs/customers/filters/CustomerListByFiltersDTO";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    CustomerDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: CustomerListByFiltersDTO;
  filtersMemo?: CustomerListByFiltersDTO;
}

export const CustomersSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: OwnProps<DisableClearable>) => {
  const [{ value: customer }] = useField<CustomerDTO | null>(name);

  const { t } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getCustomersFinal = useCallback(async (tradingName: string) => {
    const paginatedCustomers = await getCustomers({
      filters: { tradingName, ...finalFilters },
      pagination: { pageSize: 10 },
    });
    return paginatedCustomers.tableData;
  }, []);

  const endAdornment = useMemo(() => {
    if (!customer?.registrationCode) return undefined;
    return (
      <Tooltip title={customer.registrationCode} placement="top">
        <AutocompleteInfoBubble>
          {customer.registrationCode}
        </AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [customer]);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "customers.errors.data.fetch.failedToFetchCustomer"
      )}
      fetchOptionsMemo={getCustomersFinal}
      textfieldProps={{
        label: t("customers.keywords.general.customers"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          endAdornment,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.tradingName,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.tradingName}
            {option.registrationCode && `- ${option.registrationCode}`}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
