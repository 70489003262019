import { CircularProgress } from "@mui/material";
import { LoadingPageContainer } from "./index.styles";

export const FullPageLoading = () => {
  return (
    <LoadingPageContainer>
      <CircularProgress size={60} />
    </LoadingPageContainer>
  );
};
