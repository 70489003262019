import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { BrlParityDTO, schemaBrlParityDTO } from "./BrlParityDTO";

export type PaginatedBrlParityDTO = PaginationResponse<BrlParityDTO>;

export const schemaPaginatedBrlParityDTO =
  getSchemaPaginationResponse<BrlParityDTO>(schemaBrlParityDTO);
