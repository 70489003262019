import * as yup from "yup";
import { riseApi } from "./connection";
import { PiipedriveDealUpdateTypeDTO } from "../../../shared/specific/DTOs/pipedrive/PiipedriveDealUpdateTypeDTO";

export const getCustomersNames = async (): Promise<string[]> => {
  const { data: CustomersNames } = await riseApi.get(
    "Pipedrive/CustomersNames"
  );

  return yup
    .array(yup.string().defined())
    .defined()
    .validateSync(CustomersNames);
};

export const UpdateDealType = async (info: PiipedriveDealUpdateTypeDTO) => {
  await riseApi.put("Pipedrive/UpdateDealType", info);
};
