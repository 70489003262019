import { useMemo } from "react";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { ExpensesTabWithinContext } from "./ExpensesTabWithinContext";
import { ExpensesCarouselContextProvider } from "./shared/react/contexts/ExpensesCarouselContext";
import { ExpensesSummaryContextProvider } from "./shared/react/contexts/ExpensesSummaryContext";
import { ExpensesSuperUserContext } from "./shared/react/contexts/ExpensesSuperUserContext";

export const ExpensesTab = () => {
  const {
    superUserButton: canEditAllExpensesButton,
    inSuperUserMode: canEditAllExpenses,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllExpenses,
    tooltipNotInSuperUserModeOff:
      "expenses.actions.authorization.editExpensesWithoutRestrictions",
    tooltipInSuperUserMode:
      "expenses.actions.authorization.stopEditExpensesWithoutRestrictions",
  });

  const allocationsSuperUserProvider = useMemo(() => {
    return {
      canEditAllExpenses,
      canEditAllExpensesButton,
    };
  }, [canEditAllExpensesButton, canEditAllExpenses]);

  return (
    <ExpensesSuperUserContext.Provider value={allocationsSuperUserProvider}>
      <ExpensesCarouselContextProvider>
        <ExpensesSummaryContextProvider>
          <ExpensesTabWithinContext />
        </ExpensesSummaryContextProvider>
      </ExpensesCarouselContextProvider>
    </ExpensesSuperUserContext.Provider>
  );
};
