import * as yup from "yup";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  SkillCertificationDTO,
  schemaSkillCertificationDTO,
} from "../skillCertification/SkillCertificationDTO";

export interface UserCertificationDTO {
  id: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  skillCertification: SkillCertificationDTO;
  dateOfAcquisition: Date;
  expirationDate: Date | null;
  filePath: string;
  version: string;
}

export const schemaUserCertificationDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  skillCertification: schemaSkillCertificationDTO.defined(),
  dateOfAcquisition: yup.date().defined(),
  expirationDate: yup.date().nullable().defined(),
  filePath: yup.string().defined(),
  version: yup.string().defined(),
});
