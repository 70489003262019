import {
  CallbackFunction,
  ContructorArguments,
  RunFunction,
  StoredData,
} from "./index.types";

const DEFAULT_CACHED_DURATION = 600000; // 10 minutes

export class CachedData<T> {
  private callback: CallbackFunction<T>;
  private defaultCacheDuration: number;

  private currentlyRunningAsyncCall: Promise<T> | null = null;
  private storedDataValue: StoredData<T> | null = null;

  constructor({
    callback,
    defaultCacheDuration = DEFAULT_CACHED_DURATION,
  }: ContructorArguments<T>) {
    this.callback = callback;
    this.defaultCacheDuration = defaultCacheDuration;
  }

  public run: RunFunction<T> = async ({ cacheDuration } = {}) => {
    const finalCacheDuration = cacheDuration ?? this.defaultCacheDuration;

    if (this.currentlyRunningAsyncCall) return this.currentlyRunningAsyncCall;

    if (
      this.storedDataValue &&
      !this.checkIsPastCacheDuration(
        this.storedDataValue.lastUpdated,
        finalCacheDuration
      )
    )
      return this.storedDataValue.data;

    this.currentlyRunningAsyncCall = Promise.resolve(this.callback());

    try {
      const data = await this.currentlyRunningAsyncCall;
      this.storedDataValue = {
        data,
        lastUpdated: new Date(),
      };
      return data;
    } finally {
      this.currentlyRunningAsyncCall = null;
    }
  };

  private checkIsPastCacheDuration = (
    lastUpdated: Date,
    cacheDuration: number
  ) => {
    return Date.now() - lastUpdated.getTime() > cacheDuration;
  };
}
