import * as yup from "yup";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "../user/UserCollaboratorDTO";

export interface InvoiceCopyToUserDTO {
  id: number;
  userCollaborator: UserCollaboratorDTO;
}

export const schemaInvoiceCopyToUserDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorDTO.defined(),
});
