import { SvgIconComponent } from "@mui/icons-material";
import { ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  PageCardButtonContainer,
  StyledPageCardButtonsList,
} from "./index.styles";

interface OwnProps {
  title: string;
  Icon: SvgIconComponent;
  link: string;
}

export { StyledPageCardButtonsList as PageCardButtonsList };

export const PageCardButton = ({ title, Icon, link }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: "inline-block" }}>
      <ButtonBase component={Link} to={link}>
        <PageCardButtonContainer>
          <Icon color="primary" />
          <Typography>{t(title)}</Typography>
        </PageCardButtonContainer>
      </ButtonBase>
    </div>
  );
};
