import { css } from "@emotion/react";
import { styled } from "@mui/material";
import { HTMLAttributes } from "react";
import { centeredLayoutClassnames } from "../../../../ui/CenteredContent";

interface OwnProps extends HTMLAttributes<HTMLDivElement> {
  minMessageHeight?: number;
  hasDynamicTableHeight: boolean;
}

const PureDiv = ({
  minMessageHeight,
  hasDynamicTableHeight,
  ...rest
}: OwnProps) => <div {...rest} />;

export const CenteredTableContentContainer = styled(PureDiv)<OwnProps>`
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};

  .${centeredLayoutClassnames.root} {
    ${({ minMessageHeight }) =>
      minMessageHeight &&
      css`
        min-height: ${minMessageHeight}px;
      `}
  }

  ${({ hasDynamicTableHeight }) =>
    hasDynamicTableHeight &&
    css`
      flex: 1;
    `}
`;
