import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { HorizontalDTO } from "../../../../../../../DTOs/horizontal/HorizontalDTO";
import { getHorizontals } from "../../../../../../../../../services/horizontal/horizontal.service";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    HorizontalDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  blockGeneral?: boolean;
}

const cachedOffices = new CachedTranslatedData({
  callback: getHorizontals,
  defaultCacheDuration: 600000, // 10 minutes
});

export const HorizontalAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  blockGeneral = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const getFinalHorizontals = async () => {
    const horizontals = await cachedOffices.run();

    if (blockGeneral) {
      return horizontals.filter((x) => x.name !== "Geral");
    }

    return horizontals;
  };

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "usersCollaborators.errors.data.fetch.failedToFecthHorizontals"
      )}
      fetchOptionsMemo={getFinalHorizontals}
      textfieldProps={{
        label: t("Horizontal"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          x.horizontalTranslation.find((y) => y.idLanguage === i18n.language)
            ?.name ?? x.name,
        isOptionEqualToValueMemo: (option, value) => option.name === value.name,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
