import { Paper, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormAdditiveSection = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a a a a a"
    "b b b c c c"
    "d d e e f f"
    "g g g g g g"
    "h h h h h h";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(8)}
`;

export const StyledFormProjectSection = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a a"
    "b c";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(8)}
`;

export const StyledInternalFormFooter = styled("div")`
  display: flex;
  justify-content: right;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: 8px;
`;

export const StyledInternalFormikForm = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const StyledMainContainerForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: 8px;
  overflow: auto;
`;
