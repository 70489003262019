import { TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDisciplinesDropdown } from "../../../../../../../../../services/disciplines/disciplines.service";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { DisciplineDropdownDTO } from "../../../../../../../DTOs/discipline/DisciplineDropdownDTO";
import { DisciplineFiltersDTO } from "../../../../../../../DTOs/discipline/DisciplineFiltersDTO";

export interface DisciplinesSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    DisciplineDropdownDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: DisciplineFiltersDTO;
  filtersMemo?: DisciplineFiltersDTO;
}

export const DisciplinesAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: DisciplinesSearchAutocompleteFormikProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getDisciplinesFinal = useCallback(async () => {
    const paginatedSkillCategory = await getDisciplinesDropdown({
      filters: { name: "", ...finalFilters },
    });
    return paginatedSkillCategory;
  }, [finalFilters]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "disciplines.errors.data.fetch.failedToFetchDisciplines"
      )}
      fetchOptionsMemo={getDisciplinesFinal}
      textfieldProps={{
        label: t("disciplines.keywords.general.discipline"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language, finalFilters]}
    />
  );
};
