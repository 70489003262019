import * as yup from "yup";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../types/data/form/values.types";
import { TestContextComplete } from "../../../../../../../../../../../shared/common/types/externalLibraries/yup.types";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { checkDoesWeekExist } from "../../../../../../../../../../../shared/common/helpers/data/dates/checkers.helpers";
import { checkIsDisabledCostCenter } from "../../../helpers/data/form/fields/checkers.helpers";
import { AutocompleteOption } from "../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { EmployeeAllocationType } from "../../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { CorporateAllocationsSuperUserContext } from "../../../../../../shared/react/contexts/CorporateAllocationsSuperUserContext";
import { getNowYearMonthBrazil } from "../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { getCostCentersByAllocationTypeForCorporateAllocation } from "../../../../../../../../../../../services/corporateSegments/corporateSegments.service";
import { useMonthlyAllocationClosing } from "../../../../../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";

export const useFormikConfig = () => {
  const { t } = useTranslation();
  const { canEditAllCorporateAllocations } = useContext(
    CorporateAllocationsSuperUserContext
  );

  const { monthlyAllocationClosedList } = useMonthlyAllocationClosing();

  const initialValues = useMemo((): FormValues => {
    return {
      userCollaborators: [],
      allocationType: null,
      costCenter: null,
      corporateSegment: null,
      periodStart: null,
      periodEnd: null,
      allocationYearMonths: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      userCollaborators: yup.array(yup.object()).test({
        message: t(
          "corporateAllocations.errors.data.fields.userCollaborators.atLeastOneMustBeSelected"
        ),
        test: (userCollaborators) =>
          !!userCollaborators && userCollaborators.length >= 1,
      }),
      allocationType: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      costCenter: yup
        .object()
        .nullable()
        .when("allocationType", (allocationType, schema) => {
          const allocationTypeValue =
            allocationType[0] as unknown as AutocompleteOption<EmployeeAllocationType> | null;
          if (checkIsDisabledCostCenter(allocationTypeValue?.id ?? null))
            return schema;
          return schema.required(
            t("general.errors.data.fields.general.required")
          );
        }),
      corporateSegment: yup
        .object()
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          async test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (
              this.parent.allocationType.id === EmployeeAllocationType.Support
            ) {
              const costCentersAllowed =
                await getCostCentersByAllocationTypeForCorporateAllocation(
                  EmployeeAllocationType.Support
                );

              return !(
                costCentersAllowed
                  .map((x) => x.id)
                  .includes(this.parent.costCenter.id) && !value
              );
            }

            return !(
              [
                EmployeeAllocationType.Unallocated,
                EmployeeAllocationType.Training,
              ].includes(this.parent.allocationType.id) && !value
            );
          },
        }),
      periodStart: yup
        .date()
        .withMutation((schema) => {
          const biggestYearMonth = YearMonth.max(
            ...monthlyAllocationClosedList.map((x) => x.yearMonth)
          );
          const minDate = biggestYearMonth
            ? biggestYearMonth.toDate()
            : getNowYearMonthBrazil().toDate();

          if (monthlyAllocationClosedList.length > 0) {
            minDate.setMonth(minDate.getMonth() + 1);
          }

          return canEditAllCorporateAllocations
            ? schema
            : schema.min(
                minDate,
                t(
                  "corporateAllocations.errors.data.fields.yearMonth.monthlyAllocationClosedForSomeSelectedMonth"
                )
              );
        })
        .nullable()
        .when("periodEnd", (periodEnd, schema) => {
          const periodEndValue = periodEnd[0] as unknown as Date | null;
          if (!periodEndValue) return schema;
          return schema.test({
            name: "After end",
            message: t(
              "allocations.errors.data.fields.periodStart.cannotBeAfterPeriodEnd"
            ),
            test: (periodStart: Date | null | undefined) => {
              if (!periodStart) return true;

              const startDateIsAfterEndDate =
                new Date(
                  `${
                    periodStart.getMonth() + 1
                  }-${periodStart.getDate()}-${periodStart.getFullYear()}`
                ) > periodEndValue;

              return !startDateIsAfterEndDate;
            },
          });
        })
        .required(t("general.errors.data.fields.general.required")),
      periodEnd: yup
        .date()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      allocationYearMonths: yup.array(
        yup.object({
          yearMonth: yup
            .date()
            .nullable()
            .test(
              "allocation yearMonth tester",
              t(
                "allocations.errors.data.fields.yearMonth.cannotBeOlderThanProjectBaselineStart"
              ),
              function test(allocationDate) {
                const periodStart = (this as TestContextComplete).from[1].value
                  .periodStart as Date | null;
                const periodEnd = (this as TestContextComplete).from[1].value
                  .periodEnd as Date | null;

                if (!allocationDate) return true;
                const allocationDateYearMonth =
                  YearMonth.createFromDate(allocationDate);

                if (periodStart) {
                  const periodStartYearMonth =
                    YearMonth.createFromDate(periodStart);
                  if (periodStartYearMonth.greaterThan(allocationDateYearMonth))
                    return false;
                }

                if (periodEnd) {
                  const periodEndYearMonth =
                    YearMonth.createFromDate(periodEnd);
                  if (periodEndYearMonth.lessThan(allocationDateYearMonth))
                    return false;
                }

                return true;
              }
            )
            .required(t("general.errors.data.fields.general.required")),
          week1Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week2Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week3Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week4Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week5Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .when("yearMonth", (yearMonth, schema) => {
              const yearMonthValue = new Date(yearMonth[0] as unknown as Date);
              const doesWeekExist = checkDoesWeekExist(
                5,
                YearMonth.createFromDate(yearMonthValue)
              );
              if (!doesWeekExist) return schema;
              return schema.required(
                t("general.errors.data.fields.general.required")
              );
            }),
        })
      ),
    });
  }, [t, canEditAllCorporateAllocations, monthlyAllocationClosedList]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
