import i18next from "i18next";
import saveAs from "file-saver";
import { DreSnapshotReportFilterDto } from "../../../shared/specific/DTOs/ProjectionAdjustment/DreSnapshotReportFilterDto";
import {
  ProjectionDTO,
  schemaProjectionDTO,
} from "../../../shared/specific/DTOs/projection/ProjectionDTO";
import { ProjectionFiltersDTO } from "../../../shared/specific/DTOs/projection/filters/ProjectionFitlersDTO";
import { formatFilenameReports } from "../../../shared/specific/helpers/DTOs/projects/reports/formatters/fileFormatters.helpers";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { riseApi } from "./connection";

export const getProjections = async ({
  filters,
}: {
  filters: ProjectionFiltersDTO;
}): Promise<ProjectionDTO> => {
  const { data: projectionResults } = await riseApi.get("/Projection", {
    params: {
      ...filters,
    },
  });

  return schemaProjectionDTO.validateSync(projectionResults);
};

export const downloadProjectionSpreadsheet = async (
  filters: DreSnapshotReportFilterDto
) => {
  const response = await riseApi.post(
    "/Projection/GenerateProjectionSnapshotReport",
    filters,
    { responseType: "blob" }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("downloadDRESpreadsheet.keywords.downloadFileName"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};
