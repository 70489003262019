import { styled } from "@mui/material";

export const StyledLeftActions = styled("div")`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  padding: 2px 10px;

  button {
    margin: 4px 4px 4px 4px;
  }

  gap: 8px;
`;

export const StyledFooterSumActivities = styled("div")`
  margin: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSumActivities = styled("div")`
  position: relative;
  display: flex;
  white-space: nowrap;
  gap: 6px;
  margin: 8px 0;

  line-height: 1.3;

  & > :first-child {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
  }
`;
