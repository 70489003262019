import { riseApi } from "./connection";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { WaccFilterDTO } from "../../../shared/specific/DTOs/wacc/WaccFilterDTO";
import {
  PaginatedWaccDTO,
  schemaPaginatedWaccDTO,
} from "../../../shared/specific/DTOs/wacc/PaginatedWaccDTO";
import {
  WaccDTO,
  schemaWaccDTO,
} from "../../../shared/specific/DTOs/wacc/WaccDTO";
import { WaccCreateDTO } from "../../../shared/specific/DTOs/wacc/WaccCreateDTO";
import { WaccUpdateDTO } from "../../../shared/specific/DTOs/wacc/WaccUpdateDTO";

export const getWacc = async ({
  filters,
  pagination,
}: {
  filters?: WaccFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedWaccDTO> => {
  const { data: waccs } = await riseApi.get("Wacc", {
    params: {
      ...pagination,
      ...filters,
    },
  });

  return schemaPaginatedWaccDTO.validateSync(waccs);
};

export const getWaccById = async (id: number): Promise<WaccDTO | null> => {
  const { data: wacc } = await riseApi.get(`/Wacc/${id}`);
  return schemaWaccDTO.nullable().validateSync(wacc);
};

export const createWacc = async (wacc: WaccCreateDTO) => {
  await riseApi.post("Wacc", wacc);
};

export const updateWacc = async (wacc: WaccUpdateDTO) => {
  await riseApi.put("Wacc", wacc);
};

export const deleteWacc = async (id: number, version: string) => {
  await riseApi.delete(`Wacc/${id}/${version}`);
};
