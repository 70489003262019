import axios from "axios";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { TranslatedError } from "../../../../../../specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../specific/helpers/data/errors/apiError4xx.helpers";
import { useEffectAfterRenders } from "../../../enhancedReactHooks/useEffectAfterRenders";
import {
  OnErrorFunction,
  OnSuccessFunction,
  ReloadValueArguments,
} from "./index.types";

interface OwnProps<T> {
  initialValue: T;
  getValue: () => T | Promise<T>;
  dependencies?: unknown[];
  onError?: OnErrorFunction<T>;
  onSuccess?: OnSuccessFunction<T>;
  shouldLoadOnMount?: boolean;
  defaultIsLoadingValue?: boolean;
}

export const useLoadLatest = <T,>({
  initialValue,
  dependencies = [],
  getValue,
  onError,
  onSuccess,
  shouldLoadOnMount = true,
  defaultIsLoadingValue,
}: OwnProps<T>) => {
  const [value, setValue] = useState<T>(initialValue);
  const [errorStatusCode, setErrorStatusCode] = useState<number | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [translationError, setTranslationError] =
    useState<TranslatedError | null>(null);
  const [hasError, setHasError] = useState<boolean>(false);
  const [externalError, setExternalError] = useState<ReactNode>(null);
  const [isLoading, setIsLoading] = useState(
    defaultIsLoadingValue ?? shouldLoadOnMount
  );

  const counterGetValueCalled = useRef(0);

  const reloadValue = async ({
    shouldResetValue = false,
  }: ReloadValueArguments = {}) => {
    setExternalError(null);
    setErrorStatusCode(null);
    setError(null);
    setTranslationError(null);
    setHasError(false);
    setIsLoading(true);
    if (shouldResetValue) setValue(initialValue);

    counterGetValueCalled.current++;
    const currentCounterGetValue = counterGetValueCalled.current;

    const getTranslationError = (error: unknown) => {
      const translationError = getErrorIf4xxApiErrorDTO(error);
      return translationError instanceof TranslatedError
        ? translationError
        : null;
    };

    const checkIsLatestCall = () =>
      currentCounterGetValue === counterGetValueCalled.current;

    try {
      const value = await Promise.resolve(getValue());
      if (!checkIsLatestCall()) return;

      setValue(value);
      onSuccess?.({ value });
      setIsLoading(false);
    } catch (error) {
      if (!checkIsLatestCall()) return;

      console.error(error);

      const translationError = getTranslationError(error);
      setTranslationError(translationError);

      setHasError(true);
      setError(error);
      onError?.({
        error,
        translationError,
        setError: setExternalError,
        value,
        setValue,
        initialValue,
      });
      setIsLoading(false);

      if (axios.isAxiosError(error))
        setErrorStatusCode(error.response?.status ?? null);
    }
  };

  const runGetValueRef = useRef(reloadValue);
  runGetValueRef.current = reloadValue;

  const externalReloadValue = useCallback((args: ReloadValueArguments = {}) => {
    runGetValueRef.current(args);
  }, []);

  useEffect(() => {
    if (shouldLoadOnMount) reloadValue();
  }, []);

  useEffectAfterRenders({
    effect: () => {
      reloadValue();
    },
    deps: dependencies,
    rendersBeforeEffect: 1,
  });

  return {
    value,
    externalError,
    isLoading,
    hasError,
    error,
    errorStatusCode,
    reloadValue: externalReloadValue,
    translationError,
  };
};
