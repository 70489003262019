import { Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { YesNoAutocompleteFormik } from "../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { LabelColorTypeColorCode } from "../../../shared/maps/LabelColorTypeColorCode";
import {
  AllocationMapEditingFormValues,
  FormMode,
} from "../../../shared/types/form.types";
import { LabelColorTypeAutocomplete } from "./LabelColorTypeAutocomplete";
import { SubBox } from "./LabelColorTypeAutocomplete/index.styles";
import { LabelTypeTranslationCode } from "../../../shared/maps/LabelTypeTranslationCode";
import { StyledIconButton } from "../index.styles";

interface OwnProps
  extends Omit<
    FormContentProps<AllocationMapEditingFormValues>,
    "internalFormData"
  > {
  mode: FormMode;
}

export const CommonFormEditingCell = ({
  formikProps: { values, isSubmitting, setFieldValue },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  const fields = {
    vacationForcast: (
      <TextFieldFormik
        label={t("allocationsMap.keywords.general.vocationForecast")}
        name="vacationForcast"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setFieldValue("vacationForcast", "")}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    ),
    allocationMapObservation: (
      <TextFieldFormik
        label={t("allocationsMap.keywords.general.observation")}
        name="allocationMapObservation"
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => setFieldValue("allocationMapObservation", "")}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    ),
    customerOfficeAllocation: (
      <YesNoAutocompleteFormik
        name="customerOfficeAllocation"
        textfieldProps={{
          label: t("allocationsMap.keywords.general.allocatedToTheClient"),
        }}
      />
    ),
    labelType: (
      <LabelColorTypeAutocomplete
        name="labelType"
        textfieldProps={{
          label: t("allocationsMap.keywords.general.legend"),
          InputProps: {
            endAdornment: (
              <StyledIconButton
                size="small"
                onClick={() =>
                  setFieldValue("labelType", {
                    id: 6,
                    label: t(LabelTypeTranslationCode[6]),
                  })
                }
              >
                <CloseIcon fontSize="small" />
              </StyledIconButton>
            ),
          },
        }}
        autocompleteProps={{
          disableClearable: true,
          renderOption: (props, option) => (
            <Box component="li" {...props}>
              <SubBox
                labelColorType={
                  option.id !== 6 ? LabelColorTypeColorCode[option.id] : ""
                }
              >
                <>
                  {option.label}
                  <div />
                </>
              </SubBox>
            </Box>
          ),
        }}
      />
    ),
  };

  return fields[mode];
};
