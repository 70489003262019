import { enviroment } from "../../../../../../core/enviroment";

export function getMainEnviromentColor() {
  const redirectUrl = enviroment.apiURL;

  if (redirectUrl.includes("dev")) {
    return "#993399";
  }

  if (redirectUrl.includes("lab")) {
    return "#8C6332";
  }

  if (redirectUrl.includes("homolog")) {
    return "#9c2f77";
  }

  if (redirectUrl.includes("localhost")) {
    return "#23198b";
  }

  return "#3492A8";
}
