import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../shared/types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      jobRole: null,
      costCenter: null,
      horizontal: null,
      contractRole: null,
      office: null,
      startYearMonth: null,
      registryNumber: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      jobRole: yup.object().nullable(),
      costCenter: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      horizontal: yup.object().nullable(),
      contractRole: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      office: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      registryNumber: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
