import { FieldArrayRenderProps } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExternalEnhancedRow } from "../../../../../../../../../common/react/components/table/EnhancedTable";
import { CheckboxPermission } from "../../AccessManagementTable/CheckboxPermission";
import { permissionTypeToTranslationCodeMap } from "../specific/maps/users/permissions/permissionTypeToTranslationCodeMap";
import { AccessManagementListPermissions } from "../types/general.types";
import { usePermissionsFormatted } from "./usePermissionsFormatted";

interface OwnProps {
  listPermissions: AccessManagementListPermissions[] | null;
}

export const useRowsTypeGeneralPermissions = ({
  listPermissions,
}: OwnProps) => {
  const { t } = useTranslation();

  const { permissionTypesFormatted, permissionLevels } =
    usePermissionsFormatted();

  const generateRowsTypeGeneralPermissions = useCallback(
    (array: FieldArrayRenderProps): ExternalEnhancedRow[] =>
      permissionTypesFormatted.general.map(
        (permissionTypeData): ExternalEnhancedRow => ({
          id: permissionTypeData.id,
          cells: [
            t(permissionTypeToTranslationCodeMap[permissionTypeData.id] ?? ""),
            {
              CellRenderer: () => (
                <CheckboxPermission
                  listPermissions={listPermissions}
                  permissionTypeData={permissionTypeData}
                  array={array}
                  permissionLevel={permissionLevels[1].id}
                />
              ),
              align: "center",
              paddingmode: "horizontal",
            },
            {
              CellRenderer: () => (
                <CheckboxPermission
                  listPermissions={listPermissions}
                  permissionTypeData={permissionTypeData}
                  array={array}
                  permissionLevel={permissionLevels[0].id}
                />
              ),
              align: "center",
              paddingmode: "horizontal",
            },
            {
              CellRenderer: () => (
                <CheckboxPermission
                  listPermissions={listPermissions}
                  permissionTypeData={permissionTypeData}
                  array={array}
                  permissionLevel={permissionLevels[2].id}
                />
              ),
              align: "center",
              paddingmode: "horizontal",
            },
            {
              CellRenderer: () => (
                <CheckboxPermission
                  listPermissions={listPermissions}
                  permissionTypeData={permissionTypeData}
                  array={array}
                  permissionLevel={permissionLevels[3].id}
                />
              ),
              align: "center",
              paddingmode: "horizontal",
            },
          ],
        })
      ),
    [permissionTypesFormatted, permissionLevels]
  );

  return useMemo(() => {
    return { generateRowsTypeGeneralPermissions };
  }, [generateRowsTypeGeneralPermissions]);
};
