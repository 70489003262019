import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { StyledProjectInfo } from "../../index.styles";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { CommonInvoicesFormValues } from "../../../../types/form/general.types";
import { InvoicesStatusEnum } from "../../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { DateOnly } from "../../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { getNowBrazilianDate } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  values: CommonInvoicesFormValues;
  status: InvoicesStatusEnum;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const CommonOnlyDateForm = ({
  isSubmitting,
  invoiceData,
  values,
  status,
  setFieldValue,
}: OwnProps) => {
  const { t } = useTranslation();

  const dataForm = useMemo(() => {
    switch (status) {
      case InvoicesStatusEnum.PaidOut:
        return {
          name: "paymentDate",
          label: t("invoice.keywords.fields.paymentDate"),
        };
      case InvoicesStatusEnum.Pdd:
        return {
          name: "pddDate",
          label: t("invoice.keywords.fields.pddDate"),
        };
      case InvoicesStatusEnum.Loss:
        return {
          name: "lossDate",
          label: t("invoice.keywords.fields.lossDate"),
        };
      default:
        return null;
    }
  }, [status, t]);

  useEffect(() => {
    setFieldValue(
      "invoiceNote",
      invoiceData.status === InvoicesStatusEnum.Canceled ||
        invoiceData.status === InvoicesStatusEnum.ProvisionForCancellation
        ? invoiceData.invoiceNote || ""
        : ""
    );

    if (invoiceData.status === InvoicesStatusEnum.ProvisionForCancellation) {
      setFieldValue(
        "plannedCancellationDate",
        values?.plannedCancellationDate
          ? DateOnly.createFromDate(values?.plannedCancellationDate)
          : undefined
      );
    }

    if (invoiceData.status === InvoicesStatusEnum.Canceled) {
      setFieldValue(
        "cancellationDate",
        values?.cancellationDate
          ? DateOnly.createFromDate(values?.cancellationDate)
          : undefined
      );
    }
  }, []);

  return (
    <StyledProjectInfo>
      <DatePickerFormik
        name={dataForm?.name ?? ""}
        datePickerProps={{
          label: dataForm?.label ?? "",
          disabled: isSubmitting,
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          minDate: getNowBrazilianDate().setDate(1),
        }}
      />
    </StyledProjectInfo>
  );
};
