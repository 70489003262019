import { useTranslation } from "react-i18next";
import { Checkbox, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { StyledForm } from "./index.styles";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../shared/types/data/form/values.types";
import { UsersCurrentJobRoleSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersCurrentJobRoleSearchAutocompleteFormik";

interface OwnProps
  extends Omit<FormContentProps<FormValues>, "internalFormData"> {
  jobRole: {
    id: number | null;
    name: string | null;
  } | null;
}

export const AllocationSingleForm = ({
  formikProps: { values, isSubmitting },
  jobRole,
}: OwnProps) => {
  const { t } = useTranslation();
  const [onlyResignatedLessThanOneMonth, setOnlyResignatedLessThanOneMonth] =
    useState(false);

  const UsersCurrentJobRole = useMemo(() => {
    return (
      <UsersCurrentJobRoleSearchAutocompleteFormik
        name="userCurrentJobRole"
        textfieldProps={{
          label: t("general.keywords.fields.colaborator"),
        }}
        autocompleteProps={{
          disabled: isSubmitting,
        }}
        filters={{
          isActive: !onlyResignatedLessThanOneMonth,
          onlyResignatedLessThanOneMonth,
          idsJobRoles: jobRole?.id ? [jobRole?.id] : undefined,
        }}
      />
    );
  }, [onlyResignatedLessThanOneMonth, values, jobRole]);

  return (
    <StyledForm>
      <div>
        <Checkbox
          checked={onlyResignatedLessThanOneMonth ?? false}
          onChange={() =>
            setOnlyResignatedLessThanOneMonth(!onlyResignatedLessThanOneMonth)
          }
        />
        <span>
          {t("allocations.actions.data.onlyResignatedLessThanOneMonth")}
        </span>
      </div>
      {UsersCurrentJobRole}
      <TextField
        label={t("jobRoles.keywords.general.jobRole")}
        value={jobRole?.name || "-"}
        disabled
      />
    </StyledForm>
  );
};
