import { TimesheetMonthWithOthersDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { TimesheetCalendarContextProvider } from "./shared/react/contexts/TimesheetCalendarContext";
import { TimesheetCalendarFormWithinContext } from "./TimesheetCalendarFormWithinContext";

interface OwnProps {
  name: string;
  timesheetMonth: TimesheetMonthWithOthersDTO | null;
  isReadOnly?: boolean;
}

export const TimesheetCalendarForm = ({
  name,
  timesheetMonth,
  isReadOnly,
}: OwnProps) => {
  return (
    <TimesheetCalendarContextProvider
      name={name}
      timesheetMonth={timesheetMonth}
      isReadOnly={isReadOnly}
    >
      <TimesheetCalendarFormWithinContext />
    </TimesheetCalendarContextProvider>
  );
};
