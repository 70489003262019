import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../../shared/common/react/components/table/EnhancedTable";

interface OwnProps {
  rows: ExternalEnhancedRow[];
}

export const InvoiceGroupTable = ({ rows }: OwnProps) => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("invoice.keywords.fields.invoiceMilestone"),
      t("invoice.keywords.fields.billingAmount"),
      t("projects.keywords.general.project"),
      t("PEP"),
      t("general.keywords.fields.currency"),
      t("cfops.keywords.general.cfop"),
      t("projects.keywords.fields.manager"),
      t("projects.keywords.fields.coordinator"),
      t("costCenters.keywords.general.costCenter"),
      {
        value: "",
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        hasDynamicTableHeight: true,
        stickyHeader: true,
        maxTableHeight: 180,
      }}
      rows={rows}
      orderDefaults={{
        defaultOrderBy: "none",
      }}
    />
  );
};
