import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { InputAdornment } from "@mui/material";
import { isValid } from "date-fns";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { StyledForm } from "./index.styles";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { DatePickerFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { generalFieldsConstants } from "../../../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { useMinPlannedBillingDate } from "../../../hooks/data/fields/useMinPlannedBillingDate";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";
import { CommonInvoiceFormValues } from "../../../../types/data/form/values.types";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";

interface OwnProps
  extends Omit<FormContentProps<CommonInvoiceFormValues>, "internalFormData"> {
  project: ProjectWithRelatedInfoDTO | null;
}

export const CommonInvoiceForm = ({
  formikProps: { isSubmitting, values },
  project,
}: OwnProps) => {
  const { t } = useTranslation();

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {project?.subsidiary.currencySymbol ?? ""}
      </InputAdornment>
    );
  }, [project?.subsidiary.currencySymbol]);

  const { minPlannedBillingDate } = useMinPlannedBillingDate();

  const hiddenDelayJustification = useMemo(() => {
    if (!values.plannedBillingDate || !isValid(values.plannedBillingDate))
      return true;

    const getDateToCompare = () => {
      if (
        project?.finalScheduledEndDate &&
        project?.scheduledEndDateWithAdditives
      ) {
        return project?.finalScheduledEndDate >=
          project?.scheduledEndDateWithAdditives
          ? project?.finalScheduledEndDate
          : project?.scheduledEndDateWithAdditives;
      }

      if (project?.finalScheduledEndDate) return project.finalScheduledEndDate;

      if (project?.scheduledEndDateWithAdditives)
        return project.scheduledEndDateWithAdditives;

      return null;
    };

    const dateToCompare = getDateToCompare();

    return dateToCompare
      ? values.plannedBillingDate <= dateToCompare.toDate()
      : true;
  }, [
    project?.scheduledEndDateWithAdditives,
    project?.finalScheduledEndDate,
    values.plannedBillingDate,
  ]);

  return (
    <StyledForm hiddenDelayJustification={hiddenDelayJustification}>
      <TextFieldFormik
        name="milestone"
        label={t("invoice.keywords.fields.invoiceMilestone")}
        inputProps={{ maxLength: maxLengthFields.milestone }}
        required
        disabled={isSubmitting}
      />
      <LocalizedNumberTextFieldFormik
        name="billingAmount"
        min={0}
        max={generalFieldsConstants.maxAmountValue}
        padFractionalZeros
        textFieldProps={{
          label: t("invoice.keywords.fields.billingAmount"),
          required: true,
          disabled: isSubmitting,
          InputProps: {
            startAdornment: currencyStartAdornment,
          },
        }}
      />
      <DatePickerFormik
        name="plannedBillingDate"
        datePickerProps={{
          label: t("invoice.keywords.fields.plannedDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
          minDate: minPlannedBillingDate,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      {!hiddenDelayJustification && (
        <TextFieldFormik
          name="delayJustification"
          label={t("invoice.keywords.fields.delayJustification")}
          inputProps={{ maxLength: maxLengthFields.delayJustification }}
          required
          disabled={isSubmitting}
        />
      )}
    </StyledForm>
  );
};
