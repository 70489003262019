import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SkillCertificationDTO } from "../../../../../../../DTOs/skillCertification/SkillCertificationDTO";
import { getSkillCertificationsDropdown } from "../../../../../../../../../services/skillCertification/skillCertification.service";

export interface DisciplinesSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillCertificationDTO,
    DisableClearable
  >["autocompleteProps"];
}

export const SkillCertificationsAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: DisciplinesSearchAutocompleteFormikProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "collaboratorProfile.errors.data.fetch.failedToFetchCertification"
      )}
      fetchOptions={getSkillCertificationsDropdown}
      textfieldProps={{
        label: t("collaboratorProfile.keywords.fields.certification"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => `${x.name} - ${x.provider}`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
