import {
  AdminPanelSettings as AdminPanelSettingsIcon,
  GppMaybe as GppMaybeIcon,
} from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../store";
import { SimpleIconButton } from "../../../../common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { RestrictionsOperand } from "../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../classes/user/permissions/permissionsOperators/RootPermissionsOperator";

interface OwnProps {
  restrictions: RestrictionsOperand | RestrictionsOperand[];
  defaultMode?: boolean;
  hideIfCannotBecomeSuperUser?: boolean;
  tooltipInSuperUserMode?: string;
  tooltipNotInSuperUserModeOff?: string;
}

export const useSuperUserButton = ({
  restrictions,
  defaultMode = false,
  hideIfCannotBecomeSuperUser = true,
  tooltipInSuperUserMode = "general.actions.authorization.disableSuperUserMode",
  tooltipNotInSuperUserModeOff = "general.actions.authorization.enableSuperUserMode",
}: OwnProps) => {
  const { t } = useTranslation();
  const userData = useTypedSelector((state) => state.user.userData);

  const [inSuperUserMode, setInSuperUserMode] = useState(defaultMode);

  const canBecomeSuperUser = useMemo(() => {
    if (!userData) return false;

    if (Array.isArray(restrictions)) {
      const permissionsList = restrictions.map((restriction) => {
        const permissionsOperator = new RootPermissionsOperator(restriction);

        return permissionsOperator.checkPermissions({
          userPermissions: userData.permissions,
          isAdmin: userData.isAdmin,
        });
      });

      return permissionsList.every((permission) => permission);
    }

    const permissionsOperator = new RootPermissionsOperator(restrictions);

    return permissionsOperator.checkPermissions({
      userPermissions: userData.permissions,
      isAdmin: userData.isAdmin,
    });
  }, [userData?.permissions, userData?.isAdmin]);

  const superUserButton = useMemo(() => {
    const toggleInSuperUserMode = () => {
      if (!canBecomeSuperUser) return;
      setInSuperUserMode((mode) => !mode);
    };

    if (!canBecomeSuperUser && hideIfCannotBecomeSuperUser) return null;

    return (
      <SimpleIconButton
        Icon={inSuperUserMode ? GppMaybeIcon : AdminPanelSettingsIcon}
        color={inSuperUserMode ? "success" : "default"}
        tooltipText={
          inSuperUserMode
            ? t(tooltipInSuperUserMode)
            : t(tooltipNotInSuperUserModeOff)
        }
        onClick={() => toggleInSuperUserMode()}
        disabled={!canBecomeSuperUser}
      />
    );
  }, [t, inSuperUserMode, canBecomeSuperUser, hideIfCannotBecomeSuperUser]);

  useEffect(() => {
    if (!canBecomeSuperUser) setInSuperUserMode(false);
  }, [canBecomeSuperUser]);

  return { superUserButton, inSuperUserMode };
};
