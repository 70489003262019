import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback } from "react";
import {
  TwoSidedModalActions,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "../../shared/react/hooks/form/validation/useFormikConfig";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import {
  mofifyTimesheet,
  getTimesheetMonthSingleEntry,
} from "../../../../../../../services/timesheet/timesheets.service";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonFormValues } from "../../shared/types/data/form/values.types";
import { CommonTimesheetModifyingForm } from "../../shared/react/components/content/CommonTimesheetModifyingForm";
import { TimesheetMonthWithOthersDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { TimesheetMonthDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { EditIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { generateUpdatedDaysTimesheetValues } from "../../shared/helpers/data/calendar/generators.helpers";
import { parseHourStringToMinutes } from "../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { validateDaysTimesheetValuesExceeded } from "../../shared/helpers/data/form/validators.helpers";
import { OtherHoursHelpIconButton } from "../../shared/react/components/buttons/IconButtons/OtherHoursHelpIconButton";
import { EmployeeAllocationType } from "../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

interface OwnProps {
  yearMonth: YearMonth;
  reloadTablePage: () => void;
}

interface InternalFormData {
  yearMonth: YearMonth;
  timesheetMonthWithOthers: TimesheetMonthWithOthersDTO | null;
  timesheetMonth: TimesheetMonthDTO;
}

export const useEditingForm = ({ yearMonth, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      CommonFormValues,
      InternalFormData,
      TimesheetMonthDTO
    >
  >(
    async ({
      contentButtonContentProps: timesheetMonth,
      setInternalFormData,
      setFormValues,
      checkInCurrentModalCount,
      setModalError,
      setIsLoadingModal,
      getOpenCloseModalCount,
    }) => {
      const modalCountOnOpen = getOpenCloseModalCount();

      setIsLoadingModal(true);
      try {
        const timesheetMonthWithOthers = await getTimesheetMonthSingleEntry({
          yearMonth: timesheetMonth.yearMonth,
          allocationType: timesheetMonth.allocationType,
          idCostCenter:
            timesheetMonth.allocationType ===
              EmployeeAllocationType.Allocation ||
            timesheetMonth.allocationType === EmployeeAllocationType.Support
              ? timesheetMonth.costCenter?.id
              : undefined,
          idProject: timesheetMonth.project?.id,
          idCorporateSegment: timesheetMonth.corporateSegment?.id,
        });
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        setFormValues({
          ...formikConfig.initialValues,
          yearMonth,
          days: generateUpdatedDaysTimesheetValues(
            yearMonth,
            timesheetMonthWithOthers.days
          ),
        });
        setInternalFormData({
          yearMonth,
          timesheetMonthWithOthers,
          timesheetMonth,
        });
      } catch (error) {
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage: "customers.errors.data.fetch.failedToFetchCustomer",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [yearMonth, formikConfig.initialValues]
  );

  const { ContentButton: EditingButton, contentModal: editingModal } =
    useFormikModalButton<CommonFormValues, InternalFormData, TimesheetMonthDTO>(
      {
        modal: {
          keepModalMounted: 1000,
          modalTitle: t("timesheets.modal.data.edit.title"),
        },
        button: {
          FormButtonMemo: ({ onClick }) => {
            const { t } = useTranslation();

            return (
              <EditIconButton
                onClick={onClick}
                tooltipText={t("general.actions.general.edit")}
              />
            );
          },
          onClickContentButtonComponent,
        },
        form: {
          formikConfig,
          FormContentMemo: ({ internalFormData }) =>
            internalFormData && (
              <CommonTimesheetModifyingForm
                timesheetMonth={internalFormData.timesheetMonth}
                timesheetMonthWithOthers={
                  internalFormData.timesheetMonthWithOthers
                }
                yearMonth={internalFormData.yearMonth}
                formMode="editing"
              />
            ),
          FormActionsMemo: ({
            submitFormValues,
            closeModal,
            isSubmitting,
            isLoadingModal,
          }) => {
            const { t } = useTranslation();

            return (
              <TwoSidedModalActions>
                <div>{!isLoadingModal && <OtherHoursHelpIconButton />}</div>
                <div>
                  <Button onClick={closeModal} disabled={isSubmitting}>
                    {t("general.actions.general.cancel")}
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    disabled={isLoadingModal}
                    onClick={submitFormValues}
                    variant="contained"
                  >
                    {t("general.actions.general.save")}
                  </LoadingButton>
                </div>
              </TwoSidedModalActions>
            );
          },
          onSubmit: async ({
            formValues,
            internalFormData,
            formikHelpers: { setSubmitting, setFieldError },
            closeModal,
          }) => {
            if (!internalFormData)
              throw new Error("'internalFormData' cannot be null on submit.");

            const allDaysAreEmpty = formValues.days.every(
              (x) => x.time === "00:00"
            );
            if (allDaysAreEmpty) {
              notifyIf4xxApiErrorDTO({
                error: "all days are empty",
                defaultMessage:
                  "timesheets.errors.data.fields.days.allDaysAreEmpty",
              });
              return;
            }

            const hasFoundErrors = validateDaysTimesheetValuesExceeded(
              formValues.days,
              internalFormData.timesheetMonthWithOthers
                ?.otherProjectOrCostCenterDays ?? null,
              setFieldError
            );
            if (hasFoundErrors) return;

            try {
              await mofifyTimesheet({
                yearMonth: formValues.yearMonth,
                allocationType: internalFormData.timesheetMonth.allocationType,
                idCorporateSegment:
                  internalFormData.timesheetMonth.corporateSegment?.id,
                idCostCenter:
                  internalFormData.timesheetMonth.allocationType ===
                    EmployeeAllocationType.Allocation ||
                  internalFormData.timesheetMonth.allocationType ===
                    EmployeeAllocationType.Support
                    ? internalFormData.timesheetMonth.costCenter?.id
                    : undefined,
                idProject: internalFormData.timesheetMonth.project?.id,
                days: formValues.days.map((x) => ({
                  id: x.id,
                  version: x.version,

                  day: x.day,
                  minutes: parseHourStringToMinutes(x.time),
                })),
              });

              notifySuccess(
                i18next.t(
                  "general.success.data.general.operationExecutedSuccessfully"
                )
              );
              reloadTablePage();
              closeModal();
            } catch (error) {
              notifyIf4xxApiErrorDTO({
                error,
                defaultMessage:
                  "timesheets.errors.data.edit.failedToEditTimesheetEntry",
              });
            } finally {
              setSubmitting(false);
            }
          },
        },
      }
    );

  return { EditingButton, editingModal };
};
