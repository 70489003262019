import {
  ContentCopy as ContentCopyIcon,
  Task as TaskIcon,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorVariant } from "../../../../types/externalLibraries/mui.types";
import { SomePartial } from "../../../../types/core/typescript.types";
import {
  SimpleIconButton,
  SimpleIconButtonProps,
} from "./simpleIconButtons/SimpleIconButton";

interface OwnProps
  extends SomePartial<
    Omit<SimpleIconButtonProps, "Icon" | "onClick">,
    "tooltipText"
  > {
  text: string;
  colorAfterCopy?: ColorVariant;
  tooltipTextAfterCopy?: string;
}

export const ClipboardIconButton = ({
  tooltipText = "general.actions.general.copyToClipboard",
  tooltipTextAfterCopy = "general.info.data.actions.copied",
  color = "primary",
  colorAfterCopy = "success",
  text,
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();
  const [isAfterCopy, setIsAfterCopy] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearInternalTimeout = () => {
    if (!timeoutRef.current) return;
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  };

  useEffect(() => {
    return clearInternalTimeout;
  }, []);

  const onClick = () => {
    navigator.clipboard.writeText(text);

    setIsAfterCopy(true);
    clearInternalTimeout();
    timeoutRef.current = setTimeout(() => {
      setIsAfterCopy(false);
      clearInternalTimeout();
    }, 5000);
  };

  return (
    <SimpleIconButton
      Icon={isAfterCopy ? TaskIcon : ContentCopyIcon}
      color={isAfterCopy ? colorAfterCopy : color}
      tooltipText={isAfterCopy ? t(tooltipTextAfterCopy) : t(tooltipText)}
      onClick={onClick}
      {...rest}
    />
  );
};
