import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import lodash from "lodash";
import { Alert, LoadingButton } from "@mui/lab";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { AutocompleteOption } from "../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { AllocationCreationYearMonthsFormValues } from "../../../shared/types/data/form/values.types";
import { getMissingPercentagesToMax } from "../../../../../../../../../../services/corporateAllocations/corporateAllocations.service";
import { StyledAllocationBatchFiller } from "./index.styles";
import { CustomAutocomplete } from "../../../../../../../../../../shared/common/react/components/form/general/autocomplete/general/CustomAutocomplete";
import { UserCollaboratorDTO } from "../../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { checkDoesWeekExist } from "../../../../../../../../../../shared/common/helpers/data/dates/checkers.helpers";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";
import { HelpIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/HelpIconButton";
import { LoadingBackdrop } from "../../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface OwnProps {
  periodYearMonths: YearMonth[] | null;
}

export const AllocationBatchFiller = ({ periodYearMonths }: OwnProps) => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();
  const [
    { value: allocationYearMonths },
    ,
    { setValue: setAllocationYearMonths },
  ] = useField<AllocationCreationYearMonthsFormValues[]>(
    "allocationYearMonths"
  );
  const [{ value: userCollaborators }] =
    useField<UserCollaboratorDTO[]>("userCollaborators");

  const [isCalculatingPercentages, setIsCalculatingPercentages] =
    useState(false);
  const [option, setOption] = useState<
    AutocompleteOption<string> | YearMonth | null
  >(null);
  const [
    userNegativePercentagesYearMonths,
    setUserNegativePercentagesYearMonths,
  ] = useState<YearMonth[] | null>(null);

  const options = useMemo(() => {
    if (!periodYearMonths) return [];
    return [
      {
        id: "all",
        label: t("allocations.modal.data.create.allAllocationsText"),
      },
      ...periodYearMonths,
    ];
  }, [periodYearMonths, t]);

  useEffect(() => {
    setUserNegativePercentagesYearMonths(null);
  }, [userCollaborators, periodYearMonths]);

  const canCalculatePercentages = userCollaborators.length === 1;

  const formatWeekPercentage = (weekPercentage: number | null) => {
    if (weekPercentage === null || weekPercentage < 0) return "";
    return formatNumber(weekPercentage, {
      fractionDigits: 2,
    });
  };

  const onSubmit = async () => {
    if (!option) return;
    if (userCollaborators.length !== 1) return;

    const earliestYearMonth = lodash.minBy(periodYearMonths, (yearMonth) =>
      yearMonth.getTimestamp()
    );
    const latestYearMonth = lodash.maxBy(periodYearMonths, (yearMonth) =>
      yearMonth.getTimestamp()
    );

    if (!earliestYearMonth || !latestYearMonth) return;

    setIsCalculatingPercentages(true);
    try {
      const remainingPercentages = await getMissingPercentagesToMax({
        idUserCollaborator: userCollaborators[0].id,
        earliestYearMonth,
        latestYearMonth,
      });

      const remainingPercentagesDictionary = lodash.keyBy(
        remainingPercentages,
        (x) => x.yearMonth.toString()
      );

      const negativePercentageYearMonths = remainingPercentages
        .filter(
          (x) =>
            (!!x.week1Percentage && x.week1Percentage < 0) ||
            (!!x.week2Percentage && x.week2Percentage < 0) ||
            (!!x.week3Percentage && x.week3Percentage < 0) ||
            (!!x.week4Percentage && x.week4Percentage < 0) ||
            (!!x.week5Percentage && x.week5Percentage < 0)
        )
        .map((x) => x.yearMonth);

      setUserNegativePercentagesYearMonths(
        negativePercentageYearMonths.length > 0
          ? negativePercentageYearMonths
          : null
      );

      const newAllocationYearMonths = allocationYearMonths.map(
        (x): AllocationCreationYearMonthsFormValues => {
          const remainingPercentageYearMonth =
            remainingPercentagesDictionary[x.yearMonth.toString()];

          if (!remainingPercentageYearMonth)
            return {
              yearMonth: x.yearMonth,
              week1Percentage: "100",
              week2Percentage: "100",
              week3Percentage: "100",
              week4Percentage: "100",
              week5Percentage: checkDoesWeekExist(5, x.yearMonth) ? "100" : "",
            };

          return {
            yearMonth: x.yearMonth,
            week1Percentage: formatWeekPercentage(
              remainingPercentageYearMonth.week1Percentage
            ),
            week2Percentage: formatWeekPercentage(
              remainingPercentageYearMonth.week2Percentage
            ),
            week3Percentage: formatWeekPercentage(
              remainingPercentageYearMonth.week3Percentage
            ),
            week4Percentage: formatWeekPercentage(
              remainingPercentageYearMonth.week4Percentage
            ),
            week5Percentage: formatWeekPercentage(
              remainingPercentageYearMonth.week5Percentage
            ),
          };
        }
      );

      setAllocationYearMonths(newAllocationYearMonths);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: t(
          "corporateAllocations.errors.data.fetch.failedToGetMissingPercentages"
        ),
      });
    } finally {
      setIsCalculatingPercentages(false);
    }
  };

  return (
    <>
      <StyledAllocationBatchFiller>
        <Trans
          i18nKey="corporateAllocations.modal.data.create.calculateMonthsPercentageRemaining"
          components={{
            autocompleteField: (
              <CustomAutocomplete
                autocompleteProps={{
                  value: option,
                  setValue: (value) => setOption(value),
                  options,
                  getOptionLabelMemo: (option) =>
                    option instanceof YearMonth
                      ? option.toPrettyString()
                      : option.label,
                  isOptionEqualToValueMemo: (option, value) => {
                    if (
                      option instanceof YearMonth &&
                      value instanceof YearMonth
                    )
                      return option.equals(value);

                    if (
                      !(option instanceof YearMonth) &&
                      !(value instanceof YearMonth)
                    )
                      return option.id === value.id;

                    return false;
                  },
                  disabled: isSubmitting || !canCalculatePercentages,
                }}
              />
            ),
          }}
        />
        <LoadingButton
          color="primary"
          onClick={onSubmit}
          disabled={isSubmitting || !canCalculatePercentages || !option}
        >
          {t("general.actions.general.calculate")}
        </LoadingButton>
        {!canCalculatePercentages && (
          <TipPopoverButton
            button={<HelpIconButton size="small" />}
            content={
              <div>
                {t(
                  "corporateAllocations.modal.data.create.canOnlyCalculateWhenOneCollaborator"
                )}
              </div>
            }
          />
        )}
      </StyledAllocationBatchFiller>
      {userNegativePercentagesYearMonths && (
        <Alert severity="error">
          {t(
            "corporateAllocations.errors.data.write.atLeastOnePercentageIsGreaterThanMonthlyLimit",
            {
              yearMonthsList: userNegativePercentagesYearMonths
                .map((x) => x.toPrettyString())
                .join(", "),
            }
          )}
        </Alert>
      )}
      <LoadingBackdrop open={isCalculatingPercentages} />
    </>
  );
};
