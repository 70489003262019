import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAllocationMap } from "../../../../../../../services/allocationMap/allocationMap.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { AllocationCollaboratorDTO } from "../../../../../../../shared/specific/DTOs/allocationMap/AllocationCollaboratorDTO";
import { AllocationWeekDTO } from "../../../../../../../shared/specific/DTOs/allocationMap/AllocationWeekDTO";
import { AllocationMapFilterDTO } from "../../../../../../../shared/specific/DTOs/allocationMap/filters/AllocationMapFilterDTO";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { AllocationMapContext } from "../shared/context";
import { useEditingCellForm } from "../useEditingCellForm";
import { CellContent } from "./CellContent";
import { PercentageAllocationContent } from "./PercentageAllocationContent";
import { StyleDobleHeader } from "./index.styles";
import { AllocationPercentageEnum } from "../../../../../../../shared/specific/DTOs/allocationMap/filters/AllocationPercentageEnum";

interface OwnProps {
  reloadTablePage: () => void;
  modalFilters: AllocationMapFilterDTO;
}

export const useTableData = ({ reloadTablePage, modalFilters }: OwnProps) => {
  const { t } = useTranslation();

  const { setData, data } = useContext(AllocationMapContext);

  const [currentFilters, setCurrentFilters] =
    useState<AllocationMapFilterDTO | null>(null);

  const [allocationsWeek, setAllocationsWeek] = useState<AllocationWeekDTO[]>(
    []
  );

  const { EditingButtonContainer, editingModal } = useEditingCellForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.name"),
        minWidth: 520,
        headColumn: true,
        align: "left",
        canSort: false,
        HeadRenderer: () => (
          <StyleDobleHeader>
            <>
              <p>{t("general.keywords.fields.name")}</p>
              {t("timesheets.keywords.fields.projectCostCenter")}
            </>
          </StyleDobleHeader>
        ),
      },
      {
        value: t("divisions.keywords.general.un"),
        minWidth: 200,
        canSort: false,
      },
      {
        value: t("contractRole.keywords.general.contractRole"),
        minWidth: 200,
        canSort: false,
      },
      {
        value: t("disciplines.keywords.general.discipline"),
        minWidth: 200,
        canSort: false,
      },
      {
        value: t("projects.keywords.fields.manager"),
        minWidth: 200,
        canSort: false,
      },
    ];

    allocationsWeek.forEach((allocationWeek) =>
      headCells.push({
        value: `${allocationWeek.weekDate.getDate()}/${
          allocationWeek.weekDate.getMonth() + 1
        }`,
        minWidth: 80,
        canSort: false,
        align: "center",
      })
    );

    headCells.push(
      {
        value: t("allocationsMap.keywords.general.vocationForecast"),
        minWidth: 200,
        canSort: false,
        align: "center",
      },
      {
        value: t("allocationsMap.keywords.general.observation"),
        minWidth: 200,
        canSort: false,
        align: "center",
      },
      {
        value: t("allocationsMap.keywords.general.legend"),
        minWidth: 150,
        canSort: false,
        align: "center",
      }
    );

    return headCells;
  }, [t, allocationsWeek]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        if (
          !modalFilters.startDate ||
          !modalFilters.endDate ||
          (!modalFilters.idUserCollaborator &&
            (modalFilters.onlyAllocatedCollaborators?.length === 0 ||
              !modalFilters.onlyAllocatedCollaborators) &&
            !modalFilters.idProject &&
            !modalFilters.idDivision &&
            !modalFilters.idSegment &&
            !modalFilters.idManager &&
            (modalFilters.idsDiscipline?.length === 0 ||
              !modalFilters.idsDiscipline) &&
            (modalFilters.idsContractRoles?.length === 0 ||
              !modalFilters.idsContractRoles))
        ) {
          setInfo(t("allocationsMap.info.data.general.fillTheFilter"));
          return null;
        }

        try {
          const getData = async (): Promise<{
            allocationMapData: AllocationCollaboratorDTO[];
            page: number;
            totalCount: number;
          } | null> => {
            if (
              data.length > 0 &&
              currentFilters?.idDivision === modalFilters.idDivision &&
              currentFilters?.idSegment === modalFilters.idSegment &&
              currentFilters?.idUserCollaborator ===
                modalFilters.idUserCollaborator &&
              currentFilters?.startDate === modalFilters?.startDate &&
              currentFilters?.endDate === modalFilters.endDate &&
              currentFilters?.onlyAllocatedCollaborators ===
                modalFilters.onlyAllocatedCollaborators
            ) {
              const dataFiltered = modalFilters?.name
                ? data.filter((allocationMap) =>
                    allocationMap.name
                      .toLowerCase()
                      .includes(modalFilters?.name?.toLowerCase() ?? "")
                  )
                : data;
              return {
                allocationMapData: dataFiltered,
                page: newPage + 1,
                totalCount: dataFiltered.length,
              };
            }
            const allocationMapData = await getAllocationMap({
              filters: modalFilters,
            });

            if (allocationMapData.length === 0) {
              setInfo(t("allocationsMap.errors.data.notFound"));
              return null;
            }

            const dataFiltered = modalFilters?.name
              ? allocationMapData.filter((allocationMap) =>
                  allocationMap.name
                    .toLowerCase()
                    .includes(modalFilters?.name?.toLowerCase() ?? "")
                )
              : allocationMapData;

            setData(allocationMapData);
            setAllocationsWeek(allocationMapData[0].allocationWeeks);

            return {
              allocationMapData: dataFiltered,
              page: newPage + 1,
              totalCount: allocationMapData.length,
            };
          };

          const allocationsData = await getData();

          if (!allocationsData) {
            setInfo(t("allocationsMap.errors.data.notFound"));
            return null;
          }

          const { allocationMapData, page, totalCount } = allocationsData;

          const start = newPage * rowsPerPage;
          const end = start + rowsPerPage;

          const paginatedData = allocationMapData.slice(start, end);

          const rows: ExternalEnhancedRow[] = paginatedData.map(
            (collaborator, i) => {
              const row: ExternalEnhancedRow = {
                id: i,
                cells: [
                  {
                    paddingmode: "none",
                    CellRenderer: () => (
                      <CellContent
                        key={i}
                        EditingButtonContainer={EditingButtonContainer}
                        labelColorType={collaborator.labelColorType}
                        labelType={collaborator.labelType}
                        customerOfficeAllocation={
                          collaborator.customerOfficeAllocation ?? false
                        }
                        vacationForcast={collaborator.vacationForecast ?? ""}
                        allocationMapObservation={
                          collaborator.allocationMapObservation ?? ""
                        }
                        idUserCollaborator={collaborator.idUserCollaborator}
                        collaboratorName={collaborator.name}
                        projectOrCostCenterName={
                          collaborator.projectOrCostCenterName || "-"
                        }
                        formMode="labelType"
                      />
                    ),
                  },
                  collaborator.segmentName
                    ? `${collaborator.divisionName} - ${collaborator.segmentName}`
                    : collaborator.divisionName,
                  collaborator.contractRoleName || "-",
                  collaborator.disciplineName || "-",
                  collaborator.projectManagerName || "-",
                ],
              };

              collaborator.allocationWeeks.forEach((allocationWeek, index) => {
                if (allocationWeek.weekDate) {
                  row.cells.push({
                    paddingmode: "none",
                    CellRenderer: () => (
                      <PercentageAllocationContent
                        idUserCollaborator={collaborator.idUserCollaborator}
                        allocationWeek={allocationWeek}
                        onlyAllocatedCollaborators={
                          modalFilters?.onlyAllocatedCollaborators[index] ??
                          AllocationPercentageEnum.Allocated
                        }
                      />
                    ),
                  });
                } else {
                  row.cells.push("-");
                }
              });

              row.cells.push(
                {
                  paddingmode: "none",
                  justifyContent: "center",
                  CellRenderer: () => (
                    <CellContent
                      key={i}
                      EditingButtonContainer={EditingButtonContainer}
                      labelColorType={collaborator.labelColorType}
                      labelType={collaborator.labelType}
                      customerOfficeAllocation={
                        collaborator.customerOfficeAllocation ?? false
                      }
                      vacationForcast={collaborator.vacationForecast ?? ""}
                      allocationMapObservation={
                        collaborator.allocationMapObservation ?? ""
                      }
                      idUserCollaborator={collaborator.idUserCollaborator}
                      formMode="vacationForcast"
                    />
                  ),
                },
                {
                  paddingmode: "none",
                  justifyContent: "center",
                  CellRenderer: () => (
                    <CellContent
                      key={i}
                      EditingButtonContainer={EditingButtonContainer}
                      labelColorType={collaborator.labelColorType}
                      labelType={collaborator.labelType}
                      customerOfficeAllocation={
                        collaborator.customerOfficeAllocation ?? false
                      }
                      vacationForcast={collaborator.vacationForecast ?? ""}
                      allocationMapObservation={
                        collaborator.allocationMapObservation ?? ""
                      }
                      idUserCollaborator={collaborator.idUserCollaborator}
                      formMode="allocationMapObservation"
                    />
                  ),
                },
                {
                  paddingmode: "none",
                  justifyContent: "center",
                  CellRenderer: () => (
                    <CellContent
                      key={i}
                      EditingButtonContainer={EditingButtonContainer}
                      labelColorType={collaborator.labelColorType}
                      labelType={collaborator.labelType}
                      customerOfficeAllocation={
                        collaborator.customerOfficeAllocation ?? false
                      }
                      vacationForcast={collaborator.vacationForecast ?? ""}
                      allocationMapObservation={
                        collaborator.allocationMapObservation ?? ""
                      }
                      idUserCollaborator={collaborator.idUserCollaborator}
                      formMode="labelType"
                    />
                  ),
                }
              );

              return row;
            }
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        } finally {
          setCurrentFilters(modalFilters);
        }
      },
      [t, modalFilters, data]
    );

  return {
    headCells,
    onTablePageChange,
    editingModal,
  };
};
