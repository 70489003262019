import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Badge as BadgeIcon, Groups as GroupsIcon } from "@mui/icons-material";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  PageCardButton,
  PageCardButtonsList,
} from "../../../../../shared/common/react/components/navigation/PageCardButton";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import {
  allocationsMapReadRestrictions,
  collaboratorReadRestrictions,
  contractRoleReadRestrictions,
  workloadsCreateRestrictions,
} from "../../../../shared/constants/routing/restrictions/peopleAndManagementRestrictions.constants";

export const PeopleAndManagement = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("peopleAndManagement.pages.main.title"));
  }, [t]);

  return (
    <PageCardButtonsList>
      <Protected restrictions={allocationsMapReadRestrictions}>
        <PageCardButton
          title="allocationsMap.pages.main.title"
          Icon={DirectionsWalkIcon}
          link="/allocations-map"
        />
      </Protected>
      <Protected restrictions={collaboratorReadRestrictions}>
        <PageCardButton
          title="usersCollaborators.keywords.general.collaborators"
          Icon={GroupsIcon}
          link="/collaborators"
        />
      </Protected>
      <Protected restrictions={contractRoleReadRestrictions}>
        <PageCardButton
          title="contractRole.keywords.general.contractRoles"
          Icon={BadgeIcon}
          link="/contract-roles"
        />
      </Protected>
      <Protected restrictions={workloadsCreateRestrictions}>
        <PageCardButton
          title="workloads.keywords.general.workload"
          Icon={WorkHistoryIcon}
          link="/workloads"
        />
      </Protected>
    </PageCardButtonsList>
  );
};
