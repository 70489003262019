import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectPlanningFinishingValidationDTO } from "../../../../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningFinishingValidationDTO";
import { ValidationItem } from "../../../../types/data/validation/general.types";
import { usePlanningSavingValidationData } from "./usePlanningSavingValidationData";

interface OwnProps {
  projectFinishingValidation: ProjectPlanningFinishingValidationDTO;
}

export const usePlanningFinishingValidationData = ({
  projectFinishingValidation,
}: OwnProps) => {
  const { t } = useTranslation();

  const projectSavingValidationData = usePlanningSavingValidationData({
    projectValidation:
      projectFinishingValidation.projectPlanningSavingValidation,
  });

  return useMemo((): ValidationItem[] => {
    return [
      ...projectSavingValidationData,
      {
        name: t("projects.keywords.projectValidation.allActivitiesClosed"),
        isValid: projectFinishingValidation.allActivitiesClosed,
      },
      {
        name: t(
          "projects.keywords.projectValidation.allInvoicesIssueDatesNotNullAndBeforeNow"
        ),
        isValid:
          projectFinishingValidation.allInvoicesIssueDatesNotNullAndBeforeNow,
      },
      {
        name: t(
          "projects.keywords.projectValidation.finalScheduledEndBeforeNow"
        ),
        isValid: projectFinishingValidation.finalScheduledEndBeforeNow,
      },
    ];
  }, [t, projectSavingValidationData, projectFinishingValidation]);
};
