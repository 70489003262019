import * as yup from "yup";
import {
  HorizontalDTO,
  schemaHorizontalDTO,
} from "../horizontal/HorizontalDTO";

export interface DisciplineDTO {
  id: number;
  horizontal: HorizontalDTO | null;
  name: string;
  deactivatedDate: Date | null;
  version: string;
}

export const schemaDisciplineDTO = yup.object({
  id: yup.number().integer().defined(),
  horizontal: schemaHorizontalDTO.nullable().defined(),
  name: yup.string().defined(),
  deactivatedDate: yup.date().nullable().default(null),
  version: yup.string().defined(),
});
