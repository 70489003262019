import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledActivityDescriptionSection = StyledSection;
export const StyledScheduledDateSection = StyledSection;

export const StyledActivityDescriptionFirstRow = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "a b c";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(3)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c c";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "a"
      "b"
      "c";
  }
`;

export const StyledActivityDescriptionSecondRow = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "a"
      "b";
  }
`;

export const StyledScheduledDateAlwaysVisiblePart = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c c";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(3)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "a"
      "b"
      "c";
  }
`;

export const StyledValueSection = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "a b c";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(3)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c c";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      "a"
      "b"
      "c";
  }
`;

export const StyledRepeatValuesArea = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
  white-space: nowrap;
`;
