import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLoadLedgerAccount } from "./shared/hooks/useLoadLedgerAccount";
import { useWorksheetHelp } from "./shared/hooks/useWorksheetHelp";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";

export const LedgerAccountPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("ledgerAccount.pages.main.title"));
  }, [t]);

  const { LoadLedgerAccount, uploadLedgerAccountModal } =
    useLoadLedgerAccount();

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();
  return (
    <div>
      <LoadLedgerAccount />
      <UseWorksheetHelpModalButtonContainer />
      {useWorksheetHelpModal}
      {uploadLedgerAccountModal}
    </div>
  );
};
