import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const portfolio = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const portfolioLableCpt: CptProps = {
    text: "Portfolio Radix",
    options: {
      align: pres.AlignH.left,
      y: "34%",
      x: "1%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const portfolioNameCpt: CptProps = {
    text:
      projectData.projectPipedriveServiceLines.length > 0
        ? projectData.projectPipedriveServiceLines
            .map((x) => x.name)
            .join(" / ")
        : "-",
    options: {
      align: pres.AlignH.left,
      y: "35%",
      x: "1%",
      w: "48%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: getFontSizeByString(
        projectData.projectPipedriveServiceLines.length > 0
          ? projectData.projectPipedriveServiceLines
              .map((x) => x.name)
              .join(" / ")
          : "-"
      ),
    },
  };

  slide.addText(portfolioLableCpt.text, portfolioLableCpt.options);
  slide.addText(portfolioNameCpt.text, portfolioNameCpt.options);
};
