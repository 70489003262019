import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode, FormValues } from "../../../types/form.types";
import { StyledFormContainer } from "./indes.styles";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  mode: FormMode;
};

export const CommonFeeForm = ({
  formikProps: { isSubmitting, values },
  mode,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const datePattern = i18n.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy";

  return (
    <StyledFormContainer>
      <TextFieldFormik
        name="description"
        label={t("general.keywords.fields.description")}
        required
        disabled={isSubmitting}
      />

      <CostCentersSearchAutocompleteFormik
        name="costCenter"
        textfieldProps={{
          required: true,
        }}
      />

      <TextField
        label={t("subsidiaries.keywords.general.subsidiary")}
        disabled
        value={values.costCenter?.subsidiary.name ?? ""}
      />

      <TextFieldFormik
        name="accountingAccountCredit"
        label={t("fee.keywords.fields.accountingAccountCredit")}
        required
        disabled={isSubmitting}
      />

      <TextFieldFormik
        name="accountingAccountDebit"
        label={t("fee.keywords.fields.accountingAccountDebit")}
        required
        disabled={isSubmitting}
      />

      {mode === "editing" && (
        <DatePickerFormik
          name="deactivatedDate"
          datePickerProps={{
            views: ["year", "month", "day"],
            label: t("jobRoles.keywords.general.deactivatedDate"),
            format: datePattern,
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            maxDate: new Date(),
            disabled: isSubmitting,
          }}
        />
      )}
    </StyledFormContainer>
  );
};
