import { styled } from "@mui/material/styles";

interface StyledFixedColumnProps {
  isread: string;
}

export const StyledFixedColumn = styled("div")<StyledFixedColumnProps>`
  width: 100%;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: ${({ isread, theme }) =>
    isread === "true" ? "#f1f1f1" : ""};
`;
