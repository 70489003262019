import * as yup from "yup";
import {
  ExpenseType,
  schemaExpenseType,
} from "../../enums/expenses/ExpenseType";
import { ExpenseOfTypeDTO, schemaExpenseOfTypeDTO } from "./ExpenseOfTypeDTO";

export interface ExpensesOfTypeDTO {
  idCostCenterPep: number;
  expenseType: ExpenseType;
  expenses: ExpenseOfTypeDTO[];
  expensesTotalAmount: number;
}

export const schemaExpensesOfTypeDTO = yup.object({
  idCostCenterPep: yup.number().integer().defined(),
  expenseType: schemaExpenseType.defined(),
  expenses: yup.array(schemaExpenseOfTypeDTO).defined(),
  expensesTotalAmount: yup.number().defined(),
});
