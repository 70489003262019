import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatMinutesToHourMinute } from "../../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { TimesheetMonthDayDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDayDTO";
import { DaysTimesheetValues } from "../../../types/data/form/values.types";

export const generateDaysForMonth = (yearMonth: YearMonth) => {
  const days: DaysTimesheetValues[] = [];

  const daysInMonth = yearMonth.toDateEndOfMonth().getDate();
  for (let day = 1; day <= daysInMonth; day++)
    days.push({
      day,
      time: "00:00",
    });

  return days;
};

export const generateUpdatedDaysTimesheetValues = (
  yearMonth: YearMonth,
  existingDaysMonth: TimesheetMonthDayDTO[]
): DaysTimesheetValues[] => {
  const days = generateDaysForMonth(yearMonth);

  return days.map((dayEntry) => {
    const existingDayEntry = existingDaysMonth.find(
      (x) => x.entryDate.toDate().getDate() === dayEntry.day
    );

    if (!existingDayEntry) return dayEntry;
    return {
      id: existingDayEntry.id,
      version: existingDayEntry.version,
      day: existingDayEntry.entryDate.toDate().getDate(),
      time: formatMinutesToHourMinute(existingDayEntry.minutes),
    };
  });
};
