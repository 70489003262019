import { CostDistributionCreateDTO } from "../../../shared/specific/DTOs/CostDistribution/CostDistributionCreateDTO";
import {
  CostDistributionDTO,
  schemaCostDistributionDTO,
} from "../../../shared/specific/DTOs/CostDistribution/CostDistributionDTO";
import { CostDistributionDeleteDTO } from "../../../shared/specific/DTOs/CostDistribution/CostDistributionDeleteDTO";
import { CostDistributionFilterDTO } from "../../../shared/specific/DTOs/CostDistribution/CostDistributionFilterDTO";
import { CostDistributionListFilterDTO } from "../../../shared/specific/DTOs/CostDistribution/CostDistributionListFilterDTO";
import { CostDistributionUpdateDTO } from "../../../shared/specific/DTOs/CostDistribution/CostDistributionUpdateDTO";
import {
  PaginatedCostDistributionDTO,
  schemaPaginatedCostDistributionDTO,
} from "../../../shared/specific/DTOs/CostDistribution/PaginatedCostDistributionDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getCostDistribution = async ({
  filters,
  pagination,
}: {
  filters?: CostDistributionListFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedCostDistributionDTO> => {
  const { data: costDistribution } = await riseApi.get("/CostDistribution", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedCostDistributionDTO.validateSync(costDistribution);
};

export const getCostDistributionOnly = async (
  filter: CostDistributionFilterDTO
): Promise<CostDistributionDTO | null> => {
  const { data: customer } = await riseApi.get(`/CostDistribution/Only`, {
    params: { ...filter },
  });

  return schemaCostDistributionDTO.nullable().validateSync(customer);
};

export const deleteCostDistribution = async (
  costDistribution: CostDistributionDeleteDTO
) => {
  await riseApi.delete(`/CostDistribution`, {
    params: { ...costDistribution },
  });
};

export const createCostDistribution = async (
  costDistribution: CostDistributionCreateDTO[]
) => {
  await riseApi.post("/CostDistribution/", costDistribution);
};

export const updateCostDistribution = async (
  costDistribution: CostDistributionUpdateDTO[]
) => {
  await riseApi.put("/CostDistribution/", costDistribution);
};
