import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getRevenueInvoices } from "../../../../../../../services/revenueInvoices/revenueInvoices.service";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { CenteredContent } from "../../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { CalcStartCaroussel } from "../../shared/react/utils/CalcStartCaroussel";
import { RevenueInvoicesTabWithinContext } from "./RevenueInvoicesTabWithinContext";
import { RevenueInvoicesCarouselContextProvider } from "./shared/react/contexts/RevenueInvoicesCarouselContext";

export const RevenueInvoicesTab = () => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const [positionStart, setPositionStart] = useState<number>(0);

  const {
    value: revenueInvoices,
    hasError,
    isLoading,
    translationError,
  } = useLoadLatest({
    initialValue: null,
    getValue: () => getRevenueInvoices(project.id),
    onError: ({ setValue }) => setValue(null),
  });

  const periodStart = useMemo(() => {
    return project.scheduledStartDate
      ? YearMonth.createFromDate(project.scheduledStartDate.toDate())
      : null;
  }, [project.scheduledStartDate]);

  const periodEnd = useMemo(() => {
    return project.finalScheduledEndDate
      ? YearMonth.createFromDate(project.finalScheduledEndDate.toDate())
      : null;
  }, [project.scheduledEndDate]);

  useEffect(() => {
    if (periodStart && periodEnd) {
      const position = CalcStartCaroussel(periodStart, periodEnd);
      return setPositionStart(position);
    }
  }, [periodStart, periodEnd]);

  if (isLoading) return <CenteredLoading />;
  if (hasError)
    return (
      <CenteredContent>
        {translationError
          ? t(translationError.message, translationError.interpolation)
          : t("planningSummary.errors.data.fetch.failedToFetchSummary")}
      </CenteredContent>
    );
  if (!revenueInvoices)
    return (
      <CenteredContent stretchMode="horizontal">
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );

  return (
    <RevenueInvoicesCarouselContextProvider
      periodStart={periodStart}
      periodEnd={periodEnd}
      firstYearMonth={positionStart}
    >
      <RevenueInvoicesTabWithinContext revenueInvoices={revenueInvoices} />
    </RevenueInvoicesCarouselContextProvider>
  );
};
