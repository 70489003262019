import * as yup from "yup";

export interface BasePaginationResponse<T> {
  tableData: T;
  numberOfPages: number;
  pageSize: number;
  page: number;
  totalCount: number;
}

export const getSchemaBasePaginationResponse = <
  SchemaType extends yup.AnySchema,
>(
  tableDataSchema: SchemaType
) => {
  return yup.object({
    tableData: tableDataSchema,
    numberOfPages: yup.number().integer().defined(),
    pageSize: yup.number().integer().defined(),
    page: yup.number().integer().defined(),
    totalCount: yup.number().integer().defined(),
  });
};
