import { createTheme } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";
import { getMainEnviromentColor } from "../../shared/specific/helpers/data/utils/enviroment/getMainEnviromentColor";

export const lightTheme = createTheme({
  typography: {
    fontSize: 14,
  },
  palette: {
    primary: {
      main: getMainEnviromentColor(),
    },
    text: {
      primary: "#111",
      secondary: "#666",
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem",
          padding: "6px 12px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 40,
          "@media (min-width: 600px)": {
            minHeight: 40,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 24,
        },
        fontSizeSmall: {
          fontSize: 20,
        },
        fontSizeLarge: {
          fontSize: 32,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "0.95rem",
        },
        sizeMedium: {
          fontSize: "1rem",
        },
        sizeLarge: {
          fontSize: "1.05rem",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 42,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 42,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
        displayedRows: {
          fontSize: "1rem",
        },
        selectLabel: {
          fontSize: "1rem",
        },
      },
    },
  },
});
