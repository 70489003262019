import { ProjectionFormValues } from "../../types/form.types";
import {
  getAdjustmentInitialValuesSumByMonth,
  getDreSumByMonth,
} from "./auxFunctions";

function getTotalSalesCost(formValues: ProjectionFormValues) {
  const totalSalesCostPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const riseMonthExpense = getDreSumByMonth(m, formValues.riseDreExpense);
    const pipedriveMonthExpense = getDreSumByMonth(
      m,
      formValues.pipedriveDreExpense
    );
    const adjustmentMonthExpense = formValues.salesCost
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.salesCost)
      : 0;
    totalSalesCostPerMonth.push(
      riseMonthExpense + pipedriveMonthExpense + adjustmentMonthExpense
    );
  });

  return totalSalesCostPerMonth;
}

function getTotalSgAndAAmount(formValues: ProjectionFormValues) {
  const totalSgAndAPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthExpense = formValues.sgAndA
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.sgAndA)
      : 0;
    totalSgAndAPerMonth.push(adjustmentMonthExpense);
  });

  return totalSgAndAPerMonth;
}

function getTotalPddAmount(formValues: ProjectionFormValues) {
  const totalPddPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthExpense = formValues.pdd
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.pdd)
      : 0;
    totalPddPerMonth.push(adjustmentMonthExpense);
  });

  return totalPddPerMonth;
}

function getTotalDolAmount(formValues: ProjectionFormValues) {
  const totalDolPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthExpense = formValues.dol
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.dol)
      : 0;
    totalDolPerMonth.push(adjustmentMonthExpense);
  });

  return totalDolPerMonth;
}

function getTotalGrossRevenue(formValues: ProjectionFormValues) {
  const totalGrossRevenuePerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const riseMonthRevenue = getDreSumByMonth(m, formValues.riseDreRevenue);
    const pipedriveMonthRevenue = getDreSumByMonth(
      m,
      formValues.pipedriveDreRevenue
    );
    const adjustmentMonthRevenue = formValues.grossRevenue
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.grossRevenue)
      : 0;
    totalGrossRevenuePerMonth.push(
      riseMonthRevenue + pipedriveMonthRevenue + adjustmentMonthRevenue
    );
  });

  return totalGrossRevenuePerMonth;
}

function getTotalTax(formValues: ProjectionFormValues) {
  const totalGrossTaxPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const riseMonthTax = getDreSumByMonth(m, formValues.riseDreTax);
    const pipedriveMonthTax = getDreSumByMonth(m, formValues.pipedriveDreTax);
    const adjustmentMonthTax = formValues.tax
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.tax)
      : 0;
    totalGrossTaxPerMonth.push(
      riseMonthTax + pipedriveMonthTax + adjustmentMonthTax
    );
  });

  return totalGrossTaxPerMonth;
}

function getTotalAdmCorpAmount(formValues: ProjectionFormValues) {
  const totalAdmCorpPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthExpense = formValues.admCorp
      ? getAdjustmentInitialValuesSumByMonth(m, formValues.admCorp)
      : 0;
    totalAdmCorpPerMonth.push(adjustmentMonthExpense);
  });

  return totalAdmCorpPerMonth;
}

export const expenseGroupTotalValuesFunctions = {
  getTotalSalesCost,
  getTotalSgAndAAmount,
  getTotalPddAmount,
  getTotalDolAmount,
  getTotalGrossRevenue,
  getTotalTax,
  getTotalAdmCorpAmount,
};
