import { css, styled } from "@mui/material";
import { HTMLProps } from "react";
import { PaletteColorVariant } from "../../../../../../../../shared/common/types/externalLibraries/mui.types";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  color: PaletteColorVariant;
}

const PureComponent = ({ color, ...rest }: OwnProps) => <div {...rest} />;

export const StyledIncomeItem = styled(PureComponent)`
  font-weight: 500;

  ${({ color, theme }) =>
    color &&
    css`
      color: ${theme.palette[color].main};
    `}
`;
