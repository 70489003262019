import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrency } from "../../../../../../../../../services/subsidiaries/subsidiaries.service";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    string,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
}

const cachedSubsidiaries = new CachedTranslatedData({
  callback: () => getCurrency({ currencyToRemove: "BRL" }),
  defaultCacheDuration: 600000, // 10 minutes
});

export const CurrencySubsidiariesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "subsidiaries.errors.data.fetch.failedToFetchSubsidiaries"
      )}
      fetchOptionsMemo={cachedSubsidiaries.run}
      textfieldProps={{
        label: t("brlParity.keywords.fields.currency"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
