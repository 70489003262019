import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckboxFormik } from "../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AllocationTypeAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/allocations/AllocationTypeAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const DisciplinesFilterForm = ({
  formikProps: { values },
}: OwnProps) => {
  const { t } = useTranslation();
  return (
    <StyledForm>
      <CostCentersSearchAutocompleteFormik name="costCenter" />
      <AllocationTypeAutocompleteFormik name="allocationType" />
      <FormControlLabel
        label={t("general.keywords.fields.onlyActivated")}
        control={<CheckboxFormik name="onlyActivated" />}
      />
    </StyledForm>
  );
};
