import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { createCorporateAllocations } from "../../../../../../../services/corporateAllocations/corporateAllocations.service";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormContent } from "./FormContent";
import { useFormikConfig } from "./shared/react/hooks/validation/useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("corporateAllocations.modal.data.create.title"),
      },
      button: {
        FormButton: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: FormContent,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (formValues.allocationType === null)
            throw new Error("'allocationType' cannot be null on submit.");

          try {
            await createCorporateAllocations({
              allocationType: formValues.allocationType.id,
              idsUserCollaborators: formValues.userCollaborators.map(
                (x) => x.id
              ),
              idCostCenter: formValues.costCenter?.id,
              idCorporateSegment: formValues.corporateSegment?.id,
              allocationYearMonths: formValues.allocationYearMonths.map(
                (x) => ({
                  yearMonth: x.yearMonth,
                  week1Percentage: parseLocaleNumber(x.week1Percentage),
                  week2Percentage: parseLocaleNumber(x.week2Percentage),
                  week3Percentage: parseLocaleNumber(x.week3Percentage),
                  week4Percentage: parseLocaleNumber(x.week4Percentage),
                  week5Percentage:
                    x.week5Percentage !== ""
                      ? parseLocaleNumber(x.week5Percentage)
                      : undefined,
                })
              ),
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            console.error(error);

            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "corporateAllocations.errors.data.create.failedToCreateCorporateAllocations",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
