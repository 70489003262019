import {
  Assignment as AssignmentIcon,
  CorporateFare as CorporateFareIcon,
  BusinessCenter,
  AccountBox,
  PostAdd,
} from "@mui/icons-material";
import PolylineIcon from "@mui/icons-material/Polyline";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  PageCardButton,
  PageCardButtonsList,
} from "../../../../../shared/common/react/components/navigation/PageCardButton";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import {
  budgetReadRestrictions,
  collaboratorAllocationsReadRestrictions,
  collaboratorManagementReadRestrictions,
  corporateAllocationsReadRestrictions,
  costDistributionReadRestrictions,
  projectReadRestrictions,
  projectRegisterReadRestrictions,
  resultsProjectionReadRestrictions,
} from "../../../../shared/constants/routing/restrictions/managementRestrictions.contants";
import {
  disciplinesReadRestrictions,
  skillCategoriesReadRestrictions,
} from "../../../../shared/constants/routing/restrictions/peopleAndManagementRestrictions.constants";

export const Management = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("management.pages.main.title"));
  }, [t]);

  return (
    <PageCardButtonsList>
      <Protected restrictions={projectRegisterReadRestrictions}>
        <PageCardButton
          title="pipedrive.pages.main.title"
          Icon={PostAdd}
          link="/registration-of-projects-and-additives"
        />
      </Protected>
      <Protected restrictions={projectReadRestrictions}>
        <PageCardButton
          title="projects.keywords.general.projects"
          Icon={AssignmentIcon}
          link="/projects"
        />
        <PageCardButton
          title="frames.keywords.general.frames"
          Icon={BusinessCenter}
          link="/frames"
        />
      </Protected>
      <Protected restrictions={corporateAllocationsReadRestrictions}>
        <PageCardButton
          title="corporateAllocations.keywords.general.corporateAllocations"
          Icon={CorporateFareIcon}
          link="/corporate-allocations"
        />
      </Protected>
      <Protected restrictions={collaboratorAllocationsReadRestrictions}>
        <PageCardButton
          title="collaboratorAllocations.keywords.general.collaboratorAllocations"
          Icon={AccountBox}
          link="/collaborator-allocations"
        />
      </Protected>
      <Protected restrictions={resultsProjectionReadRestrictions}>
        <PageCardButton
          title="projection.pages.main.title"
          Icon={MultilineChartIcon}
          link="/projection"
        />
      </Protected>
      <Protected
        listRestrictions={[
          disciplinesReadRestrictions,
          skillCategoriesReadRestrictions,
        ]}
      >
        <PageCardButton
          title="skillsandCertifications.pages.main.title"
          Icon={WorkspacePremiumIcon}
          link="/skills-and-certifications"
        />
      </Protected>
      <Protected restrictions={budgetReadRestrictions}>
        <PageCardButton
          title="budget.pages.main.title"
          Icon={CurrencyExchangeIcon}
          link="/budget"
        />
      </Protected>
      <Protected restrictions={collaboratorManagementReadRestrictions}>
        <PageCardButton
          title="collaboratorManagement.general.main.title"
          Icon={SupervisedUserCircleIcon}
          link="/collaborator-management"
        />
      </Protected>
      <Protected restrictions={costDistributionReadRestrictions}>
        <PageCardButton
          title="costDistribution.general.main.title"
          Icon={PolylineIcon}
          link="/cost-distribution"
        />
      </Protected>
    </PageCardButtonsList>
  );
};
