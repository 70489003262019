import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { uploadProjectAllocations } from "../../../../../services/allocations/allocations.service";
import { convertTo } from "../../../../../shared/common/helpers/types/converters.helpers";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { CorporateAllocationUploadResponseDTO } from "../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationUploadResponseDTO";
import { ProjectsFiltersDTO } from "../../../../../shared/specific/DTOs/project/filters/ProjectsFiltersDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { uploadProjectAllocationsRestrictions } from "../../../../shared/constants/routing/restrictions/performanceRestrictions.constants";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { useUploadForm } from "./hooks/useUploadForm";
import {
  StyledProjectAllocationUpload,
  StyledTextButton,
} from "./hooks/useUploadForm/components";
import { useWorksheetHelp } from "./hooks/useWorksheetHelp";

export const ProjectsMainPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);
  const loadProjectAllocations = () => fileUploadInputRef.current?.click();
  const [filename, setFilename] = useState<string | null>(null);
  const [responseUpload, setResponseUpload] =
    useState<CorporateAllocationUploadResponseDTO | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("projects.pages.main.title"));
  }, [t]);

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();
  const { filterButton, filters: modalFilters, filtersModal } = useFilterForm();

  const filters = useMemo(() => {
    return convertTo<ProjectsFiltersDTO>({
      ...belowToolbarFilters,
      ...modalFilters,
    });
  }, [belowToolbarFilters, modalFilters]);

  const { headCells, onTablePageChange, planningCreationModal, deletionModal } =
    useTableData({
      filters,
      reloadTablePage,
    });

  const rightActionButtons = useMemo(() => {
    return filterButton;
  }, [filterButton]);

  const { UploadButton, uploadModal, uploadButtonRef } = useUploadForm({
    isSubmitting,
    filename,
    hasSubmitted,
    responseUpload,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters, i18n.language],
    rendersBeforeEffect: 1,
  });

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;
    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      const result = await uploadProjectAllocations(file);
      setResponseUpload(result);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <StyledProjectAllocationUpload>
              <Protected restrictions={uploadProjectAllocationsRestrictions}>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                  hidden
                  ref={fileUploadInputRef}
                  onChange={onPickFile}
                />
                <UploadButton />
                <StyledTextButton onClick={loadProjectAllocations}>
                  {t("uploadProjectAllocation.keywords.uploadButton")}
                </StyledTextButton>
                <UseWorksheetHelpModalButtonContainer />
              </Protected>
            </StyledProjectAllocationUpload>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 25,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {filtersModal}
      {planningCreationModal}
      {deletionModal}
      {useWorksheetHelpModal}
      {uploadModal}
    </>
  );
};
