import * as yup from "yup";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import {
  YearMonthValueDTO,
  schemaYearMonthValueDTO,
} from "../projection/auxTypes/YearMonthValueDTO";

export interface DreValuesDTO {
  costCenter: CostCenterDTO | null;
  costCenterCode: string | null;
  yearMonthValues: YearMonthValueDTO[];
}

export const schemaDreValuesDTO = yup.object({
  costCenter: schemaCostCenterDTO.defined().nullable(),
  costCenterCode: yup.string().defined().nullable(),
  yearMonthValues: yup.array(schemaYearMonthValueDTO).defined(),
});
