import * as yup from "yup";

export enum InvoiceFilesTypeEnum {
  Request = 1,
  Issued = 2,
}

export const schemaInvoiceFilesTypeEnum = yup
  .mixed<InvoiceFilesTypeEnum>()
  .oneOf(Object.values(InvoiceFilesTypeEnum) as InvoiceFilesTypeEnum[]);
