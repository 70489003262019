import { useContext } from "react";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { CorporateAllocationsOfUserDTO } from "../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationsOfUserDTO";
import { CorporateAllocationsSuperUserContext } from "../../shared/react/contexts/CorporateAllocationsSuperUserContext";
import { useMonthlyAllocationClosing } from "../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";

interface OwnProps {
  allocationsOfUser: CorporateAllocationsOfUserDTO;
  DeleteButtonContainer: (
    props: CorporateAllocationsOfUserDTO
  ) => JSX.Element | null;
}

export const DeleteButtonSuperUserContainer = ({
  allocationsOfUser,
  DeleteButtonContainer,
}: OwnProps) => {
  const { canEditAllCorporateAllocations } = useContext(
    CorporateAllocationsSuperUserContext
  );

  const { checkIfMonthlyAllocationIsClosed } = useMonthlyAllocationClosing();

  if (canEditAllCorporateAllocations)
    return <DeleteButtonContainer {...allocationsOfUser} />;

  const monthlyAllocationIsClosed = allocationsOfUser.allocationYearMonths.some(
    (x) => checkIfMonthlyAllocationIsClosed(x.yearMonth)
  );

  if (monthlyAllocationIsClosed) return <DeleteIconButton disabled />;

  return <DeleteButtonContainer {...allocationsOfUser} />;
};
