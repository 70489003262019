import { BackdropProps, CircularProgress } from "@mui/material";
import { FocusTrap } from "@mui/base";
import { StyledBackdrop, StyledFocusDiv } from "./index.styles";

export const LoadingBackdrop = ({ open, ...props }: BackdropProps) => {
  return (
    <StyledBackdrop {...props} open={open}>
      <FocusTrap open={open}>
        <StyledFocusDiv tabIndex={-1}>
          <CircularProgress color="inherit" />
        </StyledFocusDiv>
      </FocusTrap>
    </StyledBackdrop>
  );
};
