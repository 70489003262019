import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getPageFromCurrent } from "../../../../../../../../../../../services/applicationState/history.service";
import { CustomLink } from "../../../../../../../../../../../shared/common/react/components/navigation/CustomLink";
import { FormContext } from "../../shared/contexts/FormContext";
import { StyledInternalFormFooter } from "./index.styles";

interface OwnProps {
  isSubmitting: boolean;
  submitForm: (() => Promise<void>) & (() => Promise<unknown>);
}

export const InternalFormFooter = ({ isSubmitting, submitForm }: OwnProps) => {
  const { t } = useTranslation();
  const { loading, error, mode } = useContext(FormContext);

  const shouldShowSaveButton = mode !== "viewing";

  const [searchParams] = useSearchParams();
  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);
  const previousPage = useMemo(() => {
    if (pageReturnTo) return pageReturnTo;

    const location = getPageFromCurrent(1);
    if (!location) return "/projects";
    if (location.pathname?.includes("registration-of-projects-and-additives"))
      return "/registration-of-projects-and-additives";
    return location.pathname + location.search;
  }, []);

  return (
    <StyledInternalFormFooter>
      <Button component={CustomLink} disabled={isSubmitting} to={previousPage}>
        {previousPage
          ? t("general.actions.general.return")
          : t("costCenterPeps.actions.navigation.viewProjects")}
      </Button>
      {shouldShowSaveButton && (
        <LoadingButton
          loading={isSubmitting}
          onClick={submitForm}
          variant="contained"
          disabled={Boolean(loading || error)}
        >
          {t("general.actions.general.save")}
        </LoadingButton>
      )}
    </StyledInternalFormFooter>
  );
};
