import { useFormikContext } from "formik";
import { useContext, useMemo } from "react";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { Week } from "../../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { formatMinutesToHourMinute } from "../../../../../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import { MaskedTextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { TimesheetCalendarContext } from "../shared/react/contexts/TimesheetCalendarContext";
import { DayNumber, TimeOtherProjects } from "./styles/general.styles";
import { StyledCalendarCell } from "./styles/StyledCalendarCell.styles";

interface OwnProps {
  day: number;
  yearMonth: YearMonth;
}

export const CalendarCell = ({ day, yearMonth }: OwnProps) => {
  const { isSubmitting } = useFormikContext();
  const { name, timesheetMonth, isReadOnly } = useContext(
    TimesheetCalendarContext
  );

  const minutesInOtherTimesheets = useMemo(() => {
    if (!timesheetMonth) return 0;

    const existingOtherTimesheetsDay =
      timesheetMonth.otherProjectOrCostCenterDays.find(
        (x) => x.entryDate.toDate().getDate() === day
      );
    return existingOtherTimesheetsDay?.minutes ?? 0;
  }, [timesheetMonth]);

  const formattedMinutesInOtherTimesheets = useMemo(() => {
    return formatMinutesToHourMinute(minutesInOtherTimesheets);
  }, [minutesInOtherTimesheets]);

  const isWeekend = useMemo(() => {
    const weekDay = yearMonth.toDate(day).getDay();
    return weekDay === Week.Saturday || weekDay === Week.Sunday;
  }, [day, yearMonth]);

  return (
    <StyledCalendarCell isWeekend={isWeekend}>
      <DayNumber>{day}</DayNumber>
      <TimeOtherProjects>
        ({formattedMinutesInOtherTimesheets})
      </TimeOtherProjects>
      <MaskedTextFieldFormik
        name={`${name}[${day - 1}].time`}
        overwrite="shift"
        mask="00:00"
        textFieldProps={{
          disabled: isSubmitting,
          InputProps: {
            readOnly: isReadOnly,
          },
        }}
      />
    </StyledCalendarCell>
  );
};
