import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "../shared/types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      description: "",
      costCenter: null,
      accountingAccountCredit: "",
      accountingAccountDebit: "",
      deactivatedDate: null,
    };
  }, [t]);

  const validationSchema = useMemo(() => {
    return yup.object({
      description: yup
        .string()
        .typeError(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
      costCenter: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      accountingAccountCredit: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      accountingAccountDebit: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      deactivatedDate: yup
        .date()
        .typeError("general.errors.data.fields.dates.invalidDate")
        .nullable(),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
