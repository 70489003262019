import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { getTextIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { getUserAllocationByHistory } from "../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { ListAllocations } from "./UserCollaboratorModalContent";
import { InternalFormDataContentAllocationsProps } from "./UserCollaboratorModalContent/shared/types/modal.types";

export const useCollaboratorAllocationsListByHistory = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        return (
          <Trans
            i18nKey="allocations.keywords.general.allocations"
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) => {
        return <ListAllocations internalFormData={internalModalData} />;
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => (
        <ViewModalButton
          onClick={onClick}
          buttonTooltip="allocations.keywords.general.allocations"
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: data,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const allocations = await getUserAllocationByHistory(
            data.historyContent?.historyId ? data.historyContent.historyId : 0
          );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!allocations)
            return setModalError(
              i18next.t(
                "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist"
              )
            );

          setInternalModalData({
            historyContent: data.historyContent,
            allocations,
          });
        } catch (error) {
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    general: {
      initialInternalModalData:
        null as InternalFormDataContentAllocationsProps | null,
    },
  });

  return {
    ViewingButtonContainer,
    viewingModal,
  };
};
