import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useCreationForm } from "../hooks/useCreationForm";
import { useTableData } from "../hooks/useTableData";
import { useViewingAllEntriesForm } from "../hooks/useViewingAllEntriesForm";
import { useYearMonthFilter } from "../hooks/useYearMonthFilter";
import { TimesheetSuperUserContext } from "../shared/react/contexts/TimesheetSuperUserContext";
import { TableFooter } from "../TableFooter";
import { StyledCenteredPicker } from "./index.styles";

export const TimesheetPageWithinContext = () => {
  const { t } = useTranslation();
  const [totalMinutes, setTotalMinutes] = useState<number | null>(null);

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(() => {
    enhancedTableRef.current?.reloadPage();
  }, []);

  useEffect(() => {
    setPageTitle(t("timesheets.pages.main.title"));
  }, [t]);

  const { canEditPastTimesheetButton } = useContext(TimesheetSuperUserContext);

  const { yearMonth, yearMonthDatePicker } = useYearMonthFilter();
  const {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    viewingModal,
  } = useTableData({
    yearMonth,
    reloadTablePage,
    setTotalMinutes,
  });
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
    yearMonth,
  });
  const { viewingAllEntriesButton, viewingAllEntriesModal } =
    useViewingAllEntriesForm({
      yearMonth,
    });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [yearMonth],
    rendersBeforeEffect: 1,
  });

  const middleToolbar = useMemo(() => {
    return (
      <StyledCenteredPicker>
        {yearMonthDatePicker}
        {viewingAllEntriesButton}
      </StyledCenteredPicker>
    );
  }, [yearMonthDatePicker, viewingAllEntriesButton]);

  const tableFooter = useMemo(() => {
    return <TableFooter totalMinutes={totalMinutes} />;
  }, [totalMinutes]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: creationButton,
          title: middleToolbar,
          rightActionButtons: canEditPastTimesheetButton,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        footerSettings={{
          content: tableFooter,
        }}
      />
      {creationModal}
      {deletionModal}
      {editingModal}
      {viewingModal}
      {viewingAllEntriesModal}
    </>
  );
};
