import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import i18next from "i18next";
import { useFormikConfig } from "./useFormikConfig";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CreationEditingForm } from "../components/CreationEditingForm";
import { UserCertificationCreateDTO } from "../../../../../../../../shared/specific/DTOs/userCertification/UserCertificationCreateDTO";
import { createUserCertification } from "../../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";

export const useCreationForm = () => {
  const formikConfig = useFormikConfig();
  const { loadPage } = useContext(ProfileContext);

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return (
          <div>
            {t("collaboratorProfile.actions.data.general.addCertification")}
          </div>
        );
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => <CreationEditingForm {...rest} />,
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          if (!formValues.certificationFile) {
            notifyIf4xxApiErrorDTO({
              error:
                "collaboratorProfile.errors.data.general.mustAttachCertification",
              defaultMessage:
                "collaboratorProfile.errors.data.general.mustAttachCertification",
            });
            setSubmitting(false);
            return;
          }

          const certificationToCreate: UserCertificationCreateDTO = {
            dateOfAcquisition: formValues.dateOfAcquisition ?? new Date(),
            expirationDate: formValues.expirationDate ?? null,
            idSkillCertification: formValues.certification?.id ?? 0,
          };

          await createUserCertification(
            [formValues.certificationFile as File],
            certificationToCreate
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          closeModal();
          await loadPage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "collaboratorProfile.errors.data.create.failedToAddCertification",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
