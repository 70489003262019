/* eslint-disable react/no-this-in-sfc */
import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesBaseCollaborators } from "./types/form.types";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { generateBaseCollaboratorsReport } from "../../../../../../../../../services/reports/reports.service";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { BaseCollaboratorReportScopeTypeEnum } from "../../../../../../../../../shared/specific/enums/BaseCollaboratorReportScopeType/ReportScopeType.enum";
import { BaseCollaboratorReportScopeTypeToTranslationCodeMap } from "../../../../../../../../../shared/specific/maps/baseCollaboratorReportScopeType/baseCollaboratorReportScopeType.maps";
import { BaseCollaboratorForm } from "./components/BaseCollaboratorForm";

export const BaseCollaborators = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesBaseCollaborators>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesBaseCollaborators = {
      userCollaborators: [],
      contractRole: null,
      costCenter: null,
      project: null,
      userManager: null,
      division: null,
      startYearMonth: null,
      endYearMonth: null,
      onlyActiveUsers: true,
      reportScopeType: {
        id: BaseCollaboratorReportScopeTypeEnum.AllCollaborators,
        label: t(
          BaseCollaboratorReportScopeTypeToTranslationCodeMap[
            BaseCollaboratorReportScopeTypeEnum.AllCollaborators
          ]
        ),
      },
    };

    const validationSchema = yup.object({
      userCollaborators: yup.array(yup.object()).defined(),
      contractRole: yup.object().nullable(),
      jobRole: yup.object().nullable(),
      costCenter: yup.object().nullable(),
      project: yup.object().nullable(),
      userManager: yup.object().nullable(),
      division: yup.object().nullable(),
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      endYearMonth: yup
        .date()
        .nullable()
        .test({
          name: "teste",
          message: t(
            "reports.errors.generation.baseCollaboratorReport.datetimeLimitToNotHaveTimeout"
          ),
          test(endYearMonth) {
            if (!endYearMonth) return true;
            if (
              !this.parent.division &&
              this.parent.userCollaborators.length !== 1
            ) {
              const oneYearInMiliseconds = 31536000000;

              const endYearMonthDate = new Date(endYearMonth);
              const startYearMonthDate = new Date(this.parent.startYearMonth);

              const diffInMiliseconds =
                endYearMonthDate.getTime() - startYearMonthDate.getTime();

              return !(diffInMiliseconds > oneYearInMiliseconds);
            }
            return true;
          },
        })
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      onlyActiveUsers: yup.boolean().required(),
      reportScopeType: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesBaseCollaborators,
    { setSubmitting }: FormikHelpers<FormValuesBaseCollaborators>
  ) => {
    if (!values.startYearMonth || !values.endYearMonth)
      throw new Error("All required fields must be filled.");

    try {
      await generateBaseCollaboratorsReport({
        IdsUserCollaborator: values.userCollaborators.map((x) => x.id),
        IdContractRole: values?.contractRole
          ? values.contractRole.id
          : undefined,
        IdCostCenter: values?.costCenter ? values.costCenter.id : undefined,
        IdProject: values?.project ? values.project.id : undefined,
        IdUserManager: values?.userManager ? values.userManager.id : undefined,
        IdDivision: values?.division ? values.division.id : undefined,
        StartTime: YearMonth.createFromDate(values.startYearMonth),
        EndTime: YearMonth.createFromDate(values.endYearMonth),
        OnlyActiveUsers: values.onlyActiveUsers,
        ReportScopeType: values.reportScopeType.id,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => <BaseCollaboratorForm {...formikProps} />}
    </Formik>
  );
};
