import {
  Language,
  schemaLanguage,
} from "../../shared/common/enums/locale/Language.enum";
import { checkIsValidType } from "../../shared/common/helpers/data/validation/schema.helpers";

const storedLanguageKey = "storedLanguage";

export const getStoredLanguage = (): Language | null => {
  const language = localStorage.getItem(storedLanguageKey);
  if (checkIsValidType<Language>(schemaLanguage, language)) return language;

  return null;
};

export const setStoredLanguage = (language: Language) => {
  localStorage.setItem(storedLanguageKey, language);
};

export const removeStoredLanguage = () => {
  localStorage.removeItem(storedLanguageKey);
};
