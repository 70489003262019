import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const skills = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const skillsLableCpt: CptProps = {
    text: "Applied Technologies",
    options: {
      align: pres.AlignH.left,
      y: "74%",
      x: "1%",
      w: "25%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const skillsNameCpt: CptProps = {
    text:
      projectData.projectSkill.length > 0
        ? projectData.projectSkill.map((x) => x.name).join(" / ")
        : "-",
    options: {
      align: pres.AlignH.left,
      y: "75%",
      x: "1%",
      w: "48%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: getFontSizeByString(
        projectData.projectSkill.length > 0
          ? projectData.projectSkill.map((x) => x.name).join(" / ")
          : "-"
      ),
    },
  };

  slide.addText(skillsLableCpt.text, skillsLableCpt.options);
  slide.addText(skillsNameCpt.text, skillsNameCpt.options);
};
