import { AutocompleteOption } from "../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { DivisionDTO } from "../../../../../../../../../../shared/specific/DTOs/division/DivisionDTO";
import { ProjectOnlyDTO } from "../../../../../../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import { SubsidiaryDTO } from "../../../../../../../../../../shared/specific/DTOs/subsidiary/SubsidiaryDTO";

export enum FilterType {
  division = 1,
  projects = 2,
  all = 3,
}

export interface FormValuesProvisionReport {
  filterType: AutocompleteOption<FilterType> | null;
  projects: ProjectOnlyDTO[];
  division: DivisionDTO | null;
  startYearMonth: Date | null;
  endYearMonth: Date | null;
  subsidiary: SubsidiaryDTO | null;
}
