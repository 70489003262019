import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  ProjectOnlyDTO,
  schemaProjectOnlyDTO,
} from "../project/ProjectOnlyDTO";

export interface AdditiveDTO {
  id: number;
  insertedDate: Date;
  scheduledStartDate: DateOnly;
  scheduledEndDate: DateOnly;
  mv: number;
  amount: number;
  justification: string;
  anticipationJustification: string | null;
  createdBy: string;
  project: ProjectOnlyDTO;
  approvedCredit: boolean | null;
  version: string;
}

export const schemaAdditiveDTO = yup.object({
  id: yup.number().integer().defined(),
  insertedDate: yup.date().defined(),
  scheduledStartDate: schemaDateOnly.defined(),
  scheduledEndDate: schemaDateOnly.defined(),
  mv: yup.number().defined(),
  amount: yup.number().defined(),
  justification: yup.string().defined(),
  anticipationJustification: yup.string().nullable().defined(),
  createdBy: yup.string().defined(),
  project: schemaProjectOnlyDTO.defined(),
  approvedCredit: yup.boolean().nullable().defined(),
  version: yup.string().defined(),
});
