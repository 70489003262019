import * as yup from "yup";
import {
  DashboardGroupTranslationDTO,
  schemaDashboardGroupTranslationDTO,
} from "./translate/DashboardGroupTranslationDTO";

export interface DashboardGroupDTO {
  id: number;
  name: string;
  version: string;
  dashboardGroupTranslations: DashboardGroupTranslationDTO[];
}

export const schemaDashboardGroupDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  version: yup.string().defined(),
  dashboardGroupTranslations: yup
    .array(schemaDashboardGroupTranslationDTO)
    .defined(),
});
