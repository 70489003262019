import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BrlParityFiltersDTO } from "../../../../../../shared/specific/DTOs/brlParity/filters/BrlParityFiltersDTO";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/data.types";
import { ExternalEnhancedTableExternalPageChanger } from "../../../../../../shared/common/react/components/table/EnhancedTable/shared/types/propsRef.types";
import { getBrlParitys } from "../../../../../../services/brlParity/BrlParity.service";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { useEditingForm } from "./useEditingForm";
import { useDeletionForm } from "./useDeletionForm";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";

interface OwProps {
  filters?: BrlParityFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwProps) => {
  const { t } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("brlParity.keywords.fields.yearMonth"),
      t("brlParity.keywords.fields.currency"),
      t("brlParity.keywords.fields.plannedQuote"),
      t("brlParity.keywords.fields.performedQuote"),
      t("brlParity.keywords.fields.lastBusinessDayQuote"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onBrlParityPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = [
          "yearMonth",
          "currency",
          "plannedValue",
          "performedValue",
        ];
        try {
          const {
            tableData: brlParityData,
            page,
            totalCount,
          } = await getBrlParitys({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });
          const rows = brlParityData.map(
            (brlParityData): ExternalEnhancedRow => ({
              id: brlParityData.id,
              cells: [
                brlParityData.yearMonth.toString(),
                brlParityData.currency,
                formatNumber(brlParityData.plannedValue, { fractionDigits: 4 }),
                brlParityData.performedValue
                  ? formatNumber(brlParityData.performedValue, {
                      fractionDigits: 4,
                    })
                  : "-",
                brlParityData.lastBusinessDayValue
                  ? formatNumber(brlParityData.lastBusinessDayValue, {
                      fractionDigits: 4,
                    })
                  : "-",
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.BrlParity,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer brlParity={brlParityData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.BrlParity,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...brlParityData} />
                      </Protected>
                    </>
                  ),
                },
              ],
            })
          );
          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onBrlParityPageChange,
    editingModal,
    deletionModal,
  };
};
