import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";

export interface TimesheetMonthDayDTO {
  id: number;
  entryDate: DateOnly;
  minutes: number;
  version: string;
}

export const schemaTimesheetMonthDayDTO = yup.object({
  id: yup.number().integer().defined(),
  entryDate: schemaDateOnly.defined(),
  minutes: yup.number().integer().defined(),
  version: yup.string().defined(),
});
