import * as yup from "yup";
import {
  HorizontalTranslationDTO,
  schemaHorizontalTranslationDTO,
} from "./translation/HorizontalTranslation";

export interface HorizontalDTO {
  id: number;
  name: string;
  horizontalTranslation: HorizontalTranslationDTO[];
}

export const schemaHorizontalDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  horizontalTranslation: yup.array(schemaHorizontalTranslationDTO).defined(),
});
