import { InputAdornment, TextField, Typography } from "@mui/material";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { generalFieldsConstants } from "../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { FormattedCurrency } from "../../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { YesNoAutocompleteFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useProjectContext } from "../../../../../shared/react/contexts/ProjectContext";
import {
  StyledInfoItem,
  StyledInfoItems,
} from "../../../../../shared/react/styles/ui/layouts/general.styles";
import { IntervalTypeAutocompleteFormik } from "../shared/react/components/form/formik/autocomplete/fields/IntervalTypeAutocompleteFormik";
import {
  ActivityAmountOption,
  IntervalType,
} from "../shared/enums/data/form/general.enums";
import { CreateManyActivitiesFormValues } from "../shared/types/data/form/values.types";
import {
  StyledActivityDescriptionSection,
  StyledActivityDescriptionFirstRow,
  StyledForm,
  StyledRepeatValuesArea,
  StyledScheduledDateSection,
  StyledSection,
  StyledValueSection,
  StyledActivityDescriptionSecondRow,
  StyledScheduledDateAlwaysVisiblePart,
} from "./index.styles";
import { WeekAutocompleteFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/dates/WeekAutocompleteFormik";
import { ActivityAmountAutocompleteFormik } from "../shared/react/components/form/formik/autocomplete/fields/ActivityAmountAutocompleteFormik";
import { generateActivityDescription } from "../shared/helpers/data/general/generators.helpers";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { ActivityCreationInfoDTO } from "../../../../../../../../../../shared/specific/DTOs/activity/ActivityCreationInfoDTO";
import { useMinScheduledDate } from "../../../shared/react/hooks/data/fields/useMinScheduledDate";
import { ContractType } from "../../../../../../../../../../shared/specific/enums/projects/ContractType.enum";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps
  extends Omit<
    FormContentProps<CreateManyActivitiesFormValues>,
    "internalFormData"
  > {
  activityCreationInfo: ActivityCreationInfoDTO;
}

export const CreateManyForm = ({
  formikProps: { values, isSubmitting, setFieldValue, errors },
  activityCreationInfo,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project, costCenterPep } = useProjectContext();

  const { minScheduledDate } = useMinScheduledDate();

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {costCenterPep.currencySymbol}
      </InputAdornment>
    );
  }, [costCenterPep.currencySymbol]);

  const repeatFieldData = useMemo(() => {
    const possibilities = {
      [IntervalType.Day]: {
        labelKey: "activities.keywords.fields.repeatEveryValueDays",
        name: "repeatDays",
      },
      [IntervalType.Week]: {
        labelKey: "activities.keywords.fields.repeatEveryValueWeeks",
        name: "repeatWeeks",
      },
      [IntervalType.Month]: {
        labelKey: "activities.keywords.fields.repeatEveryValueMonths",
        name: "repeatMonths",
      },
    };

    return possibilities[values.intervalType.id];
  }, [values.intervalType]);

  const activitiesDescription = useMemo(() => {
    return generateActivityDescription({
      prefix: values.prefix,
      suffix: values.suffix,
      sequentialText: values.sequential?.id ? "N" : undefined,
      separatorBeforeSequential: values.separatorBeforeSequential,
      separatorAfterSequential: values.separatorAfterSequential,
    });
  }, [
    values.prefix,
    values.suffix,
    values.sequential?.id,
    values.separatorBeforeSequential,
    values.separatorAfterSequential,
  ]);

  useEffectAfterRenders({
    effect: () => {
      if (values.sequential?.id) return;

      setFieldValue("separatorBeforeSequential", "");
      setFieldValue("separatorAfterSequential", "");
    },
    deps: [values.sequential?.id],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("repeatDays", "");
      setFieldValue("repeatWeeks", "");
      setFieldValue("repeatMonths", "");
    },
    deps: [values.intervalType.id],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      if (
        values.activityAmountOption.id ===
        ActivityAmountOption.MaxBasedProjectSize
      ) {
        setFieldValue(
          "activityAmountSize",
          formatNumber(activityCreationInfo.maxActivityAmount, {
            fractionDigits: 2,
          })
        );
      } else {
        setFieldValue("activityAmountSize", "");
      }
    },
    deps: [values.activityAmountOption.id],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledForm>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("general.keywords.fields.budget")}
        </Typography>
        <StyledInfoItems>
          <StyledInfoItem>
            <div>{t("activities.keywords.fields.totalAmount")}</div>
            <div>
              <FormattedCurrency
                value={activityCreationInfo.billingAmountWithAdditives}
                currencySymbol={project.subsidiary.currencySymbol}
              />
            </div>
          </StyledInfoItem>
          <StyledInfoItem>
            <div>{t("activities.keywords.fields.activitiesAmount")}</div>
            <div>
              <FormattedCurrency
                value={activityCreationInfo.totalProjectIncome}
                currencySymbol={project.subsidiary.currencySymbol}
              />
            </div>
          </StyledInfoItem>
          <StyledInfoItem>
            <div>{t("activities.keywords.fields.amountLeft")}</div>
            <div>
              <FormattedCurrency
                value={values.readOnly.amountLeft}
                currencySymbol={project.subsidiary.currencySymbol}
              />
            </div>
          </StyledInfoItem>
        </StyledInfoItems>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("activities.keywords.fields.activityDescription")}
        </Typography>
        <StyledActivityDescriptionSection>
          <StyledActivityDescriptionFirstRow>
            <TextFieldFormik
              name="prefix"
              label={t("general.keywords.fields.prefix")}
              disabled={isSubmitting}
            />
            <TextFieldFormik
              name="suffix"
              label={t("general.keywords.fields.suffix")}
              disabled={isSubmitting}
            />
            <YesNoAutocompleteFormik
              name="sequential"
              autocompleteProps={{
                disabled: isSubmitting,
              }}
              textfieldProps={{
                label: t("activities.keywords.fields.withSequential"),
                disabled: isSubmitting,
                required: true,
              }}
              shouldReplaceValueOnMount
            />
          </StyledActivityDescriptionFirstRow>
          {values.sequential?.id && (
            <StyledActivityDescriptionSecondRow>
              <TextFieldFormik
                name="separatorBeforeSequential"
                label={t(
                  "activities.keywords.fields.separatorBeforeSequential"
                )}
                disabled={isSubmitting}
              />
              <TextFieldFormik
                name="separatorAfterSequential"
                label={t("activities.keywords.fields.separatorAfterSequential")}
                disabled={isSubmitting}
              />
            </StyledActivityDescriptionSecondRow>
          )}
          <TextField
            label={t("activities.keywords.fields.activitiesDescription")}
            value={activitiesDescription}
            disabled
          />
        </StyledActivityDescriptionSection>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("activities.keywords.fields.scheduledDate")}
        </Typography>
        <StyledScheduledDateSection>
          <StyledScheduledDateAlwaysVisiblePart>
            <DatePickerFormik
              name="initialScheduledDate"
              datePickerProps={{
                label: t("activities.keywords.fields.firstScheduledDate"),
                slotProps: {
                  popper: {
                    placement: "left",
                  },
                },
                minDate: minScheduledDate,
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <IntervalTypeAutocompleteFormik
              name="intervalType"
              autocompleteProps={{
                disabled: isSubmitting,
                disableClearable: true,
              }}
              textfieldProps={{
                label: t("activities.keywords.fields.intervalType"),
                required: true,
                disabled: isSubmitting,
              }}
              shouldReplaceValueOnMount
            />
            <StyledRepeatValuesArea>
              <Trans
                i18nKey={repeatFieldData.labelKey}
                components={{
                  value: (
                    <LocalizedNumberTextFieldFormik
                      textFieldProps={{
                        label: " ",
                        required: true,
                      }}
                      name={repeatFieldData.name}
                      min={0}
                      max={generalFieldsConstants.maxDaysValue}
                      scale={2}
                      disabled={isSubmitting}
                    />
                  ),
                }}
              />
            </StyledRepeatValuesArea>
          </StyledScheduledDateAlwaysVisiblePart>
          {values.intervalType.id === IntervalType.Week && (
            <WeekAutocompleteFormik
              name="weekDayToRepeat"
              autocompleteProps={{
                disabled: isSubmitting,
                disableClearable: true,
              }}
              textfieldProps={{
                label: t("general.keywords.dateTime.general.dayOfTheWeek"),
                required: true,
              }}
              shouldReplaceValueOnMount
            />
          )}
        </StyledScheduledDateSection>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("general.keywords.fields.value")}
        </Typography>
        <StyledValueSection>
          <ActivityAmountAutocompleteFormik
            name="activityAmountOption"
            autocompleteProps={{
              disabled:
                isSubmitting ||
                project.contractType !== ContractType.FixedAllocation,
              disableClearable: true,
            }}
            textfieldProps={{
              label: t("activities.keywords.fields.activityAmountType"),
              required: true,
            }}
            shouldReplaceValueOnMount
            contractType={project.contractType}
          />
          <LocalizedNumberTextFieldFormik
            name="activityAmountSize"
            textFieldProps={{
              label: t("activities.keywords.fields.activityAmount"),
              InputProps: {
                startAdornment: currencyStartAdornment,
              },
              required: true,
            }}
            min={0}
            max={generalFieldsConstants.maxAmountValue}
            scale={2}
            disabled={
              isSubmitting ||
              values.activityAmountOption.id ===
                ActivityAmountOption.MaxBasedProjectSize
            }
          />
          <LocalizedNumberTextFieldFormik
            name="generateActivitiesUntil"
            textFieldProps={{
              label: t("activities.keywords.fields.generateActivitiesUntil"),
              InputProps: {
                startAdornment: currencyStartAdornment,
              },
              required: true,
            }}
            min={0}
            max={generalFieldsConstants.maxAmountValue}
            scale={2}
            disabled={isSubmitting}
          />
        </StyledValueSection>
      </StyledSection>
    </StyledForm>
  );
};
