import {
  EnhancedRowCellData,
  EnhancedRowData,
  ExternalEnhancedRow,
} from "../types/data.types";
import { checkIsRowCells, checkIsRowCellValue } from "../validators";

export const formatExternalRows = (
  externalRowsData: ExternalEnhancedRow[]
): EnhancedRowData[] => {
  const defaultRowCellsData: EnhancedRowCellData = {
    value: null,
  };

  const rowsData = externalRowsData.map((row, index) => {
    if (!checkIsRowCells(row)) return row;

    return { id: index, cells: row };
  });

  return rowsData.map((row) => {
    const rowCellsData = row.cells.map((cellDataOrValue) => {
      const isRowCellValue = checkIsRowCellValue(cellDataOrValue);
      if (!isRowCellValue)
        return {
          ...defaultRowCellsData,
          ...cellDataOrValue,
        };

      const rowCellData: EnhancedRowCellData = { value: cellDataOrValue };
      return rowCellData;
    });

    return {
      id: row.id,
      cells: rowCellsData,
      rowInfo: row.rowInfo,
      CustomTableRow: row.CustomTableRow,
    };
  });
};
