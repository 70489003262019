import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import { DeleteActionButton } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/accessories/DeleteActionButton";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CostDistributionDTO } from "../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionDTO";
import {
  deleteCostDistribution,
  getCostDistributionOnly,
} from "../../../../../../services/costDistribution/costDistribution.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeletionButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<CostDistributionDTO, CostDistributionDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: costDistribution }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{
              name: `${
                costDistribution?.customerTrigram &&
                `${costDistribution?.customerTrigram} - `
              }${costDistribution?.segmentGroup.description} `,
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButton: DeleteActionButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: costDistribution,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const costDistributionData = await getCostDistributionOnly({
            customerTrigram: costDistribution.customerTrigram,
            idSegmentGroup: costDistribution.segmentGroup.id,
            idSubsidiary: costDistribution.subsidiary.id,
          });

          if (!costDistributionData)
            return setModalError(
              i18next.t(
                "costDistribution.erros.data.general.costDistributionDoesNotExist"
              )
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(costDistributionData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "costDistribution.erros.data.general.failedToFeatchCostDistribution",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: costDistribution,
      }) => {
        if (!costDistribution)
          throw new Error(
            "costDistribution.erros.data.general.costDistributionCannotBeNull"
          );

        try {
          await deleteCostDistribution({
            customerTrigram: costDistribution.customerTrigram,
            idSegmentGroup: costDistribution.segmentGroup.id,
            idSubsidiary: costDistribution.subsidiary.id,
          });
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="costDistribution.erros.data.general.failedToDeleteCostdistribution"
              values={{
                name: `${
                  costDistribution?.customerTrigram &&
                  `${costDistribution?.customerTrigram} - `
                }${costDistribution?.segmentGroup.description}`,
              }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeletionButtonContainer, deletionModal };
};
