import * as yup from "yup";
import { useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { ProjectionsFilterContainerPage } from "./shared/components/ProjectionsFilterContainerPage";
import { FilterValues } from "./shared/types/filter.types";
import { StyledTitleContainer } from "../shared/styles/pages/index.styles";
import { ProjectionFilterContext } from "../shared/context/ProjectionFilterContext";
import { CarouselContextProvider } from "../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { getNowBrazilianDate } from "../../../../../shared/common/helpers/data/dates/general.helpers";

export const ProjectionMainPage = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FilterValues>>(null);

  const { filterValues } = useContext(ProjectionFilterContext);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      clientAccount: filterValues.clientAccount ?? "",
      customerTrigram: filterValues.customerTrigram ?? "",
      division: filterValues.division ?? null,
      segmentGroup: filterValues.segmentGroup ?? null,
      subsidiary: filterValues.subsidiary ?? null,
      year: filterValues.year ?? getNowBrazilianDate() ?? null,
    };

    const validationSchema = yup.object({
      clientAccount: yup.string(),
      customerTrigram: yup.string(),
      division: yup.object(),
      segmentGroup: yup.object(),
      subsidiary: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
      year: yup
        .date()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  useEffect(() => {
    setPageTitle({
      title: t("projection.pages.main.title"),
      Component() {
        return (
          <StyledTitleContainer>
            <span>{t("projection.pages.main.title")}</span>
            <span>{t("projection.pages.main.subtitle")}</span>
          </StyledTitleContainer>
        );
      },
    });
  }, [t]);

  return (
    <CarouselContextProvider
      firstYearMonth={YearMonth.createFromNow().month}
      initialEarliestYearMonth={new YearMonth(0, 2024)}
      initialLatestYearMonth={new YearMonth(11, 2024)}
    >
      <Formik
        innerRef={formikRef}
        initialValues={formikConfig.initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={() => undefined}
        validationSchema={formikConfig.validationSchema}
      >
        {(formikProps) => (
          <ProjectionsFilterContainerPage formikContenProps={formikProps} />
        )}
      </Formik>
    </CarouselContextProvider>
  );
};
