import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getAdditive } from "../../../../../../../../services/additives/additives.service";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ViewFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/ViewFormButton";
import { AdditiveDTO } from "../../../../../../../../shared/specific/DTOs/additive/AdditiveDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonAdditivesForm } from "../shared/react/components/content/CommonAdditivesForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { FormValues } from "../shared/types/data/form/values.types";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

export const useViewingForm = () => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { ContentButton: ViewingButton, contentModal: viewingModal } =
    useFormikModalButton<FormValues, AdditiveDTO, AdditiveDTO>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("additives.modal.data.view.title"),
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => (
          <CommonAdditivesForm
            {...props}
            mode="viewing"
            createdBy={props?.internalFormData?.createdBy}
          />
        ),
        FormActionsMemo: ({ closeModal }) => {
          const { t } = useTranslation();

          return (
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          );
        },
      },
      button: {
        FormButton: ViewFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: externalAdditive,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
          setModalError,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const additive = await getAdditive(externalAdditive.id);
            if (!additive)
              return setModalError(
                i18next.t("additives.errors.data.general.additiveDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            let approvedCredit = null;
            if (additive.approvedCredit) {
              approvedCredit = {
                id: true,
                label: t("general.keywords.general.yes"),
              };
            }

            if (additive.approvedCredit === false) {
              approvedCredit = {
                id: false,
                label: t("general.keywords.general.no"),
              };
            }

            setFormValues({
              amount: formatNumber(additive.amount, { fractionDigits: 2 }),
              anticipationJustification:
                additive.anticipationJustification || "-",
              justification: additive.justification || "-",
              mv: formatNumber(additive.mv, { fractionDigits: 2 }),
              scheduledEndDate: additive.scheduledEndDate.toDate(),
              scheduledStartDate: additive.scheduledStartDate.toDate(),
              approvedCredit,
            });

            setInternalFormData(additive);
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "additives.errors.data.fetch.failedToFetchAdditive",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
    });

  return { ViewingButton, viewingModal };
};
