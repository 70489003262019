import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonPlanningFormValues } from "../../../../../types/data/form/planning.types";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { TypeOfEvidenceEnum } from "../../../../../../../../../../shared/specific/enums/projects/TypeOfEvidence.enum";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";

export const usePlanningFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: CommonPlanningFormValues = useMemo(() => {
    return {
      scheduledStartDate: null,
      scheduledEndDate: null,
      mv: "",
      typeOfEvidence: {
        id: TypeOfEvidenceEnum.Timesheet,
        label: t(
          typeOfEvidenceToTranslationCodeMap[TypeOfEvidenceEnum.Timesheet]
        ),
      },
      userCoordinator: null,
      userManager: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      typeOfEvidence: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      userCoordinator: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      userManager: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      scheduledStartDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
      scheduledEndDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .min(
          yup.ref("scheduledStartDate"),
          t("general.errors.data.fields.dates.dateCannotBeBeforeName", {
            name: t("projects.keywords.fields.baselineStart"),
          })
        )
        .required(t("general.errors.data.fields.general.required")),
      mv: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
