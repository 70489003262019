import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useTableData } from "./hooks/useTableData";
import { useFilterForm } from "./hooks/useFilterForm";
import { InvoiceBackendFiltersDTO } from "../../../../../shared/specific/DTOs/invoice/filters/InvoiceBackendFiltersDTO";
import { InvoicesFilterContext } from "../shared/context/InvoicesFilterContext";

export const InvoicesMainPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const { setFilterValues, filterValues } = useContext(InvoicesFilterContext);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("invoice.keywords.general.invoice"));
  }, [t]);

  const { invoicesFilter, FormComponent } = useFilterForm();

  const formattedFilter = useMemo(() => {
    const filter: InvoiceBackendFiltersDTO = {
      responsibleLogin: filterValues.responsible?.login ?? undefined,
      idSubsidiary: filterValues.subsidiary?.id,
      limitDate: filterValues.limmitDate ?? undefined,
      projectCode: filterValues.projectCode ?? undefined,
      status: filterValues.status.map((option) => option.id) ?? undefined,
      idDivision: filterValues.division?.id,
      invoiceNumber: filterValues.invoiceNumber ?? undefined,
      requester: filterValues.requester?.login ?? undefined,
    };

    return filter;
  }, [filterValues]);

  const { headCells, onTablePageChange, updatePaidOutModal } = useTableData({
    reloadTablePage,
    filters: formattedFilter,
  });

  useEffect(() => {
    setFilterValues(invoicesFilter);
  }, [invoicesFilter]);

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [formattedFilter, i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      {FormComponent}
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 25,
        }}
        orderDefaults={{
          defaultOrderBy: 5,
          defaultOrder: "desc",
        }}
      />
      {updatePaidOutModal}
    </>
  );
};
