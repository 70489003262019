import * as yup from "yup";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";

export interface CorporateSegmentsDTO {
  id: number;
  name: string;
  costCenter: CostCenterDTO | null;
  allocationType: EmployeeAllocationType;
  deactivatedDate: Date | null;
  version: string;
}

export const schemaCorporateSegmentsDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  costCenter: schemaCostCenterDTO.nullable().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  deactivatedDate: yup.date().nullable().defined(),
  version: yup.string().defined(),
});
