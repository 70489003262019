/* eslint-disable @typescript-eslint/no-explicit-any */
import { isValid } from "date-fns";
import { useMemo, useState } from "react";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import {
  getNowBrazilianDate,
  getNowYearMonthBrazil,
} from "../../../../../../shared/common/helpers/data/dates/general.helpers";
import { YearMonthDatePicker } from "../../../../../../shared/common/react/components/form/general/dateTime/YearMonthDatePicker";

export const useYearMonthFilter = () => {
  const [yearMonthDate, setYearMonthDate] = useState<Date | null>(
    getNowBrazilianDate()
  );
  const [yearMonth, setYearMonth] = useState(getNowYearMonthBrazil());

  const minDate = useMemo(() => {
    return getNowYearMonthBrazil().addYears(-10).toDate();
  }, []);

  const maxDate = useMemo(() => {
    return getNowYearMonthBrazil().addMonths(1).toDateEndOfMonth();
  }, []);

  const checkIsValidInternal = (date: Date) => {
    if (!isValid(date) || date.getFullYear() < 2000) return false;
    if (minDate > date || maxDate < date) return false;
    return true;
  };

  const yearMonthDatePicker = useMemo(() => {
    return (
      <YearMonthDatePicker
        datePickerProps={{
          onChange: (value: any, keyboardInputValue: any) => {
            setYearMonthDate(value);

            if (value && checkIsValidInternal(value) && keyboardInputValue)
              setYearMonth(YearMonth.createFromDate(value));
          },
          onAccept: (value: any) =>
            value && setYearMonth(YearMonth.createFromDate(value)),
          value: yearMonthDate,
          minDate,
          maxDate,
        }}
      />
    );
  }, [yearMonthDate]);

  return {
    yearMonth,
    yearMonthDatePicker,
  };
};
