import { useTranslation } from "react-i18next";
import { FrameOnlyDTO } from "../../../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../../Projects/ProjectPlanningPage/shared/react/styles/ui/layouts/general.styles";
import { FormattedCurrency } from "../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";

interface OwnProps {
  frame: FrameOnlyDTO;
}

export const MainHeader = ({ frame }: OwnProps) => {
  const { t } = useTranslation();
  return (
    <StyledSubHeaderFixed>
      <StyledInfoItem>
        <div>{t("UN")}</div>
        <div>{frame.division.name}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("customers.keywords.general.customer")}</div>
        <div>{frame.customer.tradingName}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("subsidiaries.keywords.general.subsidiary")}</div>
        <div>{frame.subsidiary.name}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.baselineStart")}</div>
        <div>{frame.startDate.toDate().toLocaleDateString()}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.baselineEnd")}</div>
        <div>{frame.endDate.toDate().toLocaleDateString()}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("invoice.keywords.fields.billingAmount")}</div>
        <div>
          <FormattedCurrency
            value={frame.amount}
            currencySymbol={frame.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("frames.keywords.general.accountManager")}</div>
        <div>{frame.accountManager?.name ?? "-"}</div>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};
