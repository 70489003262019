import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import { SummaryContextProvider } from "./shared/react/contexts/SummaryContext";
import { SummaryTabWithinContext } from "./SummaryTabWithinContext";

export const SummaryTab = () => {
  const { project } = useProjectContext();
  return (
    <SummaryContextProvider id={project.id} isFrame={false}>
      <SummaryTabWithinContext />
    </SummaryContextProvider>
  );
};
