import { Formik, FormikHelpers, FormikProps } from "formik";
import i18next from "i18next";
import {
  ForwardedRef,
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CommonProjectionFormProps,
  CommonProjectionFormRef,
} from "./shared/propsRef.types";
import { useFormikConfig } from "./hooks/useFormikConfig";
import { ProjectionFormValues } from "../../types/form.types";
import { ProjectionFormWIthInContext } from "./ProjectionFormWIthInContext";
import {
  ProjectionAdjustmentListUpdateDTO,
  ProjectionAdjustmentUpdateDTO,
} from "../../../../../../../../shared/specific/DTOs/ProjectionAdjustment/ProjectionAdjustmentUpdateDTO";
import { ProjectionAdjustmentContext } from "../../context/projectionAdjustmentContexProvider";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { updateProjectionAdjustments } from "../../../../../../../../services/ProjectionAdjustment/ProjectionAdjustment.service";
import { CarouselContextProvider } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { useProjectionSearchParams } from "../hooks/useProjectionSearchParams";
import { LoadingBackdrop } from "../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface FormikOnSubmitProps {
  values: ProjectionFormValues;
  formikHelpers: FormikHelpers<ProjectionFormValues>;
}

const ProjectionFormWithinForwardRef = (
  { onSubmit }: CommonProjectionFormProps,
  ref: ForwardedRef<CommonProjectionFormRef>
) => {
  const queryClient = useQueryClient();
  const { initialValues, validationSchema } = useFormikConfig();

  const { projectionAdjustmentInitialValues } = useContext(
    ProjectionAdjustmentContext
  );

  const { customerTrigram, clientAccount, year, idExpenseGroup } =
    useProjectionSearchParams();

  const formikRef = useRef<FormikProps<ProjectionFormValues>>(null);

  useImperativeHandle(ref, () => ({
    setFormValues: (values) => formikRef.current?.setValues(values),
    isSubmitting: formikRef.current?.isSubmitting || false,
  }));

  const formikOnSubmit = async ({
    values,
    formikHelpers,
  }: FormikOnSubmitProps) => {
    formikHelpers.setSubmitting(true);

    try {
      const adjustmentUpdated: ProjectionAdjustmentListUpdateDTO[] = [];
      if (values.adjustmentLines) {
        values.adjustmentLines.forEach((line) => {
          const yearMonthValues = [
            line.january,
            line.february,
            line.march,
            line.april,
            line.may,
            line.june,
            line.july,
            line.august,
            line.september,
            line.october,
            line.november,
            line.december,
          ];

          yearMonthValues.forEach((yearMonthValue) => {
            const numberValue = parseLocaleNumber(yearMonthValue.value);
            if (numberValue && numberValue !== 0) {
              adjustmentUpdated.push({
                id: yearMonthValue.id,
                comment: line.comment,
                accountCode: line.accountCode === "" ? null : line.accountCode,
                clientAccount:
                  line.clientAccount === "" ? null : line.clientAccount,
                customerTrigram:
                  line.customerTrigram === "" ? null : line.customerTrigram,
                idCostCenter: line.costCenter?.id ?? 0,
                idDivision:
                  projectionAdjustmentInitialValues?.division?.id ?? 0,
                idExpenseGroup: line?.expenseType?.expenseGroup.id ?? 0,
                idExpenseType: line?.expenseType?.id ?? 0,
                idGroupSegment:
                  projectionAdjustmentInitialValues?.segmentGroup.id ?? 0,
                idSubsidiary:
                  projectionAdjustmentInitialValues?.subsidiary.id ?? 0,
                value: numberValue,
                yearMonth: YearMonth.createFromDate(yearMonthValue.yearMonth),
              });
            }
          });
        });
      }

      const updatedData: ProjectionAdjustmentUpdateDTO = {
        idSubsidiary: projectionAdjustmentInitialValues?.subsidiary.id ?? 0,
        idDivision: projectionAdjustmentInitialValues?.division.id ?? 0,
        idGroupSegment: projectionAdjustmentInitialValues?.segmentGroup.id ?? 0,
        customerTrigram: customerTrigram !== "" ? customerTrigram : null,
        clientAccount: clientAccount !== "" ? clientAccount : null,
        idExpenseGroup: idExpenseGroup ?? 0,
        year: year?.toString() ?? "",
        projectionAdjustmentListUpdate: adjustmentUpdated,
      };

      await updateProjectionAdjustments(updatedData);
      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projection.errors.data.edit.failedToEditProjeção",
      });
    } finally {
      formikHelpers.setSubmitting(false);
    }
  };

  const { mutateAsync: addTodoMutation, isPending } = useMutation<
    void,
    void,
    FormikOnSubmitProps
  >({
    mutationFn: formikOnSubmit,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projectionAdjustmentData"] });
    },
  });

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={async (values, formikHelpers) =>
          addTodoMutation({
            values,
            formikHelpers,
          })
        }
        validationSchema={validationSchema}
      >
        {(formikProps) => {
          return (
            <CarouselContextProvider
              firstYearMonth={YearMonth.createFromNow().month}
              initialEarliestYearMonth={new YearMonth(0, year ?? 0)}
              initialLatestYearMonth={new YearMonth(11, year ?? 0)}
            >
              <ProjectionFormWIthInContext formikProps={formikProps} />
            </CarouselContextProvider>
          );
        }}
      </Formik>
      <LoadingBackdrop open={isPending} />
    </>
  );
};

export const ProjectionFormWithinContext = forwardRef<
  CommonProjectionFormRef,
  CommonProjectionFormProps
>(ProjectionFormWithinForwardRef);
