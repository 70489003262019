import { riseApi } from "./connection";
import {
  ProjectionAdjustmentMonthlyCommentDto,
  schemaProjectionAdjustmentMonthlyCommentDto,
} from "../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentDto";
import { ProjectionAdjustmentMonthlyCommentCreateDto } from "../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentCreateDto";
import { ProjectionAdjustmentMonthlyCommentUpdateDto } from "../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentUpdateDto";

export const createMonthlyComment = async (
  monthlyComment: ProjectionAdjustmentMonthlyCommentCreateDto
) => {
  await riseApi.post("/ProjectionAdjustmentMonthlyComment", monthlyComment);
};

export const updateMonthlyComment = async (
  monthlyComment: ProjectionAdjustmentMonthlyCommentUpdateDto
) => {
  await riseApi.put("/ProjectionAdjustmentMonthlyComment", monthlyComment);
};

export const deleteMonthlyComment = async (id: number, version: string) => {
  await riseApi.delete(`/ProjectionAdjustmentMonthlyComment/${id}/${version}`);
};

export const getMonthlyCommentById = async (
  id: number
): Promise<ProjectionAdjustmentMonthlyCommentDto | null> => {
  const { data: monthlyComment } = await riseApi.get(
    `/ProjectionAdjustmentMonthlyComment/${id}`
  );

  return schemaProjectionAdjustmentMonthlyCommentDto
    .nullable()
    .validateSync(monthlyComment);
};
