import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";
import { TextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { CheckboxFormik } from "../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { HorizontalAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/horizontal/HorizontalAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const DisciplinesFilterForm = ({
  formikProps: { values },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TextFieldFormik
        name="name"
        label={t("general.keywords.fields.name")}
        fullWidth
      />

      <HorizontalAutocompleteFormik name="horizontal" blockGeneral={false} />

      <FormControlLabel
        label={t("general.keywords.fields.onlyActivated")}
        control={<CheckboxFormik name="onlyActivated" />}
      />
    </StyledForm>
  );
};
