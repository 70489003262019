import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { BaseCollaboratorReportScopeTypeEnum } from "../../../../../../../../../../shared/specific/enums/BaseCollaboratorReportScopeType/ReportScopeType.enum";
import { BaseCollaboratorReportScopeTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/baseCollaboratorReportScopeType/baseCollaboratorReportScopeType.maps";
import { AutocompleteOption } from "../../../../../../../../../../shared/common/types/data/form/autocomplete.types";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<BaseCollaboratorReportScopeTypeEnum>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
}

export const BaseCollaboratorReportScopeTypeFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return Object.values(BaseCollaboratorReportScopeTypeEnum)
      .filter((x) => /^\d+$/.test(String(x)))
      .map(
        (x): AutocompleteOption<BaseCollaboratorReportScopeTypeEnum> => ({
          id: x as BaseCollaboratorReportScopeTypeEnum,
          label: t(
            BaseCollaboratorReportScopeTypeToTranslationCodeMap[
              x as BaseCollaboratorReportScopeTypeEnum
            ]
          ),
        })
      );
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: `${t("reports.pages.fields.reportScopeType")}`,
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
    />
  );
};
