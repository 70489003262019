import {
  RevenueInvoicesDTO,
  schemaRevenueInvoicesDTO,
} from "../../../shared/specific/DTOs/revenueInvoice/RevenueInvoicesDTO";
import { riseApi } from "./connection";

export const getRevenueInvoices = async (
  idProject: number
): Promise<RevenueInvoicesDTO> => {
  const { data: revenueInvoices } = await riseApi.get(
    `RevenueInvoices/${idProject}`
  );

  return schemaRevenueInvoicesDTO.validateSync(revenueInvoices);
};
