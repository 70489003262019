import { useField } from "formik";
import { useMemo } from "react";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import {
  SharedAllocationYearMonthTotalCell,
  SharedAllocationYearMonthTotalCellProps,
} from "../../../../../../../../../shared/specific/react/components/form/readOnly/textField/fields/SharedAllocationYearMonthTotalCell";
import { AllocationCreationYearMonthsFormValues } from "../../shared/types/data/form/values.types";

interface OwnProps {
  yearMonth: YearMonth;
}

export const AllocationYearMonthTotalCell = ({ yearMonth }: OwnProps) => {
  const [{ value: allocationYearMonths }] = useField<
    AllocationCreationYearMonthsFormValues[]
  >("allocationYearMonths");

  const allocationYearMonth = useMemo(() => {
    const allocationYearMonth = allocationYearMonths.find((x) =>
      x.yearMonth.equals(yearMonth)
    );

    if (!allocationYearMonth) return null;
    return allocationYearMonth;
  }, [allocationYearMonths, yearMonth]);

  const weeks =
    useMemo((): SharedAllocationYearMonthTotalCellProps["weeks"] => {
      if (!allocationYearMonth) return null;
      return {
        week1Percentage: allocationYearMonth.week1Percentage,
        week2Percentage: allocationYearMonth.week2Percentage,
        week3Percentage: allocationYearMonth.week3Percentage,
        week4Percentage: allocationYearMonth.week4Percentage,
        week5Percentage: allocationYearMonth.week5Percentage,
      };
    }, [
      allocationYearMonth?.week1Percentage,
      allocationYearMonth?.week2Percentage,
      allocationYearMonth?.week3Percentage,
      allocationYearMonth?.week4Percentage,
      allocationYearMonth?.week5Percentage,
    ]);

  return (
    <SharedAllocationYearMonthTotalCell weeks={weeks} yearMonth={yearMonth} />
  );
};
