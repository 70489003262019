import { useTranslation } from "react-i18next";
import { US as USIcon, BR as BRIcon } from "country-flag-icons/react/3x2";
import { LanguageButtonContainer } from "./index.styles";
import { changePersistLanguage } from "../../../../../services/applicationState/language.service";
import { Language } from "../../../../../shared/common/enums/locale/Language.enum";

export const LanguageButton = () => {
  const { i18n } = useTranslation();

  const countryFlag = i18n.language === Language.ptBR ? <BRIcon /> : <USIcon />;

  const languageAcronyn = i18n.language === Language.ptBR ? "PT" : "EN";

  const toggleLanguage = () => {
    const newLanguage =
      i18n.language === Language.ptBR ? Language.enUS : Language.ptBR;
    changePersistLanguage(newLanguage);
  };

  return (
    <LanguageButtonContainer startIcon={countryFlag} onClick={toggleLanguage}>
      <div>{languageAcronyn}</div>
    </LanguageButtonContainer>
  );
};
