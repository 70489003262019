import lodash from "lodash";
import {
  FormControl,
  FormControlProps,
  InputLabel,
  InputLabelProps,
  MenuItem,
  MenuItemProps as MuiMenuItemProps,
  Select,
  SelectProps,
} from "@mui/material";
import { ReactNode, useMemo } from "react";

type MenuItem = Omit<MuiMenuItemProps, "children"> & {
  label?: ReactNode;
};

interface OwnProps {
  label?: ReactNode;
  className?: string;
  formControlProps?: Omit<FormControlProps, "children" | "id">;
  selectProps?: Omit<SelectProps, "labelID" | "label">;
  inputLabelProps?: Omit<InputLabelProps, "children">;
  menuItems?: MenuItem[];
}

export const SelectField = ({
  className,
  label,
  formControlProps,
  selectProps,
  inputLabelProps,
  menuItems = [],
}: OwnProps) => {
  const htmlId = useMemo(() => {
    return lodash.uniqueId("SelectField_");
  }, []);

  return (
    <FormControl className={className} {...formControlProps}>
      {label && (
        <InputLabel {...inputLabelProps} id={htmlId}>
          {label}
        </InputLabel>
      )}
      <Select {...selectProps} labelId={htmlId} label={label}>
        {menuItems.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem}>
            {menuItem.label ?? menuItem.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
