import { Box } from "@mui/material";
import { FormikProps } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { GenerateReportButton } from "../../shared/components/GenerateReportButton";
import { StyledFormContainer, StyledViewContent } from "../index.styles";
import { FilterType, FormValuesProvisionReport } from "../types/form.types";
import { FilterTypeAutocompleteFormik } from "./FilterTypeAutocompleteFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";

type OwnProps = FormikProps<FormValuesProvisionReport>;

export const FormFilter = ({
  submitForm,
  isSubmitting,
  values,
  setFieldValue,
}: OwnProps) => {
  const { t } = useTranslation();

  const showPeriods = useMemo(() => {
    if (values?.filterType) return true;
    return false;
  }, [values?.filterType]);

  const showUN = useMemo(() => {
    if (values?.filterType && values.filterType.id === FilterType.division)
      return true;
    return false;
  }, [values?.filterType]);

  const showSubsidiary = useMemo(() => {
    if (
      values?.filterType &&
      (values.filterType.id === FilterType.all ||
        values.filterType.id === FilterType.division)
    )
      return true;
    return false;
  }, [values?.filterType]);

  const dateAfter = useMemo(() => {
    let maxMonths = 0;
    if (values.filterType?.id === FilterType.projects) {
      return undefined;
    }
    if (values.filterType?.id === FilterType.division) {
      maxMonths = 4;
    }
    if (values.filterType?.id === FilterType.all) {
      maxMonths = 1;
    }
    if (values?.startYearMonth) {
      const date = new Date(values.startYearMonth);
      date.setMonth(date.getMonth() + maxMonths);
      return date;
    }
    return undefined;
  }, [values?.startYearMonth]);

  const showProject = useMemo(() => {
    if (values?.filterType && values.filterType.id === FilterType.projects)
      return true;
    return false;
  }, [values?.filterType]);

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("division", null);
      setFieldValue("projects", []);
      setFieldValue("startYearMonth", null);
      setFieldValue("endYearMonth", null);
      setFieldValue("subsidiary", null);
    },
    deps: [values?.filterType],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("endYearMonth", null);
    },
    deps: [values?.startYearMonth],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledViewContent>
      <StyledFormContainer>
        <FilterTypeAutocompleteFormik
          name="filterType"
          textfieldProps={{
            label: t("reports.pages.fields.reportScopeType"),
          }}
        />
        {showPeriods ? (
          <YearMonthDatePickerFormik
            name="startYearMonth"
            datePickerProps={{
              label: t("reports.pages.form.startPeriod"),
              slotProps: {
                popper: {
                  placement: "right",
                },
              },
            }}
            textFieldProps={{
              required: !showProject,
            }}
          />
        ) : (
          ""
        )}
        {showPeriods ? (
          <YearMonthDatePickerFormik
            name="endYearMonth"
            datePickerProps={{
              label: t("reports.pages.form.endPeriod"),
              slotProps: {
                popper: {
                  placement: "right",
                },
              },
              minDate: values?.startYearMonth
                ? new Date(values.startYearMonth)
                : undefined,
              maxDate: dateAfter,
            }}
            textFieldProps={{
              required: !showProject,
            }}
          />
        ) : (
          ""
        )}
        {showUN ? (
          <DivisionsAutocompleteFormik
            name="division"
            textfieldProps={{
              required: true,
            }}
          />
        ) : (
          ""
        )}
        {showSubsidiary ? (
          <SubsidiariesAutocompleteFormik
            name="subsidiary"
            textfieldProps={{
              required: false,
            }}
          />
        ) : (
          ""
        )}
        {showProject ? (
          <ProjectSearchAutocompleteFormik
            name="projects"
            autocompleteProps={{
              multiple: true,
              renderOption: (props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              ),
            }}
            textfieldProps={{
              label: `${t("projects.keywords.fields.project")}`,
              required: true,
            }}
          />
        ) : (
          ""
        )}
      </StyledFormContainer>
      <GenerateReportButton
        onClick={submitForm}
        isSubmitting={isSubmitting}
        isDisabled={!values.filterType}
      />
    </StyledViewContent>
  );
};
