import * as yup from "yup";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";

export interface OfficeDTO {
  id: number;
  version: string;
  name: string;
  idSubsidiary: number;
  subsidiary: SubsidiaryDTO | null;
}

export const schemaOfficeDTO = yup.object({
  id: yup.number().integer().defined(),
  version: yup.string().defined(),
  name: yup.string().defined(),
  idSubsidiary: yup.number().integer().defined(),
  subsidiary: schemaSubsidiaryDTO.nullable(),
});
