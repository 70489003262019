import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CostCenterDTO } from "../../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { SharedFormComponents } from "../../../shared/react/components/content/SharedFormComponents";
import { FormValues } from "../shared/types/form.types";
import { StyledEditingForm } from "./index.styles";
import { DatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { useMaxDeactivatedDate } from "../shared/hooks/data/validation/useMaxDeactivatedDate";

type OwnProps = FormContentProps<FormValues, CostCenterDTO>;

export const EditingForm = ({
  formikProps: { isSubmitting },
  internalFormData: costCenterDTO,
}: OwnProps) => {
  const { t } = useTranslation();

  const maxDeactivatedDate = useMaxDeactivatedDate();

  return (
    <StyledEditingForm>
      <SharedFormComponents
        isSubmitting={isSubmitting}
        isLinkedToProject={costCenterDTO?.isLinkedToProject}
      />
      <DatePickerFormik
        name="deactivatedDate"
        datePickerProps={{
          label: t("general.keywords.fields.deactivatedAt"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          disabled: isSubmitting,
          maxDate: maxDeactivatedDate,
        }}
      />
    </StyledEditingForm>
  );
};
