import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { useField } from "formik";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { getCostCenters } from "../../../../../../../../../services/costCenter/costCenter.service";
import { CostCenterFiltersDTO } from "../../../../../../../DTOs/costCenter/filters/CostCenterFiltersDTO";
import { CostCenterOnlyDTO } from "../../../../../../../DTOs/costCenter/CostCenterOnlyDTO";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";
import { CostCenterDTO } from "../../../../../../../DTOs/costCenter/CostCenterDTO";

export interface CostCentersSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    CostCenterDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<CostCenterFiltersDTO, "code">;
  showCostCenterName?: boolean;
}

export const CostCentersSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  showCostCenterName = true,
}: CostCentersSearchAutocompleteFormikProps<DisableClearable>) => {
  const [{ value: costCenter }] = useField<CostCenterOnlyDTO | null>(name);
  const { t } = useTranslation();

  const getCostCentersFinal = useCallback(
    async (code: string) => {
      const paginatedCostCenters = await getCostCenters({
        filters: { code, ...filters },
        pagination: { pageSize: 100 },
      });

      return paginatedCostCenters.tableData;
    },
    [filters]
  );

  const endAdornment = useMemo(() => {
    if (!costCenter?.name) return undefined;
    return (
      <Tooltip title={costCenter.name} placement="top">
        <AutocompleteInfoBubble>{costCenter.name}</AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [costCenter]);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenters.errors.data.fetch.failedToFetchCostCenters"
      )}
      fetchOptions={getCostCentersFinal}
      textfieldProps={{
        label: t("costCenters.keywords.general.costCenter"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          endAdornment: showCostCenterName ? endAdornment : undefined,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.code,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.code} - {option.name}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
