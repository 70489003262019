import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SegmentDTO } from "../../../../../../../DTOs/Segment/SegmentDTO";
import { SegmentFiltersDTO } from "../../../../../../../DTOs/Segment/filters/SegmentFiltersDTO";
import { getSegments } from "../../../../../../../../../services/segment/segment.service";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SegmentDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: SegmentFiltersDTO;
  filtersMemo?: SegmentFiltersDTO;
  includeAllDivisionsOption?: boolean;
}

export const SegmentsAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
  includeAllDivisionsOption = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters?.idDivision]);

  const internalGetSegments = useCallback(async () => {
    if (!includeAllDivisionsOption && !filters?.idDivision) {
      return [];
    }
    return getSegments({
      filters: finalFilters,
    });
  }, [finalFilters?.idDivision]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "projection.errors.data.fetch.failedToFetchSegmento"
      )}
      fetchOptions={internalGetSegments}
      textfieldProps={{
        label: t("projection.keywords.fields.segment"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => {
          const segment =
            x.segmentTranslation.find((y) => y.idLanguage === i18n.language)
              ?.name ?? x.segmentName;

          const division = x.division.name;

          return finalFilters?.divisionHasPrefix
            ? segment
            : `${segment} - ${division}`;
        },
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
