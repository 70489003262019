import { ElementRef, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { TabContext, TabList } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { checkIsValidType } from "../../../../../shared/common/helpers/data/validation/schema.helpers";
import { TabCode, schemaTabCode } from "./shared/types/TabCode.enums";
import { useTabs } from "./hooks/useTabs";
import { useTabPanels } from "./hooks/useTabPanels";
import { StyledPlanningTabs, StyledTabs } from "./shared/styles/general.styles";
import { StyledTabContent } from "./shared/styles/StyledTabContent.styles";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { getFrameById } from "../../../../../services/frames/frames.service";
import { FrameOnlyDTO } from "../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import { CenteredLoading } from "../../../../../shared/common/react/components/ui/CenteredLoading";
import { MainHeader } from "./shared/components/MainHeader";

type OwnParams = {
  id: string;
};

export const FramesViewPage = () => {
  const { i18n } = useTranslation();

  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  const tabs = useTabs();
  const tabPanels = useTabPanels();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(() => {
    if (checkIsValidType<TabCode>(schemaTabCode, searchParams.get("tab")))
      return searchParams.get("tab") as TabCode;
    return tabs[0].key as TabCode;
  });
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [frameData, setFrameData] = useState<FrameOnlyDTO | null>(null);

  const tabListRef = useRef<ElementRef<typeof TabList>>(null);

  useEffect(() => {
    searchParams.set("tab", tabValue);
    setSearchParams(searchParams, {
      replace: true,
    });
  }, [tabValue]);

  useEffect(() => {
    const fetchSetProjects = async () => {
      setLoading(true);

      if (!id) {
        setError("Invalid ID.");
        setLoading(false);
        return;
      }

      try {
        const frame = await getFrameById(id);
        if (!frame) {
          setError("Project does not exist.");
          return;
        }

        setPageTitle(frame.name);

        setFrameData(frame);
      } catch (error) {
        // Deal with error
        console.error(error);
        setError("Something happened. :(");
      } finally {
        setLoading(false);
      }
    };

    fetchSetProjects();
  }, [i18n.language]);

  if (loading) {
    return <CenteredLoading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <TabContext value={tabValue}>
      <StyledPlanningTabs>
        <StyledTabs>
          <div
            style={{
              width: "100%",
            }}
          >
            {frameData && <MainHeader frame={frameData} />}
          </div>
          <TabList
            ref={tabListRef}
            defaultValue={tabValue}
            onChange={(_, newValue) => setTabValue(newValue)}
          >
            {tabs}
          </TabList>
        </StyledTabs>
        <StyledTabContent stickyHeight={tabListRef.current?.offsetHeight}>
          {tabPanels}
        </StyledTabContent>
      </StyledPlanningTabs>
    </TabContext>
  );
};
