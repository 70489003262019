import i18next from "i18next";
import * as yup from "yup";
import { DateOnly } from "../../../../common/classes/data/date/DateOnly";
import { YearMonth } from "../../../../common/classes/data/date/YearMonth";
import { checkIsValidType } from "../../../../common/helpers/data/validation/schema.helpers";
import {
  AllocationPercentageYearMonth,
  schemaAllocationPercentageYearMonth,
} from "../../../DTOs/allocation/AllocationPercentageYearMonth";
import { ApiErrorDTO } from "../../../DTOs/errors/ApiErrorDTO";
import { TranslationObject } from "../../../types/translations/general.types";
import { Months, monthsTranslationCodeMap } from "../months/months.maps";

export const sharedCollaboratorHistoryNoHistoryForPeriod = (
  apiError: ApiErrorDTO
): TranslationObject => {
  if (typeof apiError.details.firstStartYearMonth !== "string")
    throw new Error(
      "Unexpected value returned from the API for 'firstStartYearMonth'."
    );

  const firstStartYearMonth = YearMonth.createFromString(
    apiError.details.firstStartYearMonth
  );
  const resignationDate =
    typeof apiError.details.resignationDate === "string"
      ? DateOnly.createFromString(apiError.details.resignationDate)
      : null;

  if (!resignationDate)
    return {
      code: "allocations.errors.data.fields.userColaborator.canOnlyBeAllocatedInPeriodFromStart",
      details: {
        earliestPossibleYearMonth: firstStartYearMonth.toPrettyString(),
      },
    };

  if (firstStartYearMonth.toDate() > resignationDate.toDate())
    return {
      code: "allocations.errors.data.fields.colaboratorHistory.firstJobRoleAssignedAfterResignation",
    };

  return {
    code: "allocations.errors.data.fields.userColaborator.canOnlyBeAllocatedInPeriodFromTo",
    details: {
      earliestPossibleYearMonth: firstStartYearMonth.toPrettyString(),
      latestPossibleYearMonth: YearMonth.createFromDate(
        resignationDate.toDate()
      ).toPrettyString(),
    },
  };
};

export const sharedCollaboratorCannotAllocateAfterResignation = (
  apiError: ApiErrorDTO,
  translationCode: string
): TranslationObject => {
  if (typeof apiError.details.resignationDate !== "string")
    throw new Error(
      "Unexpected value returned from the API for 'resignationDate'."
    );

  const resignationDateOnly = DateOnly.createFromString(
    apiError.details.resignationDate
  );

  const resignationDate =
    resignationDateOnly.toDate().toLocaleDateString(i18next.language) ?? "-";

  return {
    code: translationCode,
    details: { resignationDate },
  };
};

export const sharedPercentageWouldBecomeGreaterThanMonthlyLimit = (
  apiError: ApiErrorDTO
): TranslationObject => {
  if (
    !checkIsValidType<AllocationPercentageYearMonth>(
      schemaAllocationPercentageYearMonth,
      apiError.details.newPercentageWithWeeks
    )
  )
    return {
      code: "shared.errors.data.allocations.write.percentageWouldBecomeGreaterThanMonthlyLimit",
    };

  const { newPercentageWithWeeks } = apiError.details;

  const monthNumber: Months =
    Number(newPercentageWithWeeks.yearMonth.toString().split("-")[1]) - 1;

  return {
    code: "shared.errors.data.allocations.write.percentageWouldBecomeGreaterThanMonthlyLimitValues",
    details: {
      percentage: newPercentageWithWeeks.percentage,
      month: monthsTranslationCodeMap[monthNumber],
      week1Percentage: newPercentageWithWeeks.week1Percentage,
      week2Percentage: newPercentageWithWeeks.week2Percentage,
      week3Percentage: newPercentageWithWeeks.week3Percentage,
      week4Percentage: newPercentageWithWeeks.week4Percentage,
      week5Percentage: newPercentageWithWeeks.week5Percentage
        ? `${newPercentageWithWeeks.week5Percentage}%`
        : "-",
    },
    components: { paragraph: <div /> },
  };
};

export const sharedMustHaveSomeActivityInTheSamePeriodOfAllocation = (
  apiError: ApiErrorDTO
): TranslationObject => {
  if (
    !checkIsValidType<string>(
      yup.string().defined(),
      apiError.details.yearMonth
    )
  )
    return {
      code: "shared.errors.data.allocations.write.mustHaveSomeActivityInTheSamePeriodOfAllocation",
    };

  const { yearMonth } = apiError.details;

  return {
    code: "shared.errors.data.allocations.write.mustHaveSomeActivityInTheSamePeriodOfAllocationValues",
    details: {
      yearMonth,
    },
    components: { paragraph: <div /> },
  };
};

export const sharedActivitiesCannotDeleteWhenHasAllocationInThisPeriod = (
  apiError: ApiErrorDTO
): TranslationObject => {
  if (
    !checkIsValidType<string>(
      yup.string().defined(),
      apiError.details.yearMonth
    )
  )
    return {
      code: "activities.errors.data.delete.activitiesCannotDeleteWhenHasAllocationInThisPeriod",
    };

  const { yearMonth } = apiError.details;

  return {
    code: "activities.errors.data.delete.activitiesCannotDeleteWhenHasAllocationInThisPeriodValues",
    details: {
      yearMonth,
    },
    components: { paragraph: <div /> },
  };
};
