import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { CenteredLoading } from "../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { FramePepDTO } from "../../../../../../../shared/specific/DTOs/frame/FramePepDTO";
import { getFramePepById } from "../../../../../../../services/frames/frames.service";
import { useTableData } from "./hooks/useTableData";
import { CollapseTable } from "../../../../../../../shared/common/react/components/table/CollapseTable";
import { CollapseTableRef } from "../../../../../../../shared/common/react/components/table/CollapseTable/shared/types/propsRef.types";
import {
  StyledGridContainer,
  StyledMainContainer,
  StyledTotalContainer,
} from "./index.styles";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

type OwnParams = {
  id: string;
};

export const ProjectsTab = () => {
  const { i18n } = useTranslation();

  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  const collapseTableRef = useRef<CollapseTableRef>(null);

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [framePepData, setFramePepData] = useState<FramePepDTO | null>(null);

  const { onPageChange } = useTableData({
    framePepData,
  });

  useEffect(() => {
    const fetchSetProjects = async () => {
      setLoading(true);

      if (!id) {
        setError("Invalid ID.");
        setLoading(false);
        return;
      }

      try {
        const frame = await getFramePepById(id);
        if (!frame) {
          setError("Frame does not exist.");
          return;
        }

        setFramePepData(frame);
      } catch (error) {
        // Deal with error
        console.error(error);
        setError("Something happened. :(");
      } finally {
        setLoading(false);
      }
    };

    fetchSetProjects();
  }, [i18n.language]);

  if (loading) {
    return <CenteredLoading />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <StyledMainContainer>
      <StyledGridContainer>
        <CollapseTable
          singlePageLoader={{
            fillPage: onPageChange,
          }}
          ref={collapseTableRef}
        />
      </StyledGridContainer>
      <StyledTotalContainer>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: "bold",
          }}
        >
          Valor Total:{" "}
          {formatNumber(framePepData?.total ?? 0, {
            fractionDigits: 2,
            prefix: framePepData?.subsidiary.currencySymbol ?? "",
          })}
        </Typography>
      </StyledTotalContainer>
    </StyledMainContainer>
  );
};
