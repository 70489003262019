import { useEffect, useMemo, useRef } from "react";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../../shared/types/form.types";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { BrlParityFiltersDTO } from "../../../../../../../shared/specific/DTOs/brlParity/filters/BrlParityFiltersDTO";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import { BrlParityFilterForm } from "./BrlParityFilterForm";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      currency: null,
      startYearMonth: null,
      endYearMonth: null,
    };

    const validationSchema = yup.object({
      currency: yup.string().nullable(),
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<BrlParityFiltersDTO>({
          startYearMonth: formValues.startYearMonth
            ? YearMonth.createFromDate(formValues.startYearMonth)
            : null,
          endYearMonth: formValues.endYearMonth
            ? YearMonth.createFromDate(formValues.endYearMonth)
            : null,
          currency: formValues.currency || undefined,
        }),
      FormContentMemo: BrlParityFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();
        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
