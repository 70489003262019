import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { getFeeYearMonths } from "../../../../../../../services/feeYearMonth/feeYarMonth.services";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { FeeYearMonthFiltersDTO } from "../../../../../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthFiltersDTO";

interface OwProps {
  reloadTablePage: () => void;
  filters: FeeYearMonthFiltersDTO;
}

export const useTableData = ({ reloadTablePage, filters }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("jobRates.keywords.general.startYearMonth"),
      t("Fee"),
      t("costCenters.keywords.general.costCenter"),
      t("allocations.keywords.employeeAllocationType.allocation"),
      t("general.keywords.fields.value"),
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onFeePageChange: ExternalEnhancedTableExternalPageChanger = useCallback(
    async ({ newPage, order, orderBy, rowsPerPage }) => {
      const headKeys = [
        "yearMonth",
        "fee.description",
        "fee.costCenter.code",
        "useFullAllocation",
        "value",
      ];

      try {
        const {
          tableData: feeData,
          page,
          totalCount,
        } = await getFeeYearMonths({
          filters,
          pagination: {
            pageSize: rowsPerPage,
            page: newPage + 1,
            columnOrderByDir: orderBy !== null ? order : undefined,
            columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
          },
        });

        const rows = feeData.map(
          (feeYearMonth): ExternalEnhancedRow => ({
            id: feeYearMonth.id,
            cells: [
              {
                value: feeYearMonth.yearMonth.toDate(),
                displayValue: feeYearMonth.yearMonth.toPrettyString(),
              },
              feeYearMonth.fee.description,
              feeYearMonth.fee.costCenter.code,
              feeYearMonth.useFullAllocation
                ? t("fee.keywords.fields.finalAllocation")
                : t("allocations.keywords.employeeAllocationType.allocation"),
              {
                value: feeYearMonth.value,
                displayValue: formatNumber(feeYearMonth.value, {
                  prefix: feeYearMonth.fee.subsidiary.currencySymbol,
                  fractionDigits: 2,
                }),
              },
              {
                CellRenderer: () => (
                  <>
                    <Protected
                      restrictions={{
                        type: PermissionType.FeePeriod,
                        level: PermissionLevel.Update,
                      }}
                    >
                      <EditingButtonContainer {...feeYearMonth} />
                    </Protected>
                    <Protected
                      restrictions={{
                        type: PermissionType.FeePeriod,
                        level: PermissionLevel.Delete,
                      }}
                    >
                      <DeleteButtonContainer {...feeYearMonth} />
                    </Protected>
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ],
          })
        );
        return {
          rows,
          page: page - 1,
          totalItems: totalCount,
        };
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    },
    [i18n, filters]
  );

  return {
    headCells,
    onFeePageChange,
    editingModal,
    deletionModal,
  };
};
