import { useContext, useMemo } from "react";
import { Button } from "@mui/material";
import { Replay } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CenteredContent } from "../../../ui/CenteredContent";
import { CenteredLoading } from "../../../ui/CenteredLoading";
import { CenteredTableContentContainer } from "./styles/CenteredTableContentContainer.styles";
import { TableContext } from "../shared/contexts/TableContext";
import { defaults } from "../shared/constants/defaults";

export const EnhancedTableMessage = () => {
  const {
    state: { error, info, loading },
    ui: {
      fixedcellheight,
      mincellheight,
      hasDynamicTableHeight,
      minMessageHeight,
    },
    pagination: { page, rowsPerPage, reloadPage, usesExternalDataFetching } = {
      page: null,
      rowsPerPage: defaults.pagination.defaultRowsPerPage,
      reloadPage: () => null,
      usesExternalDataFetching: false,
    },
    rows,
    messages: { noRowsMessage },
  } = useContext(TableContext);

  const { t } = useTranslation();

  const finalMinMessageHeight = useMemo(() => {
    if (minMessageHeight !== "auto") return minMessageHeight;

    return mincellheight * rowsPerPage;
  }, [fixedcellheight, rowsPerPage, mincellheight]);

  return useMemo(() => {
    if (error !== false)
      return (
        <CenteredTableContentContainer
          hasDynamicTableHeight={hasDynamicTableHeight}
          minMessageHeight={finalMinMessageHeight}
        >
          <CenteredContent>
            {typeof error === "string" ? t(error) : ""}
            {usesExternalDataFetching && (
              <Button variant="text" endIcon={<Replay />} onClick={reloadPage}>
                {t("general.actions.general.reload")}
              </Button>
            )}
          </CenteredContent>
        </CenteredTableContentContainer>
      );

    if (info !== false)
      return (
        <CenteredTableContentContainer
          hasDynamicTableHeight={hasDynamicTableHeight}
          minMessageHeight={finalMinMessageHeight}
        >
          <CenteredContent>
            {typeof info === "string" ? t(info) : ""}
          </CenteredContent>
        </CenteredTableContentContainer>
      );

    if (loading !== false)
      return (
        <CenteredTableContentContainer
          hasDynamicTableHeight={hasDynamicTableHeight}
          minMessageHeight={finalMinMessageHeight}
        >
          <CenteredLoading>
            {typeof loading === "string" && loading}
          </CenteredLoading>
        </CenteredTableContentContainer>
      );

    if (rows?.length === 0)
      return (
        <CenteredTableContentContainer
          hasDynamicTableHeight={hasDynamicTableHeight}
          minMessageHeight={finalMinMessageHeight}
        >
          <CenteredContent>
            {noRowsMessage ??
              t("general.info.data.general.noResultsFoundSearch")}
          </CenteredContent>
        </CenteredTableContentContainer>
      );

    return null;
  }, [t, error, info, loading, rows?.length, page]);
};
