import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../../shared/responses/FilterResponseDTO";

export type PaginatedStringDTO = PaginationResponse<string>;

export const schemaPaginatedStringDTO = getSchemaPaginationResponse<string>(
  yup.string().defined()
);
