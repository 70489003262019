import { Paid as PaidIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SimpleIconButton } from "../../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useModalButton } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { FormContext } from "../../../shared/contexts/FormContext";

export const usePepLeavingConfirmationModal = () => {
  const { t } = useTranslation();

  const {
    contentButton: pepLeavingConfirmationButton,
    contentModal: pepLeavingConfirmationModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      mode: "dialog",
      modalTitle: t("projects.actions.navigation.goToProjectPeps"),
      modalContent: t("projects.info.data.edit.wouldLikeSaveProjectChanges"),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { projectData, submitManually, isSubmitting } =
          useContext(FormContext);

        const onDoNotSaveChanges = () => {
          if (!projectData)
            throw new Error("projectData cannot be null on this stage.");

          navigate(`/projects/${projectData.id}/peps`);
        };

        const onSaveChanges = async () => {
          if (!projectData)
            throw new Error("projectData cannot be null on this stage.");

          await submitManually({
            redirectionLinkOnSuccess: `/projects/${projectData.id}/peps`,
          });
        };

        return (
          <>
            <Button onClick={closeModal}>
              {t("general.actions.general.cancel")}
            </Button>
            <Button
              onClick={onDoNotSaveChanges}
              color="error"
              variant="contained"
            >
              {t("general.actions.general.doNotSave")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={onSaveChanges}
              color="primary"
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            onClick={onClick}
            Icon={PaidIcon}
            color="primary"
            tooltipText={t("costCenterPeps.actions.general.managePeps")}
          />
        );
      },
    },
  });

  return {
    pepLeavingConfirmationButton,
    pepLeavingConfirmationModal,
  };
};
