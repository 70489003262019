import * as yup from "yup";

import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../../../shared/specific/DTOs/subsidiary/SubsidiaryDTO";
import { riseApi } from "./connection";

export const getSubsidiaries = async (): Promise<SubsidiaryDTO[]> => {
  const { data: subsidiaries } = await riseApi.get("Subsidiaries");

  return yup.array(schemaSubsidiaryDTO).defined().validateSync(subsidiaries);
};

export const getSubsidiaryById = async (
  id: number
): Promise<SubsidiaryDTO | null> => {
  const { data: subsidiary } = await riseApi.get(`Subsidiaries/${id}`);

  return schemaSubsidiaryDTO.nullable().validateSync(subsidiary);
};

export const getCurrency = async ({
  currencyToRemove,
}: {
  currencyToRemove?: string;
} = {}): Promise<string[]> => {
  const { data: listCurrency } = await riseApi.get(
    `subsidiaries/GetCurrency/${currencyToRemove}`
  );

  return listCurrency;
};
