import { Formik, FormikHelpers, FormikProps } from "formik";
import {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { CommonProjectAdditionalInformationFormValues } from "./shared/types/forms/general.types";
import { useFormikConfig } from "./hooks/useFormikConfig";
import { InternalFormikForm } from "./InternalFormikForm";
import {
  CommonProjectFormProps,
  CommonProjectFormRef,
} from "./shared/types/external/propsRef.types";
import { OnFormSubmitType } from "./shared/types/general/functions.types";
import {
  FormContextProvider,
  FormContextProviderRef,
} from "./shared/contexts/FormContext/FormContextProvider";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";

export type { CommonProjectFormProps, CommonProjectFormRef, OnFormSubmitType };

const CommonProjectFormWithinForwardRef = (
  { onSubmit = () => undefined, mode }: CommonProjectFormProps,
  ref: ForwardedRef<CommonProjectFormRef>
) => {
  const { initialValues, validationSchema } = useFormikConfig();

  const [projectData, setProjectData] =
    useState<ProjectAdditionalInformationDTO | null>(null);

  const contextRef = useRef<FormContextProviderRef>(null);
  const formikRef =
    useRef<FormikProps<CommonProjectAdditionalInformationFormValues>>(null);

  useImperativeHandle(ref, () => ({
    setFormValues: (values) => formikRef.current?.setValues(values),
    setLoading: (projectData) => contextRef.current?.setLoading(projectData),
    setError: (loading) => contextRef.current?.setError(loading),
    setProjectData: (error) => contextRef.current?.setProjectData(error),
  }));

  const formikOnSubmit = (
    values: CommonProjectAdditionalInformationFormValues,
    formikHelpers: FormikHelpers<CommonProjectAdditionalInformationFormValues>
  ) => {
    onSubmit(values, formikHelpers, { projectData });
  };

  const internalOnSubmit: OnFormSubmitType = (
    values: CommonProjectAdditionalInformationFormValues,
    formikHelpers: FormikHelpers<CommonProjectAdditionalInformationFormValues>,
    { redirectionLinkOnSuccess, projectData: providedProjectData }
  ) => {
    onSubmit(values, formikHelpers, {
      projectData: providedProjectData ?? projectData,
      redirectionLinkOnSuccess,
    });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => {
        return (
          <FormContextProvider
            ref={contextRef}
            mode={mode}
            formikProps={formikProps}
            onSubmit={internalOnSubmit}
            projectData={projectData}
            setProjectData={setProjectData}
          >
            <InternalFormikForm {...formikProps} />
          </FormContextProvider>
        );
      }}
    </Formik>
  );
};

export const CommonProjectForm = forwardRef<
  CommonProjectFormRef,
  CommonProjectFormProps
>(CommonProjectFormWithinForwardRef);
