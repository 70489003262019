import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormSectionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: 20px 16px;
  flex: 1;
  overflow: auto;
`;

export const StyledFormSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledProjectInfo = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a a b b b"
    "c c c d d d"
    "e e f f g g"
    "h h h h i i"
    "j j j j j j";

  ${createGridAreaLettersRange(10)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h"
      "i i"
      "j j";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "i"
      "j";
  }
`;

export const StyledProjectDatesBaseline = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "a a b b c d";
  ${createGridAreaLettersRange(4)}
`;

export const StyledControllership = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-areas:
    "a a a a b b b b c c c c"
    "d d d e e e f f f g g g";

  ${createGridAreaLettersRange(7)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;

export const SectionTitleWithButtons = styled("div")`
  display: flex;
  justify-content: space-between;
`;
