import { ReactNode } from "react";
import { FormikValues } from "formik";
import { FormContentContext } from "./FormContentContext";
import { FormContentContextValues } from "./index.types";

interface OwnProps<FormValues extends FormikValues, InternalFormValues> {
  value: FormContentContextValues<FormValues, InternalFormValues>;
  children: ReactNode;
}

export const FormContentContextProvider = <
  FormValues extends FormikValues,
  InternalFormValues,
>({
  value,
  children,
}: OwnProps<FormValues, InternalFormValues>) => {
  return (
    <FormContentContext.Provider value={value}>
      {children}
    </FormContentContext.Provider>
  );
};
