import { styled } from "@mui/material";

export const MainLayoutContainer = styled("div")`
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "a b"
    "a c";

  & > :nth-child(1) {
    grid-area: a;
  }
  & > :nth-child(2) {
    grid-area: b;
  }
  & > :nth-child(3) {
    grid-area: c;
  }

  & > :nth-child(2),
  & > :nth-child(3) {
    padding: 16px 20px;
    overflow: auto;
  }

  & > :nth-child(2) {
    padding-bottom: 8px;
  }
  & > :nth-child(3) {
    padding-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    & > :nth-child(2) {
      padding-left: 12px;
    }
  }
`;
