import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { authenticatorInstance } from "../../../core/microsoftAuthenticator";
import {
  graphScopes,
  apiScopes,
} from "../../../core/microsoftAuthenticator/config";

export const getApiAuthorizationData = async () => {
  return getAuthorizationDataFromScopes(apiScopes);
};

export const getGraphAuthorizationData = async () => {
  return getAuthorizationDataFromScopes(graphScopes);
};

export const getAuthorizationDataFromScopes = async (scopes: string[]) => {
  const account = getAccount();
  if (!account) throw new Error("No account found.");

  const tokenRequest = {
    scopes,
    account,
  };

  try {
    const authenticationData =
      await authenticatorInstance.acquireTokenSilent(tokenRequest);

    return authenticationData;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.error("Interactive token fetching is required. Redirecting...");
      await authenticatorInstance.acquireTokenRedirect(tokenRequest);
    }

    throw error;
  }
};

export const getAccount = () => {
  const accounts = authenticatorInstance.getAllAccounts();
  if (accounts.length === 0) return null;

  const account = accounts[0];

  return account;
};
