import * as yup from "yup";
import { AdditiveCreateDTO } from "../../../shared/specific/DTOs/additive/AdditiveCreateDTO";
import {
  AdditiveDTO,
  schemaAdditiveDTO,
} from "../../../shared/specific/DTOs/additive/AdditiveDTO";
import { AdditiveUpdateDTO } from "../../../shared/specific/DTOs/additive/AdditiveUpdateDTO";
import { AdditiveFiltersDTO } from "../../../shared/specific/DTOs/additive/filters/AdditiveFiltersDTO";
import { riseApi } from "./connection";
import { DeadlineAdditiveCreateDTO } from "../../../shared/specific/DTOs/additive/DeadlineAdditiveCreateDTO";
import { DeadlineAdditiveUpdateDTO } from "../../../shared/specific/DTOs/additive/DeadlineAdditiveUpdateDTO";

export const getAdditives = async ({
  filters,
}: {
  filters: AdditiveFiltersDTO;
}): Promise<AdditiveDTO[]> => {
  const { data: additives } = await riseApi.get("Additives", {
    params: filters,
  });

  return yup.array(schemaAdditiveDTO).defined().validateSync(additives);
};

export const getAdditive = async (id: number): Promise<AdditiveDTO | null> => {
  const { data: additive } = await riseApi.get(`Additives/${id}`);

  return schemaAdditiveDTO.nullable().validateSync(additive);
};

export const createAdditive = async (additiveCreateDTO: AdditiveCreateDTO) => {
  await riseApi.post("Additives", additiveCreateDTO);
};

export const createDeadlineAdditive = async (
  deadlineAdditiveCreateDTO: DeadlineAdditiveCreateDTO
) => {
  await riseApi.post("Additives/DeadlineAdditive", deadlineAdditiveCreateDTO);
};

export const updateAdditive = async (additiveUpdateDTO: AdditiveUpdateDTO) => {
  await riseApi.put("Additives", additiveUpdateDTO);
};

export const updateDeadlineAdditive = async (
  deadlineAdditiveUpdateDTO: DeadlineAdditiveUpdateDTO
) => {
  await riseApi.put("Additives/DeadlineAdditive", deadlineAdditiveUpdateDTO);
};

export const deleteAdditive = async (id: number, version: string) => {
  await riseApi.delete(`Additives/${id}/${version}`);
};
