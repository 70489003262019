import * as yup from "yup";

export interface LanguageDTO {
  id: string;
  name: string;
  isDefault: boolean;
}

export const schemaLanguageDTO = yup.object({
  id: yup.string().defined(),
  name: yup.string().defined(),
  isDefault: yup.boolean().defined(),
});
