import { useMemo } from "react";
import { InvoiceFilesDTO } from "../../../../../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { CommonInvoicesFormValues } from "../../../types/form/general.types";
import { CanceledForm } from "./formComponents/CanceledForm";
import { IssuedForm } from "./formComponents/IssuedForm";
import { CommonOnlyDateForm } from "./formComponents/CommonOnlyDateForm";
import { ReturnedForm } from "./formComponents/ReturnedForm";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  projectWithRelatedInfo: ProjectWithRelatedInfoDTO;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  values: CommonInvoicesFormValues;
  invoiceFilesData: InvoiceFilesDTO[];
  setInvoiceFilesData: React.Dispatch<React.SetStateAction<InvoiceFilesDTO[]>>;
}

export const FormikComponents = ({
  isSubmitting,
  invoiceData,
  projectWithRelatedInfo,
  setFieldValue,
  values,
  invoiceFilesData,
  setInvoiceFilesData,
}: OwnProps) => {
  const formToReturn = useMemo(() => {
    switch (values.status?.id) {
      case InvoicesStatusEnum.Returned:
        return (
          <ReturnedForm
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
          />
        );
      case InvoicesStatusEnum.Issued:
        return (
          <IssuedForm
            invoiceData={invoiceData}
            projectWithRelatedInfo={projectWithRelatedInfo}
            invoiceFilesData={invoiceFilesData}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            setInvoiceFilesData={setInvoiceFilesData}
            values={values}
          />
        );
      case InvoicesStatusEnum.ProvisionForCancellation:
        return (
          <CanceledForm
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case InvoicesStatusEnum.Canceled:
        return (
          <CanceledForm
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case InvoicesStatusEnum.PaidOut:
        return (
          <CommonOnlyDateForm
            values={values}
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            status={InvoicesStatusEnum.PaidOut}
          />
        );
      case InvoicesStatusEnum.Pdd:
        return (
          <CommonOnlyDateForm
            values={values}
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            status={InvoicesStatusEnum.Pdd}
          />
        );
      case InvoicesStatusEnum.Loss:
        return (
          <CommonOnlyDateForm
            values={values}
            invoiceData={invoiceData}
            isSubmitting={isSubmitting}
            setFieldValue={setFieldValue}
            status={InvoicesStatusEnum.Loss}
          />
        );
      default:
        return "";
    }
  }, [values]);

  return values.status?.id ? <>{formToReturn}</> : <div />;
};
