import { FormControlLabel, Typography } from "@mui/material";
import lodash from "lodash";
import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  StyledLocationDataContainer,
  StyledTotalInput,
  StyledViewContent,
} from "../../../../styles/ui.styles";
import { FormMode, FormValues } from "../../../../types/form.types";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { CheckboxFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { ExpenseType } from "../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { expenseTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/expenses/expenseType.maps";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { SegmentGroupsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentGroupsAutoCompleteFormik";

interface OwnProps {
  formikProps: FormContentProps<FormValues>;
  formMode: FormMode;
}

export const CreationEditingForm = ({
  formikProps: {
    formikProps: { values, setFieldValue, isSubmitting },
  },
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      if (values.isNewClient) {
        setFieldValue("customerTrigram", null);
      }
    },
    deps: [values.isNewClient],
  });

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      if (values.customerTrigram && values.customerTrigram !== "") {
        setFieldValue("isNewClient", false);
      } else {
        setFieldValue("isNewClient", true);
      }
    },
    deps: [values.customerTrigram],
  });

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      const sum = Number(
        lodash.sum([
          ...values.costBr.map((x) =>
            x.percentage ? parseLocaleNumber(x.percentage) : 0
          ),
          ...values.costUs.map((x) =>
            x.percentage ? parseLocaleNumber(x.percentage) : 0
          ),
        ])
      );

      const sumString = formatNumber(sum, { fractionDigits: 2 });

      setFieldValue("total", sumString);
    },
    deps: [values.costBr, values.costUs],
  });

  const isBrCountry = values.subsidiary?.name === "RDX.BR";

  useEffectAfterRenders({
    rendersBeforeEffect: 1,
    effect: () => {
      if (isBrCountry) {
        setFieldValue("costUs", [
          {
            expenseType: ExpenseType.Workforce,
            percentage: "",
          },
          {
            expenseType: ExpenseType.SoftwareHardware,
            percentage: "",
          },
          {
            expenseType: ExpenseType.Travels,
            percentage: "",
          },
          {
            expenseType: ExpenseType.Infrastructure,
            percentage: "",
          },
          {
            expenseType: ExpenseType.ResellingProducts,
            percentage: "",
          },
          {
            expenseType: ExpenseType.Training,
            percentage: "",
          },
          {
            expenseType: ExpenseType.ConsultantServices,
            percentage: "",
          },
          {
            expenseType: ExpenseType.Refund,
            percentage: "",
          },
          {
            expenseType: ExpenseType.Other,
            percentage: "",
          },
        ]);
      }
    },
    deps: [isBrCountry],
  });

  const isEditing = formMode === "edit";

  return (
    <StyledViewContent>
      <StyledLocationDataContainer>
        {isEditing ? (
          <TextFieldFormik
            name="customerTrigram"
            label={t("costDistribution.keywords.fields.customerTrigram")}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <CustomerCodesSearchAutocompleteFormik
            name="customerTrigram"
            textfieldProps={{
              label: t("costDistribution.keywords.fields.customerTrigram"),
            }}
            autocompleteProps={{
              disabled: isSubmitting,
              readOnly: isEditing,
            }}
          />
        )}
        <SegmentGroupsAutocompleteFormik
          name="segmentGroup"
          autocompleteProps={{
            disabled: isSubmitting,
            readOnly: isEditing,
          }}
          textfieldProps={{
            label: t("costDistribution.keywords.fields.segmentGroup"),
          }}
        />
        <SubsidiariesAutocompleteFormik
          name="subsidiary"
          textfieldProps={{
            label: t("general.keywords.fields.country"),
          }}
          autocompleteProps={{
            disabled: isSubmitting,
            readOnly: isEditing,
          }}
        />
      </StyledLocationDataContainer>
      <FormControlLabel
        label={t("costDistribution.keywords.fields.newCustomers")}
        control={
          <CheckboxFormik
            name="isNewClient"
            disabled={
              !values.customerTrigram ||
              values.customerTrigram === "" ||
              isEditing
            }
          />
        }
      />
      <StyledLocationDataContainer>
        <div />
        <Typography variant="h6">{t("US")}</Typography>
        <Typography variant="h6">{t("BR")}</Typography>
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Workforce])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[0].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Workforce]),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[0].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Workforce]),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.SoftwareHardware])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[1].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.SoftwareHardware]
            ),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[1].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.SoftwareHardware]
            ),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Travels])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[2].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Travels]),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[2].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Travels]),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Infrastructure])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[3].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.Infrastructure]
            ),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[3].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.Infrastructure]
            ),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.ResellingProducts])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[4].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.ResellingProducts]
            ),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[4].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.ResellingProducts]
            ),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Training])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[5].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Training]),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[5].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Training]),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.ConsultantServices])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[6].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.ConsultantServices]
            ),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[6].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(
              expenseTypeToTranslationCodeMap[ExpenseType.ConsultantServices]
            ),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Refund])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[7].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Refund]),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[7].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Refund]),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledLocationDataContainer>
        <Typography variant="h6">
          {t(expenseTypeToTranslationCodeMap[ExpenseType.Other])}
        </Typography>
        <LocalizedNumberTextFieldFormik
          name="costUs[8].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Other]),
            disabled: isSubmitting || isBrCountry,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="costBr[8].percentage"
          min={0}
          max={100}
          textFieldProps={{
            label: t(expenseTypeToTranslationCodeMap[ExpenseType.Other]),
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledLocationDataContainer>
      <StyledTotalInput>
        <div />
        <LocalizedNumberTextFieldFormik
          name="total"
          textFieldProps={{
            label: t("general.keywords.fields.total"),
            InputProps: {
              endAdornment: "%",
              readOnly: true,
            },
          }}
        />
      </StyledTotalInput>
    </StyledViewContent>
  );
};
