import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledViewContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const StyledFrameFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "a b c"
    "d e f"
    "g h i";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(9)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h"
      "i i";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "i";
  }
`;

export const StyledTotalFrame = styled("div")`
  display: flex;
  div:nth-child(1) {
    margin-right: 10px;
  }
`;
