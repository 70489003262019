import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { JobRateDTO, schemaJobRateDTO } from "./JobRateDTO";

export type PaginatedJobRateDTO = PaginationResponse<JobRateDTO>;

export const schemaPaginatedJobRateDTO =
  getSchemaPaginationResponse<JobRateDTO>(schemaJobRateDTO);
