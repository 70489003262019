import { Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { DatePickerFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { InvoiceDTO } from "../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { maxLengthFields } from "../../../../constants/data/form/fields.constants";
import {
  StyledFormContainer,
  StyledFormSection,
  StyledLabelChip,
  StyledGrayBox,
  StyledMainContainer,
  StyledButtons,
  StyledCloseButton,
} from "./index.styles";
import { InvoiceTypeAutocompleteFormik } from "../InvoiceTypeAutocompleteFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import {
  CommonInvoiceRequestFormValues,
  FormMode,
} from "../../../../types/data/form/values.types";
import { InvoiceFilesComponent } from "./components/InvoiceFilesComponent";
import { FinancialResponsibleComponent } from "./components/FinancialResponsibleComponent";
import { CompetenceComonent } from "./components/CompetenceComonent";
import { getNowBrazilianDate } from "../../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { CustomerByCodeAutocompleteFormik } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerByCodeAutocompleteFormik";
import { InvoicesByCustomerCodeAutocompleteFormik } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/invoices/InvoicesByCustomerCodeAutocompleteFormik/InvoicesByCustomerCodeAutocompleteFormik";
import { InvoiceOnlyDTO } from "../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceOnlyDTO";
import { InvoicesStatusEnum } from "../../../../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { CostCenterPepsOnlyAutocompleteFormik } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenterPep/CostCenterPepsOnlyAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { UpdateInvoiceRequestNotReleasedDTO } from "../../../../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateInvoiceRequestNotReleasedDTO";
import { DateOnly } from "../../../../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { YearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { UpdateInvoiceRequestNotReleased } from "../../../../../../../../../../../../services/invoices/invoices.service";
import { uploadInvoiceFile } from "../../../../../../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";
import { useErrorToastMessage } from "./hooks/useErrorToastMessage";

interface OwnProps
  extends Omit<
    FormContentProps<CommonInvoiceRequestFormValues>,
    "internalFormData"
  > {
  invoice?: InvoiceDTO;
  formMode: FormMode;
  reloadTablePage?: () => void;
}

const updateInvoiceRequestNotReleased = async (
  formValues: CommonInvoiceRequestFormValues,
  closeModal: () => void,
  invoice: InvoiceDTO,
  setSubmitting: (isSubmitting: boolean) => void,
  reloadTablePage: () => void,
  reloadProject: () => void
) => {
  setSubmitting(true);
  try {
    const invoiceToUpdate: UpdateInvoiceRequestNotReleasedDTO = {
      idCustomer: formValues.customer?.id ?? 0,
      idCostCenterPepToInvoice: formValues?.costCenterPep?.id ?? 0,
      invoiceIds: formValues.invoices.map((x) => x.id),
      invoiceType: formValues.invoiceType?.id ?? undefined,
      invoiceCompetence: formValues.invoiceCompetence.map((x) =>
        YearMonth.createFromDate(x)
      ),
      limitDate: formValues?.limitDate
        ? DateOnly.createFromDate(formValues.limitDate)
        : undefined,
      invoiceFinancialResponsible: formValues.invoiceFinancialResponsible,
      serviceDescription: formValues.serviceDescription,
      launchInstructions: formValues.launchInstructions,
      invoiceNote: formValues.invoiceNote,
      invoiceCopyToUser: formValues.invoiceCopyToUser.map((x) => x.id),
      invoiceFilesToDelete: formValues.invoiceFilesToDelete,
      idInvoiceGroup: invoice.idInvoiceGroup ?? undefined,
      version: invoice.version,
    };

    await UpdateInvoiceRequestNotReleased(invoiceToUpdate);

    await uploadInvoiceFile(
      formValues.invoiceFilesToUpload,
      formValues.invoices.map((x) => x.id.toString()),
      InvoiceFilesTypeEnum.Request
    );

    setSubmitting(false);
    closeModal();
    reloadTablePage();
    reloadProject();
  } catch (error) {
    console.error(error);
    setSubmitting(false);
  }
};

export const InvoiceRequestForm = ({
  formikProps,
  invoice,
  formMode,
  closeModal,
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();

  const {
    isSubmitting,
    values,
    setFieldValue,
    setSubmitting,
    submitForm,
    errors,
  } = formikProps;

  const { reloadProject } = useProjectContext();

  useErrorToastMessage({ errors, isSubmitting });

  const generalReadOnly = formMode === "viewing";

  useEffect(() => {
    if (!values.customer && invoice?.projectCustomer) {
      setFieldValue("customer", invoice?.projectCustomer);
    }
  }, [values.customer]);

  useEffect(() => {
    if (!values.costCenterPep) {
      setFieldValue("costCenterPep", invoice?.costCenterPep);
    }
  }, []);

  useEffectAfterRenders({
    effect: () => {
      if (
        values.costCenterPep &&
        !values.invoices.some(
          (x) => x.costCenterPep.id === values.costCenterPep?.id
        )
      ) {
        setFieldValue("costCenterPep", null);
      }
    },
    deps: [values.invoices.length],
    rendersBeforeEffect: 1,
  });

  useEffect(() => {
    if (invoice && !values.invoices.map((x) => x.id).includes(invoice.id)) {
      const currentInvoice: InvoiceOnlyDTO = {
        id: invoice.id,
        billingAmount: invoice.billingAmount,
        milestone: invoice.milestone,
        plannedBillingDate: invoice.plannedBillingDate,
        costCenterPep: invoice.costCenterPep,
        cfop: null,
        coodinatorName: null,
        managerName: null,
        status: invoice.status,
      };
      setFieldValue("invoices", [currentInvoice, ...values.invoices]);
    }
  }, []);

  return (
    <StyledMainContainer>
      <StyledFormContainer>
        <InvoicesByCustomerCodeAutocompleteFormik
          name="invoices"
          filters={{
            customerCode: invoice?.projectCustomer.code ?? "-",
            status: [InvoicesStatusEnum.NotReleased],
            idInvoiceGroup: invoice?.idInvoiceGroup ?? undefined,
            idSubsidiary: invoice?.costCenterPep.subsidiary.id,
          }}
          autocompleteProps={{
            disableClearable: true,
            multiple: true,
            readOnly: generalReadOnly || isSubmitting,
          }}
          isDisabled={generalReadOnly || isSubmitting}
        />
        <InvoiceTypeAutocompleteFormik
          name="invoiceType"
          textfieldProps={{
            label: t("invoice.keywords.fields.invoiceType"),
            required: true,
          }}
          autocompleteProps={{
            disableClearable: true,
            readOnly: generalReadOnly || isSubmitting,
          }}
        />
        <DatePickerFormik
          name="limitDate"
          datePickerProps={{
            label: t("invoice.keywords.fields.deadline"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: generalReadOnly || isSubmitting,
            minDate: getNowBrazilianDate().setDate(1),
          }}
          textFieldProps={{
            required: true,
          }}
        />
        {generalReadOnly ? (
          <TextField
            label={t("customers.keywords.general.customer")}
            value={`${invoice?.customer?.tradingName} - ${invoice?.customer?.registrationCode}`}
            InputProps={{
              readOnly: true,
            }}
          />
        ) : (
          <CustomerByCodeAutocompleteFormik
            name="customer"
            cusomerCode={invoice?.projectCustomer.code || ""}
            textfieldProps={{
              label: t("customers.keywords.general.customer"),
            }}
            autocompleteProps={{
              disableClearable: true,
              readOnly: generalReadOnly || isSubmitting,
            }}
          />
        )}
        {values.invoices.length > 0 && !generalReadOnly ? (
          <CostCenterPepsOnlyAutocompleteFormik
            name="costCenterPep"
            filtersMemo={{
              idsProjects: values.invoices.map(
                (x) => x.costCenterPep.project.id
              ),
            }}
            autocompleteProps={{
              disableClearable: true,
            }}
            textfieldProps={{
              label: t("invoice.keywords.fields.pepThatWillBeBilled"),
            }}
          />
        ) : (
          <TextField
            label={t("costCenterPeps.keywords.general.costCenterPeps")}
            value={`${invoice?.costCenterPepToInvoice?.pepCode}`}
            InputProps={{
              readOnly: true,
            }}
          />
        )}
        <CompetenceComonent
          formikProps={formikProps}
          invoice={invoice}
          formMode={formMode}
        />
        <FinancialResponsibleComponent
          formikProps={formikProps}
          invoice={invoice}
          formMode={formMode}
        />
        <TextFieldFormik
          name="serviceDescription"
          label={t("invoice.keywords.fields.serviceDescription")}
          inputProps={{ maxLength: maxLengthFields.invoiceNote }}
          InputProps={{ readOnly: generalReadOnly, required: true }}
          disabled={isSubmitting}
          multiline
          required
          rows={3}
        />
        <TextFieldFormik
          name="launchInstructions"
          label={t("invoice.keywords.fields.launchInstructions")}
          inputProps={{ maxLength: maxLengthFields.invoiceNote }}
          InputProps={{ readOnly: generalReadOnly, required: true }}
          disabled={isSubmitting}
          multiline
          required
          rows={3}
        />
        <TextFieldFormik
          name="invoiceNote"
          label={t("invoice.keywords.fields.invoiceNote")}
          inputProps={{ maxLength: maxLengthFields.invoiceNote }}
          InputProps={{ readOnly: generalReadOnly }}
          disabled={isSubmitting}
          multiline
          rows={3}
        />
        {generalReadOnly ? (
          <StyledFormSection>
            <Typography variant="h6" component="div">
              {t("invoice.keywords.fields.sendCopyTo")}
            </Typography>
            <div>
              {values.invoiceCopyToUser.map((x, i) => {
                return (
                  <StyledGrayBox
                    key={i}
                    size="medium"
                    label={<StyledLabelChip>{x.name}</StyledLabelChip>}
                  />
                );
              })}
            </div>
          </StyledFormSection>
        ) : (
          <UsersSearchAutocompleteFormik
            name="invoiceCopyToUser"
            autocompleteProps={{
              multiple: true,
              readOnly: generalReadOnly || isSubmitting,
            }}
            textfieldProps={{
              label: t("invoice.keywords.fields.sendCopyTo"),
              required: true,
            }}
            filtersMemo={{
              isActive: true,
            }}
          />
        )}
        <InvoiceFilesComponent
          formikProps={formikProps}
          invoice={invoice}
          formMode={formMode}
        />
      </StyledFormContainer>
      <StyledButtons>
        {formMode === "viewing" ? (
          <StyledCloseButton>
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          </StyledCloseButton>
        ) : (
          <>
            {closeModal &&
              invoice &&
              reloadTablePage &&
              InvoicesStatusEnum.NotReleased === invoice.status && (
                <LoadingButton
                  loading={isSubmitting}
                  onClick={async () =>
                    updateInvoiceRequestNotReleased(
                      values,
                      closeModal,
                      invoice,
                      setSubmitting,
                      reloadTablePage,
                      reloadProject
                    )
                  }
                  variant="contained"
                  color="primary"
                >
                  {t("general.actions.general.saveAndContinueLater")}
                </LoadingButton>
              )}
            <div>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitForm}
                variant="contained"
              >
                {t("general.actions.general.submitRequest")}
              </LoadingButton>
            </div>
          </>
        )}
      </StyledButtons>
    </StyledMainContainer>
  );
};
