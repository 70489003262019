import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  CommonProjectForm,
  CommonProjectFormRef,
} from "../../shared/react/components/content/CommonProjectForm";
import { getProjectById } from "../../../../../../services/projects/projects.service";
import { contractTypeToTranslationCodeMap } from "../../../../../../shared/specific/maps/projects/contractType.maps";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";

type OwnParams = {
  id: string;
};

export const ProjectInformation = () => {
  const { t, i18n } = useTranslation();
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      formRef.current?.setLoading(true);

      if (!id) {
        formRef.current?.setError("Invalid ID.");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const project = await getProjectById(id);
        if (!project) {
          formRef.current?.setError("Project does not exist.");
          return;
        }

        setPageTitle(project.name);

        formRef.current?.setProjectData(project);
        formRef.current?.setFormValues({
          customer: project.customer,
          userManager: project.userManager,
          userCoordinator: project.userCoordinator,
          division: project.division,
          subsidiary: project.subsidiary,
          productService: project.productService,
          frame: project.frame,
          code: project.code,
          name: project.name,
          description: project.description,
          contractType: {
            id: project.contractType,
            label: t(contractTypeToTranslationCodeMap[project.contractType]),
          },
          typeOfEvidence: project.typeOfEvidence
            ? {
                id: project.typeOfEvidence,
                label: t(
                  typeOfEvidenceToTranslationCodeMap[project.typeOfEvidence]
                ),
              }
            : null,
          daysForPayment: String(project.daysForPayment),
          billingAmount: project.billingAmount
            ? formatNumber(project.billingAmount, { fractionDigits: 2 })
            : "",
          mv: project.mv ? formatNumber(project.mv, { fractionDigits: 2 }) : "",
          customerNameFromDeal: "",
          userManagerNameFromDeal: "",
          productServiceFromDeal: "",
          divisionFromDeal: "",
          segment: project.segment,
          approvedCredit: {
            id: project.dealApprovedCredit ?? false,
            label: project.dealApprovedCredit
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
          clientAccount: project.clientAccount ?? "-",
          isInvestmentProject: {
            id: project.isInvestmentProject ?? false,
            label: project.isInvestmentProject
              ? t("general.keywords.general.yes")
              : t("general.keywords.general.no"),
          },
        });
      } catch (error) {
        // Deal with error
        console.error(error);
        formRef.current?.setError("Something happened. :(");
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, [i18n.language]);

  return <CommonProjectForm mode="viewing" ref={formRef} />;
};
