import lodash from "lodash";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpensesOfTypeDTO } from "../../../../../../../../../shared/specific/DTOs/expenses/ExpensesOfTypeDTO";
import { ExpenseType } from "../../../../../../../../../shared/specific/enums/expenses/ExpenseType";

export const complementSortExpensesTable = (
  expensesOfTypes: ExpensesOfTypeDTO[],
  costCenterPep: CostCenterPepOnlyDTO
) => {
  const newExpensesOfTypes = [...expensesOfTypes];

  for (const expenseType of Object.values(ExpenseType)) {
    if (typeof expenseType === "string") continue;
    if (
      [
        ExpenseType.Tax,
        ExpenseType.GrossRevenue,
        ExpenseType.FinancialCost,
      ].includes(expenseType)
    )
      continue;
    if (newExpensesOfTypes.some((x) => x.expenseType === expenseType)) continue;

    newExpensesOfTypes.push({
      idCostCenterPep: costCenterPep.id,
      expenseType,
      expenses: [],
      expensesTotalAmount: 0,
    });
  }

  const additionalExpenses = newExpensesOfTypes.find(
    (x) => x.expenseType === ExpenseType.Additional
  );

  const formattedToAddAdditionalExpenses = newExpensesOfTypes.map(
    (expensesTypes) => {
      if (
        expensesTypes.expenseType === ExpenseType.Workforce &&
        additionalExpenses
      ) {
        additionalExpenses.expenses.forEach((additionalExpenseData) => {
          const verifyIfIsEmptyWorkforceYearMonth =
            !expensesTypes.expenses.find((a) =>
              a.yearMonth.equals(additionalExpenseData.yearMonth)
            );
          if (verifyIfIsEmptyWorkforceYearMonth) {
            expensesTypes.expenses.push({
              amount: 0,
              version: additionalExpenseData.version,
              yearMonth: additionalExpenseData.yearMonth,
            });
          }
        });
        expensesTypes.expenses.forEach((expensesTypesData) => {
          const getCommonYearMonthValues = additionalExpenses.expenses.filter(
            (a) => a.yearMonth.equals(expensesTypesData.yearMonth)
          );
          if (getCommonYearMonthValues) {
            const updatedAmount = getCommonYearMonthValues.reduce(
              (sum, current) => sum + current.amount,
              0
            );
            expensesTypesData.amount += updatedAmount;
          }
        });

        expensesTypes.expensesTotalAmount +=
          additionalExpenses.expensesTotalAmount;

        return {
          ...expensesTypes,
          expenses: [...expensesTypes.expenses],
        };
      }
      return expensesTypes;
    }
  );

  const sortedExpensesOfTypes = lodash.sortBy(
    formattedToAddAdditionalExpenses,
    (x) => x.expenseType
  );
  return sortedExpensesOfTypes;
};

export const getMonthlyTotal = (expensesOfTypes: ExpensesOfTypeDTO[]) => {
  const monthlyTotals: {
    [yearMonthKey: string]: {
      amount: number;
      yearMonth: YearMonth;
    };
  } = {};

  expensesOfTypes = expensesOfTypes.filter(
    (x) => x.expenseType !== ExpenseType.Additional
  );

  for (const expensesOfType of expensesOfTypes) {
    for (const expense of expensesOfType.expenses) {
      const yearMonthKey = expense.yearMonth.toString();
      if (!monthlyTotals.hasOwnProperty(yearMonthKey)) {
        monthlyTotals[yearMonthKey] = {
          amount: 0,
          yearMonth: expense.yearMonth,
        };
      }

      monthlyTotals[yearMonthKey].amount += expense.amount;
    }
  }

  return Object.values(monthlyTotals);
};
