import { ForwardedRef, forwardRef, useMemo } from "react";
import {
  EnhancedHeadCellData,
  EnhancedRowData,
} from "../shared/types/data.types";
import { checkIsHeadCellValue } from "../shared/validators";
import { CustomTableContextProvider } from "../shared/contexts/TableContext";
import { EnhancedTableWithinContext } from "../EnhancedTableWithinContext";
import { defaults } from "../shared/constants/defaults";
import { formatExternalRows } from "../shared/helpers/data.helpers";
import {
  EnhancedTableProps,
  EnhancedTableRef,
} from "../shared/types/propsRef.types";
import { EnhancedTableMessagesSettings } from "../shared/types/settings.types";

const EnhancedTableWithinForwardRef = (
  {
    rows: externalRows,
    headCells: externalHeadCells,
    toolbarSettings,
    paginationSettings: externalPaginationSettings,
    uiSettings: externalUiSettings,
    orderDefaults,
    belowToolbarSettings,
    footerSettings,
    filterSettings,
    messagesSettings: externalMessagesSettings = {},
    headerSettings,
  }: EnhancedTableProps,
  ref: ForwardedRef<EnhancedTableRef>
) => {
  const headCells = useMemo(() => {
    const defaultHeadCellsData: EnhancedHeadCellData = {
      value: "",
      canSort: true,
      paddingmode: "both",
    };

    return externalHeadCells.map((headCellDataOrValue) => {
      const isHeadCellValue = checkIsHeadCellValue(headCellDataOrValue);
      if (!isHeadCellValue)
        return { ...defaultHeadCellsData, ...headCellDataOrValue };

      const headCellData: EnhancedHeadCellData = {
        ...defaultHeadCellsData,
        value: headCellDataOrValue,
      };
      return headCellData;
    });
  }, [externalHeadCells]);

  const rows: EnhancedRowData[] | null = useMemo(() => {
    if (!externalRows) return null;

    return formatExternalRows(externalRows);
  }, [externalRows]);

  const paginationSettings = useMemo(() => {
    const externalPagination = externalPaginationSettings?.externalPagination
      ? {
          ...defaults.pagination.externalPagination,
          ...externalPaginationSettings?.externalPagination,
        }
      : undefined;

    return {
      ...defaults.pagination,
      ...externalPaginationSettings,
      externalPagination,
    };
  }, [externalPaginationSettings]);

  const uiSettings = useMemo(() => {
    const uiData = { ...defaults.ui, ...externalUiSettings };
    if (uiData.fixedcellheight !== undefined) {
      uiData.mincellheight = Math.max(
        uiData.fixedcellheight,
        uiData.mincellheight
      );
    }

    if (uiData.hasDynamicTableHeight) {
      uiData.minMessageHeight = undefined;
    }

    return uiData;
  }, [externalUiSettings]);

  const orderDefaultSettings = useMemo(() => {
    const { defaultOrderBy, ...restOrderDefaults } = orderDefaults ?? {};

    const orderDefaultSettings = { ...defaults.order, ...restOrderDefaults };
    if (defaultOrderBy === "none") {
      orderDefaultSettings.defaultOrderBy = null;
    } else if (typeof defaultOrderBy === "number") {
      orderDefaultSettings.defaultOrderBy = defaultOrderBy;
    }

    return orderDefaultSettings;
  }, [orderDefaults]);

  const messagesSettings: EnhancedTableMessagesSettings = useMemo(() => {
    return { ...externalMessagesSettings };
  }, [...Object.values(externalMessagesSettings)]);

  if (!rows && !paginationSettings.externalPagination)
    throw new Error(
      "If you don't provide 'rows', you must set " +
        "paginationSettings.externalPagination"
    );

  return (
    <CustomTableContextProvider
      rows={rows}
      headCells={headCells}
      uiSettings={uiSettings}
      toolbarSettings={toolbarSettings}
      paginationSettings={paginationSettings}
      orderDefaultSettings={orderDefaultSettings}
      belowToolbarSettings={belowToolbarSettings}
      footerSettings={footerSettings}
      filterSettings={filterSettings}
      messagesSettings={messagesSettings}
      headerSettings={headerSettings}
      ref={ref}
    >
      <EnhancedTableWithinContext />
    </CustomTableContextProvider>
  );
};

export const EnhancedTable = forwardRef<EnhancedTableRef, EnhancedTableProps>(
  EnhancedTableWithinForwardRef
);
