import * as yup from "yup";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";

export interface UserResumeDTO {
  id: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  filePath: string;
  version: string;
}

export const schemaUserResumeDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  filePath: yup.string().defined(),
  version: yup.string().defined(),
});
