import { AutocompleteOption } from "../../../../../../../shared/common/types/data/form/autocomplete.types";

export enum LabelColorType {
  Allocated = 0,
  Unallocated = 1,
  Vacation = 2,
  Doubt = 3,
  Resignation = 4,
  Attention = 5,
}

export enum LabelType {
  None = 6,
  Doubt = 3,
  Resignation = 4,
  Attention = 5,
}

export interface AllocationMapEditingFormValues {
  vacationForcast: string;
  allocationMapObservation: string;
  customerOfficeAllocation: AutocompleteOption<boolean> | null;
  labelType: AutocompleteOption<LabelType>;
}

export type FormMode =
  | "vacationForcast"
  | "allocationMapObservation"
  | "customerOfficeAllocation"
  | "labelType";
