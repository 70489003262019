import * as yup from "yup";
import {
  AmountMonthDTO,
  schemaAmountMonthDTO,
} from "../general/balance/AmountMonthDTO";

export interface RevenueInvoicesDTO {
  revenues: AmountMonthDTO[];
  invoices: AmountMonthDTO[];
  provisions: AmountMonthDTO[];
  revenuesTotal: number;
  invoicesTotal: number;
  provisionsTotal: number;
  provisionsCurrent: number;
}

export const schemaRevenueInvoicesDTO = yup.object({
  revenues: yup.array(schemaAmountMonthDTO).defined(),
  invoices: yup.array(schemaAmountMonthDTO).defined(),
  provisions: yup.array(schemaAmountMonthDTO).defined(),
  revenuesTotal: yup.number().defined(),
  invoicesTotal: yup.number().defined(),
  provisionsTotal: yup.number().defined(),
  provisionsCurrent: yup.number().defined(),
});
