import { CollaboratorManagementFilterDTO } from "../../../shared/specific/DTOs/collaboratorManagement/CollaboratorManagementFilterDTO";
import {
  PaginatedCollaboratorManagementDTO,
  schemaPaginatedPaginatedCollaboratorManagementDTO,
} from "../../../shared/specific/DTOs/collaboratorManagement/PaginatedCollaboratorManagementDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getPaginatedCollaboratorsProfile = async ({
  filters,
  pagination,
}: {
  filters?: CollaboratorManagementFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedCollaboratorManagementDTO> => {
  const queryParams = new URLSearchParams(JSON.stringify(pagination));
  const { data: users } = await riseApi.post(
    `CollaboratorManagement?${queryParams}`,
    {
      pagination,
      filters,
    }
  );

  return schemaPaginatedPaginatedCollaboratorManagementDTO.validateSync(users);
};
