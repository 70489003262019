import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { createDeadlineAdditive } from "../../../../../../../../services/additives/additives.service";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { CommonAdditivesForm } from "../shared/react/components/content/CommonAdditivesForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { DeadlineAdditiveCreateDTO } from "../../../../../../../../shared/specific/DTOs/additive/DeadlineAdditiveCreateDTO";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { sleep } from "../../../../../../../../shared/common/helpers/general.helpers";
import { FormValues } from "../shared/types/data/form/values.types";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationDeadlineAdditiveForm = ({
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useFormikConfig();

  const {
    ContentButton: CreationDeadlineAdditiveButton,
    contentModal: creationDeadlineAdditiveModal,
  } = useFormikModalButton<FormValues>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("deadlineAdditives.modal.data.create.title"),
    },
    form: {
      formikConfig,
      FormContentMemo: (props) => (
        <CommonAdditivesForm {...props} mode="deadlineAdditive" />
      ),
      FormActionsMemo: ({ closeModal, isSubmitting, submitFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.close")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!formValues.scheduledStartDate || !formValues.scheduledEndDate)
          throw new Error("Dates cannot be null on submit.");

        try {
          const deadlineAdditive: DeadlineAdditiveCreateDTO = {
            idProject: project.id,
            scheduledStartDate: DateOnly.createFromDate(
              formValues.scheduledStartDate
            ),
            scheduledEndDate: DateOnly.createFromDate(
              formValues.scheduledEndDate
            ),
            justification: formValues.justification,
          };

          await createDeadlineAdditive(deadlineAdditive);

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          closeModal();
          reloadProject();
          reloadTablePage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "additive.errors.data.create.failedToCreateAdditive",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
    button: {
      FormButton: AddFormButton,
      onClickContentButtonComponent: async ({
        setFormValues,
        setIsLoadingModal,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        await sleep(1000);
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;
        setFormValues({
          amount: formatNumber(0, {
            fractionDigits: 2,
          }),
          anticipationJustification: "",
          justification: "",
          mv: formatNumber(project?.mv ?? 0, { fractionDigits: 2 }),
          scheduledEndDate: null,
          scheduledStartDate: null,
          approvedCredit: {
            id: true,
            label: t("general.keywords.general.yes"),
          },
        });
        setIsLoadingModal(false);
      },
    },
  });

  return { CreationDeadlineAdditiveButton, creationDeadlineAdditiveModal };
};
