import { LabelType } from "../types/form.types";

export const LabelTypeTranslationCode: {
  [key in LabelType]: string;
} = {
  [LabelType.None]: "allocationsMap.keywords.form.none",
  [LabelType.Resignation]: "allocationsMap.keywords.form.resignation",
  [LabelType.Doubt]: "allocationsMap.keywords.form.doubt",
  [LabelType.Attention]: "allocationsMap.keywords.form.attention",
};
