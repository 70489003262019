import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../corporateSegment/CorporateSegmentsDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import {
  CostCenterPepDTO,
  schemaCostCenterPepDTO,
} from "../costCenterPeps/CostCenterPepDTO";
import {
  schemaUserCollaboratorOnlyDTO,
  UserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";

export interface CollaboratorAllocationDTO {
  id?: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  costCenterPep: CostCenterPepDTO | null;
  costCenter: CostCenterDTO | null;
  corporateSegment: CorporateSegmentsDTO | null;
  allocationGroup: number;
  allocationType: EmployeeAllocationType;
  yearMonth: YearMonth;
  percentage: number;
  week1Percentage: number;
  week2Percentage: number;
  week3Percentage: number;
  week4Percentage: number;
  week5Percentage: number | null;
  canEdit: boolean;
}

export const schemaCollaboratorAllocationDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  costCenterPep: schemaCostCenterPepDTO.nullable().defined(),
  costCenter: schemaCostCenterDTO.nullable().defined(),
  corporateSegment: schemaCorporateSegmentsDTO.nullable().defined(),
  allocationGroup: yup.number().integer().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  yearMonth: schemaYearMonth.defined(),
  percentage: yup.number().defined(),
  week1Percentage: yup.number().defined(),
  week2Percentage: yup.number().defined(),
  week3Percentage: yup.number().defined(),
  week4Percentage: yup.number().defined(),
  week5Percentage: yup.number().nullable().defined(),
  canEdit: yup.boolean().defined(),
});
