import { Typography } from "@mui/material";
import { FullPageCenteredContent } from "../../../shared/common/react/components/ui/FullPageCenteredContent";

export const FinalFallbackErrorPage = () => {
  return (
    <FullPageCenteredContent>
      <Typography paragraph>Um erro desconhecido ocorreu.</Typography>
      <Typography paragraph>An unknown error has occurred.</Typography>
    </FullPageCenteredContent>
  );
};
