import { Paper, styled } from "@mui/material";
import { ProjectStatusFlag } from "../../../shared/react/components/general/info/ProjectStatusFlag";

export const StyledProjectPlanningPage = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledMainContent = styled(Paper)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const MobileProjectStatusFlag = styled(ProjectStatusFlag)`
  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;

export const TitleProjectStatusFlag = styled(ProjectStatusFlag)`
  padding-top: ${({ theme }) => theme.spacing(0.7)};
  padding-bottom: ${({ theme }) => theme.spacing(0.7)};
`;

export const StyledProjectScrollable = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

export const StyledPageTitle = styled("div")`
  display: flex;
  position: relative;
  gap: ${({ theme }) => theme.spacing(1)};

  & > :first-child {
    flex-shrink: 0;
    align-self: start;
    font-size: 1.2rem;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      display: none;
    }
  }

  & > :nth-child(2) {
    padding-top: ${({ theme }) => theme.spacing(0.2)};
  }
`;
