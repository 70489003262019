import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "../shared/react/hooks/useFormikConfig";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CommonFrameForm } from "../shared/react/components/content/CommonFrameForm";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { DateOnly } from "../../../../../../shared/common/classes/data/date/DateOnly";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FrameCreateDTO } from "../../../../../../shared/specific/DTOs/frame/FrameCreateDTO";
import { createFrame } from "../../../../../../services/frames/frames.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();

  const {
    contentButton: creationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <>{t("frames.modal.data.creation.title")}</>;
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonFrameForm {...rest} mode="creation" />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData: frameData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const frameCreated: FrameCreateDTO = {
            idSubsidiary: formValues.subsidiary?.id ?? 0,
            idDivision: formValues.division?.id ?? 0,
            idCustomer: formValues.customer?.id ?? 0,
            idAccountManager: formValues.accountManager?.id ?? 0,
            name: formValues.name,
            startDate: DateOnly.createFromDate(
              formValues.startDate ?? new Date()
            ),
            endDate: DateOnly.createFromDate(formValues.endDate ?? new Date()),
            amount: parseLocaleNumber(formValues.amount ?? ""),
          };

          await createFrame(frameCreated);
          reloadTablePage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "frames.errors.data.fetch.failedToCreateFrame",
          });
        } finally {
          setSubmitting(false);
          closeModal();
        }
      },
    },
  });

  return { creationButtonContainer, creationModal };
};
