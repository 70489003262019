import * as yup from "yup";
import { riseApi } from "./connection";
import {
  schemaWorkloadDTO,
  WorkloadDTO,
} from "../../../shared/specific/DTOs/workload/WorkloadDTO";
import { WorkloadFiltersDTO } from "../../../shared/specific/DTOs/workload/filters/WorkloadFiltersDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import {
  PaginatedWorkloadDTO,
  schemaPaginatedWorkloadDTO,
} from "../../../shared/specific/DTOs/workload/PaginatedWorkloadDTO";
import { WorkloadCreateDTO } from "../../../shared/specific/DTOs/workload/WorkloadCreateDTO";
import { WorkloadUpdateDTO } from "../../../shared/specific/DTOs/workload/WorkloadUpdateDTO";
import {
  schemaWorkloadDropdownDTO,
  WorkloadDropdownDTO,
} from "../../../shared/specific/DTOs/workload/WorkloadDropdownDTO";

export const getWorkloads = async ({
  filters,
  pagination,
}: {
  filters?: WorkloadFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedWorkloadDTO> => {
  const { data: paginatedWorkloads } = await riseApi.get("Workloads", {
    params: { ...filters, ...pagination },
  });
  return schemaPaginatedWorkloadDTO.validateSync(paginatedWorkloads);
};

export const getWorkloadsDropdown = async (): Promise<
  WorkloadDropdownDTO[]
> => {
  const { data: paginatedWorkloads } = await riseApi.get("Workloads/Dropdown");
  return yup
    .array(schemaWorkloadDropdownDTO)
    .defined()
    .validateSync(paginatedWorkloads);
};

export const getWorkloadId = async (
  id: number
): Promise<WorkloadDTO | null> => {
  const { data: workload } = await riseApi.get(`/Workloads/${id}`);

  return schemaWorkloadDTO.nullable().validateSync(workload);
};

export const createWorkload = async (workload: WorkloadCreateDTO) => {
  await riseApi.post("Workloads", workload);
};

export const updateWorkload = async (workload: WorkloadUpdateDTO) => {
  await riseApi.put("Workloads", workload);
};

export const deleteWorkload = async (id: number, version: string) => {
  await riseApi.delete(`Workloads/${id}/${version}`);
};
