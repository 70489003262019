import { TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { ProjectOnlyDTO } from "../../../../../../../DTOs/project/ProjectOnlyDTO";
import { ProjectsFiltersDTO } from "../../../../../../../DTOs/project/filters/ProjectsFiltersDTO";
import { getProjectsOnly } from "../../../../../../../../../services/projects/projects.service";
import {
  StyledAutocompleteFieldReviewInfoBubble,
  StyledDiv,
} from "./shared/styles.default";

export interface ProjectSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    ProjectOnlyDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<ProjectsFiltersDTO, "name">;
  filtersMemo?: Omit<ProjectsFiltersDTO, "name">;
  projectFromDeal?: string;
}

export const ProjectSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  projectFromDeal,
}: ProjectSearchAutocompleteFormikProps<DisableClearable>) => {
  const { t } = useTranslation();
  const [hideDealProjectFromDeal, setHideDealProjectFromDeal] =
    useState<boolean>(false);

  const startAdornment = useMemo(() => {
    if (hideDealProjectFromDeal || !projectFromDeal) return undefined;
    return (
      <StyledDiv>
        {projectFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [projectFromDeal, hideDealProjectFromDeal]);

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getProjectsOnlyFinal = useCallback(
    async (name: string) => {
      const paginatedProjects = await getProjectsOnly({
        filters: { name, ...finalFilters },
        pagination: { pageSize: 10 },
      });

      return paginatedProjects.tableData;
    },
    [finalFilters]
  );

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("projects.errors.data.fetch.failedToFetchProjects")}
      fetchOptions={getProjectsOnlyFinal}
      textfieldProps={{
        label: t("projects.keywords.general.project"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
        },
        onFocus: () => setHideDealProjectFromDeal(true),
        onBlur: () => setHideDealProjectFromDeal(false),
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
