import { Trans, useTranslation } from "react-i18next";
import { Button } from "@mui/base";
import { StyledErrorOutlineIcon, StyledModalHelperInfos } from "./index.styles";
import { useModalButton } from "../../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";

export const useUploadProjectionAdjustmentHelper = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: ProjectionAdjustmentHelperModalButtonContainer,
    contentModal: ProjectionAdjustmentModalHelper,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("uploadProjectionAdjustment.modalHelp.helpTitle"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        const rulesColumns = t(
          "uploadProjectionAdjustment.modalHelp.helpTipsColumns"
        ) as unknown as string[];
        const rulesAtencions = t(
          "uploadProjectionAdjustment.modalHelp.helpTipsAtencions"
        ) as unknown as string[];
        return (
          <StyledModalHelperInfos>
            <p>{t("uploadProjectionAdjustment.modalHelp.helpTipsTitle")}</p>
            <ul>
              {rulesColumns.map((rule, index) => (
                <li key={index}>
                  <Trans i18nKey={rule} components={{ b: <b /> }} />
                </li>
              ))}
            </ul>
            <p>
              {t("uploadProjectionAdjustment.modalHelp.helpTipsAtencionTitle")}
            </p>
            <ul>
              {rulesAtencions.map((rule, index) => (
                <li key={index}>
                  <Trans i18nKey={rule} components={{ b: <b /> }} />
                </li>
              ))}
            </ul>
            <p>{t("uploadProjectionAdjustment.modalHelp.belowExample")}</p>
            <img
              src="/media/images/pages/projectionAdjustment/projectionAdjustment-Upload.png"
              width={900}
            />
          </StyledModalHelperInfos>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return <StyledErrorOutlineIcon onClick={onClick} />;
      },
    },
  });
  return {
    ProjectionAdjustmentHelperModalButtonContainer,
    ProjectionAdjustmentModalHelper,
  };
};
