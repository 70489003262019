import { useRef, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { convertTo } from "../../../../../shared/common/helpers/types/converters.helpers";
import {
  EnhancedTableRef,
  EnhancedTable,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { UsersCollaboratorFullFilterDTO } from "../../../../../shared/specific/DTOs/user/filters/UsersCollaboratorFilterDTO";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { useUploadCollaboratorForm } from "./hooks/useUploadCollaboratorForm";
import { StyledCollaboratorUpload } from "./hooks/useUploadCollaboratorForm/components";
import { useCollaboratorWorksheetHelp } from "./hooks/useCollaboratorWorksheetHelp";
import { useUploadCollaboratorHistoryForm } from "./hooks/useUploadCollaboratorHistoryForm";
import { useCollaboratorHistoryWorksheetHelp } from "./hooks/useCollaboratorHistoryWorksheetHelp";

export const CollaboratorsPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  useEffect(() => {
    setPageTitle(t("usersCollaborators.pages.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const { filterButton, filterModal, filters } = useFilterForm();
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
  });

  const { uploadButtonLoadCollaborator, uploadModalCollaborator } =
    useUploadCollaboratorForm();

  const {
    uploadButtonLoadCollaboratorHistory,
    uploadModalCollaboratorHistory,
  } = useUploadCollaboratorHistoryForm();

  const {
    UseWorksheetHelpModalButtonContainer:
      UseWorksheetCollaboratorHelpModalButtonContainer,
    useWorksheetHelpModal: useWorksheetCollaboratorHelpModal,
  } = useCollaboratorWorksheetHelp();

  const {
    UseWorksheetHelpModalButtonContainer:
      UseWorksheetCollaboratorHistoryHelpModalButtonContainer,
    useWorksheetHelpModal: useWorksheetCollaboratorHistoryHelpModal,
  } = useCollaboratorHistoryWorksheetHelp();

  const filterObject =
    belowToolbarFilters.name === ""
      ? filters
      : {
          ...filters,
          ...belowToolbarFilters,
        };

  const formattedFilters = useMemo(() => {
    return convertTo<UsersCollaboratorFullFilterDTO>(filterObject);
  }, [belowToolbarFilters, filters]);

  const {
    headCells,
    deleteDialog,
    turnOffModal,
    turnOnDialog,
    editingModal,
    viewingModal,
    userAccessPermissionsModal,
    onUserCollaboratorPageChange,
    effectiveModal,
  } = useTableData({
    filters: formattedFilters,
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, formattedFilters],
    rendersBeforeEffect: 1,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtons: (
            <StyledCollaboratorUpload>
              <Protected
                restrictions={{
                  type: PermissionType.UserCollaborator,
                  level: PermissionLevel.Create,
                }}
              >
                <>
                  {creationButton}
                  {uploadButtonLoadCollaborator}
                  <UseWorksheetCollaboratorHelpModalButtonContainer />
                </>
              </Protected>
              <Protected
                restrictions={{
                  type: PermissionType.CanUploadHandlingLoad,
                }}
              >
                <>
                  {uploadButtonLoadCollaboratorHistory}
                  <UseWorksheetCollaboratorHistoryHelpModalButtonContainer />
                </>
              </Protected>
            </StyledCollaboratorUpload>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onUserCollaboratorPageChange,
          },
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {deleteDialog}
      {creationModal}
      {editingModal}
      {viewingModal}
      {turnOffModal}
      {turnOnDialog}
      {userAccessPermissionsModal}
      {uploadModalCollaborator}
      {useWorksheetCollaboratorHelpModal}
      {uploadModalCollaboratorHistory}
      {useWorksheetCollaboratorHistoryHelpModal}
      {effectiveModal}
    </>
  );
};
