import { useTranslation } from "react-i18next";
import { DropzoneState, useDropzone } from "react-dropzone";
import { Link } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../../types/form.types";
import { SkillCertificationsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skillCertification/SkillCertificationsAutocompleteFormik";
import { DatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import {
  StyledDropzone,
  StyledFormContainer,
  StyledGrayBox,
  StyledLabelChip,
} from "./index.styles";
import { DownloadIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { getNowBrazilianDate } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

type OwnProps = FormContentProps<FormValues>;

export const CreationEditingForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setFieldValue("certificationFile", acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive }: DropzoneState =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpeg", ".jpg"],
        "image/png": [".png"],
        "application/msword": [".doc", ".docx"],
      },
    });

  return (
    <StyledFormContainer>
      <SkillCertificationsAutocompleteFormik
        name="certification"
        textfieldProps={{
          disabled: isSubmitting,
          required: true,
        }}
      />
      <DatePickerFormik
        name="dateOfAcquisition"
        datePickerProps={{
          label: t("collaboratorProfile.keywords.fields.dateOfAcquisition"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          maxDate: getNowBrazilianDate(),
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <DatePickerFormik
        name="expirationDate"
        datePickerProps={{
          label: t("collaboratorProfile.keywords.fields.expirationDate"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
        }}
      />
      {((!isDragActive && !values.certificationFile) || isDragActive) && (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          {!isDragActive && !values.certificationFile && (
            <>
              <p>
                {t(
                  "collaboratorProfile.actions.data.dropzone.certificationMessage"
                )}
              </p>
              <CloudUploadIcon fontSize="large" color="primary" />
            </>
          )}
          {isDragActive && (
            <p>{t("collaboratorProfile.actions.data.dropzone.liveFile")}</p>
          )}
        </StyledDropzone>
      )}

      {values.certificationFile && !isDragActive && (
        <StyledGrayBox
          size="medium"
          label={
            <StyledLabelChip>
              {`${values.certificationFile.name}`}
              <div>
                <Link
                  href={URL.createObjectURL(values.certificationFile)}
                  download
                >
                  <DownloadIconButton disabled={isSubmitting} color="primary" />
                </Link>
                <DeleteIconButton
                  disabled={isSubmitting}
                  onClick={() => setFieldValue("certificationFile", null)}
                />
              </div>
            </StyledLabelChip>
          }
        />
      )}
    </StyledFormContainer>
  );
};
