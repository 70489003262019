import {
  StopCircle as StopCircleIcon,
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
} from "@mui/icons-material";
import { Radio, Tooltip } from "@mui/material";
import { startOfMonth } from "date-fns";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getActivities } from "../../../../../../../../../services/activities/activities.service";
import { formatCurrency } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { FormattedCurrency } from "../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { EditIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { ActivityDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { ActivityFrontendFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/activity/filters/ActivityFrontendFiltersDTO";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { StyledSumActivities } from "../../ActivitiesTabWithinContext/index.styles";
import { ActivitiesSuperUserContext } from "../../shared/react/contexts/ActivitiesSuperUserContext";
import { useCloseActivityForm } from "../useCloseActivityForm";
import { useDeletionForm } from "../useDeletionForm";
import { useDeprovisionEditingForm } from "../useDeprovisionEditingForm";
import { useEditingForm } from "../useEditingForm";
import { TypeOfActivities } from "../useFilterForm/shared/types/filter.types";
import { useReopenActivityForm } from "../useReopenActivityForm";
import { StyledDeprovisionValue } from "./index.styles";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
  showSelected: boolean;
  filters: ActivityFrontendFiltersDTO;
}

export const useTableData = ({
  reloadTablePage,
  reloadActivitySummary,
  showSelected,
  filters,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { project, costCenterPep, accessMode } = useProjectContext();

  const [sumActivitiesFiltered, setSumActivitiesFiltered] = useState<
    number | null
  >(null);

  const { deletionModal, DeleteButtonContainer } = useDeletionForm({
    reloadTablePage,
    reloadActivitySummary,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
    reloadActivitySummary,
  });
  const { DeprovisionEditingButtonContainer, deprovisionEditingModal } =
    useDeprovisionEditingForm({
      reloadTablePage,
      reloadActivitySummary,
    });
  const { ClosingButtonContainer, closingModal } = useCloseActivityForm({
    reloadTablePage,
    reloadActivitySummary,
  });
  const { ReopeningButtonContainer, reopeningModal } = useReopenActivityForm({
    reloadTablePage,
    reloadActivitySummary,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.description"),
      t("activities.keywords.fields.baseline"),
      t("activities.keywords.fields.scheduledAt"),
      {
        value: "",
        HeadRenderer: () =>
          sumActivitiesFiltered ? (
            <div>
              <span>{t("general.keywords.fields.value")}</span>
              <StyledSumActivities>
                <div>{t("general.keywords.fields.total")}</div>
                <div>
                  <FormattedCurrency
                    value={sumActivitiesFiltered}
                    currencySymbol={project.subsidiary.currencySymbol}
                  />
                </div>
              </StyledSumActivities>
            </div>
          ) : (
            <span>{t("general.keywords.fields.value")}</span>
          ),
      },
      t("activities.keywords.fields.closedAt"),
    ];

    if (accessMode === "writing")
      headCells.push({
        value: "",
        width: 152,
        canSort: false,
      });

    if (showSelected)
      return [
        {
          value: "",
          width: 70,
          canSort: false,
        },
        ...headCells,
      ];

    return headCells;
  }, [t, accessMode, showSelected, sumActivitiesFiltered, filters]);

  const EditingButton = ({ activity }: { activity: ActivityDTO }) => {
    const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);
    if (!activity.completedDate && !activity.deprovisionType)
      return <EditingButtonContainer {...activity} />;

    const completedInThePast =
      activity.completedDate &&
      startOfMonth(Date.now()) > startOfMonth(activity.completedDate.toDate());

    const canEditDeprovisionType =
      (!completedInThePast || canEditAllActivities) &&
      activity.completedDate &&
      activity.deprovisionType;

    return canEditDeprovisionType ? (
      <DeprovisionEditingButtonContainer {...activity} />
    ) : (
      <EditIconButton disabled />
    );
  };

  const DeletionButton = ({ activity }: { activity: ActivityDTO }) => {
    const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);
    const isBeforeStartOfMonth =
      activity.completedDate &&
      activity.completedDate.toDate() < startOfMonth(Date.now());

    const canNotDeleteDeprovisionType =
      (activity.completedDate && !activity.deprovisionType) ||
      (isBeforeStartOfMonth && !canEditAllActivities);

    return canNotDeleteDeprovisionType ? (
      <DeleteIconButton disabled />
    ) : (
      <DeleteButtonContainer {...activity} />
    );
  };

  const ClosingButton = ({ activity }: { activity: ActivityDTO }) => {
    const { canEditAllActivities } = useContext(ActivitiesSuperUserContext);

    if (
      activity.deprovisionType !== null ||
      [ProjectStatus.Created, ProjectStatus.Planning].includes(project.status)
    )
      return <div />;

    if (!activity.completedDate)
      return <ClosingButtonContainer {...activity} />;

    if (
      activity.completedDate.toDate() < startOfMonth(Date.now()) &&
      !canEditAllActivities
    )
      return <SimpleIconButton Icon={StopCircleIcon} disabled />;

    return <ReopeningButtonContainer {...activity} />;
  };

  const singlePageLoader: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const activities = await getActivities({
          filters: { idProject: project.id },
        });

        if (
          filters.scheduledDateEarliest ||
          filters.scheduledDateLatest ||
          filters.typeOfActivity?.label
        ) {
          const filteredActivities = activities.filter((x) => {
            if (filters.scheduledDateEarliest && !filters.scheduledDateLatest)
              return x.scheduledDate >= filters.scheduledDateEarliest;
            if (!filters.scheduledDateEarliest && filters.scheduledDateLatest)
              return x.scheduledDate <= filters.scheduledDateLatest;
            if (filters.scheduledDateEarliest && filters.scheduledDateLatest)
              return (
                x.scheduledDate <= filters.scheduledDateLatest &&
                x.scheduledDate >= filters.scheduledDateEarliest
              );
            return true;
          });

          const filteredActivitiesFinal = filters?.typeOfActivity
            ? filteredActivities.filter((x) => {
                if (
                  filters.typeOfActivity?.id === TypeOfActivities.Activities
                ) {
                  return x.amount > 0;
                }
                if (
                  filters.typeOfActivity?.id === TypeOfActivities.Desprovisions
                ) {
                  return x.amount < 0;
                }
                if (
                  filters.typeOfActivity?.id ===
                  TypeOfActivities.ActivitiesAndDesprovisions
                ) {
                  return true;
                }
                return true;
              })
            : filteredActivities;

          const sumActivities = filteredActivitiesFinal
            .map((x) => x.amount)
            .reduce((x, y) => x + y, 0);

          setSumActivitiesFiltered(sumActivities);
        } else {
          setSumActivitiesFiltered(null);
        }

        const rows = activities.map((activity): ExternalEnhancedRow => {
          const row: ExternalEnhancedRow = {
            id: activity.id,
            rowInfo: activity,
            cells: [
              {
                value: activity.description,
              },
              {
                value: activity.baselineDate?.toDate(),
                displayValue: activity.baselineDate
                  ? Intl.DateTimeFormat(i18n.language).format(
                      activity.baselineDate.toDate()
                    )
                  : "-",
              },
              {
                value: activity.scheduledDate.toDate(),
                displayValue: Intl.DateTimeFormat(i18n.language).format(
                  activity.scheduledDate.toDate()
                ),
              },
              {
                value: activity.amount,
                CellRenderer: () => (
                  <StyledDeprovisionValue>
                    {formatCurrency({
                      value: activity.amount,
                      currencySymbol:
                        activity.costCenterPep.subsidiary.currencySymbol,
                    })}
                    {activity.deprovisionType && (
                      <Tooltip
                        title={activity.deprovisionType.name}
                        placement="top"
                      >
                        <div>
                          <IndeterminateCheckBoxIcon color="secondary" />
                        </div>
                      </Tooltip>
                    )}
                  </StyledDeprovisionValue>
                ),
              },
              {
                value: activity.completedDate?.toDate(),
                displayValue: activity.completedDate
                  ? Intl.DateTimeFormat(i18n.language).format(
                      activity.completedDate.toDate()
                    )
                  : "-",
              },
            ],
          };

          if (showSelected)
            row.cells.unshift({
              CellRenderer: () => {
                const { selectedActivities, setSelectedActivities } =
                  useContext(ActivitiesSuperUserContext);
                const [check, setCheck] = useState<boolean>(false);

                const handleWithRadioButton = () => {
                  if (!check && setSelectedActivities)
                    setSelectedActivities([activity, ...selectedActivities]);
                  else {
                    const newArray = selectedActivities.filter(
                      (x) => x.id !== activity.id
                    );
                    if (setSelectedActivities) setSelectedActivities(newArray);
                  }
                  setCheck(!check);
                };

                return (
                  <Radio
                    checked={check}
                    onClick={() => handleWithRadioButton()}
                  />
                );
              },
              align: "left",
              paddingmode: "horizontal",
            });

          if (accessMode === "writing")
            row.cells.push({
              CellRenderer: () => (
                <>
                  <Protected
                    restrictions={{
                      type: PermissionType.Activity,
                      level: PermissionLevel.Update,
                    }}
                  >
                    <EditingButton activity={activity} />
                  </Protected>
                  <Protected
                    restrictions={{
                      type: PermissionType.Activity,
                      level: PermissionLevel.Update,
                    }}
                  >
                    <ClosingButton activity={activity} />
                  </Protected>
                  <Protected
                    restrictions={{
                      type: PermissionType.Activity,
                      level: PermissionLevel.Delete,
                    }}
                  >
                    <DeletionButton activity={activity} />
                  </Protected>
                </>
              ),
              align: "right",
              paddingmode: "horizontal",
            });

          return row;
        });

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [
      project.id,
      accessMode,
      showSelected,
      sumActivitiesFiltered,
      filters,
      costCenterPep,
    ]);

  return {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    deprovisionEditingModal,
    closingModal,
    reopeningModal,
  };
};
