import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const CustomerBalancesFilterForm = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="yearMonth"
        datePickerProps={{
          label: t("jobRates.keywords.general.startYearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
      />
      <CostCentersSearchAutocompleteFormik
        name="costCenter"
        autocompleteProps={{ disabled: formikProps.isSubmitting }}
      />
      <CustomerCodesSearchAutocompleteFormik
        name="customerTrigram"
        textfieldProps={{
          label: t("costDistribution.keywords.fields.groupCode"),
        }}
      />
    </StyledForm>
  );
};
