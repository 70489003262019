import { useMemo } from "react";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { BelowToolbarFilters } from "../../shared/types/data/filters/data.types";
import { useUserCollaboratorNameFilter } from "./hooks/useUserCollaboratorNameFilter";
import { useYearFilter } from "./hooks/useYearFilter";
import { StyledBelowToolbarFilters } from "./index.styles";

export const useBelowToolbarFilters = () => {
  const { userCollaboratorName, userCollaboratorNameComponent } =
    useUserCollaboratorNameFilter();
  const { yearFilter, yearFilterComponent } = useYearFilter();

  const belowToolbarFilters = useMemo((): BelowToolbarFilters => {
    return {
      userCollaboratorName,
      earliestYearMonth: new YearMonth(0, yearFilter),
      latestYearMonth: new YearMonth(11, yearFilter),
    };
  }, [userCollaboratorName, yearFilter]);

  const belowToolbarFiltersComponent = useMemo(() => {
    return (
      <StyledBelowToolbarFilters>
        {userCollaboratorNameComponent}
        {yearFilterComponent}
      </StyledBelowToolbarFilters>
    );
  }, [userCollaboratorNameComponent, yearFilterComponent]);

  return { belowToolbarFilters, belowToolbarFiltersComponent };
};
