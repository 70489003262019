import { UserPermissionDTO } from "../../../../DTOs/user/UserPermissionDTO";
import {
  AbstractPermissionsOperator,
  RestrictionsOperand,
} from "./AbstractPermissionsOperator";

export class RootPermissionsOperator extends AbstractPermissionsOperator {
  constructor(private permission: RestrictionsOperand) {
    super();
  }

  public checkPermissions({
    userPermissions,
    isAdmin,
  }: {
    userPermissions: UserPermissionDTO[];
    isAdmin: boolean;
  }) {
    return AbstractPermissionsOperator.checkPermission({
      isAdmin,
      permission: this.permission,
      userPermissions,
    });
  }
}
