import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import { KeywordDTO } from "../../../../../../../DTOs/Keyword/KeywordDTO";
import { getKeywordsDropdown } from "../../../../../../../../../services/projects/projects.service";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    KeywordDTO,
    DisableClearable
  >["autocompleteProps"];
}

const cachedKeywords = new CachedTranslatedData({
  callback: getKeywordsDropdown,
  defaultCacheDuration: 600000, // 1 hour
});

export const KeywordsAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<DisableClearable>) => {
  const { t } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t("projects.errors.data.fetch.failedToFetchKeywords")}
      fetchOptions={cachedKeywords.run}
      textfieldProps={{
        label: t("projects.keywords.fields.keywords"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
