import { CSSObject, TableCell, styled } from "@mui/material";

interface StyledFirsColumnProps {
  isfixfirst: string;
  isfixlast: string;
  isextensible?: string;
}

export const StyledButtonColumn = styled(TableCell)<StyledFirsColumnProps>`
  ${(props) =>
    props.isfixfirst === "true" &&
    ({
      position: "sticky",
      left: 0,
    } as CSSObject)};

  background-color: white;
  ${(props) =>
    props.isextensible === "true" &&
    ({ backgroundColor: "rgba(224, 224, 224, 1)" } as CSSObject)};
  width: 65px;
`;

export const StyledFirsColumn = styled(TableCell)<StyledFirsColumnProps>`
  ${(props) =>
    props.isfixfirst === "true" &&
    ({
      position: "sticky",
      left: 65.96,
      padding: 0,
    } as CSSObject)};

  ${(props) =>
    props.isfixlast === "true" &&
    ({
      position: "sticky",
      right: 0,
      padding: 0,
    } as CSSObject)};

  background-color: white;

  ${(props) =>
    props.isextensible === "true" &&
    ({ backgroundColor: "rgba(224, 224, 224, 1)" } as CSSObject)};
  width: fit-content;
  max-width: 200px;
  padding: 0;
`;
