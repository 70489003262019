import { DateOnly } from "../../../../common/classes/data/date/DateOnly";
import { YearMonth } from "../../../../common/classes/data/date/YearMonth";

export interface UsersCollaboratorFilterDTO {
  maxStartYearMonth?: YearMonth;
}

export interface UsersCollaboratorFullFilterDTO {
  id?: number;
  name?: string;
  login?: string;
  admissionDateStart?: DateOnly;
  admissionDateEnd?: DateOnly;
  onlyActivated: boolean;
  idContractRole?: number;
  idJobRole?: number;
  idProject?: number;
  idFrame?: number;
  customerCode?: string;
  idCostCenter?: number;
  idManager?: number;
  idCoordinator?: number;
  startDate?: YearMonth;
  endDate?: YearMonth;
  onlyResignatedLessThanOneMonth?: boolean;
}

export const createUsersCollaboratorFullFilterDTO = (
  values: UsersCollaboratorFullFilterDTO
) => {
  return values;
};
