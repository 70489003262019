import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { FilterType, FormValuesProvisionReport } from "./types/form.types";
import { ProvisionReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/ProvisionReportFilterDTO";
import { generateProvisionReport } from "../../../../../../../../../services/reports/reports.service";
import { FormFilter } from "./components/FormFilter";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const ProvisionReport = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesProvisionReport>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesProvisionReport = {
      filterType: null,
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      projects: [],
      subsidiary: null,
    };

    const validationSchema = yup.object({
      filterType: yup
        .object()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      startYearMonth: yup
        .date()
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.parent.filterType.id === FilterType.projects) return true;
            return !!value;
          },
        })
        .typeError(t("general.errors.data.fields.dates.invalidDate")),
      endYearMonth: yup
        .date()
        .nullable()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.parent.filterType.id === FilterType.projects) return true;
            return !!value;
          },
        })
        .typeError(t("general.errors.data.fields.dates.invalidDate")),
      division: yup
        .object()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.parent.filterType.id === FilterType.division)
              return !!value;
            return true;
          },
        })
        .nullable(),
      projects: yup
        .array()
        .test({
          name: "teste",
          message: t("general.errors.data.fields.general.required"),
          test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.parent.filterType.id === FilterType.projects)
              return !!value;
            return true;
          },
        })
        .nullable(),
      subsidiary: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesProvisionReport,
    formikHelpers: FormikHelpers<FormValuesProvisionReport>
  ) => {
    const formattedFilters: ProvisionReportFilterDTO = {
      idsProjects: values?.projects ? values.projects.map((x) => x.id) : [],
      idDivision: values.division?.id,
      startYearMonth: values?.startYearMonth
        ? YearMonth.createFromDate(values.startYearMonth)
        : undefined,
      endYearMonth: values?.endYearMonth
        ? YearMonth.createFromDate(values.endYearMonth)
        : undefined,
      idSubsidiary: values.subsidiary?.id,
    };

    try {
      await generateProvisionReport(formattedFilters);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage:
          "reports.errors.generation.provisionReport.projectsLimitToNotHaveTimeout",
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => <FormFilter {...formikProps} />}
    </Formik>
  );
};
