import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useActivityFormikConfig } from "../shared/react/hooks/data/validation/useActivityFormikConfig";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { createActivity } from "../../../../../../../../services/activities/activities.service";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { ActivityCreateDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityCreateDTO";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CommonActivityForm } from "../shared/react/components/content/CommonActivityForm";
import { CommonActivityFormValues } from "../shared/types/data/form/values.types";
import { ProjectDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { AddIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { checkIsDateAfterProject } from "../../../../shared/helpers/DTOs/project/general.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

export const useCreationForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useActivityFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<CommonActivityFormValues, ProjectDTO>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("activities.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <AddIconButton
              onClick={onClick}
              tooltipText={t("activities.actions.general.addActivity")}
            />
          );
        },
        onClickContentButton: ({ setInternalFormData }) => {
          setInternalFormData(project);
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonActivityForm project={internalFormData} {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            await createActivity(
              convertTo<ActivityCreateDTO>({
                idProject: project.id,
                description: formValues.description,
                scheduledDate: formValues.scheduledDate
                  ? DateOnly.createFromDate(formValues.scheduledDate)
                  : DateOnly.createFromNow(),
                amount: parseLocaleNumber(formValues.amount),
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            reloadActivitySummary();
            closeModal();

            if (
              checkIsDateAfterProject(
                project.finalScheduledEndDate,
                formValues.scheduledDate
              ) ||
              project.isValid
            )
              reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "activities.errors.data.create.failedToCreateActivity",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
