import { Button, styled } from "@mui/material";

export const StyledTableContainer = styled("div")`
  overflow: auto;
`;

interface StyledFooterProps {
  hasScroll: boolean;
}

export const StyledFooter = styled("div")<StyledFooterProps>`
  display: flex;
  justify-content: flex-end;
  overflow-y: scroll;

  border-top: 3px solid ${({ theme }) => theme.palette.divider};

  span {
    padding: 8px 16px;
    width: 200px;
    font-weight: bold;
  }

  ::-webkit-scrollbar {
    display: ${({ hasScroll }) => (hasScroll ? "hidden" : "none")};
  }
`;

export const StyledCellHeader = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const StyledFormContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledButton = styled(Button)`
  margin: 12px;
`;
