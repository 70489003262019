import { Backdrop, styled } from "@mui/material";

export const StyledBackdrop = styled(Backdrop)`
  color: ${({ theme }) => theme.palette.common.white};
  z-index: ${({ theme }) => theme.zIndex.modal + 10};
`;

export const StyledFocusDiv = styled("div")`
  &:focus {
    outline: none;
  }
`;
