import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { FeeDTO, schemaFeeDTO } from "./FeeDTO";

export type PaginatedFeeDTO = PaginationResponse<FeeDTO>;

export const schemaPaginatedFeeDTO = getSchemaPaginationResponse<FeeDTO>(
  schemaFeeDTO as yup.Schema<FeeDTO>
);
