import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../shared/specific/react/components/authentication/Protected";
import { useCreationForm } from "./hooks/useCreationForm";
import { useTableData } from "./hooks/useTableData";

export const DashboardGroupsPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    setPageTitle(t("dashboardGroups.pages.main.title"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { headCells, onPageChange, editingModal, deletionModal } = useTableData(
    {
      reloadTablePage,
    }
  );

  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.DashBoards,
                level: PermissionLevel.Create,
              }}
            >
              {CreationButtonContainer}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
    </>
  );
};
