import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { Button, Link } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  StyledGrayBox,
  StyledLabelChip,
  StyledListFiles,
  StyledProjectInfo,
} from "../../index.styles";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { maxLengthFields } from "../../../../constants/form.constants";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { DeleteIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { CommonInvoicesFormValues } from "../../../../types/form/general.types";
import { InvoiceFilesDTO } from "../../../../../../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { DownloadIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { getInvoiceFile } from "../../../../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import { LoadingBackdrop } from "../../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  projectWithRelatedInfo: ProjectWithRelatedInfoDTO;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
  values: CommonInvoicesFormValues;
  invoiceFilesData: InvoiceFilesDTO[];
  setInvoiceFilesData: React.Dispatch<React.SetStateAction<InvoiceFilesDTO[]>>;
}

export const IssuedForm = ({
  isSubmitting,
  invoiceData,
  projectWithRelatedInfo,
  setFieldValue,
  values,
  invoiceFilesData,
  setInvoiceFilesData,
}: OwnProps) => {
  const { t } = useTranslation();

  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFieldValue("invoiceFilesToUpload", [
        ...values.invoiceFilesToUpload,
        file,
      ]);
    }
  };

  return (
    <>
      <StyledProjectInfo>
        <DatePickerFormik
          name="dueDate"
          datePickerProps={{
            disabled: isSubmitting,
            label: t("invoice.keywords.fields.dueDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
          }}
        />
        <DatePickerFormik
          name="issueDate"
          datePickerProps={{
            disabled: isSubmitting,
            label: t("invoice.keywords.fields.emissionDate"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
          }}
        />
        <TextFieldFormik
          name="invoiceNumber"
          label={t("invoice.keywords.fields.invoiceNumber")}
          inputProps={{ maxLength: maxLengthFields.invoiceNumber }}
          disabled={isSubmitting}
        />
      </StyledProjectInfo>
      <StyledProjectInfo>
        <Button
          onClick={() => fileInput.current?.click()}
          variant="contained"
          endIcon={<UploadIcon />}
          disabled={isSubmitting}
        >
          {t("invoice.keywords.fields.attachFile")}
        </Button>
        <input
          type="file"
          accept="application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ref={fileInput}
          onChange={handleFileSelection}
          style={{ display: "none" }}
        />
      </StyledProjectInfo>
      <StyledListFiles>
        {values.invoiceFilesToUpload.map((invFileUpload, idxInvFileUpload) => {
          return (
            <StyledGrayBox
              key={idxInvFileUpload}
              size="medium"
              label={
                <StyledLabelChip key={idxInvFileUpload}>
                  {`${invFileUpload?.name}`}
                  <div>
                    <Link href={URL.createObjectURL(invFileUpload)} download>
                      <DownloadIconButton
                        disabled={isSubmitting}
                        color="primary"
                      />
                    </Link>
                    <DeleteIconButton
                      disabled={isSubmitting}
                      onClick={() =>
                        setFieldValue(
                          "invoiceFilesToUpload",
                          values.invoiceFilesToUpload.filter(
                            (inv) => inv?.name !== invFileUpload?.name
                          )
                        )
                      }
                    />
                  </div>
                </StyledLabelChip>
              }
            />
          );
        })}
        {invoiceData.invoiceFiles
          .filter((x) => x.invoiceFilesType === InvoiceFilesTypeEnum.Issued)
          .map((invFileData, i) => {
            const elementLength = invFileData?.filePath.split("/").length;
            return (
              <StyledGrayBox
                key={i}
                size="medium"
                label={
                  <li key={i}>
                    {`${invFileData?.filePath.split("/")[elementLength - 1]}`}
                    <div>
                      <DownloadIconButton
                        disabled={isSubmitting}
                        color="primary"
                        onClick={async () => {
                          try {
                            setIsDownloading(true);
                            await getInvoiceFile(invFileData.filePath);
                          } catch (error) {
                            console.error(error);
                          } finally {
                            setIsDownloading(false);
                          }
                        }}
                      />
                      <DeleteIconButton
                        disabled={isSubmitting}
                        onClick={() => {
                          setInvoiceFilesData(
                            invoiceFilesData.filter(
                              (invFileToDelete) =>
                                invFileToDelete.id !== invFileData.id
                            )
                          );
                          return setFieldValue("invoiceFilesToDelete", [
                            ...values.invoiceFilesToDelete,
                            invFileData.filePath,
                          ]);
                        }}
                      />
                    </div>
                  </li>
                }
              />
            );
          })}
      </StyledListFiles>
      <LoadingBackdrop open={isDownloading} />
    </>
  );
};
