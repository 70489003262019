import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const results = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const resultsLableCpt: CptProps = {
    text: "Results",
    options: {
      align: pres.AlignH.left,
      y: "69%",
      x: "50%",
      w: "45%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const resultsNameCpt: CptProps = {
    text: projectData.projectResults ?? "-",
    options: {
      align: pres.AlignH.left,
      y: "70%",
      x: "50%",
      w: "45%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: getFontSizeByString(projectData.projectResults ?? "-"),
    },
  };

  slide.addText(resultsLableCpt.text, resultsLableCpt.options);
  slide.addText(resultsNameCpt.text, resultsNameCpt.options);
};
