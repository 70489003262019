export const getRange = (startOrRange: number, end?: number, step?: number) => {
  const internalStart = end !== undefined ? startOrRange : 0;
  const internalEnd = end !== undefined ? end : startOrRange;
  const internalStep = step ?? 1;

  const range = [] as number[];
  for (let i = internalStart; i < internalEnd; i += internalStep) {
    range.push(i);
  }

  return range;
};
