import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMainProjectPepOnly } from "../../../../../../services/costCenterPeps/costCenterPeps.service";
import { getProjectByIdWithRelatedInfo } from "../../../../../../services/projects/projects.service";
import { CenteredContent } from "../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { ProjectContextProvider } from "../shared/react/contexts/ProjectContext";
import { CostCenterPepOnlyDTO } from "../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";

type OwnProps = {
  idProject: number;
  children: ReactNode;
};

export const PlanningContextContainer = ({ idProject, children }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const [currentCostCenterPep, setCurrentCostCenterPep] =
    useState<CostCenterPepOnlyDTO | null>(null);

  const {
    value: project,
    isLoading: isLoadingProject,
    hasError: projectHasError,
    reloadValue: reloadProject,
    errorStatusCode: projectErrorStatusCode,
  } = useLoadLatest({
    initialValue: null,
    dependencies: [i18n.language],
    getValue: () => {
      if (idProject === null) return null;
      return getProjectByIdWithRelatedInfo(idProject);
    },
  });

  const {
    value: costCenterPep,
    isLoading: isLoadingCostCenterPep,
    hasError: codeCenterPepHasError,
    errorStatusCode: constCenterPepErrorStatusCode,
  } = useLoadLatest({
    initialValue: null,
    dependencies: [currentCostCenterPep],
    getValue: () => {
      if (idProject === null) return null;
      return currentCostCenterPep ?? getMainProjectPepOnly(idProject);
    },
    onError: ({ setValue }) => {
      setValue(null);
    },
  });

  if (project === null) {
    if (isLoadingProject) return <CenteredLoading />;
    if (projectHasError)
      return (
        <CenteredContent>
          {projectErrorStatusCode === 403 ||
          constCenterPepErrorStatusCode === 403
            ? t("general.errors.authorization.unauthorizedAccessContent")
            : t("projects.errors.data.fetch.failedToFetchProject")}
        </CenteredContent>
      );
    return (
      <CenteredContent>
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );
  }
  if (isLoadingCostCenterPep) return <CenteredLoading />;
  if (codeCenterPepHasError)
    return (
      <CenteredContent>
        {t("costCenterPeps.errors.data.fetch.failedToFetchCostCenterPep")}
      </CenteredContent>
    );
  if (costCenterPep === null)
    return (
      <CenteredContent>
        {t("projects.errors.data.general.projectDoesNotHaveMainPep")}
      </CenteredContent>
    );

  return (
    <ProjectContextProvider
      project={project}
      costCenterPep={costCenterPep}
      reloadProject={reloadProject}
      isLoadingProject={isLoadingProject}
      projectHasError={projectHasError}
      projectErrorStatusCode={projectErrorStatusCode}
      setCurrentCostCenterPep={setCurrentCostCenterPep}
    >
      {children}
    </ProjectContextProvider>
  );
};
