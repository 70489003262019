import { AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { useInvoiceRequestFormikConfig } from "../../shared/react/hooks/data/validation/useInvoiceRequestFormikConfig";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { ProjectDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { DateOnly } from "../../../../../../../../../shared/common/classes/data/date/DateOnly";
import {
  UpdateToReleased,
  getInvoiceById,
  getLastInvoiceById,
} from "../../../../../../../../../services/invoices/invoices.service";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { CommonInvoiceRequestFormValues } from "../../shared/types/data/form/values.types";
import { uploadInvoiceFile } from "../../../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { getLoggedUser } from "../../../../../../../../../services/user/user.service";
import { UpdateToReleasedDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateToReleasedDTO";
import { InvoiceTypeEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoiceTypeEnum";
import { InvoiceTypeTranslationCodeMap } from "../../shared/react/mappers/InvoiceTypeTranslationCodeMap";
import { ApplyLastInvoiceInformation } from "../../shared/react/components/content/ApplyLastInvoiceInformation";
import { LastInvoiceDto } from "../../../../../../../../../shared/specific/DTOs/invoice/LastInvoiceDto";

interface OwnProps {
  reloadTablePage: () => void;
  reloadInvoiceSummary: () => void;
}

interface InternalFormValues {
  project: ProjectDTO | null;
  invoice: InvoiceDTO | null;
  lastInvoice: LastInvoiceDto | null;
}

let uploadSuccess = false;
export const useInvoiceRequestForm = ({
  reloadTablePage,
  reloadInvoiceSummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useInvoiceRequestFormikConfig();

  const { ContentButton: RequestButton, contentModal: requestModal } =
    useFormikModalButton<
      CommonInvoiceRequestFormValues,
      InternalFormValues,
      InvoiceDTO
    >({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("invoice.modal.data.create.reuseInvoiceInformation"),
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <SimpleIconButton
              Icon={AttachMoneyIcon}
              onClick={onClick}
              color="primary"
              tooltipText={t("invoice.modal.data.create.requestInvoice")}
            />
          );
        },
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: invoice,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const invoiceData = await getInvoiceById(invoice.id);
            const lastInvoice = await getLastInvoiceById(project.id);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!invoiceData)
              return setModalError(
                t("invoice.errors.data.general.invoiceDoesNotExist")
              );

            setInternalFormData({
              invoice: invoiceData,
              project,
              lastInvoice,
            });
            setFormValues({
              customer: invoiceData?.customer,
              costCenterPep: invoiceData.costCenterPepToInvoice,
              invoiceType: invoiceData?.invoiceType
                ? {
                    id: invoiceData?.invoiceType,
                    label: t(
                      InvoiceTypeTranslationCodeMap[invoiceData?.invoiceType]
                    ),
                  }
                : {
                    id: InvoiceTypeEnum.InvoiceNote,
                    label: t(
                      InvoiceTypeTranslationCodeMap[InvoiceTypeEnum.InvoiceNote]
                    ),
                  },
              invoiceCompetence: invoiceData?.invoiceCompetence
                ? invoiceData.invoiceCompetence.map((x) => x.yearMonth.toDate())
                : [],
              selectedCompetence: null,
              limitDate: invoiceData?.limitDate
                ? invoiceData?.limitDate.toDate()
                : null,
              invoiceFinancialResponsible:
                invoiceData?.invoiceFinancialResponsible
                  ? invoiceData?.invoiceFinancialResponsible.map((x) => x.email)
                  : [],
              invoiceFinancialResponsibleSelected: "",
              serviceDescription: invoiceData?.serviceDescription ?? "",
              launchInstructions: invoiceData?.launchInstructions ?? "",
              invoiceNote: invoiceData?.invoiceNote ?? "",
              invoiceCopyToUser: invoiceData?.invoiceCopyToUser
                ? invoiceData.invoiceCopyToUser.map((x) => x.userCollaborator)
                : [],
              invoiceFilesToDelete: [],
              invoiceFilesToUpload: [],
              invoiceFilesList: [],
              invoices: invoiceData.invoiceGroup,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoices",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, closeModal, ...rest }) => (
          <ApplyLastInvoiceInformation
            {...rest}
            closeModal={closeModal}
            formMode="creation"
            invoice={internalFormData?.invoice ?? undefined}
            reloadTablePage={reloadTablePage}
            lastInvoice={internalFormData?.lastInvoice ?? undefined}
          />
        ),
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            if (!internalFormData?.invoice)
              throw new Error("'invoice' cannot be falsy on submit.");

            const invoiceCompetenceData = formValues.invoiceCompetence.map(
              (x) => YearMonth.createFromDate(x)
            );
            const invoiceCopyToUserData = formValues.invoiceCopyToUser.map(
              (x) => x.id
            );

            const loggedUser = await getLoggedUser();

            if (formValues.invoiceFilesToUpload.length < 1) {
              notifyError(t("invoice.errors.data.update.evidenceIsRequired"));
              return;
            }

            const invoiceToUpdate: UpdateToReleasedDTO = {
              idCustomer: formValues.customer?.id ?? 0,
              idCostCenterPepToInvoice: formValues?.costCenterPep?.id ?? 0,
              idInvoiceGroup: null,
              invoiceIds: formValues.invoices.map((x) => x.id),
              status: InvoicesStatusEnum.Released,
              requesterLogin: loggedUser?.login ?? "",
              requestDate: DateOnly.createFromNow(),
              invoiceType:
                formValues.invoiceType?.id ?? InvoiceTypeEnum.InvoiceNote,
              invoiceCompetence: invoiceCompetenceData,
              limitDate: formValues?.limitDate
                ? DateOnly.createFromDate(formValues.limitDate)
                : DateOnly.createFromNow(),
              invoiceFinancialResponsible:
                formValues.invoiceFinancialResponsible,
              serviceDescription: formValues.serviceDescription,
              launchInstructions: formValues.launchInstructions,
              invoiceNote: formValues.invoiceNote,
              invoiceCopyToUser: invoiceCopyToUserData,
              invoiceFilesToDelete: formValues.invoiceFilesToDelete,
              version: internalFormData.invoice.version,
            };

            await UpdateToReleased(invoiceToUpdate);

            await uploadInvoiceFile(
              formValues.invoiceFilesToUpload,
              formValues.invoices.map((x) => x.id.toString()),
              InvoiceFilesTypeEnum.Request
            );
            uploadSuccess = true;

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            if (uploadSuccess) {
              reloadTablePage();
              reloadInvoiceSummary();
              closeModal();
              reloadProject();
              setSubmitting(false);
            }
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "invoice.errors.data.update.failedToRequestInvoice",
            });
            setSubmitting(false);
          }
        },
      },
    });

  return { RequestButton, requestModal };
};
