import * as yup from "yup";

export enum CustomerBalanceTypeEnum {
  UnidentifiedDeposit = 1,
  ContractualRetention = 2,
  Tax = 3,
}

export const schemaCustomerBalanceTypeEnum = yup
  .mixed<CustomerBalanceTypeEnum>()
  .oneOf(Object.values(CustomerBalanceTypeEnum) as CustomerBalanceTypeEnum[]);
