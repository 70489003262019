import { TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { getDivisions } from "../../../../../../../../../../../../services/division/division.service";
import {
  AsyncFastAutocompleteFormikProps,
  AsyncAutocompleteFormik,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { DivisionDTO } from "../../../../../../../../../../../../shared/specific/DTOs/division/DivisionDTO";
import { DivisionFiltersDTO } from "../../../../../../../../../../../../shared/specific/DTOs/division/filters/DivisionFiltersDTO";
import {
  StyledDiv,
  StyledAutocompleteFieldReviewInfoBubble,
} from "./DivisionSearcAutocompleteDealsFormik.styles";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    DivisionDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: DivisionFiltersDTO;
  filtersMemo?: DivisionFiltersDTO;
  includeAllDivisionsOption?: boolean;
  divisionFromDeal: string;
  divisionDTO: DivisionDTO | null;
}

export const DivisionsAutocompleteDealsFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
  includeAllDivisionsOption = false,
  divisionFromDeal,
  divisionDTO,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const [hideDealDivision, setHideDealDivision] = useState<boolean>(false);

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const internalGetDivisions = useCallback(async () => {
    return getDivisions({
      filters: finalFilters,
      includeAllDivisionsOption,
    });
  }, [finalFilters]);

  const startAdornment = useMemo(() => {
    if (divisionDTO || divisionFromDeal) return undefined;
    if (!divisionFromDeal) return undefined;
    return (
      <StyledDiv>
        {divisionFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [divisionFromDeal, divisionDTO, hideDealDivision]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "divisions.errors.data.fetch.failedToFetchDivisions"
      )}
      fetchOptions={internalGetDivisions}
      textfieldProps={{
        label: t("divisions.keywords.general.un"),
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
        },
        onFocus: () => setHideDealDivision(true),
        onBlur: () => setHideDealDivision(false),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (division) => division.name ?? String(division.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
