import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/general.types";
import { StyledForm } from "./index.styles";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { SegmentGroupsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentGroupsAutoCompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (ownProps: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <CustomerCodesSearchAutocompleteFormik
        name="customerTrigram"
        textfieldProps={{
          label: t("costDistribution.keywords.fields.customerTrigram"),
        }}
      />
      <SegmentGroupsAutocompleteFormik
        name="segmentGroup"
        textfieldProps={{
          label: t("costDistribution.keywords.fields.segmentGroup"),
        }}
      />
      <SubsidiariesAutocompleteFormik
        name="subsidiary"
        textfieldProps={{
          label: t("general.keywords.fields.country"),
        }}
      />
    </StyledForm>
  );
};
