import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { useTableData } from "./hooks/useTableData";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useFilterForm } from "./hooks/useFilterForm";
import { useCreationForm } from "./hooks/useCreationForm";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";

export const CustomersPage = () => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("customers.pages.main.title"));
  }, [t]);

  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
  });
  const { filterButton, filters, filtersModal } = useFilterForm();
  const {
    headCells,
    onTablePageChange,
    deletionModal,
    viewingModal,
    editingModal,
  } = useTableData({
    filters,
    reloadTablePage,
  });

  const rightActionButtons = useMemo(() => filterButton, [filterButton]);

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.Customer,
                level: PermissionLevel.Create,
              }}
            >
              {creationButton}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
        }}
      />
      {filtersModal}
      {deletionModal}
      {viewingModal}
      {creationModal}
      {editingModal}
    </>
  );
};
