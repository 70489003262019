import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import {
  getCostCenterById,
  updateCostCenter,
} from "../../../../../../../services/costCenter/costCenter.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CostCenterDTO } from "../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import {
  formatFromTranslatableFieldValues,
  formatToTranslatableFieldValues,
} from "../../../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import { useFormikConfig } from "./shared/hooks/data/validation/useFormikConfig";
import { FormValues } from "./shared/types/form.types";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { EditingForm } from "./EditingForm";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EditingButtonContainer: (props: CostCenterDTO) => JSX.Element | null;
  editingModal: JSX.Element | null;
};

export const useEditingForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, CostCenterDTO, CostCenterDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: costCenter }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: costCenter?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: costCenterRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const costCenterDTO = await getCostCenterById(costCenterRow.id);
            if (!costCenterDTO)
              return setModalError(
                i18next.t(
                  "costCenters.errors.data.general.costCenterDoesNotExist"
                )
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(costCenterDTO);
            setFormValues({
              code: costCenterDTO.code ?? "",
              division: costCenterDTO.division,
              subsidiary: costCenterDTO.subsidiary,
              nameTranslations: formatToTranslatableFieldValues({
                source: costCenterDTO.costCenterTranslations,
                convertItem: ({ idLanguage, name }) => ({
                  language: idLanguage,
                  value: name ?? "",
                }),
              }),
              deactivatedDate: costCenterDTO.deactivatedDate,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenters.errors.data.fetch.failedToFetchCostCenter",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: EditingForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: costCenter,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!costCenter)
            throw new Error("CostCenter cannot be null on submission.");

          if (!formValues.division || !formValues.subsidiary)
            throw new Error("Required values are missing.");

          try {
            await updateCostCenter({
              id: costCenter.id,
              code: formValues.code,
              idDivision: formValues.division.id,
              idSubsidiary: formValues.subsidiary.id,
              version: costCenter.version,
              costCenterTranslations: formatFromTranslatableFieldValues({
                source: formValues.nameTranslations,
                convertItem: ({ language, value }) => ({
                  idLanguage: language,
                  name: value,
                }),
              }),
              deactivatedDate:
                formValues.deactivatedDate === null
                  ? undefined
                  : DateOnly.createFromDate(formValues.deactivatedDate),
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (costCenter?.name)
              return notifyError(
                <Trans
                  i18nKey="costCenters.errors.data.edit.failedToEditName"
                  values={{ name: costCenter?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("costCenters.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData: null as CostCenterDTO | null,
      },
    });

  return { EditingButtonContainer, editingModal };
};
