import { createContext } from "react";
import { AllocationsSummaryContextValues } from "./index.types";

export const AllocationsSummaryContext =
  createContext<AllocationsSummaryContextValues>({
    summaryData: null,
    isLoadingSummary: false,
    hasErrorSummary: false,
    setSummaryData: () => null,
    setIsLoadingSummary: () => null,
    setHasErrorSummary: () => null,
  });
