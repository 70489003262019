import { styled } from "@mui/material";

export const StyledForm = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px 0px;

  div:first-child {
    min-width: 400px;
  }
`;
