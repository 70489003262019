import * as yup from "yup";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import {
  FormValues,
  FormValuesCollaboratorAllocationsFilter,
} from "./shared/types/form.types";
import { CollaboratorAllocationContext } from "./shared/context";
import { FormFilter } from "./shared/components/FormFilter";
import { CollaboratorAllocationTable } from "./shared/components/CollaboratorAllocationTable";
import { parseLocaleNumber } from "../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { checkDoesWeekExist } from "../../../../shared/common/helpers/data/dates/checkers.helpers";
import { YearMonth } from "../../../../shared/common/classes/data/date/YearMonth";
import { notifyIf4xxApiErrorDTO } from "../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CollaboratorAllocationModifyDTO } from "../../../../shared/specific/DTOs/collaboratorAllocations/CollaboratorAllocationModifyDTO";
import { EmployeeAllocationType } from "../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { ModifyCollaboratorAllocation } from "../../../../services/collaboratorAllocations/collaboratorAllocation.service";
import { LoadingBackdrop } from "../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { notifySuccess } from "../../../../services/applicationState/toast.service";

export const CollaboratorAllocationsWithinContext = () => {
  const { t } = useTranslation();

  const {
    allocations,
    setAllocations,
    setFilter,
    search,
    CanAccessTrainingInCorporateAllocation,
    CanAccessLeaveInCorporateAllocation,
    CanAccessDeallocationInCorporateAllocation,
  } = useContext(CollaboratorAllocationContext);

  const [allocationCollaboratorFilter, setAllocationCollaboratorFilter] =
    useState<FormValuesCollaboratorAllocationsFilter | null>();

  const formikRef = useRef<FormikProps<FormValues>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValues = {
      allocationYearMonths: [],
    };

    const validationSchema = yup.object({
      allocationYearMonths: yup.array(
        yup.object({
          week1Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week2Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week3Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week4Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .required(t("general.errors.data.fields.general.required")),
          week5Percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .when("yearMonth", (yearMonth, schema) => {
              const doesWeekExist = checkDoesWeekExist(
                5,
                YearMonth.createFromDate(
                  new Date(yearMonth[0] as unknown as Date)
                )
              );
              if (!doesWeekExist) return schema;
              return schema.required(
                t("general.errors.data.fields.general.required")
              );
            }),
        })
      ),
    });

    return { initialValues, validationSchema };
  }, [t, allocations]);

  useEffect(() => {
    setPageTitle(t("collaboratorAllocations.pages.main.title"));
  }, [t]);

  const formikOnSubmit = async (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (
      !(values.allocationYearMonths.length > 0) ||
      !allocationCollaboratorFilter?.userCollaborator?.id
    )
      throw new Error("All required fields must be filled.");

    try {
      const listAllocations: CollaboratorAllocationModifyDTO = {
        idUserCollaborator: allocationCollaboratorFilter?.userCollaborator.id,
        yearMonth: YearMonth.createFromDate(
          allocationCollaboratorFilter?.yearMonth as unknown as Date
        ),
        costCenterAllocationUpdate: [],
        projectAllocationCreate: [],
        projectAllocationUpdate: [],
      };

      values.allocationYearMonths.forEach((allocation, x) => {
        const allocationOnTheContext = allocations[x];
        if (
          allocationOnTheContext.allocationType ===
            EmployeeAllocationType.Leave &&
          !CanAccessLeaveInCorporateAllocation
        ) {
          return;
        }

        if (
          allocationOnTheContext.allocationType ===
            EmployeeAllocationType.Training &&
          !CanAccessTrainingInCorporateAllocation
        ) {
          return;
        }

        if (
          allocationOnTheContext.allocationType ===
            EmployeeAllocationType.Unallocated &&
          !CanAccessDeallocationInCorporateAllocation
        ) {
          return;
        }
        if (allocationOnTheContext?.id) {
          if (allocationOnTheContext?.costCenterPep) {
            listAllocations.projectAllocationUpdate.push({
              allocationGroup: allocationOnTheContext.allocationGroup,
              week1Percentage: parseLocaleNumber(allocation.week1Percentage),
              week2Percentage: parseLocaleNumber(allocation.week2Percentage),
              week3Percentage: parseLocaleNumber(allocation.week3Percentage),
              week4Percentage: parseLocaleNumber(allocation.week4Percentage),
              week5Percentage:
                allocation.week5Percentage === ""
                  ? undefined
                  : parseLocaleNumber(allocation.week5Percentage),
              yearMonth: YearMonth.createFromDate(allocation.yearMonth),
            });
          } else {
            listAllocations.costCenterAllocationUpdate.push({
              allocationGroup: allocationOnTheContext.allocationGroup,
              week1Percentage: parseLocaleNumber(allocation.week1Percentage),
              week2Percentage: parseLocaleNumber(allocation.week2Percentage),
              week3Percentage: parseLocaleNumber(allocation.week3Percentage),
              week4Percentage: parseLocaleNumber(allocation.week4Percentage),
              week5Percentage:
                allocation.week5Percentage === ""
                  ? undefined
                  : parseLocaleNumber(allocation.week5Percentage),
              yearMonth: YearMonth.createFromDate(allocation.yearMonth),
            });
          }
        } else {
          listAllocations.projectAllocationCreate.push({
            allocationType: EmployeeAllocationType.Allocation,
            idCostCenterPep: allocationOnTheContext.costCenterPep?.id || 0,
            idUserCollaborator: allocationOnTheContext.userCollaborator.id,
            allocationYearMonths: [
              {
                week1Percentage: parseLocaleNumber(allocation.week1Percentage),
                week2Percentage: parseLocaleNumber(allocation.week2Percentage),
                week3Percentage: parseLocaleNumber(allocation.week3Percentage),
                week4Percentage: parseLocaleNumber(allocation.week4Percentage),
                week5Percentage:
                  allocation.week5Percentage === ""
                    ? 0
                    : parseLocaleNumber(allocation.week5Percentage),
                yearMonth: YearMonth.createFromDate(allocation.yearMonth),
              },
            ],
          });
        }
      });

      await ModifyCollaboratorAllocation(listAllocations);
      setAllocations([]);
      setFilter({
        userCollaborator:
          allocationCollaboratorFilter?.userCollaborator || null,
        yearMonth: allocationCollaboratorFilter?.yearMonth || null,
      });
      notifySuccess(
        t("general.success.data.general.operationExecutedSuccessfully")
      );
    } catch (error) {
      setAllocations([]);
      setFilter({
        userCollaborator:
          allocationCollaboratorFilter?.userCollaborator || null,
        yearMonth: allocationCollaboratorFilter?.yearMonth || null,
      });
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage:
          "collaboratorAllocations.errors.data.create.failedToCreateCollaboratorAllocations",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <FormFilter
        key={0}
        setAllocationCollaboratorFilter={setAllocationCollaboratorFilter}
      />
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(formikContenProps) => (
          <>
            <div
              style={{
                display: formikContenProps.isSubmitting ? "none" : "initial",
              }}
            >
              <CollaboratorAllocationTable
                formikContenProps={formikContenProps}
                allocationCollaboratorFilter={allocationCollaboratorFilter}
              />
            </div>
            <LoadingBackdrop open={formikContenProps.isSubmitting || search} />
          </>
        )}
      </Formik>
    </>
  );
};
