import React, { ReactNode, createContext, useMemo, useState } from "react";
import { ProjectionAdjustmentContextValues } from "./index.types";
import { ProjectionAdjustmentDTO } from "../../../../../../../shared/specific/DTOs/ProjectionAdjustment/ProjectionAdjustmentDTO";
import { ProjectionFormValues } from "../types/form.types";

interface OwnProps {
  children: ReactNode;
}

export const ProjectionAdjustmentContext =
  createContext<ProjectionAdjustmentContextValues>({
    error: null,
    setError: () => null,
    loading: false,
    setLoading: () => null,
    projectionAdjustmentInitialValues: null,
    setProjectionAdjustmentInitialValues: () => null,
    formValues: null,
    setFormValues: () => null,
  });

export const ProjectionAdjustmentContexProvider = ({ children }: OwnProps) => {
  const [error, setError] = useState<React.ReactNode | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<ProjectionFormValues | null>(
    null
  );
  const [
    projectionAdjustmentInitialValues,
    setProjectionAdjustmentInitialValues,
  ] = useState<ProjectionAdjustmentDTO | null>(null);

  const projectionAdjustmentContext: ProjectionAdjustmentContextValues =
    useMemo(() => {
      return {
        error,
        setError,
        loading,
        setLoading,
        projectionAdjustmentInitialValues,
        setProjectionAdjustmentInitialValues,
        formValues,
        setFormValues,
      };
    }, [error, loading, projectionAdjustmentInitialValues, formValues]);

  return (
    <ProjectionAdjustmentContext.Provider value={projectionAdjustmentContext}>
      {children}
    </ProjectionAdjustmentContext.Provider>
  );
};
