import * as yup from "yup";
import {
  AllocationsOfUserDTO,
  schemaAllocationsOfUserDTO,
} from "./AllocationsOfUserDTO";

export interface AllocationsPerUserDTO {
  totalAllocationCost: number;
  allocationsPerUser: AllocationsOfUserDTO[];
}

export const schemaAllocationsPerUserDTO = yup.object({
  totalAllocationCost: yup.number().defined(),
  allocationsPerUser: yup.array(schemaAllocationsOfUserDTO).defined(),
});
