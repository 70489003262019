import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimesheetMonthDTO } from "../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { StyledModalContent } from "./index.styles";

interface OwnProps {
  timesheetMonth: TimesheetMonthDTO;
}

export const ModalContent = ({ timesheetMonth }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledModalContent>
      <TextField
        label={t("general.keywords.dateTime.general.month")}
        value={timesheetMonth.yearMonth.toPrettyString()}
        disabled
      />
      <TextField
        label={t("general.keywords.fields.code")}
        value={timesheetMonth.costCenter?.code ?? timesheetMonth.project?.code}
        disabled
      />
      <TextField
        label={t("general.keywords.fields.code")}
        value={timesheetMonth.costCenter?.code ?? timesheetMonth.project?.code}
        disabled
      />
      <TextField
        label={t("corporateSegment.keywords.general.corporateSegments")}
        value={timesheetMonth.corporateSegment?.name || "-"}
        disabled
      />
    </StyledModalContent>
  );
};
