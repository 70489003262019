import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import i18next from "i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { getInvoiceById } from "../../../../../services/invoices/invoices.service";
import { InvoiceDTO } from "../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { useMainFormikConfig } from "./shared/components/CommonInvoicesForm/hooks/useMainFormikConfig";
import { CommonInvoicesFormValues } from "./shared/types/form/general.types";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonInvoicesForm } from "./shared/components/CommonInvoicesForm";
import { FullPageLoading } from "../../../../../shared/common/react/components/ui/FullPageLoading";
import { ProjectWithRelatedInfoDTO } from "../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { getProjectByIdWithRelatedInfo } from "../../../../../services/projects/projects.service";
import { InvoiceFilesTypeEnum } from "../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { InvoicesStatusEnum } from "../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { InvoiceFilesDTO } from "../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { HandleUpdateInvoice } from "./shared/functions/HandleUpdateInvoice";
import { InvoicesSuperUserContext } from "./shared/contexts/InvoicesSuperUserContext";

type OwnParams = {
  id: string;
};

export const InvoicesViewPageWithinContext = () => {
  const { t, i18n } = useTranslation();

  const { canEditAllInvoicesButton, canEditAllInvoices } = useContext(
    InvoicesSuperUserContext
  );

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [invoiceData, setInvoiceData] = useState<InvoiceDTO | null>(null);
  const [invoiceFilesData, setInvoiceFilesData] = useState<InvoiceFilesDTO[]>(
    []
  );
  const [projectWithRelatedInfo, setProjectWithRelatedInfo] =
    useState<ProjectWithRelatedInfoDTO | null>(null);
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formikRef = useRef<FormikProps<CommonInvoicesFormValues>>(null);

  const { initialValues, validationSchema } = useMainFormikConfig({
    projectWithRelatedInfo,
  });

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  const loadPage = useCallback(async () => {
    setLoading(true);
    setPageTitle(t("invoice.keywords.fields.invoiceView"));

    if (!id) {
      setError("invoice.errors.data.general.invoiceDoesNotExist");
      setLoading(false);
      return;
    }

    try {
      const invoice = await getInvoiceById(id);
      if (!invoice) {
        setError("invoice.errors.data.general.invoiceDoesNotExist");
        return;
      }

      const project = await getProjectByIdWithRelatedInfo(
        invoice.costCenterPep.project.id
      );

      if (!invoice) {
        setError("Project does not exist.");
        return;
      }

      setProjectWithRelatedInfo(project);
      setInvoiceData(invoice);
      if (invoice.status === InvoicesStatusEnum.NotReleased)
        setError("Something happened. :(");
    } catch (error) {
      // Deal with error
      console.error(error);
      setError("Something happened. :(");
    } finally {
      setLoading(false);
    }
  }, [t, i18n.language, canEditAllInvoices]);

  const formikOnSubmit = async (values: CommonInvoicesFormValues) => {
    if (
      values.status?.id === InvoicesStatusEnum.Issued &&
      values.invoiceFilesToUpload.length < 1 &&
      invoiceFilesData.filter(
        (x) => x.invoiceFilesType === InvoiceFilesTypeEnum.Issued
      ).length < 1
    ) {
      notifyError(t("invoice.errors.data.update.evidenceIsRequired"));
      return;
    }

    setLoading(true);

    try {
      await HandleUpdateInvoice({ invoiceData, values, canEditAllInvoices });

      await loadPage();

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "invoice.errors.data.general.invoiceDoesNotExist",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPage();
  }, [i18n.language]);

  if (loading) {
    return <FullPageLoading />;
  }

  if (error) {
    return <div>{t(error)}</div>;
  }

  if (invoiceData && projectWithRelatedInfo) {
    return (
      <>
        {canEditAllInvoicesButton}
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <CommonInvoicesForm
              formikProps={formikProps}
              invoiceData={invoiceData}
              projectWithRelatedInfo={projectWithRelatedInfo}
              invoiceFilesData={invoiceFilesData}
              setInvoiceFilesData={setInvoiceFilesData}
              reloadPage={loadPage}
            />
          )}
        </Formik>
      </>
    );
  }

  return <div>{t("general.errors.fetch.failedToFetchData")}</div>;
};
