import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { useFormikConfig } from "./useFormikConfig";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CreationEditingForm } from "../components/CreationEditingForm";
import { createUserResume } from "../../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { UploadTextButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/UploadTextButton";
import { Language } from "../../../../../../../../shared/common/enums/locale/Language.enum";

interface OwnProps {
  language: Language;
}

export const useCreationForm = ({ language }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();
  const { loadPage } = useContext(ProfileContext);

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return (
          <div>{t("collaboratorProfile.actions.data.general.addResume")}</div>
        );
      },
      modalMode: "dialog",
    },
    button: {
      FormButton: ({ onClick }) => (
        <UploadTextButton
          buttonText={
            language === Language.ptBR
              ? t(
                  "collaboratorProfile.actions.data.general.attachResumeInPortuguese"
                )
              : t(
                  "collaboratorProfile.actions.data.general.attachResumeInEnglish"
                )
          }
          onClick={onClick}
        />
      ),
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => <CreationEditingForm {...rest} />,
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          if (!formValues.resumeFile) {
            notifyIf4xxApiErrorDTO({
              error: "collaboratorProfile.errors.data.general.mustAttachResume",
              defaultMessage:
                "collaboratorProfile.errors.data.general.mustAttachResume",
            });
            setSubmitting(false);
            return;
          }

          await createUserResume([formValues.resumeFile as File], language);
          closeModal();
          await loadPage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "collaboratorProfile.errors.data.create.failedToAddResume",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
