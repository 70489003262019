import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode, FormValues } from "../../../types/form.types";
import { StyledFormContainer } from "./indes.styles";
import { HorizontalAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/horizontal/HorizontalAutocompleteFormik";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  mode: FormMode;
};

export const CommonDisciplineForm = ({
  formikProps: { isSubmitting },
  mode,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const datePattern = i18n.language === "pt-BR" ? "dd/MM/yyyy" : "MM/dd/yyyy";

  return mode === "editing" ? (
    <StyledFormContainer>
      <TextFieldFormik
        name="name"
        label={t("disciplines.keywords.general.discipline")}
        required
        disabled={isSubmitting}
      />

      <HorizontalAutocompleteFormik
        name="horizontal"
        autocompleteProps={{ disabled: isSubmitting }}
        blockGeneral={false}
      />

      <DatePickerFormik
        name="deactivatedDate"
        datePickerProps={{
          views: ["year", "month", "day"],
          label: t("jobRoles.keywords.general.deactivatedDate"),
          format: datePattern,
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: new Date(),
          disabled: isSubmitting,
        }}
      />
    </StyledFormContainer>
  ) : (
    <StyledFormContainer>
      <TextFieldFormik
        name="name"
        label={t("disciplines.keywords.general.discipline")}
        required
        disabled={isSubmitting}
      />
      <HorizontalAutocompleteFormik
        name="horizontal"
        autocompleteProps={{ disabled: isSubmitting }}
        blockGeneral={false}
      />
    </StyledFormContainer>
  );
};
