import { FormikProps, FormikValues } from "formik";
import {
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useModalButton,
  UseModalButtonRef,
} from "../../../../../modal/modalButtons/useModalButton";
import { UseFormikModalButtonProps } from "../shared/types/props.types";
import { useModalContent } from "../hooks/useModalContent";
import { useModalActions } from "../hooks/useModalActions";
import { useFinalModalTitle } from "../hooks/useFinalModalTitle";
import { useActions } from "../hooks/useActions";
import { useCreateModalButtonContainer } from "../hooks/useCreateModalButtonContainer";
import { useInternalFormButton } from "../hooks/useInternalFormButton";
import { useEffectAfterRenders } from "../../../../../../hooks/enhancedReactHooks/useEffectAfterRenders";

export const useFormikModalButton = <
  FormValues extends FormikValues,
  InternalFormData = unknown,
  ContentButtonContentProps = unknown,
  FormattedFormValues = null,
>({
  modal: {
    keepModalMounted,
    modalTitle,
    ModalTitle,
    ModalTitleMemo,
    modalColorVariant = "primary",
    modalMode,
    internalLoadingPosition = "content",
    letModalTitleDealWithLoading = false,
    letModalContentDealWithLoading = false,
    letModalTitleDealWithError = false,
    letModalContentDealWithError = false,
    suppressCloseBackdropClick = true,
    onCloseModal: externalOnCloseModal,
    onCloseModalMemo: externalOnCloseModalMemo,
  },
  button: {
    FormButton,
    FormButtonMemo,
    checkShouldShowBadgeDot,
    onInternalValuesChange,
    onClickContentButtonComponent,
    onClickContentButtonComponentMemo,
    onClickContentButton,
    onClickContentButtonMemo,
    createModalButtonContainer,
    createModalButtonContainerMemo,
  } = {},
  form: {
    formikConfig,
    FormContent,
    FormContentMemo,
    FormActions,
    FormActionsMemo,
    getFormattedFormValues,
    onSubmit: externalOnSubmit,
  },
  general: { innerRef, initialInternalFormData = null } = {},
}: UseFormikModalButtonProps<
  FormValues,
  InternalFormData,
  ContentButtonContentProps,
  FormattedFormValues
>) => {
  const [formValues, setFormValues] = useState(formikConfig.initialValues);
  const [internalFormData, setInternalFormData] = useState(
    initialInternalFormData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [isModalDisabled, setIsModalDisabled] = useState(false);
  const [modalError, setModalError] = useState<ReactNode>(null);

  const formikRef = useRef<FormikProps<FormValues>>(null);
  const modalButtonRef = useRef<UseModalButtonRef>(null);
  const formValuesRef = useRef(formValues);
  formValuesRef.current = formValues;

  const {
    closeModal,
    getOpenCloseModalCount,
    increaseOpenCloseModalCount,
    openModal,
    resetFormValues,
    setInternalExternalFormValues,
    submitFormValues,
    checkInCurrentModalCount,
  } = useActions<FormValues, InternalFormData>({
    formikConfig,
    formikRef,
    modalButtonRef,
    setFormValues,
    formValues,
    setInternalFormData,
    setIsLoadingModal,
    setModalError,
  });

  useImperativeHandle(innerRef, () => ({
    closeModal,
    openModal,
    setFormValues: setInternalExternalFormValues,
    resetFormValues,
  }));

  const formattedFormValues = useMemo(() => {
    if (!getFormattedFormValues) return null as unknown as FormattedFormValues;

    return getFormattedFormValues({ formValues });
  }, [formValues]);

  const shouldShowBadgeDot = useMemo(() => {
    if (!checkShouldShowBadgeDot) return false;

    return checkShouldShowBadgeDot(formValues);
  }, [formValues]);

  useEffect(() => {
    if (!internalFormData) return;

    onInternalValuesChange?.({
      internalFormData,
      setFormValues: setInternalExternalFormValues,
    });
  }, [internalFormData]);

  useEffectAfterRenders({
    effect: () => {
      if (formikConfig.enableReinitialize)
        setFormValues(formikConfig.initialValues);
    },
    deps: [formikConfig.initialValues],
    rendersBeforeEffect: 1,
  });

  const finalModalTitle = useFinalModalTitle({
    internalFormData,
    internalLoadingPosition,
    isLoadingModal,
    isModalDisabled,
    letModalTitleDealWithError,
    letModalTitleDealWithLoading,
    modalError,
    ModalTitle,
    ModalTitleMemo,
    modalColorVariant,
    modalTitle,
  });

  const finalCreateModalButtonContainer = useCreateModalButtonContainer({
    getOpenCloseModalCount,
    checkInCurrentModalCount,
    onClickContentButtonComponent,
    onClickContentButtonComponentMemo,
    setInternalExternalFormValues,
    setInternalFormData,
    setIsLoadingModal,
    setIsModalDisabled,
    setModalError,
    createModalButtonContainer,
    createModalButtonContainerMemo,
  });

  const modalContent = useModalContent({
    FormContent,
    FormContentMemo,
    closeModal,
    formValues,
    formikConfig,
    internalFormData,
    internalLoadingPosition,
    isLoadingModal,
    letModalContentDealWithError,
    letModalContentDealWithLoading,
    modalError,
    resetFormValues,
    setFormValues,
    setIsSubmitting,
    submitFormValues,
    externalOnSubmit,
    formikRef,
    modalColorVariant,
    isModalDisabled,
    setIsModalDisabled,
    getOpenCloseModalCount,
    setInternalFormData,
  });

  const modalActions = useModalActions({
    closeModal,
    isLoadingModal,
    isSubmitting,
    modalError,
    resetFormValues,
    submitFormValues,
    FormActions,
    FormActionsMemo,
    setInternalExternalFormValues,
    formValues,
    internalFormData,
    isModalDisabled,
    setIsModalDisabled,
  });

  const InternalFormButton = useInternalFormButton({
    shouldShowBadgeDot,
    FormButton,
    FormButtonMemo,
  });

  const onOpenModal = useCallback(() => {
    setIsModalDisabled(false);
    setModalError(null);
    formikRef.current?.setValues(formValues);
    formikRef.current?.setErrors({});
    increaseOpenCloseModalCount();
  }, [formValues]);

  const finalExternalOnCloseModal = useCallback(() => {
    const finalExternalOnCloseModal =
      externalOnCloseModal ?? externalOnCloseModalMemo;

    finalExternalOnCloseModal?.({
      formValues: formValuesRef.current,
      checkInCurrentModalCount,
      getOpenCloseModalCount,
      setFormValues: setInternalExternalFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
    });
  }, [externalOnCloseModal]);

  const onCloseModal = useCallback(() => {
    increaseOpenCloseModalCount();
    finalExternalOnCloseModal();
  }, [finalExternalOnCloseModal]);

  const menuButtonOnClickContentButton = useMemo(() => {
    const finalOnClickContentButton =
      onClickContentButton ?? onClickContentButtonMemo;

    if (!finalOnClickContentButton) return undefined;

    return () =>
      finalOnClickContentButton({
        formValues: formValuesRef.current,
        checkInCurrentModalCount,
        getOpenCloseModalCount,
        setFormValues: setInternalExternalFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
      });
  }, [onClickContentButton]);

  const {
    contentModal,
    contentButton,
    ModalButtonContainer: ContentButton,
  } = useModalButton({
    general: {
      innerRef: modalButtonRef,
    },
    modal: {
      modalTitle: finalModalTitle,
      modalColorVariant,
      mode: modalMode,
      keepModalMounted,
      modalContent,
      modalActions,
      onOpenModal,
      onCloseModal,
      suppressInternalCloseModal: isSubmitting,
      suppressCloseBackdropClick,
    },
    button: {
      ModalButton: InternalFormButton,
      createModalButtonContainer: finalCreateModalButtonContainer,
      onClickContentButton: menuButtonOnClickContentButton,
    },
  });

  return {
    contentModal,
    contentButton,
    formValues,
    formattedFormValues,
    ContentButton,
    openModal,
  };
};
