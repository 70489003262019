import * as yup from "yup";

export interface UserGroupDTO {
  id: number;
  idUserCollaborator: number;
  name: string;
}

export const schemaUserGroupDTO = yup.object({
  id: yup.number().integer().defined(),
  idUserCollaborator: yup.number().integer().defined(),
  name: yup.string().defined(),
});
