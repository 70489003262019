import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../../common/classes/data/date/DateOnly";

export interface ProjectSummaryInFrameDTO {
  id: number;
  name: string;
  scheduledStartDate: DateOnly | null;
  scheduledEndDate: DateOnly | null;
  finalScheduledEndDate: DateOnly | null;
  billingAmount: number;
  additivesAmount: number;
  billingAmountWithAdditives: number;
  currencySymbol: string;
}

export const schemaProjectSummaryInFrameDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  scheduledStartDate: schemaDateOnly.nullable().defined(),
  scheduledEndDate: schemaDateOnly.nullable().defined(),
  finalScheduledEndDate: schemaDateOnly.nullable().defined(),
  billingAmount: yup.number().defined(),
  additivesAmount: yup.number().defined(),
  billingAmountWithAdditives: yup.number().defined(),
  currencySymbol: yup.string().defined(),
});
