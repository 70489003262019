import { AutocompleteProps, TextFieldProps } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikRef,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CountryDTO } from "../../../../../../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { cachedCountries } from "../../../../../../objects/data/cache/cachedFunctions.objects";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteProps<CountryDTO, Multiple, DisableClearable, FreeSolo>,
    "renderInput" | "options"
  >;
}

export const CountriesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const asyncAutocompleteRef = useRef<AsyncFastAutocompleteFormikRef | null>(
    null
  );

  useEffect(() => {
    asyncAutocompleteRef.current?.rerunFetch();
  }, [i18n.language]);

  return (
    <AsyncAutocompleteFormik
      innerRef={asyncAutocompleteRef}
      name={name}
      customErrorMessage={t("countries.errors.data.fetch.failedToFetch")}
      fetchOptionsMemo={cachedCountries.run}
      textfieldProps={{
        label: t("countries.keywords.general.country"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (option) => option.name,
        isOptionEqualToValueMemo: (option, value) => option.code === value.code,
        ...autocompleteProps,
      }}
    />
  );
};
