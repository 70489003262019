import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useCreationEditingFormikConfig } from "./hooks/useFormikConfig";
import { useFormikModalButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ProjectionAdjustmentMonthlyCommentFormValues } from "./types/ProjectionAdjustmentMonthlyCommentFormValues";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommentFormButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/CommentFormButton";
import { sleep } from "../../../../../../../../../../../shared/common/helpers/general.helpers";
import { InternalFormDataProps } from "../../../../../types/form.types";
import { MonthlyCommentCommomForm } from "./components/MonthlyCommentCommonForm";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingRowComment = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useCreationEditingFormikConfig();
  const { t } = useTranslation();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      ProjectionAdjustmentMonthlyCommentFormValues,
      InternalFormDataProps | null,
      InternalFormDataProps
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => (
          <div>{t("projection.keywords.fields.comment")}</div>
        ),
      },
      button: {
        FormButton: CommentFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: { comment, formikProps, row, name },
          setFormValues,
          setIsLoadingModal,
          setInternalFormData,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            await sleep(300);
            setFormValues({
              comment: comment || "",
            });
            setInternalFormData({ comment, formikProps, row, name });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "projection.errors.data.fetch.failedToFetchProjection",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: MonthlyCommentCommomForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          closeModal();
          setSubmitting(false);
          if (internalFormData) {
            internalFormData.formikProps.setFieldValue(
              `adjustmentLines[${internalFormData.row}].comment`,
              formValues.comment
            );
          }
          reloadTablePage();
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
