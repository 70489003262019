import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { getCostCenterById } from "../../../../../../../services/costCenter/costCenter.service";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { CostCenterDTO } from "../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CostCenterContent } from "./CostCenterContent";

export const useViewingForm = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData: costCenter }) => {
        if (!costCenter) return null;

        const costCenterName = costCenter?.name ?? String(costCenter.id);

        return (
          <Trans
            i18nKey="general.modal.data.view.detailsOfName"
            values={{ name: costCenterName }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData: costCenter }) => (
        <CostCenterContent costCenterDTO={costCenter ?? undefined} />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ViewModalButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: costCenterData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const costCenterDTO = await getCostCenterById(costCenterData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!costCenterDTO)
            return setModalError(
              i18next.t(
                "costCenters.errors.data.general.costCenterDoesNotExist"
              )
            );

          setInternalModalData(costCenterDTO);
        } catch (error) {
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "costCenters.errors.data.fetch.failedToFetchCostCenter",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    general: {
      initialInternalModalData: null as CostCenterDTO | null,
    },
  });

  return {
    ViewingButtonContainer,
    viewingModal,
  };
};
