import * as yup from "yup";

export enum Country {
  BR = "BR",
  US = "US",
}

export const schemaCountry = yup
  .mixed<Country>()
  .oneOf(Object.values(Country) as Country[]);
