import { Help as HelpIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../types/core/typescript.types";
import { SimpleIconButton, SimpleIconButtonProps } from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const HelpIconButton = ({
  tooltipText = "general.keywords.general.help",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={HelpIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
