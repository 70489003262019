import * as yup from "yup";
import { useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "./shared/types/filter.types";
import { FilterFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { createWorkloadFiltersDTO } from "../../../../../../shared/specific/DTOs/workload/filters/WorkloadFiltersDTO";
import { WorkloadsFilterForm } from "./WorkloadsFilterForm";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      name: "",
      onlyActivated: true,
    };

    const validationSchema = yup.object({
      name: yup.string(),
      deactivatedDate: yup.string(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        createWorkloadFiltersDTO({
          name: formValues.name || undefined,
          onlyActivated: formValues.onlyActivated,
        }),
      FormContentMemo: WorkloadsFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
