import * as yup from "yup";
import {
  ProjectSummaryHeaderDTO,
  schemaProjectSummaryHeaderDTO,
} from "./ProjectSummaryHeaderDTO";
import {
  ProjectSummaryMonthlyAmountsDTO,
  schemaProjectSummaryMonthlyAmountsDTO,
} from "./ProjectSummaryMonthlyAmountsDTO";
import {
  ProjectSummaryMonthlyExpensesDTO,
  schemaProjectSummaryMonthlyExpensesDTO,
} from "./ProjectSummaryMonthlyExpensesDTO";

export interface ProjectSummaryCompleteDTO {
  header: ProjectSummaryHeaderDTO;
  monthlyAmounts: ProjectSummaryMonthlyAmountsDTO;
  monthlyExpenses: ProjectSummaryMonthlyExpensesDTO;
}

export const schemaProjectSummaryCompleteDTO = yup.object({
  header: schemaProjectSummaryHeaderDTO.defined(),
  monthlyAmounts: schemaProjectSummaryMonthlyAmountsDTO.defined(),
  monthlyExpenses: schemaProjectSummaryMonthlyExpensesDTO.defined(),
});
