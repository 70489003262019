import {
  LanguageDTO,
  schemaLanguageDTO,
} from "../../../shared/specific/DTOs/language/LanguageDTO";
import { riseApi } from "./connection";

export const getDefaultLanguage = async (): Promise<LanguageDTO | null> => {
  const { data: defaultLanguage } = await riseApi.get(
    "Languages/DefaultLanguage"
  );

  return schemaLanguageDTO.nullable().validateSync(defaultLanguage);
};
