import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import {
  ContractRoleDTO,
  schemaContractRoleDTO,
} from "../ContractRole/ContractRoleDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import { JobRoleDTO, schemaJobRoleDTO } from "../jobRole/JobRoleDTO";
import { OfficeDTO, schemaOfficeDTO } from "../Office/OfficeDTO";
import {
  HorizontalDTO,
  schemaHorizontalDTO,
} from "../horizontal/HorizontalDTO";

export interface CollaboratorHistoryDTO {
  id: number;
  idUserCollaborator: number;
  idOffice: number;
  idJobRole: number | null;
  idContractRole: number;
  idCostCenter: number;
  startYearMonth: YearMonth | null;
  registryNumber: string;
  jobRole: JobRoleDTO | null;
  contractRole: ContractRoleDTO | null;
  horizontal: HorizontalDTO | null;
  costCenter: CostCenterDTO;
  office: OfficeDTO;
  version: string;
  isCurrent: boolean;
}

export const schemaCollaboratorHistoryDTO = yup.object({
  id: yup.number().integer().defined(),
  idUserCollaborator: yup.number().integer().defined(),
  idOffice: yup.number().integer().defined(),
  idJobRole: yup.number().integer().nullable().defined(),
  idContractRole: yup.number().integer().defined(),
  idCostCenter: yup.number().integer().defined(),
  startYearMonth: schemaYearMonth.nullable().defined(),
  registryNumber: yup.string().defined(),
  jobRole: schemaJobRoleDTO.nullable().defined(),
  contractRole: schemaContractRoleDTO.nullable().defined(),
  horizontal: schemaHorizontalDTO.nullable().defined(),
  costCenter: schemaCostCenterDTO.defined(),
  office: schemaOfficeDTO.defined(),
  version: yup.string().defined(),
  isCurrent: yup.boolean().defined(),
});
