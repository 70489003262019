import { useContext, useMemo } from "react";
import { TableContext } from "../shared/contexts/TableContext";

export const EnhancedTableFooter = () => {
  const { footer } = useContext(TableContext);

  const footerContent = useMemo(() => {
    if (footer?.content) return <div>{footer.content}</div>;

    if (footer?.Content)
      return (
        <div>
          <footer.Content />
        </div>
      );

    return null;
  }, [footer?.content, footer?.Content]);

  return footerContent;
};
