import { endOfDay, endOfMonth, isValid, parse, startOfMonth } from "date-fns";
import * as yup from "yup";
import { ValueError } from "../../../errors/general/ValueError";

export class DateOnly {
  public readonly day: number;
  public readonly month: number;
  public readonly year: number;

  constructor(day: number, month: number, year: number) {
    this.day = day;
    this.month = month;
    this.year = year;
  }

  public static createFromNow = () => {
    return DateOnly.createFromDate(new Date());
  };

  public static createFromDate = (date: Date) => {
    return new DateOnly(date.getDate(), date.getMonth(), date.getFullYear());
  };

  public static createFromString = (date: string) => {
    const parsedDate = parse(date, "yyyy-MM-dd", new Date());

    if (!isValid(parsedDate)) throw new ValueError("Invalid date format.");

    return DateOnly.createFromDate(parsedDate);
  };

  public toDate = () => {
    return new Date(this.year, this.month, this.day);
  };

  public toDateEndOfDay = () => {
    return endOfDay(this.toDate());
  };

  public toStartOfMonth = () => {
    return DateOnly.createFromDate(startOfMonth(this.toDate()));
  };

  public toEndOfMonth = () => {
    return DateOnly.createFromDate(endOfMonth(this.toDate()));
  };

  public toJSON = () => {
    return this.toString();
  };

  public toString = () => {
    const day = String(this.day).padStart(2, "0");
    const month = String(this.month + 1).padStart(2, "0");
    const year = String(this.year).padStart(4, "0");

    return `${year}-${month}-${day}`;
  };

  public equals = (other: DateOnly) => {
    return (
      this.year === other.year &&
      this.month === other.month &&
      this.day === other.day
    );
  };

  public greaterThan = (other: DateOnly) => {
    if (this.year > other.year) return true;
    if (this.year < other.year) return false;

    if (this.month > other.month) return true;
    if (this.month < other.month) return false;

    return this.day > other.day;
  };

  public lessThan = (other: DateOnly) => {
    if (this.year < other.year) return true;
    if (this.year > other.year) return false;

    if (this.month < other.month) return true;
    if (this.month > other.month) return false;

    return this.day < other.day;
  };

  public greaterThanOrEqual = (other: DateOnly) => {
    return this.equals(other) || this.greaterThan(other);
  };

  public lessThanOrEqual = (other: DateOnly) => {
    return this.equals(other) || this.lessThan(other);
  };
}

export const schemaDateOnly = yup
  .object({
    day: yup.number(),
    month: yup.number(),
    year: yup.number(),
    toString: yup.object(),
  })
  .transform((value, originalValue) => {
    if (originalValue === null) return null;
    if (originalValue instanceof Date)
      return DateOnly.createFromDate(originalValue);
    if (originalValue instanceof DateOnly) return originalValue;

    if (typeof originalValue !== "string")
      throw new yup.ValidationError("dateOnly.invalid", originalValue);

    try {
      return DateOnly.createFromString(originalValue);
    } catch (error) {
      console.error(error);
      throw new yup.ValidationError("dateOnly.invalid", originalValue);
    }
  }) as unknown as yup.ObjectSchema<DateOnly>;
