import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SkillCertificationDataFormValues } from "../../../../types/form.types";

export const useCreationEditingDefaultFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: SkillCertificationDataFormValues = useMemo(() => {
    return {
      name: "",
      provider: "",
      certificationProgram: false,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      provider: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      certificationProgram: yup
        .boolean()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
