import { TableBody, TableCell } from "@mui/material";
import { useContext, useMemo } from "react";
import lodash from "lodash";
import { EnhancedRowData } from "../shared/types/data.types";
import { EnhancedTableRow } from "./EnhancedTableRow";
import { TableContext } from "../shared/contexts/TableContext";
import { StyledFillerTableRow } from "./styles/StyledFillerTableRow.styles";

export const EnhancedTableBody = () => {
  const {
    sorting: { order, orderBy },
    pagination,
    ui: { fixedcellheight },
    headCells,
    rows,
    filter: { filterRows },
  } = useContext(TableContext);

  const filteredRows = useMemo(() => {
    if (!rows) return null;
    if (!filterRows || pagination?.usesExternalDataFetching) return rows;

    return rows.filter(filterRows);
  }, [rows, filterRows, pagination?.usesExternalDataFetching]);

  const sortedRows = useMemo(() => {
    if (!filteredRows) return null;

    if (orderBy === null || pagination?.usesExternalDataFetching) {
      return filteredRows;
    }

    let sortedRows: EnhancedRowData[] = lodash.sortBy(
      filteredRows,
      (x) => x.cells[orderBy].value
    );

    if (order === "desc") sortedRows.reverse();

    sortedRows = lodash.flatten(
      lodash
        .chain(sortedRows)
        .partition(
          (x) =>
            x.cells[orderBy].value !== null && x.cells[orderBy].value !== ""
        )
        .value()
    );

    return sortedRows;
  }, [filteredRows, order, orderBy, pagination?.usesExternalDataFetching]);

  const paginatedRows = useMemo(() => {
    if (!sortedRows) return null;

    if (!pagination || pagination?.usesExternalDataFetching) {
      return sortedRows;
    }

    const { page, rowsPerPage } = pagination;

    return sortedRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [
    sortedRows,
    pagination?.page,
    pagination?.rowsPerPage,
    pagination?.usesExternalDataFetching,
  ]);

  const emptyRowsComponent = useMemo(() => {
    if (!paginatedRows) return null;
    if (!pagination || fixedcellheight === undefined) return null;

    const emptyRows = pagination.rowsPerPage - paginatedRows.length;

    if (emptyRows === 0) return null;
    if (pagination.page === 0) return null;

    return (
      <StyledFillerTableRow
        emptyRows={emptyRows}
        fixedcellheight={fixedcellheight}
      >
        <TableCell colSpan={headCells.length} />
      </StyledFillerTableRow>
    );
  }, [
    paginatedRows?.length,
    fixedcellheight,
    pagination?.rowsPerPage,
    pagination?.page,
    headCells.length,
  ]);

  const enhancedTableBody = useMemo(() => {
    return (
      <TableBody>
        {paginatedRows?.map((row) => (
          <EnhancedTableRow key={row.id} row={row} />
        ))}
        {emptyRowsComponent}
      </TableBody>
    );
  }, [paginatedRows, emptyRowsComponent]);

  return enhancedTableBody;
};
