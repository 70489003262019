import * as yup from "yup";

export interface TaxDTO {
  id: number;
  name: string;
  value: number;
  idSubsidiary: number;
  idCostCenterPep: number;
  version?: string;
}

export const schemaTaxDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  value: yup.number().defined(),
  idSubsidiary: yup.number().integer().defined(),
  idCostCenterPep: yup.number().integer().defined(),
  version: yup.string().defined(),
});
