import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { JobRateDTO } from "../../../../../../shared/specific/DTOs/jobRate/JobRateDTO";
import { JobRateDataFormValues } from "../shared/types/form.types";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getJobRateById,
  updateJobRate,
} from "../../../../../../services/jobRate/jobRate.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { JobRateDataForm } from "../components/JobRateDataForm";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { JobRateUpdateDTO } from "../../../../../../shared/specific/DTOs/jobRate/JobRateUpdateDTO";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { SubsidiaryWithBusinessRule } from "../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EditingButtonContainer: (props: JobRateDTO) => JSX.Element | null;
  editingModal: JSX.Element | null;
};

export const useEditingForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t, i18n } = useTranslation();

  const formikConfig = useCreationEditingDefaultFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<JobRateDataFormValues, JobRateDTO, JobRateDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: jobRate }) => (
          <Trans
            i18nKey="jobRates.modal.data.edit.titleEditName"
            values={{
              name:
                jobRate?.jobRole.jobRoleTranslations.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? jobRate?.jobRole.name,
            }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: jobRateRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const jobRateDTO = await getJobRateById(jobRateRow.id);
            if (!jobRateDTO)
              return setModalError(
                i18next.t("jobRates.errors.data.general.jobRateDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(jobRateDTO);
            setFormValues({
              jobRole: jobRateDTO.jobRole,
              subsidiary: jobRateDTO.subsidiary,
              startYearMonth: jobRateDTO.startYearMonth.toDate(),
              monthlyRate: String(jobRateDTO.monthlyRate),
              monthlyTax: String(jobRateDTO.monthlyTax),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "jobRates.errors.data.fetch.failedToFetchJobRate",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => (
          <JobRateDataForm {...props} mode="editing" />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: jobRate,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!jobRate)
            throw new Error("Job Rate cannot be null on submission.");

          if (!formValues.jobRole || !formValues.subsidiary)
            throw new Error("Required values are missing.");

          try {
            await updateJobRate(
              convertTo<JobRateUpdateDTO>({
                id: jobRate.id,
                idJobRole: formValues.jobRole.id,
                idSubsidiary: formValues.subsidiary.id,
                monthlyRate: parseLocaleNumber(formValues.monthlyRate),
                monthlyTax:
                  formValues.subsidiary?.name ===
                  SubsidiaryWithBusinessRule.RDXBR
                    ? parseLocaleNumber(formValues.monthlyTax)
                    : null,
                startYearMonth: YearMonth.createFromDate(
                  formValues.startYearMonth ?? new Date()
                ),
                version: jobRate.version,
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (jobRate.jobRole?.name)
              return notifyError(
                <Trans
                  i18nKey="jobRates.errors.data.edit.failedToEdit"
                  values={{ name: jobRate.jobRole?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("jobRates.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData: null as JobRateDTO | null,
      },
    });

  return { EditingButtonContainer, editingModal };
};
