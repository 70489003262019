import { ReactNode, createContext, useMemo, useState } from "react";
import { BudgetContextValues } from "./index.types";
import { BudgetDTO } from "../../../../../shared/specific/DTOs/Budget/BudgetDTO";

interface OwnProps {
  children: ReactNode;
}

export const BudgetContext = createContext<BudgetContextValues>({
  budget: null,
  setBudget: () => null,
});

export const BudgetContextProvider = ({ children }: OwnProps) => {
  const [budget, setBudget] = useState<BudgetDTO | null>(null);

  const budgetContext: BudgetContextValues = useMemo(
    () => ({
      budget,
      setBudget,
    }),
    [budget]
  );

  return (
    <BudgetContext.Provider value={budgetContext}>
      {children}
    </BudgetContext.Provider>
  );
};
