import * as yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import { useMemo } from "react";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { FormValues } from "../shared/types/form.types";
import { AccessGroupsDTO } from "../../../../../../shared/specific/DTOs/accessGroups/AccessGroupsDTO";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getAccessGroupById,
  updateAccessGroup,
} from "../../../../../../services/accessGroups/accessGroups.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonAccessGroupsForm } from "../shared/react/components/CommonAccessGroupsForm";
import { AccessGroupUpdateDTO } from "../../../../../../shared/specific/DTOs/accessGroups/AccessGroupUpdateDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: FormValues = {
      name: "",
    };

    const validationSchema = yup.object({
      name: yup
        .string()
        .defined(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      FormValues,
      AccessGroupsDTO,
      { accessGroup: AccessGroupsDTO }
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: accessGroup }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: accessGroup?.name ?? "-",
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: { accessGroup },
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const accessGroupData = await getAccessGroupById(accessGroup.id);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!accessGroupData)
              return setModalError(
                t("accessGroups.errors.data.general.accessGroupDoesNotExist")
              );

            setInternalFormData(accessGroupData);
            setFormValues({
              name: accessGroupData.name,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "accessGroups.errors.data.fetch.failedToFetchAccessGroup",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonAccessGroupsForm {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedAccessGroup: AccessGroupUpdateDTO = {
              id: internalFormData?.id ?? 0,
              name: formValues?.name ?? "",
            };

            await updateAccessGroup(updatedAccessGroup);
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "accessGroups.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
