import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { TextField } from "@mui/material";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { WaccDTO } from "../../../../../../../shared/specific/DTOs/wacc/WaccDTO";
import {
  deleteWacc,
  getWaccById,
} from "../../../../../../../services/wacc/wacc.service";
import { StyledDeletionForm } from "./index.styles";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: () => {
        return (
          <Trans
            i18nKey="wacc.modal.data.delete.title"
            values={{ name: "" }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) => {
        return (
          <StyledDeletionForm>
            <TextField
              value={internalModalData?.yearMonth.toPrettyString()}
              label={t("wacc.keywords.fields.yearMonth")}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              value={formatNumber(internalModalData?.plannedTax ?? 0, {
                fractionDigits: 2,
              })}
              label={t("wacc.keywords.fields.plannedTax")}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              value={formatNumber(internalModalData?.performedTax ?? 0, {
                fractionDigits: 2,
              })}
              label={t("wacc.keywords.fields.performedTax")}
              InputProps={{
                readOnly: true,
              }}
            />
          </StyledDeletionForm>
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData: null as WaccDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: ud,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const waccData = await getWaccById(ud.id);
          if (!waccData)
            return setModalError(
              i18next.t("wacc.errors.data.general.waccDoesNotExist")
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(waccData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "wacc.errors.data.fetch.failedToFetchWacc",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: ud }) => {
        if (!ud) throw new Error("Wacc cannot be null.");

        try {
          await deleteWacc(ud.id, ud.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(t("wacc.errors.data.delete.failedToDelete"));
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
