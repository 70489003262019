import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { getJobRoleById } from "../../../../../../../services/jobRole/jobRole.service";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { JobRoleDTO } from "../../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { JobRoleContent } from "./JobRoleContent";

export const useViewingForm = () => {
  const {
    ModalButtonContainer: ViewingButtonContainer,
    contentModal: viewingModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData: jobRole }) => {
        if (!jobRole) return null;

        const jobRoleName = jobRole?.name ?? String(jobRole.id);

        return (
          <Trans
            i18nKey="general.modal.data.view.detailsOfName"
            values={{ name: jobRoleName }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData: jobRole }) => (
        <JobRoleContent jobRoleDTO={jobRole ?? undefined} />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ViewModalButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: jobRoleData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const jobRoleDTO = await getJobRoleById(jobRoleData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!jobRoleDTO)
            return setModalError(
              i18next.t("jobRoles.errors.data.general.jobRoleDoesNotExist")
            );

          setInternalModalData(jobRoleDTO);
        } catch (error) {
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "jobRoles.errors.data.fetch.failedToFetchJobRole",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    general: {
      initialInternalModalData: null as JobRoleDTO | null,
    },
  });

  return {
    ViewingButtonContainer,
    viewingModal,
  };
};
