import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useContext, useMemo } from "react";
import { SimpleIconButton } from "../../../../general/buttons/simpleIconButtons/SimpleIconButton";
import { CarouselContext } from "../CarouselContext";

export const CarouselLeftButton = () => {
  const { addMonthsOffsetFirstYearMonth, checkCanMoveLeft } =
    useContext(CarouselContext);

  const isDisabled = useMemo(() => {
    return !checkCanMoveLeft(1);
  }, [checkCanMoveLeft]);

  return (
    <SimpleIconButton
      Icon={ChevronLeftIcon}
      onClick={() => addMonthsOffsetFirstYearMonth(-1)}
      disabled={isDisabled}
    />
  );
};
