import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { createCostCenterPep } from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import {
  getPipedriveDealCostCenterByRiseId,
  getProjectById,
} from "../../../../../../../services/projects/projects.service";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CostCenterPepCreateDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepCreateDTO";
import { ProjectDTO } from "../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCostCenterPepForm } from "../../shared/react/components/content/CommonCostCenterPepForm";
import { useFormFormikConfig } from "../../shared/react/hooks/useFormFormikConfig";
import { CostCenterPepsFormValues } from "../../shared/types/data/form.types";
import { HandleTaxes } from "./utils/HandleTaxes";
import { SubsidiaryWithBusinessRule } from "../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  idProject: number;
  reloadTable: () => void;
}

export const useCreationForm = ({ reloadTable, idProject }: OwnProps) => {
  const { t } = useTranslation();
  const { initialValues, validationSchema } = useFormFormikConfig();

  const formikConfig: EntryFormikConfig<CostCenterPepsFormValues> =
    useMemo(() => {
      return {
        initialValues,
        validationSchema,
      };
    }, [initialValues, validationSchema]);

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<CostCenterPepsFormValues, ProjectDTO>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("costCenterPeps.modal.data.creation.title"),
      },
      button: {
        FormButton: AddFormButton,
        onClickContentButtonMemo: async ({
          setIsLoadingModal,
          getOpenCloseModalCount,
          setModalError,
          checkInCurrentModalCount,
          setInternalFormData,
          setFormValues,
        }) => {
          setIsLoadingModal(true);
          const startingModalCount = getOpenCloseModalCount();

          try {
            const project = await getProjectById(idProject);

            if (!checkInCurrentModalCount(startingModalCount)) return;

            if (!project)
              return setModalError(
                i18next.t("projects.errors.data.general.projectDoesNotExist")
              );

            const dealCostCenter = await getPipedriveDealCostCenterByRiseId(
              project.id
            );
            setInternalFormData(project);
            if (dealCostCenter) {
              let totalTaxRdxUS = 0;
              if (
                dealCostCenter.costCenter?.subsidiary.name ===
                SubsidiaryWithBusinessRule.RDXUS
              ) {
                if (dealCostCenter.pis) totalTaxRdxUS += dealCostCenter.pis;
                if (dealCostCenter.cofins)
                  totalTaxRdxUS += dealCostCenter.cofins;
                if (dealCostCenter.icms) totalTaxRdxUS += dealCostCenter.icms;
                if (dealCostCenter.iss) totalTaxRdxUS += dealCostCenter.iss;
              }

              const pepCodeFromDeal =
                dealCostCenter.costCenter?.division &&
                dealCostCenter.costCenter?.subsidiary
                  ? `${dealCostCenter.costCenter?.division.prefix}${dealCostCenter.costCenter?.subsidiary.prefix}`
                  : "";

              setFormValues({
                cofins: {
                  label: dealCostCenter.cofins
                    ? formatNumber(dealCostCenter.cofins, { fractionDigits: 2 })
                    : "",
                },
                icms: dealCostCenter.icms
                  ? formatNumber(dealCostCenter.icms, { fractionDigits: 2 })
                  : "",
                iss: {
                  label: dealCostCenter.iss
                    ? formatNumber(dealCostCenter.iss, { fractionDigits: 2 })
                    : "",
                },
                pis: {
                  label: dealCostCenter.pis
                    ? formatNumber(dealCostCenter.pis, { fractionDigits: 2 })
                    : "",
                },
                pepCode: pepCodeFromDeal,
                subsidiary: dealCostCenter.costCenter?.subsidiary ?? null,
                pepDescription: "",
                cfop: null,
                costCenter: dealCostCenter.costCenter,
                isExempted: null,
                tax:
                  totalTaxRdxUS !== 0
                    ? formatNumber(totalTaxRdxUS, { fractionDigits: 2 })
                    : "",
                costCenterFromDeal: dealCostCenter.costCenterFromDeal,
              });
            }
          } catch (error) {
            if (!checkInCurrentModalCount(startingModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "projects.errors.data.fetch.failedToFetchProject",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonCostCenterPepForm
            {...rest}
            mode="creation"
            projectData={internalFormData ?? undefined}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const costCenterPepCreationDTO: CostCenterPepCreateDTO = {
              idCostCenter: formValues.costCenter?.id ?? 0,
              idProject,
              idSubsidiary: formValues.subsidiary?.id ?? 0,
              isExempted: formValues.isExempted?.id ?? false,
              pepCode: formValues.pepCode,
              pepDescription: formValues.pepDescription,
              taxes: HandleTaxes(formValues),
              idCfop: formValues.cfop?.id || null,
            };

            await createCostCenterPep(costCenterPepCreationDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTable();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenterPeps.errors.data.create.failedToCreateCostCenterPep",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
