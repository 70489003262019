import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { CostCenterPepOnlyDTO } from "../../../../../../../DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { getCostCenterPepsOnly } from "../../../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { CachedParameterizedData } from "../../../../../../../../common/classes/data/cache/CachedParameterizedData";
import { CostCenterPepListFiltersDTO } from "../../../../../../../DTOs/costCenterPeps/filters/CostCenterPepListFiltersDTO";
import {
  AsyncAutocomplete,
  AsyncAutocompleteProps,
} from "../../../../../../../../common/react/components/form/general/autocomplete/general/AsyncAutocomplete";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncAutocompleteProps<
    CostCenterPepOnlyDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: CostCenterPepListFiltersDTO;
  filtersMemo?: CostCenterPepListFiltersDTO;
}

const cachedCostCenterPepsOnly = new CachedParameterizedData({
  callback: (idProject: number) =>
    getCostCenterPepsOnly({ filters: { idProject } }),
  mapRunValueToKey: (value) => value,
  defaultCacheDuration: 600000, // 10 minutes
});

export const CostCenterPepsAutocomplete = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const internalGetCostCenterPeps = useCallback(async () => {
    if (!finalFilters?.idProject)
      throw new Error("'idProject' must be provided.");

    return cachedCostCenterPepsOnly.run({
      value: finalFilters.idProject,
    });
  }, [finalFilters?.idProject]);

  return (
    <AsyncAutocomplete
      customErrorMessage={t(
        "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPeps"
      )}
      fetchOptions={internalGetCostCenterPeps}
      textfieldProps={{
        label: t("costCenterPeps.keywords.general.costCenterPeps"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (costCenterPep) => costCenterPep.pepCode,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
