import * as yup from "yup";

export interface TypeTranslationDTO {
  ptName: string | null;
  enName: string | null;
}

export const schemaTypeTranslationDTO = yup.object({
  ptName: yup.string().defined().nullable(),
  enName: yup.string().defined().nullable(),
});
