import { useField } from "formik";
import { FocusEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MaskedTextField } from "../../../../../../../../../../../../shared/common/react/components/form/general/textField/MaskedTextField";

interface OwnProps {
  name: string;
  required?: boolean;
  disabled?: boolean;
}

export const MaskedCodeFormik = ({ name, required, disabled }: OwnProps) => {
  const { t } = useTranslation();
  const [{ value }, { error }, { setValue }] = useField<string>(name);

  return useMemo(() => {
    return (
      <MaskedTextField
        mask={/^[a-zA-Z1-9]{0,3}$/}
        label={t("customers.keywords.fields.groupCode")}
        textFieldProps={{
          error: !!error,
          helperText: error,
          InputLabelProps: {
            shrink: !!value || undefined,
          },
        }}
        prepare={(text: string) => text.toUpperCase()}
        value={value}
        onBlur={(event: FocusEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
        required={required}
        lazy={false}
        disabled={disabled}
      />
    );
  }, [name, required, value, error, t, disabled]);
};
