import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const useEffectAfterRenders = ({
  effect,
  rendersBeforeEffect,
  deps,
}: {
  effect: EffectCallback;
  rendersBeforeEffect: number;
  deps?: DependencyList;
}) => {
  const rendersCounterRef = useRef(0);

  useEffect(() => {
    rendersCounterRef.current++;
    if (rendersCounterRef.current <= rendersBeforeEffect) return;

    return effect();
  }, deps);
};
