import { Box, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SkillCertificationDTO } from "../../../../../../../DTOs/skillCertification/SkillCertificationDTO";
import { getSkillCertificationsDropdown } from "../../../../../../../../../services/skillCertification/skillCertification.service";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

export interface DisciplinesSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SkillCertificationDTO,
    DisableClearable
  >["autocompleteProps"];
}

export const SkillCertificationsMultipleAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: DisciplinesSearchAutocompleteFormikProps<DisableClearable>) => {
  const { t, i18n } = useTranslation();

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "collaboratorProfile.errors.data.fetch.failedToFetchCertification"
      )}
      fetchOptions={getSkillCertificationsDropdown}
      textfieldProps={{
        label: t("collaboratorProfile.keywords.fields.certification"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.name}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.name}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
