import * as yup from "yup";
import {
  ExpenseGroupDTO,
  schemaExpenseGroupDTO,
} from "../ExpenseGroup/ExpenseGroupDTO";
import {
  ExpenseTypeDTO,
  schemaExpenseTypeDTO,
} from "../ExpenseType/ExpenseTypeDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import {
  AdjustmentYearMonthValueDTO,
  schemaAdjustmentYearMonthValueDTO,
} from "./ProjectionAdjustmentYearMonthValueDTO";
import {
  ProjectionAdjustmentCommentDto,
  schemaProjectionAdjustmentCommentDto,
} from "../ProjectionAdjustmentComment/ProjectionAdjustmentCommentDto";

export interface AdjustmentLineDTO {
  costCenter: CostCenterDTO;
  accountCode: string | null;
  customerTrigram: string | null;
  clientAccount: string | null;
  expenseType: ExpenseTypeDTO;
  expenseGroup: ExpenseGroupDTO;
  yearMonthValues: AdjustmentYearMonthValueDTO[];
  projectionAdjustmentComment: ProjectionAdjustmentCommentDto | null;
}

export const schemaAdjustmentLineDTO = yup.object({
  costCenter: schemaCostCenterDTO.defined(),
  accountCode: yup.string().defined().nullable(),
  customerTrigram: yup.string().defined().nullable(),
  clientAccount: yup.string().defined().nullable(),
  expenseType: schemaExpenseTypeDTO.defined(),
  expenseGroup: schemaExpenseGroupDTO.defined(),
  yearMonthValues: yup.array(schemaAdjustmentYearMonthValueDTO).defined(),
  projectionAdjustmentComment: schemaProjectionAdjustmentCommentDto
    .defined()
    .nullable(),
});
