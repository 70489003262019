import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { AccessGroupsDTO, schemaAccessGroupsDTO } from "./AccessGroupsDTO";

export type PaginatedAccessGroupsDTO = PaginationResponse<AccessGroupsDTO>;

export const schemaPaginatedAccessGroupsDTO =
  getSchemaPaginationResponse<AccessGroupsDTO>(schemaAccessGroupsDTO);
