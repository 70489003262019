import { UserAllocationsModifyDTO } from "../../../shared/specific/DTOs/allocation/UserAllocationsModifyDTO";
import { AllocationFiltersDTO } from "../../../shared/specific/DTOs/allocation/filters/AllocationFiltersDTO";
import { riseApi } from "./connection";
import {
  AllocationDTO,
  schemaAllocationDTO,
} from "../../../shared/specific/DTOs/allocation/AllocationDTO";
import { AllocationGetDTO } from "../../../shared/specific/DTOs/allocation/searchParams/AllocationGetDTO";
import { AllocationModifySingleDTO } from "../../../shared/specific/DTOs/allocation/AllocationModifySingleDTO";
import { JobRoleAllocationsCreateDTO } from "../../../shared/specific/DTOs/allocation/JobRoleAllocationsCreateDTO";
import { AllocationGroupAssignToUserDTO } from "../../../shared/specific/DTOs/allocation/AllocationGroupAssignToUserDTO";
import { ProjectAllocationUploadResponseDTO } from "../../../shared/specific/DTOs/allocation/ProjectAllocationUploadResponseDTO";
import {
  AllocationsPerUserDTO,
  schemaAllocationsPerUserDTO,
} from "../../../shared/specific/DTOs/allocation/AllocationsPerUserDTO";

export const getAllocationsPerUser = async ({
  filters,
}: {
  filters?: AllocationFiltersDTO;
} = {}): Promise<AllocationsPerUserDTO> => {
  const { data: allocationsPerUserDTO } = await riseApi.get("/Allocations", {
    params: { ...filters },
  });

  return schemaAllocationsPerUserDTO
    .defined()
    .validateSync(allocationsPerUserDTO);
};

export const getAllocationSingle = async (
  params: AllocationGetDTO
): Promise<AllocationDTO | null> => {
  const { data: allocationUserYearMonth } = await riseApi.get(
    "Allocations/Single",
    { params }
  );

  return schemaAllocationDTO.nullable().validateSync(allocationUserYearMonth);
};

export const modifyUserAllocations = async (
  allocation: UserAllocationsModifyDTO
) => {
  await riseApi.post("Allocations/ToUserColaborator", allocation);
};

export const createJobRoleAllocations = async (
  allocation: JobRoleAllocationsCreateDTO
) => {
  await riseApi.post("Allocations/ToJobRole", allocation);
};

export const modifySingleAllocation = async (
  allocation: AllocationModifySingleDTO
) => {
  await riseApi.put("Allocations/Single", allocation);
};

export const assignUserToAllocationGroup = async (
  allocationGroupDTO: AllocationGroupAssignToUserDTO
) => {
  await riseApi.put(
    "Allocations/AssignAllocationGroupToUser",
    allocationGroupDTO
  );
};

export const deleteAllocations = async (allocationGroup: number) => {
  await riseApi.delete(`Allocations/Group/${allocationGroup}`);
};

export const uploadProjectAllocations = async (
  file: File
): Promise<ProjectAllocationUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: projectUploadResponse } = await riseApi.post(
    "Allocations/ProjectAllocationsFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return projectUploadResponse;
};
