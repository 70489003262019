import * as yup from "yup";
import {
  ContractType,
  schemaContractType,
} from "../../../../../../../../../../../shared/specific/enums/projects/ContractType.enum";

export interface ContractTypeOption {
  id: ContractType;
  label: string;
}

export const schemaContractTypeOption = yup.object({
  id: schemaContractType.defined(),
  label: yup.string().defined(),
});
