import { enviroment } from "../enviroment";

export const config = {
  auth: {
    clientId: enviroment.user.auth.microsoft.clientId,
    authority: enviroment.user.auth.microsoft.authority,
    redirectUri: enviroment.user.auth.microsoft.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

export const apiScopes = [enviroment.user.auth.microsoft.apiScope];

export const graphScopes = ["User.Read"];

export const loginRequest = {
  scopes: apiScopes,
};
