import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";
import { getFontSizeByString } from "../getFontSizeByString";

export const keyword = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const keywordLableCpt: CptProps = {
    text: "Keywords",
    options: {
      align: pres.AlignH.left,
      y: "54%",
      x: "1%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const keywordNameCpt: CptProps = {
    text:
      projectData.projectKeyword.length > 0
        ? projectData.projectKeyword.map((x) => x.name).join(" / ")
        : "-",
    options: {
      align: pres.AlignH.left,
      y: "55%",
      x: "1%",
      w: "48%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: getFontSizeByString(
        projectData.projectKeyword.length > 0
          ? projectData.projectKeyword.map((x) => x.name).join(" / ")
          : "-"
      ),
    },
  };

  slide.addText(keywordLableCpt.text, keywordLableCpt.options);
  slide.addText(keywordNameCpt.text, keywordNameCpt.options);
};
