import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../types/form.types";
import { CountryDTO } from "../../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { maxLengthFields } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/constants/form.constants";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      country: null,
      stateAutocomplete: null,
      stateTextField: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      country: yup
        .object({
          code: yup.string().max(maxLengthFields.country),
        })
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      stateAutocomplete: yup
        .object({
          abbreviation: yup.string().max(maxLengthFields.state),
        })
        .nullable()
        .when("country", (country, schema) => {
          const countryValue = country[0] as unknown as CountryDTO | null;
          if (!countryValue) return schema;
          if (!["BR", "US"].includes(countryValue.code)) return schema;

          return schema.required(
            t("general.errors.data.fields.general.required")
          );
        }),
      stateTextField: yup.string().max(maxLengthFields.state),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
