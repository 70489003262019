import i18next from "i18next";
import { Trans } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import {
  getByIdWithRecentlyHistory,
  turnOnUserCollaborator,
} from "../../../../../../services/user/user.service";
import { UserCollaboratorTurnOnIconButton } from "../shared/react/components/general/buttons/UserCollaboratorTurnOnButton";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { UserCollaboratorRecentlyHistoryDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorRecentlyHistoryDTO";
import { UserCollaboratorTurnOnDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorTurnOnDTO";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useTurnOnForm = ({ reloadTablePage }: OwnProps) => {
  const {
    ModalButtonContainer: TurnOnButtonContainer,
    contentModal: turnOnModal,
  } = useActionButtonDialog({
    modal: {
      ModalTitleMemo: ({ internalModalData: userCollaborator }) => {
        return (
          <Trans
            i18nKey="usersCollaborators.modal.data.turnOn.titleTurnOnName"
            values={{
              name: userCollaborator?.name,
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData:
        null as UserCollaboratorRecentlyHistoryDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <UserCollaboratorTurnOnIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: userCollaboratorData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const collaboratorRecentlyHistoryDTO =
            await getByIdWithRecentlyHistory(userCollaboratorData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!collaboratorRecentlyHistoryDTO)
            return setModalError(
              i18next.t(
                "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist"
              )
            );

          setInternalModalData(collaboratorRecentlyHistoryDTO);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      onConfirmMemo: async ({
        closeModal,
        internalModalData: userCollaborator,
      }) => {
        if (!userCollaborator)
          throw new Error("User Collaborator cannot be null.");

        const userCollaboratorTurnOnDto: UserCollaboratorTurnOnDTO = {
          id: userCollaborator.id,
          version: userCollaborator.version,
        };

        try {
          await turnOnUserCollaborator(userCollaboratorTurnOnDto);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!userCollaborator?.name)
            return notifyError(
              i18next.t("usersCollaborators.errors.data.turnOn.failedToTurnOn")
            );

          notifyError(
            <Trans
              i18nKey="usersCollaborators.errors.data.turnOn.failedToTurnOn"
              values={{ name: userCollaborator.name }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { TurnOnButtonContainer, turnOnModal };
};
