import * as yup from "yup";
import {
  Language,
  schemaLanguage,
} from "../../../../common/enums/locale/Language.enum";

export interface SkillCategoryTranslationDTO {
  id: number;
  idLanguage: Language;
  name: string | null;
}

export const schemaSkillCategoryTranslationDTO = yup.object({
  id: yup.number().integer().defined(),
  idLanguage: schemaLanguage.defined(),
  name: yup.string().nullable().defined(),
});
