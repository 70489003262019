import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { ProjectionFilterContext } from "../shared/context/ProjectionFilterContext";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { StyledTitleContainer } from "../shared/styles/pages/index.styles";
import { expenseGroupTranslationCodeMap } from "../../../../../shared/specific/maps/projection/expenseGroupTranslationCodeMap";
import { ExpenseGroup } from "../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";
import { ProjectionAdjustmentContext } from "./shared/context/projectionAdjustmentContexProvider";
import { getProjectionAdjustmentData } from "../../../../../services/ProjectionAdjustment/ProjectionAdjustment.service";
import { ProjectionFormWithinContext } from "./shared/react/ProjectionFormWithinForwardRef";
import { CommonProjectionFormRef } from "./shared/react/ProjectionFormWithinForwardRef/shared/propsRef.types";
import { ProjectionAdjustmentDTO } from "../../../../../shared/specific/DTOs/ProjectionAdjustment/ProjectionAdjustmentDTO";
import { AdjustmentLineDTO } from "../../../../../shared/specific/DTOs/ProjectionAdjustment/AdjustmentLineDTO";
import { ProjectionYearMonthValue } from "./shared/types/form.types";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { formatNumber } from "../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { useProjectionSearchParams } from "./shared/react/hooks/useProjectionSearchParams";

interface TitleProps {
  subsidiaryName: string;
  divisionName: string;
  segmentGorup: string;
  year: string;
}

export const ProjectionAdjustmentPageWithInContext = () => {
  const { t } = useTranslation();

  const formikRef = useRef<CommonProjectionFormRef>(null);
  const [titleProps, setTitleProps] = useState<TitleProps | null>();

  function generateId() {
    return Math.random().toString(30).substr(2, 12).toString();
  }

  const { filterValues, setFilterValues } = useContext(ProjectionFilterContext);

  const {
    idExpenseGroup,
    idSubsidiary,
    idDivision,
    idSegmentGroup,
    year: yearParams,
    clientAccount,
    customerTrigram,
  } = useProjectionSearchParams();

  const { setLoading, setError, setProjectionAdjustmentInitialValues, error } =
    useContext(ProjectionAdjustmentContext);

  const getKeyObjectValue = useCallback(
    (
      id: ExpenseGroup,
      projectionData: ProjectionAdjustmentDTO
    ): AdjustmentLineDTO[] => {
      switch (id) {
        case ExpenseGroup.GrossRevenue:
          return projectionData.grossRevenue;
        case ExpenseGroup.AdmCorp:
          return projectionData.admCorp;
        case ExpenseGroup.Dol:
          return projectionData.dol;
        case ExpenseGroup.Pdd:
          return projectionData.pdd;
        case ExpenseGroup.SalesCost:
          return projectionData.salesCost;
        case ExpenseGroup.Sgea:
          return projectionData.sgAndA;
        case ExpenseGroup.Tax:
          return projectionData.tax;
        case ExpenseGroup.NetFinancialExpenses:
          return projectionData.netFinancialExpenses;
        case ExpenseGroup.IrCsll:
          return projectionData.irCsll;
        case ExpenseGroup.ParticipationProgram:
          return projectionData.participationProgram;
        default:
          return [];
      }
    },
    []
  );

  const fillAdjustmentCell = useCallback(
    (
      yearMonth: YearMonth,
      line: AdjustmentLineDTO
    ): ProjectionYearMonthValue => {
      const yearMonthValue = line.yearMonthValues.find(
        (yearMonthValue) =>
          yearMonthValue.yearMonth.toDate().getMonth() ===
          yearMonth.toDate().getMonth()
      );
      return {
        id: yearMonthValue?.id ?? null,
        value: yearMonthValue?.value
          ? formatNumber(yearMonthValue.value, { fractionDigits: 2 })
          : formatNumber(0, { fractionDigits: 2 }),
        yearMonth: yearMonth.toDate(),
      };
    },
    []
  );

  const fetchProjectionData = async () => {
    if (!idExpenseGroup) {
      setError("general.errors.data.fields.id.invalid");
      return;
    }

    try {
      const projectionData = await getProjectionAdjustmentData({
        filters: {
          clientAccount:
            filterValues?.clientAccount === ""
              ? clientAccount
              : filterValues?.clientAccount,
          customerTrigram:
            filterValues?.customerTrigram === ""
              ? customerTrigram
              : filterValues?.customerTrigram,
          idDivision: filterValues?.division?.id ?? idDivision ?? 0,
          idGroupSegment: filterValues?.segmentGroup?.id ?? idSegmentGroup ?? 0,
          idSubsidiary: filterValues?.subsidiary?.id ?? idSubsidiary ?? 0,
          year: filterValues?.year?.getFullYear() ?? yearParams ?? 0,
        },
      });

      if (!projectionData) {
        return setError(
          "projection.errors.data.general.projectionDoesNotExist"
        );
      }

      setTitleProps({
        subsidiaryName: projectionData?.subsidiary.name ?? "",
        divisionName: projectionData?.division.name ?? "",
        segmentGorup: projectionData?.segmentGroup.description ?? "",
        year: yearParams?.toString() ?? "",
      });

      setFilterValues({
        clientAccount: clientAccount ?? "",
        customerTrigram: customerTrigram ?? "",
        division: projectionData?.division,
        segmentGroup: projectionData?.segmentGroup,
        subsidiary: projectionData?.subsidiary,
        year: new Date(yearParams ?? 0, 0, 1),
      });

      const expenseGroupDara = idExpenseGroup
        ? getKeyObjectValue(idExpenseGroup, projectionData)
        : [];

      setProjectionAdjustmentInitialValues(projectionData);

      const year = filterValues.year?.getFullYear() ?? yearParams ?? 0;
      const lines = expenseGroupDara.map((line) => {
        return {
          id: generateId(),
          accountCode: line.accountCode ?? "",
          clientAccount: line.clientAccount ?? "",
          customerTrigram: line.customerTrigram ?? "",
          costCenter: line.costCenter,
          expenseType: line.expenseType,
          january: fillAdjustmentCell(new YearMonth(0, year), line),
          february: fillAdjustmentCell(new YearMonth(1, year), line),
          march: fillAdjustmentCell(new YearMonth(2, year), line),
          april: fillAdjustmentCell(new YearMonth(3, year), line),
          may: fillAdjustmentCell(new YearMonth(4, year), line),
          june: fillAdjustmentCell(new YearMonth(5, year), line),
          july: fillAdjustmentCell(new YearMonth(6, year), line),
          august: fillAdjustmentCell(new YearMonth(7, year), line),
          september: fillAdjustmentCell(new YearMonth(8, year), line),
          october: fillAdjustmentCell(new YearMonth(9, year), line),
          november: fillAdjustmentCell(new YearMonth(10, year), line),
          december: fillAdjustmentCell(new YearMonth(11, year), line),
          comment: line.projectionAdjustmentComment?.comment ?? "",
          transferenceCountry: idSubsidiary !== line.costCenter.subsidiary.id,
        };
      });

      formikRef.current?.setFormValues({
        adjustmentLines: lines,
        pipedriveDreExpense:
          projectionData?.pipedriveDreExpense.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        pipedriveDreRevenue:
          projectionData?.pipedriveDreRevenue.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        pipedriveDreTax:
          projectionData?.pipedriveDreTax.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        riseDreExpense:
          projectionData?.riseDreExpense.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        riseDreRevenue:
          projectionData?.riseDreRevenue.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        riseDreTax:
          projectionData?.riseDreTax.map((x) => ({
            ...x,
            id: generateId(),
          })) || [],
        admCorp: projectionData?.admCorp,
        dol: projectionData?.dol,
        grossRevenue: projectionData?.grossRevenue,
        pdd: projectionData?.pdd,
        salesCost: projectionData?.salesCost,
        sgAndA: projectionData?.sgAndA,
        tax: projectionData?.tax,
      });

      return projectionData;
    } catch (error) {
      console.error(error);
      setError("projection.errors.data.fetch.failedToFetchProjection");
    }
  };

  const { isFetching } = useQuery({
    queryKey: [
      "projectionAdjustmentData",
      {
        idExpenseGroup,
        idSubsidiary,
        idDivision,
        idSegmentGroup,
        yearParams,
        clientAccount,
        customerTrigram,
      },
    ],
    queryFn: () => fetchProjectionData(),
    staleTime: Infinity,
  });

  useEffect(() => {
    setPageTitle({
      title: t("projection.pages.main.title"),
      Component() {
        const expenseGroup = idExpenseGroup ? Number(idExpenseGroup) : null;

        const subsidiaryName =
          filterValues?.subsidiary?.name || titleProps?.subsidiaryName
            ? `${filterValues?.subsidiary?.name || titleProps?.subsidiaryName} - `
            : "";
        const divisionName =
          filterValues?.division?.name || titleProps?.divisionName
            ? `${filterValues?.division?.name || titleProps?.divisionName} - `
            : "";
        const segmentGorup =
          filterValues?.segmentGroup?.description || titleProps?.segmentGorup
            ? `${filterValues?.segmentGroup?.description || titleProps?.segmentGorup} - `
            : "";
        const year =
          (filterValues?.year?.getFullYear() ?? titleProps?.year)
            ? `${filterValues?.year?.getFullYear() ?? titleProps?.year}`
            : "";
        const customerTrigramName =
          customerTrigram && customerTrigram !== ""
            ? ` - ${customerTrigram}`
            : "";
        const clientAccountName =
          clientAccount && clientAccount !== "" ? ` - ${clientAccount}` : "";
        return (
          <StyledTitleContainer>
            <span>
              {expenseGroup
                ? t(
                    expenseGroupTranslationCodeMap[
                      idExpenseGroup as unknown as ExpenseGroup
                    ]
                  )
                : "-"}
            </span>
            <span>{`${subsidiaryName}${divisionName}${segmentGorup}${year}${customerTrigramName}${clientAccountName}`}</span>
          </StyledTitleContainer>
        );
      },
    });
  }, [t, titleProps]);

  useEffect(() => setLoading(isFetching), [isFetching]);

  if (error) {
    return error;
  }

  return <ProjectionFormWithinContext ref={formikRef} />;
};
