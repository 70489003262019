import { Typography } from "@mui/material";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { planningFieldsConstants } from "../../../../constants/data/form/constraints.constants";
import { CommonPlanningFormValues } from "../../../../types/data/form/planning.types";
import { StyledForm, StyledFormHeader } from "./index.styles";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { ManagerSearchAutoCompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/ManagerSearchAutoCompleteFormik";
import { TypeOfEvidenceAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/TypeOfEvidenceAutocompleteFormik";

interface OwnProps {
  formikProps: FormikProps<CommonPlanningFormValues>;
}

export const CommonPlanningForm = ({
  formikProps: { isSubmitting, values },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledFormHeader>
        <Typography>
          {t("projects.modal.data.generalPlanning.header")}
        </Typography>
      </StyledFormHeader>
      <StyledForm>
        <DatePickerFormik
          name="scheduledStartDate"
          datePickerProps={{
            views: ["year", "month", "day"],
            label: t("projects.keywords.fields.baselineStart"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            maxDate: values.scheduledEndDate,
            disabled: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
        <DatePickerFormik
          name="scheduledEndDate"
          datePickerProps={{
            views: ["year", "month", "day"],
            label: t("projects.keywords.fields.baselineEnd"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            minDate: values.scheduledStartDate,
            disabled: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
        <UsersSearchAutocompleteFormik
          name="userCoordinator"
          textfieldProps={{
            label: `${t("projects.keywords.fields.coordinator")}`,
            required: true,
          }}
          filtersMemo={{
            isActive: true,
          }}
        />
        <ManagerSearchAutoCompleteFormik
          name="userManager"
          textfieldProps={{
            label: `${t("projects.keywords.fields.manager")}`,
            required: true,
          }}
          filtersMemo={{
            isActive: true,
          }}
        />
        <TypeOfEvidenceAutocompleteFormik
          name="typeOfEvidence"
          textfieldProps={{
            label: t("projects.keywords.fields.revenueTypeOfEvidence"),
            required: true,
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="mv"
          min={0}
          max={planningFieldsConstants.maxMvValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("projects.keywords.fields.commercialMv"),
            required: true,
            disabled: isSubmitting,
            InputProps: {
              endAdornment: "%",
            },
          }}
        />
      </StyledForm>
    </div>
  );
};
