import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { useFormikConfig } from "../../shared/react/shared/hooks/data/validation/useFormikConfig";
import { CommonWaccForm } from "../../shared/react/CommonWaccForm";
import { WaccCreateDTO } from "../../../../../../../shared/specific/DTOs/wacc/WaccCreateDTO";
import { createWacc } from "../../../../../../../services/wacc/wacc.service";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("wacc.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ ...rest }) => (
          <CommonWaccForm formMode="create" {...rest} internalFormData={null} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!formValues.yearMonth || !formValues.plannedTax)
            throw new Error("Required values are missing.");

          try {
            const created: WaccCreateDTO = {
              yearMonth: YearMonth.createFromDate(formValues.yearMonth),
              plannedTax: parseLocaleNumber(formValues.plannedTax),
              performedTax: formValues.performedTax
                ? parseLocaleNumber(formValues.performedTax)
                : 0,
            };

            await createWacc(created);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "wacc.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
