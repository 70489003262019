import { checkIsValidType } from "../../../../common/helpers/data/validation/schema.helpers";
import { convertTo } from "../../../../common/helpers/types/converters.helpers";
import { ApiErrorDTO } from "../../../DTOs/errors/ApiErrorDTO";
import {
  CodeErrorEnums,
  schemaCodeErrorEnums,
} from "../../../enums/errors/apiErrors.enums";
import { apiErrorCodeToTranslationCode } from "../../../maps/errors/apiErrorCodeToTranslationCode.maps";
import { apiErrorCodeToTranslationHandler } from "../../../maps/errors/apiErrorCodeToTranslationHandler/index.maps";
import { TranslationObject } from "../../../types/translations/general.types";

export const getApiErrorCodeHandler = (apiError: ApiErrorDTO) => {
  if (
    !checkIsValidType<CodeErrorEnums>(schemaCodeErrorEnums, apiError.errorCode)
  )
    return null;

  const handler = apiErrorCodeToTranslationHandler[apiError.errorCode];
  if (handler) {
    try {
      return handler(apiError);
    } catch (error) {
      console.error(error);
    }
  }

  if (apiErrorCodeToTranslationCode[apiError.errorCode])
    return convertTo<TranslationObject>({
      code: apiErrorCodeToTranslationCode[apiError.errorCode] ?? "",
      details: apiError.details,
    });

  return null;
};
