import { ListItemButton, styled } from "@mui/material";

export const ListItemTranslationButtonContainer = styled(ListItemButton)`
  .MuiListItemIcon-root {
    & > svg {
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
  }
`;
