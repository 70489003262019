import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  CostCenterOnlyDTO,
  schemaCostCenterOnlyDTO,
} from "../costCenter/CostCenterOnlyDTO";
import { CustomerDTO, schemaCustomerDTO } from "../customers/CustomerDTO";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "../user/UserCollaboratorDTO";

export interface FrameOnlyDTO {
  id: number;
  name: string;
  startDate: DateOnly;
  endDate: DateOnly;
  pepCode: string | null;
  amount: number;
  currency: string;
  currencySymbol: string;
  insertedDate: Date;
  updatedDate: Date;
  division: DivisionDTO;
  customer: CustomerDTO;
  costCenter: CostCenterOnlyDTO | null;
  version: string;
  isLinkedToProject: boolean | null;
  subsidiary: SubsidiaryDTO;
  accountManager: UserCollaboratorDTO | null;
}

export const schemaFrameOnlyDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  startDate: schemaDateOnly.defined(),
  endDate: schemaDateOnly.defined(),
  pepCode: yup.string().nullable().defined(),
  amount: yup.number().defined(),
  currency: yup.string().defined(),
  currencySymbol: yup.string().defined(),
  insertedDate: yup.date().defined(),
  updatedDate: yup.date().defined(),
  division: schemaDivisionDTO.defined(),
  customer: schemaCustomerDTO.defined(),
  costCenter: schemaCostCenterOnlyDTO.nullable().defined(),
  version: yup.string().defined(),
  isLinkedToProject: yup.bool().nullable().defined(),
  subsidiary: schemaSubsidiaryDTO.defined(),
  accountManager: schemaUserCollaboratorDTO.nullable().defined(),
});
