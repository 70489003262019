import { saveAs } from "file-saver";
import i18next from "i18next";
import { format } from "date-fns";
import { CreationProjectsAdditivesDTO } from "../../../shared/specific/DTOs/reports/CreationProjectsAdditivesDTO";
import { GlobalAllocationReportFilterDTO } from "../../../shared/specific/DTOs/reports/GlobalAllocationReportFilterDTO";
import { PerformedTimesheetReportFilterDTO } from "../../../shared/specific/DTOs/reports/PerformedTimesheetReportFilterDTO";
import { ProjectDetailedViewReportFilterDTO } from "../../../shared/specific/DTOs/reports/ProjectDetailedViewReportFilterDTO";
import { ProjectOverviewReportDTO } from "../../../shared/specific/DTOs/reports/ProjectOverviewReportDTO";
import { ProjectStatusReportFilterDTO } from "../../../shared/specific/DTOs/reports/ProjectStatusReportFilterDTO";
import { RevenueAndBillingReportFilterDTO } from "../../../shared/specific/DTOs/reports/RevenueAndBillingReportFilterDTO";
import { ProjectTimesheetReportFilterDTO } from "../../../shared/specific/DTOs/reports/ProjectTimesheetReportFilterDTO";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { riseApi } from "./connection";
import { formatFilenameReports } from "../../../shared/specific/helpers/DTOs/projects/reports/formatters/fileFormatters.helpers";
import { formatFilename } from "../../../shared/common/helpers/data/general/formatters/fileFormatters.helpers";
import { ProjectMVsReportFilterDTO } from "../../../shared/specific/DTOs/reports/ProjectMVsReportFilterDTO";
import { getProjectById } from "./projects.repository";
import { WeeklyAllocationReportFilterDTO } from "../../../shared/specific/DTOs/reports/WeeklyAllocationReportFilterDTO";
import { ProvisionReportFilterDTO } from "../../../shared/specific/DTOs/reports/ProvisionReportFilterDTO";
import { BaseCollaboratorReportFilterDTO } from "../../../shared/specific/DTOs/reports/BaseCollaboratorsReportFilterDTO";
import { PepElementReportFilterDTO } from "../../../shared/specific/DTOs/reports/PepElementReportFilterDTO";
import { BudgetOverviewReportDTO } from "../../../shared/specific/DTOs/reports/BudgetOverviewReportDTO";
import { PlannedAndPerformedByPepReportFiltersDTO } from "../../../shared/specific/DTOs/reports/PlannedAndPerformedByPepReportFiltersDTO";
import { BillingVisionReportFilterDTO } from "../../../shared/specific/DTOs/reports/BillingVisionReportFilterDTO";
import { CostMdoReportFilterDTO } from "../../../shared/specific/DTOs/reports/CostMdoReportFilterDTO";
import { ZahReportFilterDTO } from "../../../shared/specific/DTOs/reports/ZahReportFilterDTO";
import { TurnoverDaysReportFilterDto } from "../../../shared/specific/DTOs/reports/TurnoverDaysReportFilterDto";
import { MvWithFinancialCostReportFilterDTO } from "../../../shared/specific/DTOs/reports/MvWithFinancialCostReportFilterDTO";

export const generateProjectOverviewReport = async (
  filters: ProjectOverviewReportDTO
) => {
  const response = await riseApi.get("/Reports/GenerateProjectOverviewReport", {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.projectsOverview"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generatePerformedTimesheetReport = async (
  filters: PerformedTimesheetReportFilterDTO
) => {
  const usersCollaboratorsQueryString = `?${filters.usersCollaboratorsIds
    .map((n, index) => `usersCollaboratorsIds[${index}]=${n}`)
    .join("&")}`;
  filters.usersCollaboratorsIds = [];
  const response = await riseApi.get(
    `/Reports/GeneratePerformedTimesheetReport${usersCollaboratorsQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.performedTimesheet"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateEapReport = async (idProject: number) => {
  const response = await riseApi.get("/Reports/GenerateEAPReport", {
    params: {
      idProject,
    },
    responseType: "blob",
  });

  const projetc = await getProjectById(idProject);

  const name = projetc?.code
    ? `${i18next.t("reports.pages.fields.eap")}_${projetc?.code}`
    : i18next.t("reports.pages.fields.eap");

  const finalFilename = formatFilenameReports(name, "xlsx");

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProjectStatusReport = async (
  filters: ProjectStatusReportFilterDTO
) => {
  const response = await riseApi.get(`/Reports/GenerateProjectStatusReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.statusOfProjects"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProjectAndAdditiveCreationReport = async (
  filters: CreationProjectsAdditivesDTO
) => {
  const response = await riseApi.get(
    `/Reports/GenerateProjectAndAdditiveCreationReport`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.creationProjectsAdditives"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProjectDetailedViewReport = async (
  filters: ProjectDetailedViewReportFilterDTO
) => {
  const response = await riseApi.get(
    `/Reports/GenerateProjectDetailedViewReport`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.datailedProjectsOverview"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateGlobalAllocationReport = async (
  filters: GlobalAllocationReportFilterDTO
) => {
  const response = await riseApi.get(
    `/Reports/GenerateGlobalAllocationReport`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.globalAllocation"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateWeeklyAllocationReport = async (
  filters: WeeklyAllocationReportFilterDTO
) => {
  const usersCollaboratorsQueryString = `?${filters.idsUserCollaborators
    .map((n, index) => `idsUserCollaborators[${index}]=${n}`)
    .join("&")}`;
  filters.idsUserCollaborators = [];
  const response = await riseApi.get(
    `/Reports/GenerateWeeklyAllocationReport${usersCollaboratorsQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.weeklyAllocation"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProjectMVsReport = async (
  filters: ProjectMVsReportFilterDTO
) => {
  const response = await riseApi.get(`/Reports/GenerateProjectMVsReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.projectMVs"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateRevenueAndBillingReport = async (
  filters: RevenueAndBillingReportFilterDTO
) => {
  const response = await riseApi.get(
    `/Reports/GenerateRevenueAndBillingReport`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const formattedStartDate = format(
    filters.startYearMonth.toDate(),
    "dd-MM-yyyy"
  );
  const formattedEndDate = format(
    filters.endYearMonth.toDateEndOfMonth(),
    "dd-MM-yyyy"
  );

  const finalFilename = formatFilename(
    `${i18next.t(
      "reports.pages.fields.revenueAndBilling"
    )} - ${formattedStartDate} - ${formattedEndDate}`,
    { extension: "xlsx", removeWhitespace: false }
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProjectTimesheetReport = async (
  filters: ProjectTimesheetReportFilterDTO
) => {
  const usersCollaboratorsQueryString = `?${filters.usersCollaboratorsIds
    .map((n, index) => `usersCollaboratorsIds[${index}]=${n}`)
    .join("&")}`;
  filters.usersCollaboratorsIds = [];
  const response = await riseApi.get(
    `/Reports/GenerateProjectTimesheetReport${usersCollaboratorsQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.projectTimesheet"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateProvisionReport = async (
  filters: ProvisionReportFilterDTO
) => {
  const idsProjectsQueryString =
    filters.idsProjects.length > 0
      ? `?${filters.idsProjects
          .map((n, index) => `IdsProjects[${index}]=${n}`)
          .join("&")}`
      : "";
  filters.idsProjects = [];
  const response = await riseApi.get(
    `/Reports/GenerateProvisionReport${idsProjectsQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.provisionReport"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateBaseCollaboratorsReport = async (
  filters: BaseCollaboratorReportFilterDTO
) => {
  const response = await riseApi.post(
    `/Reports/GenerateBaseCollaboratorReport`,
    filters,
    { responseType: "blob" }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.baseCollaborators"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generatePepElementReport = async (
  filters: PepElementReportFilterDTO
) => {
  const response = await riseApi.post(
    `/Reports/GeneratePepElementReport`,
    filters,
    { responseType: "blob" }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.pepElement"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateBudgetOverviewReport = async (
  filters: BudgetOverviewReportDTO
) => {
  const response = await riseApi.get(`/Reports/GenerateBudgetOverviewReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.budgetOverview"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generatePlannedAndPerformedByPepReport = async (
  filters: PlannedAndPerformedByPepReportFiltersDTO
) => {
  const pepCodesQueryString =
    filters.PepCodes && filters.PepCodes.length > 0
      ? `?${filters.PepCodes.map((n, index) => `PepCodes[${index}]=${n}`).join(
          "&"
        )}`
      : "";
  filters.PepCodes = [];
  const response = await riseApi.get(
    `/Reports/GeneratePlannedAndPerformedByPepReport${pepCodesQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.plannedAndPerformedByPepReport"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateBillingVisionReport = async (
  filters: BillingVisionReportFilterDTO
) => {
  const invoiceStatusQueryString =
    filters.InvoiceStatus.length > 0
      ? `?${filters.InvoiceStatus.map(
          (n, index) => `InvoiceStatus[${index}]=${n}`
        ).join("&")}`
      : "";
  filters.InvoiceStatus = [];
  const response = await riseApi.get(
    `/Reports/GenerateBillingVisionReport${invoiceStatusQueryString}`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.billingVision"),
    "xlsx"
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateCostMdoReport = async (
  filters: CostMdoReportFilterDTO
) => {
  const response = await riseApi.get(`/Reports/GenerateCostMdoReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.generation.costMdoReport.noMdoGenerated",
    });

  const finalFilename = formatFilenameReports(
    i18next.t("reports.pages.fields.workforceCost"),
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateZahReport = async (filters: ZahReportFilterDTO) => {
  const response = await riseApi.get(`/Reports/GenerateZahReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.generation.costMdoReport.noZahGenerated",
    });

  const finalFilename = formatFilenameReports("Carga_ZAH", "xlsx");

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateTurnoverDaysReport = async (
  filters: TurnoverDaysReportFilterDto
) => {
  const response = await riseApi.get(`/Reports/GenerateTurnoverDaysReport`, {
    params: filters,
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage:
        "reports.errors.generation.turnonverDays.noTurnoverDAysReportGenerated",
    });

  const finalFilename = formatFilenameReports("Turnover_Days_report", "xlsx");

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const generateMvWithFinancialCostReport = async (
  filters: MvWithFinancialCostReportFilterDTO
) => {
  const response = await riseApi.get(
    `/Reports/GenerateMvWithFinancialCostReport`,
    {
      params: filters,
      responseType: "blob",
    }
  );

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage:
        "reports.errors.generation.turnonverDays.noTurnoverDAysReportGenerated",
    });

  const finalFilename = formatFilenameReports(
    "Mv_Com_Custo_Financeiro",
    "xlsx"
  );

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};
