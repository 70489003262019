import { format } from "date-fns";
import { useContext, useMemo } from "react";
import { CarouselContext } from "../CarouselContext";
import { StyledCarouselHead } from "./index.styles";

interface OwnProps {
  columnIndex: number;
  customHeader?: React.ReactNode;
}

export const CarouselHead = ({ columnIndex, customHeader }: OwnProps) => {
  const { calculateYearMonth } = useContext(CarouselContext);

  const yearMonthHead = useMemo(() => {
    const yearMonthDate = calculateYearMonth({ columnIndex });

    if (!yearMonthDate) return "-";
    return format(yearMonthDate.toDate(), "MM/yyyy");
  }, [columnIndex, calculateYearMonth]);

  return (
    <StyledCarouselHead>{customHeader || yearMonthHead}</StyledCarouselHead>
  );
};
