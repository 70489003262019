import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { FormValues } from "../../../types/form.types";
import { createTranslatableFieldValues } from "../../../../../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { makeTranslatableFieldValuesFormSchema } from "../../../../../../../../../../../shared/common/helpers/data/form/languages/schema.helpers";
import { RootState } from "../../../../../../../../../../../store";
import { Language } from "../../../../../../../../../../../shared/common/enums/locale/Language.enum";

export const useFormikConfig = () => {
  const { t } = useTranslation();
  const defaultLanguage = useSelector(
    (state: RootState) => state.language.defaultLanguage
  );

  const initialValues: FormValues = useMemo(() => {
    return {
      code: "",
      division: null,
      subsidiary: null,
      nameTranslations: createTranslatableFieldValues(),
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      code: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      nameTranslations: makeTranslatableFieldValuesFormSchema({
        defaultLanguage: defaultLanguage ?? Language.enUS,
        defaultLanguageSchema: yup
          .string()
          .required(t("general.errors.data.fields.general.required")),
        secondaryLanguagesSchema: yup.string(),
      }).required(),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
