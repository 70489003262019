import axios from "axios";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { notifyError } from "../../../../../services/applicationState/toast.service";
import { checkIsValidType } from "../../../../common/helpers/data/validation/schema.helpers";
import {
  ApiErrorDTO,
  schemaApiErrorDTO,
} from "../../../DTOs/errors/ApiErrorDTO";
import { TranslatedError } from "../../../errors/general/TranslatedError";
import { getApiErrorCodeHandler } from "./apiError.helpers";
import { ApiErrorDTOTextFunction } from "./apiError4xx.types";

export const throwIf4xxApiErrorDTO = (error: unknown) => {
  const errorObject = getErrorIf4xxApiErrorDTO(error);
  if (!errorObject) return;

  throw errorObject;
};

export const notifyIf4xxApiErrorDTO = ({
  error,
  defaultMessage,
}: {
  error: unknown;
  defaultMessage?: string;
}) => {
  const textOrNull = getTextIf4xxApiErrorDTO({ error, defaultMessage });
  if (textOrNull === null) return false;

  notifyError(textOrNull);
  return true;
};

export const getTextIf4xxApiErrorDTO: ApiErrorDTOTextFunction = ({
  error,
  defaultMessage,
}) => {
  const errorObject = getErrorIf4xxApiErrorDTO(error);

  if (errorObject instanceof TranslatedError) {
    if (!errorObject.components)
      return i18next.t(errorObject.message, errorObject.interpolation);

    return (
      <Trans
        i18nKey={errorObject.message}
        values={errorObject.interpolation}
        components={errorObject.components}
      />
    );
  }
  if (errorObject instanceof Error) {
    return i18next.t(errorObject.message);
  }
  if (defaultMessage !== undefined) {
    return i18next.t(defaultMessage);
  }

  return null;
};

export const getErrorIf4xxApiErrorDTO = (
  error: unknown
): null | Error | TranslatedError => {
  if (
    !axios.isAxiosError(error) ||
    !error.response ||
    error.response.status < 400 ||
    error.response.status >= 500
  )
    return null;

  if (!checkIsValidType<ApiErrorDTO>(schemaApiErrorDTO, error.response.data))
    return null;

  const apiErrorDTO = error.response.data;

  const translatedError = getApiErrorCodeHandler(apiErrorDTO);
  if (!translatedError) return new Error(apiErrorDTO.message);

  return new TranslatedError(
    translatedError.code,
    translatedError.details,
    translatedError.components
  );
};
