import { FormikHelpers } from "formik";
import i18next from "i18next";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import { createProject } from "../../../../../services/projects/projects.service";
import { parseLocaleNumber } from "../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { convertTo } from "../../../../../shared/common/helpers/types/converters.helpers";
import { ProjectCreateDTO } from "../../../../../shared/specific/DTOs/project/ProjectCreateDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  CommonProjectForm,
  CommonProjectFormRef,
} from "../shared/react/components/content/CommonProjectForm";
import { CommonProjectFormValues } from "../shared/react/components/content/CommonProjectForm/shared/types/forms/general.types";

export const ProjectsCreationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formRef = useRef<CommonProjectFormRef>(null);

  useEffect(() => {
    setPageTitle(t("projects.pages.creation.title"));
  }, [t]);

  useEffect(() => {
    // Solves the lag between clicking a navigation button to access
    // the page to actually accessing it.
    const timeout = setTimeout(() => formRef.current?.setLoading(false), 200);
    return () => clearTimeout(timeout);
  }, []);

  const onSubmit = async (
    values: CommonProjectFormValues,
    { setSubmitting }: FormikHelpers<CommonProjectFormValues>
  ) => {
    if (!values.contractType)
      throw new Error("'contractType' cannot be null on submit.");

    try {
      const projectOnly = await createProject(
        convertTo<ProjectCreateDTO>({
          idCustomer: values.customer?.id ?? 0,
          idUserManager: values.userManager?.id ?? 0,
          idUserCoordinator: values.userCoordinator?.id,
          idDivision: values.division?.id ?? 0,
          idSubsidiary: values.subsidiary?.id ?? 0,
          idProductService: values.productService?.id ?? 0,
          idFrame: values.frame?.id,
          code: values.code,
          name: values.name,
          description: values.description,
          contractType: values.contractType.id,
          daysForPayment: parseLocaleNumber(values.daysForPayment),
          billingAmount: parseLocaleNumber(values.billingAmount),
          IdPipedriveDeal: null,
          approvedCredit: values.approvedCredit?.id ?? false,
          clientAccount: values.clientAccount,
          idSegment: values.segment?.id ?? 0,
          isInvestmentProject: values.isInvestmentProject?.id ?? false,
        })
      );

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );

      const valueReturnTo = encodeURIComponent(
        `/projects/${projectOnly.id}/edit`
      );
      navigate(`/projects/${projectOnly.id}/peps?return-to=${valueReturnTo}`);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projects.errors.data.create.failedToCreate",
      });
      setSubmitting(false);
    }
  };

  return (
    <CommonProjectForm mode="creation" onSubmit={onSubmit} ref={formRef} />
  );
};
