import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../../../../types/form.types";
import { ExpenseType } from "../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      customerTrigram: null,
      segmentGroup: null,
      subsidiary: null,
      isNewClient: true,
      costUs: [
        {
          expenseType: ExpenseType.Workforce,
          percentage: "",
        },
        {
          expenseType: ExpenseType.SoftwareHardware,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Travels,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Infrastructure,
          percentage: "",
        },
        {
          expenseType: ExpenseType.ResellingProducts,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Training,
          percentage: "",
        },
        {
          expenseType: ExpenseType.ConsultantServices,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Refund,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Other,
          percentage: "",
        },
      ],
      costBr: [
        {
          expenseType: ExpenseType.Workforce,
          percentage: "",
        },
        {
          expenseType: ExpenseType.SoftwareHardware,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Travels,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Infrastructure,
          percentage: "",
        },
        {
          expenseType: ExpenseType.ResellingProducts,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Training,
          percentage: "",
        },
        {
          expenseType: ExpenseType.ConsultantServices,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Refund,
          percentage: "",
        },
        {
          expenseType: ExpenseType.Other,
          percentage: "",
        },
      ],
      total: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      customerTrigram: yup.string().nullable(),
      segmentGroup: yup
        .object()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
      isNewClient: yup.boolean().defined(),
      costBr: yup.array(
        yup.object({
          expenseType: yup
            .string()
            .defined()
            .required(t("general.errors.data.fields.general.required")),
          percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .min(0, t("general.errors.data.fields.general.min", { min: 0 }))
            .max(
              100,
              t("general.errors.data.fields.general.max", { max: 100 })
            ),
        })
      ),
      costUs: yup.array(
        yup.object({
          expenseType: yup
            .string()
            .defined()
            .required(t("general.errors.data.fields.general.required")),
          percentage: yup
            .number()
            .transform((value, originalValue, context) => {
              if (context.isType(originalValue)) return originalValue;
              return parseLocaleNumber(originalValue);
            })
            .min(0, t("general.errors.data.fields.general.min", { min: 0 }))
            .max(
              100,
              t("general.errors.data.fields.general.max", { max: 100 })
            ),
        })
      ),
      total: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .min(
          100,
          t(
            "costDistribution.errors.data.general.totalShouldBeEqualOneHundreds"
          )
        )
        .max(
          100,
          t(
            "costDistribution.errors.data.general.totalShouldBeEqualOneHundreds"
          )
        )
        .required(
          t(
            "costDistribution.errors.data.general.totalShouldBeEqualOneHundreds"
          )
        ),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
