import * as yup from "yup";

import { riseApi } from "./connection";

import {
  HorizontalDTO,
  schemaHorizontalDTO,
} from "../../../shared/specific/DTOs/horizontal/HorizontalDTO";

export const getHorizontals = async (): Promise<HorizontalDTO[]> => {
  const { data: Horizontals } = await riseApi.get("/Horizontal");

  return yup.array(schemaHorizontalDTO).defined().validateSync(Horizontals);
};
