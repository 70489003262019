import { Button } from "@mui/material";
import i18next from "i18next";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getCostCenterPepById } from "../../../../../../services/costCenterPeps/costCenterPeps.service";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ViewFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/ViewFormButton";
import { AutocompleteOption } from "../../../../../../shared/common/types/data/form/autocomplete.types";
import { CostCenterPepDTO } from "../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepDTO";
import { CostCenterPepOnlyDTO } from "../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCostCenterPepForm } from "../shared/react/components/content/CommonCostCenterPepForm";
import { useFormFormikConfig } from "../shared/react/hooks/useFormFormikConfig";
import { CostCenterPepsFormValues } from "../shared/types/data/form.types";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

export const useViewingForm = () => {
  const { initialValues, validationSchema } = useFormFormikConfig();

  const formikConfig: EntryFormikConfig<CostCenterPepsFormValues> =
    useMemo(() => {
      return {
        initialValues,
        validationSchema,
      };
    }, [initialValues, validationSchema]);

  const prepareIsExempted = (isExempted: boolean | null) => {
    if (isExempted === null) return null;

    return convertTo<AutocompleteOption<boolean>>({
      id: isExempted,
      label: isExempted
        ? i18next.t("general.keywords.general.yes")
        : i18next.t("general.keywords.general.no"),
    });
  };

  const { contentModal: viewingModal, ContentButton: ViewingButton } =
    useFormikModalButton<
      CostCenterPepsFormValues,
      CostCenterPepDTO,
      CostCenterPepOnlyDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: costCenterPep }) => {
          if (!costCenterPep) return null;

          return (
            <Trans
              i18nKey="general.modal.data.view.detailsOfName"
              values={{ name: costCenterPep.pepCode }}
              components={{ italic: <em /> }}
            />
          );
        },
        suppressCloseBackdropClick: false,
      },
      button: {
        FormButton: ViewFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: tableCostCenterPepOnlyData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          setModalError,
          checkInCurrentModalCount,
          setInternalFormData,
          setFormValues,
        }) => {
          setIsLoadingModal(true);
          const startingModalCount = getOpenCloseModalCount();

          try {
            const costCenterPep = await getCostCenterPepById(
              tableCostCenterPepOnlyData.id
            );

            if (!checkInCurrentModalCount(startingModalCount)) return;

            if (!costCenterPep)
              return setModalError(
                i18next.t("costCenterPeps.errors.data.general.pepDoesNotExist")
              );

            const cofinsTax = costCenterPep.taxes.find(
              (x) => x.name === "COFINS"
            )?.value;
            const issTax = costCenterPep.taxes.find(
              (x) => x.name === "ISS"
            )?.value;
            const pisTax = costCenterPep.taxes.find(
              (x) => x.name === "PIS"
            )?.value;
            const icmsTax = costCenterPep.taxes.find(
              (x) => x.name === "ICMS"
            )?.value;

            setInternalFormData(costCenterPep);
            setFormValues({
              costCenter: costCenterPep.costCenter,
              isExempted: prepareIsExempted(costCenterPep.isExempted),
              pepCode: costCenterPep.pepCode,
              pepDescription: costCenterPep.pepDescription,
              subsidiary: costCenterPep.subsidiary,
              tax: costCenterPep.tax
                ? formatNumber(costCenterPep.tax, { fractionDigits: 2 })
                : formatNumber(0, { fractionDigits: 2 }),
              cofins: {
                label: cofinsTax
                  ? formatNumber(cofinsTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              icms: icmsTax
                ? formatNumber(icmsTax, { fractionDigits: 2 })
                : formatNumber(0, { fractionDigits: 2 }),
              iss: {
                label: issTax
                  ? formatNumber(issTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              pis: {
                label: pisTax
                  ? formatNumber(pisTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              cfop: costCenterPep.cfop ?? null,
              costCenterFromDeal: "",
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPep",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData: costCenterPep, ...rest }) => (
          <CommonCostCenterPepForm
            {...rest}
            mode="viewing"
            projectData={costCenterPep?.project}
          />
        ),
        FormActionsMemo: ({ closeModal }) => {
          const { t } = useTranslation();

          return (
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          );
        },
      },
    });

  return { viewingModal, ViewingButton };
};
