import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import i18next from "i18next";
import { FormContext } from ".";
import {
  FormContextProviderProps,
  FormContextProviderRef,
  FormContextValues,
  SubmitManuallyFunction,
} from "./index.types";
import { notifyError } from "../../../../../../../../../../../../services/applicationState/toast.service";

export type { FormContextProviderProps, FormContextProviderRef };

const FormContextProviderWithinForwardRef = (
  {
    children,
    mode,
    formikProps,
    onSubmit,
    projectData,
    setProjectData,
  }: FormContextProviderProps,
  ref: ForwardedRef<FormContextProviderRef>
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ReactNode>(false);

  const paramsRef = useRef({ onSubmit, formikProps });
  paramsRef.current = { onSubmit, formikProps };

  const submitManually: SubmitManuallyFunction = async ({
    redirectionLinkOnSuccess,
  } = {}) => {
    const errors = await paramsRef.current.formikProps.validateForm();
    if (Object.keys(errors).length > 0)
      return notifyError(
        i18next.t(
          "general.errors.data.write.submissionContainsErrorsFixTryAgain"
        )
      );

    paramsRef.current.formikProps.setSubmitting(true);
    paramsRef.current.onSubmit(
      paramsRef.current.formikProps.values,
      paramsRef.current.formikProps,
      {
        redirectionLinkOnSuccess,
        projectData,
      }
    );
  };

  const contextValue: FormContextValues = useMemo(() => {
    return {
      loading,
      setLoading,
      error,
      setError,
      mode,
      projectData,
      setProjectData,
      submitManually,
      isSubmitting: formikProps.isSubmitting,
    };
  }, [projectData, loading, error, mode, formikProps.isSubmitting]);

  useImperativeHandle(ref, () => ({
    setLoading,
    setError,
    setProjectData,
    submitManually,
  }));

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export const FormContextProvider = forwardRef<
  FormContextProviderRef,
  FormContextProviderProps
>(FormContextProviderWithinForwardRef);
