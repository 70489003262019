import { LabelColorType } from "../types/form.types";

export const LabelColorTypeTranslationCode: {
  [key in LabelColorType]: string;
} = {
  [LabelColorType.Allocated]: "allocationsMap.keywords.form.allocated",
  [LabelColorType.Attention]: "allocationsMap.keywords.form.attention",
  [LabelColorType.Doubt]: "allocationsMap.keywords.form.doubt",
  [LabelColorType.Resignation]: "allocationsMap.keywords.form.resignation",
  [LabelColorType.Unallocated]: "allocationsMap.keywords.form.unallocated",
  [LabelColorType.Vacation]: "allocationsMap.keywords.form.vacation",
};
