import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ProjectionFormValues } from "../../../../../types/form.types";
import { grossRevenuePageFunctions } from "../../../../../context/utils/grossRevenuePageFunctions";
import { ExternalEnhancedRow } from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { expenseGroupTotalValuesFunctions } from "../../../../../context/utils/expenseGroupTotalValuesFunctions";
import { ExpenseGroup } from "../../../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";
import { taxPageFunctions } from "../../../../../context/utils/taxPageFunctions";
import { salesCostPageFuctions } from "../../../../../context/utils/salesCostPageFuctions";
import { dolPageFunctions } from "../../../../../context/utils/dolPageFunctions";
import { SgAndAPageFunction } from "../../../../../context/utils/SgAndAPageFunction";
import { admCorpPageFunctions } from "../../../../../context/utils/admCorpPageFunctions";
import { pddPageFunctions } from "../../../../../context/utils/pddPageFunctions";
import { useGenerateSumRow } from "./hooks/useGenerateSumRow";
import { netFinancialExpensesPageFunctionsPageFunctions } from "../../../../../context/utils/netFinancialExpensesPageFunctions";
import { irCsllPageFunctionsPageFunctions } from "../../../../../context/utils/irCsllPageFunctions";
import { participationProgramPageFunctionsPageFunctions } from "../../../../../context/utils/participationProgramPageFunctions";

interface OwnProps {
  formikProps: FormikProps<ProjectionFormValues>;
}

export const useSumArea = ({ formikProps }: OwnProps) => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const idExpenseGroup = searchParams.get("idExpenseGroup");

  const { GenerateSumRow } = useGenerateSumRow();

  const idExpenseGroupValidated = useMemo(() => {
    return /^\d+$/.test(idExpenseGroup ?? "")
      ? parseInt(idExpenseGroup ?? "")
      : null;
  }, [idExpenseGroup]);

  const isToShowCustomerTrigramAndClientAccount = useMemo(() => {
    return ![
      ExpenseGroup.AdmCorp,
      ExpenseGroup.Pdd,
      ExpenseGroup.IrCsll,
      ExpenseGroup.NetFinancialExpenses,
      ExpenseGroup.ParticipationProgram,
    ].includes(idExpenseGroupValidated as ExpenseGroup);
  }, [idExpenseGroupValidated]);

  const { values } = formikProps;

  const grossRevenueRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalRevenue"),
        values: grossRevenuePageFunctions.getTotalGrossRevenue(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.netRevenue"),
        values: grossRevenuePageFunctions.getTotalNetRevenue(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.salesMarginPercentage"),
        values: grossRevenuePageFunctions.getSalesMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t(
          "projection.keywords.fields.operationalMarginPercentage"
        ),
        values:
          grossRevenuePageFunctions.getTotalOperationMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.sgAndA"),
        values: expenseGroupTotalValuesFunctions.getTotalSgAndAAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padAmount"),
        values: grossRevenuePageFunctions.getTotalPadAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padPercentage"),
        values: grossRevenuePageFunctions.getTotalPadPercentage(values),
        isPercentage: true,
      }),
    ];
  }, [values]);

  const taxRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalTax"),
        values: taxPageFunctions.getTotalTaxAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.netRevenue"),
        values: taxPageFunctions.getTotalNetRevenue(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.salesMarginPercentage"),
        values: taxPageFunctions.getSalesMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t(
          "projection.keywords.fields.operationalMarginPercentage"
        ),
        values: taxPageFunctions.getTotalOperationMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.sgAndA"),
        values: expenseGroupTotalValuesFunctions.getTotalSgAndAAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padAmount"),
        values: taxPageFunctions.getTotalPadAmount(values),
      }),
    ];
  }, [values]);

  const expenseRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalSalesCost"),
        values: salesCostPageFuctions.getTotaSalesCostAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.salesMarginPercentage"),
        values: salesCostPageFuctions.getSalesMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t(
          "projection.keywords.fields.operationalMarginPercentage"
        ),
        values: salesCostPageFuctions.getTotalOperationMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padAmount"),
        values: salesCostPageFuctions.getTotalPadAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padPercentage"),
        values: salesCostPageFuctions.getTotalPadPercentage(values),
        isPercentage: true,
      }),
    ];
  }, [values]);

  const dolRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalDol"),
        values: dolPageFunctions.getTotaDolAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t(
          "projection.keywords.fields.operationalMarginPercentage"
        ),
        values: dolPageFunctions.getTotalOperationMarginPercentage(values),
        isPercentage: true,
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padAmount"),
        values: dolPageFunctions.getTotalPadAmount(values),
      }),
    ];
  }, [values]);

  const sgAndARows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalSgAndA"),
        values: SgAndAPageFunction.getTotaSgAndAAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padAmount"),
        values: SgAndAPageFunction.getTotalPadAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padPercentage"),
        values: SgAndAPageFunction.getTotalPadPercentage(values),
        isPercentage: true,
      }),
    ];
  }, [values]);

  const admCordRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalAdmCorp"),
        values: admCorpPageFunctions.getTotaAdmCorpAmount(values),
      }),
    ];
  }, [values]);

  const netFinancialExpensesRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.netFinancialExpenses"),
        values:
          netFinancialExpensesPageFunctionsPageFunctions.getTotaNetFinancialExpensesPageFunctionsAmount(
            values
          ),
      }),
    ];
  }, [values]);

  const irCsllRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.irCsll"),
        values:
          irCsllPageFunctionsPageFunctions.getTotaIrCsllPageFunctionsAmount(
            values
          ),
      }),
    ];
  }, [values]);

  const participationProgramRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.participationProgram"),
        values:
          participationProgramPageFunctionsPageFunctions.getTotaParticipationProgramPageFunctionsAmount(
            values
          ),
      }),
    ];
  }, [values]);

  const pddRows: ExternalEnhancedRow[] = useMemo(() => {
    return [
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.totalPdd"),
        values: pddPageFunctions.getTotaPddAmount(values),
      }),
      GenerateSumRow({
        isToShowCustomerTrigramAndClientAccount,
        description: t("projection.keywords.fields.padPercentage"),
        values: pddPageFunctions.getTotalPadPercentage(values),
      }),
    ];
  }, [values]);

  const allRows: {
    readonly [key in ExpenseGroup]: ExternalEnhancedRow[];
  } = {
    [ExpenseGroup.GrossRevenue]: grossRevenueRows,
    [ExpenseGroup.AdmCorp]: admCordRows,
    [ExpenseGroup.Dol]: dolRows,
    [ExpenseGroup.Pdd]: pddRows,
    [ExpenseGroup.SalesCost]: expenseRows,
    [ExpenseGroup.Sgea]: sgAndARows,
    [ExpenseGroup.Tax]: taxRows,
    [ExpenseGroup.NetFinancialExpenses]: netFinancialExpensesRows,
    [ExpenseGroup.IrCsll]: irCsllRows,
    [ExpenseGroup.ParticipationProgram]: participationProgramRows,
  };

  return {
    rows: idExpenseGroupValidated
      ? allRows[idExpenseGroupValidated as ExpenseGroup]
      : [],
  };
};
