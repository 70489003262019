import * as yup from "yup";

import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { StyledButtonContainer } from "./index.styles";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { EditingIssuedDateFormValues } from "./shared/form.types";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CommonFormEditingCell } from "./CommonFormEditingCell";
import { UpdateIssuedSuperUserDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateIssuedSuperUserDTO";
import { DateOnly } from "../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { UpdateIssuedSuperUser } from "../../../../../../../../../services/invoices/invoices.service";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";

interface OwnProps {
  reloadTablePage: () => void;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  invoice: InvoiceDTO;
}

export const useEditingIssuedDate = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: EditingIssuedDateFormValues = {
      dueDate: null,
      issuedDate: null,
      paymentDate: null,
      provisionCancellationDate: null,
      totalCancellationDate: null,
    };

    const validationSchema = yup.object({
      dueDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      issuedDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      paymentDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      provisionCancellationDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      totalCancellationDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      EditingIssuedDateFormValues,
      InvoiceDTO,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: { invoice },
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
    }) => {
      const provisionCancellationDate =
        invoice.negativeInvoicesCancellationReference.length > 1 ||
        invoice.status === InvoicesStatusEnum.ProvisionForCancellation
          ? (invoice.negativeInvoicesCancellationReference
              .reduce((a, b) => {
                if (a.issueDate && b.issueDate)
                  return a.issueDate < b.issueDate ? a : b;
                return a;
              })
              .issueDate?.toDate() ?? null)
          : null;
      const totalCancellationDate =
        invoice.negativeInvoicesCancellationReference.length > 0 &&
        invoice.status === InvoicesStatusEnum.Canceled
          ? (invoice.negativeInvoicesCancellationReference
              .reduce((a, b) => {
                if (a.issueDate && b.issueDate)
                  return a.issueDate > b.issueDate ? a : b;
                return a;
              })
              .issueDate?.toDate() ?? null)
          : null;
      setFormValues({
        dueDate: invoice.dueDate ? invoice.dueDate.toDate() : null,
        issuedDate: invoice.issueDate ? invoice.issueDate.toDate() : null,
        paymentDate: invoice.paymentDate ? invoice.paymentDate.toDate() : null,
        provisionCancellationDate,
        totalCancellationDate,
      });

      setInternalFormData(invoice);

      setIsLoadingModal(false);
    },
    [t]
  );

  const {
    ContentButton: EditingIssuedDateButtonContainer,
    contentModal: editingIssuedDateModal,
  } = useFormikModalButton({
    modal: {
      ModalTitleMemo: () => {
        return <span>{t("invoice.modal.data.edit.issuedDate")}</span>;
      },
      keepModalMounted: 1000,
      modalMode: "dialog",
    },
    button: {
      onClickContentButtonComponent,
      createModalButtonContainerMemo: ({
        onClickButton,
        onClickContentButtonComponent,
      }) => {
        return (props) => {
          const { children } = props;
          const onClick = () => {
            onClickButton();
            onClickContentButtonComponent({ props });
          };

          return (
            <StyledButtonContainer onClick={onClick}>
              {children}
            </StyledButtonContainer>
          );
        };
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) =>
        internalFormData && (
          <CommonFormEditingCell
            internalFormData={internalFormData}
            {...rest}
          />
        ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        if (!internalFormData)
          throw new Error("Missing internal form data on submit.");

        try {
          const invoiceUpdate: UpdateIssuedSuperUserDTO = {
            invoiceIds: internalFormData.invoiceGroup.map((x) => x.id),
            dueDate: formValues?.dueDate
              ? DateOnly.createFromDate(formValues.dueDate)
              : DateOnly.createFromNow(),
            issueDate: formValues?.issuedDate
              ? DateOnly.createFromDate(formValues.issuedDate)
              : DateOnly.createFromNow(),
            paymentDate: formValues?.paymentDate
              ? DateOnly.createFromDate(formValues.paymentDate)
              : undefined,
            provisionCancellationDate: formValues?.provisionCancellationDate
              ? DateOnly.createFromDate(formValues.provisionCancellationDate)
              : undefined,
            totalCancellationDate: formValues?.totalCancellationDate
              ? DateOnly.createFromDate(formValues.totalCancellationDate)
              : undefined,
            version: internalFormData.version,
          };

          await UpdateIssuedSuperUser(invoiceUpdate);

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          setSubmitting(false);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "allocations.errors.data.update.failedToUpdateAllocationsGroup",
          });
          setSubmitting(false);
        }
      },
    },
  });

  return { EditingIssuedDateButtonContainer, editingIssuedDateModal };
};
