import { useMemo } from "react";
import { PermissionLevel } from "../../../../../../../../enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../enums/users/permissions/PermissionType.enum";
import { PermissionsTypeFiltered } from "../types/permissionTypeFormatted.types";

export const usePermissionsFormatted = () => {
  const permissionTypes = useMemo(() => {
    const permissionTypes = Object.entries(PermissionType)
      .filter(([, id]) => typeof id === "number")
      .map(([name, id]) => ({
        id: id as PermissionType,
        name,
      }));

    return permissionTypes;
  }, []);

  const permissionTypesFormatted = useMemo(() => {
    const permissionTypesFormatted: PermissionsTypeFiltered = {
      general: permissionTypes.filter(
        (permissionType) => permissionType.id < 1000
      ),
      specific: permissionTypes.filter(
        (permissionType) => permissionType.id > 1000 && permissionType.id < 2000
      ),
      report: permissionTypes.filter(
        (permissionType) => permissionType.id > 2000
      ),
    };

    return permissionTypesFormatted;
  }, [permissionTypes]);

  const permissionLevels = useMemo(() => {
    const permissionLevels = Object.entries(PermissionLevel)
      .filter((obj) => typeof obj[1] === "number")
      .map(([name, id]) => ({
        id: id as PermissionLevel,
        name,
      }));

    return permissionLevels;
  }, []);

  return useMemo(
    () => ({
      permissionTypesFormatted,
      permissionLevels,
    }),
    [permissionTypes, permissionLevels, permissionTypesFormatted]
  );
};
