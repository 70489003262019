import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { FormValuesStatusOfProjects } from "./types/form.types";
import { StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { ProjectStatusReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/ProjectStatusReportFilterDTO";
import { generateProjectStatusReport } from "../../../../../../../../../services/reports/reports.service";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { ProjectStatusAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectStatusAutocompleteFormik";

export const StatusOfProjects = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesStatusOfProjects>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesStatusOfProjects = {
      division: null,
      managerCollaborator: null,
      projectStatus: null,
    };

    const validationSchema = yup.object({
      division: yup.object().nullable(),
      managerCollaborator: yup.object().nullable(),
      projectStatus: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesStatusOfProjects,
    formikHelpers: FormikHelpers<FormValuesStatusOfProjects>
  ) => {
    const formattedFilters: ProjectStatusReportFilterDTO = {
      IdDivision: values.division?.id || 0,
      DivisionName: values.division?.name ?? undefined,
      IdManagerCollaborator: values.managerCollaborator?.id ?? undefined,
      ProjectStatus: values.projectStatus?.id ?? undefined,
    };

    await generateProjectStatusReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <DivisionsAutocompleteFormik
            name="division"
            includeAllDivisionsOption
          />
          <ProjectStatusAutocompleteFormik name="projectStatus" />
          <UsersSearchAutocompleteFormik
            name="managerCollaborator"
            textfieldProps={{
              label: t("projects.keywords.fields.manager"),
            }}
            autocompleteProps={{
              multiple: false,
              value: values.managerCollaborator,
            }}
            filters={{
              onlyManager: true,
            }}
          />
          <GenerateReportButton
            isDisabled={
              !values.division &&
              !values.managerCollaborator &&
              !values.projectStatus
            }
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
