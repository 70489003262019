import * as yup from "yup";

import {
  CalculatedDataDTO,
  schemaCalculatedDataDTO,
} from "./auxTypes/CalculatedDataDTO";

export interface ProjectionDTO {
  grossRevenue: CalculatedDataDTO;
  tax: CalculatedDataDTO;
  salesCost: CalculatedDataDTO;
  netRevenue: CalculatedDataDTO;
  salesMarginAmount: CalculatedDataDTO;
  salesMarginPercentage: CalculatedDataDTO;
  dol: CalculatedDataDTO;
  operatingMarginAmount: CalculatedDataDTO;
  operatingMarginPercentage: CalculatedDataDTO;
  sgAndA: CalculatedDataDTO;
  pdd: CalculatedDataDTO;
  padAmount: CalculatedDataDTO;
  padPercentage: CalculatedDataDTO;
  admCorp: CalculatedDataDTO;
  operationalResult: CalculatedDataDTO;
  profitOrLossBeforeIrCsll: CalculatedDataDTO;
  profitOrLossAfterIrCsll: CalculatedDataDTO;
  profitOrLossFromPeriod: CalculatedDataDTO;
  netFinancialExpenses: CalculatedDataDTO;
  irCsll: CalculatedDataDTO;
  participationProgram: CalculatedDataDTO;
}

export const schemaProjectionDTO = yup.object({
  grossRevenue: schemaCalculatedDataDTO.defined(),
  tax: schemaCalculatedDataDTO.defined(),
  salesCost: schemaCalculatedDataDTO.defined(),
  netRevenue: schemaCalculatedDataDTO.defined(),
  salesMarginAmount: schemaCalculatedDataDTO.defined(),
  salesMarginPercentage: schemaCalculatedDataDTO.defined(),
  dol: schemaCalculatedDataDTO.defined(),
  operatingMarginAmount: schemaCalculatedDataDTO.defined(),
  operatingMarginPercentage: schemaCalculatedDataDTO.defined(),
  sgAndA: schemaCalculatedDataDTO.defined(),
  pdd: schemaCalculatedDataDTO.defined(),
  padAmount: schemaCalculatedDataDTO.defined(),
  padPercentage: schemaCalculatedDataDTO.defined(),
  admCorp: schemaCalculatedDataDTO.defined(),
  operationalResult: schemaCalculatedDataDTO.defined(),
  profitOrLossBeforeIrCsll: schemaCalculatedDataDTO.defined(),
  profitOrLossAfterIrCsll: schemaCalculatedDataDTO.defined(),
  profitOrLossFromPeriod: schemaCalculatedDataDTO.defined(),
  netFinancialExpenses: schemaCalculatedDataDTO.defined(),
  irCsll: schemaCalculatedDataDTO.defined(),
  participationProgram: schemaCalculatedDataDTO.defined(),
});
