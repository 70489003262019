import { DatePickerFormik, DatePickerFormikProps } from "./DatePickerFormik";

export type YearMonthDatePickerFormikProps<TDate> =
  DatePickerFormikProps<TDate>;

export const YearMonthDatePickerFormik = <TDate,>({
  datePickerProps,
  ...rest
}: YearMonthDatePickerFormikProps<TDate>) => {
  return (
    <DatePickerFormik
      datePickerProps={{
        format: "MM/yyyy",
        views: ["year", "month"],
        ...datePickerProps,
      }}
      {...rest}
    />
  );
};
