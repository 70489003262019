import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  createDeleteUserAccessPermission,
  getListPermissionsByIdUser,
} from "../../../../../../services/userAccessPermission/userAccessPermission.service";
import { LockIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/LockIconButton";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { UserAccessCreateDeletePermissionDTO } from "../../../../../../shared/specific/DTOs/userAccessPermission/UserAccessCreateDeletePermissionDTO";
import { UserAccessPermissionDTO } from "../../../../../../shared/specific/DTOs/userAccessPermission/UserAccessPermissionDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { AccessManagementTable } from "../../../../../../shared/specific/react/hooksWithComponents/content/form/formik/accessManagementModal/AccessManagementTable";
import { useFormikConfigAccessManagement } from "../../../../../../shared/specific/react/hooksWithComponents/content/form/formik/accessManagementModal/shared/hooks/useFormikConfigAccessManagement";
import { FormValuesAccessManagementModal } from "../../../../../../shared/specific/react/hooksWithComponents/content/form/formik/accessManagementModal/shared/types/form.types";
import { UserAccessPermissionsManagementFormModal } from "../shared/types/data/form/values/values.types";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useUserAccessPermissionsManagementModal = ({
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfigAccessManagement();

  const {
    ContentButton: UserAccessPermissionsContentButton,
    contentModal: userAccessPermissionsModal,
  } = useFormikModalButton<
    FormValuesAccessManagementModal,
    {
      listPermissions: UserAccessPermissionDTO[];
      userData: UserAccessPermissionsManagementFormModal;
    },
    UserAccessPermissionsManagementFormModal
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalFormData }) => {
        return (
          <Trans
            i18nKey="usersCollaborators.modal.data.manage.title"
            values={{ name: internalFormData?.userData.name ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();
        return (
          <LockIconButton
            onClick={onClick}
            tooltipText={t(
              "permissionTypes.actions.general.manageAccessPermissions"
            )}
          />
        );
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: userData,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        try {
          const listPermissions = await getListPermissionsByIdUser(userData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!listPermissions)
            return setModalError(
              t("users.errors.data.fetch.failedToFetchUserPermissionList")
            );

          setInternalFormData({
            listPermissions,
            userData,
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "users.errors.data.fetch.failedToFetchUserPermissionList",
          });
          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData }) => {
        return (
          <AccessManagementTable
            listPermissions={internalFormData?.listPermissions ?? null}
            permissionData={internalFormData?.userData ?? null}
            isUserPermissionModal
          />
        );
      },
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const permissionUpdated: UserAccessCreateDeletePermissionDTO = {
            idUserCollaborator: internalFormData?.userData?.id ?? 0,
            insert: formValues.permissions
              .filter((x) => x.method === "insert")
              .map((permission) => ({
                permissionLevel: permission?.permissionLevel || null,
                permissionType: permission?.permissionType ?? 0,
              })),
            delete: formValues.permissions
              .filter((x) => x.method === "delete")
              .map((permission) => ({
                id: permission?.id ?? 0,
                permissionLevel: permission?.permissionLevel || null,
                permissionType: permission?.permissionType ?? 0,
              })),
          };

          await createDeleteUserAccessPermission(permissionUpdated);
          reloadTablePage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "usersCollaborators.errors.data.edit.failedToEditCollaboratorAccess",
          });
        } finally {
          setSubmitting(false);
          closeModal();
        }
      },
    },
  });

  return { UserAccessPermissionsContentButton, userAccessPermissionsModal };
};
