import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getPaginatedCorporateAllocationsPerUser } from "../../../../../../../services/corporateAllocations/corporateAllocations.service";
import { CarouselCell } from "../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { CorporateAllocationsCarouselContext } from "../../shared/react/contexts/CorporateAllocationsCarouselContext";
import { CorporateAllocationPaginatedFiltersDTO } from "../../../../../../../shared/specific/DTOs/corporateAllocations/filters/CorporateAllocationPaginatedFiltersDTO";
import { SingleLineItem } from "./index.styles";
import { useDeletionForm } from "../useDeletionForm";
import { DeleteButtonSuperUserContainer } from "./DeleteButtonContainer";
import { useEditingCellForm } from "../useEditingCellForm";
import { CarouselContent } from "./CarouselContent";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";

interface OwnProps {
  filters: CorporateAllocationPaginatedFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { carouselRealSize, getCarouselRealSize } = useContext(
    CorporateAllocationsCarouselContext
  );
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingCellForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.colaborator"),
        headColumn: true,
        minWidth: 200,
      },
      {
        value: t("costCenters.keywords.general.costCenter"),
        minWidth: 180,
      },
      {
        value: t("general.keywords.fields.type"),
        minWidth: 180,
      },
      t("corporateSegment.keywords.general.corporateSegments"),
    ];

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }

    headCells.push({
      value: "",
      width: 72,
      canSort: false,
    });

    return headCells;
  }, [t, carouselRealSize]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        if (!filters.userCollaboratorName && !filters.idCostCenter) {
          setInfo(
            t("projects.info.data.general.typeSomethingNameOrFillTheFilter")
          );
          return null;
        }

        const headKeys = [
          "collaboratorName",
          "costCenterCode",
          "allocationType",
          "corporateSegment",
        ];

        try {
          const {
            tableData: corporateAllocations,
            page,
            totalCount,
          } = await getPaginatedCorporateAllocationsPerUser({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = corporateAllocations.map(
            (corporateAllocationsOfUser) => {
              const row: ExternalEnhancedRow = {
                id: corporateAllocationsOfUser.allocationGroup,
                cells: [
                  corporateAllocationsOfUser.collaboratorName,
                  corporateAllocationsOfUser?.costCenterCode ?? "-",
                  {
                    value: corporateAllocationsOfUser.allocationType,
                    CellRenderer: () => (
                      <SingleLineItem>
                        {t(
                          employeeAllocationTypeToTranslationCodeMap[
                            corporateAllocationsOfUser.allocationType
                          ]
                        )}
                      </SingleLineItem>
                    ),
                  },
                  corporateAllocationsOfUser.corporateSegment ?? "-",
                ],
              };

              const carouselRealSize = getCarouselRealSize();
              for (let i = 0; i < carouselRealSize; i++) {
                row.cells.push({
                  paddingmode: "none",
                  justifyContent: "center",
                  CellRenderer: () => (
                    <CarouselCell
                      items={corporateAllocationsOfUser.allocationYearMonths}
                      columnIndex={i}
                      checkIsCurrentItem={(item, yearMonth) =>
                        item.yearMonth.equals(yearMonth)
                      }
                      CarouselComponentMemo={({ item, yearMonth }) => (
                        <CarouselContent
                          EditingButtonContainer={EditingButtonContainer}
                          allocationGroup={
                            corporateAllocationsOfUser.allocationGroup
                          }
                          yearMonth={yearMonth}
                          percentage={item?.percentage}
                        />
                      )}
                    />
                  ),
                });
              }

              row.cells.push({
                CellRenderer: () => (
                  <Protected
                    restrictions={{
                      type: PermissionType.CorporateAllocations,
                      level: PermissionLevel.Delete,
                    }}
                  >
                    <DeleteButtonSuperUserContainer
                      DeleteButtonContainer={DeleteButtonContainer}
                      allocationsOfUser={corporateAllocationsOfUser}
                    />
                  </Protected>
                ),
                align: "right",
                justifyContent: "end",
                paddingmode: "horizontal",
              });

              return row;
            }
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );

  return {
    headCells,
    onTablePageChange,
    deletionModal,
    editingModal,
  };
};
