import * as yup from "yup";
import { useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { format } from "date-fns";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../../shared/types/filter.types";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { JobRatesFilterForm } from "./JobRatesFilterForm";
import { JobRateFilterDTO } from "../../../../../../../shared/specific/DTOs/jobRate/filters/JobRateFilterDTO";
import { JobRoleDTO } from "../../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";

interface OwnProps {
  jobRole: JobRoleDTO | null;
}

export const useFilterForm = ({ jobRole }: OwnProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      jobRole,
      subsidiary: null,
      startDate: null,
      endDate: new Date(),
    };

    const validationSchema = yup.object({
      jobRole: yup.object().nullable(),
      subsidiary: yup.object().nullable(),
      startDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      endDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
    });

    return { initialValues, validationSchema, enableReinitialize: true };
  }, [t, jobRole]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<JobRateFilterDTO>({
          currency: formValues.subsidiary?.currency || undefined,
          startDate: formValues.startDate
            ? format(formValues.startDate, "yyyy-MM")
            : undefined,
          endDate: formValues.endDate
            ? format(formValues.endDate, "yyyy-MM")
            : undefined,
          idJobRole: formValues.jobRole?.id,
        }),
      FormContentMemo: JobRatesFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });
  return { filterButton, filters, filterModal };
};
