import { ButtonBase, css, styled } from "@mui/material";
import { ReactElement } from "react";

interface OwnProps {
  labelColorType?: string;
  formMode?: string;
  isCollaboratorCell?: boolean;
  children?: ReactElement;
}

const PureDiv = ({
  labelColorType,
  formMode,
  isCollaboratorCell,
  children,
  ...rest
}: OwnProps) => <div {...rest}>{children}</div>;

export const Container = styled(PureDiv)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  ${({ labelColorType }) =>
    labelColorType &&
    css`
      background-color: ${labelColorType};
    `}
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;

export const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  width: 100%;
`;

export const StyledReadOnlyCarouselCell = styled(PureDiv)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${({ isCollaboratorCell }) =>
    isCollaboratorCell ? "flex-start" : "center"};
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSize}px;

  & > * {
    white-space: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  & > :last-child {
    color: ${({ theme, formMode, labelColorType }) =>
      formMode === "labelType" && labelColorType
        ? "black"
        : theme.palette.primary.main};
  }
`;

export const StyledCarouselCell = styled(StyledReadOnlyCarouselCell)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
  height: 100%;
  width: 100%;
  margin-right: 6px;
`;

export const StyleDobleCell = styled(PureDiv)`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;
