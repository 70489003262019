import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  getCollaboratorHistoryById,
  updateCollaboratorHistory,
} from "../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { CollaboratorHistoryDTO } from "../../../../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryDTO";
import { CollaboratorHistoryUpdateDTO } from "../../../../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCollboratorHistoryForm } from "../shared/react/components/content/CommonCollboratorHistoryForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const { initialValues, validationSchema } = useFormikConfig();

  const formikConfig = {
    initialValues,
    validationSchema,
  };

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      FormValues,
      { collaboratorHistory?: CollaboratorHistoryDTO },
      { collaboratorHistoryData: CollaboratorHistoryDTO }
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => (
          <div>{t("collaboratorTrack.modal.data.edition.title")}</div>
        ),
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: { collaboratorHistoryData },
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const collaboratorHistory = await getCollaboratorHistoryById(
              collaboratorHistoryData.id
            );
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!collaboratorHistory)
              return setModalError(
                t(
                  "collaboratorTrack.errors.data.general.collaboratorTrackDoesNotExist"
                )
              );

            setInternalFormData({ collaboratorHistory });
            setFormValues({
              ...collaboratorHistory,
              registryNumber: collaboratorHistory.registryNumber ?? "",
              startYearMonth: collaboratorHistory.startYearMonth
                ? collaboratorHistory.startYearMonth?.toDate()
                : null,
              contractRole: !collaboratorHistory.contractRole
                ? null
                : {
                    id: collaboratorHistory.contractRole.id,
                    contractRoleTranslations:
                      collaboratorHistory.contractRole.contractRoleTranslations,
                    name: collaboratorHistory.contractRole.name,
                  },
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "collaboratorTrack.errors.data.general.collaboratorTrackDoesNotExist",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonCollboratorHistoryForm {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData: collaboratorHistoryData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const collaboratorHistoryUpdated: CollaboratorHistoryUpdateDTO = {
              id: collaboratorHistoryData?.collaboratorHistory?.id ?? 0,
              idUserCollaborator:
                collaboratorHistoryData?.collaboratorHistory
                  ?.idUserCollaborator ?? 0,
              idOffice: formValues.office?.id ?? 0,
              idJobRole: formValues.jobRole?.id ?? undefined,
              idContractRole: formValues.contractRole?.id ?? 0,
              idCostCenter: formValues.costCenter?.id ?? 0,
              startYearMonth: YearMonth.createFromDate(
                formValues.startYearMonth ?? new Date()
              ),
              registryNumber: formValues?.registryNumber
                ? formValues?.registryNumber.toString()
                : "",
              idHorizontal: formValues.horizontal?.id ?? undefined,
              version:
                collaboratorHistoryData?.collaboratorHistory?.version ?? "",
            };

            await updateCollaboratorHistory(collaboratorHistoryUpdated);

            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "collaboratorTrack.errors.data.edit.failedToEditCollaboratorTrack",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
