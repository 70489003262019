import * as yup from "yup";
import {
  ExpenseGroupTranslationDTO,
  schemaExpenseGroupTranslationDTO,
} from "./translation/ExpenseGroupTranslation";

export interface ExpenseGroupDTO {
  id: number;
  groupName: string;
  expenseGroupTranslation: ExpenseGroupTranslationDTO[];
}

export const schemaExpenseGroupDTO = yup.object({
  id: yup.number().integer().defined(),
  groupName: yup.string().defined(),
  expenseGroupTranslation: yup
    .array(schemaExpenseGroupTranslationDTO)
    .defined(),
});
