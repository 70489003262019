import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { StyledProjectInfo, StyledStatusReturned } from "../../index.styles";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { maxLengthFields } from "../../../../constants/form.constants";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { CommonInvoicesFormValues } from "../../../../types/form/general.types";
import { InvoicesStatusEnum } from "../../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { getNowBrazilianDate } from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  values: CommonInvoicesFormValues;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const CanceledForm = ({
  isSubmitting,
  invoiceData,
  values,
  setFieldValue,
}: OwnProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    setFieldValue(
      "reasonForCancellation",
      [
        InvoicesStatusEnum.CancellationAnalysis,
        InvoicesStatusEnum.ProvisionForCancellation,
        InvoicesStatusEnum.Canceled,
      ].includes(invoiceData.status)
        ? invoiceData.reasonForCancellation || ""
        : ""
    );
  }, []);

  return (
    <>
      <StyledProjectInfo>
        {values.status?.id === InvoicesStatusEnum.ProvisionForCancellation ? (
          <>
            <DatePickerFormik
              name="plannedCancellationDate"
              datePickerProps={{
                label: t("invoice.keywords.fields.cancellationForecastDate"),
                disabled: isSubmitting,
                slotProps: {
                  popper: {
                    placement: "left",
                  },
                },
                minDate: getNowBrazilianDate().setDate(1),
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <LocalizedNumberTextFieldFormik
              name="percentageToCancel"
              min={1}
              max={100}
              padFractionalZeros
              textFieldProps={{
                label: t("invoice.keywords.fields.netValue"),
                required: true,
                disabled: isSubmitting,
                InputProps: {
                  endAdornment: "%",
                },
              }}
            />
          </>
        ) : (
          <DatePickerFormik
            name="cancellationDate"
            datePickerProps={{
              label: t("invoice.keywords.fields.cancellationDate"),
              disabled: isSubmitting,
              slotProps: {
                popper: {
                  placement: "left",
                },
              },
              minDate: getNowBrazilianDate().setDate(1),
            }}
            textFieldProps={{
              required: true,
            }}
          />
        )}
      </StyledProjectInfo>
      <StyledStatusReturned>
        <TextFieldFormik
          name="reasonForCancellation"
          label={t("invoice.keywords.fields.reasonForCancellation")}
          multiline
          rows={3}
          required
          fullWidth
          inputProps={{
            maxLength: maxLengthFields.invoiceNote,
          }}
          disabled={isSubmitting}
        />
      </StyledStatusReturned>
    </>
  );
};
