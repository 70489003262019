import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findBinarySearch } from "../../../../../../../../../shared/common/helpers/data/arrays/finders.helpers";
import { FormattedNumber } from "../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedNumber";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import {
  SummarySection,
  SummarySectionTitle,
} from "../../shared/react/styles/ui/summarySection.styles";
import { SummaryContext } from "../../shared/react/contexts/SummaryContext";
import { RowData } from "../../shared/types/data/carousel.types";
import { SummaryCarouselContext } from "../../shared/react/contexts/SummaryCarouselContext";
import {
  StyledCenteredInfo,
  StyledRowHeader,
} from "../../shared/react/styles/table/summaryTable.styles";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";

const CAROUSEL_SIZE = 8;

interface OwnProps {
  positionStart: number;
}

export const SummaryMvSummaryWithinContext = ({ positionStart }: OwnProps) => {
  const { t } = useTranslation();

  const { summaryData } = useContext(SummaryContext);
  const { startCarousel, carouselRealSize, totalYearMonths } = useContext(
    SummaryCarouselContext
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        headColumn: true,
        canSort: false,
        minWidth: 300,
        width: "10%",
      },
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }
    for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
      headCells.push({ value: "", canSort: false });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      align: "right",
      canSort: false,
    });

    return headCells;
  }, [t, carouselRealSize]);

  const rowsHeaders = useMemo((): RowData[] | null => {
    if (!summaryData?.monthlyAmounts) return null;

    const mvHeaders = [
      {
        name: "planningSummary.keywords.fields.plannedMv.name",
        items: summaryData.monthlyAmounts.plannedMv,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.performedMv",
        items: summaryData.monthlyAmounts.performedMv,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.accumulatedPlannedMv",
        items: summaryData.monthlyAmounts.accumulatedPlannedMv,
        isAccumulated: true,
      },
      {
        name: "planningSummary.keywords.fields.accumulatedPerformedMv",
        items: summaryData.monthlyAmounts.accumulatedPerformedMv,
        isAccumulated: true,
      },
    ];

    // TODO: essa condicional vai morrer depois que adionarmos isso ao sumário de frames
    if (
      summaryData.monthlyAmounts.plannedFinancialCostMv.length > 0 ||
      summaryData.monthlyAmounts.performedFinancialCostMv.length > 0
    ) {
      mvHeaders.splice(
        2,
        0,
        {
          name: "planningSummary.keywords.fields.plannedFinancialCostMv",
          items: summaryData.monthlyAmounts.plannedFinancialCostMv,
          isAccumulated: false,
        },
        {
          name: "planningSummary.keywords.fields.performedFinancialCostMv",
          items: summaryData.monthlyAmounts.performedFinancialCostMv,
          isAccumulated: false,
        }
      );
    }

    return mvHeaders;
  }, [summaryData?.monthlyAmounts]);

  const rows = useMemo(() => {
    if (!rowsHeaders) return null;
    if (!totalYearMonths) return [];

    startCarousel({
      carouselSize: CAROUSEL_SIZE,
      monthsOffsetFirstYearMonth: positionStart,
    });

    return rowsHeaders.map(({ name, items, isAccumulated }, index) => {
      const row: ExternalEnhancedRow = {
        id: index,
        cells: [
          {
            value: t(name),
            CellRenderer: () => <StyledRowHeader>{t(name)}</StyledRowHeader>,
          },
        ],
      };

      row.cells.push({});

      for (let i = 0; i < carouselRealSize; i++) {
        row.cells.push({
          paddingmode: "none",
          CellRenderer: () => (
            <CarouselCell
              items={items}
              columnIndex={i}
              findCurrentItem={(items, yearMonth) => {
                return findBinarySearch({
                  sortedArray: items,
                  onNotFound: isAccumulated ? "before" : "null",
                  checkDesiredValuePosition: (item) =>
                    yearMonth.differenceMonths(item.yearMonth),
                });
              }}
              CarouselComponentMemo={({ item, yearMonth }) => {
                const dateNow = new Date();
                const yearMonthNow = new YearMonth(
                  dateNow.getMonth(),
                  dateNow.getFullYear()
                ).toDate();
                return (
                  <StyledCenteredInfo
                    isPast={
                      yearMonth ? yearMonthNow > yearMonth.toDate() : true
                    }
                  >
                    {!item ? (
                      "-"
                    ) : (
                      <FormattedNumber
                        value={item.amount}
                        options={{
                          fractionDigits: 2,
                          suffix: "%",
                        }}
                      />
                    )}
                  </StyledCenteredInfo>
                );
              }}
            />
          ),
        });
      }
      for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
        row.cells.push({});
      }

      row.cells.push({});

      return row;
    });
  }, [t, rowsHeaders, carouselRealSize, totalYearMonths]);

  if (!rows) return null;

  return (
    <SummarySection>
      <SummarySectionTitle>
        {t("planningSummary.keywords.sections.mvSummary")}
      </SummarySectionTitle>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: 300,
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
    </SummarySection>
  );
};
