import { Week } from "../../../../enums/data/dates/Week.enum";

export const weekToTranslationCode: { [key in Week]: string } = {
  [Week.Sunday]: "general.keywords.dateTime.week.sunday",
  [Week.Monday]: "general.keywords.dateTime.week.monday",
  [Week.Tuesday]: "general.keywords.dateTime.week.tuesday",
  [Week.Wednesday]: "general.keywords.dateTime.week.wednesday",
  [Week.Thursday]: "general.keywords.dateTime.week.thursday",
  [Week.Friday]: "general.keywords.dateTime.week.friday",
  [Week.Saturday]: "general.keywords.dateTime.week.saturday",
};
