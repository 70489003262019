import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../common/types/data/form/autocomplete.types";
import { CustomerBalanceTypeEnum } from "../../../../../../../enums/customerBalance/customerBalance.enum";
import { customerBalanceTypeToTranslationCode } from "../../../../../../../maps/customerBalance/customerBalanceTypeToTranslationCode";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<CustomerBalanceTypeEnum>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const CustomerBalanceTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const filteredAllocationType = Object.values(CustomerBalanceTypeEnum)
      .filter((x) => typeof x === "number")
      .map((x) => x as CustomerBalanceTypeEnum);

    const data = filteredAllocationType.map(
      (x): AutocompleteOption<CustomerBalanceTypeEnum> => ({
        id: x,
        label: t(customerBalanceTypeToTranslationCode[x]),
      })
    );

    return data;
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("customerBalance.keywords.fields.balanceType"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
