import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEditingForm } from "./useEditingForm";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { WaccFilterDTO } from "../../../../../../shared/specific/DTOs/wacc/WaccFilterDTO";
import { getWacc } from "../../../../../../services/wacc/wacc.service";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { useDeletionForm } from "./useDeletionForm";

interface OwnProps {
  filters?: WaccFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("wacc.keywords.fields.yearMonth"),
        canSort: true,
      },
      t("wacc.keywords.fields.plannedTax"),
      {
        value: t("wacc.keywords.fields.performedTax"),
        canSort: false,
      },
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalPageChanger = useCallback(
    async ({ newPage, order, orderBy, rowsPerPage }) => {
      const headKeys = ["yearMonth", "plannedTax", "performedTax"];

      try {
        const {
          tableData: waccData,
          page,
          totalCount,
        } = await getWacc({
          filters,
          pagination: {
            pageSize: rowsPerPage,
            page: newPage + 1,
            columnOrderByDir: orderBy !== null ? order : undefined,
            columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
          },
        });

        const rows = waccData.map(
          (ud): ExternalEnhancedRow => ({
            id: ud.id,
            cells: [
              {
                displayValue: ud.yearMonth.toPrettyString(),
                value: ud.yearMonth.toDate(),
              },
              {
                displayValue: `${formatNumber(ud.plannedTax, { fractionDigits: 2 }) ?? ""}%`,
                value: ud.plannedTax,
              },
              {
                displayValue:
                  ud.performedTax === 0
                    ? "-"
                    : `${formatNumber(ud.performedTax!, { fractionDigits: 2 })}%`,
                value: ud.performedTax ?? 0,
              },
              {
                CellRenderer: () => (
                  <>
                    <Protected
                      restrictions={{
                        type: PermissionType.Wacc,
                        level: PermissionLevel.Update,
                      }}
                    >
                      <EditingButtonContainer {...ud} />
                    </Protected>
                    <Protected
                      restrictions={{
                        type: PermissionType.Wacc,
                        level: PermissionLevel.Delete,
                      }}
                    >
                      <DeleteButtonContainer {...ud} />
                    </Protected>
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ],
          })
        );

        return {
          rows,
          page: page - 1,
          totalItems: totalCount,
        };
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    },
    [filters]
  );

  return {
    headCells,
    onPageChange,
    deleteDialog: deletionModal,
    editingModal,
  };
};
