import { css, styled } from "@mui/material";
import { HTMLAttributes } from "react";
import { createGridAreaLettersRange } from "../../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

interface StyledFormProps extends HTMLAttributes<HTMLDivElement> {
  hiddenDelayJustification: boolean;
}

const PureStyledForm = ({
  hiddenDelayJustification,
  ...rest
}: StyledFormProps) => <div {...rest} />;

export const StyledForm = styled(PureStyledForm)`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${({ hiddenDelayJustification, theme }) => {
    return hiddenDelayJustification
      ? css`
          grid-template-columns: repeat(3, 1fr);
          grid-template-areas: "a b c";
          ${createGridAreaLettersRange(3)}

          ${theme.breakpoints.down("md")} {
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
              "a b"
              "c c";
          }

          ${theme.breakpoints.down("sm")} {
            grid-template-columns: 1fr;
            grid-template-areas:
              "a"
              "b"
              "c";
          }
        `
      : css`
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            "a b"
            "c d";
          ${createGridAreaLettersRange(4)}

          ${theme.breakpoints.down("sm")} {
            grid-template-columns: 1fr;
            grid-template-areas:
              "a"
              "b"
              "c"
              "d";
          }
        `;
  }};
`;
