import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import { ExpensesCarouselContext } from "../../shared/react/contexts/ExpensesCarouselContext";
import { getGeneralExpenses } from "../../../../../../../../../services/expenses/expenses.service";
import { complementSortExpensesTable, getMonthlyTotal } from "./index.helpers";
import { StyledHorizontalHeader } from "./index.styles";
import { expenseTypeToTranslationCodeMap } from "../../../../../../../../../shared/specific/maps/expenses/expenseType.maps";
import { CarouselContent } from "./CarouselContent";
import { formatCurrency } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { useEditingCellForm } from "../useEditingCellForm";
import { ExpensesOfTypeDTO } from "../../../../../../../../../shared/specific/DTOs/expenses/ExpensesOfTypeDTO";
import { CarouselTotaMonthsContent } from "./CarouselTotaMonthsContent";
import { ExpensesSummaryContext } from "../../shared/react/contexts/ExpensesSummaryContext";
import { ExpenseType } from "../../../../../../../../../shared/specific/enums/expenses/ExpenseType";

interface OwnProps {
  reloadTablePage: () => void;
  costCenterPep: CostCenterPepOnlyDTO | null;
}

const CAROUSEL_SIZE = 8;

export const useTableData = ({ reloadTablePage, costCenterPep }: OwnProps) => {
  const { t } = useTranslation();

  const { project, accessMode } = useProjectContext();
  const { startCarousel, carouselRealSize, getCarouselRealSize } = useContext(
    ExpensesCarouselContext
  );
  const carouselRealSizeFromFunction = getCarouselRealSize();
  const { EditingButtonContainer, editingModal } = useEditingCellForm({
    reloadTablePage,
  });
  const { setSummaryData, setIsLoadingSummary, setHasErrorSummary } =
    useContext(ExpensesSummaryContext);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        headColumn: true,
        canSort: false,
      },
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      canSort: false,
    });

    return headCells;
  }, [t, accessMode, carouselRealSize]);

  const generateMonthlyTotalRow = (
    expensesOfTypes: ExpensesOfTypeDTO[],
    costCenterPep: CostCenterPepOnlyDTO
  ) => {
    const row: ExternalEnhancedRow = {
      id: -1,
      cells: [
        {
          CellRenderer: () => (
            <StyledHorizontalHeader>
              <div>{t("expenses.keywords.fields.monthlyTotal")}</div>
            </StyledHorizontalHeader>
          ),
        },
      ],
    };

    const monthlyTotal = getMonthlyTotal(expensesOfTypes);

    row.cells.push({});

    const carouselRealSize = getCarouselRealSize();
    for (let i = 0; i < carouselRealSize; i++) {
      row.cells.push({
        paddingmode: "none",
        CellRenderer: () => (
          <CarouselCell
            items={monthlyTotal}
            columnIndex={i}
            checkIsCurrentItem={(item, yearMonth) =>
              item.yearMonth.equals(yearMonth)
            }
            CarouselComponentMemo={({ item }) => (
              <CarouselTotaMonthsContent
                costCenterPep={costCenterPep}
                amount={item?.amount}
              />
            )}
          />
        ),
      });
    }
    row.cells.push({});

    return row;
  };

  const singlePageLoader =
    useCallback<ExternalEnhancedTableExternalSinglePageLoader>(async () => {
      if (!costCenterPep)
        throw new TranslatedError(
          "costCenterPeps.errors.data.fields.costCenterPep.noPepProvided"
        );

      setIsLoadingSummary(true);
      setHasErrorSummary(false);

      try {
        const generalExpenses = await getGeneralExpenses(costCenterPep.id);

        const completeExpensesOfTypes = complementSortExpensesTable(
          generalExpenses.expensesOfTypes,
          costCenterPep
        );

        setSummaryData({
          totalExpensesAmount: generalExpenses.totalExpensesAmount,
        });

        if (project.scheduledStartDate && project.finalScheduledEndDate)
          startCarousel({ carouselSize: CAROUSEL_SIZE });

        const rows: ExternalEnhancedRow[] = [];

        rows.push(
          generateMonthlyTotalRow(completeExpensesOfTypes, costCenterPep)
        );
        rows.push(
          ...completeExpensesOfTypes.map((expensesOfType, index) => {
            if (expensesOfType.expenseType !== ExpenseType.Additional) {
              const row: ExternalEnhancedRow = {
                id: index,
                rowInfo: expensesOfType,
                cells: [
                  {
                    value: expensesOfType.expensesTotalAmount,
                    CellRenderer: () => (
                      <StyledHorizontalHeader>
                        <span>
                          {t(
                            expenseTypeToTranslationCodeMap[
                              expensesOfType.expenseType
                            ]
                          )}
                        </span>
                        <span>
                          {formatCurrency({
                            value: expensesOfType.expensesTotalAmount,
                            currencySymbol: costCenterPep.currencySymbol,
                          })}
                        </span>
                      </StyledHorizontalHeader>
                    ),
                  },
                ],
              };

              row.cells.push({});

              const carouselRealSize = getCarouselRealSize();
              for (let i = 0; i < carouselRealSize; i++) {
                row.cells.push({
                  paddingmode: "none",
                  CellRenderer: () => (
                    <CarouselCell
                      items={expensesOfType.expenses}
                      columnIndex={i}
                      checkIsCurrentItem={(item, yearMonth) =>
                        item.yearMonth.equals(yearMonth)
                      }
                      CarouselComponentMemo={({ item, yearMonth }) => {
                        return (
                          <CarouselContent
                            costCenterPep={costCenterPep}
                            EditingButtonContainer={EditingButtonContainer}
                            expensesRow={expensesOfType}
                            expenseCell={item}
                            yearMonth={yearMonth}
                          />
                        );
                      }}
                    />
                  ),
                });
              }
              row.cells.push({});

              return row;
            }
            return [];
          })
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);
        setHasErrorSummary(true);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      } finally {
        setIsLoadingSummary(false);
      }
    }, [
      costCenterPep,
      accessMode,
      carouselRealSize,
      carouselRealSizeFromFunction,
    ]);

  return {
    headCells,
    singlePageLoader,
    editingModal,
  };
};
