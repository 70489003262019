import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { adoptedSolution } from "./areas/adoptedSolution";
import { billingCountry } from "./areas/billingCountry";
import { buSegment } from "./areas/buSegment";
import { confidential } from "./areas/confidential";
import { contextChallenge } from "./areas/contextChallenge";
import { customerContact } from "./areas/customerContact";
import { keyword } from "./areas/keywords";
import { portfolio } from "./areas/portfolio";
import { projectManager } from "./areas/projectManager";
import { results } from "./areas/results";
import { skills } from "./areas/skills";
import { header } from "./areas/header";

export const generatePptxFile = async (
  projectData: ProjectAdditionalInformationDTO
) => {
  const pres = new Pptxgen();

  const slide = pres.addSlide();

  header(projectData, slide, pres);

  slide.addShape(pres.ShapeType.rect, {
    align: pres.AlignH.left,
    y: "10%",
    x: "1%",
    w: "98%",
    h: 0.02,
    fill: { color: "#c4c4c4" },
  });

  buSegment(projectData, slide, pres);
  billingCountry(projectData, slide, pres);
  projectManager(projectData, slide, pres);
  customerContact(projectData, slide, pres);
  portfolio(projectData, slide, pres);
  keyword(projectData, slide, pres);
  skills(projectData, slide, pres);
  confidential(projectData, slide, pres);

  contextChallenge(projectData, slide, pres);
  adoptedSolution(projectData, slide, pres);
  results(projectData, slide, pres);

  const ppt = await pres.writeFile();
  return ppt;
};
