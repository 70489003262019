import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  getAdditive,
  updateAdditive,
} from "../../../../../../../../services/additives/additives.service";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { DateOnly } from "../../../../../../../../shared/common/classes/data/date/DateOnly";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useFormikModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { AdditiveDTO } from "../../../../../../../../shared/specific/DTOs/additive/AdditiveDTO";
import { AdditiveUpdateDTO } from "../../../../../../../../shared/specific/DTOs/additive/AdditiveUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { CommonAdditivesForm } from "../shared/react/components/content/CommonAdditivesForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { FormValues } from "../shared/types/data/form/values.types";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButton, contentModal: editingModal } =
    useFormikModalButton<FormValues, AdditiveDTO, AdditiveDTO>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("additives.modal.data.edit.title"),
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => (
          <CommonAdditivesForm
            {...props}
            mode="editing"
            createdBy={props?.internalFormData?.createdBy}
          />
        ),
        FormActionsMemo: ({ closeModal, isSubmitting, submitFormValues }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.close")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData: additive,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!formValues.scheduledStartDate || !formValues.scheduledEndDate)
            throw new Error("Dates cannot be null on submit.");

          const amountToSubmit = parseLocaleNumber(formValues.amount);

          try {
            await updateAdditive(
              convertTo<AdditiveUpdateDTO>({
                id: additive?.id ?? 0,
                amount: amountToSubmit,
                justification: formValues.justification,
                anticipationJustification:
                  formValues.anticipationJustification || undefined,
                mv: parseLocaleNumber(formValues.mv),
                scheduledStartDate: DateOnly.createFromDate(
                  formValues.scheduledStartDate
                ),
                scheduledEndDate: DateOnly.createFromDate(
                  formValues.scheduledEndDate
                ),
                approvedCredit: formValues.approvedCredit?.id ?? false,
                version: additive?.version,
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );

            closeModal();
            reloadTablePage();

            if (
              amountToSubmit !== additive?.amount ||
              formValues.scheduledEndDate.getDate() !==
                additive.scheduledEndDate.toDate().getDate() ||
              project.isValid
            )
              reloadProject();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "additives.errors.data.edit.failedToEditAdditive",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: externalAdditive,
          setInternalFormData,
          setFormValues,
          setIsLoadingModal,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
          setModalError,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const additive = await getAdditive(externalAdditive.id);
            if (!additive)
              return setModalError(
                i18next.t("additives.errors.data.general.additiveDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(additive);
            let approvedCredit = null;
            if (additive.approvedCredit) {
              approvedCredit = {
                id: true,
                label: t("general.keywords.general.yes"),
              };
            }

            if (additive.approvedCredit === false) {
              approvedCredit = {
                id: false,
                label: t("general.keywords.general.no"),
              };
            }
            setFormValues({
              amount: formatNumber(additive.amount, { fractionDigits: 2 }),
              anticipationJustification:
                additive.anticipationJustification ?? "",
              justification: additive.justification,
              mv: formatNumber(additive.mv, { fractionDigits: 2 }),
              scheduledEndDate: additive.scheduledEndDate.toDate(),
              scheduledStartDate: additive.scheduledStartDate.toDate(),
              approvedCredit,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "additives.errors.data.fetch.failedToFetchAdditive",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
    });

  return { EditingButton, editingModal };
};
