import { styled } from "@mui/material";
import { HTMLProps } from "react";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  titleAlignment?: "left" | "right" | "center";
}

const IsolatedDiv = ({ titleAlignment, ...rest }: OwnProps) => (
  <div {...rest} />
);

export const StyledTitleString = styled(IsolatedDiv)`
  font-size: 1.25rem;
  word-break: break-all;
  text-align: ${({ titleAlignment = "left" }) => titleAlignment};
`;
