import { TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeAllocationType } from "../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { StyledForm } from "./index.styles";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import {
  CalendarCellContentProps,
  CalendarGrid,
} from "../../../../../../../../shared/common/react/components/table/CalendarGrid";
import { CalendarCell } from "./CalendarCell";
import { TimesheetMonthDayAllEntriesDTO } from "../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDayAllEntriesDTO";

interface OwnProps {
  timesheetsMonth: TimesheetMonthDayAllEntriesDTO;
  yearMonth: YearMonth;
}

export const ViewingAllEntriesForm = ({
  timesheetsMonth,
  yearMonth,
}: OwnProps) => {
  const { t } = useTranslation();

  const yearMonthField = useMemo(() => {
    return (
      <TextField
        value={yearMonth.toPrettyString()}
        label={t("general.keywords.dateTime.general.month")}
        disabled
      />
    );
  }, [t, yearMonth]);

  const CalendarCellContainer = useCallback(
    (props: CalendarCellContentProps) => {
      return <CalendarCell {...props} timesheetsMonth={timesheetsMonth} />;
    },
    [timesheetsMonth]
  );

  return (
    <StyledForm>
      {yearMonthField}
      <TextField
        value={t(
          employeeAllocationTypeToTranslationCodeMap[
            EmployeeAllocationType.Allocation
          ]
        )}
        label={t("general.keywords.fields.nature")}
        disabled
      />
      <CalendarGrid
        yearMonth={yearMonth}
        CalendarCellContent={CalendarCellContainer}
      />
    </StyledForm>
  );
};
