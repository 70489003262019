import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import { TextField } from "@mui/material";
import {
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { ProjectionFormValues } from "../../../../../types/form.types";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { TextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { ExpenseTypesAutocompleteFormil } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/expenseTypes/ExpenseTypesAutocompleteFormil";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { DeleteIconButton } from "../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { sleep } from "../../../../../../../../../../../shared/common/helpers/general.helpers";
import { useEditingRowComment } from "../useEditingRowComment";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CarouselCell } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { CustomersNamesAutocompleteFormik } from "../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/CustomersNamesAutocompleteFormik";
import { CheckboxFormik } from "../../../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { ExpenseGroup } from "../../../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";
import { ProjectionsSuperUserContext } from "../../../../../context/projectionsSuperUserContext";
import { useProjectionSearchParams } from "../../../../hooks/useProjectionSearchParams";
import { useCopyPasteCellInformation } from "../useCopyPasteCellInformation";

interface OwnProps {
  formikProps: FormikProps<ProjectionFormValues>;
  setIsLoading: (isLoading: boolean) => void | undefined;
  reloadTablePage: () => void;
}

export const useTableData = ({
  formikProps,
  setIsLoading,
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();

  const { canEditAllProjections } = useContext(ProjectionsSuperUserContext);

  const { values, setFieldValue } = formikProps;

  const { editingModal, EditingButtonContainer } = useEditingRowComment({
    reloadTablePage,
  });

  const [idTodelete, setIdTodelete] = useState<string | null>(null);
  const { PastIconButton } = useCopyPasteCellInformation({ formikProps });

  const {
    clientAccount,
    customerTrigram,
    idDivision,
    idExpenseGroup,
    idSubsidiary,
    year,
  } = useProjectionSearchParams();

  const currency = idSubsidiary === 1 ? "R$" : "US$";

  const isToShowCustomerTrigramAndClientAccount = useMemo(() => {
    return ![
      ExpenseGroup.AdmCorp,
      ExpenseGroup.Pdd,
      ExpenseGroup.IrCsll,
      ExpenseGroup.NetFinancialExpenses,
      ExpenseGroup.ParticipationProgram,
    ].includes(idExpenseGroup as ExpenseGroup);
  }, [idExpenseGroup]);

  const isToShowTransCountry = useMemo(() => {
    return [
      ExpenseGroup.AdmCorp,
      ExpenseGroup.SalesCost,
      ExpenseGroup.Sgea,
      ExpenseGroup.Dol,
    ].includes(idExpenseGroup as ExpenseGroup);
  }, [idExpenseGroup]);

  useEffect(() => {
    if (idTodelete) {
      const newAdjustmentLines =
        values.adjustmentLines?.filter(
          (adjustmentLine) => idTodelete !== adjustmentLine.id
        ) ?? [];

      setFieldValue("adjustmentLines", newAdjustmentLines);

      setIdTodelete(null);
    }
  }, [idTodelete]);

  useEffect(() => {
    values.adjustmentLines?.forEach((adjustmentLine, i) => {
      if (
        adjustmentLine.costCenter &&
        adjustmentLine.costCenter.subsidiary.id !== idSubsidiary
      ) {
        setFieldValue(`adjustmentLines[${i}].transferenceCountry`, true);
      } else {
        setFieldValue(`adjustmentLines[${i}].transferenceCountry`, false);
      }
    });
  }, [values.adjustmentLines]);

  const rangeYearMonth = YearMonth.range(
    new YearMonth(0, year ?? 0),
    new YearMonth(11, year ?? 0)
  );

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(
      async ({ setInfo }) => {
        try {
          await sleep(500);

          if (values.adjustmentLines?.length === 0 || !values.adjustmentLines) {
            setInfo("projection.errors.data.general.noManualAdjustmentFound");
            return [];
          }

          const currentMonth = YearMonth.createFromNow().month;
          const currentYear = YearMonth.createFromNow().year;
          const currentYearMonth = new YearMonth(currentMonth, currentYear);

          const isDisabled = (month: number): boolean => {
            if (canEditAllProjections) return false;

            const isPast = new YearMonth(month, year ?? 0) < currentYearMonth;

            return isPast;
          };

          const rows = values.adjustmentLines.map(
            (adjustmentLine, i): ExternalEnhancedRow => {
              const yearMonthFields = [
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].january.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(0)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].february.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(1)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].march.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(2)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].april.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(3)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].may.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(4)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].june.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(5)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].july.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(6)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].august.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(7)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].september.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(8)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].october.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(9)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].november.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(10)}
                  padFractionalZeros
                />,
                <LocalizedNumberTextFieldFormik
                  name={`adjustmentLines[${i}].december.value`}
                  textFieldProps={{
                    label: currency,
                    required: true,
                  }}
                  disabled={isDisabled(11)}
                  padFractionalZeros
                />,
              ];

              const row: ExternalEnhancedRow = {
                id: adjustmentLine.id,
                cells: [
                  "Manual",
                  {
                    CellRenderer: () => (
                      <CostCentersSearchAutocompleteFormik
                        name={`adjustmentLines[${i}].costCenter`}
                        textfieldProps={{ required: true }}
                        autocompleteProps={{
                          fullWidth: true,
                        }}
                        showCostCenterName={false}
                        filters={{
                          idDivision: idDivision ?? 0,
                        }}
                      />
                    ),
                  },
                  {
                    CellRenderer: () => (
                      <TextFieldFormik
                        name={`adjustmentLines[${i}].accountCode`}
                        label={t("projection.keywords.fields.accountCode")}
                      />
                    ),
                  },
                  {
                    CellRenderer: () =>
                      customerTrigram === "" ? (
                        <CustomerCodesSearchAutocompleteFormik
                          name={`adjustmentLines[${i}].customerTrigram`}
                          autocompleteProps={{
                            fullWidth: true,
                          }}
                        />
                      ) : (
                        <TextField value={customerTrigram} fullWidth disabled />
                      ),
                  },
                  {
                    CellRenderer: () =>
                      clientAccount === "" ? (
                        <CustomersNamesAutocompleteFormik
                          name={`adjustmentLines[${i}].clientAccount`}
                          autocompleteProps={{
                            fullWidth: true,
                          }}
                        />
                      ) : (
                        <TextField value={clientAccount} fullWidth disabled />
                      ),
                  },
                  {
                    CellRenderer: () => (
                      <ExpenseTypesAutocompleteFormil
                        name={`adjustmentLines[${i}].expenseType`}
                        textfieldProps={{
                          required: true,
                          label: t("skillCategory.keywords.general.category"),
                        }}
                        autocompleteProps={{
                          fullWidth: true,
                        }}
                        filters={{
                          idExpenseGroup: idExpenseGroup ?? undefined,
                        }}
                      />
                    ),
                  },
                  {
                    justifyContent: "center",
                    CellRenderer: () =>
                      isToShowTransCountry ? (
                        <CheckboxFormik
                          name={`adjustmentLines[${i}].transferenceCountry`}
                          readOnly
                          disabled
                        />
                      ) : (
                        <div />
                      ),
                  },
                  {
                    CellRenderer: () => (
                      <>
                        <EditingButtonContainer
                          comment={adjustmentLine.comment}
                          formikProps={formikProps}
                          row={i}
                          name={`adjustmentLines[${i}].comment`}
                        />
                        {PastIconButton(i)}
                      </>
                    ),
                  },
                ],
              };

              if (!isToShowCustomerTrigramAndClientAccount) {
                row.cells.splice(3, 2);
              }

              for (let i = 0; i < 3; i++) {
                row.cells.push({
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CarouselCell
                        items={rangeYearMonth}
                        columnIndex={i}
                        checkIsCurrentItem={(item, yearMonth) =>
                          item.equals(yearMonth)
                        }
                        CarouselComponent={({ item, yearMonth }) =>
                          yearMonthFields[yearMonth?.month ?? 12]
                        }
                      />
                    );
                  },
                });
              }

              row.cells.push({
                CellRenderer: () => (
                  <DeleteIconButton
                    onClick={() => setIdTodelete(adjustmentLine.id)}
                  />
                ),
              });

              return row;
            }
          );

          return rows;
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [t, values, canEditAllProjections]
    );

  return {
    onPageChange,
    editingAdjustmentCommentModal: editingModal,
  };
};
