import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCustomers } from "../../../../../../services/customers/customers.service";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { CustomerListByFiltersDTO } from "../../../../../../shared/specific/DTOs/customers/filters/CustomerListByFiltersDTO";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useViewingForm } from "./useViewingForm";

interface OwnProps {
  filters: CustomerListByFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { DeletionButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { ViewingButtonContainer, viewingModal } = useViewingForm();
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("customers.keywords.fields.groupCode"),
        width: "15%",
      },
      {
        value: t("customers.keywords.fields.tradingName"),
        width: 430,
      },
      t("customers.keywords.fields.corporateName"),
      t("customers.keywords.fields.publicAgency"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["code", "tradingName", "corporateName"];

        try {
          const {
            tableData: customersData,
            page,
            totalCount,
          } = await getCustomers({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = customersData.map(
            (customerData): ExternalEnhancedRow => ({
              id: customerData.id,
              cells: [
                customerData.code,
                customerData.tradingName,
                customerData.corporateName,
                customerData.isPublicAgency
                  ? t("general.keywords.general.yes")
                  : t("general.keywords.general.no"),
                {
                  CellRenderer: () => (
                    <>
                      <ViewingButtonContainer {...customerData} />
                      <Protected
                        restrictions={{
                          type: PermissionType.Customer,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...customerData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Customer,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeletionButtonContainer {...customerData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onTablePageChange,
    deletionModal,
    viewingModal,
    editingModal,
  };
};
