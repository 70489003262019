import { TextField, TextFieldProps } from "@mui/material";
import {
  DateValidationError,
  DesktopDatePicker,
  DesktopDatePickerProps,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export interface DatePickerFormikProps<TDate> {
  name: string;
  datePickerProps?: Omit<
    DesktopDatePickerProps<TDate>,
    "renderInput" | "value" | "onChange"
  > & {
    value?: TDate | null;
    onChange?: (
      value: TDate | null,
      context: PickerChangeHandlerContext<DateValidationError>
    ) => void;
    minDateMemo?: TDate;
    maxDateMemo?: TDate;
  };
  textFieldProps?: TextFieldProps;
}

export const DatePickerFormik = <TDate,>({
  name,
  datePickerProps: {
    slotProps = {},
    minDate,
    maxDate,
    minDateMemo,
    maxDateMemo,
    views,
    ...datePickerProps
  } = {},
  textFieldProps: {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...textFieldProps
  } = {},
}: DatePickerFormikProps<TDate>) => {
  const { i18n } = useTranslation();
  const [{ onChange, ...field }, { error }, { setValue }] =
    useField<TDate | null>(name);

  const finalMinDate = useMemo(() => {
    return minDate ?? minDateMemo;
  }, [minDate]);

  const finalMaxDate = useMemo(() => {
    return maxDate ?? maxDateMemo;
  }, [maxDate]);

  return useMemo(() => {
    return (
      <DesktopDatePicker
        {...field}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        slotProps={{
          ...slotProps,
          textField: {
            error: !!error,
            helperText: error,
            ...textFieldProps,
            inputProps: {
              ...inputProps,
            },
            InputProps,
            InputLabelProps,
          },
        }}
        slots={{
          textField: TextField,
        }}
        {...datePickerProps}
        minDate={finalMinDate}
        maxDate={finalMaxDate}
        views={views}
      />
    );
  }, [
    ...Object.values(field),
    ...Object.values(datePickerProps),
    ...Object.values(slotProps),
    ...Object.values(textFieldProps),
    ...Object.values(inputProps),
    ...Object.values(InputProps),
    ...Object.values(InputLabelProps),
    ...Object.values(views ?? []),
    finalMinDate,
    finalMaxDate,
    error,
    i18n.language,
  ]);
};
