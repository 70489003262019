import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";

export const header = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const customerNameCpt: CptProps = {
    text: projectData.customer.tradingName,
    options: {
      x: "1%",
      y: "5%",
      w: "45%",
      color: "363636",
      fontSize: 10,
      bold: true,
    },
  };

  const yearCpt: CptProps = {
    text: projectData.startBaselineYear ?? "-",
    options: {
      align: pres.AlignH.right,
      y: "5%",
      w: "45%",
      x: "54%",
      color: "363636",
      fontSize: 10,
      bold: true,
    },
  };

  const projectNameCpt: CptProps = {
    text: projectData.name,
    options: {
      x: "1%",
      y: "8%",
      w: "45%",
      color: "363636",
      fontSize: 10,
      bold: true,
    },
  };

  const projectCodeCpt: CptProps = {
    text: projectData.code,
    options: {
      align: pres.AlignH.right,
      y: "8%",
      w: "45%",
      x: "54%",
      color: "363636",
      fontSize: 10,
      bold: true,
    },
  };

  slide.addText(customerNameCpt.text, customerNameCpt.options);
  slide.addText(yearCpt.text, yearCpt.options);
  slide.addText(projectNameCpt.text, projectNameCpt.options);
  slide.addText(projectCodeCpt.text, projectCodeCpt.options);
};
