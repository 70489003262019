import { InvoicesStatusEnum } from "../../enums/invoices/InvoicesStatusEnum";

export const invoicesStatusTypeToTranslationCode: {
  [key in InvoicesStatusEnum]: string;
} = {
  [InvoicesStatusEnum.NotReleased]: "invoice.keywords.fields.notReleased",
  [InvoicesStatusEnum.Released]: "invoice.keywords.fields.released",
  [InvoicesStatusEnum.Returned]: "invoice.keywords.fields.returned",
  [InvoicesStatusEnum.Analysis]: "invoice.keywords.fields.analysis",
  [InvoicesStatusEnum.Issued]: "invoice.keywords.fields.issued",
  [InvoicesStatusEnum.PaidOut]: "invoice.keywords.fields.paidOut",
  [InvoicesStatusEnum.ProvisionForCancellation]:
    "invoice.keywords.fields.provisionForCancellation",
  [InvoicesStatusEnum.Canceled]: "invoice.keywords.fields.canceled",
  [InvoicesStatusEnum.CancellationAnalysis]:
    "invoice.keywords.fields.cancellationAnalysis",
  [InvoicesStatusEnum.AmountCanceled]: "invoice.keywords.fields.amountCanceled",
  [InvoicesStatusEnum.Pdd]: "invoice.keywords.fields.pdd",
  [InvoicesStatusEnum.Loss]: "invoice.keywords.fields.loss",
};
