import * as yup from "yup";
import { UserAddToGroupDTO } from "../../../shared/specific/DTOs/userGroup/UserAddToGroupDTO";
import {
  schemaUserGroupDTO,
  UserGroupDTO,
} from "../../../shared/specific/DTOs/userGroup/UserGroupDTO";
import { riseApi } from "./connection";

export const getUserGroup = async (
  idAccessGroup: number
): Promise<UserGroupDTO[]> => {
  const { data: userGroup } = await riseApi.get("UserGroup", {
    params: {
      idAccessGroup,
    },
  });

  return yup.array(schemaUserGroupDTO).defined().validateSync(userGroup);
};

export const addUserToGroup = async (user: UserAddToGroupDTO) => {
  await riseApi.post("UserGroup", user);
};

export const deleteUserFromGroup = async (id: number) => {
  await riseApi.delete(`UserGroup/${id}`);
};
