import { Tab } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabInfo } from "./index.types";
import { TabCode } from "../../shared/types/TabCode.enums";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { usePermissionChecker } from "../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";

export const useTabs = () => {
  const { t } = useTranslation();

  const { hasPermission: canReadDisciplines } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Discipline,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadSkillCategories } = usePermissionChecker({
    restrictions: {
      type: PermissionType.SkillCategory,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadSkill } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Skill,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadSkillCertifications } = usePermissionChecker({
    restrictions: {
      type: PermissionType.SkillCertification,
      level: PermissionLevel.Read,
    },
  });

  const tabs = useMemo(() => {
    const tabsInfo: (TabInfo | null)[] = [];

    if (canReadDisciplines)
      tabsInfo.push({
        label: t("disciplines.keywords.general.disciplines"),
        value: TabCode.Disciplines,
      });

    if (canReadSkillCategories)
      tabsInfo.push({
        label: t("skillsandCertifications.keywords.tabs.categories"),
        value: TabCode.Categories,
      });

    if (canReadSkill)
      tabsInfo.push({
        label: t("skillsandCertifications.keywords.tabs.skills"),
        value: TabCode.Skills,
      });

    if (canReadSkillCertifications)
      tabsInfo.push({
        label: t("skillsandCertifications.keywords.tabs.certifications"),
        value: TabCode.Certifications,
      });

    const tabsInfoNotNull = tabsInfo.filter((x) => !!x) as TabInfo[];

    return tabsInfoNotNull.map(({ label, value }) => (
      <Tab label={label} value={value} key={value} />
    ));
  }, [t]);

  return tabs;
};
