import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CollaboratorManagementFilterDTO } from "../../../../../shared/specific/DTOs/collaboratorManagement/CollaboratorManagementFilterDTO";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { getPaginatedCollaboratorsProfile } from "../../../../../services/collaboratorManagement/collaboratorManagement.service";
import { throwIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { useCollaboratorAllocationsListByHistory } from "../../../../shared/react/hooks/useCollaboratorAllocationsListByHistory";
import { useCollaboratorProfileViewForm } from "./useCollaboratorProfileViewForm";

interface OwnProps {
  filters?: CollaboratorManagementFilterDTO;
}

export const useTableData = ({ filters }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ViewingButtonContainer: CurrentAllocationsButtonContainer,
    viewingModal: viewingCurrentAllocationsModal,
  } = useCollaboratorAllocationsListByHistory();

  const {
    ViewingButtonContainer: CollaboratorProfileButtonContainer,
    viewingModal: CollaboratorProfileViewingModal,
  } = useCollaboratorProfileViewForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.name"),
        minWidth: 200,
        headColumn: true,
        align: "left",
        canSort: true,
      },
      {
        value: t("usersCollaborators.keywords.fields.currentContractRole"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: "",
        width: 260,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onUserCollaboratorPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        const filterValues = Object.values(filters || {});
        if (filterValues.every((x) => !x || x === "" || x.length === 0)) {
          setInfo(t("projects.info.data.general.typeSomethingName"));
          return null;
        }

        const headKeys = ["name", "contractRole"];

        try {
          const {
            tableData: usersCollaboratorsData,
            page,
            totalCount,
          } = await getPaginatedCollaboratorsProfile({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = usersCollaboratorsData.map(
            (userCollaborator): ExternalEnhancedRow => ({
              id: userCollaborator.id,
              cells: [
                userCollaborator.name ?? "-",
                userCollaborator.contractRole ?? "-",
                {
                  CellRenderer: () => (
                    <>
                      <CurrentAllocationsButtonContainer
                        historyContent={{
                          historyId: userCollaborator.idCurrentHistory ?? 0,
                          jobRoleName: userCollaborator.jobRole ?? "-",
                          yearMoth:
                            userCollaborator.startYearMonthCurrentHistory ??
                            undefined,
                        }}
                        allocations={null}
                      />
                      <CollaboratorProfileButtonContainer
                        idUserCollaborator={userCollaborator.id}
                      />
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );

  return {
    headCells,
    onUserCollaboratorPageChange,
    viewingCurrentAllocationsModal,
    CollaboratorProfileViewingModal,
  };
};
