import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useTypedSelector } from "../../../../../../../../../../../store";
import { FormValues } from "../../../types/form.types";
import { createTranslatableFieldValues } from "../../../../../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { makeTranslatableFieldValuesFormSchema } from "../../../../../../../../../../../shared/common/helpers/data/form/languages/schema.helpers";
import { Language } from "../../../../../../../../../../../shared/common/enums/locale/Language.enum";
import { useMaxDeactivatedDate } from "./useMaxDeactivatedDate";

export const useFormikConfig = () => {
  const { t } = useTranslation();
  const defaultLanguage = useTypedSelector(
    (state) => state.language.defaultLanguage
  );

  const maxDeactivatedDate = useMaxDeactivatedDate();

  const initialValues: FormValues = useMemo(() => {
    return {
      code: "",
      division: null,
      subsidiary: null,
      nameTranslations: createTranslatableFieldValues(),
      deactivatedDate: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      code: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      nameTranslations: makeTranslatableFieldValuesFormSchema({
        defaultLanguage: defaultLanguage ?? Language.enUS,
        defaultLanguageSchema: yup
          .string()
          .required(t("general.errors.data.fields.general.required")),
        secondaryLanguagesSchema: yup.string(),
      }).required(),
      deactivatedDate: yup
        .date()
        .nullable()
        .test({
          name: "teste",
          message: t(
            "general.errors.data.fields.dates.dateLaterAcceptableLimit"
          ),
          test(value) {
            if (!value) return true;
            return value <= maxDeactivatedDate;
          },
        }),
    });
  }, [t, maxDeactivatedDate]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
