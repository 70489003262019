import * as yup from "yup";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import { SkillDTO, schemaSkillDTO } from "../skill/SkillDTO";
import {
  UserSkillLevelEnum,
  schemaUserSkillLevelEnum,
} from "../../enums/userSkill/UserSkillLevelEnum";

export interface UserSkillDTO {
  id: number;
  userCollaborator: UserCollaboratorOnlyDTO;
  skill: SkillDTO;
  level: UserSkillLevelEnum;
  version: string;
}

export const schemaUserSkillDTO = yup.object({
  id: yup.number().integer().defined(),
  userCollaborator: schemaUserCollaboratorOnlyDTO.defined(),
  skill: schemaSkillDTO.defined(),
  level: schemaUserSkillLevelEnum.defined(),
  version: yup.string().defined(),
});
