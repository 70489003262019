import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "../../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { statusToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/projects/status.maps";
import { PaletteColorVariant } from "../../../../../../../../../../shared/common/types/externalLibraries/mui.types";
import { StyledProjectStatusFlag } from "./index.styles";

interface OwnProps {
  projectStatus: ProjectStatus;
  stretched?: boolean;
  className?: string;
}

export const ProjectStatusFlag = ({
  projectStatus,
  stretched = false,
  className,
}: OwnProps) => {
  const { t } = useTranslation();

  const color = useMemo((): PaletteColorVariant => {
    switch (projectStatus) {
      case ProjectStatus.Created:
        return "warning";
      case ProjectStatus.Planning:
        return "secondary";
      case ProjectStatus.Ongoing:
        return "primary";
      case ProjectStatus.Canceled:
        return "error";
      case ProjectStatus.Finalized:
        return "success";
      default:
        return "info";
    }
  }, [projectStatus]);

  return (
    <StyledProjectStatusFlag
      color={color}
      stretched={stretched}
      className={className}
    >
      {t(statusToTranslationCodeMap[projectStatus])}
    </StyledProjectStatusFlag>
  );
};
