import { useTranslation } from "react-i18next";
import { FormControlLabel } from "@mui/material";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { StyledJobRoleCreationForm } from "./index.styles";
import {
  FormMode,
  SkillCertificationDataFormValues,
} from "../../shared/types/form.types";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { CheckboxFormik } from "../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

type OwnProps = FormContentProps<SkillCertificationDataFormValues> & {
  mode: FormMode;
};

export const SkillCertificationDataForm = ({
  formikProps: { isSubmitting },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledJobRoleCreationForm>
      <TextFieldFormik
        name="name"
        label={t("skillCertification.keywords.general.certification")}
        required
        disabled={isSubmitting}
      />

      <TextFieldFormik
        name="provider"
        label={t("skillCertification.keywords.general.provider")}
        required
        disabled={isSubmitting}
      />

      <FormControlLabel
        label={t("skillCertification.keywords.general.certificationProgram")}
        control={<CheckboxFormik name="certificationProgram" />}
      />
    </StyledJobRoleCreationForm>
  );
};
