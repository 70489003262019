import { styled } from "@mui/material";

export const StyledInfoIconContainer = styled("div")`
  cursor: pointer;
  margin-left: 1rem;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;
