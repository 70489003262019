import {
  ReactNode,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { UserCollaboratorProfileContextValues } from "./index.types";
import { UserCollaboratorProfileDTO } from "../../../../../../shared/specific/DTOs/userCollaboratorProfile/UserCollaboratorProfileDTO";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";
import { getUserCollaboratorProfile } from "../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import { useEffectAfterRenders } from "../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { getLoggedUser } from "../../../../../../services/user/user.service";
import { Mode } from "../shared/types/index.types";

interface OwnProps {
  children: ReactNode;
  idUserCollaborator?: number;
  mode: Mode;
}

export const ProfileContext =
  createContext<UserCollaboratorProfileContextValues>({
    userCollaboratorProfile: null,
    setUserCollaboratorProfile: () => null,
    loading: true,
    setLoading: () => null,
    error: null,
    setError: () => null,
    loadPage: () => Promise.resolve(),
    mode: "viewing",
  });

export const ProfileContextProvider = ({
  children,
  idUserCollaborator,
  mode,
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const [userCollaboratorProfile, setUserCollaboratorProfile] =
    useState<UserCollaboratorProfileDTO | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const loadPage = useCallback(async () => {
    setLoading(true);

    if (mode === "editing")
      setPageTitle(t("collaboratorProfile.pages.main.title"));

    const user = !idUserCollaborator ? await getLoggedUser() : null;

    try {
      if (!user && !idUserCollaborator) {
        setError("User does not exist.");
        return;
      }

      const userProfile = await getUserCollaboratorProfile(
        user?.id ?? idUserCollaborator ?? 0
      );
      if (!userProfile) {
        setError("User does not exist.");
        return;
      }

      setUserCollaboratorProfile(userProfile);
    } catch (error) {
      // Deal with error
      console.error(error);
      setError("Something happened. :(");
    } finally {
      setLoading(false);
    }
  }, [t, i18n.language]);

  useEffectAfterRenders({
    effect: () => {
      loadPage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 0,
  });

  const profileContextValues: UserCollaboratorProfileContextValues = useMemo(
    () => ({
      userCollaboratorProfile,
      setUserCollaboratorProfile,
      loading,
      setLoading,
      error,
      setError,
      loadPage,
      mode,
    }),
    [userCollaboratorProfile, loading, error]
  );

  return (
    <ProfileContext.Provider value={profileContextValues}>
      {children}
    </ProfileContext.Provider>
  );
};
