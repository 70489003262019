import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback, useContext } from "react";
import { FormMode } from "./shared/types/data/form/general.types";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { usePermissionChecker } from "../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { CorporateAllocationsSuperUserContext } from "../../shared/react/contexts/CorporateAllocationsSuperUserContext";
import { CorporateAllocationDTO } from "../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationDTO";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { OnClickContentButtonComponentFunction } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { AllocationEditingFormValues } from "./shared/types/data/form/values.types";
import {
  getCorporateAllocationSingle,
  modifyCorporateAllocation,
} from "../../../../../../../services/corporateAllocations/corporateAllocations.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { StyledButtonContainer } from "./index.styles";
import { AllocationSingleForm } from "./AllocationSingleForm";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikConfig } from "./shared/hooks/data/form/validation/useFormikConfig";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { EmployeeAllocationType } from "../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { useMonthlyAllocationClosing } from "../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";

interface OwnProps {
  reloadTablePage: () => void;
}

interface InternalFormData {
  corporateAllocation: CorporateAllocationDTO;
  yearMonth: YearMonth;
  mode: FormMode;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  allocationGroup: number;
  yearMonth: YearMonth;
}

export const useEditingCellForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { hasPermission: canEditAllocations } = usePermissionChecker({
    restrictions: {
      type: PermissionType.CorporateAllocations,
      level: PermissionLevel.Update,
    },
  });

  const { checkIfMonthlyAllocationIsClosed } = useMonthlyAllocationClosing();

  const { hasPermission: CanAccessDeallocationInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessDeallocationInCorporateAllocation,
      },
    });

  const { hasPermission: CanAccessLeaveInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessLeaveInCorporateAllocation,
      },
    });

  const { hasPermission: CanAccessTrainingInCorporateAllocation } =
    usePermissionChecker({
      restrictions: {
        type: PermissionType.CanAccessTrainingInCorporateAllocation,
      },
    });

  const formikConfig = useFormikConfig();
  const { canEditAllCorporateAllocations } = useContext(
    CorporateAllocationsSuperUserContext
  );

  const getWeekPercentage = (weekPercentageString?: number | null) => {
    if (typeof weekPercentageString !== "number") return "";
    return formatNumber(weekPercentageString, { fractionDigits: 2 });
  };

  const getFormMode = useCallback(
    (
      allocation: CorporateAllocationDTO | null,
      yearMonth: YearMonth
    ): FormMode => {
      const canNotEditAllocations = !canEditAllocations;

      const isUnllocatedAndTheUserCanNotAccessDeallocation =
        allocation?.allocationType === EmployeeAllocationType.Unallocated &&
        !CanAccessDeallocationInCorporateAllocation;

      const isLeaveAndTheUserCanNotAccessDeallocation =
        allocation?.allocationType === EmployeeAllocationType.Leave &&
        !CanAccessLeaveInCorporateAllocation;

      const isTrainingAndTheUserCanNotAccessDeallocation =
        allocation?.allocationType === EmployeeAllocationType.Training &&
        !CanAccessTrainingInCorporateAllocation;

      const isMonthlyAllocationClosed =
        checkIfMonthlyAllocationIsClosed(yearMonth);

      const dontHaveSuperPermissionToEditCorporateAllocationsAndMonthlyAllocationClosed =
        !canEditAllCorporateAllocations && isMonthlyAllocationClosed;

      if (
        canNotEditAllocations ||
        isUnllocatedAndTheUserCanNotAccessDeallocation ||
        isLeaveAndTheUserCanNotAccessDeallocation ||
        isTrainingAndTheUserCanNotAccessDeallocation ||
        dontHaveSuperPermissionToEditCorporateAllocationsAndMonthlyAllocationClosed
      )
        return "viewing";

      if (!allocation) return "creation";

      return "editing";
    },
    [
      canEditAllCorporateAllocations,
      canEditAllocations,
      checkIfMonthlyAllocationIsClosed,
    ]
  );

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      AllocationEditingFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: { allocationGroup, yearMonth },
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    }) => {
      setIsLoadingModal(true);
      setInternalFormData(null);
      const startingOpenCloseModalCount = getOpenCloseModalCount();

      try {
        const corporateAllocation = await getCorporateAllocationSingle({
          allocationGroup,
          yearMonth,
        });
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        if (!corporateAllocation) {
          return setModalError("TODO does not exist");
        }

        setFormValues({
          yearMonth,
          week1Percentage: getWeekPercentage(
            corporateAllocation.allocationYearMonth?.week1Percentage
          ),
          week2Percentage: getWeekPercentage(
            corporateAllocation.allocationYearMonth?.week2Percentage
          ),
          week3Percentage: getWeekPercentage(
            corporateAllocation.allocationYearMonth?.week3Percentage
          ),
          week4Percentage: getWeekPercentage(
            corporateAllocation.allocationYearMonth?.week4Percentage
          ),
          week5Percentage: getWeekPercentage(
            corporateAllocation.allocationYearMonth?.week5Percentage
          ),
        });

        setInternalFormData({
          corporateAllocation,
          yearMonth,
          mode: getFormMode(corporateAllocation, yearMonth),
        });
      } catch (error) {
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        console.error(error);

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "allocations.errors.data.fetch.failedToFetchAllocation",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [getFormMode]
  );

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      AllocationEditingFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData }) => {
          switch (internalFormData?.mode) {
            case "editing":
              return (
                <div>{t("allocations.modal.data.modifySingle.titleEdit")}</div>
              );
            case "creation":
              return (
                <div>
                  {t("allocations.modal.data.modifySingle.titleCreate")}
                </div>
              );
            case "viewing":
              return (
                <div>{t("allocations.modal.data.modifySingle.titleView")}</div>
              );
            default:
              return <div> </div>;
          }
        },
        modalMode: "dialog",
      },
      button: {
        onClickContentButtonComponent,
        createModalButtonContainerMemo: ({
          onClickButton,
          onClickContentButtonComponent,
        }) => {
          return (props) => {
            const { children } = props;
            const onClick = () => {
              onClickButton();
              onClickContentButtonComponent({ props });
            };

            return (
              <StyledButtonContainer onClick={onClick}>
                {children}
              </StyledButtonContainer>
            );
          };
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) =>
          internalFormData &&
          internalFormData && (
            <AllocationSingleForm
              {...rest}
              yearMonth={internalFormData.yearMonth}
              mode={internalFormData.mode}
              corporateAllocation={internalFormData.corporateAllocation}
            />
          ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          internalFormData,
        }) => {
          const { t } = useTranslation();

          const canShowSaveButton = ["editing", "creation"].includes(
            internalFormData?.mode ?? ""
          );

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              {canShowSaveButton && (
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              )}
            </>
          );
        },
        onSubmit: async ({
          internalFormData,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!internalFormData)
            throw new Error("Missing internal form data on submit.");

          try {
            await modifyCorporateAllocation({
              allocationGroup:
                internalFormData.corporateAllocation.allocationGroup,
              yearMonth: internalFormData.yearMonth,
              week1Percentage: parseLocaleNumber(formValues.week1Percentage),
              week2Percentage: parseLocaleNumber(formValues.week2Percentage),
              week3Percentage: parseLocaleNumber(formValues.week3Percentage),
              week4Percentage: parseLocaleNumber(formValues.week4Percentage),
              week5Percentage: formValues.week5Percentage
                ? parseLocaleNumber(formValues.week5Percentage)
                : undefined,
              version:
                internalFormData.corporateAllocation.allocationYearMonth
                  ?.version,
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "allocations.errors.data.update.failedToUpdateAllocationsGroup",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
