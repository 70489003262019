import * as yup from "yup";

export enum ContractType {
  FixedAllocation = 0,
  TimeAndMaterial = 1,
  ClientAllocation = 2,
}

export const schemaContractType = yup
  .mixed<ContractType>()
  .oneOf(Object.values(ContractType) as ContractType[]);
