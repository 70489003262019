import { useMemo } from "react";
import { EnhancedTablePaginationSettings } from "../../../../types/settings.types";
import { TableContextPaginationValues } from "../../index.types";

interface OwnProps {
  paginationSettings: EnhancedTablePaginationSettings;
  page: number;
  rowsPerPage: number;
  changePage: (page: number) => void;
  setRowsPerPage: (page: number) => void;
  reloadPage: () => void;
  totalItems: number | null;
}

export const usePaginationData = ({
  paginationSettings,
  page,
  rowsPerPage,
  changePage,
  setRowsPerPage,
  reloadPage,
  totalItems,
}: OwnProps) => {
  const pagination = useMemo(() => {
    if (!paginationSettings.usesPagination) return undefined;

    const pagination: TableContextPaginationValues = {
      page,
      rowsPerPage,
      changePage,
      onRowsPerPageChange: (rowsPerPage) => {
        setRowsPerPage(rowsPerPage);
        changePage(0);
      },
      rowsPerPageOptions: paginationSettings.rowsPerPageOptions,
      rowsCount: totalItems ?? 0,
      usesExternalDataFetching:
        paginationSettings.externalPagination?.onPageChange !== undefined,
      reloadPage,
    };

    return pagination;
  }, [
    paginationSettings.defaultRowsPerPage,
    paginationSettings.rowsPerPageOptions,
    paginationSettings.usesPagination,
    paginationSettings.externalPagination?.onPageChange,
    rowsPerPage,
    page,
    totalItems,
    changePage,
    reloadPage,
  ]);

  return { pagination };
};
