import i18next from "i18next";
import { Language } from "../../../../enums/locale/Language.enum";
import { FormatNumberOptions } from "./formatters.types";

export const formatNumber = (
  value: number,
  {
    fractionDigits,
    locale = "current",
    prefix = "",
    suffix = "",
  }: FormatNumberOptions = {}
) => {
  if (locale === "current") {
    locale = i18next.language as Language;
  }

  const formattedNumber = value.toLocaleString(locale, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return `${prefix}${formattedNumber}${suffix}`;
};

export const formatCurrency = ({
  value,
  currencySymbol,
  locale = "current",
}: {
  value: number;
  currencySymbol?: string;
  locale?: Language | "current";
}) => {
  if (locale === "current") {
    locale = i18next.language as Language;
  }

  const valueString = value.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  if (currencySymbol) return `${currencySymbol} ${valueString}`;
  return valueString;
};
