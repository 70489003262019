import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getDashBoardGroup } from "../../../../../services/dashboardGroups/dashboardGroups.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";

interface OwProps {
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const dashboardGroups = await getDashBoardGroup();

        const rows: ExternalEnhancedRow[] = dashboardGroups.map(
          (dashboardGroup): ExternalEnhancedRow => {
            const translatedName =
              dashboardGroup.dashboardGroupTranslations.find(
                (x) => x.idLanguage === i18n.language
              )?.name;
            const dashboardGroupName =
              translatedName === "" || !translatedName
                ? dashboardGroup.name
                : translatedName;
            return {
              id: dashboardGroup.id,
              cells: [
                dashboardGroupName,
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.DashBoards,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...dashboardGroup} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.DashBoards,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...dashboardGroup} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            };
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, i18n]);

  return {
    headCells,
    onPageChange,
    editingModal,
    deletionModal,
  };
};
