import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const DivSuccess = styled("div")`
  text-align: center;
  padding: 20px;
  font-size: large;
`;

export const PerfectlyCenteredText = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const StyledTextButton = styled(LoadingButton)`
  flex-grow: 1;
  margin-left: 20px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const ResponseListContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > :first-child {
    font-size: 18px;
    font-weight: bold;
  }

  & > :last-child {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
    & > * {
      gap: ${({ theme }) => theme.spacing(0.5)};
    }
  }
`;

export const StyledCollaboratorUpload = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-width: 200px;
  gap: ${({ theme }) => theme.spacing(1.5)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 0;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    min-width: 0;
  }
`;

export const StyledModalHelperInfos = styled("div")`
  ul {
    margin-left: 12;
  }
  p {
    margin-top: 8;
    margin-bottom: 8;
  }
  img {
    max-width: "100%";
  }
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  cursor: pointer;
`;
