import { createContext } from "react";
import { CollaboratorAllocationContextValues } from "./index.types";

export const CollaboratorAllocationContext =
  createContext<CollaboratorAllocationContextValues>({
    allocations: [],
    setAllocations: () => null,
    filter: null,
    setFilter: () => null,
    search: false,
    setSearch: () => null,
    CanAccessLeaveInCorporateAllocation: false,
    CanAccessTrainingInCorporateAllocation: false,
    CanAccessDeallocationInCorporateAllocation: false,
  });
