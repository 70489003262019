import { css, styled } from "@mui/material";
import { HTMLAttributes } from "react";
import { PaletteColorVariant } from "../../../../../../../../../../shared/common/types/externalLibraries/mui.types";

interface StyledProjectStatusFlagProps extends HTMLAttributes<HTMLDivElement> {
  color: PaletteColorVariant;
  stretched: boolean;
}

const StyledProjectStatusFlagPureDiv = ({
  color,
  stretched,
  ...rest
}: StyledProjectStatusFlagProps) => <div {...rest} />;

export const StyledProjectStatusFlag = styled(StyledProjectStatusFlagPureDiv)`
  text-align: center;
  color: ${({ theme, color }) => theme.palette[color].contrastText};
  background-color: ${({ theme, color }) => theme.palette[color].main};
  padding: ${({ theme }) => `${theme.spacing(0.8)} ${theme.spacing(2)}`};
  border-radius: 4px;

  ${({ stretched }) =>
    stretched &&
    css`
      width: 100%;
    `}
`;
