import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { JobRoleDTO, schemaJobRoleDTO } from "./JobRoleDTO";

export type PaginatedJobRoleDTO = PaginationResponse<JobRoleDTO>;

export const schemaPaginatedJobRoleDTO =
  getSchemaPaginationResponse<JobRoleDTO>(schemaJobRoleDTO);
