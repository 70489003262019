import { css, styled } from "@mui/material";

interface OwnProps {
  color?: string;
}

const PureDiv = ({ color, ...rest }: OwnProps) => <div {...rest} />;

export const StylesContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StylesLegend = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin: 0 0 1rem 1rem;
  background-color: #2c2c2c;
  padding: 0.8rem;
  width: max-content;
  border-radius: 5px;
`;

export const StylesList = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  span {
    color: white;
  }
`;

export const StylesDivColor = styled(PureDiv)`
  height: 15px;
  width: 15px;
  border-radius: 15px;
  ${({ color }) =>
    color &&
    css`
      background-color: ${color};
    `}
`;
