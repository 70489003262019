import * as yup from "yup";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";

export interface PipedriveDealCostCenterDTO {
  id: number;
  cofins: number | null;
  icms: number | null;
  iss: number | null;
  pis: number | null;
  costCenterCode: string;
  pepCode: string;
  currencyExchange: string | null;
  costCenter: CostCenterDTO | null;
  costCenterFromDeal: string;
}

export const schemaPipedriveDealCostCenterDTO = yup.object({
  id: yup.number().integer().defined(),
  cofins: yup.number().defined().nullable(),
  icms: yup.number().defined().nullable(),
  iss: yup.number().defined().nullable(),
  pis: yup.number().defined().nullable(),
  costCenterCode: yup.string().defined(),
  pepCode: yup.string().defined(),
  currencyExchange: yup.string().defined().nullable(),
  costCenterFromDeal: yup.string().defined(),
  costCenter: schemaCostCenterDTO.defined().nullable(),
});
