import { CalendarMonth as CalendarMonthIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { getMainProjectPepOnly } from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import {
  getPipedriveDealPlanningProjectByRiseId,
  getProjectById,
  updateProjectPlanning,
} from "../../../../../../../services/projects/projects.service";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import { SimpleIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ProjectDTO } from "../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectPlanningUpdateDTO } from "../../../../../../../shared/specific/DTOs/project/ProjectPlanningUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { usePlanningFormikConfig } from "../../../shared/react/hooks/data/form/planning/usePlanningFormikConfig";
import { CommonPlanningFormValues } from "../../../shared/types/data/form/planning.types";
import { PlanningCreationForm } from "./CommonPlanningForm";
import { InternalFormData } from "./shared/types/form.types";
import { ContractType } from "../../../../../../../shared/specific/enums/projects/ContractType.enum";
import { TypeOfEvidenceEnum } from "../../../../../../../shared/specific/enums/projects/TypeOfEvidence.enum";
import { typeOfEvidenceToTranslationCodeMap } from "../../../../../../../shared/specific/maps/projects/typeOfEvidence.maps";

export const usePlanningCreationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { initialValues, validationSchema } = usePlanningFormikConfig();

  const {
    ContentButton: PlanningCreationButtonContainer,
    contentModal: planningCreationModal,
  } = useFormikModalButton<
    CommonPlanningFormValues,
    InternalFormData,
    ProjectDTO
  >({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("projects.modal.data.startPlanning.title"),
      modalMode: "dialog",
    },
    button: {
      FormButtonMemo: ({ onClick }) => (
        <SimpleIconButton
          Icon={CalendarMonthIcon}
          color="primary"
          onClick={onClick}
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: projectRowData,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
        setFormValues,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const [project, mainProjectPep] = await Promise.all([
            getProjectById(projectRowData.id),
            getMainProjectPepOnly(projectRowData.id),
          ]);
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!project)
            return setModalError(
              i18next.t("projects.errors.data.general.projectDoesNotExist")
            );

          const pipedriveDeal = await getPipedriveDealPlanningProjectByRiseId(
            project.id
          );

          const getTypeOfEvidence = () => {
            switch (project?.contractType) {
              case ContractType.FixedAllocation:
                return TypeOfEvidenceEnum.Eap;
              case ContractType.TimeAndMaterial:
                return TypeOfEvidenceEnum.Timesheet;
              case ContractType.ClientAllocation:
                return TypeOfEvidenceEnum.Bm;
              default:
                return TypeOfEvidenceEnum.Timesheet;
            }
          };

          setInternalFormData({ project, mainProjectPep });
          if (pipedriveDeal) {
            setFormValues({
              mv: pipedriveDeal.mv ?? "",
              scheduledEndDate: pipedriveDeal.scheduledEndDate
                ? new Date(pipedriveDeal.scheduledEndDate)
                : null,
              scheduledStartDate: pipedriveDeal.projectBeginningDate
                ? new Date(pipedriveDeal.projectBeginningDate)
                : null,
              userCoordinator: project?.userCoordinator ?? null,
              userManager: project?.userManager ?? null,
              typeOfEvidence: {
                id: getTypeOfEvidence(),
                label: typeOfEvidenceToTranslationCodeMap[getTypeOfEvidence()],
              },
            });
          } else {
            setFormValues({
              mv: "",
              scheduledEndDate: null,
              scheduledStartDate: null,
              userCoordinator: project?.userCoordinator ?? null,
              userManager: project?.userManager ?? null,
              typeOfEvidence: {
                id: getTypeOfEvidence(),
                label: typeOfEvidenceToTranslationCodeMap[getTypeOfEvidence()],
              },
            });
          }
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "projects.errors.data.fetch.failedToFetchProject",
          });

          setModalError(errorMessage);
        }

        setIsLoadingModal(false);
      },
    },
    form: {
      formikConfig: {
        initialValues,
        validationSchema,
      },
      FormContent: PlanningCreationForm,
      FormActionsMemo: ({
        submitFormValues,
        closeModal,
        isSubmitting,
        isLoadingModal,
        modalError,
      }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
              disabled={isLoadingModal || !!modalError}
            >
              {t("general.actions.general.start")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        internalFormData,
        formValues,
        formikHelpers: { setSubmitting },
      }) => {
        if (!internalFormData?.project)
          throw new Error("'projectData' cannot be null on submission.");

        if (!formValues.scheduledStartDate || !formValues.scheduledEndDate)
          throw new Error("Dates cannot be null on submission.");

        try {
          await updateProjectPlanning(
            convertTo<ProjectPlanningUpdateDTO>({
              id: internalFormData.project.id,
              scheduledStartDate: DateOnly.createFromDate(
                formValues.scheduledStartDate
              ),
              scheduledEndDate: DateOnly.createFromDate(
                formValues.scheduledEndDate
              ),
              mv: parseLocaleNumber(formValues.mv),
              idUserCoordinator: formValues.userCoordinator?.id ?? 0,
              idUserManager: formValues.userManager?.id ?? 0,
              typeOfEvidence: formValues.typeOfEvidence.id,
              version: internalFormData.project.version,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          navigate(`/projects/${internalFormData.project.id}/planning`);
        } catch (error) {
          console.error(error);
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "projects.errors.data.startPlanning.failedToStartPlanning",
          });
          setSubmitting(false);
        }
      },
    },
  });

  return { PlanningCreationButtonContainer, planningCreationModal };
};
