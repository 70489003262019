import {
  ReactNode,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useUpdatedRef } from "../../../../../hooks/enhancedReactHooks/useUpdatedRef";
import { FormModal, FormModalRef } from "../FormModal";
import { ButtonContainerDiv } from "../shared/styles/ButtonContainerDiv.styles";
import { OpenModalFunction } from "../shared/types/functions.types";
import { UseModalButtonProps } from "../shared/types/props.types";

export const useModalButton = <
  InternalModalData,
  ContentButtonContentProps = InternalModalData,
>({
  modal: {
    modalTitle,
    ModalTitle,
    ModalTitleMemo,
    onCloseModal: externalOnCloseModal,
    onOpenModal: externalOnOpenModal,
    keepModalMounted,
    modalColorVariant = "primary",
    mode,
    modalContent,
    modalContentMemo,
    ModalContent,
    ModalContentMemo,
    modalActions,
    ModalActions,
    ModalActionsMemo,
    internalLoadingPosition = "content",
    letModalTitleDealWithLoading = false,
    letModalContentDealWithLoading = false,
    letModalContentDealWithError = false,
    letModalTitleDealWithError = false,
    suppressInternalCloseModal = false,
    suppressCloseBackdropClick = false,
    modalActionsDirection = "horizontal",
  },
  button: {
    ModalButton,
    ModalButtonMemo,
    buttonTooltip,
    createModalButtonContainer,
    createModalButtonContainerMemo,
    onClickContentButtonComponent,
    onClickContentButtonComponentMemo,
    onClickContentButton,
    onClickContentButtonMemo,
  },
  general: { innerRef, initialInternalModalData = null } = {},
}: UseModalButtonProps<InternalModalData, ContentButtonContentProps>) => {
  const [internalModalData, setInternalModalData] = useState(
    initialInternalModalData
  );
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [modalError, setModalError] = useState<ReactNode>(null);

  const formModalRef = useRef<FormModalRef>(null);
  const openCloseModalCountRef = useRef(0);

  const openModal = useCallback<OpenModalFunction<InternalModalData>>(
    (onOpenModalCallback) => {
      formModalRef.current?.openModal();
      onOpenModalCallback?.({
        checkInCurrentModalCount,
        getOpenCloseModalCount,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
      });
    },
    []
  );
  const closeModal = useCallback(() => formModalRef.current?.closeModal(), []);

  const increaseOpenCloseModalCount = useCallback(() => {
    openCloseModalCountRef.current++;
  }, []);

  const getOpenCloseModalCount = useCallback(() => {
    return openCloseModalCountRef.current;
  }, []);

  const checkInCurrentModalCount = useCallback(
    (openCloseModalCount: number) => {
      return openCloseModalCountRef.current === openCloseModalCount;
    },
    []
  );

  useImperativeHandle(innerRef, () => ({
    openModal,
    closeModal,
  }));

  const finalOnClickContentButton = useMemo(() => {
    return onClickContentButton ?? onClickContentButtonMemo;
  }, [onClickContentButton]);

  const onClickModalButton = useCallback(() => {
    openModal();
    finalOnClickContentButton?.({
      checkInCurrentModalCount,
      getOpenCloseModalCount,
      setInternalModalData,
      setIsLoadingModal,
      setModalError,
    });
  }, [finalOnClickContentButton]);
  const onClickModalButtonRef = useUpdatedRef(onClickModalButton);

  const contentButton = useMemo(() => {
    const onClick = () => {
      onClickModalButtonRef.current();
    };

    if (ModalButton)
      return <ModalButton onClick={onClick} buttonTooltip={buttonTooltip} />;

    if (ModalButtonMemo)
      return (
        <ModalButtonMemo onClick={onClick} buttonTooltip={buttonTooltip} />
      );

    return null;
  }, [ModalButton]);

  const finalOnClickContentButtonComponent = useMemo(() => {
    return onClickContentButtonComponent ?? onClickContentButtonComponentMemo;
  }, [onClickContentButtonComponent]);

  const ModalButtonContainer = useMemo(() => {
    const onClickButton = () => {
      onClickModalButtonRef.current();
    };

    if (createModalButtonContainer)
      return createModalButtonContainer({
        button: contentButton,
        ButtonContainerDiv,
        onClickButton,
      });

    if (createModalButtonContainerMemo)
      return createModalButtonContainerMemo({
        button: contentButton,
        ButtonContainerDiv,
        onClickButton,
      });

    return (props: ContentButtonContentProps) => {
      const onClick = () => {
        finalOnClickContentButtonComponent?.({
          contentButtonContentProps: props,
          setInternalModalData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        });
      };

      return (
        <ButtonContainerDiv onClick={onClick}>
          {contentButton}
        </ButtonContainerDiv>
      );
    };
  }, [
    contentButton,
    createModalButtonContainer,
    finalOnClickContentButtonComponent,
  ]);

  const onOpenModal = useCallback(() => {
    increaseOpenCloseModalCount();
    externalOnOpenModal?.();
  }, [externalOnOpenModal]);

  const onCloseModal = useCallback(() => {
    increaseOpenCloseModalCount();
    externalOnCloseModal?.();
  }, [externalOnCloseModal]);

  const contentModal = useMemo(() => {
    return (
      <FormModal
        modalTitle={modalTitle}
        ModalTitle={ModalTitle}
        ModalTitleMemo={ModalTitleMemo}
        ModalContent={ModalContent}
        ModalContentMemo={ModalContentMemo}
        modalContent={modalContent}
        modalContentMemo={modalContentMemo}
        ModalActions={ModalActions}
        ModalActionsMemo={ModalActionsMemo}
        modalActions={modalActions}
        innerRef={formModalRef}
        keepModalMounted={keepModalMounted}
        onCloseModal={onCloseModal}
        onOpenModal={onOpenModal}
        modalColorVariant={modalColorVariant}
        mode={mode}
        internalModalData={internalModalData}
        isLoadingModal={isLoadingModal}
        modalError={modalError}
        internalLoadingPosition={internalLoadingPosition}
        letModalTitleDealWithLoading={letModalTitleDealWithLoading}
        letModalContentDealWithLoading={letModalContentDealWithLoading}
        letModalContentDealWithError={letModalContentDealWithError}
        letModalTitleDealWithError={letModalTitleDealWithError}
        suppressInternalCloseModal={suppressInternalCloseModal}
        suppressCloseBackdropClick={suppressCloseBackdropClick}
        modalActionsDirection={modalActionsDirection}
      />
    );
  }, [
    ModalContent,
    modalContent,
    modalTitle,
    ModalTitle,
    modalActions,
    ModalActions,
    keepModalMounted,
    onCloseModal,
    onOpenModal,
    modalColorVariant,
    internalModalData,
    isLoadingModal,
    modalError,
    internalLoadingPosition,
    letModalTitleDealWithLoading,
    letModalContentDealWithLoading,
    letModalContentDealWithError,
    letModalTitleDealWithError,
    suppressInternalCloseModal,
    suppressCloseBackdropClick,
    modalActionsDirection,
  ]);

  return {
    contentButton,
    contentModal,
    ModalButtonContainer,
    openModal,
  };
};
