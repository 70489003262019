import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { TextFieldFormikWithTranslationsModal } from "../../../../../../../shared/common/react/components/form/formik/translatable/TextFieldFormikWithTranslationsModal";
import { FormContentProps } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DashboardGroupsAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DashboardGroupsAutocompleteFormik";
import { DashboardFormValues } from "../../types/DashboardFormValues";
import { StyledFormContainer } from "./index.styles";

type OwnProps = FormContentProps<DashboardFormValues>;

export const DashboardCommomForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledFormContainer>
      <TextFieldFormikWithTranslationsModal
        name="nameTranslations"
        label={t("general.keywords.fields.name")}
        disabled={isSubmitting}
        inputProps={{ maxLength: 200 }}
        defaultLanguageTextFieldProps={{ required: true }}
      />
      <DashboardGroupsAutocompleteFormik
        name="dashboardGroup"
        textfieldProps={{
          disabled: isSubmitting,
          required: true,
        }}
      />
      <TextFieldFormik
        name="url"
        label="URL"
        required
        disabled={isSubmitting}
      />
    </StyledFormContainer>
  );
};
