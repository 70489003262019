import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import { formatCurrency } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { getInvoicesPaginated } from "../../../../../../services/invoices/invoices.service";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../shared/specific/maps/invoices/invoicesStatusTypeToTranslationCode";
import { subsidiaryToCountryToTranslationCode } from "../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiariesEnum";
import { InvoiceBackendFiltersDTO } from "../../../../../../shared/specific/DTOs/invoice/filters/InvoiceBackendFiltersDTO";
import { CustomLink } from "../../../../../../shared/common/react/components/navigation/CustomLink";
import { EditIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { usePaidOutForm } from "./usePaidOutForm";
import { InvoicesStatusEnum } from "../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { SimpleIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { usePermissionChecker } from "../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { ViewIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";

interface OwnProps {
  filters: InvoiceBackendFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage, filters }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { UpdatePaidOutButton, updatePaidOutModal } = usePaidOutForm({
    reloadTablePage,
  });

  const { hasPermission: canEditInvoices } = usePermissionChecker({
    restrictions: {
      type: PermissionType.InvoiceModule,
      level: PermissionLevel.Update,
    },
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.country"),
      t("customers.keywords.general.customer"),
      t("invoice.keywords.fields.billingAmount"),
      t("invoice.keywords.fields.requestDate"),
      {
        value: t("invoice.keywords.fields.deadline"),
        width: 152,
      },
      {
        value: t("general.keywords.fields.status"),
        width: 130,
      },
      t("invoice.keywords.fields.responsible"),
      {
        value: t("invoice.keywords.fields.payment"),
        align: "center",
        width: 100,
      },
      {
        value: "",
        width: 100,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        const headKeys = [
          "costCenterPep.subsidiary.name",
          "costCenterPep.customer.tradingName",
          "milestone",
          "billingAmount",
          "requestDate",
          "limitDate",
          "status",
          "responsible",
        ];

        try {
          const {
            tableData: invoicesData,
            page,
            totalCount,
          } = await getInvoicesPaginated({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = invoicesData
            .filter(
              (x) => x.invoices[0].status !== InvoicesStatusEnum.NotReleased
            )
            .map(
              (invoiceData): ExternalEnhancedRow => ({
                id: invoiceData.invoices[0].id,
                cells: [
                  {
                    value:
                      invoiceData.invoices[0].costCenterPep.subsidiary.name,
                    displayValue: subsidiaryToCountryToTranslationCode(
                      invoiceData.invoices[0].costCenterPep.subsidiary.name
                    ),
                  },
                  invoiceData.invoices[0].customer?.tradingName || "-",
                  {
                    value: invoiceData.invoices
                      .map((x) => x.billingAmount)
                      .reduce((a, b) => a + b, 0),
                    displayValue: formatCurrency({
                      currencySymbol:
                        invoiceData.invoices[0].costCenterPep.subsidiary
                          .currencySymbol,
                      value: invoiceData.invoices
                        .map((x) => x.billingAmount)
                        .reduce((a, b) => a + b, 0),
                    }),
                  },
                  {
                    value: invoiceData.invoices[0].requestDate
                      ? invoiceData.invoices[0].requestDate.toString()
                      : "-",
                    displayValue: invoiceData.invoices[0].requestDate
                      ?.toDate()
                      .toLocaleDateString(i18n.language),
                  },
                  {
                    value: invoiceData.invoices[0].limitDate
                      ? invoiceData.invoices[0].limitDate.toString()
                      : "-",
                    displayValue: invoiceData.invoices[0].limitDate
                      ?.toDate()
                      .toLocaleDateString(i18n.language),
                  },
                  {
                    value: invoiceData.invoices[0].status,
                    displayValue: t(
                      invoicesStatusTypeToTranslationCode[
                        invoiceData.invoices[0].status
                      ]
                    ),
                  },
                  invoiceData.invoices[0].responsibleLogin?.name ?? "-",
                  {
                    CellRenderer: () => (
                      <Protected
                        restrictions={{
                          type: PermissionType.InvoiceModule,
                          level: PermissionLevel.Update,
                        }}
                      >
                        {invoiceData.invoices[0].status ===
                        InvoicesStatusEnum.Issued ? (
                          <UpdatePaidOutButton {...invoiceData.invoices[0]} />
                        ) : (
                          <SimpleIconButton
                            Icon={AttachMoneyIcon}
                            color="primary"
                            tooltipText={t(
                              "invoice.actions.data.confirmPayment"
                            )}
                            disabled
                          />
                        )}
                      </Protected>
                    ),
                    align: "right",
                    paddingmode: "horizontal",
                  },
                  {
                    CellRenderer: () => (
                      <Protected
                        restrictions={{
                          type: PermissionType.InvoiceModule,
                          level: PermissionLevel.Read,
                        }}
                      >
                        <CustomLink
                          to={`/invoices/${invoiceData.invoices[0].id}`}
                        >
                          {canEditInvoices ? (
                            <EditIconButton />
                          ) : (
                            <ViewIconButton />
                          )}
                        </CustomLink>
                      </Protected>
                    ),
                    align: "center",
                    paddingmode: "horizontal",
                  },
                ],
              })
            );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [t, filters]
    );

  return {
    headCells,
    onTablePageChange,
    updatePaidOutModal,
  };
};
