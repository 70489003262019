import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { SummaryContextProvider } from "../../../../Projects/ProjectPlanningPage/tabs/SummaryTab/shared/react/contexts/SummaryContext";
import { SummaryTabWithinContext } from "../../../../Projects/ProjectPlanningPage/tabs/SummaryTab/SummaryTabWithinContext";

type OwnParams = {
  id: string;
};

export const SummaryTab = () => {
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  if (!id) {
    return <div>Failed to load frame!</div>;
  }

  return (
    <SummaryContextProvider id={id} isFrame>
      <SummaryTabWithinContext />
    </SummaryContextProvider>
  );
};
