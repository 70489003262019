import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useField } from "formik";
import {
  SearchAutocompleteFormikProps,
  SearchAutocompleteFormik,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { AutocompleteInfoBubble } from "../../../../../../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";

import { CostCenterDTO } from "../../../../../../../../../../../../shared/specific/DTOs/costCenter/CostCenterDTO";
import { CostCenterFiltersDTO } from "../../../../../../../../../../../../shared/specific/DTOs/costCenter/filters/CostCenterFiltersDTO";
import { CostCenterOnlyDTO } from "../../../../../../../../../../../../shared/specific/DTOs/costCenter/CostCenterOnlyDTO";
import { getCostCenters } from "../../../../../../../../../../../../services/costCenter/costCenter.service";
import {
  StyledDiv,
  StyledAutocompleteFieldReviewInfoBubble,
} from "./CostCenterSearchAutocompleteFormik.styles";

export interface CostCentersSearchAutocompleteFormikProps<
  DisableClearable extends boolean | undefined = undefined,
> {
  name: string;
  costCenterFromDeal: string;
  costCenterDTO: CostCenterDTO | null;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    CostCenterDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<CostCenterFiltersDTO, "code">;
}

export const CostCentersDealSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  costCenterFromDeal,
  costCenterDTO,
  textfieldProps,
  autocompleteProps,
  filters,
}: CostCentersSearchAutocompleteFormikProps<DisableClearable>) => {
  const [{ value: costCenter }] = useField<CostCenterOnlyDTO | null>(name);
  const { t } = useTranslation();

  const [hideDealCostCenter, setHideDealCostCenter] = useState<boolean>(false);

  const getCostCentersFinal = useCallback(
    async (code: string) => {
      const paginatedCostCenters = await getCostCenters({
        filters: { code, ...filters },
        pagination: { pageSize: 100 },
      });

      return paginatedCostCenters.tableData;
    },
    [filters]
  );

  const endAdornment = useMemo(() => {
    if (!costCenter?.name) return undefined;
    return (
      <Tooltip title={costCenter.name} placement="top">
        <AutocompleteInfoBubble>{costCenter.name}</AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [costCenter]);

  const startAdornment = useMemo(() => {
    if (costCenterDTO || costCenterFromDeal) return undefined;
    if (!costCenterFromDeal) return undefined;
    return (
      <StyledDiv>
        {costCenterFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [costCenterFromDeal, costCenterDTO, hideDealCostCenter]);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenters.errors.data.fetch.failedToFetchCostCenters"
      )}
      fetchOptions={getCostCentersFinal}
      textfieldProps={{
        label: t("costCenters.keywords.general.costCenter"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
          endAdornment,
        },
        onFocus: () => setHideDealCostCenter(true),
        onBlur: () => setHideDealCostCenter(false),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.code,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.code} - {option.name}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
