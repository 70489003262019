import { styled } from "@mui/material/styles";

export const StyledPageContainer = styled("div")`
  gap: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledFormContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 111;
  width: 100%;
  gap: ${(props) => props.theme.spacing(1)};
`;

export const StyledUploadsButtonsAndHelper = styled("div")`
  display: grid;
  align-items: center;
  width: max-content;

  button {
    flex-grow: 1;
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
  grid-template-areas: "a b c d";
`;

export const StyledUploadsButtonsContainer = styled("div")`
  display: flex;
  width: max-content;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledButtonContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
