import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { createCustomer } from "../../../../../../services/customers/customers.service";
import { Language } from "../../../../../../shared/common/enums/locale/Language.enum";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CustomerCreateDTO } from "../../../../../../shared/specific/DTOs/customers/CustomerCreateDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../shared/react/components/content/CreationEditingForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { schemaCountry } from "../../../../../../shared/common/enums/locale/Country.enum";
import { FormValues } from "../shared/types/form.types";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const sharedFormikConfig = useFormikConfig();

  const formikConfig: EntryFormikConfig<FormValues> = useMemo(() => {
    const initialValues: FormValues = {
      ...sharedFormikConfig.initialValues,
      country:
        i18n.language === Language.ptBR
          ? { code: "BR", name: "Brasil" }
          : { code: "US", name: "United States" },
    };

    return {
      initialValues,
      validationSchema: sharedFormikConfig.validationSchema,
      enableReinitialize: true,
    };
  }, [t, i18n.language, sharedFormikConfig]);

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          const { t } = useTranslation();

          return <div>{t("customers.modal.data.creation.title")}</div>;
        },
      },
      button: {
        FormButton: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: CreationEditingForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues: { stateAutocomplete, stateTextField, ...formValues },
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          const country = formValues.country?.code ?? "";

          try {
            const customerCreationDTO: CustomerCreateDTO = {
              ...formValues,
              daysForPayment: parseLocaleNumber(formValues.daysForPayment),
              country,
              state: schemaCountry.isValidSync(country)
                ? stateAutocomplete?.abbreviation
                : (stateTextField ?? undefined),
              registrationCode: formValues.registrationCode || undefined,
              municipalCode: formValues.municipalCode || undefined,
              stateCode: formValues.stateCode || undefined,
            };

            await createCustomer(customerCreationDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            console.error(error);

            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "costCenters.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
