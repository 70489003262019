import { css, styled } from "@mui/material";
import { ReactElement } from "react";

interface OwnProps {
  labelColorType?: string;
  children?: ReactElement;
}

const PureDiv = ({ labelColorType, children, ...rest }: OwnProps) => (
  <div {...rest}>{children}</div>
);

export const SubBox = styled(PureDiv)`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    height: 15px;
    width: 15px;
    border-radius: 15px;
    ${({ labelColorType }) =>
      labelColorType &&
      css`
        background-color: ${labelColorType};
      `}
  }
`;
