import { css, styled } from "@mui/material";
import { HTMLProps } from "react";
import { createGridAreaLettersRange } from "../../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  isWeekend: boolean;
}

const PureDiv = ({ isWeekend, ...rest }: OwnProps) => <div {...rest} />;

export const StyledCalendarCell = styled(PureDiv)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "a b"
    "c c";
  gap: ${({ theme }) => theme.spacing(0.5)};
  align-items: center;

  ${createGridAreaLettersRange(3)};

  input {
    ${({ theme, isWeekend }) =>
      isWeekend &&
      css`
        color: ${theme.palette.error.main};
      `}
  }
`;
