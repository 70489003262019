import * as yup from "yup";
import { InvoiceDTO, schemaInvoiceDTO } from "./InvoiceDTO";

export interface InvoiceGroupDTO {
  idInvoiceGroup: number | null;
  invoices: InvoiceDTO[];
}

export const schemaInvoiceGroupDTO = yup.object({
  idInvoiceGroup: yup.number().integer().defined().nullable(),
  invoices: yup.array(schemaInvoiceDTO).defined(),
});
