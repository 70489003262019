import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { FormValues } from "../../../shared/types/data/form/values.types";

export const useAllocationsPeriod = () => {
  const { values } = useFormikContext<FormValues>();

  const [periodYearMonths, setPeriodYearMonths] = useState<YearMonth[] | null>(
    null
  );

  const periodStartYearMonth = useMemo(() => {
    if (!values.periodStart) return null;
    return YearMonth.createFromDate(values.periodStart);
  }, [values.periodStart]);

  const periodEndYearMonth = useMemo(() => {
    if (!values.periodEnd) return null;
    return YearMonth.createFromDate(values.periodEnd);
  }, [values.periodEnd]);

  const periodMonths = useMemo(() => {
    if (!periodStartYearMonth || !periodEndYearMonth) return null;
    if (periodStartYearMonth.greaterThan(periodEndYearMonth)) return null;

    return periodEndYearMonth.differenceCalendarMonths(periodStartYearMonth);
  }, [periodStartYearMonth, periodEndYearMonth]);

  useEffect(() => {
    if (!periodStartYearMonth || !periodEndYearMonth)
      return setPeriodYearMonths(null);

    if (
      periodYearMonths?.at(0)?.equals(periodStartYearMonth) &&
      periodYearMonths?.at(-1)?.equals(periodEndYearMonth)
    )
      return;

    if (periodStartYearMonth.greaterThan(periodEndYearMonth))
      return setPeriodYearMonths(null);

    const newPeriodYearMonths = [] as YearMonth[];
    let currentYearMonth = periodStartYearMonth;
    while (currentYearMonth.lessThanOrEqual(periodEndYearMonth)) {
      newPeriodYearMonths.push(currentYearMonth);
      currentYearMonth = currentYearMonth.addMonths(1);
    }

    setPeriodYearMonths(newPeriodYearMonths);
  }, [periodStartYearMonth, periodEndYearMonth]);

  return {
    periodYearMonths,
    periodStartYearMonth,
    periodEndYearMonth,
    periodMonths,
  };
};
