import { TextFieldProps } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../store";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../common/types/data/form/autocomplete.types";
import { RestrictionsOperand } from "../../../../../../../classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../../../../../classes/user/permissions/permissionsOperators/RootPermissionsOperator";
import { EmployeeAllocationType } from "../../../../../../../enums/allocations/EmployeeAllocationType.enums";
import { PermissionType } from "../../../../../../../enums/users/permissions/PermissionType.enum";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../maps/allocations/EmployeeAllocationType.maps";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<EmployeeAllocationType>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
  includeOnlyAllocationType?: EmployeeAllocationType[];
  excludeOnlyAllocationType?: EmployeeAllocationType[];
  includeOnlyManagementType?: boolean;
  isTimesheet?: boolean;
}

export const AllocationTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
  includeOnlyAllocationType,
  excludeOnlyAllocationType,
  includeOnlyManagementType,
  isTimesheet,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  const canAccessProperty = useCallback(
    (restrictions: RestrictionsOperand) => {
      if (!userData) return false;

      const permissionsOperator = new RootPermissionsOperator(restrictions);

      return permissionsOperator.checkPermissions({
        userPermissions: userData.permissions,
        isAdmin: userData.isAdmin,
      });
    },
    [userData?.permissions, userData?.isAdmin]
  );

  const options = useMemo(() => {
    let filteredAllocationType = Object.values(EmployeeAllocationType)
      .filter((x) => typeof x === "number")
      .map((x) => x as EmployeeAllocationType)
      .filter((x) => {
        if (x === EmployeeAllocationType.SupplementaryAllocation) return false;
        if (x === EmployeeAllocationType.Unallocated && !isTimesheet) {
          return canAccessProperty({
            type: PermissionType.CanAccessDeallocationInCorporateAllocation,
          });
        }
        if (x === EmployeeAllocationType.Leave && !isTimesheet) {
          return canAccessProperty({
            type: PermissionType.CanAccessLeaveInCorporateAllocation,
          });
        }
        if (x === EmployeeAllocationType.Training && !isTimesheet) {
          return canAccessProperty({
            type: PermissionType.CanAccessTrainingInCorporateAllocation,
          });
        }
        return (
          includeOnlyManagementType ||
          (x !== EmployeeAllocationType.PerformanceEvaluation &&
            x !== EmployeeAllocationType.MonthlyOnboarding)
        );
      });

    if (includeOnlyManagementType) {
      filteredAllocationType = filteredAllocationType.filter(
        (x) =>
          x === EmployeeAllocationType.PerformanceEvaluation ||
          x === EmployeeAllocationType.MonthlyOnboarding
      );
    }

    if (includeOnlyAllocationType)
      filteredAllocationType = filteredAllocationType.filter((x) =>
        includeOnlyAllocationType.includes(x)
      );
    if (excludeOnlyAllocationType)
      filteredAllocationType = filteredAllocationType.filter(
        (x) => !excludeOnlyAllocationType.includes(x)
      );

    return filteredAllocationType.map(
      (x): AutocompleteOption<EmployeeAllocationType> => ({
        id: x,
        label: t(employeeAllocationTypeToTranslationCodeMap[x]),
      })
    );
  }, [i18n.language, includeOnlyManagementType, excludeOnlyAllocationType]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.type"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
