import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import {
  getCostCenterPepById,
  updateCostCenterPep,
} from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { CostCenterPepDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepDTO";
import { CostCenterPepOnlyDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CostCenterPepUpdateDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCostCenterPepForm } from "../../shared/react/components/content/CommonCostCenterPepForm";
import { useFormFormikConfig } from "../../shared/react/hooks/useFormFormikConfig";
import { CostCenterPepsFormValues } from "../../shared/types/data/form.types";
import { HandleTaxes } from "./utils/HandleTaxes";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTable: () => void;
}

export const useEditingForm = ({ reloadTable }: OwnProps) => {
  const { initialValues, validationSchema } = useFormFormikConfig();

  const formikConfig: EntryFormikConfig<CostCenterPepsFormValues> =
    useMemo(() => {
      return {
        initialValues,
        validationSchema,
      };
    }, [initialValues, validationSchema]);

  const { contentModal: editingModal, ContentButton: EditingButton } =
    useFormikModalButton<
      CostCenterPepsFormValues,
      CostCenterPepDTO,
      CostCenterPepOnlyDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: costCenterPep }) => {
          const { t } = useTranslation();
          if (!costCenterPep) return null;

          if (!costCenterPep.pepCode)
            return <>{t("general.modal.data.edit.titleEditPep")}</>;

          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: costCenterPep.pepCode }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: tableCostCenterPepOnlyData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          setModalError,
          checkInCurrentModalCount,
          setInternalFormData,
          setFormValues,
        }) => {
          setIsLoadingModal(true);
          const startingModalCount = getOpenCloseModalCount();

          try {
            const costCenterPep = await getCostCenterPepById(
              tableCostCenterPepOnlyData.id
            );

            if (!checkInCurrentModalCount(startingModalCount)) return;

            if (!costCenterPep)
              return setModalError(
                i18next.t("costCenterPeps.errors.data.general.pepDoesNotExist")
              );

            const cofinsTax = costCenterPep.taxes.find(
              (x) => x.name === "COFINS"
            )?.value;
            const issTax = costCenterPep.taxes.find(
              (x) => x.name === "ISS"
            )?.value;
            const pisTax = costCenterPep.taxes.find(
              (x) => x.name === "PIS"
            )?.value;
            const icmsTax = costCenterPep.taxes.find(
              (x) => x.name === "ICMS"
            )?.value;

            setInternalFormData(costCenterPep);
            setFormValues({
              costCenter: costCenterPep.costCenter,
              isExempted:
                costCenterPep.isExempted === null
                  ? null
                  : {
                      id: costCenterPep.isExempted,
                      label: "",
                    },
              pepCode: costCenterPep.pepCode,
              pepDescription: costCenterPep.pepDescription,
              subsidiary: costCenterPep.subsidiary,
              tax: costCenterPep.tax
                ? formatNumber(costCenterPep.tax, { fractionDigits: 2 })
                : formatNumber(0, { fractionDigits: 2 }),
              cofins: {
                label: cofinsTax
                  ? formatNumber(cofinsTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              icms: icmsTax
                ? formatNumber(icmsTax, { fractionDigits: 2 })
                : formatNumber(0, { fractionDigits: 2 }),
              iss: {
                label: issTax
                  ? formatNumber(issTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              pis: {
                label: pisTax
                  ? formatNumber(pisTax, { fractionDigits: 2 })
                  : formatNumber(0, { fractionDigits: 2 }),
              },
              cfop: costCenterPep.cfop ?? null,
              costCenterFromDeal: "",
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPep",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData: costCenterPep, ...rest }) => (
          <CommonCostCenterPepForm
            {...rest}
            mode="editing"
            projectData={costCenterPep?.project}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData: costCenterPep,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          if (!costCenterPep)
            throw new Error("costCenterPep shouldn't be null on submit.");

          try {
            const costCenterPepUpdateDTO: CostCenterPepUpdateDTO = {
              id: costCenterPep.id,
              idCostCenter: formValues.costCenter?.id ?? 0,
              idSubsidiary: formValues.subsidiary?.id ?? 0,
              isExempted: formValues.isExempted?.id ?? false,
              pepCode: formValues.pepCode,
              pepDescription: formValues.pepDescription,
              taxes: HandleTaxes(formValues, costCenterPep),
              version: costCenterPep.version,
              idCfop: formValues.cfop?.id || null,
            };

            await updateCostCenterPep(costCenterPepUpdateDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTable();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costCenterPeps.errors.data.update.failedToUpdateCostCenterPep",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { editingModal, EditingButton };
};
