import { ExpensesUploadResponseDTO } from "../../../shared/specific/DTOs/uploadPerformedExpense/ExpensesUploadResponseDTO";
import { riseApi } from "./connection";

export const uploadPerformedExpenses = async (
  file: File
): Promise<ExpensesUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: errorsString } = await riseApi.post(
    "PerformedExpense/performedExpensesFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return errorsString;
};
