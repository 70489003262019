import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ProjectionAdjustmentMonthlyCommentDto } from "../../../../../../../../../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentDto";
import { useCreationEditingFormikConfig } from "./hooks/useFormikConfig";
import { useFormikModalButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ProjectionAdjustmentMonthlyCommentFormValues } from "./types/ProjectionAdjustmentMonthlyCommentFormValues";
import {
  createMonthlyComment,
  getMonthlyCommentById,
  updateMonthlyComment,
} from "../../../../../../../../../../../services/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyComment.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { MonthlyCommentCommomForm } from "./components/MonthlyCommentCommonForm";
import { ProjectionAdjustmentMonthlyCommentUpdateDto } from "../../../../../../../../../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentUpdateDto";
import { ProjectionAdjustmentMonthlyCommentCreateDto } from "../../../../../../../../../../../shared/specific/DTOs/ProjectionAdjustmentMonthlyComment/ProjectionAdjustmentMonthlyCommentCreateDto";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { CommentFormButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/CommentFormButton";

interface InternalCommentFormDataProps {
  idSubsidiary: number;
  idDivision: number;
  idsegmentGroup: number;
  yearMonth: YearMonth;
}

interface InternalFormDataProps {
  loadFormData: InternalCommentFormDataProps;
  comment: ProjectionAdjustmentMonthlyCommentDto | null;
}

export const useEditingMonthlyComment = () => {
  const { t } = useTranslation();

  const formikConfig = useCreationEditingFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      ProjectionAdjustmentMonthlyCommentFormValues,
      InternalFormDataProps,
      InternalFormDataProps
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData }) => (
          <Trans
            i18nKey="projection.keywords.fields.commentYearMonth"
            values={{
              yearMonth:
                internalFormData?.comment?.yearMonth.toPrettyString() ??
                internalFormData?.loadFormData.yearMonth.toPrettyString() ??
                "-",
            }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: CommentFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: { comment, loadFormData },
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            if (comment?.id != null) {
              const commentData = await getMonthlyCommentById(comment.id);
              if (!checkInCurrentModalCount(startingOpenCloseModalCount))
                return;
              setFormValues({
                comment: commentData?.comment || "",
              });
            } else {
              setFormValues({
                comment: "",
              });
            }
            setInternalFormData({ comment, loadFormData });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "projection.errors.data.fetch.failedToFetchProjection",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: MonthlyCommentCommomForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: comment,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          try {
            if (comment?.comment?.id != null) {
              const updatedMonthlyComment: ProjectionAdjustmentMonthlyCommentUpdateDto =
                {
                  Id: comment?.comment.id || 0,
                  Version: comment?.comment.version || "",
                  Comment: formValues.comment || "",
                };

              await updateMonthlyComment(updatedMonthlyComment);
            } else {
              const createdMonthlyComment: ProjectionAdjustmentMonthlyCommentCreateDto =
                {
                  IdDivision: comment?.loadFormData.idDivision || 0,
                  IdSegmentGroup: comment?.loadFormData.idsegmentGroup || 0,
                  IdSubsidiary: comment?.loadFormData.idSubsidiary || 0,
                  YearMonth:
                    comment?.loadFormData.yearMonth ||
                    YearMonth.createFromNow(),
                  Comment: formValues.comment,
                };
              await createMonthlyComment(createdMonthlyComment);
            }

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            window.location.reload();
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            notifyError(
              t("projection.errors.data.edit.failedToFetchProjection")
            );
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
