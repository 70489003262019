import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { schemaWorkloadDTO, WorkloadDTO } from "./WorkloadDTO";

export type PaginatedWorkloadDTO = PaginationResponse<WorkloadDTO>;

export const schemaPaginatedWorkloadDTO =
  getSchemaPaginationResponse<WorkloadDTO>(schemaWorkloadDTO);
