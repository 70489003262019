import { useEffect, useState } from "react";
import { ButtonBase, Typography } from "@mui/material";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import { useTranslation } from "react-i18next";
import { PageCardButtonContainer } from "../../../../shared/common/react/components/navigation/PageCardButton/index.styles";
import {
  CheckCanLoadDreQueues,
  LoadDrequeues,
} from "../../../../services/DreQueue/dreQueue.service";

export const DRECardComponent = () => {
  const { t } = useTranslation();
  const [disabledLoadQueues, setDisabledLoadQueues] = useState<boolean>(true);

  const onclick = async () => {
    await LoadDrequeues();
    setDisabledLoadQueues(true);
  };

  const VerifyQueues = async () => {
    const result = await CheckCanLoadDreQueues();
    setDisabledLoadQueues(!result);
  };
  useEffect(() => {
    // Chame a função inicialmente ao montar o componente.
    VerifyQueues();

    // Em seguida, use setInterval() para chamar a função a cada 1 minuto (60000 milissegundos).
    const intervalId = setInterval(VerifyQueues, 60000);

    // Ao desmontar o componente, limpe o intervalo para evitar vazamentos de memória.
    return () => clearInterval(intervalId);
  }, []);

  return (
    <ButtonBase onClick={onclick} disabled={disabledLoadQueues}>
      <PageCardButtonContainer>
        <AddToQueueIcon color={disabledLoadQueues ? "disabled" : "primary"} />
        <Typography>{t("permissionTypes.keywords.fields.dreQueue")}</Typography>
      </PageCardButtonContainer>
    </ButtonBase>
  );
};
