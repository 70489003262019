import { ToastContainer } from "react-toastify";
// eslint-disable-next-line import/no-extraneous-dependencies
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createTheme, ThemeProvider } from "@mui/material";
import { ptBR, enUS } from "@mui/x-date-pickers/locales";
import { ptBR as coreptBR, enUS as coreenUS } from "@mui/material/locale";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { GlobalStyles } from "./core/styles/GlobalStyles";
import { lightTheme } from "./core/themes/light";

import "react-toastify/dist/ReactToastify.css";

import { AutomaticAuthenticator } from "./core/microsoftAuthenticator";
import { FinalFallbackErrorPage } from "./Router/pages/general/FinalFallbackErrorPage";
import { AuthenticatedApp } from "./core/components/AuthenticatedApp";
import { Language } from "./shared/common/enums/locale/Language.enum";

export const App = () => {
  const { i18n } = useTranslation();

  const themeWithLocale = useMemo(() => {
    const locale = i18n.language === Language.ptBR ? ptBR : enUS;
    const coreLocale = i18n.language === Language.ptBR ? coreptBR : coreenUS;

    return createTheme(lightTheme, locale, coreLocale);
  }, [i18n.language]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  });

  return (
    <ThemeProvider theme={themeWithLocale}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={FinalFallbackErrorPage}>
          <AutomaticAuthenticator>
            <AuthenticatedApp />
          </AutomaticAuthenticator>
        </ErrorBoundary>
        <GlobalStyles />
        <ToastContainer theme="colored" />
      </QueryClientProvider>
    </ThemeProvider>
  );
};
