import { useMemo } from "react";
import { useSuperUserButton } from "../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { InvoicesSuperUserContext } from "./shared/contexts/InvoicesSuperUserContext";
import { InvoicesViewPageWithinContext } from "./InvoicesViewPageWithinContext";

export const InvoicesViewPage = () => {
  const {
    superUserButton: canEditAllInvoicesButton,
    inSuperUserMode: canEditAllInvoices,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllInvoices,
    tooltipNotInSuperUserModeOff:
      "invoice.actions.authorization.editInvoicesWithoutRestrictions",
    tooltipInSuperUserMode:
      "invoice.actions.authorization.stopEditInvoicesWithoutRestrictions",
  });

  const invoicesSuperUserProvider = useMemo(() => {
    return {
      canEditAllInvoices,
      canEditAllInvoicesButton,
    };
  }, [canEditAllInvoicesButton, canEditAllInvoices]);

  return (
    <InvoicesSuperUserContext.Provider value={invoicesSuperUserProvider}>
      <InvoicesViewPageWithinContext />
    </InvoicesSuperUserContext.Provider>
  );
};
