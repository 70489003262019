import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormSectionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  padding: 20px 16px;
  flex: 1;
  overflow: auto;
`;

export const StyledTitle = styled("div")`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProjectInfo = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c c"
    "d d"
    "e f"
    "g g";

  ${createGridAreaLettersRange(7)}
`;
