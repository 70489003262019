import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "../../../shared/common/enums/locale/Language.enum";
import { LanguageState } from "./index.types";

const initialState: LanguageState = {
  defaultLanguage: null,
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setDefaultLanguage: (state, action: PayloadAction<Language | null>) => {
      state.defaultLanguage = action.payload;
    },
  },
});

export const { setDefaultLanguage } = languageSlice.actions;

export const languageReducer = languageSlice.reducer;
