import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")`
  width: 100%;
  display: grid;
  gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c d";
  ${createGridAreaLettersRange(4)};
`;
