import * as yup from "yup";

import { PipedriveDealDTO, schemaPipedriveDealDTO } from "./PipedriveDealDTO";
import {
  PaginationResponse,
  getSchemaPaginationResponse,
} from "../shared/responses/FilterResponseDTO";

export type PaginatedPipedriveDealDTO = PaginationResponse<PipedriveDealDTO>;

export const schemaPaginatedPipedriveDealDTO =
  getSchemaPaginationResponse<PipedriveDealDTO>(
    schemaPipedriveDealDTO as yup.Schema<PipedriveDealDTO>
  );
