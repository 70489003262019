import * as yup from "yup";

import {
  SkillTranslationDTO,
  schemaSkillTranslationDTO,
} from "./translation/SkillTranslationDTO";
import {
  SkillCategoryDTO,
  schemaSkillCategoryDTO,
} from "../skillCategory/SkillCategoryDTO";

export interface SkillDTO {
  id: number;
  name: string;
  skillCategory: SkillCategoryDTO;
  deactivatedDate: Date | null;
  version: string;
  skillTranslation: SkillTranslationDTO[];
}

export const schemaSkillDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  skillCategory: schemaSkillCategoryDTO.defined(),
  deactivatedDate: yup.date().nullable().default(null),
  version: yup.string().defined(),
  skillTranslation: yup.array(schemaSkillTranslationDTO).defined(),
});
