import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getTimesheetsPerMonth } from "../../../../../../../services/timesheet/timesheets.service";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatMinutesToHourMinute } from "../../../../../../../shared/common/helpers/data/dates/formatters.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { getTimesheetTypeAbbreviation } from "../../shared/helpers/data/timesheet/general.helpers";
import { useDeletionForm } from "../useDeletionForm";
import { useEditingForm } from "../useEditingForm";
import { useViewingForm } from "../useViewingForm";
import { RowButtons } from "./RowButtons";

interface OwnProps {
  yearMonth: YearMonth;
  reloadTablePage: () => void;
  setTotalMinutes: (totalMinutes: number | null) => void;
}

export const useTableData = ({
  yearMonth,
  reloadTablePage,
  setTotalMinutes,
}: OwnProps) => {
  const { t } = useTranslation();

  const { DeletionButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButton, editingModal } = useEditingForm({
    reloadTablePage,
    yearMonth,
  });
  const { ViewingButton, viewingModal } = useViewingForm({ yearMonth });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.type"),
      t("general.keywords.fields.code"),
      t("timesheets.keywords.fields.projectCostCenter"),
      t("general.keywords.fields.nature"),
      t("corporateSegment.keywords.general.corporateSegments"),
      t("timesheets.keywords.fields.totalHoursMonth"),
      {
        value: "",
        width: 152,
      },
    ];

    return headCells;
  }, [t]);

  const singlePageLoader: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const timesheetsUserMonth = await getTimesheetsPerMonth({
          filters: { yearMonth },
        });

        setTotalMinutes(timesheetsUserMonth.minutesSum);

        const rows = timesheetsUserMonth.timesheetMonths.map(
          (timesheetMonth) => {
            const row: ExternalEnhancedRow = [
              t(getTimesheetTypeAbbreviation(timesheetMonth)),
              timesheetMonth.costCenter?.code ??
                timesheetMonth.project?.code ??
                "-",
              timesheetMonth.costCenter?.name ??
                timesheetMonth.project?.name ??
                "-",
              {
                value: timesheetMonth.allocationType,
                displayValue: t(
                  employeeAllocationTypeToTranslationCodeMap[
                    timesheetMonth.allocationType
                  ]
                ),
              },
              timesheetMonth.corporateSegment?.name ?? "",
              {
                value: timesheetMonth.minutesSum,
                displayValue: formatMinutesToHourMinute(
                  timesheetMonth.minutesSum
                ),
              },
              {
                CellRenderer: () => (
                  <RowButtons
                    DeletionButtonContainer={DeletionButtonContainer}
                    EditingButton={EditingButton}
                    ViewingButton={ViewingButton}
                    timesheetMonth={timesheetMonth}
                  />
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ];

            return row;
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [yearMonth]);

  return {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    viewingModal,
  };
};
