import { ButtonBase, styled } from "@mui/material";

export const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  width: 100%;
`;

export const StyledCarouselCell = styled("div")`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 0.9rem;
  font-style: italic;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.action.focus};
  }
`;
