import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { sleep } from "../../../../../../../../../../../../shared/common/helpers/general.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { AllocationYearMonthCell } from "../AllocationYearMonthCell";
import { AllocationYearMonthTotalCell } from "../AllocationYearMonthTotalCell";

interface OwnProps {
  periodYearMonths: YearMonth[] | null;
}

export const useTableData = ({ periodYearMonths }: OwnProps) => {
  const { t } = useTranslation();

  const headCells = useMemo((): ExternalEnhancedHeadCell[] => {
    if (!periodYearMonths) return [];

    return [
      { value: "", canSort: false, width: 100, headColumn: true },
      ...periodYearMonths.map(
        (x): ExternalEnhancedHeadCell => ({
          value: x.toPrettyString(),
          canSort: false,
          width: 140,
        })
      ),
      { value: "", canSort: false, paddingmode: "none" },
    ];
  }, [periodYearMonths]);

  const singlePageLoader =
    useCallback<ExternalEnhancedTableExternalSinglePageLoader>(async () => {
      if (!periodYearMonths) return [];

      // Makes the loading spinner actually be shown and the delay to
      // load the table, more elegant.
      await sleep(200);

      const rows = [] as ExternalEnhancedRow[];
      for (let weekNumber = 1; weekNumber <= 5; weekNumber++) {
        rows.push([
          {
            value: `${t("general.keywords.fields.week")} ${weekNumber}`,
          },
          ...periodYearMonths.map((yearMonth, index) => ({
            CellRenderer: () => (
              <AllocationYearMonthCell
                columnNumber={index}
                weekNumber={weekNumber}
                yearMonth={yearMonth}
              />
            ),
          })),
          { paddingmode: "none" },
        ]);
      }

      rows.push([
        {
          value: t("general.keywords.fields.total"),
        },
        ...periodYearMonths.map((yearMonth) => ({
          CellRenderer: () => (
            <AllocationYearMonthTotalCell yearMonth={yearMonth} />
          ),
        })),
        { paddingmode: "none" },
      ]);

      return rows;
    }, [periodYearMonths]);

  return { headCells, singlePageLoader };
};
