import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PaletteColorVariant } from "../../../../../types/externalLibraries/mui.types";
import { DefaultDialogActionsProps } from "./shared/types/props.types";

interface OwnProps<InternalModalData> {
  closeModal: () => void;
  internalModalData: InternalModalData | null;
  isLoadingModal: boolean;
  modalError: ReactNode;
  defaultDialogActionsProps: DefaultDialogActionsProps<InternalModalData>;
  modalColorVariant?: PaletteColorVariant;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
}

export const InternalModalActions = <InternalModalData,>({
  closeModal,
  internalModalData,
  isLoadingModal,
  modalError,
  modalColorVariant,
  isSubmitting,
  setIsSubmitting,
  defaultDialogActionsProps: {
    cancelButtonLabel,
    confirmButtonLabel,
    onConfirm,
    onConfirmMemo,
    checkConfirmDisabled,
    checkConfirmDisabledMemo,
  },
}: OwnProps<InternalModalData>) => {
  const { t } = useTranslation();

  const finalOnConfirm = useMemo(() => {
    return onConfirm ?? onConfirmMemo ?? null;
  }, [onConfirm]);

  const finalCheckConfirmDisabled = useMemo(() => {
    return checkConfirmDisabled ?? checkConfirmDisabledMemo ?? null;
  }, [onConfirm]);

  const confirmDialog = async () => {
    if (!finalOnConfirm) {
      closeModal();
      return;
    }

    setIsSubmitting(true);

    try {
      await Promise.resolve(finalOnConfirm({ closeModal, internalModalData }));
    } catch (error) {
      console.error("Unexpected error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isConfirmDisabled = useMemo(() => {
    if (finalCheckConfirmDisabled)
      return finalCheckConfirmDisabled({
        isLoadingModal,
        hasInternalModalError: !!modalError,
        internalModalData,
      });

    return isLoadingModal || !!modalError;
  }, [isLoadingModal, !!modalError, internalModalData]);

  return (
    <>
      <Button
        onClick={closeModal}
        color={modalColorVariant}
        disabled={isSubmitting}
      >
        {cancelButtonLabel ?? t("general.actions.general.cancel")}
      </Button>
      <LoadingButton
        loading={isSubmitting}
        onClick={confirmDialog}
        variant="contained"
        color={modalColorVariant}
        disabled={isConfirmDisabled}
      >
        {confirmButtonLabel ?? t("general.actions.general.confirm")}
      </LoadingButton>
    </>
  );
};
