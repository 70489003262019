import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { FormValuesDatailedProjectsOverview } from "./types/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { ProjectDetailedViewReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/ProjectDetailedViewReportFilterDTO";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { generateProjectDetailedViewReport } from "../../../../../../../../../services/reports/reports.service";

export const DatailedProjectsOverview = () => {
  const { t } = useTranslation();

  const formikRef =
    useRef<FormikProps<FormValuesDatailedProjectsOverview>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesDatailedProjectsOverview = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      project: null,
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      division: yup
        .object()
        .nullable()
        .test({
          name: "teste",
          message: t("reports.errors.validations.mustHaveUnOrProject"),
          test(value) {
            // eslint-disable-next-line react/no-this-in-sfc
            return !!value || !!this.parent.project;
          },
        }),
      project: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesDatailedProjectsOverview,
    formikHelpers: FormikHelpers<FormValuesDatailedProjectsOverview>
  ) => {
    const formattedFilters: ProjectDetailedViewReportFilterDTO = {
      IdProject: values.project?.id ?? null,
      IdDivision: values.division?.id,
      DivisionName: values.division?.name ?? undefined,
      StartDate: values?.startYearMonth
        ? YearMonth.createFromDate(values.startYearMonth)
        : undefined,
      EndDate: values?.endYearMonth
        ? YearMonth.createFromDate(values.endYearMonth)
        : undefined,
    };

    await generateProjectDetailedViewReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <ProjectSearchAutocompleteFormik
              name="project"
              autocompleteProps={{
                renderOption: (props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                ),
              }}
              textfieldProps={{
                label: `${t("projects.keywords.fields.project")} (*)`,
              }}
            />
            <YearMonthDatePickerFormik
              name="startYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.startPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{ required: true }}
            />
            <YearMonthDatePickerFormik
              name="endYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.endPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{ required: true }}
            />
            <DivisionsAutocompleteFormik
              name="division"
              textfieldProps={{ required: true }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
