import { Menu } from "@mui/material";
import { bindTrigger, bindMenu } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useMemo } from "react";
import { MoreVerticalButton } from "../../general/buttons/simpleIconButtons/MoreVerticalButton";
import { MenuOption } from "./MenuOption";
import { MenuOptionData } from "./shared/types/general.option";

interface OwnProps {
  menuOptions: MenuOptionData[];
}

export const ContextMenu = ({ menuOptions }: OwnProps) => {
  const popupState = usePopupState({ variant: "popover", popupId: undefined });

  const menuOptionsComponent = useMemo(() => {
    const closeMenu = () => popupState.close();

    return menuOptions.map((menuOption, index) => (
      <MenuOption key={index} closeMenu={closeMenu} {...menuOption} />
    ));
  }, [menuOptions]);

  return (
    <>
      <MoreVerticalButton onClick={bindTrigger(popupState).onClick} />
      <Menu {...bindMenu(popupState)}>{menuOptionsComponent}</Menu>
    </>
  );
};
