import { styled } from "@mui/material";
import { Form } from "formik";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c d"
    "e f"
    "g h"
    "i j"
    "k l";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(12)}
`;

export const StyledLine = styled("div")`
  border-bottom: solid #cccccc 2px;
  height: 1px;
  width: 100%;
`;

export const StyledLanguages = styled("div")`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;
