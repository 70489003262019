import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { getCustomerBalance } from "../../../../../../services/customerBalance/customerBalance.service";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CustomerBalanceFilterDTO } from "../../../../../../shared/specific/DTOs/customerBalance/CustomerBalanceFilterDTO";
import { customerBalanceTypeToTranslationCode } from "../../../../../../shared/specific/maps/customerBalance/customerBalanceTypeToTranslationCode";

interface OwnProps {
  filters?: CustomerBalanceFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("jobRates.keywords.general.startYearMonth"),
      t("Tipo de Saldo"),
      t("costDistribution.keywords.fields.groupCode"),
      {
        value: t("costCenters.keywords.general.costCenter"),
        canSort: false,
      },
      {
        value: t("invoice.keywords.fields.segment"),
        canSort: false,
      },
      {
        value: t("customerBalance.keywords.fields.amount"),
        canSort: false,
      },
      {
        value: "",
        width: 152,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalPageChanger = useCallback(
    async ({ newPage, order, orderBy, rowsPerPage }) => {
      const headKeys = [
        "yearMonth",
        "customerTrigram",
        "costCenter.code",
        "segmentGroup.description",
        "amount",
      ];

      try {
        const {
          tableData: customerBalanceData,
          page,
          totalCount,
        } = await getCustomerBalance({
          filters,
          pagination: {
            pageSize: rowsPerPage,
            page: newPage + 1,
            columnOrderByDir: orderBy !== null ? order : undefined,
            columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
          },
        });

        const rows = customerBalanceData.map(
          (ud): ExternalEnhancedRow => ({
            id: ud.id,
            cells: [
              {
                displayValue: ud.yearMonth.toPrettyString(),
                value: ud.yearMonth.toDate(),
              },
              t(customerBalanceTypeToTranslationCode[ud.type]),
              ud.customerTrigram ?? "",
              ud.costCenter?.code ?? "",
              ud.segmentGroup?.segmentGroupTranslations.find(
                (x) => x.idLanguage === i18n.language
              )?.name ??
                ud.segmentGroup?.description ??
                "",
              {
                value: ud.amount,
                displayValue: formatNumber(ud.amount, {
                  fractionDigits: 2,
                  prefix: `${ud.costCenter.subsidiary.currencySymbol} `,
                }),
              },
              {
                CellRenderer: () => (
                  <>
                    <Protected
                      restrictions={{
                        type: PermissionType.CustomerBalance,
                        level: PermissionLevel.Update,
                      }}
                    >
                      <EditingButtonContainer {...ud} />
                    </Protected>
                    <Protected
                      restrictions={{
                        type: PermissionType.CustomerBalance,
                        level: PermissionLevel.Delete,
                      }}
                    >
                      <DeleteButtonContainer {...ud} />
                    </Protected>
                  </>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
            ],
          })
        );

        return {
          rows,
          page: page - 1,
          totalItems: totalCount,
        };
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    },
    [filters]
  );

  return {
    headCells,
    onPageChange,
    deleteDialog: deletionModal,
    editingModal,
  };
};
