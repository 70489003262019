import { useContext } from "react";
import { FormikValues } from "formik";
import { FormContentContext } from "./FormContentContext";
import { FormContentContextValues } from "./index.types";

export const useFormContentContext = <
  FormValues extends FormikValues,
  InternalFormValues,
>() => {
  const context = useContext<FormContentContextValues<
    FormValues,
    InternalFormValues
  > | null>(FormContentContext);

  if (!context) throw new Error("Context cannot be null at this point.");
  return context;
};
