import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCollaboratorHistory } from "../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { EditIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useCollaboratorAllocationsListByHistory } from "../../../../../shared/react/hooks/useCollaboratorAllocationsListByHistory";

interface OwnProps {
  reloadTablePage: () => void;
  collaboratorId: number;
  collaboratorName: string;
  isSuperUserContext?: boolean;
}

export const useTableData = ({
  reloadTablePage,
  collaboratorId,
  collaboratorName,
  isSuperUserContext,
}: OwnProps) => {
  const { t } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
    collaboratorName,
  });

  const { ViewingButtonContainer, viewingModal } =
    useCollaboratorAllocationsListByHistory();

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("collaboratorTrack.keywords.fields.startOfTerm"),
      t("collaboratorTrack.keywords.fields.registryNumber"),
      t("jobRoles.keywords.general.jobRole"),
      t("offices.keywords.fields.office"),
      t("collaboratorTrack.keywords.fields.motherProfitCenter"),
      t("contractRole.keywords.general.contractRole"),
      {
        value: "",
        width: 150,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onCollaboratorHistoryPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(
      async ({ setInfo }) => {
        if (!collaboratorId)
          throw new Error(
            "'collaboratorId' cannot be null when this function is called."
          );

        try {
          const collaboratorHistoryData =
            await getCollaboratorHistory(collaboratorId);

          if (collaboratorHistoryData.length === 0)
            setInfo(t("collaboratorTrack.keywords.fields.addCollaboratorInfo"));

          const rows: ExternalEnhancedRow[] = collaboratorHistoryData.map(
            (history, index) => ({
              id: index,
              cells: [
                {
                  value: history.startYearMonth?.toDate(),
                  displayValue: history.startYearMonth?.toPrettyString() ?? "-",
                },
                history.registryNumber,
                history.jobRole?.name ?? "-",
                history.office.name,
                history.costCenter.code,
                history.contractRole?.name ?? "-",
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.CollaboratorHistory,
                          level: PermissionLevel.Update,
                        }}
                      >
                        {history.isCurrent || isSuperUserContext ? (
                          <EditingButtonContainer
                            collaboratorHistoryData={history}
                          />
                        ) : (
                          <EditIconButton disabled />
                        )}
                      </Protected>
                      <ViewingButtonContainer
                        historyContent={{
                          historyId: history.id,
                          jobRoleName: history.jobRole?.name ?? "-",
                          yearMoth: history.startYearMonth ?? undefined,
                        }}
                        allocations={null}
                      />
                      <Protected
                        restrictions={{
                          type: PermissionType.CollaboratorHistory,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...history} />
                      </Protected>
                    </>
                  ),
                },
              ],
            })
          );

          return rows;
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [t, isSuperUserContext]
    );

  return {
    headCells,
    onCollaboratorHistoryPageChange,
    deletionModal,
    editingModal,
    viewingModal,
  };
};
