import * as yup from "yup";
import { Button } from "@mui/material";
import { MoneyOff as MoneyOffIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useMemo } from "react";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  UpdateToCancellationAnalysis,
  getInvoiceById,
} from "../../../../../../../../../services/invoices/invoices.service";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { maxLengthFields } from "../../shared/constants/data/form/fields.constants";
import { TextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { StyledContainer } from "./index.styles";
import { UpdateToCancellationAnalysisDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateToCancellationAnalysisDTO";

interface OwnProps {
  reloadInvoiceSummary: () => void;
  reloadTablePage: () => void;
}

interface FormValue {
  reasonForCancellation: string;
}

export const useCancelationInvoiceRequest = ({
  reloadTablePage,
  reloadInvoiceSummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: FormValue = {
      reasonForCancellation: "",
    };

    const validationSchema = yup.object({
      reasonForCancellation: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, []);

  const {
    ContentButton: CancellationInvoiceRequestButton,
    contentModal: cancellationInvoiceRequestModal,
  } = useFormikModalButton<FormValue, InvoiceDTO, InvoiceDTO>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("invoice.modal.data.cancel.title"),
      modalMode: "dialog",
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            Icon={MoneyOffIcon}
            onClick={onClick}
            color="primary"
            tooltipText={t("invoice.modal.data.cancel.title")}
          />
        );
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoice,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        try {
          const invoiceData = await getInvoiceById(invoice.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!invoiceData)
            return setModalError(
              t("invoice.errors.data.general.invoiceDoesNotExist")
            );

          setInternalFormData(invoiceData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoices",
          });
          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: () => (
        <StyledContainer>
          <TextFieldFormik
            name="reasonForCancellation"
            label={t("invoice.keywords.fields.reasonForCancellation")}
            multiline
            rows={3}
            required
            fullWidth
            inputProps={{
              maxLength: maxLengthFields.delayJustification,
            }}
            InputProps={{
              fullWidth: true,
            }}
          />
        </StyledContainer>
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          if (!internalFormData)
            throw new Error("'invoice' cannot be falsy on submit.");

          const invoiceToUpdated: UpdateToCancellationAnalysisDTO = {
            invoiceIds: internalFormData?.invoiceGroup
              ? internalFormData?.invoiceGroup.map((x) => x.id)
              : [],
            reasonForCancellation: formValues.reasonForCancellation,
            responsibleLogin: internalFormData.responsibleLogin?.login ?? "",
            status: InvoicesStatusEnum.CancellationAnalysis,
            version: internalFormData.version,
          };

          await UpdateToCancellationAnalysis(invoiceToUpdated);

          notifySuccess(
            i18next.t("invoice.modal.data.cancel.successCancelAction")
          );
          reloadTablePage();
          reloadInvoiceSummary();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: t(
              "invoice.errors.data.cancel.failedToRequestCancellation"
            ),
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CancellationInvoiceRequestButton, cancellationInvoiceRequestModal };
};
