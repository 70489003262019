import * as yup from "yup";

import { riseApi } from "./connection";
import {
  SegmentDTO,
  schemaSegmentDTO,
} from "../../../shared/specific/DTOs/Segment/SegmentDTO";
import { SegmentFiltersDTO } from "../../../shared/specific/DTOs/Segment/filters/SegmentFiltersDTO";
import {
  SegmentGroupDTO,
  schemaSegmentGroupDTO,
} from "../../../shared/specific/DTOs/Segment/SegmentGroupDTO";
import { SegmentGroupFilterDTO } from "../../../shared/specific/DTOs/Segment/filters/SegmentGroupFilterDTO";

export const getSegments = async ({
  filters,
}: {
  filters?: SegmentFiltersDTO;
}): Promise<SegmentDTO[]> => {
  const { data: segments } = await riseApi.get("/Segment", {
    params: filters,
  });

  return yup.array(schemaSegmentDTO).defined().validateSync(segments);
};

export const getSegmentGroups = async ({
  filters,
}: {
  filters?: SegmentGroupFilterDTO;
}): Promise<SegmentGroupDTO[]> => {
  const { data: segmentGroups } = await riseApi.get(
    "/Segment/GetSegmentGroup",
    {
      params: filters,
    }
  );

  return yup.array(schemaSegmentGroupDTO).defined().validateSync(segmentGroups);
};
