import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { CustomerDTO } from "../../../../../../../../shared/specific/DTOs/customers/CustomerDTO";
import {
  StyledGeneralDataContainer,
  StyledLocationDataContainer,
  StyledRegistrationDataContainer,
  StyledSection,
  StyledViewContent,
} from "../../../shared/styles/ui.styles";
import { CountryDTO } from "../../../../../../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  customer?: CustomerDTO;
  country?: CountryDTO;
  state?: string;
}

export const CustomerView = ({ customer, country, state }: OwnProps) => {
  const { t } = useTranslation();

  if (!customer || !country) return null;

  return (
    <StyledViewContent>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.location")}
        </Typography>
        <StyledLocationDataContainer>
          <TextField
            value={country.name}
            label={t("general.keywords.fields.country")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.zipCode}
            label={t("general.keywords.fields.zipCodeBoth")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={state || "-"}
            label={t("general.keywords.fields.state")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.city}
            label={t("general.keywords.fields.city")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.district}
            label={t("general.keywords.fields.district")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.streetName}
            label={t("general.keywords.fields.streetName")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.complement || "-"}
            label={t("general.keywords.fields.complement")}
            InputProps={{ readOnly: true }}
          />
        </StyledLocationDataContainer>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.generalCustomerData")}
        </Typography>
        <StyledGeneralDataContainer>
          <TextField
            value={customer.tradingName}
            label={t("customers.keywords.fields.tradingName")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.corporateName}
            label={t("customers.keywords.fields.corporateName")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.email}
            label={t("general.keywords.fields.email")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.phoneNumber}
            label={t("general.keywords.fields.phoneNumber")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.code}
            label={t("customers.keywords.fields.groupCode")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={formatNumber(customer.daysForPayment)}
            label={t("general.keywords.fields.daysForPayment")}
            InputProps={{ readOnly: true }}
          />
        </StyledGeneralDataContainer>
      </StyledSection>
      <StyledSection>
        <Typography variant="h6" component="div">
          {t("customers.keywords.categories.registrationData")}
        </Typography>
        <StyledRegistrationDataContainer>
          <TextField
            value={customer.registrationCode ?? "-"}
            label={t("customers.keywords.fields.registrationCodeBoth")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.municipalCode || "-"}
            label={t("customers.keywords.fields.municipalCode")}
            InputProps={{ readOnly: true }}
          />
          <TextField
            value={customer.stateCode || "-"}
            label={t("customers.keywords.fields.stateCode")}
            InputProps={{ readOnly: true }}
          />
          <FormControlLabel
            label={t("customers.keywords.fields.publicAgency")}
            control={<Checkbox checked={customer.isPublicAgency} />}
          />
        </StyledRegistrationDataContainer>
      </StyledSection>
    </StyledViewContent>
  );
};
