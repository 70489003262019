import { differenceInMonths } from "date-fns";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { YearMonth } from "../../../../../../../classes/data/date/YearMonth";
import { useUpdatedRef } from "../../../../../../hooks/enhancedReactHooks/useUpdatedRef";
import { CarouselContext } from "../CarouselContext";
import {
  AddMonthsOffsetFirstYearMonthFunction,
  CarouselContextValues,
  CalculateYearMonthColumnFunction,
  CheckCanMoveLeftFunction,
  GetCarouselRealSizeFunction,
  StartCarouselFunction,
} from "../index.types";

interface OwnProps {
  initialEarliestYearMonth?: YearMonth | null;
  initialLatestYearMonth?: YearMonth | null;
  initialCarouselSize?: number | null;
  firstYearMonth?: number;
  children: ReactNode;
}

export const CarouselContextProvider = ({
  initialEarliestYearMonth = null,
  initialLatestYearMonth = null,
  initialCarouselSize = null,
  firstYearMonth = 0,
  children,
}: OwnProps) => {
  const [monthsOffsetFirstYearMonth, setMonthsOffsetFirstYearMonth] = useState<
    number | null
  >(firstYearMonth);
  const [carouselSize, setCarouselSize] = useState(initialCarouselSize ?? 0);
  const [earliestYearMonth, setEarliestYearMonth] = useState(
    initialEarliestYearMonth
  );
  const [latestYearMonth, setLatestYearMonth] = useState(
    initialLatestYearMonth
  );

  const totalYearMonths = useMemo(() => {
    if (!earliestYearMonth || !latestYearMonth) return 0;

    return (
      differenceInMonths(latestYearMonth.toDate(), earliestYearMonth.toDate()) +
      1
    );
  }, [earliestYearMonth, latestYearMonth]);

  const refsGeneralStates = useUpdatedRef({
    totalYearMonths,
    monthsOffsetFirstYearMonth,
    carouselSize,
  });

  const checkCanMoveLeft = (
    numberOfColumns: number,
    monthsOffsetFirstYearMonth: number | null
  ) => {
    if (monthsOffsetFirstYearMonth === null) return false;
    return monthsOffsetFirstYearMonth - numberOfColumns >= 0;
  };

  const checkCanMoveRight = (
    numberOfColumns: number,
    monthsOffsetFirstYearMonth: number | null,
    carouselSize: number,
    totalYearMonths: number
  ) => {
    if (monthsOffsetFirstYearMonth === null) return false;
    if (carouselSize >= totalYearMonths) return false;
    return (
      monthsOffsetFirstYearMonth + carouselSize - 1 + numberOfColumns <
      totalYearMonths
    );
  };

  const startCarousel = useCallback<StartCarouselFunction>(
    ({ carouselSize, monthsOffsetFirstYearMonth }) => {
      setCarouselSize(carouselSize);
      setMonthsOffsetFirstYearMonth((x) => {
        if (x === null) return 0;
        if (monthsOffsetFirstYearMonth !== undefined)
          return monthsOffsetFirstYearMonth;
        return x;
      });
    },
    []
  );

  const addMonthsOffsetFirstYearMonth =
    useCallback<AddMonthsOffsetFirstYearMonthFunction>((addition) => {
      const { monthsOffsetFirstYearMonth, totalYearMonths, carouselSize } =
        refsGeneralStates.current;

      if (monthsOffsetFirstYearMonth === null)
        throw new Error(
          "'monthsOffsetFirstYearMonth' cannot be null on addition."
        );

      if (
        addition < 0 &&
        (!checkCanMoveLeft(-addition, monthsOffsetFirstYearMonth) ||
          totalYearMonths === 0)
      )
        return setMonthsOffsetFirstYearMonth(0);

      if (
        addition > 0 &&
        !checkCanMoveRight(
          addition,
          monthsOffsetFirstYearMonth,
          carouselSize,
          totalYearMonths
        )
      )
        return setMonthsOffsetFirstYearMonth(
          Math.max(totalYearMonths - carouselSize, 0)
        );

      setMonthsOffsetFirstYearMonth(monthsOffsetFirstYearMonth + addition);
    }, []);

  const calculateYearMonth = useCallback<CalculateYearMonthColumnFunction>(
    ({ columnIndex }) => {
      if (
        !earliestYearMonth ||
        !latestYearMonth ||
        monthsOffsetFirstYearMonth === null
      )
        return null;

      const calculatedYearMonth = earliestYearMonth.addMonths(
        monthsOffsetFirstYearMonth + columnIndex
      );

      if (calculatedYearMonth.greaterThan(latestYearMonth)) return null;

      return calculatedYearMonth;
    },
    [monthsOffsetFirstYearMonth, earliestYearMonth, latestYearMonth]
  );

  const externalCheckCanMoveLeft = useCallback<CheckCanMoveLeftFunction>(
    (numberOfColumns) => {
      return checkCanMoveLeft(numberOfColumns, monthsOffsetFirstYearMonth);
    },
    [monthsOffsetFirstYearMonth]
  );

  const externalCheckCanMoveRight = useCallback<CheckCanMoveLeftFunction>(
    (numberOfColumns) => {
      return checkCanMoveRight(
        numberOfColumns,
        monthsOffsetFirstYearMonth,
        carouselSize,
        totalYearMonths
      );
    },
    [monthsOffsetFirstYearMonth, carouselSize, totalYearMonths]
  );

  const getCarouselRealSize = useCallback<GetCarouselRealSizeFunction>(
    () =>
      Math.min(
        refsGeneralStates.current.carouselSize,
        refsGeneralStates.current.totalYearMonths
      ),
    []
  );

  const carouselRealSize = useMemo(() => {
    return Math.min(carouselSize, totalYearMonths);
  }, [totalYearMonths, carouselSize]);

  useEffect(() => {
    if (monthsOffsetFirstYearMonth === null) return;
    if (monthsOffsetFirstYearMonth + carouselRealSize > totalYearMonths)
      setMonthsOffsetFirstYearMonth(
        Math.max(0, totalYearMonths - carouselRealSize)
      );
  }, [totalYearMonths, carouselRealSize, monthsOffsetFirstYearMonth]);

  const carouselContextValues = useMemo((): CarouselContextValues => {
    return {
      earliestYearMonth,
      latestYearMonth,
      monthsOffsetFirstYearMonth,
      totalYearMonths,
      carouselRealSize,
      startCarousel,
      addMonthsOffsetFirstYearMonth,
      calculateYearMonth,
      checkCanMoveLeft: externalCheckCanMoveLeft,
      checkCanMoveRight: externalCheckCanMoveRight,
      getCarouselRealSize,
      setEarliestYearMonth,
      setLatestYearMonth,
    };
  }, [
    earliestYearMonth,
    latestYearMonth,
    monthsOffsetFirstYearMonth,
    totalYearMonths,
    carouselRealSize,
    startCarousel,
    addMonthsOffsetFirstYearMonth,
    calculateYearMonth,
    externalCheckCanMoveLeft,
    externalCheckCanMoveRight,
    getCarouselRealSize,
  ]);

  return (
    <CarouselContext.Provider value={carouselContextValues}>
      {children}
    </CarouselContext.Provider>
  );
};
