import { ReactNode, useMemo, useState } from "react";
import { AllocationCollaboratorDTO } from "../../../../../../../../shared/specific/DTOs/allocationMap/AllocationCollaboratorDTO";
import { AllocationMapContext } from "./AllocationMapContext";
import { AllocationMapContextValues } from "./index.types";

interface OwnProps {
  children: ReactNode;
}

export const AllocationMapContextProvider = ({ children }: OwnProps) => {
  const [data, setData] = useState<AllocationCollaboratorDTO[]>([]);

  const allocationMapContext: AllocationMapContextValues = useMemo(
    () => ({
      data,
      setData,
    }),
    [data]
  );

  return (
    <AllocationMapContext.Provider value={allocationMapContext}>
      {children}
    </AllocationMapContext.Provider>
  );
};
