import * as yup from "yup";
import {
  DeprovisionTypeDTO,
  schemaDeprovisionTypeDTO,
} from "../../../shared/specific/DTOs/deprovisionType/DeprovisionTypeDTO";
import { riseApi } from "./connection";

export const getDeprovisionTypes = async (): Promise<DeprovisionTypeDTO[]> => {
  const { data: deprovisionTypes } = await riseApi.get("DeprovisionTypes");

  return yup
    .array(schemaDeprovisionTypeDTO)
    .defined()
    .validateSync(deprovisionTypes);
};

export const getDeprovisionTypeById = async (
  id: number
): Promise<DeprovisionTypeDTO | null> => {
  const { data: deprovisionType } = await riseApi.get(`DeprovisionTypes/${id}`);

  return schemaDeprovisionTypeDTO.nullable().validateSync(deprovisionType);
};
