import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { AdditiveDTO } from "../../../../../../../../../../shared/specific/DTOs/additive/AdditiveDTO";
import { useProjectContext } from "../../../../../shared/react/contexts/ProjectContext";
import { StyledFormContent } from "./index.styles";

export const FormContent = ({
  internalModalData: additive,
}: FormContentProps<AdditiveDTO>) => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  if (!additive) return null;

  return (
    <StyledFormContent>
      <TextField
        label={t("general.keywords.fields.value")}
        value={formatCurrency({
          value: additive.amount,
          currencySymbol: project.subsidiary.currencySymbol,
        })}
        disabled
      />
      <TextField
        label={t("general.keywords.fields.justification")}
        value={additive.justification || "-"}
        disabled
      />
      {additive.anticipationJustification && (
        <TextField
          label={t("additives.keywords.fields.anticipationJustification")}
          value={additive.anticipationJustification || "-"}
          disabled
        />
      )}
    </StyledFormContent>
  );
};
