import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { useTranslation } from "react-i18next";
import {
  SimpleIconButton,
  SimpleIconButtonProps,
} from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { SomePartial } from "../../../../../../../../shared/common/types/core/typescript.types";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const ChangeDealTypeButton = ({
  tooltipText = "",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={ChangeCircleIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
