import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../../common/classes/data/date/YearMonth";

export interface YearMonthValueDTO {
  yearMonth: YearMonth;
  value: number;
}

export const schemaYearMonthValueDTO = yup.object({
  yearMonth: schemaYearMonth.defined(),
  value: yup.number().defined(),
});
