import * as yup from "yup";

import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { FilterFormValues } from "./types/filter.types";
import { FilterForm } from "./components/Form";
import { CalculatedFeeFiltersDTO } from "../../../../../../../../shared/specific/DTOs/calculatedFee/CalculateddFeeFiltersDTO";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";

export const useFilterForm = () => {
  const { t, i18n } = useTranslation();

  const formikRef = useRef<FormikProps<FilterFormValues>>(null);
  const [filters, setFilters] = useState<CalculatedFeeFiltersDTO | null>(null);

  const formikConfig = useMemo(() => {
    const dateNow = new Date();
    const initialValues: FilterFormValues = {
      yearMonth: dateNow,
      subsidiary: null,
      costCenter: null,
    };

    const validationSchema = yup.object({
      yearMonth: yup
        .date()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      costCenter: yup.object().nullable(),
    });

    return {
      initialValues,
      validationSchema,
    };
  }, [i18n]);

  const onSubmit = (props: FilterFormValues) => {
    if (!props.yearMonth) {
      throw new Error("YearMonth is required");
    }

    if (!props.subsidiary) {
      throw new Error("Subsidiary is required");
    }

    setFilters({
      yearMonth: YearMonth.createFromDate(props.yearMonth),
      idSubsidiary: props.subsidiary.id,
      idCostCenter: props.costCenter?.id,
    });
  };

  const formComponent = (
    <Formik
      innerRef={formikRef}
      initialValues={formikConfig.initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={onSubmit}
      validationSchema={formikConfig.validationSchema}
    >
      {(props) => <FilterForm {...props} setFilters={setFilters} />}
    </Formik>
  );

  return {
    formComponent,
    filters,
  };
};
