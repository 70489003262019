import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { expenseTypeToTranslationCodeMap } from "../../../../../../../shared/specific/maps/expenses/expenseType.maps";
import { ExpenseType } from "../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { getCostDistribution } from "../../../../../../../services/costDistribution/costDistribution.service";
import { CostDistributionCellValue } from "./shared/components/CostDistributionCellValue";
import { CostDistributionListFilterDTO } from "../../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionListFilterDTO";
import { useEditingForm } from "../useEditingForm";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "../useDeletionForm";

interface OwnProps {
  filters: CostDistributionListFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { DeletionButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("costDistribution.keywords.fields.groupCode"),
        width: 150,
      },
      {
        value: t("costDistribution.keywords.fields.segmentGroup"),
        width: 250,
      },
      {
        value: t("costDistribution.keywords.fields.subsidiary"),
      },
      {
        value: t("costDistribution.keywords.fields.type"),
        width: 80,
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Workforce]),
        width: 152,
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.SoftwareHardware]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Travels]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Infrastructure]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(
          expenseTypeToTranslationCodeMap[ExpenseType.ResellingProducts]
        ),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Training]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(
          expenseTypeToTranslationCodeMap[ExpenseType.ConsultantServices]
        ),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Refund]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: t(expenseTypeToTranslationCodeMap[ExpenseType.Other]),
        align: "center",
        justifyContent: "center",
        canSort: false,
      },
      {
        value: "",
        width: 100,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = [
          "customerTrigram",
          "segment.segmentName",
          "subsidiary.name",
        ];

        try {
          const {
            tableData: costDistributionData,
            page,
            totalCount,
          } = await getCostDistribution({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = costDistributionData.map(
            (costDistribution, index): ExternalEnhancedRow => ({
              id: index,
              cells: [
                costDistribution?.customerTrigram ||
                  t("costDistribution.keywords.fields.newCustomers"),
                costDistribution.segmentGroup.description,
                costDistribution.subsidiary.name,
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return <CostDistributionCellValue />;
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Workforce}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.SoftwareHardware}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Travels}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Infrastructure}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.ResellingProducts}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Training}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.ConsultantServices}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Refund}
                      />
                    );
                  },
                },
                {
                  justifyContent: "center",
                  paddingmode: "none",
                  CellRenderer: () => {
                    return (
                      <CostDistributionCellValue
                        costDistribution={costDistribution}
                        expenseType={ExpenseType.Other}
                      />
                    );
                  },
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.CostDistribution,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...costDistribution} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.CostDistribution,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeletionButtonContainer {...costDistribution} />
                      </Protected>
                    </>
                  ),
                  justifyContent: "center",
                  paddingmode: "none",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onTablePageChange,
    deletionModal,
    editingModal,
  };
};
