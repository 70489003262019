import { useField } from "formik";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAllocationsPeriod } from "./hooks/useAllocationsPeriod";
import { AllocationBatchFiller } from "./AllocationBatchFiller";
import { StyledAllocationsYearMonthsTable } from "./index.styles";
import { useTableData } from "./hooks/useTableData";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { AllocationCreationYearMonthsFormValues } from "../../shared/types/data/form/values.types";

export const AllocationYearMonthsTable = () => {
  const [
    { value: allocationYearMonths },
    ,
    { setValue: setAllocationYearMonths },
  ] = useField<AllocationCreationYearMonthsFormValues[]>(
    "allocationYearMonths"
  );
  const { i18n } = useTranslation();
  const { periodYearMonths } = useAllocationsPeriod();

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    if (!periodYearMonths) {
      setAllocationYearMonths([]);
      return;
    }

    const newAllocationYearMonths = periodYearMonths.map(
      (yearMonth): AllocationCreationYearMonthsFormValues => ({
        yearMonth,
        week1Percentage: "",
        week2Percentage: "",
        week3Percentage: "",
        week4Percentage: "",
        week5Percentage: "",
      })
    );

    setAllocationYearMonths(newAllocationYearMonths);
  }, [periodYearMonths]);

  const { headCells, singlePageLoader } = useTableData({ periodYearMonths });

  useEffectAfterRenders({
    effect: () => {
      enhancedTableRef.current?.reloadPage();
    },
    deps: [periodYearMonths, i18n.language],
    rendersBeforeEffect: 1,
  });

  if (allocationYearMonths.length === 0) return null;

  return (
    <StyledAllocationsYearMonthsTable>
      <AllocationBatchFiller periodYearMonths={periodYearMonths} />
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          stickyHeader: true,
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: singlePageLoader,
          },
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
    </StyledAllocationsYearMonthsTable>
  );
};
