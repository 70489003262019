import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { InputAdornment } from "@mui/material";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode, FormValues } from "../shared/types/form.types";
import { StyledForm } from "./index.styles";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { generalFieldsConstants } from "../../constants/fields.constants";
import { CustomerBalanceTypeAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customerBalance/CustomerBalanceTypeAutocompleteFormik";

type OwnProps = FormContentProps<FormValues> & {
  formMode: FormMode;
};

export const CommonCustomerBalanceForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  const isEdit = formMode === "edit";

  useEffect(() => {
    if (!isEdit) setFieldValue("segmentGroup", null);
  }, [values.costCenter?.code, isEdit]);

  return (
    <StyledForm>
      <YearMonthDatePickerFormik
        name="yearMonth"
        datePickerProps={{
          label: t("jobRates.keywords.general.startYearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
        textFieldProps={{ required: true, disabled: isSubmitting || isEdit }}
      />
      <CustomerCodesSearchAutocompleteFormik
        name="customerTrigram"
        textfieldProps={{
          label: t("costDistribution.keywords.fields.groupCode"),
          required: true,
        }}
        autocompleteProps={{
          disabled: isSubmitting || isEdit,
        }}
      />
      <CostCentersSearchAutocompleteFormik
        name="costCenter"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting || isEdit }}
        filters={{
          HasCostCenterParameter: true,
        }}
      />
      <CustomerBalanceTypeAutocompleteFormik
        name="type"
        textfieldProps={{
          required: true,
        }}
        autocompleteProps={{
          disabled: isSubmitting || isEdit,
        }}
      />
      <LocalizedNumberTextFieldFormik
        name="amount"
        scale={2}
        max={generalFieldsConstants.maxAmountValue}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("customerBalance.keywords.fields.amount"),
          required: true,
          disabled: isSubmitting,
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                {values.costCenter?.subsidiary.currencySymbol ?? ""}
              </InputAdornment>
            ),
          },
        }}
      />
    </StyledForm>
  );
};
