import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { InfoIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/InfoIconButton";
import { StyledModalHelperInfos } from "../index.style";

export const useWorksheetHelp = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: UseWorksheetHelpModalButtonContainer,
    contentModal: useWorksheetHelpModal,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("customerBalance.modal.data.title"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        const rules = t(
          "customerBalance.modal.data.information"
        ) as unknown as string[];
        const rulesAtencions = t(
          "customerBalance.modal.data.atencionInformation"
        ) as unknown as string[];
        return (
          <StyledModalHelperInfos>
            <ul>
              {rules.map((rule, index) => (
                <li key={index}>
                  <Trans i18nKey={rule} components={{ b: <b /> }} />
                </li>
              ))}
            </ul>
            <p>{t("customerBalance.modal.data.helpTipsAtencionTitle")}</p>
            <ul>
              {rulesAtencions.map((rule, index) => (
                <li key={index}>
                  <Trans i18nKey={rule} components={{ b: <b /> }} />
                </li>
              ))}
            </ul>
            <p>{t("customerBalance.modal.data.belowExample")}</p>
            <img src="/media/images/pages/customerBalance/customerBalance-upload-example.png" />
          </StyledModalHelperInfos>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick, buttonTooltip }) => (
        <InfoIconButton
          size="large"
          onClick={onClick}
          tooltipText={buttonTooltip}
        />
      ),
    },
  });
  return { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal };
};
