import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  CollaboratorManagementDTO,
  schemaCollaboratorManagementDTO,
} from "./CollaboratorManagementDTO";

export type PaginatedCollaboratorManagementDTO =
  PaginationResponse<CollaboratorManagementDTO>;

export const schemaPaginatedPaginatedCollaboratorManagementDTO =
  getSchemaPaginationResponse<CollaboratorManagementDTO>(
    schemaCollaboratorManagementDTO as yup.Schema<CollaboratorManagementDTO>
  );
