import { CircularProgress, styled } from "@mui/material";
import { css } from "@emotion/react";
import { ExtendedCircularProgressProps } from "./index.types";

const PureCircularProgress = ({
  color,
  colorSubVariant,
  ...rest
}: ExtendedCircularProgressProps) => <CircularProgress {...rest} />;

export const StyledCircularProgress = styled(PureCircularProgress)`
  .MuiCircularProgress-circle {
    ${({ theme, color, colorSubVariant = "main" }) => {
      if (!color || color === "inherit") return null;

      return css`
        color: ${theme.palette[color][colorSubVariant]};
      `;
    }}
  }
`;
