import { ProjectionFormValues } from "../../types/form.types";
import { getAdjustmentSumByMonth } from "./auxFunctions";
import { expenseGroupTotalValuesFunctions } from "./expenseGroupTotalValuesFunctions";

function getTotaPddAmount(formValues: ProjectionFormValues) {
  const totalPddPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthRevenue = formValues.adjustmentLines
      ? getAdjustmentSumByMonth(m, formValues.adjustmentLines)
      : 0;
    totalPddPerMonth.push(adjustmentMonthRevenue);
  });

  return totalPddPerMonth;
}

function getTotalNetRevenue(formValues: ProjectionFormValues) {
  const totalNetRevenuePerMonth: number[] = [];
  const totalGrossRevenuePerMonth: number[] =
    expenseGroupTotalValuesFunctions.getTotalGrossRevenue(formValues);
  const totalGrossTaxPerMonth: number[] =
    expenseGroupTotalValuesFunctions.getTotalTax(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalNetRevenuePerMonth.push(
      totalGrossRevenuePerMonth[m] + totalGrossTaxPerMonth[m]
    );
  });

  return totalNetRevenuePerMonth;
}

function getSalesMarginAmount(formValues: ProjectionFormValues) {
  const totalSalesMarginAmountPerMonth: number[] = [];
  const totalNetRevenuePerMonth: number[] = getTotalNetRevenue(formValues);
  const totalSalesCostPerMonth: number[] =
    expenseGroupTotalValuesFunctions.getTotalSalesCost(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalSalesMarginAmountPerMonth.push(
      totalNetRevenuePerMonth[m] + totalSalesCostPerMonth[m]
    );
  });

  return totalSalesMarginAmountPerMonth;
}

function getSalesMarginPercentage(formValues: ProjectionFormValues) {
  const totalSalesMarginPercentagePerMonth: number[] = [];
  const totalSalesMarginAmountPerMonth = getSalesMarginAmount(formValues);
  const totalNetRevenuePerMonth = getTotalNetRevenue(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalSalesMarginPercentagePerMonth.push(
      totalNetRevenuePerMonth[m] !== 0
        ? (totalSalesMarginAmountPerMonth[m] / totalNetRevenuePerMonth[m]) * 100
        : 0
    );
  });

  return totalSalesMarginPercentagePerMonth;
}

function getTotalOperationMarginAmount(formValues: ProjectionFormValues) {
  const totalOperationMarginAmountPerMonth: number[] = [];
  const totalSalesMarginAmountPerMonth = getSalesMarginAmount(formValues);
  const totalDolPerMonth =
    expenseGroupTotalValuesFunctions.getTotalDolAmount(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalOperationMarginAmountPerMonth.push(
      totalSalesMarginAmountPerMonth[m] + totalDolPerMonth[m]
    );
  });

  return totalOperationMarginAmountPerMonth;
}

function getTotalPadAmount(formValues: ProjectionFormValues) {
  const totalPadAmountPerMonth: number[] = [];
  const totalSgAndAmountPerMonth =
    expenseGroupTotalValuesFunctions.getTotalSgAndAAmount(formValues);
  const totalPddPerMonth = getTotaPddAmount(formValues);
  const totalOperationalMarginAmountPerMonth =
    getTotalOperationMarginAmount(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalPadAmountPerMonth.push(
      totalSgAndAmountPerMonth[m] +
        totalPddPerMonth[m] +
        totalOperationalMarginAmountPerMonth[m]
    );
  });

  return totalPadAmountPerMonth;
}

function getTotalPadPercentage(formValues: ProjectionFormValues) {
  const totalPadPercentagePerMonth: number[] = [];
  const totalPadAmountPerMonth = getTotalPadAmount(formValues);
  const totalNetRevenuePerMonth: number[] = getTotalNetRevenue(formValues);

  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    totalPadPercentagePerMonth.push(
      totalNetRevenuePerMonth[m] !== 0
        ? (totalPadAmountPerMonth[m] / totalNetRevenuePerMonth[m]) * 100
        : 0
    );
  });

  return totalPadPercentagePerMonth;
}

export const pddPageFunctions = {
  getTotaPddAmount,
  getTotalNetRevenue,
  getSalesMarginAmount,
  getSalesMarginPercentage,
  getTotalOperationMarginAmount,
  getTotalPadAmount,
  getTotalPadPercentage,
};
