import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContextProvider";
import { StyledLine } from "../../shared/index.styles";
import {
  StyledAreaListLanguages,
  StyledAreaTextFields,
  StyledModalIcons,
  StyledTextArea,
  StyledTitleContainer,
  StyledComponentAreas,
} from "./index.styles";
import { userLanguageTranslationCodeMap } from "../../../../../../../shared/specific/maps/userLanguage/userLanguageTranslationCodeMap";
import { useEditingForm } from "./hooks/useEditingForm";
import { useLevelInformation } from "./hooks/useLevelInformation";

export const LanguageArea = () => {
  const { t, i18n } = useTranslation();
  const { userCollaboratorProfile, mode } = useContext(ProfileContext);
  const isReadOnly = mode === "viewing";

  const { editingModal, EditingButtonContainer } = useEditingForm();
  const { InformationLevelButtonContainer, informationLevelModalHelper } =
    useLevelInformation();

  if (!userCollaboratorProfile) return <div> </div>;
  return (
    <StyledComponentAreas>
      <Typography variant="h6">
        <StyledTitleContainer>
          {t("collaboratorProfile.keywords.fields.languages")}
          {!isReadOnly && (
            <StyledModalIcons>
              <EditingButtonContainer
                {...userCollaboratorProfile.userLanguages}
              />
              <InformationLevelButtonContainer />
            </StyledModalIcons>
          )}
        </StyledTitleContainer>
        <StyledLine />
      </Typography>
      <StyledAreaListLanguages>
        {userCollaboratorProfile.userLanguages.length > 0 ? (
          userCollaboratorProfile.userLanguages.map((userLanguage, i) => (
            <StyledAreaTextFields key={userLanguage.skill.id}>
              <StyledTextArea
                label={t("collaboratorProfile.keywords.fields.language")}
                value={
                  userLanguage.skill.skillTranslation.find(
                    (y) => y.idLanguage === i18n.language
                  )?.name ?? userLanguage.skill?.name
                }
                InputProps={{
                  readOnly: true,
                }}
              />
              <StyledTextArea
                label={t("collaboratorProfile.keywords.fields.level")}
                value={t(userLanguageTranslationCodeMap[userLanguage.level])}
                InputProps={{
                  readOnly: true,
                }}
              />
            </StyledAreaTextFields>
          ))
        ) : (
          <Typography>
            {t("collaboratorProfile.keywords.warnings.dontHaveLangauges")}
          </Typography>
        )}
      </StyledAreaListLanguages>
      {editingModal}
      {informationLevelModalHelper}
    </StyledComponentAreas>
  );
};
