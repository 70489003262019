import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  CostDistributionDTO,
  schemaCostDistributionDTO,
} from "./CostDistributionDTO";

export type PaginatedCostDistributionDTO =
  PaginationResponse<CostDistributionDTO>;

export const schemaPaginatedCostDistributionDTO =
  getSchemaPaginationResponse<CostDistributionDTO>(schemaCostDistributionDTO);
