import { useState } from "react";
import i18next from "i18next";
import { ButtonBase, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Trans, useTranslation } from "react-i18next";
import { PageCardButtonContainer } from "../../../../shared/common/react/components/navigation/PageCardButton/index.styles";
import { GenerateDreProjection } from "../../../../services/DreQueue/dreQueue.service";
import { notifySuccess } from "../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { LoadingBackdrop } from "../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

export const DreProjectionCardComponent = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onclick = async () => {
    setIsLoading(true);
    try {
      const version = await GenerateDreProjection();

      i18next.t("projection.success.projectionCreated");

      notifySuccess(
        <Trans
          i18nKey="projection.success.projectionCreated"
          values={{ version: version ?? "" }}
          components={{ italic: <em /> }}
        />
      );
    } catch (error) {
      console.error(error);
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage:
          "projection.errors.data.fetch.failedToGenereteDreProjection",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ButtonBase onClick={onclick} disabled={isLoading}>
      <PageCardButtonContainer>
        <AddAPhotoIcon color={isLoading ? "disabled" : "primary"} />
        <Typography>
          {t("projection.keywords.fields.projectionsPhotography")}
        </Typography>
      </PageCardButtonContainer>
      <LoadingBackdrop open={isLoading} />
    </ButtonBase>
  );
};
