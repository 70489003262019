import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { createActivities } from "../../../../../../../../../../../services/activities/activities.service";
import { notifySuccess } from "../../../../../../../../../../../services/applicationState/toast.service";
import { useActionButtonDialog } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../../../shared/react/contexts/ProjectContext";
import { InternalModalData } from "./shared/types/general.types";
import { ViewCreateManyForm } from "./ViewCreateManyForm";

export const useViewCreateManyForm = () => {
  const { t } = useTranslation();

  const { reloadProject } = useProjectContext();

  const {
    contentModal: viewCreateManyModal,
    openModal: openViewCreateManyModal,
  } = useActionButtonDialog<InternalModalData>({
    button: {},
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("activities.modal.data.createMany.titlePreview"),
      ModalContentMemo: ({ internalModalData, ...rest }) =>
        internalModalData && (
          <ViewCreateManyForm
            totalActivitiesAmount={internalModalData.totalActivitiesAmount}
            activityCreateManyDTO={internalModalData.activityCreateManyDTO}
            {...rest}
          />
        ),
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.save"),
      onConfirm: async ({ closeModal, internalModalData }) => {
        if (!internalModalData)
          throw new Error("'internalModalData' cannot be null.");

        try {
          await createActivities(internalModalData.activityCreateManyDTO);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadProject({ shouldResetValue: true });
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "activities.errors.data.createMany.failedToCreateActivities",
          });
        }
      },
    },
  });

  return {
    viewCreateManyModal,
    openViewCreateManyModal,
  };
};
