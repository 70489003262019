import { Typography } from "@mui/material";
import { FieldArray } from "formik";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
} from "../../../../../../../../common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../../common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useRowsTypeGeneralPermissions } from "../shared/hooks/useRowsTypeGeneralPermissions";
import { useRowsTypeReportPermissions } from "../shared/hooks/useRowsTypeReportPermissions";
import { useRowsTypeSpecificPermissions } from "../shared/hooks/useRowsTypeSpecificPermissions";
import {
  AccessManagementListPermissions,
  AccessPermission,
} from "../shared/types/general.types";
import { useModalUserGroups } from "./hooks/useModalUserGroups";
import {
  StyledModalMainContainer,
  StyledModalSubContainers,
  StyledSubtitles,
} from "./index.styles";

interface OwnProps {
  listPermissions: AccessManagementListPermissions[] | null;
  permissionData: AccessPermission | null;
  isUserPermissionModal?: boolean;
}

export const AccessManagementTable = ({
  listPermissions,
  permissionData,
  isUserPermissionModal,
}: OwnProps) => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { generateRowsTypeGeneralPermissions } = useRowsTypeGeneralPermissions({
    listPermissions,
  });

  const { generateRowsTypeSpecificPermissions } =
    useRowsTypeSpecificPermissions({ listPermissions });

  const { generateRowsTypeReportPermissions } = useRowsTypeReportPermissions({
    listPermissions,
  });

  const { userGroupsModal, UserGroupsModalButtonContainer } =
    useModalUserGroups();

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [
      generateRowsTypeGeneralPermissions,
      generateRowsTypeSpecificPermissions,
      t,
    ],
    rendersBeforeEffect: 1,
  });

  const headCellsGeneralPermissions = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.functionality"),
        canSort: false,
      },
      {
        value: t("general.keywords.fields.reading"),
        canSort: false,
      },
      {
        value: t("general.keywords.fields.inclusion"),
        canSort: false,
      },
      {
        value: t("general.keywords.fields.edition"),
        canSort: false,
      },
      {
        value: t("general.keywords.fields.exclusion"),
        canSort: false,
      },
      {
        value: "",
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const headCellsSpecificPermissions = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.functionality"),
      {
        value: "",
        canSort: false,
        width: 70,
      },
    ];

    return headCells;
  }, [t]);

  return (
    <FieldArray
      name="permissions"
      render={(arrayHelpers) => (
        <StyledModalMainContainer>
          <>
            <Typography variant="h4" component="div">
              {permissionData?.name ?? ""}
              {isUserPermissionModal && (
                <UserGroupsModalButtonContainer
                  collaboratorId={permissionData?.id ?? 0}
                  collaboratorName={permissionData?.name ?? ""}
                />
              )}
            </Typography>
            <StyledSubtitles>
              <Typography variant="h5" component="div">
                {t("permissionTypes.keywords.fields.generalPermissions")}
              </Typography>
            </StyledSubtitles>
            <EnhancedTable
              ref={enhancedTableRef}
              headCells={headCellsGeneralPermissions}
              uiSettings={{
                hasDynamicTableHeight: true,
                stickyHeader: true,
                maxTableHeight: 265,
              }}
              rows={generateRowsTypeGeneralPermissions(arrayHelpers)}
              orderDefaults={{
                defaultOrder: "asc",
              }}
            />
          </>
          <StyledModalSubContainers>
            <div>
              <StyledSubtitles>
                <Typography variant="h5" component="div">
                  {t("permissionTypes.keywords.fields.specificPermissions")}
                </Typography>
              </StyledSubtitles>
              <EnhancedTable
                ref={enhancedTableRef}
                headCells={headCellsSpecificPermissions}
                uiSettings={{
                  hasDynamicTableHeight: true,
                  stickyHeader: true,
                  maxTableHeight: 180,
                  minTableWidth: 450,
                }}
                rows={generateRowsTypeSpecificPermissions(arrayHelpers)}
                orderDefaults={{
                  defaultOrder: "asc",
                }}
              />
            </div>
            <div>
              <StyledSubtitles>
                <Typography variant="h5" component="div">
                  {t("reports.pages.main.title")}
                </Typography>
              </StyledSubtitles>
              <EnhancedTable
                ref={enhancedTableRef}
                headCells={headCellsSpecificPermissions}
                uiSettings={{
                  hasDynamicTableHeight: true,
                  stickyHeader: true,
                  maxTableHeight: 180,
                  minTableWidth: 450,
                }}
                rows={generateRowsTypeReportPermissions(arrayHelpers)}
                orderDefaults={{
                  defaultOrder: "asc",
                }}
              />
            </div>
          </StyledModalSubContainers>
          {userGroupsModal}
        </StyledModalMainContainer>
      )}
    />
  );
};
