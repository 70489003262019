import { createContext } from "react";

export interface InvoicesSuperUserContextValues {
  canEditAllInvoices: boolean;
  canEditAllInvoicesButton: JSX.Element | null;
}

export const InvoicesSuperUserContext =
  createContext<InvoicesSuperUserContextValues>({
    canEditAllInvoices: false,
    canEditAllInvoicesButton: null,
  });
