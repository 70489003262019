import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCreationEditingFormikConfig } from "../shared/hooks/validation/useCreationEditingFormikConfig";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DashboardGroupsFormValues } from "../shared/types/DashboardGroupsFormValues";
import { DashboardGroupDTO } from "../../../../../shared/specific/DTOs/dashboardGroups/DashboardGroupDTO";
import { EditFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getDashboardGroupById,
  updateDashboardGroup,
} from "../../../../../services/dashboardGroups/dashboardGroups.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { DashboardGroupsCommomForm } from "../shared/components/DashboardGroupsCommomForm";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { DashboardGroupUpdateDTO } from "../../../../../shared/specific/DTOs/dashboardGroups/DashboardGroupUpdateDTO";
import {
  formatFromTranslatableFieldValues,
  formatToTranslatableFieldValues,
} from "../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import { Language } from "../../../../../shared/common/enums/locale/Language.enum";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const formikConfig = useCreationEditingFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      DashboardGroupsFormValues,
      DashboardGroupDTO,
      DashboardGroupDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: dashboardGroup }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{
              name:
                dashboardGroup?.dashboardGroupTranslations.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? "",
            }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: dashboardGroup,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const dashboardGroupData = await getDashboardGroupById(
              dashboardGroup.id
            );

            if (!dashboardGroupData)
              return setModalError(
                i18next.t(
                  "dashboardGroups.errors.data.general.dashboardGroupDoesNotExist"
                )
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(dashboardGroupData);
            setFormValues({
              nameTranslations: formatToTranslatableFieldValues({
                source: dashboardGroupData.dashboardGroupTranslations,
                convertItem: ({ idLanguage, name }) => ({
                  language: idLanguage,
                  value: name ?? "",
                }),
              }),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "dashboardGroups.errors.data.fetch.failedToFetchdashboardGroup",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: DashboardGroupsCommomForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: dashboardGroup,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          try {
            const updatedDashboardGroups: DashboardGroupUpdateDTO = {
              id: dashboardGroup?.id || 0,
              version: dashboardGroup?.version || "",
              dashboardGroupTranslations: formatFromTranslatableFieldValues({
                source: formValues?.nameTranslations,
                convertItem: ({ language, value }) => {
                  if (language === Language.ptBR) {
                    return {
                      idLanguage: language,
                      name: value,
                      id:
                        dashboardGroup?.dashboardGroupTranslations.find(
                          (x) => x.idLanguage === Language.ptBR
                        )?.id || 0,
                    };
                  }
                  return {
                    idLanguage: language,
                    name: value,
                    id:
                      dashboardGroup?.dashboardGroupTranslations.find(
                        (x) => x.idLanguage === Language.enUS
                      )?.id || 0,
                  };
                },
              }),
            };

            await updateDashboardGroup(updatedDashboardGroups);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            notifyError(t("dashboardGroups.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
