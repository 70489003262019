import { styled } from "@mui/material";

export const StyledCenteredInfo = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledFootnote = styled("div")`
  ${({ theme }) => theme.typography.body2};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledRowHeader = styled("div")`
  width: 100%;
  text-align: right;
  white-space: nowrap;

  & > :first-child {
    font-weight: 500;
  }
`;
