import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmployeeAllocationType } from "../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { StyledTypeCell } from "./index.styles";

interface OwnProps {
  allocationType: EmployeeAllocationType;
}

export const AllocationTypeCell = ({ allocationType }: OwnProps) => {
  const { t } = useTranslation();

  const allocationTypeFullText = useMemo(() => {
    return t(employeeAllocationTypeToTranslationCodeMap[allocationType]);
  }, [t, allocationType]);

  const allocaitonTypeFirstLetter = useMemo(() => {
    return allocationTypeFullText.slice(0, 1);
  }, [allocationTypeFullText]);

  return (
    <StyledTypeCell title={allocationTypeFullText} placement="top">
      <span>{allocaitonTypeFirstLetter}</span>
    </StyledTypeCell>
  );
};
