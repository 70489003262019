import { TabPanel } from "@mui/lab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyledTabPanelContent } from "./index.styles";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { TabCode } from "../../shared/types/TabCode.enums";
import { TypeOfFeeTab } from "../../tabs/TypeOfFeeTab";
import { SummaryTab } from "../../tabs/SummaryTab";
import { PeriodTab } from "../../tabs/PeriodTab";

export const useTabPanels = () => {
  const { t } = useTranslation();

  const tabPanels = useMemo(() => {
    const tabPanelsInfo = [
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.FeeTypes,
              level: PermissionLevel.Read,
            }}
          >
            <TypeOfFeeTab />
          </Protected>
        ),
        tabCode: TabCode.TypeOfFee,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.FeePeriod,
              level: PermissionLevel.Read,
            }}
          >
            <PeriodTab />
          </Protected>
        ),
        tabCode: TabCode.Period,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.FeeSummary,
              level: PermissionLevel.Read,
            }}
          >
            <SummaryTab />
          </Protected>
        ),
        tabCode: TabCode.Summary,
      },
    ];

    return tabPanelsInfo.map(({ component, tabCode }) => (
      <TabPanel value={tabCode} key={tabCode}>
        <StyledTabPanelContent>{component}</StyledTabPanelContent>
      </TabPanel>
    ));
  }, [t]);

  return tabPanels;
};
