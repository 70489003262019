import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";
import { GetWorkingDaysYearMonth } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { AllocationYearMonthsFormValues } from "../../../types/form.types";

export function CalculateTotalMonthPercentage(
  allocationYearMonth: AllocationYearMonthsFormValues[]
): string {
  const proporcionWeeksMonth = GetWorkingDaysYearMonth(
    YearMonth.createFromDate(allocationYearMonth[0].yearMonth)
  );

  const totalDaysWeek1 = proporcionWeeksMonth.weeks[1].totalDays ?? 0;
  const totalDaysWeek2 = proporcionWeeksMonth.weeks[2].totalDays ?? 0;
  const totalDaysWeek3 = proporcionWeeksMonth.weeks[3].totalDays ?? 0;
  const totalDaysWeek4 = proporcionWeeksMonth.weeks[4].totalDays ?? 0;
  const totalDaysWeek5 =
    proporcionWeeksMonth.weeks[5] === undefined
      ? 0
      : proporcionWeeksMonth.weeks[5].totalDays;

  const week1PercentageAmount =
    (allocationYearMonth
      .map((x) => parseLocaleNumber(x.week1Percentage) || 0)
      .reduce((a, b) => a + b, 0) *
      totalDaysWeek1) /
    proporcionWeeksMonth.workingDays;

  const week2PercentageAmount =
    (allocationYearMonth
      .map((x) => parseLocaleNumber(x.week2Percentage) || 0)
      .reduce((a, b) => a + b, 0) *
      totalDaysWeek2) /
    proporcionWeeksMonth.workingDays;

  const week3PercentageAmount =
    (allocationYearMonth
      .map((x) => parseLocaleNumber(x.week3Percentage) || 0)
      .reduce((a, b) => a + b, 0) *
      totalDaysWeek3) /
    proporcionWeeksMonth.workingDays;

  const week4PercentageAmount =
    (allocationYearMonth
      .map((x) => parseLocaleNumber(x.week4Percentage) || 0)
      .reduce((a, b) => a + b, 0) *
      totalDaysWeek4) /
    proporcionWeeksMonth.workingDays;

  const week5PercentageAmount =
    (allocationYearMonth
      .map((x) => parseLocaleNumber(x.week5Percentage) || 0)
      .reduce((a, b) => a + b, 0) *
      totalDaysWeek5) /
    proporcionWeeksMonth.workingDays;

  return formatNumber(
    week1PercentageAmount +
      week2PercentageAmount +
      week3PercentageAmount +
      week4PercentageAmount +
      week5PercentageAmount,
    { fractionDigits: 2 }
  );
}
