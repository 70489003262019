import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getProjectBaselines } from "../../../../../../services/projects/projectBaselines.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useViewingModal } from "./useViewingModal";
import { projectBaselineTypeTranslationCodeMap } from "../../../../../../shared/specific/maps/projectBaseline/projectBaselineTypeTranslationCodeMap";

interface OwnProps {
  idProject: number;
}

export const useTableData = ({ idProject }: OwnProps) => {
  const { t } = useTranslation();
  const { ViewingModalButton, viewingModal } = useViewingModal();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.version"),
      t("general.keywords.fields.type"),
      t("projectBaselines.keywords.fields.validatedBy"),
      {
        value: "",
        width: 72,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const singlePageLoader: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const projectBaselines = await getProjectBaselines({
          filters: { idProject },
        });

        const rows = projectBaselines.map(
          (projectBaseline): ExternalEnhancedRow => {
            const row: ExternalEnhancedRow = {
              id: projectBaseline.id,
              cells: [
                projectBaseline.version,
                {
                  value: projectBaseline.type,
                  CellRenderer: () => (
                    <>
                      {projectBaseline?.type
                        ? t(
                            projectBaselineTypeTranslationCodeMap[
                              projectBaseline.type
                            ]
                          )
                        : "-"}
                    </>
                  ),
                },
                projectBaseline.validatedBy,
                {
                  CellRenderer: () => (
                    <ViewingModalButton {...projectBaseline} />
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            };

            return row;
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [idProject]);

  return {
    headCells,
    singlePageLoader,
    viewingModal,
  };
};
