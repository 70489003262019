import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { useFormikConfig } from "../../shared/react/shared/hooks/data/validation/useFormikConfig";
import { WaccDTO } from "../../../../../../../shared/specific/DTOs/wacc/WaccDTO";
import { FormValues } from "../../shared/react/shared/types/form.types";
import {
  getWaccById,
  updateWacc,
} from "../../../../../../../services/wacc/wacc.service";
import { WaccUpdateDTO } from "../../../../../../../shared/specific/DTOs/wacc/WaccUpdateDTO";
import { CommonWaccForm } from "../../shared/react/CommonWaccForm";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, WaccDTO, WaccDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: ud }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: ud,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const waccData = await getWaccById(ud.id);
            if (!waccData)
              return setModalError(
                i18next.t("wacc.errors.data.general.waccDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(waccData);
            setFormValues({
              yearMonth: waccData.yearMonth.toDate(),
              plannedTax: formatNumber(waccData.plannedTax, {
                fractionDigits: 2,
              }),
              performedTax: formatNumber(waccData.performedTax ?? 0, {
                fractionDigits: 2,
              }),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "wacc.errors.data.fetch.failedToFetchWacc",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ ...rest }) => (
          <CommonWaccForm formMode="edit" {...rest} />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: ud,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!ud) throw new Error("Wacc cannot be null on submission.");

          if (!formValues.yearMonth || !formValues.plannedTax)
            throw new Error("Required values are missing.");

          try {
            const updatedData: WaccUpdateDTO = {
              id: ud.id,
              plannedTax: parseLocaleNumber(formValues.plannedTax),
              performedTax: formValues.performedTax
                ? parseLocaleNumber(formValues.performedTax)
                : 0,
              version: ud.version,
            };

            await updateWacc(updatedData);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            notifyError(t("wacc.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
