import { ProjectBaselineType } from "../../enums/projectBaseline/ProjectBaselineType.enum";

export const projectBaselineTypeTranslationCodeMap: {
  readonly [key in ProjectBaselineType]: string;
} = {
  [ProjectBaselineType.ProjectBaseline]:
    "projectBaselines.keywords.fields.projectBaseline",
  [ProjectBaselineType.VersionedProject]:
    "projectBaselines.keywords.fields.versionedProject",
};
