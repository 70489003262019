import { TableRow, styled } from "@mui/material";

export const StyledTableHeadButtons = styled("div")`
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledRedIcon = styled("div")`
  svg {
    color: red;
  }
`;

export const StyledValueWithNegativeAmount = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledNegativeValue = styled("span")`
  color: red;
`;

export const NegativeTableRow = styled(TableRow)`
  background-color: ${({ theme }) => theme.palette.error.light};

  &.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => theme.palette.error.light}dd;
  }

  .MuiTableCell-root {
    color: ${({ theme }) => theme.palette.error.contrastText};
  }

  .MuiIconButton-root {
    color: ${({ theme }) => theme.palette.error.contrastText};
  }
`;
