import * as yup from "yup";
import { riseApi } from "./connection";
import {
  MonthlyAllocationClosingDTO,
  schemaMonthlyAllocationClosingDTO,
} from "../../../shared/specific/DTOs/monthlyAllocationClosing/MonthlyAllocationClosingDTO";
import { MonthlyAllocationClosingCreateDTO } from "../../../shared/specific/DTOs/monthlyAllocationClosing/MonthlyAllocationClosingCreateDTO";

export const getMonthlyAllocationClosing = async (): Promise<
  MonthlyAllocationClosingDTO[]
> => {
  const { data: monthlyAllocationClosing } = await riseApi.get(
    "MonthlyAllocationClosing"
  );

  return yup
    .array(schemaMonthlyAllocationClosingDTO)
    .defined()
    .validateSync(monthlyAllocationClosing);
};

export const createMonthlyAllocationClosing = async (
  monthlyAllocationClosing: MonthlyAllocationClosingCreateDTO
) => {
  await riseApi.post("MonthlyAllocationClosing", monthlyAllocationClosing);
};

export const deleteMonthlyAllocationClosing = async (
  id: number,
  version: string
) => {
  await riseApi.delete(`MonthlyAllocationClosing/${id}/${version}`);
};
