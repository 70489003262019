import * as yup from "yup";
import { useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControlLabel } from "@mui/material";

import { FilterValues } from "./shared/types/filter.types";
import {
  UseFormikModalButtonRef,
  useFormikModalButton,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { FeeFilterDTO } from "../../../../../../../../shared/specific/DTOs/fee/FeeFilterDTO";
import { CheckboxFormik } from "../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      onlyActive: true,
    };

    const validationSchema = yup.object();

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
      modalMode: "dialog",
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) => {
        const filters: FeeFilterDTO = {
          onlyActive: formValues.onlyActive,
        };
        return filters;
      },
      FormContentMemo: () => (
        <FormControlLabel
          label={t("general.keywords.fields.onlyActivated")}
          control={<CheckboxFormik name="onlyActive" size="small" />}
        />
      ),
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
