import * as yup from "yup";
import { useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { FilterValues } from "./shared/types/filter.types";
import { SkillFilterForm } from "./SkillFilterForm";
import {
  UseFormikModalButtonRef,
  useFormikModalButton,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { SkillFilterDTO } from "../../../../../../../../shared/specific/DTOs/skill/filters/SkillFilterDTO";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      name: "",
      skillCategory: null,
      discipline: null,
      onlyActivated: true,
    };

    const validationSchema = yup.object({
      name: yup.string(),
      discipline: yup.object().nullable(),
      skillCategory: yup.object().nullable(),
      deactivatedDate: yup.string(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) => {
        const formattedFilter: SkillFilterDTO = {
          name: formValues.name || " ",
          onlyActivated: formValues.onlyActivated,
          idSkillCategory: formValues.skillCategory?.id ?? undefined,
          idDiscipline: formValues.discipline?.id ?? undefined,
        };
        return formattedFilter;
      },
      FormContentMemo: SkillFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
