import { Box, TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useMemo } from "react";
import {
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "../../../../../shared/styles/multipleSelection.styles";
import { AutocompleteInfoBubble } from "../../../../../general/accessories/AutocompleteInfoBubble";
import { ProjectStatus } from "../../../../../../../../enums/projects/ProjectStatus.enum";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../../common/types/data/form/autocomplete.types";
import { statusToTranslationCodeMap } from "../../../../../../../../maps/projects/status.maps";
import { ProjectStatusOption } from "../../../../../../../../../../Router/pages/authenticated/Projects/shared/types/data/form/fields/autocomplete/options/StatusOption.types";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      ProjectStatusOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
}

export const ProjectStatusMultipleAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    const filteredProjectStatusEnum = Object.values(ProjectStatus)
      .filter((x) => typeof x === "number")
      .map((x) => x as ProjectStatus);

    const data = filteredProjectStatusEnum.map(
      (x): AutocompleteOption<ProjectStatus> => ({
        id: x,
        label: t(statusToTranslationCodeMap[x]),
      })
    );

    return data;
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value) => {
          return (
            <StyledName>
              <StyledIcon
                title={
                  <div>
                    {value.map((x, i) => (
                      <div key={i}>{x.label}</div>
                    ))}
                  </div>
                }
                arrow
              >
                <AutocompleteInfoBubble>
                  {value.length > 0 ? `+ ${value.length}` : ""}
                </AutocompleteInfoBubble>
              </StyledIcon>
            </StyledName>
          );
        },
        renderOption: (props, option) => {
          return (
            <Box
              component="li"
              {...props}
              key={option.id}
              style={{
                borderBottom: "solid #c0c0c0 1px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "5px",
              }}
            >
              <StyledMultipleSelectionContainer>
                {props["aria-selected"] ? (
                  <CheckBoxIcon color="primary" fontSize="small" />
                ) : (
                  <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />
                )}

                {option.label}
              </StyledMultipleSelectionContainer>
            </Box>
          );
        },
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.status"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
    />
  );
};
