import { addDays, addMonths, addWeeks } from "date-fns";
import { DateOnly } from "../../../../../../../../../../../../../shared/common/classes/data/date/DateOnly";
import { Week } from "../../../../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { parseLocaleNumber } from "../../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { ActivityCreateManyDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/activity/ActivityCreateManyDTO";
import { ActivityCreateManyItemDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/activity/ActivityCreateManyItemDTO";
import { ContractType } from "../../../../../../../../../../../../../shared/specific/enums/projects/ContractType.enum";
import { IntervalType } from "../../../enums/data/form/general.enums";
import { CreateManyActivitiesFormValues } from "../../../types/data/form/values.types";
import { generateActivityDescription } from "../general/generators.helpers";

export const generateActivityCreateManyItems = (
  idProject: number,
  projectContractType: ContractType,
  maxActivityAmount: number,
  formValues: CreateManyActivitiesFormValues
): ActivityCreateManyDTO => {
  if (formValues.initialScheduledDate === null)
    throw new Error("'initialScheduledDate' cannot be null.");

  const incrementDate = getDateIncrementer(
    formValues.intervalType.id,
    formValues.repeatDays,
    formValues.repeatWeeks,
    formValues.repeatMonths
  );
  const initialScheduledDate = getStartDate(
    formValues.intervalType.id,
    formValues.initialScheduledDate,
    formValues.weekDayToRepeat.id
  );

  const totalAmountDesired = parseLocaleNumber(
    formValues.generateActivitiesUntil
  );
  const activityAmountSize = parseLocaleNumber(formValues.activityAmountSize);

  const activityItems: ActivityCreateManyItemDTO[] = [];
  let totalAmountAdded = 0;
  for (let i = 0; totalAmountAdded < totalAmountDesired; i++) {
    const amount =
      totalAmountAdded + activityAmountSize > totalAmountDesired
        ? totalAmountDesired - totalAmountAdded
        : activityAmountSize;

    totalAmountAdded += amount;
    const scheduledDate = DateOnly.createFromDate(
      incrementDate(initialScheduledDate, i)
    );

    if (
      projectContractType !== ContractType.FixedAllocation ||
      activityAmountSize <= maxActivityAmount
    ) {
      activityItems.push(
        generateNewActivityItem(i, amount, scheduledDate, formValues)
      );
      continue;
    }

    let totalAddedThisActivity = 0;
    while (totalAddedThisActivity < amount) {
      const amountToAdd =
        totalAddedThisActivity + maxActivityAmount > amount
          ? amount - totalAddedThisActivity
          : maxActivityAmount;
      totalAddedThisActivity += amountToAdd;

      activityItems.push(
        generateNewActivityItem(i, amountToAdd, scheduledDate, formValues)
      );
    }
  }

  return {
    idProject,
    activities: activityItems,
  };
};

const generateNewActivityItem = (
  sequential: number,
  amount: number,
  scheduledDate: DateOnly,
  formValues: CreateManyActivitiesFormValues
): ActivityCreateManyItemDTO => {
  return {
    amount,
    description: generateActivityDescription({
      prefix: formValues.prefix,
      suffix: formValues.suffix,
      sequentialText: formValues.sequential?.id
        ? (sequential + 1).toString()
        : undefined,
      separatorBeforeSequential: formValues.sequential?.id
        ? formValues.separatorBeforeSequential
        : undefined,
      separatorAfterSequential: formValues.sequential?.id
        ? formValues.separatorAfterSequential
        : undefined,
    }),
    scheduledDate,
  };
};

const getStartDate = (
  intervalType: IntervalType,
  initialScheduledDate: Date,
  weekDayToRepeat: Week
) => {
  if (intervalType !== IntervalType.Week) return initialScheduledDate;

  const differenceToDesiredWeekDay =
    weekDayToRepeat - initialScheduledDate.getDay();

  if (differenceToDesiredWeekDay === 0) return initialScheduledDate;
  if (differenceToDesiredWeekDay > 0)
    return addDays(initialScheduledDate, differenceToDesiredWeekDay);

  const DAYS_IN_WEEK = 7;
  return addDays(
    initialScheduledDate,
    DAYS_IN_WEEK + differenceToDesiredWeekDay
  );
};

const getDateIncrementer = (
  internalType: IntervalType,
  repeatDays: string,
  repeatWeeks: string,
  repeatMonths: string
) => {
  const callbacks: {
    [key in IntervalType]: () => (date: Date, increment: number) => Date;
  } = {
    [IntervalType.Day]: () => {
      const repeatDaysNumber = parseLocaleNumber(repeatDays);
      return (date, increment) => addDays(date, increment * repeatDaysNumber);
    },
    [IntervalType.Month]: () => {
      const repeatMonthsNumber = parseLocaleNumber(repeatMonths);
      return (date, increment) =>
        addMonths(date, increment * repeatMonthsNumber);
    },
    [IntervalType.Week]: () => {
      const repeatWeeksNumber = parseLocaleNumber(repeatWeeks);
      return (date, increment) => addWeeks(date, increment * repeatWeeksNumber);
    },
  };

  return callbacks[internalType]();
};
