import * as yup from "yup";

export enum ProjectBaselineType {
  ProjectBaseline = 1,
  VersionedProject = 2,
}

export const schemaContractType = yup
  .mixed<ProjectBaselineType>()
  .oneOf(Object.values(ProjectBaselineType) as ProjectBaselineType[]);
