import { LoadingButton } from "@mui/lab";
import { AttachMoney as AttachMoneyIcon } from "@mui/icons-material";
import * as yup from "yup";
import { Button, TextField } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useMemo } from "react";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { InvoiceDTO } from "../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  UpdateToPaidOut,
  getInvoiceById,
} from "../../../../../../../services/invoices/invoices.service";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { InvoicesStatusEnum } from "../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { getLoggedUser } from "../../../../../../../services/user/user.service";
import { SimpleIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { StyledPaidOutFormContainer } from "./index.style";
import { UpdateToPaidOutDTO } from "../../../../../../../shared/specific/DTOs/invoice/updateProcesses/updateToPaidOutDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

export const usePaidOutForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useMemo(() => {
    const initialValues: { paymentDate: Date | null } = {
      paymentDate: null,
    };

    const validationSchema = yup.object({
      paymentDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    ContentButton: UpdatePaidOutButton,
    contentModal: updatePaidOutModal,
  } = useFormikModalButton<
    { paymentDate: Date | null },
    InvoiceDTO,
    InvoiceDTO
  >({
    modal: {
      modalMode: "dialog",
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalFormData: invoiceData }) => {
        return (
          <Trans
            i18nKey="invoice.modal.data.edit.confirmPayment"
            values={{ name: invoiceData?.invoiceNumber ?? "" }}
            components={{ italic: <em /> }}
          />
        );
      },
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            Icon={AttachMoneyIcon}
            onClick={onClick}
            color="success"
            tooltipText={t("invoice.actions.data.confirmPayment")}
          />
        );
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoiceData,
        setFormValues,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        try {
          const invoice = await getInvoiceById(invoiceData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!invoice)
            return setModalError(
              t("invoice.errors.data.general.invoiceDoesNotExist")
            );

          setInternalFormData(invoice);
          setFormValues({
            paymentDate: new Date(),
          });
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoice",
          });
          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <StyledPaidOutFormContainer>
          <TextField
            label={t("invoice.keywords.fields.invoiceMilestone")}
            value={internalFormData?.milestone ?? "-"}
            InputProps={{ readOnly: true }}
            required
            disabled
          />
          <TextField
            label={t("invoice.keywords.fields.invoiceNote")}
            value={internalFormData?.invoiceNote ?? "-"}
            InputProps={{ readOnly: true }}
            required
            disabled
          />
          <DatePickerFormik
            name="paymentDate"
            datePickerProps={{
              label: t("invoice.keywords.fields.paymentDate"),
              slotProps: {
                popper: {
                  placement: "left",
                },
              },
            }}
          />
        </StyledPaidOutFormContainer>
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        internalFormData: invoiceData,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const loggedUser = await getLoggedUser();

          const invoiceToUpdate: UpdateToPaidOutDTO = {
            invoiceIds: invoiceData?.invoiceGroup
              ? invoiceData?.invoiceGroup.map((x) => x.id)
              : [],
            status: InvoicesStatusEnum.PaidOut,
            responsibleLogin: loggedUser?.login ?? "",
            paymentDate: formValues?.paymentDate
              ? DateOnly.createFromDate(formValues?.paymentDate)
              : DateOnly.createFromNow(),
            version: invoiceData?.version,
          };
          await UpdateToPaidOut(invoiceToUpdate);
          reloadTablePage();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToUpdateInvoice",
          });
        } finally {
          setSubmitting(false);
          closeModal();
        }
      },
    },
  });

  return { UpdatePaidOutButton, updatePaidOutModal };
};
