import { useContext } from "react";
import { useMinDateOrProjectStart } from "../../../../../../../shared/react/hooks/data/fields/useMinDateOrProjectStart";
import { InvoicesSuperUserContext } from "../../../contexts/InvoicesSuperUserContext";

export const useMinPlannedBillingDate = () => {
  const { canEditAllInvoices } = useContext(InvoicesSuperUserContext);

  const {
    minDate: minPlannedBillingDate,
    minDateOnly: minPlannedBillingDateOnly,
    minDateErrorMessage: minPlannedBillingDateErrorMessage,
  } = useMinDateOrProjectStart({
    canEditBeforeStartMonth: canEditAllInvoices,
    cannotBeforeProjectStartErrorMessage:
      "invoice.errors.data.fields.plannedBillingDate.cannotBeforeProjectStart",
    cannotBeforeCurrentMonthErrorMessage:
      "invoice.errors.data.fields.plannedBillingDate.cannotBeforeCurrentMonth",
  });

  return {
    minPlannedBillingDate,
    minPlannedBillingDateOnly,
    minPlannedBillingDateErrorMessage,
  };
};
