import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { checkIsValidHourString } from "../../../../helpers/data/calendar/validators.helpers";
import { CommonFormValues } from "../../../../types/data/form/values.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): CommonFormValues => {
    return {
      yearMonth: YearMonth.createFromNow(),
      days: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      yearMonth: schemaYearMonth.defined(),
      days: yup.array(
        yup.object({
          day: yup.number().integer().defined(),
          time: yup
            .string()
            .required(t("general.errors.data.fields.general.required"))
            .test(
              "is-valid-hour",
              t("general.errors.data.fields.dates.invalidTime"),
              (value) => {
                if (!value) return false;
                return checkIsValidHourString(value);
              }
            ),
        })
      ),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
