import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { getTimesheetMonthAllEntries } from "../../../../../../../services/timesheet/timesheets.service";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TimesheetMonthDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { ViewIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { ViewingAllEntriesForm } from "./ViewingAllEntriesForm";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { OnClickContentButtonFunction } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/shared/types/functions.types";
import { TimesheetMonthDayAllEntriesDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDayAllEntriesDTO";

interface OwnProps {
  yearMonth: YearMonth;
}

interface InternalFormData {
  yearMonth: YearMonth;
  timesheetsMonth: TimesheetMonthDayAllEntriesDTO;
}

export const useViewingAllEntriesForm = ({ yearMonth }: OwnProps) => {
  const { t } = useTranslation();

  const onClickContentButton = useCallback<
    OnClickContentButtonFunction<InternalFormData>
  >(
    async ({
      setInternalModalData,
      checkInCurrentModalCount,
      setModalError,
      setIsLoadingModal,
      getOpenCloseModalCount,
    }) => {
      const modalCountOnOpen = getOpenCloseModalCount();

      setIsLoadingModal(true);
      try {
        const timesheetsMonth = await getTimesheetMonthAllEntries({
          yearMonth,
        });
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        setInternalModalData({
          yearMonth,
          timesheetsMonth,
        });
      } catch (error) {
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "timesheets.errors.data.fetch.failedToFetchTimesheetData",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [yearMonth]
  );

  const yearMonthString = useMemo(() => {
    return yearMonth.toPrettyString();
  }, [yearMonth]);

  const {
    contentButton: viewingAllEntriesButton,
    contentModal: viewingAllEntriesModal,
  } = useModalButton<InternalFormData, TimesheetMonthDTO>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("timesheets.modal.data.viewAllEntries.title", {
        yearMonth: yearMonthString,
      }),
      ModalContentMemo: ({ internalModalData }) =>
        internalModalData && (
          <ViewingAllEntriesForm
            yearMonth={yearMonth}
            timesheetsMonth={internalModalData.timesheetsMonth}
          />
        ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.cancel")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <ViewIconButton
            onClick={onClick}
            tooltipText={t("timesheets.actions.general.viewTotalMonth")}
          />
        );
      },
      onClickContentButton,
    },
  });

  return { viewingAllEntriesButton, viewingAllEntriesModal };
};
