import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface AllocationYearMonthDTO {
  id: number;
  yearMonth: YearMonth;
  percentage: number;
  week1Percentage: number | null;
  week2Percentage: number | null;
  week3Percentage: number | null;
  week4Percentage: number | null;
  week5Percentage: number | null;
  observation: string | null;
  allocationCost: number;
  version: string;
}

export const schemaAllocationYearMonthDTO = yup.object({
  id: yup.number().defined(),
  yearMonth: schemaYearMonth.defined(),
  percentage: yup.number().defined(),
  week1Percentage: yup.number().nullable().defined(),
  week2Percentage: yup.number().nullable().defined(),
  week3Percentage: yup.number().nullable().defined(),
  week4Percentage: yup.number().nullable().defined(),
  week5Percentage: yup.number().nullable().defined(),
  observation: yup.string().nullable().defined(),
  allocationCost: yup.number().defined(),
  version: yup.string().defined(),
});
