import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";
import { FilterValues } from "../../types/filter.types";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { SegmentGroupsAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentGroupsAutoCompleteFormik";
import { YearDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearDatePickerFormik";
import { useTableData } from "./hooks/useTableData";
import { CollapseTable } from "../../../../../../../../shared/common/react/components/table/CollapseTable";
import { CollapseTableRef } from "../../../../../../../../shared/common/react/components/table/CollapseTable/shared/types/propsRef.types";
import {
  StyledButtonContainer,
  StyledFormContainer,
  StyledPageContainer,
  StyledUploadsButtonsAndHelper,
  StyledUploadsButtonsContainer,
} from "./index.styles";
import { CustomersNamesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/pipedriveDeal/CustomersNamesAutocompleteFormik";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { sleep } from "../../../../../../../../shared/common/helpers/general.helpers";
import { useUploadButton } from "./hooks/useUploadProjectionAdjustment";
import { useUploadProjectionAdjustmentHelper } from "./hooks/useUploadProjectionAdjustment/useUploadProjectionAdjustmentHelper";
import { useDownloadDre } from "./hooks/useDownloadDre/useDownloadDre";
import { ProjectionFilterContext } from "../../../../shared/context/ProjectionFilterContext";
import { CarouselContext } from "../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";
import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  formikContenProps: FormikProps<FilterValues>;
}

export const ProjectionsFilterContainerPage = ({
  formikContenProps: { values, isSubmitting, resetForm },
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const searchButtonRef = useRef<HTMLButtonElement | null>(null);
  const collapseTableRef = useRef<CollapseTableRef>(null);
  const [finalFilters, setFinalFilters] = useState<FilterValues | null>();

  const { setFilterValues } = useContext(ProjectionFilterContext);
  const carouselContext = useContext(CarouselContext);

  const reloadTablePage = useCallback(
    () => collapseTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      searchButtonRef.current?.click();
    }, 800);
    return () => clearTimeout(timer);
  }, []);

  useEffectAfterRenders({
    effect: () => {
      setFilterValues(values);
      setFinalFilters(null);
    },
    deps: [values],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [finalFilters, i18n.language],
    rendersBeforeEffect: 1,
  });

  useEffect(() => {
    carouselContext.startCarousel({ carouselSize: 5 });
  }, []);

  useEffect(() => {
    carouselContext.setEarliestYearMonth(
      new YearMonth(0, values.year?.getFullYear() || 2024)
    );

    carouselContext.setLatestYearMonth(
      new YearMonth(11, values.year?.getFullYear() || 2024)
    );
  }, [values.year]);

  const {
    uploadModalProjectionAdjustment,
    uploadButtonLoadProjectionAdjustment,
  } = useUploadButton();

  const {
    ProjectionAdjustmentModalHelper,
    ProjectionAdjustmentHelperModalButtonContainer,
  } = useUploadProjectionAdjustmentHelper();

  const downloadDreSpreadsheetButton = useDownloadDre({
    idDivision: values.division?.id || null,
    idSegmentGroup: values.segmentGroup?.id || null,
    year: values.year?.getFullYear() || null,
  });

  const canSubmit = useCallback(() => {
    if (!values.subsidiary || !values.year) return false;

    if (values.segmentGroup && !values.division) return false;

    return true;
  }, [values]);

  const { headCells, onPageChange } = useTableData({
    reloadTablePage,
    filters: {
      clientAccount: finalFilters?.clientAccount ?? null,
      customerTrigram: finalFilters?.customerTrigram ?? null,
      idDivision: finalFilters?.division?.id,
      idGroupSegment: finalFilters?.segmentGroup?.id,
      idSubsidiary: finalFilters?.subsidiary?.id ?? 0,
      year: finalFilters?.year?.getFullYear() ?? 0,
    },
    currency: finalFilters?.subsidiary?.currencySymbol ?? "",
  });

  return (
    <StyledPageContainer>
      <StyledButtonContainer>
        <StyledUploadsButtonsContainer>
          {downloadDreSpreadsheetButton}
          <StyledUploadsButtonsAndHelper>
            {uploadButtonLoadProjectionAdjustment}
            <ProjectionAdjustmentHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
        </StyledUploadsButtonsContainer>
      </StyledButtonContainer>
      <StyledFormContainer>
        <SubsidiariesAutocompleteFormik
          name="subsidiary"
          textfieldProps={{
            label: t("general.keywords.fields.country"),
            required: true,
          }}
          autocompleteProps={{
            disabled: isSubmitting,
            style: {
              flexGrow: 1,
            },
          }}
        />
        <DivisionsAutocompleteFormik
          name="division"
          autocompleteProps={{
            disabled: isSubmitting,
            style: {
              flexGrow: 2,
            },
          }}
          textfieldProps={{
            style: {
              minWidth: "150px",
            },
          }}
        />
        <SegmentGroupsAutocompleteFormik
          name="segmentGroup"
          textfieldProps={{
            label: t("costDistribution.keywords.fields.segmentGroup"),
            style: {
              minWidth: "150px",
            },
          }}
          autocompleteProps={{
            disabled: isSubmitting,
            style: {
              flexGrow: 2,
            },
          }}
          filters={{
            idDivision: values.division?.id,
          }}
        />
        <YearDatePickerFormik
          name="year"
          datePickerProps={{
            label: t("general.keywords.dateTime.general.year"),
            slotProps: {
              popper: {
                placement: "right",
                style: {
                  zIndex: 1111111111111111,
                },
              },
            },
            views: ["year"],
          }}
          textFieldProps={{
            style: {
              maxWidth: "120px",
            },
            required: true,
          }}
        />
        <CustomerCodesSearchAutocompleteFormik
          name="customerTrigram"
          autocompleteProps={{
            disabled: isSubmitting,
            style: {
              flexGrow: 2,
            },
          }}
        />
        <CustomersNamesAutocompleteFormik
          name="clientAccount"
          autocompleteProps={{
            disabled: isSubmitting,
            style: {
              flexGrow: 2,
            },
          }}
        />
        <div>
          <Button
            onClick={async () => {
              resetForm();
              await sleep(200);
              reloadTablePage();
            }}
            disabled={isSubmitting}
          >
            {t("general.actions.general.clear")}
          </Button>
          <Button
            ref={searchButtonRef}
            onClick={() => {
              setFinalFilters(values);
            }}
            variant="contained"
            disabled={isSubmitting || (!isSubmitting && !canSubmit())}
          >
            {t("general.actions.general.search")}
          </Button>
        </div>
      </StyledFormContainer>
      <CollapseTable
        header={headCells}
        singlePageLoader={{
          fillPage: onPageChange,
        }}
        fixFirstColumn
        fixLastColumn
        ref={collapseTableRef}
      />
      {uploadModalProjectionAdjustment}
      {ProjectionAdjustmentModalHelper}
    </StyledPageContainer>
  );
};
