import { FilterList as FilterListIcon } from "@mui/icons-material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../types/core/typescript.types";
import {
  BadgeProps,
  SimpleIconButton,
  SimpleIconButtonProps,
} from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {
  showBadgeDot?: boolean;
}

export const FilterIconButton = ({
  showBadgeDot,
  badgeProps: externalBadgeProps,
  tooltipText = "general.actions.general.filter",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  const badgeProps = useMemo(() => {
    if (externalBadgeProps) return externalBadgeProps;

    if (!showBadgeDot) return null;

    const badgeProps: BadgeProps = {
      variant: "dot",
      color: "secondary",
    };

    return badgeProps;
  }, [showBadgeDot]);

  return (
    <SimpleIconButton
      Icon={FilterListIcon}
      tooltipText={t(tooltipText)}
      {...rest}
      badgeProps={badgeProps ?? undefined}
    />
  );
};
