import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface BrlParityDTO {
  id: number;
  yearMonth: YearMonth;
  currency: string;
  plannedValue: number;
  performedValue: number | null;
  lastBusinessDayValue: number | null;
  version: string;
}

export const schemaBrlParityDTO = yup.object({
  id: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined(),
  currency: yup.string().defined(),
  plannedValue: yup.number().defined(),
  performedValue: yup.number().nullable().defined(),
  lastBusinessDayValue: yup.number().nullable().defined(),
  version: yup.string().defined(),
});
