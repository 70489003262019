import { createContext } from "react";
import { InvoiceFilterValues } from "./InvoiceFilterValues";

export const InvoicesFilterContext = createContext<InvoiceFilterValues>({
  filterValues: {
    subsidiary: null,
    projectCode: "",
    status: [],
    responsible: null,
    limmitDate: null,
    division: null,
    requester: null,
    invoiceNumber: "",
  },
  setFilterValues: () => null,
});
