import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import {
  getCustomerById,
  updateCustomer,
} from "../../../../../../services/customers/customers.service";
import { schemaCountry } from "../../../../../../shared/common/enums/locale/Country.enum";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { CustomerDTO } from "../../../../../../shared/specific/DTOs/customers/CustomerDTO";
import { CustomerUpdateDTO } from "../../../../../../shared/specific/DTOs/customers/CustomerUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { getStateFormValues } from "../shared/helpers/data/fetchers.helpers";
import { cachedCountries } from "../shared/objects/data/cache/cachedFunctions.objects";
import { CreationEditingForm } from "../shared/react/components/content/CreationEditingForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { FormValues } from "../shared/types/form.types";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const sharedformikConfig = useFormikConfig();

  const formikConfig: EntryFormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: sharedformikConfig.initialValues,
      validationSchema: sharedformikConfig.validationSchema,
    };
  }, [t, sharedformikConfig]);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, CustomerDTO, CustomerDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: customerData }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: customerData?.tradingName ?? "" }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: customerWithRegistration,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const [customerDTO, countries] = await Promise.all([
              getCustomerById(customerWithRegistration.id),
              cachedCountries.run(),
            ]);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!customerDTO)
              return setModalError(
                i18next.t("customers.errors.data.general.customerDoesNotExist")
              );

            const country =
              countries.find((x) => x.code === customerDTO.country) ?? null;

            const { stateAutocomplete, stateTextField } =
              await getStateFormValues({
                country,
                customerState: customerDTO.state,
              });

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(customerDTO);
            setFormValues({
              ...customerDTO,
              corporateName: customerDTO.corporateName,
              daysForPayment: String(customerDTO.daysForPayment),
              country,
              stateAutocomplete,
              stateTextField,
              complement: customerDTO.complement ?? "",
              registrationCode: customerDTO.registrationCode ?? "",
              municipalCode: customerDTO.municipalCode ?? "",
              stateCode: customerDTO.stateCode ?? "",
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "customers.errors.data.fetch.failedToFetchCustomer",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: CreationEditingForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues: { stateAutocomplete, stateTextField, ...formValues },
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          const country = formValues.country?.code ?? "";

          try {
            const customerCreationDTO: CustomerUpdateDTO = {
              ...formValues,
              id: formValues?.id ?? 0,
              daysForPayment: parseLocaleNumber(formValues.daysForPayment),
              country,
              state: schemaCountry.isValidSync(country)
                ? stateAutocomplete?.abbreviation
                : (stateTextField ?? undefined),
              registrationCode: formValues.registrationCode || undefined,
              municipalCode: formValues.municipalCode || undefined,
              stateCode: formValues.stateCode || undefined,
            };

            await updateCustomer(customerCreationDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "customers.errors.data.create.failedToEditCustomer",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
