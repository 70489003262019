import { useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  CommonProjectForm,
  CommonProjectFormRef,
  OnFormSubmitType,
} from "../../shared/react/components/content/CommonAdditionalInformationForm";
import {
  UpdateProjectAdditionalInformation,
  getProjectAdditionalInformation,
} from "../../../../../../services/projects/projects.service";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";
import { getPageFromCurrent } from "../../../../../../services/applicationState/history.service";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

type OwnParams = {
  id: string;
};

export const AdditionalInformation = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: idFromRoute = "" } = useParams<OwnParams>();
  const [searchParams] = useSearchParams();
  const pageReturnTo = useMemo(() => {
    return searchParams.get("return-to");
  }, []);
  const previousPage = useMemo(() => {
    if (pageReturnTo) return pageReturnTo;

    const location = getPageFromCurrent(1);
    if (!location) return "/projects";
    return location.pathname + location.search;
  }, []);

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const project = await getProjectAdditionalInformation(id);
        if (!project) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }

        setPageTitle(project.name);

        formRef.current?.setProjectData(project);
        formRef.current?.setFormValues({
          customerContact: project.customerContact ?? "",
          projectChallenges: project.projectChallenges ?? "",
          projectResults: project.projectResults ?? "",
          solutionAdoptedExpectedResults:
            project.solutionAdoptedExpectedResults ?? "",
          isConfidential: project.isConfidential ?? false,
          projectKeyword: project.projectKeyword,
          projectPipedriveServiceLines: project.projectPipedriveServiceLines,
          projectSkill: project.projectSkill,
        });
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, [i18n.language]);

  const onSubmit: OnFormSubmitType = async (
    values,
    { setSubmitting },
    { redirectionLinkOnSuccess, projectData }
  ) => {
    if (!projectData) throw new Error("'project' cannot be null on submit.");

    try {
      await UpdateProjectAdditionalInformation({
        id: projectData.id ?? 0,
        customerContact: values.customerContact.replace(/\n/g, " "),
        projectChallenges: values.projectChallenges.replace(/\n/g, " "),
        isConfidential: values.isConfidential,
        solutionAdoptedExpectedResults:
          values.solutionAdoptedExpectedResults.replace(/\n/g, " "),
        projectKeyword: values.projectKeyword.map((x) => x.id),
        projectPipedriveServiceLines: values.projectPipedriveServiceLines.map(
          (x) => x.id
        ),
        projectSkill: values.projectSkill.map((x) => x.id),
        projectResults: values.projectResults.replace(/\n/g, " "),
      });

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
      navigate(redirectionLinkOnSuccess ?? previousPage ?? "/projects");
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projects.errors.data.update.failedToUpdateProject",
      });
      setSubmitting(false);
    }
  };

  return <CommonProjectForm mode="editing" onSubmit={onSubmit} ref={formRef} />;
};
