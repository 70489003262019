import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CorporateAllocationsOfUserDTO } from "../../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationsOfUserDTO";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { StyledContent } from "./index.styles";

interface OwnProps {
  allocation: CorporateAllocationsOfUserDTO | null;
}

export const ModalContent = ({ allocation }: OwnProps) => {
  const { t } = useTranslation();

  if (!allocation) return null;

  return (
    <StyledContent>
      <TextField
        label={t("general.keywords.fields.userColaborator")}
        value={allocation.collaboratorName ?? "-"}
        disabled
      />
      <TextField
        label={t("costCenters.keywords.general.costCenter")}
        value={allocation.costCenterCode ?? "-"}
        disabled
      />
      <TextField
        label={t("general.keywords.fields.type")}
        value={t(
          employeeAllocationTypeToTranslationCodeMap[allocation.allocationType]
        )}
        disabled
      />
    </StyledContent>
  );
};
