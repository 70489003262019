import { useTranslation } from "react-i18next";
import { TextFieldFormikWithTranslationsModal } from "../../../../../../shared/common/react/components/form/formik/translatable/TextFieldFormikWithTranslationsModal";
import { FormContentProps } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { DashboardGroupsFormValues } from "../types/DashboardGroupsFormValues";

type OwnProps = FormContentProps<DashboardGroupsFormValues>;

export const DashboardGroupsCommomForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <TextFieldFormikWithTranslationsModal
      name="nameTranslations"
      label={t("general.keywords.fields.name")}
      disabled={isSubmitting}
      inputProps={{ maxLength: 200 }}
      defaultLanguageTextFieldProps={{ required: true }}
      fullWidth
    />
  );
};
