import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  createCollaboratorHistory,
  getCollaboratorHistory,
} from "../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CollaboratorHistoryCreateDTO } from "../../../../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryCreateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonCollboratorHistoryForm } from "../shared/react/components/content/CommonCollboratorHistoryForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
  collaboratorId: number;
  isSuperUserContext?: boolean;
}

export const useCreationForm = ({
  reloadTablePage,
  collaboratorId,
  isSuperUserContext,
}: OwnProps) => {
  const { t } = useTranslation();
  const { initialValues, validationSchema } = useFormikConfig();
  const formikConfig = {
    initialValues,
    validationSchema,
  };

  const { ContentButton: CreationButton, contentModal: creationModal } =
    useFormikModalButton<FormValues>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          const { t } = useTranslation();
          return <>{t("collaboratorTrack.modal.data.creation.title")}</>;
        },
      },
      button: {
        FormButton: AddFormButton,
        onClickContentButtonComponentMemo: async ({
          setFormValues,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const collaboratorHistoryData =
              await getCollaboratorHistory(collaboratorId);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!collaboratorHistoryData)
              return setModalError(
                t(
                  "collaboratorTrack.errors.data.general.collaboratorTrackDoesNotExist"
                )
              );

            const collaboratorHistory = collaboratorHistoryData.at(-1);

            if (
              collaboratorHistory &&
              collaboratorHistory.contractRole?.contractRoleTranslations
            ) {
              setFormValues({
                jobRole: null,
                startYearMonth: null,
                costCenter: collaboratorHistory?.costCenter,
                office: collaboratorHistory?.office,
                horizontal: collaboratorHistory?.horizontal,
                contractRole: {
                  id: collaboratorHistory.contractRole?.id ?? 0,
                  contractRoleTranslations:
                    collaboratorHistory.contractRole.contractRoleTranslations,
                  name: collaboratorHistory.contractRole?.name ?? "",
                },
                registryNumber: collaboratorHistory?.registryNumber ?? "",
              });
            }
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "collaboratorTrack.errors.data.general.collaboratorTrackDoesNotExist",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonCollboratorHistoryForm {...rest} />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const createdHistory: CollaboratorHistoryCreateDTO = {
              idUserCollaborator: collaboratorId,
              idOffice: formValues.office?.id ?? 0,
              idJobRole: formValues.jobRole?.id ?? undefined,
              idContractRole: formValues.contractRole?.id ?? 0,
              idCostCenter: formValues.costCenter?.id ?? 0,
              startYearMonth: YearMonth.createFromDate(
                formValues.startYearMonth ?? new Date()
              ),
              registryNumber: formValues?.registryNumber
                ? formValues?.registryNumber.toString()
                : "",
              idHorizontal: formValues.horizontal?.id ?? undefined,
              isSuperUserContext,
            };

            await createCollaboratorHistory(createdHistory);

            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "collaboratorTrack.errors.data.create.failedToCreateCollaboratorTrack",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { CreationButton, creationModal };
};
