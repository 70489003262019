import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import {
  StyledErrorOutlineIcon,
  StyledModalHelperInfos,
} from "../useUploadZorc/index.styles";

export const useDownloadSpreadsheetHelper = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: DownloadSpreadsheetHelperModalButtonContainer,
    contentModal: downloadSpreadsheetModalHelper,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("downloadBudgetSpreadsheet.modalHelp.helpTitle"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        const rulesColumns = t(
          "downloadBudgetSpreadsheet.modalHelp.helpTipsColumns"
        ) as unknown as string[];
        const rulesSubColumns = t(
          "downloadBudgetSpreadsheet.modalHelp.helpTipsColumnsOptions"
        ) as unknown as string[];
        return (
          <StyledModalHelperInfos>
            <ul>
              {rulesColumns.map((rule, index) => (
                <li key={index}>
                  <Trans i18nKey={rule} components={{ b: <b /> }} />
                </li>
              ))}
              <li>
                <Trans
                  i18nKey={t(
                    "downloadBudgetSpreadsheet.modalHelp.helpSubtitle"
                  )}
                  components={{ b: <b /> }}
                />
                <ul>
                  {rulesSubColumns.map((rule, index) => (
                    <li key={index}>
                      <Trans i18nKey={rule} components={{ b: <b /> }} />
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </StyledModalHelperInfos>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return <StyledErrorOutlineIcon onClick={onClick} />;
      },
    },
  });
  return {
    DownloadSpreadsheetHelperModalButtonContainer,
    downloadSpreadsheetModalHelper,
  };
};
