import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserCollaboratorRecentlyHistoryDTO } from "../../../../../../../../shared/specific/DTOs/user/UserCollaboratorRecentlyHistoryDTO";
import {
  getJobRoleName,
  getMotherProfitCenterName,
} from "../../../shared/helpers/userCollaboratorHelpers";
import { StyledUserCollaboratorContent } from "./index.style";

interface OwnProps {
  userCollaboratorDTO?: UserCollaboratorRecentlyHistoryDTO;
}

export const UserCollaboratorContent = ({ userCollaboratorDTO }: OwnProps) => {
  const { t, i18n } = useTranslation();

  if (!userCollaboratorDTO) return null;

  return (
    <StyledUserCollaboratorContent>
      <TextField
        label={t("general.keywords.fields.name")}
        value={userCollaboratorDTO.name ?? ""}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("general.keywords.fields.email")}
        value={userCollaboratorDTO.email ?? ""}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.login")}
        value={userCollaboratorDTO.login ?? ""}
        InputProps={{ readOnly: true }}
      />

      {userCollaboratorDTO.loginDeactivated && (
        <TextField
          label={t("usersCollaborators.keywords.fields.loginDeactivated")}
          value={userCollaboratorDTO.loginDeactivated}
          InputProps={{ readOnly: true }}
        />
      )}

      <TextField
        label={t("workloads.keywords.general.workload")}
        value={userCollaboratorDTO.workload?.name ?? ""}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("disciplines.keywords.general.discipline")}
        value={userCollaboratorDTO.discipline?.name ?? ""}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.isCollaborator")}
        value={
          userCollaboratorDTO.isCollaborator
            ? t("general.keywords.general.yes")
            : t("general.keywords.general.no")
        }
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.admissionDate")}
        value={
          userCollaboratorDTO.admissionDate
            ? new Date(userCollaboratorDTO.admissionDate).toLocaleDateString(
                i18n.language
              )
            : ""
        }
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.dateOfBirth")}
        value={
          userCollaboratorDTO.dateOfBirth
            ? new Date(userCollaboratorDTO.dateOfBirth).toLocaleDateString(
                i18n.language
              )
            : ""
        }
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.currentJobRole")}
        value={getJobRoleName(userCollaboratorDTO.recentlyHistory)}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t(
          "usersCollaborators.keywords.fields.currentMotherProfitCenter"
        )}
        value={getMotherProfitCenterName(userCollaboratorDTO.recentlyHistory)}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("usersCollaborators.keywords.fields.resignationDate")}
        value={
          userCollaboratorDTO.resignationDate
            ? new Date(userCollaboratorDTO.resignationDate).toLocaleDateString(
                i18n.language
              )
            : ""
        }
        InputProps={{ readOnly: true }}
      />
    </StyledUserCollaboratorContent>
  );
};
