import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ActivitiesTypeAutocompleteFormik } from "../components/form/ActivitiesTypeAutocompleteFormik";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<FilterValues>;

export const ActivitiesFilterForm = ({
  formikProps: { values, isSubmitting, errors },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <DatePickerFormik
        name="scheduledDateEarliest"
        datePickerProps={{
          label: t("activities.keywords.fields.scheduledDateEarliest"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.scheduledDateLatest ?? undefined,
          disabled: isSubmitting,
        }}
        textFieldProps={{
          helperText: errors.scheduledDateEarliest
            ? t("general.errors.data.fields.dates.invalidDate")
            : "",
        }}
      />
      <DatePickerFormik
        name="scheduledDateLatest"
        datePickerProps={{
          label: t("activities.keywords.fields.scheduledDateLatest"),
          slotProps: {
            popper: {
              placement: "left",
            },
          },
          minDate: values.scheduledDateEarliest ?? undefined,
          disabled: isSubmitting,
        }}
        textFieldProps={{
          helperText: errors.scheduledDateLatest
            ? t("general.errors.data.fields.dates.invalidDate")
            : "",
        }}
      />
      <ActivitiesTypeAutocompleteFormik name="typeOfActivity" />
    </StyledForm>
  );
};
