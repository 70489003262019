import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useField } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getCostCentersByAllocationType } from "../../../../../../../../../services/corporateSegments/corporateSegments.service";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { CostCenterDTO } from "../../../../../../../DTOs/costCenter/CostCenterDTO";
import { CostCenterOnlyDTO } from "../../../../../../../DTOs/costCenter/CostCenterOnlyDTO";
import { EmployeeAllocationType } from "../../../../../../../enums/allocations/EmployeeAllocationType.enums";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    CostCenterDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  allocationType: EmployeeAllocationType;
}

export const CostCenterByAllocationTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  allocationType,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const [{ value: costCenter }] = useField<CostCenterOnlyDTO | null>(name);
  const { t, i18n } = useTranslation();

  const getCostCentersFinal = useCallback(async () => {
    const costCenters = await getCostCentersByAllocationType(allocationType);

    return costCenters;
  }, [allocationType]);

  const endAdornment = useMemo(() => {
    if (!costCenter?.name) return undefined;
    return (
      <Tooltip title={costCenter.name} placement="top">
        <AutocompleteInfoBubble>{costCenter.name}</AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [costCenter]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenters.errors.data.fetch.failedToFetchCostCenters"
      )}
      fetchOptions={getCostCentersFinal}
      textfieldProps={{
        label: t("costCenters.keywords.general.costCenter"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          endAdornment,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.code,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.code} - {option.name}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      rerunOnDeps={[i18n.language]}
    />
  );
};
