import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { MaskedTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/MaskedTextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { ContractRolesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/contractRoles/ContractRolesAutocompleteFormik";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";
import { JobRolesSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/jobRoles/JobRoleSearchAutocompleteFormik";
import { OfficesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/Offices/OfficesAutocompleteFormik";
import { maxLengthFields } from "../../../../../../CollaboratorsPage/shared/constants/data/form/fields.constants";
import { FormValues } from "../../../../types/form.types";
import { StyledUserHistoryFormContainer } from "./index.styles";
import { HorizontalAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/horizontal/HorizontalAutocompleteFormik";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData">;

export const CommonCollboratorHistoryForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledUserHistoryFormContainer>
      <MaskedTextFieldFormik
        name="registryNumber"
        mask="0000000"
        textFieldProps={{
          label: t("collaboratorTrack.keywords.fields.registryNumber"),
          inputProps: { maxLength: maxLengthFields.registryNumber },
        }}
        disabled={isSubmitting}
        required
      />
      <JobRolesSearchAutocompleteFormik
        name="jobRole"
        autocompleteProps={{ disabled: isSubmitting }}
        shouldReplaceValueOnMount
      />
      <ContractRolesAutocompleteFormik
        name="contractRole"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting }}
        shouldReplaceValueOnMount
      />
      <OfficesAutocompleteFormik
        name="office"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting }}
      />
      <YearMonthDatePickerFormik
        name="startYearMonth"
        datePickerProps={{
          label: t("collaboratorTrack.keywords.fields.effectiveDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          disabled: isSubmitting,
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <CostCentersSearchAutocompleteFormik
        name="costCenter"
        textfieldProps={{ required: true }}
        autocompleteProps={{ disabled: isSubmitting }}
      />
      <HorizontalAutocompleteFormik
        name="horizontal"
        autocompleteProps={{ disabled: isSubmitting }}
      />
    </StyledUserHistoryFormContainer>
  );
};
