import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";

export const projectManager = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const managerLableCpt: CptProps = {
    text: "Project Manager",
    options: {
      align: pres.AlignH.left,
      y: "19%",
      x: "1%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const managerNameCpt: CptProps = {
    text: projectData.projectManager,
    options: {
      align: pres.AlignH.left,
      y: "20%",
      x: "1%",
      w: "35%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: 10,
    },
  };

  slide.addText(managerLableCpt.text, managerLableCpt.options);
  slide.addText(managerNameCpt.text, managerNameCpt.options);
};
