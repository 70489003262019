import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  getBrlParityById,
  updateBrlParity,
} from "../../../../../../services/brlParity/BrlParity.service";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { BrlParityDTO } from "../../../../../../shared/specific/DTOs/brlParity/BrlParityDTO";
import { BrlParityUpdateDTO } from "../../../../../../shared/specific/DTOs/brlParity/BrlParityUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonBrlParityForm } from "../shared/react/components/CommonBrlParityForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, BrlParityDTO, { brlParity: BrlParityDTO }>(
      {
        modal: {
          keepModalMounted: 1000,
          ModalTitleMemo: ({ internalFormData: brlParity }) => {
            return (
              <Trans
                i18nKey="general.modal.data.edit.titleEditName"
                values={{
                  name: brlParity?.yearMonth ?? "-",
                }}
                components={{ italic: <em /> }}
              />
            );
          },
        },
        button: {
          FormButton: EditFormButton,
          onClickContentButtonComponentMemo: async ({
            contentButtonContentProps: { brlParity },
            setFormValues,
            setInternalFormData,
            setIsLoadingModal,
            setModalError,
            getOpenCloseModalCount,
            checkInCurrentModalCount,
          }) => {
            setIsLoadingModal(true);
            const startingOpenCloseModalCount = getOpenCloseModalCount();

            try {
              if (!checkInCurrentModalCount(startingOpenCloseModalCount))
                return;

              const brlParityData = await getBrlParityById(brlParity.id);

              if (!brlParityData)
                return setModalError(
                  t("brlParity.errors.data.general.brlParityDoesNotExist")
                );

              setInternalFormData(brlParityData);
              setFormValues({
                currency: brlParityData.currency,
                performedValue: brlParityData.performedValue
                  ? formatNumber(brlParityData.performedValue, {
                      fractionDigits: 4,
                    })
                  : "",
                plannedValue: brlParityData.plannedValue
                  ? formatNumber(brlParityData.plannedValue, {
                      fractionDigits: 4,
                    })
                  : "",
                lastBusinessDayValue: brlParityData.lastBusinessDayValue
                  ? formatNumber(brlParityData.plannedValue, {
                      fractionDigits: 4,
                    })
                  : "",
                yearMonth: brlParityData.yearMonth.toDate(),
              });
            } catch (error) {
              if (!checkInCurrentModalCount(startingOpenCloseModalCount))
                return;

              console.error(error);

              const errorMessage = getTextIf4xxApiErrorDTO({
                error,
                defaultMessage:
                  "frames.errors.data.fetch.failedToFetchBrlParity",
              });
              setModalError(errorMessage);
            } finally {
              setIsLoadingModal(false);
            }
          },
        },
        form: {
          formikConfig,
          FormContentMemo: ({ internalFormData, ...rest }) => (
            <CommonBrlParityForm {...rest} mode="editing" />
          ),
          FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
            const { t } = useTranslation();

            return (
              <>
                <Button onClick={closeModal} disabled={isSubmitting}>
                  {t("general.actions.general.cancel")}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              </>
            );
          },
          onSubmit: async ({
            formValues,
            internalFormData,
            formikHelpers: { setSubmitting },
            closeModal,
          }) => {
            try {
              const updatedBrlParity: BrlParityUpdateDTO = {
                id: internalFormData?.id ?? 0,
                plannedValue:
                  parseLocaleNumber(formValues.plannedValue) ?? undefined,
                performedValue: formValues?.performedValue
                  ? parseLocaleNumber(formValues.performedValue)
                  : undefined,
                lastBusinessDayValue: formValues?.lastBusinessDayValue
                  ? parseLocaleNumber(formValues.lastBusinessDayValue)
                  : undefined,
                version: internalFormData?.version || "",
              };

              await updateBrlParity(updatedBrlParity);
              reloadTablePage();
              closeModal();
            } catch (error) {
              notifyIf4xxApiErrorDTO({
                error,
                defaultMessage: "brlParity.errors.data.edit.failedToEdit",
              });
            } finally {
              setSubmitting(false);
            }
          },
        },
      }
    );

  return { EditingButtonContainer, editingModal };
};
