import * as yup from "yup";
import {
  OfficeDTO,
  schemaOfficeDTO,
} from "../../../shared/specific/DTOs/Office/OfficeDTO";
import { riseApi } from "./connection";

export const getOffices = async (): Promise<OfficeDTO[]> => {
  const { data: offices } = await riseApi.get("/Office");

  return yup.array(schemaOfficeDTO).defined().validateSync(offices);
};
