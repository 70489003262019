import { saveAs } from "file-saver";
import { riseApi } from "./connection";
import {
  CalculatedFeeDTO,
  schemaCalculatedFeeDTO,
} from "../../../shared/specific/DTOs/calculatedFee/CalculatedFeeDTO";
import { CalculatedFeeFiltersDTO } from "../../../shared/specific/DTOs/calculatedFee/CalculateddFeeFiltersDTO";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const getSummaryFee = async (
  filters: CalculatedFeeFiltersDTO
): Promise<CalculatedFeeDTO> => {
  const { data: fees } = await riseApi.get("CalculatedFee", {
    params: filters,
  });

  return schemaCalculatedFeeDTO.validateSync(fees);
};

export const GenerateCalculatedFeeReport = async (
  filters: CalculatedFeeFiltersDTO
) => {
  const response = await riseApi.get(
    "/CalculatedFee/GenerateCalculatedFeeReport",
    {
      params: filters,
      responseType: "blob",
    }
  );

  const finalFilename = `Contabilização Fv50 FEE ADM ${filters.yearMonth.toPrettyString()}.xlsx`;

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};
