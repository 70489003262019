import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback } from "react";
import { Iso as IsoIcon } from "@mui/icons-material";
import {
  useFormikModalButton,
  OnClickContentButtonFunction,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { createActivityDeprovision } from "../../../../../../../../services/activities/activities.service";
import { convertTo } from "../../../../../../../../shared/common/helpers/types/converters.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { CommonDeprovisionFormValues } from "../shared/types/data/form/values.types";
import { ProjectDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ActivityDeprovisionCreateDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDeprovisionCreateDTO";
import { CommonDeprovisionForm } from "../shared/react/components/content/CommonDeprovisionForm";
import { SimpleIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useDeprovisionFormikConfig } from "../shared/react/hooks/data/validation/useDeprovisionFormikConfig";
import { getNowBrazilianDateOnly } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { checkIsDateAfterProject } from "../../../../shared/helpers/DTOs/project/general.helpers";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
}

interface InternalFormValues {
  project: ProjectDTO | null;
  maxScheduledDate: Date | null;
}

export const useDeprovisionCreationForm = ({
  reloadTablePage,
  reloadActivitySummary,
}: OwnProps) => {
  const { t } = useTranslation();

  const { project, reloadProject } = useProjectContext();
  const formikConfig = useDeprovisionFormikConfig();

  const onClickContentButton = useCallback<
    OnClickContentButtonFunction<
      CommonDeprovisionFormValues,
      InternalFormValues
    >
  >(
    ({ setInternalFormData, setFormValues, formValues }) => {
      setInternalFormData({
        project,
        maxScheduledDate: getNowBrazilianDateOnly().toDate(),
      });
      setFormValues({
        ...formValues,
        scheduledDate: new Date(),
      });
    },
    [project]
  );

  const {
    contentButton: deprovisionCreationButton,
    contentModal: deprovisionCreationModal,
  } = useFormikModalButton<CommonDeprovisionFormValues, InternalFormValues>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("activities.modal.data.createDeprovision.title"),
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            Icon={IsoIcon}
            tooltipText={t("activities.actions.general.addDeprovision")}
            onClick={onClick}
          />
        );
      },
      onClickContentButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonDeprovisionForm
          project={internalFormData?.project ?? null}
          {...rest}
          mode="creating"
          maxScheduledDate={internalFormData?.maxScheduledDate ?? null}
        />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          await createActivityDeprovision(
            convertTo<ActivityDeprovisionCreateDTO>({
              idProject: project.id,
              idDeprovisionType: formValues.deprovisionType?.id ?? 0,
              description: formValues.description,
              amount: -1 * parseLocaleNumber(formValues.amount),
              deprovisionJustification: formValues.deprovisionJustification,
            })
          );

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          reloadActivitySummary();
          closeModal();
          if (
            checkIsDateAfterProject(
              project.finalScheduledEndDate,
              formValues.scheduledDate
            ) ||
            project.isValid
          )
            reloadProject();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "costCenters.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return {
    deprovisionCreationButton,
    deprovisionCreationModal,
  };
};
