import * as yup from "yup";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { CreateSkillCertificationDTO } from "../../../shared/specific/DTOs/skillCertification/CreateSkillCertificationDTO";
import {
  PaginatedSkillCertificationDTO,
  schemaPaginatedSkillCertificationDTO,
} from "../../../shared/specific/DTOs/skillCertification/PaginatedSkillCertificationDTO";
import {
  SkillCertificationDTO,
  schemaSkillCertificationDTO,
} from "../../../shared/specific/DTOs/skillCertification/SkillCertificationDTO";
import { SkillCertificationUpdateDTO } from "../../../shared/specific/DTOs/skillCertification/SkillCertificationUpdateDTO";
import { SkillCertificationFilterDTO } from "../../../shared/specific/DTOs/skillCertification/filters/SkillCertificationFilterDTO";
import { riseApi } from "./connection";

export const getSkillCertifications = async ({
  filters,
  pagination,
}: {
  filters?: SkillCertificationFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedSkillCertificationDTO> => {
  const { data: skillCertifications } = await riseApi.get(
    "/SkillCertification",
    {
      params: { ...filters, ...pagination },
    }
  );

  return schemaPaginatedSkillCertificationDTO.validateSync(skillCertifications);
};

export const getSkillCertificationsDropdown = async ({
  filters,
}: {
  filters?: SkillCertificationFilterDTO;
} = {}): Promise<SkillCertificationDTO[]> => {
  const { data: skillCertifications } = await riseApi.get(
    "/SkillCertification/Dropdown",
    {
      params: { ...filters },
    }
  );

  return yup
    .array(schemaSkillCertificationDTO)
    .defined()
    .validateSync(skillCertifications);
};

export const getSkillCertificationById = async (
  id: number
): Promise<SkillCertificationDTO | null> => {
  const { data: skillCertification } = await riseApi.get(
    `SkillCertification/${id}`
  );

  return schemaSkillCertificationDTO
    .nullable()
    .validateSync(skillCertification);
};

export const createSkillCertification = async (
  skillCertification: CreateSkillCertificationDTO
) => {
  await riseApi.post("SkillCertification", skillCertification);
};

export const deleteSkillCertification = async (id: number, version: string) => {
  await riseApi.delete(`SkillCertification/${id}/${version}`);
};

export const updateSkillCertification = async (
  skillCertification: SkillCertificationUpdateDTO
) => {
  await riseApi.put("SkillCertification", skillCertification);
};
