import { useTheme } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";

export const useWindowSize = () => {
  const theme = useTheme();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const widthRef = useRef(width);
  const heightRef = useRef(height);
  widthRef.current = width;
  heightRef.current = height;

  const isBreakpointSm = useMemo(() => {
    return width <= theme.breakpoints.values.sm;
  }, [width]);
  const isBreakpointMd = useMemo(() => {
    return width <= theme.breakpoints.values.md;
  }, [width]);
  const isBreakpointLg = useMemo(() => {
    return width <= theme.breakpoints.values.lg;
  }, [width]);

  useEffect(() => {
    const handleResize = () => {
      if (widthRef.current !== window.innerWidth) {
        setWidth(window.innerWidth);
      }
      if (heightRef.current !== window.innerHeight) {
        setHeight(window.innerHeight);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    width,
    height,
    isBreakpointSm,
    isBreakpointMd,
    isBreakpointLg,
  };
};
