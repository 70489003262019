import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { FormValuesBudgetOveriew } from "./types/data/form.types";
import { YearDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearDatePickerFormik";
import { useEffectAfterRenders } from "../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { SegmentGroupsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentGroupsAutoCompleteFormik";

interface OwnProps {
  formikContenProps: FormikProps<FormValuesBudgetOveriew>;
}

export const FormComponent = ({
  formikContenProps: { values, isSubmitting, submitForm, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("segmentGroup", null);
    },
    deps: [values.un],
    rendersBeforeEffect: 1,
  });

  const isShowSegmentGroup = useMemo(() => {
    return values.un?.id && [5, 2].includes(values.un?.id);
  }, [values.un]);

  return (
    <StyledViewContent>
      <StyledFormContainer>
        <YearDatePickerFormik
          name="year"
          datePickerProps={{
            label: t("general.keywords.dateTime.general.year"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            views: ["year"],
          }}
          textFieldProps={{ required: true }}
        />
        <DivisionsAutocompleteFormik
          name="un"
          textfieldProps={{
            required: true,
          }}
        />
        <SegmentGroupsAutocompleteFormik
          name="segmentGroup"
          filters={{
            idDivision: values.un?.id,
          }}
          autocompleteProps={{
            disabled: !isShowSegmentGroup,
          }}
        />
      </StyledFormContainer>
      <GenerateReportButton onClick={submitForm} isSubmitting={isSubmitting} />
      <LoadingBackdrop open={isSubmitting} />
    </StyledViewContent>
  );
};
