import i18next from "i18next";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";
import { CollaboratorHistoryDTO } from "../../../../../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryDTO";

export const getJobRoleName = (
  recentlyHistory: CollaboratorHistoryDTO | null
) => {
  const locale = i18next.language as Language;

  if (recentlyHistory == null || !recentlyHistory.jobRole) return "-";

  const translation = recentlyHistory.jobRole.jobRoleTranslations.find(
    (x) => x.idLanguage === locale
  );

  if (translation?.name != null) return translation.name;

  if (recentlyHistory.jobRole.name !== null)
    return recentlyHistory.jobRole.name;

  return "-";
};

export const getContractRoleName = (
  recentlyHistory: CollaboratorHistoryDTO | null
) => {
  const locale = i18next.language as Language;

  if (recentlyHistory == null) return "-";

  const translation =
    recentlyHistory.contractRole?.contractRoleTranslations.find(
      (x) => x.idLanguage === locale
    );

  if (translation?.name != null) return translation.name;

  if (recentlyHistory?.contractRole?.name !== null)
    return recentlyHistory?.contractRole?.name;

  return "-";
};

export const getHorizontalName = (
  recentlyHistory: CollaboratorHistoryDTO | null
) => {
  const locale = i18next.language as Language;

  if (recentlyHistory == null) return "-";

  const translation = recentlyHistory.horizontal?.horizontalTranslation.find(
    (x) => x.idLanguage === locale
  );

  if (translation?.name != null) return translation.name;

  if (recentlyHistory?.horizontal?.name !== null)
    return recentlyHistory?.horizontal?.name;

  return "-";
};

export const getMotherProfitCenterName = (
  recentlyHistory: CollaboratorHistoryDTO | null
) => {
  const locale = i18next.language as Language;

  if (recentlyHistory == null) return "-";

  const prefix = recentlyHistory.costCenter.id.toString().concat(" - ");
  const translation = recentlyHistory.costCenter.costCenterTranslations.find(
    (x) => x.idLanguage === locale
  );

  if (translation?.name != null) {
    return prefix.concat(translation.name);
  }

  if (recentlyHistory.costCenter.code != null)
    return prefix.concat(recentlyHistory.costCenter.code);

  return "-";
};
