import { Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { downloadBudgetSpreadsheet } from "../../../../../../../../../services/budget/budget.service";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  idDivision: string | null;
  idSegmentGroup: string | null;
  idSubsidiary: string | null;
  isDisabledButton: boolean;
}

export const useDownloadSpreadsheet = ({
  idDivision,
  idSegmentGroup,
  idSubsidiary,
  isDisabledButton,
}: OwnProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const OnClickButton = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await downloadBudgetSpreadsheet({
        idDivision: idDivision ? parseInt(idDivision) : null,
        idSegmentGroup: idSegmentGroup ? parseInt(idSegmentGroup) : null,
        idSubsidiary: idSubsidiary ? parseInt(idSubsidiary) : null,
        year: new Date().getFullYear() + 1,
      });
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while download spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [idDivision, idSegmentGroup]);

  const button = useMemo(() => {
    return (
      <>
        <Button
          onClick={OnClickButton}
          disabled={isDisabledButton}
          variant="contained"
        >
          {t("downloadBudgetSpreadsheet.keywords.downloadButton")}
        </Button>
        <LoadingBackdrop open={isSubmitting} />
      </>
    );
  }, [
    isDisabledButton,
    idDivision,
    idSegmentGroup,
    idSubsidiary,
    isSubmitting,
  ]);

  return button;
};
