import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { MonthlyAllocationClosingDTO } from "../../../../../shared/specific/DTOs/monthlyAllocationClosing/MonthlyAllocationClosingDTO";
import { deleteMonthlyAllocationClosing } from "../../../../../services/monthlyAllocationClosing/monthlyAllocationClosing.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<
    MonthlyAllocationClosingDTO,
    MonthlyAllocationClosingDTO
  >({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: monthlyAllocationClosed }) => {
        return (
          <Trans
            i18nKey="monthlyAllocationClosing.modal.data.delete.deleteName"
            values={{
              name: monthlyAllocationClosed?.yearMonth.toPrettyString() ?? "",
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: monthlyAllocationClosed,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          if (!monthlyAllocationClosed)
            return setModalError(
              i18next.t("dashboards.errors.data.fetch.failedToFetchdashboard")
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(monthlyAllocationClosed);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "general.errors.data.fetch.failedToFetchData",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: monthlyAllocationClosed,
      }) => {
        if (!monthlyAllocationClosed)
          throw new Error(
            "monthlyAllocationClosed data cannot be null on confirm."
          );

        try {
          await deleteMonthlyAllocationClosing(
            monthlyAllocationClosed.id,
            monthlyAllocationClosed.version
          );
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="general.errors.data.delete.failedToDelete"
              values={{
                name: monthlyAllocationClosed?.yearMonth.toPrettyString() ?? "",
              }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
