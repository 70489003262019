import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../common/classes/data/date/YearMonth";
import { FeeDTO, schemaFeeDTO } from "../fee/FeeDTO";

export interface FeeYearMonthDTO {
  id: number;
  yearMonth: YearMonth;
  fee: FeeDTO;
  value: number;
  useFullAllocation: boolean;
  version: string;
}

export const schemaFeeYearMonthDTO = yup.object({
  id: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined(),
  fee: schemaFeeDTO.defined(),
  value: yup.number().defined(),
  useFullAllocation: yup.boolean().defined(),
  version: yup.string().defined(),
});
