import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox } from "@mui/material";
import { CollaboratorAllocationContext } from "../../context";
import { FormValuesCollaboratorAllocationsFilter } from "../../types/form.types";
import { UsersSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { YearMonthDatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { StyledForm } from "./index.styles";

interface OwnProps {
  setAllocationCollaboratorFilter: (
    value: React.SetStateAction<
      FormValuesCollaboratorAllocationsFilter | null | undefined
    >
  ) => void;
}

export const FormFilter = ({ setAllocationCollaboratorFilter }: OwnProps) => {
  const { t } = useTranslation();

  const { setFilter, filter, setAllocations } = useContext(
    CollaboratorAllocationContext
  );

  const [onlyResignatedLessThanOneMonth, setOnlyResignatedLessThanOneMonth] =
    useState(false);

  const formikRef =
    useRef<FormikProps<FormValuesCollaboratorAllocationsFilter>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesCollaboratorAllocationsFilter = {
      userCollaborator: null,
      yearMonth: null,
    };

    const validationSchema = yup.object({
      userCollaborator: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      yearMonth: yup
        .date()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = (
    values: FormValuesCollaboratorAllocationsFilter,
    { setSubmitting }: FormikHelpers<FormValuesCollaboratorAllocationsFilter>
  ) => {
    if (values.userCollaborator && values.yearMonth) {
      setAllocationCollaboratorFilter({
        userCollaborator: values.userCollaborator,
        yearMonth: values.yearMonth,
      });

      setFilter({
        userCollaborator: values.userCollaborator,
        yearMonth: values.yearMonth,
      });
    }
    setSubmitting(false);
  };

  const setField = useCallback(
    (field: string, value: string | null) =>
      formikRef.current?.setFieldValue(field, value),
    [formikRef]
  );

  useEffect(() => {
    if (!filter) {
      setField("userCollaborator", null);
      setField("yearMonth", null);
    }
  }, [filter]);

  const UsersCurrentJobRole = useMemo(() => {
    setField("userCollaborator", null);
    return (
      <UsersSearchAutocompleteFormik
        name="userCollaborator"
        textfieldProps={{
          label: `${t("usersCollaborators.keywords.general.collaborator")}`,
        }}
        filters={{
          isActive: !onlyResignatedLessThanOneMonth,
          isCollaborator: true,
          onlyResignatedLessThanOneMonth,
        }}
      />
    );
  }, [onlyResignatedLessThanOneMonth]);

  return (
    <>
      <div>
        <Checkbox
          checked={onlyResignatedLessThanOneMonth ?? false}
          onChange={() =>
            setOnlyResignatedLessThanOneMonth(!onlyResignatedLessThanOneMonth)
          }
        />
        <span>
          {t("allocations.actions.data.onlyResignatedLessThanOneMonth")}
        </span>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validateOnMount={false}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting, values, setFieldValue }) => {
          return (
            <StyledForm>
              {UsersCurrentJobRole}
              <YearMonthDatePickerFormik
                name="yearMonth"
                datePickerProps={{
                  label: t("reports.pages.form.period"),
                  slotProps: {
                    popper: {
                      placement: "right",
                    },
                  },
                }}
              />
              <Button
                onClick={submitForm}
                variant="contained"
                disabled={
                  isSubmitting || !values.userCollaborator || !values.yearMonth
                }
              >
                {t("general.actions.general.search")}
              </Button>
              <Button
                onClick={() => {
                  setAllocations([]);
                  setFieldValue("userCollaborator", null);
                  setFieldValue("yearMonth", null);
                  return setAllocationCollaboratorFilter(null);
                }}
                disabled={
                  isSubmitting ||
                  (!values.userCollaborator && !values.yearMonth)
                }
              >
                {t("general.actions.general.clear")}
              </Button>
            </StyledForm>
          );
        }}
      </Formik>
    </>
  );
};
