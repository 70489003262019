import {
  PaginationResponse,
  getSchemaPaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { ContractRoleDTO, schemaContractRoleDTO } from "./ContractRoleDTO";

export type PaginatedContractRoleDTO = PaginationResponse<ContractRoleDTO>;

export const schemaPaginatedContractRoleDTO =
  getSchemaPaginationResponse<ContractRoleDTO>(schemaContractRoleDTO);
