import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import {
  getFrameById,
  updateFrame,
} from "../../../../../../services/frames/frames.service";
import { DateOnly } from "../../../../../../shared/common/classes/data/date/DateOnly";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { FrameOnlyDTO } from "../../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import { FrameUpdateDTO } from "../../../../../../shared/specific/DTOs/frame/FrameUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonFrameForm } from "../shared/react/components/content/CommonFrameForm";
import { useFormikConfig } from "../shared/react/hooks/useFormikConfig";
import { FormValues } from "../shared/types/data/form.types";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      FormValues,
      { frame?: FrameOnlyDTO },
      { frameData: FrameOnlyDTO }
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: frameData }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: frameData?.frame?.name ?? "" }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: { frameData },
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            const frame = await getFrameById(frameData.id);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!frame)
              return setModalError(
                t("frames.errors.data.general.frameDoesNotExist")
              );

            setInternalFormData({ frame });
            setFormValues({
              ...frame,
              startDate: frame.startDate.toDate(),
              endDate: frame.endDate.toDate(),
              amount: formatNumber(frame.amount, { fractionDigits: 2 }),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "frames.errors.data.fetch.failedToFetchFrames",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonFrameForm
            {...rest}
            mode="editing"
            frame={internalFormData?.frame}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData: frameData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const frameUpdated: FrameUpdateDTO = {
              id: frameData?.frame?.id ?? 0,
              idSubsidiary: formValues.subsidiary?.id ?? 0,
              idDivision: formValues.division?.id ?? 0,
              idCustomer: formValues.customer?.id ?? 0,
              idAccountManager: formValues.accountManager?.id ?? 0,
              name: formValues.name,
              startDate: DateOnly.createFromDate(
                formValues.startDate ?? new Date()
              ),
              endDate: DateOnly.createFromDate(
                formValues.endDate ?? new Date()
              ),
              amount: parseLocaleNumber(formValues.amount ?? ""),
              version: frameData?.frame?.version ?? "",
            };

            await updateFrame(frameUpdated);
            reloadTablePage();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "frames.errors.data.fetch.failedToUpdateFrame",
            });
          } finally {
            setSubmitting(false);
            closeModal();
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
