import { useMemo } from "react";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { CorporateAllocationsPageWithinContext } from "./CorporateAllocationsPageWithinContext";
import { CorporateAllocationsCarouselContextProvider } from "./shared/react/contexts/CorporateAllocationsCarouselContext";
import { CorporateAllocationsSuperUserContext } from "./shared/react/contexts/CorporateAllocationsSuperUserContext";

export const CorporateAllocationsPage = () => {
  const {
    superUserButton: canEditAllCorporateAllocationsButton,
    inSuperUserMode: canEditAllCorporateAllocations,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllCorporateAllocations,
    tooltipNotInSuperUserModeOff:
      "corporateAllocations.actions.authorization.editAllocationsWithoutRestrictions",
    tooltipInSuperUserMode:
      "corporateAllocations.actions.authorization.stopEditAllocationsWithoutRestrictions",
  });

  const corporateAllocationsSuperUserValues = useMemo(() => {
    return {
      canEditAllCorporateAllocationsButton,
      canEditAllCorporateAllocations,
    };
  }, [canEditAllCorporateAllocationsButton, canEditAllCorporateAllocations]);

  return (
    <CorporateAllocationsSuperUserContext.Provider
      value={corporateAllocationsSuperUserValues}
    >
      <CorporateAllocationsCarouselContextProvider>
        <CorporateAllocationsPageWithinContext />
      </CorporateAllocationsCarouselContextProvider>
    </CorporateAllocationsSuperUserContext.Provider>
  );
};
