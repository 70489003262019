import {
  CalendarMonth as CalendarMonthIcon,
  PlayCircleFilled as PlayCircleFilledIcons,
} from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LanguageIcon from "@mui/icons-material/Language";
import { Tooltip } from "@mui/material";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { getProjects } from "../../../../../../services/projects/projects.service";
import { EditIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { SimpleIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { ViewIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { CustomLink } from "../../../../../../shared/common/react/components/navigation/CustomLink";
import {
  ExternalEnhancedTableExternalPageChanger,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { AndPermissions } from "../../../../../../shared/specific/classes/user/permissions/permissionsOperators/AndPermissions";
import { ProjectsFiltersDTO } from "../../../../../../shared/specific/DTOs/project/filters/ProjectsFiltersDTO";
import { ProjectDTO } from "../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectStatus } from "../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { ProjectStatusFlag } from "../../shared/react/components/general/info/ProjectStatusFlag";
import { usePlanningCreationForm } from "./usePlanningCreationForm";
import { useReopenProject } from "./useReopenProject";
import { forcefinishProject } from "../shared/functions/forcefinishProject";
import { RootState } from "../../../../../../store";
import { notifyError } from "../../../../../../services/applicationState/toast.service";

interface OwnProps {
  filters: ProjectsFiltersDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const userData = useSelector((state: RootState) => state.user.userData);

  const { PlanningCreationButtonContainer, planningCreationModal } =
    usePlanningCreationForm();
  const { ReopenProjectButtonContainer, deletionModal } = useReopenProject({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.status"),
        width: 172,
      },
      t("general.keywords.fields.name"),
      t("general.keywords.fields.code"),
      t("subsidiaries.keywords.general.billingCountry"),
      {
        value: "",
        width: 282,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const getPlanningButton = (projectData: ProjectDTO) => {
    const doNotHaveApprovedCredit =
      projectData.dealApprovedCredit != null &&
      projectData.dealApprovedCredit === false;

    const valueReturnTo = `${location.pathname}?name=${encodeURIComponent(
      projectData.name
    )}`;

    const actualSearchParams = new URLSearchParams();
    actualSearchParams.set("return-to", valueReturnTo);

    return (
      <Tooltip title={getPlanningTooltip(projectData.status)} placement="top">
        {projectData.status === ProjectStatus.Created ? (
          <div>
            {doNotHaveApprovedCredit ? (
              <SimpleIconButton
                Icon={CalendarMonthIcon}
                color="primary"
                onClick={() => {
                  return notifyError(
                    i18next.t(
                      "projects.errors.data.startPlanning.dealNotHaveApprovedCredit"
                    )
                  );
                }}
              />
            ) : (
              <PlanningCreationButtonContainer {...projectData} />
            )}
          </div>
        ) : (
          <CustomLink
            to={`/projects/${
              projectData.id
            }/planning?${actualSearchParams.toString()}`}
          >
            <SimpleIconButton Icon={CalendarMonthIcon} color="primary" />
          </CustomLink>
        )}
      </Tooltip>
    );
  };

  const getPlanningTooltip = (projectStatus: ProjectStatus) => {
    switch (projectStatus) {
      case ProjectStatus.Created:
        return t("projects.actions.general.startPlanning");

      case ProjectStatus.Planning:
      case ProjectStatus.Ongoing:
        return t("projects.actions.general.replanProject");

      default:
        return t("projects.actions.general.viewPlanning");
    }
  };

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        if (
          !filters.name &&
          !filters.idDivision &&
          !filters.customerName &&
          !filters.code &&
          (!filters.IdsUsersManagers ||
            filters.IdsUsersManagers?.length === 0) &&
          (!filters.IdsKeyWord || filters.IdsKeyWord?.length === 0) &&
          (!filters.IdsProjectPipedriveServiceLines ||
            filters.IdsProjectPipedriveServiceLines?.length === 0) &&
          (!filters.IdsSkills || filters.IdsSkills?.length === 0)
        ) {
          setInfo(
            t("projects.info.data.general.typeSomethingNameOrFillTheFilter")
          );
          return null;
        }

        const headKeys = ["status", "name", "code", "subsidiary.name"];

        try {
          const {
            tableData: projectsData,
            page,
            totalCount,
          } = await getProjects({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const generateViewProjectLink = (projectData: ProjectDTO) => {
            const valueReturnTo = `${
              location.pathname
            }?name=${encodeURIComponent(projectData.name)}`;

            const actualSearchParams = new URLSearchParams();
            actualSearchParams.set("return-to", valueReturnTo);

            return `/projects/${
              projectData.id
            }?${actualSearchParams.toString()}`;
          };

          const generateEditProjectLink = (projectData: ProjectDTO) => {
            const valueReturnTo = `${
              location.pathname
            }?name=${encodeURIComponent(projectData.name)}`;

            const actualSearchParams = new URLSearchParams();
            actualSearchParams.set("return-to", valueReturnTo);

            return `/projects/${
              projectData.id
            }/edit?${actualSearchParams.toString()}`;
          };

          const generatePepLink = (projectData: ProjectDTO) => {
            const valueReturnTo = `${
              location.pathname
            }?name=${encodeURIComponent(projectData.name)}`;

            const actualSearchParams = new URLSearchParams();
            actualSearchParams.set("return-to", valueReturnTo);

            return `/projects/${
              projectData.id
            }/peps?${actualSearchParams.toString()}`;
          };

          const rows: ExternalEnhancedRow[] = projectsData.map(
            (projectData): ExternalEnhancedRow => ({
              id: projectData.id,
              cells: [
                {
                  CellRenderer: () => (
                    <ProjectStatusFlag
                      projectStatus={projectData.status}
                      stretched
                    />
                  ),
                },
                projectData.name,
                projectData.code,
                projectData.subsidiary.name,
                {
                  CellRenderer: () => (
                    <>
                      <CustomLink to={generateViewProjectLink(projectData)}>
                        <ViewIconButton />
                      </CustomLink>
                      <Protected
                        restrictions={
                          new AndPermissions(
                            {
                              type: PermissionType.Project,
                              level: PermissionLevel.Update,
                            },
                            PermissionType.CanReopenProject
                          )
                        }
                      >
                        {[ProjectStatus.Finalized].includes(
                          projectData.status
                        ) ? (
                          <ReopenProjectButtonContainer {...projectData} />
                        ) : (
                          <SimpleIconButton
                            Icon={PlayCircleFilledIcons}
                            disabled
                          />
                        )}
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Project,
                          level: PermissionLevel.Update,
                        }}
                      >
                        {[
                          ProjectStatus.Finalized,
                          ProjectStatus.Canceled,
                        ].includes(projectData.status) ? (
                          <EditIconButton disabled />
                        ) : (
                          <CustomLink to={generateEditProjectLink(projectData)}>
                            <EditIconButton />
                          </CustomLink>
                        )}
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.CostCenterPeps,
                          level: PermissionLevel.Read,
                        }}
                      >
                        <CustomLink
                          to={generatePepLink(projectData)}
                          disabled={
                            projectData.status === ProjectStatus.Finalized
                          }
                        >
                          <SimpleIconButton
                            Icon={LanguageIcon}
                            color="primary"
                            tooltipText={t(
                              "costCenterPeps.actions.general.managePeps"
                            )}
                            disabled={
                              projectData.status === ProjectStatus.Finalized
                            }
                          />
                        </CustomLink>
                      </Protected>
                      {getPlanningButton(projectData)}
                      {/* // É VERDADE... SOMENTE A JOSEANNE PODE FAZER ISSO!!!! */}
                      {userData?.isAdmin &&
                        userData.username ===
                          "joseanne.alves@radixeng.com.br" && (
                          <SimpleIconButton
                            Icon={HighlightOffIcon}
                            color="error"
                            tooltipText={t("Fechar Projeto")}
                            onClick={async () =>
                              forcefinishProject(
                                {
                                  idProject: projectData.id,
                                  code: projectData.code,
                                },
                                reloadTablePage
                              )
                            }
                          />
                        )}
                    </>
                  ),
                  align: "right",
                  justifyContent: "end",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );

  return {
    headCells,
    onTablePageChange,
    planningCreationModal,
    deletionModal,
  };
};
