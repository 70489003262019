import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { uploadCorporateAllocations } from "../../../../../services/corporateAllocations/corporateAllocations.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { CorporateAllocationUploadResponseDTO } from "../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationUploadResponseDTO";
import { CorporateAllocationPaginatedFiltersDTO } from "../../../../../shared/specific/DTOs/corporateAllocations/filters/CorporateAllocationPaginatedFiltersDTO";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { uploadCorporateAllocationsRestrictions } from "../../../../shared/constants/routing/restrictions/performanceRestrictions.constants";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import { useUploadForm } from "./hooks/useUploadForm";
import {
  StyledCorporateAllocationUpload,
  StyledTextButton,
} from "./hooks/useUploadForm/components";
import { useWorksheetHelp } from "./hooks/useWorksheetHelp";
import { CorporateAllocationsCarouselContext } from "./shared/react/contexts/CorporateAllocationsCarouselContext";
import { CorporateAllocationsSuperUserContext } from "./shared/react/contexts/CorporateAllocationsSuperUserContext";

export const CorporateAllocationsPageWithinContext = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);
  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);
  const loadCorporateAllocations = () => fileUploadInputRef.current?.click();
  const [filename, setFilename] = useState<string | null>(null);
  const [responseUpload, setResponseUpload] =
    useState<CorporateAllocationUploadResponseDTO | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { setEarliestYearMonth, setLatestYearMonth } = useContext(
    CorporateAllocationsCarouselContext
  );
  const { canEditAllCorporateAllocationsButton } = useContext(
    CorporateAllocationsSuperUserContext
  );

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  useEffect(() => {
    setPageTitle(t("corporateAllocations.pages.main.title"));
  }, [t]);

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();
  const { filterButton, filtersModal, filters: modalFilters } = useFilterForm();
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
  });

  const { UploadButton, uploadModal, uploadButtonRef } = useUploadForm({
    isSubmitting,
    filename,
    hasSubmitted,
    responseUpload,
  });

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;
    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      const result = await uploadCorporateAllocations(file);
      setResponseUpload(result);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const filters = useMemo((): CorporateAllocationPaginatedFiltersDTO => {
    return {
      ...belowToolbarFilters,
      ...modalFilters,
    };
  }, [belowToolbarFilters, modalFilters]);

  useEffect(() => {
    setEarliestYearMonth(belowToolbarFilters.earliestYearMonth);
    setLatestYearMonth(belowToolbarFilters.latestYearMonth);
  }, [
    belowToolbarFilters.earliestYearMonth,
    belowToolbarFilters.latestYearMonth,
  ]);

  const { headCells, onTablePageChange, deletionModal, editingModal } =
    useTableData({
      filters,
      reloadTablePage,
    });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [filters, i18n.language],
    rendersBeforeEffect: 1,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {canEditAllCorporateAllocationsButton}
        {filterButton}
      </>
    );
  }, [filterButton, canEditAllCorporateAllocationsButton]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <StyledCorporateAllocationUpload>
              <Protected
                restrictions={{
                  type: PermissionType.CorporateAllocations,
                  level: PermissionLevel.Create,
                }}
              >
                {creationButton}
              </Protected>
              <Protected restrictions={uploadCorporateAllocationsRestrictions}>
                <input
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                  hidden
                  ref={fileUploadInputRef}
                  onChange={onPickFile}
                />
                <UploadButton />
                <StyledTextButton onClick={loadCorporateAllocations}>
                  {t("uploadCorporateAllocation.keywords.uploadButton")}
                </StyledTextButton>
                <UseWorksheetHelpModalButtonContainer />
              </Protected>
            </StyledCorporateAllocationUpload>
          ),
          rightActionButtons,
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 25,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {filtersModal}
      {deletionModal}
      {creationModal}
      {editingModal}
      {uploadModal}
      {useWorksheetHelpModal}
    </>
  );
};
