import { YearMonth } from "../../../../../../classes/data/date/YearMonth";
import { StyledCalendarGridBody } from "../../shared/styles/grid.styles";
import { CalendarCellContent } from "../../shared/types/props.types";

interface OwnProps {
  day: number;
  yearMonth: YearMonth;
  CalendarCellContent: CalendarCellContent;
}

export const CalendarCell = ({
  day,
  yearMonth,
  CalendarCellContent,
}: OwnProps) => {
  return (
    <StyledCalendarGridBody>
      <CalendarCellContent day={day} yearMonth={yearMonth} />
    </StyledCalendarGridBody>
  );
};
