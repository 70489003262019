import { useTranslation } from "react-i18next";
import { HelpIconButton } from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/HelpIconButton";
import { TipPopoverButton } from "../../../../../../../../../../shared/common/react/components/popovers/popoverButtons/TipPopoverButton";

export const OtherHoursHelpIconButton = () => {
  const { t } = useTranslation();

  return (
    <TipPopoverButton
      button={<HelpIconButton size="small" />}
      content={
        <div>
          {t("timesheets.info.data.calendar.hoursBesideDayExplanation")}
        </div>
      }
    />
  );
};
