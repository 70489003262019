import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesCostMdo } from "./types/form.types";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CostMdoReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/CostMdoReportFilterDTO";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { generateCostMdoReport } from "../../../../../../../../../services/reports/reports.service";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";

export const CostMdo = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesCostMdo>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesCostMdo = {
      yearMonth: null,
      subsidiary: null,
    };

    const validationSchema = yup.object({
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(),
      subsidiary: yup.object().required(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (values: FormValuesCostMdo) => {
    const formattedFilters: CostMdoReportFilterDTO = {
      yearMonth: values?.yearMonth
        ? YearMonth.createFromDate(values.yearMonth)
        : undefined,
      idSubsidiary: values?.subsidiary?.id ?? undefined,
    };
    await generateCostMdoReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="yearMonth"
              datePickerProps={{
                label: t("reports.pages.form.period"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <SubsidiariesAutocompleteFormik
              name="subsidiary"
              textfieldProps={{
                required: true,
              }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
