import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../../common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../../common/types/data/form/autocomplete.types";
import { EmployeeAllocationType } from "../../../../../../../../enums/allocations/EmployeeAllocationType.enums";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../maps/allocations/EmployeeAllocationType.maps";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<EmployeeAllocationType>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const TimesheetCostCenterAllocationTypeAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const options = useMemo(() => {
    const filteredAllocationType = Object.values(EmployeeAllocationType)
      .filter((x) => typeof x === "number")
      .map((x) => x as EmployeeAllocationType)
      .filter((x) => {
        if (x === EmployeeAllocationType.SupplementaryAllocation) return false;
        return ![
          EmployeeAllocationType.Allocation,
          EmployeeAllocationType.MonthlyOnboarding,
          EmployeeAllocationType.PerformanceEvaluation,
          EmployeeAllocationType.CorporateMeeting,
        ].includes(x);
      });

    return filteredAllocationType.map(
      (x): AutocompleteOption<EmployeeAllocationType> => ({
        id: x,
        label: t(employeeAllocationTypeToTranslationCodeMap[x]),
      })
    );
  }, [i18n.language]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        label: t("general.keywords.fields.type"),
        ...textfieldProps,
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
