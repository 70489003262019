import { RestrictionsOperand } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { OrPermissions } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/OrPermissions";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";

export const projectReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Project,
  level: PermissionLevel.Read,
};

export const projectRegisterReadRestrictions: RestrictionsOperand = {
  type: PermissionType.ProjectRegister,
  level: PermissionLevel.Read,
};

export const frameReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Frame,
  level: PermissionLevel.Read,
};

export const projectsBaselinesRestrictions: RestrictionsOperand = {
  type: PermissionType.ProjectBaselines,
  level: PermissionLevel.Read,
};

export const corporateAllocationsReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CorporateAllocations,
  level: PermissionLevel.Read,
};

export const collaboratorAllocationsReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CollaboratorAllocation,
  level: PermissionLevel.Read,
};

export const resultsProjectionReadRestrictions: RestrictionsOperand = {
  type: PermissionType.ResultsProjection,
  level: PermissionLevel.Read,
};

export const budgetReadRestrictions: RestrictionsOperand = {
  type: PermissionType.Budget,
  level: PermissionLevel.Read,
};

export const collaboratorManagementReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CollaboratorManagement,
  level: PermissionLevel.Read,
};

export const costDistributionReadRestrictions: RestrictionsOperand = {
  type: PermissionType.CostDistribution,
  level: PermissionLevel.Read,
};

export const managementReadRestrictions = new OrPermissions(
  projectReadRestrictions,
  frameReadRestrictions,
  projectsBaselinesRestrictions,
  corporateAllocationsReadRestrictions,
  collaboratorAllocationsReadRestrictions,
  resultsProjectionReadRestrictions,
  budgetReadRestrictions,
  collaboratorManagementReadRestrictions,
  costDistributionReadRestrictions
);
