import * as yup from "yup";
import { saveAs } from "file-saver";
import i18next from "i18next";
import { formatFilename } from "../../../shared/common/helpers/data/general/formatters/fileFormatters.helpers";
import { ContractRoleCreateDTO } from "../../../shared/specific/DTOs/ContractRole/ContractRoleCreateDTO";
import {
  ContractRoleDTO,
  schemaContractRoleDTO,
} from "../../../shared/specific/DTOs/ContractRole/ContractRoleDTO";
import { ContractRoleUpdateDTO } from "../../../shared/specific/DTOs/ContractRole/ContractRoleUpdateDTO";
import { ContractRoleFiltersDTO } from "../../../shared/specific/DTOs/ContractRole/filters/ContractRoleFiltersDTO";
import {
  PaginatedContractRoleDTO,
  schemaPaginatedContractRoleDTO,
} from "../../../shared/specific/DTOs/ContractRole/PaginatedContractRoleDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";
import {
  ContractRoleDropdownDTO,
  schemaContractRoleDropdownDTO,
} from "../../../shared/specific/DTOs/ContractRole/ContractRoleDropdownDTO";

export const getContractRoles = async ({
  filters,
  pagination,
}: {
  filters?: ContractRoleFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedContractRoleDTO> => {
  const { data: paginatedContractRoles } = await riseApi.get("ContractRole", {
    params: { ...filters, ...pagination },
  });
  return schemaPaginatedContractRoleDTO.validateSync(paginatedContractRoles);
};

export const getContractRolesDropdown = async (): Promise<
  ContractRoleDropdownDTO[]
> => {
  const { data: ContractRolesDropdown } = await riseApi.get(
    "ContractRole/Dropdown"
  );
  return yup
    .array(schemaContractRoleDropdownDTO)
    .defined()
    .validateSync(ContractRolesDropdown);
};

export const generateContractRolesSpreadsheet = async ({
  filters,
  filename,
}: {
  filters?: ContractRoleFiltersDTO;
  filename?: string;
}) => {
  const { data: spreadsheetBlob } = await riseApi.get(
    "ContractRole/Spreadsheet",
    {
      params: filters,
      responseType: "blob",
    }
  );

  const finalFilename =
    filename ??
    formatFilename(i18next.t("contractRole.keywords.general.contractRoles"), {
      extension: "xlsx",
    });

  saveAs(
    new Blob([spreadsheetBlob], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;" +
        "charset=utf-8",
    }),
    finalFilename
  );
};

export const createContractRole = async (
  contractRole: ContractRoleCreateDTO
) => {
  await riseApi.post("ContractRole", contractRole);
};

export const deleteContractRole = async (id: number, version: string) => {
  await riseApi.delete(`ContractRole/${id}/${version}`);
};

export const getContractRoleById = async (
  id: number
): Promise<ContractRoleDTO | null> => {
  const { data: contractRole } = await riseApi.get(`ContractRole/${id}`);

  return schemaContractRoleDTO.nullable().validateSync(contractRole);
};

export const updateContractRole = async (
  contractRole: ContractRoleUpdateDTO
) => {
  await riseApi.put("ContractRole", contractRole);
};
