import { FeeYearMonthCreateDTO } from "../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthCreateDTO";
import {
  FeeYearMonthDTO,
  schemaFeeYearMonthDTO,
} from "../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthDTO";
import { FeeYearMonthFiltersDTO } from "../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthFiltersDTO";
import { FeeYearMonthUpdateDTO } from "../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthUpdateDTO";
import {
  PaginatedFeeYearMonthDTO,
  schemaPaginatedFeeYearMonthDTO,
} from "../../../shared/specific/DTOs/feeYearMonth/PaginatedFeeYearMonthDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getFeeYearMonths = async ({
  filters,
  pagination,
}: {
  filters: FeeYearMonthFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedFeeYearMonthDTO> => {
  const { data: feeYearMonths } = await riseApi.get("FeeYearMonth", {
    params: { ...pagination, ...filters },
  });

  return schemaPaginatedFeeYearMonthDTO.validateSync(feeYearMonths);
};

export const getFeeYearMonthyId = async (
  id: number
): Promise<FeeYearMonthDTO | null> => {
  const { data: feeYearMonth } = await riseApi.get(`/FeeYearMonth/${id}`);
  return schemaFeeYearMonthDTO.nullable().validateSync(feeYearMonth);
};

export const createFeeYearMonth = async (
  feeYearMonth: FeeYearMonthCreateDTO
) => {
  await riseApi.post("FeeYearMonth", feeYearMonth);
};

export const updateFeeYearMonth = async (
  feeYearMonth: FeeYearMonthUpdateDTO
) => {
  await riseApi.put("FeeYearMonth", feeYearMonth);
};

export const deleteFeeYearMonth = async (id: number, version: string) => {
  await riseApi.delete(`FeeYearMonth/${id}/${version}`);
};
