import { useMemo } from "react";
import { Order } from "../../../types/data.types";
import { TableContextSortingValues } from "../index.types";

interface OwnProps {
  order: Order;
  orderBy: number | null;
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: number | null) => void;
}

export const useSortingContextData = ({
  order,
  orderBy,
  setOrder,
  setOrderBy,
}: OwnProps) => {
  const sorting = useMemo(() => {
    const onRequestSort = (columnPosition: number) => {
      if (orderBy !== columnPosition) {
        setOrderBy(columnPosition);
        setOrder("asc");
        return;
      }

      setOrder(order === "asc" ? "desc" : "asc");
    };

    const sorting: TableContextSortingValues = {
      order,
      orderBy,
      onRequestSort,
    };

    return sorting;
  }, [order, orderBy]);

  return sorting;
};
