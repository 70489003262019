import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createTranslatableFieldValues } from "../../../../../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { SkillDataFormValues } from "../../../../types/form.types";

export const useCreationEditingDefaultFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: SkillDataFormValues = useMemo(() => {
    return {
      namePt: "",
      nameEn: "",
      skillCategory: null,
      discipline: null,
      deactivatedDate: null,
      nameTranslations: createTranslatableFieldValues(),
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      namePt: yup
        .string()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      nameEn: yup.string().nullable(),
      discipline: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      skillCategory: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
