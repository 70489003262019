import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { FeeYearMonthDTO, schemaFeeYearMonthDTO } from "./FeeYearMonthDTO";

export type PaginatedFeeYearMonthDTO = PaginationResponse<FeeYearMonthDTO>;

export const schemaPaginatedFeeYearMonthDTO =
  getSchemaPaginationResponse<FeeYearMonthDTO>(
    schemaFeeYearMonthDTO as yup.Schema<FeeYearMonthDTO>
  );
