import { Box, css, styled } from "@mui/material";
import { EnhancedCellDataPadding } from "../../../shared/types/data.types";

interface OwnProps {
  paddingmode?: EnhancedCellDataPadding;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  minheadcellheight: number;
}

export const StyledTableCellHeadContainer = styled(Box)<OwnProps>`
  display: flex;
  align-items: center;
  border-bottom-width: 1px;
  overflow-x: hidden;
  min-height: ${({ minheadcellheight }) => minheadcellheight}px;

  ${({ align }) =>
    align &&
    css`
      justify-content: ${align};
    `};

  ${({ theme, paddingmode }) => {
    switch (paddingmode) {
      case "vertical":
        return css`
          padding: ${theme.spacing(1)} 0;
        `;
      case "horizontal":
        return css`
          padding: 0 ${theme.spacing(2)};
        `;
      case "both":
        return css`
          padding: ${theme.spacing(1)} ${theme.spacing(2)};
        `;
      case "none":
      default:
        return null;
    }
  }}
`;
