import { useTranslation } from "react-i18next";
import { AllocationsOfUserDTO } from "../../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { UseAssignAllocationGroupToUserFormProps } from "../../useAssignAllocationGroupToUserForm";
import { StyledButtonBase, StyledCarouselCell } from "./index.styles";

interface OwnProps {
  allocationPerUserRow: AllocationsOfUserDTO;
  AssigningAllocationUserButton: (
    props: UseAssignAllocationGroupToUserFormProps
  ) => JSX.Element | null;
  isUserColaboratorEditable: boolean;
}

export const UserColaboratorButtonCell = ({
  allocationPerUserRow,
  AssigningAllocationUserButton,
  isUserColaboratorEditable,
}: OwnProps) => {
  const { t } = useTranslation();

  if (!isUserColaboratorEditable)
    return <div>{allocationPerUserRow.name ?? "-"}</div>;

  return (
    <AssigningAllocationUserButton allocationPerUserRow={allocationPerUserRow}>
      <StyledButtonBase>
        <StyledCarouselCell>
          {t("allocations.actions.data.assignAllocationsToColaborator")}
        </StyledCarouselCell>
      </StyledButtonBase>
    </AssigningAllocationUserButton>
  );
};
