import { createContext } from "react";

export interface AllocationsSuperUserContextValues {
  canEditAllAllocations: boolean;
  canEditAllAllocationsButton: JSX.Element | null;
}

export const AllocationsSuperUserContext =
  createContext<AllocationsSuperUserContextValues>({
    canEditAllAllocations: false,
    canEditAllAllocationsButton: null,
  });
