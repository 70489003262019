import { ReportOption } from "../../../shared/types/enums/general.enum";
import { CreationProjectsAdditives } from "./CreationProjectsAdditives";
import { DatailedProjectsOverview } from "./DatailedProjectsOverview";
import { PerformedTimesheet } from "./PerformedTimesheet";
import { EapReport } from "./EapReport";
import { GlobalAllocation } from "./GlobalAllocation";
import { RevenueAndBilling } from "./RevenueAndBilling";
import { ProjectsOverview } from "./ProjectsOverview";
import { StatusOfProjects } from "./StatusOfProjects";
import { ProjectTimesheet } from "./ProjectTimesheet";
import { ProjectMVs } from "./ProjectMVs";
import { WeeklyAllocation } from "./WeeklyAllocation";
import { ProvisionReport } from "./ProvisionReport";
import { BaseCollaborators } from "./BaseCollaborator";
import { PepElement } from "./PepElement";
import { BudgetOverview } from "./BudgetOverview";
import { PlannedAndPerformedByPepReport } from "./PlannedAndPerformedByPepReport";
import { BillingVision } from "./BillingVision";
import { CostMdo } from "./CostMdo";
import { Zah } from "./ZAH";
import { TurnoverDays } from "./TurnoverDaysTimesheet";
import { MvWithFinancialCost } from "./MvWithFinancialCost";

interface OwnProps {
  id: ReportOption;
}

export const ReportForm = ({ id }: OwnProps) => {
  const ReportFormTypes: {
    [key in ReportOption]: JSX.Element;
  } = {
    [ReportOption.PerformedTimesheet]: <PerformedTimesheet />,
    [ReportOption.GlobalAllocation]: <GlobalAllocation />,
    [ReportOption.ProjectTimesheet]: <ProjectTimesheet />,
    [ReportOption.RevenueAndBilling]: <RevenueAndBilling />,
    [ReportOption.EapReport]: <EapReport />,
    [ReportOption.ProjectsOverview]: <ProjectsOverview />,
    [ReportOption.DatailedProjectsOverview]: <DatailedProjectsOverview />,
    [ReportOption.StatusOfProjects]: <StatusOfProjects />,
    [ReportOption.CreationProjectsAdditives]: <CreationProjectsAdditives />,
    [ReportOption.ProjectMVs]: <ProjectMVs />,
    [ReportOption.WeeklyAllocation]: <WeeklyAllocation />,
    [ReportOption.ProvisionReport]: <ProvisionReport />,
    [ReportOption.BaseCollaborators]: <BaseCollaborators />,
    [ReportOption.PepElement]: <PepElement />,
    [ReportOption.BudgetOverview]: <BudgetOverview />,
    [ReportOption.PlannedAndPerformedByPepReport]: (
      <PlannedAndPerformedByPepReport />
    ),
    [ReportOption.BillingVision]: <BillingVision />,
    [ReportOption.CostMdo]: <CostMdo />,
    [ReportOption.Zah]: <Zah />,
    [ReportOption.TurnoverDays]: <TurnoverDays />,
    [ReportOption.MvWithFinancialCost]: <MvWithFinancialCost />,
  };

  return ReportFormTypes[id];
};
