import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SkillFilterDTO } from "../../../../../../../shared/specific/DTOs/skill/filters/SkillFilterDTO";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { getSkills } from "../../../../../../../services/skill/skill.service";
import { throwIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import { useEditingForm } from "./useEditingForm";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { useDeletionForm } from "./useDeletionForm";

interface OwnProps {
  filters?: SkillFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      t("disciplines.keywords.fields.horizontal"),
      t("disciplines.keywords.general.discipline"),
      t("skillCategory.keywords.general.category"),
      t("jobRoles.keywords.general.deactivatedDate"),
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onSkillPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage }) => {
        const headKeys = ["name", "deactivatedDate"];

        try {
          const {
            tableData: skillsData,
            page,
            totalCount,
          } = await getSkills({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = skillsData.map(
            (skillData): ExternalEnhancedRow => ({
              id: skillData.id,
              cells: [
                skillData.skillTranslation.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? skillData.name,
                skillData.skillCategory.discipline.horizontal?.horizontalTranslation.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ??
                  skillData.skillCategory.discipline.horizontal?.name ??
                  "-",
                skillData.skillCategory.discipline.name,
                skillData.skillCategory.skillCategoryTranslation.find(
                  (x) => x.idLanguage === i18n.language
                )?.name ?? skillData.name,
                {
                  value: skillData.deactivatedDate,
                  displayValue:
                    skillData.deactivatedDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "",
                },
                {
                  CellRenderer: () => (
                    <>
                      <Protected
                        restrictions={{
                          type: PermissionType.Skill,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer {...skillData} />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.Skill,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...skillData} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters]
    );

  return {
    headCells,
    onSkillPageChange,
    deleteDialog: deletionModal,
    editingModal,
  };
};
