import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingBackdrop } from "../../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { downloadProjectionSpreadsheet } from "../../../../../../../../../../services/projection/projection.service";

interface OwnProps {
  idDivision: number | null;
  idSegmentGroup: number | null;
  year: number | null;
}

export const useDownloadDre = ({
  idDivision,
  idSegmentGroup,
  year,
}: OwnProps) => {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const isDisabledDownloadDre = useMemo(() => {
    if (!idDivision || !year) return true;
    return false;
  }, [idDivision, idSegmentGroup, year]);

  const OnClickButton = useCallback(async () => {
    setIsLoad(true);

    await downloadProjectionSpreadsheet({
      IdDivision: idDivision,
      GroupSegmentId: idSegmentGroup,
      Year: year,
    });

    setIsLoad(false);
  }, [isDisabledDownloadDre, idDivision, idSegmentGroup, year]);

  const button = useMemo(() => {
    return (
      <Button
        onClick={OnClickButton}
        disabled={isDisabledDownloadDre}
        variant="contained"
        endIcon={<DownloadIcon />}
      >
        {t("downloadDreSpreadsheet.keywords.downloadButton")}
      </Button>
    );
  }, [isDisabledDownloadDre, isLoad, idDivision, idSegmentGroup, year]);

  return (
    <>
      {button}
      <LoadingBackdrop open={isLoad} />
    </>
  );
};
