import { styled } from "@mui/material";
import { HTMLProps } from "react";

interface OwnProps extends HTMLProps<HTMLDivElement> {
  stickyHeight?: number;
}

const PureStyledTabContent = ({ stickyHeight, ...rest }: OwnProps) => (
  <div {...rest} />
);

export const StyledTabContent = styled(PureStyledTabContent)`
  flex: 1;
  max-height: calc(100% - ${({ stickyHeight }) => stickyHeight ?? 0}px);
  padding: 0;

  .MuiTabPanel-root {
    padding: 0;
    height: 100%;
  }
`;
