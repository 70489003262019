import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonInvoicesFormValues } from "../../../types/form/general.types";
import { AutocompleteOption } from "../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { getNowBrazilianDate } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { parseLocaleNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

interface OwnProps {
  projectWithRelatedInfo: ProjectWithRelatedInfoDTO | null;
}

export const useMainFormikConfig = ({ projectWithRelatedInfo }: OwnProps) => {
  const { t } = useTranslation();

  const initialValues: CommonInvoicesFormValues = useMemo(() => {
    return {
      responsible: null,
      status: null,
      percentageToCancel: "",
      reasonForCancellation: "",
      reasonForReturn: "",
      invoiceNumber: "",
      issueDate: null,
      cancellationDate: null,
      plannedCancellationDate: null,
      invoiceFilesToUpload: [],
      invoiceFilesToDelete: [],
      dueDate: null,
      paymentDate: null,
      pddDate: null,
      lossDate: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      responsible: yup.object().nullable(),
      reasonForReturn: yup.string().when("status", (statusValue, schema) => {
        const status =
          statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
        if (!status) return schema;

        if (![InvoicesStatusEnum.Returned].includes(status.id)) return schema;

        return schema.test({
          name: "After end",
          message: t("general.errors.data.fields.general.required"),
          test: (reasonForReturn: string | null | undefined) => {
            if (!reasonForReturn) return false;
            return true;
          },
        });
      }),
      reasonForCancellation: yup
        .string()
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (
            ![
              InvoicesStatusEnum.Canceled,
              InvoicesStatusEnum.ProvisionForCancellation,
            ].includes(status.id)
          )
            return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (reasonForCancellation: string | null | undefined) => {
              if (!reasonForCancellation) return false;
              return true;
            },
          });
        }),
      invoiceNumber: yup.string().when("status", (statusValue, schema) => {
        const status =
          statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
        if (!status) return schema;

        if (status.id !== InvoicesStatusEnum.Issued) return schema;

        return schema.test({
          name: "After end",
          message: t("general.errors.data.fields.general.required"),
          test: (invoiceNumber: string | null | undefined) => {
            if (!invoiceNumber) return false;
            return true;
          },
        });
      }),
      issueDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .when(
          "status",
          (status: AutocompleteOption<InvoicesStatusEnum>[] | null, schema) => {
            if (!status || status.length === 0) return schema;

            if (status[0].id !== InvoicesStatusEnum.Issued) return schema;

            return schema.test({
              name: "After end",
              message: t("general.errors.data.fields.general.required"),
              test: (issueDate: Date | null | undefined) => {
                if (!issueDate) return false;
                return true;
              },
            });
          }
        ),
      dueDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .when(
          "status",
          (status: AutocompleteOption<InvoicesStatusEnum>[] | null, schema) => {
            if (!status || status.length === 0) return schema;
            if (status[0].id !== InvoicesStatusEnum.Issued) return schema;

            return schema.test({
              name: "After end",
              message: t("general.errors.data.fields.general.required"),
              test: (dueDate: Date | null | undefined) => {
                if (!dueDate) return false;
                return true;
              },
            });
          }
        ),
      cancellationDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            if (this.parent.status.id !== InvoicesStatusEnum.Canceled)
              return true;
            const minDate = getNowBrazilianDate();
            minDate.setDate(0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.Canceled) return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (cancelationDate: Date | null | undefined) => {
              if (!cancelationDate) return false;
              return true;
            },
          });
        }),
      plannedCancellationDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            if (
              this.parent.status.id !==
              InvoicesStatusEnum.ProvisionForCancellation
            )
              return true;
            const minDate = getNowBrazilianDate();
            minDate.setDate(0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.ProvisionForCancellation)
            return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (plannedCancelationDate: Date | null | undefined) => {
              if (!plannedCancelationDate) return false;
              return true;
            },
          });
        }),
      percentageToCancel: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.ProvisionForCancellation)
            return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (amountToCancel) => {
              if (
                !amountToCancel ||
                amountToCancel === 0 ||
                (amountToCancel as unknown as string) === ""
              ) {
                return false;
              }
              return true;
            },
          });
        }),
      paymentDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            if (this.parent.status.id !== InvoicesStatusEnum.PaidOut)
              return true;
            const minDate = getNowBrazilianDate();
            minDate.setDate(0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.PaidOut) return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (paymentDate: Date | null | undefined) => {
              if (!paymentDate) return false;
              return true;
            },
          });
        }),
      pddDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            if (this.parent.status.id !== InvoicesStatusEnum.Pdd) return true;
            const minDate = getNowBrazilianDate();
            minDate.setDate(0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.Pdd) return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (paymentDate: Date | null | undefined) => {
              if (!paymentDate) return false;
              return true;
            },
          });
        }),
      lossDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .test({
          name: "teste",
          message: t("invoice.errors.data.update.minDateLimit"),
          test(value) {
            if (this.parent.status.id !== InvoicesStatusEnum.Loss) return true;
            const minDate = getNowBrazilianDate();
            minDate.setDate(0);
            if (value && value < minDate) return false;
            return true;
          },
        })
        .when("status", (statusValue, schema) => {
          const status =
            statusValue[0] as AutocompleteOption<InvoicesStatusEnum> | null;
          if (!status) return schema;

          if (status.id !== InvoicesStatusEnum.Loss) return schema;

          return schema.test({
            name: "After end",
            message: t("general.errors.data.fields.general.required"),
            test: (paymentDate: Date | null | undefined) => {
              if (!paymentDate) return false;
              return true;
            },
          });
        }),
      status: yup
        .object()
        .defined()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t, projectWithRelatedInfo]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
