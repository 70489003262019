import { CreateJobRoleDTO } from "../../../shared/specific/DTOs/jobRole/CreateJobRoleDTO";
import { JobRoleListByFiltersDTO } from "../../../shared/specific/DTOs/jobRole/filters/JobRoleListByFiltersDTO";
import {
  JobRoleDTO,
  schemaJobRoleDTO,
} from "../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { JobRoleUpdateDTO } from "../../../shared/specific/DTOs/jobRole/JobRoleUpdateDTO";
import {
  PaginatedJobRoleDTO,
  schemaPaginatedJobRoleDTO,
} from "../../../shared/specific/DTOs/jobRole/PaginatedJobRoleDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getJobRoles = async ({
  filters,
  pagination,
}: {
  filters?: JobRoleListByFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedJobRoleDTO> => {
  const { data: jobRoles } = await riseApi.get("/JobRole", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedJobRoleDTO.validateSync(jobRoles);
};

export const getJobRoleById = async (
  id: number
): Promise<JobRoleDTO | null> => {
  const { data: jobRole } = await riseApi.get(`JobRole/${id}`);

  return schemaJobRoleDTO.nullable().validateSync(jobRole);
};

export const createJobRole = async (jobRole: CreateJobRoleDTO) => {
  await riseApi.post("JobRole", jobRole);
};

export const deleteJobRole = async (id: number, version: string) => {
  await riseApi.delete(`JobRole/${id}/${version}`);
};

export const updateJobRole = async (jobRole: JobRoleUpdateDTO) => {
  await riseApi.put("JobRole", jobRole);
};
