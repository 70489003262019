import { TableRow } from "@mui/material";
import { memo, useContext, useMemo } from "react";
import { TableContext } from "../../shared/contexts/TableContext";
import { EnhancedRowData } from "../../shared/types/data.types";
import { EnhancedTableCell } from "./EnhancedTableCell";

interface OwnProps {
  row: EnhancedRowData;
}

export const EnhancedTableRow = memo(({ row }: OwnProps) => {
  const { headCells } = useContext(TableContext);

  const cells = useMemo(() => {
    return row.cells.length <= headCells.length
      ? row.cells
      : row.cells.slice(0, headCells.length);
  }, [row.cells, headCells.length]);

  const FinalTableRow = useMemo(() => {
    return row.CustomTableRow ?? TableRow;
  }, [row.CustomTableRow]);

  return useMemo(() => {
    return (
      <FinalTableRow hover tabIndex={-1}>
        {cells.map((cell, index) => (
          <EnhancedTableCell
            key={index}
            cell={cell}
            position={index}
            row={row}
          />
        ))}
      </FinalTableRow>
    );
  }, [cells, FinalTableRow]);
});
