import { css, Global } from "@emotion/react";
import { CssBaseline, useTheme } from "@mui/material";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const GlobalStyles = () => {
  const theme = useTheme();

  const globalStyles = css`
    html {
      font-size: 14px;
    }

    body {
      margin: 0;
      font-family:
        "Roboto",
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      color: ${theme.palette.text.primary};
    }

    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
  `;

  return (
    <>
      <CssBaseline />
      <Global styles={globalStyles} />
    </>
  );
};
