import { Tab } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabInfo } from "./index.types";
import { TabCode } from "../../shared/types/TabCode.enums";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { usePermissionChecker } from "../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";

export const useTabs = () => {
  const { t } = useTranslation();

  const { hasPermission: canReadFee } = usePermissionChecker({
    restrictions: {
      type: PermissionType.FeeTypes,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadFeePeriod } = usePermissionChecker({
    restrictions: {
      type: PermissionType.FeePeriod,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadFeeSummary } = usePermissionChecker({
    restrictions: {
      type: PermissionType.FeeSummary,
      level: PermissionLevel.Read,
    },
  });

  const tabs = useMemo(() => {
    const tabsInfo: (TabInfo | null)[] = [];

    if (canReadFeeSummary) {
      tabsInfo.push({
        label: t("planningSummary.keywords.general.summary"),
        value: TabCode.Summary,
      });
    }

    if (canReadFeePeriod)
      tabsInfo.push({
        label: t("fee.keywords.general.feePeriod"),
        value: TabCode.Period,
      });

    if (canReadFee)
      tabsInfo.push({
        label: t("fee.keywords.fields.typesOfFee"),
        value: TabCode.TypeOfFee,
      });

    const tabsInfoNotNull = tabsInfo.filter((x) => !!x) as TabInfo[];

    return tabsInfoNotNull.map(({ label, value }) => (
      <Tab label={label} value={value} key={value} />
    ));
  }, [t]);

  return tabs;
};
