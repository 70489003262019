import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useCallback } from "react";
import { AddIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/AddIconButton";
import {
  OnClickContentButtonFunction,
  TwoSidedModalActions,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "./hooks/useFormikConfig";
import {
  FormValues,
  InternalFormData,
} from "./shared/types/data/form/values.types";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { CreationForm } from "./CreationForm";
import { mofifyTimesheet } from "../../../../../../../services/timesheet/timesheets.service";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormButtonComponent } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/props.types";
import { useCanModifyMonth } from "../../shared/react/hooks/general/validation/useCanModifyMonth";
import { generateDaysForMonth } from "../../shared/helpers/data/calendar/generators.helpers";
import { sleep } from "../../../../../../../shared/common/helpers/general.helpers";
import { parseHourStringToMinutes } from "../../../../../../../shared/common/helpers/data/dates/parsers.helpers";
import { validateDaysTimesheetValuesExceeded } from "../../shared/helpers/data/form/validators.helpers";
import { OtherHoursHelpIconButton } from "../../shared/react/components/buttons/IconButtons/OtherHoursHelpIconButton";
import { getCostCenterLoggedUser } from "../../../../../../../services/collaboratorHistory/collaboratorHistory.service";

interface OwnProps {
  yearMonth: YearMonth;
  reloadTablePage: () => void;
}

export const useCreationForm = ({ yearMonth, reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();
  const { canModifyYearMonth } = useCanModifyMonth({ yearMonth });

  const onClickContentButton = useCallback<
    OnClickContentButtonFunction<FormValues, InternalFormData>
  >(
    async ({ setInternalFormData, setFormValues }) => {
      const days = generateDaysForMonth(yearMonth);

      const costCenterCollaborator = await getCostCenterLoggedUser(yearMonth);

      setFormValues({
        ...formikConfig.initialValues,
        yearMonth,
        days,
      });
      setInternalFormData({
        yearMonth,
        timesheetMonthWithOthers: null,
        isCalendarVisible: false,
        idSubsidiaryLoggedUser: costCenterCollaborator?.subsidiary.id,
      });
    },
    [yearMonth, formikConfig.initialValues]
  );

  const FormButton = useCallback<FormButtonComponent>(
    ({ onClick }) => {
      if (!canModifyYearMonth) return <AddIconButton disabled />;

      return (
        <AddIconButton
          onClick={onClick}
          tooltipText={t("general.actions.general.add")}
        />
      );
    },
    [t, yearMonth, canModifyYearMonth]
  );

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<FormValues, InternalFormData>({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("timesheets.modal.data.create.title"),
        onCloseModal: async ({
          setFormValues,
          formValues,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          const currentModalCount = getOpenCloseModalCount();
          await sleep(500);

          if (!checkInCurrentModalCount(currentModalCount)) return;

          setFormValues({
            ...formValues,
            costCenter: null,
            project: null,
          });
        },
      },
      button: {
        FormButton,
        onClickContentButton,
      },
      form: {
        formikConfig,
        FormContentMemo: CreationForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          internalFormData,
        }) => {
          const { t } = useTranslation();

          return (
            <TwoSidedModalActions>
              <div>
                {internalFormData?.isCalendarVisible && (
                  <OtherHoursHelpIconButton />
                )}
              </div>
              <div>
                <Button onClick={closeModal} disabled={isSubmitting}>
                  {t("general.actions.general.cancel")}
                </Button>
                <LoadingButton
                  loading={isSubmitting}
                  onClick={submitFormValues}
                  variant="contained"
                >
                  {t("general.actions.general.save")}
                </LoadingButton>
              </div>
            </TwoSidedModalActions>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting, setFieldError },
          closeModal,
          internalFormData,
        }) => {
          if (!formValues.allocationType)
            throw new Error("'allocationType' cannot be null on submit.");

          const allDaysAreEmpty = formValues.days.every(
            (x) => x.time === "00:00"
          );
          if (allDaysAreEmpty) {
            notifyIf4xxApiErrorDTO({
              error: "all days are empty",
              defaultMessage:
                "timesheets.errors.data.fields.days.allDaysAreEmpty",
            });
            return;
          }

          const hasFoundErrors = validateDaysTimesheetValuesExceeded(
            formValues.days,
            internalFormData?.timesheetMonthWithOthers
              ?.otherProjectOrCostCenterDays ?? null,
            setFieldError
          );
          if (hasFoundErrors) return;

          try {
            await mofifyTimesheet({
              yearMonth: formValues.yearMonth,
              allocationType: formValues.allocationType.id,
              idCostCenter: formValues.costCenter?.id,
              idCorporateSegment: formValues.corporateSegment?.id,
              idProject: formValues.project?.id,
              days: formValues.days.map((x) => ({
                id: x.id,
                version: x.version,

                day: x.day,
                minutes: parseHourStringToMinutes(x.time),
              })),
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "timesheets.errors.data.create.failedToCreateTimesheetEntry",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
};
