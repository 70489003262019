import * as yup from "yup";

export interface SubsidiaryDTO {
  id: number;
  name: string;
  prefix: string;
  currency: string;
  currencySymbol: string;
}

export const schemaSubsidiaryDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  prefix: yup.string().defined(),
  currency: yup.string().defined(),
  currencySymbol: yup.string().defined(),
});
