import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useCreationEditingFormikConfig } from "../shared/hooks/validation/useCreationEditingFormikConfig";
import { createBudgetRound } from "../../../../../services/budgetRound/BudgetRound.service";
import { BudgetRoundCommomForm } from "../shared/components/BudgetRoundCommomForm";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useCreationEditingFormikConfig();

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: () => {
        const { t } = useTranslation();

        return <>{t("budget.modal.data.create.createBudgetRound")}</>;
      },
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ ...rest }) => <BudgetRoundCommomForm {...rest} />,
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          await createBudgetRound({
            name: formValues.name,
            beginDate: formValues.beginDate ?? new Date(),
            endDate: formValues.endDate ?? new Date(),
          });
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "budget.errors.data.create.failedToCreateBudgetRound",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
