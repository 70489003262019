import { createContext } from "react";

export interface CollaboratorTrackSuperUserContextValues {
  canEditCollaboratorTrackWithoutRestrictions: boolean;
  canEditCollaboratorTrackWithoutRestrictionsButton: JSX.Element | null;
}

export const CollaboratorTrackSuperUserContext =
  createContext<CollaboratorTrackSuperUserContextValues>({
    canEditCollaboratorTrackWithoutRestrictions: false,
    canEditCollaboratorTrackWithoutRestrictionsButton: null,
  });
