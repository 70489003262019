import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import * as useFormikModalButton from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormMode, FormValues } from "../shared/types/form.types";
import { StyledForm } from "./index.styles";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { WaccDTO } from "../../../../../../../../shared/specific/DTOs/wacc/WaccDTO";
import { generalFieldsConstants } from "../../../../../CustomerBalance/CustomerBalancePage/shared/constants/fields.constants";

type OwnProps = useFormikModalButton.FormContentProps<FormValues> & {
  formMode: FormMode;
  internalFormData?: WaccDTO | null;
};

export const CommonWaccForm = ({
  formikProps: { isSubmitting, values },
  formMode,
  internalFormData,
}: OwnProps) => {
  const { t } = useTranslation();

  const isEdit = formMode === "edit";

  return (
    <StyledForm>
      {!isEdit ? (
        <YearMonthDatePickerFormik
          name="yearMonth"
          datePickerProps={{
            label: t("wacc.keywords.fields.yearMonth"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
          }}
          textFieldProps={{ required: true, disabled: isSubmitting || isEdit }}
        />
      ) : (
        <TextField
          disabled
          label={t("wacc.keywords.fields.yearMonth")}
          value={internalFormData?.yearMonth.toPrettyString() ?? ""}
        />
      )}
      <LocalizedNumberTextFieldFormik
        name="plannedTax"
        scale={2}
        max={generalFieldsConstants.maxAmountValue}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("wacc.keywords.fields.plannedTax"),
          required: true,
          disabled: isSubmitting,
        }}
      />
      <LocalizedNumberTextFieldFormik
        name="performedTax"
        scale={2}
        max={generalFieldsConstants.maxAmountValue}
        overwrite="shift"
        padFractionalZeros
        textFieldProps={{
          label: t("wacc.keywords.fields.performedTax"),
          required: false,
          disabled: isSubmitting,
        }}
      />
    </StyledForm>
  );
};
