import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContextProvider";
import { StyledLine } from "../../shared/index.styles";
import {
  StyledAreaTextFields,
  StyledTextArea,
  StyledComponentAreas,
  StyledTitleContainer,
} from "./index.styles";
import { useEditingForm } from "./hooks/useEditingForm";
import { UserCollaboratorOnlyDTO } from "../../../../../../../shared/specific/DTOs/user/UserCollaboratorOnlyDTO";
import { cachedCountries } from "../../../../../../pages/authenticated/Customers/CustomersPage/shared/objects/data/cache/cachedFunctions.objects";
import { getStateFormValues } from "../../../../../../pages/authenticated/Customers/CustomersPage/shared/helpers/data/fetchers.helpers";

export const MyLocalityArea = () => {
  const { t } = useTranslation();
  const { userCollaboratorProfile, mode } = useContext(ProfileContext);
  const [state, setState] = useState<string>("");
  const [country, setCountry] = useState<string>("");

  const isReadOnly = mode === "viewing";

  const { EditingButtonContainer, editingModal } = useEditingForm();

  useEffect(() => {
    async function fetchData(userCollaborator: UserCollaboratorOnlyDTO) {
      const [countries] = await Promise.all([cachedCountries.run()]);
      const countryData =
        countries.find((x) => x.code === userCollaborator.country) ?? null;
      const { stateAutocomplete, stateTextField } = await getStateFormValues({
        country: countryData,
        customerState: userCollaborator.state,
      });
      setState(stateAutocomplete?.name ?? stateTextField ?? "");
      setCountry(countryData?.name ?? "");
    }

    if (userCollaboratorProfile)
      fetchData(userCollaboratorProfile.userCollaborator);
  }, [userCollaboratorProfile]);

  return useMemo(() => {
    if (!userCollaboratorProfile) return <div> </div>;
    return (
      <StyledComponentAreas>
        <StyledTitleContainer>
          <Typography variant="h6">
            {t("collaboratorProfile.keywords.fields.locality")}
          </Typography>
          {!isReadOnly && (
            <EditingButtonContainer
              {...userCollaboratorProfile.userCollaborator}
            />
          )}
        </StyledTitleContainer>
        <StyledLine />
        {country && state ? (
          <StyledAreaTextFields>
            <StyledTextArea
              label={t("general.keywords.fields.country")}
              value={country}
              InputProps={{
                readOnly: true,
              }}
            />
            <StyledTextArea
              label={t("general.keywords.fields.state")}
              value={state}
              InputProps={{
                readOnly: true,
              }}
            />
          </StyledAreaTextFields>
        ) : (
          <StyledAreaTextFields>
            <Typography>
              {t("collaboratorProfile.keywords.warnings.dontHaveLocality")}
            </Typography>
          </StyledAreaTextFields>
        )}
        {editingModal}
      </StyledComponentAreas>
    );
  }, [state, country]);
};
