import { Menu as MenuIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetPageTitle } from "../../../../services/applicationState/pageData.service";
import { useWindowSize } from "../../../../shared/common/react/hooks/ui/useWindowSize";
import { useGetLastVersion } from "../../../../shared/specific/react/components/GetLastVersion";
import { RootState } from "../../../../store";
import { LoggedLayoutContext } from "../shared/LoggedLayoutContext";
import {
  PageTitle,
  TopBarContainer,
  TopBarLeftItems,
  TopBarRightItems,
} from "./index.styles";
import { LanguageButton } from "./LanguageButton";
import { UserArea } from "./UserArea";

export const TopBar = () => {
  const { pathname } = useLocation();
  const { isBreakpointSm, isBreakpointMd } = useWindowSize();
  const { toggleIsMenuOpen } = useContext(LoggedLayoutContext);

  const { openModal, useGetLastVersionModal } = useGetLastVersion();

  const { title, Component } = useSelector(
    (state: RootState) => state.application.pageTitle
  );

  useEffect(() => {
    resetPageTitle();
  }, [pathname]);

  useEffect(() => {
    const currentVersion = window.localStorage.getItem("RiseCurrentVersion");
    const lastVersion = window.localStorage.getItem("RiseLastVersion");

    if (currentVersion !== lastVersion && lastVersion) {
      openModal();
    }
  }, [pathname]);

  return (
    <TopBarContainer>
      {isBreakpointSm && (
        <TopBarLeftItems>
          <IconButton onClick={toggleIsMenuOpen}>
            <MenuIcon />
          </IconButton>
        </TopBarLeftItems>
      )}
      <PageTitle>{Component ? <Component /> : title}</PageTitle>
      {!isBreakpointMd && (
        <TopBarRightItems>
          <LanguageButton />
          <UserArea />
        </TopBarRightItems>
      )}
      {useGetLastVersionModal}
    </TopBarContainer>
  );
};
