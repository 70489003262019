import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { FrameOnlyDTO, schemaFrameOnlyDTO } from "./FrameOnlyDTO";

export type PaginatedFrameOnlyDTO = PaginationResponse<FrameOnlyDTO>;

export const schemaPaginatedFrameOnlyDTO =
  getSchemaPaginationResponse<FrameOnlyDTO>(schemaFrameOnlyDTO);
