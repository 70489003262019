import i18next from "i18next";
import {
  Language,
  schemaLanguage,
} from "../../../../enums/locale/Language.enum";
import { checkIsValidType } from "../../../../helpers/data/validation/schema.helpers";
import { CachedParameterizedData } from "../CachedParameterizedData";
import { ContructorArguments, RunParameters } from "./index.types";

export class CachedTranslatedData<T> extends CachedParameterizedData<
  T,
  Language
> {
  constructor({ callback, defaultCacheDuration }: ContructorArguments<T>) {
    super({
      callback: (language) => callback(language),
      mapRunValueToKey: (language) => String(language),
      defaultCacheDuration,
    });
    this.run = this.run.bind(this);
  }

  public async run({ language, ...rest }: RunParameters = {}) {
    const { language: currentLanguage } = i18next;
    if (!checkIsValidType<Language>(schemaLanguage, currentLanguage))
      throw new Error(
        `Invalid language doesn't exist in the Language enum: ${currentLanguage}`
      );

    const finalLanguage = language ?? currentLanguage;
    return super.run({ value: finalLanguage, ...rest });
  }
}
