import axios from "axios";
import { graphApi } from "./connections";

export const getProfilePictureBlob = async () => {
  try {
    const { data } = await graphApi.get("photo/$value", {
      responseType: "blob",
    });
    return URL.createObjectURL(data);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 404) {
      return null;
    }

    throw error;
  }
};
