import { useCallback, useEffect, useState } from "react";
import { MonthlyAllocationClosingDTO } from "../../../DTOs/monthlyAllocationClosing/MonthlyAllocationClosingDTO";
import { CachedTranslatedData } from "../../../../common/classes/data/cache/CachedTranslatedData";
import { getMonthlyAllocationClosing } from "../../../../../services/monthlyAllocationClosing/monthlyAllocationClosing.service";
import { YearMonth } from "../../../../common/classes/data/date/YearMonth";

const cachedMonthlyAllocationClosing = new CachedTranslatedData({
  callback: getMonthlyAllocationClosing,
  defaultCacheDuration: 600000, // 10 minutes
});

export const useMonthlyAllocationClosing = () => {
  const [monthlyAllocationClosedList, setMonthlyAllocationClosingList] =
    useState<MonthlyAllocationClosingDTO[]>([]);

  useEffect(() => {
    cachedMonthlyAllocationClosing
      .run()
      .then((monthlyAllocationClosingList) => {
        setMonthlyAllocationClosingList(monthlyAllocationClosingList);
      });
  }, []);

  const checkIfMonthlyAllocationIsClosed = useCallback(
    (yearMonth: YearMonth): boolean => {
      const monthlyAllocationClosed = monthlyAllocationClosedList.some(
        (x) => x.yearMonth.toString() === yearMonth.toString()
      );
      const MONTHLY_ALLOCATION_CLOSING_CUT_LINE =
        YearMonth.createFromString("2023-01");

      const isMonthlyAllocationClosed =
        yearMonth < MONTHLY_ALLOCATION_CLOSING_CUT_LINE
          ? true
          : monthlyAllocationClosed;

      return isMonthlyAllocationClosed;
    },
    [monthlyAllocationClosedList]
  );

  return { monthlyAllocationClosedList, checkIfMonthlyAllocationIsClosed };
};
