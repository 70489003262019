import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { CustomerDTO, schemaCustomerDTO } from "./CustomerDTO";

export type PaginatedCustomerDTO = PaginationResponse<CustomerDTO>;

export const schemaPaginatedCustomerDTO =
  getSchemaPaginationResponse<CustomerDTO>(schemaCustomerDTO);
