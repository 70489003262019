import { useTranslation } from "react-i18next";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { getProjectActivitiesSummary } from "../../../../../../../../services/activities/activities.service";
import { CenteredContent } from "../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../shared/react/styles/ui/layouts/general.styles";
import { FormattedCurrency } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { StyledIncomeItem } from "./index.styles";
import { HeaderLoading } from "../../../../../../../../shared/common/react/components/ui/HeaderLoading";

interface OwnProps {}
export interface ActivitiesSummaryRef {
  reloadActivitySummary: () => void;
}

const ActivitiesSummaryWithinForwardRef = (
  props: OwnProps,
  ref: ForwardedRef<ActivitiesSummaryRef>
) => {
  const { t } = useTranslation();
  const { project, isLoadingProject } = useProjectContext();

  const {
    value: activitiesSummary,
    isLoading: isLoadingActivitiesSummary,
    hasError: activitiesSummaryHasError,
    reloadValue: reloadActivitySummary,
    errorStatusCode: activitiesErrorStatusCode,
  } = useLoadLatest({
    initialValue: null,
    getValue: () => getProjectActivitiesSummary(project.id),
    onError: ({ setValue }) => {
      setValue(null);
    },
  });

  useImperativeHandle(ref, () => ({ reloadActivitySummary }));

  if (
    (!activitiesSummary && isLoadingActivitiesSummary) ||
    (!project && isLoadingProject)
  )
    return <CenteredLoading stretchMode="horizontal" />;
  if (activitiesSummaryHasError)
    return (
      <CenteredContent stretchMode="horizontal">
        {activitiesErrorStatusCode === 403
          ? t("general.errors.authorization.unauthorizedAccessContent")
          : t("activities.errors.data.fetch.failedToFetchActivitySummary")}
      </CenteredContent>
    );
  if (!activitiesSummary)
    return (
      <CenteredContent stretchMode="horizontal">
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );

  return (
    <StyledSubHeaderFixed>
      {(isLoadingProject || isLoadingActivitiesSummary) && <HeaderLoading />}
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.projectBudget")}</div>
        <div>
          <FormattedCurrency
            value={project.billingAmountWithAdditives}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.fields.totalActivities")}</div>
        <div>
          <FormattedCurrency
            value={activitiesSummary.totalActivityAmount}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.general.activities")}</div>
        <div>{activitiesSummary.numberActivities}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.fields.deprovisionTotal")}</div>
        <div>
          <FormattedCurrency
            value={activitiesSummary.totalDeprovisionAmount}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.fields.income")}</div>
        <StyledIncomeItem
          color={
            project.billingAmountWithAdditives <=
            activitiesSummary.totalProjectIncome
              ? "success"
              : "error"
          }
        >
          <FormattedCurrency
            value={activitiesSummary.totalProjectIncome}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </StyledIncomeItem>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};

export const ActivitiesSummary = forwardRef<ActivitiesSummaryRef, OwnProps>(
  ActivitiesSummaryWithinForwardRef
);
