import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { CommonInvoicesFormValues } from "../../types/form/general.types";
import { InvoiceDTO } from "../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { invoicesStatusTypeToTranslationCode } from "../../../../../../../../shared/specific/maps/invoices/invoicesStatusTypeToTranslationCode";
import {
  StyledButtonsContainer,
  StyledFormSection,
  StyledFormSectionsContainer,
  StyledProjectInfo,
} from "./index.styles";
import { InvoicesStatusAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/invoices/InvoicesStatusAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { InvoicesStatusEnum } from "../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";
import { UserCollaboratorOnlyDTO } from "../../../../../../../../shared/specific/DTOs/user/UserCollaboratorOnlyDTO";
import { InvoiceFilesDTO } from "../../../../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { fillLoggedUser } from "./utils/fillLoggedUser";
import { StaticFormComponents } from "./FormikComponents/formComponents/StaticFormComponents";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { FormikComponents } from "./FormikComponents";
import { Protected } from "../../../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { usePermissionChecker } from "../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { useSuperUserEditingDates } from "./hooks/useSuperUserEditingDates";
import { InvoicesSuperUserContext } from "../../contexts/InvoicesSuperUserContext";

interface OwnProps {
  formikProps: FormikProps<CommonInvoicesFormValues>;
  invoiceData: InvoiceDTO;
  projectWithRelatedInfo: ProjectWithRelatedInfoDTO;
  setInvoiceFilesData: React.Dispatch<React.SetStateAction<InvoiceFilesDTO[]>>;
  invoiceFilesData: InvoiceFilesDTO[];
  reloadPage: () => void;
}

export const CommonInvoicesForm = ({
  formikProps: { setValues, values, setFieldValue, isSubmitting, submitForm },
  invoiceData,
  projectWithRelatedInfo,
  setInvoiceFilesData,
  invoiceFilesData,
  reloadPage,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { hasPermission: canEditInvoices } = usePermissionChecker({
    restrictions: {
      type: PermissionType.InvoiceModule,
      level: PermissionLevel.Update,
    },
  });

  const { canEditAllInvoices } = useContext(InvoicesSuperUserContext);

  const [userLogged, setUserLogged] = useState<UserCollaboratorOnlyDTO | null>(
    null
  );

  const { SuperUserEditingDatesButtonContainer, superUserEditingDatesModal } =
    useSuperUserEditingDates({ reloadPage, invoiceData });

  useEffect(() => {
    fillLoggedUser({ setUserLogged });
  }, []);

  useEffect(() => {
    async function getExtraDataAndSetValues() {
      setInvoiceFilesData(invoiceData.invoiceFiles);

      setValues({
        responsible: invoiceData.responsibleLogin,
        status: {
          id: invoiceData.status,
          label: t(invoicesStatusTypeToTranslationCode[invoiceData.status]),
        },
        percentageToCancel: invoiceData.liquidAmountToCancel
          ? formatNumber(invoiceData.liquidAmountToCancel, {
              fractionDigits: 2,
            })
          : "",
        reasonForCancellation: invoiceData.reasonForCancellation || "",
        reasonForReturn: invoiceData.reasonForReturn || "",
        issueDate: invoiceData.issueDate?.toDate() || null,
        invoiceNumber: invoiceData.invoiceNumber || "",
        cancellationDate: invoiceData.cancellationDate?.toDate() || null,
        plannedCancellationDate:
          invoiceData.plannedCancellationDate?.toDate() || null,
        dueDate: invoiceData.dueDate?.toDate() || null,
        paymentDate: invoiceData.paymentDate?.toDate() || null,
        pddDate: invoiceData.pddDate?.toDate() || null,
        lossDate: invoiceData.lossDate?.toDate() || null,
        invoiceFilesToUpload: [],
        invoiceFilesToDelete: [],
      });
    }

    if (invoiceData) {
      getExtraDataAndSetValues();
    }
  }, [t, invoiceData]);

  const verifyChanges = useCallback(() => {
    if (
      invoiceData.status !== values.status?.id ||
      (invoiceData.reasonForCancellation || "") !==
        values?.reasonForCancellation ||
      (invoiceData.reasonForReturn || "") !== values?.reasonForReturn ||
      (invoiceData.invoiceNumber || "") !== values.invoiceNumber ||
      values.invoiceFilesToDelete.length > 0 ||
      values.invoiceFilesToUpload.length > 0
    ) {
      return true;
    }
    return false;
  }, [invoiceData, values]);

  useEffectAfterRenders({
    effect: () => {
      if (
        values?.status?.id !== InvoicesStatusEnum.Released &&
        values?.status?.id !== InvoicesStatusEnum.NotReleased &&
        values.status
      ) {
        const haveChanges = verifyChanges();

        if (!haveChanges) {
          setFieldValue("responsible", invoiceData.responsibleLogin);
          return;
        }

        setFieldValue("responsible", userLogged);
      } else {
        setFieldValue("responsible", null);
      }
    },
    deps: [
      values.status,
      values.plannedCancellationDate,
      values.issueDate,
      values.invoiceNumber,
      values.reasonForCancellation,
      values.reasonForReturn,
      values.cancellationDate,
      values.invoiceFilesToDelete,
      values.invoiceFilesToUpload,
      userLogged,
      invoiceData,
      i18n.language,
    ],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("issueDate", invoiceData.issueDate?.toDate() || null);
      setFieldValue("invoiceNumber", invoiceData.invoiceNumber || "");
      setFieldValue(
        "cancellationDate",
        invoiceData.cancellationDate?.toDate() || null
      );
      setFieldValue(
        "plannedCancellationDate",
        invoiceData.plannedCancellationDate?.toDate() || null
      );
      setFieldValue("dueDate", invoiceData.dueDate?.toDate() || null);
      setFieldValue("invoiceFilesToUpload", []);
      setFieldValue("invoiceFilesToDelete", []);
    },
    deps: [values.status],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledFormSectionsContainer>
      <StaticFormComponents
        invoiceData={invoiceData}
        isSubmitting={isSubmitting}
      />
      <StyledFormSection>
        <Typography variant="h6" component="div">
          {t("invoice.modal.data.edit.title")}
          {canEditAllInvoices && (
            <SuperUserEditingDatesButtonContainer {...invoiceData} />
          )}
        </Typography>
        <StyledProjectInfo>
          <TextField
            label={t("invoice.keywords.fields.responsible")}
            value={values.responsible?.login ?? "-"}
            InputProps={{ readOnly: true }}
            required
            disabled={isSubmitting}
          />
          <InvoicesStatusAutocompleteFormik
            name="status"
            blockedStatus={[
              InvoicesStatusEnum.NotReleased,
              InvoicesStatusEnum.AmountCanceled,
              InvoicesStatusEnum.CancellationAnalysis,
            ]}
            autocompleteProps={{
              disabled: isSubmitting,
              readOnly: !canEditInvoices,
            }}
            textfieldProps={{
              required: true,
            }}
          />
        </StyledProjectInfo>
        <FormikComponents
          invoiceData={invoiceData}
          projectWithRelatedInfo={projectWithRelatedInfo}
          invoiceFilesData={invoiceFilesData}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          setInvoiceFilesData={setInvoiceFilesData}
          values={values}
        />
        <Protected
          restrictions={{
            type: PermissionType.InvoiceModule,
            level: PermissionLevel.Update,
          }}
        >
          <StyledButtonsContainer>
            <Button
              onClick={() =>
                setValues({
                  responsible: invoiceData.responsibleLogin,
                  status: {
                    id: invoiceData.status,
                    label: t(
                      invoicesStatusTypeToTranslationCode[invoiceData.status]
                    ),
                  },
                  percentageToCancel: invoiceData.liquidAmountToCancel
                    ? formatNumber(invoiceData.liquidAmountToCancel, {
                        fractionDigits: 2,
                      })
                    : "",
                  reasonForCancellation:
                    invoiceData.reasonForCancellation || "",
                  reasonForReturn: invoiceData.reasonForReturn || "",
                  issueDate: invoiceData.issueDate?.toDate() || null,
                  invoiceNumber: invoiceData.invoiceNumber || "",
                  cancellationDate:
                    invoiceData.cancellationDate?.toDate() || null,
                  plannedCancellationDate:
                    invoiceData.plannedCancellationDate?.toDate() || null,
                  invoiceFilesToUpload: [],
                  invoiceFilesToDelete: [],
                  dueDate: invoiceData.dueDate?.toDate() || null,
                  paymentDate: invoiceData.paymentDate?.toDate() || null,
                  pddDate: invoiceData.pddDate?.toDate() || null,
                  lossDate: invoiceData.lossDate?.toDate() || null,
                })
              }
              variant="text"
              disabled={isSubmitting}
            >
              {t("general.actions.general.cancel")}
            </Button>
            <Button
              onClick={submitForm}
              variant="contained"
              disabled={isSubmitting}
            >
              {t("general.actions.general.save")}
            </Button>
          </StyledButtonsContainer>
        </Protected>
      </StyledFormSection>
      {superUserEditingDatesModal}
    </StyledFormSectionsContainer>
  );
};
