import { Tooltip, styled } from "@mui/material";

export const StyledName = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledIcon = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledMultipleSelectionContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
