import { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import { ReloadValueArguments } from "../../../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest/index.types";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ProjectWithRelatedInfoDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectWithRelatedInfoDTO";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { AccessMode } from "../../../types/authorization/access.types";
import { ProjectContextValues } from "./index.types";
import { ProjectContext } from "./ProjectContext";

interface OwnProps {
  children: ReactNode;
  project: ProjectWithRelatedInfoDTO;
  isLoadingProject: boolean;
  projectHasError: boolean;
  costCenterPep: CostCenterPepOnlyDTO;
  reloadProject: (args?: ReloadValueArguments) => void;
  projectErrorStatusCode: number | null;
  setCurrentCostCenterPep: Dispatch<
    SetStateAction<CostCenterPepOnlyDTO | null>
  >;
}

export const ProjectContextProvider = ({
  children,
  project,
  costCenterPep,
  reloadProject,
  isLoadingProject,
  projectHasError,
  projectErrorStatusCode,
  setCurrentCostCenterPep,
}: OwnProps) => {
  const accessMode = useMemo((): AccessMode => {
    switch (project.status) {
      case ProjectStatus.Planning:
      case ProjectStatus.Ongoing:
        return "writing";
      default:
        return "reading";
    }
  }, [project.status]);

  const planningContext: ProjectContextValues = useMemo(() => {
    return {
      project,
      isLoadingProject,
      projectHasError,
      costCenterPep,
      accessMode,
      projectErrorStatusCode,
      reloadProject,
      setCurrentCostCenterPep,
    };
  }, [
    project,
    isLoadingProject,
    projectHasError,
    costCenterPep,
    accessMode,
    projectErrorStatusCode,
  ]);

  return (
    <ProjectContext.Provider value={planningContext}>
      {children}
    </ProjectContext.Provider>
  );
};
