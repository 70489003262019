import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import {
  TwoSidedModalActions,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "../../shared/react/hooks/form/validation/useFormikConfig";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { getTimesheetMonthSingleEntry } from "../../../../../../../services/timesheet/timesheets.service";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonFormValues } from "../../shared/types/data/form/values.types";
import { CommonTimesheetModifyingForm } from "../../shared/react/components/content/CommonTimesheetModifyingForm";
import { TimesheetMonthWithOthersDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { OnClickContentButtonComponentFunction } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { TimesheetMonthDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { ViewIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { generateUpdatedDaysTimesheetValues } from "../../shared/helpers/data/calendar/generators.helpers";
import { OtherHoursHelpIconButton } from "../../shared/react/components/buttons/IconButtons/OtherHoursHelpIconButton";
import { EmployeeAllocationType } from "../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

interface OwnProps {
  yearMonth: YearMonth;
}

interface InternalFormData {
  yearMonth: YearMonth;
  timesheetMonthWithOthers: TimesheetMonthWithOthersDTO | null;
  timesheetMonth: TimesheetMonthDTO;
}

export const useViewingForm = ({ yearMonth }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useFormikConfig();

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      CommonFormValues,
      InternalFormData,
      TimesheetMonthDTO
    >
  >(
    async ({
      contentButtonContentProps: timesheetMonth,
      setInternalFormData,
      setFormValues,
      checkInCurrentModalCount,
      setModalError,
      setIsLoadingModal,
      getOpenCloseModalCount,
    }) => {
      const modalCountOnOpen = getOpenCloseModalCount();

      setIsLoadingModal(true);
      try {
        const timesheetMonthWithOthers = await getTimesheetMonthSingleEntry({
          yearMonth: timesheetMonth.yearMonth,
          allocationType: timesheetMonth.allocationType,
          idCostCenter:
            timesheetMonth.allocationType ===
              EmployeeAllocationType.Allocation ||
            timesheetMonth.allocationType === EmployeeAllocationType.Support
              ? timesheetMonth.costCenter?.id
              : undefined,
          idProject: timesheetMonth.project?.id,
          idCorporateSegment: timesheetMonth.corporateSegment?.id,
        });
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        setFormValues({
          ...formikConfig.initialValues,
          yearMonth,
          days: generateUpdatedDaysTimesheetValues(
            yearMonth,
            timesheetMonthWithOthers.days
          ),
        });
        setInternalFormData({
          yearMonth,
          timesheetMonthWithOthers,
          timesheetMonth,
        });
      } catch (error) {
        if (!checkInCurrentModalCount(modalCountOnOpen)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "timesheets.errors.data.fetch.failedToFetchTimesheetData",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [yearMonth, formikConfig.initialValues]
  );

  const { ContentButton: ViewingButton, contentModal: viewingModal } =
    useFormikModalButton<CommonFormValues, InternalFormData, TimesheetMonthDTO>(
      {
        modal: {
          keepModalMounted: 1000,
          modalTitle: t("timesheets.modal.data.view.title"),
        },
        button: {
          FormButtonMemo: ({ onClick }) => {
            const { t } = useTranslation();

            return (
              <ViewIconButton
                onClick={onClick}
                tooltipText={t("general.actions.general.view")}
              />
            );
          },
          onClickContentButtonComponent,
        },
        form: {
          formikConfig,
          FormContentMemo: ({ internalFormData }) =>
            internalFormData && (
              <CommonTimesheetModifyingForm
                timesheetMonth={internalFormData.timesheetMonth}
                timesheetMonthWithOthers={
                  internalFormData.timesheetMonthWithOthers
                }
                yearMonth={internalFormData.yearMonth}
                formMode="viewing"
              />
            ),
          FormActionsMemo: ({ closeModal, isSubmitting, isLoadingModal }) => {
            const { t } = useTranslation();

            return (
              <TwoSidedModalActions>
                <div>{!isLoadingModal && <OtherHoursHelpIconButton />}</div>
                <div>
                  <Button onClick={closeModal} disabled={isSubmitting}>
                    {t("general.actions.general.cancel")}
                  </Button>
                </div>
              </TwoSidedModalActions>
            );
          },
        },
      }
    );

  return { ViewingButton, viewingModal };
};
