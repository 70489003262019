import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  CostCenterDropdownDTO,
  schemaCostCenterDropdownDTO,
} from "./CostCenterDropdownDTO";

export type PaginatedCostCenterDropdownDTO =
  PaginationResponse<CostCenterDropdownDTO>;

export const schemaPaginatedCostCenterDropdownDTO =
  getSchemaPaginationResponse<CostCenterDropdownDTO>(
    schemaCostCenterDropdownDTO
  );
