import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikConfig } from "./useFormikConfig";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../types/form.types";
import { EditFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../components/CreationEditingForm";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { sleep } from "../../../../../../../../shared/common/helpers/general.helpers";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { UserSkillDTO } from "../../../../../../../../shared/specific/DTOs/userSkill/UserSkillDTO";
import { userSkillTranslationCodeMap } from "../../../../../../../../shared/specific/maps/userSkill/userSkillTranslationCodeMap";
import { UserSkillUpdateDTO } from "../../../../../../../../shared/specific/DTOs/userSkill/UserSkillUpdateDTO";
import { UserSkillLevelEnum } from "../../../../../../../../shared/specific/enums/userSkill/UserSkillLevelEnum";
import { UpdateUserSkill } from "../../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";

export const useEditingForm = () => {
  const { t } = useTranslation();
  const { loadPage } = useContext(ProfileContext);

  const sharedformikConfig = useFormikConfig();

  const formikConfig: EntryFormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: sharedformikConfig.initialValues,
      validationSchema: sharedformikConfig.validationSchema,
    };
  }, [t, sharedformikConfig]);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, UserSkillDTO[], UserSkillDTO[]>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: "" }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: userSkills,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            await sleep(500);
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;
            setInternalFormData(Object.values(userSkills));
            setFormValues({
              skillsDropdown: Object.values(userSkills).map(
                (userSkill) => userSkill.skill
              ),
              discipline: null,
              horizontal: null,
              skillCategory: null,
              skillsTable: Object.values(userSkills).map((userSkill) => ({
                skill: userSkill.skill,
                level: {
                  id: userSkill.level,
                  label: t(userSkillTranslationCodeMap[userSkill.level]),
                },
              })),
            });
          } catch (error) {
            console.error(error);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: CreationEditingForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues: { ...formValues },
          formikHelpers: { setSubmitting },
          internalFormData,
          closeModal,
        }) => {
          try {
            const listSkillsUpdated: UserSkillUpdateDTO[] =
              formValues.skillsTable
                .filter((skillTable) => skillTable.level)
                .map((skillTable) => ({
                  idSkill: skillTable.skill.id,
                  level: skillTable.level?.id as UserSkillLevelEnum,
                }));
            await UpdateUserSkill(listSkillsUpdated);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "usersCollaborators.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
            await loadPage();
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
