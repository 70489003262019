import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesBudgetOveriew } from "./types/data/form.types";
import { generateBudgetOverviewReport } from "../../../../../../../../../services/reports/reports.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormComponent } from "./FormComponent";
import { Language } from "../../../../../../../../../shared/common/enums/locale/Language.enum";

export const BudgetOverview = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesBudgetOveriew>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const currentDate = new Date();
    const initialValues: FormValuesBudgetOveriew = {
      un: null,
      segmentGroup: null,
      year: new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)),
    };

    const validationSchema = yup.object({
      year: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.errors.data.fields.dates.invalidDate")),
      segment: yup.object().nullable(),
      un: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesBudgetOveriew,
    { setSubmitting }: FormikHelpers<FormValuesBudgetOveriew>
  ) => {
    if (!values.un || !values.year)
      throw new Error("All required fields must be filled.");

    try {
      await generateBudgetOverviewReport({
        Year: values.year.getFullYear(),
        Un: values.un?.id,
        SegmentGroup:
          values.segmentGroup?.segmentGroupTranslations.find(
            (x) => x.idLanguage === Language.enUS
          )?.name ?? undefined,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {(formikProps) => <FormComponent formikContenProps={formikProps} />}
    </Formik>
  );
};
