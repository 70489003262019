import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AsyncAutocompleteFormik } from "../../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { cachedDivisions } from "../../../../../../../../objects/data/cache/divisions/cachedFunctions.objects";
import { DivisionOrAllAutocompleteItem } from "./index.types";

export type { DivisionOrAllAutocompleteItem };

interface OwnProps {
  name: string;
  required?: boolean;
}

export const DivisionsOrAllAutocompleteFormik = ({
  name,
  required,
}: OwnProps) => {
  const { t, i18n } = useTranslation();

  const getFormattedDivisionsData = useCallback(async (): Promise<
    DivisionOrAllAutocompleteItem[]
  > => {
    const divisions = await cachedDivisions.run();
    const formattedDivisions = divisions.map(
      (x): DivisionOrAllAutocompleteItem => ({
        id: x.id,
        label: x.name,
        originalData: x,
      })
    );

    return [
      {
        id: "all",
        label: t("general.keywords.general.all"),
      },
      ...formattedDivisions,
    ];
  }, [t]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "divisions.errors.data.fetch.failedToFetchDivisions"
      )}
      fetchOptions={getFormattedDivisionsData}
      textfieldProps={{
        label: t("divisions.keywords.general.un"),
        required,
      }}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
      }}
      shouldReplaceValueOnOptionsChange
      rerunOnDeps={[i18n.language]}
    />
  );
};
