import { ContractType } from "../../enums/projects/ContractType.enum";

export const contractTypeToTranslationCodeMap: {
  readonly [key in ContractType]: string;
} = {
  [ContractType.FixedAllocation]:
    "projects.keywords.contractType.fixedAllocation",
  [ContractType.TimeAndMaterial]:
    "projects.keywords.contractType.timeAndMaterial",
  [ContractType.ClientAllocation]:
    "projects.keywords.contractType.clientAllocation",
};
