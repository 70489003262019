import * as yup from "yup";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "./shared/types/data/form/values.types";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { createUsersCollaboratorFullFilterDTO } from "../../../../../../../shared/specific/DTOs/user/filters/UsersCollaboratorFilterDTO";
import { UserCollaboratorFilterForm } from "./UserCollaboratorFilterForm";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      login: "",
      onlyActivated: true,
      admissionDateStart: null,
      admissionDateEnd: null,
      contractRole: null,
      costCenter: null,
      customerCode: "",
      endDate: null,
      startDate: null,
      coordinator: null,
      jobRole: null,
      manager: null,
      project: null,
      frame: null,
    };

    const validationSchema = yup.object({
      name: yup.string(),
      login: yup.string(),
      onlyActivated: yup.boolean(),
      admissionDateStart: yup.date().nullable(),
      admissionDateEnd: yup.date().nullable(),
      contractRole: yup.object().nullable(),
      costCenter: yup.object().nullable(),
      customerCode: yup.object().nullable(),
      endDate: yup.date().nullable(),
      startDate: yup.date().nullable(),
      coordinator: yup.object().nullable(),
      jobRole: yup.object().nullable(),
      manager: yup.object().nullable(),
      project: yup.object().nullable(),
      frame: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        createUsersCollaboratorFullFilterDTO({
          login: formValues.login || undefined,
          admissionDateStart: formValues.admissionDateStart
            ? DateOnly.createFromDate(formValues.admissionDateStart)
            : undefined,
          admissionDateEnd: formValues.admissionDateEnd
            ? DateOnly.createFromDate(formValues.admissionDateEnd)
            : undefined,
          onlyActivated: formValues.onlyActivated,
          customerCode: formValues.customerCode,
          endDate: formValues.endDate
            ? YearMonth.createFromDate(formValues.endDate)
            : undefined,
          startDate: formValues.startDate
            ? YearMonth.createFromDate(formValues.startDate)
            : undefined,
          idContractRole: formValues.contractRole?.id,
          idCoordinator: formValues.coordinator?.id,
          idCostCenter: formValues.costCenter?.id,
          idJobRole: formValues.jobRole?.id,
          idManager: formValues.manager?.id,
          idProject: formValues.project?.id,
          idFrame: formValues.frame?.id,
        }),
      FormContentMemo: UserCollaboratorFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
