import { styled } from "@mui/material";

export const StyledModalMainContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const StyledModalSubContainers = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    flex-direction: column;
    div {
      max-width: 300px;
    }
  }
`;

export const StyledSubtitles = styled("div")`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
`;
