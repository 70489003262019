import { styled } from "@mui/material";

export const StyledTableHeadButtons = styled("div")`
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const StyledHorizontalHeader = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: ${({ theme }) => theme.spacing(0.5)};
  white-space: nowrap;
  width: 100%;

  & > :first-child {
    font-weight: 500;
  }
`;
