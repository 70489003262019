import { Box, TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useField } from "formik";
import { getCustomers } from "../../../../../../../../../../../../services/customers/customers.service";
import {
  SearchAutocompleteFormikProps,
  SearchAutocompleteFormik,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { CustomerDTO } from "../../../../../../../../../../../../shared/specific/DTOs/customers/CustomerDTO";
import { AutocompleteInfoBubble } from "../../../../../../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";
import {
  StyledAutocompleteFieldReviewInfoBubble,
  StyledDiv,
} from "./CustomersSearchAutocompleteFormik.styles";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  customerFromDeal: string;
  customerDTO: CustomerDTO | null;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    CustomerDTO,
    DisableClearable
  >["autocompleteProps"];
}

export const CustomersSearchAutocompleteDealsFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  customerFromDeal,
  customerDTO,
}: OwnProps<DisableClearable>) => {
  const [{ value: customer }] = useField<CustomerDTO | null>(name);

  const { t } = useTranslation();

  const [hideDealCustomer, setHideDealCustomer] = useState<boolean>(false);

  const getCustomersFinal = useCallback(async (tradingName: string) => {
    const paginatedCustomers = await getCustomers({
      filters: { tradingName },
      pagination: { pageSize: 10 },
    });
    return paginatedCustomers.tableData;
  }, []);

  const endAdornment = useMemo(() => {
    if (!customer?.registrationCode) return undefined;
    return (
      <Tooltip title={customer.registrationCode} placement="top">
        <AutocompleteInfoBubble>
          {customer.registrationCode}
        </AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [customer]);

  const startAdornment = useMemo(() => {
    if (customerDTO || hideDealCustomer) return undefined;
    if (!customerFromDeal) return undefined;
    return (
      <StyledDiv>
        {customerFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [customerFromDeal, customerDTO, hideDealCustomer]);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "customers.errors.data.fetch.failedToFetchCustomer"
      )}
      fetchOptionsMemo={getCustomersFinal}
      textfieldProps={{
        label: t("customers.keywords.general.customers"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          endAdornment,
          startAdornment,
        },
        onFocus: () => setHideDealCustomer(true),
        onBlur: () => setHideDealCustomer(false),
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.tradingName,
        renderOption: (props, option) => (
          <Box component="li" {...props}>
            {option.tradingName}
            {option.registrationCode && `- ${option.registrationCode}`}
          </Box>
        ),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
    />
  );
};
