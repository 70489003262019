import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { ExternalEnhancedHeadCell } from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { CarouselLeftButton } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import { CarouselHead } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { headCellsSize } from "../../../shared/consts/headCells.const";
import { ExpenseGroup } from "../../../../../../../../../../../shared/specific/enums/expenseGroup/ExpenseGroup.enum";

export const useHeadCells = () => {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const idExpenseGroup = searchParams.get("idExpenseGroup");

  const idExpenseGroupValidated = useMemo(() => {
    return /^\d+$/.test(idExpenseGroup ?? "")
      ? parseInt(idExpenseGroup ?? "")
      : null;
  }, [idExpenseGroup]);

  const isToShowCustomerTrigramAndClientAccount = useMemo(() => {
    return ![
      ExpenseGroup.AdmCorp,
      ExpenseGroup.Pdd,
      ExpenseGroup.IrCsll,
      ExpenseGroup.NetFinancialExpenses,
      ExpenseGroup.ParticipationProgram,
    ].includes(idExpenseGroupValidated as ExpenseGroup);
  }, [idExpenseGroupValidated]);

  const isToShowTransCountry = useMemo(() => {
    return [
      ExpenseGroup.AdmCorp,
      ExpenseGroup.SalesCost,
      ExpenseGroup.Sgea,
      ExpenseGroup.Dol,
    ].includes(idExpenseGroupValidated as ExpenseGroup);
  }, [idExpenseGroup]);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("projection.keywords.fields.origin"),
        width: headCellsSize.origin,
        canSort: false,
        headColumn: true,
      },
      {
        value: t("costCenters.keywords.general.costCenter"),
        width: headCellsSize.costCenter,
        canSort: false,
      },
      {
        value: t("projection.keywords.fields.accountCode"),
        width: headCellsSize.accountCode,
        canSort: false,
      },
      {
        value: t("costDistribution.keywords.fields.customerTrigram"),
        width: headCellsSize.customerTrigram,
        canSort: false,
      },
      {
        value: t("projection.keywords.fields.clientAccount"),
        width: headCellsSize.clientAccount,
        canSort: false,
      },
      {
        value: t("skillCategory.keywords.general.category"),
        width: headCellsSize.category,
        canSort: false,
      },
      {
        value: isToShowTransCountry
          ? t("projection.keywords.fields.transCountry")
          : "",
        width: isToShowTransCountry ? headCellsSize.transferenceCountry : 5,
        canSort: false,
      },
      {
        value: "",
        HeadRenderer: () => <CarouselLeftButton />,
        canSort: false,
        width: headCellsSize.lastColumn,
      },
    ];

    if (!isToShowCustomerTrigramAndClientAccount) {
      headCells.splice(3, 2);
    }

    for (let i = 0; i < 3; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
        width: headCellsSize.values,
      });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      canSort: false,
      width: headCellsSize.lastColumn,
    });

    return headCells;
  }, [t, i18n]);

  return {
    headCells,
  };
};
