import { AllocationMapWithinContext } from "./AllocationMapWithinContext";
import { AllocationMapContextProvider } from "./hooks/shared/context";

export const AllocationsMap = () => {
  return (
    <AllocationMapContextProvider>
      <AllocationMapWithinContext />
    </AllocationMapContextProvider>
  );
};
