import { useMemo } from "react";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { CollaboratorTrackPageWithinContext } from "./collaboratorTrackPageWithinContext";
import { CollaboratorTrackSuperUserContext } from "./shared/react/contexts";

export const CollaboratorTrack = () => {
  const {
    superUserButton: canEditCollaboratorTrackWithoutRestrictionsButton,
    inSuperUserMode: canEditCollaboratorTrackWithoutRestrictions,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditCollaboratorAndTrackWithoutRestriction,
    tooltipNotInSuperUserModeOff:
      "usersCollaborators.actions.authorization.canEditTrackWithoutRestrictions",
    tooltipInSuperUserMode:
      "usersCollaborators.actions.authorization.canEditTrackWithoutRestrictions",
  });

  const collaboratorTrackSuperUserValues = useMemo(() => {
    return {
      canEditCollaboratorTrackWithoutRestrictionsButton,
      canEditCollaboratorTrackWithoutRestrictions,
    };
  }, [
    canEditCollaboratorTrackWithoutRestrictionsButton,
    canEditCollaboratorTrackWithoutRestrictions,
  ]);

  return (
    <CollaboratorTrackSuperUserContext.Provider
      value={collaboratorTrackSuperUserValues}
    >
      <CollaboratorTrackPageWithinContext />
    </CollaboratorTrackSuperUserContext.Provider>
  );
};
