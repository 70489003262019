import { riseApi } from "./connection";

export const CheckCanLoadDreQueues = async (): Promise<boolean> => {
  const { data: canLoad } = await riseApi.get(
    `DreQueues/CheckCanLoadDreQueues`
  );

  return canLoad;
};

export const LoadDrequeues = async () => {
  await riseApi.post(`DreQueues/LoadDreQueues`);
};

export const GenerateDreProjection = async (): Promise<number> => {
  const { data: version } = await riseApi.get(
    `DreQueues/GenerateDreProjection`
  );

  return version;
};
