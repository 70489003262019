import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ViewIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { useModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { InvoiceObservationsContent } from "./InvoiceObservationsContent";

export const useObservationsModal = () => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: ViewingObservationsButton,
    contentModal: viewingObservationsModal,
  } = useModalButton<InvoiceDTO>({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.fields.observations"),
      mode: "dialog",
      ModalContentMemo: ({ internalModalData: invoice }) => (
        <InvoiceObservationsContent
          milestone={invoice?.milestone ?? ""}
          invoiceNumber={invoice?.invoiceNumber ?? undefined}
          invoiceNote={invoice?.invoiceNote ?? undefined}
          delayJustification={invoice?.delayJustification ?? undefined}
          status={invoice?.status}
        />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick, buttonTooltip }) => (
        <ViewIconButton
          onClick={onClick}
          tooltipText={buttonTooltip}
          size="small"
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoice,
        setInternalModalData,
      }) => {
        setInternalModalData(invoice);
      },
    },
  });

  return {
    ViewingObservationsButton,
    viewingObservationsModal,
  };
};
