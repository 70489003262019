import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useContext } from "react";
import { OnClickContentButtonComponentFunction } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/shared/types/functions.types";
import { ExpenseModifyFormValues } from "./shared/types/data/form/values.types";
import { useFormikModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { useFormikConfig } from "./shared/hooks/data/form/validation/useFormikConfig";
import { StyledButtonContainer } from "./index.styles";
import { FormMode } from "./shared/types/data/form/general.types";
import { ViewExpensesModal } from "./ViewExpensesModal";
import { ExpensesOfTypeDTO } from "../../../../../../../../../shared/specific/DTOs/expenses/ExpensesOfTypeDTO";
import { ExpenseOfTypeDTO } from "../../../../../../../../../shared/specific/DTOs/expenses/ExpenseOfTypeDTO";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { ExpensesSuperUserContext } from "../../shared/react/contexts/ExpensesSuperUserContext";
import { getNowYearMonthBrazil } from "../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { usePermissionChecker } from "../../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

interface InternalFormData {
  expensesRow: ExpensesOfTypeDTO;
  expenseCell: ExpenseOfTypeDTO | null;
  mode: FormMode;
  yearMonth: YearMonth;
  costCenterPep: CostCenterPepOnlyDTO;
}

export interface UseEditingCellFormContentButtonContentProps {
  children: JSX.Element | null;
  expensesRow: ExpensesOfTypeDTO;
  expenseCell: ExpenseOfTypeDTO | null;
  yearMonth: YearMonth;
  costCenterPep: CostCenterPepOnlyDTO;
}

export const useEditingCellForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const { hasPermission: canEditExpenses } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Expenses,
      level: PermissionLevel.Update,
    },
  });

  const formikConfig = useFormikConfig();
  const { canEditAllExpenses } = useContext(ExpensesSuperUserContext);

  const getFormMode = useCallback(
    (yearMonth: YearMonth, expensesRow: ExpensesOfTypeDTO): FormMode => {
      if (!canEditExpenses) return "viewing";
      if (!canEditAllExpenses && yearMonth.lessThan(getNowYearMonthBrazil()))
        return "viewing";
      return "editing";
    },
    [canEditAllExpenses, canEditExpenses]
  );

  const onClickContentButtonComponent = useCallback<
    OnClickContentButtonComponentFunction<
      ExpenseModifyFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >
  >(
    async ({
      contentButtonContentProps: {
        expenseCell,
        expensesRow,
        yearMonth,
        costCenterPep,
      },
      setFormValues,
      setInternalFormData,
    }) => {
      setFormValues({
        amount: expenseCell?.amount
          ? formatNumber(expenseCell.amount, { fractionDigits: 2 })
          : "",
      });

      setInternalFormData({
        expenseCell,
        expensesRow,
        costCenterPep,
        mode: getFormMode(yearMonth, expensesRow),
        yearMonth,
      });
    },
    [getFormMode]
  );

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      ExpenseModifyFormValues,
      InternalFormData,
      UseEditingCellFormContentButtonContentProps
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          return <>{t("expenses.modal.data.modifySingle.titleView")}</>;
        },
        modalMode: "dialog",
      },
      button: {
        onClickContentButtonComponent,
        createModalButtonContainerMemo: ({
          onClickButton,
          onClickContentButtonComponent,
        }) => {
          return (props) => {
            const { children } = props;
            const onClick = () => {
              onClickButton();
              onClickContentButtonComponent({ props });
            };

            return (
              <StyledButtonContainer onClick={onClick}>
                {children}
              </StyledButtonContainer>
            );
          };
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) =>
          internalFormData && (
            <ViewExpensesModal
              {...rest}
              reloadMainTablePage={reloadTablePage}
              expensesRow={internalFormData.expensesRow}
              yearMonth={internalFormData.yearMonth}
              mode={internalFormData.mode}
              costCenterPep={internalFormData.costCenterPep}
            />
          ),
        FormActionsMemo: ({ closeModal }) => {
          const { t } = useTranslation();

          return (
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          );
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
