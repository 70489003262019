import { Popover, PopoverProps } from "@mui/material";
import { MouseEvent, ReactNode, useState } from "react";
import { StyledPopoverButtonContent } from "./index.styles";

interface OwnProps {
  content: ReactNode;
  button: ReactNode;
  popoverProps?: Omit<PopoverProps, "open" | "anchorEl" | "id">;
}

export const TipPopoverButton = ({
  content,
  button,
  popoverProps: { onClose: externalOnClose, ...popoverProps } = {},
}: OwnProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose: PopoverProps["onClose"] = (event, reason) => {
    setAnchorEl(null);
    externalOnClose?.(event, reason);
  };

  return (
    <>
      <div onClick={handleClick}>{button}</div>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        {...popoverProps}
      >
        <StyledPopoverButtonContent>{content}</StyledPopoverButtonContent>
      </Popover>
    </>
  );
};
