import { Tab } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TabInfo } from "./index.types";
import { TabCode } from "../../shared/types/TabCode.enums";
import { usePermissionChecker } from "../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";

export const useTabs = () => {
  const { t } = useTranslation();

  const { hasPermission: canReadAdditiveRegister } = usePermissionChecker({
    restrictions: {
      type: PermissionType.AdditiveRegister,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadProjectRegister } = usePermissionChecker({
    restrictions: {
      type: PermissionType.ProjectRegister,
      level: PermissionLevel.Read,
    },
  });

  const tabs = useMemo(() => {
    const tabsInfo: (TabInfo | null)[] = [];

    if (canReadProjectRegister)
      tabsInfo.push({
        label: t("projects.keywords.general.projects"),
        value: TabCode.ProjectTab,
      });

    if (canReadAdditiveRegister)
      tabsInfo.push({
        label: t("additives.keywords.general.additives"),
        value: TabCode.AdditiveTab,
      });

    const tabsInfoNotNull = tabsInfo.filter((x) => !!x) as TabInfo[];

    return tabsInfoNotNull.map(({ label, value }) => (
      <Tab label={label} value={value} key={value} />
    ));
  }, [t]);

  return tabs;
};
