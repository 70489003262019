import { ButtonBase, styled } from "@mui/material";

export const StyledCenteredInfo = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const StyledButtonBase = styled(ButtonBase)`
  height: 100%;
  width: 100%;
`;

export const StyledReadOnlyCarouselCell = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-size: ${({ theme }) => theme.typography.fontSize}px;

  & > * {
    white-space: nowrap;
  }
`;

export const StyledCarouselCell = styled(StyledReadOnlyCarouselCell)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
  }
`;
