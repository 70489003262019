import { useFormikContext } from "formik";
import { CalendarGrid } from "../../../../../../../../../../shared/common/react/components/table/CalendarGrid";
import { FormValues } from "../../../../../hooks/useCreationForm/shared/types/data/form/values.types";
import { CalendarCell } from "./CalendarCell";

export const TimesheetCalendarFormWithinContext = () => {
  const { values } = useFormikContext<FormValues>();

  return (
    <CalendarGrid
      yearMonth={values.yearMonth}
      CalendarCellContent={CalendarCell}
    />
  );
};
