import * as yup from "yup";
import { CustomerDTO, schemaCustomerDTO } from "../customers/CustomerDTO";
import { DivisionDTO, schemaDivisionDTO } from "../division/DivisionDTO";
import {
  ProductServiceDTO,
  schemaProductServiceDTO,
} from "../productService/ProductServiceDTO";
import {
  SubsidiaryDTO,
  schemaSubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import {
  UserCollaboratorDTO,
  schemaUserCollaboratorDTO,
} from "../user/UserCollaboratorDTO";
import { ContractType } from "../../enums/projects/ContractType.enum";
import { SegmentDTO, schemaSegmentDTO } from "../Segment/SegmentDTO";

export interface PipedriveDealDTO {
  id: number;
  idProject: string;
  opportunityId: string | null;
  customerName: string;
  userManagerName: string;
  projectBeginnigDate: Date | null;
  additive: string | null;
  title: string | null;
  daysForPayment: number | null;
  billingAmount: number | null;
  mv: number | null;
  expectedCloseDate: Date | null;
  productServiceName: string;
  unDescription: string;
  customer: CustomerDTO | null;
  userManager: UserCollaboratorDTO | null;
  division: DivisionDTO | null;
  subsidiary: SubsidiaryDTO | null;
  productService: ProductServiceDTO | null;
  contractType: ContractType | null;
  segment: SegmentDTO | null;
  approvedCredit: boolean | null;
  projectDeadline: number | null;
}

export const schemaPipedriveDealDTO = yup.object({
  id: yup.number().integer().defined(),
  idProject: yup.string().defined(),
  opportunityId: yup.string().defined().nullable(),
  customerName: yup.string().defined(),
  userManagerName: yup.string().defined(),
  projectBeginnigDate: yup.date().defined().nullable(),
  additive: yup.string().defined().nullable(),
  title: yup.string().defined().nullable(),
  daysForPayment: yup.number().integer().defined().nullable(),
  billingAmount: yup.number().defined().nullable(),
  mv: yup.number().defined().nullable(),
  expectedCloseDate: yup.date().defined().nullable(),
  productServiceName: yup.string().defined(),
  unDescription: yup.string().defined(),
  customer: schemaCustomerDTO.defined().nullable(),
  userManager: schemaUserCollaboratorDTO.defined().nullable(),
  subsidiary: schemaSubsidiaryDTO.defined().nullable(),
  division: schemaDivisionDTO.defined().nullable(),
  productService: schemaProductServiceDTO.defined().nullable(),
  contractType: yup.number().integer().nullable().defined(),
  segment: schemaSegmentDTO.nullable().defined(),
  approvedCredit: yup.boolean().nullable().defined(),
  projectDeadline: yup.number().nullable().defined(),
});
