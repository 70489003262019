import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { FormValuesBillingVision } from "./types/form.types";
import { BillingVisionReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/BillingVisionReportFilterDTO";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { generateBillingVisionReport } from "../../../../../../../../../services/reports/reports.service";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { InvoicesStatusAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/invoices/InvoicesStatusAutocompleteFormik";
import { InvoicesStatusEnum } from "../../../../../../../../../shared/specific/enums/invoices/InvoicesStatusEnum";

export const BillingVision = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesBillingVision>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesBillingVision = {
      yearMonth: null,
      collaborator: null,
      status: [],
    };

    const validationSchema = yup.object({
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      collaborator: yup.object().nullable(),
      status: yup.array().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (values: FormValuesBillingVision) => {
    const formattedFilters: BillingVisionReportFilterDTO = {
      yearMonth: values?.yearMonth
        ? YearMonth.createFromDate(values.yearMonth)
        : undefined,
      InvoiceStatus: values.status.map((x) => x.id),
      responsibleForBilling: values.collaborator?.login ?? undefined,
    };
    await generateBillingVisionReport(formattedFilters);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="yearMonth"
              datePickerProps={{
                label: t("projection.keywords.fields.competence"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
            />
            <UsersSearchAutocompleteFormik
              name="collaborator"
              autocompleteProps={{
                multiple: false,
              }}
              textfieldProps={{
                label: t("general.keywords.fields.responsibleBilling"),
              }}
              filtersMemo={{ isActive: true }}
            />
            <InvoicesStatusAutocompleteFormik
              name="status"
              blockedStatus={[
                InvoicesStatusEnum.NotReleased,
                InvoicesStatusEnum.AmountCanceled,
              ]}
              autocompleteProps={{
                multiple: true,
              }}
            />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
