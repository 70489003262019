export const formatFilename = (
  filename: string,
  {
    extension = "",
    removeWhitespace = true,
  }: { extension?: string; removeWhitespace?: boolean } = {}
) => {
  let formattedFilename = filename.toLowerCase();
  if (removeWhitespace) {
    formattedFilename = formattedFilename
      .replaceAll(" ", "-")
      .replace(/\s/g, "");
  }

  if (extension) {
    formattedFilename += `.${extension}`;
  }
  return formattedFilename;
};
