import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectionFormValues } from "../../../types/form.types";
import { parseLocaleNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: ProjectionFormValues = useMemo(() => {
    return {
      adjustmentLines: null,
      adjustmentLinesCopy: null,
      pipedriveDreExpense: [],
      pipedriveDreRevenue: [],
      pipedriveDreTax: [],
      riseDreExpense: [],
      riseDreRevenue: [],
      riseDreTax: [],
      admCorp: [],
      dol: [],
      grossRevenue: [],
      pdd: [],
      salesCost: [],
      sgAndA: [],
      tax: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      adjustmentLines: yup
        .array(
          yup.object({
            costCenter: yup
              .object()
              .nullable()
              .required(t("general.errors.data.fields.general.required")),
            accountCode: yup.string().nullable(),
            customerTrigram: yup.string().nullable(),
            clientAccount: yup.string().nullable(),
            expenseType: yup
              .object()
              .nullable()
              .required(t("general.errors.data.fields.general.required")),
            projectionAdjustmentYearMonthValues: yup.array(
              yup.object({
                id: yup.number().nullable(),
                yearMonth: yup
                  .date()
                  .required(t("general.errors.data.fields.general.required")),
                value: yup
                  .number()
                  .transform((value, originalValue, context) => {
                    if (context.isType(originalValue)) return originalValue;
                    return parseLocaleNumber(originalValue);
                  })
                  .required(t("general.errors.data.fields.general.required")),
              })
            ),
          })
        )
        .defined()
        .nullable(),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
