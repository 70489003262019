import { useTranslation } from "react-i18next";
import { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { CenteredContent } from "../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useLoadLatest } from "../../../../../../../../shared/common/react/hooks/data/fetch/useLoadLatest";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import {
  StyledInfoItem,
  StyledSubHeaderFixed,
} from "../../../shared/react/styles/ui/layouts/general.styles";
import { FormattedCurrency } from "../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { StyledIncomeItem } from "./index.styles";
import { getProjectInvoicesSummary } from "../../../../../../../../services/invoices/invoices.service";
import { formatNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { HeaderLoading } from "../../../../../../../../shared/common/react/components/ui/HeaderLoading";

interface OwnProps {}
export interface InvoicesSummaryRef {
  reloadInvoicesSummary: () => void;
}

const InvoicesSummaryWithinForwardRef = (
  props: OwnProps,
  ref: ForwardedRef<InvoicesSummaryRef>
) => {
  const { t } = useTranslation();
  const { project, isLoadingProject } = useProjectContext();

  const {
    value: invoicesSummary,
    isLoading: isLoadingInvoicesSummary,
    hasError: invoicesSummaryHasError,
    reloadValue: reloadInvoicesSummary,
    errorStatusCode: invoicesErrorStatusCode,
  } = useLoadLatest({
    initialValue: null,
    getValue: () => getProjectInvoicesSummary(project.id),
    onError: ({ setValue }) => {
      setValue(null);
    },
  });

  useImperativeHandle(ref, () => ({ reloadInvoicesSummary }));

  if (
    (!invoicesSummary && isLoadingInvoicesSummary) ||
    (!project && isLoadingProject)
  )
    return <CenteredLoading stretchMode="horizontal" />;
  if (invoicesSummaryHasError)
    return (
      <CenteredContent stretchMode="horizontal">
        {invoicesErrorStatusCode === 403
          ? t("general.errors.authorization.unauthorizedAccessContent")
          : t("activities.errors.data.fetch.failedToFetchActivitySummary")}
      </CenteredContent>
    );
  if (!invoicesSummary)
    return (
      <CenteredContent stretchMode="horizontal">
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );

  return (
    <StyledSubHeaderFixed>
      {(isLoadingInvoicesSummary || isLoadingProject) && <HeaderLoading />}
      <StyledInfoItem>
        <div>{t("projects.keywords.fields.projectBudget")}</div>
        <div>
          <FormattedCurrency
            value={project.billingAmountWithAdditives}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("invoice.keywords.fields.plannedAmount")}</div>
        <div>
          <FormattedCurrency
            value={
              invoicesSummary.totalInvoiceAmount -
              invoicesSummary.totalInProgressOfCancellation
            }
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("invoice.keywords.fields.amountInProgressCancellation")}</div>
        <div>
          <FormattedCurrency
            value={invoicesSummary.totalInProgressOfCancellation}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("invoice.keywords.fields.percentagesSum")}</div>
        <div>
          {project.billingAmountWithAdditives
            ? `${formatNumber(
                ((invoicesSummary.totalInvoiceAmount -
                  invoicesSummary.totalInProgressOfCancellation) /
                  project.billingAmountWithAdditives) *
                  100,
                { fractionDigits: 2 }
              )}%`
            : "-"}
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("invoice.keywords.fields.invoicesMilestones")}</div>
        <div>{invoicesSummary.numberInvoices}</div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.fields.deprovisionTotal")}</div>
        <div>
          <FormattedCurrency
            value={invoicesSummary.totalDeprovisionAmount}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </div>
      </StyledInfoItem>
      <StyledInfoItem>
        <div>{t("activities.keywords.fields.income")}</div>
        <StyledIncomeItem
          color={
            invoicesSummary.projectBudget <= invoicesSummary.projectIncome
              ? "success"
              : "error"
          }
        >
          <FormattedCurrency
            value={invoicesSummary.projectIncome}
            currencySymbol={project.subsidiary.currencySymbol}
          />
        </StyledIncomeItem>
      </StyledInfoItem>
    </StyledSubHeaderFixed>
  );
};

export const InvoicesSummary = forwardRef<InvoicesSummaryRef, OwnProps>(
  InvoicesSummaryWithinForwardRef
);
