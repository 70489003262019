import { createContext } from "react";

export interface TimesheetSuperUserContextValues {
  canEditPastTimesheet: boolean;
  canEditPastTimesheetButton: JSX.Element | null;
}

export const TimesheetSuperUserContext =
  createContext<TimesheetSuperUserContextValues>({
    canEditPastTimesheet: false,
    canEditPastTimesheetButton: null,
  });
