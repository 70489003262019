import { css } from "@mui/material";
import { alphabet } from "../../constants/data/string/general.constants";

export const createGridAreaLettersRange = (numberChildren: number) => {
  if (numberChildren < 0 || numberChildren > alphabet.length)
    throw new Error(
      `'numberChildren' must be between 1 and ${alphabet.length}.`
    );

  return alphabet.slice(0, numberChildren).map(
    (area, index) => css`
      & > :nth-child(${index + 1}) {
        grid-area: ${area};
      }
    `
  );
};

export const createSingleColumnGridArea = (numberChildren: number) => {
  if (numberChildren < 0 || numberChildren > alphabet.length)
    throw new Error(
      `'numberChildren' must be between 1 and ${alphabet.length}.`
    );

  return alphabet
    .slice(0, numberChildren)
    .map((x) => `"${x}"`)
    .join(" ");
};
