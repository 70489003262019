import Pptxgen from "pptxgenjs";
import { ProjectAdditionalInformationDTO } from "../../../../../../../../../../../../../../shared/specific/DTOs/project/ProjectAdditionalInformationDTO";
import { CptProps } from "../shared/index.types";

export const customerContact = (
  projectData: ProjectAdditionalInformationDTO,
  slide: Pptxgen.Slide,
  pres: Pptxgen
) => {
  const customerContactLableCpt: CptProps = {
    text: "Customer Contact",
    options: {
      align: pres.AlignH.left,
      y: "25%",
      x: "1%",
      w: "10%",
      color: "363636",
      fontSize: 7,
      bold: true,
    },
  };

  const customerContactNameCpt: CptProps = {
    text: projectData.customerContact ?? "-",
    options: {
      align: pres.AlignH.left,
      y: "26%",
      x: "1%",
      w: "40%",
      valign: pres.AlignV.top,
      color: "363636",
      fontSize: (projectData.customerContact ?? "-").length > 60 ? 8 : 10,
    },
  };

  slide.addText(customerContactLableCpt.text, customerContactLableCpt.options);
  slide.addText(customerContactNameCpt.text, customerContactNameCpt.options);
};
