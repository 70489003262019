import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import {
  deleteFrame,
  getFrameById,
} from "../../../../../../services/frames/frames.service";
import { DeleteIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { FrameOnlyDTO } from "../../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<FrameOnlyDTO, FrameOnlyDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: frameData }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{ name: frameData?.name ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: frameData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const frame = await getFrameById(frameData.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!frame)
            return setModalError(
              i18next.t("frames.errors.data.general.frameDoesNotExist")
            );

          setInternalModalData(frame);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "frames.errors.data.fetch.failedToFetchFrames",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: frameData }) => {
        if (!frameData)
          throw new Error("Frame data cannot be null on confirm.");

        try {
          await deleteFrame(frameData.id, frameData.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!frameData?.name)
            return notifyError(
              i18next.t("frames.errors.data.delete.failedToDeleteFrame")
            );

          notifyError(
            <Trans
              i18nKey="frames.errors.data.delete.failedToDeleteFrame"
              values={{ name: frameData.name }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
