import { Button } from "@mui/material";
import { useField } from "formik";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../store";
import { Language } from "../../../../../../enums/locale/Language.enum";
import { TranslatableFieldValues } from "../../../../../../types/data/form/translation.types";
import {
  ButtonProps,
  useModalButton,
} from "../../../../../hooksWithComponents/modal/modalButtons/useModalButton";
import { TranslationsIconButton } from "../../../../general/buttons/simpleIconButtons/TranslationsIconButton";
import { TextFieldFormik } from "../../textField/TextFieldFormik";
import { TranslationsModalContent } from "./index.styles";
import { TextFieldFormikWithTranslationsModalProps } from "./index.types";

export const TextFieldFormikWithTranslationsModal = ({
  name,
  label,
  InputProps,
  InputPropsMemo,
  defaultLanguageTextFieldProps: {
    InputProps: InputPropsDefaultLanguage,
    InputPropsMemo: InputPropsDefaultLanguageMemo,
    ...defaultLanguageTextFieldProps
  } = {},
  secondaryLanguagesTextFieldProps,
  ...rest
}: TextFieldFormikWithTranslationsModalProps) => {
  const [{ value: allFields }] = useField<TranslatableFieldValues>(name);
  const defaultLanguage = useSelector(
    (state: RootState) => state.language.defaultLanguage
  );

  const areAllNonDefaultFieldsEmpty = useMemo(() => {
    return Object.entries(allFields)
      .filter(([key]) => key !== defaultLanguage)
      .every(([, value]) => !value);
  }, [allFields]);

  const ModalButton = useCallback(
    ({ onClick: openModal }: ButtonProps) => (
      <TranslationsIconButton
        onClick={openModal}
        size="small"
        badgeProps={
          areAllNonDefaultFieldsEmpty
            ? undefined
            : { variant: "dot", color: "primary" }
        }
      />
    ),
    [areAllNonDefaultFieldsEmpty]
  );

  const { contentButton, contentModal } = useModalButton({
    button: {
      ModalButton,
    },
    modal: {
      ModalTitleMemo: () => (
        <Trans
          i18nKey="general.keywords.languages.translationsOfName"
          values={{ name: label }}
          components={{ italic: <em /> }}
        />
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
      mode: "dialog",
      modalContentMemo: (
        <TranslationsModalContent>
          {Object.values(Language)
            .filter((x) => x !== defaultLanguage)
            .map((language) => (
              <TextFieldFormik
                key={language}
                name={`${name}.${language}`}
                label={`${label} (${language})`}
                InputProps={InputProps}
                InputPropsMemo={InputPropsMemo}
                {...rest}
                {...secondaryLanguagesTextFieldProps}
              />
            ))}
        </TranslationsModalContent>
      ),
    },
  });

  const InputPropsWithEndAdornment = useMemo(() => {
    return {
      ...InputProps,
      ...InputPropsMemo,
      ...InputPropsDefaultLanguage,
      ...InputPropsDefaultLanguageMemo,
      endAdornment: contentButton,
    };
  }, [InputProps, InputPropsDefaultLanguage, contentButton]);

  if (!defaultLanguage) return null;

  return (
    <>
      <TextFieldFormik
        name={`${name}.${defaultLanguage}`}
        label={`${label} (${defaultLanguage})`}
        {...rest}
        {...defaultLanguageTextFieldProps}
        InputProps={InputPropsWithEndAdornment}
      />
      {contentModal}
    </>
  );
};
