import { styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const StyledForm = styled("div")`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  cursor: pointer;
`;

export const StyledModalHelperInfos = styled("div")`
  ul {
    margin-left: 12;
  }
  p {
    margin-top: 8;
    margin-bottom: 8;
  }
  img {
    max-width: "100%";
  }
`;

export const StyledCustomerBalanceUpload = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  min-width: 250px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(0.5)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 0;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    min-width: 0;
  }
`;
