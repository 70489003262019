import { CachedData } from "../CachedData";
import {
  CachedDataDictionary,
  CallbackFunction,
  ContructorArguments,
  MapRunValueToKeyFunction,
  RunParameters,
} from "./index.types";

const DEFAULT_CACHED_DURATION = 600000; // 10 minutes

export class CachedParameterizedData<T, RunValue> {
  private callback: CallbackFunction<T, RunValue>;
  private defaultCacheDuration: number;
  private mapRunValueToKey: MapRunValueToKeyFunction<RunValue>;

  private storedCachedData: CachedDataDictionary<T> = {};

  constructor({
    callback,
    mapRunValueToKey,
    defaultCacheDuration = DEFAULT_CACHED_DURATION,
  }: ContructorArguments<T, RunValue>) {
    this.callback = callback;
    this.mapRunValueToKey = mapRunValueToKey;
    this.defaultCacheDuration = defaultCacheDuration;
    this.run = this.run.bind(this);
  }

  public async run({ value, cacheDuration }: RunParameters<RunValue>) {
    const key = this.mapRunValueToKey(value);

    if (!this.storedCachedData.hasOwnProperty(key)) {
      this.storedCachedData[key] = new CachedData({
        callback: () => this.callback(value),
        defaultCacheDuration: this.defaultCacheDuration,
      });
    }

    return this.storedCachedData[key].run({ cacheDuration });
  }
}
