import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { SkillCertificationDTO } from "../../../../../../../shared/specific/DTOs/skillCertification/SkillCertificationDTO";
import {
  deleteSkillCertification,
  getSkillCertificationById,
} from "../../../../../../../services/skillCertification/skillCertification.service";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: skillCertification }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{ name: skillCertification?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData: null as SkillCertificationDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: skillCertificationData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const skillCertificationDTO = await getSkillCertificationById(
            skillCertificationData.id
          );
          if (!skillCertificationDTO)
            return setModalError(
              i18next.t(
                "skillCertification.errors.data.general.skillCertificationDoesNotExist"
              )
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(skillCertificationDTO);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "skillCertification.errors.data.fetch.failedToFetchSkillCertification",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: skillCertification,
      }) => {
        if (!skillCertification)
          throw new Error("Skill Certification cannot be null.");

        try {
          await deleteSkillCertification(
            skillCertification.id,
            skillCertification.version ?? ""
          );
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          if (!skillCertification?.name)
            return notifyError(
              i18next.t("skillCertification.errors.data.delete.failedToDelete")
            );

          notifyError(
            <Trans
              i18nKey="skillCertification.errors.data.delete.failedToDelete"
              values={{ name: skillCertification.name }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
