import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a b";
  max-width: 600px;
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(2)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b";
  }
`;

export const StyledDashboard = styled("iframe")`
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 90%;
  height: 80%;
`;
