import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getAdditives } from "../../../../../../../../../services/additives/additives.service";
import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { useDeletionForm } from "../useDeletionForm";
import { useEditingForm } from "../useEditingForm";
import { useViewingForm } from "../useViewingForm";
import { NegativeTableRow } from "./index.styles";
import { useEditingDeadlineAdditiveForm } from "../useEditingDeadlineAdditiveForm";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const { project, accessMode } = useProjectContext();

  const { EditingButton, editingModal } = useEditingForm({ reloadTablePage });
  const {
    EditingDeadlineAdditiveButton,
    editingEditingDeadlineAdditiveButtonModal,
  } = useEditingDeadlineAdditiveForm({ reloadTablePage });
  const { DeleteButton, deletionModal } = useDeletionForm({ reloadTablePage });
  const { ViewingButton, viewingModal } = useViewingForm();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.insertedDate"),
      t("additives.keywords.fields.scheduledStart"),
      t("additives.keywords.fields.scheduledEnd"),
      t("additives.keywords.fields.commercialMv"),
      {
        value: t("general.keywords.fields.value"),
        paddingmode: "both",
        minWidth: 150,
      },
      t("projects.keywords.fields.approvedCredit"),
      t("general.keywords.fields.justification"),
    ];

    if (accessMode === "writing")
      headCells.push({
        value: "",
        canSort: false,
        width: 152,
      });

    return headCells;
  }, [t, accessMode]);

  const onCostCenterPageChange =
    useCallback<ExternalEnhancedTableExternalSinglePageLoader>(async () => {
      if (!project) {
        throw new Error("projectData shouldn't be null at this stage.");
      }

      try {
        const additives = await getAdditives({
          filters: {
            idProject: project.id,
          },
        });

        const rows = additives.map((additive) => {
          let approvedCreditString = "-";
          if (additive.approvedCredit) {
            approvedCreditString = t("general.keywords.general.yes");
          }
          if (additive.approvedCredit === false) {
            approvedCreditString = t("general.keywords.general.no");
          }
          const row: ExternalEnhancedRow = {
            id: additive.id,
            cells: [
              {
                value: additive.insertedDate,
                displayValue: additive.insertedDate.toLocaleDateString(
                  i18n.language
                ),
              },
              {
                value: additive.scheduledStartDate.toDate(),
                displayValue: additive.scheduledStartDate
                  .toDate()
                  .toLocaleDateString(i18n.language),
              },
              {
                value: additive.scheduledEndDate.toDate(),
                displayValue: additive.scheduledEndDate
                  .toDate()
                  .toLocaleDateString(i18n.language),
              },
              {
                value: additive.mv,
                displayValue: formatNumber(additive.mv, {
                  fractionDigits: 2,
                  suffix: "%",
                }),
              },
              {
                value: additive.amount,
                displayValue: formatCurrency({
                  value: additive.amount,
                  currencySymbol: project.subsidiary.currencySymbol,
                }),
              },
              approvedCreditString,
              additive.justification,
            ],
            CustomTableRow: additive.amount < 0 ? NegativeTableRow : undefined,
          };

          if (accessMode === "writing")
            row.cells.push({
              CellRenderer: () => (
                <>
                  <Protected
                    listRestrictions={[
                      {
                        type: PermissionType.Additive,
                        level: PermissionLevel.Read,
                      },
                      {
                        type: PermissionType.AdditiveRegister,
                        level: PermissionLevel.Read,
                      },
                    ]}
                  >
                    <ViewingButton {...additive} />
                  </Protected>

                  {additive.amount === 0 ? (
                    <Protected
                      listRestrictions={[
                        {
                          type: PermissionType.Additive,
                          level: PermissionLevel.Update,
                        },
                        {
                          type: PermissionType.AdditiveRegister,
                          level: PermissionLevel.Update,
                        },
                      ]}
                    >
                      <EditingDeadlineAdditiveButton {...additive} />
                    </Protected>
                  ) : (
                    <Protected
                      restrictions={{
                        type: PermissionType.AdditiveRegister,
                        level: PermissionLevel.Update,
                      }}
                    >
                      <EditingButton {...additive} />
                    </Protected>
                  )}

                  {additive.amount === 0 ? (
                    <Protected
                      listRestrictions={[
                        {
                          type: PermissionType.Additive,
                          level: PermissionLevel.Update,
                        },
                        {
                          type: PermissionType.AdditiveRegister,
                          level: PermissionLevel.Update,
                        },
                      ]}
                    >
                      <DeleteButton {...additive} />
                    </Protected>
                  ) : (
                    <Protected
                      restrictions={{
                        type: PermissionType.AdditiveRegister,
                        level: PermissionLevel.Delete,
                      }}
                    >
                      <DeleteButton {...additive} />
                    </Protected>
                  )}
                </>
              ),
              paddingmode: "horizontal",
            });

          return row;
        });

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [project?.id, accessMode]);

  return {
    headCells,
    onCostCenterPageChange,
    editingModal,
    viewingModal,
    deletionModal,
    editingEditingDeadlineAdditiveButtonModal,
  };
};
