import { t } from "i18next";
import { Button, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { FormContentProps } from "../../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { InvoiceDTO } from "../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { LastInvoiceDto } from "../../../../../../../../../../../../shared/specific/DTOs/invoice/LastInvoiceDto";
import {
  CommonInvoiceRequestFormValues,
  FormMode,
} from "../../../../types/data/form/values.types";
import { InvoiceRequestForm } from "../InvoiceRequestForm";
import {
  StyledButtonContainer,
  StyledMainContainerApplyLastInformation,
  StyledSubContainer,
  StyledTextContainer,
} from "./index.styles";

interface OwnProps
  extends Omit<
    FormContentProps<CommonInvoiceRequestFormValues>,
    "internalFormData"
  > {
  invoice?: InvoiceDTO;
  formMode: FormMode;
  lastInvoice?: LastInvoiceDto;
  reloadTablePage?: () => void;
}

export const ApplyLastInvoiceInformation = ({
  formikProps,
  invoice,
  formMode,
  closeModal,
  reloadTablePage,
  lastInvoice,
  ...rest
}: OwnProps) => {
  const [isToShowApplyInformation, setIsToShowApplyInformation] =
    useState<boolean>(true);

  const showApplyInformationModal = useMemo(
    () =>
      isToShowApplyInformation === true &&
      lastInvoice != null &&
      !invoice?.invoiceNote,
    [isToShowApplyInformation, lastInvoice, invoice]
  );

  const { setFieldValue } = formikProps;

  const handleYes = useCallback(() => {
    setFieldValue("invoiceCopyToUser", lastInvoice?.invoiceCopyToUsers ?? []);
    setFieldValue("launchInstructions", lastInvoice?.launchInstructions ?? "");
    setFieldValue("invoiceNote", lastInvoice?.invoiceNote ?? "");
    setFieldValue("serviceDescription", lastInvoice?.serviceDescription ?? "");
    setFieldValue(
      "invoiceFinancialResponsible",
      lastInvoice?.invoiceFinancialResponsibles ?? []
    );
    setIsToShowApplyInformation(false);
  }, [lastInvoice]);

  return showApplyInformationModal ? (
    <StyledMainContainerApplyLastInformation>
      <StyledSubContainer>
        <StyledTextContainer>
          <Typography paragraph>
            {t("invoice.modal.data.create.reuseInvoiceMessage")}
          </Typography>
        </StyledTextContainer>
        <StyledButtonContainer>
          <Button onClick={() => setIsToShowApplyInformation(false)}>
            {t("general.keywords.general.no")}
          </Button>
          <Button onClick={() => handleYes()} variant="contained">
            {t("general.keywords.general.yes")}
          </Button>
        </StyledButtonContainer>
      </StyledSubContainer>
    </StyledMainContainerApplyLastInformation>
  ) : (
    <InvoiceRequestForm
      {...rest}
      formikProps={formikProps}
      closeModal={closeModal}
      formMode="creation"
      invoice={invoice}
      reloadTablePage={reloadTablePage}
    />
  );
};
