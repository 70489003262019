import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { YearDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearDatePickerFormik";
import { FormValuesPlannedAndPerformedByPepReport } from "./types/data/form.types";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { CostCenterPepCodesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenterPep/CostCenterPepCodesAutocompleteFormik";

interface OwnProps {
  formikContenProps: FormikProps<FormValuesPlannedAndPerformedByPepReport>;
}

export const FormComponent = ({
  formikContenProps: { values, isSubmitting, submitForm, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledViewContent>
      <StyledFormContainer>
        <YearDatePickerFormik
          name="startYear"
          datePickerProps={{
            label: t("general.keywords.dateTime.general.startYear"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            views: ["year"],
          }}
          textFieldProps={{ required: true }}
        />
        <YearDatePickerFormik
          name="endYear"
          datePickerProps={{
            label: t("general.keywords.dateTime.general.endYear"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            views: ["year"],
          }}
          textFieldProps={{ required: true }}
        />
        <DivisionsAutocompleteFormik
          name="division"
          textfieldProps={{
            required: true,
          }}
        />
        <SubsidiariesAutocompleteFormik
          name="subsidiary"
          textfieldProps={{
            label: t("subsidiaries.keywords.general.company"),
          }}
        />
        <CostCenterPepCodesAutocompleteFormik
          name="pepCodes"
          textfieldProps={{
            label: t("PEP"),
          }}
          autocompleteProps={{
            multiple: true,
          }}
        />
      </StyledFormContainer>
      <GenerateReportButton onClick={submitForm} isSubmitting={isSubmitting} />
      <LoadingBackdrop open={isSubmitting} />
    </StyledViewContent>
  );
};
