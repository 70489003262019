import { TabPanel } from "@mui/lab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StyledTabPanelContent } from "./index.styles";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { TabCode } from "../../shared/types/TabCode.enums";
import { DisciplinesTab } from "../../tabs/DisciplinesTab";
import { SkillCategoryTab } from "../../tabs/CategoriesTab";
import { SkillCertificationTab } from "../../tabs/CertificationTab";
import { SkillTab } from "../../tabs/SkillsTab";

export const useTabPanels = () => {
  const { t } = useTranslation();

  const tabPanels = useMemo(() => {
    const tabPanelsInfo = [
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Discipline,
              level: PermissionLevel.Read,
            }}
          >
            <DisciplinesTab />
          </Protected>
        ),
        tabCode: TabCode.Disciplines,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.SkillCategory,
              level: PermissionLevel.Read,
            }}
          >
            <SkillCategoryTab />
          </Protected>
        ),
        tabCode: TabCode.Categories,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Skill,
              level: PermissionLevel.Read,
            }}
          >
            <SkillTab />
          </Protected>
        ),
        tabCode: TabCode.Skills,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.SkillCertification,
              level: PermissionLevel.Read,
            }}
          >
            <SkillCertificationTab />
          </Protected>
        ),
        tabCode: TabCode.Certifications,
      },
    ];

    return tabPanelsInfo.map(({ component, tabCode }) => (
      <TabPanel value={tabCode} key={tabCode}>
        <StyledTabPanelContent>{component}</StyledTabPanelContent>
      </TabPanel>
    ));
  }, [t]);

  return tabPanels;
};
