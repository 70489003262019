import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CostCenterPepsAutocompleteFormik } from "../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenterPep/CostCenterPepsAutocompleteFormik";
import { ProjectSearchAutocompleteFormik } from "../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { CreationFormValue } from "../../shared/types/form.types";
import { StyledForm } from "./index.styles";

type OwnProps = FormContentProps<CreationFormValue>;

export const CreationProjectAllocationForm = ({
  formikProps: { isSubmitting, values },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <ProjectSearchAutocompleteFormik
        name="project"
        textfieldProps={{
          label: t("projects.keywords.fields.project"),
        }}
      />
      {values.project?.id && (
        <CostCenterPepsAutocompleteFormik
          name="costCenterPep"
          filters={{
            idProject: values.project?.id,
          }}
        />
      )}
    </StyledForm>
  );
};
