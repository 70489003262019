import { Button } from "@mui/material";
import i18next from "i18next";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { deleteManyActivities } from "../../../../../../../../services/activities/activities.service";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../services/applicationState/toast.service";
import { useActionButtonDialog } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { ActivityDeleteManyDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDeleteManyDTO";
import { ActivityDTO } from "../../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { TranslatedError } from "../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { checkIsDateSameProject } from "../../../../shared/helpers/DTOs/project/general.helpers";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";
import { ActivitiesSuperUserContext } from "../shared/react/contexts/ActivitiesSuperUserContext";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
  setShowSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useDeletionManyForm = ({
  reloadTablePage,
  reloadActivitySummary,
  setShowSelected,
}: OwnProps) => {
  const { t } = useTranslation();
  const { reloadProject, project } = useProjectContext();
  const { setSelectedActivities } = useContext(ActivitiesSuperUserContext);

  const {
    ModalButtonContainer: DeleteManyButtonContainer,
    contentModal: deletionManyModal,
  } = useActionButtonDialog<
    { activities: ActivityDTO[] },
    { activities: ActivityDTO[] }
  >({
    modal: {
      modalColorVariant: "error",
      modalTitle: t("activities.actions.general.deleteActivity"),
      internalLoadingPosition: "title",
      ModalContentMemo: ({ internalModalData }) => {
        return (
          <div>
            {internalModalData?.activities.map((x) => <li>{x.description}</li>)}
          </div>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={openModal}
        >
          {t("activities.actions.general.deleteActivity")}
        </Button>
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: activities,
        setInternalModalData,
        setIsLoadingModal,
      }) => {
        setIsLoadingModal(true);
        setInternalModalData(activities);
        setIsLoadingModal(false);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData }) => {
        if (
          !internalModalData?.activities ||
          internalModalData.activities?.length === 0
        )
          throw new Error("activities data cannot be null on confirm.");

        try {
          const deletedActivities: ActivityDeleteManyDTO[] =
            internalModalData.activities.map((x) => ({
              idProject: project.id,
              id: x.id,
              version: x.version,
            }));

          await deleteManyActivities(deletedActivities);

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          if (setSelectedActivities) setSelectedActivities([]);
          setShowSelected(false);
          reloadActivitySummary();
          reloadTablePage();
          closeModal();
          internalModalData.activities.forEach((activity) => {
            if (
              project.isValid ||
              checkIsDateSameProject(
                project.finalScheduledEndDate,
                activity.scheduledDate
              )
            )
              return reloadProject();
          });
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);
        }
      },
    },
  });

  return { DeleteManyButtonContainer, deletionManyModal };
};
