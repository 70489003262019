import { css, styled, TableCell, TableCellProps } from "@mui/material";

interface OwnProps extends TableCellProps {
  headColumn?: boolean;
  minWidth?: number | string;
  stickyHeader?: boolean;
}

const PureInternal = ({
  headColumn,
  stickyHeader,
  minWidth,
  ...rest
}: OwnProps) => <TableCell {...rest} />;

export const StyledTableHeadCell = styled(PureInternal)`
  padding: 0;
  border-bottom-width: 4px;

  ${({ headColumn, stickyHeader, theme }) =>
    headColumn &&
    stickyHeader &&
    css`
      position: sticky;
      left: 0;
      z-index: 4;
      border-right: solid ${theme.palette.divider} 4px;
    `}

  ${({ minWidth }) => {
    if (!minWidth) return null;
    if (typeof minWidth === "number")
      return css`
        min-width: ${minWidth}px;
      `;
    return css`
      min-width: ${minWidth};
    `;
  }}
`;
