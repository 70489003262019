import * as yup from "yup";

export interface ActivityCreationInfoDTO {
  idProject: number;
  billingAmountWithAdditives: number;
  totalProjectIncome: number;
  maxActivityAmount: number;
}

export const schemaActivityCreationInfoDTO = yup.object({
  idProject: yup.number().integer().defined(),
  billingAmountWithAdditives: yup.number().defined(),
  totalProjectIncome: yup.number().defined(),
  maxActivityAmount: yup.number().defined(),
});
