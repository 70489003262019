import * as yup from "yup";

export enum InvoiceTypeEnum {
  InvoiceNote = 1,
  DebitNote = 2,
}

export const schemaInvoiceTypeEnum = yup
  .mixed<InvoiceTypeEnum>()
  .oneOf(Object.values(InvoiceTypeEnum) as InvoiceTypeEnum[]);
