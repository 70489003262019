import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { JobRateDataFormValues } from "../../../../types/form.types";
import { parseLocaleNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { JobRoleDTO } from "../../../../../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { SubsidiaryWithBusinessRule } from "../../../../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";

interface OwnProps {
  jobRole?: JobRoleDTO;
}

export const useCreationEditingDefaultFormikConfig = ({
  jobRole,
}: OwnProps = {}) => {
  const { t } = useTranslation();

  const initialValues: JobRateDataFormValues = useMemo(() => {
    return {
      jobRole: jobRole ?? null,
      subsidiary: null,
      startYearMonth: null,
      monthlyRate: "",
      monthlyTax: "",
    };
  }, [jobRole]);

  const validationSchema = useMemo(() => {
    return yup.object({
      jobRole: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      subsidiary: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      monthlyRate: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      monthlyTax: yup
        .number()
        .transform((_value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("subsidiary.name", {
          is: (subsidiaryName: string) =>
            subsidiaryName === SubsidiaryWithBusinessRule.RDXBR,
          then(schema) {
            return schema
              .required(t("general.errors.data.fields.general.required"))
              .max(
                yup.ref("monthlyRate"),
                t("jobRates.errors.data.create.taxMustBe")
              );
          },
        }),
    });
  }, [t]);

  return useMemo(() => {
    return {
      initialValues,
      validationSchema,
      enableReinitialize: true,
    };
  }, [initialValues, validationSchema]);
};
