import { ReactNode } from "react";
import { CarouselContextProvider } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";

interface OwnProps {
  children: ReactNode;
}

const CAROUSEL_SIZE = 12;

export const CorporateAllocationsCarouselContextProvider = ({
  children,
}: OwnProps) => {
  return (
    <CarouselContextProvider initialCarouselSize={CAROUSEL_SIZE}>
      {children}
    </CarouselContextProvider>
  );
};
