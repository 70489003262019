import * as yup from "yup";
import {
  ProjectSummaryInFrameDTO,
  schemaProjectSummaryInFrameDTO,
} from "./Projects/ProjectSummaryInFrameDTO";

export interface ProjectByIdFrameDTO {
  totalFrame: number;
  projects: ProjectSummaryInFrameDTO[];
}

export const schemaProjectByIdFrameDTO = yup.object({
  totalFrame: yup.number().defined(),
  projects: yup.array(schemaProjectSummaryInFrameDTO).defined(),
});
