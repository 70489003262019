import { useEffect, useState } from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import { ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { PageCardButtonContainer } from "../../../../shared/common/react/components/navigation/PageCardButton/index.styles";
import { SyncPipedriveData } from "../../../../services/admin/admin.service";
import { LoadingBackdrop } from "../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { notifySuccess } from "../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const SyncPipedriveDataCardComponent = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const syncData = async () => {
      try {
        await SyncPipedriveData();
        notifySuccess(
          i18next.t(
            "general.success.data.general.operationExecutedSuccessfully"
          )
        );
      } catch (error) {
        console.error(error);
        notifyIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "adminPage.errors.data.general.failedToSyncPipedriveData",
        });
      } finally {
        setIsSubmitting(false);
      }
    };

    if (isSubmitting) {
      syncData();
    }
  }, [isSubmitting]);

  return (
    <ButtonBase onClick={() => setIsSubmitting(true)} disabled={isSubmitting}>
      <PageCardButtonContainer>
        <CloudSyncIcon color={isSubmitting ? "disabled" : "primary"} />
        <Typography>
          {t("adminPage.keywords.general.syncPipedriveInformation")}
        </Typography>
      </PageCardButtonContainer>
      <LoadingBackdrop open={isSubmitting} />
    </ButtonBase>
  );
};
