import { ReactNode } from "react";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CarouselContextProvider } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";

interface OwnProps {
  children: ReactNode;
  earliestYearMonth: YearMonth | null;
  latestYearMonth: YearMonth | null;
  firstYearMonth?: number;
}

export const SummaryCarouselContextProvider = ({
  children,
  earliestYearMonth,
  latestYearMonth,
  firstYearMonth,
}: OwnProps) => {
  return (
    <CarouselContextProvider
      initialEarliestYearMonth={earliestYearMonth}
      initialLatestYearMonth={latestYearMonth}
      firstYearMonth={firstYearMonth}
    >
      {children}
    </CarouselContextProvider>
  );
};
