import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { FormValues } from "../types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      languages: [],
      languageDropdown: [],
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      languages: yup.array(
        yup.object({
          language: yup
            .object()
            .required(t("general.errors.data.fields.general.required")),
          level: yup
            .object()
            .required(t("general.errors.data.fields.general.required")),
        })
      ),
      languageDropdown: yup.array(),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
