import { TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { DependencyList, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffectAfterRenders } from "../../../../../hooks/enhancedReactHooks/useEffectAfterRenders";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "./AutocompleteFormik";

type FetchOptionsFunction<T> = (textFieldValue: string) => T[] | Promise<T[]>;

export type SearchAutocompleteFormikProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = {
  name: string;
  customErrorMessage?: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options" | "freeSolo"
  >;
  shouldReplaceValueOnMount?: boolean;
  rerunOnDeps?: DependencyList;
} & (
  | { fetchOptions?: undefined; fetchOptionsMemo: FetchOptionsFunction<T> }
  | { fetchOptions: FetchOptionsFunction<T>; fetchOptionsMemo?: undefined }
);

export const SearchAutocompleteFormik = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  fetchOptions,
  fetchOptionsMemo,
  customErrorMessage,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnMount,
  rerunOnDeps = [],
}: SearchAutocompleteFormikProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const [{ value: valueFormik }] = useField<T | T[]>(name);
  const { t } = useTranslation();

  const [valueTextField, setValueTextField] = useState<string>("");
  const [options, setOptions] = useState<readonly T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const timeoutValueTextFieldFetchRef = useRef<NodeJS.Timeout | null>(null);
  const fetchSetOptionsCounterRef = useRef(0);

  const fetchOptionsFinal = useMemo(() => {
    return fetchOptions ?? fetchOptionsMemo;
  }, [fetchOptions]);

  const fetchSetOptions = async (valueTextField: string) => {
    const currentCounter = ++fetchSetOptionsCounterRef.current;
    try {
      const options = await Promise.resolve(fetchOptionsFinal(valueTextField));
      if (currentCounter !== fetchSetOptionsCounterRef.current) return;

      if (autocompleteProps?.multiple && Array.isArray(valueFormik)) {
        const finalIsOptionEqualToValue =
          autocompleteProps.isOptionEqualToValue ??
          autocompleteProps.isOptionEqualToValueMemo;

        for (const itemFormik of valueFormik) {
          const hasOption = options.some((x) => {
            if (!x) return false;
            if (!finalIsOptionEqualToValue) return x === itemFormik;
            return finalIsOptionEqualToValue(x, itemFormik);
          });

          if (!hasOption) options.push(itemFormik);
        }
      }

      setOptions(options);
    } catch (error) {
      if (currentCounter !== fetchSetOptionsCounterRef.current) return;

      console.error(error);

      setError(true);
      setOptions([]);
    }

    setIsLoading(false);
  };

  useEffectAfterRenders({
    effect: () => {
      if (!valueTextField) return;

      setIsLoading(true);
      setError(false);
      setOptions([]);
      fetchSetOptions(valueTextField);
    },
    deps: [...rerunOnDeps],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      if (timeoutValueTextFieldFetchRef.current) {
        clearTimeout(timeoutValueTextFieldFetchRef.current);
        timeoutValueTextFieldFetchRef.current = null;
      }

      setError(false);
      if (options.length > 0) setOptions([]);

      if (!valueTextField) return;

      setIsLoading(true);
      timeoutValueTextFieldFetchRef.current = setTimeout(
        () => fetchSetOptions(valueTextField),
        500
      );
    },
    deps: [valueTextField],
    rendersBeforeEffect: 1,
  });

  const InputLabelProps = useMemo(() => {
    if (!autocompleteProps?.readOnly) return textfieldProps?.InputLabelProps;

    return {
      ...textfieldProps?.InputLabelProps,
      shrink: Boolean(textfieldProps?.value),
    };
  }, [
    textfieldProps?.InputLabelProps,
    textfieldProps?.value,
    autocompleteProps?.readOnly,
  ]);

  const textFieldColor = useMemo(() => {
    if (error) return "error";
    return textfieldProps?.color;
  }, [error, textfieldProps?.color]);

  const textFieldFocused = useMemo(() => {
    if (error) return true;
    return textfieldProps?.focused;
  }, [error, textfieldProps?.focused]);

  const noOptionsText = useMemo(() => {
    if (error)
      return (
        customErrorMessage ??
        t("general.errors.data.fetch.failedToFetchOptions")
      );
    if (!valueTextField) return t("general.actions.form.typeToSearch");
    return autocompleteProps?.noOptionsText;
  }, [
    t,
    error,
    valueTextField,
    customErrorMessage,
    autocompleteProps?.noOptionsText,
  ]);

  const onChangeTextField = useCallback<
    Exclude<TextFieldProps["onChange"], undefined>
  >(
    (event) => {
      setValueTextField(event.target.value);
      textfieldProps?.onChange?.(event);
    },
    [textfieldProps?.onChange]
  );

  const onBlurTextField = useCallback<
    Exclude<TextFieldProps["onBlur"], undefined>
  >(
    (event) => {
      setValueTextField("");
      setOptions([]);
      textfieldProps?.onBlur?.(event);
    },
    [textfieldProps?.onBlur]
  );

  return (
    <AutocompleteFormik
      name={name}
      textfieldProps={{
        ...textfieldProps,
        InputLabelProps,
        onChange: onChangeTextField,
        onBlur: onBlurTextField,
        color: textFieldColor,
        focused: textFieldFocused,
      }}
      autocompleteProps={{
        options,
        loading: isLoading,
        ...autocompleteProps,
        noOptionsText,
      }}
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
