import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ExternalEnhancedRow } from "../../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { CarouselCell } from "../../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { YearMonth } from "../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  values: number[];
  description: string;
  isPercentage?: boolean;
  isToShowCustomerTrigramAndClientAccount: boolean;
}

export const useGenerateSumRow = () => {
  function generateId() {
    return Math.random().toString(30).substr(2, 12).toString();
  }
  const [searchParams] = useSearchParams();
  const idSubsidiary = searchParams.get("idSubsidiary");
  const year = searchParams.get("year");

  const idSubsidiaryValidated = useMemo(() => {
    return /^\d+$/.test(idSubsidiary ?? "")
      ? parseInt(idSubsidiary ?? "")
      : null;
  }, [idSubsidiary]);

  const currency = idSubsidiaryValidated === 1 ? "R$" : "US$";

  const yearValidated = useMemo(() => {
    return /^\d+$/.test(year ?? "") ? parseInt(year ?? "") : null;
  }, [year]);

  const rangeYearMonth = YearMonth.range(
    new YearMonth(0, yearValidated ?? 0),
    new YearMonth(11, yearValidated ?? 0)
  );

  const GenerateSumRow = useCallback(
    ({
      description,
      values,
      isPercentage,
      isToShowCustomerTrigramAndClientAccount,
    }: OwnProps): ExternalEnhancedRow => {
      const row: ExternalEnhancedRow = {
        id: generateId(),
        cells: [
          "",
          "",
          "",
          "",
          "",
          {
            CellRenderer: () => <h4>{description}</h4>,
          },
          "",
          "",
        ],
      };

      if (!isToShowCustomerTrigramAndClientAccount) {
        row.cells.splice(3, 2);
      }

      for (let i = 0; i < 3; i++) {
        row.cells.push({
          paddingmode: "none",
          justifyContent: "center",
          CellRenderer: () => {
            return (
              <CarouselCell
                items={rangeYearMonth}
                columnIndex={i}
                checkIsCurrentItem={(item, yearMonth) => item.equals(yearMonth)}
                CarouselComponentMemo={({ yearMonth }) => (
                  <h4>
                    {`${!isPercentage ? currency : ""} ${formatNumber(
                      values[yearMonth?.month ?? 12],
                      {
                        fractionDigits: 2,
                      }
                    )} ${isPercentage ? "%" : ""}`}
                  </h4>
                )}
              />
            );
          },
        });
      }

      row.cells.push({});

      row.cells.push("");

      return row;
    },
    []
  );

  return { GenerateSumRow };
};
