import { styled } from "@mui/material";

export const StyledCostCenterPepsPageContainer = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FooterStyles = styled("div")`
  display: flex;
  justify-content: right;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: 8px;
`;

export const StyledTableTitle = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  justify-content: center;

  & > :nth-child(1) {
    font-size: 1.25rem;
    word-break: break-all;
  }

  & > :nth-child(2) {
    font-size: 1rem;
    color: ${({ theme }) => theme.palette.text.secondary};
    word-break: break-all;
  }
`;
