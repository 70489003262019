import i18next from "i18next";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { addMonths } from "date-fns";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import {
  getPipedriveDealById,
  getProjectsOnly,
} from "../../../../../services/projects/projects.service";
import { parseLocaleNumber } from "../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { AdditiveCreateDTO } from "../../../../../shared/specific/DTOs/additive/AdditiveCreateDTO";
import { DateOnly } from "../../../../../shared/common/classes/data/date/DateOnly";
import { createAdditive } from "../../../../../services/additives/additives.service";
import { formatNumber } from "../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  AdditivesFormMode,
  CommonAdditivesFormRef,
  OnFormSubmitType,
} from "./types/values.types";
import { CommonAdditiveForm } from "./components/CommonAdditiveForm";
import { ProjectStatus } from "../../../../../shared/specific/enums/projects/ProjectStatus.enum";

type OwnParams = {
  id: string;
};

interface OwnProps {
  mode: AdditivesFormMode;
}

export const AdditivesCreationPage = ({ mode }: OwnProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formRef = useRef<CommonAdditivesFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      try {
        if (id) {
          const pipedriveDeal = await getPipedriveDealById(id);
          if (!pipedriveDeal) {
            return formRef.current?.setError(
              "additives.errors.data.general.additiveDoesNotExist"
            );
          }
          let project = null;
          const projectsOnly = await getProjectsOnly({
            filters: {
              name: pipedriveDeal.idProject,
              statusIncludeOnly: [ProjectStatus.Ongoing],
            },
          });
          project =
            projectsOnly.tableData.length === 1
              ? projectsOnly.tableData[0]
              : null;

          let approvedCredit = null;
          if (pipedriveDeal.approvedCredit) {
            approvedCredit = {
              id: true,
              label: t("general.keywords.general.yes"),
            };
          }

          if (pipedriveDeal.approvedCredit === false) {
            approvedCredit = {
              id: false,
              label: t("general.keywords.general.no"),
            };
          }

          let scheduledStartDate = null;
          if (
            pipedriveDeal.expectedCloseDate &&
            pipedriveDeal.projectBeginnigDate
          ) {
            scheduledStartDate =
              pipedriveDeal.expectedCloseDate >
              pipedriveDeal.projectBeginnigDate
                ? pipedriveDeal.expectedCloseDate
                : pipedriveDeal.projectBeginnigDate;
          } else if (pipedriveDeal.expectedCloseDate) {
            scheduledStartDate = pipedriveDeal.expectedCloseDate;
          } else if (pipedriveDeal.projectBeginnigDate) {
            scheduledStartDate = pipedriveDeal.projectBeginnigDate;
          }

          formRef.current?.setFormValues({
            anticipationJustification: "",
            justification: "",
            amount: pipedriveDeal.billingAmount
              ? formatNumber(pipedriveDeal.billingAmount, { fractionDigits: 2 })
              : formatNumber(0, { fractionDigits: 2 }),
            approvedCredit,
            mv: pipedriveDeal.mv
              ? formatNumber(pipedriveDeal.mv, { fractionDigits: 2 })
              : formatNumber(0, { fractionDigits: 2 }),
            project,
            scheduledStartDate,
            scheduledEndDate:
              scheduledStartDate && pipedriveDeal.projectDeadline
                ? addMonths(scheduledStartDate, pipedriveDeal.projectDeadline)
                : null,
          });

          formRef.current?.setDealData(pipedriveDeal);
        }
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, []);

  const onSubmit: OnFormSubmitType = async (formValues, { setSubmitting }) => {
    if (!formValues.scheduledStartDate || !formValues.scheduledEndDate)
      throw new Error("Dates cannot be null on submit.");
    if (!formValues.project)
      throw new Error("'project' cannot be null on submit.");

    try {
      if (id) {
        const pipedriveDeal = await getPipedriveDealById(id);
        if (!pipedriveDeal) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }
      }

      const additiveToCreate: AdditiveCreateDTO = {
        idProject: formValues.project.id,
        amount: parseLocaleNumber(formValues.amount),
        justification: formValues.justification,
        anticipationJustification:
          formValues.anticipationJustification || undefined,
        mv: parseLocaleNumber(formValues.mv),
        scheduledStartDate: DateOnly.createFromDate(
          formValues.scheduledStartDate
        ),
        scheduledEndDate: DateOnly.createFromDate(formValues.scheduledEndDate),
        approvedCredit: formValues.approvedCredit?.id ?? false,
        idPipedriveDeal: id || undefined,
      };

      await createAdditive(additiveToCreate);

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
      const valueReturnTo = encodeURIComponent(
        `registration-of-projects-and-additives?tab=additive`
      );
      navigate(
        `/projects/${formValues.project.id}/planning?return-to=${valueReturnTo}&tab=additives`
      );
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "projects.errors.data.update.failedToUpdateProject",
      });
    } finally {
      setSubmitting(false);
      formRef.current?.setLoading(false);
    }
  };

  return <CommonAdditiveForm mode={mode} onSubmit={onSubmit} ref={formRef} />;
};
