import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { JobRoleDTO } from "../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { JobRoleDataFormValues } from "../shared/types/form.types";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getJobRoleById,
  updateJobRole,
} from "../../../../../../services/jobRole/jobRole.service";
import {
  formatFromTranslatableFieldValues,
  formatToTranslatableFieldValues,
} from "../../../../../../shared/common/helpers/data/form/languages/formatters.helpers";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { JobRoleDataForm } from "../components/JobRoleDataForm";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { JobRoleUpdateDTO } from "../../../../../../shared/specific/DTOs/jobRole/JobRoleUpdateDTO";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  EditingButtonContainer: (props: JobRoleDTO) => JSX.Element | null;
  editingModal: JSX.Element | null;
};

export const useEditingForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();

  const { initialValues, validationSchema } =
    useCreationEditingDefaultFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<JobRoleDataFormValues, JobRoleDTO, JobRoleDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: jobRole }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: jobRole?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: jobRoleRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const jobRoleDTO = await getJobRoleById(jobRoleRow.id);
            if (!jobRoleDTO)
              return setModalError(
                i18next.t("jobRoles.errors.data.general.jobRoleDoesNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(jobRoleDTO);
            setFormValues({
              name: jobRoleDTO.name ?? "",
              deactivatedDate: jobRoleDTO.deactivatedDate,
              nameTranslations: formatToTranslatableFieldValues({
                source: jobRoleDTO.jobRoleTranslations,
                convertItem: ({ idLanguage, name }) => ({
                  language: idLanguage,
                  value: name ?? "",
                }),
              }),
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "jobRoles.errors.data.fetch.failedToFetchJobRole",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig: {
          initialValues,
          validationSchema,
        },
        FormContentMemo: JobRoleDataForm,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: jobRole,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!jobRole)
            throw new Error("Job Role cannot be null on submission.");

          if (!formValues.name) throw new Error("Required values are missing.");

          try {
            await updateJobRole(
              convertTo<JobRoleUpdateDTO>({
                id: jobRole.id,
                name: formValues.name,
                deactivatedDate: formValues.deactivatedDate,
                version: jobRole.version,
                jobRoleTranslations: formatFromTranslatableFieldValues({
                  source: formValues.nameTranslations,
                  convertItem: ({ language, value }) => ({
                    idLanguage: language,
                    name: value,
                  }),
                }),
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (jobRole?.name)
              return notifyError(
                <Trans
                  i18nKey="jobRoles.errors.data.edit.failedToEditName"
                  values={{ name: jobRole?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("jobRoles.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData: null as JobRoleDTO | null,
      },
    });

  return { EditingButtonContainer, editingModal };
};
