import { Chip, styled } from "@mui/material";

export const StyledContainer = styled("div")`
  width: 98%;
  gap: 20px;
`;

export const MilestoneLine = styled(Chip)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const MilestoneInternalComponent = styled("div")`
  display: flex;
  justify-content: flex-start;
  min-width: 700px;
  gap: 15px;

  span {
    width: 140px;
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
  }
`;

export const MilestoneInternalComponentHeader = styled("div")`
  display: flex;
  justify-content: flex-start;
  gap: 15px;

  span {
    width: 140px;
    font-weight: bold;
  }

  margin-left: 16px;
`;

export const StyledFindProject = styled("div")`
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 2px;
  border-top: solid #c5c5c5 0.5px;
  color: ${({ theme }) => theme.palette.text.secondary};
`;
