import * as yup from "yup";
import {
  LabelColorType,
  LabelType,
} from "../../../../Router/pages/authenticated/AllocationsMap/AllocationsMapMainPage/shared/types/form.types";

export interface AllocationMapUpdatedDTO {
  labelType: LabelType;
  labelColorType: LabelColorType;
}

export const schemaAllocationMapUpdatedDTO = yup.object({
  labelType: yup.number().integer().defined(),
  labelColorType: yup.number().integer().defined(),
});
