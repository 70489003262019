import { PlayCircleFilled as PlayCircleFilledIcon } from "@mui/icons-material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import {
  getProjectOnlyById,
  reopenProject,
} from "../../../../../../services/projects/projects.service";
import { SimpleIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { ProjectDTO } from "../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectOnlyDTO } from "../../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useReopenProject = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: ReopenProjectButtonContainer,
    contentModal: reopenProjectModal,
  } = useActionButtonDialog<ProjectOnlyDTO, ProjectDTO>({
    modal: {
      ModalTitleMemo: ({ internalModalData: projectData }) => {
        return (
          <Trans
            i18nKey="projects.modal.data.reopenProjectName.title"
            values={{ projectName: projectData?.name ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => {
        const { t } = useTranslation();

        return (
          <SimpleIconButton
            tooltipText={t("projects.actions.general.reopenProject")}
            Icon={PlayCircleFilledIcon}
            onClick={openModal}
            color="primary"
          />
        );
      },
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: projectData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const project = await getProjectOnlyById(projectData.id);

          if (!project)
            return setModalError(
              i18next.t("projects.errors.data.general.projectDoesNotExist")
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(project);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "projects.errors.data.fetch.failedToFetchProjects",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.reopen"),
      onConfirmMemo: async ({ closeModal, internalModalData: projectData }) => {
        if (!projectData)
          throw new Error("'projectData' cannot be null on confirm.");

        try {
          await reopenProject({
            idProject: projectData.id,
            version: projectData.version,
          });
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          console.error(error);

          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "projects.errors.data.reopen.failedToReopenProject",
          });
        }
      },
    },
  });

  return { ReopenProjectButtonContainer, deletionModal: reopenProjectModal };
};
