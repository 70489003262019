import { BaseCollaboratorReportScopeTypeEnum } from "../../enums/BaseCollaboratorReportScopeType/ReportScopeType.enum";

export const BaseCollaboratorReportScopeTypeToTranslationCodeMap: {
  readonly [key in BaseCollaboratorReportScopeTypeEnum]: string;
} = {
  [BaseCollaboratorReportScopeTypeEnum.AllAllocations]:
    "reports.pages.fields.allAllocations",
  [BaseCollaboratorReportScopeTypeEnum.AllCollaborators]:
    "reports.pages.fields.allCollaborators",
};
