import { useField } from "formik";
import { FocusEvent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MaskedTextField } from "../../../../../../../../../../../../shared/common/react/components/form/general/textField/MaskedTextField";

interface OwnProps {
  name: string;
  prefix?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const MaskedPepCode = ({
  name,
  prefix,
  required,
  disabled,
  readOnly,
}: OwnProps) => {
  const { t } = useTranslation();
  const [{ value }, { error }, { setValue }] = useField<string>(name);

  return useMemo(() => {
    const formattedMaskPrefix = prefix
      ?.split("")
      .map((c) => `\\${c}`)
      .join("");

    return (
      <MaskedTextField
        mask={
          formattedMaskPrefix
            ? `${formattedMaskPrefix}\\-00000\\.0`
            : "aaa\\-00000\\.0"
        }
        textFieldProps={{
          label: t("costCenterPeps.keywords.fields.pepCode"),
          error: !!error,
          helperText: error,
          InputLabelProps: {
            shrink: !!value || !!formattedMaskPrefix || undefined,
          },
          InputProps: {
            readOnly,
          },
        }}
        prepare={(text: string) => text.toUpperCase()}
        value={value}
        onBlur={(event: FocusEvent<HTMLInputElement>) =>
          setValue(event.target.value)
        }
        required={required}
        lazy={false}
        disabled={disabled}
      />
    );
  }, [name, prefix, required, value, error, t, disabled, readOnly]);
};
