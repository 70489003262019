import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface WaccDTO {
  id: number;
  yearMonth: YearMonth;
  plannedTax: number;
  performedTax: number | null;
  version: string;
}

export const schemaWaccDTO = yup.object({
  id: yup.number().integer().defined(),
  yearMonth: schemaYearMonth.defined().required().defined(),
  plannedTax: yup.number().required().positive().defined(),
  performedTax: yup.number().nullable().defined(),
  version: yup.string().defined(),
});
