import { useContext, useEffect, useState } from "react";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpensesSummaryContent } from "./components/ExpensesSummaryContent";
import { ProjectContext } from "../../../../shared/react/contexts/ProjectContext";

interface OwnProps {
  initialCostCenterPep: CostCenterPepOnlyDTO;
}

export const useExpensesSummary = ({ initialCostCenterPep }: OwnProps) => {
  const projectContext = useContext(ProjectContext);
  const [costCenterPep, setCostCenterPep] =
    useState<CostCenterPepOnlyDTO>(initialCostCenterPep);

  useEffect(() => {
    if (projectContext?.setCurrentCostCenterPep) {
      projectContext.setCurrentCostCenterPep(costCenterPep);
    }
  }, [costCenterPep]);

  return {
    expensesSummaryComponent: (
      <ExpensesSummaryContent
        costCenterPep={costCenterPep}
        setCostCenterPep={setCostCenterPep}
      />
    ),
    costCenterPep,
  };
};
