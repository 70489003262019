import { FormikErrors } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CommonInvoiceRequestFormValues } from "../../../../../types/data/form/values.types";
import { useEffectAfterRenders } from "../../../../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { notifyError } from "../../../../../../../../../../../../../services/applicationState/toast.service";

interface OwnProps {
  errors: FormikErrors<CommonInvoiceRequestFormValues>;
  isSubmitting: boolean;
}

export const useErrorToastMessage = ({ errors, isSubmitting }: OwnProps) => {
  const { t } = useTranslation();

  const fieldNameMapper: { [key: string]: string } = useMemo(() => {
    return {
      invoices: t("invoice.keywords.fields.invoicesMilestones"),
      customer: t("customers.keywords.general.customer"),
      costCenterPep: t("costCenterPeps.keywords.general.costCenterPeps"),
      invoiceType: t("invoice.keywords.fields.invoiceType"),
      invoiceCompetence: t("invoice.keywords.fields.competence"),
      limitDate: t("invoice.keywords.fields.deadline"),
      invoiceFinancialResponsible: t(
        "invoice.keywords.fields.financialResponsibleEmail"
      ),
      serviceDescription: t("invoice.keywords.fields.serviceDescription"),
      launchInstructions: t("invoice.keywords.fields.launchInstructions"),
      invoiceNote: t("invoice.keywords.fields.invoiceNote"),
      invoiceCopyToUser: t("invoice.keywords.fields.sendCopyTo"),
      selectedCompetence: t("invoice.keywords.fields.competence"),
      invoiceFinancialResponsibleSelected: t(
        "invoice.keywords.fields.financialResponsibleEmail"
      ),
    };
  }, [t]);

  useEffectAfterRenders({
    effect: () => {
      if (Object.keys(errors).length > 0 && isSubmitting)
        notifyError(
          <div>
            {t("invoice.errors.data.update.invoiceRequestErrors")}
            <ul>
              {Object.keys(errors).map((key, i) => (
                <li key={key}>
                  <span
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {fieldNameMapper[key]}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        );
    },
    deps: [Object.keys(errors).length, isSubmitting],
    rendersBeforeEffect: 1,
  });
};
