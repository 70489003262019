import { useContext, useMemo } from "react";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import {
  getNowBrazilianDate,
  getNowYearMonthBrazil,
} from "../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { TimesheetSuperUserContext } from "../../../contexts/TimesheetSuperUserContext";

interface OwnProps {
  yearMonth: YearMonth;
}

export const useCanModifyMonth = ({ yearMonth }: OwnProps) => {
  const { canEditPastTimesheet } = useContext(TimesheetSuperUserContext);

  const canModifyYearMonth = useMemo(() => {
    if (canEditPastTimesheet) return true;

    const currentYearMonth = getNowYearMonthBrazil();
    if (currentYearMonth.lessThanOrEqual(yearMonth)) return true;

    const lastMonth = currentYearMonth.addMonths(-1);
    const currentDay = getNowBrazilianDate().getDate();
    if (lastMonth.equals(yearMonth) && currentDay <= 5) return true;

    return false;
  }, [canEditPastTimesheet, yearMonth]);

  return { canModifyYearMonth };
};
