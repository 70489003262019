import * as yup from "yup";
import {
  CountryDTO,
  schemaCountryDTO,
} from "../../../shared/common/DTOs/publicData/countries/CountryDTO";
import { Language } from "../../../shared/common/enums/locale/Language.enum";
import { publicConnection } from "./connection";

export const getCountries = async (
  language: Language
): Promise<CountryDTO[]> => {
  const { data: countries } = await publicConnection.get(
    `data/location/countries/${language}.json`
  );

  return yup.array(schemaCountryDTO).defined().validateSync(countries);
};
