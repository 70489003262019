import { Button, Tooltip, Typography, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledForm = styled("div")<{ filterIsOpen: boolean }>`
  display: grid;
  /* overflow-y: hidden; */
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas:
    "a b c d e"
    "f g h i j";
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  height: ${({ filterIsOpen }) => (filterIsOpen ? "125px" : "0px")};
  ${createGridAreaLettersRange(10)}
  transition: height ease 0.25s;
  overflow: ${({ filterIsOpen }) => (filterIsOpen ? "" : "hidden")};
`;

export const StyledButtons = styled(Button)`
  min-width: 35px;
  max-width: 35px;
`;

export const StyledButtonsSection = styled("div")`
  gap: 0;
  display: flex;
  width: max-content;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const WarningText = styled(Typography)`
  color: red;
`;

export const StyledMultipleSelectionContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledName = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledIcon = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledYearMonth = styled("div")`
  max-width: 120px;
`;
