import * as yup from "yup";
import { ProjectBaselineFiltersDTO } from "../../../shared/specific/DTOs/projectBaseline/filters/ProjectBaselineFiltersDTO";
import {
  ProjectBaselineOnlyDTO,
  schemaProjectBaselineOnlyDTO,
} from "../../../shared/specific/DTOs/projectBaseline/ProjectBaselineOnlyDTO";
import {
  ProjectBaselineDTO,
  schemaProjectBaselineDTO,
} from "../../../shared/specific/DTOs/projectBaseline/ProjectBaselineDTO";
import { riseApi } from "./connection";

export const getProjectBaselines = async ({
  filters,
}: {
  filters: ProjectBaselineFiltersDTO;
}): Promise<ProjectBaselineOnlyDTO[]> => {
  const { data: projectBaselines } = await riseApi.get("ProjectBaselines", {
    params: filters,
  });

  return yup
    .array(schemaProjectBaselineOnlyDTO)
    .defined()
    .validateSync(projectBaselines);
};

export const getProjectBaseline = async (
  id: number
): Promise<ProjectBaselineDTO | null> => {
  const { data: projectBaseline } = await riseApi.get(`ProjectBaselines/${id}`);

  return schemaProjectBaselineDTO.nullable().validateSync(projectBaseline);
};
