import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createTranslatableFieldValues } from "../../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { ReadOnlyTextFieldWithTranslationsModal } from "../../../../../../../../shared/common/react/components/form/general/translatable/ReadOnlyTextFieldWithTranslationsModal";
import { TranslatableFieldValues } from "../../../../../../../../shared/common/types/data/form/translation.types";
import { JobRoleDTO } from "../../../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { StyledJobRoleContent } from "./index.style";

interface OwnProps {
  jobRoleDTO?: JobRoleDTO;
}

export const JobRoleContent = ({ jobRoleDTO }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const translatableNames: TranslatableFieldValues = useMemo(() => {
    const translatableNames = createTranslatableFieldValues("-");

    if (!jobRoleDTO?.jobRoleTranslations) return translatableNames;

    const jobRoleTranslationsArray = Object.values(
      jobRoleDTO.jobRoleTranslations
    );

    for (const { idLanguage, name } of jobRoleTranslationsArray) {
      translatableNames[idLanguage] = name ?? "-";
    }

    return translatableNames;
  }, [jobRoleDTO?.jobRoleTranslations]);

  if (!jobRoleDTO) return null;

  return (
    <StyledJobRoleContent>
      <ReadOnlyTextFieldWithTranslationsModal
        label={t("general.keywords.fields.name")}
        translatableFieldValues={translatableNames}
      />
      <TextField
        label={t("jobRoles.keywords.general.deactivatedDate")}
        value={
          jobRoleDTO.deactivatedDate?.toLocaleDateString(i18n.language) ?? ""
        }
        InputProps={{ readOnly: true }}
      />
    </StyledJobRoleContent>
  );
};
