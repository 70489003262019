import * as yup from "yup";
import { DisciplineCreateDTO } from "../../../shared/specific/DTOs/discipline/DisciplineCreateDTO";
import {
  DisciplineDropdownDTO,
  schemaDisciplineDropdownDTO,
} from "../../../shared/specific/DTOs/discipline/DisciplineDropdownDTO";
import {
  DisciplineDTO,
  schemaDisciplineDTO,
} from "../../../shared/specific/DTOs/discipline/DisciplineDTO";
import { DisciplineFiltersDTO } from "../../../shared/specific/DTOs/discipline/DisciplineFiltersDTO";
import { DisciplineUpdateDTO } from "../../../shared/specific/DTOs/discipline/DisciplineUpdateDTO";
import {
  PaginatedDisciplineDTO,
  schemaPaginatedDisciplineDTO,
} from "../../../shared/specific/DTOs/discipline/PaginatedDisciplineDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getDisciplines = async ({
  filters,
  pagination,
}: {
  filters?: DisciplineFiltersDTO;
  pagination?: PaginationDTO;
}): Promise<PaginatedDisciplineDTO> => {
  const { data: disciplines } = await riseApi.get("Disciplines", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedDisciplineDTO.validateSync(disciplines);
};

export const getDisciplinesDropdown = async ({
  filters,
}: {
  filters?: DisciplineFiltersDTO;
}): Promise<DisciplineDropdownDTO[]> => {
  const { data: disciplines } = await riseApi.get("Disciplines/Dropdown", {
    params: { ...filters },
  });

  return yup
    .array(schemaDisciplineDropdownDTO)
    .defined()
    .validateSync(disciplines);
};

export const getDisciplineById = async (
  id: number
): Promise<DisciplineDTO | null> => {
  const { data: disciplines } = await riseApi.get(`/Disciplines/${id}`);
  return schemaDisciplineDTO.nullable().validateSync(disciplines);
};

export const createDiscipline = async (discipline: DisciplineCreateDTO) => {
  await riseApi.post("Disciplines", discipline);
};

export const updateDiscipline = async (discipline: DisciplineUpdateDTO) => {
  await riseApi.put("Disciplines", discipline);
};

export const deleteDiscipline = async (id: number, version: string) => {
  await riseApi.delete(`Disciplines/${id}/${version}`);
};
