import { Tab } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { TabCode } from "../../enums/TabCode.enums";
import { TabInfo } from "./index.types";
import { usePermissionChecker } from "../../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";

export const useTabs = () => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const { hasPermission: canReadActivities } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Additive,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadInvoices } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Invoice,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadRevenueInvoices } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Invoice,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadAlloctions } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Allocation,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadCostExpenses } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Expenses,
      level: PermissionLevel.Read,
    },
  });

  const { hasPermission: canReadAdditives } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Additive,
      level: PermissionLevel.Read,
    },
  });

  const canReadSummary =
    canReadActivities &&
    canReadInvoices &&
    canReadRevenueInvoices &&
    canReadAlloctions &&
    canReadCostExpenses &&
    canReadAdditives;

  const projectStatusList = [
    ProjectStatus.Ongoing,
    ProjectStatus.Finalized,
    ProjectStatus.Canceled,
  ];

  const tabs = useMemo(() => {
    const tabsInfo: (TabInfo | null)[] = [];

    if (canReadActivities)
      tabsInfo.push({
        label: t("activities.keywords.general.activities"),
        value: TabCode.Activities,
      });

    if (canReadInvoices)
      tabsInfo.push({
        label: t("invoice.keywords.general.invoice"),
        value: TabCode.Invoice,
      });

    if (canReadRevenueInvoices)
      tabsInfo.push({
        label: t("revenueInvoices.keywords.general.revenueInvoices"),
        value: TabCode.Revenue,
      });

    if (canReadAlloctions)
      tabsInfo.push({
        label: t("allocations.keywords.general.allocations"),
        value: TabCode.Allocations,
      });

    if (canReadCostExpenses)
      tabsInfo.push({
        label: t("expenses.keywords.general.costsExpenses"),
        value: TabCode.Expenses,
      });

    if (canReadAdditives && projectStatusList.includes(project.status))
      tabsInfo.push({
        label: t("additives.keywords.general.additives"),
        value: TabCode.Additives,
      });

    if (canReadSummary)
      tabsInfo.push({
        label: t("planningSummary.keywords.general.summary"),
        value: TabCode.Summary,
      });

    const tabsInfoNotNull = tabsInfo.filter((x) => !!x) as TabInfo[];
    return tabsInfoNotNull.map(({ label, value }) => (
      <Tab label={label} value={value} key={value} />
    ));
  }, [t, project.status]);

  return tabs;
};
