import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { JobRateDataForm } from "../components/JobRateDataForm";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { createJobRate } from "../../../../../../services/jobRate/jobRate.service";
import { JobRateCreateDTO } from "../../../../../../shared/specific/DTOs/jobRate/JobRateCreateDTO";
import { JobRoleDTO } from "../../../../../../shared/specific/DTOs/jobRole/JobRoleDTO";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { SubsidiaryWithBusinessRule } from "../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { YearMonth } from "../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  reloadTablePage: () => void;
  jobRole: JobRoleDTO | null;
}

export function useCreationForm({ reloadTablePage, jobRole }: OwnProps) {
  const { t } = useTranslation();
  const formikConfig = useCreationEditingDefaultFormikConfig({
    jobRole: jobRole ?? undefined,
  });

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("jobRates.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => (
          <JobRateDataForm {...props} mode="creation" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            await createJobRate(
              convertTo<JobRateCreateDTO>({
                idJobRole: formValues.jobRole?.id ?? 0,
                idSubsidiary: formValues.subsidiary?.id ?? 0,
                startYearMonth: YearMonth.createFromDate(
                  formValues.startYearMonth ?? new Date()
                ),
                monthlyRate: parseLocaleNumber(formValues.monthlyRate),
                monthlyTax:
                  formValues.subsidiary?.name ===
                  SubsidiaryWithBusinessRule.RDXBR
                    ? parseLocaleNumber(formValues.monthlyTax)
                    : null,
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "jobRates.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
}
