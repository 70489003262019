import i18next from "i18next";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { ActivitiesSuperUserContext } from "../../shared/react/contexts/ActivitiesSuperUserContext";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext/useProjectContext";
import { checkIsDateSameProject } from "../../../../../shared/helpers/DTOs/project/general.helpers";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { useActionButtonDialog } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import { ActivityErrorDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityErrorDTO";
import { ActivityManyResponseDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityManyResponseDTO";
import { closeManyActivities } from "../../../../../../../../../services/activities/activities.service";
import { ActivityCloseDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityCloseDTO";
import { useActivityManyError } from "../useActivityManyErrorForm";

interface OwnProps {
  reloadTablePage: () => void;
  reloadActivitySummary: () => void;
  setShowSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useCloseManyForm = ({
  reloadTablePage,
  reloadActivitySummary,
  setShowSelected,
}: OwnProps) => {
  const { t } = useTranslation();
  const { reloadProject, project } = useProjectContext();
  const { setSelectedActivities, selectedActivities } = useContext(
    ActivitiesSuperUserContext
  );
  const [errorMessages, setErrorMessages] = useState<ActivityErrorDTO[]>([]);

  const {
    openModal: openErrorModal,
    contentModal: activityCloseManyErrorModal,
  } = useActivityManyError({
    errorMessages,
    modalTitle: t("activities.actions.general.close"),
    reloadTablePage,
  });

  const {
    ModalButtonContainer: CloseManyButtonContainer,
    contentModal: closeManyModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "primary",
      modalTitle: t("activities.actions.general.closeActivity"),
      internalLoadingPosition: "title",
      ModalContentMemo: () => {
        return (
          <div>
            {selectedActivities.map((x) => (
              <li key={x.id}>{x.description}</li>
            ))}
          </div>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <Button
          variant="contained"
          size="small"
          onClick={openModal}
          sx={{
            backgroundColor: "#3685CE",
            "&:hover": {
              backgroundColor: "#2e70b0",
            },
          }}
        >
          {t("activities.actions.general.closeActivity")}
        </Button>
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: props,
        setInternalModalData,
        setIsLoadingModal,
      }) => {
        setIsLoadingModal(true);
        setInternalModalData(props);
        setIsLoadingModal(false);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("activities.actions.general.close"),
      onConfirm: async ({ closeModal }) => {
        if (!selectedActivities || selectedActivities.length === 0) {
          throw new Error("activities data cannot be null on confirm.");
        }

        try {
          const closedActivities: ActivityCloseDTO[] = selectedActivities.map(
            (x) => ({
              idProject: project.id,
              id: x.id,
              version: x.version,
            })
          );

          const response: ActivityManyResponseDTO =
            await closeManyActivities(closedActivities);

          if (response.listError.length > 0) {
            setErrorMessages(response.listError);
            openErrorModal();
            return;
          }

          setShowSelected(false);
          reloadActivitySummary();

          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );

          selectedActivities.forEach((activity) => {
            if (
              project.isValid ||
              checkIsDateSameProject(
                project.finalScheduledEndDate,
                activity.scheduledDate
              )
            ) {
              reloadProject();
            }
          });
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);

          if (errorApiErrorDTO instanceof TranslatedError) {
            notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          } else if (errorApiErrorDTO instanceof Error) {
            notifyError(errorApiErrorDTO.message);
          }
        } finally {
          reloadTablePage();
          closeModal();
          setSelectedActivities([]);
        }
      },
    },
  });

  return {
    CloseManyButtonContainer,
    closeManyModal,
    activityCloseManyErrorModal,
  };
};
