import { formatNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledFixedColumn } from "./index.styles";

interface OwnProps {
  info: string | number;
}

export const FixedColumnExtensibleRow = ({ info }: OwnProps) => {
  const isNumber = typeof info === "number";
  const formattedValue = isNumber
    ? formatNumber(info, { fractionDigits: 2 })
    : null;
  return (
    <StyledFixedColumn>
      <span>{formattedValue ?? info}</span>
    </StyledFixedColumn>
  );
};
