import * as yup from "yup";
import { riseApi } from "./connection";
import {
  DashboardDTO,
  schemaDashboardDTO,
} from "../../../shared/specific/DTOs/dashboards/DashboardDTO";
import { DashboardCreateDTO } from "../../../shared/specific/DTOs/dashboards/DashboardCreateDTO";
import { DashboardUpdateDTO } from "../../../shared/specific/DTOs/dashboards/DashboardUpdateDTO";

export const getDashboard = async (id?: number): Promise<DashboardDTO[]> => {
  const { data: dashboard } = await riseApi.get("/Dashboards", {
    params: {
      idDashBoardGroup: id,
    },
  });

  return yup.array(schemaDashboardDTO).defined().validateSync(dashboard);
};

export const createDashboard = async (dashboard: DashboardCreateDTO) => {
  await riseApi.post("/Dashboards", dashboard);
};

export const updateDashboard = async (dashboard: DashboardUpdateDTO) => {
  await riseApi.put("/Dashboards", dashboard);
};

export const deleteDashboard = async (id: number, version: string) => {
  await riseApi.delete(`/Dashboards/${id}/${version}`);
};

export const getDashboardById = async (
  id: number
): Promise<DashboardDTO | null> => {
  const { data: dashboard } = await riseApi.get(`/Dashboards/${id}`);

  return schemaDashboardDTO.nullable().validateSync(dashboard);
};
