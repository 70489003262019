import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../../../../../services/applicationState/toast.service";
import { createExpenses } from "../../../../../../../../../../../services/expenses/expenses.service";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { parseLocaleNumber } from "../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { ExpenseCreateDTO } from "../../../../../../../../../../../shared/specific/DTOs/expenses/ExpenseCreateDTO";
import { ExpenseType } from "../../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonExpenseForm } from "../shared/react/CommonExpenseForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
  reloadMainTablePage: () => void;
  expenseType: ExpenseType;
}

interface CreateModalButtonPropsDTO {
  costCenterPep: CostCenterPepOnlyDTO;
  expenseType: ExpenseType;
  yearMonth: YearMonth | null;
}

export const useCreationForm = ({
  reloadTablePage,
  reloadMainTablePage,
  expenseType,
}: OwnProps) => {
  const formikConfig = useFormikConfig({ expenseType });

  const { ContentButton: CreationButton, contentModal: creationModal } =
    useFormikModalButton<
      FormValues,
      CreateModalButtonPropsDTO,
      CreateModalButtonPropsDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          const { t } = useTranslation();

          return <>{t("expenses.actions.data.createExpense")}</>;
        },
      },
      button: {
        FormButton: AddFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps,
          setInternalFormData,
          setIsLoadingModal,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(contentButtonContentProps);
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => {
          if (internalFormData) {
            return (
              <CommonExpenseForm
                {...rest}
                costCenterPep={internalFormData?.costCenterPep}
                expenseType={internalFormData?.expenseType}
              />
            );
          }

          return <div> </div>;
        },
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const expenseType =
              internalFormData?.expenseType === ExpenseType.Workforce
                ? ExpenseType.Additional
                : internalFormData?.expenseType;

            const expenseCreationDTO: ExpenseCreateDTO = {
              amount: parseLocaleNumber(formValues.amount),
              description:
                internalFormData?.expenseType &&
                [ExpenseType.Additional, ExpenseType.Workforce].includes(
                  internalFormData?.expenseType
                )
                  ? formValues.additionalDescription.label
                  : formValues.description,
              expenseType: expenseType || 0,
              idCostCenterPep: internalFormData?.costCenterPep.id || 0,
              yearMonth:
                internalFormData?.yearMonth || YearMonth.createFromNow(),
              observation: formValues?.observation || undefined,
            };

            await createExpenses(expenseCreationDTO);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            reloadMainTablePage();
            closeModal();
          } catch (error) {
            console.error(error);

            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "expenses.errors.data.write.cannotWriteCurrentStage",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { CreationButton, creationModal };
};
