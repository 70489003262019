import { YearMonth } from "../../../../classes/data/date/YearMonth";
import { useTableData } from "./hooks/useTableData";
import { StyledCalendarGrid } from "./shared/styles/grid.styles";
import {
  CalendarCellContent,
  CalendarCellContentProps,
} from "./shared/types/props.types";
import { StyledPaper } from "./index.styles";

export type { CalendarCellContent, CalendarCellContentProps };

interface OwnProps {
  yearMonth: YearMonth;
  CalendarCellContent: CalendarCellContent;
}

export const CalendarGrid = ({ yearMonth, CalendarCellContent }: OwnProps) => {
  const { headCells, cells } = useTableData({
    yearMonth,
    CalendarCellContent,
  });

  return (
    <StyledPaper>
      <StyledCalendarGrid>
        {headCells}
        {cells}
      </StyledCalendarGrid>
    </StyledPaper>
  );
};
