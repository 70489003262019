import { styled } from "@mui/material";

export const StyledAutocompleteFieldReviewInfoBubble = styled("div")`
  background-color: ${({ theme }) => theme.palette.error.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => `${theme.spacing(0.3)} ${theme.spacing(0.6)}`};
  border-radius: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
`;

export const StyledDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
`;
