import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useBelowToolbarFilters } from "./hooks/useBelowToolbarFilters";
import { useCreationForm } from "./hooks/useCreationForm";
import { useTableData } from "./hooks/useTableData";

export const AccessGroupsMainPage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    setPageTitle(t("accessGroups.keywords.general.accessGroups"));
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const {
    headCells,
    onTablePageChange,
    editingModal,
    deletionModal,
    ManageGroupAccessModal,
    userGroupModal,
  } = useTableData({
    filters: belowToolbarFilters,
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, belowToolbarFilters],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.AuthorizationControl,
                level: PermissionLevel.Create,
              }}
            >
              {CreationButtonContainer}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onTablePageChange,
          },
          defaultRowsPerPage: 10,
        }}
        orderDefaults={{
          defaultOrderBy: 1,
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
      {ManageGroupAccessModal}
      {userGroupModal}
    </>
  );
};
