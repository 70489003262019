import React from "react";
import ReactDOM from "react-dom/client";
import { CacheProvider } from "@emotion/react";
import { cache } from "@emotion/css";
import { Provider as ReduxProvider } from "react-redux";

import { store } from "./store";
import { App } from "./App";

import "./core";
import { CustomAuthentication } from "./core/microsoftAuthenticator";
import { CustomLocalizationProvider } from "./core/components/CustomLocalizationProvider";

const rootElement = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

// {
// CacheProvider is needed to suppress an unrelated warning
// from Emotion, as per this post:
// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
// }
root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <CustomLocalizationProvider>
        <CustomAuthentication>
          <ReduxProvider store={store}>
            <App />
          </ReduxProvider>
        </CustomAuthentication>
      </CustomLocalizationProvider>
    </CacheProvider>
  </React.StrictMode>
);
