import { Card, styled } from "@mui/material";

export const PageCardButtonContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};

  width: 240px;
  height: 240px;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows[6]};
  }

  .MuiSvgIcon-root {
    font-size: 80px;
  }

  .MuiTypography-root {
    font-size: 1.2rem;
  }

  p {
    text-align: center;
  }
`;

export const StyledPageCardButtonsList = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  flex-wrap: wrap;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    flex-direction: column;
    flex-wrap: nowrap;

    & > *,
    & .MuiButtonBase-root,
    & .MuiCard-root {
      width: 100%;
    }
  }
`;
