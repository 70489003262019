import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { TextField } from "@mui/material";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { DeleteIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { CustomerBalanceDTO } from "../../../../../../shared/specific/DTOs/customerBalance/CustomerBalanceDTO";
import {
  deleteCustomerBalance,
  getCustomerBalanceById,
} from "../../../../../../services/customerBalance/customerBalance.service";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: () => {
        return (
          <Trans
            i18nKey="general.actions.general.delete"
            values={{ name: "" }}
            components={{ italic: <em /> }}
          />
        );
      },
      ModalContentMemo: ({ internalModalData }) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <TextField
              value={internalModalData?.costCenter.code}
              label={t("costCenters.keywords.general.costCenter")}
            />
            <TextField
              value={internalModalData?.segmentGroup.description}
              label={t("invoice.keywords.fields.segment")}
            />
            <TextField
              value={internalModalData?.customerTrigram}
              label={t("costDistribution.keywords.fields.groupCode")}
            />
            <TextField
              value={internalModalData?.yearMonth.toPrettyString()}
              label={t("jobRates.keywords.general.startYearMonth")}
            />
            <TextField
              value={
                internalModalData?.amount
                  ? formatNumber(internalModalData?.amount, {
                      fractionDigits: 2,
                      prefix:
                        internalModalData?.costCenter.subsidiary.currencySymbol,
                    })
                  : ""
              }
              label={t("customerBalance.keywords.fields.amount")}
            />
          </div>
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData: null as CustomerBalanceDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: ud,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const customerBalanceData = await getCustomerBalanceById(ud.id);
          if (!customerBalanceData)
            return setModalError(
              i18next.t(
                "customerBalance.errors.data.general.customerBalanceDoesNotExist"
              )
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(customerBalanceData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "customerBalance.errors.data.fetch.failedToFetchCustomerBalance",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({ closeModal, internalModalData: ud }) => {
        if (!ud) throw new Error(" customer balance cannot be null.");

        try {
          await deleteCustomerBalance(ud.id, ud.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(t("customerBalance.errors.data.delete.failedToDelete"));
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
