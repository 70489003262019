import { YearMonth } from "../../../../../../../../shared/common/classes/data/date/YearMonth";

export const CalcStartCaroussel = (
  earliestYearMonth: YearMonth,
  latestYearMonth: YearMonth
) => {
  const yearMonthNow = YearMonth.createFromDate(new Date());

  const dateStart = new Date(earliestYearMonth.toDate());
  const dateNow = new Date(yearMonthNow.toDate());
  const yearmonthPeriod: YearMonth[] = [];
  const current = dateStart;

  while (current <= dateNow) {
    const currentYearMonth = YearMonth.createFromDate(current);
    yearmonthPeriod.push(currentYearMonth);
    current.setMonth(current.getMonth() + 1);
  }

  const position = yearmonthPeriod.findIndex((x) => {
    return x.year === yearMonthNow.year && x.month === yearMonthNow.month;
  });

  return position === -1 ? 0 : position;
};
