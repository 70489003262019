import * as yup from "yup";

export enum TabCode {
  TypeOfFee = "typeOfFee",
  Period = "period",
  Summary = "summary",
}

export const schemaTabCode = yup
  .mixed<TabCode>()
  .oneOf(Object.values(TabCode) as TabCode[]);
