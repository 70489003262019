import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { applicationReducer } from "./features/applicationSlice";
import { languageReducer } from "./features/languageSlice";
import { userReducer } from "./features/userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    application: applicationReducer,
    language: languageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["application/setPageTitle"],
        ignoredActionPaths: ["application.pageTitle"],
        ignoredPaths: ["application.pageTitle"],
      },
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
