import * as yup from "yup";
import { JobRoleDTO, schemaJobRoleDTO } from "../jobRole/JobRoleDTO";
import {
  schemaUserCollaboratorDTO,
  UserCollaboratorDTO,
} from "./UserCollaboratorDTO";

export interface UserCurrentJobRoleDTO {
  userColaborator: UserCollaboratorDTO;
  currentJobRole: JobRoleDTO | null;
}

export const schemaUserCurrentJobRoleDTO = yup.object({
  userColaborator: schemaUserCollaboratorDTO.defined(),
  currentJobRole: schemaJobRoleDTO.nullable().defined(),
});
