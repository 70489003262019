import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useActionButtonDialog } from "../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { PipedriveDealDTO } from "../../../../../../../shared/specific/DTOs/pipedrive/PipedriveDealDTO";
import { ChangeDealTypeButton } from "./components/ChangeDealTypeButton";
import { getPipedriveDealById } from "../../../../../../../services/projects/projects.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { UpdateDealType } from "../../../../../../../services/pipedriveDeal/pipedriveDeal.service";
import { TabCode } from "../../shared/types/TabCode.enums";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";

interface OwnProps {
  reloadTablePage: () => void;
  tab: TabCode;
}

export const useUpdateDealType = ({ reloadTablePage, tab }: OwnProps) => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: ChangeDealTypeButtonContainer,
    contentModal: changeDealTypeModal,
  } = useActionButtonDialog<PipedriveDealDTO, PipedriveDealDTO>({
    modal: {
      ModalTitleMemo: ({ internalModalData: deal }) => {
        return (
          <Trans
            i18nKey={
              tab === TabCode.ProjectTab
                ? "pipedrive.modal.data.changeTypeToAdditive.title"
                : "pipedrive.modal.data.changeTypeToProject.title"
            }
            values={{ name: deal?.idProject ?? "-" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <ChangeDealTypeButton
          onClick={openModal}
          tooltipText={
            tab === TabCode.ProjectTab
              ? "pipedrive.modal.data.changeTypeToAdditive.tooltip"
              : "pipedrive.modal.data.changeTypeToProject.tooltip"
          }
        />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: deal,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const dealData = await getPipedriveDealById(deal.id);

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          if (!dealData)
            return setModalError(
              i18next.t("pipedrive.errors.data.general.dealDoesNotExist")
            );

          setInternalModalData(dealData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "pipedrive.errors.data.general.dealDoesNotExist",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.confirm"),
      onConfirmMemo: async ({ closeModal, internalModalData: dealData }) => {
        if (!dealData) throw new Error("deal cannot be null on confirm.");

        try {
          await UpdateDealType({
            idDeal: dealData.id,
            isAdditive: tab === TabCode.ProjectTab,
          });
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="frames.errors.data.delete.failedToDeleteFrame"
              values={{ name: dealData.idProject ?? "-" }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { ChangeDealTypeButtonContainer, changeDealTypeModal };
};
