import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { BudgetRoundFormValues } from "../../types/BudgetRoundFormValues";

export const useCreationEditingFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: BudgetRoundFormValues = useMemo(() => {
    return {
      beginDate: null,
      endDate: null,
      name: "",
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      beginDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.keywords.fields.required")),
      endDate: yup
        .date()
        .nullable()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .required(t("general.keywords.fields.required")),
      name: yup.string().required(t("general.keywords.fields.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
