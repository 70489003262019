import { Button, CircularProgress } from "@mui/material";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import {
  DivSuccess,
  PerfectlyCenteredText,
  ResponseListContainer,
  StyledTextButton,
} from "./components";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { uploadCollaboratorsHistory } from "../../../../../../../services/collaboratorHistory/collaboratorHistory.service";
import { CollaboratorHistoryUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/collaboratorHistory/CollaboratorHistoryUploadResponseDTO";

export const useUploadCollaboratorHistoryForm = () => {
  const { t } = useTranslation();
  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);
  const loadCollaboratorsHistory = () => fileUploadInputRef.current?.click();
  const [filename, setFilename] = useState<string | null>(null);
  const [responseUpload, setResponseUpload] =
    useState<CollaboratorHistoryUploadResponseDTO | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;
    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      const result = await uploadCollaboratorsHistory(file);
      setResponseUpload(result);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const requestReturnInfo = useMemo(() => {
    if (responseUpload === null) return null;

    if (
      responseUpload !== null &&
      responseUpload.totalError === 0 &&
      responseUpload.listError.length === 0
    ) {
      return (
        <DivSuccess>
          <>{t("uploadCollaboratorHistory.keywords.successMessage")}</>
        </DivSuccess>
      );
    }

    return (
      <ResponseListContainer>
        <div>
          <p>
            {t("uploadCollaboratorHistory.modal.data.totalSuccess").replace(
              "{0}",
              responseUpload.totalSucces.toString()
            )}
          </p>
          <p>
            {t("uploadCollaboratorHistory.modal.data.totalError").replace(
              "{0}",
              responseUpload.totalError.toString()
            )}
          </p>
        </div>
        <div>
          {responseUpload.listError.map((error, index) => (
            <div key={index}>
              <Trans
                i18nKey={
                  error.login === "" && error.registryNumber === ""
                    ? "uploadCollaboratorHistory.modal.data.responseRow"
                    : "uploadCollaboratorHistory.modal.data.responseRowCollaborator"
                }
                values={{
                  name: error.login ?? "",
                  registryNumber: error.registryNumber ?? "",
                  errorMenssage: t(error.errorMenssage) ?? "",
                }}
                components={{ b: <b /> }}
              />
            </div>
          ))}
        </div>
      </ResponseListContainer>
    );
  }, [hasSubmitted, responseUpload, t, filename]);

  const uploadButtonRef = useRef<HTMLButtonElement | null>(null);

  const {
    ModalButtonContainer: UploadButton,
    contentModal: uploadModalCollaboratorHistory,
  } = useModalButton({
    modal: {
      suppressInternalCloseModal: isSubmitting,
      suppressCloseBackdropClick: isSubmitting,
      letModalContentDealWithLoading: true,
      keepModalMounted: 1000,
      modalTitle: (
        <Trans
          i18nKey="uploadCollaboratorHistory.keywords.processingFileName"
          values={{ filename: filename ?? "" }}
          components={{ italic: <em /> }}
        />
      ),
      modalContent: isSubmitting ? (
        <PerfectlyCenteredText>
          <CircularProgress />
        </PerfectlyCenteredText>
      ) : (
        requestReturnInfo
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return (
          <button
            type="button"
            onClick={onClick}
            hidden
            ref={uploadButtonRef}
          />
        );
      },
    },
    general: {
      initialInternalModalData: null as unknown,
    },
  });

  const uploadButtonLoadCollaboratorHistory = useMemo(() => {
    return (
      <>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          hidden
          ref={fileUploadInputRef}
          onChange={onPickFile}
        />
        <UploadButton />
        <StyledTextButton onClick={loadCollaboratorsHistory}>
          {t("uploadCollaboratorHistory.keywords.uploadButton")}
        </StyledTextButton>
      </>
    );
  }, [t]);
  return {
    uploadModalCollaboratorHistory,
    uploadButtonLoadCollaboratorHistory,
  };
};
