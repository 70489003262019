import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesProjectsOverview } from "../types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const initialValues: FormValuesProjectsOverview = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
      project: null,
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      division: yup
        .object()
        .nullable()
        .test({
          name: "teste",
          message: t("reports.errors.validations.mustHaveUnOrProject"),
          test(value) {
            return !!value || !!this.parent.project;
          },
        }),
      project: yup.object().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);
};
