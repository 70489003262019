import * as yup from "yup";
import { CfopDTO, schemaCfopDTO } from "../cfop/CfopDTO";
import {
  CostCenterDTO,
  schemaCostCenterDTO,
} from "../costCenter/CostCenterDTO";
import { ProjectDTO, schemaProjectDTO } from "../project/ProjectDTO";
import {
  schemaSubsidiaryDTO,
  SubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import { schemaTaxDTO, TaxDTO } from "../Tax/TaxDTO";

export interface CostCenterPepDTO {
  id: number;
  pepCode: string;
  pepDescription: string;
  isExempted: boolean;
  tax: number | null;
  taxes: TaxDTO[];
  taxWithExemption: number | null;
  currency: string;
  currencySymbol: string;
  project: ProjectDTO;
  subsidiary: SubsidiaryDTO;
  costCenter: CostCenterDTO;
  cfop: CfopDTO | null;
  version: string;
}

export const schemaCostCenterPepDTO = yup.object({
  id: yup.number().integer().defined(),
  pepCode: yup.string().defined(),
  pepDescription: yup.string().defined(),
  isExempted: yup.boolean().defined(),
  tax: yup.number().nullable().defined(),
  taxes: yup.array(schemaTaxDTO).defined(),
  taxWithExemption: yup.number().nullable().defined(),
  currency: yup.string().defined(),
  currencySymbol: yup.string().defined(),
  project: schemaProjectDTO,
  subsidiary: schemaSubsidiaryDTO,
  costCenter: schemaCostCenterDTO,
  cfop: schemaCfopDTO.nullable(),
  version: yup.string().defined(),
});
