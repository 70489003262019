import * as yup from "yup";
import { LabelColorType } from "../../../../Router/pages/authenticated/AllocationsMap/AllocationsMapMainPage/shared/types/form.types";

export interface AllocationWeekDTO {
  weekDate: Date;
  weekNumber: number;
  weekPercentage: number | null;
  labelColorType: LabelColorType;
}

export const schemaAllocationWeekDTO = yup.object({
  weekDate: yup.date().defined(),
  weekNumber: yup.number().defined(),
  weekPercentage: yup.number().nullable().defined(),
  labelColorType: yup.number().integer().defined(),
});
