import { useMemo, useState } from "react";
import { ActivityDTO } from "../../../../../../../shared/specific/DTOs/activity/ActivityDTO";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { useSuperUserButton } from "../../../../../../../shared/specific/react/hooksWithComponents/authentication/useSuperUserButton";
import { ActivitiesTabWithinContext } from "./ActivitiesTabWithinContext";
import {
  ActivitiesSuperUserContext,
  ActivitiesSuperUserContextValues,
} from "./shared/react/contexts/ActivitiesSuperUserContext";

export const ActivitiesTab = () => {
  const {
    superUserButton: canEditAllActivitiesButton,
    inSuperUserMode: canEditAllActivities,
  } = useSuperUserButton({
    restrictions: PermissionType.CanEditAllActivities,
    tooltipNotInSuperUserModeOff:
      "activities.actions.authorization.editActivitiesWithoutRestrictions",
    tooltipInSuperUserMode:
      "activities.actions.authorization.stopEditActivitiesWithoutRestrictions",
  });

  const [selectedActivities, setSelectedActivities] = useState<ActivityDTO[]>(
    []
  );

  const activitiesSuperUserProvider: ActivitiesSuperUserContextValues =
    useMemo(() => {
      return {
        canEditAllActivities,
        canEditAllActivitiesButton,
        selectedActivities,
        setSelectedActivities,
      };
    }, [canEditAllActivities, canEditAllActivitiesButton, selectedActivities]);

  return (
    <ActivitiesSuperUserContext.Provider value={activitiesSuperUserProvider}>
      <ActivitiesTabWithinContext />
    </ActivitiesSuperUserContext.Provider>
  );
};
