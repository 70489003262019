import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CustomAutocomplete,
  CustomAutocompleteProps,
} from "../../../../../../../../shared/common/react/components/form/general/autocomplete/general/CustomAutocomplete";
import { AutocompleteOption } from "../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { RestrictionsOperand } from "../../../../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { RootPermissionsOperator } from "../../../../../../../../shared/specific/classes/user/permissions/permissionsOperators/RootPermissionsOperator";
import { PermissionType } from "../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { RootState } from "../../../../../../../../store";
import { ReportOption } from "../../../shared/types/enums/general.enum";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    CustomAutocompleteProps<
      AutocompleteOption<ReportOption>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const ReportTypesAutocomplete = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();
  const userData = useSelector((state: RootState) => state.user.userData);

  const verifyPermission = (restrictions: RestrictionsOperand) => {
    if (!userData) return false;

    const permissionsOperator = new RootPermissionsOperator(restrictions);

    return permissionsOperator.checkPermissions({
      userPermissions: userData.permissions,
      isAdmin: userData.isAdmin,
    });
  };

  const options = useMemo(() => {
    const options = [
      {
        id: ReportOption.PerformedTimesheet,
        label: verifyPermission(
          PermissionType.CanSubmitPerformedTimesheetReport
        )
          ? t("reports.pages.fields.performedTimesheet")
          : "",
      },
      {
        id: ReportOption.GlobalAllocation,
        label: verifyPermission(PermissionType.CanSubmitGlobalAllocationReport)
          ? t("reports.pages.fields.globalAllocation")
          : "",
      },
      {
        id: ReportOption.ProjectTimesheet,
        label: verifyPermission(PermissionType.CanSubmitProjectTimesheetReport)
          ? t("reports.pages.fields.projectTimesheet")
          : "",
      },
      {
        id: ReportOption.RevenueAndBilling,
        label: verifyPermission(PermissionType.CanSubmitRevenueAndBillingReport)
          ? t("reports.pages.fields.revenueAndBilling")
          : "",
      },
      {
        id: ReportOption.EapReport,
        label: verifyPermission(PermissionType.CanSubmitEAPReport)
          ? t("reports.pages.fields.eapReport")
          : "",
      },
      {
        id: ReportOption.ProjectsOverview,
        label: verifyPermission(PermissionType.CanSubmitProjectOverviewReport)
          ? t("reports.pages.fields.projectsOverview")
          : "",
      },
      {
        id: ReportOption.DatailedProjectsOverview,
        label: verifyPermission(
          PermissionType.CanSubmitProjectDetailedViewReport
        )
          ? t("reports.pages.fields.datailedProjectsOverview")
          : "",
      },
      {
        id: ReportOption.StatusOfProjects,
        label: verifyPermission(PermissionType.CanSubmitProjectStatusReport)
          ? t("reports.pages.fields.statusOfProjects")
          : "",
      },
      {
        id: ReportOption.CreationProjectsAdditives,
        label: verifyPermission(
          PermissionType.CanSubmitProjectsAndAdditivesCreationReport
        )
          ? t("reports.pages.fields.creationProjectsAdditives")
          : "",
      },
      {
        id: ReportOption.ProjectMVs,
        label: verifyPermission(PermissionType.CanSubmitProjectMVsReport)
          ? t("reports.pages.fields.projectMVs")
          : "",
      },
      {
        id: ReportOption.WeeklyAllocation,
        label: verifyPermission(PermissionType.CanSubmitWeeklyAllocationReport)
          ? t("reports.pages.fields.weeklyAllocation")
          : "",
      },
      {
        id: ReportOption.ProvisionReport,
        label: verifyPermission(PermissionType.CanSubmitProvisionReport)
          ? t("reports.pages.fields.provisionReport")
          : "",
      },
      {
        id: ReportOption.BaseCollaborators,
        label: verifyPermission(PermissionType.CanSubmitBaseCollaboratorReport)
          ? t("reports.pages.fields.baseCollaborators")
          : "",
      },
      {
        id: ReportOption.PepElement,
        label: verifyPermission(PermissionType.CanSubmitPepElementReport)
          ? t("reports.pages.fields.pepElement")
          : "",
      },
      {
        id: ReportOption.BudgetOverview,
        label: verifyPermission(PermissionType.CanSubmitBudgetOverviewReport)
          ? t("reports.pages.fields.budgetOverview")
          : "",
      },
      {
        id: ReportOption.PlannedAndPerformedByPepReport,
        label: verifyPermission(
          PermissionType.CanSubmitPlannedAndPerformedByPepReport
        )
          ? t("reports.pages.fields.plannedAndPerformedByPepReport")
          : "",
      },
      {
        id: ReportOption.BillingVision,
        label: verifyPermission(PermissionType.CanSubmitBillingVisionReport)
          ? t("reports.pages.fields.billingVision")
          : "",
      },
      {
        id: ReportOption.CostMdo,
        label: verifyPermission(PermissionType.CanSubmitCostMdoReport)
          ? t("reports.pages.fields.workforceCost")
          : "",
      },
      {
        id: ReportOption.Zah,
        label: verifyPermission(PermissionType.CanSubmitZahReport)
          ? t("reports.pages.fields.zah")
          : "",
      },
      {
        id: ReportOption.TurnoverDays,
        label: verifyPermission(PermissionType.CanSubmitTurnoverDaysReport)
          ? t("reports.pages.fields.turnonverDays")
          : "",
      },
      {
        id: ReportOption.MvWithFinancialCost,
        label: verifyPermission(
          PermissionType.CanSubmitMvWithFinancialCostReport
        )
          ? t("reports.pages.fields.mvWithFinancialCost")
          : "",
      },
    ].sort((a, b) => a.label.localeCompare(b.label));

    const filteredOptions = options.filter((opt) => opt.label !== "");

    return filteredOptions;
  }, [t]);

  return (
    <CustomAutocomplete
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={{
        ...textfieldProps,
        label: t("reports.pages.form.reportType"),
      }}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
