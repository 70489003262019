import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../../../services/applicationState/pageData.service";
import { CenteredContent } from "../../../../../../../shared/common/react/components/ui/CenteredContent";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PlanningFooter } from "../../layout/PlanningFooter";
import { PlanningHeader } from "../../layout/PlanningHeader";
import { PlanningTabs } from "../../layout/PlanningTabs";
import { useProjectContext } from "../../shared/react/contexts/ProjectContext";
import {
  MobileProjectStatusFlag,
  StyledMainContent,
  StyledPageTitle,
  StyledProjectPlanningPage,
  StyledProjectScrollable,
  TitleProjectStatusFlag,
} from "./index.styles";

export const ProjectPlanningWithinContext = () => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  useEffect(() => {
    setPageTitle({
      title: project.name,
      Component: () => (
        <StyledPageTitle>
          <TitleProjectStatusFlag projectStatus={project.status} />
          <div>{project.name ?? ""}</div>
        </StyledPageTitle>
      ),
    });
  }, [project.name, project.status]);

  if (project.status === ProjectStatus.Created)
    return (
      <CenteredContent>
        {t(
          "projects.errors.navigation.planning.cannotAccessPlanningBoardBeforeStartPlanning"
        )}
      </CenteredContent>
    );

  return (
    <StyledProjectPlanningPage>
      <MobileProjectStatusFlag projectStatus={project.status} stretched />
      <StyledMainContent>
        <StyledProjectScrollable>
          <PlanningHeader />
          <PlanningTabs />
        </StyledProjectScrollable>
        <PlanningFooter />
      </StyledMainContent>
    </StyledProjectPlanningPage>
  );
};
