import { AutocompleteValue, TextFieldProps } from "@mui/material";
import {
  DependencyList,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useIsMountedRef } from "../../../management/useIsMountedRef";

interface OwnProps<
  OptionType,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  fetchOptions?: () => OptionType[] | Promise<OptionType[]>;
  fetchOptionsMemo?: () => OptionType[] | Promise<OptionType[]>;
  rerunOnDeps?: DependencyList;
  customErrorMessage?: string;
  label?: ReactNode;
  readOnly?: boolean;
  freeSolo?: FreeSolo;
  InputLabelProps?: TextFieldProps["InputLabelProps"];
  color: TextFieldProps["color"];
  focused: TextFieldProps["focused"];
  value?: AutocompleteValue<OptionType, Multiple, DisableClearable, FreeSolo>;
}

export const useAsyncAutocompleteConfig = <
  OptionType,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  fetchOptions,
  fetchOptionsMemo,
  rerunOnDeps = [],
  customErrorMessage,
  label: externalLabel,
  readOnly: externalReadOnly,
  freeSolo: externalFreeSolo,
  InputLabelProps: externalInputLabelProps,
  color: externalColor,
  focused: externalFocused,
  value,
}: OwnProps<OptionType, Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<readonly OptionType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const isMountedRef = useIsMountedRef();

  const fetchOptionsFinal = useMemo(() => {
    return fetchOptions ?? fetchOptionsMemo;
  }, [fetchOptions]);

  const fetchSetOptions = useCallback(async () => {
    if (!fetchOptionsFinal) return;

    setIsLoading(true);
    setError(false);

    try {
      const options = await Promise.resolve(fetchOptionsFinal());
      if (!isMountedRef.current) return;

      setOptions(options);
    } catch (error) {
      if (!isMountedRef.current) return;

      console.error(error);
      setError(true);
    }

    setIsLoading(false);
  }, [fetchOptionsFinal]);

  useEffect(() => {
    isMountedRef.current = true;
    fetchSetOptions();

    return () => {
      isMountedRef.current = false;
    };
  }, [fetchSetOptions, ...rerunOnDeps]);

  const label = useMemo(() => {
    if (error) {
      if (customErrorMessage) return customErrorMessage;

      return t("general.errors.data.fetch.failedToFetchOptions");
    }

    if (isLoading) return t("general.keywords.general.loadingEtc");

    return externalLabel;
  }, [t, isLoading, customErrorMessage, externalLabel]);

  const readOnly = useMemo(() => {
    return error || Boolean(externalReadOnly);
  }, [error, externalReadOnly]);

  const freeSolo = useMemo(() => {
    if (readOnly) return true as FreeSolo;

    if (externalFreeSolo === undefined) return undefined;

    return externalFreeSolo as FreeSolo;
  }, [readOnly, externalFreeSolo]);

  const InputLabelProps = useMemo(() => {
    if (!readOnly) return externalInputLabelProps;

    return {
      ...externalInputLabelProps,
      shrink: !!value,
    };
  }, [externalInputLabelProps, value, readOnly]);

  const textFieldColor = useMemo(() => {
    if (error) return "error";
    return externalColor;
  }, [error, externalColor]);

  const textFieldFocused = useMemo(() => {
    if (error) return true;
    return externalFocused;
  }, [error, externalFocused]);

  return {
    options,
    isLoading,
    label,
    readOnly,
    freeSolo,
    InputLabelProps,
    textFieldColor,
    textFieldFocused,
    rerunFetch: fetchSetOptions,
  };
};
