import { DeleteIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { EditIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { TimesheetMonthDTO } from "../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { useCanModifyMonth } from "../../shared/react/hooks/general/validation/useCanModifyMonth";

interface OwnProps {
  DeletionButtonContainer: (props: TimesheetMonthDTO) => JSX.Element | null;
  EditingButton: (props: TimesheetMonthDTO) => JSX.Element | null;
  ViewingButton: (props: TimesheetMonthDTO) => JSX.Element | null;
  timesheetMonth: TimesheetMonthDTO;
}

export const RowButtons = ({
  DeletionButtonContainer,
  EditingButton,
  ViewingButton,
  timesheetMonth,
}: OwnProps) => {
  const { canModifyYearMonth } = useCanModifyMonth({
    yearMonth: timesheetMonth.yearMonth,
  });

  return (
    <>
      <ViewingButton {...timesheetMonth} />
      {canModifyYearMonth ? (
        <EditingButton {...timesheetMonth} />
      ) : (
        <EditIconButton disabled />
      )}
      {canModifyYearMonth ? (
        <DeletionButtonContainer {...timesheetMonth} />
      ) : (
        <DeleteIconButton disabled />
      )}
    </>
  );
};
