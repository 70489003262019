import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useCreationEditingDefaultFormikConfig } from "../shared/react/hooks/data/validation/useCreationEditingDefaultFormikConfig";
import { useFormikModalButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../shared/types/form.types";
import { WorkloadDTO } from "../../../../../shared/specific/DTOs/workload/WorkloadDTO";
import { EditFormButton } from "../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getWorkloadId,
  updateWorkload,
} from "../../../../../services/workloads/workloads.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { WorkloadsDataForm } from "../shared/components/WorkloadsDataForm";
import { WorkloadUpdateDTO } from "../../../../../shared/specific/DTOs/workload/WorkloadUpdateDTO";
import {
  notifyError,
  notifySuccess,
} from "../../../../../services/applicationState/toast.service";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const { initialValues, validationSchema } =
    useCreationEditingDefaultFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, WorkloadDTO, WorkloadDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: workload }) => (
          <Trans
            i18nKey="general.modal.data.edit.titleEditName"
            values={{ name: workload?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: workload,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const workloadData = await getWorkloadId(workload.id);
            if (!workloadData)
              return setModalError(
                i18next.t("workloads.errors.data.general.workloadNotExist")
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(workloadData);
            setFormValues({
              name: workloadData.name,
              deactivatedDate: workloadData.deactivatedDate,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "workloads.errors.data.fetch.failedToFetchWorkloads",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig: {
          initialValues,
          validationSchema,
        },
        FormContentMemo: ({ ...rest }) => (
          <WorkloadsDataForm mode="editing" {...rest} />
        ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: workload,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!workload)
            throw new Error("workload cannot be null on submission.");

          if (!formValues.name) throw new Error("Required values are missing.");

          try {
            const updatedWorkload: WorkloadUpdateDTO = {
              id: workload.id,
              name: formValues.name,
              deactivatedDate: formValues.deactivatedDate,
              version: workload.version,
            };

            await updateWorkload(updatedWorkload);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (workload?.name)
              return notifyError(
                <Trans
                  i18nKey="workloads.errors.data.edit.failedToEditName"
                  values={{ name: workload?.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(t("workloads.errors.data.edit.failedToEdit"));
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
