import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { useContext, useMemo } from "react";
import { UploadsAreaFilterValues } from "../../types/form.types";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import {
  StyledDolarField,
  StyledFilterFields,
  StyledFormContainer,
  StyledUploadsButtonsAndHelper,
  StyledUploadsButtonsContainer,
} from "./index.styles";
import { useEffectAfterRenders } from "../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useEditingDolarTaxForm } from "../../../hooks/useEditingDolarTaxForm";
import { BudgetContext } from "../../../../../context/budgetContextProvider";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { CenteredLoading } from "../../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { useUploadZorc } from "../../../hooks/useUploadButtons/useUploadZorc";
import { useDownloadSpreadsheetHelper } from "../../../hooks/useUploadButtons/useDownloadSpreadsheet/useDownloadSpreadsheetHelper";
import { useDownloadSpreadsheet } from "../../../hooks/useUploadButtons/useDownloadSpreadsheet/useDownloadSpreadsheet";
import { useUploadZorcHelper } from "../../../hooks/useUploadButtons/useUploadZorc/useUploadZorcHelper";
import { useUploadIntercompany } from "../../../hooks/useUploadButtons/useUploadIntercompany";
import { useUploadIntercompanyHelper } from "../../../hooks/useUploadButtons/useUploadIntercompany/useUploadIntercompanyHelper";
import { useUploadSpreadsheet } from "../../../hooks/useUploadButtons/useUploadSpreadsheet";
import { useUploadSpreadsheetHelper } from "../../../hooks/useUploadButtons/useUploadSpreadsheet/useUploadSpreadsheetHelper";
import { useUploadCalculatedCost } from "../../../hooks/useUploadButtons/useUploadCalculatedCost";
import { useUploadCalculatedCostHelper } from "../../../hooks/useUploadButtons/useUploadCalculatedCost/useUploadCalculatedCostHelper";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { generalFieldsConstants } from "../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { SegmentGroupsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentGroupsAutoCompleteFormik";

interface OwnProps {
  formikContenProps: FormikProps<UploadsAreaFilterValues>;
  reloadTablePage: () => void;
}

export const UploadFilterForm = ({
  formikContenProps: { setFieldValue, values },
  reloadTablePage,
}: OwnProps) => {
  const { t } = useTranslation();
  const { budget } = useContext(BudgetContext);

  const isShowSegment = useMemo(() => {
    values.segmentGroup = null;
    return (
      values.un?.name &&
      [
        "UN Matriz",
        "UN Energia",
        "Energy BU",
        "Head Office BU",
        "UN P&D",
        "P&D BU",
      ].includes(values.un?.name)
    );
  }, [values.un]);

  const isDisabledUploadZorcButton = useMemo(() => {
    if (
      !values.subsidiary ||
      !values.un ||
      (isShowSegment && !values.segmentGroup)
    )
      return true;
    return false;
  }, [isShowSegment, values.segmentGroup, values.subsidiary]);

  const isDisabledUploadIntercompanyButton = useMemo(() => {
    if (
      values.subsidiary &&
      values.un &&
      [
        "UN Infraestrutura & Serviços",
        "UN Manufatura",
        "UN Energia",
        "Infrastructure & Services BU",
        "Energy BU",
        "Manufacturing BU",
        "UN P&D",
        "P&D BU",
      ].includes(values.un?.name)
    )
      return false;
    return true;
  }, [values.un, values.subsidiary]);

  const { uploadModalZorc, uploadButtonLoadZorc } = useUploadZorc({
    idDivision: values.un?.id.toString() || null,
    idSegmentGroup: values.segmentGroup?.id.toString() || null,
    idSubsidiary: values.subsidiary?.id.toString() || null,
    isDisabledButton: isDisabledUploadZorcButton,
  });

  const { uploadModalIntercompany, uploadButtonLoadIntercompany } =
    useUploadIntercompany({
      idDivision: values.un?.id.toString() || null,
      idSegmentGroup: values.segmentGroup?.id.toString() || null,
      idSubsidiary: values.subsidiary?.id.toString() || null,
      isDisabledButton: isDisabledUploadIntercompanyButton,
    });

  const { uploadModalCalculatedCost, uploadButtonLoadCalculatedCost } =
    useUploadCalculatedCost({
      idDivision: values.un?.id.toString() || null,
      idSegmentGroup: values.segmentGroup?.id.toString() || null,
      idSubsidiary: values.subsidiary?.id.toString() || null,
      isDisabledButton: isDisabledUploadIntercompanyButton,
    });

  const { uploadModalBudget, uploadButtonLoadBudget } = useUploadSpreadsheet({
    idDivision: values.un?.id.toString() || null,
    idSegmentGroup: values.segmentGroup?.id.toString() || null,
    idSubsidiary: values.subsidiary?.id.toString() || null,
    isDisabledButton: isDisabledUploadZorcButton,
  });

  const { zorcModalHelper, ZorcHelperModalButtonContainer } =
    useUploadZorcHelper();

  const downloadSpreadsheetButton = useDownloadSpreadsheet({
    idDivision: values.un?.id.toString() || null,
    idSegmentGroup: values.segmentGroup?.id.toString() || null,
    idSubsidiary: values.subsidiary?.id.toString() || null,
    isDisabledButton: isDisabledUploadZorcButton,
  });

  const {
    downloadSpreadsheetModalHelper,
    DownloadSpreadsheetHelperModalButtonContainer,
  } = useDownloadSpreadsheetHelper();

  const { IntercompanyHelperModalButtonContainer, intercompanyModalHelper } =
    useUploadIntercompanyHelper();

  const {
    CalculatedCostHelperModalButtonContainer,
    CalculatedCostModalHelper,
  } = useUploadCalculatedCostHelper();

  const { UploadBudgetHelperModalButtonContainer, uploadBudgetModalHelper } =
    useUploadSpreadsheetHelper();

  const { EditingButtonContainer, editingModal } = useEditingDolarTaxForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue(
        "dolarTax",
        budget?.dolarTax
          ? formatNumber(budget?.dolarTax, {
              fractionDigits: 4,
            })
          : null
      );
    },
    deps: [budget],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("segment", null);
    },
    deps: [values.un],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledFormContainer>
      {budget ? (
        <StyledDolarField>
          <LocalizedNumberTextFieldFormik
            name="dolarTax"
            scale={4}
            min={0}
            max={generalFieldsConstants.maxAmountValue}
            overwrite="shift"
            padFractionalZeros
            textFieldProps={{
              label: t("budget.keywords.fields.dolarTax"),
              InputProps: {
                startAdornment: "$",
                readOnly: true,
              },
            }}
          />
          <Protected
            restrictions={{
              type: PermissionType.Budget,
              level: PermissionLevel.Create,
            }}
          >
            <EditingButtonContainer {...budget} />
          </Protected>
        </StyledDolarField>
      ) : (
        <CenteredLoading size={15} stretchMode="horizontal" />
      )}
      <Protected
        restrictions={{
          type: PermissionType.Budget,
          level: PermissionLevel.Update,
        }}
      >
        <StyledFilterFields>
          <SubsidiariesAutocompleteFormik name="subsidiary" />
          <DivisionsAutocompleteFormik name="un" />
          {isShowSegment && (
            <SegmentGroupsAutocompleteFormik
              name="segmentGroup"
              filters={{
                idDivision: values.un?.id,
              }}
            />
          )}
        </StyledFilterFields>
        <StyledUploadsButtonsContainer>
          <StyledUploadsButtonsAndHelper>
            {downloadSpreadsheetButton}
            <DownloadSpreadsheetHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
          <StyledUploadsButtonsAndHelper>
            {uploadButtonLoadZorc}
            <ZorcHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
          <StyledUploadsButtonsAndHelper>
            {uploadButtonLoadIntercompany}
            <IntercompanyHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
          <StyledUploadsButtonsAndHelper>
            {uploadButtonLoadCalculatedCost}
            <CalculatedCostHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
          <StyledUploadsButtonsAndHelper>
            {uploadButtonLoadBudget}
            <UploadBudgetHelperModalButtonContainer />
          </StyledUploadsButtonsAndHelper>
        </StyledUploadsButtonsContainer>
      </Protected>
      {uploadModalZorc}
      {editingModal}
      {zorcModalHelper}
      {intercompanyModalHelper}
      {uploadModalIntercompany}
      {CalculatedCostModalHelper}
      {uploadModalCalculatedCost}
      {downloadSpreadsheetModalHelper}
      {uploadBudgetModalHelper}
      {uploadModalBudget}
    </StyledFormContainer>
  );
};
