import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useMemo, useRef } from "react";
import { FilterValues } from "./shared/types/filter.types";
import {
  UseFormikModalButtonRef,
  useFormikModalButton,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";
import { WaccFilterDTO } from "../../../../../../../shared/specific/DTOs/wacc/WaccFilterDTO";
import { WaccFilterForm } from "./WaccFilterForm";

export const useFilterForm = () => {
  const { t } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      startYearMonth: null,
      endYearMonth: null,
    };

    const validationSchema = yup.object({
      startYearMonth: yup.date().nullable(),
      endYearMonth: yup.date().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
      modalMode: "dialog",
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<WaccFilterDTO>({
          startYearMonth: formValues.startYearMonth
            ? YearMonth.createFromDate(formValues.startYearMonth)
            : undefined,
          endYearMonth: formValues.endYearMonth
            ? YearMonth.createFromDate(formValues.endYearMonth)
            : undefined,
        }),
      FormContentMemo: WaccFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
