import { Button, CircularProgress } from "@mui/material";
import { useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import {
  DivSuccess,
  PerfectlyCenteredText,
  ResponseListContainer,
} from "./components";
import { CorporateAllocationUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationUploadResponseDTO";

interface OwnProps {
  isSubmitting: boolean;
  filename: string | null;
  hasSubmitted: boolean;
  responseUpload: CorporateAllocationUploadResponseDTO | null;
}

export const useUploadForm = ({
  isSubmitting,
  filename,
  hasSubmitted,
  responseUpload,
}: OwnProps) => {
  const { t } = useTranslation();

  const requestReturnInfo = useMemo(() => {
    if (responseUpload === null) return null;

    if (
      responseUpload !== null &&
      responseUpload.totalError === 0 &&
      responseUpload.listError.length === 0
    ) {
      return (
        <DivSuccess>
          <span>{t("uploadCorporateAllocation.keywords.successMessage")}</span>
        </DivSuccess>
      );
    }

    return (
      <ResponseListContainer>
        <div>
          <p>
            {t("uploadCorporateAllocation.modal.data.totalSuccess").replace(
              "{0}",
              responseUpload.totalSucces.toString()
            )}
          </p>
          <p>
            {t("uploadCorporateAllocation.modal.data.totalError").replace(
              "{0}",
              responseUpload.totalError.toString()
            )}
          </p>
        </div>
        <div>
          {responseUpload.listError.map((error, index) => (
            <div key={index}>
              <Trans
                i18nKey={
                  error.name === ""
                    ? "uploadCorporateAllocation.modal.data.responseRow"
                    : "uploadCorporateAllocation.modal.data.responseRowCorporateAllocation"
                }
                values={{
                  name: error.name ?? "",
                  errorMenssage: t(error.errorMenssage) ?? "",
                }}
                components={{ b: <b /> }}
              />
            </div>
          ))}
        </div>
      </ResponseListContainer>
    );
  }, [hasSubmitted, responseUpload, t, filename]);

  const uploadButtonRef = useRef<HTMLButtonElement | null>(null);

  const { ModalButtonContainer: UploadButton, contentModal: uploadModal } =
    useModalButton({
      modal: {
        suppressInternalCloseModal: isSubmitting,
        suppressCloseBackdropClick: isSubmitting,
        letModalContentDealWithLoading: true,
        keepModalMounted: 1000,
        modalTitle: (
          <Trans
            i18nKey="uploadCorporateAllocation.keywords.processingFileName"
            values={{ filename: filename ?? "" }}
            components={{ italic: <em /> }}
          />
        ),
        modalContent: isSubmitting ? (
          <PerfectlyCenteredText>
            <CircularProgress />
          </PerfectlyCenteredText>
        ) : (
          requestReturnInfo
        ),
        ModalActionsMemo: ({ closeModal }) => {
          const { t } = useTranslation();
          return (
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          );
        },
      },
      button: {
        ModalButtonMemo: ({ onClick }) => {
          return (
            <button
              type="button"
              onClick={onClick}
              hidden
              ref={uploadButtonRef}
            />
          );
        },
      },
      general: {
        initialInternalModalData: null as unknown,
      },
    });
  return { UploadButton, uploadModal, uploadButtonRef };
};
