import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import {
  SearchAutocompleteFormik,
  SearchAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { UserCollaboratorDTO } from "../../../../../../../DTOs/user/UserCollaboratorDTO";
import { getPaginatedUsers } from "../../../../../../../../../services/user/user.service";
import { UsersCollaboratorsFiltersDTO } from "../../../../../../../DTOs/user/filters/UsersCollaboratorsFiltersDTO";
import { StyledChip } from "./styles/UsersSearchAutocompleteFormik.styles";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    UserCollaboratorDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<UsersCollaboratorsFiltersDTO, "name">;
  filtersMemo?: Omit<UsersCollaboratorsFiltersDTO, "name">;
}

export const UsersSearchAutocompleteFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
}: OwnProps<DisableClearable>) => {
  const { t } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getUsersFinal = useCallback(
    async (name: string) => {
      const paginatedUsers = await getPaginatedUsers({
        filters: { ...finalFilters, name },
        pagination: {
          pageSize: 10,
          columnOrderBy: "name",
          columnOrderByDir: "asc",
        },
      });

      return paginatedUsers.tableData;
    },
    [finalFilters]
  );

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("users.errors.data.fetch.failedToFetchUsers")}
      fetchOptions={getUsersFinal}
      textfieldProps={{
        label: t("usersCollaborators.keywords.general.collaborators"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${x.name ?? x.login ?? String(x.id)} ${
            x.resignationDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value, getTagProps) =>
          value.map((option, index) => {
            return (
              <StyledChip
                size="small"
                variant="filled"
                label={option.name}
                {...getTagProps({ index })}
              />
            );
          }),
        ...autocompleteProps,
      }}
    />
  );
};
