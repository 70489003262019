import { useTranslation } from "react-i18next";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { StyledStatusReturned } from "../../index.styles";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { maxLengthFields } from "../../../../constants/form.constants";

interface OwnProps {
  isSubmitting: boolean;
  invoiceData: InvoiceDTO;
  setFieldValue: (
    field: string,
    value: unknown,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const ReturnedForm = ({
  isSubmitting,
  invoiceData,
  setFieldValue,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledStatusReturned>
      <TextFieldFormik
        name="reasonForReturn"
        label={t("invoice.keywords.fields.reasonForReturn")}
        multiline
        rows={3}
        required
        fullWidth
        inputProps={{
          maxLength: maxLengthFields.invoiceNote,
        }}
        disabled={isSubmitting}
      />
    </StyledStatusReturned>
  );
};
