import { useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getAllocationsPerUser } from "../../../../../../../../../services/allocations/allocations.service";
import { formatNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CostCenterPepOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import { useDeletionForm } from "../useDeletionForm";
import { DeleteButtonSuperUserContainer } from "./DeleteButtonContainer";
import { useEditingCellForm } from "../useEditingCellForm";
import { useAssignAllocationGroupToUserForm } from "../useAssignAllocationGroupToUserForm";
import { UserColaboratorButtonCell } from "./UserColaboratorButtonCell";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { CarouselContent } from "./CarouselContent";
import { AllocationsCarouselContext } from "../../shared/react/contexts/AllocationsCarouselContext";
import { usePermissionChecker } from "../../../../../../../../../shared/specific/react/hooks/data/user/permissions/usePermissionChecker";
import { AllocationsSummaryContext } from "../../shared/react/contexts/AllocationsSummaryContext";
import { AllocationTypeCell } from "./AllocationTypeCell";

interface OwnProps {
  reloadTablePage: () => void;
  reloadAllocationsSummary: () => void;
  costCenterPep: CostCenterPepOnlyDTO | null;
}

export const useTableData = ({
  reloadTablePage,
  reloadAllocationsSummary,
  costCenterPep,
}: OwnProps) => {
  const { t } = useTranslation();

  const { hasPermission: canEditAllocations } = usePermissionChecker({
    restrictions: {
      type: PermissionType.Allocation,
      level: PermissionLevel.Update,
    },
  });
  const { setSummaryData, setIsLoadingSummary, setHasErrorSummary } =
    useContext(AllocationsSummaryContext);
  const { accessMode } = useProjectContext();
  const { carouselRealSize, getCarouselRealSize } = useContext(
    AllocationsCarouselContext
  );
  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadAllocationsSummary,
    reloadTablePage,
  });
  const { EditingButtonContainer, editingModal } = useEditingCellForm({
    reloadAllocationsSummary,
    reloadTablePage,
  });
  const { AssigningAllocationUserButton, assigningAllocationUserModal } =
    useAssignAllocationGroupToUserForm({
      reloadAllocationsSummary,
      reloadTablePage,
    });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.colaborator"),
        headColumn: true,
        width: 150,
      },
      t("allocations.keywords.fields.currentJobRole"),
      t("allocations.keywords.fields.totalAllocationCost"),
      t("general.keywords.fields.type"),
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      canSort: false,
    });

    if (accessMode === "writing")
      headCells.push({
        value: "",
        width: 72,
        canSort: false,
      });

    return headCells;
  }, [t, accessMode, carouselRealSize]);

  const singlePageLoader =
    useCallback<ExternalEnhancedTableExternalSinglePageLoader>(async () => {
      if (!costCenterPep)
        throw new TranslatedError(
          "costCenterPeps.errors.data.fields.costCenterPep.noPepProvided"
        );

      setIsLoadingSummary(true);
      setHasErrorSummary(false);

      try {
        const allocationsPerUserDTO = await getAllocationsPerUser({
          filters: {
            idCostCenterPep: costCenterPep.id,
          },
        });

        setSummaryData({
          teamValue: allocationsPerUserDTO.totalAllocationCost,
        });

        const rows = allocationsPerUserDTO.allocationsPerUser.map(
          (allocationsOfUser, index): ExternalEnhancedRow => {
            const isUserColaboratorEditable =
              accessMode === "writing" &&
              allocationsOfUser.idJobRole !== null &&
              canEditAllocations;

            const row: ExternalEnhancedRow = {
              id: index,
              rowInfo: allocationsOfUser,
              cells: [
                {
                  value: allocationsOfUser?.name ?? null,
                  paddingmode: isUserColaboratorEditable ? "none" : undefined,
                  CellRenderer: () => (
                    <UserColaboratorButtonCell
                      AssigningAllocationUserButton={
                        AssigningAllocationUserButton
                      }
                      allocationPerUserRow={allocationsOfUser}
                      isUserColaboratorEditable={isUserColaboratorEditable}
                    />
                  ),
                },
                {
                  value:
                    allocationsOfUser.currentCollaboratorJobRoleName ??
                    allocationsOfUser?.jobRoleName ??
                    null,
                  displayValue:
                    allocationsOfUser.currentCollaboratorJobRoleName ??
                    allocationsOfUser?.jobRoleName ??
                    "-",
                },
                {
                  value: allocationsOfUser.totalAllocationCost,
                  displayValue: formatNumber(
                    allocationsOfUser.totalAllocationCost,
                    {
                      fractionDigits: 2,
                    }
                  ),
                },
                {
                  value: allocationsOfUser.allocationType,
                  CellRenderer: () => (
                    <AllocationTypeCell
                      allocationType={allocationsOfUser.allocationType}
                    />
                  ),
                },
              ],
            };

            row.cells.push({});

            const carouselRealSize = getCarouselRealSize();
            for (let i = 0; i < carouselRealSize; i++) {
              row.cells.push({
                paddingmode: "none",
                CellRenderer: () => {
                  return (
                    <CarouselCell
                      items={allocationsOfUser.allocationYearMonths}
                      columnIndex={i}
                      checkIsCurrentItem={(item, yearMonth) =>
                        item.yearMonth.equals(yearMonth)
                      }
                      CarouselComponentMemo={({ item, yearMonth }) => (
                        <CarouselContent
                          allocation={item}
                          allocationsUser={allocationsOfUser}
                          yearMonth={yearMonth}
                          EditingButtonContainer={EditingButtonContainer}
                        />
                      )}
                    />
                  );
                },
              });
            }
            row.cells.push({});

            if (accessMode === "writing")
              row.cells.push({
                CellRenderer: () => (
                  <Protected
                    restrictions={{
                      type: PermissionType.Allocation,
                      level: PermissionLevel.Delete,
                    }}
                  >
                    <DeleteButtonSuperUserContainer
                      allocationsOfUser={allocationsOfUser}
                      DeleteButtonContainer={DeleteButtonContainer}
                    />
                  </Protected>
                ),
                align: "right",
                paddingmode: "horizontal",
              });

            return row;
          }
        );

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);
        setHasErrorSummary(true);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      } finally {
        setIsLoadingSummary(false);
      }
    }, [canEditAllocations, costCenterPep, accessMode, carouselRealSize]);

  return {
    headCells,
    singlePageLoader,
    deletionModal,
    editingModal,
    assigningAllocationUserModal,
  };
};
