import * as yup from "yup";
import {
  EmployeeAllocationType,
  schemaEmployeeAllocationType,
} from "../../enums/allocations/EmployeeAllocationType.enums";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "../corporateSegment/CorporateSegmentsDTO";
import {
  CostCenterOnlyDTO,
  schemaCostCenterOnlyDTO,
} from "../costCenter/CostCenterOnlyDTO";
import { JobRoleDTO, schemaJobRoleDTO } from "../jobRole/JobRoleDTO";
import {
  schemaUserCurrentJobRoleDTO,
  UserCurrentJobRoleDTO,
} from "../user/UserCurrentJobRoleDTO";
import {
  CorporateAllocationYearMonthDTO,
  schemaCorporateAllocationYearMonthDTO,
} from "./CorporateAllocationYearMonthDTO";

export interface CorporateAllocationDTO {
  id: number;
  userCurrentJobRole: UserCurrentJobRoleDTO;
  allocationType: EmployeeAllocationType;
  costCenter: CostCenterOnlyDTO | null;
  jobRole: JobRoleDTO | null;
  allocationGroup: number;
  corporateSegment: CorporateSegmentsDTO | null;
  allocationYearMonth: CorporateAllocationYearMonthDTO | null;
}

export const schemaCorporateAllocationDTO = yup.object({
  id: yup.number().integer().defined(),
  userCurrentJobRole: schemaUserCurrentJobRoleDTO.defined(),
  jobRole: schemaJobRoleDTO.nullable().defined(),
  allocationType: schemaEmployeeAllocationType.defined(),
  costCenter: schemaCostCenterOnlyDTO.nullable().defined(),
  corporateSegment: schemaCorporateSegmentsDTO.nullable().defined(),
  allocationGroup: yup.number().integer().defined(),
  allocationYearMonth: schemaCorporateAllocationYearMonthDTO
    .nullable()
    .defined(),
});
