import { parseLocaleNumber } from "../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { TaxCreateDTO } from "../../../../../../../../shared/specific/DTOs/Tax/TaxCreateDTO";
import { SubsidiaryWithBusinessRule } from "../../../../../../../../shared/specific/enums/subsidiaries/fields/SubsidiaryWithBusinessRule.enum";
import { CostCenterPepsFormValues } from "../../../shared/types/data/form.types";

export function HandleTaxes(
  formValues: CostCenterPepsFormValues
): TaxCreateDTO[] {
  if (!formValues?.subsidiary) return [];
  switch (formValues.subsidiary.name) {
    case SubsidiaryWithBusinessRule.RDXBR:
      return [
        {
          name: "PIS",
          value: formValues.pis?.label
            ? parseLocaleNumber(formValues.pis?.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
        },
        {
          name: "COFINS",
          value: formValues.cofins?.label
            ? parseLocaleNumber(formValues.cofins.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
        },
        {
          name: "ICMS",
          value: parseLocaleNumber(formValues.icms),
          idSubsidiary: formValues.subsidiary?.id ?? 0,
        },
        {
          name: "ISS",
          value: formValues.iss?.label
            ? parseLocaleNumber(formValues.iss.label)
            : 0,
          idSubsidiary: formValues.subsidiary?.id ?? 0,
        },
      ];
    case "RDX.US":
      return [
        {
          idSubsidiary: formValues.subsidiary?.id ?? 0,
          name: "Tax",
          value: parseLocaleNumber(formValues.tax),
        },
      ];
    default:
      return [];
  }
}
