import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../services/applicationState/toast.service";
import {
  getByIdWithRecentlyHistory,
  turnOffUserCollaborator,
} from "../../../../../../../services/user/user.service";
import { DateOnly } from "../../../../../../../shared/common/classes/data/date/DateOnly";
import { UserCollaboratorTurnOffIconButton } from "../../shared/react/components/general/buttons/UserCollaboratorTurnOffButton";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TranslatedError } from "../../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TurnOffDataForm } from "./TurnOffDataForm";
import { useTurnOffFormikConfig } from "./shared/react/hooks/data/form/validation/useTurnOffFormikConfig";
import { TurnOffDataFormValues } from "./shared/types/data/form/values.types";
import { UserCollaboratorRecentlyHistoryDTO } from "../../../../../../../shared/specific/DTOs/user/UserCollaboratorRecentlyHistoryDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

type OwnReturn = {
  TurnOffButtonContainer: (
    props: UserCollaboratorRecentlyHistoryDTO
  ) => JSX.Element | null;
  turnOffModal: JSX.Element | null;
};

export const useTurnOffForm = ({ reloadTablePage }: OwnProps): OwnReturn => {
  const { t } = useTranslation();

  const formikConfig = useTurnOffFormikConfig();

  const { ContentButton: TurnOffButtonContainer, contentModal: turnOffModal } =
    useFormikModalButton<
      TurnOffDataFormValues,
      UserCollaboratorRecentlyHistoryDTO,
      UserCollaboratorRecentlyHistoryDTO
    >({
      modal: {
        modalMode: "dialog",
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: userCollaborator }) => (
          <Trans
            i18nKey="usersCollaborators.modal.data.turnOff.titleTurnOffName"
            values={{
              name: userCollaborator?.name,
            }}
            components={{ italic: <em /> }}
          />
        ),
      },
      button: {
        FormButtonMemo: UserCollaboratorTurnOffIconButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: userCollaboratorRow,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const userCollaboratorDTO = await getByIdWithRecentlyHistory(
              userCollaboratorRow.id
            );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!userCollaboratorDTO)
              return setModalError(
                i18next.t(
                  "usersCollaborators.errors.data.general.userCollaboratorDoesNotExist"
                )
              );

            setInternalFormData(userCollaboratorDTO);
            setFormValues({
              resignationDate: userCollaboratorDTO.resignationDate,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "usersCollaborators.errors.data.fetch.failedToFetchUserCollaborator",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: (props) => <TurnOffDataForm {...props} />,
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          internalFormData: userCollaborator,
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
          setFormValues,
        }) => {
          if (!userCollaborator)
            throw new Error("Collaborator cannot be null on submission.");

          try {
            await turnOffUserCollaborator({
              id: userCollaborator.id,
              resignationDate: formValues.resignationDate
                ? DateOnly.createFromDate(formValues.resignationDate)
                : undefined,
              version: userCollaborator.version,
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            setFormValues(formValues);
            closeModal();
          } catch (error) {
            const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
            if (errorApiErrorDTO instanceof TranslatedError)
              return notifyError(
                i18next.t(
                  errorApiErrorDTO.message,
                  errorApiErrorDTO.interpolation
                )
              );
            if (errorApiErrorDTO instanceof Error)
              return notifyError(errorApiErrorDTO.message);

            if (userCollaborator.name)
              return notifyError(
                <Trans
                  i18nKey="usersCollaborators.errors.data.turnOff.failedToTurnOff"
                  values={{ name: userCollaborator.name ?? "" }}
                  components={{ italic: <em /> }}
                />
              );

            notifyError(
              t("usersCollaborators.errors.data.turnOff.failedToTurnOff")
            );
          } finally {
            setSubmitting(false);
          }
        },
      },
      general: {
        initialInternalFormData:
          null as UserCollaboratorRecentlyHistoryDTO | null,
      },
    });

  return { TurnOffButtonContainer, turnOffModal };
};
