import * as yup from "yup";
import { Formik, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { FormValuesMvWithFinancialCost } from "./Types/form.types";
import { generateMvWithFinancialCostReport } from "../../../../../../../../../services/reports/reports.service";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { MvWithFinancialCostReportFilterDTO } from "../../../../../../../../../shared/specific/DTOs/reports/MvWithFinancialCostReportFilterDTO";
import { CostCenterPepCodesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenterPep/CostCenterPepCodesAutocompleteFormik";

export const MvWithFinancialCost = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesMvWithFinancialCost>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesMvWithFinancialCost = {
      subsidiary: null,
      division: null,
      costCenterPep: null,
    };

    const validationSchema = yup.object({
      subsidiary: yup.object().nullable(),
      division: yup.object().nullable(),
      costCenterPep: yup.string().nullable(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (values: FormValuesMvWithFinancialCost) => {
    const formattedFilters: MvWithFinancialCostReportFilterDTO = {
      idSubsidiary: values?.subsidiary?.id ?? undefined,
      idDivision: values?.division?.id ?? undefined,
      PepCode: values?.costCenterPep ?? undefined,
    };
    try {
      await generateMvWithFinancialCostReport(formattedFilters);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <SubsidiariesAutocompleteFormik
              name="subsidiary"
              textfieldProps={{
                label: t("subsidiaries.keywords.general.company"),
              }}
            />
            <CostCenterPepCodesAutocompleteFormik name="costCenterPep" />
            <DivisionsAutocompleteFormik name="division" />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
