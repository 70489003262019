import { useCallback, useEffect, useRef } from "react";
import { useEffectAfterRenders } from "../../../../../../../../hooks/enhancedReactHooks/useEffectAfterRenders";
import { useIsMountedRef } from "../../../../../../../../hooks/management/useIsMountedRef";
import { formatExternalRows } from "../../../../helpers/data.helpers";
import { EnhancedRowData, Order } from "../../../../types/data.types";
import { EnhancedTableExternalPagination } from "../../../../types/settings.types";

interface OwnProps {
  usesPagination: boolean;
  externalPagination?: EnhancedTableExternalPagination;
  page: number;
  rowsPerPage: number;
  order: Order;
  orderBy: number | null;
  externalRows: EnhancedRowData[] | null;
  setLoading: (loading: string | boolean) => void;
  setError: (error: string | boolean) => void;
  setInfo: (info: string | boolean) => void;
  setPage: (page: number) => void;
  setTotalItems: (totalItems: number | null) => void;
  setRows: (rows: EnhancedRowData[] | null) => void;
}

export const usePaginationHandler = ({
  setError,
  setLoading,
  setInfo,
  usesPagination,
  externalPagination,
  page,
  rowsPerPage,
  order,
  orderBy,
  externalRows,
  setPage,
  setRows,
  setTotalItems,
}: OwnProps) => {
  const pageChangerCounterRef = useRef(0);
  const isMountedRef = useIsMountedRef();

  const handlePageChange = useCallback(async () => {
    if (!externalPagination?.onPageChange) return;

    setLoading(true);
    setError(false);
    setInfo(false);

    pageChangerCounterRef.current += 1;
    const currentPageChangeCount = pageChangerCounterRef.current;

    try {
      const pageChangeResults = await externalPagination.onPageChange({
        newPage: page,
        rowsPerPage,
        order,
        orderBy,
        setError,
        setLoading,
        setInfo,
      });

      if (!isMountedRef.current) return;
      if (currentPageChangeCount < pageChangerCounterRef.current) return;

      if (pageChangeResults === null) {
        setRows(null);
        setTotalItems(null);
        setLoading(false);
        return;
      }

      const { page: returnedPage, rows, totalItems } = pageChangeResults;

      if (rows.length === 0 && returnedPage !== 0) {
        setPage(0);
        return;
      }

      const formattedRows = formatExternalRows(rows);

      setPage(returnedPage);
      setRows(formattedRows);
      setTotalItems(totalItems);
      setLoading(false);
    } catch (error) {
      if (!isMountedRef.current) return;
      if (currentPageChangeCount < pageChangerCounterRef.current) return;

      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(true);
      }

      setRows(null);
      setTotalItems(null);
      setLoading(false);
    }
  }, [externalPagination?.onPageChange, page, rowsPerPage, order, orderBy]);

  const handleSinglePageFetching = useCallback(async () => {
    if (!externalPagination?.loadSinglePageContent) return;

    setLoading(true);
    setError(false);
    setInfo(false);

    pageChangerCounterRef.current += 1;
    const currentPageChangeCount = pageChangerCounterRef.current;

    try {
      const rows = await externalPagination.loadSinglePageContent({
        order,
        orderBy,
        setError,
        setLoading,
        setInfo,
      });

      if (!isMountedRef.current) return;
      if (currentPageChangeCount < pageChangerCounterRef.current) return;

      if (rows === null) {
        setRows(null);
        setTotalItems(null);
        setLoading(false);
        return;
      }

      const formattedRows = formatExternalRows(rows);

      setRows(formattedRows);
      setLoading(false);
    } catch (error) {
      if (!isMountedRef.current) return;
      if (currentPageChangeCount < pageChangerCounterRef.current) return;

      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(true);
      }

      setRows(null);
      setLoading(false);
    }
  }, [externalPagination?.loadSinglePageContent, order, orderBy]);

  const reloadPage = useCallback(() => {
    if (!isMountedRef.current) return;

    if (usesPagination) {
      handlePageChange();
    } else {
      handleSinglePageFetching();
    }
  }, [handlePageChange, handleSinglePageFetching]);

  useEffect(() => {
    isMountedRef.current = true;
    reloadPage();

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffectAfterRenders({
    effect: () => {
      isMountedRef.current = true;
      reloadPage();

      return () => {
        isMountedRef.current = false;
      };
    },
    deps: [page, rowsPerPage],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      if (!isMountedRef.current) return;
      if (!usesPagination) return;

      handlePageChange();
    },
    deps: [order, orderBy],
    rendersBeforeEffect: 1,
  });

  useEffectAfterRenders({
    effect: () => {
      if (externalPagination?.shouldUpdateTableWhenCallbackChanges)
        reloadPage();
    },
    deps: [
      externalPagination?.onPageChange,
      externalPagination?.loadSinglePageContent,
    ],
    rendersBeforeEffect: 1,
  });

  useEffect(() => {
    if (!externalPagination) return;

    setPage(0);
  }, []);

  useEffect(() => {
    if (externalPagination) return;
    if (!externalRows) return;

    setRows(externalRows);
    setTotalItems(externalRows.length);
  }, [externalRows]);

  return { handlePageChange, reloadPage };
};
