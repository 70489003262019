import { forwardRef, Ref } from "react";
import { Link, LinkProps } from "react-router-dom";

interface CustomLinkProps extends LinkProps {
  disabled?: boolean;
}

interface CustomLinkRef extends HTMLAnchorElement {}

const CustomLinkWithinForwardRef = (
  { disabled, children, ...rest }: CustomLinkProps,
  ref: Ref<CustomLinkRef>
) => {
  if (disabled) return <>{children}</>;

  return (
    <Link {...rest} ref={ref}>
      {children}
    </Link>
  );
};

export const CustomLink = forwardRef<CustomLinkRef, CustomLinkProps>(
  CustomLinkWithinForwardRef
);
