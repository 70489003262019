import { Paper, TextField, styled } from "@mui/material";

export const StyledComponentAreas = styled(Paper)`
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTextArea = styled(TextField)`
  width: 200px;
`;

export const StyledAreaTextFields = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const StyledTitleContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`;
