import { useField } from "formik";
import { useMemo } from "react";
import {
  MaskedTextField,
  MaskedTextFieldProps,
} from "../../general/textField/MaskedTextField";

type OwnProps = MaskedTextFieldProps & {
  name: string;
};

export const MaskedTextFieldFormik = ({
  name,
  textFieldProps: {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...restTextFieldProps
  } = {},
  ...rest
}: OwnProps) => {
  const [{ value }, { error }, { setValue }] = useField(name);

  const finalInputLabelProps = useMemo(() => {
    return {
      shrink: !!value || undefined,
      ...InputLabelProps,
    };
  }, [value, ...Object.values(InputLabelProps)]);

  const { mask, ...restWithoutMask } = rest;

  return useMemo(() => {
    return (
      <MaskedTextField
        name={name}
        value={value}
        textFieldProps={{
          error: !!error,
          helperText: error,
          inputProps,
          InputProps,
          InputLabelProps: finalInputLabelProps,
          ...restTextFieldProps,
        }}
        onAccept={(value: string) => setValue(value)}
        {...rest}
      />
    );
  }, [
    value,
    name,
    finalInputLabelProps,
    ...Object.values(inputProps),
    ...Object.values(InputProps),
    ...Object.values(restTextFieldProps),
    ...Object.values(restWithoutMask),
    error,
  ]);
};
