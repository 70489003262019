import { FieldArrayRenderProps } from "formik";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExternalEnhancedRow } from "../../../../../../../../../common/react/components/table/EnhancedTable";
import { CheckboxPermission } from "../../AccessManagementTable/CheckboxPermission";
import { permissionTypeToTranslationCodeMap } from "../specific/maps/users/permissions/permissionTypeToTranslationCodeMap";
import { AccessManagementListPermissions } from "../types/general.types";
import { usePermissionsFormatted } from "./usePermissionsFormatted";

interface OwnProps {
  listPermissions: AccessManagementListPermissions[] | null;
}

export const useRowsTypeReportPermissions = ({ listPermissions }: OwnProps) => {
  const { t } = useTranslation();

  const { permissionTypesFormatted } = usePermissionsFormatted();

  const generateRowsTypeReportPermissions = useCallback(
    (array: FieldArrayRenderProps): ExternalEnhancedRow[] =>
      permissionTypesFormatted.report.map(
        (permissionTypeData): ExternalEnhancedRow => ({
          id: permissionTypeData.id,
          cells: [
            t(permissionTypeToTranslationCodeMap[permissionTypeData.id] ?? ""),
            {
              CellRenderer: () => (
                <CheckboxPermission
                  listPermissions={listPermissions}
                  permissionTypeData={permissionTypeData}
                  array={array}
                  permissionLevel={null}
                />
              ),
              align: "center",
              paddingmode: "none",
            },
          ],
        })
      ),
    [permissionTypesFormatted]
  );

  return useMemo(() => {
    return { generateRowsTypeReportPermissions };
  }, [generateRowsTypeReportPermissions]);
};
