import * as yup from "yup";
import { ExpenseCreateDTO } from "../../../shared/specific/DTOs/expenses/ExpenseCreateDTO";
import {
  ExpenseDTO,
  schemaExpenseDTO,
} from "../../../shared/specific/DTOs/expenses/ExpenseDTO";
import { ExpenseModifyDTO } from "../../../shared/specific/DTOs/expenses/ExpenseModifyDTO";
import {
  ExpensesGeneralDTO,
  schemaExpensesGeneralDTO,
} from "../../../shared/specific/DTOs/expenses/ExpensesGeneralDTO";
import { ExpenseGetByPropertiesDTO } from "../../../shared/specific/DTOs/expenses/queries/ExpenseGetByPropertiesDTO";
import { riseApi } from "./connection";

export const getGeneralExpenses = async (
  idCostCenterPep: number
): Promise<ExpensesGeneralDTO> => {
  const { data: expenses } = await riseApi.get(
    `Expenses/CostCenterPep/${idCostCenterPep}`
  );
  return schemaExpensesGeneralDTO.validateSync(expenses);
};

export const getExpense = async (id: number): Promise<ExpenseDTO | null> => {
  const { data: expense } = await riseApi.get(`Expenses/${id}`);
  return schemaExpenseDTO.nullable().validateSync(expense);
};

export const getExpenseByProperties = async (
  properties: ExpenseGetByPropertiesDTO
): Promise<ExpenseDTO[]> => {
  const { data: expense } = await riseApi.get("Expenses/ByProperties", {
    params: properties,
  });
  return yup.array(schemaExpenseDTO).defined().validateSync(expense);
};

export const modifyExpense = async (expenseModifyDTO: ExpenseModifyDTO) => {
  await riseApi.put("Expenses", expenseModifyDTO);
};

export const createExpenses = async (expense: ExpenseCreateDTO) => {
  await riseApi.post("Expenses", expense);
};

export const deleteExpense = async (id: number, version: string) => {
  await riseApi.delete(`Expenses/${id}/${version}`);
};
