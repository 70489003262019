import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { AutoFixHigh as AutoFixHighIcon } from "@mui/icons-material";
import { useCallback } from "react";
import {
  OnClickContentButtonFunction,
  useFormikModalButton,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { SimpleIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";
import { useFormikConfig } from "./shared/react/hooks/validation/useFormikConfig";
import { CreateManyForm } from "./CreateManyForm";
import { CreateManyActivitiesFormValues } from "./shared/types/data/form/values.types";
import { ActivityCreationInfoDTO } from "../../../../../../../../../shared/specific/DTOs/activity/ActivityCreationInfoDTO";
import { getActivityCreationInfo } from "../../../../../../../../../services/activities/activities.service";
import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { generateActivityCreateManyItems } from "./shared/helpers/data/form/generators.helpers";
import { parseLocaleNumber } from "../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useViewCreateManyForm } from "./hooks/useViewCreateManyForm";
import { notifyError } from "../../../../../../../../../services/applicationState/toast.service";

export const useCreationManyForm = () => {
  const { t } = useTranslation();

  const { project, costCenterPep } = useProjectContext();
  const formikConfig = useFormikConfig();

  const { openViewCreateManyModal, viewCreateManyModal } =
    useViewCreateManyForm();

  const onClickContentButton = useCallback<
    OnClickContentButtonFunction<
      CreateManyActivitiesFormValues,
      ActivityCreationInfoDTO
    >
  >(
    async ({
      setFormValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    }) => {
      setIsLoadingModal(true);
      const startingOpenCloseModalCount = getOpenCloseModalCount();
      try {
        const creationInfo = await getActivityCreationInfo(project.id);

        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        if (!creationInfo)
          return setModalError(
            i18next.t("projects.errors.data.general.projectDoesNotExist")
          );

        const amountLeft =
          creationInfo.billingAmountWithAdditives -
          creationInfo.totalProjectIncome;

        if (amountLeft < 0)
          return setModalError(
            i18next.t(
              "activities.errors.data.createMany.projectIncomeGreaterProjectAmount",
              {
                amount: formatCurrency({
                  value: Math.abs(amountLeft),
                  currencySymbol: costCenterPep.currencySymbol,
                }),
              }
            )
          );
        if (amountLeft === 0)
          return setModalError(
            i18next.t(
              "activities.errors.data.createMany.projectIncomeSameProjectAmount"
            )
          );

        setInternalFormData(creationInfo);
        setFormValues({
          ...formikConfig.initialValues,
          generateActivitiesUntil: formatNumber(amountLeft, {
            fractionDigits: 2,
          }),
          readOnly: {
            amountLeft,
          },
        });
      } catch (error) {
        if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

        const errorMessage = getTextIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "activities.errors.data.fetch.failedToFetchCreationData",
        });

        setModalError(errorMessage);
      } finally {
        setIsLoadingModal(false);
      }
    },
    [costCenterPep.currencySymbol]
  );

  const { contentButton: creationManyButton, contentModal: creationManyModal } =
    useFormikModalButton<
      CreateManyActivitiesFormValues,
      ActivityCreationInfoDTO
    >({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("activities.modal.data.createMany.title"),
        letModalTitleDealWithError: true,
      },
      button: {
        FormButtonMemo: ({ onClick }) => {
          const { t } = useTranslation();

          return (
            <SimpleIconButton
              Icon={AutoFixHighIcon}
              onClick={onClick}
              tooltipText={t(
                "activities.actions.general.addMultipleActivities"
              )}
            />
          );
        },
        onClickContentButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({
          internalFormData: activityCreationInfo,
          ...rest
        }) =>
          activityCreationInfo && (
            <CreateManyForm
              activityCreationInfo={activityCreationInfo}
              {...rest}
            />
          ),
        FormActionsMemo: ({
          submitFormValues,
          closeModal,
          isSubmitting,
          isLoadingModal,
          modalError,
        }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
                disabled={isLoadingModal || !!modalError}
              >
                {t("general.actions.general.view")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setFieldError },
        }) => {
          if (!internalFormData)
            throw new Error("'internalFormData' cannot be null on submit.");

          const activityAmountSize = parseLocaleNumber(
            formValues.activityAmountSize
          );
          const totalAmountDesired = parseLocaleNumber(
            formValues.generateActivitiesUntil
          );

          if (Math.ceil(totalAmountDesired / activityAmountSize) > 200)
            return notifyError(
              t(
                "activities.errors.data.createMany.activitiesWouldExceedLimit",
                { limit: 200 }
              )
            );

          const activityCreateManyDTO = generateActivityCreateManyItems(
            project.id,
            project.contractType,
            internalFormData.maxActivityAmount,
            formValues
          );

          const totalActivitiesAmount = parseLocaleNumber(
            formValues.generateActivitiesUntil
          );

          openViewCreateManyModal(({ setInternalModalData }) => {
            setInternalModalData({
              activityCreateManyDTO,
              totalActivitiesAmount,
            });
          });
        },
      },
    });

  return { creationManyButton, creationManyModal, viewCreateManyModal };
};
