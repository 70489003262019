import * as yup from "yup";
import {
  SkillCategoryTranslationDTO,
  schemaSkillCategoryTranslationDTO,
} from "./translation/SkillCategoryTranslationDTO";
import {
  DisciplineDTO,
  schemaDisciplineDTO,
} from "../discipline/DisciplineDTO";

export interface SkillCategoryDTO {
  id: number;
  name: string;
  discipline: DisciplineDTO;
  deactivatedDate: Date | null;
  version: string;
  skillCategoryTranslation: SkillCategoryTranslationDTO[];
}

export const schemaSkillCategoryDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  discipline: schemaDisciplineDTO.defined(),
  deactivatedDate: yup.date().nullable().default(null),
  version: yup.string().defined(),
  skillCategoryTranslation: yup
    .array(schemaSkillCategoryTranslationDTO)
    .defined(),
});
