import { createContext } from "react";
import { defaults } from "../../constants/defaults";
import { TableContextValues } from "./index.types";

const defaultValues: TableContextValues = {
  sorting: {
    order: "asc",
    orderBy: null,
    onRequestSort: () => null,
  },
  pagination: undefined,
  ui: {
    hasDynamicTableHeight: false,
    mincellheight: defaults.ui.mincellheight,
    minheadcellheight: defaults.ui.minheadcellheight,
    minTableWidth: defaults.ui.minTableWidth,
  },
  state: {
    error: false,
    setError: () => null,
    info: false,
    setInfo: () => null,
    loading: false,
    setLoading: () => null,
  },
  toolbar: {
    title: "",
  },
  headCells: [],
  rows: [],
  filter: {},
  messages: {},
};

export const TableContext = createContext(defaultValues);
