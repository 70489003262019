import * as yup from "yup";
import {
  ProjectPlanningSavingValidationDTO,
  schemaProjectPlanningSavingValidationDTO,
} from "./ProjectPlanningSavingValidationDTO";

export interface ProjectPlanningFinishingValidationDTO {
  projectPlanningSavingValidation: ProjectPlanningSavingValidationDTO;
  allActivitiesClosed: boolean;
  allInvoicesIssueDatesNotNullAndBeforeNow: boolean;
  finalScheduledEndBeforeNow: boolean;
}

export const schemaProjectPlanningFinishingValidationDTO = yup.object({
  projectPlanningSavingValidation:
    schemaProjectPlanningSavingValidationDTO.defined(),
  allActivitiesClosed: yup.boolean().defined(),
  allInvoicesIssueDatesNotNullAndBeforeNow: yup.boolean().defined(),
  finalScheduledEndBeforeNow: yup.boolean().defined(),
});
