import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { useMemo } from "react";

export type TextFieldFormikProps = TextFieldProps & {
  name: string;
  inputPropsMemo?: TextFieldProps["inputProps"];
  InputPropsMemo?: TextFieldProps["InputProps"];
  InputLabelPropsMemo?: TextFieldProps["InputLabelProps"];
};

export const TextFieldFormik = ({
  name,
  inputProps,
  inputPropsMemo,
  InputProps,
  InputPropsMemo,
  InputLabelProps,
  InputLabelPropsMemo,
  ...rest
}: TextFieldFormikProps) => {
  const [field, { error }] = useField(name);

  const inputPropsFinal = useMemo(() => {
    return inputProps ?? inputPropsMemo ?? {};
  }, [inputProps]);

  const InputPropsFinal = useMemo(() => {
    return InputProps ?? InputPropsMemo ?? {};
  }, [InputProps]);

  const InputLabelPropsFinal = useMemo(() => {
    return InputLabelProps ?? InputLabelPropsMemo ?? {};
  }, [InputLabelProps]);

  return useMemo(() => {
    return (
      <TextField
        {...field}
        error={!!error}
        helperText={error}
        inputProps={inputPropsFinal}
        InputProps={InputPropsFinal}
        InputLabelProps={InputLabelPropsFinal}
        {...rest}
      />
    );
  }, [
    ...Object.values(field),
    ...Object.values(inputPropsFinal),
    ...Object.values(InputPropsFinal),
    ...Object.values(InputLabelPropsFinal),
    ...Object.values(rest),
    error,
  ]);
};
