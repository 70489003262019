import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findBinarySearch } from "../../../../../../../../../shared/common/helpers/data/arrays/finders.helpers";
import { FormattedCurrency } from "../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { ExpenseType } from "../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { expenseTypeToTranslationCodeMap } from "../../../../../../../../../shared/specific/maps/expenses/expenseType.maps";
import {
  SummarySection,
  SummarySectionTitle,
} from "../../shared/react/styles/ui/summarySection.styles";
import { SummaryContext } from "../../shared/react/contexts/SummaryContext";
import { RowData } from "../../shared/types/data/carousel.types";
import { SummaryCarouselContext } from "../../shared/react/contexts/SummaryCarouselContext";
import { getExpenseAmountsDictionary } from "./index.helpers";
import {
  StyledCenteredInfo,
  StyledRowHeader,
} from "../../shared/react/styles/table/summaryTable.styles";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";

const CAROUSEL_SIZE = 8;

interface OwnProps {
  positionStart: number;
}

export const SummaryExpensesSummaryWithinContext = ({
  positionStart,
}: OwnProps) => {
  const { t } = useTranslation();

  const { summaryData } = useContext(SummaryContext);
  const { startCarousel, carouselRealSize, totalYearMonths } = useContext(
    SummaryCarouselContext
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        headColumn: true,
        canSort: false,
        minWidth: 300,
        width: "10%",
      },
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }
    for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
      headCells.push({ value: "", canSort: false });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      align: "right",
      canSort: false,
    });

    return headCells;
  }, [t, carouselRealSize]);

  const rowsHeaders = useMemo(() => {
    if (!summaryData?.monthlyAmounts || !summaryData?.monthlyExpenses)
      return null;

    const rowsHeaders: RowData[] = [
      {
        name: t("general.keywords.fields.total"),
        items: summaryData.monthlyAmounts.expenses.sort((a, b) =>
          a.yearMonth.differenceMonths(b.yearMonth)
        ),
        isAccumulated: false,
      },
      {
        name: t("planningSummary.keywords.fields.performedExpenses"),
        items: summaryData.monthlyAmounts.performedExpenses.sort((a, b) =>
          a.yearMonth.differenceMonths(b.yearMonth)
        ),
        isAccumulated: false,
      },
      {
        name: t("planningSummary.keywords.fields.accumulatedExpenses"),
        items: summaryData.monthlyAmounts.accumulatedExpenses.sort((a, b) =>
          a.yearMonth.differenceMonths(b.yearMonth)
        ),
        isAccumulated: true,
      },
      {
        name: t("planningSummary.keywords.fields.accumulatedPerformedExpenses"),
        items: summaryData.monthlyAmounts.accumulatedPerformedExpenses.sort(
          (a, b) => a.yearMonth.differenceMonths(b.yearMonth)
        ),
        isAccumulated: true,
      },
    ];

    const monthlyExpenses = getExpenseAmountsDictionary(
      summaryData.monthlyExpenses.monthlyExpenses
    );
    const monthlyPerformedExpenses = getExpenseAmountsDictionary(
      summaryData.monthlyExpenses.monthlyPerformedExpenses
    );

    for (const expenseType of Object.values(ExpenseType)) {
      if (typeof expenseType === "string") continue;
      if (
        [
          ExpenseType.Tax,
          ExpenseType.GrossRevenue,
          ExpenseType.Additional,
        ].includes(expenseType)
      )
        continue;

      const expenseName = t(expenseTypeToTranslationCodeMap[expenseType]);

      rowsHeaders.push({
        name: expenseName,
        items: monthlyExpenses[expenseType] ?? [],
        isAccumulated: false,
      });
      rowsHeaders.push({
        name: t("planningSummary.keywords.fields.namePerformed", {
          name: expenseName,
        }),
        items: monthlyPerformedExpenses[expenseType] ?? [],
        isAccumulated: false,
      });
    }

    return rowsHeaders;
  }, [t, summaryData?.monthlyAmounts, summaryData?.monthlyExpenses]);

  const rows = useMemo(() => {
    if (!rowsHeaders) return null;
    if (!totalYearMonths) return [];

    startCarousel({
      carouselSize: CAROUSEL_SIZE,
      monthsOffsetFirstYearMonth: positionStart,
    });

    return rowsHeaders.map(({ name, items, isAccumulated }, index) => {
      const row: ExternalEnhancedRow = {
        id: index,
        cells: [
          {
            value: name,
            CellRenderer: () => <StyledRowHeader>{name}</StyledRowHeader>,
          },
        ],
      };

      row.cells.push({});

      for (let i = 0; i < carouselRealSize; i++) {
        row.cells.push({
          paddingmode: "none",
          CellRenderer: () => (
            <CarouselCell
              items={items}
              columnIndex={i}
              findCurrentItem={(items, yearMonth) => {
                return findBinarySearch({
                  sortedArray: items,
                  onNotFound: isAccumulated ? "before" : "null",
                  checkDesiredValuePosition: (item) =>
                    yearMonth.differenceMonths(item.yearMonth),
                });
              }}
              CarouselComponentMemo={({ item, yearMonth }) => {
                const dateNow = new Date();
                const yearMonthNow = new YearMonth(
                  dateNow.getMonth(),
                  dateNow.getFullYear()
                ).toDate();
                return (
                  <StyledCenteredInfo
                    isPast={
                      yearMonth ? yearMonthNow > yearMonth.toDate() : true
                    }
                    isTotal={index <= 3}
                  >
                    <FormattedCurrency
                      value={item?.amount ?? 0}
                      currencySymbol={
                        summaryData?.header.subsidiary.currencySymbol ?? ""
                      }
                    />
                  </StyledCenteredInfo>
                );
              }}
            />
          ),
        });
      }
      for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
        row.cells.push({});
      }
      row.cells.push({});

      return row;
    });
  }, [
    rowsHeaders,
    carouselRealSize,
    summaryData?.header.subsidiary.currencySymbol,
    totalYearMonths,
  ]);

  if (!rows) return null;
  return (
    <SummarySection>
      <SummarySectionTitle>
        {t("planningSummary.keywords.sections.expensesSummary")}
      </SummarySectionTitle>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        uiSettings={{
          stickyHeader: true,
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
    </SummarySection>
  );
};
