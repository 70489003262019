import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useContext, useEffect } from "react";
import { Row } from "../CollapseTableRow";
import { CollapseTableContext } from "../shared/context/CollapseTableContext";
import { CenteredLoading } from "../../../ui/CenteredLoading";
import {
  StyledButtonColumn,
  StyledCenteredLoading,
  StyledFirsColumn,
  StyledInformation,
} from "./inedx.styles";

export const CollapseTableBody = () => {
  const tableContext = useContext(CollapseTableContext);

  useEffect(() => {
    async function GetData() {
      await tableContext.reloadPage();
    }
    GetData();
  }, []);

  return (
    <TableContainer
      component={Paper}
      style={{
        height: "100%",
      }}
    >
      <Table aria-label="collapsible table">
        {tableContext.headCells && (
          <TableHead>
            <TableRow
              style={{
                position: "sticky",
                top: 0,
                background: "white",
                zIndex: 11,
              }}
            >
              <StyledButtonColumn
                isfixfirst={tableContext.fixFirstColumn ? "true" : "false"}
                isfixlast="false"
              />
              {tableContext.headCells.map((h, i) => {
                return (
                  <StyledFirsColumn
                    align="center"
                    key={i}
                    isfixfirst={
                      i === 0 &&
                      tableContext.fixFirstColumn &&
                      tableContext.rows
                        ? "true"
                        : "false"
                    }
                    isfixlast={
                      tableContext.headCells!.length - 1 === i &&
                      tableContext.fixLastColumn
                        ? "true"
                        : "false"
                    }
                  >
                    {h.header}
                  </StyledFirsColumn>
                );
              })}
            </TableRow>
          </TableHead>
        )}
        {!tableContext.loading && (
          <TableBody>
            {tableContext?.rows &&
              tableContext.rows.map((row, i) => (
                <Row
                  key={i}
                  extensibleInformation={row.extensibleInformation}
                  mainInformation={row.mainInformation}
                />
              ))}
          </TableBody>
        )}
      </Table>
      {tableContext.info && !tableContext.loading && !tableContext.error && (
        <StyledInformation>
          <span>{tableContext.info}</span>
        </StyledInformation>
      )}
      {tableContext.error && !tableContext.loading && !tableContext.info && (
        <StyledInformation>
          <span>{tableContext.error}</span>
        </StyledInformation>
      )}
      {tableContext.loading && (
        <StyledCenteredLoading>
          <CenteredLoading />
        </StyledCenteredLoading>
      )}
    </TableContainer>
  );
};
