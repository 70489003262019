import * as yup from "yup";

export enum UserSkillLevelEnum {
  Basic = 2,
  Intermediate = 3,
  Advanced = 4,
  Expert = 5,
}

export const schemaUserSkillLevelEnum = yup
  .mixed<UserSkillLevelEnum>()
  .oneOf(Object.values(UserSkillLevelEnum) as UserSkillLevelEnum[]);
