import { styled } from "@mui/material";

export const StyledLine = styled("div")`
  border-bottom: solid #cccccc 2px;
  height: 1px;
  width: 100%;
`;

export const StyledAreasContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(3)};
`;
