import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const StyledExpensesUpload = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  min-width: 200px;
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  gap: ${({ theme }) => theme.spacing(1.5)};
  ${({ theme }) => theme.breakpoints.down("md")} {
    width: 100%;
    min-width: 0;
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    width: 100%;
    min-width: 0;
  }
`;

export const StyledTextButton = styled(LoadingButton)`
  flex-grow: 1;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const StyledLoading = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 5px;
  color: ${({ theme }) => theme.palette.common.white};
  gap: ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  cursor: pointer;
`;

export const StyledModalHelperInfos = styled("div")`
  ul {
    margin-left: 12;
  }
  p {
    margin-top: 8;
    margin-bottom: 8;
  }
  img {
    max-width: "100%";
  }
`;
