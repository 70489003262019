import * as yup from "yup";

export enum ExpenseType {
  Tax = 0,
  GrossRevenue = 1,
  Workforce = 2,
  SoftwareHardware = 3,
  Travels = 4,
  Infrastructure = 5,
  ResellingProducts = 6,
  Training = 7,
  ConsultantServices = 8,
  Refund = 9,
  Other = 10,
  Additional = 11,
  FinancialCost = 12,
}

export const schemaExpenseType = yup
  .mixed<ExpenseType>()
  .oneOf(Object.values(ExpenseType) as ExpenseType[]);
