import { Button, CircularProgress } from "@mui/material";
import { ChangeEvent, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import {
  DivSuccess,
  PerfectlyCenteredText,
  ResponseListContainer,
} from "./index.styles";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { BudgetCalculatedCostUploadDTO } from "../../../../../../../../../shared/specific/DTOs/BudgetCalculatedCost/BudgetCalculatedCostUploadDTO";
import { uploadCalculatedCost } from "../../../../../../../../../services/budgetCalculatedCost/BudgetCalculatedCost.service";

interface OwnProps {
  idDivision: string | null;
  idSegmentGroup: string | null;
  idSubsidiary: string | null;
  isDisabledButton: boolean;
}

export const useUploadCalculatedCost = ({
  idDivision,
  idSegmentGroup,
  idSubsidiary,
  isDisabledButton,
}: OwnProps) => {
  const { t } = useTranslation();
  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);
  const loadCalculatedCost = () => fileUploadInputRef.current?.click();
  const [filename, setFilename] = useState<string | null>(null);
  const [responseUpload, setResponseUpload] =
    useState<BudgetCalculatedCostUploadDTO | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;
    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      if (!idDivision) {
        return notifyIf4xxApiErrorDTO({
          error: "",
          defaultMessage: "Error while uploading spreadsheet.",
        });
      }
      if (!idSubsidiary) {
        return notifyIf4xxApiErrorDTO({
          error: "",
          defaultMessage: "Error while uploading spreadsheet.",
        });
      }
      const result = await uploadCalculatedCost(
        file,
        idDivision,
        idSegmentGroup,
        idSubsidiary
      );
      setResponseUpload(result);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const requestReturnInfo = useMemo(() => {
    if (responseUpload === null) return null;

    if (
      !responseUpload?.errorMessages ||
      responseUpload?.errorMessages?.length === 0
    ) {
      return (
        <DivSuccess>
          <>{t("uploadBudgetCalculatedCost.keywords.successMessage")}</>
        </DivSuccess>
      );
    }

    return (
      <ResponseListContainer>
        <div>
          <p>
            {t("uploadBudgetCalculatedCost.modal.data.totalError").replace(
              "{0}",
              responseUpload?.errorMessages?.length.toString()
            )}
          </p>
        </div>
        <div>
          {responseUpload?.errorMessages?.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      </ResponseListContainer>
    );
  }, [hasSubmitted, responseUpload, t, filename]);

  const uploadButtonRef = useRef<HTMLButtonElement | null>(null);

  const {
    ModalButtonContainer: UploadButton,
    contentModal: uploadModalCalculatedCost,
  } = useModalButton({
    modal: {
      suppressInternalCloseModal: isSubmitting,
      suppressCloseBackdropClick: isSubmitting,
      letModalContentDealWithLoading: true,
      keepModalMounted: 1000,
      modalTitle: (
        <Trans
          i18nKey="uploadBudgetCalculatedCost.keywords.processingFileName"
          values={{ filename: filename ?? "" }}
          components={{ italic: <em /> }}
        />
      ),
      modalContent: isSubmitting ? (
        <PerfectlyCenteredText>
          <CircularProgress />
        </PerfectlyCenteredText>
      ) : (
        requestReturnInfo
      ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick }) => {
        return (
          <button
            type="button"
            onClick={onClick}
            hidden
            ref={uploadButtonRef}
          />
        );
      },
    },
    general: {
      initialInternalModalData: null as unknown,
    },
  });

  const uploadButtonLoadCalculatedCost = useMemo(() => {
    return (
      <>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          hidden
          ref={fileUploadInputRef}
          onChange={onPickFile}
        />
        <UploadButton />
        <Button
          onClick={loadCalculatedCost}
          variant="contained"
          disabled={isDisabledButton}
        >
          {t("uploadBudgetCalculatedCost.keywords.uploadButton")}
        </Button>
      </>
    );
  }, [t, idDivision, idSegmentGroup, isDisabledButton, idSubsidiary]);
  return {
    uploadModalCalculatedCost,
    uploadButtonLoadCalculatedCost,
  };
};
