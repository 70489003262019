import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { InfoIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/InfoIconButton";
import { ExternalEnhancedRow } from "../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { userSkillTranslationCodeMap } from "../../../../../../../../shared/specific/maps/userSkill/userSkillTranslationCodeMap";
import { UserSkillLevelEnum } from "../../../../../../../../shared/specific/enums/userSkill/UserSkillLevelEnum";
import { InformationLevelViewTabel } from "../components/InformationLevelViewTable";
import { UserLanguageLevelEnum } from "../../../../../../../../shared/specific/enums/userLanguage/UserLanguageLevelEnum";
import { userLanguageTranslationCodeMap } from "../../../../../../../../shared/specific/maps/userLanguage/userLanguageTranslationCodeMap";

export const useLevelInformation = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: InformationLevelButtonContainer,
    contentModal: informationLevelModalHelper,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("collaboratorProfile.modal.languageTitle"),
      ModalContentMemo: () => {
        const { t } = useTranslation();

        const advancedLevel = t(
          "collaboratorProfile.modal.languageLevelDescription.advanced"
        ) as unknown as string[];

        const intermediateLevel = t(
          "collaboratorProfile.modal.languageLevelDescription.intermediate"
        ) as unknown as string[];

        const basicLevel = t(
          "collaboratorProfile.modal.languageLevelDescription.basic"
        ) as unknown as string[];

        const rows: ExternalEnhancedRow[] = [
          {
            id: 0,
            cells: [
              {
                value: t(userSkillTranslationCodeMap[UserSkillLevelEnum.Basic]),
              },
              {
                value: t(
                  "collaboratorProfile.modal.languageLevelDescription.basic"
                ),
                CellRenderer: () => (
                  <ul>
                    {basicLevel.map((x, i) => (
                      <li key={i}>{x}</li>
                    ))}
                  </ul>
                ),
              },
            ],
          },
          {
            id: 1,
            cells: [
              {
                value: t(
                  userSkillTranslationCodeMap[UserSkillLevelEnum.Intermediate]
                ),
              },
              {
                value: t(
                  "collaboratorProfile.modal.skillLevelDescription.intermediate"
                ),
                CellRenderer: () => (
                  <ul>
                    {intermediateLevel.map((x, i) => (
                      <li key={i}>{x}</li>
                    ))}
                  </ul>
                ),
              },
            ],
          },
          {
            id: 2,
            cells: [
              {
                value: t(
                  userLanguageTranslationCodeMap[UserLanguageLevelEnum.Advanced]
                ),
              },
              {
                value: t(
                  "collaboratorProfile.modal.languageLevelDescription.advanced"
                ),
                CellRenderer: () => (
                  <ul>
                    {advancedLevel.map((x, i) => (
                      <li key={i}>{x}</li>
                    ))}
                  </ul>
                ),
              },
            ],
          },
          {
            id: 3,
            cells: [
              {
                value: t(
                  userLanguageTranslationCodeMap[UserLanguageLevelEnum.Native]
                ),
              },
              {
                CellRenderer: () => (
                  <ul>
                    <li>
                      {t(
                        "collaboratorProfile.modal.languageLevelDescription.native"
                      )}
                    </li>
                  </ul>
                ),
              },
            ],
          },
        ];

        return <InformationLevelViewTabel rows={rows} />;
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: InfoIconButton,
    },
  });
  return {
    InformationLevelButtonContainer,
    informationLevelModalHelper,
  };
};
