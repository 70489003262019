import * as yup from "yup";
import { Language } from "../../../../enums/locale/Language.enum";

export const makeTranslatableFieldValuesFormSchema = <
  DefaultLanguageSchema extends yup.AnySchema,
  SecondaryLanguageSchema extends yup.AnySchema,
>({
  defaultLanguage,
  defaultLanguageSchema,
  secondaryLanguagesSchema,
}: {
  defaultLanguage: Language;
  defaultLanguageSchema: DefaultLanguageSchema;
  secondaryLanguagesSchema: SecondaryLanguageSchema;
}) => {
  type ReturnType = {
    [key in Language]: DefaultLanguageSchema | SecondaryLanguageSchema;
  };

  return yup.object<ReturnType>({
    [defaultLanguage]: defaultLanguageSchema,
    ...Object.fromEntries(
      Object.values(Language)
        .filter((x) => x !== defaultLanguage)
        .map((x) => [x, secondaryLanguagesSchema])
    ),
  } as ReturnType);
};
