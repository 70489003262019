import { styled } from "@mui/material";

export const StyledViewContent = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};

  & > * {
    width: 45%;
  }

  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledAreaListLanguages = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledAreaTextFields = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  width: 100%;

  & > * {
    width: 35%;
  }
`;
