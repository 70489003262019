import { FormikProps } from "formik";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { InputAdornment, TextField, Typography } from "@mui/material";
import { CommonAdditivesFormValues } from "../../types/values.types";
import {
  StyledFormAdditiveSection,
  StyledFormProjectSection,
  StyledMainContainerForm,
} from "./index.styles";
import { DatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import {
  fieldsConstants,
  maxLengthFields,
} from "../../../../Projects/ProjectPlanningPage/tabs/AdditivesTab/shared/constants/data/form/fields.constants";
import { generalFieldsConstants } from "../../../../../../../shared/common/constants/data/form/fields.constants";
import { TextFieldFormik } from "../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContext } from "../../contexts/FormContext";
import { YesNoAutocompleteFormik } from "../../../../../../../shared/common/react/components/form/formik/autocomplete/fields/general/YesNoAutocompleteFormik";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { ProjectDTO } from "../../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { getProjectById } from "../../../../../../../services/projects/projects.service";
import { CostCenterPepOnlyDTO } from "../../../../../../../shared/specific/DTOs/costCenterPeps/CostCenterPepOnlyDTO";
import { getMainProjectPepOnly } from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";

export const InternalFormContent = ({
  isSubmitting,
  values,
}: FormikProps<CommonAdditivesFormValues>) => {
  const { project } = values;
  const { t } = useTranslation();
  const [projectData, setProjectData] = useState<ProjectDTO | null>(null);
  const [mainPep, setMainPep] = useState<CostCenterPepOnlyDTO | null>(null);

  useEffect(() => {
    async function fetchProjectData() {
      if (!project) {
        setProjectData(null);
        return;
      }

      try {
        const projectData = await getProjectById(project.id);
        const mainPepData = await getMainProjectPepOnly(project.id);
        setProjectData(projectData);
        setMainPep(mainPepData);
      } catch (error) {
        console.error(error);
        setProjectData(null);
      }
    }
    fetchProjectData();
  }, [values.project]);

  const { dealData, mode } = useContext(FormContext);

  const isReadOnly = mode === "viewing";

  const currencyStartAdornment = useMemo(() => {
    return (
      <InputAdornment position="start">
        {mainPep?.currencySymbol ?? ""}
      </InputAdornment>
    );
  }, [mainPep]);

  const isAntecipationJustificationRequired = useMemo(() => {
    if (
      !values.scheduledStartDate ||
      !isValid(values.scheduledStartDate) ||
      values.scheduledStartDate.getFullYear() < 1900 ||
      !project?.scheduledStartDate
    )
      return false;

    return values.scheduledStartDate < project.scheduledStartDate.toDate();
  }, [values.scheduledStartDate, project?.scheduledStartDate]);

  return (
    <StyledMainContainerForm>
      <Typography variant="h6">
        {t("pipedrive.keywords.general.additiveInformation")}
      </Typography>
      <StyledFormAdditiveSection>
        {dealData?.opportunityId ? (
          <TextField
            label={t("pipedrive.keywords.fields.additiveName")}
            value={dealData.opportunityId}
            aria-readonly
          />
        ) : (
          <div />
        )}
        <DatePickerFormik
          name="scheduledStartDate"
          datePickerProps={{
            label: t("additives.keywords.fields.scheduledStart"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            maxDate: values.scheduledEndDate ?? undefined,
            disabled: isSubmitting,
            readOnly: isReadOnly,
          }}
          textFieldProps={{
            required: true,
          }}
        />
        <DatePickerFormik
          name="scheduledEndDate"
          datePickerProps={{
            label: t("additives.keywords.fields.scheduledEnd"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            minDate: values.scheduledStartDate ?? undefined,
            disabled: isSubmitting,
            readOnly: isReadOnly,
          }}
          textFieldProps={{
            required: true,
            InputProps: {},
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="amount"
          min={-generalFieldsConstants.maxAmountValue}
          max={generalFieldsConstants.maxAmountValue}
          padFractionalZeros
          overwrite="shift"
          textFieldProps={{
            label: t("general.keywords.fields.value"),
            required: true,
            disabled: isSubmitting,
            InputProps: {
              startAdornment: currencyStartAdornment,
              readOnly: isReadOnly,
            },
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="mv"
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("additives.keywords.fields.commercialMv"),
            InputProps: {
              endAdornment: "%",
              readOnly: isReadOnly,
            },
            required: true,
          }}
          disabled={isSubmitting}
          max={fieldsConstants.maxMv}
          min={fieldsConstants.minMv}
        />
        {isReadOnly ? (
          <TextField
            label={t("projects.keywords.fields.approvedCredit")}
            value={values.approvedCredit?.label ?? ""}
            aria-readonly
          />
        ) : (
          <YesNoAutocompleteFormik
            name="approvedCredit"
            textfieldProps={{
              label: t("projects.keywords.fields.approvedCredit"),
              InputProps: {
                readOnly: isReadOnly,
              },
              required: true,
              disabled: isSubmitting || isReadOnly,
            }}
          />
        )}
        <TextFieldFormik
          name="justification"
          label={t("general.keywords.fields.justification")}
          multiline
          rows={3}
          required
          inputProps={{
            maxLength: maxLengthFields.justification,
            readOnly: isReadOnly,
          }}
          disabled={isSubmitting}
        />
        {isAntecipationJustificationRequired ? (
          <TextFieldFormik
            name="anticipationJustification"
            label={t("additives.keywords.fields.anticipationJustification")}
            multiline
            rows={3}
            required
            inputProps={{
              maxLength: maxLengthFields.anticipationJustification,
              readOnly: isReadOnly,
            }}
            disabled={isSubmitting}
          />
        ) : (
          <div />
        )}
      </StyledFormAdditiveSection>

      <Typography variant="h6">
        {t("pipedrive.keywords.general.projectInformation")}
      </Typography>
      <StyledFormProjectSection>
        {isReadOnly ? (
          <TextField
            label={t("projects.keywords.general.project")}
            value={dealData?.idProject ?? ""}
            aria-readonly
          />
        ) : (
          <ProjectSearchAutocompleteFormik
            name="project"
            textfieldProps={{
              required: true,
              disabled: isSubmitting || isReadOnly,
              InputProps: {
                readOnly: isReadOnly,
              },
            }}
            filtersMemo={{
              statusIncludeOnly: [ProjectStatus.Ongoing],
            }}
            projectFromDeal={
              values.project ? undefined : (dealData?.idProject ?? "")
            }
          />
        )}
        <TextField
          label={t("cfops.keywords.general.cfop")}
          value={mainPep?.cfop?.name ?? ""}
          aria-readonly
        />
        <TextField
          label={t("pipedrive.keywords.fields.customerName")}
          value={projectData?.customer.tradingName ?? ""}
          aria-readonly
        />
      </StyledFormProjectSection>
    </StyledMainContainerForm>
  );
};
