import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface CollaboratorManagementDTO {
  id: number;
  name: string;
  contractRole: string | null;
  jobRole: string | null;
  idCurrentHistory: number | null;
  startYearMonthCurrentHistory: YearMonth | null;
}

export const schemaCollaboratorManagementDTO = yup.object({
  id: yup.number().integer().defined(),
  name: yup.string().defined(),
  contractRole: yup.string().nullable(),
  jobRole: yup.string().nullable(),
  idCurrentHistory: yup.number().integer().nullable(),
  startYearMonthCurrentHistory: schemaYearMonth.nullable(),
});
