import { useMemo } from "react";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { AllocationsOfUserDTO } from "../../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { AllocationYearMonthDTO } from "../../../../../../../../../../shared/specific/DTOs/allocation/AllocationYearMonthDTO";
import { useProjectContext } from "../../../../../shared/react/contexts/ProjectContext";
import { UseEditingCellFormContentButtonContentProps } from "../../useEditingCellForm";
import { StyledButtonBase } from "./styles/general.styles";
import { StyledCarouselCell } from "./styles/StyledCarouselCell.styles";
import { StyledReadOnlyCarouselCell } from "./styles/StyledReadOnlyCarouselCell.styles";

interface OwnProps {
  allocation: AllocationYearMonthDTO | null;
  allocationsUser: AllocationsOfUserDTO;
  EditingButtonContainer: (
    props: UseEditingCellFormContentButtonContentProps
  ) => JSX.Element | null;
  yearMonth: YearMonth | null;
}

export const CarouselContent = ({
  allocation,
  allocationsUser,
  EditingButtonContainer,
  yearMonth,
}: OwnProps) => {
  const { accessMode } = useProjectContext();

  const allocationCost = useMemo(() => {
    if (!yearMonth) return null;

    return formatNumber(allocation?.allocationCost ?? 0, {
      fractionDigits: 2,
    });
  }, [allocation?.allocationCost]);

  const percentage = useMemo(() => {
    if (!yearMonth) return null;

    return formatNumber(allocation?.percentage ?? 0, {
      fractionDigits: 2,
      suffix: "%",
    });
  }, [allocation?.percentage]);

  const isAllocatedResignatted = useMemo(() => {
    return Boolean(
      allocation &&
        yearMonth &&
        allocationsUser?.resignationDate &&
        allocation.percentage > 0 &&
        yearMonth > YearMonth.createFromDate(allocationsUser?.resignationDate)
    );
  }, [yearMonth, allocationsUser?.resignationDate, allocation?.percentage]);

  const isAllocatedWithoutTrack = useMemo(() => {
    return Boolean(
      allocation && allocation.percentage > 0 && allocation.allocationCost === 0
    );
  }, [allocation?.percentage, allocation?.allocationCost]);

  if (!yearMonth) return null;

  if (accessMode === "reading")
    return (
      <StyledReadOnlyCarouselCell
        isWarning={isAllocatedWithoutTrack || isAllocatedResignatted}
      >
        <>
          <div>{allocationCost}</div>
          <div>{percentage}</div>
        </>
      </StyledReadOnlyCarouselCell>
    );

  return (
    <EditingButtonContainer
      allocationPerUserRow={allocationsUser}
      yearMonth={yearMonth}
    >
      <StyledButtonBase>
        <StyledCarouselCell
          isWarning={isAllocatedWithoutTrack || isAllocatedResignatted}
        >
          <div>{allocationCost}</div>
          <div>{percentage}</div>
        </StyledCarouselCell>
      </StyledButtonBase>
    </EditingButtonContainer>
  );
};
