import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useEffect, useMemo, useRef } from "react";
import { CostCenterFiltersDTO } from "../../../../../../../shared/specific/DTOs/costCenter/filters/CostCenterFiltersDTO";
import { CostCentersFilterForm } from "./CostCentersFilterForm";
import { FilterValues } from "./shared/types/filter.types";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { convertTo } from "../../../../../../../shared/common/helpers/types/converters.helpers";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      name: "",
      code: "",
      division: null,
      activeOnly: true,
    };

    const validationSchema = yup.object({
      name: yup.string(),
      code: yup.string(),
      division: yup.object().nullable(),
      activeOnly: yup.boolean().defined(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<CostCenterFiltersDTO>({
          code: formValues.code || undefined,
          name: formValues.name || undefined,
          idDivision: formValues.division?.id,
          isDeactivated: formValues.activeOnly ? false : undefined,
        }),
      FormContentMemo: CostCentersFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
