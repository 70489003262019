import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { Close as CloseIcon, Add as AddIcon } from "@mui/icons-material";
import { FormikProps } from "formik";
import {
  StyledFormSection,
  StyledInputSelectCompetence,
  StyledLabelChip,
  StyledGrayBox,
} from "../index.styles";
import {
  CommonInvoiceRequestFormValues,
  FormMode,
} from "../../../../../types/data/form/values.types";
import { InvoiceDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { YearMonthDatePickerFormik } from "../../../../../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { YearMonth } from "../../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  formikProps: FormikProps<CommonInvoiceRequestFormValues>;
  invoice?: InvoiceDTO;
  formMode: FormMode;
}

export const CompetenceComonent = ({
  formikProps,
  invoice,
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  const { values, setFieldValue } = formikProps;

  const generalReadOnly = formMode === "viewing";

  return (
    <StyledFormSection>
      <Typography variant="h6" component="div">
        {t("invoice.keywords.fields.competence")}
      </Typography>
      <StyledInputSelectCompetence>
        {!generalReadOnly && (
          <>
            <YearMonthDatePickerFormik
              name="selectedCompetence"
              datePickerProps={{
                label: t("invoice.keywords.fields.competence"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{
                required: true,
              }}
            />
            <Button
              disabled={!values.selectedCompetence}
              onClick={() => {
                if (values.selectedCompetence) {
                  const alreadyExists = values.invoiceCompetence.some(
                    (x) =>
                      x.getFullYear() ===
                        values.selectedCompetence?.getFullYear() &&
                      x.getMonth() === values.selectedCompetence.getMonth()
                  );
                  if (!alreadyExists)
                    setFieldValue("invoiceCompetence", [
                      ...values.invoiceCompetence,
                      values.selectedCompetence,
                    ]);
                }
                setFieldValue("selectedCompetence", null);
              }}
              variant="contained"
            >
              <AddIcon />
            </Button>
          </>
        )}
        <div>
          {values.invoiceCompetence.map((x, i) => {
            const createYm = YearMonth.createFromDate(x);
            const yM = `${createYm.month + 1}/${createYm.year}`;
            return (
              <StyledGrayBox
                key={i}
                size="medium"
                label={
                  <StyledLabelChip>
                    {yM}
                    {!generalReadOnly && (
                      <CloseIcon
                        fontSize="small"
                        onClick={() => {
                          const newArray = values.invoiceCompetence.filter(
                            (competence) => {
                              const yearMonthCompetence =
                                YearMonth.createFromDate(competence);

                              return (
                                yearMonthCompetence.year !== createYm.year ||
                                yearMonthCompetence.month !== createYm.month
                              );
                            }
                          );

                          setFieldValue("invoiceCompetence", newArray);
                        }}
                      />
                    )}
                  </StyledLabelChip>
                }
              />
            );
          })}
        </div>
      </StyledInputSelectCompetence>
    </StyledFormSection>
  );
};
