import { Add as AddIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../types/core/typescript.types";
import { SimpleIconButton, SimpleIconButtonProps } from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const AddIconButton = ({
  tooltipText = "general.actions.general.add",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton Icon={AddIcon} tooltipText={t(tooltipText)} {...rest} />
  );
};
