import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonFeeForm } from "../shared/react/components/CommonFeeForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";
import { FeeDTO } from "../../../../../../../shared/specific/DTOs/fee/FeeDTO";
import {
  getFeeyId,
  updateFee,
} from "../../../../../../../services/fee/fee.service";
import { FeeUpdateDTO } from "../../../../../../../shared/specific/DTOs/fee/FeeUpdateDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, FeeDTO, FeeDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: fee }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: fee?.description ?? "-",
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: fee,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const feeData = await getFeeyId(fee.id);

            if (!feeData)
              return setModalError(
                t("disciplines.errors.data.general.disciplineDoesNotExist")
              );

            setInternalFormData(feeData);
            setFormValues({
              description: feeData.description,
              costCenter: feeData.costCenter,
              accountingAccountCredit: feeData.accountingAccountCredit,
              accountingAccountDebit: feeData.accountingAccountDebit,
              deactivatedDate: null,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage: "fee.errors.data.fetch.failedToFetchFee",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonFeeForm {...rest} mode="editing" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedFee: FeeUpdateDTO = {
              id: internalFormData?.id ?? 0,
              version: internalFormData?.version ?? "",
              description: formValues.description,
              idCostCenter: formValues.costCenter?.id ?? 0,
              idSubsidiary: formValues.costCenter?.subsidiary?.id ?? 0,
              accountingAccountCredit: formValues.accountingAccountCredit,
              accountingAccountDebit: formValues.accountingAccountDebit,
              deactivatedDate: formValues.deactivatedDate,
            };

            await updateFee(updatedFee);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "fee.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
