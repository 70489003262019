import { Outlet } from "react-router-dom";
import { RestrictionsOperand } from "../../../../../shared/specific/classes/user/permissions/permissionsOperators/AbstractPermissionsOperator";
import { ProtectedRoute } from "./ProtectedRoute";

interface ProtectedRouteContainerProps {
  restrictions: RestrictionsOperand;
}

export const ProjectedRouteContainer = ({
  restrictions,
}: ProtectedRouteContainerProps) => {
  return (
    <ProtectedRoute restrictions={restrictions}>
      <Outlet />
    </ProtectedRoute>
  );
};
