import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { CreationFormValue } from "../../shared/types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): CreationFormValue => {
    return {
      project: null,
      costCenterPep: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      project: yup
        .object()
        .nullable()
        .required("general.errors.data.fields.general.required"),
      costCenterPep: yup
        .object()
        .nullable()
        .required("general.errors.data.fields.general.required"),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
