import { TextFieldProps, Tooltip } from "@mui/material";
import { useField } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getSubsidiaries } from "../../../../../../../../../services/subsidiaries/subsidiaries.service";
import { CachedTranslatedData } from "../../../../../../../../common/classes/data/cache/CachedTranslatedData";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { SubsidiaryDTO } from "../../../../../../../DTOs/subsidiary/SubsidiaryDTO";
import { AutocompleteInfoBubble } from "../../../../general/accessories/AutocompleteInfoBubble";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    SubsidiaryDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  shouldDisplayCurrencyAdornment?: boolean;
}

const cachedSubsidiaries = new CachedTranslatedData({
  callback: getSubsidiaries,
  defaultCacheDuration: 600000, // 10 minutes
});

export const SubsidiariesAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  shouldDisplayCurrencyAdornment = true,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const [{ value: subsidiary }] = useField<SubsidiaryDTO | null>(name);
  const { t, i18n } = useTranslation();

  const endAdornment = useMemo(() => {
    if (!subsidiary?.currency || !shouldDisplayCurrencyAdornment)
      return undefined;
    return (
      <Tooltip title={subsidiary.currency} placement="top">
        <AutocompleteInfoBubble>{subsidiary.currency}</AutocompleteInfoBubble>
      </Tooltip>
    );
  }, [subsidiary]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "subsidiaries.errors.data.fetch.failedToFetchSubsidiaries"
      )}
      fetchOptionsMemo={cachedSubsidiaries.run}
      textfieldProps={{
        label: t("subsidiaries.keywords.general.subsidiary"),
        ...textfieldProps,
        InputProps: {
          ...textfieldProps?.InputProps,
          endAdornment,
        },
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) => x.name ?? String(x.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
