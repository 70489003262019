import * as yup from "yup";
import {
  ExpenseType,
  schemaExpenseType,
} from "../../../enums/expenses/ExpenseType";
import {
  ExpenseAmountMonthDTO,
  schemaExpenseAmountMonthDTO,
} from "../../expenses/ExpenseAmountMonthDTO";

export interface ProjectSummaryExpenseAmounts {
  expenseType: ExpenseType;
  expenses: ExpenseAmountMonthDTO[];
}

export const schemaProjectSummaryExpenseAmountsDTO = yup.object({
  expenseType: schemaExpenseType.defined(),
  expenses: yup.array(schemaExpenseAmountMonthDTO).defined(),
});
