import { styled } from "@mui/material";

export const StyledAutocompleteInfoBubble = styled("div")`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: ${({ theme }) => `${theme.spacing(0.3)} ${theme.spacing(0.6)}`};
  width: max-content;
  border-radius: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
