import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useCallback } from "react";
import {
  AsyncAutocompleteFormik,
  AsyncFastAutocompleteFormikProps,
} from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { ExpenseTypeDTO } from "../../../../../../../DTOs/ExpenseType/ExpenseTypeDTO";
import { getExpenseTypes } from "../../../../../../../../../services/expenseType/expenseType.service";
import { ExpenseTypeFilterDTO } from "../../../../../../../DTOs/ExpenseType/filters/ExpenseTypeFilterDTO";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    ExpenseTypeDTO | null | undefined,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: ExpenseTypeFilterDTO;
  filtersMemo?: ExpenseTypeFilterDTO;
}

export const ExpenseTypesAutocompleteFormil = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters?.idExpenseGroup]);

  const internalGetExpenseTypes = useCallback(async () => {
    return getExpenseTypes({
      filters: finalFilters,
    });
  }, [finalFilters?.idExpenseGroup]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "projection.errors.data.fetch.failedToFetchExpenseTypes"
      )}
      fetchOptions={internalGetExpenseTypes}
      textfieldProps={{
        label: t("projection.keywords.fields.expenseTypes"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          x?.expenseTypeTranslation.find((y) => y.idLanguage === i18n.language)
            ?.name ??
          x?.typeName ??
          "",
        isOptionEqualToValueMemo: (option, value) => option?.id === value?.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
