import * as yup from "yup";
import {
  DateOnly,
  schemaDateOnly,
} from "../../../common/classes/data/date/DateOnly";
import {
  CostCenterPepOnlyDTO,
  schemaCostCenterPepOnlyDTO,
} from "../costCenterPeps/CostCenterPepOnlyDTO";
import { CustomerDTO, schemaCustomerDTO } from "../customers/CustomerDTO";
import { InvoicesStatusEnum } from "../../enums/invoices/InvoicesStatusEnum";
import {
  UserCollaboratorOnlyDTO,
  schemaUserCollaboratorOnlyDTO,
} from "../user/UserCollaboratorOnlyDTO";
import {
  InvoiceCopyToUserDTO,
  schemaInvoiceCopyToUserDTO,
} from "../InvoiceCopyToUserDTO/InvoiceCopyToUserDTO";
import {
  InvoiceCompetenceDTO,
  schemaInvoiceCompetenceDTO,
} from "../InvoiceCompetence/InvoiceCompetenceDTO";
import { InvoiceTypeEnum } from "../../enums/invoices/InvoiceTypeEnum";
import {
  InvoiceFinancialResponsibleDTO,
  schemaInvoiceFinancialResponsibleDTO,
} from "../InvoiceFinancialResponsible/InvoiceFinancialResponsibleDTO";
import {
  InvoiceNegativeDTO,
  schemaInvoiceNegativeDTO,
} from "./InvoiceNegativeDTO";
import { InvoiceOnlyDTO, schemaInvoiceOnlyDTO } from "./InvoiceOnlyDTO";
import {
  InvoiceFilesDTO,
  schemaInvoiceFilesDTO,
} from "../InvoiceFiles/InvoiceFilesDTO";

export interface InvoiceDTO {
  id: number;
  idInvoiceGroup: number | null;
  milestone: string;
  projectCustomer: CustomerDTO;
  billingAmount: number;
  plannedBillingDate: DateOnly;
  issueDate: DateOnly | null;
  baselineDate: Date | null;
  invoiceNumber: string | null;
  invoiceNote: string | null;
  reasonForReturn: string | null;
  reasonForCancellation: string | null;
  delayJustification: string | null;
  costCenterPep: CostCenterPepOnlyDTO;
  costCenterPepToInvoice: CostCenterPepOnlyDTO;
  customer: CustomerDTO | null;
  status: InvoicesStatusEnum;
  responsibleLogin: UserCollaboratorOnlyDTO | null;
  requesterLogin: UserCollaboratorOnlyDTO | null;
  requestDate: DateOnly | null;
  limitDate: DateOnly | null;
  plannedCancellationDate: DateOnly | null;
  cancellationDate: DateOnly | null;
  dueDate: DateOnly | null;
  paymentDate: DateOnly | null;
  pddDate: DateOnly | null;
  lossDate: DateOnly | null;
  invoiceCopyToUser: InvoiceCopyToUserDTO[];
  invoiceCompetence: InvoiceCompetenceDTO[];
  invoiceType: InvoiceTypeEnum | null;
  invoiceFinancialResponsible: InvoiceFinancialResponsibleDTO[];
  serviceDescription: string | null;
  launchInstructions: string | null;
  liquidAmountToCancel: number | null;
  negativeInvoicesCancellationReference: InvoiceNegativeDTO[];
  invoiceGroup: InvoiceOnlyDTO[];
  cfopName: string | null;
  coodinatorName: string | null;
  managerName: string | null;
  invoiceFiles: InvoiceFilesDTO[];
  wasEdited: boolean;
  version: string;
}

export const schemaInvoiceDTO = yup.object({
  id: yup.number().integer().defined(),
  idInvoiceGroup: yup.number().integer().defined().nullable(),
  milestone: yup.string().defined(),
  billingAmount: yup.number().defined(),
  plannedBillingDate: schemaDateOnly.defined(),
  issueDate: schemaDateOnly.nullable().defined(),
  baselineDate: yup.date().nullable().defined(),
  invoiceNumber: yup.string().nullable().defined(),
  invoiceNote: yup.string().nullable().defined(),
  reasonForReturn: yup.string().nullable().defined(),
  reasonForCancellation: yup.string().nullable().defined(),
  delayJustification: yup.string().nullable().defined(),
  costCenterPep: schemaCostCenterPepOnlyDTO.defined(),
  costCenterPepToInvoice: schemaCostCenterPepOnlyDTO.defined(),
  customer: schemaCustomerDTO.nullable().defined(),
  projectCustomer: schemaCustomerDTO.defined(),
  status: yup.number().integer().defined(),
  responsibleLogin: schemaUserCollaboratorOnlyDTO.nullable().defined(),
  requesterLogin: schemaUserCollaboratorOnlyDTO.nullable().defined(),
  requestDate: schemaDateOnly.nullable().defined(),
  limitDate: schemaDateOnly.nullable().defined(),
  plannedCancellationDate: schemaDateOnly.nullable().defined(),
  cancellationDate: schemaDateOnly.nullable().defined(),
  dueDate: schemaDateOnly.nullable().defined(),
  paymentDate: schemaDateOnly.nullable().defined(),
  pddDate: schemaDateOnly.nullable().defined(),
  lossDate: schemaDateOnly.nullable().defined(),
  invoiceCopyToUser: yup.array(schemaInvoiceCopyToUserDTO).defined(),
  invoiceCompetence: yup.array(schemaInvoiceCompetenceDTO).defined(),
  invoiceType: yup.number().integer().nullable().defined(),
  invoiceFinancialResponsible: yup
    .array(schemaInvoiceFinancialResponsibleDTO)
    .defined(),
  serviceDescription: yup.string().nullable().defined(),
  launchInstructions: yup.string().nullable().defined(),
  liquidAmountToCancel: yup.number().nullable().defined(),
  negativeInvoicesCancellationReference: yup
    .array(schemaInvoiceNegativeDTO)
    .defined(),
  invoiceGroup: yup.array(schemaInvoiceOnlyDTO).defined(),
  cfopName: yup.string().nullable().defined(),
  coodinatorName: yup.string().nullable().defined(),
  managerName: yup.string().nullable().defined(),
  invoiceFiles: yup.array(schemaInvoiceFilesDTO).defined(),
  wasEdited: yup.boolean().defined(),
  version: yup.string().defined(),
});
