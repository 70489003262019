import { formatNumber } from "../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CostDistributionDTO } from "../../../../../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionDTO";
import { ExpenseType } from "../../../../../../../../../../shared/specific/enums/expenses/ExpenseType";
import { StyledCellValueMainContainer } from "./index.styles";

interface OwnProps {
  costDistribution?: CostDistributionDTO;
  expenseType?: ExpenseType;
}

export const CostDistributionCellValue = ({
  costDistribution,
  expenseType,
}: OwnProps) => {
  const brCost = costDistribution
    ? costDistribution.costDistributionPercentages.find(
        (x) => x.expenseType === expenseType && x.originCost === "RDX.BR"
      )
    : null;

  const usCost = costDistribution
    ? costDistribution.costDistributionPercentages.find(
        (x) => x.expenseType === expenseType && x.originCost === "RDX.US"
      )
    : null;

  return (
    <StyledCellValueMainContainer>
      <div>
        <span>
          {expenseType
            ? ((usCost?.percentage &&
                formatNumber(usCost?.percentage, { fractionDigits: 2 })) ??
              "0,00")
            : "US"}
        </span>
      </div>
      <div>
        <span>
          {expenseType
            ? ((brCost?.percentage &&
                formatNumber(brCost?.percentage, { fractionDigits: 2 })) ??
              "0,00")
            : "BR"}
        </span>
      </div>
    </StyledCellValueMainContainer>
  );
};
