import { Checkbox, CircularProgress } from "@mui/material";
import { useFormikContext } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";
import { useEffectAfterRenders } from "../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { FormValues } from "../../shared/types/data/form/values.types";
import { useUserCollaboratorValidator } from "./hooks/useUserCollaboratorValidator";
import { StyledSpinner } from "./index.styles";

interface OwnProps {
  isDisabledCostCenter: boolean;
}

export const ValidatedUsersSearchAutocompleteFormik = ({
  isDisabledCostCenter,
}: OwnProps) => {
  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } =
    useFormikContext<FormValues>();
  const { isValidating } = useUserCollaboratorValidator();
  const [onlyResignatedLessThanOneMonth, setOnlyResignatedLessThanOneMonth] =
    useState(false);

  const isUsersAutocompleteDisabled = useMemo(() => {
    if (isSubmitting) return true;

    if (!values.periodStart || !values.periodEnd || !values.allocationType)
      return true;

    if (!values.costCenter && !isDisabledCostCenter) return true;

    return false;
  }, [
    values.costCenter,
    values.periodStart,
    values.periodEnd,
    values.allocationType,
    isDisabledCostCenter,
    isSubmitting,
  ]);

  const loadingAdornment = useMemo(() => {
    if (!isValidating) return null;

    return (
      <StyledSpinner>
        <CircularProgress color="inherit" size={16} />
      </StyledSpinner>
    );
  }, [isValidating]);

  useEffectAfterRenders({
    effect: () => {
      if (values.userCollaborators.length === 0) return;
      setFieldValue("userCollaborators", []);
    },
    deps: [
      values.costCenter,
      values.periodStart,
      values.periodEnd,
      values.allocationType,
    ],
    rendersBeforeEffect: 1,
  });

  return (
    <div>
      <UsersSearchAutocompleteFormik
        name="userCollaborators"
        autocompleteProps={{
          disabled: isUsersAutocompleteDisabled,
          multiple: true,
        }}
        textfieldProps={{
          InputProps: {
            endAdornment: loadingAdornment,
          },
          required: true,
        }}
        filters={{
          isActive: !onlyResignatedLessThanOneMonth,
          onlyResignatedLessThanOneMonth,
          isCollaborator: true,
        }}
      />
      <div>
        <Checkbox
          checked={onlyResignatedLessThanOneMonth ?? false}
          onChange={() =>
            setOnlyResignatedLessThanOneMonth(!onlyResignatedLessThanOneMonth)
          }
        />
        <span>
          {t("allocations.actions.data.onlyResignatedLessThanOneMonth")}
        </span>
      </div>
      <LoadingBackdrop open={isValidating} />
    </div>
  );
};
