import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import {
  getDisciplineById,
  updateDiscipline,
} from "../../../../../../../services/disciplines/disciplines.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import { DisciplineDTO } from "../../../../../../../shared/specific/DTOs/discipline/DisciplineDTO";
import { DisciplineUpdateDTO } from "../../../../../../../shared/specific/DTOs/discipline/DisciplineUpdateDTO";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CommonDisciplineForm } from "../shared/react/components/CommonDisciplineForm";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, DisciplineDTO, DisciplineDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: discipline }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: discipline?.name ?? "-",
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: discipline,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const disciplineData = await getDisciplineById(discipline.id);

            if (!disciplineData)
              return setModalError(
                t("disciplines.errors.data.general.disciplineDoesNotExist")
              );

            setInternalFormData(discipline);
            setFormValues({
              name: disciplineData.name,
              deactivatedDate: disciplineData.deactivatedDate,
              horizontal: disciplineData.horizontal,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "disciplines.errors.data.fetch.failedToFetchDiscipline",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonDisciplineForm {...rest} mode="editing" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedDiscipline: DisciplineUpdateDTO = {
              id: internalFormData?.id ?? 0,
              name: formValues.name,
              deactivatedDate: formValues.deactivatedDate,
              idHorizontal: formValues.horizontal?.id ?? 0,
              version: internalFormData?.version ?? "",
            };

            await updateDiscipline(updatedDiscipline);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "disciplines.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
