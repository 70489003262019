import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckboxFormik } from "../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/data/form/values.types";
import { StyledForm } from "./index.style";
import { YearMonthDatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { CostCentersSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormikWithoutPermissionType";
import { CustomerCodesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomerCodesSearchAutocompleteFormik";
import { ProjectSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormikWithoutPermissionType";
import { JobRolesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/jobRoles/JobRoleSearchAutocompleteFormik";
import { ContractRolesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/contractRoles/ContractRolesAutocompleteFormik";
import { ManagerSearchAutoCompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/ManagerSearchAutoCompleteFormik";
import { FramesSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/frames/FramesSearchAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const UserCollaboratorFilterForm = (props: OwnProps) => {
  const { t } = useTranslation();

  const {
    formikProps: { values },
  } = props;

  return (
    <StyledForm>
      {/* <TextFieldFormik
        name="login"
        label={t("usersCollaborators.keywords.fields.login")}
      />

      <DatePickerFormik
        name="admissionDateStart"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.admissionDateStart"),
          slotProps: {
                popper: {
                  placement: "left",
                },
              },
        }}
      />
      <DatePickerFormik
        name="admissionDateEnd"
        datePickerProps={{
          label: t("usersCollaborators.keywords.fields.admissionDateEnd"),
          slotProps: {
                popper: {
                  placement: "right",
                },
              },
        }}
      /> */}

      <YearMonthDatePickerFormik
        name="startDate"
        datePickerProps={{
          label: t("general.keywords.fields.startDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.endDate || undefined,
        }}
      />
      <YearMonthDatePickerFormik
        name="endDate"
        datePickerProps={{
          label: t("general.keywords.fields.endDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          minDate: values.startDate,
        }}
      />

      <UsersSearchAutocompleteFormik
        name="coordinator"
        textfieldProps={{
          label: `${t("projects.keywords.fields.coordinator")}`,
        }}
        filtersMemo={{
          isActive: true,
        }}
      />

      <ManagerSearchAutoCompleteFormik
        name="manager"
        textfieldProps={{
          label: `${t("projects.keywords.fields.manager")}`,
        }}
        filtersMemo={{
          isActive: true,
        }}
      />

      <CostCentersSearchAutocompleteFormikWithoutPermissionType
        name="costCenter"
        textfieldProps={{ required: true }}
      />

      <CustomerCodesSearchAutocompleteFormik
        name="customerCode"
        textfieldProps={{
          label: `${t(
            "customers.keywords.fields.projectPrefixCustomerGroup"
          )} (*)`,
        }}
      />

      <ProjectSearchAutocompleteFormikWithoutPermissionType name="project" />

      <FramesSearchAutocompleteFormik name="frame" />

      <JobRolesSearchAutocompleteFormik
        name="jobRole"
        shouldReplaceValueOnMount
        onlyActivated={false}
      />
      <ContractRolesAutocompleteFormik
        name="contractRole"
        shouldReplaceValueOnMount
      />
      <FormControlLabel
        label={t("general.keywords.fields.onlyActivated")}
        control={<CheckboxFormik name="onlyActivated" />}
      />
    </StyledForm>
  );
};
