import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { findBinarySearch } from "../../../../../../../../../shared/common/helpers/data/arrays/finders.helpers";
import { FormattedCurrency } from "../../../../../../../../../shared/common/react/components/data/formatters/numbers/FormattedCurrency";
import { CarouselCell } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselCell";
import { CarouselHead } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselHead";
import { CarouselLeftButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselLeftButton";
import { CarouselRightButton } from "../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselRightButton";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import {
  SummarySection,
  SummarySectionTitle,
} from "../../shared/react/styles/ui/summarySection.styles";
import { SummaryContext } from "../../shared/react/contexts/SummaryContext";
import { RowData } from "../../shared/types/data/carousel.types";
import { SummaryCarouselContext } from "../../shared/react/contexts/SummaryCarouselContext";
import {
  StyledCenteredInfo,
  StyledRowHeader,
} from "../../shared/react/styles/table/summaryTable.styles";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";

const CAROUSEL_SIZE = 8;

interface OwnProps {
  positionStart: number;
}

export const SummaryProjectSummaryWithinContext = ({
  positionStart,
}: OwnProps) => {
  const { t } = useTranslation();

  const { summaryData } = useContext(SummaryContext);
  const { startCarousel, carouselRealSize, totalYearMonths } = useContext(
    SummaryCarouselContext
  );

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: "",
        headColumn: true,
        canSort: false,
        minWidth: 300,
        width: "10%",
      },
    ];

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselLeftButton />,
      canSort: false,
    });

    for (let i = 0; i < carouselRealSize; i++) {
      headCells.push({
        value: "",
        canSort: false,
        HeadRenderer: () => <CarouselHead columnIndex={i} />,
      });
    }
    for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
      headCells.push({ value: "", canSort: false });
    }

    headCells.push({
      value: "",
      HeadRenderer: () => <CarouselRightButton />,
      align: "right",
      canSort: false,
    });

    return headCells;
  }, [t, carouselRealSize]);

  const rowsHeaders = useMemo((): RowData[] | null => {
    if (!summaryData?.monthlyAmounts) return null;

    return [
      {
        name: "planningSummary.keywords.fields.grossRevenue",
        items: summaryData.monthlyAmounts.grossRevenues,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.performedGrossRevenue",
        items: summaryData.monthlyAmounts.performedGrossRevenues,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.liquidRevenue",
        items: summaryData.monthlyAmounts.liquidRevenues,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.performedLiquidRevenue",
        items: summaryData.monthlyAmounts.performedLiquidRevenues,
        isAccumulated: false,
      },
      {
        name: "planningSummary.keywords.fields.accumulatedLiquidRevenue",
        items: summaryData.monthlyAmounts.accumulatedLiquidRevenue,
        isAccumulated: true,
      },
      {
        name: "planningSummary.keywords.fields.performedAccumulatedLiquidRevenue",
        items: summaryData.monthlyAmounts.accumulatedPerformedLiquidRevenue,
        isAccumulated: true,
      },
    ];
  }, [summaryData?.monthlyAmounts]);

  const rows = useMemo(() => {
    if (!rowsHeaders) return null;
    if (!totalYearMonths) return [];

    startCarousel({
      carouselSize: CAROUSEL_SIZE,
      monthsOffsetFirstYearMonth: positionStart,
    });

    return rowsHeaders.map(({ name, items, isAccumulated }, index) => {
      const row: ExternalEnhancedRow = {
        id: index,
        cells: [
          {
            value: t(name),
            CellRenderer: () => <StyledRowHeader>{t(name)}</StyledRowHeader>,
          },
        ],
      };

      row.cells.push({});

      for (let i = 0; i < carouselRealSize; i++) {
        row.cells.push({
          paddingmode: "none",
          CellRenderer: () => (
            <CarouselCell
              items={items}
              columnIndex={i}
              findCurrentItem={(items, yearMonth) => {
                return findBinarySearch({
                  sortedArray: items,
                  onNotFound: isAccumulated ? "before" : "null",
                  checkDesiredValuePosition: (item) =>
                    yearMonth.differenceMonths(item.yearMonth),
                });
              }}
              CarouselComponentMemo={({ item, yearMonth }) => {
                const dateNow = new Date();
                const yearMonthNow = new YearMonth(
                  dateNow.getMonth(),
                  dateNow.getFullYear()
                ).toDate();
                return (
                  <StyledCenteredInfo
                    isPast={
                      yearMonth ? yearMonthNow > yearMonth.toDate() : true
                    }
                  >
                    <FormattedCurrency
                      value={item?.amount ?? 0}
                      currencySymbol={
                        summaryData?.header.subsidiary.currencySymbol ?? ""
                      }
                    />
                  </StyledCenteredInfo>
                );
              }}
            />
          ),
        });
      }
      for (let i = carouselRealSize; i < CAROUSEL_SIZE; i++) {
        row.cells.push({});
      }

      row.cells.push({});

      return row;
    });
  }, [
    t,
    rowsHeaders,
    summaryData?.header.subsidiary.currencySymbol,
    carouselRealSize,
    totalYearMonths,
  ]);

  if (!rows) return null;

  return (
    <SummarySection>
      <SummarySectionTitle>
        {t("planningSummary.keywords.sections.projectSummary")}
      </SummarySectionTitle>
      <EnhancedTable
        headCells={headCells}
        rows={rows}
        uiSettings={{
          stickyHeader: true,
          maxTableHeight: 300,
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
      />
    </SummarySection>
  );
};
