import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useCallback } from "react";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { parseLocaleNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../shared/react/components/content/CreationEditingForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { ExpenseTypeCostValue, FormValues } from "../shared/types/form.types";
import { CostDistributionDTO } from "../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionDTO";
import {
  getCostDistributionOnly,
  updateCostDistribution,
} from "../../../../../../services/costDistribution/costDistribution.service";
import { ExpenseType } from "../../../../../../shared/specific/enums/expenses/ExpenseType";
import { CostDistributionPercentageDTO } from "../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionPercentageDTO";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CostDistributionUpdateDTO } from "../../../../../../shared/specific/DTOs/CostDistribution/CostDistributionUpdateDTO";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const formikConfig = useFormikConfig();
  const { t } = useTranslation();

  const generateExpenseType = useCallback(
    (
      expenseType: ExpenseType,
      originCost: string,
      listOfCosts: CostDistributionPercentageDTO[]
    ): ExpenseTypeCostValue => {
      const cost = listOfCosts.find(
        (cost) =>
          cost.expenseType === expenseType && cost.originCost === originCost
      );

      return {
        expenseType,
        percentage: cost?.percentage
          ? formatNumber(cost.percentage, { fractionDigits: 2 })
          : "",
        id: cost?.id ?? undefined,
        version: cost?.version ?? undefined,
      };
    },
    []
  );

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, CostDistributionDTO, CostDistributionDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: costDistribution }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: `${
                  costDistribution?.customerTrigram
                    ? `${costDistribution.customerTrigram} - `
                    : `${t("costDistribution.keywords.fields.newCustomers")} - `
                }${
                  costDistribution?.segmentGroup.description
                    ? `${costDistribution?.segmentGroup.description}`
                    : ""
                }`,
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: costDistribution,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const costDistributionData = await getCostDistributionOnly({
              customerTrigram: costDistribution.customerTrigram,
              idSegmentGroup: costDistribution.segmentGroup.id,
              idSubsidiary: costDistribution.subsidiary.id,
            });

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!costDistributionData)
              return setModalError(
                i18next.t(
                  "costDistribution.erros.data.general.costDistributionDoesNotExists"
                )
              );

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(costDistributionData);

            const formValues: FormValues = {
              customerTrigram: costDistributionData?.customerTrigram || null,
              segmentGroup: costDistributionData.segmentGroup || null,
              subsidiary: costDistributionData.subsidiary || null,
              costBr: [
                generateExpenseType(
                  ExpenseType.Workforce,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.SoftwareHardware,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Travels,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Infrastructure,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.ResellingProducts,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Training,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.ConsultantServices,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Refund,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Other,
                  "RDX.BR",
                  costDistributionData.costDistributionPercentages
                ),
              ],
              costUs: [
                generateExpenseType(
                  ExpenseType.Workforce,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.SoftwareHardware,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Travels,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Infrastructure,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.ResellingProducts,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Training,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.ConsultantServices,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Refund,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
                generateExpenseType(
                  ExpenseType.Other,
                  "RDX.US",
                  costDistributionData.costDistributionPercentages
                ),
              ],
              isNewClient: !costDistributionData?.customerTrigram,
              total: "",
            };
            setFormValues(formValues);
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "costDistribution.erros.data.general.costDistributionDoesNotExist",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: (formikProps) => (
          <CreationEditingForm formikProps={formikProps} formMode="edit" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const costsUsToSave: CostDistributionUpdateDTO[] = formValues.costUs
              .filter((x) => parseLocaleNumber(x.percentage) > 0)
              .map((x) => ({
                id: x.id ?? null,
                expenseType: x.expenseType,
                percentage: parseLocaleNumber(x.percentage),
                customerTrigram:
                  formValues.customerTrigram != null
                    ? formValues.customerTrigram
                    : null,
                idSegmentGroup: formValues.segmentGroup?.id ?? 0,
                idSubsidiary: formValues.subsidiary?.id ?? 0,
                originCost: "RDX.US",
                version: x?.version || undefined,
              }));

            const costsBrToSave: CostDistributionUpdateDTO[] = formValues.costBr
              .filter((x) => parseLocaleNumber(x.percentage) > 0)
              .map((x) => ({
                id: x.id ?? null,
                expenseType: x.expenseType,
                percentage: parseLocaleNumber(x.percentage),
                customerTrigram:
                  formValues.customerTrigram != null
                    ? formValues.customerTrigram
                    : null,
                idSegmentGroup: formValues.segmentGroup?.id ?? 0,
                idSubsidiary: formValues.subsidiary?.id ?? 0,
                originCost: "RDX.BR",
                version: x?.version || undefined,
              }));

            await updateCostDistribution([...costsUsToSave, ...costsBrToSave]);

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "general.success.data.general.failedToEditCostDistribution",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
