import { Info as InfoIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BlockMath } from "react-katex";
import { useModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import "katex/dist/katex.min.css";

export const useViewingCommercialMvModal = () => {
  const { t } = useTranslation();

  const {
    contentButton: viewingCommercialMvButton,
    contentModal: viewingCommercialMvModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("planningSummary.keywords.fields.commercialMv.name"),
      ModalContent: () => (
        <div>
          <ul>
            <li>
              {t("planningSummary.keywords.fields.commercialMv.description")}
            </li>
            <li>
              {t("planningSummary.keywords.fields.commercialMv.description2")}
            </li>
          </ul>
          <div>
            <BlockMath>
              {t("planningSummary.keywords.fields.commercialMv.formula")}
            </BlockMath>
          </div>
        </div>
      ),
      ModalActions: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButton: ({ onClick }) => (
        <InfoIcon color="primary" onClick={onClick} />
      ),
    },
  });

  return { viewingCommercialMvButton, viewingCommercialMvModal };
};

export const useViewingPlannedMvModal = () => {
  const { t } = useTranslation();

  const {
    contentButton: viewingPlannedMvButton,
    contentModal: viewingPlannedMvModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("planningSummary.keywords.fields.plannedMv.name"),
      ModalContent: () => (
        <div>
          <ul>
            <li>
              {t("planningSummary.keywords.fields.plannedMv.description")}
            </li>
          </ul>
          <div>
            <BlockMath>
              {t("planningSummary.keywords.fields.plannedMv.formula")}
            </BlockMath>
          </div>
        </div>
      ),
      ModalActions: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButton: ({ onClick }) => (
        <InfoIcon color="primary" onClick={onClick} />
      ),
    },
  });

  return { viewingPlannedMvButton, viewingPlannedMvModal };
};

export const useViewingMvAtCompletionModal = () => {
  const { t } = useTranslation();

  const {
    contentButton: viewingMvAtCompletionButton,
    contentModal: viewingMvAtCompletionModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("planningSummary.keywords.fields.mvAtCompletion.name"),
      ModalContent: () => (
        <div>
          <ul>
            <li>
              {t("planningSummary.keywords.fields.mvAtCompletion.description")}
            </li>
            <li>
              {t("planningSummary.keywords.fields.mvAtCompletion.description2")}
            </li>
            <li>
              {t("planningSummary.keywords.fields.mvAtCompletion.description3")}
            </li>
          </ul>
          {t("planningSummary.keywords.fields.mvAtCompletion.description4")}
          <div>
            <BlockMath>
              {t("planningSummary.keywords.fields.mvAtCompletion.formula")}
            </BlockMath>
          </div>
        </div>
      ),
      ModalActions: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButton: ({ onClick }) => (
        <InfoIcon color="primary" onClick={onClick} />
      ),
    },
  });

  return { viewingMvAtCompletionButton, viewingMvAtCompletionModal };
};

export const useViewingMvFinancialCostModal = () => {
  const { t } = useTranslation();

  const {
    contentButton: viewingMvFinancialCostAtCompletionButton,
    contentModal: viewingMvFinancialCostAtCompletionModal,
  } = useModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t(
        "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.name"
      ),
      ModalContent: () => (
        <div>
          <ul>
            <li>
              {t(
                "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.description"
              )}
            </li>
            <li>
              {t(
                "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.description2"
              )}
            </li>
            <li>
              {t(
                "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.description3"
              )}
            </li>
          </ul>
          {t(
            "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.description4"
          )}
          <div
            style={{
              fontSize: "0.8rem",
            }}
          >
            <BlockMath>
              {t(
                "planningSummary.keywords.fields.mvAtCompletionWithFinancialCost.formula"
              )}
            </BlockMath>
          </div>
        </div>
      ),
      ModalActions: ({ closeModal }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButton: ({ onClick }) => (
        <InfoIcon color="primary" onClick={onClick} />
      ),
    },
  });

  return {
    viewingMvFinancialCostAtCompletionButton,
    viewingMvFinancialCostAtCompletionModal,
  };
};
