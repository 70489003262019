import { useMemo } from "react";
import { formatNumber } from "../../../../../helpers/data/numbers/localization/formatters.helpers";
import { FormatNumberOptions } from "../../../../../helpers/data/numbers/localization/formatters.types";

export interface UseFormattedNumberProps {
  value: number | null;
  defaultValue?: string;
  options?: FormatNumberOptions;
}

export const useFormattedNumber = ({
  value,
  defaultValue,
  options = {},
}: UseFormattedNumberProps) => {
  return useMemo(() => {
    if (value === null) return defaultValue ?? null;
    return formatNumber(value, options);
  }, [value, defaultValue, ...Object.values(options)]);
};
