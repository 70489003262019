import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useEffect, useMemo, useRef } from "react";
import { isValid } from "date-fns";
import {
  useFormikModalButton,
  UseFormikModalButtonRef,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "./shared/types/filter.types";
import { FilterFormButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/FilterFormButton";
import { convertTo } from "../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { InvoicesFilterForm } from "./InvoicesFilterForm";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { InvoiceFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/filters/InvoiceFiltersDTO";

export const useFilterForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { project } = useProjectContext();

  const formikModalButtonRef =
    useRef<UseFormikModalButtonRef<FilterValues>>(null);

  useEffect(() => {
    formikModalButtonRef.current?.resetFormValues();
  }, [language]);

  const formikConfig = useMemo(() => {
    const initialValues: FilterValues = {
      earliestIssueDate: null,
      latestIssueDate: null,
      earliestPlannedBillingDate: null,
      latestPlannedBillingDate: null,
    };

    const validationSchema = yup.object({
      earliestIssueDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      latestIssueDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .when("earliestIssueDate", (earliestIssueDate, schema) =>
          isValid(earliestIssueDate[0])
            ? schema.min(
                earliestIssueDate[0],
                t("general.errors.data.fields.dates.dateOlderAcceptableLimit")
              )
            : schema
        ),
      earliestPlannedBillingDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable(),
      latestPlannedBillingDate: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .when(
          "earliestPlannedBillingDate",
          (earliestPlannedBillingDate, schema) =>
            isValid(earliestPlannedBillingDate[0])
              ? schema.min(
                  earliestPlannedBillingDate[0],
                  t("general.errors.data.fields.dates.dateOlderAcceptableLimit")
                )
              : schema
        ),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: filterButton,
    formattedFormValues: filters,
    contentModal: filterModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("general.keywords.general.filters"),
    },
    button: {
      FormButtonMemo: FilterFormButton,
      checkShouldShowBadgeDot: (formValues) => {
        return Object.values(formValues).some((formValue) => formValue);
      },
    },
    form: {
      formikConfig,
      getFormattedFormValues: ({ formValues }) =>
        convertTo<InvoiceFiltersDTO>({
          idProject: project.id,
          earliestIssueDate: formValues.earliestIssueDate ?? undefined,
          latestIssueDate: formValues.latestIssueDate ?? undefined,
          earliestPlannedBillingDate:
            formValues.earliestPlannedBillingDate ?? undefined,
          latestPlannedBillingDate:
            formValues.latestPlannedBillingDate ?? undefined,
        }),
      FormContentMemo: InvoicesFilterForm,
      FormActionsMemo: ({ submitFormValues, resetFormValues }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={resetFormValues}>
              {t("general.actions.general.clear")}
            </Button>
            <Button onClick={submitFormValues} variant="contained">
              {t("general.actions.general.search")}
            </Button>
          </>
        );
      },
    },
    general: {
      innerRef: formikModalButtonRef,
    },
  });

  return { filterButton, filters, filterModal };
};
