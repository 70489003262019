export const generateActivityDescription = ({
  prefix,
  suffix,
  sequentialText,
  separatorBeforeSequential,
  separatorAfterSequential,
}: {
  prefix?: string;
  suffix?: string;
  sequentialText?: string;
  separatorBeforeSequential?: string;
  separatorAfterSequential?: string;
}) => {
  const descriptionParts: (string | undefined)[] = [];

  descriptionParts.push(prefix);
  if (sequentialText)
    descriptionParts.push(
      separatorBeforeSequential,
      sequentialText,
      separatorAfterSequential
    );
  descriptionParts.push(suffix);

  return descriptionParts.filter((x) => !!x).join(" ");
};
