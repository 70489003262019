import { useContext, useMemo } from "react";
import { getNowYearMonthBrazil } from "../../../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { CarouselContext } from "../../../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext";
import { AllocationsSuperUserContext } from "../../../../../../shared/react/contexts/AllocationsSuperUserContext";
import { useMonthlyAllocationClosing } from "../../../../../../../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";
import { YearMonth } from "../../../../../../../../../../../../../shared/common/classes/data/date/YearMonth";

export const useMinPeriodStart = () => {
  const { canEditAllAllocations } = useContext(AllocationsSuperUserContext);
  const { earliestYearMonth } = useContext(CarouselContext);
  const { monthlyAllocationClosedList } = useMonthlyAllocationClosing();

  const minPeriodStartYearMonth = useMemo(() => {
    if (canEditAllAllocations) return earliestYearMonth ?? null;

    const biggestYearMonth = YearMonth.max(
      ...monthlyAllocationClosedList.map((x) => x.yearMonth)
    );
    const minDate = biggestYearMonth
      ? biggestYearMonth.toDate()
      : getNowYearMonthBrazil().toDate();

    if (monthlyAllocationClosedList.length > 0) {
      minDate.setMonth(minDate.getMonth() + 1);
    }

    if (
      !earliestYearMonth ||
      earliestYearMonth.lessThan(YearMonth.createFromDate(minDate))
    )
      return YearMonth.createFromDate(minDate);

    return earliestYearMonth;
  }, [earliestYearMonth, canEditAllAllocations, monthlyAllocationClosedList]);

  const minPeriodStartDate = useMemo(() => {
    return minPeriodStartYearMonth?.toDate() ?? null;
  }, [minPeriodStartYearMonth]);

  const minPeriodStartErrorMessage = useMemo(() => {
    if (!minPeriodStartYearMonth) return "";

    if (earliestYearMonth && minPeriodStartYearMonth.equals(earliestYearMonth))
      return "allocations.errors.data.fields.periodStart.cannotBeBeforeProjectBaselineStart";

    return "allocations.errors.data.fields.yearMonth.monthlyAllocationClosedForSomeSelectedMonth";
  }, [minPeriodStartYearMonth]);

  return {
    minPeriodStartYearMonth,
    minPeriodStartDate,
    minPeriodStartErrorMessage,
  };
};
