import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { AccessGroupCreateDTO } from "../../../../../../shared/specific/DTOs/accessGroups/AccessGroupCreateDTO";
import { createAccessGroup } from "../../../../../../services/accessGroups/accessGroups.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormValues } from "../shared/types/form.types";
import { CommonAccessGroupsForm } from "../shared/react/components/CommonAccessGroupsForm";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useCreationForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();

  const formikConfig = useMemo(() => {
    const initialValues: FormValues = {
      name: "",
    };

    const validationSchema = yup.object({
      name: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const {
    contentButton: CreationButtonContainer,
    contentModal: creationModal,
  } = useFormikModalButton({
    modal: {
      keepModalMounted: 1000,
      modalTitle: t("accessGroups.modal.data.create.title"),
    },
    button: {
      FormButton: AddFormButton,
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <CommonAccessGroupsForm {...rest} />
      ),
      FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
        const { t } = useTranslation();

        return (
          <>
            <Button onClick={closeModal} disabled={isSubmitting}>
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.save")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        setFormValues,
        formikHelpers: { setSubmitting },
        closeModal,
      }) => {
        try {
          const createdAccessGroup: AccessGroupCreateDTO = {
            name: formValues?.name ?? "",
          };

          await createAccessGroup(createdAccessGroup);
          reloadTablePage();
          closeModal();
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage: "accessGroups.errors.data.create.failedToCreate",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { CreationButtonContainer, creationModal };
};
