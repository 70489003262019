import { ProjectPlanningSavingValidationDTO } from "../../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningSavingValidationDTO";
import { ValidationItems } from "../../../shared/react/components/data/validation/ValidationItems";
import { usePlanningSavingValidationData } from "../../../shared/react/hooks/data/validation/usePlanningSavingValidationData";

interface OwnProps {
  projectValidation: ProjectPlanningSavingValidationDTO;
}

export const PlanningSavingForm = ({ projectValidation }: OwnProps) => {
  const validationItems = usePlanningSavingValidationData({
    projectValidation,
  });

  return <ValidationItems validationItems={validationItems} />;
};
