import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../shared/specific/react/components/authentication/Protected";
import { useCreationForm } from "./hooks/useCreationForm";
import { useTableData } from "./hooks/useTableData";
import { useUploadsArea } from "./hooks/useUploadsArea";

export const BudgetPageWithinContext = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    const currentDate = new Date();
    const nextYear = currentDate.getFullYear() + 1;
    setPageTitle(`${t("budget.pages.main.title")} ${nextYear}`);
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { headCells, onPageChange, deletionModal, editingModal, dolarTax } =
    useTableData({
      reloadTablePage,
    });

  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  const { FormComponent } = useUploadsArea({
    currentDolarTax: dolarTax,
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language],
    rendersBeforeEffect: 1,
  });

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.Budget,
                level: PermissionLevel.Create,
              }}
            >
              {CreationButtonContainer}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
          maxTableHeight: "50%",
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
        orderDefaults={{
          defaultOrder: "desc",
          defaultOrderBy: 1,
        }}
      />
      {FormComponent}
      {creationModal}
      {editingModal}
      {deletionModal}
    </>
  );
};
