import { KeyboardReturn as KeyboardReturnIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { setPageTitle } from "../../../../services/applicationState/pageData.service";
import { CenteredContent } from "../../../../shared/common/react/components/ui/CenteredContent";

export const UnauthorizedAccess = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle("");
  }, []);

  return (
    <CenteredContent>
      {t("general.errors.authorization.unauthorizedAccessPage")}
      <Button
        component={Link}
        to="/"
        variant="text"
        endIcon={<KeyboardReturnIcon />}
      >
        {t("general.actions.navigation.returnToFrontPage")}
      </Button>
    </CenteredContent>
  );
};
