import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../../types/data/form/values.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): FormValues => {
    return {
      userCurrentJobRole: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      userCurrentJobRole: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
