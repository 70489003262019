import { TextField } from "@mui/material";
import { FocusEvent, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { BelowToolbarFilters } from "../../../shared/types/filter.types";

const TIME_BEFORE_UPDATING_FILTERS = 500;

export const useBelowToolbarFilters = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialSearchFilters = useMemo(
    () => ({
      name: searchParams.get("name") ?? "",
    }),
    []
  );

  const [belowToolbarFilters, setBelowToolbarFilters] =
    useState<BelowToolbarFilters>(initialSearchFilters);

  const filtersUpdateTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    return () => clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);
  }, []);

  useEffect(() => {
    if (belowToolbarFilters.name) {
      searchParams.set("name", belowToolbarFilters.name ?? "");
    } else {
      searchParams.delete("name");
    }

    setSearchParams(searchParams, { replace: true });
  }, [belowToolbarFilters.name]);

  const belowToolbarFiltersComponent = useMemo(() => {
    const onChange = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      clearTimeout(filtersUpdateTimeoutRef.current ?? undefined);

      filtersUpdateTimeoutRef.current = setTimeout(
        () =>
          setBelowToolbarFilters((belowToolbarFilters) => ({
            ...belowToolbarFilters,
            name: event.target.value,
          })),
        TIME_BEFORE_UPDATING_FILTERS
      ) as unknown as number;
    };

    return (
      <TextField
        label={t("general.keywords.fields.name")}
        fullWidth
        onChange={onChange}
        autoFocus
        defaultValue={initialSearchFilters.name}
      />
    );
  }, [t, belowToolbarFilters]);

  return { belowToolbarFilters, belowToolbarFiltersComponent };
};
