import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import { uploadPerformedExpenses } from "../../../../../services/uploadPerformedExpense/uploadPerformedExpense.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { StyledExpensesUpload, StyledTextButton } from "./index.style";
import { useModalLoading } from "./useModalLoading";
import { useWorksheetHelp } from "./useWorksheetHelp";

export const UploadPerformedExpense = () => {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filename, setFilename] = useState<string | null>(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [yearMonthSubmitted, setYearMonthSubmitted] = useState<string | null>(
    null
  );
  const [errorsList, setErrorsList] = useState<string[]>([]);

  const fileUploadInputRef = useRef<HTMLInputElement | null>(null);

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();

  const { LoadingButtonContainer, LoadingModal, uploadButtonRef } =
    useModalLoading({
      isSubmitting,
      filename,
      yearMonthSubmitted,
      hasSubmitted,
      errorsList,
    });

  useEffect(() => {
    setPageTitle(t("performedExpense.pages.main.title"));
  }, [t]);

  const loadExpenses = () => fileUploadInputRef.current?.click();

  const onPickFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    event.target.value = "";
    if (!file) return;

    setIsSubmitting(true);
    uploadButtonRef.current?.click();
    setYearMonthSubmitted(null);
    setFilename(file.name);
    setHasSubmitted(false);
    try {
      const { errorMessages, yearMonthUpdated } =
        await uploadPerformedExpenses(file);
      setErrorsList(errorMessages);
      setYearMonthSubmitted(yearMonthUpdated);
      setHasSubmitted(true);
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "Error while uploading spreadsheet.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h3>{t("performedExpense.keywords.helping")}</h3>
      <LoadingButtonContainer />
      <StyledExpensesUpload>
        <input
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
          hidden
          ref={fileUploadInputRef}
          onChange={onPickFile}
        />

        <StyledTextButton onClick={loadExpenses}>
          {t("performedExpense.keywords.uploadButton")}
        </StyledTextButton>
        <UseWorksheetHelpModalButtonContainer />
      </StyledExpensesUpload>
      {useWorksheetHelpModal}
      {LoadingModal}
    </>
  );
};
