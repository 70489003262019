import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import {
  Dispatch,
  ReactNode,
  Ref,
  SetStateAction,
  useEffect,
  useMemo,
} from "react";
import { PaletteColorVariant } from "../../../../../../../types/externalLibraries/mui.types";
import { CenteredContent } from "../../../../../../components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../components/ui/CenteredLoading";
import { FormContentContextProvider } from "../shared/react/contexts/FormContentContext";
import {
  GetOpenCloseModalCountFunction,
  OnSubmitFunction,
} from "../shared/types/functions.types";
import {
  FormContentComponent,
  EntryFormikConfig,
  InternalLoadingPosition,
} from "../shared/types/props.types";
import { ModalContentMessageContainer } from "../useFormikModalButton/index.styles";

interface OwnProps<FormValues extends FormikValues, InternalFormData> {
  FormContent?: FormContentComponent<FormValues, InternalFormData>;
  FormContentMemo?: FormContentComponent<FormValues, InternalFormData>;
  formikConfig: EntryFormikConfig<FormValues>;
  formValues: FormValues;
  isLoadingModal: boolean;
  modalError: ReactNode;
  internalLoadingPosition: InternalLoadingPosition;
  letModalContentDealWithLoading: boolean;
  letModalContentDealWithError: boolean;
  setFormValues: (formValues: FormValues) => void;
  closeModal: () => void;
  internalFormData: InternalFormData | null;
  setIsSubmitting: (isSubmitting: boolean) => void;
  resetFormValues: () => void;
  submitFormValues: () => void;
  modalColorVariant?: PaletteColorVariant;
  externalOnSubmit?: OnSubmitFunction<FormValues, InternalFormData>;
  formikRef?: Ref<FormikProps<FormValues>>;
  isModalDisabled: boolean;
  setIsModalDisabled: (isModalDisabled: boolean) => void;
  getOpenCloseModalCount: GetOpenCloseModalCountFunction;
  setInternalFormData: Dispatch<SetStateAction<InternalFormData | null>>;
}

export const useModalContent = <
  FormValues extends FormikValues,
  InternalFormData,
>({
  FormContent,
  FormContentMemo,
  formikConfig,
  formValues,
  isLoadingModal,
  modalError,
  internalLoadingPosition,
  letModalContentDealWithLoading,
  letModalContentDealWithError,
  modalColorVariant,
  externalOnSubmit,
  internalFormData,
  setIsSubmitting,
  resetFormValues,
  submitFormValues,
  setFormValues,
  closeModal,
  formikRef,
  isModalDisabled,
  setIsModalDisabled,
  getOpenCloseModalCount,
  setInternalFormData,
}: OwnProps<FormValues, InternalFormData>) => {
  const modalContent = useMemo(() => {
    const onSubmit = async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      if (!externalOnSubmit) {
        setFormValues(formValues);
        formikHelpers.setSubmitting(false);
        closeModal();
        return;
      }

      try {
        await Promise.resolve(
          externalOnSubmit({
            internalFormData,
            closeModal,
            formValues,
            formikHelpers,
            setFormValues,
            setInternalFormData,
          })
        );
      } catch (error) {
        console.error("Unexpected error: ", error);
      }
    };

    return (
      <FormContentContextProvider
        value={{
          getOpenCloseModalCount,
          internalFormData,
          isLoadingModal,
          isModalDisabled,
          modalError,
          outerFormValues: formValues,
          setIsModalDisabled,
          closeModal,
          setInternalFormData,
        }}
      >
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validateOnMount={false}
          {...formikConfig}
          onSubmit={onSubmit}
          innerRef={formikRef}
        >
          {function FormikForm(formikProps) {
            const { isSubmitting } = formikProps;

            useEffect(() => {
              setIsSubmitting(isSubmitting);
            }, [isSubmitting]);

            if (isLoadingModal && !letModalContentDealWithLoading) {
              if (internalLoadingPosition === "title") return null;

              return (
                <ModalContentMessageContainer>
                  <CenteredLoading color={modalColorVariant} />
                </ModalContentMessageContainer>
              );
            }

            if (modalError && !letModalContentDealWithError)
              return (
                <ModalContentMessageContainer>
                  <CenteredContent>{modalError}</CenteredContent>
                </ModalContentMessageContainer>
              );

            if (FormContent)
              return (
                <FormContent
                  internalFormData={internalFormData}
                  formikProps={formikProps}
                  outerFormValues={formValues}
                  resetFormValues={resetFormValues}
                  submitFormValues={submitFormValues}
                  closeModal={closeModal}
                  isModalDisabled={isModalDisabled}
                  setIsModalDisabled={setIsModalDisabled}
                  isLoadingModal={isLoadingModal}
                  modalError={modalError}
                  getOpenCloseModalCount={getOpenCloseModalCount}
                />
              );

            if (FormContentMemo)
              return (
                <FormContentMemo
                  internalFormData={internalFormData}
                  formikProps={formikProps}
                  outerFormValues={formValues}
                  resetFormValues={resetFormValues}
                  submitFormValues={submitFormValues}
                  closeModal={closeModal}
                  isModalDisabled={isModalDisabled}
                  setIsModalDisabled={setIsModalDisabled}
                  isLoadingModal={isLoadingModal}
                  modalError={modalError}
                  getOpenCloseModalCount={getOpenCloseModalCount}
                />
              );

            return null;
          }}
        </Formik>
      </FormContentContextProvider>
    );
  }, [
    FormContent,
    formikConfig,
    formValues,
    isLoadingModal,
    isModalDisabled,
    modalError,
    internalLoadingPosition,
    letModalContentDealWithLoading,
    letModalContentDealWithError,
    modalColorVariant,
    internalFormData,
  ]);

  return modalContent;
};
