import { TextFieldProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { getCostCenterPeps } from "../../../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { CostCenterPepListFiltersDTO } from "../../../../../../../DTOs/costCenterPeps/filters/CostCenterPepListFiltersDTO";
import { AsyncAutocompleteProps } from "../../../../../../../../common/react/components/form/general/autocomplete/general/AsyncAutocomplete";
import { CostCenterPepDTO } from "../../../../../../../DTOs/costCenterPeps/CostCenterPepDTO";
import { AsyncAutocompleteFormik } from "../../../../../../../../common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncAutocompleteProps<
    CostCenterPepDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  filters?: CostCenterPepListFiltersDTO;
  filtersMemo?: CostCenterPepListFiltersDTO;
}

export const CostCenterPepsAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  filters,
  filtersMemo,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo ?? {};
  }, [filters]);

  const internalGetCostCenterPeps = useCallback(async () => {
    return getCostCenterPeps({ filters: finalFilters });
  }, [finalFilters?.idProject]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "costCenterPeps.errors.data.fetch.failedToFetchCostCenterPeps"
      )}
      fetchOptions={internalGetCostCenterPeps}
      textfieldProps={{
        label: t("costCenterPeps.keywords.general.costCenterPeps"),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (costCenterPep) => costCenterPep.pepCode,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
