import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalSinglePageLoader,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { useDeletionForm } from "./useDeletionForm";
import { getMonthlyAllocationClosing } from "../../../../../services/monthlyAllocationClosing/monthlyAllocationClosing.service";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { useCreationForm } from "./useCreationForm";

interface OwProps {
  reloadTablePage: () => void;
}

export const useTableData = ({ reloadTablePage }: OwProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const { CreationButton, isSubmitting } = useCreationForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("brlParity.keywords.fields.yearMonth"),
      t("projectBaselines.keywords.fields.validatedBy"),
      t("monthlyAllocationClosing.keywords.fields.validationDate"),
      t("monthlyAllocationClosing.keywords.fields.workforceCostDate"),
      {
        width: 100,
        value: "",
      },
    ];

    return headCells;
  }, [t]);

  const onPageChange: ExternalEnhancedTableExternalSinglePageLoader =
    useCallback(async () => {
      try {
        const monthlyAllocationsClosed = await getMonthlyAllocationClosing();

        const biggestYearMonth = YearMonth.max(
          ...monthlyAllocationsClosed.map((x) => x.yearMonth)
        );

        const lastMonth = biggestYearMonth?.toDate();

        const rows: ExternalEnhancedRow[] = monthlyAllocationsClosed.map(
          (monthlyAllocationClosed): ExternalEnhancedRow => {
            return {
              id: monthlyAllocationClosed.id,
              cells: [
                {
                  value: monthlyAllocationClosed.yearMonth.toString(),
                  displayValue:
                    monthlyAllocationClosed.yearMonth.toPrettyString(),
                },
                monthlyAllocationClosed.userCollaboratorValidator?.name ??
                  t("general.keywords.fields.automatic"),
                {
                  value: monthlyAllocationClosed.validatedDate.toString(),
                  displayValue: `${monthlyAllocationClosed.validatedDate.toLocaleDateString(
                    i18n.language
                  )} ${monthlyAllocationClosed.validatedDate.toLocaleTimeString(
                    i18n.language
                  )}`,
                },
                monthlyAllocationClosed.workforceCostDate
                  ? `${monthlyAllocationClosed.workforceCostDate?.toLocaleDateString(
                      i18n.language
                    )} ${monthlyAllocationClosed.workforceCostDate?.toLocaleTimeString(
                      i18n.language
                    )}`
                  : "-",
                {
                  CellRenderer: () => {
                    if (
                      biggestYearMonth &&
                      biggestYearMonth.toString() ===
                        monthlyAllocationClosed.yearMonth.toString()
                    ) {
                      return (
                        <Protected
                          restrictions={{
                            type: PermissionType.MonthlyAllocationClosing,
                            level: PermissionLevel.Delete,
                          }}
                        >
                          <DeleteButtonContainer {...monthlyAllocationClosed} />
                        </Protected>
                      );
                    }

                    return <> </>;
                  },
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            };
          }
        );

        if (lastMonth) {
          lastMonth.setMonth(lastMonth.getMonth() + 1);
          rows.push({
            id: 0,
            cells: [
              {
                value: YearMonth.createFromDate(lastMonth).toString(),
                displayValue:
                  YearMonth.createFromDate(lastMonth).toPrettyString(),
              },
              "-",
              {
                CellRenderer: () => (
                  <Protected
                    restrictions={{
                      type: PermissionType.MonthlyAllocationClosing,
                      level: PermissionLevel.Create,
                    }}
                  >
                    <CreationButton
                      yearMonth={YearMonth.createFromDate(lastMonth)}
                    />
                  </Protected>
                ),
                align: "right",
                paddingmode: "horizontal",
              },
              "",
            ],
          });
        }

        return rows;
      } catch (error) {
        throwIf4xxApiErrorDTO(error);

        console.error(error);

        throw new TranslatedError(
          "general.errors.data.fetch.failedToFetchData"
        );
      }
    }, [t, i18n]);

  return {
    headCells,
    onPageChange,
    deletionModal,
    isSubmitting,
  };
};
