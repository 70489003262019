import { Button, Typography } from "@mui/material";
import lodash from "lodash";
import { FormikProps, useField, useFormikContext } from "formik";
import { useCallback, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { PermissionLevel } from "../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../shared/specific/react/components/authentication/Protected";
import { useCreationForm } from "../../../hooks/useCreationForm";
import { useTableData } from "../../../hooks/useTableData";
import { CollaboratorAllocationContext } from "../../context";
import {
  AllocationYearMonthsFormValues,
  FormValues,
  FormValuesCollaboratorAllocationsFilter,
} from "../../types/form.types";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import {
  StyledFooterContent,
  StyledTextField,
  StyledTotals,
} from "./index.styles";
import { CalculateTotalMonthPercentage } from "./functions/CalculateTotalMonthPercentage";
import { CollaboratorAllocationsSuperUserContext } from "../../context/CollaboratorAllocationsSuperUserContext";
import { useMonthlyAllocationClosing } from "../../../../../../../shared/specific/react/hooks/data/useMonthlyAllocationClosing";
import { YearMonth } from "../../../../../../../shared/common/classes/data/date/YearMonth";

interface OwnProps {
  allocationCollaboratorFilter?: FormValuesCollaboratorAllocationsFilter | null;
  formikContenProps: FormikProps<FormValues>;
}

export const CollaboratorAllocationTable = ({
  allocationCollaboratorFilter,
  formikContenProps: { submitForm },
}: OwnProps) => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { checkIfMonthlyAllocationIsClosed, monthlyAllocationClosedList } =
    useMonthlyAllocationClosing();

  const { allocations, filter, setFilter } = useContext(
    CollaboratorAllocationContext
  );

  const {
    canEditAllCollaboratorAllocationsButton,
    canEditAllCollaboratorAllocations,
  } = useContext(CollaboratorAllocationsSuperUserContext);

  const [
    { value: allocationYearMonths },
    ,
    { setValue: setAllocationYearMonths },
  ] = useField<AllocationYearMonthsFormValues[]>("allocationYearMonths");

  const { values } = useFormikContext();

  const formikValues = values as FormValues;

  const { headCells, onPageChange } = useTableData({
    reloadTablePage,
    setAllocationYearMonths,
    allocationCollaboratorFilter,
    formikValues,
  });

  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [
      i18n.language,
      allocationCollaboratorFilter,
      allocations.length,
      allocationYearMonths.length,
      filter,
      canEditAllCollaboratorAllocations,
    ],
    rendersBeforeEffect: 1,
  });

  const allocationMonthlyIsClosed = useMemo(() => {
    if (!allocationCollaboratorFilter?.yearMonth) return true;

    return checkIfMonthlyAllocationIsClosed(
      YearMonth.createFromDate(allocationCollaboratorFilter.yearMonth)
    );
  }, [monthlyAllocationClosedList, allocationCollaboratorFilter?.yearMonth]);

  const rightActionButtons = useMemo(() => {
    return canEditAllCollaboratorAllocationsButton;
  }, [canEditAllCollaboratorAllocationsButton]);

  const leftActions = useMemo(() => {
    const canEdit =
      canEditAllCollaboratorAllocations || !allocationMonthlyIsClosed;
    return canEdit &&
      allocationCollaboratorFilter?.userCollaborator &&
      allocationCollaboratorFilter?.yearMonth ? (
      <Protected
        restrictions={{
          type: PermissionType.CollaboratorAllocation,
          level: PermissionLevel.Create,
        }}
      >
        <CreationButtonContainer />
      </Protected>
    ) : (
      ""
    );
  }, [allocationCollaboratorFilter, canEditAllCollaboratorAllocations]);

  const footerComponent = useMemo(() => {
    if (!(formikValues.allocationYearMonths.length > 0)) return "";

    const week1PercentageAmount = Number(
      lodash
        .sum(
          formikValues.allocationYearMonths.map(
            (x) => parseLocaleNumber(x.week1Percentage) || 0
          )
        )
        .toFixed(2)
    );

    const week2PercentageAmount = Number(
      lodash
        .sum(
          formikValues.allocationYearMonths.map(
            (x) => parseLocaleNumber(x.week2Percentage) || 0
          )
        )
        .toFixed(2)
    );

    const week3PercentageAmount = Number(
      lodash
        .sum(
          formikValues.allocationYearMonths.map(
            (x) => parseLocaleNumber(x.week3Percentage) || 0
          )
        )
        .toFixed(2)
    );

    const week4PercentageAmount = Number(
      lodash
        .sum(
          formikValues.allocationYearMonths.map(
            (x) => parseLocaleNumber(x.week4Percentage) || 0
          )
        )
        .toFixed(2)
    );

    const week5PercentageAmount = Number(
      lodash
        .sum(
          formikValues.allocationYearMonths.map(
            (x) => parseLocaleNumber(x.week5Percentage) || 0
          )
        )
        .toFixed(2)
    );

    return (
      <StyledTotals>
        <Typography variant="h5">Total</Typography>
        <StyledTextField
          value={`${CalculateTotalMonthPercentage(
            formikValues.allocationYearMonths
          )}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
        <StyledTextField
          value={`${week1PercentageAmount}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
        <StyledTextField
          value={`${week2PercentageAmount}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
        <StyledTextField
          value={`${week3PercentageAmount}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
        <StyledTextField
          value={`${week4PercentageAmount}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
        <StyledTextField
          value={`${week5PercentageAmount}`}
          InputProps={{
            endAdornment: "%",
            readOnly: true,
          }}
          disabled
        />
      </StyledTotals>
    );
  }, [formikValues]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          leftActionButtons: leftActions,
          rightActionButtons,
        }}
        footerSettings={{
          content: footerComponent,
        }}
        uiSettings={{
          hasDynamicTableHeight: false,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: false,
          externalPagination: {
            loadSinglePageContent: onPageChange,
          },
        }}
      />
      <StyledFooterContent>
        <Button
          onClick={() => setFilter(null)}
          variant="text"
          disabled={
            !allocationCollaboratorFilter?.userCollaborator ||
            !allocationCollaboratorFilter?.yearMonth
          }
        >
          {t("general.actions.general.cancel")}
        </Button>
        <Button
          onClick={submitForm}
          variant="contained"
          disabled={
            !allocationCollaboratorFilter?.userCollaborator ||
            !allocationCollaboratorFilter?.yearMonth
          }
        >
          {t("general.actions.general.save")}
        </Button>
      </StyledFooterContent>
      {creationModal}
    </>
  );
};
