import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { schemaSkillDTO, SkillDTO } from "./SkillDTO";

export type PaginatedSkillDTO = PaginationResponse<SkillDTO>;

export const schemaPaginatedSkillDTO = getSchemaPaginationResponse<SkillDTO>(
  schemaSkillDTO as yup.Schema<SkillDTO>
);
