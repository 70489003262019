import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LoadCustomerBalancesFormValues } from "../../shared/react/shared/types/form.types";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CustomerBalancesUploadResponseDTO } from "../../../../../../../shared/specific/DTOs/customerBalance/CustomerBalancesUploadResponseDTO";
import { LoadForm } from "./LoadForm";
import { uploadCustomerBalances } from "../../../../../../../services/customerBalance/customerBalance.service";

export const useLoadCustomerBalance = () => {
  const { t } = useTranslation();
  const formikConfig = useMemo(() => {
    const initialValues: LoadCustomerBalancesFormValues = {
      file: null,
    };
    return { initialValues };
  }, [t]);

  const {
    ContentButton: LoadCustomerBalances,
    contentModal: uploadCustomerBalancesModal,
  } = useFormikModalButton<
    LoadCustomerBalancesFormValues,
    CustomerBalancesUploadResponseDTO
  >({
    modal: {
      modalMode: "modal",
      keepModalMounted: 1000,
      ModalTitleMemo: () => <>{t("customerBalance.modal.data.upload.title")}</>,
    },
    button: {
      FormButtonMemo: ({ onClick }) => {
        const { t } = useTranslation();
        return (
          <Button onClick={onClick} variant="contained">
            {t("customerBalance.keywords.uploadButton")}
          </Button>
        );
      },
      onClickContentButton: ({ setInternalFormData }) =>
        setInternalFormData(null),
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <LoadForm {...rest} internalFormData={internalFormData} />
      ),
      FormActions: ({
        submitFormValues,
        closeModal,
        isSubmitting,
        internalFormData,
      }) => {
        const { t } = useTranslation();

        return internalFormData ? (
          <Button
            onClick={closeModal}
            disabled={isSubmitting}
            variant="contained"
          >
            {t("general.actions.general.close")}
          </Button>
        ) : (
          <>
            <Button
              onClick={closeModal}
              disabled={isSubmitting}
              variant="contained"
            >
              {t("general.actions.general.cancel")}
            </Button>
            <LoadingButton
              loading={isSubmitting}
              onClick={submitFormValues}
              variant="contained"
            >
              {t("general.actions.general.execute")}
            </LoadingButton>
          </>
        );
      },
      onSubmit: async ({
        formValues,
        formikHelpers: { setSubmitting },
        setInternalFormData,
      }) => {
        try {
          if (!formValues.file) {
            notifyIf4xxApiErrorDTO({
              error: "customerBalance.errors.data.general.mustAttachFile",
              defaultMessage:
                "customerBalance.errors.data.general.mustAttachFile",
            });
            setSubmitting(false);
            return;
          }
          const res = await uploadCustomerBalances(formValues.file);
          setInternalFormData(res);
        } catch (error) {
          notifyIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "customerBalance.errors.data.general.failedToloadCustomerBalances",
          });
        } finally {
          setSubmitting(false);
        }
      },
    },
  });

  return { LoadCustomerBalances, uploadCustomerBalancesModal };
};
