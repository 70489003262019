import * as yup from "yup";

import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import { ProjectOnlyDTO, schemaProjectOnlyDTO } from "./ProjectOnlyDTO";

export type PaginatedProjectOnlyDTO = PaginationResponse<ProjectOnlyDTO>;

export const schemaPaginatedProjectOnlyDTO =
  getSchemaPaginationResponse<ProjectOnlyDTO>(
    schemaProjectOnlyDTO as yup.Schema<ProjectOnlyDTO>
  );
