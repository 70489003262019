import { isValid } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getNowBrazilianDate } from "../../../../../../../../shared/common/helpers/data/dates/general.helpers";
import { CustomDatePicker } from "../../../../../../../../shared/common/react/components/form/general/dateTime/CustomDatePicker";

export const useYearFilter = () => {
  const { t } = useTranslation();
  const [yearDate, setYearDate] = useState<Date>(() => getNowBrazilianDate());

  const [yearFilter, setYearFilter] = useState<number>(() =>
    yearDate.getFullYear()
  );

  useEffect(() => {
    if (!isValid(yearDate) || yearDate.getFullYear() < 2000) return;
    setYearFilter(yearDate.getFullYear());
  }, [yearDate]);

  const yearFilterComponent = useMemo(() => {
    return (
      <CustomDatePicker
        datePickerProps={{
          views: ["year"],
          label: t("general.keywords.dateTime.general.year"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          value: yearDate,
          onChange: (newYearDate: Date | null) =>
            newYearDate && setYearDate(newYearDate),
          minDateMemo: new Date("2000-01-01T00:00"),
        }}
      />
    );
  }, [t, yearDate]);

  return { yearFilter, yearFilterComponent };
};
