import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledJobRateContent = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a b b c c"
    "d d d e e e";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(5)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "a b"
      "c d"
      "e e";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e";
  }
`;
