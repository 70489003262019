import { Location } from "react-router-dom";
import { store } from "../../store";
import { pushToPagesHistory as pushToPagesHistoryAction } from "../../store/features/applicationSlice";

export const pushToPagesHistory = (location: Location) => {
  store.dispatch(pushToPagesHistoryAction(location));
};

export const getPageFromCurrent = (pagesAgo: number) => {
  if (pagesAgo <= 0) throw new Error("'pagesAgo' must be positive.");

  const { pagesHistory } = store.getState().application;
  const location = pagesHistory.at(-pagesAgo - 1);

  if (!location) return null;
  return location;
};
