import { formatNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledFixedColumn } from "./index.styles";

interface OwnProps {
  info: number | string;
  isRead?: boolean;
  currency: string;
  isPercentage?: boolean;
}

export const ValueCell = ({
  info,
  isRead,
  currency,
  isPercentage,
}: OwnProps) => {
  const formattedValue =
    typeof info === "number" ? formatNumber(info, { fractionDigits: 2 }) : info;

  return (
    <StyledFixedColumn isread={isRead ? "true" : "false"}>
      <span>{`${!isPercentage ? currency : ""} ${formattedValue} ${isPercentage ? "%" : ""}`}</span>
    </StyledFixedColumn>
  );
};
