import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledUserCollaboratorCreationForm = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "a b"
    "c d"
    "e f"
    "g h"
    "i j";

  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(9)}

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f"
      "g"
      "h"
      "i";
  }
`;

export const StyledInnactiveMessage = styled("div")`
  text-align: center;
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 500;
  padding-top: ${({ theme }) => theme.spacing(1.5)};
`;
