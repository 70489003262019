import { styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledViewContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledLocationDataContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "a b c";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(3)}
`;

export const StyledTotalInput = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "a b b";
  gap: ${({ theme }) => theme.spacing(1.5)};

  ${createGridAreaLettersRange(3)}
`;
