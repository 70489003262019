import { getCountries } from "../../../../../../../../../services/publicData/countries.service";
import { getStates } from "../../../../../../../../../services/publicData/states.service";
import { CachedParameterizedData } from "../../../../../../../../../shared/common/classes/data/cache/CachedParameterizedData";
import { CachedTranslatedData } from "../../../../../../../../../shared/common/classes/data/cache/CachedTranslatedData";

export const cachedCountries = new CachedTranslatedData({
  callback: getCountries,
  defaultCacheDuration: 600000, // 10 minutes
});

export const cachedStates = new CachedParameterizedData({
  callback: getStates,
  mapRunValueToKey: (country) => String(country),
  defaultCacheDuration: 600000, // 10 minutes
});
