import { useMemo } from "react";
import { getNowYearMonthBrazil } from "../../../../../../../../../../../shared/common/helpers/data/dates/general.helpers";

export const useMaxDeactivatedDate = () => {
  const maxDeactivatedDate = useMemo(() => {
    return getNowYearMonthBrazil().toDateEndOfMonth();
  }, []);

  return maxDeactivatedDate;
};
