import { Chip, Paper, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledFormSectionsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  flex: 1;
  overflow: auto;
  padding-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledFormSection = styled(Paper)`
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledProjectInfo = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(1)};
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: "a b c d e f";
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${createGridAreaLettersRange(6)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;

export const StyledStatusReturned = styled("div")`
  width: 100%;
`;

export const StyledButtonsContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

export const StyledListFiles = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1.5)};
  }
`;

export const StyledGrayBox = styled(Chip)`
  padding: 0;
  height: 35px;
  span {
    font-size: 15px;
  }
  width: max-content;
`;

export const StyledLabelChip = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};

  div {
    display: flex;
    width: max-content;
  }

  svg {
    cursor: pointer;
  }
`;

export const StyledInvoicesInfo = styled("div")`
  display: grid;
  gap: ${({ theme }) => theme.spacing(3)};
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "a a a a a a"
    "b c d e f g"
    "h h h h h h"
    "i i i i i i"
    "j j j j j j"
    "k k k k k k"
    "l l l l l l"
    "m m m m m m";
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${createGridAreaLettersRange(13)}

  ${({ theme }) => theme.breakpoints.down("md")} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "a b"
      "c d"
      "e f"
      "g h"
      "i j"
      "l l";
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr;
    grid-template-areas:
      "a"
      "b"
      "c"
      "d"
      "e"
      "f";
  }
`;

export const StyledEvidenceSection = styled("div")`
  padding: 8px 8px;
  display: grid;
  gap: ${({ theme }) => theme.spacing(2)};
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "a b";
  ${createGridAreaLettersRange(2)}
`;
