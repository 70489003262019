import * as yup from "yup";
import { saveAs } from "file-saver";
import {
  InvoiceFilesDTO,
  schemaInvoiceFilesDTO,
} from "../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { riseApi } from "./connection";
import { InvoiceFilesTypeEnum } from "../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import { notifyIf4xxApiErrorDTO } from "../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export const getInvoiceFiles = async (
  idInvoice: number
): Promise<InvoiceFilesDTO[]> => {
  const { data: invoiceFiles } = await riseApi.get("InvoiceFiles", {
    params: { idInvoice },
  });

  return yup.array(schemaInvoiceFilesDTO).defined().validateSync(invoiceFiles);
};

export const uploadInvoiceFile = async (
  files: File[],
  invoiceIds: string[],
  invoiceFilesType: InvoiceFilesTypeEnum
) => {
  const form = new FormData();
  for (let i = 0; i < files.length; i++) {
    form.append(`files`, files[i]);
  }

  for (let i = 0; i < invoiceIds.length; i++) {
    form.append(`invoiceIds`, invoiceIds[i]);
  }
  form.append("invoiceFilesType", invoiceFilesType.toString());

  await riseApi.post("InvoiceFiles", form, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getInvoiceFile = async (path: string) => {
  const response = await riseApi.get("/InvoiceFiles/download", {
    params: { path },
    responseType: "blob",
  });

  if (response.status === 204)
    return notifyIf4xxApiErrorDTO({
      error: null,
      defaultMessage: "reports.errors.response.noContent",
    });

  saveAs(
    new Blob([response.data], {
      type: "application/octet-stream",
    }),
    path
  );
};
