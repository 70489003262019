import { useFormikContext } from "formik";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { SharedAllocationYearMonthCell } from "../../../../../../../../../shared/specific/react/components/form/formik/textField/fields/SharedAllocationYearMonthCell";

interface OwnProps {
  columnNumber: number;
  weekNumber: number;
  yearMonth: YearMonth;
}

export const AllocationYearMonthCell = ({
  columnNumber,
  weekNumber,
  yearMonth,
}: OwnProps) => {
  const { isSubmitting } = useFormikContext();

  return (
    <SharedAllocationYearMonthCell
      name={`allocationYearMonths[${columnNumber}].week${weekNumber}Percentage`}
      weekNumber={weekNumber}
      yearMonth={yearMonth}
      disabled={isSubmitting}
    />
  );
};
