import * as yup from "yup";
import {
  YearMonth,
  schemaYearMonth,
} from "../../../common/classes/data/date/YearMonth";

export interface ProjectionAdjustmentMonthlyCommentDto {
  id: number;
  idSubsidiary: number | null;
  idSegmentGroup: number | null;
  idDivision: number | null;
  yearMonth: YearMonth;
  comment: string;
  version: string;
}

export const schemaProjectionAdjustmentMonthlyCommentDto = yup.object({
  id: yup.number().integer().defined(),
  idSubsidiary: yup.number().integer().defined().nullable(),
  idSegmentGroup: yup.number().integer().defined().nullable(),
  idDivision: yup.number().integer().defined().nullable(),
  yearMonth: schemaYearMonth.defined(),
  comment: yup.string().defined(),
  version: yup.string().defined(),
});
