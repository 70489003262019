import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TableContext } from "../TableContext";
import {
  EnhancedHeadCellData,
  EnhancedRowData,
  Order,
} from "../../../types/data.types";
import {
  EnhancedHeaderSettings,
  EnhancedOrderDefaultSettings,
  EnhancedTableBelowFooterSettings,
  EnhancedTableBelowToolbarSettings,
  EnhancedTableFilterSettings,
  EnhancedTableMessagesSettings,
  EnhancedTablePaginationSettings,
  EnhancedTableToolbarSettings,
  EnhancedTableUiSettings,
} from "../../../types/settings.types";
import {
  TableContextBelowToolbar,
  TableContextFilter,
  TableContextFooter,
  TableContextMessages,
  TableContextStateValues,
  TableContextToolbarValues,
  TableContextUiValues,
  TableContextValues,
} from "../index.types";
import { useSortingContextData } from "./useSortingContextData";
import { usePaginationContext } from "./usePaginationContext";
import { EnhancedTableRef } from "../../../types/propsRef.types";
import { convertTo } from "../../../../../../../../helpers/types/converters.helpers";

interface OwnProps {
  children: ReactNode;
  paginationSettings: EnhancedTablePaginationSettings;
  uiSettings: EnhancedTableUiSettings;
  toolbarSettings?: EnhancedTableToolbarSettings;
  headCells: EnhancedHeadCellData[];
  rows: EnhancedRowData[] | null;
  orderDefaultSettings: EnhancedOrderDefaultSettings;
  belowToolbarSettings?: EnhancedTableBelowToolbarSettings;
  footerSettings?: EnhancedTableBelowFooterSettings;
  filterSettings?: EnhancedTableFilterSettings;
  messagesSettings: EnhancedTableMessagesSettings;
  headerSettings?: EnhancedHeaderSettings;
}

const CustomTableContextProviderWithinForwardRef = (
  {
    children,
    paginationSettings,
    uiSettings,
    toolbarSettings,
    headCells,
    rows: externalRows,
    orderDefaultSettings: { defaultOrder, defaultOrderBy },
    belowToolbarSettings,
    footerSettings,
    filterSettings,
    messagesSettings,
    headerSettings,
  }: OwnProps,
  ref: ForwardedRef<EnhancedTableRef>
) => {
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState<number | null>(defaultOrderBy);
  const [error, setError] = useState<string | boolean>(false);
  const [info, setInfo] = useState<string | boolean>(false);
  const [loading, setLoading] = useState<string | boolean>(false);

  const sorting = useSortingContextData({
    order,
    orderBy,
    setOrder,
    setOrderBy,
  });

  const { pagination, rows, reloadPage, changePage } = usePaginationContext({
    paginationSettings,
    order,
    orderBy,
    externalRows,
    setError,
    setLoading,
    setInfo,
  });

  const ui = useMemo(() => {
    return convertTo<TableContextUiValues>({
      ...uiSettings,
    });
  }, [
    uiSettings.fixedcellheight,
    uiSettings.hasDynamicTableHeight,
    uiSettings.mincellheight,
    uiSettings.minTableWidth,
    uiSettings.maxTableHeight,
  ]);

  const toolbar = useMemo(() => {
    if (!toolbarSettings) return undefined;

    return convertTo<TableContextToolbarValues>({
      title: toolbarSettings.title ?? toolbarSettings.titleMemo,
      leftActionButtons:
        toolbarSettings.leftActionButtons ??
        toolbarSettings.leftActionButtonsMemo,
      rightActionButtons:
        toolbarSettings.rightActionButtons ??
        toolbarSettings.rightActionButtonsMemo,
    });
  }, [
    toolbarSettings?.leftActionButtons,
    toolbarSettings?.rightActionButtons,
    toolbarSettings?.title,
  ]);

  const state = useMemo(() => {
    return convertTo<TableContextStateValues>({
      error,
      loading,
      setError,
      setLoading,
      info,
      setInfo,
    });
  }, [error, loading, info]);

  const belowToolbar = useMemo(() => {
    return convertTo<TableContextBelowToolbar>({
      content:
        belowToolbarSettings?.content ?? belowToolbarSettings?.contentMemo,
    });
  }, [belowToolbarSettings?.content]);

  const footer = useMemo(() => {
    return convertTo<TableContextFooter>({
      content: footerSettings?.content ?? footerSettings?.contentMemo,
      Content: footerSettings?.Content ?? footerSettings?.ContentMemo,
    });
  }, [footerSettings?.content, footerSettings?.Content]);

  const filter = useMemo((): TableContextFilter => {
    return {
      filterRows: filterSettings?.filterRows ?? filterSettings?.filterRowsMemo,
    };
  }, [filterSettings?.filterRows, filterSettings?.filterRowsMemo]);

  const messages = useMemo((): TableContextMessages => {
    return { ...messagesSettings };
  }, [messagesSettings]);

  const tableContext = useMemo((): TableContextValues => {
    return {
      sorting,
      pagination,
      ui,
      toolbar,
      state,
      headCells,
      rows,
      belowToolbar,
      footer,
      filter,
      messages,
      headerSettings,
    };
  }, [
    sorting,
    pagination,
    ui,
    toolbar,
    state,
    headCells,
    rows,
    belowToolbar,
    footer,
    filter,
    messages,
    headerSettings,
  ]);

  useImperativeHandle(ref, () => ({
    ...state,
    reloadPage,
    changePage,
  }));

  return (
    <TableContext.Provider value={tableContext}>
      {children}
    </TableContext.Provider>
  );
};

export const CustomTableContextProvider = forwardRef<
  EnhancedTableRef,
  OwnProps
>(CustomTableContextProviderWithinForwardRef);
