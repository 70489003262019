import { styled } from "@mui/material";
import { StyledInfoItems } from "../../../../../../shared/react/styles/ui/layouts/general.styles";

export const StyledFieldContainer = styled("div")`
  width: 200px;
`;

export const RelativeContainer = styled("div")`
  position: relative;
  padding-left: ${({ theme }) => theme.spacing(1)};
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const CustomStyledInfoItems = styled(StyledInfoItems)`
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(1)};
  gap: ${({ theme }) => theme.spacing(2)};
  min-width: 340px;
`;
