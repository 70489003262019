import { FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FilterValues } from "../shared/types/filter.types";
import { StyledForm } from "./index.styles";
import { FormContentProps } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { TextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { CheckboxFormik } from "../../../../../../../../../shared/common/react/components/form/formik/checkbox/checkboxFormik";

type OwnProps = FormContentProps<FilterValues>;

export const SkillCertificationFilterForm = ({
  formikProps: { values },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledForm>
      <TextFieldFormik
        name="provider"
        label={t("skillCertification.keywords.general.provider")}
        fullWidth
      />

      <FormControlLabel
        label={t("skillCertification.keywords.general.certificationProgram")}
        control={<CheckboxFormik name="certificationProgram" />}
      />
    </StyledForm>
  );
};
