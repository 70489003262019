import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CenteredContent } from "../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { CenteredLoading } from "../../../../../../../../shared/common/react/components/ui/CenteredLoading";
import { CalcStartCaroussel } from "../../../shared/react/utils/CalcStartCaroussel";
import { StyledContainer } from "../../general.styles";
import { SummaryCarouselContextProvider } from "../shared/react/contexts/SummaryCarouselContext";
import { SummaryContext } from "../shared/react/contexts/SummaryContext";
import { SummaryExpensesSummaryWithinContext } from "../SummaryExpensesSummary/SummaryExpensesSummaryWithinContext";
import { SummaryHeader } from "../SummaryHeader";
import { SummaryMvSummaryWithinContext } from "../SummaryMvSummary/SummaryMvSummaryWithinContext";
import { SummaryProjectSummaryWithinContext } from "../SummaryProjectSummary/SummaryProjectSummaryWithinContext";
import { StyledSummaryTab } from "./index.styles";

export const SummaryTabWithinContext = () => {
  const { t } = useTranslation();
  const [positionStart, setPositionStart] = useState<number>(0);

  const {
    isLoadingSummary,
    summaryHasError,
    summaryData,
    summaryTranslationError,
  } = useContext(SummaryContext);

  useEffect(() => {
    if (
      summaryData?.monthlyAmounts.periodStart &&
      summaryData?.monthlyAmounts.periodEnd
    ) {
      const position = CalcStartCaroussel(
        summaryData?.monthlyAmounts.periodStart,
        summaryData?.monthlyAmounts.periodEnd
      );
      return setPositionStart(position);
    }
  }, [summaryData]);

  if (isLoadingSummary) return <CenteredLoading />;
  if (summaryTranslationError)
    return (
      <CenteredContent>
        {t(
          summaryTranslationError.message,
          summaryTranslationError.interpolation
        )}
      </CenteredContent>
    );
  if (summaryHasError)
    return (
      <CenteredContent>
        {t("planningSummary.errors.data.fetch.failedToFetchSummary")}
      </CenteredContent>
    );
  if (!summaryData)
    return (
      <CenteredContent stretchMode="horizontal">
        {t("projects.errors.data.general.projectDoesNotExist")}
      </CenteredContent>
    );

  return (
    <StyledSummaryTab>
      <SummaryHeader />
      <StyledContainer>
        <SummaryCarouselContextProvider
          earliestYearMonth={summaryData.monthlyAmounts.periodStart}
          latestYearMonth={summaryData.monthlyAmounts.periodEnd}
        >
          <SummaryProjectSummaryWithinContext positionStart={positionStart} />
          <SummaryMvSummaryWithinContext positionStart={positionStart} />
          <SummaryExpensesSummaryWithinContext positionStart={positionStart} />
        </SummaryCarouselContextProvider>
      </StyledContainer>
    </StyledSummaryTab>
  );
};
