import { InputAdornment, TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { JobRateDTO } from "../../../../../../../../shared/specific/DTOs/jobRate/JobRateDTO";
import { StyledJobRateContent } from "./index.style";

interface OwnProps {
  jobRateDTO?: JobRateDTO;
}

export const JobRateContent = ({ jobRateDTO }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const billingAmountStartAdornment = useMemo(() => {
    if (!jobRateDTO?.subsidiary) return null;

    return (
      <InputAdornment position="start">
        {jobRateDTO.subsidiary?.currencySymbol}
      </InputAdornment>
    );
  }, [jobRateDTO?.subsidiary?.currencySymbol]);

  if (!jobRateDTO) return null;

  return (
    <StyledJobRateContent>
      <TextField
        label={t("jobRoles.keywords.general.jobRole")}
        value={
          jobRateDTO.jobRole.jobRoleTranslations.find(
            (x) => x.idLanguage === i18n.language
          )?.name ?? jobRateDTO.jobRole.name
        }
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("subsidiaries.keywords.general.subsidiary")}
        value={jobRateDTO.subsidiary.name}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("jobRates.keywords.general.startYearMonth")}
        value={jobRateDTO.startYearMonth}
        InputProps={{ readOnly: true }}
      />

      <TextField
        label={t("jobRates.keywords.general.monthlyRate")}
        value={jobRateDTO.monthlyRate.toLocaleString(i18n.language, {
          minimumFractionDigits: 2,
        })}
        InputProps={{
          readOnly: true,
          startAdornment: billingAmountStartAdornment,
        }}
      />

      <TextField
        label={t("jobRates.keywords.general.monthlyTax")}
        value={jobRateDTO.monthlyTax?.toLocaleString(i18n.language, {
          minimumFractionDigits: 2,
        })}
        InputProps={{
          readOnly: true,
          startAdornment: billingAmountStartAdornment,
        }}
      />
    </StyledJobRateContent>
  );
};
