import * as yup from "yup";
import {
  schemaStateDTO,
  StateDTO,
} from "../../../shared/common/DTOs/publicData/states/StateDTO";
import { Country } from "../../../shared/common/enums/locale/Country.enum";
import { publicConnection } from "./connection";

export const getStates = async (country: Country): Promise<StateDTO[]> => {
  const { data: countries } = await publicConnection.get(
    `data/location/states/${country}.json`
  );

  return yup.array(schemaStateDTO).defined().validateSync(countries);
};
