import { BudgetZorcUploadResponseDTO } from "../../../shared/specific/DTOs/BudgetZorc/BudgetZorcUploadResponseDTO";
import { riseApi } from "./connection";

export const uploadZorc = async (
  files: File,
  IdDivision: string,
  idSegmentGroup: string | null,
  IdSubsidiary: string
): Promise<BudgetZorcUploadResponseDTO | null> => {
  const form = new FormData();
  form.append(`file`, files);
  form.append(`IdSubsidiary`, IdSubsidiary);
  form.append(`IdDivision`, IdDivision);
  if (idSegmentGroup) form.append(`idSegmentGroup`, idSegmentGroup);

  const { data: errorsString } = await riseApi.post(
    "/BudgetZorc/budgetZorcFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return errorsString;
};
