import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { TextFieldFormikWithTranslationsModal } from "../../../../../../../../../shared/common/react/components/form/formik/translatable/TextFieldFormikWithTranslationsModal";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";

interface OwnProps {
  isSubmitting: boolean;
  isLinkedToProject?: boolean;
}

export const SharedFormComponents = ({
  isSubmitting,
  isLinkedToProject,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <>
      <TextFieldFormik
        name="code"
        label={t("general.keywords.fields.code")}
        inputProps={{ maxLength: 200 }}
        required
        disabled={isSubmitting}
      />
      <TextFieldFormikWithTranslationsModal
        name="nameTranslations"
        label={t("general.keywords.fields.name")}
        disabled={isSubmitting}
        inputProps={{ maxLength: 200 }}
        defaultLanguageTextFieldProps={{ required: true }}
      />
      <DivisionsAutocompleteFormik
        name="division"
        textfieldProps={{ required: true }}
        autocompleteProps={{
          disabled: isSubmitting || isLinkedToProject,
        }}
      />
      <SubsidiariesAutocompleteFormik
        name="subsidiary"
        textfieldProps={{ required: true }}
        autocompleteProps={{
          disabled: isSubmitting || isLinkedToProject,
        }}
      />
    </>
  );
};
