import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { throwIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getPipedriveDeals } from "../../../../../../../../../services/projects/projects.service";
import { EditIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { ViewIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { CustomLink } from "../../../../../../../../../shared/common/react/components/navigation/CustomLink";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { PipedriveDealDTO } from "../../../../../../../../../shared/specific/DTOs/pipedrive/PipedriveDealDTO";
import { useDeactivationForm } from "../../../../../ProjectsDeativationDealPage";
import { PipedriveDealFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/pipedrive/filters/PipedriveDealFiltersDTO";
import { useUpdateDealType } from "../../../../hooks/useUpdateDealType";
import { TabCode } from "../../../../shared/types/TabCode.enums";

interface OwnProps {
  reloadTablePage: () => void;
  filters: PipedriveDealFiltersDTO;
}

export const useTableData = ({ reloadTablePage, filters }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("pipedrive.keywords.fields.projectName"),
      t("pipedrive.keywords.fields.opportunityId"),
      t("pipedrive.keywords.fields.customerName"),
      t("pipedrive.keywords.fields.projectBeginDate"),
      t("projects.keywords.fields.approvedCredit"),
      {
        value: "",
        width: 200,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const generateViewProjectLink = (dealData: PipedriveDealDTO) => {
    const valueReturnTo = `${location.pathname}?name=${encodeURIComponent(
      dealData.idProject
    )}`;

    const actualSearchParams = new URLSearchParams();
    actualSearchParams.set("return-to", valueReturnTo);

    return `/registration-of-projects-and-additives/${dealData.id}?${actualSearchParams.toString()}`;
  };

  const generateEditProjectLink = (dealData: PipedriveDealDTO) => {
    const valueReturnTo = `${location.pathname}?name=${encodeURIComponent(
      dealData.idProject
    )}`;

    const actualSearchParams = new URLSearchParams();
    actualSearchParams.set("return-to", valueReturnTo);

    return `/registration-of-projects-and-additives/${
      dealData.id
    }/edit?${actualSearchParams.toString()}`;
  };

  const { DeleteButtonContainer, deactivationModal } = useDeactivationForm({
    reloadTablePage,
  });

  const { ChangeDealTypeButtonContainer, changeDealTypeModal } =
    useUpdateDealType({
      reloadTablePage,
      tab: TabCode.ProjectTab,
    });

  const onTablePageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        const headKeys = [
          "idProject",
          "opportunityId",
          "customerName",
          "projectBeginnigDate",
          "approvedCredit",
        ];

        try {
          const {
            tableData: pipedriveDeals,
            page,
            totalCount,
          } = await getPipedriveDeals({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows: ExternalEnhancedRow[] = pipedriveDeals.map(
            (deal): ExternalEnhancedRow => {
              const row: ExternalEnhancedRow = {
                id: deal.id,
                cells: [
                  deal.idProject,
                  deal.opportunityId ? deal.opportunityId : 0,
                  deal.customerName,
                  {
                    value: deal.projectBeginnigDate
                      ? deal.projectBeginnigDate
                      : "",
                    displayValue: deal.projectBeginnigDate
                      ? Intl.DateTimeFormat(i18n.language).format(
                          deal.projectBeginnigDate
                        )
                      : "",
                  },
                  deal.approvedCredit
                    ? t("general.keywords.general.yes")
                    : t("general.keywords.general.no"),
                  {
                    CellRenderer: () => (
                      <>
                        <CustomLink to={generateViewProjectLink(deal)}>
                          <ViewIconButton />
                        </CustomLink>
                        <Protected
                          restrictions={{
                            type: PermissionType.ProjectRegister,
                            level: PermissionLevel.Update,
                          }}
                        >
                          <CustomLink
                            to={generateEditProjectLink(deal)}
                            disabled={deal.additive === "SIM"}
                          >
                            <EditIconButton
                              disabled={deal.additive === "SIM"}
                            />
                          </CustomLink>
                        </Protected>
                        <Protected
                          restrictions={{
                            type: PermissionType.ProjectRegister,
                            level: PermissionLevel.Update,
                          }}
                        >
                          <ChangeDealTypeButtonContainer {...deal} />
                        </Protected>
                        <Protected
                          restrictions={{
                            type: PermissionType.ProjectRegister,
                            level: PermissionLevel.Delete,
                          }}
                        >
                          <DeleteButtonContainer {...deal} />
                        </Protected>
                      </>
                    ),
                    align: "right",
                    justifyContent: "end",
                    paddingmode: "horizontal",
                  },
                ],
              };

              return row;
            }
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [t, i18n, filters]
    );

  return {
    headCells,
    onTablePageChange,
    deactivationModal,
    changeDealTypeModal,
  };
};
