import { useTranslation } from "react-i18next";
import { DatePickerFormik } from "../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxLengthFields } from "../../shared/constants/form.constants";
import { StyledJobRoleCreationForm } from "./index.styles";
import { FormMode, SkillDataFormValues } from "../../shared/types/form.types";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { SkillCategoriesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/skillCategory/SkillCategoriesAutocompleteFormik";
import { DisciplinesAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DisciplinesAutocompleteFormik";
import { useEffectAfterRenders } from "../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";

type OwnProps = FormContentProps<SkillDataFormValues> & {
  mode: FormMode;
};

export const SkillDataForm = ({
  formikProps: { isSubmitting, values, setFieldValue },
  mode,
}: OwnProps) => {
  const { t } = useTranslation();
  useEffectAfterRenders({
    effect: () => {
      setFieldValue("skillCategory", null);
    },
    deps: [values?.discipline],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledJobRoleCreationForm>
      <TextFieldFormik
        name="namePt"
        label={t("skill.keywords.general.namePt")}
        inputProps={{
          maxLength: maxLengthFields.name,
        }}
        required
        disabled={isSubmitting}
      />

      <TextFieldFormik
        name="nameEn"
        label={t("skill.keywords.general.nameEn")}
        inputProps={{
          maxLength: maxLengthFields.name,
        }}
        disabled={isSubmitting}
      />

      <DisciplinesAutocompleteFormik
        name="discipline"
        autocompleteProps={{
          disabled: isSubmitting,
        }}
        textfieldProps={{
          required: true,
        }}
      />
      <SkillCategoriesAutocompleteFormik
        name="skillCategory"
        autocompleteProps={{
          disabled: isSubmitting || !values.discipline,
        }}
        filters={{
          onlyActivated: true,
          idDiscipline: values.discipline?.id ?? undefined,
        }}
        textfieldProps={{
          required: true,
        }}
      />

      {mode === "editing" && (
        <DatePickerFormik
          name="deactivatedDate"
          datePickerProps={{
            views: ["year", "month", "day"],
            label: t("jobRoles.keywords.general.deactivatedDate"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            maxDate: new Date(),
            disabled: isSubmitting,
          }}
        />
      )}
    </StyledJobRoleCreationForm>
  );
};
