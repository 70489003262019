import { styled } from "@mui/material";

export const StyledCellValueMainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.spacing(1)};
    background-color: rgba(224, 224, 224, 1);
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.spacing(1)};
  }
`;
