import { Paper, styled } from "@mui/material";

export const StyledPlanningTabs = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  width: 100%;
`;

export const StyledTabs = styled("div")`
  position: sticky;
  top: 0px;
  z-index: ${({ theme }) => theme.zIndex.mobileStepper};
  width: 100%;

  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  justify-content: center;
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.background.default};
`;
