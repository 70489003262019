import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useInvoiceRequestFormikConfig } from "../../shared/react/hooks/data/validation/useInvoiceRequestFormikConfig";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CommonInvoiceRequestFormValues } from "../../shared/types/data/form/values.types";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { ViewFormButton } from "../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/ViewFormButton";
import { getInvoiceById } from "../../../../../../../../../services/invoices/invoices.service";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { InvoiceRequestForm } from "../../shared/react/components/content/InvoiceRequestForm";
import { InvoiceTypeTranslationCodeMap } from "../../shared/react/mappers/InvoiceTypeTranslationCodeMap";

export const useViewInvoiceRequest = () => {
  const { initialValues, validationSchema } = useInvoiceRequestFormikConfig();
  const { t } = useTranslation();

  const formikConfig: EntryFormikConfig<CommonInvoiceRequestFormValues> =
    useMemo(() => {
      return {
        initialValues,
        validationSchema,
      };
    }, [initialValues, validationSchema]);

  const {
    ContentButton: ViewingInvoiceRequesButtonContainer,
    contentModal: viewingInvoiceRequestModal,
  } = useFormikModalButton<
    CommonInvoiceRequestFormValues,
    InvoiceDTO,
    InvoiceDTO
  >({
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalFormData }) => {
        if (!internalFormData) return null;

        return (
          <Trans
            i18nKey="general.modal.data.view.detailsOfName"
            values={{ name: internalFormData.milestone }}
            components={{ italic: <em /> }}
          />
        );
      },
    },
    button: {
      FormButton: ViewFormButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: invoice,
        setFormValues,
        getOpenCloseModalCount,
        setInternalFormData,
        setIsLoadingModal,
        setModalError,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();

        try {
          const invoiceData = await getInvoiceById(invoice.id);

          if (!invoice)
            return setModalError(
              t("invoice.errors.data.general.invoiceDoesNotExist")
            );

          setFormValues({
            customer: invoiceData?.customer ?? null,
            costCenterPep: invoiceData?.costCenterPepToInvoice ?? null,
            invoiceType: invoiceData?.invoiceType
              ? {
                  id: invoiceData?.invoiceType,
                  label: t(
                    InvoiceTypeTranslationCodeMap[invoiceData?.invoiceType]
                  ),
                }
              : null,
            invoiceCompetence: invoiceData?.invoiceCompetence
              ? invoiceData.invoiceCompetence.map((x) => x.yearMonth.toDate())
              : [],
            selectedCompetence: null,
            limitDate: invoiceData?.limitDate
              ? invoiceData?.limitDate.toDate()
              : null,
            invoiceFinancialResponsible:
              invoiceData?.invoiceFinancialResponsible
                ? invoiceData?.invoiceFinancialResponsible.map((x) => x.email)
                : [],
            invoiceFinancialResponsibleSelected: "",
            serviceDescription: invoiceData?.serviceDescription ?? "",
            launchInstructions: invoiceData?.launchInstructions ?? "",
            invoiceNote: invoiceData?.invoiceNote ?? "",
            invoiceCopyToUser: invoiceData?.invoiceCopyToUser
              ? invoiceData.invoiceCopyToUser.map((x) => x.userCollaborator)
              : [],
            invoiceFilesToDelete: [],
            invoiceFilesToUpload: [],
            invoiceFilesList: [],
            invoices: invoiceData?.invoiceGroup ?? [],
          });

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalFormData(invoiceData);
        } catch (error) {
          const errorApiErrorDTO = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "invoice.errors.data.fetch.failedToFetchInvoices",
          });

          setModalError(errorApiErrorDTO);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    form: {
      formikConfig,
      FormContentMemo: ({ internalFormData, ...rest }) => (
        <InvoiceRequestForm
          {...rest}
          formMode="viewing"
          invoice={internalFormData ?? undefined}
        />
      ),
    },
  });

  return { ViewingInvoiceRequesButtonContainer, viewingInvoiceRequestModal };
};
