import * as yup from "yup";
import {
  schemaYearMonth,
  YearMonth,
} from "../../../../common/classes/data/date/YearMonth";
import {
  ProjectSummaryExpenseAmounts,
  schemaProjectSummaryExpenseAmountsDTO,
} from "./ProjectSummaryExpenseAmountsDTO";

export interface ProjectSummaryMonthlyExpensesDTO {
  idProject: number;
  monthlyExpenses: ProjectSummaryExpenseAmounts[];
  monthlyPerformedExpenses: ProjectSummaryExpenseAmounts[];
  periodStart: YearMonth | null;
  periodEnd: YearMonth | null;
}

export const schemaProjectSummaryMonthlyExpensesDTO = yup.object({
  idProject: yup.number().integer().defined(),
  monthlyExpenses: yup.array(schemaProjectSummaryExpenseAmountsDTO).defined(),
  monthlyPerformedExpenses: yup
    .array(schemaProjectSummaryExpenseAmountsDTO)
    .defined(),
  periodStart: schemaYearMonth.nullable().defined(),
  periodEnd: schemaYearMonth.nullable().defined(),
});
