import { css } from "@emotion/react";
import { Paper, PaperProps, styled } from "@mui/material";

interface OwnProps extends PaperProps {
  hasDynamicTableHeight?: boolean;
  minTableWidth?: number;
  maxTableHeight?: number | string;
  minTableHeight?: number | string;
}

const IsolatedPaper = ({
  hasDynamicTableHeight,
  minTableWidth,
  maxTableHeight,
  minTableHeight,
  ...rest
}: OwnProps) => <Paper {...rest} />;

export const StyledEnhancedTableContainer = styled(IsolatedPaper)<OwnProps>`
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${({ hasDynamicTableHeight }) =>
    hasDynamicTableHeight &&
    css`
      height: 100%;
    `}

  ${({ maxTableHeight }) => {
    if (typeof maxTableHeight === "string")
      return css`
        max-height: ${maxTableHeight};
      `;
    if (typeof maxTableHeight === "number")
      return css`
        max-height: ${maxTableHeight}px;
      `;
    return null;
  }}

  ${({ minTableHeight }) => {
    if (typeof minTableHeight === "string")
      return css`
        min-height: ${minTableHeight};
      `;
    if (typeof minTableHeight === "number")
      return css`
        min-height: ${minTableHeight}px;
      `;
    return null;
  }}

  ${({ minTableWidth }) =>
    minTableWidth &&
    css`
      .MuiTable-root {
        min-width: ${minTableWidth}px;
      }
    `}

  .MuiTableContainer-root {
    ${({ hasDynamicTableHeight }) =>
      hasDynamicTableHeight &&
      css`
        flex: 1;
      `}
  }
`;
