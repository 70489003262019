import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../shared/common/react/components/table/EnhancedTable";

interface OwnProps {
  rows: ExternalEnhancedRow[];
}

export const SkillsViewTable = ({ rows }: OwnProps) => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("disciplines.keywords.fields.horizontal"),
      t("disciplines.keywords.general.discipline"),
      t("projection.keywords.fields.expenseTypes"),
      t("collaboratorProfile.keywords.fields.skill"),
      {
        value: t("collaboratorProfile.keywords.fields.experience"),
        canSort: false,
        width: 220,
      },
      {
        value: "",
        canSort: false,
        width: 80,
      },
    ];

    return headCells;
  }, [t]);

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        hasDynamicTableHeight: true,
        stickyHeader: true,
        maxTableHeight: 350,
      }}
      rows={rows}
      orderDefaults={{
        defaultOrderBy: "none",
      }}
    />
  );
};
