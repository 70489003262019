import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../services/applicationState/toast.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { YearMonth } from "../../../../../shared/common/classes/data/date/YearMonth";
import { createMonthlyAllocationClosing } from "../../../../../services/monthlyAllocationClosing/monthlyAllocationClosing.service";

interface OwnProps {
  reloadTablePage: () => void;
}

interface SubmitButtonProps {
  yearMonth: YearMonth;
}

export function useCreationForm({ reloadTablePage }: OwnProps) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);

  const onSubmit = async ({ yearMonth }: SubmitButtonProps) => {
    try {
      setSubmitting(true);
      await createMonthlyAllocationClosing({
        yearMonth,
      });

      notifySuccess(
        i18next.t("general.success.data.general.operationExecutedSuccessfully")
      );
      reloadTablePage();
    } catch (error) {
      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "general.errors.data.create.failedToCreate",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const CreationButton: React.FC<SubmitButtonProps> = ({ yearMonth }) => {
    return (
      <div
        style={{
          margin: "8px 0",
        }}
      >
        <LoadingButton
          loading={isSubmitting}
          onClick={() => onSubmit({ yearMonth })}
          variant="contained"
        >
          {t(
            "monthlyAllocationClosing.keywords.actions.closeMonthlyAllocation"
          )}
        </LoadingButton>
      </div>
    );
  };

  return { CreationButton, isSubmitting };
}
