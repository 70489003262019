import { ListItem, styled } from "@mui/material";
import { SidebarIconCss, SidebarProps } from "../index.styles";

export const ListAreaSkeleton = styled(ListItem)<SidebarProps>`
  overflow: hidden;

  & > :first-child {
    ${SidebarIconCss}
    height: 56px;

    .MuiSkeleton-root {
      width: 40px;
      height: 40px;
      min-width: 40px;
    }
  }

  & > * {
    flex-shrink: 0;
  }
`;

export const AvatarListItem = styled(ListItem)`
  overflow-x: hidden;
`;
