import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  UserCurrentJobRoleDTO,
  schemaUserCurrentJobRoleDTO,
} from "./UserCurrentJobRoleDTO";

export type PaginatedUserCurrentJobRoleDTO =
  PaginationResponse<UserCurrentJobRoleDTO>;

export const schemaPaginatedUserCurrentJobRoleDTO =
  getSchemaPaginationResponse<UserCurrentJobRoleDTO>(
    schemaUserCurrentJobRoleDTO as yup.Schema<UserCurrentJobRoleDTO>
  );
