import { FormikProps } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { Box, Button } from "@mui/material";
import {
  StyledButtonsSection,
  StyledForm,
  StyledIcon,
  StyledMultipleSelectionContainer,
  StyledName,
} from "./index.styles";
import { UsersSearchAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import { YearMonthDatePickerFormik } from "../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FilterValues } from "./types/general.types";
import { AllocationPercentageAutocompleteFormik } from "./components/AllocationPercentageAutocompleteFormik";
import { DisciplinesAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/DisciplinesAutocompleteFormik";
import { ContractRolesAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/contractRoles/ContractRolesAutocompleteFormik";
import { AutocompleteInfoBubble } from "../../../../../../../shared/specific/react/components/form/general/accessories/AutocompleteInfoBubble";
import { ProjectSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormikWithoutPermissionType";
import { ProjectStatus } from "../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { SegmentsAutocompleteFormik } from "../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/segment/SegmentsAutocompleteFormik";
import { DivisionEnum } from "../../../../../../../shared/specific/enums/division/DivisionEnum";
import { useEffectAfterRenders } from "../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";

interface OwnProps {
  formikProps: FormikProps<FilterValues>;
  filterIsOpen: boolean;
}

export const CommonFilterForm = ({
  formikProps: { values, setFieldValue, submitForm, resetForm },
  filterIsOpen,
}: OwnProps) => {
  const { t } = useTranslation();

  const segmentIsDisabled = useMemo(() => {
    return (
      values.division?.id === DivisionEnum.HeadOffice ||
      values.division?.id === DivisionEnum.PeD
    );
  }, [values.division]);

  useEffectAfterRenders({
    effect: () => {
      if (values.segment && !values.division) {
        setFieldValue("division", values.segment?.division);
      }

      if (
        values.division &&
        values.segment &&
        values.division.id !== values.segment.division.id
      ) {
        setFieldValue("segment", null);
      }
    },
    deps: [values.segment, values.division],
    rendersBeforeEffect: 1,
  });

  return (
    <StyledForm filterIsOpen={filterIsOpen}>
      <YearMonthDatePickerFormik
        name="startDate"
        datePickerProps={{
          label: t("general.keywords.fields.startDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          maxDate: values.endDate,
        }}
      />
      <YearMonthDatePickerFormik
        name="endDate"
        datePickerProps={{
          label: t("general.keywords.fields.endDate"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
          minDate: values.startDate,
        }}
      />
      <AllocationPercentageAutocompleteFormik
        name="onlyAllocatedCollaborators"
        textfieldProps={{
          label: t("Status"),
        }}
        autocompleteProps={{
          disableCloseOnSelect: true,
          multiple: true,
          renderTags: (value) => {
            return (
              <StyledName>
                <StyledIcon
                  title={
                    <div>
                      {value.map((x, i) => (
                        <div key={i}>{x.label}</div>
                      ))}
                    </div>
                  }
                  arrow
                >
                  <AutocompleteInfoBubble>
                    {value.length > 0 ? `+ ${value.length}` : ""}
                  </AutocompleteInfoBubble>
                </StyledIcon>
              </StyledName>
            );
          },
          renderOption: (props, option) => {
            return (
              <Box
                component="li"
                {...props}
                key={option.id}
                style={{
                  borderBottom: "solid #c0c0c0 1px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <StyledMultipleSelectionContainer>
                  {props["aria-selected"] ? (
                    <CheckBoxIcon color="primary" fontSize="small" />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      color="primary"
                      fontSize="small"
                    />
                  )}

                  {option.label}
                </StyledMultipleSelectionContainer>
              </Box>
            );
          },
        }}
      />
      <DivisionsAutocompleteFormik name="division" />
      <SegmentsAutocompleteFormik
        name="segment"
        filters={{
          idDivision: values.division?.id ?? undefined,
          divisionHasPrefix: true,
        }}
        textfieldProps={{ disabled: segmentIsDisabled }}
        autocompleteProps={{
          disabled: segmentIsDisabled,
        }}
      />
      <div>
        <DisciplinesAutocompleteFormik
          name="discipline"
          autocompleteProps={{
            disableCloseOnSelect: true,
            multiple: true,
            renderTags: (value) => {
              return (
                <StyledName>
                  <StyledIcon
                    title={
                      <div>
                        {value.map((x, i) => (
                          <div key={i}>{x.name}</div>
                        ))}
                      </div>
                    }
                    arrow
                  >
                    <AutocompleteInfoBubble>
                      {value.length > 0 ? `+ ${value.length}` : ""}
                    </AutocompleteInfoBubble>
                  </StyledIcon>
                </StyledName>
              );
            },
            renderOption: (props, option) => {
              return (
                <Box
                  component="li"
                  {...props}
                  key={option.id}
                  style={{
                    borderBottom: "solid #c0c0c0 1px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "5px",
                  }}
                >
                  <StyledMultipleSelectionContainer>
                    {props["aria-selected"] ? (
                      <CheckBoxIcon color="primary" fontSize="small" />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        color="primary"
                        fontSize="small"
                      />
                    )}

                    {option.name}
                  </StyledMultipleSelectionContainer>
                </Box>
              );
            },
          }}
        />
      </div>
      <ContractRolesAutocompleteFormik
        name="contractRoles"
        autocompleteProps={{
          disableCloseOnSelect: true,
          multiple: true,
          renderTags: (value) => {
            return (
              <StyledName>
                <StyledIcon
                  title={
                    <div>
                      {value.map((x, i) => (
                        <div key={i}>{x.name}</div>
                      ))}
                    </div>
                  }
                  arrow
                >
                  <AutocompleteInfoBubble>
                    {value.length > 0 ? `+ ${value.length}` : ""}
                  </AutocompleteInfoBubble>
                </StyledIcon>
              </StyledName>
            );
          },
          renderOption: (props, option) => {
            return (
              <Box
                component="li"
                {...props}
                key={option.id}
                style={{
                  borderBottom: "solid #c0c0c0 1px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <StyledMultipleSelectionContainer>
                  {props["aria-selected"] ? (
                    <CheckBoxIcon color="primary" fontSize="small" />
                  ) : (
                    <CheckBoxOutlineBlankIcon
                      color="primary"
                      fontSize="small"
                    />
                  )}

                  {option.name}
                </StyledMultipleSelectionContainer>
              </Box>
            );
          },
        }}
      />
      <ProjectSearchAutocompleteFormikWithoutPermissionType
        name="project"
        filters={{
          statusIncludeOnly: [ProjectStatus.Ongoing],
        }}
      />
      <UsersSearchAutocompleteFormik
        name="manager"
        textfieldProps={{
          label: t("projects.keywords.fields.manager"),
        }}
        filtersMemo={{
          isActive: true,
          isCollaborator: true,
        }}
      />
      <UsersSearchAutocompleteFormik
        name="coordinator"
        textfieldProps={{
          label: t("projects.keywords.fields.coordinator"),
        }}
        filtersMemo={{
          isActive: true,
          isCollaborator: true,
        }}
      />
      <UsersSearchAutocompleteFormik
        name="collaborator"
        textfieldProps={{
          label: t("usersCollaborators.keywords.general.collaborator"),
        }}
        filtersMemo={{
          isActive: true,
          isCollaborator: true,
        }}
      />
      <StyledButtonsSection>
        <Button onClick={submitForm} variant="contained">
          {t("general.actions.general.search")}
        </Button>
        <Button onClick={() => resetForm()}>
          {t("general.actions.general.clear")}
        </Button>
      </StyledButtonsSection>
    </StyledForm>
  );
};
