import { styled } from "@mui/material";

export const StyledCalendarGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`;

export const StyledCalendarGridCell = styled("div")`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(2)}`};
`;

export const StyledCalendarGridBody = styled(StyledCalendarGridCell)`
  border-color: ${({ theme }) => theme.palette.grey[300]};
  border-style: solid;
  border-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;

  & > :nth-child(7n) {
    border-right-width: 0px;
  }
`;

export const StyledCalendarGridHeader = styled(StyledCalendarGridCell)`
  border-bottom-width: 4px;
  border-bottom-color: ${({ theme }) => theme.palette.grey[300]};
  border-bottom-style: solid;

  text-align: center;
  font-weight: 500;
`;
