import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { maxFieldsLength } from "../../constants/form.constants";
import { FormValues } from "../../types/form.types";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData">;

export const CommonAccessGroupsForm = ({
  formikProps: { isSubmitting },
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <TextFieldFormik
      name="name"
      label={t("accessGroups.keywords.fields.accessGroupName")}
      inputProps={{
        maxLength: maxFieldsLength.name,
      }}
      required
      disabled={isSubmitting}
      fullWidth
    />
  );
};
