import { ExpenseGroup } from "../../enums/expenseGroup/ExpenseGroup.enum";

export const expenseGroupTranslationCodeMap: {
  readonly [key in ExpenseGroup]: string;
} = {
  [ExpenseGroup.AdmCorp]: "projection.keywords.fields.admCorp",
  [ExpenseGroup.Dol]: "projection.keywords.fields.dol",
  [ExpenseGroup.Sgea]: "projection.keywords.fields.sgAndA",
  [ExpenseGroup.GrossRevenue]: "projection.keywords.fields.grossRevenue",
  [ExpenseGroup.Tax]: "projection.keywords.fields.tax",
  [ExpenseGroup.SalesCost]: "projection.keywords.fields.salesCost",
  [ExpenseGroup.Pdd]: "projection.keywords.fields.pdd",
  [ExpenseGroup.NetFinancialExpenses]:
    "projection.keywords.fields.netFinancialExpenses",
  [ExpenseGroup.IrCsll]: "projection.keywords.fields.irCsll",
  [ExpenseGroup.ParticipationProgram]:
    "projection.keywords.fields.participationProgram",
};
