import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { useTableData } from "./hooks/useTableData";
import { useFilterForm } from "./hooks/useFilterForm";
import { useCreationForm } from "./hooks/useCreationForm";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { useLoadCustomerBalance } from "./hooks/useLoadCustomerBalance";
import { uploadCustomerBalanceRestrictions } from "../../../../shared/constants/routing/restrictions/performanceRestrictions.constants";
import { useWorksheetHelp } from "./hooks/useLoadCustomerBalance/useWorksheetHelp";
import { StyledCustomerBalanceUpload } from "./hooks/useLoadCustomerBalance/index.style";
import { useWorksheetHelpPage } from "./hooks/useWorksheetHelpPage";
import { StyledTile } from "./index.styles";

export const CustomerBalancePage = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  useEffect(() => {
    setPageTitle({
      title: t("customerBalance.pages.main.title"),
      Component() {
        return (
          <StyledTile>
            <span>{t("customerBalance.pages.main.title")}</span>
            <UseWorksheetHelpModalPageButtonContainer />
          </StyledTile>
        );
      },
    });
  }, [t]);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { filterButton, filterModal, filters } = useFilterForm();
  const {
    UseWorksheetHelpModalPageButtonContainer,
    useWorksheetHelpModalPage,
  } = useWorksheetHelpPage();
  const { creationButton, creationModal } = useCreationForm({
    reloadTablePage,
  });
  const { LoadCustomerBalances, uploadCustomerBalancesModal } =
    useLoadCustomerBalance();

  const { UseWorksheetHelpModalButtonContainer, useWorksheetHelpModal } =
    useWorksheetHelp();

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, filters],
    rendersBeforeEffect: 1,
  });

  const { headCells, deleteDialog, editingModal, onPageChange } = useTableData({
    filters,
    reloadTablePage,
  });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtonsMemo: (
            <StyledCustomerBalanceUpload>
              <Protected
                restrictions={{
                  type: PermissionType.CustomerBalance,
                  level: PermissionLevel.Create,
                }}
              >
                {creationButton}
              </Protected>

              <Protected restrictions={uploadCustomerBalanceRestrictions}>
                <LoadCustomerBalances />
                <UseWorksheetHelpModalButtonContainer />
              </Protected>
            </StyledCustomerBalanceUpload>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange,
          },
        }}
        orderDefaults={{
          defaultOrderBy: 0,
          defaultOrder: "desc",
        }}
      />
      {deleteDialog}
      {creationModal}
      {editingModal}
      {uploadCustomerBalancesModal}
      {useWorksheetHelpModal}
      {useWorksheetHelpModalPage}
    </>
  );
};
