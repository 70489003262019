import { createContext } from "react";
import { ProjectionFilterValues } from "./ProjectionFilterValues";

export const ProjectionFilterContext = createContext<ProjectionFilterValues>({
  filterValues: {
    subsidiary: null,
    division: null,
    clientAccount: "",
    customerTrigram: "",
    segmentGroup: null,
    year: null,
  },
  setFilterValues: () => null,
});
