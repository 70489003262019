import { useContext, useMemo, useState } from "react";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContextProvider";
import { StyledLine } from "../../shared/index.styles";
import { ExternalEnhancedRow } from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import {
  StyledAreaListLanguages,
  StyledTitleContainer,
  StyledComponentAreas,
} from "./index.styles";
import { DownloadIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { CertificationsViewTable } from "./components/CertificationsViewTable";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";
import { useCreationForm } from "./hooks/useCreationForm";
import { useDeletionForm } from "./hooks/useDeletionForm";
import { getUserFile } from "../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import { LoadingBackdrop } from "../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

export const CertificationsArea = () => {
  const { t, i18n } = useTranslation();
  const { userCollaboratorProfile, mode } = useContext(ProfileContext);

  const [isDownloading, setIsDownloading] = useState(false);

  const isReadOnly = mode === "viewing";

  const { CreationButtonContainer, creationModal } = useCreationForm();
  const { DeleteButtonContainer, deletionModal } = useDeletionForm();

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    if (userCollaboratorProfile?.userCertifications) {
      const rows: ExternalEnhancedRow[] =
        userCollaboratorProfile?.userCertifications.map(
          (certification, index) => ({
            id: index,
            cells: [
              {
                value: certification.skillCertification.name,
              },
              {
                value: certification.skillCertification.provider,
              },
              {
                displayValue: format(
                  certification.dateOfAcquisition,
                  i18n.language === Language.ptBR ? "dd/MM/yyyy" : "MM/dd/yyyy"
                ),
                value: certification.dateOfAcquisition,
              },
              {
                displayValue: certification.expirationDate
                  ? format(
                      certification.expirationDate,
                      i18n.language === Language.ptBR
                        ? "dd/MM/yyyy"
                        : "MM/dd/yyyy"
                    )
                  : "-",
                value: certification.expirationDate,
              },
              {
                CellRenderer: () => (
                  <>
                    <DownloadIconButton
                      color="primary"
                      onClick={async () => {
                        try {
                          setIsDownloading(true);
                          await getUserFile(certification.filePath);
                        } catch (error) {
                          console.error(error);
                        } finally {
                          setIsDownloading(false);
                        }
                      }}
                    />
                    {!isReadOnly && (
                      <DeleteButtonContainer {...certification} />
                    )}
                    <LoadingBackdrop open={isDownloading} />
                  </>
                ),
              },
            ],
          })
        );

      return rows;
    }

    return [];
  }, [
    userCollaboratorProfile?.userCertifications,
    i18n.language,
    isDownloading,
  ]);

  if (!userCollaboratorProfile) return <div> </div>;
  return (
    <StyledComponentAreas>
      <Typography variant="h6">
        <StyledTitleContainer>
          {t("skillCertification.keywords.general.certification")}
          {!isReadOnly && CreationButtonContainer}
        </StyledTitleContainer>
        <StyledLine />
      </Typography>
      <StyledAreaListLanguages>
        {userCollaboratorProfile.userCertifications.length > 0 ? (
          <CertificationsViewTable rows={rows} />
        ) : (
          <Typography>
            {t("collaboratorProfile.keywords.warnings.dontHaveCertifications")}
          </Typography>
        )}
      </StyledAreaListLanguages>
      {creationModal}
      {deletionModal}
    </StyledComponentAreas>
  );
};
