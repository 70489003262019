import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  CommonProjectForm,
  CommonProjectFormRef,
} from "../../shared/react/components/content/CommonAdditionalInformationForm";
import { getProjectAdditionalInformation } from "../../../../../../services/projects/projects.service";
import { setPageTitle } from "../../../../../../services/applicationState/pageData.service";

type OwnParams = {
  id: string;
};

export const AdditionalInformation = () => {
  const { id: idFromRoute = "" } = useParams<OwnParams>();

  const formRef = useRef<CommonProjectFormRef>(null);

  const id = useMemo(() => {
    return /^\d+$/.test(idFromRoute) ? parseInt(idFromRoute) : null;
  }, []);

  useEffect(() => {
    const fetchSetProjects = async () => {
      if (!id) {
        formRef.current?.setError("general.errors.data.fields.id.invalid");
        formRef.current?.setLoading(false);
        return;
      }

      try {
        const project = await getProjectAdditionalInformation(id);
        if (!project) {
          return formRef.current?.setError(
            "projects.errors.data.general.projectDoesNotExist"
          );
        }

        setPageTitle(project.name);

        formRef.current?.setProjectData(project);
        formRef.current?.setFormValues({
          customerContact: project.customerContact ?? "",
          projectChallenges: project.projectChallenges ?? "",
          projectResults: project.projectResults ?? "",
          solutionAdoptedExpectedResults:
            project.solutionAdoptedExpectedResults ?? "",
          isConfidential: project.isConfidential ?? false,
          projectKeyword: project.projectKeyword,
          projectPipedriveServiceLines: project.projectPipedriveServiceLines,
          projectSkill: project.projectSkill,
        });
      } catch (error) {
        console.error(error);
        formRef.current?.setError(
          "projects.errors.data.fetch.failedToFetchProjects"
        );
      } finally {
        formRef.current?.setLoading(false);
      }
    };

    fetchSetProjects();
  }, []);

  return <CommonProjectForm mode="viewing" ref={formRef} />;
};
