import { Info as InfoIcon } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getAccessGroupByIdUserCollaborator } from "../../../../../../../../../../services/accessGroups/accessGroups.service";
import {
  EnhancedTable,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../common/react/components/table/EnhancedTable";
import { useModalButton } from "../../../../../../../../../common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../../helpers/data/errors/apiError4xx.helpers";
import { StyledInfoIconContainer } from "./index.styles";

export const useModalUserGroups = () => {
  const { t } = useTranslation();

  const {
    ModalButtonContainer: UserGroupsModalButtonContainer,
    contentModal: userGroupsModal,
  } = useModalButton<
    {
      headCells: ExternalEnhancedHeadCell[];
      rows: ExternalEnhancedRow[];
      collaboratorName: string;
    },
    {
      collaboratorId: number;
      collaboratorName: string;
    }
  >({
    button: {
      ModalButton: ({ onClick }) => (
        <Tooltip
          title={t("accessGroups.modal.data.userGroupsModalTitle.title")}
          placement="right"
        >
          <StyledInfoIconContainer>
            <InfoIcon color="primary" onClick={onClick} fontSize="small" />
          </StyledInfoIconContainer>
        </Tooltip>
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: collaboratorData,
        getOpenCloseModalCount,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const { tableData } = await getAccessGroupByIdUserCollaborator(
            collaboratorData.collaboratorId
          );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const headCells: ExternalEnhancedHeadCell[] = [
            {
              value: t(
                "accessGroups.modal.data.userGroupsModalTitle.tableTitle"
              ),
              canSort: false,
            },
          ];

          const rows =
            tableData.length > 0
              ? tableData.map(
                  (group): ExternalEnhancedRow => ({
                    id: group.id,
                    cells: [group.name],
                  })
                )
              : [
                  {
                    id: 1,
                    cells: [
                      t(
                        "accessGroups.modal.data.userGroupsModalTitle.collaboratorWithoutGroups"
                      ),
                    ],
                  },
                ];

          setInternalModalData({
            collaboratorName: collaboratorData.collaboratorName,
            headCells,
            rows,
          });
        } catch (error) {
          const errorApiErrorDTO = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "accessGroups.errors.data.fetch.failedToFetchAccessGroups",
          });

          setModalError(errorApiErrorDTO);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData }) => (
        <>{`${t("accessGroups.modal.data.userGroupsModalTitle.title")} ${
          internalModalData?.collaboratorName ?? ""
        }`}</>
      ),
      ModalContentMemo: ({ internalModalData }) =>
        internalModalData ? (
          <EnhancedTable
            headCells={internalModalData.headCells}
            rows={internalModalData.rows}
          />
        ) : (
          <> </>
        ),
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
  });

  return { UserGroupsModalButtonContainer, userGroupsModal };
};
