import { useCallback } from "react";
import { FormButtonComponent } from "../shared/types/props.types";

interface OwnProps {
  FormButton?: FormButtonComponent;
  FormButtonMemo?: FormButtonComponent;
  shouldShowBadgeDot: boolean;
}

export const useInternalFormButton = ({
  FormButton,
  FormButtonMemo,
  shouldShowBadgeDot,
}: OwnProps) => {
  const InternalFormButton = useCallback(
    ({ onClick }: { onClick: () => void }) => {
      if (FormButton)
        return (
          <FormButton
            onClick={onClick}
            shouldShowBadgeDot={shouldShowBadgeDot}
          />
        );

      if (FormButtonMemo)
        return (
          <FormButtonMemo
            onClick={onClick}
            shouldShowBadgeDot={shouldShowBadgeDot}
          />
        );

      return null;
    },
    [FormButton, shouldShowBadgeDot]
  );

  return InternalFormButton;
};
