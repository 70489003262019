import { TextField } from "@mui/material";
import i18next from "i18next";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../../services/applicationState/toast.service";
import {
  deleteInvoice,
  getInvoiceById,
} from "../../../../../../../../../services/invoices/invoices.service";
import { DeleteIconButton } from "../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  OnConfirm,
  useActionButtonDialog,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { InvoiceDTO } from "../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { TranslatedError } from "../../../../../../../../../shared/specific/errors/general/TranslatedError";
import { getErrorIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { checkIsDateSameProject } from "../../../../../shared/helpers/DTOs/project/general.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { StyledContent } from "./index.styles";

interface OwnProps {
  reloadTablePage: () => void;
  reloadInvoiceSummary: () => void;
}

export const useDeletionForm = ({
  reloadTablePage,
  reloadInvoiceSummary,
}: OwnProps) => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const onConfirm = useCallback<OnConfirm<InvoiceDTO>>(
    async ({ closeModal, internalModalData: invoice }) => {
      if (!invoice) throw new Error("Invoice cannot be null on delete.");

      try {
        const invoiceUpdated = await getInvoiceById(invoice.id);

        if (!invoiceUpdated)
          throw new Error("Invoice cannot be null on delete.");

        await deleteInvoice(invoice.id, invoiceUpdated.version);
        notifySuccess(
          i18next.t(
            "general.success.data.general.operationExecutedSuccessfully"
          )
        );
        reloadTablePage();
        reloadInvoiceSummary();
        closeModal();

        if (
          project.isValid ||
          checkIsDateSameProject(
            project.finalScheduledEndDate,
            invoice.plannedBillingDate
          )
        )
          reloadProject();
      } catch (error) {
        const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
        if (errorApiErrorDTO instanceof TranslatedError)
          return notifyError(
            i18next.t(errorApiErrorDTO.message, errorApiErrorDTO.interpolation)
          );
        if (errorApiErrorDTO instanceof Error)
          return notifyError(errorApiErrorDTO.message);

        notifyError(
          i18next.t("invoice.errors.data.delete.failedToDeleteInvoice")
        );
      }
    },
    [project.isValid, project.finalScheduledEndDate]
  );

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<InvoiceDTO>({
    modal: {
      modalColorVariant: "error",
      modalTitle: t("invoice.modal.data.delete.title"),
      internalLoadingPosition: "title",
      ModalContentMemo: ({ internalModalData: invoice }) => (
        <StyledContent>
          <TextField
            label={t("invoice.keywords.fields.invoiceMilestone")}
            value={invoice?.milestone ?? "-"}
            disabled
          />
        </StyledContent>
      ),
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: externalInvoice,
        setInternalModalData,
      }) => {
        setInternalModalData(externalInvoice);
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirm,
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
