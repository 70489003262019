import { useTranslation } from "react-i18next";
import { EditIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/EditIconButton";
import { ViewIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/ViewIconButton";
import { CustomLink } from "../../../../../../shared/common/react/components/navigation/CustomLink";
import { DivisionDTO } from "../../../../../../shared/specific/DTOs/division/DivisionDTO";
import { ProjectDTO } from "../../../../../../shared/specific/DTOs/project/ProjectDTO";
import { ProjectStatus } from "../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { StyledProjectButtons, StyledProjectInfo } from "./index.styles";

interface OwnProps {
  project: ProjectDTO;
  division: DivisionDTO;
}

export const ProjectInfo = ({ project, division }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledProjectInfo>
      <div>
        [{project.code}] {project.name}
        <StyledProjectButtons>
          <CustomLink to={`/projects/${project.id}`}>
            <ViewIconButton tooltipText="projects.actions.general.viewProject" />
          </CustomLink>
          <Protected
            restrictions={{
              type: PermissionType.Customer,
              level: PermissionLevel.Update,
            }}
          >
            {[ProjectStatus.Finalized, ProjectStatus.Canceled].includes(
              project.status
            ) ? (
              <EditIconButton disabled />
            ) : (
              <CustomLink to={`/projects/${project.id}/edit`}>
                <EditIconButton tooltipText="projects.actions.general.editProject" />
              </CustomLink>
            )}
          </Protected>
        </StyledProjectButtons>
      </div>
      <div>{division.name}</div>
      <div>
        {t("projects.keywords.general.billingInName", {
          name: project.subsidiary.name,
        })}
      </div>
    </StyledProjectInfo>
  );
};
