import { DatePickerFormik, DatePickerFormikProps } from "./DatePickerFormik";

export type YearDatePickerFormikProps<TDate> = DatePickerFormikProps<TDate>;

export const YearDatePickerFormik = <TDate,>({
  datePickerProps,
  ...rest
}: YearDatePickerFormikProps<TDate>) => {
  return (
    <DatePickerFormik
      datePickerProps={{
        // disableMaskedInput: false,
        format: "yyyy",
        ...datePickerProps,
        views: ["year"],
      }}
      {...rest}
    />
  );
};
