import * as yup from "yup";
import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";

import { schemaWaccDTO, WaccDTO } from "./WaccDTO";

export type PaginatedWaccDTO = PaginationResponse<WaccDTO>;

export const schemaPaginatedWaccDTO = getSchemaPaginationResponse<WaccDTO>(
  schemaWaccDTO as yup.Schema<WaccDTO>
);
