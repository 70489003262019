import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../../types/form.types";

export const useCreationEditingDefaultFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      name: "",
      deactivatedDate: null,
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .typeError(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
