import { ProjectStatus } from "../../enums/projects/ProjectStatus.enum";

export const statusToTranslationCodeMap: {
  readonly [key in ProjectStatus]: string;
} = {
  [ProjectStatus.Created]: "projects.keywords.status.created",
  [ProjectStatus.Planning]: "projects.keywords.status.planning",
  [ProjectStatus.Canceled]: "projects.keywords.status.canceled",
  [ProjectStatus.Ongoing]: "projects.keywords.status.ongoing",
  [ProjectStatus.Finalized]: "projects.keywords.status.finalized",
};
