import * as yup from "yup";

export enum ProjectStatus {
  Created = 1,
  Planning = 2,
  Ongoing = 3,
  Finalized = 4,
  Canceled = 5,
}

export const schemaProjectStatus = yup
  .mixed<ProjectStatus>()
  .oneOf(Object.values(ProjectStatus) as ProjectStatus[]);
