import {
  ForwardedRef,
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormContext } from ".";
import {
  FormContextProviderProps,
  FormContextProviderRef,
  FormContextValues,
} from "./index.types";

export type { FormContextProviderProps, FormContextProviderRef };

const FormContextProviderWithinForwardRef = (
  {
    children,
    mode,
    formikProps,
    dealData,
    setDealData,
  }: FormContextProviderProps,
  ref: ForwardedRef<FormContextProviderRef>
) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<ReactNode>(false);

  const contextValue: FormContextValues = useMemo(() => {
    return {
      loading,
      setLoading,
      error,
      setError,
      mode,
      dealData,
      setDealData,
      isSubmitting: formikProps.isSubmitting,
    };
  }, [dealData, loading, error, mode, formikProps.isSubmitting]);

  useImperativeHandle(ref, () => ({
    setLoading,
    setError,
    setDealData,
  }));

  return (
    <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>
  );
};

export const FormContextProvider = forwardRef<
  FormContextProviderRef,
  FormContextProviderProps
>(FormContextProviderWithinForwardRef);
