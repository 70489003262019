import {
  getSchemaPaginationResponse,
  PaginationResponse,
} from "../shared/responses/FilterResponseDTO";
import {
  CorporateSegmentsDTO,
  schemaCorporateSegmentsDTO,
} from "./CorporateSegmentsDTO";

export type PaginatedCorporateSegmentsDTO =
  PaginationResponse<CorporateSegmentsDTO>;

export const schemaPaginatedCorporateSegmentsDTO =
  getSchemaPaginationResponse<CorporateSegmentsDTO>(schemaCorporateSegmentsDTO);
