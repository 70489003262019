import { useCallback, useContext, useEffect, useRef } from "react";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../../../../shared/common/react/components/table/EnhancedTable";
import { UserGroupContext } from "../../context";
import { useBelowToolbarFilters } from "./useBelowToolbarFilters";
import { useTableData } from "./useTableData";

interface OwnProps {
  accessGroupId: number;
}

export const UserGroupTable = ({ accessGroupId }: OwnProps) => {
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const { isLoading } = useContext(UserGroupContext);

  const { belowToolbarFilters, belowToolbarFiltersComponent } =
    useBelowToolbarFilters();

  const reloadTablePage = useCallback(() => {
    return enhancedTableRef.current?.reloadPage();
  }, []);

  const { onCollaboratorsPageChange, headCells, deletionModal } = useTableData({
    accessGroupId,
    filters: { ...belowToolbarFilters },
  });

  useEffect(() => {
    if (!isLoading) reloadTablePage();
  }, [isLoading, belowToolbarFilters]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
          maxTableHeight: 500,
        }}
        orderDefaults={{
          defaultOrderBy: "none",
        }}
        paginationSettings={{
          externalPagination: {
            loadSinglePageContent: onCollaboratorsPageChange,
          },
        }}
        belowToolbarSettings={{
          content: belowToolbarFiltersComponent,
        }}
      />
      {deletionModal}
    </>
  );
};
