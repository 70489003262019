import {
  AddTask as AddTaskIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../../../services/applicationState/toast.service";
import {
  getProjectOnlyById,
  savePlanning,
  validatePlanningSaving,
} from "../../../../../../../../../services/projects/projects.service";
import {
  OnConfirm,
  useActionButtonDialog,
  UseActionButtonDialogRef,
} from "../../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { ProjectPlanningSavingValidationDTO } from "../../../../../../../../../shared/specific/DTOs/project/planningValidation/ProjectPlanningSavingValidationDTO";
import { ProjectOnlyDTO } from "../../../../../../../../../shared/specific/DTOs/project/ProjectOnlyDTO";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { useDelayJustificationForm } from "./hooks/useDelayJustificationForm";
import { PlanningSavingForm } from "./PlanningSavingForm";

interface InternalFormData {
  planningValidation: ProjectPlanningSavingValidationDTO;
  updatedProject: ProjectOnlyDTO;
}

export const usePlanningSaving = () => {
  const { t } = useTranslation();
  const { project, reloadProject } = useProjectContext();

  const planningModalRef = useRef<UseActionButtonDialogRef | null>(null);
  const closePlanningModal = useCallback(
    () => planningModalRef.current?.closeModal(),
    []
  );

  const { delayJustificationModal, openDelayJustificationModal } =
    useDelayJustificationForm({
      closeOuterModal: closePlanningModal,
    });

  const onConfirm = useCallback<OnConfirm<InternalFormData>>(
    async ({ closeModal, internalModalData }) => {
      if (!internalModalData?.updatedProject)
        throw new Error("'updatedProject' cannot be null.");

      if (
        project.finalScheduledEndDate &&
        project.scheduledEndDateWithAdditives &&
        project.finalScheduledEndDate.greaterThan(
          project.scheduledEndDateWithAdditives
        ) &&
        (!project.latestEndDateJustification ||
          project.latestEndDateJustification.lessThan(
            project.finalScheduledEndDate
          ))
      )
        return openDelayJustificationModal(({ setInternalFormData }) => {
          setInternalFormData(internalModalData.updatedProject);
        });

      try {
        await savePlanning({
          idProject: internalModalData.updatedProject.id,
          version: internalModalData.updatedProject.version,
        });
        notifySuccess(
          i18next.t(
            "general.success.data.general.operationExecutedSuccessfully"
          )
        );
        reloadProject({ shouldResetValue: true });
        closeModal();
      } catch (error) {
        notifyIf4xxApiErrorDTO({
          error,
          defaultMessage:
            "projects.errors.data.savePlanning.failedToSavePlanning",
        });
      }
    },
    [
      project.finalScheduledEndDate,
      project.scheduledEndDateWithAdditives,
      project.latestEndDateJustification,
      project.id,
      project.version,
    ]
  );

  const { contentButton: planningSaveButton, contentModal: planningSaveModal } =
    useActionButtonDialog<InternalFormData>({
      button: {
        ModalButton: ({ onClick }) => {
          const { t } = useTranslation();
          const { project } = useProjectContext();

          if (
            ProjectStatus.Planning === project.status ||
            project.projectInvalidatedToGenerateBaseline
          )
            return (
              <Button
                variant="contained"
                onClick={onClick}
                startIcon={<AddTaskIcon />}
              >
                {t("projects.actions.general.generateBaseline")}
              </Button>
            );

          return (
            <Button
              variant="contained"
              onClick={onClick}
              startIcon={<CheckCircleIcon />}
            >
              {t("projects.actions.general.savePlanning")}
            </Button>
          );
        },
        onClickContentButtonMemo: async ({
          getOpenCloseModalCount,
          checkInCurrentModalCount,
          setIsLoadingModal,
          setInternalModalData,
          setModalError,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const [planningValidation, updatedProject] = await Promise.all([
              validatePlanningSaving(project.id),
              getProjectOnlyById(project.id),
            ]);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            if (!planningValidation || !updatedProject)
              return setModalError(
                i18next.t("projects.errors.data.general.projectDoesNotExist")
              );

            setInternalModalData({ planningValidation, updatedProject });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "projects.errors.data.fetch.failedToFetchValidationData",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("projects.modal.data.savePlanning.title"),
        mode: "dialog",
        ModalContentMemo: ({ internalModalData }) =>
          internalModalData?.planningValidation ? (
            <PlanningSavingForm
              projectValidation={internalModalData.planningValidation}
            />
          ) : null,
      },
      defaultDialogActionsProps: {
        confirmButtonLabel: t("general.actions.general.save"),
        checkConfirmDisabledMemo: ({
          hasInternalModalError,
          isLoadingModal,
          internalModalData,
        }) => {
          if (hasInternalModalError || isLoadingModal) return true;
          if (!internalModalData) return false;

          const internalModalDataValues: ProjectPlanningSavingValidationDTO[keyof ProjectPlanningSavingValidationDTO][] =
            Object.values(internalModalData.planningValidation);

          return internalModalDataValues.some((x) => !x);
        },
        onConfirm,
      },
      general: { innerRef: planningModalRef },
    });

  return {
    planningSaveButton,
    planningSaveModal,
    delayJustificationModal,
  };
};
