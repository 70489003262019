import { styled, Toolbar } from "@mui/material";

export const StyledToolbar = styled(Toolbar)`
  flex-shrink: 0;
  padding: ${({ theme }) => theme.spacing(0)};
  padding-top: ${({ theme }) => theme.spacing(1)};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding-left: ${({ theme }) => theme.spacing(2)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;
