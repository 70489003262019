import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isValid } from "date-fns";
import { CommonInvoiceFormValues } from "../../../../types/data/form/values.types";
import { parseLocaleNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { useMinPlannedBillingDate } from "../fields/useMinPlannedBillingDate";
import { useProjectContext } from "../../../../../../../shared/react/contexts/ProjectContext";

export const useInvoiceFormikConfig = () => {
  const { t } = useTranslation();

  const { project } = useProjectContext();

  const initialValues: CommonInvoiceFormValues = useMemo(() => {
    return {
      billingAmount: "",
      milestone: "",
      plannedBillingDate: null,
      delayJustification: "",
    };
  }, []);

  const { minPlannedBillingDate, minPlannedBillingDateErrorMessage } =
    useMinPlannedBillingDate();

  const validationSchema = useMemo(() => {
    return yup.object({
      billingAmount: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      milestone: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      plannedBillingDate: yup
        .date()
        .withMutation((schema) => {
          return !minPlannedBillingDate
            ? schema
            : schema.min(
                minPlannedBillingDate,
                t(minPlannedBillingDateErrorMessage)
              );
        })
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      delayJustification: yup
        .string()
        .when("plannedBillingDate", (plannedBillingDate, schema) => {
          if (!plannedBillingDate[0] || !isValid(plannedBillingDate))
            return schema;

          const getDateToCompare = () => {
            if (
              project?.finalScheduledEndDate &&
              project?.scheduledEndDateWithAdditives
            ) {
              return project?.finalScheduledEndDate >=
                project?.scheduledEndDateWithAdditives
                ? project?.finalScheduledEndDate
                : project?.scheduledEndDateWithAdditives;
            }

            if (project?.finalScheduledEndDate)
              return project.finalScheduledEndDate;

            if (project?.scheduledEndDateWithAdditives)
              return project.scheduledEndDateWithAdditives;

            return null;
          };

          const dateToCompare = getDateToCompare();

          if (dateToCompare) {
            return new Date(plannedBillingDate[0] as unknown as Date) <=
              dateToCompare.toDate()
              ? schema
              : schema.required(
                  t("general.errors.data.fields.general.required")
                );
          }

          return schema;
        }),
    });
  }, [t, minPlannedBillingDate, minPlannedBillingDateErrorMessage]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
