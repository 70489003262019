import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../../../../../../shared/common/react/components/table/EnhancedTable";

interface OwnProps {
  rows: ExternalEnhancedRow[] | undefined;
}

export const ListProjects = ({ rows }: OwnProps) => {
  const { t } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      t("general.keywords.fields.name"),
      t("additives.keywords.fields.scheduledStart"),
      t("projects.keywords.fields.baselineEnd"),
      t("projects.keywords.fields.projectValue"),
      t("additives.keywords.general.valueOfAdditives"),
      t("general.keywords.fields.totalValue"),
      {
        value: "",
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        hasDynamicTableHeight: true,
        stickyHeader: true,
        maxTableHeight: 180,
      }}
      rows={rows}
      orderDefaults={{
        defaultOrderBy: "none",
      }}
    />
  );
};
