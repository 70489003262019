import { ReactNode } from "react";
import { YearMonth } from "../../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { CarouselContextProvider } from "../../../../../../../../../../../shared/common/react/components/table/accessories/Carousel/CarouselContext/CarouselContextProvider";

interface OwnProps {
  children: ReactNode;
  periodStart: YearMonth | null;
  periodEnd: YearMonth | null;
  firstYearMonth?: number;
}

export const RevenueInvoicesCarouselContextProvider = ({
  children,
  periodStart,
  periodEnd,
  firstYearMonth,
}: OwnProps) => {
  return (
    <CarouselContextProvider
      initialEarliestYearMonth={periodStart}
      initialLatestYearMonth={periodEnd}
      firstYearMonth={firstYearMonth}
    >
      {children}
    </CarouselContextProvider>
  );
};
