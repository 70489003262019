export const convertDateToUTC = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const convertToBrazilianTimezone = (date: Date) => {
  const brazilianTimezoneOffsetMs = 180 * 60 * 1000;

  const brazilianDate = convertDateToUTC(date);
  brazilianDate.setTime(brazilianDate.getTime() - brazilianTimezoneOffsetMs);
  return brazilianDate;
};
