import { getMainProjectPepOnly } from "../../../../../../../services/costCenterPeps/costCenterPeps.service";
import { forceFnishProjectNoMatterWhatDTO } from "../../../../../../../services/projects/projects.service";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

export async function forcefinishProject(
  project: {
    idProject: number;
    code: string;
  },
  reloadTablePage: () => void
) {
  try {
    const pep = await getMainProjectPepOnly(project.idProject);

    await forceFnishProjectNoMatterWhatDTO({
      code: project.code,
      idProject: project.idProject,
      pepCode: pep?.pepCode ?? "",
    });
  } catch (error) {
    notifyIf4xxApiErrorDTO({
      error,
      defaultMessage: "Falha ao fechar projeto!",
    });
  } finally {
    reloadTablePage();
  }
}
