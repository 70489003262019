import { formatNumber } from "../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledFixedColumn } from "./index.styles";

interface OwnProps {
  info?: number;
  isRead?: boolean;
  currency: string;
  isPercentage: boolean;
}

export const FixedLastColumnMainRow = ({
  info,
  isRead,
  currency,
  isPercentage,
}: OwnProps) => {
  const formattedValue =
    info || info === 0 ? formatNumber(info, { fractionDigits: 2 }) : "-";
  return (
    <StyledFixedColumn isread={isRead ? "true" : "false"}>
      <span>{`${currency} ${formattedValue}${isPercentage ? " %" : ""}`}</span>
    </StyledFixedColumn>
  );
};
