import { styled } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

export const StyledUserGroupModal = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledAddCollaboratorField = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  & > :nth-child(1) {
    flex-grow: 1;
  }
`;

export const StyledAddIconButton = styled(AddBoxIcon)`
  font-size: 44px;
`;
