import { useField } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomAutocomplete,
  CustomAutocompleteProps,
} from "../../../general/autocomplete/general/CustomAutocomplete";

export type AutocompleteFormikProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  CustomAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "autocompleteProps"
> & {
  autocompleteProps: Omit<
    CustomAutocompleteProps<
      T,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "setValue"
  >;
  name: string;
};

export interface AutocompleteFormikRef {
  setExistingOptionFromValue: () => void;
}

export const AutocompleteFormik = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  ...rest
}: AutocompleteFormikProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();
  const [{ multiple, onChange, ...field }, { error }, { setValue }] =
    useField(name);

  const finalErrorString = useMemo(() => {
    if (typeof error !== "object") return error;

    console.error(error);
    return t("general.errors.data.fields.autocomplete.invalidOption");
  }, [error, t]);

  return (
    <CustomAutocomplete
      textfieldProps={{
        error: !!error,
        helperText: finalErrorString,
        ...textfieldProps,
      }}
      autocompleteProps={{ ...field, ...autocompleteProps, setValue }}
      {...rest}
    />
  );
};
