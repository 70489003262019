import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { FilterFormValues } from "../types/filter.types";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { CostCentersSearchAutocompleteFormikWithoutPermissionType } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormikWithoutPermissionType";
import { CalculatedFeeFiltersDTO } from "../../../../../../../../../shared/specific/DTOs/calculatedFee/CalculateddFeeFiltersDTO";
import { ButtonArea, StyledFormContainer } from "./form.styles";

type OwnProps = FormikProps<FilterFormValues> & {
  setFilters: Dispatch<SetStateAction<CalculatedFeeFiltersDTO | null>>;
};
export const FilterForm = ({
  submitForm,
  isSubmitting,
  resetForm,
  setFilters,
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledFormContainer>
      <YearMonthDatePickerFormik
        name="yearMonth"
        datePickerProps={{
          label: t("brlParity.keywords.fields.yearMonth"),
          slotProps: {
            popper: {
              placement: "right",
            },
          },
        }}
        textFieldProps={{
          required: true,
        }}
      />
      <SubsidiariesAutocompleteFormik
        name="subsidiary"
        textfieldProps={{
          required: true,
        }}
      />
      <CostCentersSearchAutocompleteFormikWithoutPermissionType name="costCenter" />
      <ButtonArea>
        <Button
          onClick={() => {
            setFilters(null);
            return resetForm();
          }}
        >
          {t("general.actions.general.clear")}
        </Button>
        <Button onClick={submitForm} variant="contained">
          {t("general.actions.general.search")}
        </Button>
      </ButtonArea>
    </StyledFormContainer>
  );
};
