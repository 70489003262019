import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  Person as PersonIcon,
  Paid as PaidIcon,
  PriceChange,
} from "@mui/icons-material";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { setPageTitle } from "../../../../../services/applicationState/pageData.service";
import {
  PageCardButton,
  PageCardButtonsList,
} from "../../../../../shared/common/react/components/navigation/PageCardButton";
import { Protected } from "../../../../../shared/specific/react/components/authentication/Protected";
import {
  costCenterReadRestrictions,
  customerReadRestrictions,
  uploadInvoicesReadRestrictions,
  invoicesReadRestrictions,
  customerBalanceReadRestrictions,
  waccReadRestrictions,
  feeReadRestrictions,
} from "../../../../shared/constants/routing/restrictions/controllershipRestrictions.constants";

export const ControllershipPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageTitle(t("controllership.pages.main.title"));
  }, [t]);

  return (
    <PageCardButtonsList>
      <Protected restrictions={costCenterReadRestrictions}>
        <PageCardButton
          title="costCenters.keywords.general.costCenters"
          Icon={PaidIcon}
          link="/cost-centers"
        />
      </Protected>
      <Protected restrictions={customerReadRestrictions}>
        <PageCardButton
          title="customers.keywords.general.customers"
          Icon={PersonIcon}
          link="/customers"
        />
      </Protected>
      <Protected restrictions={invoicesReadRestrictions}>
        <PageCardButton
          title="invoice.keywords.general.invoice"
          Icon={RequestQuoteIcon}
          link="/invoices"
        />
      </Protected>
      <Protected restrictions={uploadInvoicesReadRestrictions}>
        <PageCardButton
          title="uploadPaidOutInvoices.pages.main.card"
          Icon={PriceCheckIcon}
          link="/upload-invoices"
        />
      </Protected>
      <Protected restrictions={customerBalanceReadRestrictions}>
        <PageCardButton
          title="customerBalance.pages.main.title"
          Icon={PointOfSaleIcon}
          link="/customer-balance"
        />
      </Protected>
      <Protected restrictions={waccReadRestrictions}>
        <PageCardButton
          title="wacc.pages.main.title"
          Icon={PriceChange}
          link="/wacc"
        />
      </Protected>

      <Protected restrictions={feeReadRestrictions}>
        <PageCardButton
          title="fee.keywords.general.fees"
          Icon={CreditScoreIcon}
          link="/fee"
        />
      </Protected>
    </PageCardButtonsList>
  );
};
