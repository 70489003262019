import { TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { getPaginatedUsers } from "../../../../../../../../../../../../services/user/user.service";
import {
  SearchAutocompleteFormikProps,
  SearchAutocompleteFormik,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/SearchAutocompleteFormik";
import { UserCollaboratorDTO } from "../../../../../../../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { UsersCollaboratorsFiltersDTO } from "../../../../../../../../../../../../shared/specific/DTOs/user/filters/UsersCollaboratorsFiltersDTO";
import { StyledChip } from "../../../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/styles/UsersSearchAutocompleteFormik.styles";
import {
  StyledAutocompleteFieldReviewInfoBubble,
  StyledDiv,
} from "./UserManagerSearchAutocompleteFormik.styles";

interface OwnProps<DisableClearable extends boolean | undefined = undefined> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: SearchAutocompleteFormikProps<
    UserCollaboratorDTO,
    DisableClearable
  >["autocompleteProps"];
  filters?: Omit<UsersCollaboratorsFiltersDTO, "name">;
  filtersMemo?: Omit<UsersCollaboratorsFiltersDTO, "name">;
  userManagerFromDeal: string;
  UserCollaboratorDTO: UserCollaboratorDTO | null;
}

export const UserManagerSearchAutocompleteDealsFormik = <
  DisableClearable extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  filters,
  filtersMemo,
  userManagerFromDeal,
  UserCollaboratorDTO,
}: OwnProps<DisableClearable>) => {
  const { t } = useTranslation();

  const [hideDealUserManager, setHideDealUserManager] =
    useState<boolean>(false);

  const finalFilters = useMemo(() => {
    return filters ?? filtersMemo;
  }, [filters]);

  const getUsersFinal = useCallback(
    async (name: string) => {
      const paginatedUsers = await getPaginatedUsers({
        filters: { ...finalFilters, name },
        pagination: {
          pageSize: 10,
          columnOrderBy: "name",
          columnOrderByDir: "asc",
        },
      });

      return paginatedUsers.tableData;
    },
    [finalFilters]
  );

  const startAdornment = useMemo(() => {
    if (UserCollaboratorDTO || hideDealUserManager) return undefined;
    if (!userManagerFromDeal) return undefined;
    return (
      <StyledDiv>
        {userManagerFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [userManagerFromDeal, UserCollaboratorDTO, hideDealUserManager]);

  return (
    <SearchAutocompleteFormik
      name={name}
      customErrorMessage={t("users.errors.data.fetch.failedToFetchUsers")}
      fetchOptionsMemo={getUsersFinal}
      textfieldProps={{
        label: t("usersCollaborators.keywords.general.collaborators"),
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
        },
        onFocus: () => setHideDealUserManager(true),
        onBlur: () => setHideDealUserManager(false),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (x) =>
          `${x.name ?? x.login ?? String(x.id)} ${
            x.resignationDate
              ? `(${t("jobRates.keywords.general.deactivated").toUpperCase()})`
              : ""
          }`,
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        renderTags: (value, getTagProps) =>
          value.map((option, index) => {
            return (
              <StyledChip
                size="small"
                variant="filled"
                label={option.name}
                {...getTagProps({ index })}
              />
            );
          }),
        ...autocompleteProps,
      }}
    />
  );
};
