import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { FormValuesEapReport } from "./types/form.types";
import { StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { ProjectSearchAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/projects/ProjectSearchAutocompleteFormik";
import { generateEapReport } from "../../../../../../../../../services/reports/reports.service";

export const EapReport = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesEapReport>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesEapReport = {
      project: null,
    };

    const validationSchema = yup.object({
      project: yup
        .object()
        .typeError(t("general.errors.data.fields.general.required"))
        .required(),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesEapReport,
    formikHelpers: FormikHelpers<FormValuesEapReport>
  ) => {
    await generateEapReport(values.project?.id ?? 0);
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting, values }) => (
        <StyledViewContent>
          <ProjectSearchAutocompleteFormik
            name="project"
            autocompleteProps={{
              renderOption: (props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                </Box>
              ),
            }}
            textfieldProps={{
              label: t("projects.keywords.fields.project"),
            }}
          />
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
        </StyledViewContent>
      )}
    </Formik>
  );
};
