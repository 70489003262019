const storedSidebarStateKey = "storedSidebarState";

export const getStoredSidebarState = (): boolean | null => {
  const jsonString = localStorage.getItem(storedSidebarStateKey);
  if (jsonString === null) return null;

  try {
    const isOpen = JSON.parse(jsonString);
    return !!isOpen;
  } catch {
    console.error("Corrupted stored sidebar state.");
    removeStoredSidebarState();
    return null;
  }
};

export const setStoredSidebarState = (isOpen: boolean) => {
  localStorage.setItem(storedSidebarStateKey, JSON.stringify(isOpen));
};

export const removeStoredSidebarState = () => {
  localStorage.removeItem(storedSidebarStateKey);
};
