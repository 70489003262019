import { useTranslation } from "react-i18next";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { SomePartial } from "../../../../../types/core/typescript.types";
import { SimpleIconButton, SimpleIconButtonProps } from "./SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const ProfileIconButton = ({
  tooltipText = "general.actions.general.view",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={AccountCircleIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
