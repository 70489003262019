import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store";
import { DashboardGroupsFormValues } from "../../types/DashboardGroupsFormValues";
import { createTranslatableFieldValues } from "../../../../../../../shared/common/helpers/data/form/languages/factories.helpers";
import { makeTranslatableFieldValuesFormSchema } from "../../../../../../../shared/common/helpers/data/form/languages/schema.helpers";
import { Language } from "../../../../../../../shared/common/enums/locale/Language.enum";

export const useCreationEditingFormikConfig = () => {
  const { t } = useTranslation();
  const defaultLanguage = useSelector(
    (state: RootState) => state.language.defaultLanguage
  );

  const initialValues: DashboardGroupsFormValues = useMemo(() => {
    return {
      nameTranslations: createTranslatableFieldValues(),
    };
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      nameTranslations: makeTranslatableFieldValuesFormSchema({
        defaultLanguage: defaultLanguage ?? Language.enUS,
        defaultLanguageSchema: yup
          .string()
          .required(t("general.errors.data.fields.general.required")),
        secondaryLanguagesSchema: yup.string(),
      }).required(),
    });
  }, [t]);

  return useMemo(() => {
    return { initialValues, validationSchema };
  }, [initialValues, validationSchema]);
};
