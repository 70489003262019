import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { FormikProps } from "formik";
import {
  StyledFormSection,
  StyledLabelChip,
  StyledListFiles,
  StyledGrayBox,
  UploadButtom,
} from "../index.styles";
import { DeleteIconButton } from "../../../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { DownloadIconButton } from "../../../../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DownloadIconButton";
import { getInvoiceFile } from "../../../../../../../../../../../../../services/InvoiceFiles/invoiceFiles.service";
import { InvoiceFilesTypeEnum } from "../../../../../../../../../../../../../shared/specific/enums/invoiceFiles/InvoiceFilesTypeEnum";
import {
  CommonInvoiceRequestFormValues,
  FormMode,
} from "../../../../../types/data/form/values.types";
import { InvoiceDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import { InvoiceFilesDTO } from "../../../../../../../../../../../../../shared/specific/DTOs/InvoiceFiles/InvoiceFilesDTO";
import { LoadingBackdrop } from "../../../../../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

interface OwnProps {
  formikProps: FormikProps<CommonInvoiceRequestFormValues>;
  invoice?: InvoiceDTO;
  formMode: FormMode;
}

export const InvoiceFilesComponent = ({
  formikProps,
  invoice,
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { isSubmitting, values, setFieldValue } = formikProps;

  const [invoiceFilesData, setInvoiceFilesData] = useState<InvoiceFilesDTO[]>(
    []
  );

  const generalReadOnly = formMode === "viewing";

  useEffect(() => {
    if (invoice) {
      setInvoiceFilesData(
        invoice.invoiceFiles.filter(
          (x) => x.invoiceFilesType === InvoiceFilesTypeEnum.Request
        )
      );
    }
  }, [t, invoice]);

  useEffect(() => {
    setFieldValue("invoiceFilesList", invoiceFilesData);
  }, [invoiceFilesData]);

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setFieldValue("invoiceFilesToUpload", [
        ...values.invoiceFilesToUpload,
        file,
      ]);
    }
  };

  return (
    <StyledFormSection>
      <Typography variant="h6" component="div">
        {t("invoice.keywords.fields.evidence")}
      </Typography>
      <>
        {!generalReadOnly && (
          <>
            <UploadButtom
              onClick={() => fileInput.current?.click()}
              variant="contained"
              endIcon={<UploadIcon />}
              disabled={isSubmitting}
            >
              {t("invoice.keywords.fields.attachFile")}
            </UploadButtom>
            <input
              accept="application/pdf, image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              ref={fileInput}
              onChange={handleFileSelection}
              style={{ display: "none" }}
            />
          </>
        )}
        <StyledListFiles>
          {values.invoiceFilesToUpload.map(
            (invFileUpload, idxInvFileUpload) => {
              return (
                <StyledGrayBox
                  key={idxInvFileUpload}
                  size="medium"
                  label={
                    <StyledLabelChip key={idxInvFileUpload}>
                      {`${invFileUpload?.name}`}
                      <div>
                        <Link
                          href={URL.createObjectURL(invFileUpload)}
                          download
                        >
                          <DownloadIconButton
                            disabled={isSubmitting}
                            color="primary"
                          />
                        </Link>
                        {!generalReadOnly && (
                          <DeleteIconButton
                            disabled={isSubmitting}
                            onClick={() =>
                              setFieldValue(
                                "invoiceFilesToUpload",
                                values.invoiceFilesToUpload.filter(
                                  (inv) => inv?.name !== invFileUpload?.name
                                )
                              )
                            }
                          />
                        )}
                      </div>
                    </StyledLabelChip>
                  }
                />
              );
            }
          )}
          {invoiceFilesData.map((invFileData, i) => {
            const elementLength = invFileData?.filePath.split("/").length;
            return (
              <StyledGrayBox
                key={i}
                size="medium"
                label={
                  <StyledLabelChip key={i}>
                    {`${invFileData?.filePath.split("/")[elementLength - 1]}`}
                    <div>
                      <DownloadIconButton
                        disabled={isSubmitting}
                        color="primary"
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await getInvoiceFile(invFileData.filePath);
                          } catch (error) {
                            console.error(error);
                          } finally {
                            setLoading(false);
                          }
                        }}
                      />
                      {!generalReadOnly && (
                        <DeleteIconButton
                          disabled={isSubmitting}
                          onClick={() => {
                            setInvoiceFilesData(
                              invoiceFilesData.filter(
                                (invFileToDelete) =>
                                  invFileToDelete.id !== invFileData.id
                              )
                            );
                            return setFieldValue("invoiceFilesToDelete", [
                              ...values.invoiceFilesToDelete,
                              invFileData.filePath,
                            ]);
                          }}
                        />
                      )}
                    </div>
                  </StyledLabelChip>
                }
              />
            );
          })}
        </StyledListFiles>
      </>
      <LoadingBackdrop open={loading} />
    </StyledFormSection>
  );
};
