export const findBinarySearch = <T>({
  sortedArray,
  checkDesiredValuePosition,
  onNotFound = "null",
}: {
  sortedArray: T[];
  checkDesiredValuePosition: (item: T) => number;
  onNotFound: "null" | "after" | "before";
}): T | null => {
  let start = 0;
  let end = sortedArray.length - 1;
  let middle = 0;
  while (end >= start) {
    middle = Math.floor((end + start) / 2);
    const desiredValuePosition = checkDesiredValuePosition(sortedArray[middle]);

    if (desiredValuePosition < 0) {
      end = middle - 1;
      continue;
    }
    if (desiredValuePosition > 0) {
      start = middle + 1;
      continue;
    }

    return sortedArray[middle];
  }

  if (onNotFound === "null") return null;

  const indexes = [start, end].sort();
  const values = indexes
    .map((index) => sortedArray[index])
    .filter((x) => x !== undefined);

  if (onNotFound === "before")
    return values.find((x) => checkDesiredValuePosition(x) > 0) ?? null;

  return values.find((x) => checkDesiredValuePosition(x) < 0) ?? null;
};
