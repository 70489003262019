import { FrameFilterDTO } from "../../../shared/specific/DTOs/frame/filters/FramesFiltersDTO";
import { FrameCreateDTO } from "../../../shared/specific/DTOs/frame/FrameCreateDTO";
import {
  FrameOnlyDTO,
  schemaFrameOnlyDTO,
} from "../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import {
  FramePepDTO,
  schemaFramePepDTO,
} from "../../../shared/specific/DTOs/frame/FramePepDTO";
import { FrameUpdateDTO } from "../../../shared/specific/DTOs/frame/FrameUpdateDTO";
import {
  PaginatedFrameOnlyDTO,
  schemaPaginatedFrameOnlyDTO,
} from "../../../shared/specific/DTOs/frame/PaginatedFrameOnlyDTO";
import {
  ProjectByIdFrameDTO,
  schemaProjectByIdFrameDTO,
} from "../../../shared/specific/DTOs/frame/ProjectByIdFrameDTO";
import {
  ProjectSummaryCompleteDTO,
  schemaProjectSummaryCompleteDTO,
} from "../../../shared/specific/DTOs/project/summary/ProjectSummaryCompleteDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getFramesOnly = async ({
  filters,
  pagination,
}: {
  filters?: FrameFilterDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedFrameOnlyDTO> => {
  const { data: frames } = await riseApi.get("/frames/only", {
    params: {
      ...pagination,
      ...filters,
    },
  });

  return schemaPaginatedFrameOnlyDTO.validateSync(frames);
};

export const getFrameById = async (
  frameId: number
): Promise<FrameOnlyDTO | null> => {
  const { data: frames } = await riseApi.get(`/frames/${frameId}`);

  return schemaFrameOnlyDTO.nullable().validateSync(frames);
};

export const getFramePepById = async (
  frameId: number
): Promise<FramePepDTO | null> => {
  const { data: frame } = await riseApi.get(
    `/frames/GetPepsByIdFrame/${frameId}`
  );

  return schemaFramePepDTO.nullable().validateSync(frame);
};

export const createFrame = async (frame: FrameCreateDTO) => {
  await riseApi.post("/frames", frame);
};

export const updateFrame = async (frame: FrameUpdateDTO) => {
  await riseApi.put("/frames", frame);
};

export const deleteFrame = async (id: number, version: string) => {
  await riseApi.delete(`frames/${id}/${version}`);
};

export const getProjectsByIdFrame = async (
  frameId: number
): Promise<ProjectByIdFrameDTO | null> => {
  const { data: projectsByFrames } = await riseApi.get(
    `/frames/GetProjectsByIdFrame/${frameId}`
  );

  return schemaProjectByIdFrameDTO.nullable().validateSync(projectsByFrames);
};

export const getFrameSummary = async (
  idProject: number
): Promise<ProjectSummaryCompleteDTO | null> => {
  const { data: projectSummary } = await riseApi.get(
    `frames/${idProject}/Summary`
  );

  return schemaProjectSummaryCompleteDTO
    .nullable()
    .validateSync(projectSummary);
};
