import { styled } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const StyledForm = styled("div")`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
`;

export const StyledErrorOutlineIcon = styled(ErrorOutlineIcon)`
  cursor: pointer;
`;

export const StyledModalHelperInfos = styled("div")`
  ul {
    margin-left: 12;
  }
  p {
    margin-top: 8;
    margin-bottom: 8;
  }
  img {
    max-width: "100%";
  }
`;
