import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useContext, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikConfig } from "./useFormikConfig";
import {
  EntryFormikConfig,
  useFormikModalButton,
} from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FormValues } from "../types/form.types";
import { UserCollaboratorOnlyDTO } from "../../../../../../../../shared/specific/DTOs/user/UserCollaboratorOnlyDTO";
import { EditFormButton } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { CreationEditingForm } from "../components/CreationEditingForm";
import { updateUserCollaboratorLocality } from "../../../../../../../../services/user/user.service";
import { schemaCountry } from "../../../../../../../../shared/common/enums/locale/Country.enum";
import { notifySuccess } from "../../../../../../../../services/applicationState/toast.service";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { cachedCountries } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/objects/data/cache/cachedFunctions.objects";
import { getStateFormValues } from "../../../../../../../pages/authenticated/Customers/CustomersPage/shared/helpers/data/fetchers.helpers";

export const useEditingForm = () => {
  const { t } = useTranslation();
  const { loadPage } = useContext(ProfileContext);

  const sharedformikConfig = useFormikConfig();

  const formikConfig: EntryFormikConfig<FormValues> = useMemo(() => {
    return {
      initialValues: sharedformikConfig.initialValues,
      validationSchema: sharedformikConfig.validationSchema,
    };
  }, [t, sharedformikConfig]);

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<
      FormValues,
      UserCollaboratorOnlyDTO,
      UserCollaboratorOnlyDTO
    >({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: () => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{ name: "" }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: userCollaborator,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();
          try {
            const [countries] = await Promise.all([cachedCountries.run()]);

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const country =
              countries.find((x) => x.code === userCollaborator.country) ??
              null;

            const { stateAutocomplete, stateTextField } =
              await getStateFormValues({
                country,
                customerState: userCollaborator.state,
              });

            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            setInternalFormData(userCollaborator);
            setFormValues({
              country,
              stateAutocomplete,
              stateTextField,
            });
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "customers.errors.data.fetch.failedToFetchCustomer",
            });

            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: CreationEditingForm,
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues: { stateAutocomplete, stateTextField, ...formValues },
          formikHelpers: { setSubmitting },
          internalFormData,
          closeModal,
        }) => {
          const country = formValues.country?.code ?? "";

          try {
            await updateUserCollaboratorLocality({
              country,
              state: schemaCountry.isValidSync(country)
                ? stateAutocomplete?.abbreviation
                : (stateTextField ?? undefined),
              version: internalFormData?.version,
            });

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "usersCollaborators.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
            await loadPage();
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
