import { ProjectionFormValues } from "../../types/form.types";
import { getAdjustmentSumByMonth } from "./auxFunctions";

function getTotaParticipationProgramPageFunctionsAmount(
  formValues: ProjectionFormValues
) {
  const totalParticipationProgramPageFunctionsPerMonth: number[] = [];
  const rangeMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

  rangeMonths.forEach((m) => {
    const adjustmentMonthRevenue = formValues.adjustmentLines
      ? getAdjustmentSumByMonth(m, formValues.adjustmentLines)
      : 0;
    totalParticipationProgramPageFunctionsPerMonth.push(adjustmentMonthRevenue);
  });

  return totalParticipationProgramPageFunctionsPerMonth;
}

export const participationProgramPageFunctionsPageFunctions = {
  getTotaParticipationProgramPageFunctionsAmount,
};
