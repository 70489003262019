import { ReactNode, useMemo } from "react";
import { FormikValues } from "formik";
import { FormActionsComponent } from "../shared/types/props.types";

interface OwnProps<
  FormValues extends FormikValues,
  InternalFormData = unknown,
> {
  FormActions?: FormActionsComponent<FormValues, InternalFormData>;
  FormActionsMemo?: FormActionsComponent<FormValues, InternalFormData>;
  internalFormData: InternalFormData | null;
  closeModal: () => void;
  isSubmitting: boolean;
  isLoadingModal: boolean;
  modalError: ReactNode;
  resetFormValues: () => void;
  submitFormValues: () => void;
  formValues: FormValues;
  setInternalExternalFormValues: (formValues: FormValues) => void;
  isModalDisabled: boolean;
  setIsModalDisabled: (isModalDisabled: boolean) => void;
}

export const useModalActions = <
  FormValues extends FormikValues,
  InternalFormData,
>({
  FormActions,
  FormActionsMemo,
  internalFormData,
  closeModal,
  isLoadingModal,
  isSubmitting,
  modalError,
  resetFormValues,
  submitFormValues,
  formValues,
  setInternalExternalFormValues,
  isModalDisabled,
  setIsModalDisabled,
}: OwnProps<FormValues, InternalFormData>) => {
  const modalActions = useMemo(() => {
    if (FormActions)
      return (
        <FormActions
          closeModal={closeModal}
          isSubmitting={isSubmitting}
          isLoadingModal={isLoadingModal}
          resetFormValues={resetFormValues}
          submitFormValues={submitFormValues}
          modalError={modalError}
          setInternalExternalFormValues={setInternalExternalFormValues}
          formValues={formValues}
          internalFormData={internalFormData}
          isModalDisabled={isModalDisabled}
          setIsModalDisabled={setIsModalDisabled}
        />
      );

    if (FormActionsMemo)
      return (
        <FormActionsMemo
          closeModal={closeModal}
          isSubmitting={isSubmitting}
          isLoadingModal={isLoadingModal}
          resetFormValues={resetFormValues}
          submitFormValues={submitFormValues}
          modalError={modalError}
          setInternalExternalFormValues={setInternalExternalFormValues}
          formValues={formValues}
          internalFormData={internalFormData}
          isModalDisabled={isModalDisabled}
          setIsModalDisabled={setIsModalDisabled}
        />
      );

    return undefined;
  }, [FormActions, isSubmitting, isLoadingModal, modalError, internalFormData]);

  return modalActions;
};
