import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../services/applicationState/toast.service";
import {
  deleteContractRole,
  getContractRoleById,
} from "../../../../../../services/contractRole/ContractRole.service";
import { DeleteIconButton } from "../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import { useActionButtonDialog } from "../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog/useActionButtonDialog";
import { ContractRoleDTO } from "../../../../../../shared/specific/DTOs/ContractRole/ContractRoleDTO";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useDeletionForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: contractRole }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{ name: contractRole?.name ?? "" }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    general: {
      initialInternalModalData: null as ContractRoleDTO | null,
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: contractRoleData,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const contractRoleDTO = await getContractRoleById(
            contractRoleData.id
          );
          if (!contractRoleDTO) return setModalError(i18next.t(""));

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(contractRoleDTO);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage: "",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: contractRole,
      }) => {
        if (!contractRole) throw new Error("Contract Role cannot be null");

        try {
          await deleteContractRole(contractRole.id, contractRole.version);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          reloadTablePage();
          closeModal();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);
          if (!contractRole?.name)
            return notifyError(
              i18next.t("contractRole.errors.data.delete.faledToDelete")
            );
          notifyError(
            <Trans
              i18nKey="contractRole.errors.data.delete.failedToDeleteName"
              values={{ name: contractRole.name }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
