import { createContext } from "react";
import { FormContextValues } from "./index.types";

const defaultValues: FormContextValues = {
  error: false,
  loading: false,
  mode: "creation",
  projectData: null,
  setError: () => null,
  setLoading: () => null,
  setProjectData: () => null,
  submitManually: () => Promise.resolve(),
  isSubmitting: false,
};

export const FormContext = createContext(defaultValues);
