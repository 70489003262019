import * as yup from "yup";
import { Formik, FormikHelpers, FormikProps } from "formik";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { YearMonthDatePickerFormik } from "../../../../../../../../../shared/common/react/components/form/formik/dateTime/YearMonthDatePickerFormik";
import { FormValuesRevenueAndBilling } from "./types/data/form.types";
import { StyledFormContainer, StyledViewContent } from "./index.styles";
import { GenerateReportButton } from "../shared/components/GenerateReportButton";
import { generateRevenueAndBillingReport } from "../../../../../../../../../services/reports/reports.service";
import { YearMonth } from "../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { notifyIf4xxApiErrorDTO } from "../../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { DivisionsOrAllAutocompleteFormik } from "../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsOrAllAutocompleteFormik";
import { LoadingBackdrop } from "../../../../../../../../../shared/common/react/components/ui/backdrops/LoadingBackdrop";

export const RevenueAndBilling = () => {
  const { t } = useTranslation();

  const formikRef = useRef<FormikProps<FormValuesRevenueAndBilling>>(null);

  const { initialValues, validationSchema } = useMemo(() => {
    const initialValues: FormValuesRevenueAndBilling = {
      startYearMonth: null,
      endYearMonth: null,
      division: null,
    };

    const validationSchema = yup.object({
      startYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.dates.invalidDate")),
      endYearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.dates.invalidDate")),
      division: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });

    return { initialValues, validationSchema };
  }, [t]);

  const formikOnSubmit = async (
    values: FormValuesRevenueAndBilling,
    { setSubmitting }: FormikHelpers<FormValuesRevenueAndBilling>
  ) => {
    if (!values.startYearMonth || !values.endYearMonth || !values.division)
      throw new Error("All required fields must be filled.");

    try {
      await generateRevenueAndBillingReport({
        startYearMonth: YearMonth.createFromDate(values.startYearMonth),
        endYearMonth: YearMonth.createFromDate(values.endYearMonth),
        idDivision:
          typeof values.division.id === "number"
            ? values.division.id
            : undefined,
      });
    } catch (error) {
      console.error(error);

      notifyIf4xxApiErrorDTO({
        error,
        defaultMessage: "reports.errors.data.fetch.failedToGenerateReport",
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
      onSubmit={formikOnSubmit}
      validationSchema={validationSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <StyledViewContent>
          <StyledFormContainer>
            <YearMonthDatePickerFormik
              name="startYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.startPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{ required: true }}
            />
            <YearMonthDatePickerFormik
              name="endYearMonth"
              datePickerProps={{
                label: t("reports.pages.form.endPeriod"),
                slotProps: {
                  popper: {
                    placement: "right",
                  },
                },
              }}
              textFieldProps={{ required: true }}
            />
            <DivisionsOrAllAutocompleteFormik name="division" required />
          </StyledFormContainer>
          <GenerateReportButton
            onClick={submitForm}
            isSubmitting={isSubmitting}
          />
          <LoadingBackdrop open={isSubmitting} />
        </StyledViewContent>
      )}
    </Formik>
  );
};
