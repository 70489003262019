import {
  Language,
  schemaLanguage,
} from "../../../../enums/locale/Language.enum";
import {
  TranslatableFieldValue,
  TranslatableFieldValues,
} from "../../../../types/data/form/translation.types";
import { createTranslatableFieldValues } from "./factories.helpers";

export const formatFromTranslatableFieldValues = <T>({
  source,
  convertItem,
}: {
  source: TranslatableFieldValues;
  convertItem: (entry: TranslatableFieldValue) => T;
}): T[] => {
  const entries = Object.entries(source).filter(
    ([language, value]) =>
      schemaLanguage.isValidSync(language) && typeof value === "string"
  ) as [Language, string][];

  return entries.map(([language, value]) => convertItem({ language, value }));
};

export const formatToTranslatableFieldValues = <T>({
  source,
  convertItem,
}: {
  source: T[];
  convertItem: (entry: T) => TranslatableFieldValue;
}): TranslatableFieldValues => {
  const translatableFieldValues = createTranslatableFieldValues();

  for (const sourceEntry of source) {
    const { language, value } = convertItem(sourceEntry);
    translatableFieldValues[language] = value;
  }

  return translatableFieldValues;
};
