import { TextFieldProps } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteFormik,
  AutocompleteFormikProps,
} from "../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AutocompleteFormik";
import { AutocompleteOption } from "../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { AllocationPercentageEnum } from "../../../../../../../../shared/specific/DTOs/allocationMap/filters/AllocationPercentageEnum";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: Omit<
    AutocompleteFormikProps<
      AutocompleteOption<AllocationPercentageEnum>,
      Multiple,
      DisableClearable,
      FreeSolo
    >["autocompleteProps"],
    "options"
  >;
  shouldReplaceValueOnMount?: boolean;
}

export const AllocationPercentageAutocompleteFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  autocompleteProps,
  textfieldProps,
  shouldReplaceValueOnMount,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t } = useTranslation();

  const options = useMemo(() => {
    return [
      {
        id: AllocationPercentageEnum.Allocated,
        label: t("allocationsMap.keywords.form.allocated100"),
      },
      {
        id: AllocationPercentageEnum.UnderAllocated,
        label: t("allocationsMap.keywords.form.underAllocated"),
      },
      {
        id: AllocationPercentageEnum.Deallocated,
        label: t("allocationsMap.keywords.form.deallocatted"),
      },
    ];
  }, [t]);

  return (
    <AutocompleteFormik
      name={name}
      autocompleteProps={{
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
        options,
      }}
      textfieldProps={textfieldProps}
      shouldReplaceValueOnOptionsChange
      shouldReplaceValueOnMount={shouldReplaceValueOnMount}
    />
  );
};
