import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormValuesAccessManagementModal } from "../types/form.types";

export const useFormikConfigAccessManagement = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const initialValues: FormValuesAccessManagementModal = {
      permissions: [],
    };

    const validationSchema = yup.object({
      permissions: yup.array(yup.object()).defined(),
    });

    return { initialValues, validationSchema };
  }, [t]);
};
