import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../../../../../services/applicationState/toast.service";
import { createUserCollaborator } from "../../../../../../services/user/user.service";
import { convertTo } from "../../../../../../shared/common/helpers/types/converters.helpers";
import { useFormikModalButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { AddFormButton } from "../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/AddFormButton";
import { UserCollaboratorCreateDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorCreateDTO";
import { UserCollaboratorDTO } from "../../../../../../shared/specific/DTOs/user/UserCollaboratorDTO";
import { notifyIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { UserCollaboratorDataForm } from "../shared/react/components/content/UserCollaboratorDataForm";
import { useFormikConfig } from "../shared/react/hooks/data/validation/useFormikConfig";
import { UserCollaboratorDataFormValues } from "../shared/types/data/form/values/values.types";

interface OwnProps {
  reloadTablePage: () => void;
}

export function useCreationForm({ reloadTablePage }: OwnProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formikConfig = useFormikConfig();

  const { contentButton: creationButton, contentModal: creationModal } =
    useFormikModalButton<
      UserCollaboratorDataFormValues,
      UserCollaboratorDTO,
      UserCollaboratorDTO
    >({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("usersCollaborators.modal.data.create.title"),
      },
      button: {
        FormButtonMemo: AddFormButton,
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <UserCollaboratorDataForm
            internalFormData={{ userCollaborator: internalFormData }}
            {...rest}
          />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const createdCollaborator = await createUserCollaborator(
              convertTo<UserCollaboratorCreateDTO>({
                idDiscipline: formValues.discipline?.id,
                idWorkload: formValues.workload?.id,
                admissionDate: formValues.admissionDate || undefined,
                email: formValues.email || undefined,
                login: formValues.login || undefined,
                name: formValues.name,
                isAdmin: false,
                isCollaborator: formValues.isCollaborator?.id ?? false,
                dateOfBirth: formValues.dateOfBirth || new Date(),
              })
            );

            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );

            navigate(`/collaborators/${createdCollaborator.id}/track`);
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "userCollaborator.errors.data.create.failedToCreate",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { creationButton, creationModal };
}
