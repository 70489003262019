import { styled } from "@mui/material";
import { CenteredContent } from "../../../../../../../../shared/common/react/components/ui/CenteredContent";
import { StyledInfoItems } from "../../../shared/react/styles/ui/layouts/general.styles";

export const CenteredHeaderTitle = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(0.25)};
  align-items: center;

  .MuiSvgIcon-root {
    font-size: 20px;
    cursor: pointer;
  }
`;

export const StyledRightSideHeader = styled("div")`
  display: flex;
`;

export const CustomStyledInfoItems = styled(StyledInfoItems)`
  flex-shrink: 1;
`;

export const StyledCenteredContent = styled(CenteredContent)`
  padding: ${({ theme }) => theme.spacing(2)};
`;
