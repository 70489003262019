import { InputAdornment, TextField, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/TextFieldFormik";
import { FormContentProps } from "../../../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CustomersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/customers/CustomersSearchAutocompleteFormik";
import { SubsidiariesAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/general/SubsidiariesAutocompleteFormik";
import { DivisionsAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/divisions/DivisionsAutocompleteFormik";
import {
  StyledFrameFormContainer,
  StyledTotalFrame,
  StyledViewContent,
} from "./index.styles";
import { FormMode, FormValues } from "../../../../types/data/form.types";
import { DatePickerFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/dateTime/DatePickerFormik";
import { FrameOnlyDTO } from "../../../../../../../../../../shared/specific/DTOs/frame/FrameOnlyDTO";
import { LocalizedNumberTextFieldFormik } from "../../../../../../../../../../shared/common/react/components/form/formik/textField/LocalizedNumberTextFieldFormik";
import { generalFieldsConstants } from "../../../../../../../../../../shared/common/constants/data/form/fields.constants";
import { maxLengthFields } from "../../../../constants/form.constants";
import { ListProjects } from "./ListProjects";
import { useEffectAfterRenders } from "../../../../../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { UsersSearchAutocompleteFormik } from "../../../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/users/UsersSearchAutocompleteFormik";

type OwnProps = Omit<FormContentProps<FormValues>, "internalFormData"> & {
  frame?: FrameOnlyDTO;
  mode: FormMode;
};

export const CommonFrameForm = ({
  frame,
  mode,
  formikProps: { values, isSubmitting, setFieldValue },
}: OwnProps) => {
  const { t } = useTranslation();

  const billingAmountStartAdornment = useMemo(() => {
    if (!values.subsidiary) return null;

    return (
      <InputAdornment position="start">
        {values.subsidiary?.currencySymbol}
      </InputAdornment>
    );
  }, [values.subsidiary?.currencySymbol]);

  useEffectAfterRenders({
    effect: () => {
      setFieldValue("costCenter", null);
    },
    deps: [values.subsidiary?.id, values.division?.id],
    rendersBeforeEffect: 1,
  });

  if (mode !== "creation" && !frame) return null;

  const generalReadOnly = mode === "viewing";

  return (
    <StyledViewContent>
      <StyledFrameFormContainer>
        <TextFieldFormik
          name="name"
          label={t("general.keywords.fields.frameName")}
          inputProps={{
            maxLength: maxLengthFields.name,
            readOnly: generalReadOnly,
          }}
          required
          disabled={isSubmitting}
        />
        {generalReadOnly ? (
          <>
            <TextField
              value={frame?.subsidiary.name}
              label={t("subsidiaries.keywords.general.subsidiary")}
              InputProps={{ readOnly: true }}
            />
            <TextField
              value={frame?.division.name}
              label={t("divisions.keywords.general.businessUnit")}
              InputProps={{ readOnly: true }}
            />
            <TextField
              value={frame?.customer.tradingName}
              label={t("customers.keywords.general.customer")}
              InputProps={{ readOnly: true }}
            />
            <TextField
              value={frame?.accountManager?.name}
              label={t("frames.keywords.general.accountManager")}
              InputProps={{ readOnly: true }}
            />
          </>
        ) : (
          <>
            <SubsidiariesAutocompleteFormik
              name="subsidiary"
              textfieldProps={{ required: true }}
              autocompleteProps={{
                disabled: frame?.isLinkedToProject ?? undefined,
              }}
            />
            <DivisionsAutocompleteFormik
              name="division"
              textfieldProps={{ required: true }}
              autocompleteProps={{
                disableClearable: true,
                disabled: frame?.isLinkedToProject ?? undefined,
              }}
              filtersMemo={{ hasPrefix: true }}
            />
            <CustomersSearchAutocompleteFormik
              name="customer"
              autocompleteProps={{
                disabled: frame?.isLinkedToProject ?? undefined,
              }}
            />
            <UsersSearchAutocompleteFormik
              name="accountManager"
              textfieldProps={{
                label: t("frames.keywords.general.accountManager"),
                required: true,
              }}
              filtersMemo={{ isActive: true, isCollaborator: true }}
            />
          </>
        )}
        <DatePickerFormik
          name="startDate"
          datePickerProps={{
            label: t("projects.keywords.fields.baselineStart"),
            slotProps: {
              popper: {
                placement: "right",
              },
            },
            readOnly: generalReadOnly,
            maxDate: values?.endDate,
            disabled: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
        <DatePickerFormik
          name="endDate"
          datePickerProps={{
            label: t("projects.keywords.fields.baselineEnd"),
            slotProps: {
              popper: {
                placement: "left",
              },
            },
            readOnly: generalReadOnly,
            minDate: values?.startDate,
            disabled: isSubmitting,
          }}
          textFieldProps={{
            required: true,
          }}
        />
        <LocalizedNumberTextFieldFormik
          name="amount"
          min={-generalFieldsConstants.maxAmountValue}
          max={generalFieldsConstants.maxAmountValue}
          overwrite="shift"
          padFractionalZeros
          textFieldProps={{
            label: t("general.keywords.fields.billingAmount"),
            required: true,
            disabled: isSubmitting,
            InputProps: {
              startAdornment: billingAmountStartAdornment,
              readOnly: generalReadOnly,
            },
          }}
        />
      </StyledFrameFormContainer>
      {values?.rowsProjects && values?.totalFrame && (
        <>
          <Typography variant="h6" component="div">
            {t("projects.keywords.general.projects")}
          </Typography>
          <ListProjects rows={values.rowsProjects} />
          <StyledTotalFrame>
            <Typography variant="h6" component="div">
              {t("general.keywords.fields.total")}
            </Typography>
            <Typography variant="h6" component="div" color="#707070">
              {values?.totalFrame}
            </Typography>
          </StyledTotalFrame>
        </>
      )}
    </StyledViewContent>
  );
};
