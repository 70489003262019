import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { convertTo } from "../../../../../../../../../../shared/common/helpers/types/converters.helpers";
import { CommonProjectAdditionalInformationFormValues } from "../shared/types/forms/general.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return convertTo<CommonProjectAdditionalInformationFormValues>({
      isConfidential: true,
      customerContact: "",
      solutionAdoptedExpectedResults: "",
      projectChallenges: "",
      projectResults: "",
      projectSkill: [],
      projectKeyword: [],
      projectPipedriveServiceLines: [],
    });
  }, []);

  const validationSchema = useMemo(() => {
    return yup.object({
      customerContact: yup.string().nullable(),
      solutionAdoptedExpectedResults: yup.string().nullable(),
      projectChallenges: yup.string().nullable(),
      projectResults: yup.string().nullable(),
    });
  }, [t]);

  return { initialValues, validationSchema };
};
