import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { InfoIconButton } from "../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/InfoIconButton";

export const useWorksheetHelpPage = () => {
  const { t } = useTranslation();
  const {
    ModalButtonContainer: UseWorksheetHelpModalPageButtonContainer,
    contentModal: useWorksheetHelpModalPage,
  } = useModalButton({
    modal: {
      letModalContentDealWithError: true,
      keepModalMounted: 1000,
      modalTitle: t("customerBalance.modal.data.understandingCustomerBalance"),
      ModalContentMemo: () => {
        const { t } = useTranslation();
        return (
          <div>
            <p>{t("customerBalance.modal.data.customerBalanceRepresets")}</p>
            <p>{t("customerBalance.modal.data.importantRules")}</p>
            <ul>
              <li>{t("customerBalance.modal.data.noZeroAmount")}</li>
              <li>
                {t("customerBalance.modal.data.amountValue")}
                <ul>
                  <li>{t("customerBalance.modal.data.debit")}</li>
                  <li>{t("customerBalance.modal.data.credit")}</li>
                </ul>
              </li>
            </ul>
          </div>
        );
      },
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
    button: {
      ModalButtonMemo: ({ onClick, buttonTooltip }) => (
        <InfoIconButton
          size="large"
          onClick={onClick}
          tooltipText={buttonTooltip}
        />
      ),
    },
  });
  return {
    UseWorksheetHelpModalPageButtonContainer,
    useWorksheetHelpModalPage,
  };
};
