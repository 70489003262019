import { styled } from "@mui/material";

export const SummarySection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const SummarySectionTitle = styled("div")`
  ${({ theme }) => theme.typography.h5}
`;
