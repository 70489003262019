import { useTranslation } from "react-i18next";
import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { CommonPlanningForm } from "../../../../shared/react/components/content/CommonPlanningForm";
import { CommonPlanningFormValues } from "../../../../shared/types/data/form/planning.types";
import { InternalFormData } from "../shared/types/form.types";
import { StyledFormContainer, StyledWarning } from "./index.styles";

type OwnProps = FormContentProps<CommonPlanningFormValues, InternalFormData>;

export const PlanningCreationForm = ({
  internalFormData,
  formikProps,
}: OwnProps) => {
  const { t } = useTranslation();

  if (internalFormData === null) return null;

  return (
    <StyledFormContainer>
      <StyledWarning>
        <div>
          {t("subsidiaries.keywords.general.billingCountry")}:{" "}
          {internalFormData.project.subsidiary.name}
        </div>
        <div>
          {t("costCenterPeps.keywords.general.pep")}:{" "}
          {internalFormData.mainProjectPep?.pepCode ??
            t("general.keywords.abbreviations.notAvaliable")}
        </div>
      </StyledWarning>
      <CommonPlanningForm formikProps={formikProps} />
    </StyledFormContainer>
  );
};
