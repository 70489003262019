import { Button } from "@mui/base";
import { Tooltip, Typography, styled } from "@mui/material";
import { createGridAreaLettersRange } from "../../../../../../../../shared/common/helpers/styles/utils.helpers";

export const StyledViewContent = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "a b c"
    "d d d";
  ${createGridAreaLettersRange(4)}

  gap: ${({ theme }) => theme.spacing(1.5)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const StyledAreaListLanguages = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledAreaTextFields = styled("div")`
  gap: ${({ theme }) => theme.spacing(1.5)};
  display: flex;
  width: 100%;

  & > * {
    width: 35%;
  }
`;

export const StyledButtons = styled(Button)`
  min-width: 35px;
  max-width: 35px;
`;

export const StyledButtonsSection = styled("div")`
  gap: 0;
  display: flex;
  width: max-content;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
`;

export const WarningText = styled(Typography)`
  color: red;
`;

export const StyledMultipleSelectionContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledName = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const StyledIcon = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledYearMonth = styled("div")`
  max-width: 120px;
`;
