import { createContext } from "react";
import { CollapseTableContextValues } from "./index.types";

const defaultValues: CollapseTableContextValues = {
  singlePageLoader: {
    fillPage: async () => null,
  },
  reloadPage: async () => undefined,
  loading: false,
  setLoading: () => null,
  error: false,
  setError: () => null,
  info: false,
  setInfo: () => null,
  headCells: [],
  rows: null,
  fixFirstColumn: false,
  fixLastColumn: false,
};

export const CollapseTableContext = createContext(defaultValues);
