import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "../shared/types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      currency: null,
      performedValue: "",
      plannedValue: "",
      lastBusinessDayValue: "",
      yearMonth: null,
    };
  }, [t]);

  const validationSchema = useMemo(() => {
    return yup.object({
      currency: yup
        .string()
        .typeError(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
      performedValue: yup.string().nullable(),
      plannedValue: yup
        .string()
        .required(t("general.errors.data.fields.general.required")),
      yearMonth: yup
        .date()
        .typeError(t("general.errors.data.fields.dates.invalidDate"))
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      lastBusinessDayValue: yup.string().nullable(),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
