import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AllocationsOfUserDTO } from "../../../../../../../../../../shared/specific/DTOs/allocation/AllocationsOfUserDTO";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { StyledContent } from "./index.styles";

interface OwnProps {
  allocationRow: AllocationsOfUserDTO;
}

export const DeletionForm = ({ allocationRow }: OwnProps) => {
  const { t } = useTranslation();

  return (
    <StyledContent>
      <TextField
        label={t("general.keywords.fields.userColaborator")}
        value={
          (allocationRow.currentCollaboratorJobRoleName ||
            allocationRow.jobRoleName) ??
          "-"
        }
        disabled
      />
      <TextField
        label={t("jobRoles.keywords.general.jobRole")}
        value={
          (allocationRow.currentCollaboratorJobRoleName ||
            allocationRow.jobRoleName) ??
          "-"
        }
        disabled
      />
      <TextField
        label={t("general.keywords.fields.type")}
        value={t(
          employeeAllocationTypeToTranslationCodeMap[
            allocationRow.allocationType
          ]
        )}
        disabled
      />
    </StyledContent>
  );
};
