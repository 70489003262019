import * as yup from "yup";

export interface ProjectionAdjustmentCommentDto {
  id: number;
  comment: string;
}

export const schemaProjectionAdjustmentCommentDto = yup.object({
  id: yup.number().integer().defined(),
  comment: yup.string().defined(),
});
