import { DescriptionTypes } from "../types/form.types";

export const DescriptionTranslationCode: {
  [key in DescriptionTypes]: string;
} = {
  [DescriptionTypes.NigthAdditional]:
    "expenses.keywords.expenseTypeAdditionalDescription.NigthAdditional",
  [DescriptionTypes.AccumulatedFifithPercent]:
    "expenses.keywords.expenseTypeAdditionalDescription.AccumulatedFifithPercent",
  [DescriptionTypes.PurchaseDayOffBoarding]:
    "expenses.keywords.expenseTypeAdditionalDescription.PurchaseDayOffBoarding",
  [DescriptionTypes.DobraPlatNavDoc]:
    "expenses.keywords.expenseTypeAdditionalDescription.DobraPlatNavDoc",
  [DescriptionTypes.Boarding]:
    "expenses.keywords.expenseTypeAdditionalDescription.Boarding",
  [DescriptionTypes.RemuneratedDayOff]:
    "expenses.keywords.expenseTypeAdditionalDescription.RemuneratedDayOff",
  [DescriptionTypes.HoursFifithPercent]:
    "expenses.keywords.expenseTypeAdditionalDescription.HoursFifithPercent",
  [DescriptionTypes.HoursHundredPercent]:
    "expenses.keywords.expenseTypeAdditionalDescription.HoursHundredPercent",
  [DescriptionTypes.Dangerousness]:
    "expenses.keywords.expenseTypeAdditionalDescription.Dangerousness",
  [DescriptionTypes.OnNotice]:
    "expenses.keywords.expenseTypeAdditionalDescription.OnNotice",
};
