import { styled } from "@mui/material";

export const StyledAllocationsYearMonthsTable = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
  max-width: 100%;
`;

export const StyledPeriodWarning = styled("div")`
  text-align: center;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.error.main};
`;
