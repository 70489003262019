import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { ProfileContext } from "../../context/ProfileContextProvider";
import { StyledLine } from "../../shared/index.styles";
import {
  StyledAreaListLanguages,
  StyledModalIcons,
  StyledTitleContainer,
  StyledComponentAreas,
} from "./index.styles";
import { useEditingForm } from "./hooks/useEditingForm";
import { ExternalEnhancedRow } from "../../../../../../../shared/common/react/components/table/EnhancedTable";
import { userSkillTranslationCodeMap } from "../../../../../../../shared/specific/maps/userSkill/userSkillTranslationCodeMap";
import { SkillsViewTable } from "./components/SkillsViewTable";
import { useLevelInformation } from "./hooks/useLevelInformation";

export const SkillsArea = () => {
  const { t, i18n } = useTranslation();
  const { userCollaboratorProfile, mode } = useContext(ProfileContext);

  const isReadOnly = mode === "viewing";

  const { editingModal, EditingButtonContainer } = useEditingForm();
  const { InformationLevelButtonContainer, informationLevelModalHelper } =
    useLevelInformation();

  const rows: ExternalEnhancedRow[] = useMemo(() => {
    if (userCollaboratorProfile?.userSkills) {
      const rows: ExternalEnhancedRow[] =
        userCollaboratorProfile?.userSkills.map((skill, index) => ({
          id: index,
          cells: [
            {
              value: skill.skill.skillCategory.discipline.horizontal
                ?.horizontalTranslation
                ? (skill.skill.skillCategory.discipline.horizontal?.horizontalTranslation.find(
                    (y) => y.idLanguage === i18n.language
                  )?.name ??
                  skill.skill.skillCategory.discipline.horizontal?.name)
                : "-",
            },
            {
              value: skill.skill.skillCategory.discipline.name ?? "-",
            },
            {
              value: skill.skill.skillCategory?.skillCategoryTranslation
                ? (skill.skill.skillCategory.skillCategoryTranslation.find(
                    (y) => y.idLanguage === i18n.language
                  )?.name ?? skill.skill.skillCategory?.name)
                : "-",
            },
            {
              value: skill.skill
                ? (skill.skill.skillTranslation.find(
                    (y) => y.idLanguage === i18n.language
                  )?.name ?? skill.skill?.name)
                : "-",
            },
            {
              value: t(userSkillTranslationCodeMap[skill.level]),
            },
            "",
          ],
        }));

      return rows;
    }

    return [];
  }, [userCollaboratorProfile?.userSkills, i18n.language]);

  if (!userCollaboratorProfile) return <div> </div>;
  return (
    <StyledComponentAreas>
      <Typography variant="h6">
        <StyledTitleContainer>
          {t("collaboratorProfile.keywords.fields.skills")}
          {!isReadOnly && (
            <StyledModalIcons>
              <EditingButtonContainer {...userCollaboratorProfile.userSkills} />
              <InformationLevelButtonContainer />
            </StyledModalIcons>
          )}
        </StyledTitleContainer>
        <StyledLine />
      </Typography>
      <StyledAreaListLanguages>
        {userCollaboratorProfile.userSkills.length > 0 ? (
          <SkillsViewTable rows={rows} />
        ) : (
          <Typography>
            {t("collaboratorProfile.keywords.warnings.dontHaveSkills")}
          </Typography>
        )}
      </StyledAreaListLanguages>
      {!isReadOnly && editingModal}
      {!isReadOnly && informationLevelModalHelper}
    </StyledComponentAreas>
  );
};
