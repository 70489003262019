import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Container, StyledButtonBase } from "./index.styles";
import { UseEditingCellFormContentButtonContentProps } from "..";
import { InvoiceDTO } from "../../../../../../../../../../shared/specific/DTOs/invoice/InvoiceDTO";
import {
  StyledNegativeValue,
  StyledValueWithNegativeAmount,
} from "../../useTableData/index.styles";

interface OwnProps {
  invoice: InvoiceDTO;
  EditingButtonContainer: (
    props: UseEditingCellFormContentButtonContentProps
  ) => JSX.Element | null;
}

export const CellContent = ({ EditingButtonContainer, invoice }: OwnProps) => {
  const { t, i18n } = useTranslation();
  const tooltip = t("invoice.modal.data.edit.issuedDate");

  return (
    <Container>
      <EditingButtonContainer invoice={invoice} key={invoice.id}>
        <Tooltip title={tooltip} placement="top">
          <StyledButtonBase>
            <StyledValueWithNegativeAmount>
              {invoice.issueDate
                ? Intl.DateTimeFormat(i18n.language).format(
                    invoice.issueDate.toDate()
                  )
                : "-"}
              {invoice.negativeInvoicesCancellationReference.length > 0 &&
                invoice.negativeInvoicesCancellationReference.map(
                  (negativeInvoice, i) => (
                    <StyledNegativeValue key={i}>
                      {negativeInvoice.issueDate
                        ? Intl.DateTimeFormat(i18n.language).format(
                            negativeInvoice.issueDate.toDate()
                          )
                        : "-"}
                    </StyledNegativeValue>
                  )
                )}
            </StyledValueWithNegativeAmount>
          </StyledButtonBase>
        </Tooltip>
      </EditingButtonContainer>
    </Container>
  );
};
