import { Avatar, Skeleton } from "@mui/material";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatNameWithOnlyOneFamilyName } from "../../../../../shared/common/helpers/user.helpers";
import { RootState } from "../../../../../store";
import { UserAreaContainer } from "./index.styles";

export const UserArea = () => {
  const userData = useSelector((state: RootState) => state.user.userData);

  const userFirstLastName = useMemo(() => {
    return formatNameWithOnlyOneFamilyName(userData?.userFullName ?? "");
  }, [userData?.userFullName]);

  if (!userData)
    return (
      <UserAreaContainer>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={120} />
      </UserAreaContainer>
    );

  return (
    <UserAreaContainer>
      <Avatar src={userData.avatarUrl} />
      <div>{userFirstLastName}</div>
    </UserAreaContainer>
  );
};
