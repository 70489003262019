import * as yup from "yup";

export enum TypeOfEvidenceEnum {
  Eap = 1,
  Timesheet = 2,
  Bm = 3,
}

export const schemaTypeOfEvidence = yup
  .mixed<TypeOfEvidenceEnum>()
  .oneOf(Object.values(TypeOfEvidenceEnum) as TypeOfEvidenceEnum[]);
