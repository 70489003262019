import * as yup from "yup";
import { riseApi } from "./connection";
import {
  PaginatedCorporateAllocationsOfUserDTO,
  schemaPaginatedCorporateAllocationsOfUserDTO,
} from "../../../shared/specific/DTOs/corporateAllocations/PaginatedAllocationsOfUserDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { CorporateAllocationPaginatedFiltersDTO } from "../../../shared/specific/DTOs/corporateAllocations/filters/CorporateAllocationPaginatedFiltersDTO";
import {
  CorporateAllocationsOfUserDTO,
  schemaCorporateAllocationsOfUserDTO,
} from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationsOfUserDTO";
import { CorporateAllocationsFiltersDTO } from "../../../shared/specific/DTOs/corporateAllocations/filters/CorporateAllocationsFiltersDTO";
import { CorporateAllocationMissingToTotalGetDTO } from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationMissingToTotalGetDTO";
import {
  AllocationPercentageYearMonth,
  schemaAllocationPercentageYearMonth,
} from "../../../shared/specific/DTOs/allocation/AllocationPercentageYearMonth";
import { CorporateAllocationsCreateDTO } from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationsCreateDTO";
import { CorporateAllocationGetDTO } from "../../../shared/specific/DTOs/corporateAllocations/queries/CorporateAllocationGetDTO";
import {
  CorporateAllocationDTO,
  schemaCorporateAllocationDTO,
} from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationDTO";
import { CorporateAllocationModifySingleDTO } from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationModifySingleDTO";
import { CorporateAllocationUploadResponseDTO } from "../../../shared/specific/DTOs/corporateAllocations/CorporateAllocationUploadResponseDTO";

export const getPaginatedCorporateAllocationsPerUser = async ({
  pagination,
  filters,
}: {
  pagination: PaginationDTO;
  filters?: CorporateAllocationPaginatedFiltersDTO;
}): Promise<PaginatedCorporateAllocationsOfUserDTO> => {
  const { data: allocationsPerUserDTO } = await riseApi.get(
    "CorporateAllocations/Paginated",
    {
      params: { ...filters, ...pagination },
    }
  );

  return schemaPaginatedCorporateAllocationsOfUserDTO
    .defined()
    .validateSync(allocationsPerUserDTO);
};

export const getCorporateAllocationsPerUser = async ({
  filters,
}: {
  filters?: CorporateAllocationsFiltersDTO;
}): Promise<CorporateAllocationsOfUserDTO[]> => {
  const { data: allocationsPerUserDTO } = await riseApi.get(
    "CorporateAllocations",
    { params: filters }
  );

  return yup
    .array(schemaCorporateAllocationsOfUserDTO)
    .defined()
    .validateSync(allocationsPerUserDTO);
};

export const getCorporateAllocationSingle = async (
  params: CorporateAllocationGetDTO
): Promise<CorporateAllocationDTO | null> => {
  const { data: allocationsPerUserDTO } = await riseApi.get(
    "CorporateAllocations/Single",
    { params }
  );

  return schemaCorporateAllocationDTO
    .nullable()
    .validateSync(allocationsPerUserDTO);
};

export const getMissingPercentagesToMax = async (
  corporateAllocationMissingToTotalGetDTO: CorporateAllocationMissingToTotalGetDTO
): Promise<AllocationPercentageYearMonth[]> => {
  const { data: allocationsPerUserDTO } = await riseApi.get(
    "CorporateAllocations/RemainingPercentagesToMax",
    { params: corporateAllocationMissingToTotalGetDTO }
  );

  return yup
    .array(schemaAllocationPercentageYearMonth)
    .defined()
    .validateSync(allocationsPerUserDTO);
};

export const createCorporateAllocations = async (
  corporateAllocationsCreateDTO: CorporateAllocationsCreateDTO
) => {
  await riseApi.post("CorporateAllocations", corporateAllocationsCreateDTO);
};

export const modifyCorporateAllocation = async (
  singleDTO: CorporateAllocationModifySingleDTO
) => {
  await riseApi.put("CorporateAllocations", singleDTO);
};

export const deleteCorporateAllocations = async (allocationGroup: number) => {
  await riseApi.delete(`CorporateAllocations/Group/${allocationGroup}`);
};

export const uploadCorporateAllocations = async (
  file: File
): Promise<CorporateAllocationUploadResponseDTO> => {
  const form = new FormData();
  form.append("file", file);

  const { data: collaboratorUploadResponse } = await riseApi.post(
    "CorporateAllocations/corporateAllocationsFile",
    form,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return collaboratorUploadResponse;
};
