import { styled } from "@mui/material";

export const StyledFooter = styled("div")`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(2)}`};
  gap: ${({ theme }) => theme.spacing(1.5)};

  font-size: 1.15rem;
  font-weight: 500;
`;
