import { TabPanel } from "@mui/lab";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ProjectStatus } from "../../../../../../../../../shared/specific/enums/projects/ProjectStatus.enum";
import { PermissionLevel } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { Protected } from "../../../../../../../../../shared/specific/react/components/authentication/Protected";
import { useProjectContext } from "../../../../shared/react/contexts/ProjectContext";
import { ActivitiesTab } from "../../../../tabs/ActivitiesTab";
import { AdditivesTab } from "../../../../tabs/AdditivesTab";
import { AllocationsTab } from "../../../../tabs/AllocationsTab";
import { ExpensesTab } from "../../../../tabs/ExpensesTab";
import { InvoicesTab } from "../../../../tabs/InvoicesTab";
import { RevenueInvoicesTab } from "../../../../tabs/RevenueInvoicesTab";
import { SummaryTab } from "../../../../tabs/SummaryTab";
import { TabCode } from "../../enums/TabCode.enums";
import { StyledTabPanelContent } from "./index.styles";

export const useTabPanels = () => {
  const { t } = useTranslation();
  const { project } = useProjectContext();

  const tabPanels = useMemo(() => {
    const tabPanelsInfo = [
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Activity,
              level: PermissionLevel.Read,
            }}
          >
            <ActivitiesTab />
          </Protected>
        ),
        tabCode: TabCode.Activities,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Invoice,
              level: PermissionLevel.Read,
            }}
          >
            <InvoicesTab />
          </Protected>
        ),
        tabCode: TabCode.Invoice,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Invoice,
              level: PermissionLevel.Read,
            }}
          >
            <Protected
              restrictions={{
                type: PermissionType.Activity,
                level: PermissionLevel.Read,
              }}
            >
              <RevenueInvoicesTab />
            </Protected>
          </Protected>
        ),
        tabCode: TabCode.Revenue,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Allocation,
              level: PermissionLevel.Read,
            }}
          >
            <AllocationsTab />
          </Protected>
        ),
        tabCode: TabCode.Allocations,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Expenses,
              level: PermissionLevel.Read,
            }}
          >
            <ExpensesTab />
          </Protected>
        ),
        tabCode: TabCode.Expenses,
      },
      {
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Activity,
              level: PermissionLevel.Read,
            }}
          >
            <Protected
              restrictions={{
                type: PermissionType.Invoice,
                level: PermissionLevel.Read,
              }}
            >
              <Protected
                restrictions={{
                  type: PermissionType.Expenses,
                  level: PermissionLevel.Read,
                }}
              >
                <Protected
                  restrictions={{
                    type: PermissionType.Allocation,
                    level: PermissionLevel.Read,
                  }}
                >
                  <SummaryTab />
                </Protected>
              </Protected>
            </Protected>
          </Protected>
        ),
        tabCode: TabCode.Summary,
      },
    ];

    if (
      [
        ProjectStatus.Ongoing,
        ProjectStatus.Finalized,
        ProjectStatus.Canceled,
      ].includes(project.status)
    )
      tabPanelsInfo.push({
        component: (
          <Protected
            restrictions={{
              type: PermissionType.Additive,
              level: PermissionLevel.Read,
            }}
          >
            <AdditivesTab />
          </Protected>
        ),
        tabCode: TabCode.Additives,
      });

    return tabPanelsInfo.map(({ component, tabCode }) => (
      <TabPanel value={tabCode} key={tabCode}>
        <StyledTabPanelContent>{component}</StyledTabPanelContent>
      </TabPanel>
    ));
  }, [t]);

  return tabPanels;
};
