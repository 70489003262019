import { PersonOff as PersonOffIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { SomePartial } from "../../../../../../../../../../shared/common/types/core/typescript.types";
import {
  SimpleIconButtonProps,
  SimpleIconButton,
} from "../../../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/SimpleIconButton";

interface OwnProps
  extends SomePartial<Omit<SimpleIconButtonProps, "Icon">, "tooltipText"> {}

export const UserCollaboratorTurnOffIconButton = ({
  tooltipText = "usersCollaborators.actions.general.turnOff",
  color = "primary",
  ...rest
}: OwnProps) => {
  const { t } = useTranslation();

  return (
    <SimpleIconButton
      Icon={PersonOffIcon}
      color={color}
      tooltipText={t(tooltipText)}
      {...rest}
    />
  );
};
