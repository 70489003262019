import { TablePagination } from "@mui/material";
import { useContext, useMemo } from "react";
import { TableContext } from "../shared/contexts/TableContext";
import { StyledTablePaginationContainer } from "./index.styles";

export const EnhancedTablePagination = () => {
  const {
    pagination,
    state: { error },
  } = useContext(TableContext);

  const paginationComponent = useMemo(() => {
    if (!pagination || pagination.rowsCount === 0) return null;

    const onRowsPerPageChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const rowsPerPage = parseInt(event.target.value);
      pagination.onRowsPerPageChange(rowsPerPage);
    };

    return (
      <StyledTablePaginationContainer>
        <TablePagination
          rowsPerPageOptions={pagination.rowsPerPageOptions}
          component="div"
          count={pagination.rowsCount}
          rowsPerPage={pagination.rowsPerPage}
          page={error === false ? pagination.page : 0}
          onPageChange={(_, newPage) => pagination.changePage(newPage)}
          onRowsPerPageChange={onRowsPerPageChange}
          showFirstButton
          showLastButton
        />
      </StyledTablePaginationContainer>
    );
  }, [
    error,
    pagination?.rowsCount,
    pagination?.rowsPerPage,
    pagination?.page,
    pagination?.changePage,
    pagination?.onRowsPerPageChange,
    pagination?.rowsPerPageOptions,
  ]);

  return paginationComponent;
};
