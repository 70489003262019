import { CustomerCreateDTO } from "../../../shared/specific/DTOs/customers/CustomerCreateDTO";
import {
  CustomerDTO,
  schemaCustomerDTO,
} from "../../../shared/specific/DTOs/customers/CustomerDTO";
import { CustomerUpdateDTO } from "../../../shared/specific/DTOs/customers/CustomerUpdateDTO";
import { CustomerCodesFiltersDTO } from "../../../shared/specific/DTOs/customers/filters/CustomerCodesFiltersDTO";
import { CustomerListByFiltersDTO } from "../../../shared/specific/DTOs/customers/filters/CustomerListByFiltersDTO";
import {
  PaginatedCustomerDTO,
  schemaPaginatedCustomerDTO,
} from "../../../shared/specific/DTOs/customers/PaginatedCustomerDTO";
import {
  PaginatedStringDTO,
  schemaPaginatedStringDTO,
} from "../../../shared/specific/DTOs/general/dataTypes/PaginatedStringDTO";
import { PaginationDTO } from "../../../shared/specific/DTOs/shared/pagination/PaginationDTO";
import { riseApi } from "./connection";

export const getCustomers = async ({
  filters,
  pagination,
}: {
  filters?: CustomerListByFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedCustomerDTO> => {
  const { data: customers } = await riseApi.get("/customers", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedCustomerDTO.validateSync(customers);
};

export const getCustomerCodes = async ({
  filters,
  pagination,
}: {
  filters?: CustomerCodesFiltersDTO;
  pagination?: PaginationDTO;
} = {}): Promise<PaginatedStringDTO> => {
  const { data: customerCodes } = await riseApi.get("/customers/codes", {
    params: { ...filters, ...pagination },
  });

  return schemaPaginatedStringDTO.validateSync(customerCodes);
};

export const getCustomerById = async (
  id: number
): Promise<CustomerDTO | null> => {
  const { data: customer } = await riseApi.get(`/customers/${id}`);

  return schemaCustomerDTO.nullable().validateSync(customer);
};

export const deleteCustomerById = async (id: number, version: string) => {
  await riseApi.delete(`/customers/${id}/${version}`);
};

export const createCustomer = async (
  customerCreationDTO: CustomerCreateDTO
) => {
  await riseApi.post("/customers/", customerCreationDTO);
};

export const updateCustomer = async (customerUpdateDTO: CustomerUpdateDTO) => {
  await riseApi.put("/customers/", customerUpdateDTO);
};
