import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { useContext } from "react";
import { useActionButtonDialog } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/dialogButtons/useActionButtonDialog";
import { UserCertificationDTO } from "../../../../../../../../shared/specific/DTOs/userCertification/UserCertificationDTO";
import { DeleteIconButton } from "../../../../../../../../shared/common/react/components/general/buttons/simpleIconButtons/DeleteIconButton";
import {
  deleteCertification,
  getUserCertificationById,
} from "../../../../../../../../services/userCollaboratorProfile/userCollaboratorProfile.service";
import {
  getErrorIf4xxApiErrorDTO,
  getTextIf4xxApiErrorDTO,
} from "../../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import {
  notifyError,
  notifySuccess,
} from "../../../../../../../../services/applicationState/toast.service";
import { ProfileContext } from "../../../context/ProfileContextProvider";
import { TranslatedError } from "../../../../../../../../shared/specific/errors/general/TranslatedError";

export const useDeletionForm = () => {
  const { t } = useTranslation();
  const { loadPage } = useContext(ProfileContext);

  const {
    ModalButtonContainer: DeleteButtonContainer,
    contentModal: deletionModal,
  } = useActionButtonDialog<UserCertificationDTO, UserCertificationDTO>({
    modal: {
      modalColorVariant: "error",
      ModalTitleMemo: ({ internalModalData: certification }) => {
        return (
          <Trans
            i18nKey="general.modal.data.delete.deleteName"
            values={{
              name: certification?.skillCertification.name ?? "",
            }}
            components={{ italic: <em /> }}
          />
        );
      },
      internalLoadingPosition: "title",
    },
    button: {
      ModalButtonMemo: ({ onClick: openModal }) => (
        <DeleteIconButton onClick={openModal} />
      ),
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: certification,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
        getOpenCloseModalCount,
        checkInCurrentModalCount,
      }) => {
        setIsLoadingModal(true);
        const startingOpenCloseModalCount = getOpenCloseModalCount();
        try {
          const certificationData = await getUserCertificationById(
            certification.id
          );

          if (!certificationData)
            return setModalError(
              i18next.t(
                "collaboratorProfile.errors.data.fetch.failedToFetchCertification"
              )
            );

          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          setInternalModalData(certificationData);
        } catch (error) {
          if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

          console.error(error);

          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "collaboratorProfile.errors.data.fetch.failedToFetchCertification",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    defaultDialogActionsProps: {
      confirmButtonLabel: t("general.actions.general.delete"),
      onConfirmMemo: async ({
        closeModal,
        internalModalData: certification,
      }) => {
        if (!certification)
          throw new Error("certification data cannot be null on confirm.");

        try {
          await deleteCertification(certification.id);
          notifySuccess(
            i18next.t(
              "general.success.data.general.operationExecutedSuccessfully"
            )
          );
          closeModal();
          await loadPage();
        } catch (error) {
          const errorApiErrorDTO = getErrorIf4xxApiErrorDTO(error);
          if (errorApiErrorDTO instanceof TranslatedError)
            return notifyError(
              i18next.t(
                errorApiErrorDTO.message,
                errorApiErrorDTO.interpolation
              )
            );
          if (errorApiErrorDTO instanceof Error)
            return notifyError(errorApiErrorDTO.message);

          notifyError(
            <Trans
              i18nKey="collaboratorProfile.errors.data.delete.failedToDeleteCertification"
              values={{ name: certification?.skillCertification.name ?? "" }}
              components={{ italic: <em /> }}
            />
          );
        }
      },
    },
  });

  return { DeleteButtonContainer, deletionModal };
};
