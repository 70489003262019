import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Week } from "../../../../../../../../../../../../../shared/common/enums/data/dates/Week.enum";
import { parseLocaleNumber } from "../../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";
import { AutocompleteOption } from "../../../../../../../../../../../../../shared/common/types/data/form/autocomplete.types";
import { useMinScheduledDate } from "../../../../../../shared/react/hooks/data/fields/useMinScheduledDate";
import {
  ActivityAmountOption,
  IntervalType,
} from "../../../enums/data/form/general.enums";
import { CreateManyActivitiesFormValues } from "../../../types/data/form/values.types";
import { formatNumber } from "../../../../../../../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues = useMemo((): CreateManyActivitiesFormValues => {
    return {
      prefix: "",
      suffix: "",
      sequential: null,
      separatorAfterSequential: "",
      separatorBeforeSequential: "",

      initialScheduledDate: null,
      intervalType: {
        id: IntervalType.Day,
        label: "",
      },
      repeatDays: "",
      repeatMonths: "",
      repeatWeeks: "",
      weekDayToRepeat: {
        id: Week.Sunday,
        label: "",
      },

      activityAmountOption: {
        id: ActivityAmountOption.FixedByActivity,
        label: "",
      },
      activityAmountSize: "",
      generateActivitiesUntil: "",

      readOnly: {
        amountLeft: 0,
      },
    };
  }, []);

  const { minScheduledDate, minScheduledDateErrorMessage } =
    useMinScheduledDate();

  const validationSchema = useMemo(() => {
    return yup.object({
      prefix: yup
        .string()
        .when("suffix", (suffix, schema) =>
          suffix[0]
            ? schema
            : schema.required(
                t(
                  "activities.errors.data.fields.mixed.atLeastOnePrefixSuffixProvided"
                )
              )
        ),
      suffix: yup.string(),
      sequential: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      separatorAfterSequential: yup.string(),
      separatorBeforeSequential: yup.string(),
      initialScheduledDate: yup
        .date()
        .withMutation((schema) =>
          !minScheduledDate
            ? schema
            : schema.min(minScheduledDate, t(minScheduledDateErrorMessage))
        )
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
      intervalType: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
      repeatDays: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("intervalType", (intervalType, schema) => {
          const intervalTypeValue =
            intervalType[0] as unknown as AutocompleteOption<IntervalType>;
          return intervalTypeValue.id !== IntervalType.Day
            ? schema
            : schema.required(t("general.errors.data.fields.general.required"));
        }),
      repeatMonths: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("intervalType", (intervalType, schema) => {
          const intervalTypeValue =
            intervalType[0] as unknown as AutocompleteOption<IntervalType>;
          return intervalTypeValue.id !== IntervalType.Month
            ? schema
            : schema.required(t("general.errors.data.fields.general.required"));
        }),
      repeatWeeks: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .when("intervalType", (intervalType, schema) => {
          const intervalTypeValue =
            intervalType[0] as unknown as AutocompleteOption<IntervalType>;
          return intervalTypeValue.id !== IntervalType.Week
            ? schema
            : schema.required(t("general.errors.data.fields.general.required"));
        }),
      weekDayToRepeat: yup
        .object()
        .when("intervalType", (intervalType, schema) => {
          const intervalTypeValue =
            intervalType[0] as unknown as AutocompleteOption<IntervalType>;
          return intervalTypeValue.id !== IntervalType.Week
            ? schema
            : schema.required(t("general.errors.data.fields.general.required"));
        }),

      activityAmountOption: yup
        .object()
        .required(t("general.errors.data.fields.general.required")),
      activityAmountSize: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .required(t("general.errors.data.fields.general.required")),
      generateActivitiesUntil: yup
        .number()
        .transform((value, originalValue, context) => {
          if (context.isType(originalValue)) return originalValue;
          return parseLocaleNumber(originalValue);
        })
        .test({
          name: "test-does-not-exceed-budget",
          message: t(
            "activities.errors.data.fields.amount.revenueAmountWouldBeGreaterThanBudget"
          ),
          test(value) {
            const amountLeft = parseLocaleNumber(
              formatNumber(this.parent.readOnly.amountLeft as number, {
                fractionDigits: 2,
              })
            );
            return value === undefined || value <= amountLeft;
          },
        })
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t, minScheduledDate, minScheduledDateErrorMessage]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
