import { Button } from "@mui/material";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { getProjectBaseline } from "../../../../../../../services/projects/projectBaselines.service";
import { useModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { ViewModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton/accessories/ViewModalButton";
import { ProjectBaselineOnlyDTO } from "../../../../../../../shared/specific/DTOs/projectBaseline/ProjectBaselineOnlyDTO";
import { ProjectBaselineDTO } from "../../../../../../../shared/specific/DTOs/projectBaseline/ProjectBaselineDTO";
import { getTextIf4xxApiErrorDTO } from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { ModalContent } from "./ModalContent";

export const useViewingModal = () => {
  const {
    contentModal: viewingModal,
    ModalButtonContainer: ViewingModalButton,
  } = useModalButton<ProjectBaselineDTO, ProjectBaselineOnlyDTO>({
    button: {
      ModalButton: ViewModalButton,
      onClickContentButtonComponentMemo: async ({
        contentButtonContentProps: projectBaselineCompact,
        checkInCurrentModalCount,
        getOpenCloseModalCount,
        setInternalModalData,
        setIsLoadingModal,
        setModalError,
      }) => {
        setIsLoadingModal(true);
        const modalCountStart = getOpenCloseModalCount();

        try {
          const projectBaseline = await getProjectBaseline(
            projectBaselineCompact.id
          );
          if (!checkInCurrentModalCount(modalCountStart)) return;

          if (!projectBaseline)
            return setModalError(
              i18next.t(
                "projectBaselines.errors.data.general.projectBaselineDoesNotExist"
              )
            );

          setInternalModalData(projectBaseline);
        } catch (error) {
          if (!checkInCurrentModalCount(modalCountStart)) return;

          console.error(error);
          const errorMessage = getTextIf4xxApiErrorDTO({
            error,
            defaultMessage:
              "projectBaselines.errors.data.fetch.failedToFetchProjectBaselines",
          });

          setModalError(errorMessage);
        } finally {
          setIsLoadingModal(false);
        }
      },
    },
    modal: {
      keepModalMounted: 1000,
      ModalTitleMemo: ({ internalModalData: projectBaseline }) => {
        const { t } = useTranslation();

        if (!projectBaseline?.version) return null;

        return (
          <div>
            {t("projectBaselines.modal.data.view.titleVersion", {
              version: projectBaseline.version,
            })}
          </div>
        );
      },
      ModalContent: ({ internalModalData: projectBaseline }) =>
        projectBaseline && <ModalContent projectBaseline={projectBaseline} />,
      ModalActionsMemo: ({ closeModal }) => {
        const { t } = useTranslation();

        return (
          <Button onClick={closeModal}>
            {t("general.actions.general.close")}
          </Button>
        );
      },
    },
  });

  return {
    viewingModal,
    ViewingModalButton,
  };
};
