import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useCreationForm } from "./hooks/useCreationForm";
import { useFilterForm } from "./hooks/useFilterForm";
import { useTableData } from "./hooks/useTableData";
import {
  EnhancedTable,
  EnhancedTableRef,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { useEffectAfterRenders } from "../../../../../../shared/common/react/hooks/enhancedReactHooks/useEffectAfterRenders";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";

export const DisciplinesTab = () => {
  const { t, i18n } = useTranslation();
  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const reloadTablePage = useCallback(
    () => enhancedTableRef.current?.reloadPage(),
    []
  );

  const { filterButton, filterModal, filters } = useFilterForm();
  const { CreationButtonContainer, creationModal } = useCreationForm({
    reloadTablePage,
  });

  useEffectAfterRenders({
    effect: () => {
      reloadTablePage();
    },
    deps: [i18n.language, filters],
    rendersBeforeEffect: 0,
  });

  const { headCells, onDisciplinesPageChange, editingModal, deletionModal } =
    useTableData({
      filters,
      reloadTablePage,
    });

  const rightActionButtons = useMemo(() => {
    return (
      <>
        {filterButton}
        {filterModal}
      </>
    );
  }, [filterButton, filterModal, filters, t]);

  return (
    <>
      <EnhancedTable
        ref={enhancedTableRef}
        headCells={headCells}
        toolbarSettings={{
          rightActionButtons,
          leftActionButtonsMemo: (
            <Protected
              restrictions={{
                type: PermissionType.Discipline,
                level: PermissionLevel.Create,
              }}
            >
              {CreationButtonContainer}
            </Protected>
          ),
        }}
        uiSettings={{
          hasDynamicTableHeight: true,
          stickyHeader: true,
        }}
        paginationSettings={{
          usesPagination: true,
          externalPagination: {
            onPageChange: onDisciplinesPageChange,
          },
        }}
      />
      {creationModal}
      {editingModal}
      {deletionModal}
    </>
  );
};
