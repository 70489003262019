import { useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import {
  EnhancedTable,
  EnhancedTableRef,
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { formatNumber } from "../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { StyledGridContainer } from "./index.style";
import { InternalFormDataContentAllocationsProps } from "./shared/types/modal.types";
import { YearMonthColorComponent } from "./YearMonthColorComponent";

interface OwnProps {
  internalFormData: InternalFormDataContentAllocationsProps | null;
}

export const ListAllocations = ({
  internalFormData: allocationsData,
}: OwnProps) => {
  const { t } = useTranslation();

  const enhancedTableRef = useRef<EnhancedTableRef>(null);

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.dateTime.general.month"),
        canSort: false,
        width: 100,
        justifyContent: "left",
      },
      {
        value: `${t("projects.keywords.general.project")} / ${t(
          "costCenters.keywords.general.costCenter"
        )} `,
        canSort: false,
        width: 320,
        justifyContent: "left",
      },
      {
        value: t("customers.keywords.general.customer"),
        canSort: false,
        width: 250,
        justifyContent: "left",
      },
      {
        value: t("projects.keywords.fields.manager"),
        canSort: false,
        width: 170,
        justifyContent: "left",
      },
      {
        value: t("allocations.keywords.fields.allocationPercentage"),
        canSort: false,
        width: 100,
        justifyContent: "left",
      },
    ];

    return headCells;
  }, [t]);

  if (allocationsData?.allocations) {
    const yearMonthBackground = allocationsData.allocations
      .map((allocation) => allocation.yearMonth)
      .filter((ym, index, list) => {
        return (
          index ===
          list.findIndex(
            (item) => item.toPrettyString() === ym.toPrettyString()
          )
        );
      })
      .map((item, index) => {
        const isBg = index % 2 === 0;
        return {
          yearMonth: item,
          isBg,
        };
      });

    const rows: ExternalEnhancedRow[] = allocationsData.allocations.map(
      (allocation, i): ExternalEnhancedRow => {
        const isBg =
          yearMonthBackground.find(
            (item) =>
              item.yearMonth.toPrettyString() ===
              allocation.yearMonth.toPrettyString()
          )?.isBg ?? false;
        return {
          id: allocation.id ?? i,
          cells: [
            {
              CellRenderer: () => (
                <YearMonthColorComponent
                  isBg={isBg}
                  value={allocation.yearMonth.toPrettyString()}
                />
              ),
              paddingmode: "none",
              justifyContent: "center",
            },
            {
              CellRenderer: () => (
                <YearMonthColorComponent
                  isBg={isBg}
                  value={
                    allocation.costCenterPep
                      ? allocation.costCenterPep.project.name
                      : `${allocation.costCenter?.code} - ${t(
                          t(
                            employeeAllocationTypeToTranslationCodeMap[
                              allocation.allocationType
                            ]
                          )
                        )}` || "-"
                  }
                />
              ),
              paddingmode: "none",
              justifyContent: "center",
            },
            {
              CellRenderer: () => (
                <YearMonthColorComponent
                  isBg={isBg}
                  value={
                    allocation.costCenterPep
                      ? allocation.costCenterPep.project.customer.tradingName
                      : "-"
                  }
                />
              ),
              paddingmode: "none",
              justifyContent: "center",
            },
            {
              CellRenderer: () => (
                <YearMonthColorComponent
                  isBg={isBg}
                  value={
                    allocation?.costCenterPep?.project.userManager?.name
                      ? allocation.costCenterPep.project.userManager?.name
                      : "-"
                  }
                />
              ),
              paddingmode: "none",
              justifyContent: "center",
            },
            {
              CellRenderer: () => (
                <YearMonthColorComponent
                  isBg={isBg}
                  value={`${formatNumber(allocation.percentage, {
                    fractionDigits: 2,
                  })} %`}
                />
              ),
              paddingmode: "none",
              justifyContent: "center",
            },
          ],
        };
      }
    );

    return (
      <StyledGridContainer>
        <Typography variant="h6">
          <Trans
            i18nKey="usersCollaborators.modal.data.view.title"
            values={{
              name: allocationsData.historyContent?.jobRoleName ?? "-",
              yearMonth:
                allocationsData.historyContent?.yearMoth?.toPrettyString(),
            }}
            components={{ italic: <em /> }}
          />
        </Typography>
        <EnhancedTable
          ref={enhancedTableRef}
          headCells={headCells}
          uiSettings={{
            hasDynamicTableHeight: true,
            stickyHeader: true,
          }}
          rows={rows}
          orderDefaults={{
            defaultOrderBy: 0,
            defaultOrder: "desc",
          }}
        />
      </StyledGridContainer>
    );
  }

  return (
    <EnhancedTable
      ref={enhancedTableRef}
      headCells={headCells}
      uiSettings={{
        hasDynamicTableHeight: true,
        stickyHeader: true,
      }}
      rows={[]}
      orderDefaults={{
        defaultOrderBy: 0,
        defaultOrder: "desc",
      }}
    />
  );
};
