import { ExpenseType } from "../../enums/expenses/ExpenseType";

export const expenseTypeToTranslationCodeMap: {
  readonly [key in ExpenseType]: string;
} = {
  [ExpenseType.Tax]: "expenses.keywords.expenseType.tax",
  [ExpenseType.GrossRevenue]: "expenses.keywords.expenseType.grossRevenue",
  [ExpenseType.Workforce]: "expenses.keywords.expenseType.workforce",
  [ExpenseType.SoftwareHardware]:
    "expenses.keywords.expenseType.softwareHardware",
  [ExpenseType.Travels]: "expenses.keywords.expenseType.travels",
  [ExpenseType.Infrastructure]: "expenses.keywords.expenseType.infrastructure",
  [ExpenseType.ResellingProducts]:
    "expenses.keywords.expenseType.resellingProducts",
  [ExpenseType.Training]: "expenses.keywords.expenseType.training",
  [ExpenseType.ConsultantServices]:
    "expenses.keywords.expenseType.consultantServices",
  [ExpenseType.Refund]: "expenses.keywords.expenseType.refund",
  [ExpenseType.Other]: "expenses.keywords.expenseType.other",
  [ExpenseType.Additional]: "expenses.keywords.expenseType.additional",
  [ExpenseType.FinancialCost]: "expenses.keywords.expenseType.financialCost",
};
