import { styled } from "@mui/material";

export const StyledDelayJustificationForm = styled("div")`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

export const StyledInfoMessage = styled("div")`
  text-align: center;
  font-weight: 500;
  padding: ${({ theme }) => theme.spacing(0.5)} 0;
`;
