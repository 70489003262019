import * as yup from "yup";

export enum PermissionLevel {
  Create = 1,
  Read = 2,
  Update = 3,
  Delete = 4,
}

export const schemaPermissionLevel = yup
  .mixed<PermissionLevel>()
  .oneOf(Object.values(PermissionLevel) as PermissionLevel[]);

export const schemaPermissionLevelNullable = yup
  .mixed<PermissionLevel>()
  .nullable()
  .oneOf([
    ...Object.values(PermissionLevel),
    null,
  ] as (PermissionLevel | null)[]);
