import { useContext, useMemo } from "react";
import { AllocationWeekDTO } from "../../../../../../../../shared/specific/DTOs/allocationMap/AllocationWeekDTO";
import { LabelColorTypeColorCode } from "../../../shared/maps/LabelColorTypeColorCode";
import { AllocationMapContext } from "../../shared/context";
import { Container } from "../index.styles";
import { AllocationPercentageEnum } from "../../../../../../../../shared/specific/DTOs/allocationMap/filters/AllocationPercentageEnum";

interface OwnProps {
  allocationWeek: AllocationWeekDTO;
  idUserCollaborator: number;
  onlyAllocatedCollaborators: AllocationPercentageEnum;
}

export const PercentageAllocationContent = ({
  allocationWeek,
  idUserCollaborator,
  onlyAllocatedCollaborators,
}: OwnProps) => {
  const { data } = useContext(AllocationMapContext);

  const thereIsSomeAllocation =
    onlyAllocatedCollaborators === AllocationPercentageEnum.Allocated ||
    onlyAllocatedCollaborators === AllocationPercentageEnum.UnderAllocated;

  const labelColorTypeContext = data.find(
    (e) => e.idUserCollaborator === idUserCollaborator
  );

  const color = labelColorTypeContext?.allocationWeeks.find(
    (allocation) => allocation.weekDate === allocationWeek.weekDate
  )?.labelColorType;

  const labelColorTypeValue = color || allocationWeek.labelColorType;

  return useMemo(() => {
    return (
      <Container
        labelColorType={
          (allocationWeek?.weekPercentage &&
            allocationWeek?.weekPercentage > 0) ||
          !thereIsSomeAllocation
            ? LabelColorTypeColorCode[labelColorTypeValue]
            : ""
        }
      >
        <span>
          {allocationWeek?.weekPercentage &&
          allocationWeek?.weekPercentage !== 0
            ? `${allocationWeek?.weekPercentage}%`
            : "-"}
        </span>
      </Container>
    );
  }, [data]);
};
