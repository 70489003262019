import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { notifySuccess } from "../../../../../../../services/applicationState/toast.service";
import { useFormikModalButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { EditFormButton } from "../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton/accessories/modalButtons/EditFormButton";
import {
  getTextIf4xxApiErrorDTO,
  notifyIf4xxApiErrorDTO,
} from "../../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { FormValues } from "../shared/types/form.types";
import { useFormikConfig } from "./useFormikConfig";
import { FeeYearMonthDTO } from "../../../../../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthDTO";
import { FeeYearMonthUpdateDTO } from "../../../../../../../shared/specific/DTOs/feeYearMonth/FeeYearMonthUpdateDTO";
import {
  getFeeYearMonthyId,
  updateFeeYearMonth,
} from "../../../../../../../services/feeYearMonth/feeYarMonth.services";
import { formatNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/formatters.helpers";
import { CommonFeeYearMonthForm } from "../shared/react/components/CommonFeeYearMonthForm";
import { parseLocaleNumber } from "../../../../../../../shared/common/helpers/data/numbers/localization/parsers.helpers";

interface OwnProps {
  reloadTablePage: () => void;
}

export const useEditingForm = ({ reloadTablePage }: OwnProps) => {
  const { t } = useTranslation();
  const formikConfig = useFormikConfig();

  const { ContentButton: EditingButtonContainer, contentModal: editingModal } =
    useFormikModalButton<FormValues, FeeYearMonthDTO, FeeYearMonthDTO>({
      modal: {
        keepModalMounted: 1000,
        ModalTitleMemo: ({ internalFormData: feeYearMonth }) => {
          return (
            <Trans
              i18nKey="general.modal.data.edit.titleEditName"
              values={{
                name: `${feeYearMonth?.fee.description} - ${feeYearMonth?.fee.costCenter.code} - ${feeYearMonth?.yearMonth.toPrettyString()}`,
              }}
              components={{ italic: <em /> }}
            />
          );
        },
      },
      button: {
        FormButton: EditFormButton,
        onClickContentButtonComponentMemo: async ({
          contentButtonContentProps: feeYearMonth,
          setFormValues,
          setInternalFormData,
          setIsLoadingModal,
          setModalError,
          getOpenCloseModalCount,
          checkInCurrentModalCount,
        }) => {
          setIsLoadingModal(true);
          const startingOpenCloseModalCount = getOpenCloseModalCount();

          try {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            const feeYearMonthData = await getFeeYearMonthyId(feeYearMonth.id);

            if (!feeYearMonthData)
              return setModalError(
                t("fee.errors.data.general.feeDoesNotExist")
              );

            setFormValues({
              fee: feeYearMonthData.fee,
              yearMonth: feeYearMonthData.yearMonth.toDate(),
              useFullAllocation: feeYearMonthData.useFullAllocation,
              value: formatNumber(feeYearMonthData.value, {
                fractionDigits: 2,
              }),
            });
            setInternalFormData(feeYearMonthData);
          } catch (error) {
            if (!checkInCurrentModalCount(startingOpenCloseModalCount)) return;

            console.error(error);

            const errorMessage = getTextIf4xxApiErrorDTO({
              error,
              defaultMessage:
                "feeYearMonth.errors.data.fetch.failedToFetchFeeYearMonth",
            });
            setModalError(errorMessage);
          } finally {
            setIsLoadingModal(false);
          }
        },
      },
      form: {
        formikConfig,
        FormContentMemo: ({ internalFormData, ...rest }) => (
          <CommonFeeYearMonthForm {...rest} mode="editing" />
        ),
        FormActionsMemo: ({ submitFormValues, closeModal, isSubmitting }) => {
          const { t } = useTranslation();

          return (
            <>
              <Button onClick={closeModal} disabled={isSubmitting}>
                {t("general.actions.general.cancel")}
              </Button>
              <LoadingButton
                loading={isSubmitting}
                onClick={submitFormValues}
                variant="contained"
              >
                {t("general.actions.general.save")}
              </LoadingButton>
            </>
          );
        },
        onSubmit: async ({
          formValues,
          internalFormData,
          formikHelpers: { setSubmitting },
          closeModal,
        }) => {
          try {
            const updatedFeeYearMonth: FeeYearMonthUpdateDTO = {
              id: internalFormData?.id ?? 0,
              version: internalFormData?.version ?? "",
              useFullAllocation: formValues.useFullAllocation,
              value: parseLocaleNumber(formValues.value),
            };

            await updateFeeYearMonth(updatedFeeYearMonth);
            notifySuccess(
              i18next.t(
                "general.success.data.general.operationExecutedSuccessfully"
              )
            );
            reloadTablePage();
            closeModal();
          } catch (error) {
            notifyIf4xxApiErrorDTO({
              error,
              defaultMessage: "feeYearMonth.errors.data.edit.failedToEdit",
            });
          } finally {
            setSubmitting(false);
          }
        },
      },
    });

  return { EditingButtonContainer, editingModal };
};
