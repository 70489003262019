import { Info as InfoIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useModalButton } from "../../../../../../../../shared/common/react/hooksWithComponents/modal/modalButtons/useModalButton";
import { CostCenterPepsTable } from "../../../../shared/react/components/content/CostCenterPepsTable";
import { useProjectContext } from "../../../shared/react/contexts/ProjectContext";

export const useViewingPepsModal = () => {
  const { t } = useTranslation();

  const { project } = useProjectContext();

  const { contentButton: viewingPepsButton, contentModal: viewingPepsModal } =
    useModalButton({
      modal: {
        keepModalMounted: 1000,
        modalTitle: t("costCenterPeps.keywords.general.costCenterPeps"),
        ModalContentMemo: () => <CostCenterPepsTable project={project} />,
        ModalActionsMemo: ({ closeModal }) => {
          const { t } = useTranslation();

          return (
            <Button onClick={closeModal}>
              {t("general.actions.general.close")}
            </Button>
          );
        },
      },
      button: {
        ModalButton: ({ onClick }) => (
          <InfoIcon color="primary" onClick={onClick} />
        ),
      },
    });

  return { viewingPepsButton, viewingPepsModal };
};
