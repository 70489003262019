import { FormikProps, FormikValues } from "formik";
import { ReactNode, RefObject, useCallback, useRef } from "react";
import { UseModalButtonRef } from "../../../../../modal/modalButtons/useModalButton";
import {
  CheckInCurrentModalCountFunction,
  GetOpenCloseModalCountFunction,
  OpenModalFunction,
} from "../shared/types/functions.types";
import { EntryFormikConfig } from "../shared/types/props.types";

interface OwnProps<FormValues extends FormikValues, InternalFormData> {
  modalButtonRef: RefObject<UseModalButtonRef>;
  formikRef: RefObject<FormikProps<FormValues>>;
  setFormValues: (formValues: FormValues) => void;
  formikConfig: EntryFormikConfig<FormValues>;
  formValues: FormValues;
  setInternalFormData: (internalFormData: InternalFormData | null) => void;
  setIsLoadingModal: (isLoadingModal: boolean) => void;
  setModalError: (modalError: ReactNode) => void;
}

export const useActions = <FormValues extends FormikValues, InternalFormData>({
  modalButtonRef,
  formikRef,
  formikConfig,
  setFormValues,
  formValues,
  setInternalFormData,
  setIsLoadingModal,
  setModalError,
}: OwnProps<FormValues, InternalFormData>) => {
  const openCloseModalCountRef = useRef(0);

  const propsRef = useRef({ formikConfig });
  propsRef.current = { formikConfig };

  const closeModal = useCallback(() => {
    modalButtonRef.current?.closeModal();
  }, []);

  const openModal = useCallback<
    OpenModalFunction<FormValues, InternalFormData>
  >((onOpenCallback) => {
    modalButtonRef.current?.openModal();
    onOpenCallback?.({
      formValues,
      setInternalFormData,
      setIsLoadingModal,
      setModalError,
      setFormValues,
      getOpenCloseModalCount,
      checkInCurrentModalCount,
    });
  }, []);

  const submitFormValues = useCallback(() => {
    formikRef.current?.submitForm();
  }, []);

  const increaseOpenCloseModalCount = useCallback(() => {
    openCloseModalCountRef.current++;
  }, []);

  const getOpenCloseModalCount: GetOpenCloseModalCountFunction =
    useCallback(() => {
      return openCloseModalCountRef.current;
    }, []);

  const checkInCurrentModalCount: CheckInCurrentModalCountFunction =
    useCallback((openCloseModalCount: number) => {
      return openCloseModalCountRef.current === openCloseModalCount;
    }, []);

  const setInternalExternalFormValues = useCallback(
    (formValues: FormValues) => {
      setFormValues(formValues);
      formikRef.current?.setValues(formValues);
    },
    []
  );

  const resetFormValues = useCallback(() => {
    setInternalExternalFormValues(propsRef.current.formikConfig.initialValues);
  }, []);

  return {
    closeModal,
    openModal,
    submitFormValues,
    increaseOpenCloseModalCount,
    getOpenCloseModalCount,
    setInternalExternalFormValues,
    resetFormValues,
    checkInCurrentModalCount,
  };
};
