import * as yup from "yup";

export enum DivisionEnum {
  PeD = 1,
  HeadOffice = 2,
  Infrastructure = 3,
  Manufacturing = 4,
  Energy = 5,
}

export const schemaDivisionEnum = yup
  .mixed<DivisionEnum>()
  .oneOf(Object.values(DivisionEnum) as DivisionEnum[]);
