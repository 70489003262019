import * as yup from "yup";
import {
  SubsidiaryDTO,
  schemaSubsidiaryDTO,
} from "../subsidiary/SubsidiaryDTO";
import {
  CostDistributionPercentageDTO,
  schemaCostDistributionPercentageDTO,
} from "./CostDistributionPercentageDTO";
import {
  SegmentGroupDTO,
  schemaSegmentGroupDTO,
} from "../Segment/SegmentGroupDTO";

export interface CostDistributionDTO {
  subsidiary: SubsidiaryDTO;
  segmentGroup: SegmentGroupDTO;
  customerTrigram: string | null;
  costDistributionPercentages: CostDistributionPercentageDTO[];
}

export const schemaCostDistributionDTO = yup.object({
  subsidiary: schemaSubsidiaryDTO.defined(),
  segmentGroup: schemaSegmentGroupDTO.defined(),
  customerTrigram: yup.string().nullable().defined(),
  costDistributionPercentages: yup
    .array(schemaCostDistributionPercentageDTO)
    .defined(),
});
