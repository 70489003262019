import * as yup from "yup";
import {
  ExpenseTypeTranslationDTO,
  schemaExpenseTypeTranslationDTO,
} from "./translation/ExpenseTypeTranslation";
import {
  ExpenseGroupDTO,
  schemaExpenseGroupDTO,
} from "../ExpenseGroup/ExpenseGroupDTO";

export interface ExpenseTypeDTO {
  id: number;
  typeName: string;
  expenseGroup: ExpenseGroupDTO;
  expenseTypeTranslation: ExpenseTypeTranslationDTO[];
}

export const schemaExpenseTypeDTO = yup.object({
  id: yup.number().integer().defined(),
  typeName: yup.string().defined(),
  expenseGroup: schemaExpenseGroupDTO.defined(),
  expenseTypeTranslation: yup.array(schemaExpenseTypeTranslationDTO).defined(),
});
