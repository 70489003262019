import { FormContentProps } from "../../../../../../../../shared/common/react/hooksWithComponents/form/formik/formikModalButtons/useFormikModalButton";
import { FilterValues } from "../shared/types/general.types";
import { StyledForm } from "./index.styles";
import { CostCentersSearchAutocompleteFormik } from "../../../../../../../../shared/specific/react/components/form/formik/autocomplete/fields/costCenters/CostCentersSearchAutocompleteFormik";

type OwnProps = FormContentProps<FilterValues>;

export const FilterForm = (ownProps: OwnProps) => {
  return (
    <StyledForm>
      <CostCentersSearchAutocompleteFormik name="costCenter" />
    </StyledForm>
  );
};
