import { styled } from "@mui/material";

export const TopBarContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};

  min-height: 64px;
`;

export const TopBarLeftItems = styled("div")`
  display: flex;
  align-items: center;
  align-self: start;
  height: 40px;
`;

export const TopBarRightItems = styled("div")`
  display: flex;
  gap: ${({ theme }) => theme.spacing(2)};
  align-items: center;
  align-self: start;
  height: 2.5rem;
  min-height: 40px;
`;

export const PageTitle = styled("h1")`
  font-size: 1.6rem;
  margin: 0;
  font-weight: 400;
  word-break: break-all;
  flex: 1;
`;
