import { TimesheetMonthDTO } from "../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { EmployeeAllocationType } from "../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";
import { TimesheetType } from "../../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { timesheetTypeToAbbreviationCodeMap } from "../../../../../../../../../shared/specific/maps/timesheets/TimesheetTypeTranslations.maps";

export const getTimesheetTypeAbbreviation = (
  timesheetMonth: TimesheetMonthDTO
) => {
  if (
    [
      EmployeeAllocationType.PerformanceEvaluation,
      EmployeeAllocationType.MonthlyOnboarding,
    ].includes(timesheetMonth.allocationType)
  )
    return timesheetTypeToAbbreviationCodeMap[TimesheetType.Management];
  if (timesheetMonth.costCenter !== null)
    return timesheetTypeToAbbreviationCodeMap[TimesheetType.CostCenter];
  if (timesheetMonth.project !== null)
    return timesheetTypeToAbbreviationCodeMap[TimesheetType.Project];
  if (
    [EmployeeAllocationType.CorporateMeeting].includes(
      timesheetMonth.allocationType
    )
  )
    return timesheetTypeToAbbreviationCodeMap[TimesheetType.Corporate];

  return "-";
};
