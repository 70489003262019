import { useMemo } from "react";
import { Language } from "../../../../../enums/locale/Language.enum";
import { formatCurrency } from "../../../../../helpers/data/numbers/localization/formatters.helpers";

export interface UseFormattedCurrencyProps {
  value: number | null;
  currencySymbol: string;
  locale?: Language | "current";
}

export const useFormattedCurrency = ({
  value,
  currencySymbol,
  locale,
}: UseFormattedCurrencyProps) => {
  return useMemo(() => {
    if (value === null) return null;
    return formatCurrency({ value, currencySymbol, locale });
  }, [value, currencySymbol, locale]);
};
