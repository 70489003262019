import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import {
  CollapseTableExternalSinglePageLoader,
  HeaderComponentProps,
  RowComponentProps,
} from "../../types/data.types";
import { CollapseTableRef } from "../../types/propsRef.types";
import { CollapseTableContext } from ".";
import { CollapseTableContextValues } from "./index.types";

interface OwnProps {
  children: ReactNode;
  headCells?: HeaderComponentProps[];
  singlePageLoader: CollapseTableExternalSinglePageLoader;
  fixFirstColumn?: boolean;
  fixLastColumn?: boolean;
}

export const CollapseTableForwardRef = (
  {
    headCells,
    singlePageLoader,
    children,
    fixFirstColumn,
    fixLastColumn,
  }: OwnProps,
  ref: ForwardedRef<CollapseTableRef>
) => {
  const [error, setError] = useState<string | boolean>(false);
  const [info, setInfo] = useState<string | boolean>(false);
  const [loading, setLoading] = useState<string | boolean>(false);
  const [rows, setRows] = useState<RowComponentProps[] | null>(null);

  const reloadPage = useCallback(async () => {
    if (!singlePageLoader?.fillPage) return;

    setLoading(true);

    try {
      const rowsData = await singlePageLoader.fillPage({
        setError,
        setLoading,
        setInfo,
      });

      if (!rowsData) {
        setLoading(false);
        setError(false);
        setRows(null);
        return;
      }

      setInfo(false);
      setError(false);
      setLoading(false);
      setRows(rowsData);
    } catch (error) {
      setInfo(false);
      setError("Failed to load data.");
      console.error(error);
      setRows(null);
      setLoading(false);
    }
  }, [singlePageLoader]);

  const contexValues: CollapseTableContextValues = useMemo(() => {
    return {
      headCells,
      singlePageLoader,
      error,
      info,
      loading,
      setError,
      setInfo,
      setLoading,
      reloadPage,
      rows,
      fixFirstColumn,
      fixLastColumn,
    };
  }, [headCells, error, info, loading, rows, singlePageLoader]);

  useImperativeHandle(ref, () => ({
    ...contexValues,
  }));

  return (
    <CollapseTableContext.Provider value={contexValues}>
      {children}
    </CollapseTableContext.Provider>
  );
};

export const CollapseTableProvider = forwardRef<CollapseTableRef, OwnProps>(
  CollapseTableForwardRef
);
