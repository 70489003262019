import { TextFieldProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import { getProductServices } from "../../../../../../../../../../../../services/projects/productServices.service";
import { CachedTranslatedData } from "../../../../../../../../../../../../shared/common/classes/data/cache/CachedTranslatedData";
import {
  AsyncFastAutocompleteFormikProps,
  AsyncAutocompleteFormik,
} from "../../../../../../../../../../../../shared/common/react/components/form/formik/autocomplete/general/AsyncAutocompleteFormik";
import { ProductServiceDTO } from "../../../../../../../../../../../../shared/specific/DTOs/productService/ProductServiceDTO";
import {
  StyledAutocompleteFieldReviewInfoBubble,
  StyledDiv,
} from "./ProductServicesAutocompleteDealsFormik.styles";

interface OwnProps<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> {
  name: string;
  textfieldProps?: TextFieldProps;
  autocompleteProps?: AsyncFastAutocompleteFormikProps<
    ProductServiceDTO,
    Multiple,
    DisableClearable,
    FreeSolo
  >["autocompleteProps"];
  shouldReplaceValueOnOptionsChange?: boolean;
  productServiceFromDeal: string;
  productServiceDTO: ProductServiceDTO | null;
}

const cachedProductServices = new CachedTranslatedData({
  callback: getProductServices,
  defaultCacheDuration: 600000, // 10 minutes
});

export const ProductServicesAutocompleteDealsFormik = <
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  name,
  textfieldProps,
  autocompleteProps,
  shouldReplaceValueOnOptionsChange = true,
  productServiceFromDeal,
  productServiceDTO,
}: OwnProps<Multiple, DisableClearable, FreeSolo>) => {
  const { t, i18n } = useTranslation();

  const [hideDealProductService, setHideDealProductService] =
    useState<boolean>(false);

  const startAdornment = useMemo(() => {
    if (productServiceDTO || hideDealProductService) return undefined;
    if (!productServiceFromDeal) return undefined;
    return (
      <StyledDiv>
        {productServiceFromDeal}
        <Tooltip
          title={t("general.errors.data.fields.general.reviewField") ?? ""}
          placement="top"
        >
          <StyledAutocompleteFieldReviewInfoBubble>
            {t("general.errors.data.fields.general.reviewField")}
          </StyledAutocompleteFieldReviewInfoBubble>
        </Tooltip>
      </StyledDiv>
    );
  }, [productServiceFromDeal, productServiceDTO, hideDealProductService]);

  return (
    <AsyncAutocompleteFormik
      name={name}
      customErrorMessage={t(
        "productServices.errors.data.fetch.failedToFetchProductServices"
      )}
      fetchOptionsMemo={cachedProductServices.run}
      textfieldProps={{
        label: t("productServices.keywords.general.productService"),
        InputProps: {
          ...textfieldProps?.InputProps,
          startAdornment,
        },
        onFocus: () => setHideDealProductService(true),
        onBlur: () => setHideDealProductService(false),
        ...textfieldProps,
      }}
      autocompleteProps={{
        getOptionLabelMemo: (division) => division.name ?? String(division.id),
        isOptionEqualToValueMemo: (option, value) => option.id === value.id,
        ...autocompleteProps,
      }}
      shouldReplaceValueOnOptionsChange={shouldReplaceValueOnOptionsChange}
      rerunOnDeps={[i18n.language]}
    />
  );
};
