import { TextField, TextFieldProps } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface CustomDatePickerProps<TDate> {
  datePickerProps: Omit<DesktopDatePickerProps<TDate>, "renderInput"> & {
    minDateMemo?: TDate;
    maxDateMemo?: TDate;
  };
  textFieldProps?: TextFieldProps;
}

export const CustomDatePicker = <TDate,>({
  datePickerProps: {
    slotProps = {},
    minDate,
    maxDate,
    minDateMemo,
    maxDateMemo,
    views,
    ...datePickerProps
  },
  textFieldProps: {
    inputProps = {},
    InputProps = {},
    InputLabelProps = {},
    ...textFieldProps
  } = {},
}: CustomDatePickerProps<TDate>) => {
  const { i18n } = useTranslation();
  const finalMinDate = useMemo(() => {
    return minDate ?? minDateMemo;
  }, [minDate]);

  const finalMaxDate = useMemo(() => {
    return maxDate ?? maxDateMemo;
  }, [maxDate]);

  const finalViews = useMemo(() => {
    return views;
  }, [...(views ?? [])]);

  return useMemo(() => {
    return (
      <DesktopDatePicker
        slotProps={slotProps}
        slots={{
          textField: TextField,
        }}
        {...datePickerProps}
        minDate={finalMinDate}
        maxDate={finalMaxDate}
        views={finalViews}
      />
    );
  }, [
    ...Object.values(datePickerProps),
    ...Object.values(slotProps),
    ...Object.values(textFieldProps),
    ...Object.values(inputProps),
    ...Object.values(InputProps),
    ...Object.values(InputLabelProps),
    finalViews,
    finalMinDate,
    finalMaxDate,
    i18n.language,
  ]);
};
