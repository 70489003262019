import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getAllWithRecentlyHistory } from "../../../../../../services/user/user.service";
import {
  ExternalEnhancedHeadCell,
  ExternalEnhancedRow,
  ExternalEnhancedTableExternalPageChanger,
} from "../../../../../../shared/common/react/components/table/EnhancedTable";
import { PermissionLevel } from "../../../../../../shared/specific/enums/users/permissions/PermissionLevel.enum";
import { PermissionType } from "../../../../../../shared/specific/enums/users/permissions/PermissionType.enum";
import { TranslatedError } from "../../../../../../shared/specific/errors/general/TranslatedError";
import { throwIf4xxApiErrorDTO } from "../../../../../../shared/specific/helpers/data/errors/apiError4xx.helpers";
import { Protected } from "../../../../../../shared/specific/react/components/authentication/Protected";
import {
  getContractRoleName,
  getJobRoleName,
} from "../shared/helpers/userCollaboratorHelpers";
import { useDeletionForm } from "./useDeletionForm";
import { useEditingForm } from "./useEditingForm";
import { useTurnOnForm } from "./useTurnOnForm";
import { useTurnOffForm } from "./useTurnOffForm";
import { useUserAccessPermissionsManagementModal } from "./useUserAccessPermissionsManagementModal";
import { useViewingForm } from "./useViewingForm";
import { UserCollaboratorTrackButton } from "../shared/react/components/general/buttons/UserCollaboratorTrackButton";
import { UsersCollaboratorFullFilterDTO } from "../../../../../../shared/specific/DTOs/user/filters/UsersCollaboratorFilterDTO";
import { useEffectiveForm } from "./useEffectiveForm";

interface OwnProps {
  filters?: UsersCollaboratorFullFilterDTO;
  reloadTablePage: () => void;
}

export const useTableData = ({ filters, reloadTablePage }: OwnProps) => {
  const { t, i18n } = useTranslation();

  const { DeleteButtonContainer, deletionModal } = useDeletionForm({
    reloadTablePage,
  });

  const { EditingButtonContainer, editingModal } = useEditingForm({
    reloadTablePage,
  });

  const { TurnOffButtonContainer, turnOffModal } = useTurnOffForm({
    reloadTablePage,
  });

  const { TurnOnButtonContainer, turnOnModal } = useTurnOnForm({
    reloadTablePage,
  });

  const { userAccessPermissionsModal, UserAccessPermissionsContentButton } =
    useUserAccessPermissionsManagementModal({
      reloadTablePage,
    });

  const { ViewingButtonContainer, viewingModal } = useViewingForm();

  const { EffectiveButtonContainer, effectiveModal } = useEffectiveForm({
    reloadTablePage,
  });

  const headCells = useMemo(() => {
    const headCells: ExternalEnhancedHeadCell[] = [
      {
        value: t("general.keywords.fields.name"),
        minWidth: 200,
        headColumn: true,
        align: "left",
        canSort: false,
      },
      {
        value: t("usersCollaborators.keywords.fields.admissionDate"),
        minWidth: 200,
      },
      {
        value: t("usersCollaborators.keywords.fields.currentContractRole"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: t("usersCollaborators.keywords.fields.currentJobRole"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: t("usersCollaborators.keywords.fields.UN"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: t("usersCollaborators.keywords.fields.manager"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: t("usersCollaborators.keywords.fields.coordinator"),
        canSort: false,
        minWidth: 200,
      },
      {
        value: t("allocationsMap.keywords.form.resignation"),
        minWidth: 100,
      },
      {
        value: "",
        width: 290,
        canSort: false,
      },
    ];

    return headCells;
  }, [t]);

  const onUserCollaboratorPageChange: ExternalEnhancedTableExternalPageChanger =
    useCallback(
      async ({ newPage, order, orderBy, rowsPerPage, setInfo }) => {
        const filterValues = Object.values(filters || {});
        if (filterValues.every((x) => !x || x === "" || x === true)) {
          setInfo(t("projects.info.data.general.typeSomethingName"));
          return null;
        }

        const headKeys = ["name", "login", "admissionDate"];

        try {
          const {
            tableData: usersCollaboratorsData,
            page,
            totalCount,
          } = await getAllWithRecentlyHistory({
            filters,
            pagination: {
              pageSize: rowsPerPage,
              page: newPage + 1,
              columnOrderByDir: orderBy !== null ? order : undefined,
              columnOrderBy: orderBy !== null ? headKeys[orderBy] : undefined,
            },
          });

          const rows = usersCollaboratorsData.map(
            (userCollaborator): ExternalEnhancedRow => ({
              id: userCollaborator.id,
              cells: [
                userCollaborator.name ?? "-",
                {
                  value: userCollaborator.admissionDate,
                  displayValue:
                    userCollaborator.admissionDate?.toLocaleDateString(
                      i18n.language
                    ) ?? "-",
                },
                getContractRoleName(userCollaborator.recentlyHistory) ?? "-",
                getJobRoleName(userCollaborator.recentlyHistory),
                userCollaborator?.largestAllocationDivision?.name ?? "-",
                userCollaborator?.largestAllocationManagerName ?? "-",
                userCollaborator?.largestAllocationCoordinatorName ?? "-",
                {
                  CellRenderer: () => (
                    <>
                      {userCollaborator.resignationDate
                        ? t("general.keywords.general.yes")
                        : t("general.keywords.general.no")}
                    </>
                  ),
                },
                {
                  CellRenderer: () => (
                    <>
                      <ViewingButtonContainer {...userCollaborator} />
                      <Protected
                        restrictions={{
                          type: PermissionType.UserCollaborator,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <EditingButtonContainer
                          userCollaborator={{
                            idWorkload: null,
                            idDiscipline: null,
                            country: null,
                            state: null,
                            isAdmin: false,
                            permissions: [],
                            ...userCollaborator,
                          }}
                          contractRole={
                            getContractRoleName(
                              userCollaborator.recentlyHistory
                            ) ?? "-"
                          }
                        />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.UserCollaborator,
                          level: PermissionLevel.Update,
                        }}
                      >
                        <UserAccessPermissionsContentButton
                          id={userCollaborator.id}
                          name={userCollaborator?.name ?? ""}
                        />
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.CollaboratorHistory,
                          level: PermissionLevel.Read,
                        }}
                      >
                        <Link
                          to={`/collaborators/${userCollaborator.id}/track`}
                          target="_blank"
                        >
                          <UserCollaboratorTrackButton />
                        </Link>
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.UserCollaborator,
                          level: PermissionLevel.Create,
                        }}
                      >
                        {userCollaborator.recentlyHistory?.contractRole
                          ?.effectiveRole === false &&
                        userCollaborator.isActive === true ? (
                          <EffectiveButtonContainer
                            userCollaborator={{
                              idWorkload: null,
                              idDiscipline: null,
                              country: null,
                              state: null,
                              isAdmin: false,
                              permissions: [],
                              ...userCollaborator,
                            }}
                          />
                        ) : (
                          <> </>
                        )}
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.UserCollaborator,
                          level: PermissionLevel.Update,
                        }}
                      >
                        {userCollaborator.isActive ? (
                          <TurnOffButtonContainer {...userCollaborator} />
                        ) : (
                          <TurnOnButtonContainer {...userCollaborator} />
                        )}
                      </Protected>
                      <Protected
                        restrictions={{
                          type: PermissionType.UserCollaborator,
                          level: PermissionLevel.Delete,
                        }}
                      >
                        <DeleteButtonContainer {...userCollaborator} />
                      </Protected>
                    </>
                  ),
                  align: "right",
                  paddingmode: "horizontal",
                },
              ],
            })
          );

          return {
            rows,
            page: page - 1,
            totalItems: totalCount,
          };
        } catch (error) {
          throwIf4xxApiErrorDTO(error);

          console.error(error);

          throw new TranslatedError(
            "general.errors.data.fetch.failedToFetchData"
          );
        }
      },
      [filters, t]
    );

  return {
    headCells,
    onUserCollaboratorPageChange,
    deleteDialog: deletionModal,
    turnOffModal,
    turnOnDialog: turnOnModal,
    editingModal,
    viewingModal,
    userAccessPermissionsModal,
    effectiveModal,
  };
};
