import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CenteredContent } from "../../../../../shared/common/react/components/ui/CenteredContent";
import { PlanningContextContainer } from "./general/PlanningContextContainer";
import { ProjectPlanningWithinContext } from "./general/ProjectPlanningWithinContext";

type OwnParams = {
  idProject: string;
};

export const ProjectPlanningPage = () => {
  const { t } = useTranslation();

  const { idProject: idProjectString = "" } = useParams<OwnParams>();

  const idProject = useMemo(() => {
    return /^\d+$/.test(idProjectString) ? parseInt(idProjectString) : null;
  }, [idProjectString]);

  if (idProject === null)
    return (
      <CenteredContent>
        {t("general.errors.data.fields.id.invalid")}
      </CenteredContent>
    );

  return (
    <PlanningContextContainer idProject={idProject}>
      <ProjectPlanningWithinContext />
    </PlanningContextContainer>
  );
};
