import React from "react";
import { StyledCellTable } from "./index.style";

interface OwnProps {
  value: string | number;
  isBg: boolean;
}

export const YearMonthColorComponent: React.FC<OwnProps> = ({
  isBg,
  value,
}) => {
  return (
    <StyledCellTable isbg={isBg ? "true" : "false"}>{value}</StyledCellTable>
  );
};
