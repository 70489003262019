import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { useContext, useMemo } from "react";
import { SimpleIconButton } from "../../../../general/buttons/simpleIconButtons/SimpleIconButton";
import { CarouselContext } from "../CarouselContext";

export const CarouselRightButton = () => {
  const { addMonthsOffsetFirstYearMonth, checkCanMoveRight } =
    useContext(CarouselContext);

  const isDisabled = useMemo(() => {
    return !checkCanMoveRight(1);
  }, [checkCanMoveRight]);

  return (
    <SimpleIconButton
      Icon={ChevronRightIcon}
      onClick={() => addMonthsOffsetFirstYearMonth(1)}
      disabled={isDisabled}
    />
  );
};
