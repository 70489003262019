import { TextField } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { YearMonth } from "../../../../../../../../../../shared/common/classes/data/date/YearMonth";
import { TimesheetMonthDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthDTO";
import { TimesheetMonthWithOthersDTO } from "../../../../../../../../../../shared/specific/DTOs/timesheet/TimesheetMonthWithOthersDTO";
import { TimesheetType } from "../../../../../../../../../../shared/specific/enums/timesheets/TimesheetType.enum";
import { employeeAllocationTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/allocations/EmployeeAllocationType.maps";
import { timesheetTypeToTranslationCodeMap } from "../../../../../../../../../../shared/specific/maps/timesheets/TimesheetTypeTranslations.maps";
import { FormMode } from "../../../../types/data/form/general.types";
import { TimesheetCalendarForm } from "../TimesheetCalendarForm";
import { StyledForm } from "./index.styles";
import { EmployeeAllocationType } from "../../../../../../../../../../shared/specific/enums/allocations/EmployeeAllocationType.enums";

interface OwnProps {
  timesheetMonthWithOthers: TimesheetMonthWithOthersDTO | null;
  timesheetMonth: TimesheetMonthDTO;
  yearMonth: YearMonth;
  formMode: FormMode;
}

export const CommonTimesheetModifyingForm = ({
  timesheetMonthWithOthers,
  timesheetMonth,
  yearMonth,
  formMode,
}: OwnProps) => {
  const { t } = useTranslation();

  const isReadOnly = formMode === "viewing";

  const yearMonthField = useMemo(() => {
    return (
      <TextField
        value={yearMonth.toPrettyString()}
        label={t("general.keywords.dateTime.general.month")}
        disabled={!isReadOnly}
        InputProps={{ readOnly: isReadOnly }}
      />
    );
  }, [t, yearMonth, isReadOnly]);

  const timesheetType = useMemo(() => {
    if (
      timesheetMonth.allocationType ===
      EmployeeAllocationType.PerformanceEvaluation
    )
      return t(timesheetTypeToTranslationCodeMap[TimesheetType.Management]);
    if (timesheetMonth.costCenter)
      return t(timesheetTypeToTranslationCodeMap[TimesheetType.CostCenter]);

    if (timesheetMonth.project)
      return t(timesheetTypeToTranslationCodeMap[TimesheetType.Project]);

    if (
      timesheetMonth.allocationType === EmployeeAllocationType.CorporateMeeting
    )
      return t(timesheetTypeToTranslationCodeMap[TimesheetType.Corporate]);

    return "-";
  }, [
    timesheetMonth.costCenter,
    timesheetMonth.project,
    timesheetMonth.allocationType,
  ]);

  const projectCostCenterName = useMemo(() => {
    return (
      timesheetMonth.costCenter?.name ?? timesheetMonth.project?.name ?? "-"
    );
  }, [timesheetMonth.costCenter, timesheetMonth.project]);

  return (
    <StyledForm
      isProject={
        timesheetMonth.allocationType !==
          EmployeeAllocationType.PerformanceEvaluation &&
        timesheetMonth.allocationType !==
          EmployeeAllocationType.CorporateMeeting
      }
    >
      <TextField
        value={timesheetType}
        label={t("general.keywords.fields.type")}
        disabled={!isReadOnly}
        InputProps={{ readOnly: isReadOnly }}
      />
      {timesheetMonth.allocationType !==
        EmployeeAllocationType.PerformanceEvaluation &&
        timesheetMonth.allocationType !==
          EmployeeAllocationType.CorporateMeeting && (
          <TextField
            value={projectCostCenterName}
            label={t("timesheets.keywords.fields.projectCostCenter")}
            disabled={!isReadOnly}
            InputProps={{ readOnly: isReadOnly }}
          />
        )}

      {timesheetMonth.allocationType !==
        EmployeeAllocationType.PerformanceEvaluation &&
        timesheetMonth.allocationType !==
          EmployeeAllocationType.CorporateMeeting && (
          <TextField
            value={timesheetMonth.corporateSegment?.name || "-"}
            label={t("corporateSegment.keywords.general.corporateSegments")}
            disabled={!isReadOnly}
            InputProps={{ readOnly: isReadOnly }}
          />
        )}
      {yearMonthField}
      <TextField
        value={t(
          employeeAllocationTypeToTranslationCodeMap[
            timesheetMonth.allocationType
          ]
        )}
        label={t("general.keywords.fields.nature")}
        disabled={!isReadOnly}
        InputProps={{ readOnly: isReadOnly }}
      />
      <TimesheetCalendarForm
        name="days"
        timesheetMonth={timesheetMonthWithOthers}
        isReadOnly={isReadOnly}
      />
    </StyledForm>
  );
};
