import * as yup from "yup";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FormValues } from "../shared/types/form.types";

export const useFormikConfig = () => {
  const { t } = useTranslation();

  const initialValues: FormValues = useMemo(() => {
    return {
      name: "",
      horizontal: null,
      deactivatedDate: null,
    };
  }, [t]);

  const validationSchema = useMemo(() => {
    return yup.object({
      name: yup
        .string()
        .typeError(t("general.errors.data.fields.general.required"))
        .required(t("general.errors.data.fields.general.required")),
      horizontal: yup
        .object()
        .nullable()
        .required(t("general.errors.data.fields.general.required")),
    });
  }, [t]);

  return useMemo(
    () => ({
      initialValues,
      validationSchema,
    }),
    [initialValues, validationSchema]
  );
};
